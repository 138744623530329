// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { FormikRadio } from "src/components/Forms/Radio";
import { platform_types } from "src/constants/account";

interface Props {}

const InfluencerFlags: React.FC<Props> = () => {
  return (
    <Box mb={20} mt={10}>
      <FormikRadio name="platformType" data={platform_types} />
    </Box>
  );
};

export default InfluencerFlags;
