// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Popover } from "antd";
import styled from "styled-components";
import numeral from "numeral";
import compact from "lodash/compact";
import AccountTitle from "./AccountTitle";
import AccountContent from "./AccountContent";

const AccountPopover = styled(Popover)`
  &&& {
    .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
      display: none !important;
    }
  }
`;

const getColumns = ({ sorter, type, onTrack }) => {
  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      sorter: false,
      sortOrder: "",
      width: 50,
      visible: true,
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.key}
        </Typography>
      ),
    },

    {
      title: "Account name",
      dataIndex: "accountName",
      key: "accountName",
      sorter: false,
      sortOrder: "",
      width: 150,
      visible: true,
      render: (text, row) => (
        <AccountPopover
          placement="left"
          content={<AccountContent account={row} />}
          title={<AccountTitle account={row} onTrack={onTrack} />}
        >
          <a
            href={`https://tiktok.com/@${row?.accountName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography size={12} cursor="pointer" color="blue" mb={0}>
              {row?.accountName ? row?.accountName : "-"}
            </Typography>
          </a>
        </AccountPopover>
      ),
    },
  ];

  if (type === "views") {
    columns.push({
      title: "Views",
      dataIndex: "averageViews",
      key: "averageViews",
      sorter: (a, b) => {
        return a.averageViews - b.averageViews;
      },
      sortOrder: sorter.field === "averageViews" && sorter.order,
      width: 100,
      visible: true,
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {numeral(row?.averageViews).format("0,0")}
        </Typography>
      ),
    } as any);
  }

  if (type === "engagement") {
    columns.push({
      title: "Eng. Rate",
      dataIndex: "engagementRate",
      key: "engagementRate",
      sorter: (a, b) => {
        return a.engagementRate - b.engagementRate;
      },
      sortOrder: sorter.field === "engagementRate" && sorter.order,
      width: 100,
      visible: true,
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {numeral(row?.engagementRate).format("0,0.00") + "%"}
        </Typography>
      ),
    } as any);
  }

  if (type === "growth") {
    columns.push({
      title: "Growth",
      dataIndex: "growthRate",
      key: "growthRate",
      sorter: (a, b) => {
        return a.growthRate - b.growthRate;
      },
      sortOrder: sorter.field === "growthRate" && sorter.order,
      width: 100,
      visible: true,
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {numeral(row?.growthRate).format("0,0.00") + "%"}
        </Typography>
      ),
    } as any);
  }

  return compact(columns);
};

export default getColumns;
