// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Spin } from "antd";
import { Button } from "src/components/Core/Button";
import { FormikHelpers as FormikActions } from "formik";
import { Schema } from "yup";
import { Input, DatePicker, Form as FormikForm } from "formik-antd";
import moment from "moment";
import {
  UpdateGiftingCampaign,
  GiftingCampaignFragmentFragment,
} from "src/generated/ApolloComponents";
import { Form } from "src/components/Forms/Form";
import { ImageInput } from "src/components/Forms/ImageInput";
import { GiftingWizardWrapper } from "../../GiftingWizardWrapper";

interface Props {
  schema: Schema<object>;
  initialValues?: UpdateGiftingCampaign;
  loading: boolean;
  campaign?: GiftingCampaignFragmentFragment;
  setStep: (number: number) => void;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
}

export const AboutProduct = ({
  schema,
  onSubmit,
  setStep,
  initialValues,
  loading,
  campaign,
}: Props) => {
  const dateFormat = "YYYY/MM/DD";

  return (
    <GiftingWizardWrapper campaign={campaign} setStep={setStep} step="product">
      <Box borderRadius={5} background="white" padding={30} width="100%">
        <Spin spinning={loading}>
          <Form
            initialValues={initialValues}
            schema={schema}
            onSubmit={onSubmit}
          >
            {({ submitForm }) => {
              return (
                <Box width="100%">
                  <FormikForm.Item
                    name="title"
                    label="What is the title of this campaign?"
                    colon={false}
                  >
                    <Input
                      name="title"
                      placeholder="e.g. Massive christmas giveaway"
                    />
                  </FormikForm.Item>

                  <FormikForm.Item
                    name="image"
                    label="Please upload an image for this campaign (e.g. Company logo)"
                    colon={false}
                  >
                    <ImageInput name="image" />
                  </FormikForm.Item>

                  <FormikForm.Item
                    name="brief.brandName"
                    label="How would you like your brand name to appear to influencers?"
                    colon={false}
                  >
                    <Input name="brief.brandName" placeholder="e.g. Adidas" />
                  </FormikForm.Item>

                  <FormikForm.Item
                    name="brief.linkToWebsite"
                    label="Please enter a link to your company/product website"
                    colon={false}
                  >
                    <Input
                      name="brief.linkToWebsite"
                      placeholder="e.g. https://google.com"
                    />
                  </FormikForm.Item>

                  <FormikForm.Item
                    label="Tell influencers a little bit about your brand"
                    name="brief.aboutBrand"
                    colon={false}
                  >
                    <Input.TextArea
                      name="brief.aboutBrand"
                      rows={4}
                      placeholder="e.g. We are the largest producer of christmas jumpers"
                    />
                  </FormikForm.Item>

                  <FormikForm.Item
                    name="startDate"
                    label="When would you like this campaign to run?"
                    colon={false}
                  >
                    <DatePicker
                      name="startDate"
                      placeholder="Start date"
                      defaultValue={moment("2015/01/01", dateFormat)}
                      format={dateFormat}
                    />
                    <DatePicker
                      name="endDate"
                      placeholder="End date"
                      defaultValue={moment("2015/01/01", dateFormat)}
                      format={dateFormat}
                    />
                  </FormikForm.Item>

                  <Button onClick={submitForm} type="action">
                    Continue
                  </Button>
                </Box>
              );
            }}
          </Form>
        </Spin>
      </Box>
    </GiftingWizardWrapper>
  );
};
