// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import AccountItem from "./AccountItem";

interface Props {
  accounts: Array<any>;
  selectedAccounts: Array<any>;
  onSelectAccount: (value: any) => void;
}

const AccountList: React.FC<Props> = ({
  accounts,
  selectedAccounts,
  onSelectAccount,
}) => {
  return (
    <Box>
      {accounts.map((account, i) => {
        let selectAccount = find(
          selectedAccounts,
          matchesProperty("invitationId", account?.invitationId)
        );

        let index = selectedAccounts.findIndex(
          obj => obj.invitationId === account?.invitationId
        );

        return (
          <AccountItem
            key={i}
            account={account}
            isActive={
              selectAccount?.invitationId === account?.invitationId
                ? true
                : false
            }
            onSelectAccount={() => onSelectAccount(account)}
            index={index}
          />
        );
      })}
    </Box>
  );
};

export default AccountList;
