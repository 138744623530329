// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { InfluencerRatingFormContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import { Loading } from "src/components/Core/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  CreationTime,
  ResponseTime,
  ContentQuality,
  Professionalism,
} from "./FormFields";

interface InfluencerRatingFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  closeInfluencerRatingModal: () => void;
  initialValues: Values;
  isSubmitting: boolean;
  influencerRatingSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
}

export const InfluencerRatingFormWrapper = styled.div`
  width: 100%;
`;

const InfluencerRatingForm: React.FC<InfluencerRatingFormProps> = ({
  initialValues,
  isSubmitting,
  influencerRatingSchema,
  onSubmit,
  globalErrors,
  closeInfluencerRatingModal,
}) => {
  return (
    <InfluencerRatingFormWrapper>
      <Loading spinning={isSubmitting}>
        <Form
          initialValues={initialValues}
          schema={influencerRatingSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                <Box mb={40}>
                  <Box display="flex" justifyContent="space-between" mt={18}>
                    <Typography
                      size={30}
                      weight="semi-bold"
                      color="black"
                      lineHeight={0}
                    >
                      Influencer rating
                    </Typography>
                    {/* <Typography size={16} color="black">
                      <Icon type="plus" /> Blacklist this Influencer
                    </Typography> */}
                  </Box>
                  <Typography size={16} color="grey7">
                    Please fill out the following information about this
                    influencer. We use this information to help you find the
                    best talent to work with.
                  </Typography>
                </Box>

                <GlobalErrors errors={globalErrors} />

                <Row gutter={48} justify="start">
                  <Col span={12}>
                    <CreationTime />
                    <ContentQuality />
                  </Col>

                  <Col span={12}>
                    <ResponseTime />
                    <Professionalism />
                  </Col>
                </Row>

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={20}
                >
                  <LinkButton
                    onClick={() => {
                      closeInfluencerRatingModal();
                    }}
                    mr={30}
                    id="infl_rating_scrn_cancel"
                    color="red6"
                    background="grey21"
                    height="40px"
                  >
                    Cancel
                  </LinkButton>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    buttonSize="middle"
                    data-cy="rating-modal-form-submit"
                    id="infl_rating_scrn_save"
                  >
                    Save
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </InfluencerRatingFormWrapper>
  );
};

export { InfluencerRatingForm, InfluencerRatingFormContainer };
