// @ts-nocheck
import React, { useState } from "react";
import InfluencerSignupForm, {
  InfluencerSignupFormValues,
  InfluencerSignupFormAccountValues,
} from ".";
import { object } from "yup";
import { gql } from "apollo-boost";
import { useNewInfluencerSignupMutation } from "src/generated/ApolloComponents";
import map from "lodash/map";

interface Props {}

gql`
  mutation NewInfluencerSignup($data: NewInfluencerInput!) {
    newInfluencerSignup(data: $data) {
      id
    }
  }
`;

const InfluencerSignupFormContainer = (props: Props) => {
  const [accounts, setAccounts] = useState<InfluencerSignupFormAccountValues[]>(
    []
  );
  const [
    doInfluencerSignup,
    { data, loading },
  ] = useNewInfluencerSignupMutation();

  const removeAccount = (index: number) => {
    setAccounts(accounts.filter((_, i) => i !== index));
  };

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
  };

  const schema = object<InfluencerSignupFormValues>().shape({});

  const onSubmit = values => {
    doInfluencerSignup({
      variables: {
        data: {
          ...values,
          accounts,
        },
      },
    });
  };

  const onNewAccountSubmit = values => {
    if (values.index !== undefined) {
      setAccounts(
        map(accounts, (x, k) => {
          if (k === values.index) {
            return values;
          } else {
            return x;
          }
        })
      );
    } else {
      setAccounts([...accounts, values]);
    }
  };

  return (
    <InfluencerSignupForm
      initialValues={initialValues}
      schema={schema}
      completed={!!data?.newInfluencerSignup}
      onSubmit={onSubmit}
      onNewAccountSubmit={onNewAccountSubmit}
      onRemoveAccount={removeAccount}
      accounts={accounts}
      loading={loading}
    />
  );
};

export default InfluencerSignupFormContainer;
