import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { message, Button } from "antd";
import withModules from "src/utils/hoc/withModules";
import withModal from "src/utils/hoc/withModal";
import PageHero from "src/shared/PageHero";
import { BrandListModule, BrandList } from "src/ducks/DataList";
import { BrandPersistModule, BrandPersist } from "src/ducks/Persist";
import Auth from "src/ducks/Auth";
import DashboardLayout from "../../layouts/DashboardLayout";
import Container from "src/shared/Container";
import InviteUserForm from "./InviteUserForm";
import TeamTable from "src/shared/TeamTable";

const modules = [
  BrandListModule("BrandTeamMembers")(),
  BrandPersistModule("BrandTeamMembers")()
];

const TeamList = BrandList("BrandTeamMembers");
const TeamPersist = BrandPersist("BrandTeamMembers");

const ExtraButton = styled(Button)`
  margin-top: -3px;
`;

const TableContainer = styled("div")`
  background: #fff;

  &&& {
    .ant-table-pagination.ant-pagination {
      display: block;
      text-align: center;
      float: none;
    }
  }
`;

class TeamManagement extends Component {
  state = {
    page: 1
  };

  componentDidMount() {
    this.loadMembers();
  }

  loadMembers() {
    this.props.loadTeamMembers(this.state);
  }

  removeTeamMember = id => {
    const { destroyTeamMember } = this.props;
    destroyTeamMember(id).catch(() => message.error("Failed to delete user"));
  };

  createNewTeamMember = data => {
    const { createTeamMember, closeModal } = this.props;
    return createTeamMember(data)
      .then(() => {
        this.loadMembers();
        closeModal();
      })
      .catch(res => {
        message.error("Failed to create user");
        throw res;
      });
  };

  changePage = page => {
    this.setState(
      {
        page: page
      },
      this.loadMembers
    );
  };

  render() {
    const {
      teamMembers,
      visible,
      closeModal,
      loading,
      currentUser,
      showModal,
      pagination
    } = this.props;

    return (
      <DashboardLayout title="Team">
        <PageHero
          title="Team Management"
          subTitle="Here you can add and remove team members"
          extra={[
            <ExtraButton key="1" onClick={showModal} type="primary">
              Add new team member
            </ExtraButton>
          ]}
        />

        <Container>
          <TableContainer>
            <TeamTable
              pagination={pagination}
              data={teamMembers}
              currentUser={currentUser}
              removeTeamMember={this.removeTeamMember}
              changePage={this.changePage}
            />
          </TableContainer>
        </Container>

        {visible && (
          <InviteUserForm
            visible={visible}
            onCancel={closeModal}
            loading={loading}
            onOk={this.createNewTeamMember}
          />
        )}
      </DashboardLayout>
    );
  }
}

const mapStateToProps = state => ({
  teamMembers: TeamList.selectMany(state),
  pagination: TeamList.selectPagination(state),
  currentUser: Auth.selectUser(state),
  loading: TeamPersist.selectCreatePending(state)
});

const mapDispatchToProps = {
  loadTeamMembers: TeamList.loadMany,
  destroyTeamMember: TeamPersist.destroy,
  createTeamMember: TeamPersist.create
};

export default withModules(
  withModal(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(TeamManagement)
  )
)(modules);
