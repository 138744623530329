// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import PaymentDetail from "./PaymentDetail";
import { useGetPaymentRequestsQuery } from "src/generated/ApolloComponents";

const DetailModal = styled(Modal)`
  &&& {
    border-radius: 6px;
  }
`;

interface Props {
  showPaymentDetail: boolean;
  closePaymentDetailModal: () => void;
  paymentDetail: any;
  isSuperAdmin: boolean;
  onAccept: (value: any) => void;
  onReject: (value: any) => void;
  onReconcile: (value: any) => void;
  onUndo: (value: any) => void;
  onDelete: (value: any) => void;
  loading: boolean;
}

const PaymentDetailModal = ({
  closePaymentDetailModal,
  showPaymentDetail,
  paymentDetail,
  isSuperAdmin,
  onAccept,
  onReject,
  onReconcile,
  onUndo,
  onDelete,
  loading,
}: Props) => {

  const { data, loading: currPaymentLoading } = useGetPaymentRequestsQuery(
    {
      variables: {
        paymentRequestId: paymentDetail?.id,
      },
      fetchPolicy: "network-only",
      skip: !showPaymentDetail,
      notifyOnNetworkStatusChange: true,
    }
  );

  return (
    <DetailModal
      style={{
        maxWidth: 1094,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showPaymentDetail}
      onCancel={closePaymentDetailModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <PaymentDetail
        paymentDetail={data?.getPaymentRequests.paymentRequests[0]}
        onAccept={value => onAccept(value)}
        onReject={value => onReject(value)}
        onReconcile={value => onReconcile(value)}
        onUndo={value => onUndo(value)}
        onDelete={value => onDelete(value)}
        isSuperAdmin={isSuperAdmin}
        loading={loading || currPaymentLoading}
      />
    </DetailModal>
  );
};

export default PaymentDetailModal;
