import React, { Component } from "react";
import { connect } from "react-redux";
import { DownOutlined } from '@ant-design/icons';
import { Popconfirm, message, Spin, Dropdown, Menu } from "antd";

import CampaignManage from "src/ducks/CampaignManage";
import { EmailPersist } from "src/ducks/Persist";
const SendEmailReview = EmailPersist("SendEmailReview");

export class SendEmail extends Component {
  state = {
    loading: false
  };

  sendEmail = () => {
    const { campaign, accountReview } = this.props;
    this.setState({ loading: true });
    accountReview(campaign.id, 'post', 'emails', { type: "review_accounts", campaign: campaign.id })
      .catch(() => {
        message.error("Failed to send email.");
        this.setState({ loading: false });
      })
      .then(() => {
        this.setState({ loading: false })
        message.success("Email Sent");
      });
  };


  render() {

    const menu = (
      <Menu>
        <Menu.Item key="influencer-review">
          <Popconfirm
            placement="left"
            title="Are you sure you want to send email?"
            onConfirm={this.sendEmail}
            okText="Yes"
            cancelText="No"
          >
            Send influencers for review email
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <Spin spinning={this.state.loading}>
        <Dropdown.Button overlay={menu} data-cy="campaign-send-email" trigger="click" icon={<DownOutlined />} >
          Alert Brand
        </Dropdown.Button>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state)
});

const mapDispatchToProps = {
  accountReview: SendEmailReview.customMethod
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendEmail);
