import React, { Component } from "react";
import { Typography } from "antd";
import styled from "styled-components";
import { Icon } from '@ant-design/compatible'

const Tag = styled("div")`
  border: 1px solid #d9d9d9;
  margin-top: 24px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  border-radius: 4px;
`;

Tag.Close = styled(Icon)`
  margin-top: 5px;
  cursor: pointer;
`;

Tag.Value = styled("div")``;

Tag.Name = styled(Typography.Text)`
  &&& {
    display: block;
    color: #000;
  }
`;

Tag.Text = styled(Typography.Text)``;

export default class FilterTag extends Component {
  render() {
    const { name, value, onRemove } = this.props;

    const valueText = Array.isArray(value) ? value.join(", ") : value;

    return (
      <Tag>
        <Tag.Value>
          <Tag.Name>{name}</Tag.Name>
          <Tag.Text>{valueText}</Tag.Text>
        </Tag.Value>
        <Tag.Close type="close" onClick={onRemove} />
      </Tag>
    );
  }
}
