// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";
import { AdHubTable } from ".";
import qs from "qs";
import { useRouteMatch, useLocation, useHistory } from "react-router";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useGetAdHubMediaListQuery } from "src/generated/ApolloComponents";

type Props = {};

export const AdHubTableContainer: React.FC<Props> = () => {
  const [showAdUpload, setShowAdUpload] = useState(false);
  const [showAuthoriseAdPlatform, setShowAuthoriseAdPlatform] = useState(false);

  const match: any = useRouteMatch();
  const history: any = useHistory();
  const params: any = match?.params;

  const location: any = useLocation();

  const campaignId = parseInt(params?.id);

  useEffect(() => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (parsed?.success === "1") {
      setShowAuthoriseAdPlatform(true);
      history?.replace(`/campaigns/${campaignId}/ad-hub`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filters, setFilters] = useState({
    accountName: "",
    campaignId: campaignId,
  });

  const { data, loading, error, refetch } = useGetAdHubMediaListQuery({
    variables: filters,
  });

  const onFilterChange = async filters => {
    try {
      refetch(filters);
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onFilterChange, 1000)).current;

  useEffect(() => {
    let updatedFilters = { ...filters };

    debouncedFilterChange(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <AdHubTable
      data={data?.getAdHubMediaList || {}}
      globalErrors={globalErrors}
      loading={loading}
      onFilter={(key, value) => {
        filters[key] = value;
        setFilters({ ...filters });
      }}
      filters={filters}
      showAdUpload={showAdUpload}
      setShowAdUpload={value => setShowAdUpload(value)}
      setShowAuthoriseAdPlatform={value => setShowAuthoriseAdPlatform(value)}
      showAuthoriseAdPlatform={showAuthoriseAdPlatform}
      refetchAdHubMedia={refetch}
      campaignId={campaignId}
    />
  );
};
