// @ts-nocheck
import React from "react";
import { ExternalInfluencer } from ".";
import { useRouteMatch } from "react-router";
import { useInfluencerDetails } from "../InfluencerProfileProvider";
import { useGetAccountDetailByIdQuery } from "src/generated/ApolloComponents";

interface Props {}

export const ExternalInfluencerContainer: React.FC<Props> = () => {
  const { platformType } = useInfluencerDetails();

  const newPlatformType =
    platformType.charAt(0).toUpperCase() + platformType.slice(1);

  const match: any = useRouteMatch();

  let accountId = match.params["id"];

  const account = useGetAccountDetailByIdQuery({
    variables: {
      accountId: accountId,
      platformType: newPlatformType,
    },
  });

  const accountData = account?.data?.getAccountDetailById || {};

  return (
    <ExternalInfluencer
      loading={account?.loading}
      platformType={platformType}
      accountData={accountData}
    />
  );
};
