// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import logo from "../../../../assets/images/logo.png";
import whitelogo from "../../../../assets/images/logo-white.png";
import { Wrapper, Content, Logo, TopBar } from "./styles";

interface Props {
  title: string;
  styles: React.CSSProperties;
  children: React.ReactNode;
  isOverlayDiv?: boolean;
}

const FromLayout: React.FC<Props> = ({
  title,
  styles,
  children,
  isOverlayDiv,
}) => {
  return (
    <DocumentTitle title={title}>
      <Wrapper style={styles}>
        <Content>
          <TopBar>
            <Link to={"/sign_in"}>
              <Logo src={isOverlayDiv ? whitelogo : logo} />
            </Link>
          </TopBar>
          {children}
        </Content>
      </Wrapper>
    </DocumentTitle>
  );
};

export default FromLayout;
