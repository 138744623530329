// @ts-nocheck
import React from 'react'
import { Field } from 'src/components/Forms/Field'
import { genders } from 'src/constants/influencer'
import { FormikSelect } from 'src/components/Forms/Select'

const Gender = () => {
  return (
    <Field label="Gender:" mb={26}>
      <FormikSelect
        name="gender"
        size="large"
        placeholder="Select..."
        data={genders}
        local
        borderRadius={3}
        borderColor="grey8"
      />
    </Field>
  );
};

export default Gender;
