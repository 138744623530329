import Review from "./Review";

import {
  BasicDetailsValidate,
  TrackingValidate,
  TargettingValidate,
  CreativeValidate,
} from "./Validations";

import {
  Title,
  Goal,
  Budget,
  StartDate,
  EndDate,
  AdFormat,
  Age,
  DistributionPlatforms,
  Gender,
  Locations,
  TrackingLink,
  IntegrationPartner,
  IosId,
  AndroidId,
  Creative,
  Brief,
  BriefDo,
  BriefDont,
  AboutBrand,
  HygieneFactor,
  InfluencerKinds,
  HashTags,
  PerfectCreative,
  Tags,
  NeedLink,
} from "src/shared/CampaignFields";

export default [
  {
    title: "Basic Details",
    fields: {
      title: Title,
      objective: Goal,
      ad_format: AdFormat,
      distribution_platforms: DistributionPlatforms,
      budget_pennies: Budget,
      start_date: StartDate,
      end_date: EndDate,
    },
    validate: BasicDetailsValidate,
  },
  {
    title: "Targeting",
    fields: {
      ages: Age,
      genders: Gender,
      locations: Locations,
      tags: Tags,
    },
    validate: TargettingValidate,
  },
  {
    title: "Tracking",
    fields: {
      link_tracked: NeedLink,
      default_link: TrackingLink,
      integration_partner: IntegrationPartner,
      ios_id: IosId,
      android_id: AndroidId,
    },
    validate: TrackingValidate,
  },
  {
    title: "Creative",
    fields: {
      creative: Creative,
      perfect_creative: PerfectCreative,
      about_brand: AboutBrand,
      brief: Brief,
      brief_do: BriefDo,
      brief_dont: BriefDont,
      hashtags: HashTags,
      hygiene_factor: HygieneFactor,
      influencer_kinds: InfluencerKinds,
    },
    validate: CreativeValidate,
  },
  {
    title: "Review",
    updateOnly: true,
    component: Review,
  },
];
