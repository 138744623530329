import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Popconfirm, message, Spin } from "antd";
import CampaignManage from "src/ducks/CampaignManage";

export class UpdateCampaignStatus extends Component {
  toggleStatus = () => {
    const { campaign } = this.props;
    return campaign.status === "active" ? "complete" : "active";
  }

  state = {
    loading: false,
    newStatus: this.toggleStatus()
  };

  updateCampaign = () => {
    const { campaign, updateCampaign } = this.props;
    this.setState({ loading: true });
    updateCampaign(campaign.id, { status: this.state.newStatus })
      .catch(() => {
        message.error(`Failed to mark campaign as ${this.state.newStatus}.`);
        this.setState({ loading: false });
      })
      .then(() => this.setState({ loading: false, newStatus: this.toggleStatus() }));
  };



  render() {
    return (
      <Popconfirm
        placement="left"
        title={`Are you sure you want to mark this campaign as ${this.state.newStatus}?`}
        onConfirm={this.updateCampaign}
        okText="Yes"
        cancelText="No"
      >
        <Spin spinning={this.state.loading}>
          <Button data-cy="campaign-complete-button" type="primary">
            {this.state.newStatus === "active" ? "Activate Campaign" : "Complete Campaign"}
          </Button>
        </Spin>
      </Popconfirm>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {
  updateCampaign: CampaignManage.campaign.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCampaignStatus);
