import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Upload, Button } from "antd";
import FormField from "../FormField";
import { Field } from "formik";
import withModules from "src/utils/hoc/withModules";
import { CreativePersist } from "src/ducks/Persist";
import CreativeLoad from "./CreativeLoad";
import axios from "axios";
import { useGetGeneralSignedUrlMutation } from 'src/generated/ApolloComponents'

const CreativeManage = CreativePersist("CreativeField");
const modules = [CreativeManage.module()];

export const Creative = ({...props}) => {
  const [loading, setLoading] = useState(false)
  const [getGeneralSignedUrlMutation] = useGetGeneralSignedUrlMutation()

  const onUpload = async (data, name, form) => {
    const { createCreative, company_id } = props;
    const { setFieldValue, setFieldTouched } = form;

    const additional = company_id ? { company: company_id } : {};

    setLoading(true);

    const fileData = {
      fileName: data.name,
      fileType: data.type,
      folder: 'campaign-creative'
    };

    const signedUrlRes = await getGeneralSignedUrlMutation({
      variables: fileData,
    });

    const signedUrl = signedUrlRes.data.getGeneralSignedURL.url;

    const options = {
      headers: {
        "Content-Type": data.type,
      },
    };

    const creativeUpload = {
      title: "New creative",
      video_url: signedUrlRes.data.getGeneralSignedURL.uploadUrl,
      ...additional,
    }

    axios
      .put(signedUrl, data, options)
      .then((response) => {
        createCreative(creativeUpload).then(res => {
          setFieldValue(name, res.value.data.id);
          setFieldTouched(name, true);
        });

        setLoading(false);
      }).catch(err => setLoading(false))


    // return cloudinary
    //   .v2uploadCreative(data)
    //   .then(response => {
    //     createCreative({
    //       title: "New creative",
    //       video: response.data.public_id,
    //       ...additional,
    //     }).then(res => {
    //       setFieldValue(name, res.value.data.id);
    //       setFieldTouched(name, true);
    //     });

    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     setLoading(false);
    //   });
  };

  const name = props.name || "creative";

  return (
    <FormField
      name={name}
      label="Do you already have an example creative that you like and want to share with us?"
      help="This should be a vertical video"
    >
      <Field name={name}>
        {({ field: { value }, form }) => (
          <Upload
            name={name}
            accept="video/*"
            showUploadList={false}
            customRequest={({ file }) => onUpload(file, name, form)}
          >
            <CreativeLoad creative_id={value} />
            <Button>
              <LegacyIcon type={loading ? "loading" : "upload"} /> Click
              to Upload
            </Button>
          </Upload>
        )}
      </Field>
    </FormField>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  createCreative: CreativeManage.create,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(Creative)
)(modules);
