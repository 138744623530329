// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  getAgencies: Function;
}

const Agency: React.FC<Props> = ({ getAgencies }) => {
  return (
    <Field label="Agency:" mb={26}>
      <FormikSelect
        name="agencyId"
        size="middle"
        placeholder="Select..."
        loadData={async value => {
          let agency = await getAgencies({
            agencyName: value,
          });
          if (agency?.data?.getAllAgencies?.length > 0) {
            return agency.data.getAllAgencies;
          } else {
            return [];
          }
        }}
        optionGenerator={value => ({
          value: value.id,
          label: value.agencyName,
        })}
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Agency;
