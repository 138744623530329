import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter, Switch, Route } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import { PageHeader, Layout } from "antd";
import DashboardLayout from "src/scenes/layouts/DashboardLayout";
import { CompanyPersist } from "src/ducks/Persist";
import Loading from "src/shared/Loading";
import CompanyDetails from "./CompanyDetails";
import UpdateCompany from "./UpdateCompany";
import SideMenu from "./SideMenu";
import { useAppDetails } from "src/AppProvider";

const { Content, Sider } = Layout;

const ShowCompany = CompanyPersist("AdminCompanyShow");

const modules = [ShowCompany.module()];

const BorderHeader = styled(PageHeader)`
  border-bottom: 1px solid #e8e8e8;
`;

const CompanyShow = props => {
  const { collapsed } = useAppDetails();

  useEffect(() => {
    const {
      loadCompany,
      match: {
        params: { id },
      },
    } = props;
    loadCompany(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { history, company, loading, match } = props;

  return (
    <DashboardLayout title={company ? company.company_name : "Loading..."}>
      <Loading spinning={!company || loading}>
        {company && (
          <>
            <BorderHeader
              style={{
                top: "68px",
                width: `calc(100% - ${collapsed ? 80 : 200}px)`,
                overflow: "auto",
                position: "fixed",
                zIndex: 60,
                backgroundColor: 'white',
              }}
              onBack={history.goBack}
              title={company.company_name}
            />
            <Layout>
              <Sider
                width={200}
                style={{
                  background: "#fff",
                  overflow: "auto",
                  height: "100vh",
                  marginTop: 69,
                  position: "fixed",
                }}
              >
                <SideMenu />
              </Sider>
              <Content
                style={{
                  marginLeft: 200,
                  marginTop: 58,
                  height: "calc(100vh - 126px)",
                }}
              >
                <Switch>
                  <Route
                    path={`${match.path}/update`}
                    exact
                    component={UpdateCompany}
                  />
                  <Route
                    path={`${match.path}`}
                    exact
                    component={CompanyDetails}
                  />
                </Switch>
              </Content>
            </Layout>
          </>
        )}
      </Loading>
    </DashboardLayout>
  );
};

const mapStateToProps = state => ({
  company: ShowCompany.selectOne(state),
  loading: ShowCompany.selectGetPending(state),
});

const mapDispatchToProps = {
  loadCompany: ShowCompany.get,
};

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyShow))
)(modules);
