// @ts-nocheck
import React, { useState } from "react";
import { Row, Empty } from "antd";
import styled from "styled-components";
import moment from "moment";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { CurrentFollowersGraphContainer } from "src/scenes/admins/InfluencerProfile/AccountDetail/Graphs/CurrentFollowersGraph";
import { AverageCommentsGraphContainer } from "src/scenes/admins/InfluencerProfile/AccountDetail/Graphs/AverageCommentsGraph";
import { AverageHeartsGraphContainer } from "src/scenes/admins/InfluencerProfile/AccountDetail/Graphs/AverageHeartsGraph";
import { DataSourcePlatform } from "src/generated/ApolloComponents";

const GraphRow = styled(Row)`
  && {
    .ant-col {
      padding: 8px !important;
    }
  }
`;

interface Props {
  accountData: any;
  breakdownLastUpdateDate: any;
}

const AccountCharts: React.FC<Props> = ({
  accountData,
  breakdownLastUpdateDate,
}) => {
  const [hasCurrentFollowers, setHasCurrentFollowers] = useState(false);
  const [hasAverageComments, setHasAverageComments] = useState(false);
  const [hasAverageHerts, setHasAverageHerats] = useState(false);

  const username = accountData?.username;

  return (
    <>
      <Box
        display={
          hasCurrentFollowers || hasAverageComments || hasAverageHerts
            ? "block"
            : "none"
        }
      >
        <Box>
          <Typography size={14} weight="semi-bold" color="grey6">
            Last updated:{" "}
            {moment(breakdownLastUpdateDate).format("Do MMM YYYY, h:mm a")}
            {/* Last updated: {moment(
            chartData?.lastUpdatedDate
          ).format("Do MMM YYYY, h:mm a")}  */}
          </Typography>
        </Box>

        {/* <Box>
        <Typography size={24} weight="semi-bold" color="black5">
          Profile data
        // </Typography>
      </Box>
      <Divider /> */}

        <GraphRow gutter={16}>
          <Box
            background="#FFFFFF"
            p={10}
            borderRadius="2px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
          >
            <CurrentFollowersGraphContainer
              username={username}
              platformType={DataSourcePlatform.Bytesights}
              onChangeData={data => setHasCurrentFollowers(data?.length > 0)}
            />
          </Box>

          <Box
            background="#FFFFFF"
            p={10}
            borderRadius="2px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
          >
            <AverageCommentsGraphContainer
              username={username}
              platformType={DataSourcePlatform.Bytesights}
              onChangeData={data => setHasAverageComments(data?.length > 0)}
            />
          </Box>
          <Box
            background="#FFFFFF"
            p={10}
            borderRadius="2px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
          >
            <AverageHeartsGraphContainer
              username={username}
              platformType={DataSourcePlatform.Bytesights}
              onChangeData={data => setHasAverageHerats(data?.length > 0)}
            />
          </Box>
        </GraphRow>
      </Box>

      {!hasCurrentFollowers && !hasAverageComments && !hasAverageHerts && (
        <Empty description="No data available!" />
      )}
    </>
  );
};

export default AccountCharts;
