// @ts-nocheck
import { string, object, ref } from "yup";

export default object().shape({
  name: string().required("Please tell us your name"),
  age: string().required("Please tell us your age"),
  gender: string().required("Please tell use your age"),
  email: string().email().required("Please tell use your email"),
  location: string().required('Please choose country'),
  password: string().required('Password is required'),
  confirm_password:
    string().oneOf([ref('password'), null], 'Passwords must match')
    .required('Please confirm your password')
});
