import React, { Component } from "react";
import FormField from "../FormField";
import { Rate } from "formik-antd";

export default class CreationTime extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "metadata.rating.creation_time"}
        label="Creation time"
        help="From the time of briefing how quickly did you recieve content"
      >
        <Rate data-cy="account-creation-time" />
      </FormField>
    );
  }
}
