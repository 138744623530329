import axiosInstance from "../axiosInstance";

const GET_UPLOADS = `/uploads`;
const CREATE_UPLOAD = `/uploads`;
const GENERATE_UPLOAD = `/uploads/generate`;

export const index = data => axiosInstance.get(GET_UPLOADS, data);

export const create = data => axiosInstance.post(CREATE_UPLOAD, data);

export const generate = data => axiosInstance.post(GENERATE_UPLOAD, data);
