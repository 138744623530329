// @ts-nocheck
import React from "react";
import { InputProps } from "formik-antd";
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from "antd";
import { Field, ErrorMessage } from "formik";
import styled from "styled-components";
import axios from "axios";
import { useGetGeneralSignedUrlMutation } from "src/generated/ApolloComponents";

const UploadButton = styled(Upload)`
  && {
    display: inline !important;
    .ant-upload.ant-upload-select-picture-card {
      height: 57px;
      width: 57px;
      background-color: #f6f6f6;
      border: none;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 57px;
      width: 57px;
      padding: 0px !important;
      border: none;
    }
    .anticon-upload {
      font-size: 20px;
      color: #bcbcbc;
    }
    .anticon-eye-o {
      display: none;
    }
    .anticon-close {
      display: none;
    }
  }
`;

type Props = InputProps;

export const ImageInput = (props: Props) => {
  const [getGeneralSignedUrlMutation] = useGetGeneralSignedUrlMutation()

  const onUpload = async (data, name, form) => {
    const { setFieldValue, setFieldTouched } = form;

    const fileData = {
      fileName: data.file.name,
      fileType: data.file.type,
    };

    try {
      const result = await getGeneralSignedUrlMutation({
        variables: fileData,
      });

      if (result) {
        const signedUrl = result?.data?.getGeneralSignedURL?.url!;

        const options = {
          headers: {
            "Content-Type": data.file.type,
          },
        };

        axios
          .put(signedUrl as string, data.file, options)
          .then(() => {
            setFieldValue(name, result?.data?.getGeneralSignedURL?.uploadUrl);

            setFieldTouched(name, true);
          })
          .catch(err => {});
      }
    } catch (e) {}
  };

  const handleRemove = (file, name, form) => {
    const { setFieldValue } = form;

    setFieldValue(name, null);
  };

  return (
    <Field {...props}>
      {({ field: { value, name }, form }) => (
        <div>
          <UploadButton
            listType="picture-card"
            accept="image/*"
            showUploadList={false}
            onRemove={file => handleRemove(file, name, form)}
            customRequest={file => onUpload(file, name, form)}
          >
            <div data-cy="upload-screenshots">
              {value ? (
                <img src={value} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <UploadOutlined />
              )}
            </div>
          </UploadButton>

          <ErrorMessage name={name} />
        </div>
      )}
    </Field>
  );
};
