// @ts-nocheck
import React from 'react'
import { AppstoreFilled, UnorderedListOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { Typography } from "src/components/Core/Typography";

interface Props {
  status: string
  length: number
  view: string
  handleChange: (value: string) => void
}

const TitleSection = ({status, length, view, handleChange}: Props) => {

  const title = (status: string) => {
    if(status === "accepted"){
      return "Liked influencers"
    } else {
      return "Suggested influencers"
    }
  }

  const fetchColor = (type: string) => {
    return view === type ? "#1890FF" : "#D9D9D9"
  }

  return (
    <React.Fragment>
      <Row justify="space-between">
        <Col>
          <Typography size={24} color="black">
            {title(status)}
          </Typography>
        </Col>
        <Col xl={5} xxl={4}>
          <Row justify="space-between" style={{ marginTop: '5px'}}>
            <Col>
              <UnorderedListOutlined style={{color: fetchColor("list") }} onClick={() => handleChange("list")} />
            </Col>
            <Col>
              <AppstoreFilled
                style={{ color: fetchColor("grid") }}
                onClick={() => handleChange("grid")} /></Col>
            <Col>
              <Typography size={14} color="black2">
                {length} accounts
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default TitleSection;
