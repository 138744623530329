// @ts-nocheck
import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Card } from "antd";
import { Box } from "src/components/Core/Box";
import { TextInput } from "src/components/Forms/TextInput";
import { Table } from "src/components/Core/TableManager";
import { CampaignsTableContainer } from "./container";
import { Loading } from "src/components/Core/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import getColumns from "./Columns";
import withWindowResize from "src/utils/hoc/withWindowResize";

export interface FilterProps {
  searchInput: string;
}

interface Props {
  allAccounts: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  height?: number; // from withWindowResize HOC
  filters: FilterProps;
}

const MainCampaignsTable: React.FC<Props> = ({
  allAccounts,
  loading,
  globalErrors,
  onFilter,
  height,
  filters,
}) => {
  return (
    <Box p={20}>
      <Card>
        <GlobalErrors errors={globalErrors} />

        <Box maxWidth={300}>
          <TextInput
            size="small"
            background="background"
            borderRadius={3}
            borderColor="grey5"
            value={filters?.searchInput}
            onChange={e => onFilter("searchInput", e.target.value)}
            suffix={<SearchOutlined />}
            placeholder="Search keyword..."
          />
        </Box>

        <Box background="white" mt={10} mb={10} borderRadius="4px">
          <Loading spinning={loading}>
            <Table
              getColumns={getColumns}
              rowKey="accountName"
              dataSource={allAccounts || []}
              data-cy="table-CampaignsTable"
              rowHover={true}
              scroll={{ y: height, x: "max-content" }}
            />
          </Loading>
        </Box>
      </Card>
    </Box>
  );
};

const CampaignsTable = withWindowResize({ extraHeight: 350 })(
  MainCampaignsTable
);

export { CampaignsTable, CampaignsTableContainer };
