import React, { Component } from "react";

import {
  AlignLeftOutlined,
  EditOutlined,
  EyeOutlined,
  FileOutlined,
  HddOutlined,
  LineChartOutlined,
  LinkOutlined,
  SearchOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CampaignManage from "src/ducks/CampaignManage";

export class SideMenu extends Component {
  render() {
    const { campaign, location } = this.props;

    const campaignPath = `/campaigns/${campaign.id}`;

    return (
      <Menu
        mode="vertical"
        selectedKeys={[location.pathname]}
        style={{ height: "100%" }}
      >
        <Menu.Item key={campaignPath}>
          <Link data-cy="campaign-details" to={campaignPath} replace>
            <AlignLeftOutlined />
            <span>Campaign Details</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/update"}>
          <Link data-cy="campaign-update" to={campaignPath + "/update"} replace>
            <EditOutlined />
            <span>Update Campaign</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/creative"}>
          <Link
            data-cy="campaign-creative"
            to={campaignPath + "/creative"}
            replace
          >
            <EyeOutlined />
            <span>Creative</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/find"}>
          <Link
            data-cy="campaign-influencers"
            to={campaignPath + "/find"}
            replace
          >
            <SearchOutlined />
            <span>Find Influencers</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/manage"}>
          <Link data-cy="campaign-manage" to={campaignPath + "/manage"} replace>
            <VideoCameraOutlined />
            <span>Manage Invitations</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/applicants"}>
          <Link data-cy="campaign-applicants" to={campaignPath + "/applicants"} replace>
            <VideoCameraOutlined />
            <span>Manage Applicants</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/content"}>
          <Link
            data-cy="campaign-content"
            to={campaignPath + "/content"}
            replace
          >
            <VideoCameraOutlined />
            <span>Posted Content</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/ad-hub"}>
          <Link data-cy="campaign-ad-hub" to={campaignPath + "/ad-hub"} replace>
            <VideoCameraOutlined />
            <span>Ad Hub</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/analytics"}>
          <Link
            data-cy="campaign-analytics"
            to={campaignPath + "/analytics"}
            replace
          >
            <LineChartOutlined />
            <span>Analytics</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/links"}>
          <Link data-cy="campaign-links" to={campaignPath + "/links"} replace>
            <LinkOutlined />
            <span>Links</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={campaignPath + "/invitation"}>
          <Link
            data-cy="campaign-invitations"
            to={campaignPath + "/invitation"}
            replace
          >
            <HddOutlined />
            <span>Invitations</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={campaignPath + "/reporting"}>
          <Link
            data-cy="campaign-reporting"
            to={campaignPath + "/reporting"}
            replace
          >
            <FileOutlined />
            <span>Reporting</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);
