// @ts-nocheck
import React from 'react'
import { SettingOutlined } from '@ant-design/icons';
import { Card as AntdCard, Progress } from 'antd';
import { Typography } from 'src/components/Core/Typography'
import styled from 'styled-components'
const StyledProgress = styled(Progress)`
  margin-bottom: 10px;
`

const StyledCard = styled(AntdCard)`
  margin-bottom: 16px;
`

interface Props {
  toggleVisibility: () => void
  milestone: any
  user: any
}

const Card = ({toggleVisibility, milestone, user}: Props) => {
  const percent = Number(((milestone.actualValue / milestone.goal) * 100).toFixed(2))
  return (
    <StyledCard
      title={milestone.milestoneName}
      extra={user.type === "Admin" && <SettingOutlined onClick={() => toggleVisibility()} />}
    >
      <Typography fontSize={40} color="black" mb={0}>{percent}%</Typography>
      <StyledProgress percent={percent} showInfo={false} />
      <Typography fontSize={16} color="black">
        {Number(milestone.actualValue.toFixed(2)).toLocaleString()} / {milestone.goal.toLocaleString()}
      </Typography>
    </StyledCard>
  );
}

export default Card
