// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Layout, Card, Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import screenshot from "src/assets/images/screenshot.png";
import tiktik_gender_screenshot from "src/assets/images/tiktokBreakdown/tiktok_gender.png";
import tiktik_location_screenshot from "src/assets/images/tiktokBreakdown/tiktok_location.png";
import snapchat_age_screenshot from "src/assets/images/snapchatBreakdown/snapchat_age.png";
import snapchat_gender_screenshot from "src/assets/images/snapchatBreakdown/snapchat_gender.png";
import snapchat_location_screenshot from "src/assets/images/snapchatBreakdown/snapchat_location.png";
import instagram_location_screenshot from "src/assets/images/instagramBreakdown/instagram_location.png";
import instagram_age_screenshot from "src/assets/images/instagramBreakdown/instagram_age.png";
import instagram_gender_screenshot from "src/assets/images/instagramBreakdown/instagram_gender.png";
import youtube_location_screenshot from "src/assets/images/youtubeBreakdown/youtube_location.png";
import youtube_age_screenshot from "src/assets/images/youtubeBreakdown/youtube_age.png";
import youtube_gender_screenshot from "src/assets/images/youtubeBreakdown/youtube_gender.png";
import instagram_welcome_profile_screenshot from "src/assets/images/welcome-profile/instagram.png";
import snapchat_welcome_profile_screenshot from "src/assets/images/welcome-profile/snapchat.png";
import tiktok_welcome_profile_screenshot from "src/assets/images/welcome-profile/tiktok.png";

import { Visible, useScreenClass } from "react-grid-system";

const { Meta } = Card;

const Wrapper = styled(Layout)`
  &&& {
    -webkit-transition: all 0.5s ease-in;
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    -ms-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;

    &.open {
      opacity: 1;
    }

    &.close {
      opacity: 0;
    }
  }
`;

const ScreenshotCard = styled(Card)`
  &&& {
    background: none !important;
    border: none !important;
    box-shadow: none !important;

    .ant-card-head {
      background: #fff;
      color: #808080;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.46px;
      line-height: 12px;
      padding: 0 12px !important;
      min-height: 35px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.21);
      border-radius: 6px 6px 0px 0px !important;
    }
    .ant-card-cover {
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.21);
      padding-bottom: 10px;
      border-radius: 0px 0px 6px 6px !important;
      background: #fff;
    }

    .ant-card-body {
      padding: 20px 5px 30px 5px;
      color: #7f7f7f;
      font-size: 12px;
      letter-spacing: 0.38px;
      line-height: 21px;
      display: block;
    }
    .anticon {
      margin-right: 30px;
      font-size: 12px;
    }

    @media (min-width: 768px) {
      .ant-card-body {
        display: none;
      }
    }
  }
`;

interface Props {
  showExample: object;
  hideExample: () => void;
  style?: React.CSSProperties;
  platformType?: string;
  screenshotType: string;
}

const AnalyticsScreenshotExample: React.FC<Props> = ({
  showExample,
  hideExample,
  style,
  platformType,
  screenshotType,
}) => {
  const platformsBreakdownScreenshot = {
    Snapchat: {
      age: snapchat_age_screenshot,
      gender: snapchat_gender_screenshot,
      location: snapchat_location_screenshot,
      campaign: screenshot,
      campaignScreenshot: screenshot,
      screenshot: snapchat_welcome_profile_screenshot,
    },

    TikTok: {
      age: screenshot,
      gender: tiktik_gender_screenshot,
      location: tiktik_location_screenshot,
      campaign: screenshot,
      campaignScreenshot: screenshot,
      screenshot: tiktok_welcome_profile_screenshot,
    },

    Instagram: {
      age: instagram_age_screenshot,
      gender: instagram_gender_screenshot,
      location: instagram_location_screenshot,
      campaign: screenshot,
      campaignScreenshot: screenshot,
      screenshot: instagram_welcome_profile_screenshot,
    },

    YouTube: {
      age: youtube_age_screenshot,
      gender: youtube_gender_screenshot,
      location: youtube_location_screenshot,
      campaign: screenshot,
      campaignScreenshot: screenshot,
      screenshot: youtube_location_screenshot,
    },
  };

  const screenClass = useScreenClass();

  return (
    <Wrapper
      style={style}
      className={showExample ? "open" : "close"}
      onClick={e => e.stopPropagation()}
    >
      <Box
        width={
          screenClass === "md" || screenClass === "xl" || screenClass === "lg"
            ? 550
            : ""
        }
        background="white"
        p={20}
      >
        <Row gutter={8}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <ScreenshotCard
              title={<div>{platformType} Example</div>}
              hoverable
              cover={
                platformType === "YouTube" ? (
                  <img
                    src={
                      platformsBreakdownScreenshot[platformType][screenshotType]
                    }
                    alt="YouTube Profile Anayltics Screenshots "
                  />
                ) : platformType === "Snapchat" ? (
                  <img
                    src={
                      platformsBreakdownScreenshot[platformType][screenshotType]
                    }
                    alt="Snapchat Profile Anayltics Screenshots "
                  />
                ) : platformType === "Instagram" ? (
                  <img
                    src={
                      platformsBreakdownScreenshot[platformType][screenshotType]
                    }
                    alt="Instagram Profile Anayltics Screenshots "
                  />
                ) : platformType === "TikTok" ? (
                  <img
                    src={
                      platformsBreakdownScreenshot[platformType][screenshotType]
                    }
                    alt="TikTok Profile Anayltics Screenshots "
                  />
                ) : (
                  <img src={screenshot} alt="campaign example" />
                )
              }
            >
              <Visible xs sm>
                {screenshotType === "campaign" ||
                screenshotType === "campaignScreenshot" ? (
                  <Meta
                    description="Your screenshot should include the amount of views all
                campaign related posts recieved."
                  />
                ) : (
                  <Meta description="Your analytics screenshot will look something like this." />
                )}
              </Visible>
            </ScreenshotCard>
          </Col>

          <Visible xl lg>
            <Col span={12}>
              {screenshotType === "campaign" ||
              screenshotType === "campaignScreenshot" ? (
                <>
                  <Typography size={20} weight="semi-bold" color="black">
                    Campaign screenshot
                  </Typography>

                  <Typography size={16} color="black2" lineHeight="32px">
                    Your screenshot should include the amount of views all
                    campaign related posts recieved
                  </Typography>
                </>
              ) : (
                <>
                  <Typography size={20} weight="semi-bold" color="black">
                    Analytics screenshot
                  </Typography>

                  <Typography size={16} color="black2" lineHeight="32px">
                    Your analytics screenshot will look something like this.
                  </Typography>
                </>
              )}
            </Col>
          </Visible>
          <Box
            mr={10}
            mt="-20px"
            fontSize={20}
            style={{ position: "absolute", right: 0 }}
          >
            <CloseCircleOutlined
              onClick={e => {
                e.stopPropagation();
                hideExample();
              }} />
          </Box>
        </Row>
      </Box>
    </Wrapper>
  );
};

export default AnalyticsScreenshotExample;
