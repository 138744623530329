import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Descriptions } from "antd";
import Container from "src/shared/Container";
import CampaignsTable from "./CampaignsTable";
import BrandsTable from "./BrandsTable";
import { CompanyPersist } from "src/ducks/Persist";

const ShowCompany = CompanyPersist("AdminCompanyShow");

const DetailDescriptions = styled(Descriptions)`
  background: #fff;
`;

export class CompanyDetails extends Component {
  render() {
    const { company } = this.props;

    return (
      <Container style={{ marginTop: 24 }}>
        <DetailDescriptions size="middle" bordered>
          <Descriptions.Item label="Company Name" span={2}>
            {company.company_name}
          </Descriptions.Item>
        </DetailDescriptions>

        <BrandsTable style={{ marginTop: 24 }} />
        <CampaignsTable style={{ marginTop: 24 }} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  company: ShowCompany.selectOne(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
