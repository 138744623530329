// @ts-nocheck
import React from 'react'
import { Table } from "src/components/Core/TableManager";
import getColumns from "./Columns"
import { useDispatch } from 'react-redux'

interface Props {
  suggestions: any
  handleUpdate: (account: any) => void
}

const ListView = ({suggestions, handleUpdate}: Props) => {
  const dispatch = useDispatch()
  return(
    <React.Fragment>
      <Table
        getColumns={() => getColumns({dispatch, handleUpdate})}
        rowKey="id"
        dataSource={suggestions?.suggestions}
        rowHover={true}
        pagination={false}
      />
    </React.Fragment>
  )
}

export default ListView;
