import React, { Component } from "react";
import { EyeOutlined, HeartOutlined, MessageOutlined } from '@ant-design/icons';
import { Card, Row, Col, Typography as AntdTypegraphy } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import styled from "styled-components";
import TikTokPost from "src/components/Modules/TikTokPost";

const { Meta } = Card;
const { Paragraph } = AntdTypegraphy;

const AccountPostCard = styled(Card)`
  &&& {
    .ant-card-body {
      padding: 14px !important;
    }
  }
`;

const videoStyle = {
  width: "100%",
  height: "380px",
  objectFit: "contain",
  backgroundColor: "#000",
};

export default class AccountPost extends Component {
  render() {
    const {
      content_type,
      content_url,
      description,
      views,
      likes,
      comments,
      posted_at,
      web_video_url,
    } = this.props;

    const content =
      content_type === "video" ? (
        <video style={videoStyle} src={content_url} controls />
      ) : content_type === "youtube" ? (
        <iframe
          title="account-post"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          width="788.54"
          height="380"
          type="text/html"
          src={`https://www.youtube.com/embed/${content_url}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}
        />
      ) : (
        <img
          style={{ width: "100%" }}
          src={content_url}
          alt="account-post-img"
        />
      );

    return web_video_url ? (
      <AccountPostCard hoverable cover={<TikTokPost url={web_video_url} />}>
        <Meta
          title={
            <Typography weight="semi-bold" size={10}>
              {posted_at}
            </Typography>
          }
          description={
            <Paragraph ellipsis={{ rows: 2, expandable: true }}>
              {description}
            </Paragraph>
          }
        />
      </AccountPostCard>
    ) : description ? (
      <AccountPostCard hoverable cover={content}>
        <Meta
          title={
            <Box>
              {(likes || comments || views) && (
                <Row

                  style={{ marginTop: 10, background: "#FFFFFF" }}
                  justify="space-between"
                >
                  {likes && (
                    <Col>
                      <Typography weight="semi-bold" size={12}>
                        <HeartOutlined style={{ marginRight: 5 }} />
                        {likes}
                      </Typography>
                    </Col>
                  )}
                  {views && (
                    <Col>
                      <Typography weight="semi-bold" size={12}>
                        <EyeOutlined style={{ marginRight: 5 }} />
                        {views}
                      </Typography>
                    </Col>
                  )}
                  {comments && (
                    <Col>
                      <Typography weight="semi-bold" size={12}>
                        <MessageOutlined style={{ marginRight: 5 }} />
                        {comments}
                      </Typography>
                    </Col>
                  )}
                </Row>
              )}
              <Box>
                <Typography weight="semi-bold" size={10}>
                  {posted_at}
                </Typography>
              </Box>
            </Box>
          }
          description={
            <Paragraph ellipsis={{ rows: 3, expandable: true }}>
              {description}
            </Paragraph>
          }
        />
      </AccountPostCard>
    ) : (
      content
    );
  }
}
