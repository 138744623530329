// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { PaymentQueueListContainer } from "./PaymentQueueList";

interface Props {}


const PaymentQueue: React.FC<Props> = () => {

  return (
    <Box mt={10} display="block">
      <PaymentQueueListContainer />
    </Box>
  );
};

export default PaymentQueue;
