// @ts-nocheck
import React from "react";
import { ScreenshotsContainer } from "./container";
import { InboxOutlined } from '@ant-design/icons';
import { Upload, Popover } from "antd";
import FieldError from "../FieldError";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import AnalyticsScreenshotExample from "../../AnalyticsScreenshotExample";
import { Visible } from "react-grid-system";

export const ScreenshotsWrapper = styled.div`
  width: 100%;
`;

type ScreenshotsProps = {
  name: string;
  fileList: Array<any>;
  setShowExample: () => void;
  handleRemove: (value: any) => void;
  onUpload: (value: any) => void;
  setScreenshotType: () => void;
  multiple: boolean;
  disabled: boolean;
  platformType: string;
  screenshotType: string;
  showExample: object;
  hideExample: () => void;
  uploadText?: string;
};

const Screenshots: React.FC<ScreenshotsProps> = ({
  handleRemove,
  setShowExample,
  onUpload,
  fileList,
  setScreenshotType,
  multiple,
  disabled,
  name,
  platformType,
  screenshotType,
  showExample,
  hideExample,
  uploadText,
}) => {
  return (
    <ScreenshotsWrapper data-cy={`input-${name}-Screenshots`}>
      <Upload.Dragger
        multiple={multiple}
        disabled={disabled}
        accept="image/*"
        fileList={fileList}
        customRequest={file => onUpload(file)}
        onRemove={file => handleRemove(file)}
      >
        <Box p={10}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            {uploadText
              ? uploadText
              : "Please provide your analyitics snapshot."}{" "}
            <Visible xl lg>
              <Popover
                content={
                  <AnalyticsScreenshotExample
                    hideExample={() => hideExample()}
                    showExample={showExample[screenshotType]}
                    platformType={platformType}
                    screenshotType={screenshotType}
                  />
                }
                trigger="click"
                visible={showExample[screenshotType]}
                onVisibleChange={value => {
                  if (!value) {
                    hideExample();
                  }
                }}
              >
                <span
                  style={{ color: "#1890ff", cursor: "pointer" }}
                  onClick={e => {
                    e.stopPropagation();
                    setScreenshotType();
                    setShowExample();
                  }}
                >
                  What is this?
                </span>
              </Popover>
            </Visible>
            <Visible xs sm md>
              <span
                style={{ color: "#1890ff", cursor: "pointer" }}
                onClick={e => {
                  e.stopPropagation();
                  setScreenshotType();
                  setShowExample();
                }}
              >
                What is this?
              </span>
            </Visible>
          </p>
        </Box>
      </Upload.Dragger>
      <FieldError fieldName={name} />
    </ScreenshotsWrapper>
  );
};

export { Screenshots, ScreenshotsContainer };
