// @ts-nocheck
import { Card, Typography } from "antd";
import styled from "styled-components";

const { Title } = Typography;

export const ErrorContainer = styled(Card)`
  width: 100%;
  max-width: 380px;
  margin: 0 auto !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 6px !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.21);
`;

export const ErrorTitle = styled(Title)`
  &&& {
    color: #000000;
    font-size: 30px;
    font-weight: 500 !important;
    line-height: 36px;
    text-align: center;
  }
`;

export const ErrorDescription = styled("p")`
  &&& {
    color: #7f7f7f;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.2px;
  }
`;
