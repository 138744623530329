import React, { Component } from "react";
import FormField from "../FormField";
import { Row, Col, Button } from "antd";
import { FieldArray } from "formik";
import {
  Wrapper,
  InputText,
  FieldLabel,
  NumberText,
  DeleteIcon
} from './styles'

export default class CreativeLink extends Component {
  render() {
    const {
      values: {metadata: { creative_links = [] }},
    } = this.props;

    return (
      <FieldArray
        name="metadata[creative_links]"
      >
        {({ insert, remove, push }) => (
          <Wrapper>
            <FieldLabel>
              Add the creative links here if any
            </FieldLabel>
            {typeof creative_links !== "undefined" &&
              creative_links.length > 0 &&
              creative_links.map((data, index) => {
                return (
                  <Row gutter={8} key={index}>
                    <Col span={1}>
                      <NumberText>{index + 1}.</NumberText>
                    </Col>
                    <Col span={22}>
                      <FormField
                        name={`metadata[creative_links][${index}]`}
                        label=""
                        style={{ marginBottom: 0 }}
                      >
                        <InputText
                          data-cy={`creative-link-input[${index}]`}
                          rows={1}
                          size="large"
                          placeholder="e.g. https://www.youtube.com/watch?v=jWuKjpoYWNU"
                        />
                      </FormField>
                    </Col>

                    <Col span={1}>
                      <DeleteIcon
                        type="delete"
                        data-cy={`creative-link-input-delete[${index}]`}
                        onClick={() => remove(index)}
                      />
                    </Col>
                  </Row>
                );
              })}
            <Row gutter={8}>
              <Col span={22}>
                <Button type="link" onClick={() => push("")}>
                  + Add
                </Button>
              </Col>

              <Col span={2}></Col>
            </Row>
          </Wrapper>
        )}
      </FieldArray>
    );
  }
}
