import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Card, Statistic } from "antd";
import numeral from "numeral";

const Wrapper = styled(Row)`
  margin-bottom: 10px;
`;

export default class Stats extends Component {
  render() {
    const {
      stats: { total },
      pagination: { total_entries },
    } = this.props;

    return (
      <Wrapper gutter={16} justify="center" align="top">
        {total && (
          <>
            <Col span={4}>
              <Card size="small">
                <Statistic
                  title="Total views"
                  value={total ? numeral(total.views).format("0,0") : 0}
                  valueStyle={{ fontSize: "14px" }}
                />
              </Card>
            </Col>

            <Col span={4}>
              <Card size="small">
                <Statistic
                  title="Verified views"
                  value={
                    total ? numeral(total.verified_views).format("0,0") : 0
                  }
                  valueStyle={{ fontSize: "14px" }}
                />
              </Card>
            </Col>

            <Col span={4}>
              <Card size="small">
                <Statistic
                  title="Verified influencers"
                  value={
                    total ? numeral(total.verified_accounts).format("0,0") : 0
                  }
                  valueStyle={{ fontSize: "14px" }}
                />
              </Card>
            </Col>
          </>
        )}
        {total_entries && (
          <Col span={4}>
            <Card size="small">
              <Statistic
                title="Total records"
                value={total_entries}
                valueStyle={{ fontSize: "14px" }}
              />
            </Card>
          </Col>
        )}
      </Wrapper>
    );
  }
}
