// @ts-nocheck
import React, { useState, useEffect } from "react";
import { AccountList, FilterProps } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetSuggestionAccountsByCampaignIdQuery,
  BreakdownType,
  useDismissSuggestionAccountMutation,
} from "src/generated/ApolloComponents";

type Props = {
  campaign: any;
};

export const AccountListContainer: React.FC<Props> = ({ campaign }) => {
  const [filters, setFilters] = useState({
    campaignId: campaign?.id,
    limit: 12,
    page: 1,
    matchType: BreakdownType.Location,
  } as FilterProps);

  const [
    dismissSuggestionAccountMutation,
    dismiss,
  ] = useDismissSuggestionAccountMutation();

  const {
    data,
    loading,
    error,
    refetch,
  } = useGetSuggestionAccountsByCampaignIdQuery({
    variables: filters,
  });

  useEffect(() => {
    let updatedFilters = { ...filters };

    refetch(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const suggestions = data?.getSuggestionAccountsByCampaignId || {};

  const onDismiss = async accountId => {
    try {
      const res = await dismissSuggestionAccountMutation({
        variables: {
          campaignId: campaign?.id,
          accountId: accountId,
        },
      });
      if (res.data?.dismissSuggestionAccount?.id) {
        refetch(filters);
      }
    } catch (e) {}
  };

  const refetchSuggestion = async () => {
    try {
      refetch(filters);
    } catch (e) {}
  };

  const globalErrors = useMapGraphQLErrorsMessages(error || dismiss?.error);

  return (
    <AccountList
      suggestions={suggestions}
      globalErrors={globalErrors}
      loading={loading || dismiss?.loading}
      onFilter={(key, value) => {
        filters[key] = value;
        setFilters({ ...filters });
      }}
      filters={filters}
      onDismiss={value => onDismiss(value)}
      campaign={campaign}
      refetchSuggestion={() => refetchSuggestion()}
    />
  );
};
