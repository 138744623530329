// @ts-nocheck
import React from "react"
import { FormLayout } from "../components"
import { Row } from "antd"
import TrackingDetailsContainer from "./TrackingDetails/container"

interface Props {}

const TrackInvitation = (props: Props) => {
  return (
    <FormLayout title="Track invitation" styles={{ alignItems: "center" }}>
      <Row gutter={16} style={{ width: 350 }}>
        <TrackingDetailsContainer />
      </Row>
    </FormLayout>
  )
}

export default TrackInvitation
