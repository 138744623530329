// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import OrganicContent from "src/shared/OrganicContent";
import BrandedContent from "src/shared/BrandedContent";
import AccountCharts from "src/shared/AccountCharts";
import numeral from "numeral";
import { Statistic, Tabs } from "antd";

const { TabPane } = Tabs;

interface Props {
  account: any;
  rationale?: string;
  breakdownLastUpdateDate: any;
  accountId?: number;
  grid?: any;
}

const AccountDetail: React.FC<Props> = ({
  account,
  rationale,
  breakdownLastUpdateDate,
  accountId,
  grid,
}) => {
  return (
    <Box>
      {rationale && account?.id === accountId && (
        <Box ml={20}>
          <Typography
            color="blue"
            size={16}
            fontWeight="semi-bold"
            lineHeight={0}
          >
            Why have we suggested this influencer?
          </Typography>

          <Typography color="black2" size={14}>
            {rationale}
          </Typography>
        </Box>
      )}

      <Tabs>
        <TabPane tab="Profile data" key="1">
          <Box display="flex" flex-wrap="wrap" mb={20}>
            {(account?.type === "Instagram" ||
              account?.type === "Snapchat") && (
              <>
                {account?.formattedAvgCtr() && (
                  <Box width="25%">
                    <Statistic
                      title="Avg. CTR%"
                      value={account?.formattedAvgCtr()}
                    />
                  </Box>
                )}
              </>
            )}

            {account?.formattedEngagementRate() && (
              <Box width="25%">
                <Statistic
                  title="Avg. ER%"
                  value={account?.formattedEngagementRate()}
                />
              </Box>
            )}

            {(account?.type === "Instagram" ||
              account?.type === "TikTok" ||
              account?.type === "Snapchat") && (
              <>
                {account?.formattedViews() && (
                  <Box width="25%">
                    <Statistic
                      title="Avg. Views"
                      value={numeral(account?.formattedViews()).format("0a")}
                    />
                  </Box>
                )}
              </>
            )}

            {account?.type === "YouTube" && (
              <>
                {account?.formattedAvgViews() && (
                  <Box width="25%">
                    <Statistic
                      title="Avg. Views"
                      value={numeral(account?.formattedAvgViews()).format("0a")}
                    />
                  </Box>
                )}
              </>
            )}

            {account?.type === "Instagram" && (
              <>
                {account?.formattedAvgLikes() && (
                  <Box width="25%">
                    <Statistic
                      title="Avg. Likes"
                      value={numeral(account?.formattedAvgLikes()).format("0a")}
                    />
                  </Box>
                )}
              </>
            )}

            {account?.type === "TikTok" && (
              <>
                {account?.formattedAvgHearts() && (
                  <Box width="25%">
                    <Statistic
                      title="Avg. Hearts"
                      value={numeral(account?.formattedAvgHearts()).format(
                        "0a"
                      )}
                    />
                  </Box>
                )}
              </>
            )}

            {(account?.type === "Instagram" ||
              account?.type === "TikTok" ||
              account?.type === "YouTube") && (
              <>
                {account?.formattedFollowers() && (
                  <Box width="25%">
                    <Statistic
                      title="Followers"
                      value={numeral(account?.formattedFollowers()).format(
                        "0a"
                      )}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>

          {account?.type === "TikTok" && (
            <AccountCharts
              accountData={account}
              breakdownLastUpdateDate={breakdownLastUpdateDate}
            />
          )}
        </TabPane>

        <TabPane tab="Previous branded content" key="2">
          <BrandedContent account={account} shouldShowLabel={false} />
        </TabPane>
        <TabPane tab="Previous content" key="3">
          <OrganicContent
            account={account}
            shouldShowLabel={false}
            grid={grid}
          />
        </TabPane>
      </Tabs>
    </Box>
  );
};

export default AccountDetail;
