import styled from "styled-components"
import { Checkbox } from "formik-antd"

export default styled(Checkbox)`
  &&& {
    display: flex;
    height: 30px;
    line-height: 30px;
    color: #595959;
    font-size: 14px;
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`
