import styled from "styled-components";
import { Typography } from "antd";
import { Input } from "formik-antd";
import { Icon } from '@ant-design/compatible'

const { Text } = Typography;

export const Wrapper = styled("div")`
  &&& {
    margin-bottom: 30px;
  }
`;

export const TextArea = styled(Input.TextArea)`
  &&& {
    padding: 6px 11px;
    font-size: 16px;
    min-height: 40px !important;
    height: 40px;
  }
`;

export const InputText = styled(Input)`
  &&& {
    padding: 6px 11px;
    font-size: 16px;
    min-height: 40px !important;
    height: 40px;
  }
`;

export const FieldLabel = styled(Text)`
  && {
    display: block;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const NumberText = styled(Text)`
  && {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
  }
`;

export const DeleteIcon = styled(Icon)`
  &&& {
    color: #ff7878 !important;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
`;
