import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { withRouter, Link } from "react-router-dom"
import { message } from "antd";
import { withFormik } from "formik"
import { Form, Input, SubmitButton } from "formik-antd"
import Auth from "src/ducks/Auth"
import FormField from "src/shared/FormField"
import  { Icon } from '@ant-design/compatible'

const FormIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.25);
`

class ForgotForm extends Component {
  render() {
    const { handleSubmit } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <FormField name="email" label="Please enter your email">
          <Input size="large" prefix={<FormIcon type="user" />} placeholder="Email" />
        </FormField>
        <SubmitButton size="large" type="primary" disabled={false} block>
          Send reset email
        </SubmitButton>
        Or <Link to="/sign_in">Login</Link>
      </Form>
    )
  }
}

const EnhancedForgotForm = withFormik({
  mapPropsToValues: () => ({ email: "" }),

  validate: (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = "Fill in your email"
    }

    return errors
  },

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props
      .forgotEmail(values)
      .then(() => {
        message.success("Forgot email sent!")
        setSubmitting(false)
      })
      .catch((res) => {
        message.error("Failed to send forgot email")
        setErrors({ email: "Incorrect email" })
        setSubmitting(false)
      })
  },

  displayName: "ForgotForm"
})(ForgotForm)

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  forgotEmail: Auth.forgotEmail
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnhancedForgotForm))
