// @ts-nocheck
import React, { useState, useEffect } from "react";
import { CheckCircleFilled } from '@ant-design/icons';
import { Card, Layout, Menu, Collapse } from "antd";
import styled from "styled-components";
import { FormLayout } from "../../components";
import Container from "src/shared/Container";
import { Box } from "src/components/Core/Box";
import { InfluencerHubTabContainer } from "./container";
import { Loading } from "src/components/Core/Loading";
import { GlobalErrorProps } from "src/components/Forms/ErrorMessage";
import HeadingInfoText from "./HeadingInfoText";
import { ProfileDataFormContainer } from "./ProfileDataForm";
import { AgreementDetailContainer } from "./AgreementDetail";
import { PaymentDetailFormContainer } from "./PaymentDetailForm";
import { InvitationContentFormContainer } from "./InvitationContent";
import { PostDetailFormContainer } from "./PostDetailForm";
import ThankYou from "./ThankYou";
import { Visible } from "react-grid-system";
import AnalyticsScreenshotExampleModal from "../../components/AnalyticsScreenshotExampleModal";

const { Content, Sider } = Layout;
const { Panel } = Collapse;

const FormContainer = styled(({ currentStep, ...props }) => (
  <Card {...props} />
))`
  && {
    width: 100%;
    margin: 0 auto !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 6px !important;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.21);
    height: auto;
    min-height: 600px;

    .ant-card-body {
      padding: 0px;
    }
  }
`;

const InfluencerHubCollapse = styled(Collapse)`
  && {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px !important;
    }
  }
`;

interface Props {
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  cashoutData: any;
  claimCashoutId: any;
  hubLinkData: any;
  refetchHublink: () => void;
}

const InfluencerHubTab: React.FC<Props> = ({
  loading,
  cashoutData,
  claimCashoutId,
  hubLinkData,
  refetchHublink,
}) => {
  const [showExample, setShowExample] = useState({});
  const [screenshotType, setScreenshotType] = useState("");
  const [isAllComplete, setIsAllComplete] = useState(false);
  const [step, setStep] = useState<number>(1);

  const goNext = () => {
    refetchHublink();
    const inCompleteStep = getIncompleteStep();

    if (inCompleteStep !== -1) {
      setStep(inCompleteStep + 1);
    } else {
      setIsAllComplete(true);
      setStep(0);
    }
  };

  const goBack = () => {
    setStep(step - 1);
  };

  let arr = [] as any;

  if (
    cashoutData?.tabDetails?.initialContact ||
    cashoutData?.tabDetails?.initialContactYouTube
  ) {
    arr.push({
      label: "Profile data",
      component: ProfileDataFormContainer,
      componentProps: {
        claimCashoutId: claimCashoutId,
        cashoutData: cashoutData,
        goNext: goNext,
        goBack: goBack,
        type: cashoutData?.tabDetails?.initialContactYouTube ? "youtube" : "",
        showExample: showExample,
        setShowExample: value => setShowExample(value),
        setScreenshotType: value => setScreenshotType(value),
      },
      status:
        hubLinkData?.initialContact?.status === "complete" ||
        hubLinkData?.initialContactYouTube?.status === "complete",
    });
  }

  if (cashoutData?.tabDetails?.contract) {
    arr.push({
      label: "Contract",
      component: AgreementDetailContainer,
      componentProps: {
        claimCashoutId: claimCashoutId,
        cashoutData: cashoutData,
        goNext: goNext,
        goBack: goBack,
      },
      status: hubLinkData?.contract?.status === "complete",
    });
  }

  if (
    cashoutData?.tabDetails?.inviteFeedback ||
    cashoutData?.tabDetails?.inviteFeedbackYouTube
  ) {
    arr.push({
      label: "Content",
      component: InvitationContentFormContainer,
      componentProps: {
        claimCashoutId: claimCashoutId,
        cashoutData: cashoutData,
        goNext: goNext,
        goBack: goBack,
        type: cashoutData?.tabDetails?.inviteFeedbackYouTube ? "youtube" : "",
      },
      status:
        hubLinkData?.inviteFeedback?.status === "complete" ||
        hubLinkData?.inviteFeedbackYouTube?.status === "complete",
    });
  }

  if (
    cashoutData?.tabDetails?.postDetail ||
    cashoutData?.tabDetails?.postDetailYouTube
  ) {
    arr.push({
      label: "Post details",
      component: PostDetailFormContainer,
      componentProps: {
        claimCashoutId: claimCashoutId,
        cashoutData: cashoutData,
        goNext: goNext,
        goBack: goBack,
        type: cashoutData?.tabDetails?.postDetailYouTube ? "youtube" : "",
        showExample: showExample,
        setShowExample: value => setShowExample(value),
        setScreenshotType: value => setScreenshotType(value),
      },
      status:
        hubLinkData?.postDetail?.status === "complete" ||
        hubLinkData?.postDetailYouTube?.status === "complete",
    });
  }

  if (cashoutData?.tabDetails?.paymentDetail) {
    arr.push({
      label: "Payment detail",
      component: PaymentDetailFormContainer,
      componentProps: {
        claimCashoutId: claimCashoutId,
        cashoutData: cashoutData,
        goNext: goNext,
        goBack: goBack,
      },
      status: hubLinkData?.paymentDetail?.status === "complete",
    });
  }

  const getIncompleteStep = () => {
    const index = arr.findIndex(x => {
      return !x.status;
    });

    return index;
  };

  useEffect(() => {
    const isCheckedCompleted = (tab1, tab2 = "") => {
      const newHubLinkData1 = hubLinkData?.[tab1];

      const newHubLinkData2 = hubLinkData?.[tab2];

      if (newHubLinkData1?.checked && newHubLinkData1?.status) {
        return newHubLinkData1?.status === "complete";
      } else if (
        newHubLinkData2 &&
        newHubLinkData2?.checked &&
        newHubLinkData2?.status
      ) {
        return newHubLinkData2?.status === "complete";
      }

      return true;
    };

    if (
      hubLinkData &&
      isCheckedCompleted("initialContact", "initialContactYouTube") &&
      isCheckedCompleted("contract") &&
      isCheckedCompleted("inviteFeedback", "inviteFeedbackYouTube") &&
      isCheckedCompleted("postDetail", "postDetailYouTube") &&
      isCheckedCompleted("paymentDetail")
    ) {
      setIsAllComplete(true);
      setStep(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubLinkData]);

  return (
    <FormLayout
      title={"Influencer Hub"}
      styles={{ alignItems: "center", height: "auto" }}
    >
      <Loading spinning={loading}>
        <Container style={{ paddingBottom: 30 }}>
          <FormContainer currentStep={step}>
            <HeadingInfoText cashoutData={cashoutData} />

            <Visible xl lg>
              <Layout
                style={{
                  background: "#fff",
                }}
              >
                <Sider
                  width={200}
                  style={{
                    background: "#fff",
                    overflow: "auto",
                    height: "auto",
                  }}
                >
                  <Menu
                    mode="vertical"
                    selectedKeys={[step.toString()]}
                    style={{ height: "100%" }}
                    onClick={e => {
                      setStep(parseInt(e?.key));
                      setIsAllComplete(false);
                    }}
                  >
                    {arr.map((item, index) => {
                      return (
                        <Menu.Item
                          key={index + 1}
                          style={
                            index === step - 1
                              ? {
                                  borderRight: "3px solid #1890FF",
                                }
                              : {}
                          }
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="row"
                          >
                            <span> {`${index + 1}. ${item.label}`}</span>

                            {item?.status && (
                              <CheckCircleFilled
                                style={{
                                  color: "#95DE64",
                                  marginLeft: "10px",
                                }} />
                            )}
                          </Box>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Sider>

                {isAllComplete ? (
                  <Content>
                    <ThankYou />
                  </Content>
                ) : (
                  <Content>
                    {arr.map((item, index) => {
                      const Tab = item.component;
                      if (index === step - 1) {
                        return (
                          <Tab
                            {...item.componentProps}
                            key={index}
                            index={index}
                          />
                        );
                      }
                      return true;
                    })}
                  </Content>
                )}
              </Layout>
            </Visible>
            <Visible xs sm md>
              <InfluencerHubCollapse
                activeKey={[step.toString()]}
                onChange={key => {
                  setStep(parseInt(key?.[0]));
                  setIsAllComplete(false);
                }}
                accordion
              >
                {arr.map((item, index) => {
                  const Tab = item.component;
                  return (
                    <Panel
                      header={
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                        >
                          <span> {`${index + 1}. ${item.label}`}</span>

                          {item?.status && (
                            <CheckCircleFilled
                              style={{
                                color: "#95DE64",
                                marginLeft: "10px",
                              }} />
                          )}
                        </Box>
                      }
                      key={index + 1}
                    >
                      <Tab {...item.componentProps} key={index} index={index} />
                    </Panel>
                  );
                })}

                {isAllComplete && <ThankYou />}
              </InfluencerHubCollapse>
            </Visible>
          </FormContainer>
          <Visible xs sm md>
            <AnalyticsScreenshotExampleModal
              hideExample={() =>
                setShowExample({
                  age: false,
                  location: false,
                  gender: false,
                  campaign: false,
                  campaignScreenshot: false,
                })
              }
              showExample={showExample}
              platformType={cashoutData?.account?.type || ""}
              screenshotType={screenshotType}
            />
          </Visible>
        </Container>
      </Loading>
    </FormLayout>
  );
};

export { InfluencerHubTab, InfluencerHubTabContainer };
