// @ts-nocheck
import React from 'react'
import { Typography } from 'antd';
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

const { Paragraph } = Typography;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 0px !important;
  padding: 5px 2px;
  word-break: break-word;
`

const CopyLink = () => {
  const params: any = useParams();
  return(
    <StyledParagraph copyable>{`${window.location.host}/campaigns/${params.id}`}</StyledParagraph>
  )
}

export default CopyLink
