// @ts-nocheck
import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { IconProps } from "antd/lib/icon";
import { Icon } from '@ant-design/compatible'

const { Text } = Typography;

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface NewIconProps {
  color: string | undefined;
}

export const NewIcon = styled(Icon)<NewIconProps & IconProps>`
  && {
    font-size: 45px;
    color: ${props => props.color ? props.theme.colors[props.color] : null};
  }
`;

export const SuccessIcon = styled("img")`
  && {
    width: 45px;
    height: 45px;
    margin: 0px auto;
  }
`;

export const Message = styled(({type, ...props}) => <Text {...props} />)`
  && {
    color: ${props => props.type ? props.theme.colors[props.type] : null} !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    margin-top: 10px;
  }
`;
