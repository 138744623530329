// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Button as AntdButton } from "antd";

import {
  space,
  SpaceProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
} from "styled-system";
import { FontWeights, FontSizes, ThemeColors } from "src/utils/theme";

interface Props {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  weight?: FontWeights;
  fontSize?: FontSizes;
  color?: ThemeColors;
  background?: ThemeColors;
  style?: any;
  id?: string;
  disabled?: boolean;
}

const OriginalButton = props => <AntdButton {...props} type="link" />;

const LinkStyledButton = styled(OriginalButton)`
  && {
    font-size: ${props => (props.fontSize ? props.fontSize : 16)}px;
    font-weight: ${props =>
      props.weight ? props.theme.typeography.fontWeights[props.weight] : 300};
    line-height: ${props =>
      props.fontSize && props.theme.typeography.lineHeights[props.fontSize]};
    color: ${props =>
      props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.grey3} !important;
    background: ${props =>
      props.background
        ? props.theme.colors[props.background]
        : "none"} !important;;
    border: none;
    cursor: pointer;
    :focus,
    :active,
    :hover {
      color: none !important;
      outline: none;
    }
    ${space}
    ${typography}
    ${layout}
  }
`;

export type ButtonProps = SpaceProps & TypographyProps & LayoutProps;

export const LinkButton: React.FC<Props & ButtonProps> = props => (
  <LinkStyledButton {...props} />
);

export default LinkButton;
