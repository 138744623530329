// @ts-nocheck
import React from 'react'
import qs from 'qs'
import { Signup } from '.'
import Auth from 'src/ducks/Auth'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { useMapGraphQLErrorsMessages } from 'src/utils/hooks/useMapGraphQLErrors'
import { useSignUpPublicInfluencerMutation } from 'src/generated/ApolloComponents'

export const SignupContainer = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  let params = qs.parse(location.search, { ignoreQueryPrefix: true });
  ["name", "email"].map(key => delete params[key])

  let queryString = qs.stringify(params)
  if(queryString.length)
    queryString = "?" + queryString

    const [
    signUpPublicInfluencer,
    signupRes
  ] = useSignUpPublicInfluencerMutation()
  const globalErrors = useMapGraphQLErrorsMessages(signupRes.error);

  const handleSignup = async (values: any) => {
    try {
      const result = await signUpPublicInfluencer({
        variables: values
      })
      if(result?.data?.signUpPublicInfluencer) {
        localStorage.setItem("authToken", result.data.signUpPublicInfluencer.authToken)
        dispatch(Auth.fetchUser())
        history.push(`/connect-accounts${queryString}`)
      }
    } catch {
    }
  }

  return(
    <Signup
      loading={signupRes.loading}
      handleSignup={handleSignup}
      globalErrors={globalErrors}
    />
  )

}
