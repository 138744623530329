import axiosInstance from "../axiosInstance";
// Endpoint
const CREATE_DRAFT = `/invitation_drafts`;
const GET_DRAFT = `/invitation_drafts`;
const getInvitationDraft = id => `/invitation_drafts/${id}`;
const updateInvitationDraft = id => `/invitation_drafts/${id}`;
const deleteInvitationDraft = id => `/invitation_drafts/${id}`;

export const create = data => axiosInstance.post(CREATE_DRAFT, data);

export const index = data => axiosInstance.get(GET_DRAFT, data);

export const get = (id, data) =>
  axiosInstance.get(getInvitationDraft(id), data);

export const update = (id, data) =>
  axiosInstance.patch(updateInvitationDraft(id), data);

export const destroy = id => axiosInstance.delete(deleteInvitationDraft(id));
