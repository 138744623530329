// @ts-nocheck
import React, { Fragment } from 'react'
import { Row, Col } from 'antd'
import { object } from 'yup'
import Wrapper from '../Wrapper'
import styled from 'styled-components'
import Loading from 'src/shared/Loading'
import Form from 'src/components/Forms/Form'
import { Box } from 'src/components/Core/Box'
import { PreferencesContainer } from './container'
import { Field } from 'src/components/Forms/Field'
import { Button } from 'src/components/Core/Button'
import getFormattedTags from 'src/utils/formattedTags'
import { Typography } from 'src/components/Core/Typography'
import { FormikCheckbox } from 'src/components/Forms/Checkbox'
import { CheckboxStyle } from 'src/scenes/admins/CampaignsList/Wrapper'
import HomeButton from '../HomeButton'
import {
  GlobalErrorProps,
  GlobalErrors,
} from 'src/components/Forms/ErrorMessage'

interface Props {
  preferences?: any
  loading: boolean
  handleSubmit: Function
  globalErrors?: GlobalErrorProps[]
}

const StyledRow = styled(Row)`
  width: 50%;
  margin: auto;
  min-width: 400px;
  @media screen and (max-width: 768px) {
    min-width: fit-content;
    margin: unset;
  }
`

const Preferences = ({preferences, loading, handleSubmit, globalErrors}: Props) => {
  let tagData = getFormattedTags(preferences)
  return(
    <Wrapper>
      <Box px={28}>
        <GlobalErrors errors={globalErrors} />
        <Form
          schema={object().shape ({})}
          onSubmit={(values, actions) => handleSubmit(values)}
          >
          {(formProps: any) => {
            return(
              <Fragment>
                <StyledRow style={{margin: 'auto'}}>
                  <Typography fontSize={30} color="black" fontWeight="bold" mb={0}>Preferences</Typography>
                  <Typography fontSize={16}>Please select the kind of campaigns you’re interested in</Typography>
                </StyledRow>
                <StyledRow>
                  <Loading spinning={loading}>
                    <Field
                      mb={20}
                      color="black"
                      isBeside={true}
                      id="tags"
                    >
                      <FormikCheckbox name="tags" data={tagData} style={CheckboxStyle} />
                    </Field>
                  </Loading>
                </StyledRow>
                <StyledRow style={{marginTop: '35px'}}>
                  {window.innerWidth < 768 &&
                    <Col style={{marginRight: '15px'}}>
                      <HomeButton top={0} left={0} position="inherit" />
                    </Col>
                  }
                  <Col>
                    <Button
                      type="action"
                      onClick={formProps.handleSubmit}
                    >
                      <Typography fontSize={18} mb={0} cursor="pointer">Continue</Typography>
                    </Button>
                  </Col>
                </StyledRow>
              </Fragment>
            )
          }}
        </Form>
      </Box>
    </Wrapper>
  )
}

export {Preferences, PreferencesContainer};
