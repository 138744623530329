// @ts-nocheck
import React, { useEffect } from "react";
// import { NotFound } from "../../components";
import { useRouteMatch } from "react-router";
import { CampaignInstantReport } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useGetCampaignInstantReportQuery } from "src/generated/ApolloComponents";

type Props = {};

export const CampaignInstantReportContainer: React.FC<Props> = () => {
  const match: any = useRouteMatch();
  const params: any = match?.params;

  const campaignId = parseInt(params?.id);

  const { data, loading, error, refetch } = useGetCampaignInstantReportQuery({
    variables: {
      campaignId: campaignId,
    },
  });

  useEffect(() => {
    refetch({
      campaignId: campaignId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const campaignData: any = data?.getCampaignInstantReport || {};

  const globalErrors = useMapGraphQLErrorsMessages(error);

  // if (!loading && (error || !accountData)) return <NotFound />;

  return (
    <CampaignInstantReport
      globalErrors={globalErrors}
      loading={loading}
      campaignData={campaignData}
      campaignId={campaignId}
    />
  );
};
