// @ts-nocheck
import React from "react";
import { CompleteDataForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { useSetAccountBreakdownAfterPaymentRequestMutation } from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { PaymentRequestSchema } from "./Validations";
import _values from "lodash/values";
import sum from "lodash/sum";
import has from "lodash/has";

interface Props {
  closePaymentModal: () => void;
  setStep: (value: number) => void;
  invitationData: any;
}

interface CompleteDataFormValues {
  accountId: number;
  followers: number;
  ageBreakdown: Array<any>;
  genderBreakdown: Array<any>;
  locationBreakdown: Array<any>;
}

export const CompleteDataFormContainer: React.FC<Props> = ({
  closePaymentModal,
  setStep,
  invitationData,
}) => {
  const [
    setAccountBreakdownAfterPaymentRequestMutation,
    { loading, error },
  ] = useSetAccountBreakdownAfterPaymentRequestMutation();

  let ageBreakdownData = invitationData?.account?.ageBreakdown?.manual || {};

  const ageBreakdownArray =
    Object.keys(ageBreakdownData).map(function(key) {
      return {
        key: key,
        value: ageBreakdownData[key],
      };
    }) || [];

  let genderBreakdownData =
    invitationData?.account?.genderBreakdown?.manual || {};

  const genderBreakdownArray =
    Object.keys(genderBreakdownData).map(function(key) {
      return {
        key: key,
        value: genderBreakdownData[key],
      };
    }) || [];

  let locationBreakdownData =
    invitationData?.account?.locationBreakdown?.manual || {};

  const locationBreakdownArray =
    Object.keys(locationBreakdownData).map(function(key) {
      return {
        key: key,
        value: locationBreakdownData[key],
      };
    }) || [];

  let initialValues = {
    accountId: invitationData?.account?.id,
    followers: invitationData?.account?.metadata?.followers || null,
    ageBreakdown: ageBreakdownArray || [{ key: "", value: 0 }],
    genderBreakdown: genderBreakdownArray || [{ key: "", value: 0 }],
    locationBreakdown: locationBreakdownArray || [{ key: "", value: 0 }],
  };

  const onSubmit = async (
    values: CompleteDataFormValues,
    actions: FormikActions<CompleteDataFormValues>
  ) => {
    const ageBreakdownData = values?.ageBreakdown.reduce(function(
      result,
      item
    ) {
      if (
        item.key !== "" &&
        typeof item.value !== "undefined" &&
        item.value !== "" &&
        item.value !== null &&
        item.value !== false
      ) {
        result[item.key] = item.value;
        return result;
      }
      return result;
    },
    {});

    const genderBreakdownData = values?.genderBreakdown.reduce(function(
      result,
      item
    ) {
      if (
        item.key !== "" &&
        typeof item.value !== "undefined" &&
        item.value !== "" &&
        item.value !== null &&
        item.value !== false
      ) {
        result[item.key] = item.value;
        return result;
      }
      return result;
    },
    {});

    let locationBreakdownData = values?.locationBreakdown.reduce(function(
      result,
      item
    ) {
      if (
        item.key !== "" &&
        typeof item.value !== "undefined" &&
        item.value !== "" &&
        item.value !== null &&
        item.value !== false
      ) {
        result[item.key] = item.value;
        return result;
      }
      return result;
    },
    {});

    let locationSum = sum(_values(locationBreakdownData));

    if (locationSum < 100 && !has(locationBreakdownData, "other")) {
      let otherLocationBreakdownData = { other: 100 - locationSum };

      locationBreakdownData = Object.assign(
        locationBreakdownData,
        otherLocationBreakdownData
      );
    }

    let formData: any = {
      accountId: values?.accountId,
      followers: values?.followers,
      ageBreakdown: ageBreakdownData,
      genderBreakdown: genderBreakdownData,
      locationBreakdown: locationBreakdownData,
    };

    const res = await setAccountBreakdownAfterPaymentRequestMutation({
      variables: formData,
    });

    if (res?.data?.setAccountBreakdownAfterPaymentRequest?.id) {
      setStep(4);
      //closePaymentModal();
    }

    try {
    } catch (e) {
      actions.setSubmitting(false);
    }
  };
  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <CompleteDataForm
      initialValues={initialValues}
      PaymentRequestSchema={PaymentRequestSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      isSubmitting={loading}
      globalErrors={globalErrors}
      closePaymentModal={closePaymentModal}
      invitationData={invitationData}
      goFirstStep={() => setStep(1)}
    />
  );
};
