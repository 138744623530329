import styled from "styled-components"
import { Checkbox } from "formik-antd"

export default styled(Checkbox)`
  &&& {
    display: flex;
    height: 30px;
    line-height: 30px;
    color: #182026;
    font-size: 16px;
    margin-left: 0px;
  }
`
