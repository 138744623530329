// @ts-nocheck
import React, { useState } from "react";
import { ManualDataModalForm } from ".";
import { string, object, array, number } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useSetBreakdownByAccountIdMutation,
  BreakdownType,
} from "src/generated/ApolloComponents";
import { useRouteMatch } from "react-router";
import _values from "lodash/values";
import sum from "lodash/sum";
import sumBy from "lodash/sumBy";
import has from "lodash/has";

interface Props {
  showManualDataModal: boolean;
  closeManualDataModal: () => void;
  setDoRefresh: () => void;
}

interface ManualDataModalFormValues {
  accountId: string;
  type: string;
  data: Array<any>;
}
export const ManualDataModalFormContainer: React.FC<Props> = ({
  showManualDataModal,
  closeManualDataModal,
  setDoRefresh,
}) => {
  const [step, setStep] = useState<number>(1);

  const match: any = useRouteMatch();
  const params: any = match?.params;

  const accountId = params.accountId;

  const ManualDataModalSchema = object().shape({
    type: string()
      .nullable()
      .required("Please choose breakdown"),

    data: array()
      .of(
        object().shape({
          key: string()
            .nullable()
            .required("Please select breakdown option"),

          value: number()
            .nullable()
            .required("Please enter value"),
        })
      )
      .min(1, "You must add at least one breakdown")
      .test("data", "Please enter total of percentage upto 100%", function(
        value
      ) {
        let totalPercentage = sumBy(value, function(location: any) {
          return location?.value;
        });

        if (totalPercentage > 100) return false;
        return true;
      }),
  });

  let initialValues = {
    type: "Age Breakdown",
    data: [{ key: "", value: 0 }],
  };

  const [
    setBreakdownByAccountIdMutation,
    { loading, error },
  ] = useSetBreakdownByAccountIdMutation();

  const onSubmit = async (
    values: ManualDataModalFormValues,
    actions: FormikActions<ManualDataModalFormValues>
  ) => {
    try {
      let type = "" as BreakdownType;

      if (values?.type === "Age Breakdown") {
        type = BreakdownType.Age;
      } else if (values?.type === "Gender Breakdown") {
        type = BreakdownType.Gender;
      } else if (values?.type === "Location Breakdown") {
        type = BreakdownType.Location;
      }

      let breakdownData = values.data.reduce(function(result, item) {
        if (
          item.key !== "" &&
          typeof item.value !== "undefined" &&
          item.value !== "" &&
          item.value !== null &&
          item.value !== false
        ) {
          result[item.key] = item.value;
          return result;
        }
        return result;
      }, {});

      if (values?.type === "Location Breakdown") {
        let locationSum = sum(_values(breakdownData));

        if (locationSum < 100 && !has(breakdownData, "other")) {
          let otherBreakdownData = { other: 100 - locationSum };

          breakdownData = Object.assign(breakdownData, otherBreakdownData);
        }
      }

      let formData = {
        accountId: accountId.toString(),
        type,
        data: breakdownData,
      };

      const res = await setBreakdownByAccountIdMutation({
        variables: formData,
      });

      if (res?.data?.setBreakdownByAccountId?.id) {
        setDoRefresh();
        setStep(3);
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <ManualDataModalForm
      initialValues={initialValues}
      ManualDataModalSchema={ManualDataModalSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      showManualDataModal={showManualDataModal}
      closeManualDataModal={() => closeManualDataModal()}
      step={step}
      setStep={value => setStep(value)}
    />
  );
};
