// @ts-nocheck
import React, { useState } from "react";
import { FilterForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { object } from "yup";
import isEqual from "lodash/isEqual";
import {
  useGetAllTagsQuery,
  useGetAllCampaignsQuery,
  DataSourcePlatform,
} from "src/generated/ApolloComponents";
import { useInfluencersListDetails } from "../InfluencersListProvider";
import { getFormData, getInitialValues } from "./utils";
import { getFilterData } from "../utils";
import { useLocation } from "react-router";
import qs from "qs";

interface Props {}

interface FilterFormValues {
  platformType?: string;
  searchInput?: string;
  metricSelect?: string;
  campaignIds?: Array<any>;
  accountType?: string;
  influencerGender?: string;
  influencerLocation?: string;
  includeEstimatedCosts?: boolean;
  tagIds?: Array<any>;
  followers?: number;
  followersIsgreaterThan?: boolean;
  hearts?: number;
  heartsIsgreaterThan?: boolean;
  price?: number;
  priceIsgreaterThan?: boolean;
  locationAudience?: {
    location?: string;
    locationIsgreaterThan?: boolean;
    count?: number;
  };
  genderAudience?: {
    gender?: string;
    genderIsgreaterThan?: boolean;
    count?: number;
  };
  ageAudience?: {
    age?: string;
    ageIsgreaterThan?: boolean;
    count?: number;
  };
}

export const FilterFormContainer: React.FC<Props> = props => {
  const location: any = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  // need to convert string and boolean value from query string
  const formatFilters = getFilterData(parsed);

  const initialFilters = {
    platformType: DataSourcePlatform.Fanbytes,
    page: 1,
  };

  const [internalFilters, setInternalFilters] = useState({
    ...formatFilters,
    ...initialFilters,
  });

  const tags = useGetAllTagsQuery();

  const allTags = tags?.data?.getAllTags || [];

  const FilterFormSchema = object().shape({});

  const campaigns = useGetAllCampaignsQuery({
    skip: true,
  });

  const {
    actions: influencerAction,
    isLoading,
    filters,
  } = useInfluencersListDetails();

  let initialValues = getInitialValues(filters);

  const clearFilterKey = key => {
    const newFileter = { ...filters };
    newFileter[key] = null as any;
    if (key === "price") {
      newFileter["includeEstimatedCosts"] = null as any;
    }
    influencerAction.setFilters({ ...newFileter });
    setInternalFilters({ ...newFileter });
    influencerAction.refetchAccounts({ ...newFileter });
  };

  const clearAllFilter = () => {
    let container = {} as any;
    container["platformType"] = DataSourcePlatform.Fanbytes;
    container["page"] = 1;
    influencerAction.setFilters({ ...container });
    setInternalFilters({ ...container });
    influencerAction.refetchAccounts({ ...container });
  };

  const onSubmit = async (
    values: FilterFormValues,
    actions: FormikActions<FilterFormValues>
  ) => {
    try {
      const filterValue = getFormData(values);
      let newFilter = { ...filters, ...filterValue };
      influencerAction.setFilters(newFilter);

      if (!isEqual(newFilter, internalFilters)) {
        influencerAction.refetchAccounts(newFilter);
        setInternalFilters(newFilter);
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <FilterForm
      initialValues={initialValues}
      FilterSchema={FilterFormSchema}
      isSubmitting={isLoading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      getCampaigns={campaigns.refetch}
      allTags={(allTags || []) as any}
      clearFilterKey={key => clearFilterKey(key)}
      clearAllFilter={() => clearAllFilter()}
      internalFilters={internalFilters}
    />
  );
};
