// @ts-nocheck
import React, { useState, useEffect } from "react";
import { InfluencerSelection } from ".";
import {
  useGetAllAcceptedAccountsByCampaignIdQuery,
  useGetAllSuggestedAccountsByCampaignIdQuery,
  useRemoveInvitedAccountMutation,
  useAddSelectedAccountsByCampaignIdMutation,
  useUpdateSuggestionAccountMutation,
} from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { DragDropContext } from "react-beautiful-dnd";

interface Props {
  campaignId: number;
}

export const InfluencerSelectionContainer: React.FC<Props> = ({
  campaignId,
}) => {
  // From suggestion with status pending
  const {
    data: suggestedData,
    error: suggestedError,
    loading: suggestedLoading,
    refetch: suggestedRefetch
  } = useGetAllSuggestedAccountsByCampaignIdQuery({
    variables: { campaignId: Number(campaignId) },
  });

  //
  const {
    data: acceptedData,
    error: acceptedError,
    loading: acceptedLoading,
    refetch: acceptedRefetch
  } = useGetAllAcceptedAccountsByCampaignIdQuery({
    variables: { campaignId: Number(campaignId) },
  });

  // selection pool
  const [suggestedAccounts, setSuggestedAccounts] = useState([] as Array<any>);

  // selected influencers
  const [acceptedInviteAccounts, setAcceptedInviteAccounts] = useState(
    [] as Array<any>
  );

  // confirmed influencers
  const [acceptedAccounts, setAcceptedAccounts] = useState([] as Array<any>);

  const [isSubmit, setIsSubmit] = useState(false as boolean);
  const [isLoading, setIsLoading] = useState(false as boolean);

  useEffect(() => {
    const hiddSuggestion = (suggestedData?.getAllSuggestedAccountsByCampaignId || []).filter(
      v => v?.status === "hidden"
    );

    const unHiddSuggestion = (suggestedData?.getAllSuggestedAccountsByCampaignId || []).filter(
      v => v?.status !== "hidden"
    );

    const newSuggestedAccountsData = [...unHiddSuggestion, ...hiddSuggestion];

    setSuggestedAccounts(newSuggestedAccountsData);
  }, [suggestedData]);

  useEffect(() => {
    // its for filter suggestion based on campaignInvitationId as null as well invitation satus accepted or complete
    const newAcceptedAccountsData = (acceptedData?.getAllAcceptedAccountsByCampaignId || []).filter(
      v =>
        v?.campaignInvitationId === null ||
        v.invitation?.status === "accepted" ||
        v?.invitation?.status === "complete"
    );

    // its for filter suggestion based on campaignInvitationId as not null as well invitation satus approved or sent
    const newAcceptedInviteAccountsData = (acceptedData?.getAllAcceptedAccountsByCampaignId || []).filter(
      v =>
        v?.campaignInvitationId !== null &&
        (v.invitation?.status === "approved" ||
          v?.invitation?.status === "sent")
    );

    setAcceptedAccounts(newAcceptedAccountsData);
    setAcceptedInviteAccounts(newAcceptedInviteAccountsData);
  }, [acceptedData]);

  const [
    removeInvitedAccountMutation,
    remove,
  ] = useRemoveInvitedAccountMutation();

  const removeAccount = async (index, suggestionStatus) => {
    let newAccounts = [...acceptedInviteAccounts];
    let element = newAccounts[index];
    if (element?.campaignInvitationId) {
      try {
        const res = await removeInvitedAccountMutation({
          variables: {
            campaignInvitationId: element?.campaignInvitationId,
            suggestionStatus: suggestionStatus,
          },
        });
        if (res.data?.removeInvitedAccount?.id) {
          setIsLoading(true);
          await suggestedRefetch();
          await acceptedRefetch();
          setIsLoading(false);
        }
      } catch (e) {}
    }
  };

  const moveAccount = (fromIndex, toIndex) => {
    let newAccounts = [...acceptedInviteAccounts];
    let element = newAccounts[fromIndex];
    newAccounts.splice(fromIndex, 1);
    newAccounts.splice(toIndex, 0, element);
    setAcceptedInviteAccounts(newAccounts);
  };

  const addAccount = index => {
    let newSuggestedAccounts = [...suggestedAccounts];
    let newAcceptedInviteAccounts = [...acceptedInviteAccounts];
    let element = newSuggestedAccounts[index];
    newAcceptedInviteAccounts.push(element);
    let accountIds = newAcceptedInviteAccounts.map(i => i.account.id) || [];
    submitAccount(accountIds);
  };

  const [
    addSelectedAccountsByCampaignIdMutation,
    add,
  ] = useAddSelectedAccountsByCampaignIdMutation();

  // when click on submit or add to list its call api
  const submitAccount = async accountIds => {
    if ((accountIds || []).length > 0) {
      try {
        const res = await addSelectedAccountsByCampaignIdMutation({
          variables: {
            campaignId: Number(campaignId),
            accountIds: accountIds,
          },
        });
        if (res.data?.addSelectedAccountsByCampaignId) {
          setIsSubmit(false);
          setIsLoading(true);
          await suggestedRefetch();
          await acceptedRefetch();
          setIsLoading(false);
        }
      } catch (e) {
        setIsSubmit(false);
      }
    }
  };

  const [
    updateSuggestionAccountMutation,
    update,
  ] = useUpdateSuggestionAccountMutation();

  // when click on submit or add to list its call api
  const updateSuggestion = async (index, status) => {
    try {
      let newSuggestedAccounts = [...suggestedAccounts];
      let element = newSuggestedAccounts[index];

      const res = await updateSuggestionAccountMutation({
        variables: {
          suggestionId: Number(element?.id),
          status: status,
        },
      });
      if (res.data?.updateSuggestionAccount) {
        await suggestedRefetch();
      }
    } catch (e) {
      setIsSubmit(false);
    }
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    // if its drag card from selected influencer column to selection pool
    //  call addAccount
    if (
      destination.droppableId === "selected" &&
      source.droppableId === "selection"
    ) {
      addAccount(source?.index);
    }

    // if its drag card from selection pool to  selected influencer column
    //  call removeAccount
    if (
      destination.droppableId === "selection" &&
      source.droppableId === "selected"
    ) {
      removeAccount(source?.index, "pending");
    }

    // if its both same then deag card from source index to dextination index

    if (
      destination.droppableId === "selection" &&
      source.droppableId === "selection"
    ) {
      const fromIndex = source?.index;
      const toIndex = destination?.index;
      let newSuggestedAccounts = [...suggestedAccounts];
      let element = newSuggestedAccounts[fromIndex];
      newSuggestedAccounts.splice(fromIndex, 1);
      newSuggestedAccounts.splice(toIndex, 0, element);
      setSuggestedAccounts(newSuggestedAccounts);
    }

    if (
      destination.droppableId === "selected" &&
      source.droppableId === "selected"
    ) {
      const fromIndex = source?.index;
      const toIndex = destination?.index;

      moveAccount(fromIndex, toIndex);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(
    suggestedError ||
    acceptedError ||
    remove?.error ||
    add?.error ||
    update?.error
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <InfluencerSelection
        loading={
          isLoading ||
          suggestedLoading ||
          acceptedLoading ||
          remove?.loading ||
          add?.loading ||
          update?.loading
        }
        globalErrors={globalErrors}
        suggestedAccounts={suggestedAccounts}
        acceptedAccounts={acceptedAccounts}
        acceptedInviteAccounts={acceptedInviteAccounts}
        updateSuggestion={(index, status) => updateSuggestion(index, status)}
        removeAccount={index => removeAccount(index, "hidden")}
        submitAccount={() => {
          let newAcceptedInviteAccounts = [...acceptedInviteAccounts];
          let accountIds =
            newAcceptedInviteAccounts.map(i => i.account.id) || [];
          setIsSubmit(true);
          submitAccount(accountIds);
        }}
        isSubmit={isSubmit}
      />
    </DragDropContext>
  );
};
