// @ts-nocheck
import React from "react";
import { InfluencerRatingForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { object } from "yup";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useUpdateAccountRatingMutation } from "src/generated/ApolloComponents";

interface Props {
  closeInfluencerRatingModal: () => void;
  accountId: number;
  rating: any;
  onRating: Function;
}

interface InfluencerRatingFormValues {
  accountId: number;
  creationTime: number;
  responseTime: number;
  contentQuality: number;
  professionalism: number;
}

export const InfluencerRatingFormContainer: React.FC<Props> = ({
  closeInfluencerRatingModal,
  accountId,
  rating,
  onRating,
}) => {
  const [
    updateAccountRatingMutation,
    { loading, error },
  ] = useUpdateAccountRatingMutation();

  const InfluencerRatingSchema = object().shape({});

  let initialValues = {
    accountId: accountId,
    creationTime: Math.round(rating?.creation_time) || null,
    responseTime: Math.round(rating?.response_time) || null,
    contentQuality: Math.round(rating?.content_quality) || null,
    professionalism: Math.round(rating?.professionalism) || null,
  };

  const onSubmit = async (
    values: InfluencerRatingFormValues,
    actions: FormikActions<InfluencerRatingFormValues>
  ) => {
    let formData: any = {
      accountId: values?.accountId,
      creationTime: values?.creationTime,
      responseTime: values?.responseTime,
      contentQuality: values?.contentQuality,
      professionalism: values?.professionalism,
    };

    const res = await updateAccountRatingMutation({
      variables: formData,
    });

    if (res?.data?.updateAccountRating?.id) {
      onRating();
      closeInfluencerRatingModal();
    }

    try {
    } catch (e) {
      actions.setSubmitting(false);
    }
  };
  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <InfluencerRatingForm
      initialValues={initialValues}
      influencerRatingSchema={InfluencerRatingSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      isSubmitting={loading}
      globalErrors={globalErrors}
      closeInfluencerRatingModal={closeInfluencerRatingModal}
    />
  );
};
