import React, { Component } from "react";
import styled from "styled-components";
import { Steps } from "antd";

const { Step } = Steps;

const ClickableStep = styled(Step)`
  cursor: pointer;
`;

export default class FormSteps extends Component {
  step = index => {
    const { changeStep } = this.props;
    changeStep(index);
  };

  render() {
    const {
      stepStatuses,
      currentStep,
      steps,
      campaign,
      serverErrors
    } = this.props;

    const statuses = stepStatuses(
      campaign ? campaign.ref : null,
      serverErrors,
      currentStep
    );

    return (
      <Steps current={currentStep}>
        {steps.map((step, index) => (
          <ClickableStep
            key={index}
            status={statuses[index]}
            onClick={() => this.step(index)}
            title={step.title}
            data-cy={`step-${index}`}
          />
        ))}
      </Steps>
    );
  }
}
