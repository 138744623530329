// @ts-nocheck
import React from "react";
import { render } from "react-dom";

import App from "./App";

render(<App />, document.getElementById("root"));

if (process.env.NODE_ENV === "development") {
  (module as any).hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(<NextApp />, document.getElementById("root"));
  });
}
