import React, { Component } from "react";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { default as $TableManager } from "src/ducks/TableManager";
import {
  AccountList as $AccountList,
  CompanyList as $CompanyList,
  CampaignList as $CampaignList,
  InfluencerList as $InfluencerList,
  AgencyList as $AgencyList,
} from "src/ducks/DataList";

import Profile from "./Profile";

import Dashboard from "./Dashboard";

import Companies from "./Companies";
import CompanyShow from "./CompanyShow";

import AccountShow from "./AccountShow";
import AccountsList from "./AccountsList";

import Influencers from "./Influencers";
import InfluencerShow from "./InfluencerShow";
import InfluencerProfile from "./InfluencerProfile";
import InfluencersList from "./InfluencersList";

import CampaignShow from "./CampaignShow";
import CampaignsList from "./CampaignsList";

import CampaignGroupShow from "./CampaignGroupShow";

import TeamManagement from "./TeamManagement";

import Agencies from "./Agencies";
import AgencyShow from "./AgencyShow";

import PaymentRequest from "./PaymentRequest";

import BrandSafety from "../auth/BrandSafety";

import AdAuthorise from "./AdAuthorise";

import InstantReport from "../auth/InstantReport";
import { SuggestionContainer } from "../auth/SharedSuggestions"

import Discover from "./Discover";
// import ClaimCashout from "../influencers/ClaimCashout";
// import InitialContract from "../influencers/InitialContract";
import InfluencerHub from "../influencers/InfluencerHub";

const AccountList = $AccountList("AdminAccountList");
const AccountFilters = $TableManager("AdminAccountList");

const InfluencerList = $InfluencerList("AdminInfluencerList");
const InfluencerFilters = $TableManager("AdminInfluencerList");

const CompanyList = $CompanyList("AdminCompanyList");
const CompanyFilters = $TableManager("AdminCompanyList");

const CampaignList = $CampaignList("AdminCampaignList");
const CampaignFilters = $TableManager("AdminCampaignList");

const AgencyList = $AgencyList("AdminAgencyList");
const AgencyFilters = $TableManager("AdminAgencyList");

const modules = [
  AccountList.module(),
  AccountFilters.module(),

  InfluencerList.module(),
  InfluencerFilters.module(),

  CompanyList.module(),
  CompanyFilters.module(),

  CampaignList.module(),
  CampaignFilters.module(),

  AgencyList.module(),
  AgencyFilters.module(),
];

class AdminRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />

        <Route exact path="/companies" component={Companies} />
        <Route exact path="/companies/:id/:page?" component={CompanyShow} />

        <Route exact path="/campaigns" component={CampaignsList} />

        <Route
          path="/campaigns/instant-report/:id"
          exact
          component={InstantReport}
        />

        <Route exact path="/campaigns/:id/:page?" component={CampaignShow} />

        <Route
          exact
          path="/campaign-group/:id/:page?"
          component={CampaignGroupShow}
        />

        <Route exact path="/influencers" component={Influencers} />
        <Route
          exact
          path="/influencers/:id/:page?"
          component={InfluencerShow}
        />

        <Route exact path="/influencers-list" component={InfluencersList} />
        <Route
          path="/influencer-profile/:type/:id/:page?/:accountId?"
          component={InfluencerProfile}
        />

        <Route exact path="/profiles/:id" component={Profile} />

        <Route exact path="/accounts" component={AccountsList} />
        <Route exact path="/accounts/:id/:page?" component={AccountShow} />

        <Route exact path="/team" component={TeamManagement} />

        <Route exact path="/agencies" component={Agencies} />
        <Route exact path="/agencies/:id/:page?" component={AgencyShow} />

        <Route exact path="/payment-request" component={PaymentRequest} />

        <Route path="/ad-authorise" exact component={AdAuthorise} />

        <Route path="/brand-safety/" exact component={BrandSafety} />

        <Route path="/discover" exact component={Discover} />

        <Route path="/invitation/hub/:id" component={InfluencerHub} />

        {/* <Route path="/claim-cashout/:id/:type?" component={ClaimCashout} />

        <Route
          path="/initial-contact/:id/:type"
          exact
          component={InitialContract}
        /> */}

        {/* Shared Routes Start */}
        <Route path="/sharedsuggestions/:id" exact component={SuggestionContainer} />
        {/* Shared Routes End */}

        <Route path="*" component={() => <Redirect to="/" />} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withModules(
  withRouter(connect(mapStateToProps, {})(AdminRoutes))
)(modules);
