// @ts-nocheck
import React from 'react'
import { Row, Col, Button } from 'antd'
import { Typography } from 'src/components/Core/Typography'

interface Props {
  user: any
  setShowNewMileStoneForm: (flag: boolean) => void
  lastCheckedAt: string
}

const Header = ({user, setShowNewMileStoneForm, lastCheckedAt}: Props) => {
  return(
    <>
      <Row justify="space-between">
        <Col>
          <Typography color="black" size={16} family="Futura">
            Milestones
          </Typography>
        </Col>
        {user.type === "Admin" &&
          <Col>
            <Button type="primary" onClick={() => setShowNewMileStoneForm(true)}>
              + New Milestone
            </Button>
          </Col>
        }
      </Row>
      <Typography color="black2" fontSize={12}>
        Here you can setup and track custom deliverables and be notified about their progress.
      </Typography>
      <Typography color="black2" fontSize={12}>Last updated: {lastCheckedAt}</Typography>
    </>
  )
}

export default Header
