export const costTypes = [
  "Content creation",
  "Content Distribution",
  "Designer Payout",
  "Influencer Payout",
];

export const currency = [
  { label: "£", value: "GBP" },
  { label: "€", value: "EUR" },
  { label: "$", value: "USD" },
  { label: "A$", value: "AUD" },
  { label: "C$", value: "CAD" },
  { label: "S$", value: "SGD" },
  { label: "₱", value: "PHP" },
  { label: "RM", value: "MYR" },
  { label: "Ft", value: "HUF" },
  // { label: "د.إ", value: "AED" },
  { label: "₹", value: "INR" },
];

export const countries = [
  { label: "UK", value: "GB" },
  { label: "USA", value: "US" },
  { label: "Europe", value: "EU" },
  { label: "New Zealand", value: "NZ" },
  { label: "Australia", value: "AU" },
  { label: "Philippines", value: "PH" },
  { label: "Canada", value: "CA" },
];

export const bankFields = {
  countryCode: "Country",
  fullName: "Name on Account",
  sortCode: "Sort code",
  transitNumber: "Transit number",
  institutionNumber: "Institution number",
  accountNumber: "Account number",
  recipientAddress: "Recipient Address",
  routingNumber: "Routing number",
  swift: "Swift",
  iban: "Iban",
  bsbCode: "BSB code",
};
