// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import RatingInput from "./StyledRatingInput";
import { RatingInputProps } from "./StyledRatingInput";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";

interface Props {
  name?: string;
}

const FormikInput: React.FC<Props & RatingInputProps> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur, setFieldTouched, setFieldValue },
      }) => (
        <>
          <RatingInput
            {...props}
            {...field}
            onBlur={e => {
              handleBlur(e);
            }}
            onChange={value => {
              setFieldTouched(field.name, true);
              setFieldValue(field.name, value);
            }}
            data-cy={`input-rating-${field.name.replace(" ", "-")}`}
          />
          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={field.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikInput;
