import React, { Component } from "react";
import FormField from "../FormField";
import LargeTextArea from "../LargeTextArea";

export default class Note extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "detail.note"}
        label="Note"
      >
        <LargeTextArea
          data-cy="influencer-note"
          rows={5}
          size="large"
          placeholder="We would like you to..."
        />
      </FormField>
    );
  }
}
