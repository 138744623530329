// @ts-nocheck
import React, { useEffect, useState } from 'react'
import qs from 'qs'
import { Dashboard } from '.'
import { isEmpty } from 'lodash'
import {
  useGetPublicCampaignsQuery,
  useGetPublicInfluencerProfileQuery,
  useConnectPublicTikTokAccountMutation
} from 'src/generated/ApolloComponents'
import { useHistory, useLocation } from 'react-router'
import { getFilterData, getInitialValues } from './utils'
import { useMapGraphQLErrorsMessages } from 'src/utils/hooks/useMapGraphQLErrors'

export const DashboardContainer = () => {
  const history  = useHistory()
  const location = useLocation()
  const parsed = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [filters, setFilters] = useState(getFilterData(parsed))
  const [tiktokInfo, setTikTokInfo] = useState(null as any)
  const [email, setEmail] = useState(false)

  useEffect(() => {
    if(parsed.email) {
      delete parsed.email
      setEmail(true)
    }
  },[parsed])


  const queryString = `?${qs.stringify(parsed)}`


  const profile: any = useGetPublicInfluencerProfileQuery({
    variables: {}
  })

  useEffect(() => {
    if(email && profile?.data){
      let expressApplicants: any = profile?.data?.getPublicInfluencerProfile?.expressApplicants || []
      expressApplicants = expressApplicants.filter(applicant => ["YouTube", "TikTok"].includes(applicant.platform))
      if(expressApplicants.length){
        history.push(`/connect-accounts${queryString}`)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile])


  const { data, loading, error, refetch } = useGetPublicCampaignsQuery({
    variables: filters,
    fetchPolicy: "network-only",
  });
  const [connectPublicTikTokAccount, tiktokAccount] = useConnectPublicTikTokAccountMutation()

  const globalErrors = useMapGraphQLErrorsMessages(error || tiktokAccount?.error || profile?.error);

  useEffect(() => {
    const { code, scopes, state} = parsed
    if(code && scopes && state) {
      setTikTokInfo({
        code: code,
        scopes: scopes,
        state: state
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(isEmpty(tiktokInfo)){
      return
    }
    async function fetchData() {
      try {
        const response: any = await connectPublicTikTokAccount({
          variables: {
            code: tiktokInfo['code']
          }
        })
        if(response?.data?.connectPublicTikTokAccount?.username){
          profile.refetch()
          setTimeout(() => {
            history.push('/connect-accounts')
          }, 1500);
        }
      } catch(e) {
        console.log(e)
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tiktokInfo])

  useEffect(() => {
    history.push({search: qs.stringify(filters)})
    refetch(filters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters])

  useEffect(() => {
    setFilters(getFilterData(parsed))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.pathname])

  const handleChangedFilter = (values: any) => {
    setFilters({...filters, ...values})
  }

  return(
    <Dashboard
      loading={loading || tiktokAccount.loading || profile?.loading}
      handleFilter={handleChangedFilter}
      campaigns={data?.getPublicCampaigns}
      isDashboard={window.location.pathname === '/'}
      initialValues={getInitialValues(filters)}
      globalErrors={globalErrors}
    />
  )
}

