// @ts-nocheck
import background from "../../../../assets/images/background.png";
import styled from "styled-components";
import { Layout } from "antd";
import { Box } from "src/components/Core/Box";

export const Wrapper = styled(Layout)`
  && {
    min-height: 100vh;
    background-image: url(${background}) !important;
  }
`;

export const Logo = styled("img")`
  width: 100px;
`;

export const Content = styled(Box as any)`
  width: 310px;
  margin: 0 auto;
`;

export const FormWrapper = styled(Box as any)`
  background: #fff;
  border-radius: 5px;
  padding: 30px;
`;
