// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";

interface Props {
  addClick: (value: boolean) => void;
  text: string;
}

const AddButton: React.FC<Props> = ({ addClick, text }) => {
  return (
    <Box
      textAlign="center"
      onClick={() => addClick(true)}
      p={30}
      style={{ cursor: "pointer" }}
      data-cy={`block-${text.replace(" ", "-")}`}
    >
      <Typography size={12} weight="semi-bold" color="blue">
        +
      </Typography>
      <Typography size={10} weight="semi-bold" color="blue" lspace={0.725}>
        {text}
      </Typography>
    </Box>
  );
};

export default AddButton;
