// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikRatingInput } from "src/components/Forms/Rating";

interface Props {}

const Quality: React.FC<Props> = () => {
  return (
    <Field label="Quality:" mb={26}>
      <FormikRatingInput name="quality" size={24} allowHalf />
    </Field>
  );
};

export default Quality;
