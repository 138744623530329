// @ts-nocheck
import React from "react";
import { Row, Card, Col, Statistic } from "antd";
import formatCurrency from "src/utils/formatCurrency";
import numeral from "numeral";
import styled from "styled-components";

const StatsCard = styled(Card)`
  && {
    border: 0px !important;
    margin-top: 20px;
  }
`;

type Props = {
  data: any;
};

const Topstats: React.FC<Props> = ({ data }) => {
  return !!data?.clicks || !!data?.views || !!data?.installs ? (
    <Row gutter={32}>
      {data?.views > 0 && (
        <Col span={6}>
          <StatsCard>
            <Statistic title="Views" value={data?.views} />
          </StatsCard>
        </Col>
      )}

      {data?.clicks > 0 && (
        <Col span={6}>
          <StatsCard>
            <Statistic title="Clicks" value={data?.clicks} />
          </StatsCard>
        </Col>
      )}

      {data?.cpcPennies && data?.status === "complete" && (
        <Col span={6}>
          <StatsCard>
            <Statistic
              title="Cost per click"
              value={
                data?.cpcPennies ? formatCurrency(data?.cpcPennies) : "£--"
              }
            />
          </StatsCard>
        </Col>
      )}

      {typeof data?.metadata != "undefined" &&
        data?.metadata?.engagementRate > 0 && (
          <Col span={6}>
            <StatsCard>
              <Statistic
                title="Engagement Rate"
                value={
                  numeral(data?.metadata?.engagementRate).format("0,0.00") + "%"
                }
              />
            </StatsCard>
          </Col>
        )}

      {typeof data?.metadata != "undefined" && data?.metadata?.likeCount > 0 && (
        <Col span={6}>
          <StatsCard>
            <Statistic
              title="Likes"
              value={numeral(data?.metadata?.likeCount).format("0,0")}
            />
          </StatsCard>
        </Col>
      )}

      {typeof data?.metadata != "undefined" &&
        data?.metadata?.commentCount > 0 && (
          <Col span={6}>
            <StatsCard>
              <Statistic
                title="Comments"
                value={numeral(data?.metadata.commentCount).format("0,0")}
              />
            </StatsCard>
          </Col>
        )}

      {data?.cpiPennies && data?.status === "complete" && (
        <Col span={6}>
          <StatsCard>
            <Statistic
              title="Cost per install"
              value={
                data?.cpiPennies ? formatCurrency(data?.cpiPennies) : "£--"
              }
            />
          </StatsCard>
        </Col>
      )}

      {data?.installs > 0 && (
        <Col span={6}>
          <StatsCard>
            <Statistic title="Installs" value={data?.installs} />
          </StatsCard>
        </Col>
      )}

      {!!data?.ctr && data?.status === "complete" && (
        <Col span={6}>
          <StatsCard>
            <Statistic
              title="Click through rate"
              value={data?.ctr}
              precision={2}
              suffix={"%"}
            />
          </StatsCard>
        </Col>
      )}

      {data?.cpm && data?.status === "complete" && (
        <Col span={6}>
          <StatsCard>
            <Statistic
              title="Cost per 1,000 views"
              value={data?.cpm ? formatCurrency(data?.cpm) : "£--"}
            />
          </StatsCard>
        </Col>
      )}
    </Row>
  ) : null;
};

export default Topstats;
