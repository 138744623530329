import React, { Component } from "react"
import { connect } from "react-redux"
import { createSelector } from "reselect"
import { connectModal } from "redux-modal"
import { Form } from "formik-antd"
import { message, Modal, Button } from "antd"
import { withFormik } from "formik"
import { RemoveReason } from "src/shared/InvitationFields"
import FormError from "src/shared/FormField/FormError"
import InvitationManage from "src/ducks/InvitationManage"
import { Invitation } from "src/ducks/Orm"

export class RemoveModal extends Component {
  render() {
    const { show, handleHide, status, handleSubmit, isSubmitting } = this.props

    return (
      <Modal
        visible={show}
        onCancel={handleHide}
        title="Remove account"
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ]}
      >
        <Form data-cy="remove-modal-form">
          {status &&
            Object.values(status.meta.errors).map((error, i) => (
              <FormError key={i} style={{ margin: "0 0 10px 0" }} message={error} />
            ))}

          <RemoveReason />
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => ({
  invitation: createSelector(
    (state) => state.entities,
    () => props.invitation_id,
    Invitation.selectOne
  )(state, props)
})

const mapDispatchToProps = {
  updateStatus: InvitationManage.updateStatus
}

export default connectModal({
  name: "RemoveModal",
  destroyOnHide: true
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withFormik({
      mapPropsToValues: ({ invitation }) => invitation.ref,
      enableReinitialize: true,

      handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        const { updateStatus, invitation, setStatus, from, to, handleHide } = props

        updateStatus(invitation.id, from, to, values)
          .then(() => {
            setSubmitting(false)
            handleHide()
            message.success("Invitation updated!")
          })
          .catch((err) => {
            setStatus(err.response.data)
          })
      }
    })(RemoveModal)
  )
)
