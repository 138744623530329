// @ts-nocheck
import React, { useEffect } from "react";
import { AdAuthorise } from ".";
import { useLocation, useHistory } from "react-router";
import qs from "qs";
import { useSetAuthCodeToCampaignCompanyMutation } from "src/generated/ApolloComponents";

interface Props {}

export const AdAuthoriseContainer: React.FC<Props> = () => {
  const history: any = useHistory();
  const location: any = useLocation();

  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [
    setAuthCodeToCampaignCompanyMutation,
    { loading },
  ] = useSetAuthCodeToCampaignCompanyMutation();

  useEffect(() => {
    const authorise = async () => {
      if (parsed?.auth_code) {
        try {
          const res = await setAuthCodeToCampaignCompanyMutation({
            variables: {
              campaignId: parseInt(parsed?.state as any),
              authCode: parsed?.auth_code as any,
            },
          });
          if (res.data?.setAuthCodeToCampaignCompany?.id) {
            history.push(`campaigns/${parsed?.state}/ad-hub?success=1`);
          }
        } catch (e) {}
      } else {
        history.push(`campaigns/${parsed?.state}/ad-hub?success=2`);
      }
    };
    authorise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AdAuthorise isSubmitting={loading} globalErrors={[]} />;
};
