import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class PaypalEmail extends Component {
  render() {
    return (
      <FormField name={this.props.name || "paypal_email"} label="Paypal Email">
        <Input data-cy="agency-paypal-email" size="large" placeholder="e.g. joe@bloggs.com" />
      </FormField>
    )
  }
}
