// @ts-nocheck
import { string, object } from "yup";

export default object().shape({
  default_link: string()
    .nullable()
    .required("Please enter as URL"),
  integration_partner: string()
    .default(null)
    .nullable()
    .notRequired(),
  ios_id: string()
    .nullable()
    .test(
      "ios_id",
      "Please enter either an iOS or Android identifier",
      function(value) {
        const { integration_partner, android_id } = this.parent;
        if (integration_partner && !android_id) return value != null;
        return true;
      }
    ),
  android_id: string()
    .nullable()
    .test(
      "android_id",
      "Please enter either an iOS or Android identifier",
      function(value) {
        const { integration_partner, ios_id } = this.parent;
        if (integration_partner && !ios_id) return value != null;
        return true;
      }
    ),
});
