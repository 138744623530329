import React, { Component } from "react";
import FormField from "../FormField";
import { InputNumber, Radio } from "formik-antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import ComparisonRadio from "./ComparisonRadio";

export default class ClickThroughRate extends Component {
  render() {
    return (
      <Row gutter={8} justify="start">
        <Col span={12}>
          <Box mt="-10px">
            <FormField
              name={`avg_ctr.comparison`}
              style={{ marginBottom: "0px" }}
            >
              <ComparisonRadio>
                <Radio.Button value="lt">
                  <LeftOutlined />
                </Radio.Button>
                <Radio.Button value="gt">
                  <RightOutlined />
                </Radio.Button>
              </ComparisonRadio>
            </FormField>
          </Box>
        </Col>
        <Col span={12}>
          <Box mt="-10px">
            <FormField name={`avg_ctr.value`} style={{ marginBottom: "0px" }}>
              <InputNumber size="large" min={0} />
            </FormField>
          </Box>
        </Col>
      </Row>
    );
  }
}
