import React, { Component } from "react";
import FormField from "../FormField";
import CurrencyInput from "src/shared/CurrencyInput";

export default class Payout extends Component {
  render() {
    const { name, rest } = this.props;

    return (
      <FormField name={name || "payout_pennies"} label="Agreed Payout">
        <CurrencyInput data-cy="account-payout" {...rest} />
      </FormField>
    );
  }
}
