// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";

interface Props {
  isVATRegister: boolean;
}

const VatNumber: React.FC<Props> = ({ isVATRegister }) => {
  return (
    <Field label="VAT Number:" mb={26} disabled={!isVATRegister}>
      <FormikInput
        name="vatNumber"
        size="large"
        placeholder="e.g. GB 795796789"
        background="background"
        borderRadius={3}
        borderColor="grey5"
        disabled={!isVATRegister}
      />
    </Field>
  );
};

export default VatNumber;
