// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";
import { CampaignsTable } from ".";
import { useRouteMatch } from "react-router";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useSearchCampaignsByCampaignGroupIdQuery } from "src/generated/ApolloComponents";

type Props = {};

export const CampaignsTableContainer: React.FC<Props> = () => {
  const [showAddCampaigns, setShowAddCampaigns] = useState(false);
  const [showAddNewCampaign, setShowAddNewCampaign] = useState(false);

  const match: any = useRouteMatch();
  const params: any = match?.params;

  const [filters, setFilters] = useState({
    page: 1,
    groupId: parseInt(params?.id),
  });

  const {
    data,
    loading,
    error,
    refetch,
  } = useSearchCampaignsByCampaignGroupIdQuery({
    variables: filters,
  });

  const allCampaigns = data?.searchCampaignsByCampaignGroupId;

  const onFilterChange = async filters => {
    try {
      refetch(filters);
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onFilterChange, 1000)).current;

  useEffect(() => {
    let updatedFilters = { ...filters };

    debouncedFilterChange(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <CampaignsTable
      allCampaigns={allCampaigns}
      globalErrors={globalErrors}
      loading={loading}
      onFilter={(key, value) => {
        filters[key] = value;
        setFilters({ ...filters });
      }}
      filters={filters}
      showAddCampaigns={showAddCampaigns}
      setShowAddCampaigns={value => setShowAddCampaigns(value)}
      showAddNewCampaign={showAddNewCampaign}
      setShowAddNewCampaign={value => setShowAddNewCampaign(value)}
      refetchCampaign={refetch}
    />
  );
};
