// @ts-nocheck
import React, { useRef } from "react";
import { ChartCard } from "ant-design-pro/lib/Charts";
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Table, Input, Button } from "antd";
import numeral from "numeral";
import formatCountries from "src/utils/formatCountries";

type Props = {
  data: any;
};

const LocationsTable: React.FC<Props> = ({ data }) => {
  const searchInput = useRef(null) as any;

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
  });

  if (!data?.clicks || data?.clicks === 0) return null;

  const countryColumns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      ...getColumnSearchProps("country"),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      sorter: (a, b) => b.clicks - a.clicks,
      sortOrder: "desc",
      render: value => numeral(value).format("0,0"),
    },
  ];

  const cityColumns = [
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ...getColumnSearchProps("city"),
    },
    {
      title: "Click Percentage",
      dataIndex: "percent",
      key: "percent",
      sorter: (a, b) => b.percent - a.percent,
      sortOrder: "desc",
      render: value => numeral(value).format("0,0[.]00") + "%",
    },
  ];

  const cityData = data?.cities.map((city, index) => ({
    key: index,
    city: city[0],
    percent: city[1],
  }));

  const countriesData = data?.countries.map((country, index) => ({
    key: index,
    country: formatCountries(country[0]) || "",
    clicks: country[1],
  }));

  return (
    <Row>
      <Col span={24} style={{ marginTop: 24 }}>
        <ChartCard
          title="Clicks by location"
          action={
            <Tooltip
              placement="left"
              title="Click breakdown by country and city"
            >
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ padding: 20 }}
        >
          <Row gutter={14} style={{ marginTop: 24 }}>
            <Col span={12}>
              <Table
                pagination={{ pageSize: 5 }}
                columns={countryColumns as any}
                dataSource={countriesData}
              />
            </Col>
            <Col span={12}>
              <Table
                pagination={{ pageSize: 5 }}
                columns={cityColumns as any}
                dataSource={cityData}
              />
            </Col>
          </Row>
        </ChartCard>
      </Col>
    </Row>
  );
};

export default LocationsTable;
