// @ts-nocheck
import React from "react";
import { AdAuthoriseContainer } from "src/components/Modules/Campaign/AdHub/AdAuthorise";

interface Props {}

const AdAuthorise: React.FC<Props> = () => {
  return <AdAuthoriseContainer />;
};

export default AdAuthorise;
