// @ts-nocheck
import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { TextInput } from "src/components/Forms/TextInput";
import { FilterProps } from "../index";

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const Filters: React.FC<Props> = ({ handleChange, filters }) => {
  return (
    <TextInput
      size="middle"
      background="background"
      borderRadius={3}
      borderColor="grey5"
      onChange={e => {
        handleChange("searchInput", e.target.value);
      }}
      value={filters?.searchInput}
      placeholder="Search influencer campaigns..."
      suffix={<SearchOutlined />}
    />
  );
};

export default Filters;
