import React, { Component } from "react";
import styled from "styled-components";
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Tag } from "antd";

const ClickTag = styled(Tag)`
  &&& {
    cursor: pointer;
  }
`;

export default class ConfirmDeleteIcon extends Component {
  render() {
    const { text, title, as, ...rest } = this.props;
    return (
      <Popconfirm
        data-cy="status-container"
        title={title ? title : "Are you sure you want to delete this?"}
        trigger="hover"
        okText="Yes"
        cancelText="No"
        onConfirm={e => e.preventDefault()}
        onClick={e => e.preventDefault()}
        onCancel={e => e.preventDefault()}
        {...rest}
      >
        <ClickTag as={as} color="volcano" data-cy="delete-icon">
          {text} <DeleteOutlined />
        </ClickTag>
      </Popconfirm>
    );
  }
}
