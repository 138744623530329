import axiosInstance from "../axiosInstance";

const CREATE_ACCOUNT = `/accounts`;
const GET_ACCOUNTS = `/accounts`;
const getAccount = id => `/accounts/${id}`;
const get_branded_content = id => `/accounts/${id}/branded_content`;
const get_organic_content = id => `/accounts/${id}/organic_content`;
const deleteAccount = id => `/accounts/${id}`;
const GET_ACCOUNTS_TOTAL = `/accounts/totals`;

export const index = data => axiosInstance.get(GET_ACCOUNTS, data);

export const create = data => axiosInstance.post(CREATE_ACCOUNT, data);

export const get = (id, data) => axiosInstance.get(getAccount(id), data);

export const destroy = id => axiosInstance.delete(deleteAccount(id));

export const update = (id, data) => axiosInstance.patch(getAccount(id), data);

export const getBrandedContent = (id, data) =>
  axiosInstance.get(get_branded_content(id), data);

export const getOrganicContent = (id, data) =>
  axiosInstance.get(get_organic_content(id), data);

export const getAccountTotal = data => axiosInstance.get(GET_ACCOUNTS_TOTAL, data);