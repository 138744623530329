import React, { Component } from "react"
import { withFormik } from "formik"
import { Modal, Button } from "antd"
import { Form, Input } from "formik-antd"
import FormField from "src/shared/FormField"

class InviteUserForm extends Component {
  render() {
    const { onCancel, loading, handleSubmit } = this.props
    return (
      <Modal
        title="Invite new team member"
        visible={true}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Invite
          </Button>
        ]}
        {...this.props}
      >
        <Form data-cy="team-form">
          <FormField name="name" label="Name">
            <Input size="large" data-cy="team-name" placeholder="e.g. Joe Bloggs" />
          </FormField>
          <FormField name="email" label="Email">
            <Input size="large" data-cy="team-email" placeholder="e.g. joe@company.com" />
          </FormField>
        </Form>
      </Modal>
    )
  }
}

export default withFormik({
  validateOnBlur: false,

  mapPropsToValues: () => ({ email: "", name: "" }),

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props
      .onOk(values)
      .then(() => setSubmitting(false))
      .catch((res) => {
        setSubmitting(false)
        setErrors(res.response.data.meta.errors)
      })
  },

  displayName: "InviteTeamMemberForm"
})(InviteUserForm)
