import React, { Component } from "react";
import { connect } from "react-redux";
import { Radio, Row, Col } from "antd";
import Table from "src/shared/Table";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import Filters from "./Filters";
import { default as $TableManager } from "src/ducks/TableManager";
import AccountTotal from "src/ducks/AccountTotal";
import withWindowResize from "src/utils/hoc/withWindowResize";

const AccountFilters = $TableManager("AdminAccountTotalList");
const modules = [AccountTotal.module(), AccountFilters.module()];

export class AccountsTotalList extends Component {
  state = {
    filter: "count",
    isLoad: false,
  };

  loadAccounts = () => {
    const { filters } = this.props;
    const { isLoad } = this.state;
    if (!isLoad) {
      this.props.addFilter({ format: ["Human"] });
    }
    this.setState({ isLoad: true });
    return this.props.loadAccounts({
      ...filters,
    });
  };

  handleFilterChange = e => {
    const { filters } = this.props;
    this.setState({ filter: e.target.value });
    return this.props.loadAccounts({
      ...filters,
      filter: e.target.value,
    });
  };

  render() {
    const { pagination, accounts, loading, height } = this.props;

    const { state } = this;

    const columns = [
      {
        title: "Tag",
        dataIndex: "particular",
        visible: true,
        sorter: true,
        width: 150,
      },
      {
        title: "Not Verified",
        dataIndex: "not_verified",
        key: "not_verified",
        visible: true,
        sorter: true,
        width: 150,
      },
      {
        title: "Semi-Verified",
        dataIndex: "semi_verified",
        key: "semi_verified",
        visible: true,
        sorter: true,
        width: 150,
      },
      {
        title: "Fully Verified",
        dataIndex: "fully_verified",
        key: "fully_verified",
        visible: true,
        sorter: true,
        width: 150,
      },
      {
        title: "Total",
        dataIndex: "subtotal",
        key: "subtotal",
        visible: true,
        sorter: true,
        width: 150,
      },
    ];

    return (
      <Table
        title="Vertical Analysis"
        name="AdminAccountTotalList"
        pagination={pagination}
        loadData={this.loadAccounts}
        columns={columns}
        data={accounts}
        filterOptions={Filters}
        loading={loading}
        rowKey="particular"
        extra={[
          <Radio.Group
            key="filtertype"
            filter="count"
            value={state.filter}
            onChange={this.handleFilterChange}
          >
            <Radio.Button key="filtercount" value="count">
              Show Count
            </Radio.Button>
            <Radio.Button key="filterview" value="views">
              Show View
            </Radio.Button>
          </Radio.Group>,
        ]}
        footer={() => {
          return (
            <Row>
              <Col span={7}>
                <strong>Total</strong>
              </Col>
              <Col span={5}>
                {pagination.subtotals && pagination.subtotals.not_verified}
              </Col>
              <Col span={5}>
                {pagination.subtotals && pagination.subtotals.semi_verified}
              </Col>
              <Col span={5}>
                {pagination.subtotals && pagination.subtotals.fully_verified}
              </Col>
            </Row>
          );
        }}
        scroll={{ y: height, x: "max-content" }}
      />
    );
  }
}

const mapStateToProps = state => ({
  filters: AccountFilters.selectFilters(state),
  accounts: AccountTotal.selectMany(state),
  loading: AccountTotal.selectPending(state),
  pagination: AccountTotal.selectPagination(state),
});

const mapDispatchToProps = {
  loadAccounts: AccountTotal.loadMany,
  addFilter: AccountFilters.addFilter,
};

const MainAccountsTotalList = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsTotalList))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainAccountsTotalList);
