// @ts-nocheck
import React, { useState } from "react";
import { Row, Col } from "antd";
import AccountList from "./AccountList";
import AccountDetail from "./AccountDetail";

interface Props {
  accounts: Array<any>;
  breakdownLastUpdateDate: any;
  rationale?: string;
  accountId?: number;
  grid?:any; // Organic Content Embeded Tiktok Video Grid
}

const InfluencerProfile: React.FC<Props> = ({
  accounts,
  breakdownLastUpdateDate,
  rationale,
  accountId,
  grid
}) => {
  const [index, setIndex] = useState(0);

  return (
    <Row gutter={32}>
      <Col span={8}>
        <AccountList
          accounts={accounts}
          index={index}
          setIndex={value => setIndex(value)}
          accountId={accountId}
        />
      </Col>
      <Col span={16}>
        <AccountDetail
          account={accounts[index]}
          breakdownLastUpdateDate={breakdownLastUpdateDate}
          rationale={rationale}
          accountId={accountId}
          grid={grid}
        />
      </Col>
    </Row>
  );
};

export default InfluencerProfile;
