// @ts-nocheck
import React from "react";
import { Card } from "antd";

interface Props {}

const videoStyle = {
  width: "100%",
  objectFit: "cover",
};

const PreviewVideo: React.FC<Props> = () => {
  return (
    <Card
      cover={
        <video
          style={videoStyle as React.CSSProperties}
          src={
            "https://storage.googleapis.com/artifacts.fanbytes.appspot.com/example_videos/youtube/Draft_url_compressed.mov"
          }
          controls
        />
      }
    />
  );
};

export default PreviewVideo;
