// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Input, Row, Col } from "antd";
import { Tag } from "src/components/Core/Tag";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import { FormikAutoComplete } from "src/components/Forms/AutoComplete";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";

interface Props {
  formProps?: FormikProps<any>;
  allTags: Array<any>;
}

const AccountTags: React.FC<Props> = ({ formProps, allTags }) => {
  const handleChange = value => {
    let newValue = value.trim();

    if (newValue !== "") {
      const index = formProps?.values["tags"].indexOf(newValue);

      if (index === -1) {
        let item: any = find(allTags, function(obj) {
          return (obj as any)?.name.replace(/\s+/g, "").toLowerCase() ===
          newValue.replace(/\s+/g, "").toLowerCase();
        });
        let container = {} as any;

        if (item) {
          container = {
            id: item.id,
            name: item.name,
          };
        } else {
          container = {
            name: newValue,
          };
        }
        formProps?.values["tags"].push(container);
        formProps?.setFieldValue("tags", formProps?.values["tags"]);
        formProps?.setFieldValue("tagName", "");
      }
    } else {
      formProps?.setFieldError("tags", `Please enter valid value`);
    }
  };

  const deleteItem = index => {
    formProps?.values["tags"].splice(index, 1);
    formProps?.setFieldValue("tags", formProps?.values["tags"]);
  };

  let tagData = (allTags || []).map((item: any, index) => {
    const newItem = { ...item };
    return newItem.name;
  });

  tagData = tagData.filter((item: any, index) => {
    if (!find(formProps?.values["tags"], matchesProperty("name", item))) {
      return true;
    }
    return false;
  });

  return (
    <Box mt="5px">
      <Row>
        <Col span={24}>
          <Box mt={10} display="inline-flex" style={{ flexWrap: "wrap" }}>
            {formProps?.values?.tags.map((tag, index) => {
              return (
                <Tag
                  size={12}
                  tagColor="cyan7"
                  background="cyan1"
                  borderColor="cyan3"
                  key={index}
                  marginBottom="6px"
                >
                  {tag?.name}
                  {formProps?.values?.isEditable && (
                    <CloseOutlined
                      onClick={() => {
                        deleteItem(index);
                      }} />
                  )}
                </Tag>
              );
            })}

            {formProps?.values?.isEditable && (
              <Box width={180}>
                <FormikAutoComplete
                  name="tagName"
                  size="small"
                  borderRadius={3}
                  borderColor="grey5"
                  dataSource={tagData}
                  filterOption={(inputValue, option) => {
                    if (option.props.children) {
                      return (
                        (option.props.children as string)
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      );
                    }
                    return false;
                  }}
                  onSelect={value => {
                    handleChange(value);
                  }}
                >
                  <Input
                    size="small"
                    style={{ minWidth: 500 }}
                    onKeyPress={e => {
                      if (e.which === 13) {
                        e.preventDefault();
                        handleChange(formProps?.values["tagName"]);
                      }
                    }}
                    value={formProps?.values["tagName"]}
                    placeholder="Enter Tag"
                  />
                </FormikAutoComplete>
              </Box>
            )}
          </Box>
        </Col>
      </Row>
    </Box>
  );
};

export default AccountTags;
