import React, { Component } from "react";
import { Row, Col, Descriptions } from "antd";
import { Link } from "react-router-dom";

export default class InvitationDetails extends Component {
  render() {
    const { invitation } = this.props;

    return (
      <Row gutter={24}>
        <Col span={12}>
          <Descriptions title="Meta Data" size="small" bordered column={1}>
            <Descriptions.Item label="Campaign">
              <Link to={`/campaigns/${invitation.campaign.id}`}>
                {invitation.campaign.title}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Account">
              {invitation.account.username}
            </Descriptions.Item>
            <Descriptions.Item label="Estimated Views">
              {invitation.formattedEstimatedViews()}
            </Descriptions.Item>
            <Descriptions.Item label="Payout Type">
              {invitation.formattedPayoutType()}
            </Descriptions.Item>
            <Descriptions.Item label="CPM">
              {invitation.formattedCpm()}
            </Descriptions.Item>
            <Descriptions.Item label="Estimated Payout">
              {invitation.formattedPayoutTypePennies()}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Descriptions title="Statistics" size="small" bordered column={1}>
            <Descriptions.Item label="Posted At">
              {invitation.formattedPostedAt()}
            </Descriptions.Item>
            <Descriptions.Item label="Views">
              {invitation.formattedViews()}
            </Descriptions.Item>
            <Descriptions.Item label="Installs">
              {invitation.formattedInstalls()}
            </Descriptions.Item>
            <Descriptions.Item label="Clicks">
              {invitation.formattedClicks()}
            </Descriptions.Item>
            <Descriptions.Item label="Payout">
              {invitation.formattedPayout()}
            </Descriptions.Item>
            <Descriptions.Item label="Click through rate">
              {invitation.formattedCtr()}
            </Descriptions.Item>
            <Descriptions.Item label="Cost per click">
              {invitation.formattedCpc()}
            </Descriptions.Item>
            <Descriptions.Item label="Cost per install">
              {invitation.formattedCpi()}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
}
