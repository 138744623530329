// @ts-nocheck
import React, { useState, useEffect } from "react";
import { ThankYou } from ".";
import { useRateCampaignHelpfulnessMutation } from "src/generated/ApolloComponents";

interface Props {
  campaignId: number;
}

export const ThankYouContainer: React.FC<Props> = ({ campaignId }) => {
  const [helpfulnessRating, setHelpfulnessRating] = useState(0);

  const [
    rateCampaignHelpfulnessMutation,
  ] = useRateCampaignHelpfulnessMutation();

  useEffect(() => {
    if (Number(localStorage.getItem("helpfulnessRating"))) {
      setHelpfulnessRating(Number(localStorage.getItem("helpfulnessRating")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRating = async value => {
    try {
      const res = await rateCampaignHelpfulnessMutation({
        variables: {
          helpfulnessRating: value,
          campaignId: campaignId,
        },
      });
      if (res.data?.rateCampaignHelpfulness?.id) {
        localStorage.setItem("helpfulnessRating", value);
        setHelpfulnessRating(value);
      }
    } catch (e) {}
  };

  return (
    <ThankYou
      onRating={value => onRating(value)}
      helpfulnessRating={helpfulnessRating}
    />
  );
};
