// @ts-nocheck
import React, { useRef } from "react";
import { useScreenClass } from "react-grid-system";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { ThankYouContainer } from "./container";
import { Rate } from "antd";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import FadeInSection from "../../FadeInSection";

interface Props {
  onRating: (value: any) => void;
  helpfulnessRating: number;
}

const ThankYou: React.FC<Props> = ({ onRating, helpfulnessRating }) => {
  const screenClass = useScreenClass();

  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};
  let props = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  return (
    <FadeInSection>
      <Box
        textAlign="center"
        mt={250}
        mb={200}
        display="block"
        position="relative"
        ref={myRef as any}
      >
        <VelocityComponent
          key="slideUp"
          animation={
            enterCount <= 1 && inViewport
              ? "transition.slideUpIn"
              : "transition.fadeIn"
          }
          delay={50}
        >
          <Typography
            fontSize={screenClass === "xs" || screenClass === "sm" ? 44 : 75}
            fontWeight={600}
            color="white"
            lineHeight={0}
          >
            Thank You!
          </Typography>
        </VelocityComponent>
        <VelocityComponent
          key="slideUp1"
          animation={
            enterCount <= 1 && inViewport
              ? "transition.slideUpIn"
              : "transition.fadeIn"
          }
          delay={70}
        >
          <Typography
            fontSize={screenClass === "xs" || screenClass === "sm" ? 18 : 24}
            color="white"
          >
            How helpful was this?
          </Typography>
        </VelocityComponent>

        <Box display="inline-flex" alignItems="center">
          <VelocityComponent
            key="slideLeft"
            animation={
              enterCount <= 1 && inViewport
                ? "transition.slideLeftIn"
                : "transition.fadeIn"
            }
            delay={80}
          >
            <Typography
              fontSize={screenClass === "xs" || screenClass === "sm" ? 10 : 16}
              color="white"
              mr={20}
              mb={0}
            >
              Not helpful
            </Typography>
          </VelocityComponent>
          <Rate
            allowHalf
            defaultValue={helpfulnessRating}
            style={{
              fontSize:
                screenClass === "sm" || screenClass === "xs" ? "17px" : "40px",
            }}
            disabled={helpfulnessRating ? true : false}
            value={helpfulnessRating}
            onChange={value => onRating(value)}
          />
          <VelocityComponent
            key="slideRight"
            animation={
              enterCount <= 1 && inViewport
                ? "transition.slideRightIn"
                : "transition.fadeIn"
            }
            delay={80}
          >
            <Typography
              fontSize={screenClass === "xs" || screenClass === "sm" ? 10 : 16}
              color="white"
              ml={20}
              mb={0}
            >
              Very helpful
            </Typography>
          </VelocityComponent>
        </Box>
      </Box>
    </FadeInSection>
  );
};

export { ThankYou, ThankYouContainer };
