// @ts-nocheck
import React, { useState } from "react";
import { InfluencerCard } from ".";
import { useInfluencerDetails } from "../InfluencerProfileProvider";

interface Props {}

export const InfluencerCardContainer: React.FC<Props> = () => {
  const [showInfluencerForm, setShowInfluencerForm] = useState(false);
  const { influencerDetails, isLoading } = useInfluencerDetails();

  return (
    <InfluencerCard
      loading={isLoading}
      showInfluencerForm={showInfluencerForm}
      setShowInfluencerForm={value => setShowInfluencerForm(value)}
      influencerDetails={influencerDetails}
    />
  );
};
