import { string, number, object, array, boolean } from "yup";

export default object().shape({
  title: string().nullable(),
  company: number()
    .nullable()
    .required("Please select a company"),
  notified_brand_ids: array(),
  objective: string().nullable(),
  ad_format: string().required("You need to select an ad format"),
  distribution_platforms: array().required("Please select a platform"),
  budget_pennies: number().nullable(),
  start_date: string().nullable(),
  ages: array(),
  genders: array(),
  locations: array(),
  tags: array(),
  link_tracked: boolean(),
  hubspot_deal_id: string().required("Must have valid Hubspot ID").nullable(),

  default_link: string()
    .nullable()
    .when("link_tracked", {
      is: true,
      then: string()
        .required("Please enter as URL")
        .url("Must be a valid URL (please include http://)"),
    }),
  integration_partner: string()
    .default(null)
    .nullable()
    .notRequired(),
  ios_id: string().nullable(),
  android_id: string().nullable(),

  invite_limit_type: boolean(),

  influencer_invite_limit: number().when("invite_limit_type", {
    is: true,
    then: number()
      .nullable()
      .required("Please enter invite limit"),
    otherwise: number().nullable(),
  }),

  influencer_spend_pennies: number().when("invite_limit_type", {
    is: false,
    then: number()
      .nullable()
      .required("You need to enter an invite spend budget"),
    otherwise: number().nullable(),
  }),
  is_public: boolean(),
  public_deadline: string().when("is_public", {
    is: true,
    then: string().required("You need to enter a public deadline"),
    otherwise: string().nullable()
  })
});
