import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { connectModal } from "redux-modal";
import withModules from "src/utils/hoc/withModules";
import Loading from "src/shared/Loading";
import { DraftList } from "src/ducks/DataList";
import { DraftPersist, InvitationPersist, WorkerPersist } from "src/ducks/Persist";
import AccountDetails from "src/shared/AccountDetails";
import InvitationForm from "./InvitationForm";
import AnalyticsForm from "./AnalyticsForm";
import InvitationNote from "./InvitationNote";
import PayInfluencer from "./PayInfluencer";
import ManageInvitation from "./ManageInvitation";
import DraftsCollapse from "./DraftsCollapse";
import InfluencerContact from "src/shared/InfluencerContact";
import { Steps, Tabs, Row, Tag, message } from "antd";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import InfluencerData from "./InfluencerData";
import {
  useUdpateInvitationTitleMutation,
  useDuplicateInvitationMutation
} from "src/generated/ApolloComponents";
import { InviteModal, StyledHeader } from './styles'
import Title from './Components/Title'
import { DuplicateInvitation } from './Components/DuplicateInvitation'
const { Step } = Steps;
const { TabPane } = Tabs;

const InvitationManage = InvitationPersist("InvitationModal");
const Drafts = DraftList("InvitationModal");
const DraftManage = DraftPersist("InvitationModal");
const WorkerManage = WorkerPersist("InvitationModal")

const modules = [
  InvitationManage.module(),
  Drafts.module(),
  DraftManage.module(),
  WorkerManage.module(),
];

export const InvitationModal = ({invitation, ...props}) => {
  const [activeKey, setActiveKey] = useState("1")
  const [importing, setImporting] = useState(false)
  const [
    udpateInvitationTitleMutation,
    updateInvitationTitle
  ] = useUdpateInvitationTitleMutation()

  const [
    duplicateInvitationMutation,
    duplicateInvitation
  ] = useDuplicateInvitationMutation()

  useEffect(() => {
    fetchInvitations();
    // eslint-disable-next-line
  }, [])

  const fetchInvitations = () => {
    const { loadInvitation, invitation_id } = props;
    return loadInvitation(invitation_id, {
      params: {
        get_metrics: true,
        expand: ["account", "account.influencer", "campaign"],
      },
    });
  };

  const deleteInvitation = () => {
    const { destroyInvitation, handleHide } = props;
    destroyInvitation(invitation.id)
      .then(handleHide)
      .catch(() => message.error("Could not delete invitation"));
  };

  const refreshAnalyticsData = () => {
    setImporting(true)
    const { invitation_id, importTiktokVideo } = props
    importTiktokVideo("post", "import_tiktok_video", {invitation_id: invitation_id})
      .then(res => fetchInvitations())
      .catch(err => console.log(err))
      .finally(() => setImporting(false))
  }

  const updateTitle = (value) => {
    if(value === invitation.title)
      return
    const { invitation_id } = props
    udpateInvitationTitleMutation({
      variables: {invitationId: invitation_id, title: value}
    }).then(res => fetchInvitations())
      .catch(() => message.error("Could not update the title"))
  }

  const handleDuplicateInvitation = () => {
    const { invitation_id } = props
    duplicateInvitationMutation({variables: {invitationId: invitation_id}})
      .then(res => message.success('Invitation duplicated successfully') && onUpdate())
      .catch(() => message.error("Could not duplicate invitation"))
  }

  const {
    show,
    handleHide,
    loading,
    onUpdate,
    updateInvitation,
  } = props;

  const title = <Title updateTitle={updateTitle} invitation={invitation} duplicateInvitation={duplicateInvitation} />
  const duplicateButton = <DuplicateInvitation updateTitle={updateInvitationTitle} handleDuplicate={handleDuplicateInvitation} />

  let currentStep = invitation
    ? ["drafing", "redrafting", "under-review", "approved", "posted"].indexOf(
        invitation.stage
      )
    : 0;

  if (invitation && invitation.stage === "admin-review") {
    currentStep = 2;
  }

  return (
    <InviteModal
      title={
        !loading && (
          <StyledHeader
            title={title}
            extra={
              invitation != null &&
              typeof invitation.can_be_deleted != "undefined" &&
              invitation.can_be_deleted
                ? [
                    duplicateButton,
                    '\u00A0',
                    <ConfirmDeleteIcon
                      text="Delete"
                      trigger="click"
                      key="delete-icon"
                      onConfirm={deleteInvitation}
                      placement="bottomRight"
                    />,
                  ]
                : [duplicateButton]
            }
          />
        )
      }
      visible={show}
      footer={null}
      width={860}
      onCancel={handleHide}
      maskClosable={false}
      {...props}
    >
      {invitation && !loading ? (
        <>
          <Steps current={currentStep}>
            <Step title="Drafting" />
            <Step title="Redrafting" />
            <Step title="In Review" />
            <Step title="Approved" />
            <Step title="Posted" />
          </Steps>

          <Tabs
            style={{ marginTop: 24 }}
            onChange={activeKey => setActiveKey(activeKey)}
            activeKey={activeKey}
          >
            <TabPane tab="Manage" key="1">
              <ManageInvitation fetchInvitations={fetchInvitations} />
            </TabPane>

            <TabPane tab="Draft" key="2">
              <DraftsCollapse
                invitation={invitation}
                fetchInvitations={fetchInvitations}
              />
            </TabPane>

            <TabPane tab="Data" key="10">
              <InfluencerData
                invitation={invitation}
                fetchInvitations={fetchInvitations}
              />
            </TabPane>

            <TabPane tab="Account" key="7">
              <AccountDetails account={invitation.account} />
            </TabPane>
            <TabPane tab="Influencer" key="6">
              <InfluencerContact influencer={invitation.account.influencer} />
            </TabPane>
            {/* <TabPane tab="Submit Draft" key="5">
              <SubmitDraft
                onDraftUpload={() => this.setState({ activeKey: "1" })}
              />
            </TabPane> */}
            <TabPane tab="Update" key="3">
              <InvitationForm
                invitation={invitation}
                onUpdate={onUpdate}
                updateInvitation={updateInvitation}
              />
            </TabPane>
            <TabPane
              tab={
                <Row align="middle">
                  <span>Pay</span>
                  {invitation.failed_payment && (
                    <Tag color="red" style={{ marginLeft: 10 }}>
                      Failed
                    </Tag>
                  )}

                  {!invitation.failed_payment &&
                    !!invitation.payout_pennies &&
                    invitation.payout_pennies > 0 && (
                      <Tag color="green" style={{ marginLeft: 10 }}>
                        Paid
                      </Tag>
                    )}
                </Row>
              }
              key="4"
            >
              <PayInfluencer onUpdate={onUpdate} />
            </TabPane>
            <TabPane tab="Analytics" key="8">
              <AnalyticsForm
                invitation={invitation}
                onUpdate={onUpdate}
                loading={importing}
                refreshData={refreshAnalyticsData}
                updateInvitation={updateInvitation}
              />
            </TabPane>
            <TabPane tab="Note" key="9">
              <InvitationNote
                invitation={invitation}
                onUpdate={onUpdate}
                updateInvitation={updateInvitation}
              />
            </TabPane>
          </Tabs>
        </>
      ) : (
        <Loading />
      )}
    </InviteModal>
  );
}

const mapStateToProps = state => ({
  invitation: InvitationManage.selectOne(state),
  loading: InvitationManage.selectGetPending(state),
});

const mapDispatchToProps = {
  loadInvitation: InvitationManage.get,
  updateInvitation: InvitationManage.update,
  destroyInvitation: InvitationManage.destroy,
  importTiktokVideo: WorkerManage.customMethod,
};

export default withModules(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    connectModal({
      name: "InvitationModal",
      destroyOnHide: true,
    })(InvitationModal)
  )
)(modules);
