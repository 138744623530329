// @ts-nocheck
import React from "react";
import { Avatar } from "antd";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { AnalyseFormContainer } from "../../Analyse/AnalyseForm";

interface Props {
  step: number;
  analyseData: any;
}

const SidebarContent: React.FC<Props> = ({ step, analyseData }) => {
  return (
    <Box>
      {analyseData?.username && (
        <Box textAlign="center" mt={90}>
          <Avatar size={81} src={analyseData?.avatarUrl} />
          <Typography
            size={24}
            textAlign="center"
            color="black"
            lineHeight="10px"
            mt={20}
          >
            @{analyseData?.username}
          </Typography>
        </Box>
      )}

      {analyseData?.bio && (
        <Box ml={20} mr={20} mt={30}>
          <Typography size={18} color="grey7" lineHeight={0}>
            Bio:
          </Typography>

          <Typography size={14} color="black">
            {analyseData?.bio}
          </Typography>
        </Box>
      )}

      {analyseData?.hygineRating && (
        <Box ml={20} mr={20} mt={30}>
          <Typography size={18} color="grey7" lineHeight={0}>
            Hygiene rating:
          </Typography>

          <Typography size={24} color="black">
            {analyseData?.hygineRating}/100
          </Typography>
        </Box>
      )}

      <AnalyseFormContainer step={step} />

      <Box bottom={0} position="absolute" textAlign="center" width="100%">
        <Typography size={14} textAlign="center" color="grey7">
          Powered by Fanbytes
        </Typography>
      </Box>
    </Box>
  );
};

export default SidebarContent;
