import React, { Component } from "react";
import { AlignLeftOutlined, ContainerOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { InfluencerPersist } from "src/ducks/Persist";

const ShowInfluencer = InfluencerPersist("AdminInfluencerShow");

export class SideMenu extends Component {
  render() {
    const { influencer, location } = this.props;

    const influencerPath = `/influencers/${influencer.id}`;

    return (
      <Menu
        mode="vertical"
        selectedKeys={[location.pathname]}
        style={{ height: "100%" }}
      >
        <Menu.Item key={influencerPath}>
          <Link data-cy="influencer-details" to={influencerPath} replace>
            <AlignLeftOutlined />
            <span>Influencer Details</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={influencerPath + "/profile"}>
          <Link data-cy="influencer-profile" to={influencerPath + "/profile"} replace>
            <UserOutlined />
            <span>Influencer Profile</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={influencerPath + "/update"}>
          <Link
            data-cy="influencer-update"
            to={influencerPath + "/update"}
            replace
          >
            <EditOutlined />
            <span>Update Influencer</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={influencerPath + "/payouts"}>
          <Link
            data-cy="influencer-payouts"
            to={influencerPath + "/payouts"}
            replace
          >
            <ContainerOutlined />
            <span>Payouts</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  influencer: ShowInfluencer.selectOne(state)
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideMenu)
);
