// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { UpdatePaymentDetailFormContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { usePaymentDetails } from "../PaymentDetailsProvider";

interface UpdatePaymentDetailFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  setStep: (value: number) => void;
  initialValues: Values;
  isSubmitting: boolean;
  PaymentRequestSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
}

export const UpdatePaymentDetailFormWrapper = styled.div`
  width: 100%;
`;

const UpdatePaymentDetailForm: React.FC<UpdatePaymentDetailFormProps> = ({
  initialValues,
  isSubmitting,
  PaymentRequestSchema,
  onSubmit,
  globalErrors,
  setStep,
}) => {
  const { selectedInfluencer, selectedAgency } = usePaymentDetails();

  return (
    <UpdatePaymentDetailFormWrapper>
      <Loading spinning={isSubmitting}>
        <Box mb={40}>
          <Typography size={40} weight="semi-bold" color="black">
            Updated Details
          </Typography>
          <Typography size={20} color="black2">
            {/* prioritized selected agency, as influecer will be selected in manage invitation even if paying out to agency */}
            You have update some of {selectedAgency?.agencyName || selectedInfluencer?.name} ‘s payment
            details. Would you like to permanenty update the {selectedAgency?.agencyName ? 'agency' : 'influencers'} payment
            details?
          </Typography>
        </Box>

        <GlobalErrors errors={globalErrors} />
        <Form
          initialValues={initialValues}
          schema={PaymentRequestSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <Box
                alignItems="right"
                textAlign="right"
                display="inherit"
                mt={40}
              >
                <Button
                  onClick={() => setStep(3)}
                  mr={50}
                  type="ghost"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  buttonSize="middle"
                  data-cy="dont-save-button"
                >
                  <Typography size={14} color="grey7" pt={10}>
                    Don't save
                  </Typography>
                </Button>

                <Button
                  htmlType="submit"
                  type="action"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  buttonSize="middle"
                  data-cy="update-payment-button"
                >
                  Save details
                </Button>
              </Box>
            );
          }}
        </Form>
      </Loading>
    </UpdatePaymentDetailFormWrapper>
  );
};

export { UpdatePaymentDetailForm, UpdatePaymentDetailFormContainer };
