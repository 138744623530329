// @ts-nocheck
import React, { ReactNode } from "react"
import DashboardLayout from "src/scenes/layouts/DashboardLayout"
import { Box } from "src/components/Core/Box"
import { GiftingSteps, GiftingStepOptions } from "../NewGiftingCampaign/GiftingSteps"
import { GiftingCampaign } from "src/generated/ApolloComponents"

interface Props {
  campaign?: Partial<GiftingCampaign>
  setStep?: (number: number) => void
  step: GiftingStepOptions
  children: ReactNode
}

export const GiftingWizardWrapper = ({ campaign, setStep, children, step }: Props) => {
  return (
    <DashboardLayout title={campaign?.title || "Gifting Campaign"}>
      <Box display="flex" justifyContent="center">
        <Box width={950} mt={45} mb={45} display="flex">
          <Box width={300}>
            <GiftingSteps setStep={setStep} step={step} />
          </Box>

          {children}
        </Box>
      </Box>
    </DashboardLayout>
  )
}
