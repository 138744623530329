// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Table } from "src/components/Core/TableManager";
import { CampaignsTableContainer } from "./container";
import { Loading } from "src/components/Core/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import getColumns from "./Columns";
import withWindowResize from "src/utils/hoc/withWindowResize";
import { getApplyFilter } from "../utils";
import { FilterFormValues } from "../CampaignFilterModal/CampaignFilter/container";

interface Props {
  allCampaigns: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  filters: FilterFormValues;
  height?: number; // from withWindowResize HOC
  clearFilterKey: (key: string, index: number) => void;
  filterBackground: string;
  allTags: Array<any>;
}

const MainCampaignsTable: React.FC<Props> = ({
  allCampaigns,
  loading,
  globalErrors,
  onFilter,
  filters,
  height,
  clearFilterKey,
  filterBackground,
  allTags,
}) => {
  const appliedFilter = getApplyFilter(filters, clearFilterKey, allTags);

  const columnVisibility = {
    title: true,
    status: true,
    platforms: true,
    startDate: true,
    budgetPennies: true,
    costOfSales: true,
    completedPayments: true,
  };

  return (
    <>
      <GlobalErrors errors={globalErrors} />

      <Box
        display="flex"
        justifyContent="space-between"
        background={filterBackground}
      >
        <Box background="white" borderTopRightRadius="4px">
          {(appliedFilter || []).length > 0 && (
            <Box p="8px" ml={35}>
              {appliedFilter}
            </Box>
          )}
        </Box>

        <Typography size={12} color="black2" mt="10px" mr="10px">
          {allCampaigns?.totalEntries} results
        </Typography>
      </Box>
      <Box background="white" p={10} borderRadius="4px">
        <Loading spinning={loading}>
          <Table
            getColumns={getColumns}
            rowKey="id"
            dataSource={allCampaigns?.groupedCampaigns || []}
            stripe={true}
            data-cy="table-GroupCampaignTable"
            pagination={{
              position: "bottom",
              current: allCampaigns?.currentPage,
              pageSize: allCampaigns?.perPage,
              total: allCampaigns?.totalEntries,
            }}
            rowHover={true}
            scroll={{ y: height, x: "max-content" }}
            filters={filters}
            onFilter={onFilter}
            defaultColumnVisibilty={columnVisibility}
          />
        </Loading>
      </Box>
    </>
  );
};

const CampaignsTable = withWindowResize({ extraHeight: 260 })(
  MainCampaignsTable
);

export { CampaignsTable, CampaignsTableContainer };
