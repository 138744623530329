// @ts-nocheck
import React from "react";
import { Info, Description, NoteWrapper } from "./styles";

interface Props {}

const FeedbackNote: React.FC<Props> = () => {
  return (
    <NoteWrapper>
      <Info>What would you like the influencer to change?</Info>
      <Description type="secondary">
        This will go directly to the influencer so please be descriptive
      </Description>
    </NoteWrapper>
  );
};

export default FeedbackNote;
