// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch, useHistory } from "react-router";
import { PageHeader, Layout, message } from "antd";
import { Loading } from "src/components/Core/Loading";
import DashboardLayout from "src/scenes/layouts/DashboardLayout";
import SideMenu from "../SideMenu";
import CampaignGroupDetails from "../CampaignGroupDetails";
import { UpdateCampaignGroupFormContainer } from "../UpdateCampaignGroupForm";
import { useAppDetails } from "src/AppProvider";
import { useCampaignGroupDetails } from "../CampaignGroupProvider";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import { useDeleteCampaignGroupMutation } from "src/generated/ApolloComponents";

const { Content, Sider } = Layout;

const BorderHeader = styled(PageHeader)`
  &&& {
    border-bottom: 1px solid #e8e8e8;

    .ant-page-header-heading-extra {
      display: flex;
    }

    .ant-page-header-heading-extra .ant-tag {
      line-height: 30px;
      margin-left: 8px;
    }
  }
`;

const CampaignGroupLayout = () => {
  const { collapsed } = useAppDetails();
  const { campaignGroupDetails, isLoading } = useCampaignGroupDetails();

  const [deleteCampaignGroupMutation] = useDeleteCampaignGroupMutation();

  const match: any = useRouteMatch();
  const params: any = match?.params;

  const history: any = useHistory();

  let campaignGroupId = params?.id;

  const onDelete = async () => {
    try {
      const res = await deleteCampaignGroupMutation({
        variables: {
          groupId: parseInt(campaignGroupId),
        },
      });
      if (res.data?.deleteCampaignGroup) {
        history.goBack();
      }
    } catch (e) {
      message.error("Could not delete campaign group");
    }
  };

  let isAdmin = ((window as any)?.intercomSettings || {}).user_type;

  return (
    <Loading spinning={isLoading}>
      <DashboardLayout
        title={campaignGroupDetails?.groupName || "Campaign Group"}
      >
        <>
          <BorderHeader
            style={{
              top: "68px",
              width: `calc(100% - ${collapsed ? 80 : 200}px)`,
              overflow: "auto",
              position: "fixed",
              zIndex: 60,
            }}
            onBack={history.goBack}
            title={campaignGroupDetails?.groupName}
            extra={[
              isAdmin === "Admin" && (
                <ConfirmDeleteIcon
                  text="Delete"
                  title="Are you sure you want to delete this group? Campaigns inside this group will not be deleted"
                  key="delete-icon"
                  onConfirm={onDelete}
                  placement="bottomRight"
                />
              ),
            ]}
          />
          <Layout>
            <Sider
              width={200}
              style={{
                background: "#fff",
                overflow: "auto",
                height: "100vh",
                marginTop: 55,
                position: "fixed",
              }}
            >
              <SideMenu />
            </Sider>
            <Content
              style={{
                marginLeft: 200,
                marginTop: 58,
                height: "calc(100vh - 126px)",
              }}
            >
              <Switch>
                <Route
                  path={`${match.path}/update`}
                  exact
                  component={UpdateCampaignGroupFormContainer}
                />
                <Route
                  path={`${match.path}`}
                  exact
                  component={CampaignGroupDetails}
                />
              </Switch>
            </Content>
          </Layout>
        </>
      </DashboardLayout>
    </Loading>
  );
};

export default CampaignGroupLayout;
