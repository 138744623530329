import axiosInstance from "../axiosInstance";

const CREATE_SUGGESTION = `/suggestions`;
const GET_SUGGESTIONS = `/suggestions`;
const getSuggestion = id => `/suggestions/${id}`;
const updateSuggestion = id => `/suggestions/${id}`;
const deleteSuggestion = id => `/suggestions/${id}`;

export const create = data => axiosInstance.post(CREATE_SUGGESTION, data);

export const index = data => axiosInstance.get(GET_SUGGESTIONS, data);

export const get = (id, data) => axiosInstance.get(getSuggestion(id), data);

export const update = (id, data) =>
  axiosInstance.patch(updateSuggestion(id), data);

export const destroy = id => axiosInstance.delete(deleteSuggestion(id));
