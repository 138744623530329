// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { locations } from "src/constants/account";
import { FormikProps } from "formik";
import ClearButton from "./ClearButton";
import { Box } from "src/components/Core/Box";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Location: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let location = formProps?.values?.influencerLocation;
  let isVisible = false;
  let backgroundColor = "#FFFFFF";

  if (location !== "") {
    isVisible = true;
    backgroundColor = "#FAFAFA";
  }

  return (
    <Box background={backgroundColor} padding="5px">
      <Field
        label="Location:"
        mb={20}
        extra={
          <ClearButton
            isVisible={isVisible}
            buttonClick={() => {
              formProps?.setFieldValue("influencerLocation", "");
              clearFilterKey("influencerLocation");
            }}
          />
        }
      >
        <FormikSelect
          name="influencerLocation"
          data={locations}
          local
          borderRadius={3}
          borderColor="grey5"
          size="middle"
        />
      </Field>
    </Box>
  );
};

export default Location;
