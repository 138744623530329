import React, { Component } from "react";
import FormField from "../FormField";
import { Rate } from "formik-antd";

export default class ContentQuality extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "metadata.rating.content_quality"}
        label="Content Quality"
        help="How good was the content delivered"
      >
        <Rate data-cy="account-content-quiality" />
      </FormField>
    );
  }
}
