// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { types } from "src/constants/account";

interface Props {}

const Platform: React.FC<Props> = () => {
  return (
    <Field label="Platform:" mb={26}>
      <FormikSelect
        name="platform"
        size="middle"
        placeholder="Select..."
        data={types}
        local
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Platform;
