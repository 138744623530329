import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class YouTubeProfile extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "metadata.youtube_profile_url"}
        label="YouTube Profile Url"
      >
        <Input
          data-cy="account-youtube-url"
          size="large"
          placeholder="e.g. https://www.youtube.com/channel/UCoebwHSTvwalADTJhps0emA"
        />
      </FormField>
    )
  }
}
