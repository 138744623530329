// @ts-nocheck
import React from "react";
import { UploadDraft } from ".";

interface Props {
  name: string;

  setFieldValue: (value: any) => void;
  setFieldTouched: () => void;
  values: any;
}

export const UploadDraftContainer: React.FC<Props> = ({
  setFieldValue,
  setFieldTouched,
  values,
  name,
}) => {
  const handleChange = files => {
    const newFiles = values?.drafts?.concat(files) as any;

    setFieldValue(newFiles);
    setFieldTouched();
  };

  const handleRemove = file => {
    let valueCopy = values[name];
    const index = valueCopy.indexOf(file);

    if (index > -1) {
      valueCopy.splice(index, 1);
    } else {
      valueCopy[file.uid].delete = true;
      valueCopy[file.uid].status = "error";
    }

    setFieldValue(valueCopy);
  };

  return (
    <UploadDraft
      handleRemove={file => handleRemove(file)}
      handleChange={file => {
        handleChange(file);
      }}
      fileList={values?.drafts || []}
      name={name}
    />
  );
};
