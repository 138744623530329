// @ts-nocheck
import { Avatar } from "antd";
import styled from "styled-components";

export const Avatar1 = styled(Avatar)`
  && {
    position: absolute !important;

    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      left: 240px;
      top: -70px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      left: 200px;
      top: -135px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      left: 225px;
      top: -160px;
    }
  }
`;

export const Avatar2 = styled(Avatar)`
  && {
    position: absolute !important;

    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      left: 10px;
      top: -70px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      left: 45px;
      top: -140px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      left: 143px;
      top: -160px;
    }
  }
`;

export const Avatar3 = styled(Avatar)`
  && {
    position: absolute !important;

    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      right: -30px;
      top: 20px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      right: 24px;
      top: -120px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      right: 129px;
      top: -150px;
    }
  }
`;
