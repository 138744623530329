// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { FormikRatingInput } from "src/components/Forms/Rating";
import { RemoveIcon } from "../../../Wrapper";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const CreationTime: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (formProps?.values?.rating?.creationTime) {
    isVisible = true;
  }

  return (
    <Field
      label="Creation Time:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("rating.creationTime", null);
              clearFilterKey("rating.creationTime");
            }}
            style={{ marginTop: "-2px", marginLeft: "2px" }}
          />
        )
      }
      id="creation_time"
    >
      <Typography size={12} color="grey7">
        Greater than:
      </Typography>
      <FormikRatingInput name="rating.creationTime" size={14} />
    </Field>
  );
};

export default CreationTime;
