// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { ProfileCompleteContainer } from "./container";
import { Button } from "src/components/Core/Button";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { Visible, useScreenClass } from "react-grid-system";

interface ProfileCompleteProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  registerForBetaSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  isRegisteredForBeta: boolean;
}

export const ProfileCompleteWrapper = styled.div`
  width: 100%;
`;

const ProfileComplete: React.FC<ProfileCompleteProps> = ({
  initialValues,
  isSubmitting,
  registerForBetaSchema,
  onSubmit,
  globalErrors,
  isRegisteredForBeta,
}) => {
  const screenClass = useScreenClass();

  return (
    <ProfileCompleteWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={registerForBetaSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                <Visible xs sm md>
                  <Box borderBottom="1px solid #1890FF">
                    <Typography
                      size={16}
                      color="blue"
                      lineHeight="20px"
                      textAlign="center"
                    >
                      3. Profile Complete
                    </Typography>
                  </Box>
                </Visible>

                <Box p={24}>
                  <Typography size={20} color="black3" lineHeight="20px">
                    Thank you!
                  </Typography>

                  <Typography size={16} color="grey2">
                    You’re on your way to becoming a star influencer. What
                    happens next? Our team will review your details and contact
                    you about any campaigns that match your profile.
                  </Typography>

                  <Typography size={16} color="black" lineHeight="20px" mt={40}>
                    Pssst….
                  </Typography>

                  <Typography size={16} color="grey2">
                    We’re building new tools for our users (that’s you!) so it’s
                    only right we ask your opinion. If you’d like to be the
                    first to know about new features & be part of our Beta
                    testing program just click the button below.
                  </Typography>

                  <Box
                    display="flex"
                    flexDirection={
                      screenClass === "xs" || screenClass === "sm"
                        ? "column"
                        : "row"
                    }
                    mt={20}
                  >
                    <Button
                      htmlType="submit"
                      type={isRegisteredForBeta ? "confirm" : "action"}
                      loading={isSubmitting}
                      disabled={isRegisteredForBeta}
                      buttonSize="middle"
                      data-cy="submit-button"
                      style={
                        isRegisteredForBeta
                          ? {
                              backgroundColor: "#97d691",
                              border: "#97d691",
                              color: "#fff",
                            }
                          : {}
                      }
                    >
                      {isSubmitting
                        ? "Registering..."
                        : !isRegisteredForBeta
                        ? "Register for Beta"
                        : "Registered!"}
                    </Button>
                  </Box>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </ProfileCompleteWrapper>
  );
};

export { ProfileComplete, ProfileCompleteContainer };
