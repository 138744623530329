// @ts-nocheck
import React from 'react'
import { Row, Col } from 'antd'
import Account from './Account'
import { Draggable } from 'react-beautiful-dnd'

const videoStyle = {
  width: "100%",
  height: "75px",
  objectFit: "cover",
};

interface Props {
  accounts: Array<any>;
  isInnerAccountList: boolean;
  list?: any
  menu: Function
}

const GridList = ({ accounts, isInnerAccountList, menu, list }: Props) => {
  const accountComp = (account, newPrevContent) => {
    return(
      <Account
        account={account}
        isInnerAccountList={isInnerAccountList}
        menu={menu}
        newPrevContent={newPrevContent}
        videoStyle={videoStyle}
      />
    )
  }

  return (
    <Row gutter={8} style={{minHeight: '100px'}}>
      {(accounts || []).map((account, i) => {
        let prevContent = [
          ...(account?.previousBrandedContent || []),
          ...(account?.previousContent || []),
        ];
        let remainingLength = 4 - prevContent?.length;
        let newPrevContent = [
          ...prevContent,
          ...new Array(remainingLength < 0 ? 0 : remainingLength).fill({}),
        ];
        return (
          <Col span={list?.id ? 8 : 4} style={{ paddingBottom: "8px", opacity: account?.isSuggested ? "0.5" : "1" }}>
            {list?.id ?
              <Draggable draggableId={String(account.id)} index={i}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {accountComp(account, newPrevContent)}
                  </div>
                )}
              </Draggable>
              :
              <div>
                {accountComp(account, newPrevContent)}
              </div>
            }
          </Col>
        )
      })}
    </Row>
  );
};

export default GridList;
