// @ts-nocheck
import React from 'react'
import LinkButton from 'src/components/Core/LinkButton'
import { Box } from 'src/components/Core/Box'
import { Button } from 'src/components/Core/Button'

const Menu = ({account, createSuggestion, campaign, refetch, createInvitation, show}) => (
  <Box display="flex" flexDirection="column">
    <Button
      disabled={account?.isSuggested}
      type="action"
      buttonSize="middle"
      style={{ width: "100%" }}
      onClick={() =>
        show("InviteModal", {
          title: "Suggest Account",
          onCreate: createSuggestion,
          account: account,
          campaign: campaign,
          refetch: refetch
        })
      }
    >
      Suggest this influencer
    </Button>
    <LinkButton
      onClick={() =>
        show("InviteModal", {
          title: "Invite Account",
          onCreate: createInvitation,
          account: account,
          campaign: campaign,
          refetch: refetch
        })
      }
      mt={10}
      color="blue"
      fontSize={14}
      fontWeight="500"
      width="100%"
      style={{ border: "1px solid #40A9FF", borderRadius: "1px" }}
    >
      Invite directly
    </LinkButton>
  </Box>
);

export default Menu
