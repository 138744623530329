// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { UpdateInfluencerFormContainer } from "./UpdateInfluencerForm";

const InfluencerModal = styled(Modal)`
  &&& {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.213287);
    border-radius: 6px;
  }
`;

interface Props {
  showInfluencerForm: boolean;
  closeInfluencerModal: () => void;
}

const UpdateInfluencerModal = ({
  closeInfluencerModal,
  showInfluencerForm,
}: Props) => {
  return (
    <InfluencerModal
      title="Update Influencer"
      style={{
        maxWidth: 600,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showInfluencerForm}
      onCancel={closeInfluencerModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <UpdateInfluencerFormContainer
        closeInfluencerModal={closeInfluencerModal}
      />
    </InfluencerModal>
  );
};

export default UpdateInfluencerModal;
