// @ts-nocheck
import React from "react";
import { NewNoteForm } from ".";
import { string, object } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { useCreateNewNoteByInfluencerIdMutation } from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useInfluencerNotesDetails } from "../../InfluencerNotesProvider";
import { useInfluencerDetails } from "../../InfluencerProfileProvider";
import { useHistory } from "react-router";

type Props = {};

interface NewNoteFormValues {
  influencerId: number;
  title: string;
  body: string;
}

export const NewNoteFormContainer: React.FC<Props> = props => {
  const { influencerId, platformType } = useInfluencerDetails();

  const history: any = useHistory();

  const { actions: noteAction } = useInfluencerNotesDetails();

  const NewNoteSchema = object().shape({
    title: string()
      .nullable()
      .required("Please enter title"),

    body: string()
      .nullable()
      .required("Please enter body"),
  });

  const [
    createNewNoteByInfluencerIdMutation,
    { loading, error },
  ] = useCreateNewNoteByInfluencerIdMutation();

  let initialValues = {
    influencerId: influencerId,
    title: "",
    body: "",
  };

  const onSubmit = async (
    values: NewNoteFormValues,
    actions: FormikActions<NewNoteFormValues>
  ) => {
    try {
      let formData: NewNoteFormValues = {
        influencerId: values?.influencerId,
        title: values?.title,
        body: values?.body,
      };

      const res = await createNewNoteByInfluencerIdMutation({
        variables: formData,
      });
      if ((res?.data?.createNewNoteByInfluencerId || []).length > 0) {
        noteAction.refetchNotes();

        history.replace(
          `/influencer-profile/${platformType}/${influencerId}/notes`
        );
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <NewNoteForm
      cancelClick={()=>history.replace(
        `/influencer-profile/${platformType}/${influencerId}/notes`
      )}
      initialValues={initialValues}
      NewNoteSchema={NewNoteSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
    />
  );
};
