// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikTextArea } from "src/components/Forms/TextArea";

interface Props {}

const Rationale: React.FC<Props> = () => {
  return (
    <Field label="Why this Influencer?" mb={26}>
      <FormikTextArea
        size="middle"
        name="rationale"
        row={5}
        placeholder="Help the brand understand why this influencer will be a great fit for their campaign..."
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Rationale;
