// @ts-nocheck
import React, { useState } from "react";
import { Field } from "formik";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Popconfirm } from "antd";
import {
  FeedbackItemWrapper,
  AdminFeedbackInput,
  SerialNumber,
  TimestampInput,
} from "./styles";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";

interface Props {
  name: string;
  placeholder?: string;
  draft: any;
  userType?: string;
}

const FeedbackFieldItem = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const addFeedback = (field, form, value) => {
    if (value !== "") {
      (form?.values[field.name]).push({
        feedback: "",
        min: "",
        sec: "",
        isChecked: true,
      });
      form.setFieldValue(field.name, form?.values[field.name]);
    }
  };

  const removeFeedback = (field, form, value) => {
    const index = (form?.values[field.name]).indexOf(value);
    if (index > -1) {
      (form?.values[field.name]).splice(index, 1);
      form.setFieldValue(field.name, form?.values[field.name]);
    }
  };

  return (
    <Field {...props}>
      {({ field, form }) => {
        return <>
          {(form?.values[field.name]).map((value, i) => {
            const nextFieldValue = form.values[field.name][i + 1];
            const isThereNextField = typeof nextFieldValue !== "undefined";
            let inputText = form.values[field.name][i]["feedback"];

            return (
              <div key={i}>
                <FeedbackItemWrapper key={i} userType={props.userType}>
                  <Box display="block">
                    <Box display="flex" alignItems="center">
                      <SerialNumber>{i + 1}.</SerialNumber>
                      <AdminFeedbackInput
                        size="large"
                        placeholder={props.placeholder}
                        value={value?.feedback}
                        onChange={e => {
                          let newValue = { ...form.values };

                          newValue[field.name][i]["feedback"] =
                            e.target.value;
                          form.setFieldValue(
                            field.name,
                            newValue[field.name]
                          );
                        }}
                        onBlur={() => {
                          setIsEditing(false);
                          setSelectedIndex(-1);
                        }}
                        onClick={() => {
                          if (props?.draft?.status !== "approved") {
                            setIsEditing(true);
                            setSelectedIndex(i);
                          }
                        }}
                        onKeyPress={e => {
                          if (e.which === 13) {
                            e.preventDefault();
                            addFeedback(field, form, inputText);
                          }
                        }}
                        isDisabled={
                          isEditing &&
                          selectedIndex > -1 &&
                          i === selectedIndex
                        }
                        autoFocus
                        suffix={
                          isThereNextField &&
                          props?.draft?.status !== "approved" && (
                            <Popconfirm
                              title="Are you sure to delete this feedback?"
                              trigger="click"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={e =>
                                removeFeedback(field, form, value)
                              }
                              onCancel={(e: any) => e.preventDefault()}
                            >
                              <DeleteOutlined style={{ color: "#FF4444" }} />
                            </Popconfirm>
                          )
                        }
                      />
                    </Box>
                    {props?.draft?.draftType === "youtube" && (
                      <Box
                        display="inline-flex"
                        mt={10}
                        ml={27}
                        alignItems="center"
                      >
                        <Checkbox
                          onChange={() => {
                            let newValue = { ...form.values };

                            newValue[field.name][i]["isChecked"] = !newValue[
                              field.name
                            ][i]["isChecked"];
                            form.setFieldValue(
                              field.name,
                              newValue[field.name]
                            );
                          }}
                          checked={value?.isChecked}
                          value={value?.isChecked}
                          data-cy="checkbox-payingAgency"
                        />
                        <Typography
                          size={12}
                          color="grey9"
                          ml={10}
                          mt={0.9}
                          mr={10}
                          mb={0}
                        >
                          Add time stamp:
                        </Typography>
                        <TimestampInput
                          parser={value => {
                            let parsed = value.replace(/\[0-9]/g, "");
                            if (parsed && parsed?.length <= 2) {
                              return parsed.match(/\d+/g)
                                ? parseInt(parsed)
                                : "";
                            } else {
                              return null;
                            }
                          }}
                          name={`${field.name}.${i}.min`}
                          size="small"
                          placeholder="mm"
                          onBlur={() => {
                            setIsEditing(false);
                            setSelectedIndex(-1);
                          }}
                          onClick={() => {
                            if (props?.draft?.status !== "approved") {
                              setIsEditing(true);
                              setSelectedIndex(i);
                            }
                          }}
                          isDisabled={
                            isEditing &&
                            selectedIndex > -1 &&
                            i === selectedIndex
                          }
                        />
                        <Box ml="5px" mr="5px">
                          :
                        </Box>
                        <TimestampInput
                          parser={value => {
                            let parsed = value.replace(/\[0-9]/g, "");

                            if (parsed && parsed?.length <= 2) {
                              return parsed.match(/\d+/g)
                                ? parseInt(parsed)
                                : "";
                            } else {
                              return null;
                            }
                          }}
                          name={`${field.name}.${i}.sec`}
                          size="small"
                          placeholder="ss"
                          onBlur={() => {
                            setIsEditing(false);
                            setSelectedIndex(-1);
                          }}
                          onClick={() => {
                            if (props?.draft?.status !== "approved") {
                              setIsEditing(true);
                              setSelectedIndex(i);
                            }
                          }}
                          isDisabled={
                            isEditing &&
                            selectedIndex > -1 &&
                            i === selectedIndex
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </FeedbackItemWrapper>

                {!isThereNextField && (
                  <Box
                    onClick={() => {
                      if (inputText) {
                        addFeedback(field, form, inputText);
                      }
                    }}
                    ml={20}
                  >
                    <Typography size={14} color="blue" cursor="pointer">
                      <PlusOutlined style={{ marginRight: "5px" }} />
                      Add another piece of feedback
                    </Typography>
                  </Box>
                )}
              </div>
            );
          })}
        </>;
      }}
    </Field>
  );
};

export default FeedbackFieldItem;
