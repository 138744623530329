// @ts-nocheck
import React from "react";
import { useRouteMatch } from "react-router";
import { InfluencerHubTabContainer } from "./InfluencerHubTab";

interface Props {}

const InfluencerHub: React.FC<Props> = () => {
  const match: any = useRouteMatch();

  const params: any = match?.params;

  const claimCashoutId: any = params?.id;

  return <InfluencerHubTabContainer claimCashoutId={claimCashoutId} />;
};

export default InfluencerHub;
