// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { AddCampaignsListContainer } from "./AddCampaignList";

const CampaignsModal = styled(Modal)`
  &&& {
    border-radius: 6px;
  }
`;

interface Props {
  showAddCampaigns: boolean;
  closeAddCampaignsModal: () => void;
  getCampaignForCampaignGroup: () => void;
}

const AddCampaignsModal = ({
  closeAddCampaignsModal,
  showAddCampaigns,
  getCampaignForCampaignGroup,
}: Props) => {
  return (
    <CampaignsModal
      title="Add campaigns to your group"
      style={{
        maxWidth: 800,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showAddCampaigns}
      onCancel={closeAddCampaignsModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <AddCampaignsListContainer
        closeAddCampaignsModal={closeAddCampaignsModal}
        getCampaignForCampaignGroup={getCampaignForCampaignGroup}
      />
    </CampaignsModal>
  );
};

export default AddCampaignsModal;
