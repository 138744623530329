// @ts-nocheck
import React from "react";
import { DraftButtonsWrapper, SubmitButton } from "./styles";
import { CheckCircleOutlined } from '@ant-design/icons';
interface Props {
  isLoading: boolean;
  isDisabled: boolean;
  isSubmitted: boolean;
}

const FeedbackButton: React.FC<Props> = ({
  isLoading,
  isDisabled,
  isSubmitted,
}) => {
  return (
    <DraftButtonsWrapper>
      <SubmitButton
        htmlType="submit"
        type="confirm"
        loading={isLoading}
        disabled={isDisabled}
      >
        {!isLoading && (
          <>{isSubmitted ? <CheckCircleOutlined /> : "Send"}</>
        )}
      </SubmitButton>
    </DraftButtonsWrapper>
  );
};

export default FeedbackButton;
