// @ts-nocheck
import React from "react";
import { UpdateCampaignGroupForm } from ".";
import { string, object, number } from "yup";
import { message } from "antd";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetAllCompaniesQuery,
  useGetAllBrandsQuery,
  useUpdateCampaignGroupMutation,
} from "src/generated/ApolloComponents";
import { useCampaignGroupDetails } from "../CampaignGroupProvider";

interface Props {}

interface CreateCampaignGroupFormValues {
  groupName: string;
  companyId: number;
  groupId: number;
  extraBrandIds: Array<number>;
}

export const UpdateCampaignGroupFormContainer: React.FC<Props> = () => {
  const {
    actions: campaignGroupAction,
    campaignGroupDetails,
  } = useCampaignGroupDetails();

  const company = useGetAllCompaniesQuery({
    skip: true,
  });

  const brands = useGetAllBrandsQuery({
    skip: true,
  });

  const [
    updateCampaignGroupMutation,
    updateCampaignGroup,
  ] = useUpdateCampaignGroupMutation();

  const UpdateCampaignGroupSchema = object().shape({
    groupName: string()
      .nullable()
      .required("Please enter a title"),

    companyId: number()
      .nullable()
      .required("Please select a company"),
  });

  let extraBrandIds = [] as any;
  extraBrandIds =
    (campaignGroupDetails?.extraBrands || []).map(item => {
      return item.id;
    }) || [];

  let initialValues = {
    groupName: campaignGroupDetails?.groupName || "",
    companyId: campaignGroupDetails?.companyId || null,
    groupId: campaignGroupDetails?.id,
    extraBrandIds: extraBrandIds,
  };

  const onSubmit = async (
    values: CreateCampaignGroupFormValues,
    actions: FormikActions<CreateCampaignGroupFormValues>
  ) => {
    try {
      const res = await updateCampaignGroupMutation({
        variables: values,
      });
      if (res?.data?.updateCampaignGroup?.id) {
        message.success("Campaign Group update successfully");
        campaignGroupAction.refetchCampaignGroup();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(updateCampaignGroup?.error);

  return (
    <UpdateCampaignGroupForm
      initialValues={initialValues}
      updateCampaignGroupSchema={UpdateCampaignGroupSchema}
      isSubmitting={updateCampaignGroup?.loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      getCompanies={company.refetch}
      getBrands={brands.refetch}
    />
  );
};
