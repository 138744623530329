// @ts-nocheck
import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { Typography } from "src/components/Core/Typography";
import { TextInput } from "src/components/Forms/TextInput";
import { Box } from "src/components/Core/Box";
import { PostedContainer } from "./container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import AccountList from "./AccountList";
import PostedData from "./PostedData";

export interface FilterProps {
  accountName: string;
}

interface Props {
  accounts: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  filters: FilterProps;
  selectedAccount: any;
}

const Posted: React.FC<Props> = ({
  accounts,
  loading,
  globalErrors,
  onFilter,
  filters,
  selectedAccount,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState([] as Array<any>);

  useEffect(() => {
    if (selectedAccount) {
      setSelectedAccounts([selectedAccount]);
    } else {
      setSelectedAccounts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  const onSelectChange = account => {
    const index = selectedAccounts.findIndex(
      obj => obj.invitationId === account?.invitationId
    );

    if (index > -1) {
      selectedAccounts.splice(index, 1);
      setSelectedAccounts([...selectedAccounts]);
    } else {
      selectedAccounts.push(account);

      setSelectedAccounts([...selectedAccounts]);
    }
  };
  return (
    <Box>
      <Loading spinning={loading}>
        <Box background="white" paddingBottom="30px">
          {globalErrors && <GlobalErrors errors={globalErrors} />}

          <Row gutter={32}>
            <Col span={8}>
              <Box borderRight="1px solid #E8E8E8">
                <Box borderBottom="1px solid #E8E8E8" p={20}>
                  <Typography color="black" weight="semi-bold" size={16} mb={0}>
                    Post performance
                  </Typography>
                  <Typography color="black2" size={12} mb={0} mt="5px">
                    Below are all the posts currently attached to this campaign
                  </Typography>
                  <Box mt={15} mb={20}>
                    <TextInput
                      size="small"
                      background="background"
                      borderRadius={3}
                      borderColor="grey5"
                      value={filters?.accountName}
                      onChange={e => onFilter("accountName", e.target.value)}
                      suffix={<SearchOutlined />}
                      placeholder="Search tracked accounts..."
                    />
                    <Typography color="grey7" size={10} mb={0} mt="5px">
                      {(accounts || []).length} results
                    </Typography>
                  </Box>
                </Box>

                <AccountList
                  accounts={accounts}
                  selectedAccounts={selectedAccounts}
                  onSelectAccount={account => {
                    onSelectChange(account);
                  }}
                />
              </Box>
            </Col>
            <Col span={16}>
              <Box padding="20px 20px 20px 0px">
                <PostedData accounts={selectedAccounts} />
              </Box>
            </Col>
          </Row>
        </Box>
      </Loading>
    </Box>
  );
};

export { Posted, PostedContainer };
