import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "formik-antd";
import { connectModal } from "redux-modal";
import ModalForm from "src/shared/ModalForm";
import withModules from "src/utils/hoc/withModules";
import { InfluencerPersist } from "src/ducks/Persist";
import {
  Name,
  Email,
  Address,
  PhoneNumber,
  VatNumber,
  PaypalEmail,
  ManageTag,
  Locations,
  Ages,
  Gender,
  Note,
  Agency,
} from "src/shared/InfluencerFields";
import Validate from "./Validate";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import parseDotNotation from "src/utils/parseDotNotation";
import idx from "idx";
import { featureFlags } from "src/constants/featureFlags";

const InfluencerCreate = InfluencerPersist("CreateInfluencerModal");

const modules = [InfluencerCreate.module()];

class CreateInfluencerModal extends Component {
  createInfluencer = (values, formik) => {
    const { create } = this.props;
    const { setErrors, setSubmitting } = formik;

    let data;
    data = { ...values };

    if (isArray(idx(values, x => x.detail.age))) {
      data.detail.age = idx(values, x => x.detail.age);
    } else if (
      idx(values, x => x.detail.age) !== "" &&
      isString(idx(values, x => x.detail.age))
    ) {
      data.detail.age = [idx(values, x => x.detail.age)];
    }

    if (isArray(idx(values, x => x.detail.gender))) {
      data.detail.gender = idx(values, x => x.detail.gender);
    } else if (
      idx(values, x => x.detail.gender) !== "" &&
      isString(idx(values, x => x.detail.gender))
    ) {
      data.detail.gender = [idx(values, x => x.detail.gender)];
    }

    if (isArray(idx(values, x => x.detail.location))) {
      data.detail.location = idx(values, x => x.detail.location);
    } else if (
      idx(values, x => x.detail.location) !== "" &&
      isString(idx(values, x => x.detail.location))
    ) {
      data.detail.location = [idx(values, x => x.detail.location)];
    }

    return create(data)
      .then(res => {})
      .catch(err => {
        setSubmitting(false);
        setErrors(parseDotNotation(err.response.data.meta.errors));
        return Promise.reject();
      });
  };

  render() {
    const {
      show,
      create,
      handleHide,
      onCreate,
      loadTags,
      hideAgency,
      ...rest
    } = this.props;

    return (
      <ModalForm
        show={show}
        onSave={this.createInfluencer}
        afterSave={onCreate}
        validate={Validate}
        handleHide={handleHide}
        title="Create new influencer"
        {...rest}
      >
        <Form data-cy="influencer-create-modal-form">
          <Name />
          <Email />
          {featureFlags.isPaypalEnabled && <PaypalEmail />}
          <PhoneNumber />
          <Address />
          <VatNumber />
          {!hideAgency && <Agency />}
          <ManageTag />
          <Gender />
          <Ages />
          <Locations />
          <Note />
        </Form>
      </ModalForm>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  create: InfluencerCreate.create,
};

export default withModules(
  connectModal({
    name: "CreateInfluencerModal",
    destroyOnHide: true,
  })(connect(mapStateToProps, mapDispatchToProps)(CreateInfluencerModal))
)(modules);
