import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class Email extends Component {
  render() {
    return (
      <FormField name={this.props.name || "email"} label="Email">
        <Input data-cy="company-user-email" size="large" placeholder="e.g. joe@company.com" />
      </FormField>
    )
  }
}
