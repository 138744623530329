// @ts-nocheck
import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { Box } from 'src/components/Core/Box'
import TikTokPost from 'src/components/Modules/TikTokPost'
import { Typography } from 'src/components/Core/Typography'

interface Props {
  posts: []
}

const StyledCol = styled(Col)`
  margin: 4px 6px;
`

const Posts = ({posts}: Props) => {

  const getSpace = () => {
    const width = window.innerWidth
    switch(true){
      case(width > 2400):
        return 290;
      case(width > 1800):
        return 260;
      case(width > 1600):
        return 240
      case(width > 1400):
        return 220
      case(width > 1300):
        return 210
      case(width > 1200):
        return 200
    }
  }

  const getPost = (post :any) => {
    switch(post.typeString){
      case "youtube":
        return <iframe
          key={post.snaplyticsContentUrl}
          title="account-post"
          frameBorder="0"
          scrolling="no"
          marginHeight={0}
          marginWidth={0}
          width={getSpace()}
          height={getSpace()}
          src={`https://www.youtube.com/embed/${post.snaplyticsContentUrl}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}
        />
      case "video":
        return <TikTokPost url={post.metadata?.webVideoUrl} />
    }
  }

  return(
    <Box px={20}>
      <Typography size={14} color='grey7'>Posts</Typography>
      <Row justify="center">
        {posts.map((post: any) =>
          <StyledCol key={post.snaplyticsContentUrl}>
            {getPost(post)}
          </StyledCol>
        )}
      </Row>
    </Box>
  )
}

export default Posts
