// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import AnalyticsScreenshotExample from "../AnalyticsScreenshotExample";

const ExampleModal = styled(Modal)`
  &&& {
    max-width: 242px !important;

    .ant-modal-content {
      border-radius: 6px !important;
    }
    .ant-modal-body {
      padding: 0px !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
`;

interface Props {
  showExample: object;
  hideExample: () => void;
  platformType: string;
  screenshotType: string;
}

const AnalyticsScreenshotExampleModal = ({
  hideExample,
  showExample,
  platformType,
  screenshotType,
}: Props) => {
  return (
    <ExampleModal
      width="100%"
      visible={showExample[screenshotType]}
      onCancel={hideExample}
      footer={null}
    >
      <AnalyticsScreenshotExample
        hideExample={hideExample}
        showExample={showExample}
        platformType={platformType}
        screenshotType={screenshotType}
      />
    </ExampleModal>
  );
};

export default AnalyticsScreenshotExampleModal;
