import React, { Component } from "react";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import FormField from "../FormField";
import { Checkbox } from "formik-antd";
import VerticalCheckbox from "./VerticalCheckbox";
import { TagList as $TagList, TagListModule } from "src/ducks/DataList";
import AddTag from "./AddTag";
import { Row } from 'antd'

const TagList = $TagList("AccountTagList");

const modules = [TagListModule("AccountTagList")()];

export class AccountTag extends Component {
  componentDidMount() {
    const { loadTags } = this.props;
    loadTags({ per_page: 100 });
  }

  render() {
    const { data } = this.props;
    data.sort((a, b) => {
      let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
  });

    return (
      <>
        <FormField name={this.props.name || "tags"} label="Tagging">
          <Checkbox.Group>
            <Row>
              {data.map((value, i) => (
                <VerticalCheckbox
                  data-cy={`tags-checkbox-${i}`}
                  key={i}
                  value={value.id}
                >
                  {value.name === "Business & Finance"
                    ? "Bus. & Fin."
                    : value.name}
                </VerticalCheckbox>
              ))}
            </Row>
          </Checkbox.Group>
        </FormField>
        <AddTag />
      </>
    );
  }
}

const mapStateToProps = state => ({
  data: TagList.selectMany(state),
});

const mapDispatchToProps = {
  loadTags: TagList.loadMany,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(AccountTag)
)(modules);
