// @ts-nocheck
import React from 'react'
import { Field } from 'src/components/Forms/Field'
import { FormikInput } from 'src/components/Forms/TextInput'

const Username = () => {
  return (
    <Field id="username">
      <FormikInput
        size="middle"
        borderRadius={3}
        name="username"
        placeholder="Enter your handle"
      />
    </Field>
  );
};

export default Username;
