import React, { Component } from "react";
import { connect } from 'react-redux'
import { AccountContainer } from './Account'
import { DashboardContainer } from './Dashboard'
import { PreferencesContainer } from './Auth/Preferences'
import { TransactionContainer } from './TransactionCampaign'
import { ConnectAccountContainer } from './Auth/ConnectAccounts'
import { TermsAndConditionsContainer } from './Auth/TermsAndConditions'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'

class InfluencerRoutes extends Component {
  render() {
    return(
      <Switch>
        <Route path="/" exact component={DashboardContainer} />
        <Route path="/preferences" exact component={PreferencesContainer} />
        <Route path="/connect-accounts" exact component={ConnectAccountContainer} />
        <Route path="/my-campaigns" exact component={DashboardContainer} />
        <Route path="/campaigns/:id" exact component={TransactionContainer} />
        <Route path="/account" exact component={AccountContainer} />
        <Route path="/terms-and-conditions/:id" exact component={TermsAndConditionsContainer} />
        <Route path="*" component={() => <Redirect to="/" />} />
      </Switch>)
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(InfluencerRoutes));
