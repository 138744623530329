// @ts-nocheck
import React from "react"
import styled from "styled-components"
import { Steps } from "antd"

const { Step } = Steps

export type GiftingStepOptions = "posting" | "product" | "review"

interface Props {
  step: GiftingStepOptions
  setStep?: (number: number) => void
}

const StyledStep = styled(Step)`
  && {
    .ant-steps-item-content {
      cursor: pointer;
    }
  }
`

export const GiftingSteps = ({ step, setStep }: Props) => {
  const steps = {
    product: 0,
    posting: 1,
    review: 2
  }

  return (
    <Steps direction="vertical" current={steps[step]}>
      <StyledStep
        onClick={() => setStep && setStep(0)}
        title="About your product"
        description="Tell influencers about your brand"
      />
      <StyledStep
        onClick={() => setStep && setStep(1)}
        title="Posting guidlines"
        description="Explain what type of content you want"
      />
      <StyledStep
        onClick={() => setStep && setStep(2)}
        title="Review"
        description="Submit your campaign"
      />
    </Steps>
  )
}
