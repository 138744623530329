// @ts-nocheck
import React from 'react'
import { Preferences } from '.'
import { useHistory, useLocation } from 'react-router'
import {
  useGetAllTagsQuery,
  useUpdatePublicInfluencerFlagsMutation
} from 'src/generated/ApolloComponents'
import queryString from 'query-string'
import { useMapGraphQLErrorsMessages } from 'src/utils/hooks/useMapGraphQLErrors'

export const PreferencesContainer = () => {
  const history = useHistory()
  const location = useLocation()
  const params = queryString.parse(location.search)
  const tags = useGetAllTagsQuery()
  const [
    updatePublicInfluencerFlags,
    response
  ] = useUpdatePublicInfluencerFlagsMutation()
  const globalErrors = useMapGraphQLErrorsMessages(response?.error);

  const redirectTo = () => {
    history.push(`/${params.redirect}`)
  }

  const setPreference = async (values) => {
    if(!values.tags){
      redirectTo()
      return
    }
    let allTags = tags?.data?.getAllTags || []
    let selectedTags = allTags.filter(tag => values.tags.includes(tag.id)).map(tag => tag.name)
    try {
      let res = await updatePublicInfluencerFlags({
        variables: {flags: selectedTags}
      })
      if(res.data?.updatePublicInfluencerFlags?.id) {
        redirectTo()
      }
    } catch(e) {
      console.log(e)
    }
  }
  return(
    <Preferences
      handleSubmit={setPreference}
      globalErrors={globalErrors}
      preferences={tags?.data?.getAllTags}
      loading={tags?.loading || response?.loading}
    />
  )
}
