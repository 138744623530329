import axiosInstance from "../axiosInstance";

const GET_ADMINS = `/admins`;
const CREATE_ADMIN = `/admins`;
const getAdmin = id => `/admins/${id}`;
const deleteAdmin = id => `/admins/${id}`;

export const index = data => axiosInstance.get(GET_ADMINS, data);

export const create = data => axiosInstance.post(CREATE_ADMIN, data);

export const get = (id, data) => axiosInstance.get(getAdmin(id), data);

export const destroy = id => axiosInstance.delete(deleteAdmin(id));
