// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { CreateCampaignGroupFormContainer } from "./CreateCampaignGroupForm";

const CampaignGroupModal = styled(Modal)`
  &&& {
    border-radius: 6px;
  }
`;

interface Props {
  showNewCampaignGroupForm: boolean;
  closeCampaignGroupModal: () => void;
}

const CreateCampaignGroupModal = ({
  showNewCampaignGroupForm,
  closeCampaignGroupModal,
}) => {
  return (
    <CampaignGroupModal
      title="Create new campaign group"
      style={{
        maxWidth: 520,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showNewCampaignGroupForm}
      onCancel={closeCampaignGroupModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <CreateCampaignGroupFormContainer
        closeCreateCampaignGroupModal={closeCampaignGroupModal}
      />
    </CampaignGroupModal>
  );
};

export default CreateCampaignGroupModal;
