// @ts-nocheck
import React from "react";
import { ReportingForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { object } from "yup";
import { useRouteMatch } from "react-router";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useUpdateCampaignReportingMutation,
  useGetCampaignReportingQuery,
} from "src/generated/ApolloComponents";

interface Props {}

interface ReportingFormValues {
  campaignId: number;
  reportLink: string;
  instantReportLink: string;
  reportObjective: string;
}

export const ReportingFormContainer: React.FC<Props> = () => {
  const match: any = useRouteMatch();

  const params: any = match?.params;

  const campaignId = parseInt(params?.id);

  const reporting = useGetCampaignReportingQuery({
    variables: {
      campaignId: campaignId,
    },
  });

  const campaignReport = reporting?.data?.getCampaignReporting;

  const [
    updateCampaignReportingMutation,
    { loading, error },
  ] = useUpdateCampaignReportingMutation();

  const ReportingFormSchema = object().shape({});

  let initialValues = {
    campaignId: campaignId,
    reportLink: campaignReport?.reportLink || "",
    instantReportLink: `${window.location.origin}/campaigns/instant-report/${campaignId}`,
    reportObjective: campaignReport?.reportObjective || "",
  };

  const onSubmit = async (
    values: ReportingFormValues,
    actions: FormikActions<ReportingFormValues>
  ) => {
    let formData: any = {
      campaignId: values?.campaignId,
      reportLink: values?.reportLink,
      reportObjective: values?.reportObjective,
    };
    const res = await updateCampaignReportingMutation({
      variables: formData,
    });
    if (res?.data?.updateCampaignReporting?.id) {
    }
    try {
    } catch (e) {
      actions.setSubmitting(false);
    }
  };
  const globalErrors = useMapGraphQLErrorsMessages(error || reporting?.error);

  return (
    <ReportingForm
      initialValues={initialValues}
      reportingFormSchema={ReportingFormSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      isSubmitting={loading || reporting?.loading}
      globalErrors={globalErrors}
    />
  );
};
