// @ts-nocheck
import React from "react";
import { FormikCurrencyInput } from "src/components/Forms/CurrencyInput";
import { Field } from "src/components/Forms/Field";
import { RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";
import { Box } from "src/components/Core/Box";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Budget: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;
  let value = formProps?.values?.budget;

  if (value && (value?.start || value?.end)) {
    isVisible = true;
  }

  return (
    <Field
      label="Budget:"
      mb={20}
      mt={32}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("budget", { start: 0, end: 0 });
              clearFilterKey("budget");
            }}
            style={{ marginTop: "-1px", marginLeft: "15px" }}
          />
        )
      }
      id="budget"
    >
      <Box display="flex">
        <FormikCurrencyInput
          name="budget.start"
          size="middle"
          borderRadius={2}
          borderColor="grey5"
          prefix="£"
          shouldShowError={true}
          style={{ width: 80 }}
        />
        <Box ml={10} mr={10} verticalAlign="center">
          -
        </Box>
        <FormikCurrencyInput
          name="budget.end"
          size="middle"
          borderRadius={2}
          borderColor="grey5"
          prefix="£"
          shouldShowError={true}
          style={{ width: 80 }}
        />
      </Box>
    </Field>
  );
};

export default Budget;
