// @ts-nocheck
import React from "react"
import { Card, Col, Statistic } from "antd"
import Typography from "antd/lib/typography/Typography"

interface Props {
  influencerName?: string | null
  campaignTitle?: string | null
  clicks?: number | null
  installs?: number | null
  amountEarned: string
}

const TrackingDetails = ({
  influencerName,
  campaignTitle,
  amountEarned,
  clicks,
  installs
}: Props) => {
  return (
    <>
      <Col span={24}>
        <Card style={{ marginBottom: 30 }}>
          <Typography>
            Hey {influencerName}, below are your stats for {campaignTitle}. So far you have earned{" "}
            <b>{amountEarned}</b>
          </Typography>
        </Card>
      </Col>

      <Col span={12}>
        <Card>
          <Statistic title="Clicks" value={clicks || 0} />
        </Card>
      </Col>
      <Col span={12}>
        <Card>
          <Statistic title="Installs" value={installs || 0} />
        </Card>
      </Col>
    </>
  )
}

export default TrackingDetails
