// @ts-nocheck
import React, { useState } from "react";
import { ScreenshotsContainer } from "./container";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Upload } from "antd";
import styled from "styled-components";
import { Typography } from "src/components/Core/Typography";

export const ScreenshotsWrapper = styled.div`
  width: 100%;
`;

const StyledUpload = styled(Upload)`
  && {
    display: inline !important;

    .ant-upload.ant-upload-select-picture-card {
      height: 57px;
      width: 57px;
      background-color: #f6f6f6;
      margin-top: 15px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 57px;
      width: 57px;
      padding: 0px !important;
      margin-top: 15px;
    }
    .anticon-upload {
      font-size: 20px;
      color: #bcbcbc;
    }

    .ant-upload-list-item-uploading-text {
      display: none;
    }
  }
`;

type ScreenshotsProps = {
  value: any;
  isUploading: boolean;
  handleRemove: (value: any) => void;
  onUpload: (value: any) => void;
};

const Screenshots: React.FC<ScreenshotsProps> = ({
  value,
  isUploading,
  handleRemove,
  onUpload,
}) => {
  const [previewImage, setPreviewImage] = useState(value?.[0]?.previewUrl || "");

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
  };

  return (
    <ScreenshotsWrapper data-cy="input-screenshots">
      {previewImage && (
        <a href={previewImage} target="_blank" rel="noopener noreferrer">
          <img
            style={{
              width: "100%",
              height: 330,
              objectFit: "contain",
              backgroundColor: "#D3D3D3",
              borderRadius: "4px",
              border: "1px solid #D9D9D9",
            }}
            src={previewImage as any}
            alt="data-screenshots"
          />
        </a>
      )}

      <StyledUpload
        listType="picture-card"
        accept="image/*"
        fileList={
          value
            ? value
                .filter(v => !v.isDeleted)
                .map((x, index) => ({
                  uid: index,
                  id: x.id,
                  url: x.previewUrl,
                }))
            : // map((x, index) => ({
              //     uid: index,
              //     url: x.uploadUrl,
              //   }))
              []
        }
        onPreview={handlePreview}
        onRemove={file => handleRemove(file)}
        customRequest={file => onUpload(file)}
      >
        <div>
          <LegacyIcon type={isUploading ? "loading" : "plus"} />
          <Typography mt="8px" size={12}>
            Upload
          </Typography>
        </div>
      </StyledUpload>
    </ScreenshotsWrapper>
  );
};

export { Screenshots, ScreenshotsContainer };
