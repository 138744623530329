// @ts-nocheck
import React, { useState } from "react";
import { Card } from "antd";
import Container from "src/shared/Container";
import { Field } from "src/components/Forms/Field";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { ReportingFormContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import { FormikInput } from "src/components/Forms/TextInput";
import { FormikTextArea } from "src/components/Forms/TextArea";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import { Loading } from "src/components/Core/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";

interface ReportingFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  reportingFormSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
}

export const ReportingFormWrapper = styled.div`
  width: 100%;
`;

const ReportingForm: React.FC<ReportingFormProps> = ({
  initialValues,
  isSubmitting,
  reportingFormSchema,
  onSubmit,
  globalErrors,
}) => {
  const [linkCopyButtonAction, setLinkCopyButttonAction] = useState(false);

  return (
    <Container>
      <Card style={{ marginBottom: 24, marginTop: 24 }}>
        <Loading spinning={isSubmitting}>
          <Form
            initialValues={initialValues}
            schema={reportingFormSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {(formProps: any) => {
              return (
                <>
                  <Box mb={40}>
                    <GlobalErrors errors={globalErrors} />

                    <Field label="Full report link" mb={26}>
                      <FormikInput
                        name="reportLink"
                        size="middle"
                        placeholder="Please insert link to full manual report"
                        borderRadius={2}
                        borderColor="grey5"
                      />
                      {formProps?.values?.fullReportLink && (
                        <CopyToClipboard
                          text={formProps?.values?.fullReportLink}
                          onCopy={() => {
                            linkCopyButtonAction
                              ? setLinkCopyButttonAction(false)
                              : setLinkCopyButttonAction(true);
                            setTimeout(
                              () => setLinkCopyButttonAction(false),
                              1000
                            );
                          }}
                        >
                          <Typography
                            size={14}
                            color="blue"
                            mt={10}
                            cursor="pointer"
                          >
                            {linkCopyButtonAction ? "Copied!" : "Copy link"}
                          </Typography>
                        </CopyToClipboard>
                      )}
                    </Field>

                    <Field label="Instant report link" mb={26}>
                      <FormikInput
                        name="instantReportLink"
                        size="middle"
                        placeholder="http://app.fanbytes.co.uk/campaigns/2863/instant-report"
                        borderRadius={2}
                        borderColor="grey5"
                        disabled={true}
                      />
                      {formProps?.values?.instantReportLink && (
                        <Box display="inline-flex" color="blue" mt={10}>
                          <CopyToClipboard
                            text={formProps?.values?.instantReportLink}
                            onCopy={() => {
                              linkCopyButtonAction
                                ? setLinkCopyButttonAction(false)
                                : setLinkCopyButttonAction(true);
                              setTimeout(
                                () => setLinkCopyButttonAction(false),
                                1000
                              );
                            }}
                          >
                            <Typography
                              size={14}
                              color="blue"
                              mr={20}
                              cursor="pointer"
                              id="copy_report_link"
                            >
                              {linkCopyButtonAction ? "Copied!" : "Copy link"}
                            </Typography>
                          </CopyToClipboard>

                          <a
                            href={formProps?.values?.instantReportLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ fontSize: "14px" }}
                            id="visit_report"
                          >
                            Visit Link
                          </a>
                        </Box>
                      )}
                    </Field>

                    <Field label="Objective" mb={26}>
                      <FormikTextArea
                        size="middle"
                        name="reportObjective"
                        rows={1}
                        placeholder="Please describe the objective of this campaiagn"
                        borderRadius={2}
                        borderColor="grey5"
                      />
                      <Typography color="grey7" size={14} mt="5px">
                        This will be displayed as the objective on the instant
                        report. If left empty the primary goal of the campaign
                        will be dislpayed i.e. App installs
                      </Typography>
                    </Field>

                    <Button
                      htmlType="submit"
                      type="action"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      buttonSize="middle"
                      data-cy="reporting-form-submit"
                    >
                      Save
                    </Button>
                  </Box>
                </>
              );
            }}
          </Form>
        </Loading>
      </Card>
    </Container>
  );
};

export { ReportingForm, ReportingFormContainer };
