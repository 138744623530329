// @ts-nocheck
import { string, object, number, boolean, array } from "yup";

export const PaymentRequestSchema = object().shape({
  // views: string()
  //   .nullable()
  //   .required("Please enter views"),
  campaignId: string()
    .nullable()
    .required("Please select campaign"),
  accountId: string()
    .nullable()
    .required("Please select an account"),
  description: string()
    .nullable()
    .required("Please enter Cost Type"),
  isVATRegister: boolean(),

  vatNumber: string().when("isVATRegister", {
    is: true,
    then: string()
      .nullable()
      .required("Please enter VAT Number"),
  }),
  currency: string()
    .nullable()
    .required("Please select curremcy"),
  amount: number()
    .typeError("Please enter valid amount")
    .test("amount", "Please enter amount below 100k", function(value) {
      if (Number(value) / 100 > 100000) return false;
      return true;
    })
    .nullable()
    .required("Please enter amount"),

  screenshots: array().of(
    object().shape({
      uploadUrl: string()
        .nullable()
        .required(),
      uploadType: string()
        .nullable()
        .required(),
    })
  ),

  accountType: string(),

  // tiktokUrl: string().when("accountType", {
  //   is: "TikTok",
  //   then: string()
  //     .nullable()
  //     .url("Must be a valid URL (please include http://)")
  //     .required("Please enter post url"),
  // }),

  tiktokUrl: string()
    .nullable()
    .url("Must be a valid URL (please include http://)"),

  doNotCreatePaymentRequest: boolean(),

  payingAgency: boolean(),

  agencyName: string().when("payingAgency", {
    is: true,
    then: string()
      .nullable()
      .required("Please enter Agency name"),
  }),

  attachments: array().min(1, "Attach at least one file"),
});
