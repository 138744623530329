// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Row, Col, Select } from "antd";
import styled from "styled-components";
import { TextInput } from "src/components/Forms/TextInput";
import { FilterProps } from "../../CampaignsList";

const { Option } = Select;

const WideSelect = styled(Select)`
  width: 100%;
`;

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const Filters = ({ handleChange, filters }: Props) => {
  return (
    <Box mb={10}>
      <Row gutter={16}>
        <Col span={12}>
          <TextInput
            size="middle"
            borderRadius={3}
            borderColor="grey5"
            placeholder="Search..."
            onChange={e => handleChange("title", e.target.value)}
            value={filters?.title}
          />
        </Col>

        <Col span={6}>
          <WideSelect
            size="large"
            placeholder="Status"
            onChange={v => handleChange("status", v)}
            value={filters?.status}
          >
            <Option key="active">Active</Option>
            <Option key="draft">Draft</Option>
            <Option key="submitted">Under Review</Option>
            <Option key="complete">Complete</Option>
          </WideSelect>
        </Col>
        <Col span={6}>
          <WideSelect
            size="large"
            placeholder="Sort by"
            onChange={v => handleChange("order", JSON.parse(v as any))}
            value={JSON.stringify(filters?.order)}
          >
            <Option key={JSON.stringify({ startDate: "asc" })}>
              Launch date (asc)
            </Option>
            <Option key={JSON.stringify({ startDate: "desc" })}>
              Launch date (desc)
            </Option>
            <Option key={JSON.stringify({ createdAt: "asc" })}>
              Created at (asc)
            </Option>
            <Option key={JSON.stringify({ createdAt: "desc" })}>
              Created at (desc)
            </Option>
          </WideSelect>
        </Col>
      </Row>
    </Box>
  );
};

export default Filters;
