// @ts-nocheck
import React from "react";
import numeral from "numeral";
import { VelocityComponent } from "velocity-react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { Avatar } from "antd";
import BarSection from "../../BarSection";

interface Props {
  percentage: number;
  profileUrl: string;
  username: string;
  value: number;
  inViewport: boolean;
  enterCount: number;
  direction: string;
  type: "engagement" | "views";
}

const Strip: React.FC<Props> = ({
  percentage,
  profileUrl,
  username,
  value,
  inViewport,
  enterCount,
  direction,
  type,
}) => {
  return (
    <BarSection percentage={percentage}>
      <Box
        width="100%"
        display="flex"
        flexDirection={direction === "left" ? "row" : "row-reverse"}
        justifyContent="space-between"
      >
        <Box
          display="inline-flex"
          flexDirection={direction === "left" ? "row" : "row-reverse"}
          mr={direction === "right" ? 10 : 0}
        >
          <Avatar src={profileUrl} size={36} />

          <VelocityComponent
            key="fadeIn"
            animation={
              enterCount <= 1 && inViewport
                ? "transition.fadeIn"
                : "transition.fadeIn"
            }
            delay={50}
          >
            <Typography
              size={16}
              color="black"
              mb={0}
              mr={direction === "right" ? 10 : 0}
              ml={direction === "left" ? 10 : 0}
              lineHeight="36px"
              fontWeight={500}
            >
              {username}
            </Typography>
          </VelocityComponent>
        </Box>
        <Typography
          size={16}
          color="black"
          mb={0}
          mr={direction === "right" ? 10 : 20}
          ml={direction === "left" ? 20 : 10}
          lineHeight="36px"
          fontWeight={500}
        >
          {type === "views"
            ? numeral(value).format("0a")
            : numeral(value).format("0,0.00") + "%"}
        </Typography>
      </Box>
    </BarSection>
  );
};

export default Strip;
