import { string, number, object, array } from "yup";

export default object().shape({
  title: string()
    .nullable()
    .required("You need to have a title"),
  ad_format: string().required("You need to select an ad format"),
  distribution_platforms: array().required("Please select a platform"),
  objective: string()
    .nullable()
    .required("You need to have an objective"),
  budget_pennies: number()
    .nullable()
    .required("You need to have a budget"),
  start_date: string()
    .nullable()
    .required("You need to have a start date"),
  // end_date: string()
  //   .nullable()
  //   .required("You need to have a end date")
});
