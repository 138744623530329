import React, { Component } from "react";
import { connect } from "react-redux";
import CampaignManage from "src/ducks/CampaignManage";
import { withFormik } from "formik";
import { string, object, array } from "yup";
import { message } from "antd";
import { Form } from "formik-antd";
import {
  Brief,
  SubmitButton,
  Creative,
  PerfectCreative,
  AboutBrand,
  BriefDo,
  BriefDont,
  CreativeLink,
} from "src/shared/CampaignFields";

const Validate = object().shape({
  about_brand: string()
    .nullable()
    .required("Please tell us about your brand"),

  brief: string()
    .nullable(),

  brief_do: array().nullable(),
  brief_dont: array().nullable(),
  metadata: object({ creative_links: array(string().url()).nullable() }),
});

export class CampaignForm extends Component {
  render() {
    const { isSubmitting, campaign, values } = this.props;

    return (
      <Form>
        <Creative company_id={campaign.ref.company} />
        <CreativeLink values={values} />
        <PerfectCreative />
        <AboutBrand />
        <Brief />
        <BriefDo values={values} />
        <BriefDont values={values} />
        <SubmitButton text="Update" isSubmitting={isSubmitting} />
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {
  updateCampaign: CampaignManage.campaign.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,

    mapPropsToValues: ({ campaign }) =>
      campaign ? campaign.ref : Validate.cast(),
    validationSchema: () => Validate,

    handleSubmit: (values, { props, setSubmitting }) => {
      const { updateCampaign, campaign } = props;

      updateCampaign(campaign.id, values)
        .then(res => {
          setSubmitting(false);
        })
        .catch(err => {
          message.error("Failed to update campaign");
          setSubmitting(false);
        });
    },

    displayName: "CampaignForm",
  })(CampaignForm)
);
