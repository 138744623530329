import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class CompanyName extends Component {
  render() {
    return (
      <FormField name={this.props.name || "company_name"} label="Company Name">
        <Input data-cy="company-name" size="large" placeholder="e.g. Adidas" />
      </FormField>
    )
  }
}
