// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikRatingInput } from "src/components/Forms/Rating";

interface Props {}

const Punctuality: React.FC<Props> = () => {
  return (
    <Field label="Punctuality:" mb={26}>
      <FormikRatingInput name="punctuality" size={24} allowHalf />
    </Field>
  );
};

export default Punctuality;
