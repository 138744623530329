import { applyMiddleware, compose } from "redux";
import Raven from "raven-js";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import createRavenMiddleware from "raven-for-redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import reducers from "./rootReducer";
import { createStore as dynamicStore } from "redux-dynamic-modules";
import Auth from "src/ducks/Auth";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const history = createBrowserHistory();

const sentryUrl =
  process.env.NODE_ENV === "production"
    ? "https://7490dedadaa449c997f3fcd1fceec276@sentry.io/1208271"
    : "https://f654d2665d3e47fb8f0fba5fc8f98f46@sentry.io/1402134";

if (process.env.NODE_ENV === "development") {
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), promise(), thunk)
  );
} else {
  Raven.config(sentryUrl).install();
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      promise(),
      thunk,
      createRavenMiddleware(Raven)
    )
  );
}

const staticModule = () => ({
  id: "static",
  reducerMap: reducers(history),
});

const configureStore = railsProps =>
  dynamicStore(
    /* initial state */
    {},

    /** enhancers **/
    [],

    /* extensions to include */
    [
      {
        middleware: [
          routerMiddleware(history),
          promise(),
          thunk,
          createRavenMiddleware(Raven),
        ],
      },
    ],

    staticModule(),
    Auth.module()
  );

export default configureStore();
