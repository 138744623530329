// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { FilterOutlined } from '@ant-design/icons';
import { Badge } from "antd";
import { useAccountsListDetails } from "../AccountsListProvider";
import { getTotalAppliedFilter } from "../utils";

interface Props {
  containerType?: string;
  showAccountFilterForm: () => void;
  isInnerAccountList: boolean;
}

const FilterIcon = ({ showAccountFilterForm, isInnerAccountList, containerType }: Props) => {
  const { filters } = useAccountsListDetails();

  return (
    <Box
      background="#FFFFFF"
      height={60}
      width={60}
      textAlign="center"
      borderRadius={50}
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
      fontSize={16}
      p={20}
      onClick={() => showAccountFilterForm()}
      style={{
        cursor: "pointer",
        position: "absolute",
        zIndex: 999,
      }}
      mt={isInnerAccountList ? "-28px" : "-30px"}
      ml={isInnerAccountList ? "-25px" : "-20px"}
    >
      <Badge
        count={Number(getTotalAppliedFilter(filters))}
        style={{ background: "#1890FF" }}
      >
        <FilterOutlined style={{ fontSize: "20px", color: "#1890FF" }} />
      </Badge>
    </Box>
  );
};

export default FilterIcon;
