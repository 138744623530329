import { ORM } from "redux-orm";
import {
  Account,
  AccountPost,
  Agency,
  Campaign,
  Company,
  Creative,
  Group,
  Influencer,
  Invitation,
  RequestCreative,
  Suggestion,
  Tag,
  Admin,
  Brand,
  CampaignAnalytic,
  Draft,
  CampaignPackage,
  Link,
  Payout,
  Worker,
} from "./models";

const orm = new ORM();
orm.register(
  Admin,
  Brand,
  Account,
  AccountPost,
  Agency,
  Campaign,
  Company,
  Creative,
  Group,
  Draft,
  Influencer,
  Invitation,
  RequestCreative,
  Suggestion,
  Tag,
  CampaignAnalytic,
  CampaignPackage,
  Link,
  Payout,
  Worker
);

export default orm;
