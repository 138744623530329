// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal, Row, Col, Card } from "antd";
import styled from "styled-components";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import Image1 from "src/assets/images/cashout_youtube/img1.png";
import Image2 from "src/assets/images/cashout_youtube/img2.png";

const ScreenahotModal = styled(Modal)`
  &&& {
    border-radius: 6px;
  }
`;

const ImageCard = styled(Card)`
  &&& {
    border: 0px;
  }
`;

interface Props {
  showYoutubeScreenshot: boolean;
  closeYoutubeScreenshotModal: () => void;
}

const YoutubeScreenshotModal = ({
  closeYoutubeScreenshotModal,
  showYoutubeScreenshot,
}: Props) => {
  return (
    <ScreenahotModal
      style={{
        maxWidth: 900,
      }}
      title="Finding your details"
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showYoutubeScreenshot}
      onCancel={closeYoutubeScreenshotModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <Typography size={14} color="grey2">
        The images below outline what information should be visibke in your
        screenshots
      </Typography>

      <Box mt={20}>
        <Row gutter={16}>
          <Col span={12}>
            <Typography size={12} fontWeight="semi-bold" color="black" mb={10}>
              Views, post length, likes, dislikes & comments
            </Typography>

            <ImageCard
              cover={
                <img
                  src={Image1}
                  alt=" Views, post length, likes, dislikes & comments"
                />
              }
            />
          </Col>
          <Col span={12}>
            <Typography size={12} fontWeight="semi-bold" color="black" mb={10}>
              Watch time
            </Typography>

            <ImageCard cover={<img src={Image2} alt=" Watch time" />} />
          </Col>
        </Row>
      </Box>
    </ScreenahotModal>
  );
};

export default YoutubeScreenshotModal;
