// @ts-nocheck
import React from 'react'
import { Field } from 'src/components/Forms/Field'
import { FormikInput } from 'src/components/Forms/TextInput'

const FullName = () => {
  return (
    <Field
      mb={20}
      color="black"
      isBeside={true}
      id="name"
      label="Full name"
    >
      <FormikInput
        size="middle"
        borderRadius={3}
        name="name"
        placeholder="John Doe"
      />
    </Field>
  );
};

export default FullName;
