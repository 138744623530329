import React, { Component } from "react"
import FormField from "../FormField"
import { Select } from "formik-antd"
import { genders } from "src/constants/influencer";

export default class Gender extends Component {
  render() {
    return (
      <FormField name={this.props.name || "detail.gender"} label="Gender">
        <Select size="large" data-cy="influencer-gender" placeholder="Select..." options={genders}>
        </Select>
      </FormField>
    )
  }
}
