// @ts-nocheck
import * as React from "react";

export interface statisticsDetail {
  payments: number;
  totalAmount: number;
  totalVatAmount: number;
}

interface ContextType {
  queueStatistics?: any;

  actions: {
    setQueueStatistics: (value: any) => void;
    setRefetchMethodRef: (value: any) => void;
    refetchQueue: () => void;
  };
}

const PaymentQueueContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our payment queue statistics detail context
export function usePaymentQueueDetails() {
  const context = React.useContext(PaymentQueueContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function PaymentQueueProvider(props) {
  const [queueStatistics, setQueueStatistics] = React.useState({});
  const refetchMethod = React.useRef(() => {});
  const setRefetchMethodRef = fn => {
    refetchMethod.current = fn;
  };

  const refetchQueue = () => {
    if (refetchMethod.current) {
      refetchMethod?.current();
    }
  };

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      queueStatistics,
      actions: {
        setQueueStatistics,
        setRefetchMethodRef,
        refetchQueue,
      },
    };
    return internalVal;
  }, [queueStatistics]);

  return (
    <PaymentQueueContext.Provider value={value}>
      {props.children}
    </PaymentQueueContext.Provider>
  );
}
