// @ts-nocheck
import { message } from "antd";
import { FormikHelpers as FormikActions } from "formik";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import matchesProperty from "lodash/matchesProperty";
import React, { useMemo, useState } from "react";
import { featureFlags } from "src/constants/featureFlags";
import {
  PaymentDetailType,
  useCreateNewPaymentRequestMutation,
  useGetAccountsByCampaignIdQuery,
  useGetAllAgenciesQuery,
  useGetAllCampaignsQuery,
} from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { PaymentRequestForm } from ".";
import { usePaymentDetails } from "../PaymentDetailsProvider";
import { PaymentRequestSchema } from "./Validations";

interface Props {
  closePaymentModal: () => void;
  setStep: (value: number) => void;
}

interface PaymentRequestFormValues {
  campaignId?: number;
  accountId?: number;
  agencyId?: number;
  vatNumber: string;
  description: string;
  currency: string;
  isVATRegister?: boolean;
  amount: number;
  paymentDetail: PaymentDetailType;
  payingAgency?: boolean;
  agencyName?: string;
  attachments?: Array<any>;
  agencyAccountIds?: Array<any>;
}

export const PaymentRequestFormContainer: React.FC<Props> = props => {
  const [paymentType, setPaymentType] = useState(
    featureFlags.isPaypalEnabled ? "paypal" : "bank"
  );
  const [isPaymentDetailError, setPaymentDetailError] = useState(false);

  const { actualPaymentDetails, existingPaymentDetails } = usePaymentDetails();

  const handleSetPaymentType = value => {
    setPaymentType(value);
  };

  const campaigns = useGetAllCampaignsQuery({
    skip: true,
  });

  const agency = useGetAllAgenciesQuery({
    skip: true,
  });

  const accountsData = useGetAccountsByCampaignIdQuery();

  const [
    createNewPaymentRequestMutation,
    { loading, error },
  ] = useCreateNewPaymentRequestMutation();

  let accountArray = accountsData?.data?.getAccountsByCampaignId || [];

  const initialValues = useMemo(() => {
    return {
      campaignId: "",
      agencyId: null,
      accountId: "",
      description: "",
      isVATRegister: false,
      vatNumber: "",
      currency: "GBP",
      amount: "",
      payingAgency: false,
      agencyName: "",
      agencyAccountIds: [],
      attachments: [],
    };
  }, []);

  const onSubmit = async (
    values: PaymentRequestFormValues,
    actions: FormikActions<PaymentRequestFormValues>
  ) => {
    try {
      let isSuccess = true;
      let errorMessage = "";

      if (
        !values?.payingAgency &&
        (values?.accountId ||
          values?.description.replace(/\s+/g, "").toLowerCase() ===
            "influencerpayout")
      ) {
        // let accountId = values?.accountId as any;
        // let account = find(accountArray, matchesProperty("id", accountId));
        // if (account?.status === "Not Verified") {
        //   isSuccess = false;
        //   errorMessage = "Account must be verified!";
        // }
      } else {
        let agencyAccountIds = values?.agencyAccountIds as any;
        let unVerifiedAccounts = [] as Array<any>;

        agencyAccountIds.forEach(acccontId => {
          let account = find(accountArray, matchesProperty("id", acccontId));

          if (account?.status === "Not Verified") {
            unVerifiedAccounts.push(account?.username);
            isSuccess = false;
          }
        });

        // if (unVerifiedAccounts.length > 0) {
        //   errorMessage =
        //     unVerifiedAccounts.length > 1
        //       ? `${unVerifiedAccounts.toString()} Accounts must be verified!`
        //       : "Account must be verified!";
        // }
      }

      if (!isSuccess) {
        message.warning(errorMessage);
      } else {
        let paymentDetail = {};

        if (
          featureFlags.isPaypalEnabled &&
          paymentType === "paypal" &&
          actualPaymentDetails?.paypalEmail
        ) {
          paymentDetail = {
            ...paymentDetail,
            paypalEmail: actualPaymentDetails?.paypalEmail,
          };
        }

        if (
          paymentType === "bank" &&
          !isEmpty(actualPaymentDetails?.bankAccount)
        ) {
          paymentDetail = {
            ...paymentDetail,
            bankAccount: actualPaymentDetails?.bankAccount,
          };
        }

        if (isEmpty(paymentDetail) && !isPaymentDetailError) {
          setPaymentDetailError(true);
        } else {
          setPaymentDetailError(false);
          let formData: PaymentRequestFormValues = {
            description: values?.description,
            vatNumber: values?.vatNumber,
            amount: Math.round(values?.amount),
            currency: values?.currency,
            paymentDetail: paymentDetail,
            attachments: values?.attachments,
          };

          if (
            !values?.payingAgency &&
            (values?.accountId ||
              values?.description.replace(/\s+/g, "").toLowerCase() ===
                "influencerpayout")
          ) {
            formData = {
              ...formData,
              campaignId: values?.campaignId as any,
              accountId: values?.accountId as any,
            };
          } else {
            formData = {
              ...formData,
              campaignId: values?.campaignId as any,
              agencyName: values?.agencyName as any,
              agencyAccountIds: values?.agencyAccountIds as any,
              agencyId: values?.agencyId as number,
            };
          }

          const res = await createNewPaymentRequestMutation({
            variables: formData,
          });
          if (res.data?.createNewPaymentRequest?.paymentRequest?.id) {
            // payment detail not match
            if (!isEqual(existingPaymentDetails, actualPaymentDetails)) {
              props.setStep(2);
            } else {
              props.setStep(3);
            }
          }
        }
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <PaymentRequestForm
      initialValues={initialValues}
      PaymentRequestSchema={PaymentRequestSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      closePaymentModal={props.closePaymentModal}
      paymentType={paymentType}
      setPaymentType={value => handleSetPaymentType(value)}
      getCampaigns={campaigns.refetch}
      getAccountsByCampaignId={accountsData.refetch}
      allAccountList={accountArray}
      isPaymentDetailError={isPaymentDetailError}
      getAgencies={agency.refetch}
    />
  );
};
