import { Model } from "redux-orm";

class Tag extends Model {
  static parse(data) {
    if (typeof data === "number") {
      data = { id: data };
    }

    if (!data || typeof data !== "object") return data;

    const parsedData = {
      ...data,
    };

    return this.upsert(parsedData);
  }
}
Tag.modelName = "Tag";

// Declare your related fields.
Tag.fields = {};

export default Tag;
