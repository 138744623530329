import React, { Component } from "react"
import FormField from "../FormField"
import CurrencyInput from "src/shared/CurrencyInput"

export default class CPIPayoutPennies extends Component {
  render() {
    const { name, rest } = this.props
    return (
      <FormField name={name || "cpi_payout_pennies"} label="CPI Payout Pennies">
        <CurrencyInput data-cy="cpi_payout_pennies" {...rest} />
      </FormField>
    )
  }
}
