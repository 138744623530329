// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import {
  useGetPendingShareSuggestionsQuery,
  useGetAcceptedShareSuggestionsQuery,
  useAddLikedShareSuggestionMutation,
  useRemoveLikedShareSuggestionMutation
} from "src/generated/ApolloComponents"
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors"
import { Suggestions } from "."


export const SuggestionContainer = () => {
  const params: any = useParams()
  const [pendingFilters, setPendingFilters] = useState({
    campaignId: Number(params?.id),
    page: 1,
  })
  const [acceptedFilters, setAcceptedFilters] = useState({
    campaignId: Number(params?.id),
    page: 1,
  })

  const pending: any = useGetPendingShareSuggestionsQuery({
    variables: pendingFilters,
  });

  const accepted: any = useGetAcceptedShareSuggestionsQuery({
    variables: acceptedFilters,
  });

  useEffect(() => {
    try{
      pending.refetch(pendingFilters)
    } catch {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pendingFilters])

  useEffect(() => {
    try{
      accepted.refetch(acceptedFilters)
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[acceptedFilters])

  const [
    addLikedShareSuggestion,
    acceptedSuggestion
  ] = useAddLikedShareSuggestionMutation()

  const [
    removeLikedShareSuggestion,
    removedSuggestion
  ] = useRemoveLikedShareSuggestionMutation()

  const refetch = () => {
    try {
      pending.refetch(pendingFilters)
      accepted.refetch(acceptedFilters)
    } catch {}
  }

  const handleUpdate = async (suggestion: any) => {
    const params = { variables:  {suggestionId: suggestion.id} }
    try {
      if(suggestion.status === "pending"){
        const res = await addLikedShareSuggestion(params)
        if(res?.data?.addLikedShareSuggestion?.status === "accepted"){
          if(pending.data?.getPendingShareSuggestions?.totalPages === pendingFilters?.page){
            if(pendingFilters.page > 1 && pending.data?.getPendingShareSuggestions?.totalEntries%30 === 1) {
              setPendingFilters(prevState => { return {...prevState, page: prevState.page - 1} })
              accepted.refetch(acceptedFilters)
            } else {
              refetch()
            }
          } else {
            refetch()
          }
        }
      } else {
        const res = await removeLikedShareSuggestion(params)
        if(res?.data?.removeLikedShareSuggestion?.status === "pending"){
          if(accepted.data?.getAcceptedShareSuggestions?.totalPages === acceptedFilters?.page){
            if(acceptedFilters.page > 1 && accepted.data?.getAcceptedShareSuggestions?.totalEntries%30 === 1) {
              setAcceptedFilters(prevState => { return {...prevState, page: prevState.page - 1} })
              pending.refetch(pendingFilters)
            } else {
              refetch()
            }
          } else {
            refetch()
          }
        }
      }
    } catch {}
  }

  const fetchErrors = () =>
    pending.error || accepted.error || acceptedSuggestion?.error || removedSuggestion?.error

  const fetchLoading = () =>
    pending.loading || accepted.loading || acceptedSuggestion?.loading || removedSuggestion?.loading

  const globalErrors = useMapGraphQLErrorsMessages(fetchErrors());

  return (
    <Suggestions
      fetchLoading={fetchLoading}
      globalErrors={globalErrors}
      title={pending.data?.getShareSuggestions?.pageTitle}
      accepted={accepted.data?.getAcceptedShareSuggestions}
      pending={pending.data?.getPendingShareSuggestions}
      handleUpdate={handleUpdate}
      setPendingFilters={setPendingFilters}
      setAcceptedFilters={setAcceptedFilters}
    />
  );
}
