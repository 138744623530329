import React, { Component } from "react"
import styled from "styled-components"
import FormField from "../FormField"
import { InputNumber, Select } from "formik-antd"
import { genders } from "src/constants/account"
import { PlusCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button, Divider } from "antd"
import { FieldArray } from "formik"
const RemoveButton = styled(Button)`
  &&& {
    margin-top: 35px;
  }
`

export default class GenderBreakdown extends Component {
  render() {
    const { genderBreakdown } = this.props
    const comparisonOptions = [
      {
        label: "Less than (<)",
        value: "lt"
      },
      {
        label: "Greater than (>)",
        value: "gt"
      }
    ]

    return (
      <FieldArray
        name={`genderBreakdown`}
      >
        {({ insert, remove, push }) => (
          <div>
            <Divider orientation="left">Gender Breakdown</Divider>
            {typeof genderBreakdown !== "undefined" &&
              genderBreakdown.length > 0 &&
              genderBreakdown.map((data, index) => {
                return (
                  <Row gutter={8} key={index}>
                    <Col span={8}>
                      <FormField name={`genderBreakdown[${index}].key`} label="Gender">
                        <Select size="large" data-cy="account-gender" placeholder="Select..." options={genders}>
                        </Select>
                      </FormField>
                    </Col>

                    <Col span={6}>
                      <FormField name={`genderBreakdown[${index}].comparison`} label="comparison">
                        <Select size="large" placeholder="comparison" options={comparisonOptions}>
                        </Select>
                      </FormField>
                    </Col>

                    <Col span={6}>
                      <FormField name={`genderBreakdown[${index}].value`} label="Percentage">
                        <InputNumber size="large" min={0} max={100} />
                      </FormField>
                    </Col>
                    <Col span={4}>
                      <RemoveButton
                        onClick={() => remove(index)}
                        type="primary"
                        shape="circle"
                        icon="close-circle"
                      />
                    </Col>
                  </Row>
                )
              })}
            <Row gutter={8}>
              <Col span={8}></Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={4}>
                <Button
                  onClick={() => push({ key: "", comparison: "", value: "" })}
                  type="primary"
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                />
              </Col>
            </Row>
          </div>
        )}
      </FieldArray>
    );
  }
}
