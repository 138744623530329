import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Col, Typography, Badge } from "antd";
import { Droppable, Draggable } from "react-beautiful-dnd";
import ManageCard from "./ManageCard";
import MiniStats from "./MiniStats";
import { show } from "redux-modal";
import InvitationManage from "src/ducks/InvitationManage";
import CampaignManage from "src/ducks/CampaignManage";
import InfiniteScroll from "react-infinite-scroller";

const GridTitle = styled(Typography)`
  font-size: 16px;
`;

const GridHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
`;

const GridScroll = styled("div")`
  overflow-y: scroll;
  flex-grow: 1;

  /* for Firefox */
  min-height: 0;

  ::-webkit-scrollbar {
    display: none;
  }
`;

class InvitationList extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadInvitations();
    this.timer = null;
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props;
    if (prevProps.search !== search) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.resetInvitations();
      }, 500);
    }
  }

  resetInvitations = () => {
    const { clearAll } = this.props;
    clearAll();
    this.loadInvitations();
  };

  componentWillUnmount() {
    const { clearAll } = this.props;
    clearAll();
  }

  loadInvitations = (page = 1) => {
    const { loadData } = this.props;
    loadData({ page });
  };

  loadMore = page => {
    this.loadInvitations(page);
  };

  render() {
    const {
      moduleKey,
      title,
      data,
      stats,
      show,
      loading,
      pagination: { total_entries, next_page },
    } = this.props;

    return (
      <Col
        style={{
          flexGrow: 1,
          flexBasis: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <GridHeader>
          <GridTitle>{title}</GridTitle>
          <Badge count={total_entries} style={{ backgroundColor: "#6f6f6f" }} />
        </GridHeader>

        <MiniStats stats={stats} />

        <Droppable droppableId={moduleKey}>
          {(provided, snapshot) => (
            <GridScroll
              data-cy={`droppable-${moduleKey}`}
              ref={provided.innerRef}
            >
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMore}
                hasMore={!loading && !!next_page}
                useWindow={false}
              >
                {data.map((invite, index) => {
                  let is_tag_verified = invite?.account_verification
                    ?.is_tag_verified
                    ? "Yes"
                    : "No";
                  let is_screenshot_verified = invite?.account_verification
                    ?.is_screenshot_verified
                    ? "Yes"
                    : "No";
                  let is_breakdown_verified = invite?.account_verification
                    ?.is_breakdown_verified
                    ? "Yes"
                    : "No";
                  return (
                    <Draggable
                      index={index}
                      key={invite.id}
                      draggableId={`${invite.account?.status}:${invite.id}:${is_tag_verified}:${is_screenshot_verified}:${is_breakdown_verified}`}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ManageCard
                            onClick={() => {
                              show("InvitationModal", {
                                invitation_id: invite.id,
                                onUpdate: this.resetInvitations,
                              });
                            }}
                            invite={invite}
                            onRating={rating => {
                              show("InfluencerRatingModal", {
                                accountId: invite.account.id,
                                rating: rating,
                                onRating: this.resetInvitations,
                              });
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </InfiniteScroll>
              {provided.placeholder}
            </GridScroll>
          )}
        </Droppable>
      </Col>
    );
  }
}

const mapStateToProps = (state, props) => ({
  campaign: CampaignManage.campaign.selectOne(state),
  data: InvitationManage[props.moduleKey].selectAll(state),
  loading: InvitationManage[props.moduleKey].selectPending(state),
  pagination: InvitationManage[props.moduleKey].selectPagination(state),
  stats: InvitationManage[props.moduleKey].selectStats(state),
  search: InvitationManage.selectSearch(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  loadData: data =>
    dispatch(InvitationManage.loadInvitationStatus(props.moduleKey)(data)),
  clearAll: () => dispatch(InvitationManage[props.moduleKey].clearAll()),
  show: (...args) => dispatch(show(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationList);
