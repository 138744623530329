import React, { Component } from "react";
import styled from "styled-components";
import FormField from "../FormField";
import { InputNumber } from "formik-antd";
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button } from "antd";
import SearchSelect from "src/shared/SearchSelect";
import { locations } from "src/constants/account";
import { FieldArray } from "formik";
import { ErrorMessage } from "formik";
import FormError from "src/shared/FormField/FormError";

const RemoveButton = styled(Button)`
  &&& {
    margin-top: 35px;
  }
`;

export default class LocationsBreakdown extends Component {
  render() {
    const { locationBreakdown } = this.props;
    return <>
      <FieldArray
        name={`locationBreakdown`}
      >
      {({ insert, remove, push }) => (
          <div>
            {locationBreakdown.length > 0 &&
              locationBreakdown.map((data, index) => {
                return (
                  <Row gutter={8} key={index}>
                    <Col span={8}>
                      <FormField
                        name={`locationBreakdown[${index}].country`}
                        label="Locations"
                      >
                        <SearchSelect
                          size="large"
                          placeholder="Select..."
                          data={locations}
                          local
                        />
                      </FormField>
                    </Col>
                    <Col span={6}>
                      <FormField
                        name={`locationBreakdown[${index}].percentage`}
                        label="Percentage"
                      >
                        <InputNumber size="large" min={0} max={100} />
                      </FormField>
                    </Col>
                    <Col span={4}>
                      <RemoveButton
                        onClick={() => remove(index)}
                        type="primary"
                        shape="circle"
                        icon={<CloseCircleOutlined />}
                      />
                    </Col>
                  </Row>
                );
              })}
            <Row gutter={8}>
              <Col span={8}></Col>
              <Col span={6}></Col>
              <Col span={4}>
                <Button
                  onClick={() => push({ country: "", percentage: "" })}
                  type="primary"
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                />
              </Col>
            </Row>
          </div>
        )}
      </FieldArray>
      <ErrorMessage
        component={FormError}
        name={`locationBreakdown`}
        cy={`locationBreakdown`}
        style={{ marginBottom: "15px" }}
      />
    </>;
  }
}
