// @ts-nocheck
import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { FormikInput } from "src/components/Forms/TextInput";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import { DataSourcePlatform } from "src/generated/ApolloComponents";

interface Props {
  formProps: FormikProps<any>;
}

const SearchInput: React.FC<Props> = ({ formProps }) => {
  const value = formProps?.values;
  return (
    <Box mb={20}>
      <FormikInput
        size="middle"
        borderRadius={3}
        borderColor="grey5"
        name="searchInput"
        placeholder={`${
          value?.platformType === DataSourcePlatform.Fanbytes
            ? "Search username or influencer.."
            : value?.platformType === DataSourcePlatform.Bytesights
            ? "Search on selected criteria"
            : "Search username"
        }`}
        suffix={<SearchOutlined />}
      />
    </Box>
  );
};

export default SearchInput;
