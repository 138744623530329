// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Accounts } from '.'
import {
  useAddAccountsToAdminListMutation,
  useCreateAdminListMutation,
  useDeleteAdminListMutation,
  useRemoveAccountsFromAdminListMutation,
} from "src/generated/ApolloComponents";
import { isUndefined } from 'lodash';
import {AccountsContainer as Props} from './props'

const AccountContainer = (props: Props) => {
  const { createListFlag, refetchFlag, setRefetchFlag, listName, setCreateListFlag, handleCreatedList } = props

  const [createdList, setCreatedList] = useState(null as any)
  const [addList, setAddList] = useState([])
  const [removeList, setRemoveList] = useState([])
  const [resetSelectedRows, setResetSelectedRows] = useState(0)

  useEffect(() => {
    handleCreatedList(createdList)
  },[createdList, handleCreatedList])

  const [ createAdminListMutation, createAdminList ] = useCreateAdminListMutation()
  const [ addAccountsToAdminListMutation, addAccountsMutation ] = useAddAccountsToAdminListMutation()
  const [ removeAccountsFromAdminListMutation, removeAccountsMutation ] = useRemoveAccountsFromAdminListMutation()
  const [ deleteAdminListMutation, deleteList ] = useDeleteAdminListMutation()

  useEffect(() => {
    setCreatedList(createAdminList?.data?.createAdminList)
  },[createAdminList, setCreatedList])

  useEffect(() => {
    if(!createListFlag)
      return
    createAdminListMutation({
      variables: {
        listName: listName as string
      }
    }).then( res => {
      setCreateListFlag(false)
    }).catch(e => console.log('error', e))
  },[createListFlag, listName, createAdminListMutation, setCreateListFlag])

  const manageListAndAccounts = (selectedRowKeys, type) => {
    switch(type){
      case 'add':
        manageAccounts(addAccountsToAdminListMutation, selectedRowKeys, setAddList)
        break
      case 'remove':
        manageAccounts(removeAccountsFromAdminListMutation, selectedRowKeys, setRemoveList)
        break
      case 'delete':
        handleDeleteList()
        break;
    }
  }

  const manageAccounts = (mutation, selectedRowKeys, setList) => {
    const listId = createdList?.id
    if(!listId)
      return
    mutation({
      variables: {
        adminListId: listId,
        accountIds: selectedRowKeys.map(key => Number(key))
      }
    }).then(_ => {
      setResetSelectedRows(resetSelectedRows + 1)
      setList([])
      if(selectedRowKeys.length && !isUndefined(refetchFlag) && !isUndefined(setRefetchFlag)){
        setRefetchFlag(refetchFlag + 1)
      }
    }).catch(e => console.log(e))
  }

  const handleDeleteList = () => {
    const listId = createdList?.id
    if(!listId)
      return
    deleteAdminListMutation({
      variables: {
        adminListId: listId
      }
    }).then(_ => setCreatedList({} as any)) // null or undefined used as initial value
    .catch()
    .finally(() => setCreateListFlag(false))
  }

  const onDragEnd = result => {
    if(!result.destination || result.destination?.droppableId === result.source.droppableId)
      return

    if(result.destination.droppableId === 'listAccounts') {
      manageAccounts(addAccountsToAdminListMutation, [result.draggableId], () => {})
    } else if(result.destination.droppableId === 'other') {
      manageAccounts(removeAccountsFromAdminListMutation, [result.draggableId], () => {})
    }
  }

  return(
    <Accounts
      addList={addList}
      list={createdList}
      onDragEnd={onDragEnd}
      removeList={removeList}
      setAddList={setAddList}
      setRemoveList={setRemoveList}
      manageListAccounts={manageListAndAccounts}
      error={
        createAdminList?.error ||
        addAccountsMutation?.error ||
        removeAccountsMutation?.error ||
        deleteList?.error
      }
      resetSelectedRows={resetSelectedRows}
      {...props}
    />
  )
}

export default AccountContainer
