import { fk, Model } from "redux-orm";

class Suggestion extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Account } = this.session;

    const parsedData = {
      ...data,
      account: Account.parse(data.account),
    };

    return this.upsert(parsedData);
  }
}
Suggestion.modelName = "Suggestion";

Suggestion.fields = {
  account: fk("Account"),
};

export default Suggestion;
