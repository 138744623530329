// @ts-nocheck
import React, { useEffect } from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Radio, Col, Row } from "antd";
import { AmountRadio } from "../../../Wrapper";
import { Box } from "src/components/Core/Box";
import { locations } from "src/constants/account";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { FormikSelect } from "src/components/Forms/Select";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import LinkButton from "src/components/Core/LinkButton";
import { PlusIcon, RemoveIcon } from "../../../Wrapper";
import { AudienceBreakdownComparisonType } from "src/generated/ApolloComponents";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const AdvanceLocationAudience: React.FC<Props> = ({
  formProps,
  clearFilterKey,
}) => {
  let isVisible = false;

  if (
    (formProps?.values?.audienceLocation || []).length > 0 &&
    (formProps?.values?.audienceLocation || [])?.[0]?.key !== ""
  ) {
    isVisible = true;
  }

  let drodownData = locations;
  let dataCount = locations.length;

  drodownData = drodownData.filter((item: any, index) => {
    const newItem = { ...item };
    if (
      !find(
        formProps?.values["audienceLocation"],
        matchesProperty("key", newItem?.value)
      )
    ) {
      return true;
    }
    return false;
  });

  const addData = () => {
    (formProps?.values["audienceLocation"]).push({
      key: "",
      comparison: AudienceBreakdownComparisonType.GreaterEqualThan,
      value: 0,
    });
    formProps?.setFieldValue(
      "audienceLocation",
      formProps?.values["audienceLocation"]
    );
  };

  const removeData = index => {
    (formProps?.values["audienceLocation"]).splice(index, 1);
    formProps?.setFieldValue(
      "audienceLocation",
      formProps?.values["audienceLocation"]
    );
  };

  useEffect(() => {
    let advanceLocationAudience = [] as any;

    if ((formProps?.values?.audienceLocation || []).length > 0) {
      advanceLocationAudience = (formProps?.values?.audienceLocation || []).map(
        item => {
          let container = {} as any;
          container.key = item?.key;
          container.comparison = item?.comparison
            ? item?.comparison
            : AudienceBreakdownComparisonType.GreaterEqualThan;
          container.value = item?.value ? item?.value : 0;
          return container;
        }
      );
    } else {
      advanceLocationAudience = [
        {
          key: "",
          comparison: AudienceBreakdownComparisonType.GreaterEqualThan,
          value: 0,
        },
      ];
    }

    formProps.setFieldValue("audienceLocation", advanceLocationAudience);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={8}>
      <Field
        label="Location:"
        mb={20}
        color="black"
        isBeside={true}
        extra={
          isVisible && (
            <RemoveIcon
              type="close-circle"
              onClick={() => {
                formProps?.setFieldValue("audienceLocation", [
                  {
                    key: "",
                    comparison:
                      AudienceBreakdownComparisonType.GreaterEqualThan,
                    value: 0,
                  },
                ]);
                clearFilterKey("audienceLocation");
              }}
              style={{ marginTop: "-1px", marginLeft: "10px" }}
            />
          )
        }
        id="countryBreakdown"
      >
        {formProps?.values?.["audienceLocation"].map((value, i) => (
          <Box display="flex" key={i} marginBottom={10} mt={10}>
            <Col span={10}>
              <FormikSelect
                name={`audienceLocation[${i}].key`}
                data={locations}
                local
                borderRadius={3}
                borderColor="grey5"
                size="small"
              />
            </Col>

            <Col span={12}>
              <AmountRadio>
                <Radio.Button
                  value={AudienceBreakdownComparisonType.SmallerEqualThan}
                  onChange={v => {
                    if (value?.value || value?.value === 0) {
                      formProps?.setFieldValue(
                        `audienceLocation[${i}].comparison`,
                        AudienceBreakdownComparisonType.SmallerEqualThan
                      );
                    }
                  }}
                  checked={
                    value?.comparison ===
                    AudienceBreakdownComparisonType.SmallerEqualThan
                  }
                >
                  <LeftOutlined />
                </Radio.Button>
                <Radio.Button
                  value={AudienceBreakdownComparisonType.GreaterEqualThan}
                  onChange={v => {
                    if (value?.value || value?.value === 0) {
                      formProps?.setFieldValue(
                        `audienceLocation[${i}].comparison`,
                        AudienceBreakdownComparisonType.GreaterEqualThan
                      );
                    }
                  }}
                  checked={
                    value?.comparison ===
                    AudienceBreakdownComparisonType.GreaterEqualThan
                  }
                >
                  <RightOutlined />
                </Radio.Button>
              </AmountRadio>

              <FormikInputNumber
                parser={value => {
                  let parsed = value.replace(/\[0-9]/g, "");
                  if (parsed) {
                    return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                  } else {
                    return null;
                  }
                }}
                name={`audienceLocation[${i}].value`}
                borderRadius={3}
                borderColor="grey5"
                size="small"
                style={{ marginLeft: 5, width: "72px" }}
              />
            </Col>
            <Col span={2}>
              <RemoveIcon type="close-circle" onClick={() => removeData(i)} />
            </Col>
          </Box>
        ))}
        {drodownData?.length > 0 &&
          dataCount >= formProps?.values?.["audienceLocation"].length + 1 && (
            <LinkButton
              onClick={() => addData()}
              color="blue"
              fontSize={12}
              fontWeight="500"
            >
              <PlusIcon type="plus-circle" /> Add new filter layer
            </LinkButton>
          )}
      </Field>
    </Row>
  );
};

export default AdvanceLocationAudience;
