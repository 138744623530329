import React, { Component } from "react"
import FormField from "../FormField"
import { Select } from "formik-antd"
import { payout_types } from "src/constants/invitations"

export default class PayoutType extends Component {
  render() {
    return (
      <FormField name={this.props.name || "payout_type"} label="Payout Type">
        <Select data-cy="invitation-payout-type" size="large" placeholder="Select..." options={payout_types}>
        </Select>
      </FormField>
    )
  }
}
