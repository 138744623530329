import React, { Component } from "react";
import { withFormik } from "formik";
import { Button, Typography, message, Row, Col } from "antd";
import { Form } from "formik-antd";
import {
  PayoutType,
  DefaultLink,
  RemoveReason,
  Screenshots,
  EstimatedViews,
  Views,
  Stage,
  PayoutCurrency,
  OriginalPayoutPennies,
  Rationale,
} from "src/shared/InvitationFields";
import { object } from "yup";
import idx from "idx";
import FormError from "src/shared/FormField/FormError";
import formatCurrency from "src/utils/formatCurrency";
import currencyRate from "src/utils/currencyRate";
import { Typography as InfoText } from "src/components/Core/Typography";

const Validate = object().shape({});

export class InvitationForm extends Component {
  state = {
    gbp: 0,
  };

  componentDidMount() {
    const { values } = this.props;

    this.onChangeCurrency(values.payout_type_currency);
  }

  onChangeCurrency = async currency => {
    if (currency !== "GBP") {
      const gbp = await currencyRate(currency);

      if (gbp) {
        this.setState({
          gbp: gbp,
        });
      }
    }
  };

  render() {
    const { isSubmitting, values, status, setFieldValue } = this.props;

    return (
      <Form>
        {status &&
          Object.values(status.meta.errors).map((error, i) => (
            <FormError
              key={i}
              style={{ margin: "0 0 10px 0" }}
              message={error}
            />
          ))}
        <DefaultLink />
        <EstimatedViews />
        <PayoutType />

        <Row gutter={8}>
          <Col span={8}>
            <PayoutCurrency
              onChangeCurrency={currency => {
                this.onChangeCurrency(currency);
                setFieldValue("payout_type_currency", currency);
              }}
            />
          </Col>
          <Col span={16}>
            <OriginalPayoutPennies
              title={
                values.payout_type === "fixed" ? "Fixed Payout" : "CPM Payout"
              }
            />
            {values.payout_type_currency !== "GBP" &&
              idx(values, x => x.payout_type_original_pennies) && (
                <InfoText size={14} color="grey9" mt="-25px">
                  which converts to{" "}
                  {formatCurrency(
                    idx(values, x => x.payout_type_original_pennies) *
                      this.state.gbp
                  )}
                </InfoText>
              )}
          </Col>
        </Row>

        {values.payout_type === "cpm" && (
          <>
            <Typography style={{ marginBottom: 24 }}>
              Estimate:{" "}
              {formatCurrency(values.cpm * (values.estimated_views / 1000.0))}
            </Typography>
          </>
        )}

        <Rationale />

        <RemoveReason />
        <Stage />
        <Views />
        <Screenshots />
        <Button
          loading={isSubmitting}
          disabled={isSubmitting}
          htmlType="submit"
          type="primary"
          size="large"
        >
          Update Invitation
        </Button>
      </Form>
    );
  }
}

export default withFormik({
  validateOnBlur: false,
  enableReinitialize: true,

  mapPropsToValues: ({ invitation }) =>
    invitation ? invitation.ref : Validate.cast(),
  validationSchema: () => Validate,

  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus }) => {
    const { updateInvitation, invitation, onUpdate } = props;

    // we add payout_type_currency and  payout_type_original_pennies
    // so base on that backend will calculate cpm and fixed_payout_pennies
    // if we pass that its will directly update so we need to remove when its submit

    delete values.cpm;
    delete values.fixed_payout_pennies;

    updateInvitation(invitation.id, values)
      .then(() => {
        setSubmitting(false);
        message.success("Invitation updated!");
        onUpdate && onUpdate(values);
      })
      .catch(err => {
        setSubmitting(false);
        setStatus(err.response.data);
      });
  },
})(InvitationForm);
