// @ts-nocheck
import React from "react";
import { FormikInput } from "src/components/Forms/TextInput";
import { Field } from "src/components/Forms/Field";
import { RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const HubSpotId: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (formProps?.values?.hubspotDealId) {
    isVisible = true;
  }

  return (
    <Field
      label="HubSpot Deal ID:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("hubspotDealId", "");
              clearFilterKey("hubspotDealId");
            }}
            style={{ marginTop: "-1px", marginLeft: "15px" }}
          />
        )
      }
      id="hubspotDealId"
    >
      <FormikInput
        size="middle"
        borderRadius={3}
        name="hubspotDealId"
        placeholder="HubSpot Deal ID"
      />
    </Field>
  );
};

export default HubSpotId;
