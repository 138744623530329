// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import StyledInput from "./StyledInput";
import { TextInputProps } from "./StyledInput";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";

interface Props {
  name: string;
  disabled?: boolean;
  size?: any;
  onPressEnter?: Function;
  type?: string;
}
type InputProps = Omit<TextInputProps, "size">;

const FormikInput: React.FC<Props & InputProps> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur },
      }) => (
        <>
          <StyledInput
            {...props}
            {...field}
            onBlur={e => {
              handleBlur(e);
            }}
            data-cy={`input-${field.name.replace(" ", "-")}`}
          />
          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={field.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikInput;
