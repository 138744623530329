// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Table } from "src/components/Core/Table";
import { InfluencerAccountListContainer } from "./container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import getColumns from "./Columns";
import withWindowResize from "src/utils/hoc/withWindowResize";

export interface FilterProps {
  platformType?: string;
  searchInput?: string;
  campaign?: string;
  platform?: string;
  gender?: string;
  location?: string;
  includeEstimatedCost?: boolean;
  tagName?: string;
  followers?: number;
  followersIsgreaterThan?: boolean;
  hearts?: number;
  heartsIsgreaterThan?: boolean;
  price?: number;
  priceIsgreaterThan?: boolean;
  locationAudience?: {
    location?: string;
    locationIsgreaterThan?: boolean;
    count?: number;
  };
  genderAudience?: {
    gender?: string;
    genderIsgreaterThan?: boolean;
    count?: number;
  };
  ageAudience?: {
    age?: string;
    ageIsgreaterThan?: boolean;
    count?: number;
  };
  page: number;
}

interface Props {
  allAccounts: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  filters: FilterProps;
  height?: number; // from withWindowResize HOC
}

const MainInfluencerAccountList: React.FC<Props> = ({
  allAccounts,
  loading,
  globalErrors,
  onFilter,
  filters,
  height,
}) => {
  const isCampaignCreatedInLastMonth = createdDate => {
    let d = moment(createdDate);

    const campaignCreateMonth = d.month(); // 1

    const currentMonth = moment().month();

    if (
      currentMonth === 0 &&
      campaignCreateMonth === 11 &&
      moment()
        .subtract(1, "months")
        .month() === campaignCreateMonth
    ) {
      return true;
    } else if (campaignCreateMonth + 1 === currentMonth) {
      return true;
    }
    return false;
  };
  return (
    <Box p={10} background="white">
      <GlobalErrors errors={globalErrors} />

      <Row gutter={16}>
        <Col span={12}>
          <Typography size={12} color="black" position="absolute" mt="10px">
            {allAccounts?.totalEntries} results
          </Typography>
        </Col>
      </Row>
      <Loading spinning={loading}>
        <Table
          columns={getColumns(filters?.platformType)}
          rowKey="accountId"
          dataSource={allAccounts?.searchAccounts || []}
          stripe={true}
          data-cy="table-InfluencerAccountList"
          rowClassName={(record: any) =>
            record?.campaignCount === 0 &&
            isCampaignCreatedInLastMonth(record?.createdAt)
              ? "bluetick"
              : ""
          }
          pagination={{
            position: "both",
            current: allAccounts?.currentPage,
            pageSize: allAccounts?.perPage,
            total: allAccounts?.totalEntries,
            onChange: page => onFilter("page", page),
          }}
          rowHover={true}
          scroll={{ y: height, x: "max-content" }}
        />
      </Loading>
    </Box>
  );
};

const InfluencerAccountList = withWindowResize({ extraHeight: 260 })(
  MainInfluencerAccountList
);

export { InfluencerAccountList, InfluencerAccountListContainer };
