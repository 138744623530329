// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { CampaignsListContainer } from "./container";
import { Loading } from "src/components/Core/Loading";
import { Pagination, Empty } from "antd";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { GroupedCampaignStatusType } from "src/generated/ApolloComponents";
import Stats from "./Stats";
import CampaignCard from "./CampaignCard";
import Filters from "./Filters";

export interface FilterProps {
  title: string;
  status: [GroupedCampaignStatusType];
  order: any;
  page: number;
}

interface Props {
  allCampaigns: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  filters: FilterProps;
  onDelete: (campaignId: Number) => void;
}

const CampaignsList: React.FC<Props> = ({
  allCampaigns,
  loading,
  globalErrors,
  onFilter,
  filters,
  onDelete,
}) => {
  return (
    <>
      <GlobalErrors errors={globalErrors} />

      <Loading spinning={loading}>
        {(allCampaigns?.groupedCampaigns || []).length > 0 ? (
          <>
            <Stats
              totalSpent={allCampaigns?.totalSpent}
              totalRecords={allCampaigns?.campaignNumbers}
              totalViews={allCampaigns?.totalViews}
            />

            <Filters
              handleChange={(key, value) => onFilter(key, value)}
              filters={filters}
            />

            {(allCampaigns?.groupedCampaigns || []).map((campaign, index) => {
              return (
                <CampaignCard
                  campaign={campaign}
                  key={index}
                  destroyCampaign={campaignId => onDelete(campaignId)}
                />
              );
            })}

            <Box mt={20} p={10} textAlign="center">
              <Pagination
                current={allCampaigns?.currentPage}
                pageSize={allCampaigns?.perPage}
                total={allCampaigns?.totalEntries}
                onChange={page => onFilter("page", page)}
              />
            </Box>
          </>
        ) : (
          <Empty description="No campaigns yet" />
        )}
      </Loading>
    </>
  );
};

export { CampaignsList, CampaignsListContainer };
