import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class RemoveReason extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "remove_reason"}
        label="Remove reason"
        help="Why is this influencer getting removed?"
      >
        <Input data-cy="invitation-remove-reason" size="large" placeholder="Remove Reason..." />
      </FormField>
    )
  }
}
