// @ts-nocheck
import React from "react";
import { ScreenshotsContainer } from "./container";
import { UploadInput } from "src/components/Forms/UploadImage";
import styled from "styled-components";
import {
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';

export const ScreenshotsWrapper = styled.div`
  width: 100%;
`;

type ScreenshotsProps = {
  value: any;
  isUploading: boolean;
  handleRemove: (value: any) => void;
  onUpload: (value: any) => void;
};

const Screenshots: React.FC<ScreenshotsProps> = ({
  value,
  isUploading,
  handleRemove,
  onUpload,
}) => {
  return (
    <ScreenshotsWrapper data-cy="input-screenshots">
      <UploadInput
        listType="picture-card"
        accept="image/*"
        fileList={
          value
            ? value.map((x, index) => ({
                uid: index,
                url: x.previewUrl,
              }))
            : []
        }
        onRemove={file => handleRemove(file)}
        customRequest={file => onUpload(file)}
      >
        <div>
          {isUploading ?
            <LoadingOutlined />
            :
            <UploadOutlined />
          }
        </div>
      </UploadInput>
    </ScreenshotsWrapper>
  );
};

export { Screenshots, ScreenshotsContainer };
