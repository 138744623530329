// @ts-nocheck
import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "src/shared/Container";
import withModules from "src/utils/hoc/withModules";
import { default as SharedInfluencerProfile } from "src/shared/InfluencerProfile";
import NoAuthLayout from "../../layouts/NoAuthLayout";
import { InfluencerPersist } from "src/ducks/Persist";
const ShowInfluencer = InfluencerPersist("AdminInfluencerProfile");

const modules = [ShowInfluencer.module()];

type Props = {
  influencer: any;
};

class InfluencerProfile extends Component<Props> {
  render() {
    const { influencer } = this.props;

    return (
      <NoAuthLayout title={influencer ? influencer.name : "Loading..."}>
        <Container style={{ paddingBottom: 30 }}>
          <SharedInfluencerProfile />
        </Container>
      </NoAuthLayout>
    );
  }
}

const mapStateToProps = state => ({
  influencer: ShowInfluencer.selectMethodData("get", "profile")(state),
  loading: ShowInfluencer.selectMethodPending("get", "profile")(state),
});

const mapDispatchToProps = {};

export default withModules(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InfluencerProfile)
)(modules);
