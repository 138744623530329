// @ts-nocheck
import React, { useState } from "react";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import Button from "src/components/Core/Button";
import { Typography, Timeline, Row, Empty, Tag } from "antd";
import { useGetSubmissionInvitationQuery } from "src/generated/ApolloComponents";
import moment from "moment";
import { DraftStatus } from "./components";
import DarftCollapseContent from "./DraftCollapseContent";
import styled from "styled-components";
import { NewDraftFormContainer } from "../NewDraft";
// import { UpdateDraftFormContainer } from "../UpdateDraft";
import renderLastDraftActivity from "./utils/renderLastDraftActivity";

const DeleteDraft = styled(Tag)`
  & {
    background: none !important;
    border: none !important;
    color: #ff4d4f;
  }
`;

interface Props {
  invitation: any;
  deleteDraft: (value?: number) => void;
  fetchInvitations: Function;
}

const DraftContainer: React.FC<Props> = props => {
  const [showNewDraftForm, setShowNewDraftForm] = useState(false);

  const { invitation, deleteDraft, fetchInvitations } = props;

  const { data, loading, refetch } = useGetSubmissionInvitationQuery({
    variables: {
      invitationId: invitation?.id.toString() as string,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const draftData = data?.getSubmissionInvitation;

  let newDrafts = [...(draftData?.drafts || [])] as any;
  if (showNewDraftForm) {
    newDrafts.splice(0, 0, { type: "new_draft" });
  }

  let activeDraftIndex = 0;
  if ((newDrafts || []).length > 0) {
    let indexToChoose = 0;

    if (showNewDraftForm) {
      indexToChoose = 1;
      activeDraftIndex = 0;
    }

    const draft = newDrafts[indexToChoose];
    const activityContent = renderLastDraftActivity(invitation, draft);

    if (activityContent) {
      newDrafts.splice(indexToChoose, 0, {
        type: "activity",
        content: activityContent,
      });
    }

    if (!showNewDraftForm && activityContent) {
      activeDraftIndex = 1;
    }
  }

  return (
    <Loading spinning={loading}>
      <Box mb={30}>
        <Button
          type="action"
          buttonSize="small"
          data-cy="create-new-draft"
          disabled={showNewDraftForm || invitation?.stage === "awaiting"}
          onClick={() => setShowNewDraftForm(true)}
        >
          New draft
        </Button>
      </Box>

      {(newDrafts || [])?.length > 0 ? (
        <Timeline>
          {newDrafts?.map((draft, index) => (
            <Timeline.Item key={index} dot={(draft as any)?.content?.icon}>
              {draft?.type === "activity" ? (
                (draft as any)?.content?.render()
              ) : draft?.type === "new_draft" && showNewDraftForm ? (
                <NewDraftFormContainer
                  cancelDraft={() => setShowNewDraftForm(false)}
                  refetchDrafts={() => refetch()}
                  invitationId={invitation?.id.toString()}
                  fetchInvitations={fetchInvitations}
                />
              ) : (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                      <Typography>
                        {draft?.createdAt
                          ? moment(draft?.createdAt).format("Do MMM YYYY")
                          : ""}{" "}
                        Draft #{(newDrafts || [])?.length - index}
                      </Typography>
                      <DraftStatus status={draft?.status} />
                    </Box>
                    <Box>
                      <ConfirmDeleteIcon
                        text="Delete draft"
                        trigger="click"
                        key="delete-icon"
                        onConfirm={async () => {
                          try {
                            await deleteDraft(draft?.id);
                            refetch();
                          } catch (e) {}
                        }}
                        as={DeleteDraft}
                      />
                    </Box>
                  </Box>

                  {invitation?.stage === "awaiting" &&
                  draft?.status === "approved" ? (
                    <span></span>
                    // <UpdateDraftFormContainer
                    //   refetchDrafts={() => refetch()}
                    //   draft={draft}
                    //   fetchInvitations={fetchInvitations}
                    // />
                  ) : (
                    <Row>
                      <DarftCollapseContent
                        draft={draft}
                        fetchInvitations={fetchInvitations}
                        isActiveDraft={index === activeDraftIndex}
                      />
                    </Row>
                  )}
                </>
              )}
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <Empty />
      )}
    </Loading>
  );
};

export default DraftContainer;
