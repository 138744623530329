// @ts-nocheck
import React from 'react'
import Stats from './Stats'
import Header from './Header'
import Posts from './Posts'
import Loading from 'src/shared/Loading'
import { Box } from 'src/components/Core/Box'
import { AccountContainer } from './container'
import HeroActions from '../components/HeroAction'
import ConnectedAccounts from './ConnectedAccounts'
import DashboardLayout from 'src/scenes/layouts/DashboardLayout'
import {
  GlobalErrorProps,
  GlobalErrors,
} from 'src/components/Forms/ErrorMessage'

interface Props {
  loading: boolean
  data: any
  account: any
  setAccount: Function
  globalErrors?: GlobalErrorProps[]
}

const Account = ({data, loading, account, setAccount, globalErrors}: Props) => {
  return(
    <DashboardLayout title="Influencers">
      <Loading spinning={loading}>
        <HeroActions
          title="Your profile"
          subTitle="This is how your profiile will appear to the campaign manager."
        />
        <Box background="white" mx={25}>
          <GlobalErrors errors={globalErrors} />
          <Header profile={data} />
          <ConnectedAccounts accounts={data?.accounts || []} setAccount={setAccount} selected={account} />
          <Stats account={account} />
          <Posts posts={account?.posts || []} />
        </Box>
      </Loading>
    </DashboardLayout>
  )
}

export { Account, AccountContainer  }
