// @ts-nocheck
import React, { useEffect } from "react";
import { usePaymentDetails, paymentDetail as PaymentDetail } from "../../PaymentDetailsProvider";
import { FormikSelect } from "src/components/Forms/Select";
import find from "lodash/find";
import { bankDetailFields } from "../../PaymentDetails/BankDetailForm/Steps/BankDetailFields";
import { featureFlags } from "src/constants/featureFlags";
interface Props {
  accountId: number;
  campaignId: number;
  accounts: Array<any>;
  allAccounts: Array<any>;
  setAccountData: (value: Array<any>) => void;
  setAllAccountData: (value: Array<any>) => void;
  getAccountsByCampaignId: Function;
  setFieldValue: (key: string, value: any) => void;
  allAccountList: Array<any>;
}

const AccountField: React.FC<Props> = ({
  accountId,
  campaignId,
  accounts,
  allAccounts,
  setAccountData,
  setAllAccountData,
  getAccountsByCampaignId,
  setFieldValue,
  allAccountList,
}) => {
  const { actions } = usePaymentDetails();
  const { isPaypalEnabled } = featureFlags;

  useEffect(() => {
    if (allAccountList?.length > 0) {
      const accountArray = allAccountList.map(item => {
        let container = {} as any;
        container.value = item.id;
        container.label = item.username;
        return container;
      });
      setAllAccountData(allAccountList as any);
      setAccountData(accountArray as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccountList]);

  // set selected Influencer Object
  useEffect(() => {
    if (accountId) {
      let item: any = find(allAccounts, function(obj) {
        return (obj as any)?.id === accountId;
      });

      actions.setSelectedInfluencer(item?.influencer);

      setFieldValue(
        "vatNumber",
        item?.influencer?.detail?.vatNumber
          ? item?.influencer?.detail?.vatNumber
          : ""
      );

      const payoutDetails = item?.influencer?.payoutDetails;
      const bankAccount = item?.influencer?.payoutDetails?.bankAccount;

      let paymentDetail = {};

      if (isPaypalEnabled && payoutDetails?.paypalEmail) {
        paymentDetail = {
          ...paymentDetail,
          paypalEmail: payoutDetails?.paypalEmail,
        };
      }
      //set bank detail fields with loop from backDetail fields
      if (bankAccount) {
        let container = {} as any;
        // if bankaccount has country code then we set as per country wise field
        // else before we not set country code just UK bank detail so we set bydefault that fields
        if (bankAccount?.countryCode) {
          container["countryCode"] = bankAccount?.countryCode;
          bankDetailFields[bankAccount?.countryCode].fields.forEach(field => {
            container[field.name] =
              (bankAccount[field.name] && bankAccount[field.name].toString()) ||
              "";
          });

          paymentDetail = {
            ...paymentDetail,
            bankAccount: container,
          };
        } else {
          paymentDetail = {
            ...paymentDetail,
            bankAccount: {
              fullName: bankAccount?.fullName || "",
              accountNumber: bankAccount?.accountNumber || null,
              sortCode: bankAccount?.sortCode || null,
            },
          };
        }
      }

      actions.setExistingPaymentDetails({
        ...paymentDetail,
      } as PaymentDetail);
      actions.setActualPaymentDetails({ ...paymentDetail } as PaymentDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <FormikSelect
      name="accountId"
      size="large"
      data={accounts || []}
      loadData={async value => {
        let variable: { accountName: string; campaignId?: number } = {
          accountName: value,
        };
        if (campaignId) {
          variable = {
            ...variable,
            campaignId: campaignId as number,
          };
        }
        if (value) {
          let account = await getAccountsByCampaignId(variable);
          if (account?.data?.getAccountsByCampaignId?.length > 0) {
            setAllAccountData({ ...account?.data?.getAccountsByCampaignId });
            setAccountData([]);
            return account?.data?.getAccountsByCampaignId;
          } else {
            return [];
          }
        }
        return [];
      }}
      optionGenerator={value => ({
        value: value.id,
        label: value.username,
      })}
      background="background"
      borderRadius="3px"
      borderColor="grey5"
    />
  );
};
export default AccountField;
