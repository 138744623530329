// @ts-nocheck
import React from 'react'
import { Row, Col } from 'antd'
import { Box } from 'src/components/Core/Box'
import { Typography } from 'src/components/Core/Typography'

interface Props {
  account: any
}

const Stats = ({account}: Props) => {
  let data = account?.metadata
  const stats = [
    {label: 'Avg. Views', value: data?.avgViews, fixed: 0},
    {label: 'Avg. Comments', value: data?.avgComments, fixed: 2},
    {label: 'Avg. Hearts', value: data?.avgLikes, fixed: 2},
    {label: 'Engagement rate', value: data?.engagementRate, fixed: 0},
  ]

  return(
    <Box px={20} pt={36} pb={42}>
      <Row>
        {stats.map((stat, index) =>
          <Col span={6} key={index}>
            <Typography size={14} color='grey7'>{stat.label}</Typography>
            <Typography size={48} color='black' >{stat.value?.toFixed(stat.fixed)}</Typography>
          </Col>
        )}
      </Row>
    </Box>
  )
}

export default Stats
