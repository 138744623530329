// @ts-nocheck
import React from "react";
import { Row, Col, Pagination, Empty } from "antd";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { AccountListContainer } from "./container";
import { Loading } from "src/components/Core/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import AccountCard from "./AccountCard";
import { BreakdownType } from "src/generated/ApolloComponents";
import MatchType from "./MatchType";

export interface FilterProps {
  matchType: BreakdownType;
  page: number;
  limit: number;
  campaignId: number;
}

interface Props {
  suggestions: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  filters: FilterProps;
  onDismiss: (value: any) => void;
  refetchSuggestion: () => void;
  campaign: any;
}

const AccountList: React.FC<Props> = ({
  suggestions,
  loading,
  globalErrors,
  onFilter,
  filters,
  onDismiss,
  refetchSuggestion,
  campaign,
}) => {
  return (
    <Box mb={20} background="white">
      <Loading spinning={loading}>
        <Box borderBottom="1px solid #D9D9D9">
          <Box display="flex" justifyContent="space-between">
            <Typography
              size={18}
              weight="semi-bold"
              color="black"
              lineHeight={0}
              p={15}
              pb={10}
              mt={10}
            >
              Suggested Influencer's
            </Typography>

            <Box display="inline-flex" mt={10} mr={20}>
              <Typography size={14} color="black" lineHeight="28px" mr="5px">
                Match by:
              </Typography>
              <MatchType
                matchType={filters?.matchType}
                handleChange={(key, value) => onFilter(key, value)}
              />
            </Box>
          </Box>
        </Box>

        {(suggestions?.accounts || []).length > 0 ? (
          <>
            <Typography size={14} color="black2" p={15}>
              Based on your selection of influencers so far here are some
              influencers with similar demographics that have not been used
              recently:
            </Typography>

            <Box pl={10} pr={10} background="white">
              <GlobalErrors errors={globalErrors} />

              <Row gutter={12}>
                {(suggestions?.accounts || []).map((account, index) => {
                  return (
                    <Col span={2} key={index}>
                      <AccountCard
                        account={account}
                        campaign={campaign}
                        key={index}
                        onDismiss={value => onDismiss(value)}
                        refetchSuggestion={refetchSuggestion}
                      />
                    </Col>
                  );
                })}
              </Row>

              <Box mt={20} p={10}>
                <Pagination
                  current={suggestions?.currentPage}
                  pageSize={suggestions?.perPage}
                  total={suggestions?.totalEntries}
                  onChange={page => onFilter("page", page)}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box p={60}>
            <Empty description="No data avaialble" />
          </Box>
        )}
      </Loading>
    </Box>
  );
};

export { AccountList, AccountListContainer };
