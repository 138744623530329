// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import LinkButton from "src/components/Core/LinkButton";
import tiktokLogo from "src/assets/images/tiktok_logo.png";
import snapchatLogo from "src/assets/images/snapchat_logo.png";
import youtubeLogo from "src/assets/images/youtube_logo.png";
import instagramLogo from "src/assets/images/instagram_logo.png";
import { Typography } from "src/components/Core/Typography";

interface Props {
  type: string;
  username: string;
  onEdit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onRemove: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const AccountRow = ({ type, username, onEdit, onRemove }: Props) => {
  const typeLogo = (type: string) => {
    switch (type) {
      case "TikTok":
        return tiktokLogo;
      case "Instagram":
        return instagramLogo;
      case "Snapchat":
        return snapchatLogo;
      case "Youtube":
        return youtubeLogo;
    }
  };

  return (
    <Box
      mb={15}
      display="flex"
      height="40px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <Box>
          <img height="32px" src={typeLogo(type)} alt="logo" />
        </Box>
        <Typography margin={0} ml="10px">
          {username}
        </Typography>
      </Box>
      <LinkButton
        weight="semi-bold"
        onClick={onEdit}
        fontSize={14}
        color="blue"
      >
        Edit
      </LinkButton>
      <LinkButton
        weight="semi-bold"
        pr={0}
        fontSize={14}
        onClick={onRemove}
        color="red"
      >
        Remove
      </LinkButton>
    </Box>
  );
};

export default AccountRow;
