// @ts-nocheck
import React, { useState } from "react";
import { TrackingGraph } from ".";
import {
  useGetCampaignAnalyticsTrackingQuery,
  useDeleteCampaignAnalyticsTrackingMutation,
  useCreateCampaignAnalyticsTrackingMutation,
  CampaignTrackingTypeEnum,
} from "src/generated/ApolloComponents";
import { string, object } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { useRouteMatch } from "react-router";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";

interface Props {
  isShowTrack: boolean;
  trackingType: CampaignTrackingTypeEnum;
}

interface NewTrackingFormValues {
  trackingKey: string;
  campaignId: number;
}

export const TrackingGraphContainer: React.FC<Props> = ({
  isShowTrack,
  trackingType,
}) => {
  const match: any = useRouteMatch();

  const params: any = match?.params;

  const campaignId = parseInt(params?.id);

  const {
    data,
    loading,
    error,
    refetch,
  } = useGetCampaignAnalyticsTrackingQuery({
    variables: {
      trackingType: trackingType,
      campaignId: campaignId,
    },
    fetchPolicy: "network-only",
  });

  const allChartsData = data?.getCampaignAnalyticsTracking || [];

  const [isError, setIsError] = useState(false);
  const [success, setIsSuccess] = useState(false);

  const NewTrackingSchema = object().shape({
    trackingKey: string()
      .nullable()
      .required("Please enter a value"),
  });

  let initialValues = {
    trackingKey: "",
    campaignId: campaignId,
  };

  const [
    createCampaignAnalyticsTrackingMutation,
    createTrack,
  ] = useCreateCampaignAnalyticsTrackingMutation();

  const onSubmit = async (
    values: NewTrackingFormValues,
    actions: FormikActions<NewTrackingFormValues>
  ) => {
    setIsSuccess(false);
    setIsError(false);
    try {
      let formData: any = {
        trackingKey:
          (values?.trackingKey).charAt(0) === "#"
            ? (values?.trackingKey).slice(1)
            : values?.trackingKey,
        campaignId: values?.campaignId,
      };

      const res = await createCampaignAnalyticsTrackingMutation({
        variables: formData,
      });
      if (res?.data?.createCampaignAnalyticsTracking?.id) {
        setIsSuccess(true);
        refetch();
      } else {
        setIsError(true);
      }
    } catch (e) {
      setIsError(true);
      actions.setSubmitting(false);
    }
  };

  const [
    deleteCampaignAnalyticsTrackingMutation,
    deleteTrack,
  ] = useDeleteCampaignAnalyticsTrackingMutation();

  const onClickYesRemoveButton = async trackingKey => {
    try {
      let formData: any = {
        trackingKey: trackingKey,
        campaignId: campaignId,
      };

      const res = await deleteCampaignAnalyticsTrackingMutation({
        variables: formData,
      });
      if (res?.data?.deleteCampaignAnalyticsTracking?.id) {
        refetch();
      }
    } catch (e) {}
  };

  const globalErrors = useMapGraphQLErrorsMessages(
    error || createTrack?.error || deleteTrack?.error
  );

  return (
    <TrackingGraph
      allChartsData={allChartsData}
      isEditTrack={isShowTrack}
      initialValues={initialValues}
      NewTrackingSchema={NewTrackingSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      loading={loading || deleteTrack?.loading || createTrack?.loading}
      onClickYesRemoveButton={onClickYesRemoveButton}
      trackingType={trackingType}
      isError={isError}
      success={success}
    />
  );
};
