import React, { Component } from "react"
import { connect } from "react-redux"
import { CompanyPersist } from "src/ducks/Persist"
import { withFormik } from "formik"
import { Form } from "formik-antd"
import Validate from "./Validate"
import { message, Button, Card } from "antd"
import parseDotNotation from "src/utils/parseDotNotation"
import { CompanyName } from "src/shared/CompanyFields"
import Container from "src/shared/Container"

const ShowCompany = CompanyPersist("AdminCompanyShow")

export class CompanyForm extends Component {
  render() {
    const { isSubmitting } = this.props

    return (
      <Container style={{ marginTop: 24, marginBottom: 24 }}>
        <Card>
          <Form data-cy="company-update-form">
            <CompanyName />

            <Button
              type="primary"
              size="large"
              loading={isSubmitting}
              disabled={isSubmitting}
              htmlType="submit"
            >
              Update
            </Button>
          </Form>
        </Card>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  company: ShowCompany.selectOne(state)
})

const mapDispatchToProps = {
  updateCompany: ShowCompany.update
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,

    mapPropsToValues: ({ company }) => (company ? company.ref : Validate.cast()),
    validationSchema: () => Validate,

    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { updateCompany, company } = props

      updateCompany(company.id, values)
        .then((res) => {
          setSubmitting(false)
        })
        .catch((err) => {
          message.error("Failed to update company")
          setSubmitting(false)

          if (err.response.data) setErrors(parseDotNotation(err.response.data.meta.errors))
        })
    }
  })(CompanyForm)
)
