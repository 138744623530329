import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AccountPersist } from "src/ducks/Persist";
import withModules from "src/utils/hoc/withModules";
import Loading from "src/shared/Loading";
import { Card } from "antd";
// import OrganicContent from "src/shared/OrganicContent"
// import BrandedContent from "src/shared/BrandedContent"
// import AccountMini from "src/shared/AccountMini"
// import AccountMetrics from "src/shared/AccountMetrics"
// import AccountLink from "src/shared/AccountLink"
// import AccountStats from "src/shared/AccountStats"
import InfluencerDetail from "src/components/Modules/Influencer/Profile";

const ShowAccount = AccountPersist("ProfileShow");

const modules = [ShowAccount.module()];

class Profile extends Component {
  componentDidMount() {
    const {
      loadAccount,
      match: {
        params: { id },
      },
    } = this.props;
    loadAccount(id, {
      params: {
        expand: ["influencer"],
      },
    });
  }

  render() {
    const { account, loading } = this.props;

    return (
      <Loading spinning={!account || loading}>
        {account && (
          <>
            <Card style={{ marginTop: 24 }}>
              {/* <AccountMini account={account} />
              <AccountMetrics
                style={{
                  marginTop: "14px",
                  paddingLeft: "64px",
                  marginBottom: "30px"
                }}
                account={account}
              />

              <AccountLink account={account} />

              <AccountStats account={account} /> */}

              <InfluencerDetail
                accounts={[account]}
                breakdownLastUpdateDate={
                  account?.influencer?.breakdown_last_update_date
                }
                grid={window.innerWidth >= 1350 ? 2 : 1}
              />
            </Card>

            {/* <Card style={{ marginTop: 24 }}>
              <BrandedContent account={account} />
              <OrganicContent account={account} />
            </Card> */}
          </>
        )}
      </Loading>
    );
  }
}

const mapStateToProps = state => ({
  account: ShowAccount.selectOne(state),
  loading: ShowAccount.selectGetPending(state),
});

const mapDispatchToProps = {
  loadAccount: ShowAccount.get,
};

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
)(modules);
