// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { AnalyseFormContainer } from "./AnalyseForm";
import shield from "src/assets/images/shield.png";
import { useBrandSafetyDetails } from "../BrandSafetyProvider";
import AnalyseLoading from "../AnalyseLoading";

interface Props {
  step: number;
  goNext: () => void;
}

const Analyse: React.FC<Props> = ({ step, goNext }) => {
  const { analyseLoading } = useBrandSafetyDetails();

  return (
    <Box>
      {analyseLoading && <AnalyseLoading />}
      <Box
        width="60%"
        height="350px"
        position="fixed"
        top="20%"
        left="20%"
        backgroundImage={`url(${shield})`}
        backgroundSize="300px 300px"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        p={90}
      >
        <Typography
          size={40}
          textAlign="center"
          color="black"
          lineHeight="10px"
        >
          Brand Safety
        </Typography>
        <Typography size={20} textAlign="center" color="black" mb={40}>
          Moderate, detect and filter any unwanted content in photos, videos, or
          captions from any TikTok account.
        </Typography>
        <AnalyseFormContainer step={step} goNext={goNext} />
      </Box>

      <Box bottom={0} position="absolute" textAlign="center" width="100%">
        <Typography size={14} textAlign="center" color="grey7">
          Powered by Fanbytes
        </Typography>
      </Box>
    </Box>
  );
};

export default Analyse;
