import React, { Component, Fragment } from "react"
import FormField from "../FormField"
import { Checkbox } from "formik-antd"

export default class Instagram extends Component {
  render() {
    return (
      <Fragment>
        <FormField
          name={this.props.name || "instagram"}
          label="Platforms"
          help="Do you want this to share on Instagram?"
        >
          <Checkbox data-cy="link-instagram">Instagram</Checkbox>
        </FormField>
      </Fragment>
    )
  }
}
