// @ts-nocheck
import React, { Fragment, useEffect } from "react";
import { usePrevious } from "react-use";
import { FormikProps } from "formik";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { genders } from "src/constants/account";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { FieldError } from "src/components/Forms/ErrorMessage";

interface Props {
  formProps?: FormikProps<any>;
}

const GenderBreakdown: React.FC<Props> = ({ formProps }) => {
  const prevGenderBreakdown = usePrevious(formProps?.values?.genderBreakdown);

  useEffect(() => {
    const currentGenderBreakdown = formProps?.values?.genderBreakdown;

    if (
      prevGenderBreakdown?.["Male"] !== currentGenderBreakdown?.["Male"] &&
      currentGenderBreakdown?.["Male"] <= 100
    ) {
      const newGenderBreakdown = {};
      newGenderBreakdown["Male"] = currentGenderBreakdown?.["Male"];
      newGenderBreakdown["Female"] = Number(
        100 - currentGenderBreakdown?.["Male"]
      );

      formProps?.setFieldValue("genderBreakdown", newGenderBreakdown);
    }

    if (
      prevGenderBreakdown?.["Female"] !== currentGenderBreakdown?.["Female"] &&
      currentGenderBreakdown?.["Female"] <= 100
    ) {
      const newGenderBreakdown = {};
      newGenderBreakdown["Female"] = currentGenderBreakdown?.["Female"];
      newGenderBreakdown["Male"] = Number(
        100 - currentGenderBreakdown?.["Female"]
      );

      formProps?.setFieldValue("genderBreakdown", newGenderBreakdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formProps?.values?.genderBreakdown]);

  return (
    <Fragment>
      <Typography
        mb={26}
        mt={26}
        fontWeight="semi-bold"
        color="black"
        size={16}
      >
        Gender breakdown
      </Typography>
      <Box display="flex">
        <Box width="50%" mr="5px">
          <Typography size={14} color="grey2">
            Label:
          </Typography>
        </Box>
        <Box width="50%">
          <Typography size={14} color="grey2">
            Percentage:
          </Typography>
        </Box>
      </Box>
      {genders.map((gender, i) => (
        <Box display="flex" key={i} marginBottom={10} mt={10}>
          <Box width="40%" mr="5px">
            {gender.label}:
          </Box>
          <Box width="40%">
            <FormikInputNumber
              parser={value => {
                let parsed = value.replace(/\[0-9]/g, "");
                if (parsed) {
                  return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                } else {
                  return null;
                }
              }}
              name={`genderBreakdown[${gender.label}]`}
              borderRadius={2}
              borderColor="grey5"
              size="middle"
            />
          </Box>
          <Box width="10%" mt={10} ml={10}>
            %
          </Box>
        </Box>
      ))}

      <FieldError errors={formProps?.errors as any} name={`genderBreakdown`} />
    </Fragment>
  );
};
export default GenderBreakdown;
