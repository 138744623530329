// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "antd";
import Container from "src/shared/Container";
import BrandCampaignTrackingForm from "./UpdateTracking/BrandCampaignTrakingForm";
import { TestLinkContainer } from "./TestLink";
import { RouteComponentProps } from "react-router-dom";

const DetailsWrapper = styled(Container)`
  margin-top: 30px;
`;

interface Props {}

export const Tracking: React.FC<Props & RouteComponentProps> = ({ match }) => {
  const [isTrackingLinkExits, setTrackingLinkExits] = useState(false);
  let campaignId = match.params["id"];
  return (
    <DetailsWrapper>
      <Card style={{ marginBottom: 24, marginTop: 24 }}>
        <BrandCampaignTrackingForm
          setTrackingLinkExits={value => setTrackingLinkExits(value)}
        />
        {isTrackingLinkExits && <TestLinkContainer campaignId={campaignId} />}
      </Card>
    </DetailsWrapper>
  );
};

export default Tracking;
