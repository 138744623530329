import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { List, Card, Avatar, Row, Col } from "antd";
import CampaignManage from "src/ducks/CampaignManage";
import { Typography } from "src/components/Core/Typography";
import { Loading } from "src/components/Core/Loading";
import { show } from "redux-modal";
import { DraftMedia } from "src/shared/InvitationModal/DraftsFeedback/components";
import {
  RedraftContent,
  ApproveContentButtons,
  SendFeedbackButtons,
  YoutubeDraftCard,
} from "./components";
import BrandFeedbackFrom from "./BrandFeedbackForm";
import moment from 'moment'

const HighlightCard = styled(props => {
  const { highlight, ...rest } = props;
  return <Card {...rest} />;
})`
  &&& {
    border: ${props => (props.highlight ? "2px solid #ffa75b" : null)};

    .ant-card-head {
      padding: 0px 15px !important;
    }
    .ant-card-body {
      padding: 15px;
    }
    .ant-card-head-title {
      padding: 12px 0px !important;
    }
  }
`;

const StyledTypo = styled(Typography)`
&&& {
  display: contents;
}
`

class ApproveContentCard extends Component {
  state = {
    loading: false,
    isRequestEdit: false,
  };
  formRef = React.createRef(null)

  approveContent = draft => {
    const { draftUpdate } = this.props;
    this.setState({ loading: true });
    draftUpdate(draft.id, {
      status: "approved",
      expand: ["invitation"],
    })
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }));
  };

  getDescription = description => {
    return (
      <>
        <StyledTypo
          size={16}
          weight="semi-bold"
          color="black"
          mb={10}
          mt={10}
          lineHeight={0}
          style={{display: 'content'}}
        >
          Description of content:
        </StyledTypo>

        <Typography size={12} color="black">
          {description}
        </Typography>
      </>
    );
  };

  formattedUpdatedAt = date => {
    return "Updated " + moment(date).fromNow()

  }

  render() {
    const { item, loadMoreInvitations } = this.props;

    if (this.state.loading) {
      return [<Loading loading={this.state.loading} />];
    }

    const firstDraft = item.orderedDrafts().at(0);

    return (
      <>
        {firstDraft && firstDraft.submitted_to_brand && (
          <List.Item data-cy="campaign-content-item">
            <HighlightCard
              highlight={item.stage === "under-review" ? true : false}
              title={
                <Box
                  display="flex"
                  flexDirection='column'
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar src={item.account?.profile_pic_url} shape="circle" />
                    <Typography
                      size={18}
                      weight="semi-bold"
                      color="black2"
                      ml={10}
                      mb={0}
                    >
                      {item.account?.username}
                    </Typography>
                    <Box ml={10}>{item.formattedStage()}</Box>
                  </Box>

                  <Typography size={14} color="grey7" mb={0}>
                    {this.formattedUpdatedAt(firstDraft.updated_at)}
                  </Typography>
                </Box>
              }
            >
              {firstDraft.draft_type === "youtube" ? (
                <YoutubeDraftCard
                  onApprove={() => this.approveContent(firstDraft)}
                  onCancel={() => this.setState({ isRequestEdit: false })}
                  firstDraft={firstDraft}
                  isRequestEdit={this.state.isRequestEdit}
                  loadMoreInvitations={loadMoreInvitations}
                  stage={item.stage}
                  onRequestEdit={value =>
                    this.setState({ isRequestEdit: value })
                  }
                />
              ) : (
                <Row gutter={16} justify="center" align="middle">
                  {firstDraft && firstDraft?.all_media.length > 0 ? (
                    <>
                      <Col span={9}>
                        <DraftMedia allMedia={firstDraft?.all_media} />
                      </Col>
                      <Col span={15}>
                        {firstDraft?.description &&
                          this.getDescription(firstDraft?.description)}

                        {firstDraft?.caption && (
                          <>
                            <Typography
                              size={16}
                              weight="semi-bold"
                              color="black"
                              mb={0}
                              mt={10}
                            >
                              Caption:
                            </Typography>

                            <Typography size={14} color="black">
                              {firstDraft?.caption}
                            </Typography>
                          </>
                        )}

                        {firstDraft?.status !== "approved" && (
                          <>
                            {!this.state.isRequestEdit &&
                              (firstDraft?.brand_feedback || []).length > 0 && (
                                <RedraftContent
                                  feedbacks={firstDraft?.brand_feedback || []}
                                  draftType={firstDraft?.draft_type}
                                />
                              )}

                            {this.state.isRequestEdit && (
                              <BrandFeedbackFrom
                                draft={firstDraft}
                                loadMoreInvitations={loadMoreInvitations}
                                getRef={ref => {
                                  this.formRef.current = ref;
                                }}
                                closeFeedbackForm={() =>
                                  this.setState({ isRequestEdit: false })
                                }
                              />
                            )}
                          </>
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={this.state.isRequestEdit ? 12 : 24}>
                        {this.getDescription(firstDraft.description)}

                        {firstDraft?.status !== "approved" && (
                          <>
                            {!this.state.isRequestEdit &&
                              (firstDraft?.brand_feedback || []).length > 0 && (
                                <RedraftContent
                                  feedbacks={firstDraft?.brand_feedback || []}
                                  draftType={firstDraft?.draft_type}
                                />
                              )}
                          </>
                        )}
                      </Col>

                      {firstDraft?.status !== "approved" && (
                        <Col span={12}>
                          {this.state.isRequestEdit && (
                            <BrandFeedbackFrom
                              draft={firstDraft}
                              loadMoreInvitations={loadMoreInvitations}
                              getRef={ref => {
                                this.formRef.current = ref;
                              }}
                              closeFeedbackForm={() =>
                                this.setState({ isRequestEdit: false })
                              }
                            />
                          )}
                        </Col>
                      )}
                    </>
                  )}
                  {!this.state.isRequestEdit && item.stage !== "approved" && (
                    <Col span={24}>
                      <ApproveContentButtons
                        onRequestEdit={() =>
                          this.setState({ isRequestEdit: true })
                        }
                        onApprove={() => this.approveContent(firstDraft)}
                      />
                    </Col>
                  )}

                  {this.state.isRequestEdit && (
                    <Col span={24}>
                      <SendFeedbackButtons
                        type="simple"
                        onCancel={() => this.setState({ isRequestEdit: false })}
                        onSubmit={() => {
                          document.querySelector('#brandFeedbackForm form button')?.click()
                          // this.formRef.submitForm()
                        }}
                        status={firstDraft.status}
                      />
                    </Col>
                  )}
                </Row>
              )}
            </HighlightCard>
          </List.Item>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  draftUpdate: CampaignManage.draftPersist.update,
  show,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveContentCard);
