import React, { Component } from "react";
import { Row, Col } from "antd";

export default class Container extends Component {
  render() {
    return (
      <Row justify="center" align="top" {...this.props}>
        <Col span={22} style={{ maxWidth: "calc(100% - 32px)" }}>
          {this.props.children}
        </Col>
      </Row>
    );
  }
}
