import React, { Component } from "react";
import { connect } from "react-redux";
import { InfluencerPersist } from "src/ducks/Persist";
import { withFormik } from "formik";
import { Form } from "formik-antd";
import Validate from "./Validate";
import { message, Button, Card, Divider } from "antd";
import idx from "idx";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import parseDotNotation from "src/utils/parseDotNotation";
import {
  Name,
  Email,
  PaypalEmail,
  PhoneNumber,
  Address,
  VatNumber,
  ManageTag,
  Locations,
  Ages,
  Gender,
  Note,
  Agency,
  BankAccount
} from "src/shared/InfluencerFields";
import Container from "src/shared/Container";
import { featureFlags } from "src/constants/featureFlags";

const ShowInfluencer = InfluencerPersist("AdminInfluencerShow");

export class InfluencerForm extends Component {
  render() {
    const { isSubmitting, values } = this.props;

    return (
      <Container style={{ marginTop: 24, marginBottom: 24 }}>
        <Card>
          <Form data-cy="influencer-update-form">
            <Name />
            <Email />
            {featureFlags.isPaypalEnabled && <PaypalEmail />}
            <Divider orientation="left">Bank Detail</Divider>
            <BankAccount bank_account={values.payout_details.bank_account} />
            <Divider />
            <PhoneNumber />
            <Address />
            <VatNumber />
            <Agency />
            <ManageTag />
            <Gender />
            <Ages />
            <Locations />
            <Note />
            {/* <Divider orientation="left">Ages Breakdown</Divider>
            <AgesBreakdown />
            <Divider orientation="left">Gender Breakdown</Divider>
            <GenderBreakdown />
            <Divider orientation="left">Country Breakdown</Divider>
            <LocationsBreakdown countryBreakdown={values.countryBreakdown} /> */}

            <Button
              type="primary"
              size="large"
              loading={isSubmitting}
              disabled={isSubmitting}
              htmlType="submit"
            >
              Update
            </Button>
          </Form>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  influencer: ShowInfluencer.selectOne(state),
});

const mapDispatchToProps = {
  updateInfluencer: ShowInfluencer.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,

    mapPropsToValues: ({ influencer }) => {
      let age = "";
      if (idx(influencer, x => x.ref.detail.age)) {
        age = isString(idx(influencer, x => x.ref.detail.age[0]))
          ? idx(influencer, x => x.ref.detail.age[0])
          : isArray(idx(influencer, x => x.ref.detail.age[0])) &&
            !isArray(idx(influencer, x => x.ref.detail.age[0][0]))
          ? idx(influencer, x => x.ref.detail.age[0][0])
          : "";
      }

      let gender = "";
      if (idx(influencer, x => x.ref.detail.gender)) {
        gender = isString(idx(influencer, x => x.ref.detail.gender[0]))
          ? idx(influencer, x => x.ref.detail.gender[0])
          : isArray(idx(influencer, x => x.ref.detail.gender[0])) &&
            !isArray(idx(influencer, x => x.ref.detail.gender[0][0]))
          ? idx(influencer, x => x.ref.detail.gender[0][0])
          : "";
      }

      let location = "";
      if (idx(influencer, x => x.ref.detail.location)) {
        location = isString(idx(influencer, x => x.ref.detail.location[0]))
          ? idx(influencer, x => x.ref.detail.location[0])
          : isArray(idx(influencer, x => x.ref.detail.location[0])) &&
            !isArray(idx(influencer, x => x.ref.detail.location[0][0]))
          ? idx(influencer, x => x.ref.detail.location[0][0])
          : "";
      }

      return influencer
        ? {
            ...influencer.ref,
            detail: {
              ...((influencer.ref || {}).detail || {}),
              age: age,
              gender: gender,
              location: location,
            },
          }
        : Validate.cast();
    },

    validationSchema: () => Validate,

    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { updateInfluencer, influencer } = props;

      let data;
      data = { ...values };

      if (idx(values, x => x.detail.age) === "") {
        delete data.detail.age;
      } else if (isArray(idx(values, x => x.detail.age))) {
        data.detail.age = idx(values, x => x.detail.age);
      } else if (
        idx(values, x => x.detail.age) !== "" &&
        isString(idx(values, x => x.detail.age))
      ) {
        data.detail.age = [idx(values, x => x.detail.age)];
      }

      if (idx(values, x => x.detail.gender) === "") {
        delete data.detail.gender;
      } else if (isArray(idx(values, x => x.detail.gender))) {
        data.detail.gender = idx(values, x => x.detail.gender);
      } else if (
        idx(values, x => x.detail.gender) !== "" &&
        isString(idx(values, x => x.detail.gender))
      ) {
        data.detail.gender = [idx(values, x => x.detail.gender)];
      }

      if (idx(values, x => x.detail.location) === "") {
        delete data.detail.location;
      } else if (isArray(idx(values, x => x.detail.location))) {
        data.detail.location = idx(values, x => x.detail.location);
      } else if (
        idx(values, x => x.detail.location) !== "" &&
        isString(idx(values, x => x.detail.location))
      ) {
        data.detail.location = [idx(values, x => x.detail.location)];
      }

      updateInfluencer(influencer.id, data)
        .then(res => {
          setSubmitting(false);
        })
        .catch(err => {
          message.error("Failed to update influencer");
          setSubmitting(false);

          if (err.response.data)
            setErrors(parseDotNotation(err.response.data.meta.errors));
        });
    },

    displayName: "InfluencerForm",
  })(InfluencerForm)
);
