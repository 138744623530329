// @ts-nocheck
import React from "react";
import { Formik, FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { Form as AntdForm } from "formik-antd";

interface Props<Values = FormikValues> {
  schema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  children: React.ReactNode;
  initialValues?: Values;
  forwardedRef?: any;
  layout?: string;
  style?: any
}

export const Form: React.FC<Props> = ({
  initialValues,
  schema,
  onSubmit,
  children,
  forwardedRef,
}) => {
  return (
    <Formik
      initialValues={initialValues || schema.cast({})}
      validationSchema={schema}
      onSubmit={onSubmit}
      ref={forwardedRef}
      enableReinitialize
    >
      {formProps => {
        return (
          <AntdForm layout="vertical">
            {typeof children === "function" ? children(formProps) : children}
          </AntdForm>
        );
      }}
    </Formik>
  );
};

const ForwardedForm = React.forwardRef((props: Props, ref) => {
  return <Form {...props} forwardedRef={ref} />;
});

export default ForwardedForm;
