// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import sortBy from "lodash/sortBy";
import maxBy from "lodash/maxBy";

interface Props {
  keywords: Array<any>;
}

const Keywords: React.FC<Props> = ({ keywords }) => {
  const data01 = keywords;

  const data02 = sortBy(data01, "count");

  let maxValue: any = maxBy(data01, "count");

  let newData = data02.map((item: any, index) => {
    const newItem = { ...item };

    let ratio = 200;
    let fontSize = 24;
    if (newItem?.count !== maxValue?.count) {
      ratio = Number((newItem?.count * ratio) / maxValue?.count) as any;
      fontSize = Number((newItem?.count * fontSize) / maxValue?.count) as any;
    }

    newItem.ratio = ratio < 80 ? 80 : ratio;
    newItem.fontSize = fontSize < 9 ? 9 : fontSize;

    return newItem;
  });

  return (
    <Box mt={30}>
      <Typography size={24} color="black">
        Keywords
      </Typography>
      {newData.map((data, i) => (
        <Box
          borderRadius="50%"
          height={`${data.ratio}px`}
          width={`${data.ratio}px`}
          fontSize={`${data.fontSize}px`}
          background="#1890FF"
          textAlign="center"
          verticalAlign="middle"
          justify-content="center"
          alignItems="center"
          display="inline-grid"
          mr={10}
          key={i}
        >
          <Typography color="white" mb={0} style={{ wordBreak: "break-word" }}>
            {data.word}({data.count})
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Keywords;
