import React, { Component } from "react";
import { connect } from "react-redux";
import FormField from "../FormField";
import SearchSelect from "src/shared/SearchSelect";
import { TagList as $TagList } from "src/ducks/DataList";

const TagList = $TagList("AccountTagList");

export class Tag extends Component {
  render() {
    const { loadTags } = this.props;
    return (
      <FormField name={this.props.name || "tags"} label="Interests">
        <SearchSelect
          data-cy="account-interests"
          loadData={value =>
            loadTags({ search: value }).then(res => res.value.data.data)
          }
          optionGenerator={value => ({
            value: value.id,
            label: value.name
          })}
          size="large"
          mode="multiple"
          placeholder="Select..."
        />
      </FormField>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loadTags: TagList.loadMany
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tag);
