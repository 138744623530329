import { InvitationList as $InvitationList } from "./DataList"
import { InvitationPersist } from "./Persist"
import CampaignManage from "./CampaignManage"

const name = "InvitationManage"

const SentList = $InvitationList(name + "Sent")
const ApprovedList = $InvitationList(name + "Approved")
const AcceptedList = $InvitationList(name + "Accepted")
const CompleteList = $InvitationList(name + "Complete")
const RemovedList = $InvitationList(name + "Removed")
const InvitationManage = InvitationPersist(name)

const modules = {
  sent: SentList,
  accepted: AcceptedList,
  approved: ApprovedList,
  complete: CompleteList,
  removed: RemovedList,
  invitation: InvitationManage
}

const initialState = {
  search: null
}

// Types
const types = {
  setSearch: name + "_SET_SEARCH"
}

const actionCreator = (module, data) => {
  return (params) => {
    return (dispatch, getState) => {
      const campaign = CampaignManage.campaign.selectOne(getState())
      const search = selectors.selectSearch(getState())

      return dispatch(
        module.loadMany({
          ...data,
          ...params,
          campaign: campaign.id,
          search: search,
          expand: ["account", "account.influencer","suggestion"],
          order: {
            updated_at: "desc"
          },
          stats: {
            total: ["views", "estimated_views", "payout_type_pennies", "payout_pennies"]
          }
        })
      )
    }
  }
}

// Action creators
const actions = {
  loadInvitationStatus: (status) => actionCreator(modules[status], { status }),
  setSearch: (search) => ({
    type: types.setSearch,
    payload: search
  }),
  updateStatus: (id, from, to, extra = {}) => {
    return (dispatch) => {
      const fromModule = modules[from]
      const toModule = modules[to]

      dispatch(fromModule.clearOne(id))
      dispatch(toModule.addOne(id))

      const response = dispatch(InvitationManage.update(id, { ...extra, status: to }))

      response
        .then(() => {
          dispatch(actions.loadInvitationStatus(from)({ page: 1 }))
          dispatch(actions.loadInvitationStatus(to)({ page: 1 }))
        })
        .catch(() => {
          dispatch(toModule.clearOne(id))
          dispatch(fromModule.addOne(id))
        })

      return response
    }
  }
}

// Reducer
const reducer = (state = initialState, action) => {
  const handlers = {
    [types.setSearch]: (state, action) => ({
      ...state,
      search: action.payload
    })
  }

  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  } else {
    return state
  }
}

// Selectors
const selectors = {
  selectSearch: (state) => state[name].search
}

const InvitationManageModule = () => [
  SentList.module(),
  ApprovedList.module(),
  AcceptedList.module(),
  CompleteList.module(),
  RemovedList.module(),
  InvitationManage.module(),
  CampaignManage.module(),
  {
    id: "InvitationManage",
    reducerMap: {
      InvitationManage: reducer
    }
  }
]

export default {
  ...modules,
  types,
  reducer,
  module: InvitationManageModule,
  ...actions,
  ...selectors
}
