import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Profile from "./Profile";
import { Login } from "./Login";
import Forgot from "./Forgot";
import ResetPassword from "./ResetPassword";
import Complete from "./Complete";
import InfluencerProfile from "./InfluencerProfile";
// import ClaimCashout from "../influencers/ClaimCashout";
import InfluencerHub from "../influencers/InfluencerHub";
import TrackInvitation from "../influencers/TrackInvitation";
// import Invitation from "../influencers/Invitation";
import { InfluencerSignup } from "./InfluencerSignup";
import WelcomeProfile from "../influencers/WelcomeProfile";
import BrandSafety from "./BrandSafety";
import InstantReport from "./InstantReport";
// import InitialContract from "../influencers/InitialContract";
import { SuggestionContainer } from "./SharedSuggestions"
import { SignupContainer } from "src/scenes/influencers/Auth/Signup"
import { ConnectAccountContainer } from "src/scenes/influencers/Auth/ConnectAccounts"
import { PreferencesContainer } from "src/scenes/influencers/Auth/Preferences"
import { LoginContainer } from "src/scenes/influencers/Auth/Login"
import RedirectContainer from 'src/scenes/auth/Redirect/container'
import { TransactionContainer } from 'src/scenes/influencers/TransactionCampaign'

class NoAuthRoutes extends Component {
  render() {
    return (
      <Switch>
        {window.location.host.split('.')[0].includes('influencers') ?
          <Route path="/sign_in" component={LoginContainer} />
          :
          <Route path="/sign_in" component={Login} />
        }
        <Route path="/redirect" exact component={RedirectContainer} />
        <Route path="/sign_up" exact component={SignupContainer} />
        <Route path="/connect-accounts" exact component={ConnectAccountContainer} />
        <Route path="/preferences" exact component={PreferencesContainer} />
        <Route path="/password_resets/:id" exact component={ResetPassword} />
        <Route path="/forgot" exact component={Forgot} />
        <Route path="/complete/:id" exact component={Complete} />

        <Route path="/profiles/:id" component={Profile} />

        <Route path="/influencers/:id/profile" component={InfluencerProfile} />

        <Route
          path="/refer/:referralToken"
          component={props => {
            localStorage.setItem(
              "referral_token",
              props.match.params.referralToken
            );
            return <Redirect to="/influencers/signup" />;
          }}
        />

        {/* <Route path="/claim-cashout/:id/:type?" component={ClaimCashout} /> */}
        <Route path="/invitation/hub/:id" component={InfluencerHub} />
        {/* <Route path="/invitation/:id/:type?" component={Invitation} /> */}
        <Route path="/track_invitation/:id" component={TrackInvitation} />
        <Route path="/influencers/signup" component={InfluencerSignup} />
        <Route path="/welcome-profile/:id" component={WelcomeProfile} />
        <Route path="/brand-safety/" exact component={BrandSafety} />
        {/*
        <Route
          path="/initial-contact/:id/:type"
          exact
          component={InitialContract}
        /> */}

        <Route
          path="/campaigns/instant-report/:id"
          exact
          component={InstantReport}
        />

        {/* Shared Routes Start */}
        <Route path="/sharedsuggestions/:id" exact component={SuggestionContainer} />
        <Route path="/campaigns/:id" exact component={TransactionContainer} />
        {/* Shared Routes End */}

        <Route path="*" component={props => <Redirect to="/sign_in" />} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(NoAuthRoutes));
