import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class TikTokProfile extends Component {
  render() {
    return (
      <FormField name={this.props.name || "metadata.tiktok_profile_url"} label="TikTok Profile Url">
        <Input
          size="large"
          data-cy="account-tiktok-url"
          placeholder="e.g. https://www.tiktok.com/share/user/241714132083900416"
        />
      </FormField>
    )
  }
}
