import React, { Component } from "react";
import { connect } from "react-redux";
import { connectModal } from "redux-modal";
import CampaignManage from "src/ducks/CampaignManage";
import { Modal, Table, Button, message } from "antd";
import { Link } from "react-router-dom";
import ShareSuggestionContainer from "./ShareSuggestion/container"

export class SuggestionsModal extends Component {
  componentDidMount() {
    this.fetchSuggestions(1)
  }

  deleteSuggestion = row => {
    const { destroySuggestion } = this.props;
    destroySuggestion(row.id).catch(() =>
      message.error("Failed to delete suggestion")
    );
  };

  fetchSuggestions = page => {
    const { loadSuggestions, campaign } = this.props;
    loadSuggestions({
      campaign: campaign.id,
      page: page,
      expand: ["account"],
    });
  }

  render() {
    const { show, handleHide, suggestions, pagination, campaign } = this.props;
    const handleChange = (page) => {
      this.fetchSuggestions(page)
    }

    const paginationConfig = () => {
      if(pagination?.total_entries) {
        return {
          current: pagination.current_page,
          pageSize: pagination.per_page,
          total: pagination.total_entries,
          onChange: handleChange,
          hideOnSinglePage: true,
        }
      } else {
        return {}
      }
    }

    const columns = [
      {
        title: "Account",
        render: (text, row, index) => (
          <Link to={`/accounts/${row?.account?.id}`}>
            {index + 1}
            {".   "}
            {row?.account?.username}
          </Link>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Remove",
        render: (text, row) =>
          row.status === "pending" && (
            <Button type="danger" onClick={() => this.deleteSuggestion(row)}>
              Delete
            </Button>
          ),
        dataIndex: "remove",
      },
    ];

    return (
      <Modal
        title="Manage suggestions"
        visible={show}
        footer={null}
        onCancel={handleHide}
        {...this.props}
      >
        <ShareSuggestionContainer campaign={campaign} />
        <Table
          columns={columns}
          rowKey="id"
          pagination={paginationConfig()}
          dataSource={suggestions}
          size="small"
          scroll={{y: 400}}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
  suggestions: CampaignManage.suggestions.selectMany(state),
  pagination: CampaignManage.suggestions.selectPagination(state),
});

const mapDispatchToProps = {
  loadSuggestions: CampaignManage.suggestions.loadMany,
  destroySuggestion: CampaignManage.deleteSuggestion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  connectModal({
    name: "SuggestionsModal",
    destroyOnHide: true,
  })(SuggestionsModal)
);
