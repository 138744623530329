// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { FormikProps } from "formik";
import { LinkButton } from "src/components/Core/LinkButton";
import Name from "./Name";
import Email from "./Email";
import PaypalEmail from "./PaypalEmail";
import { featureFlags } from "src/constants/featureFlags";

interface Props {
  getInfluencers: Function;
  formProps: FormikProps<any>;
}

const CampaignField: React.FC<Props> = ({ getInfluencers, formProps }) => {
  const toggleCreate = () => {
    if (!formProps?.values?.createInfluencer) {
      formProps?.setFieldValue("influencerId", undefined);
    }

    formProps?.setFieldValue(
      "createInfluencer",
      !formProps?.values.createInfluencer
    );
  };
  return (
    <>
      {formProps?.values?.createInfluencer ? (
        <>
          <Name />
          <Email />
          {featureFlags.isPaypalEnabled && <PaypalEmail />}
        </>
      ) : (
        <Field label="Influencer:" mb={26}>
          <FormikSelect
            name="influencerId"
            size="middle"
            loadData={async value => {
              let influencer = await getInfluencers({
                influencerName: value,
              });
              if (influencer?.data?.getAllInfluencers?.length > 0) {
                return influencer.data.getAllInfluencers;
              } else {
                return [];
              }
            }}
            optionGenerator={value => ({
              value: value.id,
              label: value.name,
            })}
            borderRadius={2}
            borderColor="grey5"
          />
        </Field>
      )}

      <LinkButton
        onClick={() => toggleCreate()}
        weight="semi-bold"
        data-cy="create-influencer"
        color="blue"
        style={{ float: "right", marginTop: "-20px" }}
      >
        Or create a new influencer
      </LinkButton>
    </>
  );
};
export default CampaignField;
