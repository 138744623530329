// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react'
import Menu from './Menu'
import { show } from 'redux-modal'
import { connect } from 'react-redux'
import { getApplyFilter } from '../utils'
import { AccountTable as Props } from './props'
import AccountsContainer from './AccountsContainer'
import { AccountsTableContainer } from './container'
import CampaignManage from 'src/ducks/CampaignManage'
import {  GlobalErrors } from 'src/components/Forms/ErrorMessage'
import withWindowResize from 'src/utils/hoc/withWindowResize'
import InviteModal from 'src/scenes/admins/CampaignShow/FindInfluencers/InviteModal'
import { Col } from 'antd'

const MainAccountsTable: React.FC<Props> = (props) => {
  const { globalErrors, filters, clearFilterKey, allTags, isInnerAccountList, refetch,
          resetSelectedRows, campaign, handleSuggestedAccounts, setAddList, setRemoveList,
          adminLists, containerType, list, } = props
  // Find Influencers in Campaigns is inner account list
  // Account from main sidebar is not inner account list

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const hasSelected = selectedRowKeys.length > 0;
  const appliedFilter = getApplyFilter(filters, clearFilterKey, allTags, adminLists, list);

  const menu = account =>
    <Menu
      account={account}
      campaign={campaign}
      refetch={refetch}
      {...props}
   />

  const rowSelection = {
    selectedRowKeys,
    onChange: selected => {
      handleSelectedKeys(selected)
    },
    getCheckboxProps: record => {
      return {
        disabled:  record?.isSuggested
      };
    },
  };

  const handleSelectedKeys = selected => {
    if(containerType === 'listAccounts'){
      setRemoveList && setRemoveList(selected)
    } else {
      setAddList && setAddList(selected)
    }
    setSelectedRowKeys(selected);
  }

  const handleSuggestedClick = () => {
    handleSuggestedAccounts(selectedRowKeys)
    setSelectedRowKeys([])
  }

  const escFunction = useCallback(
    (event) => {
      if(isInnerAccountList)
        return
      if (event.key === "Escape") {
        setSelectedRowKeys([])
        setRemoveList && setRemoveList([])
        setAddList && setAddList([])
      }
  },[isInnerAccountList, setAddList, setRemoveList])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
  },[escFunction])

  useEffect(() => {
    setSelectedRowKeys([])
  },[resetSelectedRows])

  return (
    <>
      {containerType !== "listAccounts" && <GlobalErrors errors={globalErrors} />}

      <Col md={(isInnerAccountList || !list?.id) ? 24 : 12}>
        <AccountsContainer
          appliedFilter={appliedFilter}
          handleSuggestedClick={handleSuggestedClick}
          hasSelected={hasSelected}
          selectedRowKeys={selectedRowKeys}
          rowSelection={(list?.id || isInnerAccountList) && rowSelection}
          menu={menu}
          {...props}
        />
      </Col>

      {isInnerAccountList && <InviteModal />}
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  createSuggestion: CampaignManage.createSuggestion,
  createInvitation: CampaignManage.createInvitation,
  show,
};

const NewAccountsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainAccountsTable);

const AccountsTable = withWindowResize({ extraHeight: 260 })(NewAccountsTable);

export { AccountsTable, AccountsTableContainer };
