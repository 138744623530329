import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class BriefDocument extends Component {
  render() {
    return (
      <FormField name={this.props.name || "brief_doc_link"} label="Brief Document">
        <Input data-cy="brief-document-link" size="large" placeholder="http://docs.google.com/dwkbSHfb748hd" />
      </FormField>
    )
  }
}
