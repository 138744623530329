import React, { Component } from "react";
import { Field } from "formik";
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal } from "antd";
import { Upload as UploadApi } from "src/api/endpoints";
import axios from "axios";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default class ImageUploadField extends Component {
  state = {
    previewVisible: false,
    previewImage: "",
  };

  onUpload = (data, name, form) => {
    const { setFieldValue, setFieldTouched, values } = form;

    this.setState({ loading: true });

    // Big task on rails side, so for now using htis
    // Needs to convert to google cloud storage later.
    return UploadApi.generate({
      name: data.name,
      type: data.type,
    })
      .then(result => {
        var signedUrl = result.data.url;

        var options = {
          headers: {
            "Content-Type": result.data.type,
          },
        };

        axios.put(signedUrl, data, options).then(() => {
          var value = [
            {
              upload_url: result.data.download_url,
              preview_url: result.data.preview_url,
              upload_type: data.type,
            },
          ];

          setFieldValue(
            name,
            values[name] ? values[name].concat(value) : value
          );
          setFieldTouched(name, true);

          this.setState({ loading: false });
        });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleRemove = (file, name, form) => {
    const { setFieldValue, values } = form;
    let valueCopy = values[name].slice();

    if (!file.upload_id) {
      valueCopy.splice(file.uid, 1);
    } else {
      valueCopy[file.uid].delete = true;
      valueCopy[file.uid].status = "error";
    }

    setFieldValue(name, valueCopy);
  };

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  render() {
    const { previewVisible, previewImage } = this.state;

    const uploadButton = (
      <div data-cy={this.props["data-cy"]}>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const name = this.props.name || "screenshots";

    return (
      <>
        <Field name={name}>
          {({ field: { value }, form }) => (
            <Upload
              listType="picture-card"
              accept="image/*"
              fileList={
                value
                  ? value.map((x, index) => ({
                      uid: index,
                      upload_id: x.id,
                      status: x.status,
                      url: x.preview_url,
                    }))
                  : []
              }
              onPreview={this.handlePreview}
              onRemove={file => this.handleRemove(file, name, form)}
              customRequest={({ file }) => this.onUpload(file, name, form)}
            >
              {uploadButton}
            </Upload>
          )}
        </Field>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </>
    );
  }
}
