// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Tag } from "src/components/Core/Tag";

interface Props {
  mentionedAccounts: Array<any>;
}

const MentionedAccounts: React.FC<Props> = ({ mentionedAccounts }) => {
  return (
    <Box mt={30}>
      <Typography size={24} color="black">
        Mentioned Accounts
      </Typography>

      <Box>
        {(mentionedAccounts || []).map((account, i) => (
          <Tag
            key={i}
            size={14}
            tagColor="blue"
            background="white"
            borderColor="white"
            marginBottom="8px"
            style={{ padding: "5px 15px" }}
          >
            <a
              href={account?.accountUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#1890FF" }}
            >
              @{account?.username} ({account?.count})
            </a>
          </Tag>
        ))}
      </Box>
    </Box>
  );
};

export default MentionedAccounts;
