import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { show } from "redux-modal";
import { default as TableManagerDuck } from "src/ducks/TableManager";
import Filters from "./Filters";
import CreateModal from "./CreateModal";
import TableManager from "src/shared/TableManager";
import { AgencyList as $AgencyList } from "src/ducks/DataList";
import withWindowResize from "src/utils/hoc/withWindowResize";

const AgencyList = $AgencyList("AdminAgencyList");
const AgencyFilters = TableManagerDuck("AdminAgencyList");

export class Agencies extends Component {
  loadAgencies = () => {
    const { filters } = this.props;
    return this.props.loadAgencies(filters);
  };

  render() {
    const { agencies, pagination, loading, height } = this.props;

    const columns = {
      agency_name: {
        title: "Agency Name",
        dataIndex: "agency_name",
        sorter: true,
        visible: true,
        width: 300,
        render: (text, row) => <Link to={`/agencies/${row.id}`}>{text}</Link>,
      },
      created_at: {
        title: "Created At",
        dataIndex: "created_at",
        sorter: true,
        visible: true,
        width: 150,
        render: (text, row) => row.formattedCreatedAt(),
      },
    };

    return (
      <TableManager
        name="AdminAgencyList"
        model="Agency"
        columns={columns}
        loadData={this.loadAgencies}
        filterOptions={Filters}
        data={agencies}
        pagination={pagination}
        loading={loading}
        createModal={<CreateModal onCreate={this.loadAgencies} />}
        scroll={{ y: height, x: "max-content" }}
      />
    );
  }
}

const mapStateToProps = state => ({
  filters: AgencyFilters.selectFilters(state),
  agencies: AgencyList.selectMany(state),
  loading: AgencyList.selectPending(state),
  pagination: AgencyList.selectPagination(state),
});

const mapDispatchToProps = {
  show,
  loadAgencies: AgencyList.loadMany,
};

const MainAgencies = connect(mapStateToProps, mapDispatchToProps)(Agencies);

export default withWindowResize({ extraHeight: 260 })(MainAgencies);
