import styled from "styled-components"
import { Radio } from "formik-antd"

export default styled(Radio)`
  && {
    display: block;
    height: 30px;
    line-height: 30px;
    color: #182026;
    font-size: 16px;

    .ant-radio-button {
      margin-right: 8px;
    }
  }
`
