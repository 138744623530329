// @ts-nocheck
import moment from "moment";
import { isBoolean } from "lodash"

export function getInitialValues(filters) {
  let initialValues = {
    title: filters?.title || "",
    hubspotDealId: filters?.hubspotDealId || "",
    status: filters?.status || [],
    platforms: filters?.platforms || [],
    locations: filters?.locations || [],
    startedAfter: filters?.startedAfter ? moment(filters?.startedAfter) : null,
    tags: filters?.tags || [],
    customTags: filters?.customTags || [],
    costOfSales: {
      start: Number(filters?.costOfSales?.start) || 0,
      end: Number(filters?.costOfSales?.end) || 0,
    },
    budget: {
      start: Number(filters?.budget?.start) || 0,
      end: Number(filters?.budget?.end) || 0,
    },

    order: filters?.order || null,
    adminExecId: filters?.adminExecId || undefined,
    isPublic: isBoolean(filters?.isPublic) ? filters?.isPublic : null,
    paymentCurrency: filters?.paymentCurrency || null
  };

  return initialValues;
}

export function getFormvalues(values) {
  let filters = {} as any;

  if (values?.title) {
    filters["title"] = values?.title;
  }

  if (values?.hubspotDealId) {
    filters["hubspotDealId"] = values?.hubspotDealId;
  }

  if ((values?.adminExecId)) {
    filters['adminExecId'] = values?.adminExecId
  }

  if (isBoolean(values?.isPublic)) {
    filters['isPublic'] = values?.isPublic
  }

  if ((values?.status || []).length > 0) {
    filters["status"] = values?.status;
  }

  if ((values?.platforms || []).length > 0) {
    filters["platforms"] = values?.platforms;
  }

  if ((values?.locations || []).length > 0) {
    filters["locations"] = values?.locations;
  }

  if (values?.startedAfter) {
    filters["startedAfter"] = values?.startedAfter.format();
  }

  if ((values?.tags || []).length > 0) {
    filters["tags"] = values?.tags;
  }

  if ((values?.customTags || []).length > 0) {
    filters["customTags"] = values?.customTags;
  }

  if (
    values?.costOfSales &&
    (values?.costOfSales?.start || values?.costOfSales?.end)
  ) {
    filters["costOfSales"] = {
      start: Number(values?.costOfSales?.start),
      end: Number(values?.costOfSales?.end),
    };
  }

  if (values?.budget && (values?.budget?.start || values?.budget?.end)) {
    filters["budget"] = {
      start: Number(values?.budget?.start),
      end: Number(values?.budget?.end),
    };
  }

  filters["page"] = 1;

  if (values?.order) {
    filters["order"] = values?.order;
  }

  if (values?.paymentCurrency) {
    filters["paymentCurrency"] = values?.paymentCurrency
  }

  return filters;
}

export function shouldShowClearAllFilter(filters) {
  let show = false;

  if (
    filters?.title ||
    filters?.hubspotDealId ||
    (filters?.status || []).length > 0 ||
    (filters?.platforms || []).length > 0 ||
    (filters?.locations || []).length > 0 ||
    filters?.startedAfter ||
    (filters?.tags || []).length > 0 ||
    (filters?.customTags || []).length > 0 ||
    (filters?.budget && (filters?.budget?.start || filters?.budget?.end)) ||
    (filters?.costOfSales &&
      (filters?.costOfSales?.start || filters?.costOfSales?.end)
    ) ||
    filters?.paymentCurrency
  ) {
    show = true;
  }

  return show;
}
