// @ts-nocheck
import React, { Fragment } from 'react'
import { FilterForm } from '.'
import { object } from 'yup'
import {
  useGetAllTagsQuery
} from 'src/generated/ApolloComponents'
import { FormikValues } from 'formik'

interface Props {
  handleFilter: Function
  initialValues: FormikValues
}

export const FilterContainer = ({handleFilter, initialValues}: Props) => {
  const tags = useGetAllTagsQuery()

  const FilterFormSchema = object().shape({})

  return(
    <Fragment>
      <FilterForm
        FilterSchema={FilterFormSchema}
        allTags={tags?.data?.getAllTags || []}
        tagsLoading={tags?.loading}
        handleFilter={handleFilter}
        initialValues={initialValues}
      />
    </Fragment>
  )
}
