// @ts-nocheck
import React from "react";
import { AlignLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Menu } from "antd";
import { useLocation, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

const SideMenu = () => {
  const match: any = useRouteMatch();
  const params: any = match?.params;
  const id = parseInt(params.id);
  const location: any = useLocation();

  const campaignPath = `/campaign-group/${id}`;

  return (
    <Menu
      mode="vertical"
      selectedKeys={[location.pathname]}
      style={{ height: "100%" }}
    >
      <Menu.Item key={campaignPath}>
        <Link data-cy="group-details" to={campaignPath} replace>
          <AlignLeftOutlined />
          <span>Group Details</span>
        </Link>
      </Menu.Item>

      <Menu.Item key={campaignPath + "/update"}>
        <Link data-cy="update-group" to={campaignPath + "/update"} replace>
          <EditOutlined />
          <span>Update Group</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default SideMenu;
