// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { FormikRatingInput } from "src/components/Forms/Rating";
import { Box } from "src/components/Core/Box";

interface Props {}

const Professionalism: React.FC<Props> = () => {
  return (
    <Box mb={26}>
      <Typography size={20} fontWeight="semi-bold" color="black" lineHeight={0}>
        Professionalism
      </Typography>
      <Typography size={14} color="grey7">
        Did your influencer conduct themsleves professinally throughout your
        campaign
      </Typography>

      <FormikRatingInput name="professionalism" size={24} />
    </Box>
  );
};

export default Professionalism;
