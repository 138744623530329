// @ts-nocheck
import React, { useState } from "react";
import { AverageLikesGraph } from ".";
import {
  useGetAverageHeartsByAccountIdQuery,
  LineChartFilter,
  DataSourcePlatform,
} from "src/generated/ApolloComponents";

interface Props {
  accountId?: string;
  username: string;
  platformType: string;
}

export const AverageLikesGraphContainer: React.FC<Props> = ({
  accountId,
  username,
  platformType,
}) => {
  const [period, setPeriod] = useState("HalfYear");

  let queryData = {
    username: username,
    filter: period as LineChartFilter,
    platformType: platformType as DataSourcePlatform,
  } as any;

  if (accountId) {
    queryData["accountId"] = accountId;
  }

  const { data, loading, error } = useGetAverageHeartsByAccountIdQuery({
    variables: queryData,
  });

  const graphData = data?.getAverageHeartsByAccountId || [];
  let lastItem = graphData.filter(x => x?.value != null).slice(-1)[0];

  if (!loading && (error || graphData?.length === 0)) return null;

  return (
    <AverageLikesGraph
      loading={loading}
      data={graphData}
      period={period}
      setPeriod={value => setPeriod(value)}
      titleValue={lastItem?.value}
    />
  );
};
