// @ts-nocheck
import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import {
  useGetAllGroupedCampaignsQuery,
  useGetAllTagsQuery,
  useGetAllAdminsQuery
} from "src/generated/ApolloComponents";
import qs from "qs";
import { getFilterData } from "./utils";

interface ContextType {
  isLoading: boolean;
  listErrors?: any;
  allTags?: any;
  admins?: any;
  campaignsListDetails?: any;
  filters?: any;
  actions: {
    refetchCampaigns: (value: any) => void;
    setFilters: (value: any) => void;
  };
}

const CampaignsListContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our influencer detail context
export function useCampaignsListDetails() {
  const context = React.useContext(CampaignsListContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
const BaseCampaignsListProvider = React.memo((props: any) => {
  const history: any = useHistory();
  const location: any = useLocation();

  const match: any = useRouteMatch();
  const params: any = match?.params;

  const parsed = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // need to convert string and boolean value from query string
  const formatFilters = getFilterData(parsed);
  //const formatFilters = parsed;
  const initialFilters = {
    page: 1,
  };

  if ((match?.path || "").search("companies") && parseInt(params.id)) {
    initialFilters["companyId"] = parseInt(params?.id);
  }

  const [filters, setFilters] = React.useState({
    ...initialFilters,
    ...formatFilters,
  });

  const { data, loading, error, refetch } = useGetAllGroupedCampaignsQuery({
    variables: filters,
    fetchPolicy: "network-only",
  });

  const campaignData = data?.getAllGroupedCampaigns;
  // refetch Campaigns
  const refetchCampaigns = filterData => {
    // remove null element from object

    const newFilterData = Object.keys(filterData)
      .filter(e => filterData[e] !== null)
      .reduce((o, e) => {
        o[e] = filterData[e];
        return o;
      }, {});

    history.push({
      search: qs.stringify(newFilterData, {
        skipNulls: true,
      }),
    });
    refetch(newFilterData as any);
  };

  const tags = useGetAllTagsQuery();
  const admins = useGetAllAdminsQuery();

  const allTags = tags?.data?.getAllTags || [];

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      allTags,
      admins,
      campaignsListDetails: campaignData,
      isLoading: loading,
      listErrors: error,
      filters,
      actions: {
        refetchCampaigns,
        setFilters,
      },
    };
    return internalVal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error, filters]);

  return (
    <CampaignsListContext.Provider value={value}>
      {props.children}
    </CampaignsListContext.Provider>
  );
});

const CampaignsListProvider = React.memo(BaseCampaignsListProvider);

export { CampaignsListProvider };
