// @ts-nocheck
import React, { useState } from "react";
import { GeneralTab } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useGetCampaignAnalyticQuery } from "src/generated/ApolloComponents";
import { useRouteMatch } from "react-router";
import { WorkerPersist } from "src/ducks/Persist";
import { connect } from "react-redux";

const WorkerManage = WorkerPersist("GeneralAnalytics");

type Props = {
  onChangeActiveKey: Function;
  onViewPerformance: Function;
  refreshAnalytics: Function;
};

const GeneralTabContainer: React.FC<Props> = ({
  onChangeActiveKey,
  onViewPerformance,
  refreshAnalytics,
}) => {
  const [refreshing, setRefreshing] = useState(false)
  const match: any = useRouteMatch();

  const params: any = match?.params;

  const campaignId = parseInt(params?.id);

  const { data, loading, error, refetch } = useGetCampaignAnalyticQuery({
    variables: {
      campaignId: campaignId,
    },
  });

  const analyticsData = data?.getCampaignAnalytic || {};

  const globalErrors = useMapGraphQLErrorsMessages(error);

  const refreshAnalyticsData = () => {
    setRefreshing(true)
    refreshAnalytics("post", "run_campaign_analytics", {campaign_id: campaignId})
      .then(res => refetch())
      .catch(err => console.log(err))
      .finally(() => setRefreshing(false))
  }

  return (
    <GeneralTab
      data={analyticsData}
      globalErrors={globalErrors}
      loading={loading || refreshing}
      refresh={refreshAnalyticsData}
      refreshing={refreshing}
      onChangeActiveKey={onChangeActiveKey}
      onViewPerformance={onViewPerformance}
    />
  );
};

const mapDispatchToProps = {
  refreshAnalytics: WorkerManage.customMethod,
};

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralTabContainer);
