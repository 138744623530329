// @ts-nocheck
import styled from "styled-components";
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  border,
  BorderProps,
  background,
  BackgroundProps,
  position,
  PositionProps,
} from "styled-system";
import { ThemeColors } from "src/utils/theme";

interface Props {
  color?: ThemeColors;
}

export type BoxProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  TypographyProps &
  ShadowProps &
  BorderProps &
  BackgroundProps &
  PositionProps;

export const Box = styled.div<BoxProps & Props>`
  color: ${props => props.color && props.theme.colors[props.color]};
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
  ${shadow}
  ${border}
  ${background}
  ${position}
`;
