// @ts-nocheck
import React from "react";
import { Menu } from "antd";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { useInfluencerDetails } from "../../InfluencerProfileProvider";

const InfluencerMenu = styled(Menu)`
  && {
    .ant-menu-item > a {
      color: ${props => props.theme.colors.grey6};
    }

    .ant-menu-item > a:hover {
      color: ${props => props.theme.colors.blue};
    }

    .ant-menu-item-selected > a,
    .ant-menu-item-selected > a:hover {
      color: ${props => props.theme.colors.black};
    }
  }
  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff !important;
  }
`;

const SideMenu = ({ location }: RouteComponentProps) => {
  const { influencerId, platformType } = useInfluencerDetails();
  const influencerPath = `/influencer-profile/${platformType}/${influencerId}`;

  return (
    <InfluencerMenu
      mode="vertical"
      selectedKeys={[location.pathname]}
      style={{ height: "100%" }}
    >
      <Menu.Item key={influencerPath + "/campaigns"}>
        <Link
          data-cy="campaign-list"
          to={influencerPath + "/campaigns"}
          replace
        >
          <span>Campaigns</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={influencerPath + "/notes"}>
        <Link data-cy="notes-list" to={influencerPath + "/notes"} replace>
          <span>Notes</span>
        </Link>
      </Menu.Item>
    </InfluencerMenu>
  );
};

export default withRouter(SideMenu);
