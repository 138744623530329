// @ts-nocheck
import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const VideoPlayer = styled(ReactPlayer)`
  && {
    video {
      display: block;
      height: auto;
      width: 100%;
    }
  }
`;

interface Props {
  videoUrl: string;
  isPlay: any;
  shouldShowControls?: boolean | false;
  style?: React.CSSProperties;
  getRef?: Function;
  onPlay?: Function;
  onPause?: Function;
  height?: any;
}

const ReactVideoPlayer: React.FC<Props> = ({
  videoUrl,
  isPlay,
  shouldShowControls,
  style,
  getRef,
  onPlay,
  onPause,
  height,
}) => {
  return (
    <VideoPlayer
      ref={getRef && (getRef as any)}
      style={style}
      width="100%"
      height={height ? height : "100%"}
      url={videoUrl}
      muted
      playing={isPlay}
      controls={shouldShowControls}
      onPlay={onPlay}
      onPause={onPause}
    />
  );
};

export default ReactVideoPlayer;
