// @ts-nocheck
import { Col, Button } from 'antd'
import styled from 'styled-components'

export const StyledCol = styled(Col)`
  border-radius: 3px;
  background: #FFFFFF;
`

export const StyledButton = styled(Button)`
  margin-left: 40px;
  margin-bottom: 36px;
  width: 132px;
  height: 37px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.157439) !important;
  border-radius: 2px;
`
