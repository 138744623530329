// @ts-nocheck
import React from "react";
import { LinkOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { Box } from "src/components/Core/Box";

interface Props {
  accountData: any;
}

const PlatformHref = styled("a")`
  && {
    color: ${props => props.theme.colors.grey8};
  }
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`;

const PlatformLink: React.FC<Props> = ({ accountData }) => {
  return (
    <Box ml={15} color="grey6" lineHeight="35px">
      {accountData?.type === "TikTok" ? (
        <PlatformHref
          href={
            accountData?.metadata?.tiktokProfileUrl
              ? accountData?.metadata?.tiktokProfileUrl
              : `https://www.tiktok.com/@${accountData?.username}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOutlined />
        </PlatformHref>
      ) : (
        ""
      )}
      {accountData?.type === "Instagram" ? (
        <PlatformHref
          href={
            accountData?.metadata?.instagramProfileUrl
              ? accountData?.metadata?.instagramProfileUrl
              : `http://instagram.com/${accountData?.username}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOutlined />
        </PlatformHref>
      ) : (
        ""
      )}
      {accountData?.type === "YouTube" ? (
        <PlatformHref
          href={
            accountData?.metadata?.youtubeProfileUrl
              ? accountData?.metadata?.youtubeProfileUrl
              : `https://www.youtube.com/${accountData?.username}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOutlined />
        </PlatformHref>
      ) : (
        ""
      )}
    </Box>
  );
};

export default PlatformLink;
