import { fk, Model, attr } from "redux-orm";

class Brand extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Company } = this.session;

    const parsedData = {
      ...data,
      company: Company.parse(data.company)
    };

    return this.upsert(parsedData);
  }
}
Brand.modelName = "Brand";

// Declare your related fields.
Brand.fields = {
  id: attr(),
  company: fk("Company")
};

export default Brand;
