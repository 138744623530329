import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "src/shared/Table";
import { Button } from "antd";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import TableManager from "src/ducks/TableManager";
import CreateModal from "../Accounts/CreateModal";
import { show } from "redux-modal";
import { AccountList as $AccountList } from "src/ducks/DataList";
import columns from "../Accounts/Columns";
import filters from "../Accounts/Filters";
import withWindowResize from "src/utils/hoc/withWindowResize";

const AccountTable = TableManager("InfluencerAccountList");
const AccountList = $AccountList("InfluencerAccountList");

const modules = [AccountTable.module(), AccountList.module()];

export class AccountsTable extends Component {
  loadAccounts = filters => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.loadAccounts({
      influencer: id,
      ...filters,
    });
  };

  render() {
    const {
      data,
      pagination,
      loading,
      style,
      currentFilters,
      show,
      height,
      match: {
        params: { id },
      },
    } = this.props;

    return (
      <>
        <Table
          style={style}
          title="Accounts"
          columns={columns}
          loadData={this.loadAccounts}
          extra={[
            <Button
              type="primary"
              key="create"
              data-cy="influencer-account-create-button"
              onClick={() => show(`CreateAccountModal`)}
            >
              Create new Account
            </Button>,
          ]}
          name="InfluencerAccountList"
          pagination={pagination}
          data={data}
          filterOptions={filters}
          loading={loading}
          size="middle"
          scroll={{ y: height, x: "max-content" }}
        />

        <CreateModal
          initialValues={{ influencer: parseInt(id) }}
          hideInfluencer={true}
          onCreate={() => this.loadAccounts(currentFilters)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentFilters: AccountTable.selectFilters(state),
  data: AccountList.selectMany(state),
  pagination: AccountList.selectPagination(state),
  loading: AccountList.selectPending(state),
});

const mapDispatchToProps = {
  loadAccounts: AccountList.loadMany,
  show,
};

const MainAccountsTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainAccountsTable);
