// @ts-nocheck
import React from "react";
import { Checkbox } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";

interface Props {
  formProps?: FormikProps<any>;
}

const IsVATRegister: React.FC<Props> = ({ formProps }) => {
  return (
    <Box display="inline-flex">
      <Checkbox
        name="isVATRegister"
        onChange={() => {
          formProps?.setFieldTouched("isVATRegister");
          formProps?.setFieldValue(
            "isVATRegister",
            !formProps?.values.isVATRegister
          );
        }}
        value={formProps?.values.isVATRegister}
        data-cy="checkbox-isVATRegister"
      />
      <Typography size={12} color="grey9" ml={10} mt={0.9}>
        Influencer is VAT registered
      </Typography>
    </Box>
  );
};

export default IsVATRegister;
