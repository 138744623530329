// @ts-nocheck
import React from 'react'
import { TermsAndConditions } from '.'
import {
  useGetPublicCampaignQuery
} from 'src/generated/ApolloComponents'
import {
  useMapGraphQLErrorsMessages
} from 'src/utils/hooks/useMapGraphQLErrors'

export const TermsAndConditionsContainer = ({match: { params }}) => {
  const { data, loading, error } = useGetPublicCampaignQuery({
    variables: {campaignId: Number(params?.id)},
  });

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return(
    <TermsAndConditions
      campaign={data?.getPublicCampaign?.publicCampaign || {}}
      loading={loading}
      error={globalErrors}
    />
  )
}
