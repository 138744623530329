import axiosInstance from "../axiosInstance";

const GET_PAYOUT = `/payouts`;
const CREATE_PAYOUT = `/payouts`;
const getPayout = id => `/payouts/${id}`;

export const index = data => axiosInstance.get(GET_PAYOUT, data);

export const create = data => axiosInstance.post(CREATE_PAYOUT, data);

export const get = (id, data) => axiosInstance.get(getPayout(id), data);
