// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { NewDraftFormContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { Field } from "src/components/Forms/Field";
import {
  DraftFormat,
  Caption,
  Description,
  YoutubeDraftUrl,
} from "./FormFields";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { UploadDraftContainer } from "./FormFields/UploadDraft";

interface NewDraftFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  NewDraftSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  cancelDraft: () => void;
}

const NewDraftForm: React.FC<NewDraftFormProps> = ({
  initialValues,
  isSubmitting,
  NewDraftSchema,
  onSubmit,
  globalErrors,
  cancelDraft,
}) => {
  return (
    <Box width="100%">
      <Loading spinning={isSubmitting}>
        <Box display="flex" justifyContent="space-between">
          <Typography size={16} weight="semi-bold" color="black">
            New Draft
          </Typography>

          <Typography
            size={12}
            color="red5"
            onClick={() => {
              cancelDraft();
            }}
            cursor="pointer"
          >
            Cancel draft{"  "} <DeleteOutlined />
          </Typography>
        </Box>

        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={NewDraftSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {/* @ts-ignore */}
          {(formProps: any) => {
            const value = formProps?.values;

            return (
              <>
                <DraftFormat />

                {value?.draftType === "youtube" && <YoutubeDraftUrl />}

                {value?.draftType === "text" && <Description />}

                {value?.draftType === "media" && (
                  <Field label="Content:">
                    <Row gutter={24}>
                      <Col span={12}>
                        <UploadDraftContainer
                          name="drafts"
                          setFieldValue={value =>
                            formProps?.setFieldValue("drafts", value)
                          }
                          setFieldTouched={() =>
                            formProps?.setFieldTouched("drafts", true)
                          }
                          values={formProps?.values}
                        />
                      </Col>
                      <Col span={12}>
                        <Caption />
                      </Col>
                    </Row>
                  </Field>
                )}

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={50}
                >
                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    buttonSize="small"
                    data-cy="submit-button"
                  >
                    Submit draft
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </Box>
  );
};

export { NewDraftForm, NewDraftFormContainer };
