// @ts-nocheck
import React from "react";
import { Button } from "src/components/Core/Button";
import { Box } from "src/components/Core/Box";

interface Props {
  onSubmit: Function;
  onCancel: Function;
  type: string;
  status: string;
}

const SendFeedbackButtons: React.FC<Props> = ({
  onSubmit,
  onCancel,
  type,
  status,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" mt={20} bottom={0}>
      {type !== "youtube" && (
        <Button
          onClick={onCancel}
          mr={20}
          buttonSize="small"
          style={{
            border: "1px solid #FF4D4F",
            color: "#FF4D4F",
          }}
        >
          Cancel
        </Button>
      )}

      <Button
        type="action"
        buttonSize="small"
        data-cy="submit-button"
        onClick={onSubmit}
      >
        Send feedback
      </Button>
    </Box>
  );
};

export default SendFeedbackButtons;
