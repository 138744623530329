// @ts-nocheck
import React, { useState } from 'react'
import AccountsContainer from './container'
import { AccountsList } from './AccountsList'
import Stats from './Stats'
import AdminList from './AdminList'
import { DragDropContext } from 'react-beautiful-dnd'
import { Accounts as Props } from './props'
import { Row } from 'antd'

const Accounts = (props: Props) => {
  const { list, onDragEnd } = props

  const [allAccounts, setAllAccounts] = useState({} as any)
  let containers = ['allAccounts'] as any
  if(list?.id) {
    containers.push("listAccounts")
  }

  return(
    <div>
      <Stats
        verifiedViews={allAccounts?.verifiedViews || 0}
        verifiedInfluencers={allAccounts?.verifiedInfluencers || 0}
        totalRecords={allAccounts?.totalRecords || 0}
        totalViews={allAccounts?.totalViews || 0}
      />
      <AdminList {...props} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          {containers.map((containerType, index) =>
            <AccountsList
              key={index}
              containerType={containerType}
              setAllAccounts={setAllAccounts}
              {...props}
            />
          )}
        </Row>
      </DragDropContext>
    </div>
  )
}

export { Accounts, AccountsContainer }
