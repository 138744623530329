import axiosInstance from "../axiosInstance";

const GET_BRANDS = `/brands`;
const CREATE_BRAND = `/brands`;
const updateBrand = id => `/brands/${id}`;
const deleteBrand = id => `/brands/${id}`;

export const index = data => axiosInstance.get(GET_BRANDS, data);

export const create = data => axiosInstance.post(CREATE_BRAND, data);

export const destroy = id => axiosInstance.delete(deleteBrand(id));

export const update = (id, data) => axiosInstance.patch(updateBrand(id), data);
