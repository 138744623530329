// @ts-nocheck
import React from "react";
import { object, string, date } from "yup";
import { AboutProduct } from ".";
import {
  UpdateGiftingCampaign,
  GiftingCampaignFragmentFragment,
} from "src/generated/ApolloComponents";
import { FormikHelpers as FormikActions } from "formik";

interface Props {
  nextStep: Function;
  campaign?: GiftingCampaignFragmentFragment;
  initialValues?: UpdateGiftingCampaign;
  loading: boolean;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  setStep: (number: number) => void;
}

export const AboutProductContainer = ({
  setStep,
  onSubmit,
  initialValues,
  loading,
  campaign,
}: Props) => {
  const AboutProductSchema = object().shape({
    title: string().required("You must complete this field"),
    image: string().required("Please upload an image"),
    startDate: date().required(),
    endDate: date(),
    brief: object().shape({
      linkToWebsite: string().required(
        "You must provide a link to your website"
      ),
      aboutBrand: string().required("You must complete this field"),
      brandName: string().required("You must complete this field"),
    }),
  });

  return (
    <AboutProduct
      onSubmit={onSubmit}
      loading={loading}
      campaign={campaign}
      initialValues={initialValues}
      setStep={setStep}
      schema={AboutProductSchema}
    />
  );
};
