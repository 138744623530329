// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";

interface Props {}

const Views: React.FC<Props> = () => {
  return (
    <Field label="View Count" mb={26}>
      <FormikInputNumber
        parser={value => {
          let parsed = value.replace(/\[0-9]/g, "");
          if (parsed) {
            return parsed.match(/\d+/g) ? parseInt(parsed) : "";
          } else {
            return null;
          }
        }}
        name="viewCount"
        size="middle"
        borderRadius={3}
        borderColor="grey8"
        placeholder="Please enter the amount of views your post got "
      />
    </Field>
  );
};

export default Views;
