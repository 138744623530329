// @ts-nocheck
import styled from "styled-components";

export const DraftWrapper = styled("div")`
   {
    position: relative;
    width: 100%;
    border: 1px solid #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
  }
`;

export const FeedbackWrapper = styled("div")`
   {
    position: relative;
    min-height: 300px;
    width: 100%;
  }
`;

export const DraftCollapseWrapper = styled("div")`
   {
    position: relative;

    margin-top: 24px;

    .draftsTitle {
      font-size: 16px;
      margin: 24px 0 24px 0;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
`;

export const FeedbackActionWrapper = styled.div<{ isActiveDraft?: boolean }>`
  display: flex;
  align-items: center;
  pointer-events: ${props => (props.isActiveDraft ? "auto" : "none")};
  opacity: ${props => (props.isActiveDraft ? 1 : 0.5)};
`;
