import React, { Component } from "react"
import FormField from "../FormField"
import { Checkbox } from "formik-antd"
import VerticalCheckbox from "./VerticalCheckbox"
import { gender_targets } from "src/constants/campaign"

export default class Gender extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "genders"}
        label="Gender"
        help="What gender break down do you want influencers to have?"
      >
        <Checkbox.Group>
          {gender_targets.map((value, i) => (
            <VerticalCheckbox data-cy={`gender-checkbox-${i}`} key={i} value={value.value}>
              {value.label}
            </VerticalCheckbox>
          ))}
        </Checkbox.Group>
      </FormField>
    )
  }
}
