import React, { Component } from "react"
import { connect } from "react-redux"
import { Form } from "formik-antd"
import { connectModal } from "redux-modal"
import { createSelector } from "reselect"
import { Modal, Button, message } from "antd"
import { withFormik } from "formik"
import { PayoutPennies } from "src/shared/PayoutFields"
import { PayoutPersist } from "src/ducks/Persist"
import { InfluencerPersist } from "src/ducks/Persist"

import { number, object } from "yup"
import FormError from "src/shared/FormField/FormError"
import { Payout } from "src/ducks/Orm"
const PayoutCreate = PayoutPersist("InfluencerPayoutModal")
const ShowInfluencer = InfluencerPersist("AdminInfluencerShow")

const Validate = object().shape({
  payout_pennies: number()
    .nullable()
    .required("Please enter a payout")
})

export class PayoutModal extends Component {
  render() {
    const { show, handleHide, handleSubmit, isSubmitting, status } = this.props
    return (
      <Modal
        visible={show}
        onCancel={handleHide}
        title={"Create new Payout"}
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ]}
        {...this.props}
      >
        <Form data-cy="influencer-payout-form">
          {status &&
            Object.values(status.meta.errors).map((error, i) => (
              <FormError key={i} style={{ margin: "0 0 10px 0" }} message={error} />
            ))}
          <PayoutPennies />
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => ({
  influencer: ShowInfluencer.selectOne(state),
  link: createSelector(
    (state) => state.entities,
    () => props.payoutId,
    Payout.selectOne
  )(state, props)
})

const mapDispatchToProps = {
  createPayout: PayoutCreate.create
}

const EnhancedWithFormik = withFormik({
  validateOnBlur: false,
  enableReinitialize: true,

  mapPropsToValues: ({ link }) => (link ? link.ref : Validate.cast()),

  validationSchema: () => Validate,

  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus }) => {
    const { createPayout, influencer, handleHide, onCreate } = props
    createPayout({
      ...values,
      payable_type: "Influencer",
      payable_id: influencer.id,
      user: influencer.id
    })
      .then((res) => {
        setSubmitting(false)
        handleHide()
        message.success("Payout Created Successfully")
        onCreate()
      })
      .catch((err) => {
        setSubmitting(false)
        setStatus(err.response.data)
        setErrors(err.response.data.meta.errors)
      })
  },

  displayName: "InfluencerPayoutModal"
})(PayoutModal)

const EnhancedPayout = connect(mapStateToProps, mapDispatchToProps)(EnhancedWithFormik)

const EnhancedPayoutUpdateModal = connectModal({
  name: "InfluencerPayoutModal",
  destroyOnHide: false
})(EnhancedPayout)

export default EnhancedPayoutUpdateModal
