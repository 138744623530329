// @ts-nocheck
import React from "react";
import { RightOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { AccountsListContainer } from "./container";
import Loading from "src/shared/Loading";
import { withRouter, RouteComponentProps } from "react-router-dom";
import PlatformLink from "../AccountDetail/PlatformLink";

export const EllipsisText = styled("span")`
  &&& {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 145px;
  }
`;

const AccountListBlock = styled(Box as any)`
  && {
  }
  &:hover {
    background: ${props => props.theme.colors.white1};
    .username {
      color: ${props => props.theme.colors.blue};
    }
    .type {
      color: ${props => props.theme.colors.black};
    }
    .anticon-right {
      color: ${props => props.theme.colors.black};
    }
  }
  &.active {
    background: ${props => props.theme.colors.white1};
  }
  &.active .username {
    color: ${props => props.theme.colors.blue};
  }
  &.active .type {
    color: ${props => props.theme.colors.black};
  }
  &.active .anticon-right {
    color: ${props => props.theme.colors.black};
  }
`;

interface Props {
  loading: boolean;
  accounts: Array<any>;
}

const BaseAccountList: React.FC<Props & RouteComponentProps> = ({
  loading,
  match,
  accounts,
  history,
}) => {
  const influencerId = match.params["id"];
  const influencerType = match.params["type"];
  const accountId = match.params["accountId"];
  const influencerPath = `/influencer-profile/${influencerType}/${influencerId}`;
  return (
    <Box maxHeight={250} overflowY="auto">
      <Loading spinning={loading}>
        {accounts.map((account, index) => {
          return (
            <Box
              onClick={() =>
                history.replace(`${influencerPath}/account/${account?.id}`)
              }
              key={index}
              style={{ cursor: "pointer" }}
            >
              <AccountListBlock
                display="flex"
                justifyContent="space-between"
                p={10}
                className={
                  parseInt(accountId) === parseInt(account?.id) ? "active" : ""
                }
              >
                <Box>
                  <Typography
                    size={14}
                    weight="semi-bold"
                    color="grey6"
                    lineHeight="14px"
                    className="username"
                    cursor="pointer"
                  >
                    <EllipsisText>@{account?.username}</EllipsisText>
                  </Typography>
                  <Typography
                    size={10}
                    color="grey7"
                    lineHeight={0}
                    className="type"
                    cursor="pointer"
                  >
                    {account?.type}
                  </Typography>
                </Box>
                <Box display="flex">
                  <PlatformLink accountData={account} />

                  <Box ml={15} mt="8px" color="grey6">
                    <RightOutlined />
                  </Box>
                </Box>
              </AccountListBlock>
            </Box>
          );
        })}
      </Loading>
    </Box>
  );
};

const AccountsList = withRouter(BaseAccountList);

export { AccountsList, AccountsListContainer };
