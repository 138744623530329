// @ts-nocheck
import React, { useState } from "react";
import { StyledTable } from "./style";
import isEmpty from "lodash/isEmpty";
import ColumnsModal from "./ColumnsModal";

interface Props {
  stripe?: boolean;
  rowHover?: boolean;
  columns?: any;
  components?: any;
  rowKey?: string;
  dataSource: Array<any>;
  pagination?: any;
  onChange?: any;
  scroll?: any;
  getColumns?: Function;
  filters?: any;
  onFilter?: Function;
  defaultColumnVisibilty?: any;
  extraGetColumnsArgs?: any;
  rowSelection?: any;
  name?: string;
  rowClassName?: any;
  bordered?: any;
}

export const Table: React.FC<Props> = props => {
  let sortField = {};
  let filters = props?.filters;

  if (filters?.order) {
    const orderKeys = Object.keys(filters?.order);
    const field = orderKeys[0] || "";
    sortField["field"] = field;
    sortField["order"] = filters.order[field] === "desc" ? "descend" : "ascend";
  }

  const [sorter, setSorter] = useState(sortField);

  const [columnVisibility, setColumnVisibility] = useState(
    props?.defaultColumnVisibilty
  );

  const onTableChange = (pagination, tableFilters, sort) => {
    if (filters?.page !== pagination?.current) {
      if (props?.onFilter) {
        props?.onFilter("page", Number(pagination?.current));
      }
    }

    let shouldOrder = false;
    const key = sort.field;
    let sortOrder = sort.order === "descend" ? "desc" : "asc";

    if (key) {
      shouldOrder =
        !filters?.order?.[key] || filters?.order?.[key] !== sortOrder;
    } else if (!isEmpty(filters?.order)) {
      // sort.field is not set now, and we have order set in filters
      // make order null
      shouldOrder = true;
    }

    if (shouldOrder) {
      const order = sort.field
        ? {
            [sort.field]: sortOrder,
          }
        : null;

      const sorter = {
        field: sort.field,
        order: sort.order,
      };

      setSorter(sorter);

      if (props?.onFilter) {
        props?.onFilter("order", order);
      }
    }
  };

  let columns = [];
  let allColumns = [] as any;
  let columnsArgs = { sorter, columnVisibility };

  if (props?.extraGetColumnsArgs) {
    columnsArgs = { ...columnsArgs, ...props?.extraGetColumnsArgs };
  }

  if (props?.getColumns) {
    allColumns = props?.getColumns(columnsArgs) || [];

    columns = allColumns.filter(c => c.visible === true);
  }

  return (
    <>
      <StyledTable {...props} columns={columns} onChange={onTableChange} />
      <ColumnsModal
        allColumns={allColumns}
        changeVisible={(value, key) => {
          const newClumnVisibility = { ...columnVisibility };

          newClumnVisibility[key] = value;

          setColumnVisibility(newClumnVisibility);
        }}
      />
    </>
  );
};
