import React, { Component, Fragment } from "react"
import FormField from "../FormField"
import { Checkbox } from "formik-antd"
import VerticalCheckbox from "./VerticalCheckbox"
import { distribution_platforms } from "src/constants/campaign"

export default class DistributionPlatforms extends Component {
  render() {
    return (
      <Fragment>
        <FormField
          name={this.props.name || "distribution_platforms"}
          label="Distribution Platforms"
          help="Which platforms do you want this to run on?"
        >
          <Checkbox.Group>
            {distribution_platforms.map((value, i) => (
              <VerticalCheckbox data-cy={`platform-radio-${i}`} key={i} value={value.value}>
                {value.label}
              </VerticalCheckbox>
            ))}
          </Checkbox.Group>
        </FormField>
      </Fragment>
    )
  }
}
