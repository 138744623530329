import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class Name extends Component {
  render() {
    return (
      <FormField name={this.props.name || "name"} label="Name">
        <Input data-cy="company-user-name" size="large" placeholder="e.g. Joe Bloggs" />
      </FormField>
    )
  }
}
