import React, { Component } from "react";
import { connect } from "react-redux";
import FormField from "../FormField";
import SearchSelect from "src/shared/SearchSelect";
import withModules from "src/utils/hoc/withModules";
import { AdminList as $AdminList } from "src/ducks/DataList";

const AdminList = $AdminList("CampaignTagsInput");

const modules = [AdminList.module()];

class Interests extends Component {
  render() {
    const { loadAdmins } = this.props;
    return (
      <FormField
        name={this.props.name || "admin"}
        label="Admin"
        help="Which admin does this campaign belong to?"
      >
        <SearchSelect
          loadData={value =>
            loadAdmins({ search: value, per_page: 100 }).then(
              res => res.value.data.data
            )
          }
          optionGenerator={value => ({
            value: value.id,
            label: value.name,
          })}
          data-cy="campaign-admin"
          size="large"
          placeholder="Select..."
        />
      </FormField>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loadAdmins: AdminList.loadMany,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(Interests)
)(modules);
