// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";

interface Props {}

const Thankyou: React.FC<Props> = () => {
  return (
    <Box p={24}>
      <Typography size={20} color="black3" lineHeight="20px">
        Thank you!
      </Typography>

      <Typography size={16} color="grey2">
        We will review the details you have submitted and your payout will be
        made within 30 days
      </Typography>
    </Box>
  );
};
export default Thankyou;
