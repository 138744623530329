import axios from "axios";

const currencyRate = async currencyBase => {
  try 
  {
    const { data } = await axios.get('https://api.exchangerate.host/latest ', {
      params: {
        base: currencyBase,
        symbols: 'GBP',
        source: 'ecb'
      },
    });
    return data.rates.GBP;
  }
  catch (e) {
  }
}

export default currencyRate;
