// @ts-nocheck
import React from "react";
import { currency } from "src/constants/payments";
import { Field } from "src/components/Forms/Field";
import { FilterProps } from "../../PaymentQueueList";
import { Select } from "antd";

const { Option } = Select;

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const Public: React.FC<Props> = ({ handleChange, filters }) => {
  const options = (currency || []).map(c => (
    <Option key={c.value}>{c.label}</Option>
  ));

  return (
    <Field
      label="Currency:"
    >
      <Select
        showSearch
        placeholder="Search Campaign"
        style={{ width: "200px" }}
        filterOption={false}
        onChange={v => handleChange("paymentCurrency", v)}
        value={filters?.paymentCurrency ? filters?.paymentCurrency : ""}
      >
        {options}
      </Select>
    </Field>
  );
};

export default Public;
