// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";

interface Props {
  data: any;
  title: string;
}

const BreakdownData: React.FC<Props> = ({ data, title }) => {
  return (
    <Box mt="15px">
      <Typography fontSize={14} color="black2" lineHeight={0}>
        {title}
      </Typography>
      <Box display="flex" width="100%">
        {data.map((item, i) => {
          return (
            <Box
              width={`${item.value}%`}
              background={item.color}
              height="30px"
              fontSize="10px"
              paddingLeft="5px"
              display="flex"
              alignItems="center"
              key={i}
              borderTopLeftRadius={i === 0 ? "3px" : "0px"}
              borderBottomLeftRadius={i === 0 ? "3px" : "0px"}
              borderTopRightRadius={
                (data || [])?.length - 1 === i ? "3px" : "0px"
              }
              borderBottomRightRadius={
                (data || [])?.length - 1 === i ? "3px" : "0px"
              }
              style={{ boxSizing: "content-box" }}
              color="white"
              font-weight="semi-bold"
            >
              {item.value}%
            </Box>
          );
        })}
      </Box>
      {data.map((item, i) => {
        return (
          <Box display="inline-flex" mr={10} key={i} mt={10}>
            <Box
              width="10px"
              height="10px"
              background={item.color}
              borderRadius="2px"
            />
            <Typography fontSize={12} color="black2" ml="5px" lineHeight="10px">
              {item.key}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default BreakdownData;
