// @ts-nocheck
import React from "react"
import { Box } from "src/components/Core/Box"
import { GiftingCampaignFragmentFragment } from "src/generated/ApolloComponents"
import { Button, Input } from "antd"
import { GiftingCampaignItemContainer } from "./CampaignItem/container"

interface Props {
  campaigns: GiftingCampaignFragmentFragment[]
  reloadRecords: Function
  setCreateModalVisible: (value: boolean) => void
}

export const GiftingCampaignList = ({ campaigns, reloadRecords, setCreateModalVisible }: Props) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box width={950} mt={45} mb={45}>
        <Box display="flex" width={1} mb={30}>
          <Input size="large" placeholder="Search campaigns" />
          <Button
            size="large"
            type="primary"
            onClick={() => setCreateModalVisible(true)}
            style={{ marginLeft: 15 }}
          >
            Create Gifting Campaign
          </Button>
        </Box>

        <Box>
          {campaigns.map((c, k) => (
            <GiftingCampaignItemContainer reloadRecords={reloadRecords} key={k} campaign={c} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
