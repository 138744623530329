// @ts-nocheck
import React from 'react'
import Wrapper from '../Wrapper'
import HeadSection from './HeadSection'
import { Box } from 'src/components/Core/Box'
import ConnectButtons from './ConnectButtons'
import DemographicForm from './DemographicForm'
import { ConnectAccountContainer } from './container'
import {
  GlobalErrorProps,
  GlobalErrors,
} from 'src/components/Forms/ErrorMessage'
import Loading from 'src/shared/Loading'
import Button from 'src/components/Core/Button'
import { Typography } from 'src/components/Core/Typography'
import { Row, Col } from 'antd'
import { useHistory } from 'react-router'

interface Props {
  profile: any
  account: any
  express: boolean
  loading?: boolean
  collect: boolean
  expressApplicant: any
  selectedPlatform: string
  handleClick: Function
  submitDemographic: Function
  globalErrors?: GlobalErrorProps[]
}

const ConnectAccounts = ({
  collect,
  profile,
  account,
  express,
  loading,
  handleClick,
  globalErrors,
  expressApplicant,
  selectedPlatform,
  submitDemographic
}: Props) => {

  const history = useHistory()

  return(
    <Wrapper>
      <Box px={28}>
        <GlobalErrors errors={globalErrors} />
        <Loading spinning={loading}>
          <HeadSection selectedPlatform={selectedPlatform} collect={collect} profile={profile} express={express} />
          {express ?
            <Row>
              <Col>
                <Button type="action" buttonSize="middle" onClick={() => handleClick(expressApplicant.platform)}>
                  <Typography mb={0} cursor="pointer">Continue</Typography>
                </Button>
              </Col>
              <Col>&nbsp;&nbsp;</Col>
              <Col>
                <Button type="ghost" buttonSize="middle" onClick={() => history.push('/')}>
                  <Typography mb={0} color="blue" cursor="pointer">No, Cancel</Typography>
                </Button>
              </Col>
            </Row>
            :
            collect ?
              <DemographicForm handleSubmit={submitDemographic} account={account} />
              :
              <ConnectButtons handleSelect={handleClick} />
          }
        </Loading>
      </Box>
    </Wrapper>
  )
}

export {ConnectAccountContainer, ConnectAccounts};
