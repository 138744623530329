// @ts-nocheck
import React, { useState, useRef } from 'react'
import Express from './Express'
import Internal from './Internal'
import { isUndefined } from 'lodash'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ApplyContainer } from './container'
import { Box } from 'src/components/Core/Box'
import { Row, Checkbox, Col, Button } from 'antd'
import { Typography } from 'src/components/Core/Typography'
import {
  GlobalErrors,
  GlobalErrorProps,
} from 'src/components/Forms/ErrorMessage'

const StyledButton = styled(Button)`
  width: 100%;
  height: 42px !important;
  margin: 10px 0px 10px !important;
`

interface FormProps {
  accId: number | undefined
  email: string | undefined
  setAccId: Function
  influencerAccounts: [any]
  handleApply: (any) => void
  applied: boolean | undefined
  moveTo: () => void
  isMobile?: boolean
  campaignId: number
  loggedIn: boolean
  globalErrors?: GlobalErrorProps[];
}

const ApplyForm = ({
  accId,
  email,
  moveTo,
  applied,
  setAccId,
  loggedIn,
  isMobile,
  campaignId,
  handleApply,
  globalErrors,
  influencerAccounts,
}: FormProps) => {
  const formRef = useRef() as any
  const [tnc, setTnc] = useState(false)         // terms and conditons
  const [postDet, setPostDet] = useState(false) //post details

  const isDisabled = () => {
    if(!loggedIn)
      return !tnc || !postDet || !!email
    else
      return isUndefined(accId) || !tnc || !postDet || applied
  }

  const submitForm = () => {
    if (formRef?.current) {
      formRef.current!.submitForm()
    }
  }

  const handleSubmit = (values, actions) => {
    handleApply(values)
  }


  return(
    <Box>
      <Row>
        <Col span={3}>
          <Checkbox onChange={e => setTnc(e.target.checked)} checked={applied || tnc} disabled={applied} />
        </Col>
        <Col span={21}>
          <Typography color="silver">
            I have read and accept Fanbytes <Link to={`/terms-and-conditions/${campaignId}`} target="_blank">Terms & Conditions</Link>
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col span={3}>
          <Checkbox onChange={e => setPostDet(e.target.checked)} checked={applied || postDet} disabled={applied} />
        </Col>
        <Col span={21}>
          <Typography color="silver">
            I have read and understand my post must abide by all <Link to='#' onClick={moveTo}>Post Details</Link>
          </Typography>
        </Col>
      </Row>
      <Box background={isMobile ? "inherit" : "#F5F5F5"} px={20} mx={-20} py={20}>
        <Typography color="black2" size={14} weight="semi-bold">
          {email ? 'Application submitted!' : 'Choose account to apply with:'}
        </Typography>
        {globalErrors && <GlobalErrors errors={globalErrors} />}
        {loggedIn ?
          <Internal setAccId={setAccId} influencerAccounts={influencerAccounts} />
          :
          <Express formRef={formRef} onSubmit={handleSubmit} regEmail={email} />
        }
      </Box>
      <StyledButton type="primary" disabled={isDisabled()} onClick={loggedIn ? handleApply : submitForm}>
        {applied || email ? 'Applied' : 'Apply'}
      </StyledButton>
      <StyledButton type="primary">Contact</StyledButton>
    </Box>
  )
}

export { ApplyForm, ApplyContainer};
