// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikTextArea } from "src/components/Forms/TextArea";
import { Typography } from "src/components/Core/Typography";

interface Props {}

const Description: React.FC<Props> = () => {
  return (
    <Field label="Description of content:" mb={26}>
      <FormikTextArea
        size="middle"
        name="description"
        rows={1}
        placeholder="Description of content"
        borderRadius={2}
        borderColor="grey5"
      />
      <Typography size={14} color="grey7">
        Please describe the style and content of the media to produced in as
        much detail as possible
      </Typography>
    </Field>
  );
};

export default Description;
