// @ts-nocheck
import React, { useState } from "react";
import Form from "src/components/Forms/Form";
import { Row, Col } from "antd";
import isEmpty from "lodash/isEmpty";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { ProfileDetailFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  AgeBreakdown,
  GenderBreakdown,
} from "src/components/Modules/BreakdownFields";
import { LocationBreakdown } from "./FormFields";
import { ScreenshotsContainer } from "../../../components/Fields/Screenshots";
import { Visible, useScreenClass } from "react-grid-system";

interface ProfileDetailFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  profileDetailSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  accountData: any;
  goBack: () => void;
  setShowExample: (value: object) => void;
  setScreenshotType: (value: string) => void;
  showExample: object;
}

export const ProfileDetailFormWrapper = styled.div`
  width: 100%;
`;

const ProfileDetailForm: React.FC<ProfileDetailFormProps> = ({
  initialValues,
  isSubmitting,
  profileDetailSchema,
  onSubmit,
  globalErrors,
  accountData,
  goBack,
  setShowExample,
  setScreenshotType,
  showExample,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const screenClass = useScreenClass();

  return (
    <ProfileDetailFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Visible xs sm md>
          <Box borderBottom="1px solid #1890FF">
            <Typography
              size={16}
              color="blue"
              lineHeight="20px"
              textAlign="center"
            >
              2. Profile details
            </Typography>
          </Box>
        </Visible>

        <Box p={24}>
          <Visible xl lg>
            <Typography size={20} color="black3" lineHeight="20px">
              Profile details
            </Typography>
          </Visible>

          <Typography size={16} color="grey2">
            Please enter the following details about your{" "}
            <strong style={{ color: "#000" }}>{accountData?.type} </strong>
            account{" "}
            <strong style={{ color: "#1890FF" }}>
              {accountData?.username}
            </strong>{" "}
            and provide screenshots. Examples of what your screenshots should
            look like can be found by clicking "What is this?" below
          </Typography>

          <Form
            initialValues={initialValues}
            schema={profileDetailSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {(formProps: any) => {
              return (
                <>
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <GenderBreakdown formProps={formProps} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <LocationBreakdown formProps={formProps} />
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    {formProps?.values?.platformType !== "TikTok" && (
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <AgeBreakdown formProps={formProps} />
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Box mt={65}>
                        <ScreenshotsContainer
                          name="screenshot"
                          setIsUploading={loading => setIsUploading(loading)}
                          setFieldValue={value =>
                            formProps?.setFieldValue("screenshot", value)
                          }
                          setFieldTouched={() =>
                            formProps?.setFieldTouched("screenshot", true)
                          }
                          values={formProps?.values}
                          setShowExample={() =>
                            setShowExample({
                              screenshot: true,
                            })
                          }
                          setScreenshotType={() =>
                            setScreenshotType("screenshot")
                          }
                          platformType={accountData?.type || ""}
                          accountName={accountData?.username || ""}
                          screenshotType="screenshot"
                          multiple={false}
                          disabled={!isEmpty(formProps?.values?.screenshot)}
                          showExample={showExample}
                          hideExample={() =>
                            setShowExample({
                              screenshot: false,
                            })
                          }
                          uploadText="Please provide your demographic screenshot."
                        />
                      </Box>
                    </Col>
                  </Row>

                  <Box
                    display="flex"
                    flexDirection={
                      screenClass === "xs" || screenClass === "sm"
                        ? "column"
                        : "row"
                    }
                    mt={20}
                  >
                    <LinkButton
                      onClick={() => {
                        goBack();
                      }}
                      mr={screenClass === "xs" || screenClass === "sm" ? 0 : 50}
                      mt={screenClass === "xs" || screenClass === "sm" ? 10 : 0}
                      color="grey3"
                      style={
                        screenClass === "xs" || screenClass === "sm"
                          ? {
                              order: 1,
                            }
                          : {}
                      }
                    >
                      Go back
                    </LinkButton>

                    <Button
                      htmlType="submit"
                      type="action"
                      loading={isSubmitting}
                      disabled={isSubmitting || isUploading}
                      buttonSize="middle"
                      data-cy="submit-button"
                    >
                      Next
                    </Button>
                  </Box>
                </>
              );
            }}
          </Form>
        </Box>
      </Loading>
    </ProfileDetailFormWrapper>
  );
};

export { ProfileDetailForm, ProfileDetailFormContainer };
