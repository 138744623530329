// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { PaymentQueueList, FilterProps } from ".";
import debounce from "lodash/debounce";
import {
  useGetPaymentRequestsQuery,
  useAcceptPaymentRequestMutation,
  useRejectPaymentRequestMutation,
  useUndoPaymentRequestMutation,
  useDeletePaymentRequestMutation,
  useGetPaymentRequestQueryQuery,
  useReconcilePaymentRequestMutation,
} from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  usePaymentQueueDetails,
  statisticsDetail as StatisticDetail,
} from "../../PaymentQueueProvider";

interface Props {}

export const PaymentQueueListContainer: React.FC<Props> = () => {
  const [filters, setFilters] = useState({
    isGreaterThan: true,
    cost: 0,
  } as FilterProps);
  const [isShowFilter, setIsShowFilter] = useState(false);

  const { actions } = usePaymentQueueDetails();

  const paymentRequest = useGetPaymentRequestsQuery({
    variables: filters,
  });

  const allPaymentRequest = paymentRequest?.data?.getPaymentRequests;

  const searchInputResult = useGetPaymentRequestQueryQuery({
    variables: { searchInput: "" },
    skip: !filters?.searchInput,
  });

  const searchInputResults =
    searchInputResult?.data?.getPaymentRequestQuery || {};

  const newSearchInputResults = { ...searchInputResults };
  delete (newSearchInputResults as any).__typename;

  const onFilterChange = async filters => {
    try {
      paymentRequest.refetch(filters);
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onFilterChange, 1000)).current;

  useEffect(() => {
    let updatedFilters = { ...filters };

    if (
      updatedFilters?.searchInput &&
      (updatedFilters?.requestedBy ||
        updatedFilters?.influencer ||
        updatedFilters?.campaign ||
        updatedFilters?.paymentRequestId)
    ) {
      updatedFilters["searchInput"] = null as any;
    }

    if (String(updatedFilters?.cost) === "") {
      updatedFilters.cost = 0 as any;
      updatedFilters.isGreaterThan = true as any;
    }

    if (updatedFilters?.status?.length === 0) {
      updatedFilters.status = null;
    }

    if (updatedFilters?.paymentType?.length === 0) {
      updatedFilters.paymentType = null;
    }
    if (updatedFilters?.campaignId) {
      updatedFilters.campaignId = parseInt(updatedFilters?.campaignId as any);
    }
    if (updatedFilters?.paymentRequestId) {
      updatedFilters.paymentRequestId = parseInt(
        updatedFilters?.paymentRequestId as any
      );
    }
    if (updatedFilters?.paymentCurrency) {
      updatedFilters.paymentCurrency = updatedFilters?.paymentCurrency as any;
    }
    if (updatedFilters?.hubspotDealId) {
      updatedFilters.hubspotDealId = updatedFilters?.hubspotDealId as any;
    }

    debouncedFilterChange(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    let statisticsDetail = {};

    statisticsDetail = {
      ...statisticsDetail,
      payments: allPaymentRequest?.paymentCount,
      totalAmount: allPaymentRequest?.totalAmount,
      totalNetAmount: allPaymentRequest?.totalNetAmount,
      totalVatAmount: allPaymentRequest?.totalVatAmount,
    };

    actions.setQueueStatistics({
      ...statisticsDetail,
    } as StatisticDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPaymentRequest]);

  const [
    acceptPaymentRequestMutation,
    accept,
  ] = useAcceptPaymentRequestMutation();

  const [
    rejectPaymentRequestMutation,
    reject,
  ] = useRejectPaymentRequestMutation();

  const [undoPaymentRequestMutation, undo] = useUndoPaymentRequestMutation();

  const [
    deletePaymentRequestMutation,
    deleteRequest,
  ] = useDeletePaymentRequestMutation();

  const [
    reconcilePaymentRequestMutation,
    reconcile,
  ] = useReconcilePaymentRequestMutation();

  const onAccept = async paymentRequestId => {
    try {
      const res = await acceptPaymentRequestMutation({
        variables: {
          paymentRequestId: paymentRequestId,
        },
      });
      if (res.data?.acceptPaymentRequest?.id) {
        paymentRequest.refetch(filters);
      }
    } catch (e) {}
  };

  const onReconcile = async paymentRequestId => {
    try {
      const res = await reconcilePaymentRequestMutation({
        variables: {
          paymentRequestId: paymentRequestId,
        },
      });
      if (res.data?.reconcilePaymentRequest?.id) {
        paymentRequest.refetch(filters);
      }
    } catch (e) {}
  };

  const onUndo = async paymentRequestId => {
    try {
      const res = await undoPaymentRequestMutation({
        variables: {
          paymentRequestId: paymentRequestId,
        },
      });
      if (res.data?.undoPaymentRequest?.id) {
        paymentRequest.refetch(filters);
      }
    } catch (e) {}
  };

  const onDelete = async paymentRequestId => {
    try {
      const res = await deletePaymentRequestMutation({
        variables: {
          paymentRequestId: paymentRequestId,
        },
      });
      if (res.data?.deletePaymentRequest?.id) {
        paymentRequest.refetch(filters);
      }
    } catch (e) {}
  };

  const onReject = async paymentRequestId => {
    try {
      const res = await rejectPaymentRequestMutation({
        variables: {
          paymentRequestId: paymentRequestId,
        },
      });
      if (res.data?.rejectPaymentRequest?.id) {
        paymentRequest.refetch(filters);
      }
    } catch (e) {}
  };

  const onRefetch = async () => {
    try {
      paymentRequest.refetch(filters);
    } catch (e) {}
  };

  actions.setRefetchMethodRef(onRefetch);

  const globalErrors = useMapGraphQLErrorsMessages(
    paymentRequest?.error ||
      accept?.error ||
      reject?.error ||
      undo?.error ||
      deleteRequest?.error ||
      reconcile?.error
  );

  const resetFilter = () => {
    if (filters?.cost || filters?.isGreaterThan === null) {
      filters["isGreaterThan"] = true as any;
      filters["cost"] = 0 as any;
    }

    if (filters?.status) {
      filters["status"] = null as any;
    }

    if (filters?.paymentType) {
      filters["paymentType"] = null as any;
    }

    if (filters?.searchInput) {
      filters["searchInput"] = null as any;
    }

    if (filters?.requestedBy) {
      filters["requestedBy"] = null as any;
    }

    if (filters?.influencer) {
      filters["influencer"] = null as any;
    }

    if (filters?.campaign) {
      filters["campaign"] = null as any;
    }

    if (filters?.paymentRequestId) {
      filters["paymentRequestId"] = null as any;
    }

    if (filters?.typeOfCost) {
      filters["typeOfCost"] = null as any;
    }

    if (filters?.dateFrom) {
      filters["dateFrom"] = null as any;
    }

    if (filters?.dateTo) {
      filters["dateTo"] = null as any;
    }

    if (filters?.dueDateFrom) {
      filters["dueDateFrom"] = null as any;
    }

    if (filters?.dueDateTo) {
      filters["dueDateTo"] = null as any;
    }

    if (filters?.campaignId) {
      filters["campaignId"] = null as any;
    }

    if (filters?.paypalTransactionId) {
      filters["paypalTransactionId"] = null as any;
    }

    if(filters?.paymentCurrency) {
      filters["paymentCurrency"] = null as any;
    }
    filters["page"] = 1;
    setFilters({ ...filters });
  };

  useEffect(() => {
    if (filters?.searchInput) {
      searchInputResult.refetch({
        searchInput: filters?.searchInput,
      });
    } else {
      if (filters?.requestedBy) {
        filters["requestedBy"] = null as any;
      }

      if (filters?.influencer) {
        filters["influencer"] = null as any;
      }

      if (filters?.campaign) {
        filters["campaign"] = null as any;
      }

      if (filters?.paymentRequestId) {
        filters["paymentRequestId"] = null as any;
      }

      setFilters({ ...filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.searchInput]);

  return (
    <PaymentQueueList
      allPaymentRequest={allPaymentRequest}
      globalErrors={globalErrors}
      loading={
        paymentRequest?.loading ||
        accept?.loading ||
        reject?.loading ||
        undo?.loading ||
        deleteRequest?.loading ||
        reconcile?.loading
      }
      onAccept={value => onAccept(value)}
      onReject={value => onReject(value)}
      onUndo={value => onUndo(value)}
      onFilter={(key, value) => {
        filters[key] = value;
        if (String(filters?.cost) === "") {
          filters["cost"] = 0;
          filters["isGreaterThan"] = true as any;
        }

        if (filters?.paymentRequestId) {
          filters["paymentRequestId"] = parseInt(
            filters?.paymentRequestId as any
          );
        }

        setFilters({ ...filters });
      }}
      isShowFilter={isShowFilter}
      showFilter={() => setIsShowFilter(!isShowFilter)}
      filters={filters}
      resetFilter={() => {
        resetFilter();
      }}
      onDelete={value => onDelete(value)}
      onReconcile={value => onReconcile(value)}
      filterDataSoruce={newSearchInputResults}
    />
  );
};
