import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Button, Divider } from "antd";
import SearchForm from "./SearchForm";
import { show } from "redux-modal";
import FilterModal from "./FilterModal";
import FilterList from "./FilterList";
import TableContext from "./Context";

class FilterBar extends Component {
  render() {
    const { show, filterOptions } = this.props;

    return (
      <Card>
        <TableContext.Consumer>
          {value => (
            <>
              <SearchForm name={value} />

              <Divider />

              <Button
                block
                onClick={() =>
                  show("FilterModal", {
                    filterOptions: filterOptions,
                    moduleName: value,
                  })
                }
                id="addFilter"
              >
                Add Filter
              </Button>

              <FilterList name={value} filterOptions={filterOptions} />
              <FilterModal />
            </>
          )}
        </TableContext.Consumer>
      </Card>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  show,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
