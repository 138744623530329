// @ts-nocheck
import React, { useRef, useEffect } from "react";
import { Box } from "src/components/Core/Box";
import { useInViewport } from "react-in-viewport";
import FadeInSection from "../../FadeInSection";
import CategorisedTopData from "../CategorisedTopData";

interface Props {
  onChangeSection: (value: string) => void;
  campaignData: any;
}

const Engagement: React.FC<Props> = ({ onChangeSection, campaignData }) => {
  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};
  let props = {};
  const { inViewport } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  useEffect(() => {
    if (inViewport) {
      onChangeSection("engagement");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport]);

  return (
    <FadeInSection>
      <Box
        mt={50}
        mb={50}
        position="relative"
        display="block"
        id="engagement"
        ref={myRef as any}
      >
        {(campaignData?.categorisedTopEngagements || []).map((item, i) => {
          return (
            <CategorisedTopData
              viewData={item}
              key={i}
              direction={i % 2 === 0 ? "left" : "right"}
              type="engagement"
            />
          );
        })}
      </Box>
    </FadeInSection>
  );
};

export default Engagement;
