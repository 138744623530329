// @ts-nocheck
import React, { Fragment } from 'react'
import validations from './Validations'
import Form from 'src/components/Forms/Form'
import { Typography } from 'src/components/Core/Typography'
import {
  Fee,
  Email,
  Platform,
  Username,
} from './FormFields'

const Express = ({formRef, onSubmit, regEmail}) => {
  const values = {
    platform: "",
    fee: "",
    username: "",
    email: ""
  }
  return(
    <Fragment>
      {regEmail ?
        <Typography size={14} fontFamily="Futura" color="black2">
          Thanks for applying. You’ll receive a confirmation on&nbsp;
          <Typography weight="semi-bold" style={{display: 'inline'}}>{regEmail}</Typography>.
          We’ll contact you about the progress of your application shortly.
        </Typography>
        :
        <Form
          ref={formRef}
          schema={validations}
          initialValues={values}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return(
              <Fragment>
                <Platform />
                <br />
                <Username />
                <Typography size={12} color="grey7" fontFamily="Futura">
                  e.g. username
                </Typography>
                <Email />
                <Typography color="black2" size={12} fontFamily="Futura">
                  We will contact you on this email address about the progress of your application
                </Typography>
                <Fee />
                <Typography color="black2" size={12} fontFamily="Futura">
                  This is an estimate of the amount you would expect to receive for your participation in this campaign
                </Typography>
              </Fragment>
            )
          }}
        </Form>
      }
    </Fragment>
  )
}

export default Express
