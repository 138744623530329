// @ts-nocheck
import React from 'react'
import { AccountsTableContainer } from './AccountsTable'
import { AccountsListProvider } from './AccountsListProvider'
import NewAccountModalWrapper from './NewAccountModalWrapper'
import { AccountsList as Props } from '../props'

export const AccountsList: React.FC<Props> = (props) => {
  return (
    <AccountsListProvider>
      <AccountsTableContainer {...props} />
      <NewAccountModalWrapper {...props} />
    </AccountsListProvider>
  );
};
