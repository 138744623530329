import axiosInstance from "../axiosInstance";

const GET_AGENCIES = `/agencies`;
const CREATE_AGENCY = `/agencies`;
const getAgency = id => `/agencies/${id}`;
const updateAgency = id => `/agencies/${id}`;
const deleteAgency = id => `/agencies/${id}`;

export const index = data => axiosInstance.get(GET_AGENCIES, data);

export const get = (id, data) => axiosInstance.get(getAgency(id), data);

export const create = data => axiosInstance.post(CREATE_AGENCY, data);

export const update = (id, data) => axiosInstance.patch(updateAgency(id), data);

export const destroy = id => axiosInstance.delete(deleteAgency(id));
