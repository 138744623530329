// @ts-nocheck
import React from "react";
import { Alert, Row, Col } from "antd";
import Loading from "src/shared/Loading";
import styled from "styled-components";
import Form from "src/components/Forms/Form";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Field } from "src/components/Forms/Field";
import { FormikCurrencyInput } from "src/components/Forms/CurrencyInput";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import { Schema } from "yup";
import { BasicInfoContainer } from "./container";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import AccountTag from "./AccountTags";
import AccountInfo from "./AccountInfo";
import PlatformLink from "../PlatformLink";
import { Icon } from '@ant-design/compatible'

export const CampaignAlert = styled(Alert)`
  &&& {
    border: none !important;
    font-size: 12px !important;
    padding: 6px 6px 6px 30px !important;
    .ant-alert-icon {
      top: 8.5px;
      left: 10px;
    }
    .ant-alert-message {
      color: ${props => props.theme.colors.black2} !important;
    }
  }
`;

const SettingIcon = styled(Icon)`
  && {
  }
  &:hover {
    color: ${props => props.theme.colors.blue} !important;
  }
`;

interface BasicInfoFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  BasicInfoFormSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  accountData: any;
  allTags: Array<any>;
}

const BasicInfo: React.FC<BasicInfoFormProps> = ({
  initialValues,
  isSubmitting,
  BasicInfoFormSchema,
  onSubmit,
  globalErrors,
  accountData,
  allTags,
}) => {
  return (
    <Box p={15}>
      <Loading spinning={isSubmitting}>
        <Form
          initialValues={initialValues}
          schema={BasicInfoFormSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            const values = formProps?.values;
            return (
              <>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <GlobalErrors errors={globalErrors} />
                  </Box>

                  <Box color={values?.isEditable ? "blue" : "grey6"} mt="-10px">
                    <SettingIcon
                      type="setting"
                      onClick={() =>
                        formProps?.setFieldValue("isEditable", true)
                      }
                    />
                  </Box>
                </Box>
                <Box borderBottom="1px solid #F5F5F5">
                  <Row gutter={8}>
                    <Col span={16}>
                      <Box display="flex">
                        <Typography
                          size={26}
                          weight="semi-bold"
                          color="black"
                          lineHeight="22px"
                        >
                          @{accountData?.username}
                        </Typography>
                        <PlatformLink accountData={accountData} />

                        {accountData?.isOnCampaign && (
                          <Box ml={15}>
                            <CampaignAlert
                              message="This influencer is currently on a campaign"
                              type="info"
                              showIcon
                            />
                          </Box>
                        )}
                      </Box>
                    </Col>

                    {values?.isEditable ? (
                      <Col span={8}>
                        <Field label="Price per post:" mb={10} mt="-25px">
                          <FormikCurrencyInput
                            name="actualCostPerPost"
                            size="small"
                            borderColor="grey5"
                            prefix="£"
                            shouldShowError={true}
                          />
                        </Field>
                      </Col>
                    ) : (
                      <AccountInfo accountData={accountData} />
                    )}
                  </Row>
                </Box>

                <AccountTag formProps={formProps} allTags={allTags} />

                {values?.isEditable && (
                  <Box
                    alignItems="right"
                    textAlign="right"
                    display="inherit"
                    mt={20}
                  >
                    <LinkButton
                      onClick={() => {
                        formProps?.resetForm();
                        formProps?.setFieldValue("isEditable", false);
                      }}
                      mr={20}
                    >
                      Cancel
                    </LinkButton>

                    <Button
                      htmlType="submit"
                      type="action"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      buttonSize="middle"
                      data-cy="submit-button"
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </>
            );
          }}
        </Form>
      </Loading>
    </Box>
  );
};

export { BasicInfo, BasicInfoContainer };
