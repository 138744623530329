// @ts-nocheck
import React from "react"
import { Modal } from "antd"
import { Form } from "src/components/Forms/Form"
import { Schema } from "yup"
import { Input, Form as FormikForm } from "formik-antd"

interface Props {
  schema: Schema<any>
  initialValues: any
  visible: boolean
  setVisible: (visible: boolean) => void
  onSubmit: (values: any, actions: any) => any
}

export const CreateGiftingCampaignModal = ({
  initialValues,
  schema,
  onSubmit,
  visible,
  setVisible
}: Props) => {
  return (
    <Form initialValues={{ ...initialValues }} schema={schema} onSubmit={onSubmit}>
      {({ submitForm }) => (
        <Modal
          title="Create Gifting Campaign"
          onOk={submitForm}
          visible={visible}
          onCancel={() => setVisible(false)}
        >
          <FormikForm.Item name="title" label="What is the title of this campaign?" colon={false}>
            <Input name="title" placeholder="e.g. Massive christmas giveaway" />
          </FormikForm.Item>
        </Modal>
      )}
    </Form>
  )
}
