import React, { Component, Fragment } from "react";
import styled from "styled-components";
import FormField from "../FormField";
import { DatePicker } from "formik-antd";
import moment from "moment";

const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default class Goal extends Component {
  render() {
    return (
      <Fragment>
        <p style={{ fontSize: 16 }}>
          <b>Public Deadline:</b>
        </p>
        <FormField name={this.props.name || "public_deadline"} label="Public Deadline">
          <FullWidthDatePicker
            data-cy="date-picker"
            disabledDate={current => current && current < moment().endOf("day")}
            size="large"
            format="DD/MM/YYYY"
          />
        </FormField>
      </Fragment>
    );
  }
}
