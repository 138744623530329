// @ts-nocheck
import React, { useRef } from "react";
import styled from "styled-components";

interface Props {
  children?: React.ReactNode;
}

const Section = styled("div")`
  && {
    opacity: 0;
    transform: translate(0, 10%);
    visibility: hidden;
    transition: opacity 1ms ease-out, transform 1ms ease-out;
    will-change: opacity, visibility;
  }
  &.is-visible {
    opacity: 1 !important;
    transform: none;
    visibility: visible !important;
  }
`;

const FadeInSection: React.FC<Props> = ({ children }) => {
  const domRef = useRef();

  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Not possible to set it back to false like this:

          setVisible(true);

          // No need to keep observing:
          observer.unobserve(domRef?.current as any);
        }
      });
    };
    const options = {
      root: null,
      threshold: 0,
      rootMargin: "0px 0px -100px 0px",
    };
    const observer = new IntersectionObserver(callback, options);

    // const observer = new IntersectionObserver(entries => {
    //   // In your case there's only one element to observe:

    //   if (entries[0].isIntersecting) {
    //     // Not possible to set it back to false like this:

    //     setVisible(true);

    //     // No need to keep observing:
    //     observer.unobserve(domRef?.current as any);
    //   }
    // });

    observer.observe(domRef?.current as any);

    return () => observer.unobserve(domRef?.current as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section ref={domRef as any} className={isVisible ? " is-visible" : ""}>
      {children}
    </Section>
  );
};

export default FadeInSection;
