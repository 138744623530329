import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Input } from 'antd'
import { withFormik } from "formik"
import TableManager from "src/ducks/TableManager"

class SearchForm extends Component {
  handleSearch = (e) => {
    const value = e.target.value
    this.props.addFilter({ search: value })
  }

  render() {
    return (
      <Input
        name="search"
        onChange={this.handleSearch}
        placeholder="Search..."
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const Manager = TableManager(props.name)

  return {
    filters: Manager.selectFilters(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const Manager = TableManager(ownProps.name)

  return bindActionCreators(
    {
      addFilter: Manager.addFilter,
      removeFilter: Manager.removeFilter
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,
    enableReinitialize: true,

    mapPropsToValues: ({ filters }) => filters,
    validationSchema: () => ({}),

    handleSubmit: (values, { props, setSubmitting }) => {
      props.addFilter(values)
      setSubmitting(false)
    },

    displayName: "SearchRecordsForm"
  })(SearchForm)
)
