import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import idx from "idx";
import { Descriptions } from "antd";
import Container from "src/shared/Container";
import AccountsTable from "./AccountsTable";
import { InfluencerPersist } from "src/ducks/Persist";
import { featureFlags } from "src/constants/featureFlags";

const ShowInfluencer = InfluencerPersist("AdminInfluencerShow");

const DetailDescriptions = styled(Descriptions)`
  background: #fff;
`;

export class InfluencerDetails extends Component {
  render() {
    const { influencer } = this.props;

    return (
      <Container style={{ marginTop: 24 }}>
        {influencer.agency && (
          <div style={{ background: "#fff", marginBottom: 24 }}>
            <Descriptions data-cy="influencer-agency-table" column={1} bordered>
              <Descriptions.Item data-cy="influencer-agency" label="Agency">
                <Link to={`/agencies/` + influencer.agency.id}>
                  {influencer.agency.agency_name}
                </Link>
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}

        <DetailDescriptions size="middle" bordered>
          <Descriptions.Item label="Name" span={1}>
            {influencer.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={2}>
            {influencer.email}
          </Descriptions.Item>
          <Descriptions.Item label="Phone" span={3}>
            {influencer.phone_number}
          </Descriptions.Item>
          {featureFlags.isPaypalEnabled && (
            <Descriptions.Item label="Paypal Email" span={3}>
              {influencer.payout_details.paypal_email}
            </Descriptions.Item>
          )}
          {!!idx(influencer, x => x.payout_details.bank_account.full_name) && (
            <Descriptions.Item label="Bank Account Full Name" span={3}>
              {idx(influencer, x => x.payout_details.bank_account.full_name) ||
                ""}
            </Descriptions.Item>
          )}
          {!!idx(
            influencer,
            x => x.payout_details.bank_account.account_number
          ) && (
            <Descriptions.Item label="Bank Account Number" span={3}>
              {idx(
                influencer,
                x => x.payout_details.bank_account.account_number
              ) || ""}
            </Descriptions.Item>
          )}
          {!!idx(influencer, x => x.payout_details.bank_account.sort_code) && (
            <Descriptions.Item label="Bank Account Sort Code" span={3}>
              {idx(influencer, x => x.payout_details.bank_account.sort_code) ||
                ""}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="VAT Number" span={3}>
            {influencer.detail.vat_number}
          </Descriptions.Item>
          <Descriptions.Item label="Payout Currency" span={3}>
            {influencer.detail.payout_currency}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={3}>
            {influencer.detail.address}
          </Descriptions.Item>
          <Descriptions.Item label="Age" span={3}>
            {influencer.formattedAges()}
          </Descriptions.Item>
          <Descriptions.Item label="Location" span={3}>
            {influencer.formattedLocations()}
          </Descriptions.Item>
          <Descriptions.Item label="Gender" span={3}>
            {influencer.formattedGender()}
          </Descriptions.Item>
          <Descriptions.Item label="Note" span={3}>
            {influencer.detail.note}
          </Descriptions.Item>
        </DetailDescriptions>

        <AccountsTable style={{ marginTop: "24px" }} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  influencer: ShowInfluencer.selectOne(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerDetails);
