import React, { Component } from "react"
import FormField from "../FormField"
import { Radio } from "formik-antd"
import VerticalRadio from "./VerticalRadio"
import { objectives } from "src/constants/campaign"

export default class Goal extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "objective"}
        label="Primary Goal"
        help="What are you trying to achieve with this campaign?"
      >
        <Radio.Group>
          {objectives.map((value, i) => (
            <VerticalRadio data-cy={`goal-radio-${i}`} key={i} value={value.value}>
              {value.label}
            </VerticalRadio>
          ))}
        </Radio.Group>
      </FormField>
    )
  }
}
