// @ts-nocheck
import React from "react";
import ListItem from "./ListItem";

interface Props {
  feedbacks: any;
  type: string;
}

const FeedbackList: React.FC<Props> = ({ feedbacks, type }) => {
  return (
    <>
      {feedbacks &&
        feedbacks.map((feedback, index) => (
          <ListItem
            key={index}
            listNumber={index + 1}
            feedback={feedback}
            type={type}
          />
        ))}
    </>
  );
};

export default FeedbackList;
