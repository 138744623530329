// @ts-nocheck
import React from "react"
import { GiftOutlined } from '@ant-design/icons';
import { Box } from "src/components/Core/Box"
import { Button } from "src/components/Core/Button"
import { Typography } from "src/components/Core/Typography"

interface Props {
  setCreateModalVisible: (value: boolean) => void
}

export const NoGiftingCampaigns = ({ setCreateModalVisible }: Props) => {
  return (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <Box textAlign="center" width={400}>
        <Box>
          <GiftOutlined style={{ fontSize: 70 }} />
        </Box>
        <Typography mt={15}>
          A gifting campaign allows you to give discounts, freebies and more to influencers in
          exchange for social media posts
        </Typography>

        <Button type="action" onClick={() => setCreateModalVisible(true)}>
          Create your first gifting campaign
        </Button>
      </Box>
    </Box>
  );
}
