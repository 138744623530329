// @ts-nocheck
import React from "react";
import { Alert } from "antd";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import Container from "src/shared/Container";
import AnalyticsTab from "./AnalyticsTab";

const AnylyticsAlert = styled(Alert)`
  &&& {
    font-size: 14px !important;
    max-width: 450px;
    width: 100%;
    .ant-alert-message {
      color: ${props => props.theme.colors.black2} !important;
    }
  }
`;

type Props = {
  isShowTrack: boolean;
};

const Analytics: React.FC<Props> = ({ isShowTrack }) => {
  return (
    <Container>
      <Box mt={20} mb={20} display="flex" justifyContent="space-between">
        <AnylyticsAlert
          message="Analytics are gathered hourly while your campaign is running"
          type="info"
          showIcon
        />
      </Box>

      <AnalyticsTab isShowTrack={isShowTrack} />
    </Container>
  );
};

export default Analytics;
