// @ts-nocheck
import React, { useRef, useEffect } from "react";
import maxBy from "lodash/maxBy";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { useInViewport } from "react-in-viewport";
import { useScreenClass } from "react-grid-system";
import { VelocityComponent } from "velocity-react";
import Strip from "../CategorisedTopData/Strip";
import FadeInSection from "../../FadeInSection";

interface Props {
  onChangeSection: (value: string) => void;
  campaignData: any;
}

const ClickThroughRate: React.FC<Props> = ({
  onChangeSection,
  campaignData,
}) => {
  let data = campaignData?.topClickThroughtRates || [];

  const maxValue = maxBy(data, "engagementRate") as any;

  let newData = data.map((item: any, index) => {
    const newItem = { ...item };

    let newItemPercentage =
      (item?.engagementRate * 100) / maxValue?.engagementRate;

    newItem.percentage = Math.round(newItemPercentage);

    return newItem;
  });

  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};
  let props = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  const screenClass = useScreenClass();

  useEffect(() => {
    if (inViewport) {
      onChangeSection("ctr");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport]);

  return (
    <FadeInSection>
      <Box
        mt={50}
        mb={50}
        position="relative"
        display="block"
        id="ctr"
        ref={myRef as any}
      >
        {(newData || []).length > 0 && (
          <Box textAlign="right">
            <VelocityComponent
              key="slideUp"
              animation={
                enterCount <= 1 && inViewport
                  ? "transition.slideUpIn"
                  : "transition.fadeIn"
              }
              delay={50}
            >
              <Box>
                <Typography
                  fontSize={
                    screenClass === "xs" || screenClass === "sm" ? 32 : 55
                  }
                  fontWeight={600}
                  color="white"
                  mb={0}
                >
                  Click-through rate
                </Typography>
                <Typography
                  fontSize={
                    screenClass === "xs" || screenClass === "sm" ? 26 : 36
                  }
                  fontWeight={500}
                  color="white"
                  mb={30}
                >
                  Snapchat
                </Typography>
              </Box>
            </VelocityComponent>
            <VelocityComponent
              key="slideUp1"
              animation={
                enterCount <= 1 && inViewport
                  ? "transition.slideUpIn"
                  : "transition.fadeIn"
              }
              delay={70}
            >
              <Typography
                fontSize={
                  screenClass === "xs" || screenClass === "sm" ? 18 : 24
                }
                color="white"
                mb={30}
              >
                Here are your top 5 performing influencers for this campaign
                based on engagement
              </Typography>
            </VelocityComponent>

            <Box display="flex" flexDirection="column" alignItems="flex-end">
              {newData.map((item, i) => {
                return (
                  <Strip
                    key={i}
                    percentage={item?.percentage}
                    profileUrl={item?.profilePicUrl}
                    username={item?.username}
                    value={item?.engagementRate}
                    inViewport={inViewport}
                    enterCount={enterCount}
                    type="engagement"
                    direction="right"
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </FadeInSection>
  );
};

export default ClickThroughRate;
