// @ts-nocheck
import React from "react";
import { CreateSuggestionForm } from ".";
import { string, object } from "yup";
import { message } from "antd";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useCreateSuggestionAccountMutation } from "src/generated/ApolloComponents";

interface Props {
  closeCreateSuggestionModal: () => void;
  account: any;
  campaign: any;
  refetchSuggestion: () => void;
}

interface CreateSuggestionFormValues {
  campaignId: number;
  accountId: number;
  payoutType: string;
  currency: string;
  rationale: string;
  payoutTypeOriginalPennies: number;
}

export const CreateSuggestionFormContainer: React.FC<Props> = props => {
  const [
    createSuggestionAccountMutation,
    { loading, error },
  ] = useCreateSuggestionAccountMutation();

  const CreateSuggestionSchema = object().shape({
    rationale: string(),
  });

  let initialValues = {
    campaignId: props?.campaign?.id,
    accountId: props?.account?.id,
    rationale: "",
  };

  const onSubmit = async (
    values: CreateSuggestionFormValues,
    actions: FormikActions<CreateSuggestionFormValues>
  ) => {
    try {
      let formData: any = {
        campaignId: values?.campaignId,
        accountId: values?.accountId,
        rationale: values?.rationale,
      };

      const res = await createSuggestionAccountMutation({
        variables: formData,
      });

      if (res?.data?.createSuggestionAccount?.id) {
        message.success("Influencer update successfully");

        props.closeCreateSuggestionModal();
        props.refetchSuggestion();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <CreateSuggestionForm
      initialValues={initialValues}
      CreateSuggestionSchema={CreateSuggestionSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      closeCreateSuggestionModal={props.closeCreateSuggestionModal}
      account={props.account}
    />
  );
};
