import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Field } from "formik";
import FormField from "../FormField";
import { Checkbox } from "formik-antd";
import { Input } from "formik-antd";
import { influencer_kinds } from "src/constants/campaign";
import idx from "idx";
import FormError from "../FormField/FormError";
import { ErrorMessage } from "formik";

const CheckboxGroup = styled(Checkbox.Group)`
  && {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 8px 8px 0px 0px;
    }
  }
`;

export default class InfluencerKinds extends Component {
  render() {
    const influencerKinds =
      idx(this.props, x => x.values.influencer_kinds) || [];

    return (
      <Fragment>
        <FormField
          name={this.props.name || "influencer_kinds"}
          label="What kind of influencer would be perfect for your campaign?"
        >
          <CheckboxGroup>
            {influencer_kinds.map((value, i) => (
              <Checkbox
                data-cy={`influencer-kinds-checkbox-${i}`}
                key={i}
                value={value.value}
              >
                {value.label}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </FormField>

        {influencerKinds.indexOf("Others") > -1 && (
          <div style={{ marginBottom: 20 }}>
            <Field>
              {({ form }) => {
                const influencer_kinds_other =
                  idx(form, x => x.values.influencer_kinds_other) || "";
                return (
                  <Input
                    type="text"
                    size="large"
                    placeholder="Please describe your ideal influencer"
                    name="influencer_kinds_other"
                    onChange={e => {
                      form.setFieldValue(
                        "influencer_kinds_other",
                        e.target.value
                      );
                    }}
                    style={{ width: 450 }}
                    value={influencer_kinds_other}
                  />
                );
              }}
            </Field>
            <ErrorMessage
              component={FormError}
              name="influencer_kinds_other"
              cy="influencer_kinds_other_error"
            />
          </div>
        )}
      </Fragment>
    );
  }
}
