// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FilterProps } from "../../PaymentQueueList";
import { Input } from "antd"

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const HubSpotId: React.FC<Props> = ({ filters, handleChange }) => {
  return (
    <Field label="HubSpot Deal ID:" >
      <Input
        value={filters.hubspotDealId}
        onChange={e => handleChange("hubspotDealId", e.target.value)}
        placeholder="HubSpot Deal ID"
      />
    </Field>
  );
};

export default HubSpotId;
