import React, { Component } from "react"
import FormField from "../FormField"
import { Select } from "formik-antd"
import { formats } from "src/constants/account"

export default class Platform extends Component {
  render() {
    return (
      <FormField name={this.props.name || "format"} label="Format">
        <Select data-cy="account-format" size="large" placeholder="Select..." options={formats}>
        </Select>
      </FormField>
    )
  }
}
