import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Row, Alert } from "antd";
import PageHero from "src/shared/PageHero";
import { withRouter } from "react-router-dom";
import CampaignReview from "src/shared/CampaignReview";
import Container from "src/shared/Container";
import AccountModal from "./FindInfluencers/AccountModal";

const ReviewWrapper = styled(Row)`
  background: #fff;
  margin-bottom: 24px;
`;

class Submitted extends Component {

  render() {
    const { campaign, history, title } = this.props;

    return (
      <Fragment>
        <PageHero onBack={history.goBack} title={title} />

        <Container>
          <Alert
            message="Campaign under review"
            description="We will review your campaign in the next 24 hours and send you an email when it has been approved."
            type="info"
            style={{ marginBottom: "24px" }}
          />

          <ReviewWrapper>
            <CampaignReview campaign={campaign} />
          </ReviewWrapper>
        </Container>
        <AccountModal />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Submitted));
