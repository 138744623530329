import React, { Component } from "react";
import styled from "styled-components";
import { PageHeader } from "antd";

const MarginBottomPageHeader = styled(PageHeader)`
  &&& {
    margin-bottom: 30px;
  }
`;

export default class PageHero extends Component {
  render() {
    return <MarginBottomPageHeader {...this.props} />;
  }
}
