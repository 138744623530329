// @ts-nocheck
import React from "react";
import { Checkbox } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";

interface Props {
  isChecked: boolean;
  setFieldValue: Function;
  setFieldTouched: Function;
  setPayingToAgency: Function;
}

const PayingAgency: React.FC<Props> = ({
  isChecked,
  setFieldValue,
  setFieldTouched,
  setPayingToAgency,
}) => {
  return (
    <Box display="inline-flex">
      <Checkbox
        name="payingAgency"
        onChange={() => {
          setFieldTouched("payingAgency");
          setFieldValue("payingAgency", !isChecked);
          setPayingToAgency(!isChecked)
        }}
        value={isChecked}
        data-cy="checkbox-payingAgency"
      />
      <Typography size={12} color="grey9" ml={10} mt={0.9}>
        I’m paying a Talent Agency for this campaign
      </Typography>
    </Box>
  );
};

export default PayingAgency;
