// @ts-nocheck
import React, { useEffect } from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Radio, Col, Row } from "antd";
import { AmountRadio } from "../../../Wrapper";
import { Box } from "src/components/Core/Box";
import { ages } from "src/constants/account";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { FormikSelect } from "src/components/Forms/Select";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import LinkButton from "src/components/Core/LinkButton";
import { PlusIcon, RemoveIcon } from "../../../Wrapper";
import { AudienceBreakdownComparisonType } from "src/generated/ApolloComponents";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const AdvanceAgeAudience: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (
    (formProps?.values?.audienceAge || []).length > 0 &&
    (formProps?.values?.audienceAge || [])?.[0]?.key !== ""
  ) {
    isVisible = true;
  }

  let drodownData = ages;
  let dataCount = ages.length;

  drodownData = drodownData.filter((item: any, index) => {
    const newItem = { ...item };
    if (
      !find(
        formProps?.values["audienceAge"],
        matchesProperty("key", newItem?.value)
      )
    ) {
      return true;
    }
    return false;
  });

  const addData = () => {
    (formProps?.values["audienceAge"]).push({
      key: "",
      comparison: AudienceBreakdownComparisonType.GreaterEqualThan,
      value: 0,
    });
    formProps?.setFieldValue("audienceAge", formProps?.values["audienceAge"]);
  };

  const removeData = index => {
    (formProps?.values["audienceAge"]).splice(index, 1);
    formProps?.setFieldValue("audienceAge", formProps?.values["audienceAge"]);
  };

  useEffect(() => {
    let advanceAgeAudience = [] as any;

    if ((formProps?.values?.audienceAge || []).length > 0) {
      advanceAgeAudience = (formProps?.values?.audienceAge || []).map(item => {
        let container = {} as any;
        container.key = item?.key;
        container.comparison = item?.comparison
          ? item?.comparison
          : AudienceBreakdownComparisonType.GreaterEqualThan;
        container.value = item?.value ? item?.value : 0;
        return container;
      });
    } else {
      advanceAgeAudience = [
        {
          key: "",
          comparison: AudienceBreakdownComparisonType.GreaterEqualThan,
          value: 0,
        },
      ];
    }

    formProps.setFieldValue("audienceAge", advanceAgeAudience);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={8}>
      <Field
        label="Age:"
        mb={20}
        color="black"
        isBeside={true}
        extra={
          isVisible && (
            <RemoveIcon
              type="close-circle"
              onClick={() => {
                formProps?.setFieldValue("audienceAge", [
                  {
                    key: "",
                    comparison:
                      AudienceBreakdownComparisonType.GreaterEqualThan,
                    value: 0,
                  },
                ]);
                clearFilterKey("audienceAge");
              }}
              style={{ marginTop: "0px", marginLeft: "10px" }}
            />
          )
        }
        id="ageBreakdown"
      >
        {(formProps?.values?.["audienceAge"] || []).map((value, i) => (
          <Box display="flex" key={i} marginBottom={10} mt={10}>
            <Col span={10}>
              <FormikSelect
                name={`audienceAge[${i}].key`}
                data={drodownData}
                local
                borderRadius={3}
                borderColor="grey5"
                size="small"
              />
            </Col>

            <Col span={12}>
              <AmountRadio>
                <Radio.Button
                  value={AudienceBreakdownComparisonType.SmallerEqualThan}
                  onChange={v => {
                    if (value?.value || value?.value === 0) {
                      formProps?.setFieldValue(
                        `audienceAge[${i}].comparison`,
                        AudienceBreakdownComparisonType.SmallerEqualThan
                      );
                    }
                  }}
                  checked={
                    value?.comparison ===
                    AudienceBreakdownComparisonType.SmallerEqualThan
                  }
                >
                  <LeftOutlined />
                </Radio.Button>
                <Radio.Button
                  value={AudienceBreakdownComparisonType.GreaterEqualThan}
                  onChange={v => {
                    if (value?.value || value?.value === 0) {
                      formProps?.setFieldValue(
                        `audienceAge[${i}].comparison`,
                        AudienceBreakdownComparisonType.GreaterEqualThan
                      );
                    }
                  }}
                  checked={
                    value?.comparison ===
                    AudienceBreakdownComparisonType.GreaterEqualThan
                  }
                >
                  <RightOutlined />
                </Radio.Button>
              </AmountRadio>

              <FormikInputNumber
                parser={value => {
                  let parsed = value.replace(/\[0-9]/g, "");
                  if (parsed) {
                    return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                  } else {
                    return null;
                  }
                }}
                name={`audienceAge[${i}].value`}
                borderRadius={3}
                borderColor="grey5"
                size="small"
                style={{ marginLeft: 5, width: "72px" }}
              />
            </Col>

            <Col span={2}>
              <RemoveIcon type="close-circle" onClick={() => removeData(i)} />
            </Col>
          </Box>
        ))}

        {drodownData?.length > 0 &&
          dataCount >= formProps?.values?.["audienceAge"].length + 1 && (
            <LinkButton
              onClick={() => addData()}
              color="blue"
              fontSize={12}
              fontWeight="500"
            >
              <PlusIcon type="plus-circle" /> Add new filter layer
            </LinkButton>
          )}
      </Field>
    </Row>
  );
};

export default AdvanceAgeAudience;
