// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import CampaignDetail from "./CampaignDetail";
import Objective from "./Objective";
import Views from "./Views";
import Engagement from "./Engagement";
import ClickThroughRate from "./ClickThroughRate";
import Stats from "./Stats";
import InfluencerStats from "./InfluencerStats";
import { ThankYouContainer } from "./ThankYou";
import { showEngagement, showCTR } from "../../utils";

interface Props {
  onChangeSection: (value: string) => void;
  campaignId: number;
  campaignData: any;
  loading: boolean;
}

const Content: React.FC<Props> = ({
  onChangeSection,
  campaignId,
  campaignData,
  loading,
}) => {
  return (
    <Box width="100%" p={40}>
      <Box width="100%" maxWidth="1020px" margin="0 auto">
        <CampaignDetail campaignData={campaignData} loading={loading} />
        {campaignData?.campaignObjective && (
          <Objective
            onChangeSection={value => onChangeSection(value)}
            objective={campaignData?.campaignObjective}
          />
        )}

        <Views
          onChangeSection={value => onChangeSection(value)}
          campaignData={campaignData}
        />

        {showCTR(campaignData) && (
          <ClickThroughRate
            onChangeSection={value => onChangeSection(value)}
            campaignData={campaignData}
          />
        )}
        {showEngagement(campaignData) && (
          <Engagement
            onChangeSection={value => onChangeSection(value)}
            campaignData={campaignData}
          />
        )}

        {(campaignData?.campaignComments ||
          campaignData?.campaignHearts ||
          campaignData?.campaignEngagementRate ||
          campaignData?.campaignClickThroughtRate) && (
          <Stats campaignData={campaignData} />
        )}

        {campaignData?.mostLikedPost && showEngagement(campaignData) && (
          <InfluencerStats
            onChangeSection={value => onChangeSection(value)}
            campaignData={campaignData}
          />
        )}
        <ThankYouContainer campaignId={campaignId} />
      </Box>
    </Box>
  );
};

export default Content;
