// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Popover, Avatar } from "antd";

const EllipsisText = styled("span")`
  && {
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: semi-bold;
    margin-left: 10px;
  }
`;

const ReferenceLineLabel = props => {
  const { viewBox, influencers, labelType, isFirst } = props;

  const x = isFirst ? viewBox.x : viewBox.x - 140;
  const y = labelType === "even" ? 160 : 80;

  const renderContent = () => {
    const { influencers } = props;
    return (
      <Box>
        {influencers.map((value, i) => (
          <Box key={i}>
            <Box display="inline-flex">
              <Avatar src={value?.image} size={20} />
              <Typography size={14} color="black" ml={10}>
                {value?.name}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <foreignObject x={x} y={y} width="140" height="200">
      {(influencers || []).length > 1 ? (
        <Popover content={renderContent()} trigger="hover">
          <Box
            color="blue"
            fontSize={14}
            background="#FFFFFF"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
            borderRadius="4px"
            p="8px"
          >
            {(influencers || []).length} Influencer's
          </Box>
        </Popover>
      ) : (
        <>
          {influencers.map((value, i) => (
            <Box
              key={i}
              color="blue"
              fontSize={14}
              background="#FFFFFF"
              boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
              borderRadius="4px"
              p="8px"
            >
              <Box display="inline-flex">
                <Avatar src={value?.image} size={20} />
                <EllipsisText>{value?.name}</EllipsisText>
              </Box>
            </Box>
          ))}
        </>
      )}
    </foreignObject>
  );
};

export default ReferenceLineLabel;
