// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { UpdateInfluencerFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  Name,
  Email,
  PaypalEmail,
  PhoneNumber,
  Address,
  VatNumber,
  Punctuality,
  Professionalism,
  Quality,
  ManageTag,
  Locations,
  Agency,
  Gender,
  Ages,
  InfluencerFlags,
} from "./FormFields";
import { featureFlags } from "src/constants/featureFlags";

interface UpdateInfluencerFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  UpdateInfluencerSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  closeInfluencerModal: () => void;
  getAgencies: Function;
}

export const UpdateInfluencerFormWrapper = styled.div`
  width: 100%;
`;

const UpdateInfluencerForm: React.FC<UpdateInfluencerFormProps> = ({
  initialValues,
  isSubmitting,
  UpdateInfluencerSchema,
  onSubmit,
  globalErrors,
  closeInfluencerModal,
  getAgencies,
}) => {
  return (
    <UpdateInfluencerFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={UpdateInfluencerSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                <Name />
                <Email />
                {featureFlags.isPaypalEnabled && <PaypalEmail />}
                <PhoneNumber />
                <Address />
                <VatNumber />
                <Agency getAgencies={getAgencies} />
                <ManageTag />
                <Gender />
                <Ages />
                <Locations />

                <Box mb="5px" mt="15px">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Punctuality />
                    </Col>
                    <Col span={12}>
                      <Professionalism />
                    </Col>
                  </Row>
                </Box>
                <Box mt="15px">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Quality />
                    </Col>
                    <Col span={12}></Col>
                  </Row>
                </Box>

                <InfluencerFlags />

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={20}
                >
                  <LinkButton onClick={() => closeInfluencerModal()} mr={20}>
                    Cancel
                  </LinkButton>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    buttonSize="middle"
                    data-cy="submit-button"
                  >
                    Update
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </UpdateInfluencerFormWrapper>
  );
};

export { UpdateInfluencerForm, UpdateInfluencerFormContainer };
