import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { show } from "redux-modal";
import { Button } from "antd";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import Table from "src/shared/Table";
import Filters from "../Influencers/Filters";
import CreateModal from "../Influencers/CreateModal";
import { default as $TableManager } from "src/ducks/TableManager";
import { InfluencerList as $InfluencerList } from "src/ducks/DataList";
import withWindowResize from "src/utils/hoc/withWindowResize";

const InfluencerList = $InfluencerList("AgencyInfluencerList");
const InfluencerFilters = $TableManager("AgencyInfluencerList");

const modules = [InfluencerList.module(), InfluencerFilters.module()];

export class InfluencersTable extends Component {
  loadInfluencers = filters => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.loadInfluencers({
      agency: id,
      ...filters,
    });
  };

  render() {
    const {
      influencers,
      pagination,
      loading,
      style,
      filters,
      show,
      height,
      match: {
        params: { id },
      },
    } = this.props;

    const columns = {
      name: {
        title: "Name",
        dataIndex: "name",
        visible: true,
        width: 300,
        render: (text, row) => (
          <Link to={`/influencers/${row.id}`}>{text}</Link>
        ),
      },
      email: {
        title: "Email",
        dataIndex: "email",
        visible: true,
        width: 350,
      },
    };

    return (
      <>
        <Table
          style={style}
          title="Influencer"
          columns={columns}
          loadData={this.loadInfluencers}
          name="AgencyInfluencerList"
          pagination={pagination}
          data={influencers}
          extra={[
            <Button
              type="primary"
              key="create"
              data-cy="influencer-create-button"
              onClick={() => show(`CreateInfluencerModal`)}
            >
              Create new Influencer
            </Button>,
          ]}
          filterOptions={Filters}
          loading={loading}
          size="middle"
          scroll={{ y: height, x: "max-content" }}
        />

        <CreateModal
          initialValues={{ agency: id }}
          hideAgency={true}
          onCreate={() => this.loadInfluencers(filters)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  filters: InfluencerFilters.selectFilters(state),
  influencers: InfluencerList.selectMany(state),
  loading: InfluencerList.selectPending(state),
  pagination: InfluencerList.selectPagination(state),
});

const mapDispatchToProps = {
  show,
  loadInfluencers: InfluencerList.loadMany,
};

const MainInfluencersTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InfluencersTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainInfluencersTable);
