// @ts-nocheck
import React from "react";
import { ExternalBasicInfo } from ".";

import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";

interface Props {
  account: any;
}

export const ExternalBasicInfoContainer: React.FC<Props> = ({ account }) => {
  const accountData = account?.data?.getAccountDetailById;

  const globalErrors = useMapGraphQLErrorsMessages(account?.error);

  return (
    <ExternalBasicInfo
      isLoading={account?.loading}
      globalErrors={globalErrors}
      accountData={accountData}
    />
  );
};
