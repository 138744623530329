// @ts-nocheck
import React, { Fragment } from 'react'
import { AppstoreFilled, UnorderedListOutlined } from '@ant-design/icons';
import { Box } from 'src/components/Core/Box'
import { Typography } from 'src/components/Core/Typography'
import { Button } from 'src/components/Core/Button'
import ManageColumn from '../../ManageColumn'
import QuickSearch from '../QuickSearch'
import { Actions as Props } from '../props'

const Actions = ({
  allAccounts,
  appliedFilter,
  contentType,
  containerType,
  filterDataSoruce,
  handleSuggestedClick,
  hasSelected,
  isInnerAccountList,
  loading,
  onFilter,
  onSearchTextChange,
  setContentType,
  searchText,
  selectedRowKeys,
  list,
}: Props) => {
  const notValue = () => {
    return containerType !== 'listAccounts' &&
            <b style={{fontWeight: 600, fontSize: '18px'}}>not</b>
  }
  return (
    <Fragment>
      {!isInnerAccountList ? (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {list?.id &&
                <Typography color="black" size={16} style={{marginLeft: '50px'}}>
                  Users {notValue()} included in this list
                </Typography>
              }
            </Box>
            {!loading && (
              <Box display="inline-flex" alignItems="center" minWidth={115}>
                <UnorderedListOutlined
                  onClick={() => {
                    onFilter("viewMode", false);
                    setContentType("list");
                  }}
                  style={{
                    color: contentType === "list" ? "#1890FF" : "#BFBFBF",
                  }} />
                <AppstoreFilled
                  onClick={() => {
                    onFilter("viewMode", true);
                    setContentType("grid");
                  }}
                  style={{
                    color: contentType === "grid" ? "#1890FF" : "#BFBFBF",
                    marginLeft: "10px",
                  }} />

                <Typography size={12} color="black2" mt="10px" mr="10px" ml={15}>
                  {allAccounts?.totalEntries} results
                </Typography>
              </Box>
            )}
          </Box>
          <Box background="white" borderTopRightRadius="4px">
            {(appliedFilter || []).length > 0 && (
              <Box p="8px">
                {appliedFilter}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          background="white"
          display="flex"
          justifyContent="space-between"
          p={10}
        >
          {!loading && (
            <>
              <Box ml={35}>
                <Box display="inline-flex">
                  <QuickSearch
                    handleChange={onFilter}
                    onSearchTextChange={onSearchTextChange}
                    searchText={searchText}
                    filterDataSoruce={filterDataSoruce}
                  />

                  <Box display="inline-flex" alignItems="center" ml={10}>
                    <UnorderedListOutlined
                      onClick={() => {
                        onFilter("viewMode", false);
                        setContentType("list");
                      }}
                      style={{
                        color: contentType === "list" ? "#1890FF" : "#BFBFBF",
                      }} />
                    <AppstoreFilled
                      onClick={() => {
                        onFilter("viewMode", true);
                        setContentType("grid");
                      }}
                      style={{
                        color: contentType === "grid" ? "#1890FF" : "#BFBFBF",
                        marginLeft: "10px",
                      }} />
                  </Box>
                  {contentType === "list" &&
                    <Button
                      type="action"
                      buttonSize="small"
                      onClick={handleSuggestedClick}
                      ml={10}
                      disabled={!hasSelected}
                    >
                      Suggest selected ({selectedRowKeys.length})
                    </Button>
                }
                </Box>
                {(appliedFilter || []).length > 0 && (
                  <Box p="8px">{appliedFilter}</Box>
                )}
              </Box>
              {contentType === "list" && <ManageColumn />}
            </>
          )}
        </Box>
      )}
    </Fragment>
  );
}

export default Actions;
