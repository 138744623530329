// @ts-nocheck
import { Upload } from "antd"
import React from "react"
import styled from "styled-components"

export const UploadButton = styled(({ totalDraft, ...props }) => <Upload {...props} />)`
  && {
    display: flex !important;

    .ant-upload-select-picture-card {
      height: 123px;
      width: ${(props) => (props.totalDraft > 0 || props.isUploading ? "81px" : "291px")};
      border: 1px dashed #1890ff;
      border-radius: 9px;
      margin-top: 20px;
      text-align: center;
      margin-left: ${(props) => (props.totalDraft > 0 || props.isUploading ? "8px" : "0px")};
    }

    .anticon-upload {
      font-size: 20px;
      color: #bcbcbc;
    }
    .anticon-eye-o {
      display: none;
    }
    .anticon-close {
      display: none;
    }
    .ant-upload-list-item-uploading-text {
      display: none;
    }
    .anticon {
      opacity: 0.82;
      font-size: 37px;
      font-weight: 500;
      letter-spacing: 1.81px;
      line-height: 45px;
      text-align: center;
      color: #1890ff;
    }

    @media (min-width: 768px) {
      .ant-upload-select-picture-card {
        width: 81px;
      }
    }
  }
`

export const PreviewWrapper = styled.div<{
  totalDraft: any
  isFeedback: boolean | undefined
}>`
  /* It is for Mobile View PostFeedback screen we need to hide PreviewWarpper
  if feedback list not get or not select any one video*/

  display: ${(props) => (props.totalDraft > 0 || !props.isFeedback ? "flex" : "none")};

  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: left;
    display: flex;
  }
`
