// @ts-nocheck
import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import { Typography } from "src/components/Core/Typography";
import { Link } from "react-router-dom";
import numeral from "numeral";
import { Box } from "src/components/Core/Box";
import compact from "lodash/compact";
import {
  DataSourcePlatform,
  AccountType,
} from "src/generated/ApolloComponents";

const LineIcon = styled("span")`
  &&& {
    padding: 3px;
    background: ${props => props.theme.colors[props.color as string]};
    border-radius: 3px;
    width: 24px;
    margin: 2px;
  }
`;

const getFormula = accountType => {
  let formula = "";
  if (accountType === AccountType.Instagram) {
    formula = "Avg like / Avg views";
  } else if (accountType === AccountType.TikTok) {
    formula = "Avg like / Avg views";
  } else if (accountType === AccountType.YouTube) {
    formula = "";
  } else if (accountType === AccountType.Snapchat) {
    formula = "";
  }
  return formula;
};

const getColumns = platform => {
  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 180,
      render: (text, row) => (
        <Link
          to={`/influencer-profile/${platform.toLowerCase()}/${
            platform === DataSourcePlatform.Fanbytes
              ? `${row?.influencerId}/account/${row?.accountId}`
              : row?.accountId
          }`}
        >
          <Typography size={12} cursor="pointer">
            {row?.username ? row?.username : "-"}
          </Typography>
        </Link>
      ),
    },
    platform === DataSourcePlatform.Fanbytes && {
      title: "Influencer",
      dataIndex: "influencerName",
      key: "influencerName",
      width: 180,
      render: (text, row) => (
        <Typography size={12} cursor="pointer">
          {row?.influencerName ? row?.influencerName : "-"}
        </Typography>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      width: 100,
      render: (text, row) => (
        <Typography size={12} cursor="pointer">
          {row?.platform ? row?.platform : "-"}
        </Typography>
      ),
    },
    platform === DataSourcePlatform.Fanbytes && {
      title: "Format",
      dataIndex: "format",
      key: "format",
      width: 100,
      render: (text, row) => (
        <Typography size={12} cursor="pointer">
          {row?.format ? row?.format : "-"}
        </Typography>
      ),
    },
    platform === DataSourcePlatform.Fanbytes && {
      title: "Campaign",
      dataIndex: "campaignCount",
      key: "campaignCount",
      width: 100,
      render: (text, row) => (
        <>{row?.campaignCount ? row?.campaignCount : "-"}</>
      ),
    },

    platform === DataSourcePlatform.Bytesights && {
      title: "Avg. Likes",
      dataIndex: "avgLikes",
      key: "avgLikes",
      width: 100,
      render: (text, row) => (
        <Typography size={12}>
          {row?.avgLikes ? numeral(row?.avgLikes).format("0,0") : "-"}
        </Typography>
      ),
    },

    platform === DataSourcePlatform.Bytesights && {
      title: "Avg. Views",
      dataIndex: "avgViews",
      key: "avgViews",
      width: 100,
      render: (text, row) => (
        <Typography size={12}>
          {row?.avgViews ? numeral(row?.avgViews).format("0,0") : "-"}
        </Typography>
      ),
    },

    platform === DataSourcePlatform.Bytesights && {
      title: "Avg. Comments",
      dataIndex: "avgComments",
      key: "avgComments",
      width: 100,
      render: (text, row) => (
        <Typography size={12}>
          {row?.avgComments ? numeral(row?.avgComments).format("0,0") : "-"}
        </Typography>
      ),
    },

    platform !== DataSourcePlatform.Fanbytes && {
      title: "Followers",
      dataIndex: "followers",
      key: "followers",
      width: 100,
      render: (text, row) => (
        <Typography size={12}>
          {row?.followers ? numeral(row?.followers).format("0,0") : "-"}
        </Typography>
      ),
    },

    {
      title: "Eng./CTR",
      dataIndex: "engCTR",
      key: "engCTR",
      width: 120,
      render: (text, row) => {
        let color1 = "grey8";
        let color2 = "grey8";
        let color3 = "grey8";

        if (row?.engCTR < 1) {
          color1 = "red5";
        } else if (row?.engCTR >= 1 && row?.engCTR <= 2) {
          color1 = "blue";
          color2 = "blue";
        } else if (row?.engCTR >= 3) {
          color1 = "green4";
          color2 = "green4";
          color3 = "green4";
        }

        return (
          <Tooltip placement="top" title={`${getFormula(row?.platform)}`}>
            <Box display="inline-flex">
              <LineIcon color={color1} />
              <LineIcon color={color2} />
              <LineIcon color={color3} />
            </Box>
          </Tooltip>
        );
      },
    },

    platform !== DataSourcePlatform.Bytesights && {
      title: "Price p/p",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (text, row) => {
        return (
          <Typography size={12} color="black3">
            {row?.price ? numeral(row?.price / 100).format("0,0[.]00") : ""}
          </Typography>
        );
      },
    },
  ];

  return compact(columns);
};

export default getColumns;
