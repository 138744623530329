import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class Address extends Component {
  render() {
    return (
      <FormField name={this.props.name || "detail.address"} label="Address">
        <Input
          data-cy="influencer-address"
          size="large"
          placeholder="e.g. 39 Dressing Crescent, SE14 653"
        />
      </FormField>
    )
  }
}
