// @ts-nocheck
import React from "react";
import { UploadDraftContainer } from "./container";
import FieldError from "../FieldError";
import { InboxOutlined } from '@ant-design/icons';
import { Upload, message } from "antd";
import styled from "styled-components";

export const UploadDraftWrapper = styled.div`
  width: 100%;
`;

type UploadDraftProps = {
  handleRemove: (value: any) => void;
  handleChange: (value: any) => void;
  fileList: Array<any>;
  name: string;
};

const UploadDraft: React.FC<UploadDraftProps> = ({
  handleRemove,
  handleChange,
  fileList,
  name,
}) => {
  return (
    <UploadDraftWrapper data-cy="input-uploadDraft">
      <Upload.Dragger
        multiple={true}
        onChange={({ file, fileList: newFiles }) => {
          const isLt250M = file.size / 1024 / 1024 < 250;

          if (!isLt250M) {
            message.error("Draft video must be smaller than 250MB!");
          } else {
            handleChange(
              newFiles
                .map(file => file.originFileObj)
                .filter(
                  file =>
                    typeof file !== "undefined" && file.size / 1024 / 1024 < 250
                )
            );
          }
        }}
        onRemove={file => handleRemove(file)}
        accept="video/mp4,video/x-m4v,video/*, image/png, image/jpeg, image/*"
        beforeUpload={() => false}
        fileList={fileList}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading
          company data or other band files and Draft video must be smaller than
          250MB!
        </p>
      </Upload.Dragger>
      <FieldError fieldName={name} />
    </UploadDraftWrapper>
  );
};

export { UploadDraft, UploadDraftContainer };
