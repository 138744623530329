// @ts-nocheck
import { Layout } from "antd";
import styled from "styled-components";
import background from "../../../../assets/images/background.png";

export const Wrapper = styled(Layout)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: unset !important;
  background-image: url(${background}) !important;
  background-repeat: repeat !important;
`;

export const Content = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Logo = styled("img")`
  width: 150px;
  display: block;
  margin: 0 auto;
  text-align: center;
`;

export const TopBar = styled("div")`
  display: inline-block;
  margin: 0 auto;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  z-index: 9;
`;
