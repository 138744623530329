import React, { Component } from "react";
import { PercentageOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import FormField from "../FormField";
import { InputNumber } from "formik-antd";
import { ages } from "src/constants/account";
import { ErrorMessage } from "formik";
import FormError from "src/shared/FormField/FormError";

export default class AgesBreakdown extends Component {
  render() {
    return <>
      <Row gutter={8}>
        {ages.map((age, index) => (
          <Col span={4} key={index}>
            <FormField
              name={
                this.props.age_breakdown ||
                `age_breakdown.manual[${age.value}]`
              }
              label={`Age (${age.label})`}
            >
              <InputNumber
                size="large"
                min={0}
                max={100}
                addonAfter={<PercentageOutlined />}
              />
            </FormField>
          </Col>
        ))}
      </Row>
      <ErrorMessage
        component={FormError}
        name={`age_breakdown.manual`}
        cy={`age_breakdown-manual`}
        style={{ marginTop: "0px" }}
      />
    </>;
  }
}
