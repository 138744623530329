// @ts-nocheck
import styled from "styled-components";
import { Table as AntdTable } from "antd";

interface Props {
  stripe?: boolean;
  rowHover?: boolean;
}

export const StyledTable = styled(AntdTable)<Props>`
  && {
    .ant-table-thead > tr > th {
      background: ${props => (props?.stripe ? "#fff" : "#fafafa")};
      border-bottom: ${props => (props?.stripe ? "none" : "1px solid #e8e8e8")};
    }
    .ant-table-row-expand-icon {
      display: none;
    }

    .ant-table-expand-icon-th,
    .ant-table-row-expand-icon-cell {
      width: 0px;
      min-width: 0px;
      padding: 0px;
    }

    .ant-table-body {
      overflow: auto !important;
    }

    .ant-table-tbody > tr:nth-child(odd) > td {
      border-bottom: ${props => (props?.stripe ? "none" : "1px solid #e8e8e8")};
      background: ${props => (props?.stripe ? "#f7f7f7" : "none")};
    }

    .ant-table-tbody > tr:nth-child(even) > td {
      border-bottom: ${props => (props?.stripe ? "none" : "1px solid #e8e8e8")};
      background: #fff;
    }

    .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
      background: ${props =>
        props?.rowHover ? props.theme.colors.blue1 : "none"} !important;
    }

    .ant-table-tbody > .bluetick > td {
      background: ${props => props.theme.colors.geekblue1} !important;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 8px 8px !important;
      border-bottom: 1px solid #e8e8e8 !important;
      text-align: left;
    }

    .ant-table-row-selected {
      opacity: 0.5;
    }
  }
`;
