import React, { Component } from "react";
import styled from "styled-components";
import { Modal, Layout, Menu, Typography } from "antd";

const { Content, Sider } = Layout;
const { Paragraph } = Typography;

const DetailsWrapper = styled("div")`
  padding: 30px;

  video {
    width: 80%;
  }
`;

const VideoContainer = styled("div")`
  text-align: center;
  margin-bottom: 24px;
`;

export default class AdFormatModal extends Component {
  state = {
    page: "snapchat",
  };

  changePage = page => {
    this.setState({ page: page });
  };

  render() {
    const { visible, handleClose } = this.props;

    const menuOptions = [
      {
        key: "snapchat",
        label: "Snapchat Influencers",
        video:
          "https://s3.eu-west-2.amazonaws.com/dashboard-files/static/sc-example.mp4",
        body: (
          <Paragraph>
            This video will be posted on the account of human influencers on
            Snapchat as a story. The purpose is to encourage their followers to
            engage with your product. Human influencers have a lower reach than
            themed pages but better engagement.
          </Paragraph>
        ),
      },
      {
        key: "instagram",
        label: "Instagram Influencers",
        video:
          "https://s3.eu-west-2.amazonaws.com/dashboard-files/static/ig-example.mp4",
        body: (
          <Paragraph>
            This video will be posted on the account of human influencers on
            Instagram as a story. The purpose is to encourage their followers to
            engage with your product. Human influencers have a lower reach than
            media influencers but better engagement.
          </Paragraph>
        ),
      },
      {
        key: "tiktok",
        label: "TikTok Influencers",
        video:
          "https://s3.eu-west-2.amazonaws.com/dashboard-files/static/tiktok-example.mp4",
        body: (
          <Paragraph>
            This video will be posted on the account of human influencers on
            TikTok as a story. The purpose is to encourage their followers to
            engage with your product. Human influencers have a lower reach than
            media influencers but better engagement. TikTok influencers are very
            creative and talented, however they can’t add a link to their post.
            In other words, the call to action has to be fully organic.
          </Paragraph>
        ),
      },
      {
        key: "youtube",
        label: "YouTube Influencers",
        video:
          "https://s3.eu-west-2.amazonaws.com/dashboard-files/static/youtube-example.mp4",
        body: (
          <Paragraph>
            This video will be posted on the account of human influencers on
            YouTube as a story. The purpose is to encourage their followers to
            engage with your product. Human influencers have a lower reach than
            media influencers but better engagement. YouTube influencers are
            very creative and talented, however they can’t add a link to their
            post. In other words, the call to action has to be fully organic.
          </Paragraph>
        ),
      },
      {
        key: "themed",
        label: "Themed Pages",
        video:
          "https://s3.eu-west-2.amazonaws.com/dashboard-files/static/themed-example.mp4",
        body: (
          <Paragraph>
            Themed pages are accounts that post content about a specific theme
            on a daily basis. For example, there are pages about comedy, make up
            or sports. This video will be posted on their stories in the middle
            of their usual themed feed so it will look more organic than a Snap
            Ad. The creative is usually a 10 second motion graphic video
            promoting your product. Themed pages have a wider reach than human
            influencers but better lower engagement.
          </Paragraph>
        ),
      },
      {
        key: "lenses",
        label: "Content generation",
        video:
          "https://s3.eu-west-2.amazonaws.com/dashboard-files/static/lens-example.mp4",
        body: (
          <Paragraph>
            “Content generation” is new type for Campaign and that users don’t
            need to select age gender location they just need to enter a content
            brief and then they will receive content submission that they can
            reviewand submit feedback for.
          </Paragraph>
        ),
      },

      {
        key: "contents",
        label: "Lenses",
        video:
          "https://s3.eu-west-2.amazonaws.com/dashboard-files/static/lens-example.mp4",
        body: (
          <Paragraph>
            Lenses are 2D/3D animations that can be used by Snapchat users to
            apply them on their face or somewhere in their video. Lenses can be
            distributed using themed pages or human influencers and can be
            unlocked for 48h by any of the viewers. Once unlocked, a user can
            record some videos using your lens and share it to his/her friends.
          </Paragraph>
        ),
      },
    ];

    const activeOption = menuOptions.find(x => x.key === this.state.page);

    return (
      <Modal
        title="Which ad format is for you?"
        visible={visible}
        footer={null}
        onCancel={handleClose}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Layout>
          <Sider width={200} style={{ background: "#fff" }}>
            <Menu
              onClick={this.handleClick}
              selectedKeys={[this.state.page]}
              mode="vertical"
            >
              {menuOptions.map(x => (
                <Menu.Item key={x.key} onClick={() => this.changePage(x.key)}>
                  {x.label}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Content>
            <DetailsWrapper>
              {activeOption && (
                <>
                  <VideoContainer>
                    <video src={activeOption.video} controls />
                  </VideoContainer>
                  {activeOption.body}
                </>
              )}
            </DetailsWrapper>
          </Content>
        </Layout>
      </Modal>
    );
  }
}
