// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Account } from '.'
import { useGetPublicInfluencerProfileQuery } from 'src/generated/ApolloComponents'
import { useMapGraphQLErrorsMessages } from 'src/utils/hooks/useMapGraphQLErrors'

export const AccountContainer = () => {
  const [selectedAccount, setSelectedAccount] = useState(null as any)
  const { data, loading, error } = useGetPublicInfluencerProfileQuery({
    variables: {}
  })
  const globalErrors = useMapGraphQLErrorsMessages(error);

  useEffect(() => {
    const account = data?.getPublicInfluencerProfile?.accounts[0]
    if(account)
      setSelectedAccount(account)
  },[data])

  const handleSelect = (account) => {
    setSelectedAccount(account)
  }

  return(
    <Account
      data={data?.getPublicInfluencerProfile}
      loading={loading}
      account={selectedAccount}
      setAccount={handleSelect}
      globalErrors={globalErrors}
    />
  )
}
