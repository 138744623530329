// @ts-nocheck
import React, { useState } from "react";
import { Box } from "src/components/Core/Box";
import Filters from "../Filters";
import { PaymentQueueListContainer } from "./container";
import { Loading } from "src/components/Core/Loading";
import { Typography } from "src/components/Core/Typography";
import { Table } from "src/components/Core/Table";
import styled from "styled-components";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import columns from "./Columns";
import { connect } from "react-redux";
import Auth from "src/ducks/Auth";
import withWindowResize from "src/utils/hoc/withWindowResize";
import PaymentDetailModal from "./PaymentDetailModal";

const PaymentTable = styled(Table as any)`
  &&& {
    .ant-table-tbody > tr {
      cursor: pointer;
    }
    .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
      cursor: pointer;
    }
  }
`;

export interface FilterProps {
  searchInput: string;
  requestedBy: string;
  campaign: string;
  influencer: string;
  paymentRequestId: number;
  cost: number;
  isGreaterThan: boolean;
  typeOfCost: string;
  dateFrom: Date;
  dateTo: Date;
  status: string[] | null;
  paymentType: string[] | null;
  page: number;
  campaignId: number;
  paypalTransactionId: string;
  dueDateFrom: Date;
  dueDateTo: Date;
  paymentCurrency: string;
  hubspotDealId: string
}

interface Props {
  allPaymentRequest: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onAccept: (value: any) => void;
  onReject: (value: any) => void;
  onReconcile: (value: any) => void;
  onUndo: (value: any) => void;
  onFilter: (key: string, value: any) => void;
  isShowFilter: boolean;
  showFilter: () => void;
  filters: FilterProps;
  currentUser: any;
  resetFilter: () => void;
  height?: number; // from withWindowResize HOC
  onDelete: (value: any) => void;
  filterDataSoruce: any;
}

const BasePaymentQueueList: React.FC<Props> = ({
  allPaymentRequest,
  loading,
  globalErrors,
  onAccept,
  onReject,
  onReconcile,
  onUndo,
  onFilter,
  isShowFilter,
  showFilter,
  filters,
  currentUser,
  resetFilter,
  height,
  onDelete,
  filterDataSoruce,
}) => {
  // const [expandedRowKeys, setExpandedRowKeys] = useState([] as any);
  const [showPaymentDetail, setShowPaymentDetail] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState(null as any);

  // const addedColumns = [
  //   {
  //     title: "",
  //     dataIndex: "",
  //     key: "x",
  //     width: 250,
  //     render: (text: any, row: any) => (
  //       <ActionColumn
  //         row={row}
  //         isSuperAdmin={currentUser?.super_admin}
  //         setExpandedRowKeys={value => setExpandedRowKeys(value as any)}
  //         expandedRowKeys={expandedRowKeys}
  //         onAccept={value => onAccept(value)}
  //         onReject={value => onReject(value)}
  //         onUndo={value => onUndo(value)}
  //       />
  //     ),
  //   },
  // ];

  return (
    <Box mt={10}>
      <GlobalErrors errors={globalErrors} />
      <Filters
        handleChange={(key, value) => onFilter(key, value)}
        isShowFilter={isShowFilter}
        showFilter={() => showFilter()}
        filters={filters}
        resetFilter={resetFilter}
        filterDataSoruce={filterDataSoruce}
      />
      <Loading spinning={loading}>
        <Typography size={12} color="black" position="absolute" mt={20}>
          {allPaymentRequest?.totalEntries} results
        </Typography>
        <PaymentTable
          columns={columns}
          // expandedRowKeys={expandedRowKeys}
          // expandedRowRender={(record: any) => {
          //   return (
          //     <ExpandedContent
          //       record={record}
          //       onAccept={() => {
          //         onAccept(record?.id);
          //         setExpandedRowKeys([]);
          //       }}
          //     />
          //   );
          // }}
          rowKey={(record: any) => record?.id}
          dataSource={allPaymentRequest?.paymentRequests}
          pagination={{
            position: "both",
            current: allPaymentRequest?.currentPage,
            pageSize: allPaymentRequest?.perPage,
            total: allPaymentRequest?.totalEntries,
            onChange: page => onFilter("page", page),
          }}
          stripe={true}
          data-cy="table-PaymentQueueList"
          scroll={{ y: height, x: "max-content" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                setShowPaymentDetail(true);
                setPaymentDetail(record);
              },
            };
          }}
          rowHover={true}
        />
      </Loading>

      <PaymentDetailModal
        closePaymentDetailModal={() => {
          setShowPaymentDetail(false);
          setPaymentDetail(null);
        }}
        showPaymentDetail={showPaymentDetail}
        paymentDetail={paymentDetail}
        onAccept={value => {
          setShowPaymentDetail(false);
          onAccept(value);
          setPaymentDetail(null);
        }}
        onReject={value => {
          setShowPaymentDetail(false);
          onReject(value);
          setPaymentDetail(null);
        }}
        onReconcile={value => {
          setShowPaymentDetail(false);
          onReconcile(value);
          setPaymentDetail(null);
        }}
        onUndo={value => {
          setShowPaymentDetail(false);
          onUndo(value);
          setPaymentDetail(null);
        }}
        onDelete={value => {
          setShowPaymentDetail(false);
          onDelete(value);
          setPaymentDetail(null);
        }}
        isSuperAdmin={currentUser?.super_admin}
        loading={loading}
      />
    </Box>
  );
};

const mapStateToProps = state => ({
  currentUser: Auth.selectUser(state),
});

const mapDispatchToProps = {};

const MainBasePaymentQueueList = connect(
  mapStateToProps,
  mapDispatchToProps
)(BasePaymentQueueList);

const PaymentQueueList = withWindowResize({ extraHeight: 260 })(
  MainBasePaymentQueueList
);

export { PaymentQueueList, PaymentQueueListContainer };
