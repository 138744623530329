// @ts-nocheck
import React from "react";
import { Tag } from "antd";

export function formattedStatus(status) {
  return status === "active" ? (
    <Tag color="green" key="status">
      Active
    </Tag>
  ) : status === "submitted" ? (
    <Tag color="purple" key="status">
      Under Review
    </Tag>
  ) : status === "complete" ? (
    <Tag color="magenta" key="status">
      Complete
    </Tag>
  ) : (
    <Tag color="red" key="status">
      Draft
    </Tag>
  );
}
