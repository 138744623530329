// @ts-nocheck
import React from "react"
import TrackingDetails from "."
import { gql } from "apollo-boost"
import { useGetTrackingInvitationByCashoutIdQuery } from "src/generated/ApolloComponents"
import { withRouter, RouteComponentProps } from "react-router-dom"
import formatCurrency from "src/utils/formatCurrency"

interface Props {}

gql`
  query GetTrackingInvitationByCashoutId($cashoutId: String!) {
    getInfluencerCashoutInvitation(claimCashoutId: $cashoutId) {
      id
      installs
      clicks
      campaign {
        id
        title
        cpiPayoutPennies
      }
      account {
        id
        username
      }
    }
  }
`

const TrackingDetailsContainer = ({ match }: Props & RouteComponentProps<{ id: string }>) => {
  const { data } = useGetTrackingInvitationByCashoutIdQuery({
    variables: { cashoutId: match.params.id }
  })

  const invite = data?.getInfluencerCashoutInvitation

  const amountEarned = formatCurrency(
    (invite?.installs || 0) * (invite?.campaign?.cpiPayoutPennies || 0)
  )

  return (
    <TrackingDetails
      influencerName={invite?.account.username}
      campaignTitle={invite?.campaign.title}
      installs={invite?.installs}
      clicks={invite?.clicks}
      amountEarned={amountEarned}
    />
  )
}

export default withRouter(TrackingDetailsContainer)
