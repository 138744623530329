// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Tag as AntdTag } from "antd";
import {
  space,
  SpaceProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
} from "styled-system";
import { FontSizes, ThemeColors } from "src/utils/theme";
interface Props {
  size?: FontSizes;
  tagColor?: ThemeColors;
  background?: ThemeColors;
  borderColor?: ThemeColors;
  marginBottom?: any;
  borderRadius?: any;
  style?: any;
}

const StyledTag = styled(
  ({ tagColor, borderColor, marginBottom, borderRadius, ...props }) => (
    <AntdTag {...props} />
  )
)`
  && {
    width:auto;
    height: auto !important;
    background: ${props =>
      props.background && props.theme.colors[props.background]} !important;
    border-color: ${props =>
      props.borderColor && props.theme.colors[props.borderColor]} !important;
    color: ${props =>
      props.tagColor && props.theme.colors[props.tagColor]} !important;
    font-size: ${props => props.size}px;
    border-radius:${props => (props.borderRadius ? props.borderRadius : "2px")};
    margin-bottom:${props => (props.marginBottom ? props.marginBottom : "0px")};
  }
  ${space}
  ${typography}
  ${layout}
  ${border}
`;

export type TagProps = SpaceProps & TypographyProps & LayoutProps & BorderProps;

export const Tag: React.FC<Props & TagProps> = props => (
  <StyledTag {...props} />
);

export default Tag;
