import React, { Component } from "react"
import styled from "styled-components"
import FormField from "../FormField"
import { Select } from "formik-antd"
import { integration_partners } from "src/constants/campaign"

const FixedWidthSelect = styled(Select)`
  width: 100%;
  max-width: 250px;
`

export default class IntegrationPartner extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "integration_partner"}
        label="App tracking partner"
        help="If you enter an app partner URL above and select a tracking partner we will track installs of your app."
      >
        <FixedWidthSelect size="large" placeholder="Select..." options={integration_partners}>
        </FixedWidthSelect>
      </FormField>
    )
  }
}
