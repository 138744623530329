// @ts-nocheck
import React from "react";
import { connect } from "react-redux";
import { show } from "redux-modal";
import numeral from "numeral";
import { CheckCircleFilled, PushpinOutlined, SafetyCertificateFilled, StarFilled } from '@ant-design/icons';
import { Card, Avatar, Row, Col, Popover, Badge, Popconfirm } from "antd";
import { Box } from "src/components/Core/Box";
import Button from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import { Icon } from '@ant-design/compatible'

const AccountBlock = styled(Card)`
  &&& {
    border-radius: 5px;
    border: none;
    margin-bottom: 10px !important;

    .ant-card-body {
      padding: 5px 10px 5px 10px !important;
    }
  }
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
  }
`;

const AccountOrder = styled(Badge)`
  &&& {
    .ant-badge-count {
      background: #1890ff;
      top: 49px;
      right: 10px;
      font-size: 10px;
      height: 15px;
      min-width: 15px;
      line-height: 16px;
      padding: 0 4px;
    }
  }
`;

const DeleteIcon = styled(Icon)`
  && {
    color: #f5222d;
    opacity: 0.5;
    cursor: pointer;
  }
  &:hover {
    opacity: 1;
  }
`;

const UndoRemoveButton = styled(Button)`
  && {
    height: 20px !important;
    padding: 4px !important;
    margin-top: 5px !important;
    font-size: 10px !important;
    line-height: 5px !important;
    margin-bottom: 7px;
  }
`;

interface Props {
  isSelected: boolean; // its means selected accounts
  show: any;
  account: any;
  index: number;
  removeAccount?: () => void;
  updateSuggestion?: (status: string) => void;
  isAdmin?: boolean; // its means its account which is directly invite by admin or suggestion which has no campaignInvitationId
  // So they can  not order and remove that accont from selected pool
  isAccepted?: boolean; // if invitation card accepted column
  moduleKey: string; // column type
}

const AccountCard: React.FC<Props> = ({
  isSelected,
  show,
  account,
  index,
  removeAccount,
  updateSuggestion,
  isAdmin,
  isAccepted,
  moduleKey,
}) => {
  return (
    <AccountBlock>
      <Row gutter={64}>
        <Col span={4}>
          <Box
            mt="10px"
            style={{
              opacity:
                moduleKey === "selection" && account?.status === "hidden"
                  ? 0.2
                  : 1,
            }}
          >
            {isSelected && !isAdmin && isAccepted ? (
              <AccountOrder count={index + 1}>
                <Avatar size={49} src={account?.account?.profilePicUrl} />
              </AccountOrder>
            ) : (
              <Avatar size={49} src={account?.account?.profilePicUrl} />
            )}
          </Box>
        </Col>
        <Col span={19} style={{ paddingLeft: "23px", paddingRight: "20px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={10}
            style={{
              opacity:
                moduleKey === "selection" && account?.status === "hidden"
                  ? 0.2
                  : 1,
            }}
          >
            <div>
              <Box display="flex">
                <Popover
                  placement="topRight"
                  content={account?.account?.username}
                >
                  <Typography
                    size={16}
                    weight="semi-bold"
                    color="black"
                    lineHeight="14px"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: !isAdmin ? "110px" : "150px",
                    }}
                  >
                    @{account?.account?.username}
                  </Typography>
                </Popover>

                {isSelected && (
                  <>
                    {!isAdmin || isAccepted ? (
                      <>
                        {account?.invitation?.status === "accepted" && (
                          <Popover
                            placement="topRight"
                            content="This influencer has been contacted and
                   is confirmed to be part of your campaign."
                          >
                            <CheckCircleFilled
                              style={{
                                color: "#73D13D",
                                marginLeft: "5px",
                              }} />
                          </Popover>
                        )}
                      </>
                    ) : (
                      <Popover
                        placement="topRight"
                        content="Your campaign manager selected this
                    influencer to be on your campaign"
                      >
                        <PushpinOutlined
                          style={{
                            color: "#1890ff",
                            marginLeft: "5px",
                          }} />
                      </Popover>
                    )}
                  </>
                )}
              </Box>

              <Typography color="grey7" size={12} lineHeight="5px" mt="-4px">
                {account?.account?.type},{" "}
                {numeral(account?.account?.views).format("0a")} Avg. views
              </Typography>
            </div>

            <Box mt="-5px">
              {!isAdmin && isAccepted && (
                <>
                  {!isSelected && account?.account?.status === "Verified" && (
                    <Popover
                      placement="topRight"
                      content="This influencer’s data has been verified by
                        our in-house team"
                    >
                      <SafetyCertificateFilled style={{ color: "#B7EB8F" }} />
                    </Popover>
                  )}

                  {account?.account?.influencer?.detail?.managed && (
                    <Popover
                      placement="topRight"
                      content="This influencer is managed by Fanbytes which
means we can guarantee a faster, more effecient
experience with them."
                    >
                      <StarFilled style={{ color: "#FFEC3D" }} />
                    </Popover>
                  )}
                </>
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mt="-8px">
            {moduleKey === "selection" && account?.status === "hidden" ? (
              <Popconfirm
                data-cy="status-container"
                title="Are you sure you want to add this influencer back to your campaign?"
                trigger="click"
                okText="Yes"
                cancelText="No"
                onConfirm={() =>
                  updateSuggestion && updateSuggestion("pending")
                }
                onCancel={(e: any) => e.preventDefault()}
              >
                <UndoRemoveButton type="action" buttonSize="small">
                  Undo remove
                </UndoRemoveButton>
              </Popconfirm>
            ) : (
              <LinkButton
                color="blue"
                fontSize={12}
                weight="semi-bold"
                style={{ padding: 0 }}
                onClick={e => {
                  e.stopPropagation();
                  show("InfluencerModal", {
                    influencerId: Number(account?.account?.influencerId),
                    rationale:
                      account?.rationale || account?.invitation?.rationale,
                    accountId: Number(account?.account?.id),
                  });
                }}
              >
                View details
              </LinkButton>
            )}

            {!isAdmin && (
              <>
                {isSelected ? (
                  <>
                    {(account?.invitation?.status === "approved" ||
                      account?.invitation?.status === "sent") && (
                      <Box
                        mt="5px"
                        pr="5px"
                        style={{
                          display:
                            moduleKey === "selection" &&
                            account?.status === "hidden"
                              ? "none"
                              : "block",
                        }}
                      >
                        <Popconfirm
                          data-cy="status-container"
                          title="Are you sure you want to remove this influencer from your campaign?"
                          trigger="click"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => removeAccount && removeAccount()}
                          onCancel={(e: any) => e.preventDefault()}
                        >
                          <DeleteIcon type="delete" />
                        </Popconfirm>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    mt="5px"
                    pr="5px"
                    style={{
                      display:
                        moduleKey === "selection" &&
                        account?.status === "hidden"
                          ? "none"
                          : "block",
                    }}
                  >
                    <Popconfirm
                      data-cy="status-container"
                      title="Are you sure you want to remove this influencer from your campaign?"
                      trigger="click"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        updateSuggestion && updateSuggestion("hidden")
                      }
                      onCancel={(e: any) => e.preventDefault()}
                    >
                      <DeleteIcon type="delete" />
                    </Popconfirm>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Col>
      </Row>
    </AccountBlock>
  );
};

export default connect(state => ({}), {
  show,
})(AccountCard);
