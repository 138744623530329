// @ts-nocheck
import React, { useState } from "react";
import Form from "src/components/Forms/Form";
import {Col, Row} from "antd";
import {FormikHelpers as FormikActions, FormikValues} from "formik";
import {Schema} from "yup";
import {PaymentDetailFormContainer} from "./container";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import {Box} from "src/components/Core/Box";
import {Typography} from "src/components/Core/Typography";
import {GlobalErrorProps, GlobalErrors,} from "src/components/Forms/ErrorMessage";
import {PaypalEmail} from "./FormFields";
import {Visible} from "react-grid-system";
import FormButtons from "../FormButtons";
import { InvoicesContainer } from "./Invoices";
import {
  InfluencerCashoutInvitationFragmentFragment,
} from "src/generated/ApolloComponents";
import {snakeCase} from "lodash";
import {Field} from "src/components/Forms/Field";
import {bankDetailFieldsByCurrency} from "./FormFields/BankDetailFieldsByCurrency";

interface PaymentDetailFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  paymentDetailSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  goBack: () => void;
  index: number;
  cashoutData: InfluencerCashoutInvitationFragmentFragment | null | undefined;
}

export const PaymentDetailFormWrapper = styled.div`
  width: 100%;
`;

const PaymentDetailForm: React.FC<PaymentDetailFormProps> = ({
  initialValues,
  isSubmitting,
  paymentDetailSchema,
  onSubmit,
  globalErrors,
  goBack,
  index,
  cashoutData
}) => {
  const [isUploading, setIsUploading] = useState(false);
  return (
    <PaymentDetailFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />
          <Box p={24}>
            <Visible xl lg>
              <Typography size={20} color="black6" lineHeight="20px">
                Payment details
              </Typography>
            </Visible>

            <Typography size={16} color="grey2">
              Please enter your preferred payment method
            </Typography>

            <Form
              initialValues={initialValues}
              schema={paymentDetailSchema}
              onSubmit={(values, actions) => onSubmit(values, actions)}
            >
              {(formProps: any) => {
                return (
                  <>
                    <Row gutter={12}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <PaypalEmail/>
                        <InvoicesContainer
                          name="invoiceScreenshots"
                          setIsUploading={loading => setIsUploading(loading)}
                          // setFieldValue={value =>
                          //   formProps?.setFieldValue("invoiceScreenshots", value)
                          // }
                          setFieldValue={formProps?.setFieldValue}
                          setFieldTouched={() =>
                            formProps?.setFieldTouched("invoiceScreenshots", true)
                          }
                          values={formProps?.values}
                          multiple={true}
                          disabled={false}
                          accountName={cashoutData?.account?.username || ""}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {initialValues.payoutCurrency &&
                          bankDetailFieldsByCurrency[initialValues.payoutCurrency].fields.map(item => {
                            const FieldComponent = item.component;
                            const label = item.props.label;
                            return (
                              <Box mb={9}>
                                <Field label={label} mb={20}>
                                  <FieldComponent
                                    name={`payout_details.bank_account.${snakeCase(item.name)}`}
                                    {...item.props}
                                  />
                                </Field>
                              </Box>
                            );
                          })
                        }
                      </Col>
                    </Row>

                    <FormButtons
                      index={index}
                      goBack={goBack}
                      onSubmit={formProps.handleSubmit}
                      isSubmitting={isSubmitting}
                      isDisabled={isSubmitting || isUploading}
                      id="hub-payment-detail"
                    />
                  </>
                );
              }}
            </Form>
        </Box>
      </Loading>
    </PaymentDetailFormWrapper>
  );
};

export {PaymentDetailForm, PaymentDetailFormContainer};
