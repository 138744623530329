// @ts-nocheck
import React from "react";
import { Radio, Row, Col } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";

interface Props {
  paymentType: string;
  title: string;
  subTitle: string;
  formType: string;
  setShowDetailForm: (value: boolean) => void;
  setPaymentType: (value: string) => void;
}

const DisplayDetails: React.FC<Props> = ({
  setShowDetailForm,
  paymentType,
  setPaymentType,
  title,
  subTitle,
  formType,
}) => {
  return (
    <Box p={13.5}  data-cy={`block-${formType.replace(" ", "-")}`}>
      <Row gutter={8}>
        <Col span={6}>
          <Box mt="60%" ml="10%">
            <Radio
              checked={paymentType === formType}
              onClick={() => setPaymentType(formType)}
              data-cy={`radio-${formType.replace(" ", "-")}`}
            />
          </Box>
        </Col>
        <Col span={18}>
          <Typography size={16} weight="semi-bold" color="black">
            {title}
          </Typography>
          <Typography size={12} color="grey9" lspace={0.725}>
            {subTitle}
          </Typography>
          <Typography
            size={10}
            color="blue"
            lspace={0.42}
            onClick={() => setShowDetailForm(true)}
            cursor="pointer"
          >
            Edit details
          </Typography>
        </Col>
      </Row>
    </Box>
  );
};

export default DisplayDetails;
