// @ts-nocheck
import React, { useState } from "react";
import { Card, Layout } from "antd";
import styled from "styled-components";
import { FormLayout } from "../../components";
import Container from "src/shared/Container";
import { ProfileStepContainer } from "./container";
import { Loading } from "src/components/Core/Loading";
import { GlobalErrorProps } from "src/components/Forms/ErrorMessage";
import SideMenu from "./SideMenu";
import ProfileInfoText from "./ProfileInfoText";
import { Visible } from "react-grid-system";
import { PersonalDetailFormContainer } from "./PersonalDetailForm";
import { ProfileDetailFormContainer } from "./ProfileDetailForm";
import { ProfileCompleteContainer } from "./ProfileComplete";
import AnalyticsScreenshotExampleModal from "../../components/AnalyticsScreenshotExampleModal";

const { Content, Sider } = Layout;

const FormContainer = styled(({ currentStep, ...props }) => (
  <Card {...props} />
))`
  && {
    width: 100%;
    margin: 0 auto !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 6px !important;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.21);

    .ant-card-body {
      padding: 0px;
    }
  }
`;

interface Props {
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  accountData: any;
  step: number;
  setStep: (value: any) => void;
  claimAccountId: any;
}

const ProfileStep: React.FC<Props> = ({
  loading,
  globalErrors,
  accountData,
  setStep,
  step,
  claimAccountId,
}) => {
  // const [showExample, setShowExample] = useState(false);
  const [showExample, setShowExample] = useState({});

  const [screenshotType, setScreenshotType] = useState("");
  return (
    <FormLayout
      title={"Influencer Welcome Profile"}
      styles={{ alignItems: "center", height: "auto" }}
    >
      <Loading spinning={loading}>
        <Container style={{ paddingBottom: 30 }}>
          <FormContainer currentStep={step}>
            <ProfileInfoText accountData={accountData} />

            <Layout
              style={{
                background: "#fff",
              }}
            >
              <Visible xl lg>
                <Sider
                  width={200}
                  style={{
                    background: "#fff",
                    overflow: "auto",
                    height: "auto",
                  }}
                >
                  <SideMenu currentStep={step} />
                </Sider>
              </Visible>

              <Content>
                {step === 1 && (
                  <PersonalDetailFormContainer
                    claimAccountId={claimAccountId}
                    goNext={() => {
                      setStep(2);
                    }}
                    accountData={accountData}
                  />
                )}

                {step === 2 && (
                  <ProfileDetailFormContainer
                    claimAccountId={claimAccountId}
                    goNext={() => {
                      setShowExample(false);
                      setStep(3);
                    }}
                    goBack={() => {
                      setStep(1);
                    }}
                    accountData={accountData}
                    setShowExample={value => setShowExample(value)}
                    setScreenshotType={value => setScreenshotType(value)}
                    showExample={showExample}
                  />
                )}

                {step === 3 && (
                  <ProfileCompleteContainer
                    claimAccountId={claimAccountId}
                    registeredForBeta={
                      accountData?.influencer?.registerForBeta as boolean
                    }
                  />
                )}
              </Content>
            </Layout>
          </FormContainer>
          {step === 2 && (
            <Visible xs sm md>
              <AnalyticsScreenshotExampleModal
                hideExample={() =>
                  setShowExample({
                    screenshot: false,
                  })
                }
                showExample={showExample}
                platformType={accountData?.type || ""}
                screenshotType={screenshotType}
              />
            </Visible>
          )}
        </Container>
      </Loading>
    </FormLayout>
  );
};

export { ProfileStep, ProfileStepContainer };
