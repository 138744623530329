// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { formats } from "src/constants/account";

interface Props {}

const Format: React.FC<Props> = () => {
  return (
    <Field label="Format:" mb={26}>
      <FormikSelect
        name="format"
        size="middle"
        placeholder="Select..."
        data={formats}
        local
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Format;
