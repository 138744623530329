import React, { Component } from "react";
import FormField from "../FormField";
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import CurrencyInput from "src/shared/CurrencyInput";

export default class AmountPaid extends Component {
  render() {
    return (
      <Row gutter={4} justify="start">
        <Col span={11}>
          <Box mt="-10px">
            <FormField
              name={`last_paid_amount.min`}
              style={{ marginBottom: "0px" }}
            >
              <CurrencyInput
                size="large"
                min={0}
                placeholder="£ Min"
                withoutPrefix
              />
            </FormField>
          </Box>
        </Col>
        <Box p="6px">-</Box>
        <Col span={11}>
          <Box mt="-10px">
            <FormField
              name={`last_paid_amount.max`}
              style={{ marginBottom: "0px" }}
            >
              <CurrencyInput
                size="large"
                min={0}
                placeholder="£ Max"
                withoutPrefix
              />
            </FormField>
          </Box>
        </Col>
      </Row>
    );
  }
}
