import React, { Component } from "react";
import FormField from "../FormField";
import LargeTextArea from "../LargeTextArea";

export default class Rationale extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "rationale"}
        label="Why this Influencer?"
        style={{ marginTop: "10px" }}
      >
        <LargeTextArea
          data-cy="influencer-note"
          rows={5}
          size="large"
          placeholder="Help the brand understand why this influencer will be a great fit for their campaign..."
        />
      </FormField>
    );
  }
}
