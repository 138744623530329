// @ts-nocheck
import React from "react";
import {
  ListItem,
  SerialNumber,
  FeedbackItemWrapper,
  DraftTime,
} from "./styles";
import { Empty } from "antd";
import { Box } from "src/components/Core/Box";

interface Props {
  feedbacks: Array<string>;
  draftType: string;
}

const FeedbackListItem: React.FC<Props> = ({ feedbacks, draftType }) => {
  return (
    <>
      {(feedbacks || [])?.length > 0 ? (
        feedbacks.map((feedback, index) => {
          let time = "";

          if (draftType === "youtube") {
            let startPos = feedback.lastIndexOf("[[[");
            let endPos = feedback.lastIndexOf("]]]");

            if (startPos > -1 && endPos > -1) {
              time = feedback.substring(startPos + 3, endPos);
              feedback = feedback.substring(0, startPos);
            }
          }

          return (
            <FeedbackItemWrapper key={index}>
              <Box display="flex">
                <SerialNumber>{index + 1}.</SerialNumber>
                <ListItem>{feedback} </ListItem>
              </Box>
              {draftType === "youtube" && <DraftTime>{time}</DraftTime>}
            </FeedbackItemWrapper>
          );
        })
      ) : (
        <Empty />
      )}
    </>
  );
};

export default FeedbackListItem;
