import React from "react";
import { fk, attr, many, Model } from "redux-orm";
import numeral from "numeral";
import moment from "moment";
import { Tag } from "antd";
import { objectives, integration_partners } from "src/constants/campaign";
import formatCountries from "src/utils/formatCountries";
import formatCurrency from "src/utils/formatCurrency";

class Campaign extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Company, Creative, Tag } = this.session;

    const parsedData = {
      ...data,
      company: Company.parse(data.company),
      tags: data.tags.map(tag => Tag.parse(tag)),
      creative: Creative.parse(data.creative)
    };

    return this.upsert(parsedData);
  }

  get ref() {
    const data = super.ref;

    return {
      ...data,
      tags: this.tags.toRefArray().map(x => x.id)
    };
  }

  formattedBudget() {
    return this.budget_pennies ? formatCurrency(this.budget_pennies) : "£--";
  }

  formattedStartDate() {
    return this.start_date ? moment(this.start_date).format("Do MMM YYYY") : "";
  }

  formattedEndDate() {
    return this.end_date ? moment(this.end_date).format("Do MMM YYYY") : "";
  }

  formattedAdFormat() {
    return this.ad_format;
  }

  formattedDistributionPlatforms() {
    return this.distribution_platforms.join(", ");
  }

  formattedViews(format = "0a") {
    return numeral(this.views).format(format);
  }

  formattedClicks(format = "0a") {
    return numeral(this.clicks).format(format);
  }

  formattedObjective() {
    return this.objective
      ? (objectives.find(x => x.value === this.objective) || {}).label
      : null;
  }

  formattedIntegrationPartner() {
    return this.integration_partner
      ? (
          integration_partners.find(
            x => x.value === this.integration_partner
          ) || {}
        ).label
      : null;
  }

  formattedLocations() {
    return formatCountries(this.locations);
  }

  formattedAges() {
    return this.ages ? this.ages.join(", ") : null;
  }

  formattedGenders() {
    return this.genders ? this.genders.join(", ") : null;
  }

  formattedTags() {
    return this.tags
      .toRefArray()
      .map(x => x.name)
      .join(", ");
  }

  formattedStatus() {
    return this.status === "active" ? (
      <Tag color="green" key="status">
        Active
      </Tag>
    ) : this.status === "submitted" ? (
      <Tag color="purple" key="status">
        Under Review
      </Tag>
    ) : this.status === "complete" ? (
      <Tag color="magenta" key="status">
        Complete
      </Tag>
    ) : (
      <Tag color="red" key="status">
        Draft
      </Tag>
    );
  }

  formattedInfluencerKinds() {

    const influencerKinds = [...(this.influencer_kinds || [])] ;

    if (influencerKinds.indexOf("Others") > -1) {

      const index = influencerKinds.indexOf("Others");

      influencerKinds.splice(index, 1);

      if(typeof this.influencer_kinds_other !== 'undefined' &&
        this.influencer_kinds_other !== '' &&
        this.influencer_kinds_other !== null)
      {
        influencerKinds.push(this.influencer_kinds_other);
      }
    }
    return influencerKinds.length > 0 ? influencerKinds.join(", ") : null;
  }

  formattedCostOfSales() {
    return this.cost_of_sales ? formatCurrency(this.cost_of_sales) : "£--";
  }

}

Campaign.modelName = "Campaign";

// Declare your related fields.
Campaign.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  title: attr(),
  group: fk("Group"),
  company: fk("Company"),
  creative: fk("Creative"),
  tags: many("Tag")
};

export default Campaign;
