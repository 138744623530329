// @ts-nocheck
import React from "react";
import { useRouteMatch } from "react-router";
import { AdminInviteAccountList } from ".";
import { useGetAllInvitationOfAdminByCampaignIdQuery } from "src/generated/ApolloComponents";

interface Props {}

export const AdminInviteAccountListContainer: React.FC<Props> = () => {
  const match: any = useRouteMatch();
  const params: any = match?.params;

  const campaignId: any = params["id"];

  const { data, loading } = useGetAllInvitationOfAdminByCampaignIdQuery({
    variables: { campaignId: Number(campaignId) },
  });

  const adminInviteAccounts = data?.getAllInvitationOfAdminByCampaignId || [];

  return (
    <AdminInviteAccountList
      loading={loading}
      adminInviteAccounts={adminInviteAccounts}
    />
  );
};
