// @ts-nocheck
import React from "react";
import { Row, Card, Statistic } from "antd";
import { Box } from "src/components/Core/Box";
import numeral from "numeral";
import styled from "styled-components";
import formatCurrency from "src/utils/formatCurrency";
import { usePaymentQueueDetails } from "../../PaymentQueueProvider";

const StatisticsCard = styled(Card)`
  &&& {
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 1.69591px;
    min-width: 120px;
    max-width: 120px;
    margin-left: 10px;
  }
`;

const PaymentStatistic = styled(Statistic)`
  &&& {
    .ant-statistic-title {
      color: #595959;
      font-size: 12px !important;
    }
    .ant-statistic-content {
      color: #595959;
      font-size: 12px !important;
    }
  }
`;

interface Props {}

const Statistics: React.FC<Props> = () => {
  const { queueStatistics } = usePaymentQueueDetails();
  return (
    <Box ml={120} mt={10}>
      <Row gutter={16}>
        <StatisticsCard size="small">
          <PaymentStatistic
            title="# of Payments"
            value={numeral(queueStatistics?.payments).format("0,0")}
          />
        </StatisticsCard>

        <StatisticsCard size="small">
          <PaymentStatistic
            title="Gross Paid"
            value={formatCurrency(queueStatistics?.totalAmount)}
          />
        </StatisticsCard>

        <StatisticsCard size="small">
          <PaymentStatistic
            title="Net Paid"
            value={formatCurrency(queueStatistics?.totalNetAmount)}
          />
        </StatisticsCard>

        <StatisticsCard size="small">
          <PaymentStatistic
            title="VAT Paid"
            value={formatCurrency(queueStatistics?.totalVatAmount)}
          />
        </StatisticsCard>
      </Row>
    </Box>
  );
};

export default Statistics;
