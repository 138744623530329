import { many, attr, Model } from "redux-orm";
import moment from "moment";

class Company extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Campaign } = this.session;

    const parsedData = {
      ...data,
      campaign: Campaign.parse(data.campaign)
    };

    return this.upsert(parsedData);
  }

  formattedCreatedAt() {
    return this.created_at ? moment(this.created_at).format("Do MMM YYYY") : "";
  }
}
Company.modelName = "Company";

// Declare your related fields.
Company.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  campaigns: many("Campaign")
};

export default Company;
