import { string, object } from "yup"

export default object().shape({
  default_link: string().nullable(),
  integration_partner: string()
    .default(null)
    .nullable()
    .notRequired(),
  ios_id: string().nullable(),
  android_id: string().nullable()
})
