// @ts-nocheck
import React from "react";
import { Spin } from "antd";

interface Props {
  spinning: boolean;
}

export const Loading: React.FC<Props> = props => {
  return <Spin {...props} />;
};
