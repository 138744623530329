// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikCheckbox } from "src/components/Forms/Checkbox";
import { account_types } from "src/constants/account";

interface Props {}

const Notifiables: React.FC<Props> = () => {
  return (
    <Field label="Who will be notified when this Milestone is reached?" mb={26} fontSize={14}>
      <FormikCheckbox name="notifiables" data={account_types} />
    </Field>
  );
};

export default Notifiables;
