import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class Title extends Component {
  render() {
    return (
      <FormField name={this.props.name || "default_link"} label="Influencer specific link">
        <Input data-cy="invitation-default-link" size="large" placeholder="https://google.com" />
      </FormField>
    )
  }
}
