// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";
import { Typography } from "src/components/Core/Typography";

interface Props {}

const YoutubeDraftUrl: React.FC<Props> = () => {
  return (
    <Field label="Youtube draft URL:" mb={26}>
      <FormikInput
        name="youtubeDraftUrl"
        size="middle"
        placeholder="e.g. youtube.com/vsu7dh"
        borderRadius={2}
        borderColor="grey5"
      />
      <Typography size={14} color="grey7">
        Please ensure the draft permissions allow anyone with the link to view
      </Typography>
    </Field>
  );
};

export default YoutubeDraftUrl;
