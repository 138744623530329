import React, { Component } from "react";
import { EyeOutlined, HeartOutlined, MessageOutlined } from '@ant-design/icons';
import { Card, Row, Col } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import styled from "styled-components";

const { Meta } = Card;

const EllipsisText = styled("span")`
  &&& {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BrandedPostCard = styled(Card)`
  &&& {
    .ant-card-body {
      padding: 14px !important;
    }
  }
`;

export default class BrandedPost extends Component {
  render() {
    const {
      content_type,
      content_url,
      company_name,
      invitation,
      likes,
      comments,
      views,
      posted_at,
    } = this.props;

    const videoStyle = {
      width: "100%",
      height: "380px",
      objectFit: "contain",
      backgroundColor: "#000",
    };

    return (
      <BrandedPostCard
        hoverable
        cover={
          content_type === "video" ? (
            <video style={videoStyle} src={content_url} controls />
          ) : content_type === "youtube" ? (
            <iframe
              title="branded-post"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              width="788.54"
              height="380"
              type="text/html"
              src={`https://www.youtube.com/embed/${content_url}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}
            />
          ) : (
            <img
              style={{ width: "100%" }}
              src={content_url}
              alt="branded-post-img"
            />
          )
        }
      >
        <Meta
          title={
            <Box>
              {(likes || comments) && (
                <Row

                  style={{ marginTop: 10, background: "#FFFFFF" }}
                  justify="space-between"
                >
                  {likes && (
                    <Col>
                      <Typography weight="semi-bold" size={12}>
                        <HeartOutlined style={{ marginRight: 5 }} />
                        {likes}
                      </Typography>
                    </Col>
                  )}

                  {views && (
                    <Col>
                      <Typography weight="semi-bold" size={12}>
                        <EyeOutlined style={{ marginRight: 5 }} />
                        {views}
                      </Typography>
                    </Col>
                  )}

                  {comments && (
                    <Col>
                      <Typography weight="semi-bold" size={12}>
                        <MessageOutlined style={{ marginRight: 5 }} />
                        {comments}
                      </Typography>
                    </Col>
                  )}
                </Row>
              )}

              {posted_at && (
                <Box>
                  <Typography weight="semi-bold" size={10}>
                    {posted_at}
                  </Typography>
                </Box>
              )}

              <EllipsisText>{company_name}</EllipsisText>
            </Box>
          }
          description={
            invitation.formattedCtr() && invitation.ctr > 1.5
              ? invitation.formattedCtr() + " CTR"
              : null
          }
        />
      </BrandedPostCard>
    );
  }
}
