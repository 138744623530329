// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { Descriptions, Card } from "antd";
import formatCurrency from "src/utils/formatCurrency";
import { Typography } from "src/components/Core/Typography";
import { Loading } from "src/components/Core/Loading";
import Container from "src/shared/Container";
import { CampaignsTableContainer } from "../CampaignsList/CampaignsTable";
import { useCampaignGroupDetails } from "../CampaignGroupProvider";
import { formattedStatus } from "src/components/Modules/Campaign/utils";

interface Props {}

const CampaignGroupDetails: React.FC<Props> = () => {
  const { campaignGroupDetails, isLoading } = useCampaignGroupDetails();

  return (
    <Loading spinning={isLoading}>
      <Container>
        <Card style={{ marginBottom: 24, marginTop: 24 }}>
          <Typography
            size={20}
            weight="semi-bold"
            color="black"
            lineHeight="22px"
          >
            Campaign group details
          </Typography>

          <Descriptions
            data-cy="campaign-group-company-table"
            column={1}
            bordered
          >
            <Descriptions.Item data-cy="campaign-group-company" label="Company">
              <Link to={`/companies/` + campaignGroupDetails?.company?.id}>
                {campaignGroupDetails?.company?.companyName}
              </Link>
            </Descriptions.Item>

            <Descriptions.Item
              data-cy="campaign-extra-brands"
              label="Who has access to this campaign?"
            >
              {(campaignGroupDetails?.extraBrands || [])
                .map(x => x.name)
                .join(", ")}
            </Descriptions.Item>
          </Descriptions>

          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <Descriptions
              data-cy="campaign-group-review-table"
              column={1}
              bordered
            >
              <Descriptions.Item
                data-cy="campaign-group-review-title"
                label="Title"
              >
                {campaignGroupDetails?.groupName}
              </Descriptions.Item>
              {campaignGroupDetails?.status && (
                <Descriptions.Item
                  data-cy="campaign-group-review-status"
                  label="Status"
                >
                  {formattedStatus(campaignGroupDetails?.status)}
                </Descriptions.Item>
              )}

              {campaignGroupDetails?.objective && (
                <Descriptions.Item
                  data-cy="campaign-group-review-goal"
                  label="Primary goal"
                >
                  {campaignGroupDetails?.objective}
                </Descriptions.Item>
              )}

              <Descriptions.Item
                data-cy="campaign-group-review-budget"
                label="Budget"
              >
                {campaignGroupDetails?.budgetPennies
                  ? formatCurrency(campaignGroupDetails?.budgetPennies)
                  : "£--"}
              </Descriptions.Item>
            </Descriptions>
          </div>

          <CampaignsTableContainer />
        </Card>
      </Container>
    </Loading>
  );
};

export default CampaignGroupDetails;
