import React, { Component } from "react";
import FormField from "../FormField";
import LargeTextArea from "../LargeTextArea";

export default class PerfectCreative extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "perfect_creative"}
        label="What would the perfect creative look like?"
        help="Please describe or supply links to content you’ve seen"
      >
        <LargeTextArea
          data-cy="perfect_creative"
          rows={5}
          size="large"
          placeholder="e.g. The perfect creative would include..."
        />
      </FormField>
    );
  }
}
