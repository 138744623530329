// @ts-nocheck
import React from "react"
import { Wrapper, Logo, Content } from "./Wrapper"
import DocumentTitle from "react-document-title"
import logo from "../../../assets/images/logo.png"
import { Box } from "src/components/Core/Box"
import InfluencerSignupFormContainer from "./Form/container"

interface Props {}

export const InfluencerSignup = (props: Props) => {
  return (
    <DocumentTitle title="Signup">
      <Wrapper>
        <Content>
          <Box display="flex" mt={20} mb={20} justifyContent="center">
            <Logo src={logo} />
          </Box>

          <InfluencerSignupFormContainer />
        </Content>
      </Wrapper>
    </DocumentTitle>
  )
}
