// @ts-nocheck
import { string, object, number, array } from "yup";
import isEmpty from "lodash/isEmpty";
import sum from "lodash/sum";
import values from "lodash/values";
import has from "lodash/has";
import sumBy from "lodash/sumBy";
import { genders } from "src/constants/account";
import { ages } from "src/constants/account";

export const ProfileDetailSchema = object().shape({
  platformType: string(),

  screenshot: object()
    .nullable()
    .shape({
      uploadUrl: string()
        .nullable()
        .required(),
      uploadType: string()
        .nullable()
        .required(),
    })
    .test("screenshot", "Please provide your demographic screenshot", function(
      value
    ) {
      const { screenshot } = this.parent;
      if (isEmpty(screenshot)) return false;
      return true;
    }),

  ageBreakdown: object()
    .nullable()
    .test(
      "ageBreakdown",
      "Please enter total of percentage upto 100%",
      function(value) {
        const { platformType } = this.parent;
        if (platformType !== "TikTok" && sum(values(value)) > 100) return false;
        return true;
      }
    )
    .test("ageBreakdown", "Please enter all values.", function(value) {
      const { ageBreakdown, platformType } = this.parent;

      let foundError = false;

      for (let i = 0; i < ages.length; i++) {
        if (
          !has(ageBreakdown, ages[i].value) ||
          (typeof ageBreakdown[ages[i].value] !== "undefined" &&
            (ageBreakdown[ages[i].value] === null ||
              ageBreakdown[ages[i].value] === ""))
        ) {
          foundError = true;
          break;
        }
      }

      if (platformType !== "TikTok" && foundError) return false;
      return true;
    }),

  genderBreakdown: object()
    .nullable()
    .test(
      "genderBreakdown",
      "Please enter total of percentage upto 100%",
      function(value) {
        if (sum(values(value)) > 100) return false;
        return true;
      }
    )
    .test("genderBreakdown", "Please enter all values.", function(value) {
      const { genderBreakdown } = this.parent;

      let foundError = false;

      for (let i = 0; i < genders.length; i++) {
        if (
          !has(genderBreakdown, genders[i].value) ||
          (typeof genderBreakdown[genders[i].value] !== "undefined" &&
            (genderBreakdown[genders[i].value] === null ||
              genderBreakdown[genders[i].value] === ""))
        ) {
          foundError = true;
          break;
        }
      }

      if (foundError) return false;
      return true;
    }),
  locationBreakdown: array()
    .of(
      object().shape({
        key: string()
          .nullable()
          .required("Please select breakdown option"),

        value: number()
          .nullable()
          .required("Please enter value"),
      })
    )
    .min(2, "You must add minimum 2 breakdown")
    .test(
      "locationBreakdown",
      "Please enter total of percentage upto 100%",
      function(value) {
        let totalPercentage = sumBy(value, function(location: any) {
          return location?.value;
        });

        if (totalPercentage > 100) return false;
        return true;
      }
    ),
});
