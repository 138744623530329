// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import AccountCard from "../AccountCard";
import { AdminInviteAccountListContainer } from "./AdminInviteAccountList";

interface Props {
  acceptedAccounts: Array<any>;
}

const ConfirmedAccounts: React.FC<Props> = ({ acceptedAccounts }) => {
  return (
    <Box overflowY="auto" height={400} overflowX="hidden">
      <AdminInviteAccountListContainer />
      {acceptedAccounts.map((account, index) => (
        <AccountCard
          isSelected={true}
          account={account}
          index={index}
          isAdmin={account?.campaignInvitationId !== null ? false : true}
          key={index}
          isAccepted={account?.campaignInvitationId !== null ? false : true}
          moduleKey="confirmed"
        />
      ))}
    </Box>
  );
};

export default ConfirmedAccounts;
