// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Modal, Card } from "antd";
import analytics from "src/assets/images/contract/analytics.png";
import { Typography } from "src/components/Core/Typography";

const ExampleModal = styled(Modal)`
  &&& {
    max-width: 242px !important;
    background-color: none !important;
    box-shadow: none !important;

    .ant-modal-content {
      border-radius: 6px !important;
      background: none !important;
      background-color: none !important;
      box-shadow: none !important;
    }

    .ant-modal-body {
      padding: 0px !important;
    }
    .ant-modal-close {
      display: none;
    }
  }
`;

const ScreenshotCard = styled(Card)`
  &&& {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

interface Props {
  showExample: boolean;
  hideExample: () => void;
}

const AnalyticsScreenshotExampleModal = ({
  hideExample,
  showExample,
}: Props) => {
  return (
    <ExampleModal
      width="100%"
      visible={showExample}
      onCancel={hideExample}
      footer={null}
    >
      <Typography size={16} color="white">
        Your screenshot should include your audience data
      </Typography>
      <ScreenshotCard
        hoverable
        cover={<img src={analytics} alt="analytics " />}
      ></ScreenshotCard>
      <Typography size={16} color="white">
        (click anywhere to dismiss)
      </Typography>
    </ExampleModal>
  );
};

export default AnalyticsScreenshotExampleModal;
