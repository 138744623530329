// @ts-nocheck
import React from 'react'
import { Radio } from 'antd'
import styled from 'styled-components'

const StyledRadio = styled(Radio)`
  background: #FFFFFF;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  padding: 20px !important;
  margin-bottom: 15px !important;
`

interface Props {
  setAccId: Function
  influencerAccounts: [any]
}

const Internal = ({setAccId, influencerAccounts}: Props) => {
  return(
    <Radio.Group
      onChange={e => {
        setAccId(e.target.value);
      }}
    >
      {influencerAccounts.map(acc =>
        <StyledRadio value={acc.id} key={acc.id}>
          @{acc.username}
        </StyledRadio>
      )}
    </Radio.Group>
  )
}

export default Internal
