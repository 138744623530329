// @ts-nocheck
import React, { useRef } from "react";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Link } from "react-router-dom";
import { Visible, useScreenClass } from "react-grid-system";
import logo from "src/assets/images/logo-white.png";
import Menu from "./Menu";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import FadeInSection from "../FadeInSection";

interface Props {
  scrollPercentage: number;
  activeSection: string;
  campaignData: any;
  loading: boolean;
}

const ScrollBar = styled.div<{
  scrollPercentage: number;
}>`
  && {
    background: #ff4d4f;
    height: 8px;
    width: ${props => props.scrollPercentage}% !important;
    transition: width 0.8s;
    transition-delay: 0.35s;
    moz-transition: width 0.8s;
  }
`;

const Logo = styled("img")`
  padding: 15px 30px;
  height: 70px;
`;

const Header: React.FC<Props> = ({
  scrollPercentage,
  activeSection,
  campaignData,
  loading,
}) => {
  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};

  let props = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  const screenClass = useScreenClass();

  return (
    <Box width="100%" position="fixed" zIndex={9999} ref={myRef as any}>
      <FadeInSection>
        <Box width="100%" height="8px" background="#0C3055">
          {/* <Box
            height="8px"
            background="#FF4D4F"
            width={`${scrollPercentage}%`}
            transition="width 0.8ms"
          ></Box> */}
          <ScrollBar scrollPercentage={scrollPercentage} />
        </Box>
        <Box
          background="#0C3055"
          display="inline-flex"
          justifyContent="space-between"
          width="100%"
          borderBottomLeftRadius="10px"
          borderBottomRightRadius="10px"
        >
          <Box
            display={
              screenClass === "sm" || screenClass === "xs"
                ? "block"
                : "inline-flex"
            }
            alignItems="center"
            textAlign={
              screenClass === "sm" || screenClass === "xs"
                ? "center"
                : "initial"
            }
            width={
              screenClass === "sm" || screenClass === "xs" ? "100%" : "auto"
            }
          >
            <VelocityComponent
              key="slideRight"
              animation={
                !loading && enterCount <= 1 && inViewport
                  ? "transition.slideRightIn"
                  : "transition.fadeIn"
              }
              delay={50}
              easing="easeInSine"
            >
              <Link to="/">
                <Logo src={logo} />
              </Link>
            </VelocityComponent>

            <VelocityComponent
              key="slideRight1"
              animation={
                !loading && enterCount <= 1 && inViewport
                  ? "transition.slideRightIn"
                  : "transition.fadeIn"
              }
              delay={70}
            >
              <Visible xl lg>
                <Box alignItems="center" display="flex">
                  <Typography size={16} color="white" marginBottom="-8px">
                    {campaignData?.companyName}
                  </Typography>
                </Box>
              </Visible>
            </VelocityComponent>
          </Box>
          <Visible md xl lg>
            <Menu
              activeSection={activeSection}
              loading={loading}
              campaignData={campaignData}
            />
          </Visible>
        </Box>
      </FadeInSection>
    </Box>
  );
};

export default Header;
