// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { DraftWrapper, DraftMedia, DraftYoutube } from "./components";
import DraftFeedbackForm from "./DraftFeedbackForm";

interface Props {
  draft: any;
  fetchInvitations: Function;
  isActiveDraft: boolean;
}

const DarftCollapseContent: React.FC<Props> = props => {
  const { draft, fetchInvitations, isActiveDraft } = props;

  return (
    <DraftWrapper>
      <Col span={24}>
        <Row gutter={24} justify="center" align="middle" style={{ margin: 0 }}>
          {draft?.draftType === "youtube" ? (
            <Col span={12} style={{ paddingLeft: 0 }}>
              <Box height="350px" p={15}>
                <DraftYoutube videoUrl={draft?.allMedia?.[0]} />
              </Box>
            </Col>
          ) : draft?.draftType === "media" ? (
            <>
              {draft?.description && (
                <>
                  <Typography
                    size={14}
                    weight="semi-bold"
                    color="black"
                    mb={10}
                    mt={10}
                    lineHeight={0}
                  >
                    Description of content:
                  </Typography>

                  <Typography size={12} color="black">
                    {draft?.description}
                  </Typography>
                </>
              )}

              <Col span={7}>
                <DraftMedia allMedia={draft?.previewAllMedia} />
              </Col>
              <Col span={5}>
                <Typography
                  size={14}
                  weight="semi-bold"
                  color="black"
                  mb={20}
                  mt={10}
                >
                  Caption:
                </Typography>
                {draft?.caption && (
                  <Typography size={12} color="black">
                    {draft?.caption}
                  </Typography>
                )}
              </Col>
            </>
          ) : (
            <Col span={12}>
              <Typography size={14} color="black2" p={20}>
                {draft?.description}
              </Typography>
            </Col>
          )}

          <Col span={12} style={{ paddingLeft: 0 }}>
            <DraftFeedbackForm
              draft={draft}
              fetchInvitations={fetchInvitations}
              isActiveDraft={isActiveDraft}
            />
          </Col>
        </Row>
      </Col>
    </DraftWrapper>
  );
};

export default DarftCollapseContent;
