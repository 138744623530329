// @ts-nocheck
import React from "react";
import { AccountTagsContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { Field } from "src/components/Forms/Field";
import { FormikCheckbox } from "src/components/Forms/Checkbox";
import { TextInput } from "src/components/Forms/TextInput";

const CheckboxStyle = {
  color: "#595959",
  fontSize: "14px",
  marginLeft: "0px",
  width: "98px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: 300,
};

type AccountTagsProps = {
  allTags: any;
  onCreate: () => void;
  error: string;
  setError: Function;
  tagName: string;
  setTagName: Function;
};

const AccountTags: React.FC<AccountTagsProps> = ({
  allTags,
  onCreate,
  error,
  setError,
  tagName,
  setTagName,
}) => {
  let tagData = [] as any;

  tagData =
    (allTags || []).map(item => {
      let container = {} as any;
      container.value = item?.id;
      container.label =
        item.name === "Business & Finance" ? "Bus. & Fin." : item.name;
      return container;
    }) || [];

  return (
    <Box>
      <Field
        label="Please tag what kind of influencer this account is:"
        mb={20}
        color="black"
      >
        <FormikCheckbox name="tags" data={tagData} style={CheckboxStyle} tooltip />
      </Field>

      <Box>
        <TextInput
          size="middle"
          borderRadius={2}
          borderColor="grey5"
          onChange={e => {
            if (
              !allTags.find(
                item =>
                  (item?.name || "").toLowerCase() ===
                  e.target.value.toLowerCase()
              )
            ) {
              setError("");
            } else {
              setError("Please enter unique tagname");
            }
            setTagName(e.target.value);
          }}
          value={tagName}
          onKeyPress={e => {
            if (e.which === 13) {
              e.preventDefault();
              onCreate();
            }
          }}
          placeholder="Add a new tag"
        />

        <Typography color="grey7" mt={10}>
          Avoid adding duplicate and similar tags
        </Typography>

        {error && (
          <Typography size={14} color="red" mt="5px">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export { AccountTags, AccountTagsContainer };
