// @ts-nocheck
import React from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Radio, Col, Row } from "antd";
import { AmountRadio } from "../../Wrapper";
import { Box } from "src/components/Core/Box";
import { genders } from "src/constants/account";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { FormikSelect } from "src/components/Forms/Select";
import ClearButton from "./ClearButton";

interface Props {
  formProps: FormikProps<any>;
  collapsed: any;
  clearFilterKey: (key: string) => void;
}

const GenderAudience: React.FC<Props> = ({
  formProps,
  collapsed,
  clearFilterKey,
}) => {
  const fieldValue = formProps?.values;

  let isVisible = false;
  let backgroundColor = "#FFFFFF";

  if (fieldValue?.genderAudience?.gender !== "") {
    isVisible = true;
    backgroundColor = "#FAFAFA";
  }

  return (
    <Box background={backgroundColor} padding="5px">
      <Row gutter={8}>
        <Field
          label="Gender:"
          mb={20}
          extra={
            <ClearButton
              isVisible={isVisible}
              buttonClick={() => {
                formProps?.setFieldValue("genderAudience.gender", "");
                formProps?.setFieldValue("genderAudience.count", 0);
                formProps?.setFieldValue(
                  "genderAudience.genderIsgreaterThan",
                  true
                );
                clearFilterKey("audienceGender");
              }}
            />
          }
        >
          <Col span={collapsed ? 12 : 24}>
            <Box mb={collapsed ? 0 : 10}>
              <FormikSelect
                name="genderAudience.gender"
                data={genders}
                local
                borderRadius={3}
                borderColor="grey5"
                size="middle"
              />
            </Box>
          </Col>

          <Col span={collapsed ? 12 : 24}>
            <AmountRadio>
              <Radio.Button
                value={false}
                onChange={value => {
                  if (
                    fieldValue?.genderAudience?.count ||
                    fieldValue?.genderAudience?.count === 0
                  ) {
                    formProps?.setFieldValue(
                      "genderAudience.genderIsgreaterThan",
                      false
                    );
                  }
                }}
                checked={
                  fieldValue?.genderAudience?.genderIsgreaterThan === false
                }
              >
                <LeftOutlined />
              </Radio.Button>
              <Radio.Button
                value={true}
                onChange={value => {
                  if (
                    fieldValue?.genderAudience?.count ||
                    fieldValue?.genderAudience?.count === 0
                  ) {
                    formProps?.setFieldValue(
                      "genderAudience.genderIsgreaterThan",
                      true
                    );
                  }
                }}
                checked={
                  fieldValue?.genderAudience?.genderIsgreaterThan === true
                }
              >
                <RightOutlined />
              </Radio.Button>
            </AmountRadio>

            <FormikInputNumber
              parser={value => {
                let parsed = value.replace(/\[0-9]/g, "");
                if (parsed) {
                  return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                } else {
                  return null;
                }
              }}
              name="genderAudience.count"
              borderRadius={3}
              borderColor="grey5"
              size="small"
              value={fieldValue?.genderAudience?.count}
              style={{ marginLeft: 5, width: `${collapsed ? 72 : 105}px` }}
            />
          </Col>
        </Field>
      </Row>
    </Box>
  );
};

export default GenderAudience;
