// @ts-nocheck
import React, { useState } from "react";
import { connect } from "react-redux";
import { show, hide } from "redux-modal";
import { Box } from "src/components/Core/Box";
import HeroActions from "../../CampaignsList/HeroActions";
import { CampaignsTableContainer } from "../../CampaignsList/CampaignsTable";
import { CampaignsListProvider } from "../../CampaignsList/CampaignsListProvider";
import NewCampaignGroupModal from "../../CampaignsList/NewCampaignGroupModal";
import NewCampaignpModalWrapper from "../../CampaignsList/NewCampaignModalWrapper";
import CampaignFilterModal from "../../CampaignsList/CampaignFilterModal";
import FilterIcon from "../../CampaignsList/FilterIcon";

interface Props {
  showModal: Function; // from connect HOC
  hideModal: Function;
}

const CampaignsTable: React.FC<Props> = ({ showModal, hideModal }) => {
  const [showNewCampaignGroupForm, setShowNewCampaignGroupForm] = useState(
    false
  );

  const [showFilterCampaignForm, setShowFilterCampaignForm] = useState(false);

  return (
    <Box mt={24}>
      <CampaignsListProvider>
        <HeroActions
          title="Campaigns"
          openCampaignGroupModal={() => setShowNewCampaignGroupForm(true)}
          openCampaignModal={() => showModal("CreateCampaignModal")}
        />

        {!showFilterCampaignForm && (
          <FilterIcon
            showCampaignFilterForm={() => setShowFilterCampaignForm(true)}
          />
        )}
        <CampaignsTableContainer filterBackground="white" />

        <NewCampaignGroupModal
          closeCampaignGroupModal={() => setShowNewCampaignGroupForm(false)}
          showNewCampaignGroupForm={showNewCampaignGroupForm}
        />

        <NewCampaignpModalWrapper
          closeCampaignModal={() => hideModal("CreateCampaignModal")}
        />

        <CampaignFilterModal
          closeCampaignFilterModal={() => setShowFilterCampaignForm(false)}
          showFilterCampaignForm={showFilterCampaignForm}
        />
      </CampaignsListProvider>
    </Box>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  showModal: show,
  hideModal: hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsTable);
