import { string, object, array, number } from "yup";

export default object().shape({
  about_brand: string()
    .nullable()
    .required("Please tell us about your brand"),

  hashtags: array().nullable(),

  hygiene_factor: number().required("Please select a Hygiene Factor"),

  brief: string()
    .nullable()
    .required("Please enter a brief"),

  influencer_kinds: array().required("Please select a Influencer Kinds"),

  influencer_kinds_other: string()
    .nullable()
    .test("influencer_kinds_other", "Please enter other value", function(value) {
      const { influencer_kinds } = this.parent;
      if ((influencer_kinds || []).indexOf("Others") > -1) return value != null;
      return true;
    }),

});
