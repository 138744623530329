// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  getBrands: Function;
}

const Brands: React.FC<Props> = ({ getBrands }) => {
  return (
    <Field label="Who has access to this campaign?" mb={26}>
      <FormikSelect
        name="extraBrandIds"
        size="middle"
        placeholder="Select..."
        loadData={async value => {
          let barnd = await getBrands({
            brandName: value,
          });
          if ((barnd?.data?.getAllBrands?.brands || []).length > 0) {
            return barnd?.data?.getAllBrands?.brands;
          } else {
            return [];
          }
        }}
        optionGenerator={value => ({
          value: value.id,
          label: value.name,
        })}
        borderRadius={2}
        borderColor="grey5"
        mode="multiple"
      />
    </Field>
  );
};

export default Brands;
