// @ts-nocheck
import React from "react";
import { Popconfirm } from "antd";
import { Typography } from "src/components/Core/Typography";

interface Props {
  text?: any;
  title?: string;
  trigger?: "contextMenu" | "hover" | "focus" | "click";
  onClick?: Function;
  onConfirm?: any;
}

const ConfirmDelete: React.FC<Props> = props => {
  return (
    <Popconfirm
      data-cy="status-container"
      title={props?.title}
      trigger={props?.trigger ? props?.trigger : "hover"}
      okText="Yes"
      cancelText="No"
      onConfirm={(e: any) => e.preventDefault()}
      onClick={e => e.preventDefault()}
      onCancel={(e: any) => e.preventDefault()}
      {...props}
    >
      <Typography size={12} weight="semi-bold" color="red6" cursor="pointer">
        {props?.text}
      </Typography>
    </Popconfirm>
  );
};

export default ConfirmDelete;
