// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { NewNoteFormContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { FormikInput } from "src/components/Forms/TextInput";
import { FormikTextArea } from "src/components/Forms/TextArea";
import { Field } from "src/components/Forms/Field";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";

interface NewNoteFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  NewNoteSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  cancelClick: () => void;
}

const NewNoteForm: React.FC<NewNoteFormProps> = ({
  initialValues,
  isSubmitting,
  NewNoteSchema,
  onSubmit,
  globalErrors,
  cancelClick,
}) => {
  return (
    <Box width="100%" p={15}>
      <Loading spinning={isSubmitting}>
        <Box mb={40}>
          <Typography size={24} weight="semi-bold" color="black">
            New Note
          </Typography>
        </Box>

        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={NewNoteSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                <Field label="Title:" mb={26}>
                  <FormikInput
                    name="title"
                    size="middle"
                    placeholder="Title of note"
                    borderRadius={2}
                    borderColor="grey5"
                  />
                </Field>

                <Field label="Body:" mb={26}>
                  <FormikTextArea
                    size="middle"
                    name="body"
                    row={5}
                    placeholder="Enter your note"
                    borderRadius={2}
                    borderColor="grey5"
                  />
                </Field>

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={100}
                >
                  <LinkButton onClick={() => cancelClick()} mr={20}>
                    Cancel
                  </LinkButton>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    buttonSize="middle"
                    data-cy="submit-button"
                  >
                    Save
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </Box>
  );
};

export { NewNoteForm, NewNoteFormContainer };
