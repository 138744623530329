// @ts-nocheck
import React, { Component } from "react";
import Container from "src/shared/Container";
import { default as SharedInfluencerProfile } from "src/shared/InfluencerProfile";

export default class InfluencerProfile extends Component {
  render() {
    return (
      <Container style={{ marginTop: 24 }}>
        <SharedInfluencerProfile />
      </Container>
    );
  }
}
