// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import Button from "src/components/Core/Button";
import { GiftingCampaignFragmentFragment } from "src/generated/ApolloComponents";
import { GiftingWizardWrapper } from "../../GiftingWizardWrapper";
import { GiftingCampaignBrief } from "../../GiftingCampaignBrief";

interface Props {
  campaign?: GiftingCampaignFragmentFragment;
  onSubmit: Function;
  loading: boolean;
  setStep: (number: number) => void;
}

export const Review = ({ setStep, campaign, onSubmit }: Props) => {
  return (
    <GiftingWizardWrapper campaign={campaign} setStep={setStep} step="review">
      <Box width="100%">
        <Button block mb={15} onClick={() => onSubmit()} type="confirm">
          Approve and submit opportunity
        </Button>
        <GiftingCampaignBrief campaign={campaign} />
      </Box>
    </GiftingWizardWrapper>
  );
};
