import React, { useEffect } from "react";
import { connect } from "react-redux";
import CampaignManage from "src/ducks/CampaignManage";
import { withFormik } from "formik";
import { Form } from "formik-antd";
import Validate from "./Validate";
import { message } from "antd";
import {
  Title,
  Company,
  Goal,
  Budget,
  StartDate,
  AdFormat,
  DistributionPlatforms,
  Age,
  Gender,
  Locations,
  TrackingLink,
  IntegrationPartner,
  IosId,
  AndroidId,
  Admin,
  SubmitButton,
  InviteLimit,
  ViewTarget,
  InviteLimitSwitch,
  Executive,
  CPIPayoutPennies,
  Brand,
  LinkTitle,
  CampaignAccess,
  BriefDocument,
  Tags,
  NeedLink,
  HubspotId,
  AdditionalTerms,
  Public,
  PublicDeadline,
} from "src/shared/CampaignFields";
import idx from "idx";
import styled from "styled-components";
import {
  useGetHubSpotDealValidationByIdQuery
} from "src/generated/ApolloComponents";

const InviteLimitBlock = styled("div")`
   {
    position: relative;
  }
`;

const InviteLimitSwitchBlock = styled("div")`
   {
    position: absolute;
    right: 0;
  }
`;

export const CampaignForm = (props) => {
  const { values, isSubmitting } = props;
  const {data, loading, error, refetch} = useGetHubSpotDealValidationByIdQuery({
    variables: {dealId: values?.hubspot_deal_id}
  });
  const warning = document.getElementsByClassName('ant-alert-warning')[0]
  if(warning)
    warning.scrollIntoView({behavior: 'smooth', block: 'start'})

  useEffect(() => {
    if(values?.hubspot_deal_id)
      refetch({dealId: values?.hubspot_deal_id})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[values?.hubspot_deal_id])

  return (
    <Form data-cy="campaign-update-form">
      <Title />
      <Company />
      <CampaignAccess />
      <Brand companyId={values.company} />

      <InviteLimitBlock>
        <InviteLimitSwitchBlock>
          <InviteLimitSwitch />
        </InviteLimitSwitchBlock>
        {values.invite_limit_type ? <InviteLimit /> : <ViewTarget />}
      </InviteLimitBlock>
      <Admin />
      <Executive />
      <Goal />
      <Budget />
      <CPIPayoutPennies />
      <StartDate />
      <AdFormat />
      <DistributionPlatforms />
      <Age />
      <Gender />
      <Locations />
      <Tags />
      <NeedLink />
      <TrackingLink values={values} />
      <LinkTitle />
      <IntegrationPartner />
      <IosId values={values} />
      <AndroidId values={values} />
      <BriefDocument />
      <HubspotId
        dealId={values?.hubspot_deal_id}
        error={error}
        isValid={data?.getHubSpotDealValidationById?.isDealValid}
        loading={loading}
      />
      <AdditionalTerms />
      <Public />
      {values?.is_public &&
        <PublicDeadline />
      }
      <SubmitButton
        text="Update"
        isSubmitting={isSubmitting}
        disabled={!data?.getHubSpotDealValidationById?.isDealValid}
      />
    </Form>
  );
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {
  updateCampaign: CampaignManage.campaign.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,
    enableReinitialize: true,

    mapPropsToValues: ({ campaign }) => {
      let invite_limit_type = true;

      let link_tracked = false;
      if (idx(campaign, x => x.ref.influencer_invite_limit)) {
        invite_limit_type = true;
      } else if (idx(campaign, x => x.ref.influencer_spend_pennies)) {
        invite_limit_type = false;
      }

      if (idx(campaign, x => x.ref.default_link)) {
        link_tracked = true;
      }

      return campaign
        ? {
            ...campaign.ref,
            invite_limit_type,
            link_tracked,
          }
        : Validate.cast();
    },

    validationSchema: () => Validate,

    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { updateCampaign, campaign } = props;

      let data;

      const { invite_limit_type, link_tracked, ...rest } = values;

      if (values.invite_limit_type) {
        rest.influencer_spend_pennies = null;
      } else {
        rest.influencer_invite_limit = null;
      }

      if (!values.link_tracked) {
        rest.default_link = null;
      }
      data = rest;

      updateCampaign(campaign.id, data)
        .then(res => {
          setSubmitting(false);
        })
        .catch(err => {
          message.error("Failed to update campaign");
          setSubmitting(false);

          if (err.response.data) setErrors(err.response.data.meta.errors);
        });
    },

    displayName: "CampaignForm",
  })(CampaignForm)
);
