import axiosInstance from "../axiosInstance";
const CREATE_INVITATION = `/invitations`;
const GET_INVITATIONS = `/invitations`;
const getInvitation = id => `/invitations/${id}`;
const updateInvitation = id => `/invitations/${id}`;
const deleteInvitation = id => `/invitations/${id}`;

export const create = data => axiosInstance.post(CREATE_INVITATION, data);

export const index = data => axiosInstance.get(GET_INVITATIONS, data);

export const get = (id, data) => axiosInstance.get(getInvitation(id), data);

export const destroy = id => axiosInstance.delete(deleteInvitation(id));

export const update = (id, data) =>
  axiosInstance.patch(updateInvitation(id), data);
