import React, { Component, Fragment } from "react"
import { withFormik } from "formik"
import { ReloadOutlined } from '@ant-design/icons';
import { Button, message, Row } from "antd";
import { Form } from "formik-antd"
import { TikTokUrl, Likes, Comments } from "src/shared/InvitationFields"
import { object } from "yup"
import FormError from "src/shared/FormField/FormError"
import LineChartComp from './LineChart/index'
import { Typography } from "src/components/Core/Typography";
import moment from "moment"
import styled from "styled-components"

const StyledRow = styled(Row)`
  margin: 20px 0px;
`

const Validate = object().shape({})

export class AnalyticsForm extends Component {
  render() {
    const { isSubmitting, status, values: { metrics }, refreshData, loading } = this.props
    const formatStr = "DD-MM-YYYY HH:MM:SS"
    return (
      <Form>
        {status &&
          Object.values(status.meta.errors).map((error, i) => (
            <FormError key={i} style={{ margin: "0 0 10px 0" }} message={error} />
          ))}
        <StyledRow justify="end">
          <Button type="primary" ghost onClick={refreshData} disabled={loading}>
            <ReloadOutlined />
            Refresh data
          </Button>
        </StyledRow>
        {metrics && metrics.length ?
          <Fragment>
            <LineChartComp data={[...metrics]} loading={loading} />
            <Typography size={14} color="black2" mt={20} mb={20}>
              Last fetched: {metrics[0] ? moment(metrics[0].record_at).format('DD-MM-YYYY HH:mm:ss') : formatStr}
            </Typography>
          </Fragment>
          :
          null
        }
          <TikTokUrl />
        <Likes />
        <Comments />
        <Button
          loading={isSubmitting}
          disabled={isSubmitting}
          htmlType="submit"
          type="primary"
          size="large"
        >
          Update Analytics
        </Button>
      </Form>
    );
  }
}

export default withFormik({
  validateOnBlur: false,
  enableReinitialize: true,

  mapPropsToValues: ({ invitation }) => (invitation ? invitation.ref : Validate.cast()),
  validationSchema: () => Validate,

  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus }) => {
    const { updateInvitation, invitation, onUpdate } = props

    updateInvitation(invitation.id, values)
      .then(() => {
        setSubmitting(false)
        message.success("Invitation updated!")
        onUpdate && onUpdate(values)
      })
      .catch((err) => {
        setSubmitting(false)
        setStatus(err.response.data)
      })
  }
})(AnalyticsForm)
