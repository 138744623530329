import React, { Component } from "react";
import { connect } from "react-redux";
import { show } from "redux-modal";
import { Row, Button, Alert, Typography } from "antd";
import ApproveModal from "./ApproveModal";

export class ApproveAlert extends Component {
  render() {
    const { show } = this.props;
    return (
      <>
        <Alert
          message={
            <Row justify="space-between" align="middle">
              <Typography>
                Please check all the details for this campaign and ensure they
                are complete.
              </Typography>
              <Button
                type="success"
                data-cy="campaign-approve-button"
                onClick={() => show("ApproveCampaignModal")}
              >
                Approve
              </Button>
            </Row>
          }
          type="info"
          showIcon={false}
          banner
        />
        <ApproveModal />
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  show
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveAlert);
