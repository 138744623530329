// @ts-nocheck
import React from "react";
import { Provider } from "react-redux";
import { ScreenClassProvider } from "react-grid-system";
import store from "./store/fanbytesStore";
import Routes from "./scenes/Routes";

import { ConnectedRouter } from "connected-react-router";
import { history } from "./store/fanbytesStore";
import "react-dates/initialize";
import { StripeProvider } from "react-stripe-elements";

import "ant-design-pro/dist/ant-design-pro.css";
import "./assets/styles/App.less";
import "./assets/styles/App.css";

import client from "./graphql";
import { ApolloProvider } from "@apollo/react-hooks";
import Theme from "./utils/theme";
import analytics from "react-segment";
import { AppProvider } from "./AppProvider";
require('velocity-animate');
require('velocity-animate/velocity.ui');


let stripe_key: string = "";

if (process.env.NODE_ENV === "production") {
  stripe_key = "pk_live_GFQHM9DdmXB1GQM28SXfOgFL";
} else {
  stripe_key = "pk_test_xbk1wHvini7nCFw9QGJOja2y";
}

if (process.env.NODE_ENV === "production") {
  analytics.default.load("L8xQXSR7sOGF12KBb8o6mL1uPEHOTfrS");
} else {
  analytics.default.load("MfxdIQpjD5poBLQ1ILGy17IoY77GBlxT");
}

if ((window as any).Cypress) {
  (window as any).store = store;
}

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.

const FanbytesApp = props => (
  <Theme>
    <AppProvider>
      <ScreenClassProvider>
        <Provider store={store as any}>
          <ApolloProvider client={client}>
            <StripeProvider apiKey={stripe_key}>
              <ConnectedRouter history={history}>
                <Routes />
              </ConnectedRouter>
            </StripeProvider>
          </ApolloProvider>
        </Provider>
      </ScreenClassProvider>
    </AppProvider>
  </Theme>
);

export default FanbytesApp;
