// @ts-nocheck
import React from "react";
import Button from "src/components/Core/Button";
import { PageHeader } from "antd";
import { connect } from "react-redux";
import { show } from "redux-modal";

interface Props {
  title: string;
  openCampaignGroupModal: () => void;
  openCampaignModal: () => void;
  show: Function;
}

const HeroActions: React.FC<Props> = ({
  title,
  openCampaignGroupModal,
  openCampaignModal,
  show,
}) => {
  return (
    <PageHeader
      title={title}
      subTitle={``}
      extra={[
        <Button
          buttonSize="small"
          key="columns"
          onClick={() => show("ColumnsModal")}
          style={{ boxShadow: "none" }}
        >
          Manage Columns
        </Button>,
        <Button
          type="action"
          buttonSize="small"
          key="create-campaign"
          data-cy="create-hero-button"
          onClick={openCampaignModal}
          style={{ boxShadow: "none" }}
        >
          Create new Campaign
        </Button>,
        <Button
          type="action"
          buttonSize="small"
          key="create-group"
          data-cy="create-hero-button"
          onClick={openCampaignGroupModal}
          style={{ boxShadow: "none" }}
        >
          Create campaign group
        </Button>,
      ]}
    />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  show,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroActions);
