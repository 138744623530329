import axiosInstance from "../axiosInstance";
// Endpoint
const CREATE_COMPANY = `/companies`;
const GET_COMPANIES = `/companies`;
const getCompany = id => `/companies/${id}`;
const updateCompany = id => `/companies/${id}`;
const deleteCompany = id => `'/companies/${id}`;

export const create = data => axiosInstance.post(CREATE_COMPANY, data);

export const index = data => axiosInstance.get(GET_COMPANIES, data);

export const get = (id, data) => axiosInstance.get(getCompany(id), data);

export const update = (id, data) =>
  axiosInstance.patch(updateCompany(id), data);

export const destroy = id => axiosInstance.delete(deleteCompany(id));
