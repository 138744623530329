// @ts-nocheck
import React from 'react'
import { Typography } from "src/components/Core/Typography";

interface Props {
  username: string
}

export const Username = ({username}: Props) => (
  <Typography color="grey7">
    {username}
  </Typography>
)
