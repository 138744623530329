import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class PhoneNumber extends Component {
  render() {
    return (
      <FormField name={this.props.name || "phone_number"} label="Phone Number">
        <Input data-cy="influencer-phone-number" size="large" placeholder="e.g. +44 7902 353 294" />
      </FormField>
    )
  }
}
