import { string, object } from "yup";

export default object().shape({
  name: string()
    .nullable()
    .required("Please enter a name"),
  email: string()
    .nullable()
    .required("Please enter an email"),
  phone_number: string().nullable(),
  detail: object().shape({
    address: string().nullable(),
    vat_number: string().nullable(),
  }),
});
