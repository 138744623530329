// @ts-nocheck
import React from 'react'
import { Login } from '.'
import Auth from 'src/ducks/Auth'
import { connect } from 'react-redux'
import {
  withRouter,
  useHistory,
  useLocation
} from 'react-router-dom'
 import qs from 'qs'

const LoginContainerComp = ({submitting, failed, login, user}) => {
  const location = useLocation()
  const history = useHistory()
  const search = location.search
  const params = qs.parse(search, { ignoreQueryPrefix: true })
  const initialValues = {}
  Object.keys(params).map(key => initialValues[key] = params[key])

  const handleSubmit = (values: any, setSubmitting: Function) => {
    login(values)
    .then(res => {
      if(res?.value?.data?.type === "Influencer") {
        if(params.redirect) {
          history.push(`/${params.redirect}`)
        } else {
          history.push(`/?${qs.stringify(params)}`)
        }
      }
      setSubmitting(false);
    })
    .catch(() => setSubmitting(false));
  }
  return(
    <Login handleSubmit={handleSubmit} initialValues={initialValues} failed={failed} />
  )
}

const mapStateToProps = state => ({
  submitting: Auth.selectLoading(state),
  failed: Auth.selectLoginError(state),
  user: Auth.selectUser(state),
});

const mapDispatchToProps = {
  login: Auth.loginUser,
};

export const LoginContainer =  withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainerComp)
);

