// @ts-nocheck
import React, { useState } from "react";
import { Attachments } from ".";
import find from "lodash/find";
import { FormikProps } from "formik";
import axios from "axios";
import { useGetGeneralSignedUrlMutation } from "src/generated/ApolloComponents";

interface Props {
  name: string;
  setIsUploading: (loading: boolean) => void;
  setFieldValue: (value: any) => void;
  setFieldTouched: () => void;
  values: any;
  formProps: FormikProps<any>;
}

export const AttachmentsContainer: React.FC<Props> = ({
  setIsUploading,
  setFieldValue,
  setFieldTouched,
  values,
  name,
  formProps,
}) => {
  const [fileList, setUploadedFile] = useState([] as Array<any>);
  const [getGeneralSignedUrlMutation] = useGetGeneralSignedUrlMutation();

  const onUpload = async data => {
    const fileData = {
      fileName: data.file.name,
      fileType: data.file.type,
      folder: "payment-request",
    };
    setIsUploading(true);
    const id = Math.floor(Math.random() * 90000) + 10000;

    const newFileList = [
      ...(fileList || []),
      {
        id: id,
        uid: id,
        data: data?.file,
        name: data?.file?.name,
        status: "uploading",
      },
    ];

    setUploadedFile([...newFileList]);

    try {
      const result = await getGeneralSignedUrlMutation({
        variables: fileData,
      });

      if (result) {
        const signedUrl = result?.data?.getGeneralSignedURL?.url!;

        const options = {
          headers: {
            "Content-Type": data.file.type,
          },
        };

        axios
          .put(signedUrl as string, data.file, options)
          .then(response => {
            const file = find(newFileList, { id: id });
            file.status = "done";
            file.uid = response?.data?.public_id || data?.file?.uid;
            file.url = response?.data?.url;
            file.name = response?.data?.original_filename || data?.file?.name;

            setUploadedFile([...newFileList]);
            const newValues = (values[name] || []).push(
              result?.data?.getGeneralSignedURL?.uploadUrl
            );

            setFieldValue([...newValues]);

            setIsUploading(false);
            setFieldTouched();
          })
          .catch(err => {
            setIsUploading(false);
          });
      }
    } catch (e) {}
  };

  const handleRemove = data => {
    const index = fileList.findIndex(obj => obj.id === data?.id);

    if (index > -1) {
      fileList.splice(index, 1);
      setUploadedFile([...fileList]);

      values[name].splice(index, 1);
      setFieldValue(values[name]);
    }
  };

  return (
    <Attachments
      handleRemove={value => handleRemove(value)}
      onUpload={value => onUpload(value)}
      fileList={fileList}
      formProps={formProps}
    />
  );
};
