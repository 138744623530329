// @ts-nocheck
import React from "react";
import { Modal, Table, Switch } from "antd";
import { connectModal } from "redux-modal";

// interface Props {
//   allColumns: Array<any>;
//   changeVisible: (value, key) => void;
//   show: boolean;
//   handleHide: Function;
// }

const ColumnsModal: React.FC<any> = ({
  allColumns,
  changeVisible,
  show,
  handleHide,
}) => {
  const columns = [
    // {
    //   title: "",
    //   render: () => <Icon type="ordered-list" />,
    //   key: "order",
    // },
    {
      title: "Column name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Visible?",
      render: (value, row, index) => (
        <Switch
          checked={value.visible}
          onChange={checked => changeVisible(checked, row?.key)}
        />
      ),
      key: "visible",
    },
  ];

  return (
    <Modal
      title="Manage columns"
      visible={show}
      footer={null}
      onCancel={handleHide}
    >
      {/* <ReactDragListView onDragEnd={reorderColumns} handleSelector="i"> */}
      <Table
        columns={columns}
        rowKey="key"
        pagination={false}
        dataSource={allColumns}
        size="small"
      />
      {/* </ReactDragListView> */}
    </Modal>
  );
};

export default connectModal({
  name: "ColumnsModal",
  destroyOnHide: false,
})(ColumnsModal);
