// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import isEqual from "lodash/isEqual";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Button } from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";
import { FilterFormContainer } from "./container";
import {
  Title,
  Platform,
  Status,
  Tags,
  CustomTags,
  CampaignDate,
  Budget,
  CostOfSale,
  Locations,
  Admins,
  Public,
  Currency,
  HubSpotDealId
} from "./FormFields";
import { shouldShowClearAllFilter } from "./utils";

interface FilterFormProps<Values = FormikValues> {
  initialValues: Values;
  isSubmitting: boolean;
  FilterSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  clearAllFilter: () => void;
  internalFilters: any;
  clearFilterKey: (key: string) => void;
  allTags: Array<any>;
  admins: any;
}

const FilterForm: React.FC<FilterFormProps> = ({
  initialValues,
  isSubmitting,
  FilterSchema,
  onSubmit,
  clearAllFilter,
  internalFilters,
  clearFilterKey,
  allTags,
  admins,
}) => {
  return (
    <>
      <Loading spinning={false}>
        <Form
          initialValues={initialValues}
          schema={FilterSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <Box p="15px">
                <Row gutter={32}>
                  <Col span={6}>
                    <Box mb={30}>
                      <Typography
                        size={20}
                        weight="semi-bold"
                        color="black"
                        lineHeight={0}
                      >
                        Search Campaigns
                      </Typography>
                      <Typography
                        size={14}
                        weight="semi-bold"
                        color="grey8"
                        mb={0}
                      >
                        Search all campaigns
                      </Typography>
                    </Box>

                    <Title
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />

                    <Button
                      htmlType="submit"
                      type="action"
                      loading={isSubmitting}
                      disabled={
                        isSubmitting ||
                        isEqual(formProps?.initialValues, formProps?.values)
                      }
                      buttonSize="middle"
                      style={{ width: "100%" }}
                    >
                      Update results
                    </Button>

                    {shouldShowClearAllFilter(internalFilters) && (
                      <Typography
                        size={12}
                        color="red"
                        cursor="pointer"
                        mt={20}
                        textAlign="center"
                        onClick={() => {
                          formProps?.resetForm();
                          clearAllFilter();
                        }}
                      >
                        Clear all filters
                      </Typography>
                    )}
                  </Col>
                  <Col span={6}>
                    <Platform
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                    <CostOfSale
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                  </Col>
                  <Col span={6}>
                    <CampaignDate
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                    <Budget
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                  </Col>
                  <Col span={6}>
                    <Status
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                    <Public
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                  </Col>
                </Row>

                <Row gutter={32}>
                  <Col span={6}></Col>

                  <Col span={12}>
                    <Row>
                      <Col span={8}>
                        <Currency
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                      <Col span={9} offset={5}>
                        <HubSpotDealId
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    </Row>
                    <Tags
                      allTags={allTags}
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                  </Col>

                  <Col span={6}>
                    <Locations
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                    <CustomTags
                      allTags={allTags}
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                    <Admins
                      admins={admins}
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                  </Col>
                </Row>
              </Box>
            );
          }}
        </Form>
      </Loading>
    </>
  );
};

export { FilterForm, FilterFormContainer };
