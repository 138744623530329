// @ts-nocheck
import * as React from "react";

interface ContextType {
  tiktokUserUrl?: any;
  analyseData?: any;
  analyseLoading: boolean;

  actions: {
    setTiktokUserUrl: (value: any) => void;
    setAnalyseData: (value: any) => void;
    setAnalyseLoading: (value: boolean) => void;
  };
}

const BrandSafetyContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our brand safety context
export function useBrandSafetyDetails() {
  const context = React.useContext(BrandSafetyContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
const BaseBrandSafetyProvider = props => {
  const [analyseLoading, setAnalyseLoading] = React.useState(false);
  const [tiktokUserUrl, setTiktokUserUrl] = React.useState();
  const [analyseData, setAnalyseData] = React.useState({});

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      tiktokUserUrl,
      analyseData,
      analyseLoading,

      actions: {
        setTiktokUserUrl,
        setAnalyseData,
        setAnalyseLoading,
      },
    };
    return internalVal;
  }, [tiktokUserUrl, analyseData, analyseLoading]);

  return (
    <BrandSafetyContext.Provider value={value}>
      {props.children}
    </BrandSafetyContext.Provider>
  );
};

const BrandSafetyProvider = React.memo(BaseBrandSafetyProvider);

export { BrandSafetyProvider };
