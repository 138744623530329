// @ts-nocheck
import React from "react"
import { Box } from "src/components/Core/Box"
import { Spin } from "antd"
import { Button } from "src/components/Core/Button"
import Form from "src/components/Forms/Form"
import { FormikHelpers as FormikActions } from "formik"
import { Schema } from "yup"
import { Input, Checkbox, Form as FormikForm, Select } from "formik-antd"
import LinkButton from "src/components/Core/LinkButton"
import {
  UpdateGiftingCampaign,
  GiftingCampaignFragmentFragment,
  GiveawayCampaignType
} from "src/generated/ApolloComponents"
import { GiftingWizardWrapper } from "../../GiftingWizardWrapper"

interface Props {
  previousStep: Function
  schema: Schema<object>
  initialValues?: UpdateGiftingCampaign
  loading: boolean
  campaign?: GiftingCampaignFragmentFragment
  setStep: (number: number) => void
  onSubmit: (values: any, formikActions: FormikActions<any>) => void
}

export const PostingGuidelines = ({
  previousStep,
  schema,
  onSubmit,
  setStep,
  loading,
  campaign,
  initialValues
}: Props) => {
  const options = [
    {
      value: GiveawayCampaignType.Giveaway,
      label: "Giveaway (e.g. Competition)"
    },
    {
      value: GiveawayCampaignType.Experience,
      label: "Experience (e.g. Skydiving)"
    },
    {
      value: GiveawayCampaignType.Product,
      label: "Product (e.g. Free clothing)"
    }
  ]
  return (
    <GiftingWizardWrapper campaign={campaign} setStep={setStep} step="posting">
      <Box borderRadius={5} background="white" padding={30} width="100%">
        <Spin spinning={loading}>
          <Form initialValues={initialValues} schema={schema} onSubmit={onSubmit}>
            {({ submitForm }) => {
              return (
                <Box width={400}>
                  <FormikForm.Item
                    name="brief.campaignType"
                    label="What type of gifting campaign is this?"
                    colon={false}
                  >
                    <Select name="brief.campaignType" defaultValue={GiveawayCampaignType.Product} options={options}>
                    </Select>
                  </FormikForm.Item>

                  <FormikForm.Item
                    label="Tell influencers about what you are offering them"
                    name="brief.aboutCampaign"
                    colon={false}
                  >
                    <Input.TextArea
                      name="brief.aboutCampaign"
                      rows={4}
                      placeholder="e.g. We are offering you the chance to travel the world with this celebrity"
                    />
                  </FormikForm.Item>

                  <FormikForm.Item
                    name="brief.postingGuidlines"
                    label="What would you like influencers to do/say in their content?"
                    help="List at least three things you want influencers to do in their videos"
                    colon={false}
                  >
                    <Input.TextArea
                      name="brief.postingGuidlines"
                      rows={4}
                      placeholder="e.g. Please show off this particular feature of our product"
                    />
                  </FormikForm.Item>

                  <FormikForm.Item
                    name="brief.requiredInformation"
                    label="What information do you need from influencers?"
                    colon={false}
                  >
                    <Checkbox name="brief.requiredInformation.address">Home Address</Checkbox>
                    <Checkbox name="brief.requiredInformation.phoneNumber">Phone number</Checkbox>
                    <Checkbox name="brief.requiredInformation.email">Email</Checkbox>
                  </FormikForm.Item>

                  <FormikForm.Item
                    name="brief.repurposeContent"
                    label="Would you like the ability to repurpose content on your own platforms?"
                    colon={false}
                  >
                    <Checkbox name="brief.repurposeContent">Yes/No</Checkbox>
                  </FormikForm.Item>

                  <LinkButton onClick={() => previousStep()}>Back</LinkButton>
                  <Button onClick={submitForm} type="action">
                    Continue
                  </Button>
                </Box>
              )
            }}
          </Form>
        </Spin>
      </Box>
    </GiftingWizardWrapper>
  )
}
