import React, { Component } from "react";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import { Descriptions } from "antd";
import { Link } from "react-router-dom";
import Container from "src/shared/Container";
import CampaignReview from "src/shared/CampaignReview";
import BrandsTable from "./BrandsTable";
import CampaignManage from "src/ducks/CampaignManage";
import { BrandList as $BrandList } from "src/ducks/DataList";
import { AdminList as $AdminList } from "src/ducks/DataList";

const BrandList = $BrandList("CampaignBrandList");
const AdminList = $AdminList("CampaignExecutiveInput");

const modules = [BrandList.module(), AdminList.module()];

export class CampaignDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      admin: {},
      executive: {}
    };
  }

  loadUser(id, type) {
    const { loadAdmin } = this.props;
    if(id){
      loadAdmin(id).then(user => {
        this.setState({ [type]: user?.value?.data })
      })
    }
  }

  componentDidMount() {
    const { campaign, loadBrands } = this.props;
    loadBrands({ campaign: campaign.id });
    this.loadUser(campaign?.admin, 'admin')
    this.loadUser(campaign?.executive, 'executive')
  }


  render() {
    const { campaign, brands } = this.props;
    const { admin, executive } = this.state
    let extraBrand = (brands || [])
      .filter(x => (campaign.extra_brand_ids || []).includes(x.id))
      .map(x => x.name)
      .join(", ");

    return (
      <Container>
        {campaign.company && (
          <div style={{ background: "#fff", marginTop: 24, marginBottom: 24 }}>
            <Descriptions data-cy="campaign-company-table" column={1} bordered>
              <Descriptions.Item data-cy="campaign-company" label="Company">
                <Link to={`/companies/` + campaign.company.id}>
                  {campaign.company.company_name}
                </Link>
              </Descriptions.Item>

              <Descriptions.Item
                data-cy="campaign-extra-brands"
                label="Who has access to this campaign?"
              >
                {extraBrand}
              </Descriptions.Item>
              <Descriptions.Item
                data-cy="campaign-extra-brands"
                label="Admin"
              >
                {admin?.name}
              </Descriptions.Item>
              <Descriptions.Item
                data-cy="campaign-extra-brands"
                label="Executive"
              >
                {executive?.name}
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
        <BrandsTable style={{ marginBottom: 24 }} campaign={campaign} />
        <CampaignReview style={{ marginBottom: 24 }} campaign={campaign} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
  brands: BrandList.selectMany(state),
})

const mapDispatchToProps = {
  loadBrands: BrandList.loadMany,
  loadAdmin: AdminList.loadOne,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(CampaignDetails)
)(modules);
