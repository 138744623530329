// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import { HubCheckbox } from "./styles";

interface Props {
  formProps: FormikProps<any>;
  hubLinkData: any;
  getStatus: (value: string) => any;
}

const PostDetailYouTube: React.FC<Props> = ({
  formProps,
  hubLinkData,
  getStatus,
}) => {
  const value = formProps?.values;
  return (
    <Box mt={10}>
      <Box display="inline-flex">
        <HubCheckbox
          name="postDetailYouTube"
          onChange={() => {
            formProps?.setFieldTouched("postDetailYouTube");
            formProps?.setFieldValue(
              "postDetailYouTube",
              !value.postDetailYouTube
            );

            if (!value.postDetailYouTube) {
              formProps?.setFieldValue("postDetail", false);
            }
          }}
          disabled={
            hubLinkData?.postDetail?.status === "complete" ||
            hubLinkData?.postDetailYouTube?.status === "complete"
          }
          value={value.postDetailYouTube}
          data-cy="checkbox-postDetailYouTube"
          checked={value.postDetailYouTube}
        />
        <Typography size={14} color="black2" ml={10} mb={10} fontWeight={600}>
          Post details (Youtube)
        </Typography>

        {value.postDetailYouTube &&
          getStatus(hubLinkData?.postDetailYouTube?.status)}
      </Box>
      <Typography size={12} color="black">
        This will allow influencers to submit the link to their live content and
        record their current numbers
      </Typography>
    </Box>
  );
};

export default PostDetailYouTube;
