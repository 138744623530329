// @ts-nocheck
import React from "react";
import { FilterProps } from "../../PaymentQueueList";
import { AutoCompleteInput } from "src/components/Forms/AutoComplete";
import Highlighter from "react-highlight-words";
import compact from "lodash/compact";

const { Option, OptGroup } = AutoCompleteInput;

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
  filterDataSoruce: any;
}

const SearchInput: React.FC<Props> = ({
  handleChange,
  filters,
  filterDataSoruce,
}) => {
  function renderTitle(title) {
    return <span>{title}</span>;
  }

  function renderMatchValue(value) {
    return (
      <Highlighter
        highlightClassName="matchWord"
        searchWords={[filters?.searchInput]}
        autoEscape={true}
        textToHighlight={value}
        highlightStyle={{ fontWeight: "500", backgroundColor: "transparent" }}
      />
    );
  }

  const mappingLableWithKey = {
    campaign: "Campaign",
    influencer: "Influencer",
    id: "Payment Request Id",
    requestedBy: "Requested by",
  };

  const options = Object.keys(filterDataSoruce || {}).map((key, i) => {
    if ((filterDataSoruce[key] || []).length > 0) {
      return (
        <OptGroup key={i} label={renderTitle(mappingLableWithKey[key])}>
          {(filterDataSoruce[key] || []).slice(0, 3).map((opt, j) => (
            <Option key={j} value={opt?.result} data-category={key}>
              {renderMatchValue(opt?.result)}
            </Option>
          ))}
        </OptGroup>
      );
    } else {
      return null;
    }
  });

  const newOptions = compact(options);

  return (
    <AutoCompleteInput
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ width: 300 }}
      size="large"
      background="background"
      borderRadius={3}
      borderColor="grey5"
      style={{ width: "100%" }}
      dataSource={newOptions}
      placeholder="Search by ID, campaign, requested or influencer"
      optionLabelProp="value"
      onSelect={(value, y: any) => {
        if (y?.props["data-category"] === "influencer") {
          handleChange("influencer", value);
          handleChange("campaign", null);
          handleChange("paymentRequestId", null);
          handleChange("requestedBy", null);
        }
        if (y?.props["data-category"] === "campaign") {
          handleChange("campaign", value);
          handleChange("influencer", null);
          handleChange("paymentRequestId", null);
          handleChange("requestedBy", null);
        }
        if (y?.props["data-category"] === "id") {
          handleChange("paymentRequestId", value);
          handleChange("influencer", null);
          handleChange("campaign", null);
          handleChange("requestedBy", null);
        }
        if (y?.props["data-category"] === "requestedBy") {
          handleChange("requestedBy", value);
          handleChange("influencer", null);
          handleChange("campaign", null);
          handleChange("requestedBy", null);
        }
      }}
      onChange={value => {
        handleChange("searchInput", value);
      }}
      value={filters?.searchInput}
    />
  );
};

export default SearchInput;
