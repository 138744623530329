// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { ExternalBasicInfoContainer } from "./container";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import PlatformLink from "../PlatformLink";

interface Props {
  globalErrors?: GlobalErrorProps[];
  accountData: any;
  isLoading: boolean;
}

const ExternalBasicInfo: React.FC<Props> = ({
  globalErrors,
  accountData,
  isLoading,
}) => {
  return (
    <Box p={15}>
      <Loading spinning={isLoading}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <GlobalErrors errors={globalErrors} />
          </Box>
        </Box>
        <Box>
          <Row gutter={8}>
            <Col span={16}>
              <Box display="flex">
                <Typography
                  size={26}
                  weight="semi-bold"
                  color="black"
                  lineHeight="22px"
                >
                  @{accountData?.username}
                </Typography>
                <PlatformLink accountData={accountData} />
              </Box>
            </Col>
          </Row>
        </Box>
      </Loading>
    </Box>
  );
};

export { ExternalBasicInfo, ExternalBasicInfoContainer };
