// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import { GeneralTabContainer } from "./GeneralTab";
import { PostedContainer } from "./Posted";
import { TrackingGraphContainer } from "./Track/TrackingGraph";
import { CampaignTrackingTypeEnum } from "src/generated/ApolloComponents";
import { MilestoneContainer } from './Milestone'

const AnalyticsTabsBar = styled(Tabs)`
  && {
    .ant-tabs-bar {
      background: #ffffff !important;
      border-bottom: 2px solid #e5e5e5;
      margin-bottom: 0px;
    }
    .ant-tabs-nav .ant-tabs-tab {
      color: #e5e5e5;
      font-size: 16px;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #1890ff !important;
    }
  }
`;

const { TabPane } = Tabs;

interface Props {
  isShowTrack: boolean;
}

const AnalyticsTab: React.FC<Props> = ({ isShowTrack }) => {
  const [activeKey, setActiveKey] = useState("1");
  const [selectedAccount, setSelectedAccount] = useState(null);

  return (
    <AnalyticsTabsBar
      activeKey={activeKey}
      onChange={activeKey => setActiveKey(activeKey)}
    >
      <TabPane tab="General" key="1">
        <GeneralTabContainer
          onChangeActiveKey={() => setActiveKey("3")}
          onViewPerformance={value => setSelectedAccount(value)}
        />
      </TabPane>
      <TabPane tab="Milestones" key="2">
        <MilestoneContainer />
      </TabPane>
      <TabPane tab="Posts" key="3">
        <PostedContainer
          activeKey={activeKey}
          selectedAccount={selectedAccount}
        />
      </TabPane>
      <TabPane tab="Sounds" key="4">
        <TrackingGraphContainer
          isShowTrack={isShowTrack}
          trackingType={CampaignTrackingTypeEnum.Music}
        />
      </TabPane>
      <TabPane tab="Hashtags" key="5">
        <TrackingGraphContainer
          isShowTrack={isShowTrack}
          trackingType={CampaignTrackingTypeEnum.Hashtag}
        />
      </TabPane>
    </AnalyticsTabsBar>
  );
};

export default AnalyticsTab;
