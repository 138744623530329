// @ts-nocheck
import React from "react";
import Button from "src/components/Core/Button";
import { connect } from "react-redux";
import { show } from "redux-modal";

interface Props {
  show: Function;
}

const ManageColumn: React.FC<Props> = ({ show }) => {
  return (
    <Button
      buttonSize="small"
      key="columns"
      onClick={() => show("ColumnsModal")}
      style={{ boxShadow: "none" }}
    >
      Manage Columns
    </Button>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  show,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageColumn);
