import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Loading from "../shared/Loading";
import FullStory, { FullStoryAPI } from "react-fullstory";

import { withRouter } from "react-router-dom";

import BrandRoutes from "./brands/Routes";
import AdminRoutes from "./admins/Routes";
import AuthRoutes from "./auth/Routes";
import InflunecerRoutes from "./influencers/Routes";

import Auth from "../ducks/Auth";
import analytics from "react-segment";

class Routes extends Component {
  componentDidMount() {
    const { fetch } = this.props;
    fetch();
  }


  render() {
    const {
      user,
      user: { type },
      appLoaded,
      authenticated,
    } = this.props;

    if (user) {
      window.intercomSettings = {
        app_id: "ahkr0lkc",
        user_id: user.id,
        name: user.name, // Full name
        email: user.email, // Email address
        user_type: user.type,
        created_at: new Date(user.created_at).getTime() / 1000, // Signup date as a Unix timestamp
      };

      FullStoryAPI("identify", user.id, {
        displayName: user.name,
        email: user.email,
      });

      analytics.default.identify({
        userId: user.id,
        displayName: user.name,
        email: user.email,
        userType: user.type,
      });
      window.heap.identify(user.type);
    }

    const routes = () => {
      switch (type) {
        case "Brand":
          return <BrandRoutes />;
        case "Influencer":
          return <InflunecerRoutes />;
        case "Admin":
          return <AdminRoutes />;
          // return <InflunecerRoutes />;
        default:
          return <AuthRoutes />;
      }
    };

    return (
      <Fragment>
        {console.log('Hey! ', process.env.REACT_APP_FULLSTORY_ORG_ID)}
        <FullStory org={process.env.REACT_APP_FULLSTORY_ORG_ID} />
        {appLoaded && (type || !authenticated) ? routes() : <Loading />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: Auth.selectUser(state),
  appLoaded: Auth.selectAppLoaded(state),
  authenticated: Auth.selectAuthenticated(state),
});

const mapDispatchToProps = {
  login: Auth.loginUser,
  fetch: Auth.fetchUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
