// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";

interface Props {}

const VatNumber: React.FC<Props> = () => {
  return (
    <Field label="VAT Number:" mb={26}>
      <FormikInput
        name="vatNumber"
        size="middle"
        placeholder="e.g. GB 795796789"
        borderRadius={3}
        borderColor="grey5"
      />
    </Field>
  );
};

export default VatNumber;
