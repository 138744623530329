// @ts-nocheck
import React from 'react'
import { NewMilestoneForm } from '.'
import {
  useCreateCampaignMilestoneMutation,
  useUpdateCampaignMilestoneMutation,
  useDeleteCampaignMilestoneMutation,
} from 'src/generated/ApolloComponents'
import { useRouteMatch } from 'react-router'
import { useMapGraphQLErrorsMessages } from 'src/utils/hooks/useMapGraphQLErrors'

interface Props {
  toggleVisibility: () => void
  milestone: any
  refetch: any
}

const NewMilestoneFormContainer = ({toggleVisibility, milestone, refetch}: Props) => {
  const match: any = useRouteMatch();
  const params: any = match?.params;

  const campaignId = parseInt(params?.id);

  const [ createMilestoneMutation, createData ] = useCreateCampaignMilestoneMutation()
  const [ updateMilestoneMutation, updateData ] = useUpdateCampaignMilestoneMutation()
  const [ deleteMilestoneMutation, deleteData ] = useDeleteCampaignMilestoneMutation()

  const globalErrors = useMapGraphQLErrorsMessages(createData?.error || updateData?.error || deleteData?.error);

  const handleAction = (values, type) => {
    switch(type){
      case 'save':
        manageMilestone(milestone?.id ? updateMilestoneMutation : createMilestoneMutation, values)
        break;
      case 'delete':
        manageMilestone(deleteMilestoneMutation, null)
        break;
    }

  }

  const manageMilestone = (mutation, values) => {
    mutation({
      variables: {
        campaignMilestoneId: milestone?.id,
        campaignId: campaignId,
        ...formatValues(values)
      }
    }).then(() => {
      toggleVisibility()
      refetch()
    })
    .catch(e => console.log(e))
  }

  const formatValues = (values) => {
    const formattedValues = { ...values }
    const booleansObj =  {notifiables: 'notification', viewers: 'visibility' }
    Object.keys(booleansObj).map(key => {
      if(formattedValues?.[key]){
        delete formattedValues[key]
        formattedValues[booleansObj[key]] = {}
        const subjects = ['admins', 'clients']
        subjects.map(subject => formattedValues[booleansObj[key]][subject] = values[key].includes(subject))
      }
      return true // removes arrow function return value warning
    })
    if(formattedValues.goal){
      formattedValues['goal'] = parseFloat(formattedValues.goal)
    }
    return formattedValues
  }

  return(
    <NewMilestoneForm
      toggleVisibility={toggleVisibility}
      handleAction={handleAction}
      globalErrors={globalErrors}
      milestone={milestone}
      loading={createData?.loading || updateData?.loading || deleteData?.loading}
    />
  )
}

export default NewMilestoneFormContainer
