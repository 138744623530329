// @ts-nocheck
import * as React from "react";
import {
  useSearchInfluencerAccountsQuery,
  DataSourcePlatform,
} from "src/generated/ApolloComponents";
import { useHistory, useLocation } from "react-router";
import qs from "qs";
import { getFilterData, formatExportCSVData } from "./utils";

interface ContextType {
  isLoading: boolean;
  listErrors?: any;
  accountsListDetails?: any;
  filters?: any;
  actions: {
    refetchAccounts: (value: any) => void;
    setFilters: (value: any) => void;
    exportAccounts: () => void;
  };
}

const InfluencersListContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our influencer detail context
export function useInfluencersListDetails() {
  const context = React.useContext(InfluencersListContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
const BaseInfluencersListProvider = React.memo((props: any) => {
  const history: any = useHistory();
  const location: any = useLocation();

  const parsed = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // need to convert string and boolean value from query string
  const formatFilters = getFilterData(parsed);

  const initialFilters = {
    platformType: DataSourcePlatform.Fanbytes,
    page: 1,
  };

  const [filters, setFilters] = React.useState({
    ...initialFilters,
    ...formatFilters,
  });

  const { data, loading, error, refetch } = useSearchInfluencerAccountsQuery({
    variables: filters,
  });

  const accountData = data?.searchInfluencerAccounts;
  // refetch Accounts
  const refetchAccounts = filterData => {
    // remove null element from object
    const newFilterData = Object.keys(filterData)
      .filter(e => filterData[e] !== null)
      .reduce((o, e) => {
        o[e] = filterData[e];
        return o;
      }, {});

    history.push({
      search: qs.stringify(newFilterData, {
        encode: false,
        skipNulls: true,
      }),
    });
    refetch(newFilterData as any);
  };

  const exportAccounts = async () => {
    const newFilterData = { ...filters, exportCSV: true };

    const accounts = await refetch(newFilterData as any);
    const accountData =
      accounts?.data?.searchInfluencerAccounts?.searchAccounts || [];

    if (accountData?.length > 0) {
      formatExportCSVData(newFilterData?.platformType, accountData);
    }
  };

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      accountsListDetails: accountData,
      isLoading: loading,
      listErrors: error,
      filters,
      actions: {
        refetchAccounts,
        setFilters,
        exportAccounts,
      },
    };
    return internalVal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error, filters]);

  return (
    <InfluencersListContext.Provider value={value}>
      {props.children}
    </InfluencersListContext.Provider>
  );
});

const InfluencersListProvider = React.memo(BaseInfluencersListProvider);

export { InfluencersListProvider };
