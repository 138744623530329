// @ts-nocheck
import React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Layout } from "antd";
import { SidebarBox, ContentBox, Breadcrumb } from "./Wrapper";
import { CampaignsListContainer } from "./CampaignsList";
import Sidebar from "./Sidebar";
import { NotesListContainer } from "./Notes/NotesList";
import { NewNoteFormContainer } from "./Notes/NewNoteForm";
import { AccountDetailContainer } from "./AccountDetail";
import { InfluencerProfileProvider } from "./InfluencerProfileProvider";
import { InfluencerNotesProvider } from "./InfluencerNotesProvider";

const InfluencerProfile = ({ match, history }: RouteComponentProps) => {
  const params: any = match?.params;
  const platformType = params.type;
  return (
    <DashboardLayout title="Influencer Profile">
      <InfluencerProfileProvider>
        <InfluencerNotesProvider>
          <Breadcrumb
            title={
              <Box display="flex" color="grey7">
                Search Result /
                <Typography color="blue" ml="5px" lineHeight="0px" mt={10}>
                  Influencer Profile
                </Typography>
              </Box>
            }
            onBack={() => history.goBack()}
          />
          <Box p={20}>
            <Layout>
              <SidebarBox width={240}>
                <Sidebar />
              </SidebarBox>
              <ContentBox>
                <Switch>
                  <Route
                    path={`${match.path}/campaigns`}
                    exact
                    component={CampaignsListContainer}
                  />
                  <Route
                    path={`${match.path}/notes`}
                    exact
                    component={NotesListContainer}
                  />
                  <Route
                    path={`${match.path}/notes/add`}
                    exact
                    component={NewNoteFormContainer}
                  />
                  <Route
                    path={`${match.path}/account/:accountId?`}
                    exact
                    component={AccountDetailContainer}
                  />

                  <Redirect
                    to={
                      platformType !== "fanbytes"
                        ? `${match.path}/account/:accountId?`
                        : `${match.path}/campaigns`
                    }
                  />
                </Switch>
              </ContentBox>
            </Layout>
          </Box>
        </InfluencerNotesProvider>
      </InfluencerProfileProvider>
    </DashboardLayout>
  );
};

export default InfluencerProfile;
