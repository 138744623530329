// @ts-nocheck
import React from 'react'
import { Box } from 'src/components/Core/Box'
import { Typography } from 'src/components/Core/Typography'

interface Props {
  head: string
  description: string
}

const Section = ({head, description}: Props) => {
  return(
    <Box px={40}>
      <Typography fontSize={20} color="black1" mb={2} family="Futura">{head}</Typography>
      <Typography fontSize={16} color="black1" mb={4}>{description}</Typography>
    </Box>
  )
}

export default Section
