// @ts-nocheck
import React, { Component } from "react";
import { connect } from "react-redux";
import CampaignManage from "src/ducks/CampaignManage";
import { withFormik } from "formik";
import { Form } from "formik-antd";
import Validate from "./Validate";
import { message } from "antd";
import { Button } from "src/components/Core/Button";
import {
  TrackingLink,
  IntegrationPartner,
  IosId,
  AndroidId,
} from "src/shared/CampaignFields";

type Props = {
  values?: any;
  handleSubmit?: any;
  isSubmitting?: boolean;
  isValid?: boolean;
  campaign?: any;
  setFieldValue?: any;
  setTrackingLinkExits: Function;
};

export class BrandCampaignTrackingForm extends Component<Props> {
  componentDidMount() {
    if (this.props.values.default_link) {
      this.props.setTrackingLinkExits(true);
    }
  }

  componentDidUpdate(prevProps) {
    const currentValue = this.props.values.default_link;
    const prevValue = prevProps.values.default_link;

    if (currentValue !== prevValue) {
      if (currentValue) {
        this.props.setTrackingLinkExits(true);
      } else {
        this.props.setTrackingLinkExits(false);
      }
    }
  }

  render() {
    const { values, isSubmitting } = this.props;
    return (
      <Form data-cy="brand-campaign-tracking-update-form">
        <TrackingLink values={values} />
        <IntegrationPartner values={values} />
        <IosId values={values} />
        <AndroidId values={values} />

        <Button
          htmlType="submit"
          type="action"
          loading={isSubmitting}
          disabled={isSubmitting}
          buttonSize="middle"
          data-cy="update-button"
        >
          Update
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {
  updateCampaign: CampaignManage.campaign.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,
    enableReinitialize: true,

    mapPropsToValues: ({ campaign, Validate }: any) =>
      campaign ? campaign.ref : Validate.cast(),
    validationSchema: () => Validate,

    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { updateCampaign, campaign } = props;

      updateCampaign(campaign.id, values)
        .then(res => {
          setSubmitting(false);
        })
        .catch(err => {
          message.error("Failed to update campaign");
          setSubmitting(false);

          if (err.response.data) setErrors(err.response.data.meta.errors);
        });
    },

    displayName: "BrandCampaignTrackingForm",
  })(BrandCampaignTrackingForm)
);
