// @ts-nocheck
import React from 'react'
import { Typography } from 'src/components/Core/Typography'
import { Box } from 'src/components/Core/Box'
import { Row, Col, Button } from 'antd';
import styled from 'styled-components'
import { Icon } from '@ant-design/compatible'

const StyledIcon = styled(Icon)`
  color: #F5222D !important;
  margin-top: 2px;
`

const StyledButton = styled(Button)`
  border: none !important;
`
interface Props {
  handleAction: (values: any, action: string) => void
}

const Header = ({handleAction}: Props) => {
  return(
    <Row justify="space-between">
      <Col>
        <Typography size={20} color="black" family="Futura">
          Create new Milestone
        </Typography>
      </Col>
      <Col>
        <StyledButton onClick={_ => handleAction(null, 'delete')}>
          <Box display="flex">
            <StyledIcon type="delete" /> &nbsp;&nbsp;
            <Typography color="danger" mb={0} cursor="pointer">
              Delete
            </Typography>
          </Box>
        </StyledButton>
      </Col>
    </Row>
  )
}

export default Header
