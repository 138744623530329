import React, { Component } from "react";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import { Input, Button } from "antd";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { TagList as $TagList, TagListModule } from "src/ducks/DataList";
import { TagPersist } from "src/ducks/Persist";
import FormError from "src/shared/FormField/FormError";
const TagCreate = TagPersist("InfluencerPayoutModal");
const TagList = $TagList("AccountTagList");

const modules = [TagListModule("AccountTagList")(), TagCreate.module()];

export class AddTag extends Component {
  state = {
    name: "",
    error: "",
  };

  addNewTag = () => {
    const { name, error } = this.state;
    const { createTag, loadTags, data } = this.props;

    if (name !== "") {
      if (
        !data.find(item => item.name.toLowerCase() === name.toLowerCase()) &&
        error === ""
      ) {
        createTag({
          name: name,
        })
          .then(res => {
            this.setState({ name: "", error: "" });
            loadTags();
          })
          .catch(err => {
            if (err.response.data) this.setState({ error: "some went wrong!" });
          });
      } else {
        this.setState({ error: "Please enter unique tagname" });
      }
    } else {
      this.setState({ error: "Please enter tagname" });
    }
  };

  render() {
    const { name, error } = this.state;
    const { data } = this.props;
    return (
      <Box mb={20} mt={10}>
        <Input
          size="large"
          placeholder="Add a new tag"
          name="name"
          onChange={e => {
            if (
              !data.find(
                item => item.name.toLowerCase() === e.target.value.toLowerCase()
              )
            ) {
              this.setState({ error: "" });
            } else {
              this.setState({ error: "Please enter unique tagname" });
            }
            this.setState({ name: e.target.value });
          }}
          value={name}
        />
        <Typography color="grey7" mt={10}>
          Avoid adding duplicate and similar tags
        </Typography>

        {error && (
          <FormError style={{ margin: "0 0 10px 0" }} message={error} />
        )}

        <Button type="primary" onClick={() => this.addNewTag()}>
          Add new tag
        </Button>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  data: TagList.selectMany(state),
});

const mapDispatchToProps = {
  loadTags: TagList.loadMany,
  createTag: TagCreate.create,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(AddTag)
)(modules);
