// @ts-nocheck
import React from "react";
import { CampaignGroupProvider } from "./CampaignGroupProvider";
import CampaignGroupLayout from "./CampaignGroupLayout";

const CampaignGroupShow = () => {
  return (
    <CampaignGroupProvider>
      <CampaignGroupLayout />
    </CampaignGroupProvider>
  );
};

export default CampaignGroupShow;
