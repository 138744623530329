// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import StyledAutoComplete from "./StyledAutoComplete";
import { AutoCompleteProps } from "../AutoComplete/StyledAutoComplete";
import get from "lodash/get";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";

interface Props {
  name?: any;
  value?: any;
  dataSource?: Array<any>;
  filterOption?: Function;
  onSelect?: Function;
}

const FormikAutoComplete: React.FC<Props & AutoCompleteProps> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur, setFieldTouched, setFieldValue },
      }) => (
        <>
          <StyledAutoComplete
            {...props}
            {...field}
            touched={get(touched, props.name)}
            error={get(touched, props.name) && get(errors, props.name)}
            onBlur={e => {
              handleBlur(e);
            }}
            onChange={value => {
              setFieldTouched(props.name);
              setFieldValue(props.name, value);
            }}
            data-cy={`input-${props.name.replace(" ", "-")}`}
          />
          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={props.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikAutoComplete;
