// @ts-nocheck
import React, { useRef } from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import { showEngagement, showCTR } from "../../../utils";

interface Props {
  activeSection: string;
  loading: boolean;
  campaignData: any;
}

const Menu: React.FC<Props> = ({ activeSection, loading, campaignData }) => {
  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};
  let props = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  return (
    <Box display="inline-flex" ref={myRef as any}>
      <Box alignItems="center" display="flex">
        <VelocityComponent
          key="slideLeft"
          animation={
            !loading && enterCount <= 1 && inViewport
              ? "transition.slideLeftIn"
              : "transition.fadeIn"
          }
          delay={50}
          stagger={500}
          duration={500}
        >
          <Typography
            size={16}
            color="white"
            marginBottom={0}
            borderBottom={
              activeSection === "objective" ? "2px solid #FF4D4F" : "none"
            }
            fontWeight={activeSection === "objective" ? 600 : ""}
            lineHeight={activeSection === "objective" ? "66px" : "24px"}
            mr={20}
            ml={20}
            onClick={() => {
              let elmnt: any = document.getElementById("objective");
              elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
            }}
            cursor="pointer"
          >
            Objective
          </Typography>
        </VelocityComponent>
        <VelocityComponent
          key="slideLeft1"
          animation={
            !loading && enterCount <= 1 && inViewport
              ? "transition.slideLeftIn"
              : "transition.fadeIn"
          }
          delay={70}
          stagger={700}
          duration={700}
        >
          <Typography
            size={16}
            color="white"
            marginBottom={0}
            borderBottom={
              activeSection === "views" ? "2px solid #FF4D4F" : "none"
            }
            fontWeight={activeSection === "views" ? 600 : ""}
            lineHeight={activeSection === "views" ? "66px" : "24px"}
            mr={20}
            ml={20}
            onClick={() => {
              let elmnt: any = document.getElementById("views");
              elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
            }}
            cursor="pointer"
          >
            Views
          </Typography>
        </VelocityComponent>

        {showCTR(campaignData) && (
          <VelocityComponent
            key="slideLeft2"
            animation={
              !loading && enterCount <= 1 && inViewport
                ? "transition.slideLeftIn"
                : "transition.fadeIn"
            }
            delay={90}
            stagger={900}
            duration={900}
          >
            <Typography
              size={16}
              color="white"
              marginBottom={0}
              borderBottom={
                activeSection === "ctr" ? "2px solid #FF4D4F" : "none"
              }
              fontWeight={activeSection === "ctr" ? 600 : ""}
              lineHeight={activeSection === "ctr" ? "66px" : "24px"}
              mr={20}
              ml={20}
              onClick={() => {
                let elmnt: any = document.getElementById("ctr");
                elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
              }}
              cursor="pointer"
            >
              CTR
            </Typography>
          </VelocityComponent>
        )}

        {showEngagement(campaignData) && (
          <VelocityComponent
            key="slideLeft3"
            animation={
              !loading && enterCount <= 1 && inViewport
                ? "transition.slideLeftIn"
                : "transition.fadeIn"
            }
            delay={90}
            stagger={900}
            duration={900}
          >
            <Typography
              size={16}
              color="white"
              marginBottom={0}
              borderBottom={
                activeSection === "engagement" ? "2px solid #FF4D4F" : "none"
              }
              fontWeight={activeSection === "engagement" ? 600 : ""}
              lineHeight={activeSection === "engagement" ? "66px" : "24px"}
              mr={20}
              ml={20}
              onClick={() => {
                let elmnt: any = document.getElementById("engagement");
                elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
              }}
              cursor="pointer"
            >
              Engagement
            </Typography>
          </VelocityComponent>
        )}

        {/* This will be visible if platform array length 1 and value is nor Snapchat or platform have multiple values  */}
        {showEngagement(campaignData) && (
          <VelocityComponent
            key="slideLeft4"
            animation={
              !loading && enterCount <= 1 && `inViewport`
                ? "transition.slideLeftIn"
                : "transition.fadeIn"
            }
            delay={110}
            stagger={1100}
            duration={1100}
          >
            <Typography
              size={16}
              color="white"
              marginBottom={0}
              borderBottom={
                activeSection === "influencerstats"
                  ? "2px solid #FF4D4F"
                  : "none"
              }
              fontWeight={activeSection === "influencerstats" ? 600 : ""}
              lineHeight={activeSection === "influencerstats" ? "66px" : "24px"}
              mr={20}
              ml={20}
              onClick={() => {
                let elmnt: any = document.getElementById("influencerstats");
                elmnt.scrollIntoView({
                  block: "start",
                  behavior: "smooth",
                });
              }}
              cursor="pointer"
            >
              Influencer stats
            </Typography>
          </VelocityComponent>
        )}
      </Box>
    </Box>
  );
};

export default Menu;
