import React, { Component } from "react"
import FormField from "../FormField"
import { Checkbox } from "formik-antd"
import VerticalCheckbox from "./VerticalCheckbox"
import { age_targets } from "src/constants/campaign"

export default class Age extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "ages"}
        label="Age"
        help="What is the primary age group you are trying to target?"
      >
        <Checkbox.Group>
          {age_targets.map((value, i) => (
            <VerticalCheckbox data-cy={`age-checkbox-${i}`} key={i} value={value.value}>
              {value.label}
            </VerticalCheckbox>
          ))}
        </Checkbox.Group>
      </FormField>
    )
  }
}
