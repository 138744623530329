import React, { Component } from "react";
import styled from "styled-components";
import union from "lodash/union";
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Button, Tag, Typography } from "antd";
import { FieldArray } from "formik";
import { Input } from "formik-antd";

const { Text } = Typography;

const HashTagsWrapper = styled("div")`
  &&& {
    margin-bottom: 30px;
  }
`;

const FieldLabel = styled(Text)`
  && {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`;

const HashTag = styled(Tag)`
  && {
    margin-top: 8px;
  }
`;

export default class HashTags extends Component {
  state = {
    inputText: "",
  };

  handleChange = (value, push) => {
    const {
      values: { hashtags = [] },
    } = this.props;

    let newValue = value.trim();

    newValue = value.replace(/#/g, "");

    if (newValue !== "" && typeof hashtags !== "undefined") {
      const index = hashtags.indexOf(newValue);

      if (index === -1) {
        if (newValue.indexOf(",") > -1) {
          let finalArray = union(hashtags, newValue.split(","));
          push(finalArray);
        } else {
          push(newValue);
        }
        this.setState({
          inputText: "",
        });
      }
    }
  };

  renderItemValue = value => {
    return `#${value.charAt(0).toUpperCase() + value.slice(1)}`;
  };

  render() {
    const {
      values: { hashtags = [], distribution_platforms = [] },
    } = this.props;

    return <>
      {distribution_platforms.indexOf("TikTok") > -1 ? (
        <FieldArray
          name={`hashtags`}
        >
          {({ insert, remove, push }) => (
            <HashTagsWrapper>
              <FieldLabel>
                Which hashtags would you like your influecer(s) to use:
              </FieldLabel>
              <Row gutter={8}>
                <Col span={22}>
                  <Input
                    data-cy="hashtag-input"
                    size="large"
                    placeholder="Enter words without spaces or #’s"
                    onKeyDown={e => {
                      if (e.which === 32) return false;
                    }}
                    onChange={e => {
                      this.setState({
                        inputText: e.target.value.replace(/\s/g, ""),
                      });
                    }}
                    value={this.state.inputText}
                  />
                </Col>
                <Col span={2}>
                  <Button
                    onClick={() =>
                      this.handleChange(this.state.inputText, push)
                    }
                    type="primary"
                    size="large"
                  >
                    Add
                  </Button>
                </Col>
              </Row>

              {typeof hashtags !== "undefined" &&
                hashtags.length > 0 &&
                hashtags.map((data, index) => {
                  return (
                    <HashTag key={index}>
                      {this.renderItemValue(data)}{" "}
                      <CloseOutlined onClick={() => remove(index)} />
                    </HashTag>
                  );
                })}
            </HashTagsWrapper>
          )}
        </FieldArray>
      ) : null}
    </>;
  }
}
