// @ts-nocheck
import React, { useEffect } from 'react'
import { FormikProps } from 'formik'
import Loading from 'src/shared/Loading'
import { Box } from 'src/components/Core/Box'
import { Row, Col } from 'antd'
import { Field } from 'src/components/Forms/Field'
import getFormattedTags from 'src/utils/formattedTags'
import { FormikCheckbox } from 'src/components/Forms/Checkbox'
import { CheckboxStyle } from 'src/scenes/admins/CampaignsList/Wrapper'
import { FormikSelect } from 'src/components/Forms/Select'
import { getSelectedTags } from './utils'
import styled from 'styled-components'

interface Props {
  tags: any[]
  loading: boolean
  formProps: FormikProps<any>
  setModalVisible?: Function
}

const StyledSelect = styled(FormikSelect)`
  .ant-select-selection__choice {
    display: none;
  }
`

const Tags = ({tags, loading, formProps, setModalVisible}: Props) => {
  let tagData = getFormattedTags(tags)
  let selectedTags = getSelectedTags(formProps, tagData)

  useEffect(() => {
    formProps.handleSubmit()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formProps.values.tags])

  return(
    <Box background="white" border="1px solid #DCDCDC" borderRadius={4} px={3} py={2}>
      <Row>
        <Col md={6} sm={24} xs={24}>
          <Field
            label="Campaign Tags:"
            color="black"
            isBeside={true}
            id="tags"
          >
            <StyledSelect
              name="tags"
              data={tagData}
              local
              borderRadius={3}
              borderColor="grey5"
              size="small"
              mode="multiple"
            />
          </Field>
          {selectedTags}
        </Col>
        <Col md={18} sm={24} xs={24}>
          <Box pl={4} pt={4}>
            <Loading spinning={loading}>
              <Field
                mb={20}
                color="black"
                isBeside={true}
                id="tags"
                // onChange={e => formProps.handleSubmit()}
              >
                <FormikCheckbox name="tags" data={tagData} style={CheckboxStyle} />
              </Field>
            </Loading>
          </Box>
        </Col>
      </Row>
    </Box>
  )
}

export default Tags;
