// @ts-nocheck
import React from "react"
import { GiftingCampaignListItem } from "."
import {
  GiftingCampaignFragmentFragment,
  useDeleteGiftingCampaignMutation
} from "src/generated/ApolloComponents"

interface Props {
  campaign: GiftingCampaignFragmentFragment
  reloadRecords: Function
}

export const GiftingCampaignItemContainer = ({ reloadRecords, campaign }: Props) => {
  const [deleteCampaign] = useDeleteGiftingCampaignMutation()

  const deleteDraft = async () => {
    await deleteCampaign({
      variables: {
        campaignId: campaign.id
      }
    })

    reloadRecords()
  }

  return <GiftingCampaignListItem deleteDraft={deleteDraft} campaign={campaign} />
}
