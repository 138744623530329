import React, { Component } from "react";
import FormField from "../FormField";
import { Input } from "formik-antd";

export default class TrackingLink extends Component {
  render() {
    const { name, values } = this.props;

    return values.link_tracked ? (
      <FormField
        name={name || "default_link"}
        label="Where should we direct people to if applicable"
      >
        <Input
          data-cy="tracking-link-title"
          size="large"
          placeholder="https://google.com"
        />
      </FormField>
    ) : null;
  }
}
