// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { UpdateCampaignGroupFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import { Card } from "antd";
import Container from "src/shared/Container";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  GroupTitle,
  Company,
  Brands,
} from "src/components/Modules/Campaign/Fields";

interface UpdateInfluencerFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  updateCampaignGroupSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  getCompanies: Function;
  getBrands: Function;
}

export const UpdateCampaignGroupFormWrapper = styled.div`
  width: 100%;
`;

const UpdateCampaignGroupForm: React.FC<UpdateInfluencerFormProps> = ({
  initialValues,
  isSubmitting,
  updateCampaignGroupSchema,
  onSubmit,
  globalErrors,
  getCompanies,
  getBrands,
}) => {
  let isAdmin = ((window as any)?.intercomSettings || {}).user_type;
  return (
    <Container>
      <Card style={{ marginBottom: 24, marginTop: 24 }}>
        <UpdateCampaignGroupFormWrapper>
          <Loading spinning={isSubmitting}>
            <GlobalErrors errors={globalErrors} />

            <Form
              initialValues={initialValues}
              schema={updateCampaignGroupSchema}
              onSubmit={(values, actions) => onSubmit(values, actions)}
            >
              {(formProps: any) => {
                return (
                  <>
                    <GroupTitle title="Group Title:" name="groupName" />
                    <Company getCompanies={getCompanies} />

                    {isAdmin === "Admin" && <Brands getBrands={getBrands} />}

                    <Box display="inherit" mt={20}>
                      <Button
                        htmlType="submit"
                        type="action"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        buttonSize="middle"
                        data-cy="campaign-group-update-button"
                      >
                        Update
                      </Button>
                    </Box>
                  </>
                );
              }}
            </Form>
          </Loading>
        </UpdateCampaignGroupFormWrapper>
      </Card>
    </Container>
  );
};

export { UpdateCampaignGroupForm, UpdateCampaignGroupFormContainer };
