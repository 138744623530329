import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class AndroidId extends Component {
  render() {
    const { name, values } = this.props

    return values.integration_partner ? (
      <FormField name={name || "android_id"} label="Android app identifier">
        <Input data-cy="android-id-input" size="large" placeholder="com.android.android" />
      </FormField>
    ) : null
  }
}
