// @ts-nocheck
import { DataSourcePlatform } from "src/generated/ApolloComponents";
import map from "lodash/map";
import numeral from "numeral";
import { exportCSVFile } from "src/utils/exportCSVFile";

export function getFilterData(data) {
  let filters = {} as any;

  if (data?.platformType) {
    filters["platformType"] = data?.platformType;
  }

  if (data?.searchInput) {
    filters["searchInput"] = data?.searchInput;
  }

  if (
    data?.metricSelect &&
    data?.platformType === DataSourcePlatform.Bytesights
  ) {
    filters["metricSelect"] = data?.metricSelect;
  }

  if (
    (data?.campaignIds || []).length > 0 &&
    data?.platformType === DataSourcePlatform.Fanbytes
  ) {
    filters["campaignIds"] = map(data?.campaignIds, Number);
  }

  if (
    (data?.tagIds || []).length > 0 &&
    data?.platformType === DataSourcePlatform.Fanbytes
  ) {
    filters["tagIds"] = map(data?.tagIds, Number);
  }

  if (data?.followers) {
    let container = {} as any;
    const value = data?.followers;
    container[value?.gt ? "gt" : "lt"] = Number(
      value?.gt ? value?.gt : value?.lt
    );
    filters["followers"] = container;
  }

  if (data?.hearts) {
    let container = {} as any;
    const value = data?.hearts;
    container[value?.gt ? "gt" : "lt"] = Number(
      value?.gt ? value?.gt : value?.lt
    );
    filters["hearts"] = container;
  }

  if (data?.price) {
    let container = {} as any;
    const value = data?.price;
    container[value?.gt ? "gt" : "lt"] = Number(
      value?.gt ? value?.gt : value?.lt
    );
    filters["price"] = container;
  }

  if (data?.includeEstimatedCosts) {
    filters["includeEstimatedCosts"] = JSON.parse(data?.includeEstimatedCosts);
  }

  if (
    data?.audienceLocation &&
    data?.platformType !== DataSourcePlatform.Bytesights
  ) {
    let container = {} as any;
    const value = data?.audienceLocation;
    container["key"] = value?.key;

    let obj = {} as any;

    obj[value?.value?.gt ? "gt" : "lt"] = Number(
      value?.value?.gt ? value?.value?.gt : value?.value?.lt
    );

    container["value"] = obj;
    filters["audienceLocation"] = container;
  }

  if (
    data?.audienceGender &&
    data?.platformType !== DataSourcePlatform.Bytesights
  ) {
    let container = {} as any;
    const value = data?.audienceGender;
    container["key"] = value?.key;

    let obj = {} as any;

    obj[value?.value?.gt ? "gt" : "lt"] = Number(
      value?.value?.gt ? value?.value?.gt : value?.value?.lt
    );

    container["value"] = obj;
    filters["audienceGender"] = container;
  }

  if (
    data?.audienceAge &&
    data?.platformType !== DataSourcePlatform.Bytesights
  ) {
    let container = {} as any;
    const value = data?.audienceAge;
    container["key"] = value?.key;

    let obj = {} as any;

    obj[value?.value?.gt ? "gt" : "lt"] = Number(
      value?.value?.gt ? value?.value?.gt : value?.value?.lt
    );

    container["value"] = obj;
    filters["audienceAge"] = container;
  }

  if (data?.page) {
    filters["page"] = Number(data?.page);
  }

  return filters;
}

export function formatExportCSVData(platformType, accountData) {
  var itemsFormatted = [] as Array<any>;
  let headers = {};

  if (platformType === DataSourcePlatform.Fanbytes) {
    headers = {
      username: "Username",
      influencerName: "Influencer",
      platform: "Platform",
      format: "Format",
      campaignCount: "Campaign Count",
      engCTR: "Eng./CTR",
      price: "Price p/p",
    };

    accountData.forEach(item => {
      itemsFormatted.push({
        username: item?.username,
        influencerName: item?.influencerName,
        platform: item?.platform,
        format: item?.format,
        campaignCount: item?.campaignCount,
        engCTR: parseFloat(item?.engCTR).toFixed(2),
        price: item?.price ? numeral(item?.price / 100).format("0,0[.]00") : "",
      });
    });
  } else if (platformType === DataSourcePlatform.Bytesights) {
    headers = {
      username: "Username",
      platform: "Platform",
      avgLikes: "Avg. Likes",
      avgViews: "Avg. Views",
      avgComments: "Avg. Comments",
      followers: "Followers",
      engCTR: "Eng./CTR",
    };

    accountData.forEach(item => {
      itemsFormatted.push({
        username: item?.username,
        platform: item?.platform,
        avgLikes: item?.avgLikes ? numeral(item?.avgLikes).format("0,0") : "-",
        avgViews: item?.avgViews ? numeral(item?.avgViews).format("0,0") : "-",
        avgComments: item?.avgComments
          ? numeral(item?.avgComments).format("0,0")
          : "-",
        followers: item?.followers
          ? numeral(item?.followers).format("0,0")
          : "-",
        engCTR: parseFloat(item?.engCTR).toFixed(2),
      });
    });
  }

  var fileTitle = "accounts";

  exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
}
