import React, { Component } from "react";
import { connect } from "react-redux";
import FormField from "../FormField";
import { connect as FormikConnect } from "formik";
import SearchSelect from "src/shared/SearchSelect";
import { BrandList as $BrandList } from "src/ducks/DataList";

const BrandList = $BrandList("AccountBrandList");

export class Brand extends Component {
  componentDidUpdate(prevProps) {
    const {
      formik: { setFieldValue },
      companyId,
    } = this.props;

    if (prevProps.companyId !== companyId) {
      if (typeof this.search !== "undefined") {
        this.search.fetchSearch();
        setFieldValue("notified_brand_ids", []);
      }
    }
  }

  render() {
    const { companyId, loadBrands } = this.props;
    return (
      <FormField
        name={this.props.notified_brand_ids || "notified_brand_ids"}
        label="Who should receive notifications?"
      >
        <SearchSelect
          ref={ref => (this.search = ref)}
          data-cy="campaign-brand"
          loadData={value =>
            loadBrands({ search: value, company: companyId }).then(
              res => res.value.data.data
            )
          }
          optionGenerator={value => ({
            value: value.id,
            label: value.name,
          })}
          size="large"
          placeholder="Select..."
          mode="multiple"
        />
      </FormField>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loadBrands: BrandList.loadMany,
};

export default FormikConnect(
  connect(mapStateToProps, mapDispatchToProps)(Brand)
);
