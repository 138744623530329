import { countries } from "country-data";

export default function formattedCountryData(locations) {
  if (typeof locations === "string")
    return countries[locations] ? countries[locations].name : null;

  return locations
    ? locations
        .map(x => (countries[x] ? countries[x].name : null))
        .filter(x => x !== null)
        .join(", ")
    : null;
}
