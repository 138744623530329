import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { default as TableManagerDuck } from "src/ducks/TableManager";
import FilterModal from "../TableManager/FilterModal";
import { withRouter } from "react-router-dom";
import qs from "qs";
import { Button, Tag } from "antd";
import { show } from "redux-modal";
import isEmpty from "lodash/isEmpty";
import ColumnsModal from "../TableManager/ColumnsModal";
import TableContainer from "./TableContainer";

export class Table extends Component {
  componentDidMount() {
    const {
      addFilter,
      updateColumns,
      columns,
      withUrl,
      initialFilters,
      location,
    } = this.props;

    if (withUrl) {
      const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
      addFilter({ ...parsed, ...initialFilters, per_page: 20 });
    } else {
      addFilter({ ...initialFilters, per_page: 20 });
    }

    this.timer = null;

    updateColumns(
      Object.keys(columns).map(key => ({
        key,
        dataIndex: columns[key].dataIndex,
        name: columns[key].title,
        visible: columns[key].visible,
      }))
    );
  }

  componentDidUpdate(prevProps) {
    const { history, filters, loadData, withUrl } = this.props;

    if (prevProps.filters !== filters) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        loadData(filters).then(
          () =>
            withUrl &&
            history.push({
              search: qs.stringify(filters),
            })
        );
      }, 500);
    }
  }

  render() {
    const {
      columns,
      tableColumns,
      addFilter,
      name,
      show,
      filterOptions,
      loading,
      title,
      removeFilter,
      pagination,
      data,
      extra,
      filters,
      style,
      exportData,
      scroll,
      ...rest
    } = this.props;

    return (
      <TableContainer style={style}>
        <TableContainer.Header>
          <TableContainer.Title>{title}</TableContainer.Title>
          <TableContainer.Actions>
            {exportData ? (
              <Button key="export" onClick={exportData}>
                Export CSV
              </Button>
            ) : (
              ""
            )}

            {Object.keys(filters).map(
              key =>
                filters[key] &&
                filterOptions[key] && (
                  <Tag key={key} closable onClose={() => removeFilter(key)}>
                    {filterOptions[key].label}
                  </Tag>
                )
            )}

            {!isEmpty(filterOptions) && (
              <Button
                data-cy={`${name}-filters-button`}
                onClick={() =>
                  show("FilterModal", {
                    filterOptions: filterOptions,
                    moduleName: name,
                  })
                }
              >
                Add Filter
              </Button>
            )}

            <Button
              data-cy={`${name}-columns-button`}
              onClick={() => show("ColumnsModal", { moduleName: name })}
            >
              Manage Columns
            </Button>

            {extra}
          </TableContainer.Actions>
        </TableContainer.Header>

        <TableContainer.Table
          moduleName={name}
          columns={columns}
          data={data}
          name={name}
          loading={loading}
          pagination={pagination}
          scroll={scroll}
          {...rest}
        />

        <FilterModal />
        <ColumnsModal />
      </TableContainer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const Manager = TableManagerDuck(props.name);

  return {
    filters: Manager.selectFilters(state),
    tableColumns: Manager.selectColumnKeys(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const Manager = TableManagerDuck(ownProps.name);

  return bindActionCreators(
    {
      addFilter: Manager.addFilter,
      updateColumns: Manager.updateColumns,
      removeFilter: Manager.removeFilter,
      show,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Table));
