// @ts-nocheck
import React from "react";
import ReactVideoPlayer from "src/components/Modules/ReactVideoPlayer";

interface Props {
  videoUrl: string;
  getRef?: Function;
  isPlay?: boolean;
  onPlay?: Function;
  onPause?: Function;
}

const DraftYoutube: React.FC<Props> = ({
  videoUrl,
  getRef,
  isPlay,
  onPlay,
  onPause,
}) => {
  return (
    <ReactVideoPlayer
      videoUrl={videoUrl}
      isPlay={isPlay}
      shouldShowControls={true}
      getRef={getRef}
      onPlay={onPlay}
      onPause={onPause}
    />
  );
};

export default DraftYoutube;
