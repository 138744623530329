import React, { Component } from "react";
import { Button } from "antd";

export default class SubmitButton extends Component {
  render() {
    const { isSubmitting, text, ...rest } = this.props;

    return (
      <Button
        type="primary"
        size="large"
        loading={isSubmitting}
        disabled={isSubmitting}
        htmlType="submit"
        {...rest}
      >
        {text || "Continue"}
      </Button>
    );
  }
}
