// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import { CampaignsTableContainer } from "./Search";
import { TagsTableContainer } from "./Tags";
import { TrackContainer } from "./Track";
import { DiscoverCategory } from "src/generated/ApolloComponents";

const DiscoverTabsBar = styled(Tabs)`
  && {
    .ant-tabs-nav-wrap {
      padding: 0 24px;
      background: #ffffff;
      border-bottom: 2px solid #e5e5e5;
      margin-bottom: 0px;
    }
    .ant-tabs-nav-list .ant-tabs-tab {
      color: #a0a0a0;
      font-size: 16px;
    }
  }
`;

const DiscoverTab = () => {
  const [activeKey, setActiveKey] = useState("1");

  const items = [
    { label: 'Search', key: '1', children: <CampaignsTableContainer /> },
    { label: 'Track', key: '2', children: <TrackContainer activeKey={activeKey} /> },
  ];

  const tagContainers = () => {
    return [
      DiscoverCategory.Students,
      DiscoverCategory.Gaming,
      DiscoverCategory.Sports,
      DiscoverCategory.Fitness,
      DiscoverCategory.Food,
      DiscoverCategory.BusFin,
      DiscoverCategory.Beauty
    ].map((category, index) => { return {
      label: category,
      key: String(index + 3),
      children: <TagsTableContainer category={category} />
    }})
  }

  return (
    <DiscoverTabsBar
      items={[...items, ...tagContainers()]}
      activeKey={activeKey}
      onChange={activeKey => setActiveKey(activeKey)}
    >
    </DiscoverTabsBar>
  );
};

export default DiscoverTab;
