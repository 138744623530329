// @ts-nocheck
import React from "react";
import Button from "src/components/Core/Button";
import { PageHeader } from "antd";
import { useInfluencersListDetails } from "../InfluencersListProvider";

interface Props {
  title: string;
  exportData: boolean;
  openAccountModal: () => void;
}

const HeroActions: React.FC<Props> = ({
  title,
  exportData,
  openAccountModal,
}) => {
  const { actions } = useInfluencersListDetails();

  return (
    <PageHeader
      title={title}
      subTitle={``}
      extra={[
        exportData ? (
          <Button
            key="export"
            buttonSize="small"
            onClick={() => actions?.exportAccounts()}
          >
            Export CSV
          </Button>
        ) : (
          ""
        ),
        <Button
          type="action"
          buttonSize="small"
          key="create"
          data-cy="create-hero-button"
          onClick={openAccountModal}
        >
          Create new Account
        </Button>,
      ]}
    />
  );
};

export default HeroActions;
