// @ts-nocheck
import { string, object } from "yup";

export default object().shape({
  platform: string().required("Please select a platform"),
  email: string().email().required("Please tell use your email"),
  fee: string().required("Please select the fee"),
  username: string().matches(/^[a-zA-Z0-9_.]+$/, "Only letters, numbers, underscores and periods are allowed")
                    .required("Please enter your username"),
});
