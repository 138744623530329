// @ts-nocheck
import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const VideoPlayer = styled(ReactPlayer)`
  && {
    video {
      display: block;
      height: auto;
      width: 100%;
    }
  }
`;

interface Props {
  videoUrl: string;
  isPlay: boolean;
  onVideoLoad?: (duration: any) => void;
  style?: React.CSSProperties;
}

const ReactVideoPlayer: React.FC<Props> = ({
  videoUrl,
  isPlay,
  onVideoLoad,

  style,
}) => {
  return (
    <VideoPlayer
      style={style}
      width="100%"
      height="100%"
      url={videoUrl}
      muted
      playing={isPlay}
      onDuration={duration => {
        onVideoLoad && onVideoLoad(duration);
      }}
      controls={false}
    />
  );
};

export default ReactVideoPlayer;
