// @ts-nocheck
import React from "react";
import { location_targets } from "src/constants/campaign";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { RemoveIcon } from "../../../Wrapper";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Locations: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if ((formProps?.values?.locations || []).length > 0) {
    isVisible = true;
  }

  return (
    <Field
      label="Location:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("locations", []);
              clearFilterKey("locations");
            }}
            style={{ marginTop: "-1px", marginLeft: "10px" }}
          />
        )
      }
      id="countryBreakdown"
    >
      <FormikSelect
        name="locations"
        data={location_targets}
        local
        borderRadius={3}
        borderColor="grey5"
        size="small"
        mode="multiple"
      />
    </Field>
  );
};

export default Locations;
