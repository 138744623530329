// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Divider } from "antd";
import compact from "lodash/compact";
import sumBy from "lodash/sumBy";
import orderBy from "lodash/orderBy";
import styled from "styled-components";
import BreakdownData from "src/components/Modules/Influencer/Profile/AccountList/AccountItem/BreakdownData";

const AccountDivider = styled(Divider)`
  &&& {
    margin: 10px 0px 0px 0px !important;
  }
`;

export const AccountBreakdown = styled.div`
  transition: all 0.5s ease-in-out;
  z-index: 999;
  position: relative;
`;

interface Props {
  account: any;
}

const AccountStats: React.FC<Props> = ({ account }) => {
  const colors = ["#69C0FF", "#1890FF", "#91D5FF", "#BAE7FF"];

  let ageBreakdownData = account?.ageBreakdown?.manual || {};

  const ageBreakdownArray = compact(
    Object.keys(ageBreakdownData).map(function(key, i) {
      if (Number(ageBreakdownData[key]) === 0) {
        return null;
      }
      return {
        key: key,
        value: Number(ageBreakdownData[key]),
      };
    }) || []
  );

  let ageSum = sumBy(ageBreakdownArray, function(age: any) {
    return age?.value;
  });

  if (ageSum < 100) {
    let otherAgePercentage = {
      key: "No Data",
      value: Number(100 - ageSum),
    };

    ageBreakdownArray.push(otherAgePercentage);
  }

  let newAgeBreakdownArray = ageBreakdownArray.map((item: any, index) => {
    const newItem = { ...item };
    newItem.color = colors[index];
    return newItem;
  });

  let genderBreakdownData = account?.genderBreakdown?.manual || {};

  const genderBreakdownArray = compact(
    Object.keys(genderBreakdownData).map(function(key, i) {
      if (Number(genderBreakdownData[key]) === 0) {
        return null;
      }
      return {
        key: key,
        value: Number(genderBreakdownData[key]),
      };
    }) || []
  );

  let genderSum = sumBy(genderBreakdownArray, function(gender: any) {
    return gender?.value;
  });

  if (genderSum < 100) {
    let otherGenderPercentage = {
      key: "No Data",
      value: Number(100 - genderSum),
    };

    genderBreakdownArray.push(otherGenderPercentage);
  }

  let newGenderBreakdownArray = genderBreakdownArray.map((item: any, index) => {
    const newItem = { ...item };
    newItem.color = colors[index];
    return newItem;
  });

  let locationBreakdownData = account?.locationBreakdown?.manual || ({} as any);

  // delete locationBreakdownData.other;

  const locationBreakdownArray = compact(
    Object.keys(locationBreakdownData).map(function(key, i) {
      if (Number(locationBreakdownData[key]) === 0) {
        return null;
      }
      return {
        key: key,
        value: Number(locationBreakdownData[key]),
      };
    }) || []
  );

  let newLocationBreakdown = [] as Array<any>;

  if (locationBreakdownArray.length > 0) {
    let sortLocationBreakdownArray = orderBy(
      locationBreakdownArray,
      ["value"],
      ["desc"]
    );

    let topTwoLocationBreakdown = sortLocationBreakdownArray.slice(0, 2);

    let locationSum = sumBy(topTwoLocationBreakdown, function(location: any) {
      return location?.value;
    });

    if (locationSum < 100) {
      let otherLocationPercentage = {
        key: "other",
        value: Number(100 - locationSum),
      };

      topTwoLocationBreakdown.push(otherLocationPercentage);
    }

    newLocationBreakdown = topTwoLocationBreakdown.map((item: any, index) => {
      const newItem = { ...item };
      newItem.color = colors[index];
      return newItem;
    });
  }

  return (
    <Box mt={10}>
      {((newGenderBreakdownArray || []).length > 0 ||
        (newAgeBreakdownArray || []).length > 0 ||
        (newLocationBreakdown || []).length > 0) && (
        <AccountBreakdown>
          {(newGenderBreakdownArray || []).length > 0 && (
            <BreakdownData
              title="Audience Gender:"
              data={newGenderBreakdownArray}
            />
          )}

          {(newAgeBreakdownArray || []).length > 0 && (
            <BreakdownData title="Audience Age:" data={newAgeBreakdownArray} />
          )}

          {(newLocationBreakdown || []).length > 0 && (
            <BreakdownData
              title="Audience Location:"
              data={newLocationBreakdown}
            />
          )}

          <AccountDivider />
        </AccountBreakdown>
      )}
    </Box>
  );
};

export default AccountStats;
