// @ts-nocheck
import React, { useState } from "react";
import { AgreementDetail } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useSignInitialContractMutation } from "src/generated/ApolloComponents";

interface Props {
  cashoutData: any;
  claimCashoutId: any;
  goNext: () => void;
  goBack: () => void;
  index: number;
}

export const AgreementDetailContainer: React.FC<Props> = ({
  cashoutData,
  claimCashoutId,
  goNext,
  goBack,
  index,
}) => {
  const [contractSigned, setContractSigned] = useState(false);

  const [
    signInitialContractMutation,
    { loading, error },
  ] = useSignInitialContractMutation();

  const onSubmit = async () => {
    try {
      const res = await signInitialContractMutation({
        variables: {
          claimCashoutId: claimCashoutId,
        },
      });

      if (res?.data?.signInitialContract?.id) {
        goNext();
      }
    } catch (e) {}
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <AgreementDetail
      isSubmitting={loading}
      onSubmit={onSubmit}
      globalErrors={globalErrors}
      cashoutData={cashoutData}
      contractSigned={contractSigned}
      setContractSigned={() => {
        setContractSigned(!contractSigned);
      }}
      goBack={goBack}
      index={index}
    />
  );
};
