// @ts-nocheck
import React, { useState, useEffect } from "react";
import { FormikHelpers as FormikActions } from "formik";
import Form from "src/shared/Form";
import Loading from "src/shared/Loading";
import { object } from "yup";
import { message } from "antd";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import compact from "lodash/compact";
import { FeedbackFieldItem } from "src/shared/InvitationModal/DraftsFeedback/components";
import { useUpdateDraftFeedbackMutation } from "src/generated/ApolloComponents";
import { FeedbackNote } from "./components";

interface Props {
  draft: any;
  loadMoreInvitations: Function;
  getRef?: Function;
  closeFeedbackForm?: Function;
  timestamp: string;
  localFeedbacks?: Array<any>;
}

interface FormValues {
  brandFeedback: Array<any>;
  status: string;
}

const BrandFeedbackForm: React.FC<Props> = props => {
  const {
    draft,
    loadMoreInvitations,
    closeFeedbackForm,
    getRef,
    timestamp,
    localFeedbacks = [],
  } = props;

  const [errors, setErrors] = useState([]);

  let brandFeedback = [] as any;

  (draft?.brand_feedback || []).forEach((item, index) => {
    let startPos = item.lastIndexOf("[[[");
    let endPos = item.lastIndexOf("]]]");
    let time = "";
    let feedback = "";

    if (startPos > -1 && endPos > -1) {
      time = item.substring(startPos + 3, endPos);
      feedback = item.substring(0, startPos);
    } else {
      time = "";
      feedback = item;
    }

    let mainTime = (time || "").split(":");

    brandFeedback.push({
      feedback,
      min: mainTime?.[0] || "",
      sec: mainTime?.[1] || "",
      isChecked: time !== "" ? true : false,
    });
  });

  localFeedbacks.map(item => {
    let item2: any = find(brandFeedback, function(obj) {
      return isEqual(item, obj);
    });

    if (!item2) {
      brandFeedback.push({
        ...item,
        min: item.min || "",
        sec: item.sec || "",
      });
    }
    return null;
  });

  let splitTimestamp = (timestamp || "").split(":");

  let initialBrandFeedback = [
    {
      feedback: "",
      min: splitTimestamp?.[1] || "",
      sec: splitTimestamp?.[2] || "",
      isChecked: true,
    },
  ];

  let initialValues = {
    brandFeedback:
      brandFeedback.length > 0
        ? [...brandFeedback, ...initialBrandFeedback]
        : initialBrandFeedback,
  };

  const BrandFeedbackSchema = object().shape({});

  const [
    updateDraftFeedbackMutation,
    { loading, error },
  ] = useUpdateDraftFeedbackMutation();

  useEffect(() => {
    const allErrors: any =
      error &&
      error.graphQLErrors.map(function({ message }, i) {
        return message;
      });

    setErrors(allErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    try {
      let brandFeedback = values.brandFeedback.map(function(el) {
        //return el !== "";

        if (!!el.feedback) {
          if (el.isChecked && (!!el.min || !!el.sec)) {
            return `${el.feedback}[[[${el.min}:${el.sec}]]]`;
          } else {
            return el.feedback;
          }
        }
        return null;
      });

      const formData = {
        draftId: draft?.id.toString() as string,
        status: "redraft",
        brandFeedback: compact(brandFeedback),
      };

      const res = await updateDraftFeedbackMutation({
        variables: formData,
      });

      if (res) {
        message.success("Draft feedback added successfully!");
        closeFeedbackForm && closeFeedbackForm();
        loadMoreInvitations();
      }
    } catch (e) {
      setErrors([e.message] as any);
      closeFeedbackForm && closeFeedbackForm();
      actions.setSubmitting(false);
    }
  };

  return (
    <div>
      <Loading spinning={loading}>
        <div id="brandFeedbackForm">
          <Form
            initialValues={initialValues}
            schema={BrandFeedbackSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
            errors={errors}
            ref={getRef && (getRef as any)}
          >
            {(formProps: any) => {
              return (
                <>
                  {(draft?.status === "submitted" ||
                    draft?.status === "redraft") && <FeedbackNote />}
                  <div>
                    <FeedbackFieldItem
                      name="brandFeedback"
                      placeholder="Please enter feedback..."
                      draft={{ ...draft, draftType: draft?.draft_type }}
                      userType="brand"
                    />
                    <button type="submit" style={{display: 'none'}}></button>
                  </div>
                </>
              );
            }}
          </Form>
        </div>
      </Loading>
    </div>
  );
};

export default BrandFeedbackForm;
