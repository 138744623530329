// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikCheckbox } from "src/components/Forms/Checkbox";
import { CheckboxStyle, RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  allTags: Array<any>;
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Tags: React.FC<Props> = ({ allTags, formProps, clearFilterKey }) => {
  let isVisible = false;

  if ((formProps?.values?.tags || []).length > 0) {
    isVisible = true;
  }

  let tagData = [] as any;

  tagData =
    (allTags || [])
      .filter(v => v.isDefined)
      .map(item => {
        let container = {} as any;
        container.value = item?.id;
        container.label =
          item.name === "Business & Finance" ? "Bus. & Fin." : item.name;
        return container;
      }) || [];

  return (
    <Field
      label="Tags:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("tags", []);
              clearFilterKey("tags");
            }}
            style={{ marginTop: "-1px", marginLeft: "15px" }}
          />
        )
      }
      id="tags"
    >
      <FormikCheckbox name="tags" data={tagData} style={CheckboxStyle} />
    </Field>
  );
};

export default Tags;
