// @ts-nocheck
import React, { Fragment } from "react";
import { DashboardOutlined, LineChartOutlined } from '@ant-design/icons';
import { Tooltip, Col } from "antd";
import numeral from "numeral";
import formatCurrency from "src/utils/formatCurrency";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";

interface Props {
  accountData: any;
}

const AccountInfo: React.FC<Props> = ({ accountData }) => {
  return (
    <Fragment>
      <Col span={2}>
        {accountData?.growthRate && (
          <Tooltip placement="top" title={"Growth rate"}>
            <Box textAlign="center" display="inline-flex" mt={10}>
              <Box color="blue" fontSize={14}>
                <LineChartOutlined />
              </Box>
              <Box ml="5px">
                <Typography size={14} weight="semi-bold" color="grey6">
                  {accountData?.growthRate}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        )}
      </Col>
      <Col span={2}>
        {accountData?.engagementRate && (
          <Tooltip placement="top" title={"Engagement rate"}>
            <Box textAlign="center" display="inline-flex" mt={10}>
              <Box color="green" fontSize={14}>
                <DashboardOutlined />
              </Box>
              <Box ml="5px">
                <Typography size={14} weight="semi-bold" color="grey6">
                  {numeral(accountData?.engagementRate).format("0,0.00") + "%"}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        )}
      </Col>

      <Col span={2}>
        {accountData?.estCostPerPost && (
          <Box textAlign="center">
            <Tooltip placement="top" title={"Estimated cost"}>
              <Typography size={14} weight="semi-bold" color="grey6" mt={10}>
                {formatCurrency(accountData?.estCostPerPost)}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </Col>

      <Col span={2}>
        <Box textAlign="center">
          {accountData?.actualCostPerPost && (
            <Tooltip placement="top" title="Actual cost">
              <Typography size={14} weight="semi-bold" color="black" mt={10}>
                {formatCurrency(accountData?.actualCostPerPost)}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </Col>
    </Fragment>
  );
};
export default AccountInfo;
