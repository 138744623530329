import React from 'react'
import moment from 'moment'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import Loading from 'src/shared/Loading'
import { capitalize } from 'lodash'

const LineChartComp = ({data, loading}) => {
  const max = data.length ? data.reduce((prev, current) => (prev.views > current.views) ? prev : current).views : 'auto'

  return(
    <Loading spinning={loading}>
      <LineChart width={780} height={300} data={data.reverse()} margin={{left: 10, right: 10, top: 10, bottom: 10}}>
        <Line type="monotone" dataKey="views" stroke="#A6CEE3" />
        <CartesianGrid stroke="#F0F0F0" />
        <XAxis
          axisLine={false}
          dataKey="record_at"
          style={{fontSize: "12px"}}
          dy={10}
          tickLine={false}
          tickFormatter={(tick) => moment(tick).format('DD/MM')}
        />
        <YAxis
          style={{fontSize: "12px"}}
          axisLine={false} dx={-10}
          tickLine={false}
          domain={[0, max + 2000]}
        />
        <Tooltip
          labelFormatter={(label) => moment(label).format('DD/MM/YYYY')}
          formatter={(value, name) => [value, capitalize(name)]}
        />
      </LineChart>
    </Loading>
  )
}

export default LineChartComp
