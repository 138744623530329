// @ts-nocheck
import React from 'react'
import { fees } from 'src/constants/account'
import { Field } from 'src/components/Forms/Field'
import { FormikSelect } from 'src/components/Forms/Select'

const Fee = () => {
  return (
    <Field id="fee">
      <FormikSelect
        name="fee"
        data={fees}
        local
        borderRadius={3}
        borderColor="grey5"
        size="small"
        minHeight={40}
      />
    </Field>
  );
};

export default Fee;
