import React, { Component } from "react"
import { connect } from "react-redux"
import { Form } from "formik-antd"
import { connectModal } from "redux-modal"
import ModalForm from "src/shared/ModalForm"
import withModules from "src/utils/hoc/withModules"
import { CompanyPersist } from "src/ducks/Persist"
import Validate from "./Validate"
import { CompanyName } from "src/shared/CompanyFields"

const CompanyCreate = CompanyPersist("CreateCompanyModal")

const modules = [CompanyCreate.module()]

class CreateCompanyModal extends Component {
  render() {
    const { show, create, handleHide, onCreate } = this.props

    return (
      <ModalForm
        show={show}
        onSave={create}
        afterSave={onCreate}
        validate={Validate}
        handleHide={handleHide}
        title="Create new company"
      >
        <Form data-cy="company-create-modal-form">
          <CompanyName />
        </Form>
      </ModalForm>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  create: CompanyCreate.create
}

export default withModules(
  connectModal({
    name: "CreateCompanyModal",
    destroyOnHide: false
  })(connect(mapStateToProps, mapDispatchToProps)(CreateCompanyModal))
)(modules)
