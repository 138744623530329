import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class IosId extends Component {
  render() {
    const { name, values } = this.props

    return values.integration_partner ? (
      <FormField name={name || "ios_id"} label="iOS app identifier">
        <Input data-cy="ios-input" size="large" placeholder="id8659769" />
      </FormField>
    ) : null
  }
}
