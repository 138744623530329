import { useState, useEffect } from "react";
import { ApolloError } from "apollo-boost";
import { GlobalErrorProps } from "src/components/Forms/ErrorMessage";

export function useMapGraphQLErrorsMessages(error: ApolloError | undefined) {
  const [errors, setErrors] = useState<GlobalErrorProps[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (error) {
      setErrors(
        (error?.graphQLErrors || []).map((e, i) => ({
          id: i.toString(),
          message: e.message,
        }))
      );
    } else {
      setErrors(undefined);
    }
  }, [error, setErrors]);

  return errors;
}
