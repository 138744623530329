// @ts-nocheck
import React, { Fragment } from 'react'
import CopyLink from './CopyLink'
import styled from 'styled-components'
import { Typography } from 'src/components/Core/Typography'

const StyledDiv = styled.div`
  border: 1px solid #EBEBEB;
  background: #FAFAFA;
  border-radius: 5px;
`

const Footer = () => {
  return(
    <Fragment>
      <Typography color="black1" fontSize={16} textAlign='center'>Share this brief:</Typography>
      <StyledDiv>
        <CopyLink />
      </StyledDiv>
    </Fragment>
  )
}

export default Footer;
