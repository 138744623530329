// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd";

interface Props {}

const AnalyseLoading: React.FC<Props> = () => {
  const [index, setIndex] = useState(0);

  const intervalId = useRef<any>(0);

  const analyseTextArray = [
    "Analysing profile...",
    "Analysing videos...",
    "Checking for nude content...",
    "Checking for profanity...",
  ];

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, marginTop: "5px" }} spin />
  );

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setIndex(val => (val < 3 ? val + 1 : val));
    }, 5000);
  }, []);

  useEffect(() => {
    if (index >= 3) {
      clearInterval(intervalId.current);
    }
  }, [index]);

  return (
    <Box
      background="#ffffff"
      top="0px"
      bottom="0px"
      position="fixed"
      minHeight="100%"
      minWidth="100%"
      style={{ zIndex: 999999 }}
    >
      <Box
        display="inline-flex"
        padding="5px 19px"
        background="#ffffff"
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
        borderRadius="2px"
        position="absolute"
        top="45%"
        left="45%"
        textAlign="center"
      >
        <Spin indicator={antIcon} />
        <Typography size={14} color="black2" ml={10} mt="3px">
          {analyseTextArray?.[index]}
        </Typography>
      </Box>
    </Box>
  );
};

export default AnalyseLoading;
