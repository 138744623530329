import React, { Component } from "react"
import { connect } from "react-redux"
import { createSelector } from "reselect"
import { withRouter } from "react-router-dom"
import { message } from "antd"
import { withFormik } from "formik"
import { Form, Input, SubmitButton } from "formik-antd"
import Auth from "src/ducks/Auth"
import FormField from "src/shared/FormField"
import { CompanyPersist as $CompanyPersist, CompanyPersistModule } from "src/ducks/Persist"
import withModules from "src/utils/hoc/withModules"
import { Company } from "src/ducks/Orm"

const modules = [CompanyPersistModule("CompanySettings")()]

const CompanyPersist = $CompanyPersist("CompanySettings")

class CompanyForm extends Component {
  render() {
    const { handleSubmit } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <FormField name="company_name" label="Name">
          <Input size="large" placeholder="Name" name="company_name" />
        </FormField>
        <SubmitButton size="large" type="primary" disabled={false} block>
          Update company
        </SubmitButton>
      </Form>
    )
  }
}

const EnhancedCompanyForm = withFormik({
  mapPropsToValues: ({ currentCompany }) => (currentCompany ? currentCompany.ref : {}),
  enableReinitialize: true,

  validate: (values) => {
    const errors = {}

    if (!values.company_name) {
      errors.company_name = "Fill in your company name"
    }

    return errors
  },

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props
      .updateCompany(props.currentUser.ref.company, values)
      .then(() => {
        message.success("Company updated")
        setSubmitting(false)
      })
      .catch((res) => {
        message.error("Failed to update company")
        setErrors(res.response.data.meta.errors)
        setSubmitting(false)
      })
  },

  displayName: "CompanyForm"
})(CompanyForm)

const mapStateToProps = (state) => ({
  submitting: Auth.selectLoading(state),
  currentUser: Auth.selectUser(state),
  currentCompany: createSelector(
    (state) => state.entities,
    (state) => Auth.selectUser(state).ref.company,
    Company.selectOne
  )(state)
})

const mapDispatchToProps = {
  updateCompany: CompanyPersist.update
}

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EnhancedCompanyForm))
)(modules)
