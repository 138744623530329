// @ts-nocheck
import React, { useState } from 'react'
import styled from "styled-components";
import TitleSection from './TitleSection'
import GridView from 'src/shared/GridView'
import ListView from 'src/shared/ListView'
import { Box } from 'src/components/Core/Box'
import { Droppable } from "react-beautiful-dnd";
import { Typography } from "src/components/Core/Typography";
import InfluencerModal from "src/scenes/brands/CampaignManage/FindInfluencers/InfluencerModal"
import { Pagination, Row } from 'antd'

interface Props {
  suggestions: any
  status: string
  onPageChange: Function
  handleUpdate: (account: any) => void
}

const GridScroll = styled("div")`
  overflow-y: scroll;
  flex-grow: 1;

  /* for Firefox */
  min-height: 0;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Influencers = ({status, onPageChange, ...props}: Props) => {
  const { suggestions } = props

  const [view, setView] = useState('list')

  const handleViewChange = (value: string) => {
    setView(value)
  }

  const description = () => {
    if(status === "accepted"){
      return "Here is a list of influencers we have selected for your campaign. Drag influencers you like to the right"
    } else {
      return "Here is a list of influencers we have selected for your campaign. Drag influencers you like to the right. Click an influencrs name to view more details."
    }
  }

  const PaginationComponent = () => {
    return <Row justify="center" style={{marginTop: '5px'}}>
            <Pagination
              current={suggestions?.currentPage}
              total={suggestions?.totalEntries}
              onChange={page =>
                onPageChange(prevState => {return {...prevState, page: page}})
              }
              defaultPageSize={30}
            />
          </Row>
  }

  return(
    <Box>
      <TitleSection status={status} length={suggestions?.totalEntries} view={view} handleChange={handleViewChange} />
      <Box height={58}>
        <Typography size={14} color="black">
          {description()}
        </Typography>
      </Box>
      {view === "grid" ?
        <Droppable droppableId={status} direction="horizontal">
          {provided =>(
            <GridScroll ref={provided.innerRef} {...provided.dropableProps}>
              <Box background="#F5F5F5" p={25} height={661} overflowY="scroll">
                <GridView
                  suggestions={suggestions?.suggestions || []}
                  isInnerAccountList={false}
                  menu={()=>{}}
                />
                <Row justify="center" style={{marginTop: '10px'}}>
                  {suggestions?.totalEntries && PaginationComponent()}
                </Row>
              </Box>
            </GridScroll>
          )}
        </Droppable>
        :
        <Box background="#F5F5F5" height={661} overflowY="scroll" style={{padding: '10px'}}>
          <ListView {...props} />
          <Row justify="center" style={{marginTop: '5px'}}>
            {suggestions?.totalEntries > 0 && PaginationComponent()}
          </Row>
        </Box>
      }
      <InfluencerModal />
    </Box>
  )
}

export default Influencers;
