// @ts-nocheck
import React, { Fragment } from 'react'
import moment from 'moment'
import { Row, Col, Divider } from 'antd'
import { Typography } from 'src/components/Core/Typography'

const DateSection = (campaign: any) => {

  const getDateCOl = (label: string, date: string) => {
    return(
      <Col span={12}>
        <Typography mb={0} color="black1" size={12} textAlign="center">{label}</Typography>
        <Typography color="black1" size={14} textAlign="center">{moment(date).format('DD/MM/YYYY')}</Typography>
      </Col>
    )
  }

  return(
    <Fragment>
      {window.innerWidth < 768 &&
        <Row>
          <Divider />
          <Col>
            <Row>
              {getDateCOl('Submission by:', campaign.publicDeadline)}
              {getDateCOl('Launch date:', campaign.startDate)}
            </Row>
          </Col>
        </Row>
      }
    </Fragment>

  )
}

export default DateSection;
