// @ts-nocheck
import * as React from "react";
import { useGetProfileByInfluencerIdQuery } from "src/generated/ApolloComponents";
import { useRouteMatch, useHistory } from "react-router";

interface ContextType {
  influencerId?: any;
  platformType?: any;
  isLoading: boolean;
  influencerDetails?: any;
  actions: {
    setInfluencerId: (value: any) => void;
    setInfluencerPlatformType: (value: any) => void;
    refetchInfluencer: () => void;
  };
}

const InfluencerProfileContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our influencer detail context
export function useInfluencerDetails() {
  const context = React.useContext(InfluencerProfileContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
const BaseInfluencerProfileProvider = props => {
  const match: any = useRouteMatch();
  const params: any = match?.params;

  const history: any = useHistory();

  //get influencer id
  const id = parseInt(params.id);
  const platform = params.type;

  const [influencerId, setInfluencerId] = React.useState(id);
  const [platformType, setInfluencerPlatformType] = React.useState(platform);

  const { data, loading, error, refetch } = useGetProfileByInfluencerIdQuery({
    variables: {
      influencerId: influencerId,
    },
    skip: platformType !== "fanbytes",
  });

  // when change influencer id its called
  React.useEffect(() => {
    if (platformType === "fanbytes") {
      refetch({
        influencerId: influencerId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencerId, platformType]);

  React.useEffect(() => {
    if (error) {
      history.replace({ pathname: "/influencers-list" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const refetchInfluencer = () => {
    refetch({
      influencerId: influencerId,
    });
  };

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      influencerDetails: data?.getProfileByInfluencerId,
      influencerId,
      platformType,
      isLoading: loading,
      actions: {
        setInfluencerId,
        setInfluencerPlatformType,
        refetchInfluencer,
      },
    };
    return internalVal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, influencerId, loading, platformType]);

  return (
    <InfluencerProfileContext.Provider value={value}>
      {props.children}
    </InfluencerProfileContext.Provider>
  );
};

const InfluencerProfileProvider = React.memo(BaseInfluencerProfileProvider);

export { InfluencerProfileProvider };
