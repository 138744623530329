// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";
import { AddCampaignsList } from ".";
import { useRouteMatch } from "react-router";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetCampaignsForCampaignGroupQuery,
  useAddCampaignsToCampaignGroupMutation,
} from "src/generated/ApolloComponents";
import { useCampaignGroupDetails } from "../../../CampaignGroupProvider";

type Props = {
  closeAddCampaignsModal: () => void;
  getCampaignForCampaignGroup: () => void;
};

export const AddCampaignsListContainer: React.FC<Props> = ({
  closeAddCampaignsModal,
  getCampaignForCampaignGroup,
}) => {
  const [selectedCampaigns, setSelectedCampaigns] = useState([] as Array<any>);

  const { actions } = useCampaignGroupDetails();

  const match: any = useRouteMatch();
  const params: any = match?.params;

  const [filters, setFilters] = useState({
    page: 1,
    groupId: parseInt(params?.id),
  });

  const {
    data,
    loading,
    error,
    refetch,
  } = useGetCampaignsForCampaignGroupQuery({
    variables: filters,
  });

  const allCampaigns = data?.getCampaignsForCampaignGroup;

  const [
    addCampaignsToCampaignGroupMutation,
  ] = useAddCampaignsToCampaignGroupMutation();

  const onFilterChange = async filters => {
    try {
      refetch(filters);
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onFilterChange, 1000)).current;

  useEffect(() => {
    let updatedFilters = { ...filters };

    debouncedFilterChange(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const addCampaigns = async () => {
    if ((selectedCampaigns || []).length > 0) {
      try {
        const res = await addCampaignsToCampaignGroupMutation({
          variables: {
            groupId: parseInt(params?.id),
            campaignIds: selectedCampaigns,
          },
        });
        if (res.data?.addCampaignsToCampaignGroup?.id) {
          closeAddCampaignsModal();
          getCampaignForCampaignGroup();
          actions?.refetchCampaignGroup();
        }
      } catch (e) {}
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <AddCampaignsList
      allCampaigns={allCampaigns}
      globalErrors={globalErrors}
      loading={loading}
      onFilter={(key, value) => {
        filters[key] = value;
        setFilters({ ...filters });
      }}
      filters={filters}
      closeAddCampaignsModal={closeAddCampaignsModal}
      selectedCampaigns={selectedCampaigns}
      setSelectedCampaigns={value => setSelectedCampaigns(value)}
      addCampaigns={() => addCampaigns()}
    />
  );
};
