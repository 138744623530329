// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { NewAccountFormContainer } from "./NewAccountForm";

const AccountModal = styled(Modal)`
  &&& {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.213287);
    border-radius: 6px;
  }
`;

interface Props {
  showNewAccountForm?: boolean;
  closeAccountModal?: () => void;
  onSucessAddNewAccount: () => void;
}

const NewAccountModal = ({
  closeAccountModal,
  showNewAccountForm,
  onSucessAddNewAccount,
}: Props) => {
  return (
    <AccountModal
      title="Create new Account"
      style={{
        maxWidth: 500,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showNewAccountForm}
      onCancel={closeAccountModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <NewAccountFormContainer
        closeAccountModal={closeAccountModal}
        onSucessAddNewAccount={onSucessAddNewAccount}
      />
    </AccountModal>
  );
};

export default NewAccountModal;
