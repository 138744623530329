// @ts-nocheck
import React from "react";
import { UpdateInfluencerForm } from ".";
import { string, object } from "yup";
import { message } from "antd";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useInfluencerDetails } from "../../../InfluencerProfileProvider";
import {
  useGetInfluencerByIdQuery,
  useGetAllAgenciesQuery,
  useUpdateInfluencerByIdMutation,
} from "src/generated/ApolloComponents";

interface Props {
  closeInfluencerModal: () => void;
}

interface UpdateInfluencerFormValues {
  id: number;
  name: string;
  email: string;
  paypalEmail: string;
  phoneNumber: string;
  address: string;
  vatNumber: string;
  agencyId: number;
  managed: boolean;
  gender: string;
  age: string;
  location: string;
  punctuality: number;
  professionalism: number;
  quality: number;
  flags: string[];
}

export const UpdateInfluencerFormContainer: React.FC<Props> = props => {
  const { actions: influencerAction, influencerId } = useInfluencerDetails();

  const agency = useGetAllAgenciesQuery({
    skip: true,
  });

  const influencer = useGetInfluencerByIdQuery({
    variables: {
      influencerId: influencerId,
    },
  });

  const influencerData = influencer?.data?.getInfluencerById;

  const [
    updateInfluencerByIdMutation,
    updateInfluencer,
  ] = useUpdateInfluencerByIdMutation();

  const UpdateInfluencerSchema = object().shape({
    name: string()
      .nullable()
      .required("Please enter a name"),
    email: string()
      .nullable()
      .required("Please enter an email"),
    phoneNumber: string().nullable(),
    paypalEmail: string().nullable(),
    address: string().nullable(),
    vatNumber: string().nullable(),
  });

  let initialValues = {
    id: influencerData?.id,
    name: influencerData?.name || "",
    email: influencerData?.email || "",
    paypalEmail: influencerData?.payoutDetails?.paypalEmail || "",
    phoneNumber: influencerData?.phoneNumber || "",
    address: influencerData?.detail?.address || "",
    vatNumber: influencerData?.detail?.vatNumber || "",
    agencyId: influencerData?.agencyId || null,
    managed: influencerData?.detail?.managed || false,
    gender: influencerData?.detail?.gender?.[0] || "",
    age: influencerData?.detail?.age?.[0] || "",
    location: influencerData?.detail?.location?.[0] || "",
    punctuality: influencerData?.detail?.rating?.punctuality || null,
    professionalism: influencerData?.detail?.rating?.professionalism || null,
    quality: influencerData?.detail?.rating?.quality || null,
    flags: influencerData?.detail?.flags || [],
  };

  const onSubmit = async (
    values: UpdateInfluencerFormValues,
    actions: FormikActions<UpdateInfluencerFormValues>
  ) => {
    try {
      const formData = {
        ...values,
        age: [values?.age] as Array<string>,
        gender: [values?.gender] as Array<string>,
        location: [values?.location] as Array<string>,
      };

      const res = await updateInfluencerByIdMutation({
        variables: formData,
      });

      if (res?.data?.updateInfluencerById?.id) {
        message.success("Influencer update successfully");
        influencerAction.refetchInfluencer();
        props.closeInfluencerModal();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(
    influencer?.error || updateInfluencer?.error
  );

  return (
    <UpdateInfluencerForm
      {...props}
      initialValues={initialValues}
      UpdateInfluencerSchema={UpdateInfluencerSchema}
      isSubmitting={influencer?.loading || updateInfluencer?.loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      closeInfluencerModal={props.closeInfluencerModal}
      getAgencies={agency.refetch}
    />
  );
};
