// @ts-nocheck
import * as React from "react";
import {
  useGetFilteringAccountsQuery,
  useGetAllTagsQuery,
} from "src/generated/ApolloComponents";
import { useHistory, useLocation } from "react-router";
import qs from "qs";
import { getFilterData } from "./utils";
import { useParams } from "react-router-dom";
import { getFilterParams } from "./AccountFilterModal/AccountFilter/utils"

interface ContextType {
  isLoading: boolean;
  listErrors?: any;
  allTags?: any;
  accountsListDetails?: any;
  accountsAdminListDetails?: any;
  filters?: any;
  listFilters?: any;
  containerType?: string;
  actions: {
    refetchAccounts: (value: any, cType?: string | undefined) => void;
    setFilters: (value: any) => void;
    setContainerType: (value: any) => void;
  };
}

const AccountsListContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our influencer detail context
export function useAccountsListDetails() {
  const context = React.useContext(AccountsListContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}


// Create a component that controls auth state and exposes it via
// the React Context we created.
const BaseAccountsListProvider = React.memo((props: any) => {
  const history: any = useHistory();
  const location: any = useLocation();
  const params: any = useParams();
  const campaignId = Number(params?.id)

  const parsed = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  // need to convert string and boolean value from query string
  const formatFilters = getFilterData(parsed);
  const initialFilters = {
    page: 1
  };

  if(campaignId) {
    initialFilters['campaignIdForSuggestedAccounts'] = campaignId
  }

  const [containerType, setContainerType] = React.useState()

  const [filters, setFilters] = React.useState({
    ...initialFilters,
    ...formatFilters,
  });

  const { data, loading, error, refetch } = useGetFilteringAccountsQuery({
    variables: getFilterParams(filters),
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const accountData = data?.getFilteringAccounts;


  // refetch Accounts
  const refetchAccounts = React.useCallback((filterData, cType = undefined) => {
     // remove null element from object
    let newFilterData = Object.keys(filterData)
      .filter(e => filterData[e] !== null)
      .reduce((o, e) => {
        o[e] = filterData[e];
        return o;
      }, {});

      if((containerType || cType) !== "listAccounts") {
        history.push({
          search: qs.stringify({...newFilterData}, {
            skipNulls: true,
          }),
        });
      }

    refetch(newFilterData as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[containerType])

  const tags = useGetAllTagsQuery();

  const allTags = tags?.data?.getAllTags || [];

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      allTags,
      accountsListDetails: accountData,
      isLoading: loading,
      listErrors: error,
      filters,
      containerType,
      actions: {
        refetchAccounts,
        setFilters,
        setContainerType,
      },
    };
    return internalVal;
  }, [loading, error, filters, accountData, allTags, refetchAccounts, containerType]);

  return (
    <AccountsListContext.Provider value={value}>
      {props.children}
    </AccountsListContext.Provider>
  );
});

const AccountsListProvider = React.memo(BaseAccountsListProvider);

export { AccountsListProvider };
