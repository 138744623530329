// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Table } from "src/components/Core/Table";
import Filters from "./Filters";
import { CampaignsListContainer } from "./container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import columns from "./Columns";
import withWindowResize from "src/utils/hoc/withWindowResize";

export interface FilterProps {
  searchInput: string;
  influencerId: number;
  page: number;
}

interface Props {
  allCampaings: Array<any>;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  filters: FilterProps;
  height?: number; // from withWindowResize HOC
}

const MainCampaignsList: React.FC<Props> = ({
  allCampaings,
  loading,
  globalErrors,
  onFilter,
  filters,
  height,
}) => {
  return (
    <Box mt={10} p={15}>
      <Typography size={24} weight="semi-bold" color="black" mr={30}>
        Campaigns
      </Typography>
      <GlobalErrors errors={globalErrors} />

      <Row gutter={16}>
        <Col span={12}>
          <Typography size={12} color="black" position="absolute" mt="10px">
            {allCampaings?.length} results
          </Typography>
        </Col>
        <Col span={12}>
          <Filters
            handleChange={(key, value) => onFilter(key, value)}
            filters={filters}
          />
        </Col>
      </Row>
      <Loading spinning={loading}>
        <Table
          columns={columns}
          rowKey="index"
          dataSource={allCampaings}
          stripe={true}
          data-cy="table-CampaignsList"
          pagination={false}
          rowHover={true}
          scroll={{ y: height, x: "max-content" }}
        />
      </Loading>
    </Box>
  );
};

const CampaignsList = withWindowResize({ extraHeight: 260 })(
  MainCampaignsList
);



export { CampaignsList, CampaignsListContainer };
