import React, { Component } from "react";
import { AlignLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CompanyPersist } from "src/ducks/Persist";

const ShowCompany = CompanyPersist("AdminCompanyShow");

export class SideMenu extends Component {
  render() {
    const { company, location } = this.props;

    const companyPath = `/companies/${company.id}`;

    return (
      <Menu
        mode="vertical"
        selectedKeys={[location.pathname]}
        style={{ height: "100%" }}
      >
        <Menu.Item key={companyPath}>
          <Link data-cy="company-details" to={companyPath} replace>
            <AlignLeftOutlined />
            <span>Company Details</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={companyPath + "/update"}>
          <Link data-cy="company-update" to={companyPath + "/update"} replace>
            <EditOutlined />
            <span>Update Company</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  company: ShowCompany.selectOne(state)
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideMenu)
);
