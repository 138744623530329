import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardLayout from "../../layouts/DashboardLayout";
import PageHero from "src/shared/PageHero";
import AccountsTotalList from "./AccountsTotalList";
import Container from "src/shared/Container";

export class Dashboard extends Component {
  render() {
    return (
      <DashboardLayout title="Dashboard">
        <PageHero title="Dashboard" />
        <Container>
          <AccountsTotalList />
        </Container>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
