import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "src/shared/Table";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import { Select } from "formik-antd";
import TableManager from "src/ducks/TableManager";
import { InvitationList as $InvitationList } from "src/ducks/DataList";
import { statuses } from "src/constants/invitations";
import withWindowResize from "src/utils/hoc/withWindowResize";

const InvitationTable = TableManager("AccountInvitationList");
const InvitationList = $InvitationList("AccountInvitationList");

const modules = [InvitationTable.module(), InvitationList.module()];

const columns = {
  campaign: {
    title: "Campaign",
    dataIndex: "campaign.title",
    visible: true,
    width: 150,
    render: (text, row) =>
      row.campaign && <Link to={`/campaigns/${row.campaign.id}`}>{text}</Link>,
  },
  status: {
    title: "Status",
    dataIndex: "status",
    visible: true,
    width: 100,
  },

  estimated_payout: {
    title: "Estimated Payout",
    dataIndex: "payout_type_pennies",
    visible: true,
    width: 100,
    render: (text, row) => row.formattedPayoutTypePennies(),
  },
  ctr: {
    title: "CTR",
    dataIndex: "ctr",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedCtr(),
  },
  itr: {
    title: "ITR",
    dataIndex: "itr",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedItr(),
  },
  views: {
    title: "Views",
    dataIndex: "views",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedViews(),
  },
  installs: {
    title: "Installs",
    dataIndex: "installs",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedInstalls(),
  },
  cpi: {
    title: "CPI",
    dataIndex: "cpi",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedCpi(),
  },
  cpc: {
    title: "CPC",
    dataIndex: "cpc",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedCpc(),
  },
  remove_reason: {
    title: "Remove Reason",
    dataIndex: "remove_reason",
    visible: true,
    width: 120,
  },
};

const filters = {
  status: {
    input: () => (
      <Select placeholder="Status" size="large" options={statuses}>
      </Select>
    ),
    label: "Status",
  },
};

export class InvitationsTable extends Component {
  loadInvitations = filters => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.loadInvitations({
      expand: ["campaign"],
      account: id,
      ...filters,
    });
  };

  render() {
    const { data, pagination, loading, style, height } = this.props;

    return (
      <Table
        style={style}
        title="Invitations"
        columns={columns}
        loadData={this.loadInvitations}
        name="AccountInvitationList"
        pagination={pagination}
        data={data}
        filterOptions={filters}
        loading={loading}
        size="middle"
        scroll={{ y: height, x: "max-content" }}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: InvitationList.selectMany(state),
  pagination: InvitationList.selectPagination(state),
  loading: InvitationList.selectPending(state),
});

const mapDispatchToProps = {
  loadInvitations: InvitationList.loadMany,
};

const MainInvitationsTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationsTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainInvitationsTable);
