// @ts-nocheck
import React, { useEffect, useState } from "react";
import { FilterForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { object } from "yup";
import isEqual from "lodash/isEqual";
import set from "lodash/set";
import { useAccountsListDetails } from "../../AccountsListProvider";
import { getInitialValues, getFormvalues, getFilterParams } from "./utils";
import { getFilterData } from "../../utils";
import { useLocation } from "react-router";
import qs from "qs";
import {
  useGetAdminListsQuery,
} from "src/generated/ApolloComponents";

interface Props {
  containerType?: string;
  closeAccountFilterModal: () => void;
  isExpand: boolean;
  setExpand: (value: boolean) => void;
  list?: any;
}

export interface FilterFormValues {
  searchInput?: string;
  searchType?: string;
  accountType?: Array<any>;
  influencerGender?: Array<any>;
  influencerAge?: Array<any>;
  influencerLocation?: Array<any>;
  tags?: Array<any>;
  customTags?: Array<any>;
  status?: Array<any>;
  audienceLocation?: [
    {
      key: string;
      comparison?: string;
      value?: any;
    }
  ];
  audienceGender?: [
    {
      key: string;
      comparison?: string;
      value?: number;
    }
  ];
  audienceAge?: [
    {
      key: string;
      comparison?: string;
      value?: number;
    }
  ];
  completedCampaigns?: [
    {
      months: string;
      comparison?: string;
      value?: number;
    }
  ];
  followers?: [
    {
      comparison?: string;
      value?: number;
    }
  ];
  rating?: {
    creationTime?: number;
    responseTime?: number;
    contentQuality?: number;
    professionalism?: number;
  };
  page: number;
  accountId: number;
  influencerId: number;
  campaignId: number;
  order: any;
  campaignIdForSuggestedAccounts: number;
  adminListId: number
  adminListIdForNotIncluded: number
}

export const FilterFormContainer: React.FC<Props> = props => {
  const location: any = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  // need to convert string and boolean value from query string
  const formatFilters = getFilterData(parsed);
  let initialFilters = {
    page: 1,
  };

  useEffect(() => {
    if(props?.list?.id) {
      const filterName = props?.containerType === 'listAccounts' ?
                          'adminListId' : 'adminListIdForNotIncluded'
      initialFilters[filterName] = props?.list?.id
    }
  }, [props, initialFilters])

  const [internalFilters, setInternalFilters] = useState({
    ...formatFilters,
    ...initialFilters,
  });

  const FilterFormSchema = object().shape({});

  const {
    actions: influencerAction,
    isLoading,
    filters,
    allTags,
  } = useAccountsListDetails();

  let initialValues = getInitialValues(filters);

  const clearAllFilter = () => {
    let container = {} as any;
    container["page"] = 1;
    influencerAction?.setFilters({ ...container });
    setInternalFilters({ ...container });
    influencerAction.refetchAccounts({ ...container });
    props.closeAccountFilterModal();
  };

  const clearFilterKey = key => {
    let newFilters = { ...filters };

    newFilters = set(newFilters, key, null);

    influencerAction?.setFilters({ ...newFilters });
    setInternalFilters({ ...newFilters });
    influencerAction.refetchAccounts({ ...newFilters });
  };

  const onSubmit = async (
    values: FilterFormValues,
    actions: FormikActions<FilterFormValues>
  ) => {
    try {
      const filterValue = getFormvalues(values);

      let newFilter = { ...filters, ...filterValue };

      influencerAction?.setFilters(filterValue);
      if (!isEqual(newFilter, internalFilters)) {
        const filterParams = getFilterParams(newFilter)
        influencerAction.refetchAccounts(filterParams);
        setInternalFilters(newFilter);
      }
      props?.closeAccountFilterModal();
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const adminLists = useGetAdminListsQuery()

  return (
    <FilterForm
      containerType={props.containerType}
      list={props.list}
      initialValues={initialValues}
      FilterSchema={FilterFormSchema}
      isSubmitting={isLoading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      allTags={(allTags || []) as any}
      clearAllFilter={() => clearAllFilter()}
      internalFilters={internalFilters}
      isExpand={props.isExpand}
      setExpand={value => props.setExpand(value)}
      clearFilterKey={key => clearFilterKey(key)}
      adminLists={adminLists?.data?.getAdminLists || []}
    />
  );
};
