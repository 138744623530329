import React, { Component } from "react";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import { CreativePersist } from "src/ducks/Persist";
import CreativeDisplay from "../CreativeDisplay";

const CreativeManage = CreativePersist("CreativeField");
const modules = [CreativeManage.module()];

export class CreativeLoad extends Component {
  componentDidMount() {
    const { creative_id, loadCreative } = this.props;
    creative_id && loadCreative(creative_id);
  }

  render() {
    const { creative } = this.props;
    return <CreativeDisplay creative={creative} style={{ marginBottom: 24 }} />;
  }
}

const mapStateToProps = state => ({
  creative: CreativeManage.selectOne(state),
});

const mapDispatchToProps = {
  loadCreative: CreativeManage.get,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(CreativeLoad)
)(modules);
