// @ts-nocheck
import React, { Fragment } from 'react'
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Row, Col } from 'antd'
import { Tag } from 'src/components/Core/Tag'
import { Box } from 'src/components/Core/Box'
import { Typography } from 'src/components/Core/Typography'

interface Props {
  profile: any
}

const Header = ({profile}: Props) => {
  return (
    <Box px={43} pt={41} pb={56}>
      <Row>
        <Col span={4}>
          {profile?.accounts[0] ?
            <Avatar size={94} src={profile.accounts[0].profilePicUrl} />
            :
            <Avatar size={94} icon={<UserOutlined />} />
          }

        </Col>
        <Col span={20}>
          <Typography size={18} color="black" mb={0}>{profile?.name}</Typography>
          {profile?.detail?.flags?.length > 0 &&
            <Fragment>
              <Typography size={12} color="black2">Interested in:</Typography>
              {profile?.detail?.flags.map(tag =>
                <Tag
                  size={12}
                  tagColor="geekblue6"
                  background="geekblue1"
                  borderColor="geekblue3"
                  key={tag}
                  style={{marginTop: "10px"}}
                >
                  {tag}
                </Tag>
              )}
            </Fragment>
          }
        </Col>
      </Row>
    </Box>
  );
}

export default Header
