// @ts-nocheck
import React from "react";
import { ReloadOutlined } from '@ant-design/icons';
import { Card, Button } from "antd";
import { Box } from "src/components/Core/Box";
import GeneralTabContainer from "./container";
import { Loading } from "src/components/Core/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import withWindowResize from "src/utils/hoc/withWindowResize";
import Topstats from "./Topstats";
import ClickGraph from "./ClickGraph";
import InstallGraph from "./InstallGraph";
import LocationsTable from "./LocationsTable";
import DevicePie from "./DevicePie";
import InfluencersPosted from "./InfluencersPosted";
import styled from "styled-components";

const Wrapper = styled(Card)`
  && {
    border: 0px;
    .ant-card-body {
      padding: 0 !important;
    }
  }
`;

interface Props {
  data: any;
  loading: boolean;
  refresh: () => void;
  refreshing: boolean
  globalErrors?: GlobalErrorProps[];
  onChangeActiveKey: Function;
  onViewPerformance: Function;
}

const MainGeneralTab: React.FC<Props> = ({
  data,
  loading,
  refresh,
  refreshing,
  globalErrors,
  onChangeActiveKey,
  onViewPerformance,
}) => {
  return (
    <Box>
      <Loading spinning={loading}>
        <Wrapper>
          {globalErrors && <GlobalErrors errors={globalErrors} />}

          <Box p={24}>
            <Button type="primary" ghost onClick={refresh} disabled={refreshing}>
              <ReloadOutlined />
              Refresh data
            </Button>
          </Box>

          <Box borderBottom="1px solid #E8E8E8">
            <Box p={24}>
              <Topstats data={data} />
            </Box>
          </Box>

          <Box p={24}>
            <InfluencersPosted
              data={data}
              onChangeActiveKey={onChangeActiveKey}
              onViewPerformance={value => onViewPerformance(value)}
            />
          </Box>

          <Box p={24}>
            <ClickGraph data={data} />
          </Box>

          <Box p={24}>
            <InstallGraph data={data} />
          </Box>

          <Box p={24}>
            <LocationsTable data={data} />
          </Box>

          <Box p={24}>
            <DevicePie data={data} />
          </Box>
        </Wrapper>
      </Loading>
    </Box>
  );
};

const GeneralTab = withWindowResize({ extraHeight: 350 })(MainGeneralTab);

export { GeneralTab, GeneralTabContainer };
