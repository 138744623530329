import React, { Component } from "react";
import styled from "styled-components";
import { Typography } from "antd";
import { ErrorMessage } from "formik";
import FormError from "./FormError";

const { Text } = Typography;

const FieldWrapper = styled("div")`
  margin-bottom: 30px;
`;

const InputContainer = styled("div")`
  margin-top: 10px;
`;

const FieldLabel = styled(Text)`
  && {
    display: block;
    font-size: 16px;
    font-weight: 500;
  }
`;

const FieldHelp = styled(Text)`
  && {
    margin-top: 4px;
    display: block;
    font-size: 16px;
  }
`;

export default class FormField extends Component {
  render() {
    const { name, label, help, children, style } = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { name: name, ...child.props })
    );

    return (
      <FieldWrapper style={style}>
        {label && <FieldLabel>{label}</FieldLabel>}
        {help && <FieldHelp type="secondary">{help}</FieldHelp>}
        <InputContainer>
          {childrenWithProps}
          <ErrorMessage
            component={FormError}
            name={name}
            cy={name.replace(".", "-")}
          />
        </InputContainer>
      </FieldWrapper>
    );
  }
}
