// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSwitch } from "src/components/Forms/Switch";

interface Props {}

const SalesAppropriative: React.FC<Props> = () => {
  return (
    <Field label="Sales appropriate?:" mb={26}>
      <FormikSwitch name="salesAppropriate" />
    </Field>
  );
};

export default SalesAppropriative;
