// @ts-nocheck
import React from "react";
import { FormikDatePicker } from "src/components/Forms/DatePicker";
import { Field } from "src/components/Forms/Field";
import { RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const CampaignDate: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (formProps?.values?.startedAfter) {
    isVisible = true;
  }

  return (
    <Field
      label="Campaign started after:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("startedAfter", "");
              clearFilterKey("startedAfter");
            }}
            style={{ marginTop: "-1px", marginLeft: "15px" }}
          />
        )
      }
      id="startedAfter"
    >
      <FormikDatePicker
        format="YYYY-MM-DD"
        size="middle"
        borderRadius={3}
        name="startedAfter"
        placeholder="Select Date"
      />
    </Field>
  );
};

export default CampaignDate;
