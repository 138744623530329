// @ts-nocheck
import React, { useEffect } from "react";
import { locations } from "src/constants/account";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { RemoveIcon } from "../../../Wrapper";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const LocationAudience: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (
    (formProps?.values?.audienceLocation || []).length > 0 &&
    (formProps?.values?.audienceLocation || [])?.[0]?.key !== ""
  ) {
    isVisible = true;
  }

  let selectedData =
    (formProps?.values?.audienceLocation || [])
      .map(item => item?.key)
      .filter(v => v !== "") || [];

  useEffect(() => {
    let simpleLocationAudience = [] as any;
    simpleLocationAudience = (selectedData || []).map(item => {
      let container = {} as any;
      container.key = item;
      return container;
    });

    formProps.setFieldValue("audienceLocation", simpleLocationAudience);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Field
      label="Location:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("audienceLocation", [{ key: "" }]);
              clearFilterKey("audienceLocation");
            }}
            style={{ marginTop: "-1px", marginLeft: "10px" }}
          />
        )
      }
      id="countryBreakdown"
    >
      <FormikSelect
        name="locationAudience"
        data={locations}
        local
        borderRadius={3}
        borderColor="grey5"
        size="middle"
        mode="multiple"
        value={selectedData}
        onChange={v => {
          let locationAudience = [] as any;
          locationAudience = (v || []).map(item => {
            let container = {} as any;
            container.key = item;
            return container;
          });
          formProps.setFieldValue("audienceLocation", locationAudience);
        }}
      />
    </Field>
  );
};

export default LocationAudience;
