// @ts-nocheck
import React from "react";
import Modal from "src/components/Core/Modal";
import AuthoriseAdPlatform from "./AuthoriseAdPlatform";

interface Props {
  showAuthoriseAdPlatform: boolean;
  closeAuthoriseAdPlatformModal: () => void;
  authorised: any;
  campaignId: number;
}

const AuthoriseAdPlatformsModal = ({
  closeAuthoriseAdPlatformModal,
  showAuthoriseAdPlatform,
  authorised,
  campaignId,
}: Props) => {
  return (
    <Modal
      title="Authorise Ad platforms"
      subTitle="Get access to your ad platforms so you can upload creatives directly to them"
      width={500}
      showModal={showAuthoriseAdPlatform}
      closeModal={closeAuthoriseAdPlatformModal}
    >
      <AuthoriseAdPlatform authorised={authorised} campaignId={campaignId} />
    </Modal>
  );
};

export default AuthoriseAdPlatformsModal;
