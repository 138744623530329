// @ts-nocheck
import React from "react";
import { CreateCampaignGroupForm } from ".";
import { string, object } from "yup";
import { message } from "antd";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetAllCompaniesQuery,
  useGetAllBrandsQuery,
  useCreateCampaignGroupMutation,
} from "src/generated/ApolloComponents";
import { useCampaignsListDetails } from "../../CampaignsListProvider";

interface Props {
  closeCreateCampaignGroupModal: () => void;
}

interface CreateCampaignGroupFormValues {
  groupName: string;
  companyId: number;
  extraBrandIds: Array<number>;
}

export const CreateCampaignGroupFormContainer: React.FC<Props> = props => {
  const company = useGetAllCompaniesQuery({
    skip: true,
  });

  const brands = useGetAllBrandsQuery({
    skip: true,
  });

  const { actions: campaignAction } = useCampaignsListDetails();

  const [
    createCampaignGroupMutation,
    createCampaignGroup,
  ] = useCreateCampaignGroupMutation();

  const CreateCampaignGroupSchema = object().shape({
    groupName: string()
      .nullable()
      .required("Please enter a title"),
    companyId: string()
      .nullable()
      .required("Please select company"),
  });

  let initialValues = {
    groupName: "",
    companyId: null,
    extraBrandIds: [],
  };

  const onSubmit = async (
    values: CreateCampaignGroupFormValues,
    actions: FormikActions<CreateCampaignGroupFormValues>
  ) => {
    try {
      const res = await createCampaignGroupMutation({
        variables: values,
      });
      if (res?.data?.createCampaignGroup?.id) {
        message.success("Campaign Group add successfully");
        let container = {} as any;
        container["page"] = 1;
        campaignAction.setFilters({ ...container });
        campaignAction.refetchCampaigns({ ...container });
        props.closeCreateCampaignGroupModal();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(createCampaignGroup?.error);

  return (
    <CreateCampaignGroupForm
      initialValues={initialValues}
      createCampaignGroupSchema={CreateCampaignGroupSchema}
      isSubmitting={createCampaignGroup?.loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      closeCreateCampaignGroupModal={props.closeCreateCampaignGroupModal}
      getCompanies={company.refetch}
      getBrands={brands.refetch}
    />
  );
};
