// @ts-nocheck
import styled from "styled-components";
import { Layout, PageHeader } from "antd";
const { Content, Sider } = Layout;

export const SidebarBox = styled(Sider)`
  && {
    background: #fff;
    overflow: auto;
    min-height: 100vh;
    position: fixed;
    border-radius: 6px;
  }
`;

export const ContentBox = styled(Content)`
  && {
    margin-left: 260px;
    background: #fff;
    border-radius: 6px;
  }
`;

export const Breadcrumb = styled(PageHeader)`
  &&& {
    .ant-page-header-heading-title {
      font-weight: 400 !important;
    }
  }
`;
