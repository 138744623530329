// @ts-nocheck
import { Checkbox } from "antd";
import styled from "styled-components";

export const HubCheckbox = styled(Checkbox)`
  &&& {
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
    .ant-checkbox-disabled + span {
      color: #595959 !important;
    }
  }
`;
