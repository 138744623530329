import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { show } from "redux-modal";
import TableManager from "src/shared/TableManager";
import Filters from "./Filters";
import CreateModal from "./CreateModal";
import { default as $TableManager } from "src/ducks/TableManager";
import { InfluencerList as $InfluencerList } from "src/ducks/DataList";
import withWindowResize from "src/utils/hoc/withWindowResize";

const InfluencerList = $InfluencerList("AdminInfluencerList");
const InfluencerFilters = $TableManager("AdminInfluencerList");

export class Influencers extends Component {
  loadInfluencers = () => {
    const { filters } = this.props;
    return this.props.loadInfluencers(filters);
  };

  render() {
    const { influencers, pagination, loading, height } = this.props;

    const columns = {
      name: {
        title: "Name",
        dataIndex: "name",
        visible: true,
        width: 300,
        render: (text, row) => (
          <Link to={`/influencers/${row.id}`}>{text}</Link>
        ),
      },
      email: {
        title: "Email",
        dataIndex: "email",
        visible: true,
        width: 350,
      },
    };

    return (
      <TableManager
        name="AdminInfluencerList"
        model="Influencer"
        columns={columns}
        loadData={this.loadInfluencers}
        filterOptions={Filters}
        data={influencers}
        pagination={pagination}
        loading={loading}
        createModal={<CreateModal onCreate={this.loadInfluencers} />}
        scroll={{ y: height, x: "max-content" }}
      />
    );
  }
}

const mapStateToProps = state => ({
  filters: InfluencerFilters.selectFilters(state),
  influencers: InfluencerList.selectMany(state),
  loading: InfluencerList.selectPending(state),
  pagination: InfluencerList.selectPagination(state),
});

const mapDispatchToProps = {
  show,
  loadInfluencers: InfluencerList.loadMany,
};

const MainInfluencers = connect(
  mapStateToProps,
  mapDispatchToProps
)(Influencers);

export default withWindowResize({ extraHeight: 260 })(MainInfluencers);
