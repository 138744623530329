// @ts-nocheck
import React, { Fragment } from 'react'
import { Row, Col } from 'antd'
import Wrapper from '../Wrapper'
import Actions from './Actions'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import Form from 'src/components/Forms/Form'
import ValidationSchema from './Validations'
import { SignupContainer } from './container'
import { Box } from 'src/components/Core/Box'
import {
  GlobalErrorProps,
  GlobalErrors,
} from 'src/components/Forms/ErrorMessage'
import { Loading } from 'src/components/Core/Loading'
import qs from 'qs'

interface Props {
  loading: boolean
  handleSignup: Function
  globalErrors?: GlobalErrorProps[]
}

const Signup = ({handleSignup, globalErrors, loading}: Props) => {
  const search = window.location.search
  const params = qs.parse(search, { ignoreQueryPrefix: true })
  const initialValues = {}
  Object.keys(params).map(key => initialValues[key] = params[key])
  return(
    <Wrapper>
        <Form
          initialValues={initialValues}
          schema={ValidationSchema}
          onSubmit={(values, actions) => handleSignup(values)}
        >
          {(formProps: any) => {
            return(
              <Fragment>
                <GlobalErrors errors={globalErrors} />
                <Loading spinning={loading}>
                  {window.innerWidth > 768 ?
                    <Row gutter={16} style={{ width: '100%'}}>
                      <Col md={12} sm={24}>
                        <LeftPanel formProps={formProps} />
                        <Actions formProps={formProps} />
                      </Col>
                      <Col md={12} sm={24}>
                        <Box mt={81}>
                          <RightPanel formProps={formProps} />
                        </Box>
                      </Col>
                    </Row>
                    :
                    <Box p={40}>
                      <LeftPanel formProps={formProps} />
                      <RightPanel formProps={formProps} />
                      <Actions formProps={formProps} />
                    </Box>
                  }
                </Loading>
              </Fragment>
            )}
          }
        </Form>
    </Wrapper>
  )
}

export { Signup, SignupContainer };
