import axiosInstance from "../axiosInstance";
// Endpoint
const CREATE_ACCOUNT_POST = `/account_posts`;
const GET_ACCOUNT_POSTS = `/account_posts`;
const getAccount = id => `/account_posts/${id}`;
const updateAccount = id => `/account_posts/${id}`;
const deleteAccount = id => `'/account_posts/${id}`;

export const create = data => axiosInstance.post(CREATE_ACCOUNT_POST, data);

export const index = data => axiosInstance.get(GET_ACCOUNT_POSTS, data);

export const get = (id, data) => axiosInstance.get(getAccount(id), data);

export const update = (id, data) =>
  axiosInstance.patch(updateAccount(id), data);

export const destroy = id => axiosInstance.delete(deleteAccount(id));
