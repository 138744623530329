// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Empty } from "antd";
import numeral from "numeral";
import moment from "moment";
import {
  LineChart,
  Line,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AxisDomain,
  ResponsiveContainer,
} from "recharts";

interface Props {
  data: Array<any>;
  YDomain?: [AxisDomain, AxisDomain];
  dataPoint: any;
}
const LineChartGraph: React.FC<Props> = ({ data, YDomain, dataPoint }) => {
  const renderColorfulLegendText = (value: string, name: any, props: any) => {
    if (name === "engagementRate") {
      return <span> {numeral(value).format("0,0.00") + "%"}</span>;
    } else {
      return <span> {numeral(value).format("0,0")}</span>;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
      {data?.length > 0 ? (
        <LineChart
          data={data}
          margin={{
            top: 5,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            stroke="#98A1B5"
            axisLine={false}
            tickFormatter={x => moment(x).format("Do MMM YYYY")}
            style={{
              fontSize: "12px",
            }}
          >
            <Label value="Time" offset={-30} position="insideBottom" />
          </XAxis>
          <YAxis
            domain={YDomain}
            axisLine={false}
            tickFormatter={x => numeral(x).format("0a")}
            style={{
              fontSize: "12px",
            }}
            label={{ value: "Amount", angle: -90, position: "insideLeft" }}
          />
          <Tooltip
            formatter={renderColorfulLegendText}
            labelFormatter={value => moment(value).format("Do MMM YYYY")}
          />
          <Legend />
          {dataPoint?.views && (
            <Line
              type="monotone"
              dataKey="averageViews"
              stroke="#8884d8"
              connectNulls={true}
              activeDot={{ r: 8 }}
            />
          )}

          {dataPoint?.engagement && (
            <Line type="monotone" dataKey="engagementRate" stroke="#82ca9d" />
          )}

          {dataPoint?.followers && (
            <Line type="monotone" dataKey="followers" stroke="#4f6884" />
          )}
        </LineChart>
      ) : (
        <Box p={85}>
          <Empty description="No data avaialble" />
        </Box>
      )}
    </ResponsiveContainer>
  );
};

export default LineChartGraph;
