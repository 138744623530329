import React from "react";

// This function takes a component...
function withModal(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    state = {
      visible: false
    };

    closeModal = () => {
      this.setState({ visible: false });
    };

    showModal = () => {
      this.setState({ visible: true });
    };

    render() {
      return (
        <WrappedComponent
          visible={this.state.visible}
          closeModal={this.closeModal}
          showModal={this.showModal}
          {...this.props}
        />
      );
    }
  };
}

export default withModal;
