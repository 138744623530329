import React, { Component } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { connect } from "react-redux";
import { Table } from "antd";
import TableManager from "src/ducks/TableManager";

const ResultTableUI = styled(Table)`
  &&& {
    .ant-table-body {
      background: #fff;
      margin: 0px;
      overflow: auto !important;
    }
  }
`;

class ResultsTable extends Component {
  onTableChange = (pagination, filters, sorter) => {
    const { addOrder } = this.props;
    const order = sorter.field
      ? {
          [sorter.field]: sorter.order === "descend" ? "desc" : "asc",
        }
      : null;

    addOrder(order);
  };

  render() {
    const {
      loading,
      addFilter,
      tableColumns,
      addedColumns,
      data,
      name,
      columns,
      scroll,
      pagination: { current_page, total_entries, per_page },
      ...rest
    } = this.props;

    return (
      <ResultTableUI
        loading={loading}
        size="small"
        pagination={{
          current: current_page,
          pageSize: per_page,
          total: total_entries,
          onChange: page => addFilter({ page: page }),
          showSizeChanger: true,
          onShowSizeChange: (current, pageSize) =>
            addFilter({ per_page: pageSize }),
        }}
        rowKey="id"
        columns={tableColumns.map(x => columns[x]).concat(addedColumns || [])}
        dataSource={data}
        onChange={this.onTableChange}
        data-cy={`table-${name}`}
        scroll={scroll}
        {...rest}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const Manager = TableManager(props.moduleName);

  return {
    currentFilters: Manager.selectFilters(state),
    tableColumns: Manager.selectColumnKeys(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const Manager = TableManager(ownProps.moduleName);

  return bindActionCreators(
    {
      addFilter: Manager.addFilter,
      addOrder: Manager.addOrder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTable);
