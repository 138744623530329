// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import isEmpty from "lodash/isEmpty";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import {
  LocationBreakdown,
  AgeBreakdown,
  GenderBreakdown,
} from "src/components/Modules/BreakdownFields";
import { ScreenshotsContainer } from "../../../../components/Fields/Screenshots";

interface Props {
  setShowExample: (value: object) => void;
  setScreenshotType: (value: string) => void;
  showExample: object;
  formProps: FormikProps<any>;
  cashoutData: any;
  setIsUploading: (value: boolean) => void;
}

const YoutubeProfileData = ({
  setShowExample,
  setScreenshotType,
  showExample,
  formProps,
  cashoutData,
  setIsUploading,
}: Props) => {
  return (
    <Box>
      {formProps?.values?.platformType !== "TikTok" && (
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <AgeBreakdown formProps={formProps} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {formProps?.values?.isAccountScreenshotOutdated && (
              <Box mb={40} mt={20}>
                <ScreenshotsContainer
                  name="ageScreenshot"
                  setIsUploading={loading => setIsUploading(loading)}
                  setFieldValue={value =>
                    formProps?.setFieldValue("ageScreenshot", value)
                  }
                  setFieldTouched={() =>
                    formProps?.setFieldTouched("ageScreenshot", true)
                  }
                  values={formProps?.values}
                  setShowExample={() =>
                    setShowExample({
                      age: true,
                      location: false,
                      gender: false,
                      campaign: false,
                      campaignScreenshot: false,
                    })
                  }
                  setScreenshotType={() => setScreenshotType("age")}
                  platformType={cashoutData?.account?.type || ""}
                  accountName={cashoutData?.account?.username || ""}
                  screenshotType="age"
                  multiple={false}
                  disabled={!isEmpty(formProps?.values?.ageScreenshot)}
                  showExample={showExample}
                  hideExample={() =>
                    setShowExample({
                      age: false,
                      location: false,
                      gender: false,
                      campaign: false,
                      campaignScreenshot: false,
                    })
                  }
                />
              </Box>
            )}
          </Col>
        </Row>
      )}

      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <GenderBreakdown formProps={formProps} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {formProps?.values?.isAccountScreenshotOutdated && (
            <Box mb={40} mt={20}>
              <ScreenshotsContainer
                name="genderScreenshot"
                setIsUploading={loading => setIsUploading(loading)}
                setFieldValue={value =>
                  formProps?.setFieldValue("genderScreenshot", value)
                }
                setFieldTouched={() =>
                  formProps?.setFieldTouched("genderScreenshot", true)
                }
                values={formProps?.values}
                setShowExample={() =>
                  setShowExample({
                    age: false,
                    location: false,
                    gender: true,
                    campaign: false,
                    campaignScreenshot: false,
                  })
                }
                setScreenshotType={() => setScreenshotType("gender")}
                platformType={cashoutData?.account?.type || ""}
                accountName={cashoutData?.account?.username || ""}
                screenshotType="gender"
                multiple={false}
                disabled={!isEmpty(formProps?.values?.genderScreenshot)}
                showExample={showExample}
                hideExample={() =>
                  setShowExample({
                    age: false,
                    location: false,
                    gender: false,
                    campaign: false,
                    campaignScreenshot: false,
                  })
                }
              />
            </Box>
          )}
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <LocationBreakdown formProps={formProps} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {formProps?.values?.isAccountScreenshotOutdated && (
            <Box mb={40} mt={20}>
              <ScreenshotsContainer
                name="locationScreenshot"
                setIsUploading={loading => setIsUploading(loading)}
                setFieldValue={value =>
                  formProps?.setFieldValue("locationScreenshot", value)
                }
                setFieldTouched={() =>
                  formProps?.setFieldTouched("locationScreenshot", true)
                }
                values={formProps?.values}
                setShowExample={() =>
                  setShowExample({
                    age: false,
                    location: true,
                    gender: false,
                    campaign: false,
                    campaignScreenshot: false,
                  })
                }
                setScreenshotType={() => setScreenshotType("location")}
                platformType={cashoutData?.account?.type || ""}
                accountName={cashoutData?.account?.username || ""}
                screenshotType="location"
                multiple={false}
                disabled={!isEmpty(formProps?.values?.locationScreenshot)}
                showExample={showExample}
                hideExample={() =>
                  setShowExample({
                    age: false,
                    location: false,
                    gender: false,
                    campaign: false,
                    campaignScreenshot: false,
                  })
                }
              />
            </Box>
          )}
        </Col>
      </Row>
    </Box>
  );
};

export default YoutubeProfileData;
