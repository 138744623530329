// @ts-nocheck
import React, { Fragment, useState } from 'react'
import Wrapper from '../Wrapper'
import { Link, useLocation } from 'react-router-dom'
import { LoginContainer } from './container'
import Form from 'src/components/Forms/Form'
import ValidationSchema from './Validations'
import { Box } from 'src/components/Core/Box'
import { Button } from 'src/components/Core/Button'
import { Typography } from 'src/components/Core/Typography'
import {
  Email,
  Password
} from './FormFields'
import { Row, Col, Alert } from 'antd'
import { Loading } from 'src/components/Core/Loading'
import styled from 'styled-components'

const LoginError = styled(props => (
  <Alert type="warning" message={props.children} {...props} />
))`
  margin-top: 15px;
`;

const Login = ({handleSubmit, initialValues, failed}) => {
  const location = useLocation()
  const [submitting, setSubmitting] = useState(false)
  return(
    <Wrapper>
      <Box width={[3/4, 1/3]} maxWidth={450}>
        <Typography fontSize={30} color="black" fontWeight="bold" mb={0}>
          Sign in
        </Typography>
        <Typography fontSize={16} color="black2">
          To apply and have access to campaigns you need to have a Fanbytes account.
        </Typography>
        <Form
          initialValues={initialValues}
          schema={ValidationSchema}
          onSubmit={(values, actions) => handleSubmit(values, setSubmitting)}
        >
          {(formProps: any) => {
            return(
              <Fragment>
                {failed &&
                    <LoginError message="Incorrect login details" type="error" />
                }
                <Email formProps={formProps} />
                <Password formProps={formProps} />
                <Typography fontSize={12} mt={-15}>
                  <Link to="/forgot">Forgot password</Link>
                </Typography>
                <Box mt={35}>
                  <Row>
                    <Col>
                      <Button
                        type="action"
                        onClick={formProps.handleSubmit}
                      >
                        <Typography fontSize={18} mb={0} cursor="pointer">
                          <Loading spinning={submitting}>
                              Sign in
                          </Loading>
                        </Typography>
                      </Button>
                    </Col>
                    <Box mt={2}>
                      <Col>
                        <Typography fontSize={20}>
                          &nbsp; or <Link to={`/sign_up${location.search}`}>Sign up</Link>
                        </Typography>
                      </Col>
                    </Box>
                  </Row>
                </Box>
              </Fragment>
            )
          }}
        </Form>
      </Box>
    </Wrapper>
  )
}

export {Login, LoginContainer};
