import React, { Component } from "react";
import styled from "styled-components";
import { Layout, Button } from "antd";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import logo from "../../../assets/images/logo.png";

const Wrapper = styled(Layout)`
  display: flex;
`;

const Logo = styled("img")`
  width: 150px;
  display: block;
`;

const TopBar = styled("div")`
  display: flex;
  width: 960px;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;

export default class LoginLayout extends Component {
  getTitle = (title) => {
    const titleStr = "Cortex Influencer Campaign Manager"
    return title ? `${title} | ${titleStr}` : titleStr
  }

  render() {
    const { title } = this.props;
    return (
      <DocumentTitle title={this.getTitle(title)}>
        <>
          <Wrapper>
            <TopBar>
              <Link to={"/sign_in"}>
                <Logo src={logo} />
              </Link>
              <Link to={"/sign_in"}>
                <Button>Sign in</Button>
              </Link>
            </TopBar>
            {this.props.children}
          </Wrapper>
        </>
      </DocumentTitle>
    );
  }
}
