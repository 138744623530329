// @ts-nocheck
import React from "react";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal as AntdModal } from "antd";
import styled from "styled-components";
import { Typography } from "src/components/Core/Typography";

const MainModal = styled(AntdModal)`
  &&& {
    border-radius: 6px;
  }
`;

interface Props {
  showModal: boolean;
  closeModal: () => void;
  width: number;
  title: string;
  subTitle?: string;
  children: React.ReactNode;
}

const Modal = ({
  closeModal,
  showModal,
  width,
  title,
  subTitle,
  children,
}: Props) => {
  return (
    <MainModal
      title=""
      style={{
        maxWidth: width,
      }}
      bodyStyle={{
        padding: "30px",
      }}
      width="100%"
      visible={showModal}
      onCancel={closeModal}
      footer={null as any}
      closeIcon={<CloseCircleOutlined style={{ fontSize: "20px" }} />}
      maskClosable={false}
    >
      <Typography size={30} weight="semi-bold" color="black1" mb="8px">
        {title}
      </Typography>

      {subTitle && (
        <Typography size={16} color="grey7">
          {subTitle}
        </Typography>
      )}
      {children}
    </MainModal>
  );
};

export default Modal;
