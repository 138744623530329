// @ts-nocheck
import React, { useRef, useEffect } from "react";
import { Box } from "src/components/Core/Box";
import { useInViewport } from "react-in-viewport";
import Stats from "./Stats";
import FadeInSection from "../../FadeInSection";
import CategorisedTopData from "../CategorisedTopData";

interface Props {
  onChangeSection: (value: string) => void;
  campaignData: any;
}

const Views: React.FC<Props> = ({ onChangeSection, campaignData }) => {
  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};

  let props = {};
  const { inViewport } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  useEffect(() => {
    if (inViewport) {
      onChangeSection("views");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport]);

  return (
    <FadeInSection>
      <Box mt={60} position="relative" id="views" ref={myRef as any}>
        {(campaignData?.categorisedTopViews || []).map((item, i) => {
          return (
            <CategorisedTopData
              viewData={item}
              key={i}
              direction={i % 2 === 0 ? "left" : "right"}
              type="views"
            />
          );
        })}
        {campaignData?.totalWatchedTime && (
          <Stats totalWatchedTime={campaignData?.totalWatchedTime} />
        )}
      </Box>
    </FadeInSection>
  );
};

export default Views;
