import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "src/shared/Container";
import PayoutsTable from "./PayoutsTable";
import { InfluencerPersist } from "src/ducks/Persist";
const ShowInfluencer = InfluencerPersist("AdminInfluencerShow");

export class Payouts extends Component {
  render() {
    return (
      <Container style={{ marginTop: "24px" }}>
        <PayoutsTable />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  influencer: ShowInfluencer.selectOne(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payouts);
