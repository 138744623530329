import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Input, message, Typography } from "antd";
import InvitationList from "./InvitationList";
import { Row } from "antd";
import { show } from "redux-modal";
import { Box } from "src/components/Core/Box";
import { DragDropContext } from "react-beautiful-dnd";
import InvitationManage from "src/ducks/InvitationManage";
import CampaignManage from "src/ducks/CampaignManage";
import CompleteModal from "./CompletePaymentModal";
import AcceptModal from "./AcceptModal";
import RemoveModal from "./RemoveModal";
import InfluencerRatingModal from "./InfluencerRatingModal";
import InvitationModal from "src/shared/InvitationModal";
const { Text } = Typography

const TopSearch = styled(Input)`
  &&& {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    padding: 20px;
  }
`;

export class ManageInvitations extends Component {
  onDragEnd = async result => {
    const { show, updateStatus, campaign, loadInvitation } = this.props;
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    // remove restriction
    const splitData = draggableId.split(":");
    const accountStatus = splitData?.[0];
    const invitationId = parseInt(splitData?.[1]);
    const isTagVerified = splitData?.[2];
    const isScreenshotVerified = splitData?.[3];
    const isBreakdownVerified = splitData?.[4];

    const response = await loadInvitation(invitationId, { params: { expand: ["account"] }})
    const latestInvite = response?.value?.data

    if (
      destination.droppableId === "complete" &&
      (campaign?.status).toLowerCase() === "draft"
    ) {
      message.warning(
        <Box display="flex" flexDirection="column" textAlign="left">
          <Typography>This is a "Draft" campaign</Typography>
          <Typography>Please approve it or ask for approval</Typography>
        </Box>
      );
      return;
    }

    // disable verification for influencer accounts
    // if (
    //   destination.droppableId === "complete" &&
    //   (latestInvite?.account?.status || accountStatus) !== "Verified"
    // ) {
    //   message.warning(
    //     <Box display="flex" flexDirection="column" textAlign="left">
    //       <Typography>Influencer is not verified. Please correct the following to verify:</Typography>
    //       {(isTagVerified === "No" || latestInvite?.account_verification.is_tag_verified) && (
    //         <Text type="danger">- Add tags to influencer account</Text>
    //       )}
    //       {(isScreenshotVerified === "No" || latestInvite?.account_verification.is_screenshot_verified) && (
    //         <Text type="danger">- Upload/Reupload screenshot of demographics</Text>
    //       )}
    //       {(isBreakdownVerified === "No" || latestInvite?.account_verification.is_breakdown_verified) && (
    //         <Text type="danger">- Enter demographic data</Text>
    //       )}
    //     </Box>, [30]
    //   );

    //   return;
    // }

    if (destination.droppableId === "accepted") {
      return show("AcceptModal", {
        invitation_id: invitationId,
        from: source.droppableId,
        to: destination.droppableId
      });
    }

    if (destination.droppableId === "complete") {
      if (campaign?.status === "complete")
        return;
      return show("CompleteModal", {
        invitation_id: invitationId,
        from: source.droppableId,
        to: destination.droppableId
      });
    }

    if (destination.droppableId === "removed") {
      return show("RemoveModal", {
        invitation_id: invitationId,
        from: source.droppableId,
        to: destination.droppableId
      });
    }

    updateStatus(invitationId, source.droppableId, destination.droppableId);
  };

  render() {
    const { search, setSearch } = this.props;

    return (
      <>
        <TopSearch
          placeholder="Search..."
          value={search}
          onChange={e => setSearch(e.target.value)}
          size="large"
        />
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Row
            gutter={24}
            style={{
              width: '1200px',
              overflow: 'auto',
              padding: "0 24px",
              flexWrap: "nowrap",
              height: "calc(100% - 61px)",
              backgroundColor: '#f0f2f5',
            }}
          >
            <InvitationList moduleKey="approved" title="Brand Approved" />
            <InvitationList moduleKey="sent" title="Sent" />
            <InvitationList moduleKey="accepted" title="Accepted" />
            <InvitationList moduleKey="complete" title="Complete" />
            <InvitationList moduleKey="removed" title="Removed" />
          </Row>
        </DragDropContext>
        <AcceptModal />
        <CompleteModal />
        <RemoveModal />
        <InvitationModal />
        <InfluencerRatingModal />
      </>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
  search: InvitationManage.selectSearch(state),
});

const mapDispatchToProps = {
  loadInvitation: InvitationManage.invitation.get,
  setSearch: InvitationManage.setSearch,
  updateStatus: InvitationManage.updateStatus,
  show
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvitations);
