// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { Button } from "src/components/Core/Button";
import { Row, Col, Card } from "antd";
import numeral from "numeral";
import { Typography } from "src/components/Core/Typography";
import TikTokPost from "src/components/Modules/TikTokPost";

interface Props {
  account: any;
}

const PostVideoCard = styled(Card)`
  &&& {
    background: none !important;
    border: none !important;
    .ant-card-body {
      padding: 0px;
    }
  }
`;

const AccountContent: React.FC<Props> = ({ account }) => {
  const lastVideourl = `https://www.tiktok.com/@${account?.accountName}/video/${account?.lastVideoId}`;

  const getTitle = text => {
    return (
      <Typography size={10} color="black2" mb={0}>
        {text}
      </Typography>
    );
  };

  const getContent = text => {
    return (
      <Typography size={10} color="black" mb={0}>
        {text}
      </Typography>
    );
  };

  return (
    <Box width="580px">
      <Row gutter={8}>
        <Col span={14}>
          <PostVideoCard hoverable cover={<TikTokPost url={lastVideourl} />} />
        </Col>
        <Col span={10}>
          <Box p={10}>
            {getTitle("Bio")}
            {getContent(account?.bio)}

            <Box display="flex" justifyContent="space-between" mt={20}>
              {getTitle("Avg Views:")}
              {getContent(numeral(account?.averageViews).format("0a"))}
            </Box>

            <Box display="flex" justifyContent="space-between" mt={20}>
              {getTitle("Eng. Rate:")}
              {getContent(
                numeral(account?.engagementRate).format("0,0.00") + "%"
              )}
            </Box>

            <Box display="flex" justifyContent="space-between" mt={20}>
              {getTitle("Growth:")}
              {getContent(
                `+${numeral(account?.growthRate).format("0,0.00") + "%"}`
              )}
            </Box>

            <Box display="flex" justifyContent="space-between" mt={20}>
              {getTitle("Followers: ")}
              {getContent(numeral(account?.followers).format("0a"))}
            </Box>

            <Box display="flex" justifyContent="space-between" mt={20}>
              {getTitle("Avg. Hearts: ")}
              {getContent(numeral(account?.averageHearts).format("0a"))}
            </Box>

            <Box display="flex" justifyContent="space-between" mt={20}>
              {getTitle("Avg. Comments: ")}
              {getContent(numeral(account?.averageComments).format("0a"))}
            </Box>

            <a
              href={`https://tiktok.com/@${account?.accountName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                type="action"
                buttonSize="small"
                data-cy="visit-button"
                mt={30}
              >
                Visit on Tiktok
              </Button>
            </a>
          </Box>
        </Col>
      </Row>
    </Box>
  );
};

export default AccountContent;
