import React from "react";

const withWindowResize = option => WrapperComponent => {
  return class WindowResize extends React.Component {
    state = {
      newHeight: window.innerHeight - option?.extraHeight,
    };

    resizeEvent = () => {
      const { extraHeight } = option;

      const newHeight =
        (window.innerHeight > 300 ? window.innerHeight : 300) - extraHeight;

      this.setState({
        newHeight: newHeight < 200 ? 200 : newHeight,
      });
    };

    componentDidMount() {
      window.addEventListener("resize", this.resizeEvent);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.resizeEvent);
    }

    render() {
      return <WrapperComponent {...this.props} height={this.state.newHeight} />;
    }
  };
};

export default withWindowResize;
