// @ts-nocheck
import React from "react";
import { BarChartOutlined, StopFilled } from '@ant-design/icons';
import { Row, Col, Avatar, Popover } from "antd";
import tiktok from "src/assets/images/tiktok.png";
import AccountStats from "../../AccountStats";
import isEmpty from "lodash/isEmpty";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import styled from "styled-components";
import AccountContent from "../AccountContent";
import numeral from "numeral";
import UserNameContent from "../UserNameContent"

const Image = styled("img")`
   {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const Account = ({account, newPrevContent, videoStyle, menu, isInnerAccountList}) => {
  return (
    <Box border="1px solid #CCCCCC" borderRadius="2px" p="10px">
      <Row gutter={4}>
        {(newPrevContent || []).slice(0, 4).map((content, i) => {
          return (
            <Col span={12} key={i} style={{ paddingBottom: "4px" }}>
              {!isEmpty(content) ? (
                <Box
                  borderRadius={4}
                  height="75px"
                  width="100%"
                  border="1px solid #c4c4c4"
                >
                  <Popover
                    content={
                      <AccountContent
                        contentType={
                          (content?.contentType || "")?.match("image.*")
                            ? "image"
                            : content?.contentType === "tiktok"
                            ? "tiktok"
                            : "video"
                        }
                        contentUrl={content?.contentUrl}
                        likes={numeral(content?.likes).format("0a")}
                        views={numeral(content?.views).format("0a")}
                        accountName={`@${account?.username}`}
                      />
                    }
                    destroyTooltipOnHide={true}
                    placement="right"
                    align={{
                      overflow: { adjustX: true, adjustY: true },
                    }}
                  >
                    {(content?.contentType || "")?.match("image.*") ? (
                      <Image
                        src={content?.contentUrl}
                        alt="previousBranded"
                      />
                    ) : content?.contentType === "tiktok" ? (
                      <Image src={tiktok} alt="tiktok" />
                    ) : (
                      <video
                        style={videoStyle as React.CSSProperties}
                        src={content?.contentUrl}
                      />
                    )}
                  </Popover>
                </Box>
              ) : (
                <Box
                  background="#c4c4c4"
                  textAlign="center"
                  height="75px"
                  width="100%"
                  borderRadius={4}
                  p="14.5px"
                  border="1px solid #c4c4c4"
                >
                  <StopFilled style={{ color: "#595959" }} />
                  <Typography
                    mb={0}
                    color="black2"
                    style={{ fontSize: "9px" }}
                  >
                    Not available
                  </Typography>
                </Box>
              )}
            </Col>
          );
        })}
      </Row>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={10}
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Avatar
            shape="circle"
            src={account?.profilePicUrl}
            size="small"
          />
          <UserNameContent menu={menu} isInnerAccountList={isInnerAccountList} account={account} />
        </Box>

        <Popover
          content={<AccountStats account={account} />}
          title={`${account?.username}’s stats`}
        >
          <BarChartOutlined />
        </Popover>
      </Box>
    </Box>
  );
}

export default Account
