// @ts-nocheck
import React from "react";
import { GiftingCampaign } from "src/generated/ApolloComponents";
import { Box } from "src/components/Core/Box";
import { Tag } from "antd";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { Typography } from "src/components/Core/Typography";

interface Props {
  campaign?: Partial<GiftingCampaign>;
}

export const GiftingCampaignBrief = ({ campaign }: Props) => {
  return (
    <Box borderRadius={5} background="white" padding={30} width="100%">
      <Box display="flex" alignItems="center" mb={30}>
        {campaign?.image && (
          <img
            src={campaign?.image || undefined}
            width={105}
            alt={campaign?.title}
          />
        )}
        <Box ml={15}>
          <Typography size={16} mb={0} color="black" weight="semi-bold">
            {campaign?.title}
          </Typography>
          <Typography size={14} mb={0}>
            Running dates: {moment(campaign?.startDate).format("Do MMMM YYYY")}{" "}
            - {moment(campaign?.endDate).format("Do MMMM YYYY")}
          </Typography>
          <Typography size={16} mb="5px">
            {campaign?.brief?.brandName} (
            <a
              href={campaign?.brief?.linkToWebsite || ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {campaign?.brief?.linkToWebsite}
            </a>
            )
          </Typography>
          {campaign?.brief?.campaignType && (
            <Box mb="5px">
              <Tag color="#2db7f5">
                {capitalize(campaign.brief?.campaignType.toString())}
              </Tag>
            </Box>
          )}

          {campaign?.brief?.platforms?.snapchat && (
            <Tag color="orange">Snapchat</Tag>
          )}
          {campaign?.brief?.platforms?.youtube && (
            <Tag color="orange">Youtube</Tag>
          )}
          {campaign?.brief?.platforms?.tiktok && (
            <Tag color="orange">Tiktok</Tag>
          )}
          {campaign?.brief?.platforms?.instagram && (
            <Tag color="orange">Instagram</Tag>
          )}
        </Box>
      </Box>

      <Typography size={16} mb="5px" color="black" weight="semi-bold">
        About brand
      </Typography>

      {campaign?.brief?.aboutBrand && (
        <Box mb={30}>
          {campaign?.brief.aboutBrand.split("\n").map((text, key) => (
            <Typography key={key} size={16}>
              {text}
            </Typography>
          ))}
        </Box>
      )}

      <Typography size={16} mb="5px" color="black" weight="semi-bold">
        About the giveaway or experience
      </Typography>

      {campaign?.brief?.aboutCampaign && (
        <Box mb={30}>
          {campaign?.brief.aboutCampaign.split("\n").map((text, key) => (
            <Typography key={key} size={16}>
              {text}
            </Typography>
          ))}
        </Box>
      )}

      <Typography size={16} mb="5px" color="black" weight="semi-bold">
        What will you need to do?
      </Typography>

      {campaign?.brief?.postingGuidlines && (
        <Box mb={30}>
          {campaign?.brief.postingGuidlines.split("\n").map((text, key) => (
            <Typography key={key} size={16}>
              {text}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};
