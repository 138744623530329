// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Field } from "src/components/Forms/Field";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import { TextInput } from "src/components/Forms/TextInput";
import TestLinkModal from "./TestLinkModal";
import { TestLinkContainer } from "./container";
import { Icon } from '@ant-design/compatible'

const CopyIcon = styled(Icon)`
  &&& {
    color: ${props => props.theme.colors.grey7} !important;
    margin-left: 10px;
    line-height: 38px;
    font-size: 16px;
    cursor: pointer;
  }
`;

interface Props {
  loading: boolean;
  data: any;
  showTestLinkModal: boolean;
  setShowTestLinkModal: (value: boolean) => void;
}

const TestLink: React.FC<Props> = ({
  loading,
  data,
  showTestLinkModal,
  setShowTestLinkModal,
}) => {
  const [linkCopyButtonAction, setLinkCopyButttonAction] = useState(false);
  return (
    <Box mt={20} mb={10}>
      <Field label="Test Link">
        <Box width="80%" display="inline-flex">
          <TextInput
            size="middle"
            value={data?.snaplink}
            placeholder="http://Trackinglink.com"
            readOnly={true}
          />

          {data?.snaplink && (
            <CopyToClipboard
              text={data?.snaplink}
              onCopy={() => {
                linkCopyButtonAction
                  ? setLinkCopyButttonAction(false)
                  : setLinkCopyButttonAction(true);
                setTimeout(() => setLinkCopyButttonAction(false), 1000);
              }}
            >
              <CopyIcon type="copy" />
            </CopyToClipboard>
          )}
        </Box>
      </Field>

      <Typography size={14} color="black2" position="absolute">
        {linkCopyButtonAction ? "Copied!" : ""}
      </Typography>

      <Button
        type="action"
        buttonSize="middle"
        data-cy="testlink-button"
        style={{ marginTop: "30px" }}
        onClick={() => setShowTestLinkModal(true)}
      >
        Test this link
      </Button>

      <TestLinkModal
        closeTestLinkModal={() => setShowTestLinkModal(false)}
        showTestLinkModal={showTestLinkModal}
        loading={loading}
        data={data}
      />
    </Box>
  );
};

export { TestLink, TestLinkContainer };
