import React, { Component } from "react";
import FormField from "../FormField";
import CurrencyInput from "src/shared/CurrencyInput";

export default class Budget extends Component {
  render() {
    const { name, rest } = this.props;
    return (
      <FormField name={name || "budget_pennies"} label="Budget">
        <CurrencyInput data-cy="budget" {...rest} />
      </FormField>
    );
  }
}
