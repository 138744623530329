// @ts-nocheck
import React from 'react'
import getColumns from './Columns'
import { Box } from 'src/components/Core/Box';
import { Table } from 'src/components/Core/TableManager'
import Loading from 'src/shared/Loading'
import { Row, Pagination } from 'antd'

interface Props {
  campaigns?: any
  loading: boolean
  handleFilter: Function
}

const CampaignTable = ({campaigns, loading, handleFilter}: Props) => {
  const { currentPage, totalEntries, perPage } = campaigns || {}

  return(
    <Box p={24}>
      <Loading spinning={loading}>
        <Table
          bordered
          rowKey="id"
          rowHover={true}
          pagination={false}
          scroll={{ y: 500, x: "max-content" }}
          dataSource={campaigns?.publicCampaigns || []}
          getColumns={() => getColumns()}
        />
        <Box mt={3}>
          <Row justify="end">
            <Pagination
              pageSize={perPage || 30}
              total={totalEntries}
              defaultCurrent={currentPage}
              onChange={page => handleFilter({page: page})}
            />
          </Row>
        </Box>
      </Loading>
    </Box>
  )
}

export default CampaignTable;
