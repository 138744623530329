// @ts-nocheck
import styled from "styled-components";
import { Upload } from "antd";

interface Props {}

const StyledUpload = styled(Upload)<Props>`
  && {
    display: inline !important;

    .ant-upload.ant-upload-select-picture-card {
      height: 57px;
      width: 57px;
      background-color: #f6f6f6;
      border: none;
      margin-top: 15px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 57px;
      width: 57px;
      padding: 0px !important;
      border: none;
      margin-top: 15px;
    }
    .anticon-upload {
      font-size: 20px;
      color: #bcbcbc;
    }

    .ant-upload-list-item-uploading-text {
      display: none;
    }

    .anticon-eye-o {
      display: none;
    }
    .anticon-close {
      display: none;
    }
  }
`;

export default StyledUpload;
