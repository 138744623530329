// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikAutoComplete } from "src/components/Forms/AutoComplete";
import { costTypes } from "src/constants/payments";

interface Props {
  value: string;
}

const CostType: React.FC<Props> = ({ value }) => {
  return (
    <Field label="Cost Type:" mb={26}>
      <FormikAutoComplete
        size="large"
        dataSource={costTypes}
        filterOption={(inputValue, option) => {
          if (option.props.children) {
            return (
              (option.props.children as string)
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            );
          }
          return false;
        }}
        background="background"
        borderRadius={3}
        borderColor="grey5"
        name="description"
        value={value}
      />
    </Field>
  );
};

export default CostType;
