import React, { Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Auth from "src/ducks/Auth";
import logo from "../../../assets/images/logo-white.png";
import flogo from "../../../assets/images/flogo.png";
import { Link } from "react-router-dom";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu, Layout, Tag } from "antd";
import { withRouter } from "react-router-dom";
import { useAppDetails } from "src/AppProvider";

const { Sider } = Layout;

const Logo = styled("img")`
  width: 100%;
  padding: 15px 30px;
  background: #002140;
`;

const StyledSider = styled(Sider)`
  .ant-layout-sider-zero-width-trigger {
    z-index: 101;
  }
`

const Sidebar = ({ user, location }) => {
  const { actions, collapsed } = useAppDetails();

  let menuItems = [
    {
      link: "/",
      icon: "appstore-o",
      title: "Dashboard",
    },
  ];

  if (user.type === "Admin") {
    menuItems = menuItems.concat([
      {
        link: "/campaigns",
        icon: "database",
        title: "Campaigns",
      },
      {
        link: "/companies",
        icon: "book",
        title: "Companies",
      },
      {
        link: "/agencies",
        icon: "container",
        title: "Agencies",
      },
      {
        link: "/accounts",
        icon: "user",
        title: "Accounts",
      },

      {
        link: "/discover",
        icon: "radar-chart",
        title: "Discover",
      },

      {
        link: "/influencers",
        icon: "star",
        title: "Influencers",
      },
      {
        link: "/influencers-list",
        icon: "star",
        title: "Influencers Search",
      },
      {
        link: "/payment-request",
        icon: "pay-circle",
        title: "Payments",
      },{
        link: "https://datastudio.google.com/u/0/reporting/7b7a5da8-9815-4ac1-8b21-5731899169a4/page/iGNxB",
        icon: "search",
        title: "Data Studio",
        target: "_blank"
      },
    ]);
  }

  if (user.type === "Brand") {
    menuItems = menuItems.concat([
      {
        link: "/gifting",
        icon: "gift",
        title: (
          <>
            Gifting <Tag color="#f50">Premium</Tag>
          </>
        ),
      },
    ]);
  }

  if (user.type === "Influencer") {
    menuItems = menuItems.concat([
      {
        link: "/my-campaigns",
        icon: "gift",
        title: "My campaigns",
      },
      {
        link: "/account",
        icon: "team",
        title: "Account",
      },
    ])

  } else {
    menuItems.push({
      link: "/team",
      icon: "usergroup-add",
      title: "Team",
    });
  }

  const getChildren = () => {
    return (
      <Fragment>
        <Link to="/">
          <Logo src={collapsed ? flogo : logo} />
        </Link>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={["/" + location.pathname.split("/")[1]]}
        >
          {menuItems.map(x => (
            <Menu.Item key={x.link}>
              <Link to={{pathname: x.link}} target={x.target}>
                <LegacyIcon type={x.icon} />
                <span>{x.title}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Fragment>
    );
  }

  return (
    window.innerWidth > 768 ?
      <Sider
        breakpoint="sm"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          zIndex: 2,
          left: 0,
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={(collapsed, type) => {
          if (type === "clickTrigger") {
            actions.setCollapsed(collapsed);
          }
        }}
      >
        {getChildren()}
      </Sider>
      :
      <StyledSider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {
          if (type === "clickTrigger") {
            actions.setCollapsed(collapsed);
          }
        }}
      >
        {getChildren()}
      </StyledSider>
  );
};

const mapStateToProps = state => ({
  user: Auth.selectUser(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
