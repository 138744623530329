// @ts-nocheck
import React from "react";
import { CreateNewCampaignForm } from ".";
import { string, object } from "yup";
import { message } from "antd";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetAllCompaniesQuery,
  useGetAllBrandsQuery,
  useCreateCampaignsToCampaignGroupMutation,
} from "src/generated/ApolloComponents";
import { useRouteMatch } from "react-router";

interface Props {
  closeCreateNewCampaignModal: () => void;
  getCampaignForCampaignGroup: () => void;
}

interface CreateNewCampaignFormValues {
  groupId: number;
  title: string;
  companyId: number;
  extraBrandIds: Array<number>;
}

export const CreateNewCampaignFormContainer: React.FC<Props> = props => {
  const match: any = useRouteMatch();
  const params: any = match?.params;
  const campaignGroupId = parseInt(params?.id);

  const company = useGetAllCompaniesQuery({
    skip: true,
  });

  const brands = useGetAllBrandsQuery({
    skip: true,
  });

  const [
    createCampaignsToCampaignGroupMutation,
    createCampaignGroup,
  ] = useCreateCampaignsToCampaignGroupMutation();

  const CreateNewCampaignSchema = object().shape({
    title: string()
      .nullable()
      .required("Please enter a title"),
    companyId: string()
      .nullable()
      .required("Please select company"),
  });

  let initialValues = {
    groupId: campaignGroupId,
    title: "",
    companyId: null,
    extraBrandIds: [],
  };

  const onSubmit = async (
    values: CreateNewCampaignFormValues,
    actions: FormikActions<CreateNewCampaignFormValues>
  ) => {
    try {
      const res = await createCampaignsToCampaignGroupMutation({
        variables: values,
      });
      if (res?.data?.createCampaignsToCampaignGroup?.id) {
        message.success("Campaign add successfully");
        props.getCampaignForCampaignGroup();
        props.closeCreateNewCampaignModal();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(createCampaignGroup?.error);

  return (
    <CreateNewCampaignForm
      initialValues={initialValues}
      createNewCampaignSchema={CreateNewCampaignSchema}
      isSubmitting={createCampaignGroup?.loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      closeCreateNewCampaignModal={props.closeCreateNewCampaignModal}
      getCompanies={company.refetch}
      getBrands={brands.refetch}
    />
  );
};
