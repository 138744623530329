// @ts-nocheck
import { string, object, number, boolean } from "yup";

export const NewAccountSchema = object().shape({
  username: string()
    .nullable()
    .required("Please enter a username"),
  platform: string()
    .nullable()
    .required("Please enter a platform"),
  status: string()
    .nullable()
    .required("Please enter a status"),
  cpm: number()
    .nullable()
    .required("Please enter a cpm"),

  createInfluencer: boolean(),
  influencerName: string()
    .nullable()
    .test("name", "Please enter a name", function(value) {
      const { createInfluencer, name } = this.parent;
      if (createInfluencer && !name) return value != null;
      return true;
    }),
  influencerEmail: string()
    .nullable()
    .test("email", "Please enter an email", function(value) {
      const { createInfluencer, email } = this.parent;
      if (createInfluencer && !email) return value != null;
      return true;
    }),

  influencerId: number().when("createInfluencer", {
    is: false,
    then: number()
      .nullable()
      .required("Please select an influencer"),
  }),
  format: string()
    .nullable()
    .required("Please enter a format"),
});
