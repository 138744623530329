// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Empty } from "antd";
import { Typography } from "src/components/Core/Typography";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";
import ChartDataType from "../ChartDataType";
import { useInfluencerDetails } from "../../../InfluencerProfileProvider";

interface Props {
  data01: Array<any>;
  data02?: Array<any>;
  title: string;
  isComposite?: boolean;
  chartDataType?: any;
  setChartDataType?: (value: string) => void;
  isShowFilter?: boolean;
}

const PieChartGraphCard: React.FC<Props> = ({
  title,
  data01,
  data02,
  isComposite,
  chartDataType,
  setChartDataType,
  isShowFilter,
}) => {
  const { platformType } = useInfluencerDetails();

  const renderLegend = props => {
    const { payload } = props;

    return (
      <ol style={{ marginRight: 30 }}>
        {payload.map((entry, index) => {
          if (entry?.payload?.message) {
            return (
              <li key={`item-${index}`} style={{ color: entry?.color }}>
                {entry?.payload?.message}
              </li>
            );
          }
          return null;
        })}
      </ol>
    );
  };

  return (
    <Box background="#FFFFFF" p={10} borderRadius="2px">
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottom="1px solid #E2E2E2"
      >
        <Box>
          <Typography size={12} weight="semi-bold" color="black">
            {title}
          </Typography>
        </Box>

        {isShowFilter && platformType === "fanbytes" && (
          <ChartDataType
            chartDataType={chartDataType}
            setChartDataType={value =>
              setChartDataType && setChartDataType(value)
            }
          />
        )}
      </Box>

      <Box>
        {data01?.length > 0 ? (
          <PieChart
            width={380}
            height={300}
            margin={{ top: -70, right: 0, bottom: 0, left: -100 }}
          >
            <Pie
              data={data01}
              nameKey="key"
              dataKey="value"
              cx={200}
              cy={200}
              innerRadius={isComposite ? 0 : 50}
              outerRadius={isComposite ? 60 : 90}
              fill={isComposite ? "#8884d8" : "#82ca9d"}
            >
              {data01.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Pie>

            {isComposite && (
              <Pie
                data={data02}
                nameKey="key"
                dataKey="value"
                cx={200}
                cy={200}
                innerRadius={70}
                outerRadius={90}
                fill="#82ca9d"
              >
                {(data02 || []).map((entry, index) => (
                  <Cell key={index} fill={entry.color} />
                ))}
              </Pie>
            )}

            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              content={renderLegend}
            />
            <Tooltip
              formatter={(value, name, props) => {
                return `${value}%`;
              }}
            />
          </PieChart>
        ) : (
          <Box p={85}>
            <Empty description="No data avaialble" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PieChartGraphCard;
