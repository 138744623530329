// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FilterProps } from "../../PaymentQueueList";
import { AutoCompleteInput } from "src/components/Forms/AutoComplete";
import { costTypes } from "src/constants/payments";

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const CostType: React.FC<Props> = ({ handleChange, filters }) => {
  return (
    <Field label="Type of Cost:">
      <AutoCompleteInput
        size="small"
        dataSource={costTypes}
        filterOption={(inputValue, option) => {
          if (option.props.children) {
            return (
              (option.props.children as string)
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            );
          }
          return false;
        }}
        background="background"
        borderRadius={3}
        borderColor="grey5"
        onChange={v => handleChange("typeOfCost", v)}
        value={filters?.typeOfCost}
      />
    </Field>
  );
};

export default CostType;
