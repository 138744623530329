// @ts-nocheck
import React, { useState, useEffect } from "react";
import { AccountDetail } from ".";
import { RouteComponentProps } from "react-router-dom";
import { useInfluencerDetails } from "../InfluencerProfileProvider";
import {
  useGetAccountDetailByIdQuery,
  DataSourcePlatform,
} from "src/generated/ApolloComponents";

interface Props {}

export const AccountDetailContainer: React.FC<Props & RouteComponentProps> = ({
  match,
}) => {
  let accountId = match.params["id"];
  const { platformType } = useInfluencerDetails();
  const [doRefresh, setDoRefresh] = useState(false);

  const newPlatformType =
    platformType.charAt(0).toUpperCase() + platformType.slice(1);

  if (newPlatformType === DataSourcePlatform.Fanbytes) {
    accountId = match.params["accountId"];
  }

  const account = useGetAccountDetailByIdQuery({
    variables: {
      accountId: accountId,
      platformType: newPlatformType,
    },
  });

  useEffect(() => {
    account.refetch({
      accountId: accountId,
      platformType: newPlatformType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <AccountDetail
      loading={account?.loading}
      accountId={accountId}
      platformType={newPlatformType}
      doRefresh={doRefresh}
      setDoRefresh={value => setDoRefresh(value)}
      account={account}
    />
  );
};
