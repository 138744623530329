import React, { Component } from "react"
import { withFormik } from "formik"
import { Button, message } from "antd"
import { Form } from "formik-antd"
import { Note } from "src/shared/InvitationFields"
import { object } from "yup"
import FormError from "src/shared/FormField/FormError"

const Validate = object().shape({})

export class AnalyticsForm extends Component {
  render() {
    const { isSubmitting, status } = this.props

    return (
      <Form>
        {status &&
          Object.values(status.meta.errors).map((error, i) => (
            <FormError key={i} style={{ margin: "0 0 10px 0" }} message={error} />
          ))}
        <Note />
        <Button
          loading={isSubmitting}
          disabled={isSubmitting}
          htmlType="submit"
          type="primary"
          size="large"
        >
          Update Note
        </Button>
      </Form>
    )
  }
}

export default withFormik({
  validateOnBlur: false,
  enableReinitialize: true,

  mapPropsToValues: ({ invitation }) => (invitation ? invitation.ref : Validate.cast()),
  validationSchema: () => Validate,

  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus }) => {
    const { updateInvitation, invitation, onUpdate } = props

    updateInvitation(invitation.id, values)
      .then(() => {
        setSubmitting(false)
        message.success("Invitation updated!")
        onUpdate && onUpdate(values)
      })
      .catch((err) => {
        setSubmitting(false)
        setStatus(err.response.data)
      })
  }
})(AnalyticsForm)
