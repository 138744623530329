// @ts-nocheck
import React, { Fragment, useState, useEffect } from "react";
import { FormikProps } from "formik";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { locations } from "src/constants/account";
import { FormikSelect } from "src/components/Forms/Select";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";

import { FieldError } from "src/components/Forms/ErrorMessage";

interface Props {
  formProps?: FormikProps<any>;
}

const LocationBreakdown: React.FC<Props> = ({ formProps }) => {
  const [other, setOther] = useState(0 as number);

  let drodownData = locations;

  let locationSum = 0;

  let topTwoLocationBreakdown = [] as Array<any>;
  let locationBreakdownArray = formProps?.values["locationBreakdown"] as Array<
    any
  >;

  if (formProps?.values["locationBreakdown"].length > 0) {
    let sortLocationBreakdownArray = orderBy(
      locationBreakdownArray,
      ["value"],
      ["desc"]
    );

    topTwoLocationBreakdown = sortLocationBreakdownArray.slice(0, 2);

    locationSum = sumBy(topTwoLocationBreakdown, function(location: any) {
      return location?.value;
    });
  }

  useEffect(() => {
    if (locationSum <= 100) {
      setOther(Number(100 - locationSum));
    }
  }, [locationSum]);

  drodownData = drodownData.filter((item: any, index) => {
    const newItem = { ...item };
    if (
      !find(
        formProps?.values["locationBreakdown"],
        matchesProperty("key", newItem?.value)
      )
    ) {
      return true;
    }
    return false;
  });

  return (
    <Fragment>
      <Typography
        mb={26}
        mt={26}
        fontWeight="semi-bold"
        color="black"
        size={16}
      >
        Location breakdown
      </Typography>
      <Box display="flex">
        <Box width="50%" mr="5px">
          <Typography size={14} color="grey2">
            Label:
          </Typography>
        </Box>
        <Box width="50%">
          <Typography size={14} color="grey2">
            Percentage:
          </Typography>
        </Box>
      </Box>

      {topTwoLocationBreakdown.map((value, i) => (
        <Box display="flex" key={i} marginBottom={10} mt={10}>
          <Box width="40%" mr="5px">
            <FormikSelect
              name={`locationBreakdown[${i}].key`}
              data={drodownData}
              local
              borderRadius={2}
              borderColor="grey5"
              size="middle"
              value={value?.key}
            />
          </Box>
          <Box width="40%">
            <FormikInputNumber
              parser={value => {
                let parsed = value.replace(/\[0-9]/g, "");
                if (parsed) {
                  return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                } else {
                  return null;
                }
              }}
              name={`locationBreakdown[${i}].value`}
              borderRadius={2}
              borderColor="grey5"
              size="middle"
              value={value?.value}
              // onChange={() =>
              //   formProps.setFieldTouched("locationBreakdown", true)
              // }
            />
          </Box>
          <Box width="10%" mt={10} ml={10}>
            %
          </Box>
        </Box>
      ))}

      <Box display="flex" marginBottom={10} mt={10}>
        <Box width="40%" mr="5px">
          Other
        </Box>
        <Box width="40%">{other}%</Box>
      </Box>

      <FieldError
        errors={formProps?.errors as any}
        name={`locationBreakdown`}
      />
    </Fragment>
  );
};
export default LocationBreakdown;
