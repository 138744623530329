// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { Row, Col } from "antd";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { InvitationContentFormContainer } from "./container";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import ReactVideoPlayer from "src/components/Modules/ReactVideoPlayer";
import {
  UploadDraft,
  Progress,
  IconMessage,
  ProcessFeedback,
  YoutubeDraftUrl,
  FeedbackList,
} from "./components";
import { Visible } from "react-grid-system";
import FormButtons from "../FormButtons";

interface InvitationContentFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  invitationContentSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  draftData: any;
  isShowAwaitingScreen: boolean; // For show Awaiting Feedback Screen PageHeading
  isFeedback: boolean; // If we get FeedbackList
  isShowProgress: boolean;
  progressPercentage: number;
  goBack: () => void;
  index: number;
}

export const InvitationContentFormWrapper = styled.div`
  width: 100%;
`;

const InvitationContentForm: React.FC<InvitationContentFormProps> = ({
  initialValues,
  isSubmitting,
  invitationContentSchema,
  onSubmit,
  globalErrors,
  draftData,
  isFeedback,
  isShowProgress,
  isShowAwaitingScreen,
  progressPercentage,
  goBack,
  index,
}) => {
  return (
    <InvitationContentFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Box p={24}>
          <Box>
            <Visible xl lg>
              <Typography size={20} color="black6" lineHeight="20px">
                {draftData?.stage !== "approved" &&
                  isFeedback &&
                  !isShowAwaitingScreen && <>Content - Feedback submitted</>}

                {draftData?.stage !== "approved" && !isFeedback && <>Content</>}
              </Typography>
            </Visible>

            <Typography size={16} color="grey2">
              {draftData?.stage === "approved" && (
                <div>
                  You draft has been seen and accepted by the brand. Your
                  campaign manager will be in touch with next steps shortly.
                </div>
              )}

              {draftData?.stage !== "approved" &&
                !isFeedback &&
                !isShowAwaitingScreen && (
                  <>Please submit your content by clicking the button below</>
                )}

              {draftData?.stage !== "approved" &&
                isFeedback &&
                !isShowAwaitingScreen && (
                  <>
                    The brand has come back with some edits, please review and
                    re-submit:
                  </>
                )}

              {isShowAwaitingScreen && (
                <div>
                  You draft has been submitted and somebody from our team will
                  be in touch with you within 48 hours.
                </div>
              )}
            </Typography>

            {draftData?.stage !== "approved" && (
              <Form
                initialValues={initialValues}
                schema={invitationContentSchema}
                onSubmit={(values, actions) => onSubmit(values, actions)}
              >
                {(formProps: any) => {
                  return (
                    <>
                      <Row gutter={12}>
                        {isFeedback && (
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <FeedbackList
                              feedbacks={
                                draftData?.drafts?.[0]?.adminFeedback || []
                              }
                              type={draftData?.drafts?.[0]?.type}
                            />
                          </Col>
                        )}

                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          {!isShowProgress && !isShowAwaitingScreen && (
                            <>
                              {formProps?.values?.type === "youtube" ? (
                                <>
                                  <YoutubeDraftUrl />

                                  {formProps?.values?.youtubeDraftUrl && (
                                    <ReactVideoPlayer
                                      videoUrl={
                                        formProps?.values?.youtubeDraftUrl
                                      }
                                      shouldShowControls={true}
                                      isPlay={false}
                                    />
                                  )}
                                </>
                              ) : (
                                <UploadDraft
                                  name={"drafts"}
                                  isShowProgress={false}
                                  isFeedback={false}
                                />
                              )}
                            </>
                          )}

                          {/* After click on "Send new Draft" Button isShowAwaitingScreen=false and
                isShowProgress become true and it show Progress Circle */}
                          {isShowAwaitingScreen && isShowProgress && (
                            <Progress
                              type="circle"
                              percent={progressPercentage}
                              width={80}
                            />
                          )}

                          {/* After Upload Video Succesfull isShowAwaitingScreen= true and thumb like screen show */}
                          {isShowAwaitingScreen && (
                            <>
                              <IconMessage type="confirm" message="Success!" />
                              <br />
                              <ProcessFeedback draftData={draftData} />
                            </>
                          )}
                        </Col>
                      </Row>

                      {!isShowProgress && !isShowAwaitingScreen && (
                        <FormButtons
                          index={index}
                          goBack={goBack}
                          onSubmit={formProps.handleSubmit}
                          isSubmitting={isSubmitting}
                          isDisabled={isSubmitting}
                          id="hub-content"
                        />
                      )}
                    </>
                  );
                }}
              </Form>
            )}
          </Box>
        </Box>
      </Loading>
    </InvitationContentFormWrapper>
  );
};

export { InvitationContentForm, InvitationContentFormContainer };
