// @ts-nocheck
import React, { useState } from "react";
import Form from "src/components/Forms/Form";
import { Row, Col, Popover } from "antd";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { PostDetailFormContainer } from "./container";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  Views,
  TikTokUrl,
  ViewCount,
  PostLength,
  Likes,
  DisLikes,
  Comments,
  WatchTime,
} from "./FormFields";
import { Visible } from "react-grid-system";
import { ScreenshotsContainer } from "../../../components/Fields/Screenshots";
import AnalyticsScreenshotExample from "../../../components/AnalyticsScreenshotExample";
import YoutubeScreenshotModal from "./YoutubeScreenshotModal";
import FormButtons from "../FormButtons";

interface PostDetailFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  campaignDetailSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  cashoutData: any;
  setShowExample: (value: object) => void;
  setScreenshotType: (value: string) => void;
  showExample: object;
  type: string;
  goBack: () => void;
  index: number;
}

export const PostDetailFormWrapper = styled.div`
  width: 100%;
`;

const PostDetailForm: React.FC<PostDetailFormProps> = ({
  initialValues,
  isSubmitting,
  campaignDetailSchema,
  onSubmit,
  globalErrors,
  cashoutData,
  setShowExample,
  setScreenshotType,
  showExample,
  type,
  goBack,
  index,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [showYoutubeScreenshot, setShowYoutubeScreenshot] = useState(false);

  return (
    <PostDetailFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Box p={24}>
          <Visible xl lg>
            <Typography size={20} color="black6" lineHeight="20px">
              Content - Post details
            </Typography>
          </Visible>

          <Typography size={16} color="grey2">
            Please provide analytics for the posts related to this campaign.
          </Typography>

          <Form
            initialValues={initialValues}
            schema={campaignDetailSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {(formProps: any) => {
              return (
                <>
                  {type === "youtube" && (
                    <>
                      <Row gutter={12}>
                        <Col xs={12}>
                          <ViewCount />
                        </Col>
                        <Col xs={12}>
                          <Comments />
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={12}>
                          <Likes />
                        </Col>
                        <Col xs={12}>
                          <DisLikes />
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={12}>
                          <WatchTime errors={formProps?.errors} />
                        </Col>
                        <Col xs={12}>
                          <PostLength errors={formProps?.errors} />
                        </Col>
                      </Row>
                      <Typography size={16} color="grey2">
                        Please provide screenshots validating each data point.
                        <span
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={e => {
                            e.stopPropagation();
                            setShowYoutubeScreenshot(true);
                          }}
                        >
                          Click here{" "}
                        </span>
                        if you are unsure what to provide
                      </Typography>
                    </>
                  )}

                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Visible xl lg>
                        <Popover
                          content={
                            <AnalyticsScreenshotExample
                              hideExample={() =>
                                setShowExample({
                                  age: false,
                                  location: false,
                                  gender: false,
                                  campaign: false,
                                  campaignScreenshot: false,
                                })
                              }
                              showExample={showExample["campaignScreenshot"]}
                              platformType={cashoutData?.account?.type || ""}
                              screenshotType="campaign"
                            />
                          }
                          trigger="click"
                          visible={showExample["campaignScreenshot"]}
                          onVisibleChange={value => {
                            if (!value) {
                              setShowExample({
                                age: false,
                                location: false,
                                gender: false,
                                campaign: false,
                                campaignScreenshot: false,
                              });
                            }
                          }}
                        >
                          <span
                            style={{
                              color: "#1890ff",
                              cursor: "pointer",
                              marginBottom: 20,
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              setScreenshotType("campaign");
                              setShowExample({
                                age: false,
                                location: false,
                                gender: false,
                                campaign: true,
                                campaignScreenshot: false,
                              });
                            }}
                          >
                            What should my screenshots look like?
                          </span>
                        </Popover>
                      </Visible>
                      <Visible xs sm md>
                        <span
                          style={{
                            color: "#1890ff",
                            cursor: "pointer",
                            marginBottom: 20,
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            setShowExample({
                              age: false,
                              location: false,
                              gender: false,
                              campaign: true,
                            });
                            setScreenshotType("campaign");
                          }}
                        >
                          What should my screenshots look like?
                        </span>
                      </Visible>

                      <Box mb={40} mt={20}>
                        <ScreenshotsContainer
                          name="files"
                          setIsUploading={loading => setIsUploading(loading)}
                          setFieldValue={value =>
                            formProps?.setFieldValue("files", value)
                          }
                          setFieldTouched={() =>
                            formProps?.setFieldTouched("files", true)
                          }
                          values={formProps?.values}
                          platformType={cashoutData?.account?.type || ""}
                          accountName={cashoutData?.account?.username || ""}
                          screenshotType="campaign"
                          multiple={true}
                          disabled={false}
                          setShowExample={() =>
                            setShowExample({
                              age: false,
                              location: false,
                              gender: false,
                              campaign: true,
                            })
                          }
                          setScreenshotType={() =>
                            setScreenshotType("campaign")
                          }
                          showExample={showExample}
                          hideExample={() =>
                            setShowExample({
                              age: false,
                              location: false,
                              gender: false,
                              campaign: false,
                            })
                          }
                        />
                      </Box>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      {type !== "youtube" && <Views />}

                      {cashoutData?.account?.type === "TikTok" && <TikTokUrl />}
                    </Col>
                  </Row>

                  <FormButtons
                    index={index}
                    goBack={goBack}
                    onSubmit={formProps.handleSubmit}
                    isSubmitting={isSubmitting}
                    isDisabled={isSubmitting || isUploading}
                    id="hub-post-detail"
                  />
                </>
              );
            }}
          </Form>
        </Box>
      </Loading>
      <YoutubeScreenshotModal
        closeYoutubeScreenshotModal={() => {
          setShowYoutubeScreenshot(false);
        }}
        showYoutubeScreenshot={showYoutubeScreenshot}
      />
    </PostDetailFormWrapper>
  );
};

export { PostDetailForm, PostDetailFormContainer };
