// @ts-nocheck
import * as React from "react";
import { useGetNotesByInfluencerIdQuery } from "src/generated/ApolloComponents";
import { useRouteMatch } from "react-router";

interface ContextType {
  isLoading: boolean;
  notesErrors?: any;
  influencerNotesDetails?: any;
  actions: {
    refetchNotes: () => void;
  };
}

const InfluencerNotesContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our influencer detail context
export function useInfluencerNotesDetails() {
  const context = React.useContext(InfluencerNotesContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
const BaseInfluencerNotesProvider = React.memo((props: any) => {
  const match: any = useRouteMatch();
  const params: any = match?.params;

  //get influencer id
  const influencerId = parseInt(params.id);

  // we only want to call notes list query only on notes list page
  const shouldNotSkip =
    params.page === "notes" && params.accountId === undefined;

  const { data, loading, error, refetch } = useGetNotesByInfluencerIdQuery({
    variables: {
      influencerId: influencerId,
    },
    skip: !shouldNotSkip,
  });

  // refetch notes
  const refetchNotes = () => {
    refetch({
      influencerId: influencerId,
    });
  };

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      influencerNotesDetails: data?.getNotesByInfluencerId,
      isLoading: loading,
      notesErrors: error,
      actions: {
        refetchNotes,
      },
    };
    return internalVal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  return (
    <InfluencerNotesContext.Provider value={value}>
      {props.children}
    </InfluencerNotesContext.Provider>
  );
});

const InfluencerNotesProvider = React.memo(BaseInfluencerNotesProvider);

export { InfluencerNotesProvider };
