import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography } from "src/components/Core/Typography";
import Container from "src/shared/Container";
import InvitationsTable from "./InvitationsTable";
import { AccountPersist } from "src/ducks/Persist";
import { default as AccountDescriptions } from "src/shared/AccountDetails";
import AccountDemographic from "./AccountDemographic";

const ShowAccount = AccountPersist("AdminAccountShow");

export class AccountDetails extends Component {
  render() {
    const { account } = this.props;

    return (
      <Container style={{ marginTop: 24 }}>
        <AccountDemographic account={account} />

        <Typography color="black" size={18}>
          Account owner details
        </Typography>

        <div style={{ background: "#fff" }}>
          <AccountDescriptions account={account} />
        </div>
        <InvitationsTable style={{ marginTop: 24, marginBottom: 24 }} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  account: ShowAccount.selectOne(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
