import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter, Switch, Route } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import { PageHeader, Layout } from "antd";
import DashboardLayout from "src/scenes/layouts/DashboardLayout";
import { InfluencerPersist } from "src/ducks/Persist";
import Loading from "src/shared/Loading";
import InfluencerDetails from "./InfluencerDetails";
import UpdateInfluencer from "./UpdateInfluencer";
import InfluencerProfile from "./InfluencerProfile";
import Payouts from "./Payouts";
import SideMenu from "./SideMenu";
import { useAppDetails } from "src/AppProvider";

const { Content, Sider } = Layout;

const ShowInfluencer = InfluencerPersist("AdminInfluencerShow");

const modules = [ShowInfluencer.module()];

const BorderHeader = styled(PageHeader)`
  border-bottom: 1px solid #e8e8e8;
`;

const InfluencerShow = props => {
  const { collapsed } = useAppDetails();

  useEffect(() => {
    const {
      loadInfluencer,
      match: {
        params: { id },
      },
    } = props;
    loadInfluencer(id, {
      params: {
        expand: ["agency"],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { history, influencer, loading, match } = props;

  return (
    <DashboardLayout title={influencer ? influencer.name : "Loading..."}>
      <Loading spinning={!influencer || loading}>
        {influencer && (
          <>
            <BorderHeader
              style={{
                top: "68px",
                width: `calc(100% - ${collapsed ? 80 : 200}px)`,
                overflow: "auto",
                position: "fixed",
                zIndex: 60,
              }}
              onBack={history.goBack}
              title={influencer.name}
            />
            <Layout>
              <Sider
                width={200}
                style={{
                  background: "#fff",
                  overflow: "auto",
                  height: "auto",
                  minHeight: "100vh",
                  marginTop: 55,
                  position: "fixed",
                }}
              >
                <SideMenu />
              </Sider>
              <Content
                style={{
                  marginLeft: 200,
                  marginTop: 58,
                  height: "calc(100vh - 126px)",
                }}
              >
                <Switch>
                  <Route
                    path={`${match.path}/update`}
                    exact
                    component={UpdateInfluencer}
                  />
                  <Route
                    path={`${match.path}/payouts`}
                    exact
                    component={Payouts}
                  />
                  <Route
                    path={`${match.path}/profile`}
                    exact
                    component={InfluencerProfile}
                  />
                  <Route
                    path={`${match.path}`}
                    exact
                    component={InfluencerDetails}
                  />
                </Switch>
              </Content>
            </Layout>
          </>
        )}
      </Loading>
    </DashboardLayout>
  );
};

const mapStateToProps = state => ({
  influencer: ShowInfluencer.selectOne(state),
  loading: ShowInfluencer.selectGetPending(state),
});

const mapDispatchToProps = {
  loadInfluencer: ShowInfluencer.get,
};

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InfluencerShow))
)(modules);
