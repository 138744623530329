// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { locations } from "src/constants/account";

interface Props {}

const Locations: React.FC<Props> = () => {
  return (
    <Field label="Location:" mb={26}>
      <FormikSelect
        name="location"
        size="middle"
        placeholder="Select..."
        data={locations}
        local
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Locations;
