// @ts-nocheck
import styled from "styled-components";

export const Wrapper = styled("div")`
  position: relative;
  align-items: center;
  padding: 10px 0;

  h3 {
    flex: 1;
    font-size: 12px;
    letter-spacing: 0.98px;
    line-height: 22px;
    text-align: center;
  }

`;

export const DraftText = styled("p")`
  &&& {
    flex: 8;
    text-align: justify;
    color: #575757;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 25px;
    margin-left: 5px;
    margin-bottom: 0px;
  }
`;

export const DraftTime = styled("p")`
  &&& {
    flex: 16;
    text-align: justify;
    color: #40a9ff;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 15px;
    margin-left: 40px;
  }
`;
