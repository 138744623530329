import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Button, Dropdown, Menu } from "antd";
import { connect } from "react-redux";
import CurrentUser from "./CurrentUser";
import Auth from "src/ducks/Auth";
import flogo from 'src/assets/images/cortex-fav.svg'

const { Header } = Layout;

const Wrapper = styled(Header)`
  && {
    height: 68px;
    display: flex;
    align-items: center;
    background: #ffffff;
    z-index: 100;
    width: calc(100% - ${props => props.offset}px);
    position: fixed;
    box-shadow: 0 1px 4px 0 rgba(50, 64, 77, 0.2);
    @media screen and (max-width: 768px) {
      width: auto;
      margin: auto;
      position: initial;
      background: inherit;
      box-shadow: none;
    }
  }
`;


export class Nav extends Component{
  handleLogout = () => {
    const { logoutUser, history } = this.props;
    logoutUser();
    history.push("/sign_in");
  };

  render() {
    const { user, sidebarWidth } = this.props;

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="/settings">Settings</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" onClick={this.handleLogout}>
          Logout
        </Menu.Item>
      </Menu>
    );

    return (
      <Wrapper offset={sidebarWidth}>
        {user.type === "Brand" && (
          <Link data-cy="link-to-new-campaign" to="/campaigns/new">
            <Button type="primary" size="large">
              Launch a new Campaign
            </Button>
          </Link>
        )}
        {window.innerWidth > 767 ?
          <CurrentUser>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="link">
                <CurrentUser.Avatar size="large" icon={<UserOutlined />} />
                <CurrentUser.Name>{user.name}</CurrentUser.Name>
                <CurrentUser.Icon type="down" />
              </Button>
            </Dropdown>
          </CurrentUser>
          :
          <Fragment>
            <img src={flogo} alt='fanbytes' />
            <LogoutOutlined
              style={{
                fontSize: '30px',
                position: 'absolute',
                marginLeft: '40%',
                marginTop: '10px'
              }} />
          </Fragment>
        }
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  user: Auth.selectUser(state),
});

const mapDispatchToProps = {
  logoutUser: Auth.logoutUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));
