// @ts-nocheck
import React from "react";
import { Input as AntdInput } from "antd";
import styled from "styled-components";

const { TextArea } = AntdInput;

interface AutoSize {
  minRows: number;
  maxRows: number;
}

interface Props {
  name?: string;
  type?: "text" | "textarea";
  size?: "large" | "default" | "small";
  value?: string | number;
  defaultValue?: string | number;
  disabled?: boolean;
  loading?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  onChange?: (event?: any) => void;
  autoSize?: AutoSize;
  className?: string;
  onBlur?: () => void;
  autoFocus?: boolean;
}

const OriginalInput = props =>
  props.type === "textarea" ? (
    <TextArea {...props} />
  ) : (
    <AntdInput {...props} />
  );

const StyledInput = styled(OriginalInput)`
  && {
    background: #f5f5f5;

    :focus {
      background: #fff;
    }
  }
`;

export const Input: React.FC<Props> = (props) => <StyledInput {...props} />

export default Input;
