// @ts-nocheck
import React from "react";
import { FormikInput } from "src/components/Forms/TextInput";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';

interface Props {
  formProps: FormikProps<any>;
  setModalVisible: () => void;
}

const Title = ({ formProps, setModalVisible }: Props) => {

  const getSuffix = () => {
    return window.innerWidth > 768 ?
      <></>
      :
      <FilterOutlined onClick={setModalVisible} />;
  }

  return (
    <Field
      mb={20}
      color="black"
      isBeside={true}
      id="searchInput"
      >
      <FormikInput
        size="middle"
        borderRadius={3}
        name="searchInput"
        placeholder="Search campaigns"
        prefix={<SearchOutlined />}
        onPressEnter={e => formProps.handleSubmit()}
        suffix={getSuffix()}
      />
    </Field>
  );
};

export default Title;
