// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { FormikRatingInput } from "src/components/Forms/Rating";
import { Box } from "src/components/Core/Box";

interface Props {}

const CreationTime: React.FC<Props> = () => {
  return (
    <Box mb={26}>
      <Typography size={20} fontWeight="semi-bold" color="black" lineHeight={0}>
        Creation time
      </Typography>
      <Typography size={14} color="grey7">
        From the time of briefing how quickly did you recieve content
      </Typography>
      <FormikRatingInput name="creationTime" size={24} />
    </Box>
  );
};

export default CreationTime;
