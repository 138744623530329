import {mapKeys, snakeCase, camelCase} from 'lodash';

const camelToSnake = (obj) => {
    return mapKeys(obj, (value: string, key: string) => {
        return snakeCase(key);
    })
}

const snakeToCamel = (obj: Object) => {
    return mapKeys(obj, (value: string, key: string) => {
        return camelCase(key);
    })
}

export {camelToSnake, snakeToCamel}
