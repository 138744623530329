// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Badge as AntdBadge } from "antd";
import {
  space,
  SpaceProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
} from "styled-system";
import { FontWeights, FontSizes, ThemeColors } from "src/utils/theme";
interface Props {
  children?: React.ReactNode;
  weight?: FontWeights;
  size?: FontSizes;
  color?: ThemeColors;
  background?: ThemeColors;
  height?: number;
  width?: number;
}

// @ts-ignore
const StyledBadge = styled(({ buttonSize, ...props }) => (
  // @ts-ignore
  <AntdBadge {...props} />
))`
  && {
    height: ${props => (props.height ? props.height : 25)}px !important;
    width: ${props => (props.width ? props.width : 78)}px !important;
    background: ${props =>
      props.background && props.theme.colors[props.background]};
    border-color: ${props =>
      props.background && props.theme.colors[props.background]};
    color: ${props => props.color && props.theme.colors[props.color]};
    font-size: ${props => props.size}px;
    border-radius: 2px;
    line-height: 23px;
    text-align: center;
    font-weight: 400;
    .ant-badge-dot
    {
      display:none !important;
    }
  }
  ${space}
  ${typography}
  ${layout}
`;

export type BadgeProps = SpaceProps & TypographyProps & LayoutProps;

export const Badge: React.FC<Props & BadgeProps> = props => (
  <StyledBadge {...props} />
);

export default Badge;
