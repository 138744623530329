// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import compact from "lodash/compact";
import numeral from "numeral";

const getColumns = () => {
  const columns = [
    {
      title: "Account name",
      dataIndex: "title",
      key: "title",
      width: 200,
      visible: true,
      render: (text, row) => (
        <a
          href={`https://tiktok.com/@${row?.accountName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography size={12} cursor="pointer" color="blue" mb={0}>
            {row?.accountName ? row?.accountName : "-"}
          </Typography>
        </a>
      ),
    },
    {
      title: "Engagement rate",
      dataIndex: "engagementRate",
      key: "engagementRate",
      width: 120,
      visible: true,
      render: (text, row) =>
        numeral(row?.engagementRate).format("0,0.00") + "%",
    },
    {
      title: "Avg. Comments",
      dataIndex: "averageComments",
      key: "averageComments",
      width: 120,
      visible: true,
      render: (text, row) => numeral(row?.averageComments).format("0a"),
    },
    {
      title: "Avg. Views",
      dataIndex: "averageViews",
      key: "averageViews",
      width: 130,
      visible: true,
      render: (text, row) => numeral(row?.averageViews).format("0a"),
    },
    {
      title: "Follower Count",
      dataIndex: "followers",
      key: "followers",
      width: 130,
      visible: true,
      render: (text, row) => numeral(row?.followers).format("0a"),
    },
  ];

  return compact(columns);
};

export default getColumns;
