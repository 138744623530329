import { fk, Model, attr } from "redux-orm";

class Link extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Campaign } = this.session;

    const parsedData = {
      ...data,
      campaign: Campaign.parse(data.campaign)
    };

    return this.upsert(parsedData);
  }
}
Link.modelName = "Link";

// Declare your related fields.
Link.fields = {
  id: attr(),
  campaign: fk("Campaign")
};

export default Link;
