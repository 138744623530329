// @ts-nocheck
import styled from 'styled-components'
import { Col } from 'antd'

export const StyledCol = styled(Col)`
  top: 5px;
  padding-right: 15px;
`

export const ButtonCol = styled(Col)`
  padding-right: 10px;
`

export const TipCol = styled(Col)`
  margin-top: 14px;
`

export const StyledDiv = styled.div`
  margin-bottom: 30px;
`
