// @ts-nocheck
import React, { useState } from 'react'
import MilestoneContainer from './container'
import { Box } from 'src/components/Core/Box'
import { Row, Col } from 'antd'
import NewMilestoneModal from './NewMilestoneModal'
import Card from './Card'
import { Loading } from 'src/components/Core/Loading'
import styled from 'styled-components'
import {
  GlobalErrorProps,
  GlobalErrors,
} from 'src/components/Forms/ErrorMessage'
import moment from 'moment'
import Header from './Header'

const StyledCol = styled(Col)`
  margin-top: 10px;
  margin-bottom: 10px;
`

interface Props {
  milestones: any[]
  refetch: any
  loading?: any
  globalErrors?: GlobalErrorProps[]
  user: any
}

const Milestone = ({milestones, refetch, loading, globalErrors, user}: Props) => {
  const checkedAtVals = milestones?.map(ms => ms.checkedAt)?.filter(n => n)?.sort((a, b) => a > b ? 1 : -1)
  const lastCheckedAt = checkedAtVals[0] ? moment(checkedAtVals[0]).format("DD.MM.YYYY") : 'N/A'
  const [showNewMileStoneForm, setShowNewMileStoneForm] = useState(false)
  const [selectedMilestone, setSelectedMilestone] = useState()
  const toggleVisibility = () => {
    if(showNewMileStoneForm) {
      setSelectedMilestone(undefined)
    }
    setShowNewMileStoneForm(!showNewMileStoneForm)
  }
  return(
    <Box background="#FFFFFF" p={24}>
      <GlobalErrors errors={globalErrors} />
      <Header
        setShowNewMileStoneForm={setShowNewMileStoneForm}
        lastCheckedAt={lastCheckedAt}
        user={user}
      />
      {user.type === "Admin" &&
        <NewMilestoneModal
          show={showNewMileStoneForm}
          toggleVisibility={toggleVisibility}
          milestone={selectedMilestone}
          refetch={refetch}
        />
      }
      <Loading spinning={loading}>
        <Row gutter={16}>
          {milestones.map(milestone =>
            <StyledCol key={milestone.id} span={12} onClick={() => setSelectedMilestone(milestone)} >
              <Card toggleVisibility={toggleVisibility} milestone={milestone} user={user} />
            </StyledCol>
          )}
        </Row>
      </Loading>
    </Box>
  )
}

export { Milestone, MilestoneContainer }
