// @ts-nocheck
import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box } from "src/components/Core/Box";
import { Layout } from "antd";
import HeroActions from "./HeroActions";
import { InfluencerAccountListContainer } from "./InfluencerAccountList";
import { SidebarBox, ContentBox } from "./Wrapper";
import { FilterFormContainer } from "./SidebarFilter";
import { InfluencersListProvider } from "./InfluencersListProvider";
import { useAppDetails } from "src/AppProvider";
import NewAccountModalWrapper from "./NewAccountModalWrapper";

const InfluencersList = () => {
  const { collapsed } = useAppDetails();
  const [showNewAccountForm, setShowNewAccountForm] = useState(false);

  return (
    <DashboardLayout title="Cortex Influencer Campaign Manager">
      <InfluencersListProvider>
        <HeroActions
          title="Influencers"
          exportData={true}
          openAccountModal={() => setShowNewAccountForm(true)}
        />

        <Box p={15}>
          <Layout>
            <SidebarBox width={collapsed ? 320 : 200}>
              <FilterFormContainer />
            </SidebarBox>
            <ContentBox>
              <InfluencerAccountListContainer />
            </ContentBox>
          </Layout>
        </Box>

        <NewAccountModalWrapper
          closeAccountModal={() => setShowNewAccountForm(false)}
          showNewAccountForm={showNewAccountForm}
        />
      </InfluencersListProvider>
    </DashboardLayout>
  );
};

export default InfluencersList;
