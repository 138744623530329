// @ts-nocheck
import { string, object, number, boolean, array } from "yup";

export const PaymentRequestSchema = object().shape({
  campaignId: string().required("Please select a campaign"),
  // .nullable()
  // .test(
  //   "campaignId",
  //   "You must select a campaign if this is an influencer payout",
  //   function(value) {
  //     const { description } = this.parent;

  //     if (
  //       description &&
  //       description.replace(/\s+/g, "").toLowerCase() === "influencerpayout"
  //     )
  //       return value != null;
  //     return true;
  //   }
  // ),

  description: string()
    .nullable()
    .required("Please enter Cost Type"),
  isVATRegister: boolean(),

  vatNumber: string().when("isVATRegister", {
    is: true,
    then: string()
      .nullable()
      .required("Please enter VAT Number"),
  }),
  currency: string()
    .nullable()
    .required("Please select curremcy"),
  amount: number()
    .typeError("Please enter valid amount")
    .test("amount", "Please enter amount below 100k", function(value) {
      if (Number(value) / 100 > 100000) return false;
      return true;
    })
    .nullable()
    .required("Please enter amount"),

  payingAgency: boolean(),

  agencyName: string().when("payingAgency", {
    is: true,
    then: string()
      .nullable()
      .required("Please select an agency"),
  }),

  accountId: string()
    .nullable()
    .test(
      "accountId",
      "You must select a account if this is an influencer payout",
      function(value) {
        const { description, payingAgency } = this.parent;
        if (
          !payingAgency &&
          description &&
          description.replace(/\s+/g, "").toLowerCase() === "influencerpayout"
        )
          return value != null;
        return true;
      }
    ),

  agencyAccountIds: array().test(
    "agencyAccountIds",
    "You must select at least one account if this is an influencer payout",
    function(value) {
      const { description, payingAgency, agencyAccountIds } = this.parent;
      if (
        payingAgency &&
        description &&
        description.replace(/\s+/g, "").toLowerCase() === "influencerpayout" &&
        agencyAccountIds.length === 0
      )
        return value.length > 0;
      return true;
    }
  ),

  attachments: array().min(1, "Attach at least one file"),
});
