import React, { Component } from "react";
import { AlignLeftOutlined, EditOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AccountPersist } from "src/ducks/Persist";

const ShowAccount = AccountPersist("AdminAccountShow");

export class SideMenu extends Component {
  render() {
    const { account, location } = this.props;

    const accountPath = `/accounts/${account.id}`;

    return (
      <Menu
        mode="vertical"
        selectedKeys={[location.pathname]}
        style={{ height: "100%" }}
      >
        <Menu.Item key={accountPath}>
          <Link data-cy="account-details" to={accountPath} replace>
            <AlignLeftOutlined />
            <span>Account Details</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={accountPath + "/update"}>
          <Link data-cy="account-update" to={accountPath + "/update"} replace>
            <EditOutlined />
            <span>Update Account</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={accountPath + "/content"}>
          <Link
            data-cy="account-past-content"
            to={accountPath + "/content"}
            replace
          >
            <VideoCameraOutlined />
            <span>Past Content</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  account: ShowAccount.selectOne(state)
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideMenu)
);
