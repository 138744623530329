import React, { Component } from "react";
import { Descriptions } from "antd";

export default class AccountMetrics extends Component {
  render() {
    const { account, ...rest } = this.props;

    return (
      <div {...rest}>
        <Descriptions bordered size="small" column={1}>
          <Descriptions.Item label="Age">
            {account.formattedAges()}
          </Descriptions.Item>
          {account.formattedAgeBreakdown() && (
            <Descriptions.Item label="Age Breakdown" span={3}>
              {account.formattedAgeBreakdown()}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Gender">
            {account.formattedGender()}
          </Descriptions.Item>
          {account.formattedGenderBreakdown() && (
            <Descriptions.Item label="Gender Breakdown" span={3}>
              {account.formattedGenderBreakdown()}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Interests">
            {account.formattedTags()}
          </Descriptions.Item>
          <Descriptions.Item label="Location">
            {account.formattedLocations()}
          </Descriptions.Item>
          {account.formattedLocationsBreakdown() && (
            <Descriptions.Item label="Location Breakdown" span={3}>
              {account.formattedLocationsBreakdown()}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  }
}
