import React, { Component } from "react";
import styled from "styled-components";
import {
  Typography,
  Avatar,
  Card,
  Row,
  Tag as AntdTag,
  Tooltip,
  message,
} from "antd";
import { Tag } from "src/components/Core/Tag";
import { Box } from "src/components/Core/Box";
import idx from "idx";
import compact from "lodash/compact";
import mean from "lodash/mean";
import values from "lodash/values";
import { RatingInput } from "src/components/Forms/Rating";
import { CopyToClipboard } from "react-copy-to-clipboard";

const { Text } = Typography;

const GridCard = styled(Card)`
  &&& {
    display: flex;
    margin-bottom: 24px;
    border-radius: 5px;

    .ant-card-body {
      width: 100%;
    }
  }
`;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const Details = styled("div")`
  margin-left: 15px;
  flex-grow: 1;
`;

const Name = styled(Text)`
  &&& {
    font-size: 17px;
    color: #000;
    display: block;
  }
`;

const Views = styled(props => <Text type="secondary" {...props} />)`
  &&& {
    font-size: 14px;
  }
`;

const EllipsisText = styled("span")`
  &&& {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    display: block;
    cursor: pointer;
  }
`;

export default class ManageCard extends Component {
  render() {
    const { invite, onRating, ...rest } = this.props;

    return invite.account ? (
      <GridCard size="small" hoverable {...rest}>
        <Wrapper>
          <Avatar
            shape="square"
            size={25}
            src={invite.account.profile_pic_url}
          />

          <Details>
            <Name>{invite.account.username}</Name>
            <Row

              style={{ marginTop: 10 }}
              justify="space-between"
              align="middle"
            >
              <Views>{invite.formattedEstimatedViews()}</Views>
              {invite.status === "accepted" && invite.formattedStage()}
            </Row>
            {invite.status === "complete" && (
              <Box
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRating(idx(invite, x => x.account.metadata.rating));
                }}
              >
                <RatingInput
                  size={14}
                  defaultValue={Math.round(
                    mean(
                      compact(
                        values(idx(invite, x => x.account.metadata.rating))
                      )
                    )
                  )}
                  value={Math.round(
                    mean(
                      compact(
                        values(idx(invite, x => x.account.metadata.rating))
                      )
                    )
                  )}
                  disabled
                />
              </Box>
            )}
          </Details>

          {invite?.suggestion?.sort_order && invite.status === "approved" && (
            <Tooltip
              placement="topLeft"
              title="The client ordered their suggested influencers
from most wanted to least. This number represents
this influencer’s place in that list."
            >
              <AntdTag
                color="geekblue"
                style={{
                  margin: 0,
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
                key="sortOrder"
              >
                {invite?.suggestion?.sort_order}
              </AntdTag>
            </Tooltip>
          )}
        </Wrapper>
        {(!invite.contract_signed || invite.account.status !== "Verified") &&
          (invite.status === "approved" || invite.status === "sent") && (
            <Box
              mt={10}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Tooltip
                placement="topLeft"
                title={`Send this link to the inlfuencer to gather
              Account data before moving on. Once the
              form has been submitted they will be moved
              to the accepted column`}
              >
                <Tag
                  size={14}
                  tagColor="grey7"
                  background="white1"
                  borderColor="grey8"
                  height="22px"
                >
                  <CopyToClipboard
                    text={`${window.location.origin}/invitation/hub/${invite.claim_cashout_id}`}
                    onCopy={() => message.success("Link copied")}
                  >
                    <EllipsisText>
                      {window.location.origin}/invitation/hub/
                      {invite.claim_cashout_id}
                    </EllipsisText>
                  </CopyToClipboard>
                </Tag>
              </Tooltip>
            </Box>
          )}

        {/* just need to show initial contact link */}

        {/* {!invite.contract_signed &&
          invite.account.status === "Verified" &&
          (invite.status === "approved" || invite.status === "sent") && (
            <Box
              mt={10}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Tooltip
                placement="topLeft"
                title={`Send this link to the inlfuencer to signed the contract`}
              >
                <Tag
                  size={14}
                  tagColor="grey7"
                  background="white1"
                  borderColor="grey8"
                  height="22px"
                  style={{ width: "100%" }}
                >
                  <CopyToClipboard
                    text={`${window.location.origin}/initial-contact/${invite.claim_cashout_id}/agreement`}
                    onCopy={() => message.success("Link copied")}
                  >
                    <EllipsisText>
                      {window.location.origin}/initial-contact/
                      {invite.claim_cashout_id}/agreement
                    </EllipsisText>
                  </CopyToClipboard>
                </Tag>
              </Tooltip>
            </Box>
          )} */}
      </GridCard>
    ) : null;
  }
}
