import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "src/shared/Table";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { show } from "redux-modal";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import TableManager from "src/ducks/TableManager";
import { InvitationList as $InvitationList } from "src/ducks/DataList";
import idx from "idx";
import withWindowResize from "src/utils/hoc/withWindowResize";
import moment from "moment";
import InvitationLinkModal from "./InvitationLinkModal";

const InvitationTable = TableManager("CampaignInvitationLinkList");
const InvitationList = $InvitationList("CampaignInvitationLinkList");

const modules = [InvitationTable.module(), InvitationList.module()];

const columns = {
  account: {
    title: "Account",
    dataIndex: "account.username",
    visible: true,
    width: 150,
    render: (text, row) =>
      idx(row, x => x.account.id) ? (
        <Link to={`/accounts/${idx(row, x => x.account.id)}`}>{text}</Link>
      ) : (
        text
      ),
  },
  influencer: {
    title: "Influencer",
    dataIndex: "account.influencer.name",
    visible: true,
    width: 150,
    render: (text, row) =>
      idx(row, x => x.account.influencer) ? (
        <Link to={`/accounts/${idx(row, x => x.account.influencer)}`}>
          {text}
        </Link>
      ) : (
        text
      ),
  },

  link: {
    title: "Link",
    dataIndex: "metadat.tiktok_url",
    visible: true,
    width: 150,
    render: (text, row) =>
      idx(row, x => x.metadata.tiktok_url) &&
      idx(row, x => x.metadata.tiktok_url) !== "" ? (
        <div style={{ overflowWrap: "anywhere" }}>
          <a
            href={idx(row, x => x.metadata.tiktok_url)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {idx(row, x => x.metadata.tiktok_url)}
          </a>
        </div>
      ) : (
        "-"
      ),
  },
  submission_date: {
    title: "Submission Date",
    dataIndex: "updated_at",
    visible: true,
    width: 150,
    render: (text, row) =>
      row.created_at ? moment(row.created_at).format("Do MMM YYYY") : "",
  },
};

const filters = {};

export class InvitationsLinkTable extends Component {
  resetInvitations = () => {
    this.loadInvitations();
  };

  loadInvitations = filters => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.loadInvitations({
      expand: ["account", "account.influencer"],
      campaign: id,
      ...filters,
    });
  };

  render() {
    const { data, pagination, loading, style, height, show } = this.props;

    return (
      <>
        <Table
          style={style}
          title=""
          columns={columns}
          loadData={this.loadInvitations}
          name="CampaignInvitationLinkList"
          pagination={pagination}
          data={data}
          addedColumns={[
            {
              title: "Action",
              key: "action",
              width: 50,
              render: (text, row) => {
                const invitationId = row._fields.id;

                return (
                  <Button
                    type="link"
                    data-cy="link-edit-button"
                    onClick={() =>
                      show(`CampaignInvitationLinkModal`, {
                        invitationId: invitationId,
                        onUpdate: this.resetInvitations,
                      })
                    }
                  >
                    Edit
                  </Button>
                );
              },
            },
          ]}
          filterOptions={filters}
          loading={loading}
          size="middle"
          scroll={{ y: height, x: "max-content" }}
        />
        <InvitationLinkModal />
      </>
    );
  }
}

const mapStateToProps = state => ({
  data: InvitationList.selectMany(state),
  pagination: InvitationList.selectPagination(state),
  loading: InvitationList.selectPending(state),
});

const mapDispatchToProps = {
  loadInvitations: InvitationList.loadMany,
  show,
};

const MainInvitationsLinkTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationsLinkTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainInvitationsLinkTable);
