import React, { Component } from "react";
import { Row, Card, Col, Statistic } from "antd";

export default class AccountStats extends Component {
  render() {
    const { account } = this.props;

    return (
      <Row justify="center" style={{ marginTop: 24 }}>
        {account.formattedAvgCtr() && (
          <Col span={6}>
            <Card>
              <Statistic title="Avg. CTR" value={account.formattedAvgCtr()} />
            </Card>
          </Col>
        )}

        {account.type === "YouTube" && account.formattedAvgViews() && (
          <Col span={6}>
            <Card>
              <Statistic title="Avg. View" value={account.formattedAvgViews()} />
            </Card>
          </Col>
        )}

        {/* {account.formattedAvgItr() && (
          <Col span={6}>
            <Card>
              <Statistic
                title="Conversion Rate"
                value={account.formattedAvgItr()}
              />
            </Card>
          </Col>
        )} */}

        {account.formattedFollowers() && (
          <Col span={6}>
            <Card>
              <Statistic
                title="Followers"
                value={account.formattedFollowers()}
              />
            </Card>
          </Col>
        )}

        {account.formattedEngagementRate() && (
          <Col span={6}>
            <Card>
              <Statistic
                title="Engagement Rate"
                value={account.formattedEngagementRate()}
              />
            </Card>
          </Col>
        )}
      </Row>
    );
  }
}
