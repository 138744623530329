// @ts-nocheck
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ProfileStepContainer } from "./ProfileStep";

interface Props {}

const WelcomeProfile: React.FC<Props & RouteComponentProps> = ({ match }) => {
  const claimAccountId: any = match.params["id"];
  return <ProfileStepContainer claimAccountId={claimAccountId} />;
};

export default WelcomeProfile;
