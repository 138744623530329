// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSwitch } from "src/components/Forms/Switch";

interface Props {}

const ManageTag: React.FC<Props> = () => {
  return (
    <Field label="Managed Influencer?:" mb={26}>
      <FormikSwitch name="managed" />
    </Field>
  );
};

export default ManageTag;
