// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { AnalyseFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { FormikInput } from "src/components/Forms/TextInput";

interface AnalyseFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  analyseFormSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  step: number;
}

export const AnalyseFormWrapper = styled.div`
  width: 100%;
`;

const AnalyseForm: React.FC<AnalyseFormProps> = ({
  initialValues,
  isSubmitting,
  analyseFormSchema,
  onSubmit,
  globalErrors,
  step,
}) => {
  return (
    <AnalyseFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={analyseFormSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return <>
              <Box p={20}>
                <Row gutter={32}>
                  <Col span={step === 1 ? 18 : 24}>
                    <FormikInput
                      name="tiktokUserUrl"
                      size="middle"
                      placeholder="Enter any TikTok Account URL"
                      background="background"
                      borderRadius={3}
                      borderColor="grey5"
                      prefix={<SearchOutlined />}
                    />
                  </Col>

                  <Col span={step === 1 ? 6 : 24}>
                    <Box mt={step === 1 ? 0 : 10}>
                      <Button
                        htmlType="submit"
                        type="action"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        buttonSize="middle"
                        data-cy="submit-button"
                        style={{ width: "100%" }}
                      >
                        Analyse
                      </Button>
                    </Box>
                  </Col>
                </Row>
              </Box>
            </>;
          }}
        </Form>
      </Loading>
    </AnalyseFormWrapper>
  );
};

export { AnalyseForm, AnalyseFormContainer };
