// @ts-nocheck
import React, { useEffect } from "react";
import { BasicInfo } from ".";
import { string, object } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetAllTagsQuery,
  useUpdateAccountDetailByIdMutation,
} from "src/generated/ApolloComponents";

interface Props {
  accountId: number;
  account: any;
}

interface BasicInfoFormValues {
  id: number;
  actualCostPerPost: number;
  tags: Array<any>;
  isEditable?: boolean;
}

export const BasicInfoContainer: React.FC<Props> = ({ accountId, account }) => {
  const tags = useGetAllTagsQuery();

  useEffect(() => {
    tags.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const allTags = tags?.data?.getAllTags || [];

  const accountData = account?.data?.getAccountDetailById;

  const tagsArray = (accountData?.tags || []).map(item => {
    let container = {} as any;
    container.id = item.id;
    container.name = item.name;
    return container;
  });

  const [
    updateAccountDetailByIdMutation,
    updateAccount,
  ] = useUpdateAccountDetailByIdMutation();

  const BasicInfoFormSchema = object().shape({
    actualCostPerPost: string()
      .nullable()
      .required("Please enter cost"),
  });

  let initialValues = {
    id: accountId,
    actualCostPerPost: accountData?.actualCostPerPost || 0,
    tags: tagsArray || [],
    isEditable: false,
  };

  const onSubmit = async (
    values: BasicInfoFormValues,
    actions: FormikActions<BasicInfoFormValues>
  ) => {
    try {
      let formData: BasicInfoFormValues = {
        id: values?.id,
        tags: values?.tags,
        actualCostPerPost: values?.actualCostPerPost,
      };

      const res = await updateAccountDetailByIdMutation({
        variables: formData,
      });

      if (res?.data?.updateAccountDetailById?.id) {
        account.refetch({
          accountId: accountId.toString(),
        });
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(
    account?.error || updateAccount?.error
  );

  return (
    <BasicInfo
      initialValues={initialValues}
      BasicInfoFormSchema={BasicInfoFormSchema}
      isSubmitting={account?.loading || updateAccount?.loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      accountData={accountData}
      allTags={(allTags || []) as any}
    />
  );
};
