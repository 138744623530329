// @ts-nocheck
import React from "react"
import { Form } from "src/components/Forms/Form"
import { Schema } from "yup"
import { Modal } from "antd"
import { Field } from "src/components/Forms/Field"
import FormikInput from "src/components/Forms/TextInput/FormikInput"
import { FormikCurrencyInput } from "src/components/Forms/CurrencyInput"

export interface AddTiktokModalValues {
  username: string
  type: string
  screenshots: string[]
}

interface Props {
  visible: boolean
  platform: string
  setVisible: Function
  initialValues: any
  schema: Schema<AddTiktokModalValues>
  onSubmit: (values: any, actions: any) => any
}

export const AddAccountModal = ({
  initialValues,
  schema,
  onSubmit,
  platform,
  visible,
  setVisible
}: Props) => {
  return (
    <Form initialValues={{ ...initialValues, type: platform }} schema={schema} onSubmit={onSubmit}>
      {({ submitForm, resetForm }) => (
        <Modal
          title={`Add ${platform} Account`}
          visible={visible}
          onOk={() => {
            submitForm().then(resetForm)
          }}
          onCancel={() => setVisible(false)}
        >
          <Field label="Username" mb={26}>
            <FormikInput name="username" placeholder="e.g. lorengrey" />
          </Field>

          <Field label="How much do you charge for a post on this channel?" mb={26}>
            <FormikCurrencyInput
              placeholder="100"
              prefix="£"
              name="payoutPennies"
              shouldShowError={false}
            />
          </Field>
        </Modal>
      )}
    </Form>
  )
}
