// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { CreateSuggestionFormContainer } from "./CreateSuggestionForm";

const SuggestionModal = styled(Modal)`
  &&& {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.213287);
    border-radius: 6px;
  }
`;

interface Props {
  showCreateSuggestionModal: boolean;
  closeCreateSuggestionModal: () => void;
  account: any;
  campaign: any;
  refetchSuggestion: () => void;
}

const CreateSuggestionModal = ({
  showCreateSuggestionModal,
  closeCreateSuggestionModal,
  account,
  campaign,
  refetchSuggestion,
}: Props) => {
  return (
    <SuggestionModal
      title="Suggest Account"
      style={{
        maxWidth: 600,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showCreateSuggestionModal}
      onCancel={closeCreateSuggestionModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <CreateSuggestionFormContainer
        closeCreateSuggestionModal={closeCreateSuggestionModal}
        account={account}
        campaign={campaign}
        refetchSuggestion={refetchSuggestion}
      />
    </SuggestionModal>
  );
};

export default CreateSuggestionModal;
