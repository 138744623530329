// @ts-nocheck
import React, { Fragment } from 'react'
import { Row } from 'antd'
import Loading from 'src/shared/Loading'
import Account, { DisabledButton } from '../Button'
import { Typography } from 'src/components/Core/Typography'
import Auth from 'src/ducks/Auth'
import { useSelector } from 'react-redux'

interface Props {
  profile: any
  collect: boolean
  express: boolean
  selectedPlatform: string
}

const HeadSection = ({selectedPlatform, collect, profile, express}: Props) => {
  const influencer = profile?.data?.getPublicInfluencerProfile
  const accounts = influencer?.accounts
  const expressApplicant = influencer?.expressApplicants && influencer.expressApplicants[0]
  const user = useSelector(state => Auth.selectUser(state))
  return(
    <Fragment>
      <Typography fontSize={30} color="black" fontWeight="bold">
        {express ?
          `Welcome back, ${user.name}!`
          :
          `Connect accounts${selectedPlatform && collect ? ' - Profile details' : ''}`
        }
      </Typography>
      <Typography fontSize={16} color="black2">
        Connect your social media accounts to apply for campaigns
      </Typography>
      {express ?
        <Typography>Would you like to connect your {expressApplicant?.platform} account <Typography color="blue" style={{display: 'inline'}}>@{expressApplicant?.username}</Typography> to this Fanbytes acocunt?</Typography>
        :
        <Typography fontSize={16} color="black2">
          Connected accounts:
        </Typography>
      }
      {!express &&
        <Loading spinning={profile?.loading}>
          <Row>
            {accounts?.length > 0 ?
              accounts.map(account =>
                <Account connected={true} text={account.username} handleClick={() => {}} key={account.username} />
              )
              :
              <DisabledButton />
            }
          </Row>
        </Loading>
      }
    </Fragment>
  )
}

export default HeadSection
