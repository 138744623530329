// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Empty } from "antd";
import { Typography } from "src/components/Core/Typography";
import numeral from "numeral";
import Period from "../Period";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AxisDomain,
  ResponsiveContainer,
} from "recharts";

interface Props {
  data: Array<any>;
  title: string;
  titleValue: number;
  period: string;
  YDomain?: [AxisDomain, AxisDomain];
  setPeriod: (value: string) => void;
}
const LineChartGraphCard: React.FC<Props> = ({
  title,
  data,
  titleValue,
  YDomain,
  period,
  setPeriod,
}) => {
  const getPeriod = period => {
    let periodString = "";
    if (period === "Month") {
      periodString = "Last 30 days";
    } else if (period === "HalfYear") {
      periodString = "Last 6 month";
    }
    return periodString;
  };
  return (
    <Box
      background="#FFFFFF"
      p={10}
      borderRadius="2px"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography size={12} weight="semi-bold" color="black">
            {title}: {numeral(titleValue).format("0,0")}
          </Typography>
        </Box>

        {data?.length > 0 && (
          <Period
            period={getPeriod(period)}
            setPeriod={value => setPeriod(value)}
          />
        )}
      </Box>

      <ResponsiveContainer width="100%" height={300}>
        {data?.length > 0 ? (
          <LineChart
            data={data}
            margin={{
              top: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="key"
              stroke="#98A1B5"
              axisLine={false}
              style={{
                fontSize: "12px",
              }}
            />
            <YAxis
              domain={YDomain}
              axisLine={false}
              tickFormatter={x => numeral(x).format("0a")}
              style={{
                fontSize: "12px",
              }}
            />
            <Tooltip formatter={value => numeral(value).format("0,0")} />

            <Line
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              connectNulls={true}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        ) : (
          <Box p={85}>
            <Empty description="No data avaialble" />
          </Box>
        )}
      </ResponsiveContainer>
    </Box>
  );
};

export default LineChartGraphCard;
