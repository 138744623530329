// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { FormikRatingInput } from "src/components/Forms/Rating";
import { Box } from "src/components/Core/Box";

interface Props {}

const ResponseTime: React.FC<Props> = () => {
  return (
    <Box mb={26}>
      <Typography size={20} fontWeight="semi-bold" color="black" lineHeight={0}>
        Response time
      </Typography>
      <Typography size={14} color="grey7">
        How quickly did the influencer respond to messages and email
      </Typography>

      <FormikRatingInput name="responseTime" size={24} />
    </Box>
  );
};

export default ResponseTime;
