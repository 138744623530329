import React, { Component } from "react";
import FormField from "../FormField";
import { Row, Col, Button } from "antd";
import { FieldArray } from "formik";
import {
  Wrapper,
  TextArea,
  FieldLabel,
  NumberText,
  DeleteIcon
} from './styles'

export default class BriefDo extends Component {
  render() {
    const {
      values: { brief_do },
    } = this.props;

    return (
      <FieldArray
        name={`brief_do`}
      >
        {({ insert, remove, push }) => (
          <Wrapper>
            <FieldLabel>
              What are the 3-5 top things you’d like influencers to mention?
            </FieldLabel>
            {typeof brief_do !== "undefined" &&
              brief_do.length > 0 &&
              brief_do.map((data, index) => {
                return (
                  <Row gutter={8} key={index}>
                    <Col span={1}>
                      <NumberText>{index + 1}.</NumberText>
                    </Col>
                    <Col span={22}>
                      <FormField
                        name={`brief_do[${index}]`}
                        label=""
                        style={{ marginBottom: 0 }}
                      >
                        <TextArea
                          data-cy={`brief-do-input[${index}]`}
                          rows={1}
                          size="large"
                          placeholder="e.g. Must mention our most recent collection"
                        />
                      </FormField>
                    </Col>

                    <Col span={1}>
                      <DeleteIcon
                        type="delete"
                        data-cy={`brief-do-input-delete[${index}]`}
                        onClick={() => remove(index)}
                      />
                    </Col>
                  </Row>
                );
              })}
            <Row gutter={8}>
              <Col span={22}>
                <Button type="link" onClick={() => push("")}>
                  + Add
                </Button>
              </Col>

              <Col span={2}></Col>
            </Row>
          </Wrapper>
        )}
      </FieldArray>
    );
  }
}
