// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FormikSelect } from "src/components/Forms/Select";
import ClearButton from "./ClearButton";
import { Box } from "src/components/Core/Box";

interface Props {
  formProps?: FormikProps<any>;
  allTags: Array<any>;
  clearFilterKey: (key: string) => void;
}

const Tags: React.FC<Props> = ({ formProps, allTags, clearFilterKey }) => {
  let tagData = [] as any;
  tagData = (allTags || []).map(item => {
    let container = {} as any;
    container.value = item.id;
    container.label = item.name;
    return container;
  });

  let tagIds = formProps?.values?.tagIds;
  let isVisible = false;
  let backgroundColor = "#FFFFFF";

  if (tagIds?.length > 0) {
    isVisible = true;
    backgroundColor = "#FAFAFA";
  }

  return (
    <Box background={backgroundColor} padding="5px">
      <Field
        label="Tags:"
        mb={20}
        extra={
          <ClearButton
            isVisible={isVisible}
            buttonClick={() => {
              formProps?.setFieldValue("tagIds", []);
              clearFilterKey("tagIds");
            }}
          />
        }
      >
        <FormikSelect
          name="tagIds"
          size="middle"
          borderRadius={3}
          borderColor="grey5"
          data={tagData}
          mode="multiple"
          local
        />
      </Field>
    </Box>
  );
};

export default Tags;
