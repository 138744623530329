// @ts-nocheck
import React from 'react'
import { FormikProps } from 'formik'
import { ages } from 'src/constants/account'
import { Field } from 'src/components/Forms/Field'
import { FormikSelect } from 'src/components/Forms/Select'

interface Props {
  formProps: FormikProps<any>;
}

const Name = ({ formProps }: Props) => {

  return (
    <Field
      mb={20}
      color="black"
      isBeside={true}
      id="age"
      label="Age"
    >
      <FormikSelect
        name="age"
        size="large"
        placeholder="- -"
        data={ages}
        local
      />
    </Field>
  );
};

export default Name;
