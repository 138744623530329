// @ts-nocheck
import React from "react";
import { Button } from "src/components/Core/Button";
import { Box } from "src/components/Core/Box";

interface Props {
  image: string;
  onClick: Function;
}

const SocialButton = ({ image, onClick }: Props) => {
  return (
    <Button mr="5px" mt="5px" onClick={onClick}>
      <Box height="100%" padding={10}>
        <img height="100%" src={image} alt="social-button" />
      </Box>
    </Button>
  );
};

export default SocialButton;
