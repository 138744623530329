// @ts-nocheck
import styled from "styled-components";
import { Typography } from "antd";
const { Text } = Typography;

export const NoteWrapper = styled("div")`
  padding: 0px;
`;

export const Info = styled("p")`
  color: #000000;
  font-weight: 500;
  font-size: 14px;
`;

export const Description = styled(Text)`
  && {
    display: block;
    font-size: 12px;
    margin-bottom: 1em;
    color: #595959;
  }
`;
