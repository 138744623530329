import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import {
  AlignLeftOutlined,
  LineChartOutlined,
  ProjectOutlined,
  SearchOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

import { Menu, Badge } from "antd";
import CampaignManage from "src/ducks/CampaignManage";

const NavBadge = styled(Badge)`
  &&& {
    margin-left: 10px;
    margin-top: -4px;
  }
`;

export class ActiveMenu extends Component {
  render() {
    const {
      campaign,
      location,
      suggestionsPagination: { total_entries },
      pendingInvitations,
    } = this.props;

    const campaignPath = `/campaigns/${campaign.id}`;

    return (
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{ height: "100%" }}
      >
        <Menu.Item key={campaignPath}>
          <Link data-cy="campaign-details" to={campaignPath}>
            <AlignLeftOutlined />
            <span>Campaign Details</span>
          </Link>
        </Menu.Item>

        {(campaign.ad_format !== "Themed Pages" ||
          campaign.ad_format !== "CPI") && (
          <Menu.Item key={campaignPath + "/find"}>
            <Link data-cy="campaign-influencers" to={campaignPath + "/find"}>
              <SearchOutlined />
              <span>Review Influencers</span>
              <NavBadge count={total_entries} />
            </Link>
          </Menu.Item>
        )}

        {(campaign.ad_format !== "Themed Pages" ||
          campaign.ad_format !== "CPI") && (
          <Menu.Item key={campaignPath + "/approve"}>
            <Link data-cy="campaign-content" to={campaignPath + "/approve"}>
              <VideoCameraOutlined />
              <span>Approve Content</span>
              <NavBadge count={pendingInvitations.length} />
            </Link>
          </Menu.Item>
        )}

        {campaign.ad_format !== "Content generation" && (
          <Menu.Item key={campaignPath + "/content"}>
            <Link data-cy="campaign-content" to={campaignPath + "/content"}>
              <VideoCameraOutlined />
              <span>Posted Content</span>
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key={campaignPath + "/ad-hub"}>
          <Link data-cy="ad-hub" to={campaignPath + "/ad-hub"}>
            <VideoCameraOutlined />
            <span>Ad Hub</span>
          </Link>
        </Menu.Item>

        {campaign.ad_format !== "Content generation" && (
          <Menu.Item key={campaignPath + "/analytics"}>
            <Link data-cy="campaign-analytics" to={campaignPath + "/analytics"}>
              <LineChartOutlined />
              <span>Analytics</span>
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key={campaignPath + "/tracking"}>
          <Link data-cy="campaign-tracking" to={campaignPath + "/tracking"}>
            <ProjectOutlined />
            <span>Tracking</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  suggestionsPagination: CampaignManage.accounts.selectPagination(state),
  pendingInvitations: CampaignManage.drafts
    .selectAll(state)
    .filter(x => x.stage === "under-review"),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActiveMenu)
);
