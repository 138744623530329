import React, { Component } from "react"
import FormField from "../FormField"
import { InputNumber } from "formik-antd"

export default class Views extends Component {
  render() {
    return (
      <FormField name={this.props.name || "views"} label="Views">
        <InputNumber
          style={{ width: "100%" }}
          size="large"
          data-cy="account-views"
          placeholder="1000"
        />
      </FormField>
    )
  }
}
