import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { DynamicModuleLoader } from "redux-dynamic-modules";

import Dashboard from "./Dashboard";
import CampaignCreate from "./CampaignCreate";
import CampaignManage from "./CampaignManage";
import CampaignGroupShow from "./CampaignGroupShow";
import TeamManagement from "./TeamManagement";
import Settings from "./Settings";
import Profile from "./Profile";
import InfluencerProfile from "../auth/InfluencerProfile";
import { GiftingDashboard } from "./Gifting/GiftingDashboard";

import { CampaignListModule } from "src/ducks/DataList";
import { ManageGiftingCampaign } from "./Gifting/ManageGiftingCampaign";

import BrandSafety from "../auth/BrandSafety";
import { SuggestionContainer } from "../auth/SharedSuggestions";

import AdAuthorise from "./AdAuthorise";

import InstantReport from "../auth/InstantReport";

// import ClaimCashout from "../influencers/ClaimCashout";
// import InitialContract from "../influencers/InitialContract";

class BrandRoutes extends Component {
  modules = [CampaignListModule("BrandDashboard")()];

  render() {
    return (
      <DynamicModuleLoader modules={this.modules}>
        <Switch>
          <Route path="/settings" component={Settings} />
          <Route path="/campaigns/new" component={CampaignCreate} />
          <Route
            path="/campaigns/instant-report/:id"
            exact
            component={InstantReport}
          />
          <Route path="/campaigns/:id" component={CampaignManage} />

          <Route
            exact
            path="/campaign-group/:id/:page?"
            component={CampaignGroupShow}
          />
          <Route path="/gifting/:id" component={ManageGiftingCampaign} />
          <Route path="/gifting" component={GiftingDashboard} />
          <Route path="/profiles/:id" component={Profile} />
          <Route
            path="/influencers/:id/profile"
            component={InfluencerProfile}
          />
          <Route path="/team" component={TeamManagement} />
          <Route path="/brand-safety/" exact component={BrandSafety} />
          <Route path="/ad-authorise" exact component={AdAuthorise} />
          {/* <Route path="/claim-cashout/:id/:type?" component={ClaimCashout} />

          <Route
            path="/initial-contact/:id/:type"
            exact
            component={InitialContract}
          /> */}

          {/* Shared Routes Start */}
          <Route path="/sharedsuggestions/:id" exact component={SuggestionContainer} />
          {/* Shared Routes End */}

          <Route path="/" exact component={Dashboard} />
          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
      </DynamicModuleLoader>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(BrandRoutes));
