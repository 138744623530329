// @ts-nocheck
import { string, object } from "yup";
import { bankDetailFields } from "../Steps/BankDetailFields";

const isFieldRequired = (countryCode, field) => {
  const fields = bankDetailFields[countryCode].fields;
  const fieldRequired = fields.some(item => item.name === field);
  return fieldRequired;
};

export const BankDetailSchema = object().shape({
  countryCode: string()
    .nullable()
    .required("Please select country"),

  fullName: string()
    .nullable()
    .test("fullName", "Please enter a account name", function(value) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "fullName")) return value != null;
      return true;
    }),

  accountNumber: string()
    .nullable()
    .test("accountNumber", "Please enter account number", function(value) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "accountNumber")) return value != null;
      return true;
    }),

  sortCode: string()
    .length(6, "Sort Code must be exactly 6 digit")
    .nullable()
    .test("sortCode", "Please enter 6 digit Sort Code", function(value) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "sortCode")) return value != null;
      return true;
    }),

  recipientAddress: string()
    .nullable()
    .test("recipientAddress", "Please enter recipient address", function(
      value
    ) {
      const { countryCode } = this.parent;
      if (
        countryCode !== "CA" &&
        isFieldRequired(countryCode, "recipientAddress")
      )
        return value != null;
      return true;
    }),

  routingNumber: string()
    .nullable()
    .test("routingNumber", "Please enter routing number", function(value) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "routingNumber")) return value != null;
      return true;
    }),

  swift: string().nullable(),

  iban: string()
    .nullable()
    .test("iban", "Please enter iban", function(value) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "iban")) return value != null;
      return true;
    }),

  bsbCode: string()
    .nullable()
    .test("bsbCode", "Please enter bsb code", function(value) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "bsbCode")) return value != null;
      return true;
    }),

  bankName: string()
    .nullable()
    .test("bankName", "Please enter bank name", function(value) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "bankName")) return value != null;
      return true;
    }),

  transitNumber: string()
    .nullable()
    .test("transitNumber", "Please enter transit number", function(value) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "transitNumber")) return value != null;
      return true;
    }),

  institutionNumber: string()
    .nullable()
    .test("institutionNumber", "Please enter institution number", function(
      value
    ) {
      const { countryCode } = this.parent;
      if (isFieldRequired(countryCode, "institutionNumber"))
        return value != null;
      return true;
    }),
});
