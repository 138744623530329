// @ts-nocheck
import React, { useState } from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { HubLinkAccessFormContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Row, Col } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { Tag } from "src/components/Core/Tag";
import { LinkButton } from "src/components/Core/LinkButton";
import {
  InitialContact,
  InitialContactYouTube,
  InviteFeedback,
  InviteFeedbackYouTube,
  PostDetail,
  PostDetailYouTube,
  Contract,
  PaymentDetail,
} from "./Fields";
import { Icon } from '@ant-design/compatible'

const CopyIcon = styled(Icon)`
  &&& {
    color: ${props => props.theme.colors.grey6} !important;
    margin-left: 10px;
    line-height: 26px;
    font-size: 14px;
    cursor: pointer;
  }
`;

interface HubLinkAccessFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  hubLinkAccessFormSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  claimCashoutId: string;
  hubLinkData: any;
}

const HubLinkAccessForm: React.FC<HubLinkAccessFormProps> = ({
  initialValues,
  isSubmitting,
  hubLinkAccessFormSchema,
  onSubmit,
  globalErrors,
  claimCashoutId,
  hubLinkData,
}) => {
  const [linkCopyButtonAction, setLinkCopyButttonAction] = useState(false);

  const getStatus = status => {
    if (status === "complete") {
      return (
        <Box ml={10}>
          <Tag
            size={12}
            tagColor="green6"
            background="green1"
            borderColor="lime"
            marginBottom="7px"
          >
            Complete
          </Tag>
        </Box>
      );
    } else if (status === "pending") {
      return (
        <Box ml={10}>
          <Tag
            size={12}
            tagColor="geekblue6"
            background="geekblue1"
            borderColor="geekblue3"
            marginBottom="7px"
            style={{ marginLeft: "10px" }}
          >
            Pending
          </Tag>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <Box width="100%" mb={50}>
      <Loading spinning={isSubmitting}>
        <Box display="flex" mt={20}>
          <Typography size={14} fontWeight="bold" color="black" mb={0}>
            Hub Link:
          </Typography>

          <Box display="flex" ml={20}>
            <Typography size={14} color="blue">
              <a
                href={`${window.location.origin}/invitation/hub/${claimCashoutId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${window.location.origin}/invitation/hub/${claimCashoutId}`}
              </a>
            </Typography>

            <CopyToClipboard
              text={`${window.location.origin}/invitation/hub/${claimCashoutId}`}
              onCopy={() => {
                linkCopyButtonAction
                  ? setLinkCopyButttonAction(false)
                  : setLinkCopyButttonAction(true);
                setTimeout(() => setLinkCopyButttonAction(false), 1000);
              }}
            >
              <CopyIcon type="copy" />
            </CopyToClipboard>

            <Typography size={14} color="black2" ml={10}>
              {linkCopyButtonAction ? "Copied!" : ""}
            </Typography>
          </Box>
        </Box>
        <Typography size={14} weight="semi-bold" color="black">
          You can send this link to request information from the influencer.
          Below you can choose what is displayed/requested to the influencer
        </Typography>

        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={hubLinkAccessFormSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <InitialContact
                      formProps={formProps}
                      hubLinkData={hubLinkData}
                      getStatus={getStatus}
                    />
                  </Col>

                  <Col span={12}>
                    <InitialContactYouTube
                      formProps={formProps}
                      hubLinkData={hubLinkData}
                      getStatus={getStatus}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <InviteFeedback
                      formProps={formProps}
                      hubLinkData={hubLinkData}
                      getStatus={getStatus}
                    />
                  </Col>

                  <Col span={12}>
                    <InviteFeedbackYouTube
                      formProps={formProps}
                      hubLinkData={hubLinkData}
                      getStatus={getStatus}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <PostDetail
                      formProps={formProps}
                      hubLinkData={hubLinkData}
                      getStatus={getStatus}
                    />
                  </Col>

                  <Col span={12}>
                    <PostDetailYouTube
                      formProps={formProps}
                      hubLinkData={hubLinkData}
                      getStatus={getStatus}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Contract
                      formProps={formProps}
                      hubLinkData={hubLinkData}
                      getStatus={getStatus}
                    />
                  </Col>

                  <Col span={12}>
                    <PaymentDetail
                      formProps={formProps}
                      hubLinkData={hubLinkData}
                      getStatus={getStatus}
                    />
                  </Col>
                </Row>

                <Box alignItems="right" textAlign="right" display="block">
                  <a
                    href={`${window.location.origin}/invitation/hub/${claimCashoutId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkButton
                      color="blue"
                      fontSize={12}
                      mr={20}
                      style={{
                        border: "1px solid #F5F5F5",
                        borderRadius: "3px",
                        background: "#F5F5F5",
                      }}
                    >
                      Preview
                    </LinkButton>
                  </a>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    buttonSize="small"
                    data-cy="submit-button"
                  >
                    Save
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </Box>
  );
};

export { HubLinkAccessForm, HubLinkAccessFormContainer };
