import React, { Component } from "react";
import { Alert } from "antd";

export default class FormError extends Component {
  constructor() {
    super()
    this.executeScroll = this.executeScroll.bind(this)
  }
  componentDidMount() {
    const { children, message } = this.props;
    if(children?.length > 0 || message?.length > 0)
      this.executeScroll()
  }

  executeScroll () {
    const elem = document.getElementsByClassName('ant-alert-message')[0]
    if(elem)
      elem.scrollIntoView({ block: "start", behavior: "smooth" })
  }

  render() {
    const { children, message, rest } = this.props;
    let calculatedMessage = children || message;

    if (Array.isArray(calculatedMessage))
      calculatedMessage = calculatedMessage.join(", ");

    return (
      <Alert
        type="warning"
        style={{ marginTop: 15, ...this.props.style }}
        data-cy={`form-error-${this.props.cy}`}
        message={calculatedMessage}
        {...rest}
      />
    );
  }
}
