import React, { Component } from "react";
import styled from "styled-components";
import DashboardLayout from "../../layouts/DashboardLayout";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import Container from "src/shared/Container";
import Auth from "src/ducks/Auth";
import { BookOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, PageHeader } from "antd";
import ProfileForm from "./ProfileForm";
import CompanyForm from "./CompanyForm";

const { Content, Sider } = Layout;

const BorderHeader = styled(PageHeader)`
  border-bottom: 1px solid #e8e8e8;
`;

const FormContainer = styled(Container)`
  padding-top: 30px;
`;

export class Settings extends Component {
  state = {
    page: "profile"
  };

  componentDidMount() {
    const { fetchUser } = this.props;
    fetchUser({
      expand: ["company"]
    });
  }

  changePage = page => {
    this.setState({
      page: page
    });
  };

  render() {
    const { page } = this.state;
    return (
      <DocumentTitle title="Settings">
        <DashboardLayout>
          <BorderHeader
            style={{
              top: "68px",
              width: "100vw",
              overflow: "auto",
              position: "fixed",
              zIndex: 60
            }}
            title="Profile"
          />
          <Layout>
            <Sider
              style={{
                background: "#fff",
                overflow: "auto",
                height: "100vh",
                marginTop: 55,
                position: "fixed"
              }}
              width={200}
            >
              <Menu
                mode="inline"
                selectedKeys={[page]}
                style={{ height: "100%" }}
              >
                <Menu.Item
                  onClick={() => this.changePage("profile")}
                  key="profile"
                >
                  <UserOutlined />
                  <span>Personal Details</span>
                </Menu.Item>
                <Menu.Item
                  onClick={() => this.changePage("company")}
                  key="company"
                >
                  <BookOutlined />
                  <span>Company Settings</span>
                </Menu.Item>
              </Menu>
            </Sider>
            <Content style={{ marginLeft: 200, marginTop: 58 }}>
              <FormContainer>
                {page === "profile" && <ProfileForm />}
                {page === "company" && <CompanyForm />}
              </FormContainer>
            </Content>
          </Layout>
        </DashboardLayout>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchUser: Auth.fetchUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
