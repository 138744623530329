// @ts-nocheck
import React, { Fragment } from 'react'
import { Row, Col } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { Props } from '../LeftPanel'
import { Box } from 'src/components/Core/Box'
import { Button } from 'src/components/Core/Button'
import { Link, useLocation } from 'react-router-dom'
import { Typography } from 'src/components/Core/Typography'

const Actions = ({formProps}: Props) => {
  const location = useLocation()
  return(
    <Fragment>
      <Row>
        <Col>
          <Button
            type="action"
            onClick={formProps.handleSubmit}
            disabled={!isEmpty(formProps.errors)}
          >
            <Typography fontSize={18} mb={0} cursor="pointer">Sign up</Typography>
          </Button>
        </Col>
        <Box mt={2}>
          <Col>
            <Typography fontSize={20}>
              &nbsp; or <Link to={`/sign_in${location.search}`}>Sign in</Link>
            </Typography>
          </Col>
        </Box>
      </Row>
    </Fragment>
  )
}

export default Actions;
