// @ts-nocheck
import React from "react";
import { AutoCompleteInput } from "src/components/Forms/AutoComplete";
import Highlighter from "react-highlight-words";
import compact from "lodash/compact";

interface Props {
  handleChange: (key: string, value: any) => void;
  onSearchTextChange: (value: string) => void;
  searchText: string;
  filterDataSoruce: any;
}

const QuickSearch: React.FC<Props> = ({
  handleChange,
  onSearchTextChange,
  searchText,
  filterDataSoruce,
}) => {
  function renderTitle(title) {
    return <span>{title}</span>;
  }

  function renderMatchValue(value) {
    return (
      <Highlighter
        highlightClassName="matchWord"
        searchWords={[searchText]}
        autoEscape={true}
        textToHighlight={value}
        highlightStyle={{ fontWeight: "500", backgroundColor: "transparent" }}
      />
    );
  }

  const mappingLableWithKey = {
    account: "Acount",
    influencer: "Influencer",
    tag: "Tag",
    campaign: "Campaign",
  };

  const options = Object.keys(filterDataSoruce || {}).map((key, i) => {
    if ((filterDataSoruce[key] || []).length > 0 && key !== '__typename') {
      return (
        {
          label: renderTitle(mappingLableWithKey[key]),
          options: (filterDataSoruce[key] || []).slice(0, 3).map((opt, j) => ({
            value: opt?.id,
            label: renderMatchValue(opt?.result),
            key: j,
            'data-category': key,
            'data-name': opt?.result,
          })),
        }
      );
    } else {
      return null;
    }
  });

  const newOptions = compact(options);

  return (
    <AutoCompleteInput
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ width: 300 }}
      size="small"
      borderRadius={2}
      borderColor="grey8"
      style={{ width: "450px" }}
      options={newOptions}
      placeholder="Search by account, influencer, campaign, tag"
      optionLabelProp="value"
      onSelect={(value, y: any) => {
        if (y?.props["data-category"] === "account") {
          let searchObject = {
            accountId: value,
            influencerId: null,
            campaignId: null,
            tags: [],
          };
          handleChange("quickSearch", searchObject);
        }

        if (y?.props["data-category"] === "influencer") {
          let searchObject = {
            influencerId: value,
            accountId: null,
            campaignId: null,
            tags: [],
          };
          handleChange("quickSearch", searchObject);
        }

        if (y?.props["data-category"] === "tag") {
          let searchObject = {
            tags: [value],
            accountId: null,
            campaignId: null,
            influencerId: null,
          };
          handleChange("quickSearch", searchObject);
        }

        if (y?.props["data-category"] === "campaign") {
          let searchObject = {
            campaignId: value,
            accountId: null,
            influencerId: null,
            tags: [],
          };
          handleChange("quickSearch", searchObject);
        }
        onSearchTextChange(y?.props["data-name"]);
      }}
      onSearch={value => {
        onSearchTextChange(value as string);
      }}
      value={searchText}
    />
  );
};

export default QuickSearch;
