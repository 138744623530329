// @ts-nocheck
import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box } from "src/components/Core/Box";
import { Layout } from "antd";
import HeroActions from "./HeroActions";
import { ContentBox } from "./Wrapper";
// import { AccountsList as MainAccountsList } from "src/components/Modules/Accounts/AccountsList";
import { AccountsContainer } from 'src/components/Modules/Accounts'

const AccountsList = () => {
  const [showNewAccountForm, setShowNewAccountForm] = useState(false);
  const [contentType, setContentType] = useState("list");
  const [listName, setListName] = useState('')
  const [createListFlag, setCreateListFlag] = useState(false)
  const [createdList, setCreatedList] = useState({} as any)
  const [refetchFlag, setRefetchFlag] = useState(0)

  const handleCreatedList = (createdList: any) => {
    setCreatedList(createdList)
  }



  return (
    <DashboardLayout title="Accounts">
      <HeroActions
        title="Accounts"
        openAccountModal={() => setShowNewAccountForm(true)}
        contentType={contentType}
        createList={() => setCreateListFlag(true)}
        listFlag={listName.length === 0 || createdList?.id}
      />

      <Box p={15}>
        <Layout>
          <ContentBox>
            <AccountsContainer
              closeAccountModal={() => setShowNewAccountForm(false)}
              showNewAccountForm={showNewAccountForm}
              isInnerAccountList={false}
              contentType={contentType}
              setContentType={(type)=>setContentType(type)}
              createListFlag={createListFlag}
              listName={listName}
              setListName={setListName}
              setCreateListFlag={setCreateListFlag}
              handleCreatedList={handleCreatedList}
              refetchFlag={refetchFlag}
              setRefetchFlag={setRefetchFlag}
            />
          </ContentBox>
        </Layout>
      </Box>
    </DashboardLayout>
  );
};

export default AccountsList;
