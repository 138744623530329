import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class Username extends Component {
  render() {
    return (
      <FormField name={this.props.name || "username"} label="Username">
        <Input size="large" data-cy="account-username" placeholder="e.g. zoella" />
      </FormField>
    )
  }
}
