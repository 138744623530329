import { fk, attr, Model } from "redux-orm";

class RequestCreative extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Company, Campaign } = this.session;

    const parsedData = {
      ...data,
      company: Company.parse(data.company),
      campaign: Campaign.parse(data.campaign)
    };

    return this.upsert(parsedData);
  }
}
RequestCreative.modelName = "RequestCreative";

// Declare your related fields.
RequestCreative.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  campaign: attr(),
  company: fk("Company")
};

export default RequestCreative;
