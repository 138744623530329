// @ts-nocheck
import { message } from "antd";
import { FormikHelpers as FormikActions } from "formik";
import isEqual from "lodash/isEqual";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { featureFlags } from "src/constants/featureFlags";
import InvitationManage from "src/ducks/InvitationManage";
import {
  useCreateNewPaymentRequestMutation,
  useGetAllAgenciesQuery,
} from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { CompletePaymentRequestForm } from ".";
import { bankDetailFields } from "../../../../PaymentRequest/NewPaymentModal/PaymentDetails/BankDetailForm/Steps/BankDetailFields";
import {
  paymentDetail as PaymentDetail,
  usePaymentDetails,
} from "../../../../PaymentRequest/NewPaymentModal/PaymentDetailsProvider";
import { PaymentRequestSchema } from "./Validations";

interface Props {
  closePaymentModal: () => void;
  setStep: (value: number) => void;
  invitationId: number;
  updateStatus?: (id: any, from: any, to: any) => any;
  from: string;
  to: string;
  invitationData: any;
}

interface File {
  uploadUrl: string;
  uploadType: string;
}

interface CompletePaymentRequestFormValues {
  campaignId: number;
  accountId: number;
  agencyId?: number;
  vatNumber: string;
  description: string;
  currency: string;
  isVATRegister: boolean;
  amount: number;
  invitationId: number;
  views: number;
  screenshots: File[];
  influencerName: string;
  accountType?: string;
  tiktokUrl?: string;
  dontCreatePaymentRequest?: boolean;
  payingAgency?: boolean;
  agencyName?: string;
  attachments?: Array<any>;
}

const CompletePaymentRequestFormContainer: React.FC<Props> = props => {
  const [paymentType, setPaymentType] = useState(
    featureFlags.isPaypalEnabled ? "paypal" : "bank"
  );
  const [updateStatusloading, setUpdateStatusloading] = useState(false);
  const [payingToAgency, setPayingToAgency] = useState(false);

  const {
    actions,
    actualPaymentDetails,
    existingPaymentDetails,
  } = usePaymentDetails();

  const agency = useGetAllAgenciesQuery({
    skip: true,
  });

  const [
    createNewPaymentRequestMutation,
    { loading, error },
  ] = useCreateNewPaymentRequestMutation();

  const invitationData = props?.invitationData;

  // set selected Influencer Object
  useEffect(() => {
    if (payingToAgency) return;
    // if not paying out to agency then reset payment detail with invited influencer
    actions.setSelectedAgency({}); //reset selected agency
    if (invitationData?.account?.id) {
      let item = invitationData?.account?.influencer;
      actions.setSelectedInfluencer({ ...item } as any);

      const payoutDetails = item?.payoutDetails;
      const bankAccount = item?.payoutDetails?.bankAccount;

      let paymentDetail = {};

      if (featureFlags.isPaypalEnabled && payoutDetails?.paypalEmail) {
        paymentDetail = {
          ...paymentDetail,
          paypalEmail: payoutDetails?.paypalEmail,
        };
      }
      //set bank detail fields with loop from backDetail fields
      if (bankAccount) {
        let container = {} as any;
        // if bankaccount has country code then we set as per country wise field
        // else before we not set country code just UK bank detail so we set bydefault that fields
        if (bankAccount?.countryCode) {
          container["countryCode"] = bankAccount?.countryCode;
          bankDetailFields[bankAccount?.countryCode].fields.forEach(field => {
            container[field.name] = bankAccount[field.name]?.toString();
          });

          paymentDetail = {
            ...paymentDetail,
            bankAccount: container,
          };
        } else {
          paymentDetail = {
            ...paymentDetail,
            bankAccount: {
              fullName: bankAccount?.fullName || "",
              accountNumber: bankAccount?.accountNumber || null,
              sortCode: bankAccount?.sortCode || null,
            },
          };
        }
      }

      actions.setExistingPaymentDetails({ ...paymentDetail } as PaymentDetail);
      actions.setActualPaymentDetails({ ...paymentDetail } as PaymentDetail);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationData?.id, payingToAgency]);

  let initialValues = useMemo(() => {
    return {
      campaignId: invitationData?.campaign?.id,
      accountId: invitationData?.account?.id,
      invitationId: invitationData?.id,
      description: "Influencer Payout",
      isVATRegister: false,
      vatNumber: invitationData?.account?.influencer?.detail?.vatNumber || "",
      currency: invitationData?.payoutCurrency || "GBP",
      amount: invitationData?.actualPaidPennies || "",
      views: invitationData?.views || 0,
      influencerName: invitationData?.account?.influencer?.name,
      accountType: invitationData?.account?.type,
      screenshots:
        (
          (invitationData?.screenshots || []).filter(
            x => !!x?.uploadUrl && !!x?.uploadType
          ) || []
        ).map(x => ({
          uploadType: x?.uploadType,
          uploadUrl: x?.uploadUrl,
        })) || [],
      tiktokUrl: invitationData?.metadata?.tiktok_url || "",
      dontCreatePaymentRequest: false,
      payingAgency: false,
      agencyName: invitationData?.account?.influencer?.agency?.agencyName || "",
      agencyId: invitationData?.account?.influencer?.agency?.id || null,
      attachments: [],
    };
  }, [invitationData]);

  const onSubmit = async (
    values: CompletePaymentRequestFormValues,
    actions: FormikActions<CompletePaymentRequestFormValues>
  ) => {
    try {
      // if (invitationData?.account?.status === "Verified") {
      let paymentDetail = {};

      if (featureFlags.isPaypalEnabled && paymentType === "paypal") {
        paymentDetail = {
          ...paymentDetail,
          paypalEmail: actualPaymentDetails?.paypalEmail,
        };
      }

      if (paymentType === "bank") {
        paymentDetail = {
          ...paymentDetail,
          bankAccount: actualPaymentDetails?.bankAccount,
        };
      }

      let formData: any = {
        campaignId: values?.campaignId,
        accountId: values?.accountId,
        description: values?.description,
        vatNumber: values?.vatNumber,
        amount: Math.round(values?.amount),
        currency: values?.currency,
        paymentDetail: paymentDetail,
        invitationId: values?.invitationId,
        status: "complete",
        actualViews: values?.views,
        screenshots: values.screenshots.map(({ previewUrl, ...rest }) => rest),
        dontCreatePaymentRequest: values?.dontCreatePaymentRequest,
        attachments: values?.attachments,
      };

      if (values?.accountType === "TikTok") {
        formData = { ...formData, tiktokUrl: values?.tiktokUrl as string };
      }

      if (values?.payingAgency) {
        formData = {
          ...formData,
          agencyName: values?.agencyName as any,
          agencyId: values?.agencyId,
        };
      }
      const res = await createNewPaymentRequestMutation({
        variables: formData,
      });

      if (
        res?.data?.createNewPaymentRequest?.invitation?.id ||
        values?.dontCreatePaymentRequest
      ) {
        if (props.updateStatus) {
          setUpdateStatusloading(true);
          const updateStatus = await props.updateStatus(
            values?.invitationId,
            props.from,
            props.to
          );

          if (updateStatus) {
            message.success("Invitation updated!");
            setUpdateStatusloading(false);
            if (!values?.dontCreatePaymentRequest) {
              if (isEqual(existingPaymentDetails, actualPaymentDetails)) {
                props.setStep(3);
              } else {
                props.setStep(2);
              }
            } else {
              props.closePaymentModal();
            }
          }
        }
      }
      // } else {
      //   message.warning("Account must be verified!");
      // }
    } catch (e) {
      actions.setSubmitting(false);
      setUpdateStatusloading(false);
    }
  };
  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <CompletePaymentRequestForm
      key={invitationData?.id}
      initialValues={initialValues}
      PaymentRequestSchema={PaymentRequestSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      isSubmitting={loading || updateStatusloading}
      globalErrors={globalErrors}
      invitationData={invitationData}
      closePaymentModal={props.closePaymentModal}
      paymentType={paymentType}
      setPaymentType={value => setPaymentType(value)}
      getAgencies={agency.refetch}
      setPayingToAgency={setPayingToAgency}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  updateStatus: (id, from, to) =>
    dispatch(InvitationManage.updateStatus(id, from, to)),
});

export default connect(
  () => ({}),
  mapDispatchToProps
)(CompletePaymentRequestFormContainer);
