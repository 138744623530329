// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";
import { CampaignsTable } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useGetDiscoverSearchingTopInfluencersQuery } from "src/generated/ApolloComponents";

type Props = {};

export const CampaignsTableContainer: React.FC<Props> = () => {
  const [filters, setFilters] = useState({
    searchInput: "",
  });

  const {
    data,
    loading,
    error,
    refetch,
  } = useGetDiscoverSearchingTopInfluencersQuery({
    variables: filters,
  });

  const allAccounts = data?.getDiscoverSearchingTopInfluencers;

  const onFilterChange = async filters => {
    try {
      refetch(filters);
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onFilterChange, 1000)).current;

  useEffect(() => {
    let updatedFilters = { ...filters };

    debouncedFilterChange(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <CampaignsTable
      allAccounts={allAccounts}
      globalErrors={globalErrors}
      loading={loading}
      onFilter={(key, value) => {
        filters[key] = value;
        setFilters({ ...filters });
      }}
      filters={filters}
    />
  );
};
