// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";

interface Props {
  title: string;
  name: string;
}

const GroupTitle: React.FC<Props> = ({ title, name }) => {
  return (
    <Field label={title} mb={26}>
      <FormikInput
        name={name}
        size="middle"
        placeholder="e.g. Joe"
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default GroupTitle;
