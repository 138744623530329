// @ts-nocheck
import React from "react";
import { PaymentDetailForm } from ".";
import { object, string, array } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  InfluencerCashoutInvitationFragmentFragment,
  useInfluencerCashoutPaymentDetailMutation,
} from "src/generated/ApolloComponents";
import {camelToSnake, snakeToCamel} from "../../../../../utils/snakeCamelSnake";

interface Props {
  claimCashoutId: any;
  cashoutData: InfluencerCashoutInvitationFragmentFragment | null | undefined;
  goNext: () => void;
  goBack: () => void;
  index: number;
}

interface PaymentDetailFormValues {
  invoiceScreenshots: any;
  paypalEmail: string,
  payout_details: {
    bank_account: {
      country_code: string,
      full_name: string,
      sort_code: string,
      account_number: string,
      recipient_address: string,
      routing_number: string,
      swift: string,
      iban: string,
      bsb_code: string,
      bank_name: string,
      transit_number: string,
      institution_number: string,
    },
  }
}

export const PaymentDetailFormContainer: React.FC<Props> = ({
                                                              claimCashoutId,
                                                              goNext,
                                                              cashoutData,
                                                              goBack,
                                                              index,
                                                            }) => {
  const [
    influencerCashoutPaymentDetailMutation,
    {loading, error},
  ] = useInfluencerCashoutPaymentDetailMutation();

    const PaymentDetailSchema = object().shape({
      fullName: string()
        .nullable()
        .test("fullName", "Please enter fullname", function (value) {
          const {fullName, paypalEmail} = this.parent;
          if (!fullName && !paypalEmail) return value != null;
          return true;
        }),
      accountNumber: string()
        .nullable()
        .test("accountNumber", "Please enter account number", function (value) {
          const {paypalEmail, accountNumber} = this.parent;
          if (!paypalEmail && !accountNumber) return value != null;
          return true;
        }),
      sortCode: string()
        .length(6, "Sort Code must be exactly 6 digit")
        .nullable()
        .test("sortCode", "Please enter 6 digit Sort Code", function (value) {
          const {paypalEmail, sortCode} = this.parent;
          if (!paypalEmail && !sortCode) return value != null;
          return true;
        }),
      paypalEmail: string()
        .default(null)
        .nullable()
        .email("Must be a valid Email ID")
        .test(
          "paypalEmail",
          "Please enter either Paypal email Id or Bank Detail",
          function (value) {
            const {
              fullName,
              paypalEmail,
              accountNumber,
              sortCode,
            } = this.parent;
            if (!paypalEmail && (!fullName || !accountNumber || !sortCode))
              return value != null;
            return true;
          }
        ),
      invoiceScreenshots: array().default([]).nullable()
    });

    const payoutDetails = cashoutData?.account?.influencer?.payoutDetails;
    const snakeBankAccount = camelToSnake(payoutDetails?.bankAccount);
    const payoutCurrency = cashoutData?.payoutTypeCurrency || "";
    let initialValues = {
      payoutCurrency,
      paypalEmail: payoutDetails?.paypalEmail || null,
      fullName: payoutDetails?.bankAccount?.fullName || "",
      accountNumber: payoutDetails?.bankAccount?.accountNumber || "",
      sortCode: payoutDetails?.bankAccount?.sortCode || "",
      payout_details: {
        bank_account: {...snakeBankAccount}
      },
      invoiceScreenshots: []
    };

const onSubmit = async (
    values: PaymentDetailFormValues,
    actions: FormikActions<PaymentDetailFormValues>
) => {
  try {
    const bankAccountDetail = snakeToCamel(values?.payout_details.bank_account);
    const cleanBankAccountDetail = Object.fromEntries(Object.entries(bankAccountDetail)
      .filter(([k, v]) => v != null && k !== "typename"));
    const formData = {
      claimCashoutId: claimCashoutId,
      paypalEmail: values?.paypalEmail,
      bankAccount: cleanBankAccountDetail,
      invoiceScreenshots: values?.invoiceScreenshots
    };

    const res = await influencerCashoutPaymentDetailMutation({
      variables: formData,
    });

    if (res?.data?.influencerCashoutPaymentDetail) {
      goNext();
    }
  } catch (e) {
    actions.setSubmitting(false);
  }
};

const globalErrors = useMapGraphQLErrorsMessages(error);

return (
    <PaymentDetailForm
      initialValues={initialValues}
      paymentDetailSchema={PaymentDetailSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      goBack={goBack}
      index={index}
      cashoutData={cashoutData}
    />
);
}
;
