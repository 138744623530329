import React, { Component } from "react";
import { PercentageOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import FormField from "../FormField";
import { InputNumber } from "formik-antd";
import { genders } from "src/constants/account";
import { ErrorMessage } from "formik";
import FormError from "src/shared/FormField/FormError";

export default class GenderBreakdown extends Component {
  render() {
    return <>
      <Row gutter={8}>
        {genders.map((gender, index) => (
          <Col span={4} key={index}>
            <FormField
              name={
                this.props.gender_breakdown ||
                `gender_breakdown.manual[${gender.value}]`
              }
              label={`Gender (${gender.label})`}
            >
              <InputNumber
                size="large"
                min={0}
                max={100}
                addonAfter={<PercentageOutlined />}
              />
            </FormField>
          </Col>
        ))}
      </Row>
      <ErrorMessage
        component={FormError}
        name={`gender_breakdown.manual`}
        cy={`gender_breakdown-manual`}
        style={{ marginTop: "0px" }}
      />
    </>;
  }
}
