// @ts-nocheck
import React from 'react'
import Applicant from './Applicant'
import { Row, Col, Tag } from 'antd'
import { values } from '../constants'
import styled from 'styled-components'
import { Typography } from 'src/components/Core/Typography'
import { Droppable, Draggable } from 'react-beautiful-dnd'

const StyledRow = styled(Row)`
  background: white;
  padding: 10px 15px 0px;
`

const StyledCol = styled(Col)`
  display: flex !important;
  background: #E8E8E8;
  padding: 0px !important;
  margin: 20px 5px 0px;
  border-radius: 4px;
  min-height: 100vh !important;
  height: fit-content;
`

interface Props {
  moduleKey: string,
  accounts: []
  handleClick: (accId) => void
}

const Applicants = ({moduleKey, accounts, handleClick}: Props) => {
  return(
    <Droppable droppableId={moduleKey}>
      {(provided) => (
        <StyledCol span={8}>
          <div ref={provided.innerRef} {...provided.dropableProps}>
            <StyledRow justify="space-between">
              <Col>
                <Typography size={16} color="black2">{values[moduleKey].head}</Typography>
              </Col>
              <Col>
                <Tag color={values[moduleKey].color}>{accounts.length}</Tag>
              </Col>
              <Typography>{values[moduleKey].title}</Typography>
            </StyledRow>
            {accounts.map((acc: any) =>
              ["confirmed", "notified"].includes(acc.applyStatus) || !acc.id ?
                <Applicant
                  moduleKey={moduleKey}
                  account={acc}
                  handleClick={handleClick}
                  key={acc.id || acc.username + acc.platform}
                />
                :
                <Draggable draggableId={`${acc.id}`} index={0} key={acc.id}>
                  {(pro) => (
                    <div ref={pro.innerRef} {...pro.draggableProps} {...pro.dragHandleProps}>
                      <Applicant moduleKey={moduleKey} account={acc} handleClick={handleClick} />
                    </div>
                  )}
                </Draggable>
            )}
          </div>
        </StyledCol>
      )}
    </Droppable>
  )
}

export default Applicants
