import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import pluralize from "pluralize";
import qs from "qs";
import { withRouter } from "react-router-dom";
import { default as TableManagerDuck } from "src/ducks/TableManager";
import HeroActions from "./HeroActions";
import TableLayout from "./TableLayout";
import TableContext from "./Context";
import DashboardLayout from "src/scenes/layouts/DashboardLayout";

export class TableManager extends Component {
  componentDidMount() {
    const { addFilter, location, updateColumns, columns } = this.props;
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    addFilter({ ...parsed });
    this.timer = null;

    updateColumns(
      Object.keys(columns).map(key => ({
        key,
        dataIndex: columns[key].dataIndex,
        name: columns[key].title,
        visible: columns[key].visible,
      }))
    );
  }

  componentDidUpdate(prevProps) {
    const { history, filters, loadData } = this.props;

    if (prevProps.filters !== filters) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        loadData(filters).then(() =>
          history.push({
            search: qs.stringify(filters),
          })
        );
      }, 500);
    }
  }

  render() {
    const {
      filterOptions,
      columns,
      addedColumns,
      data,
      pagination,
      loading,
      model,
      name,
      createModal,
      stats,
      exportData,
      scroll,
    } = this.props;
    return (
      <DashboardLayout title={pluralize(model)}>
        <TableContext.Provider value={name}>
          <HeroActions
            model={model}
            createModal={createModal}
            name={name}
            exportData={exportData}
          />

          <TableLayout
            filterOptions={filterOptions}
            columns={columns}
            addedColumns={addedColumns}
            data={data}
            name={name}
            pagination={pagination}
            loading={loading}
            stats={stats}
            scroll={scroll}
          />
        </TableContext.Provider>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state, props) => {
  const Manager = TableManagerDuck(props.name);

  return {
    filters: Manager.selectFilters(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const Manager = TableManagerDuck(ownProps.name);

  return bindActionCreators(
    {
      addFilter: Manager.addFilter,
      updateColumns: Manager.updateColumns,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableManager)
);
