// @ts-nocheck
import React from "react";
import { Field } from "formik";
import { feedback_action_types } from "../../../../../constants/invitations";
import { SelectSearch } from "./styles";
type Props = {
  name: string;
  placeHolder?: string;
};

const FeedbackActionSelect = (props: Props) => {
  const handleChange = (field, form, value) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <Field {...props}>
      {({ field, form }) => (
        <div>
          <SelectSearch
            {...props}
            size="large"
            placeholder={props.placeHolder}
            data={feedback_action_types}
            local
            onChange={value => {
              handleChange(field, form, value);
            }}
          />
        </div>
      )}
    </Field>
  );
};

export default FeedbackActionSelect;
