// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox, Alert } from "antd";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import CompletePaymentRequestFormContainer from "./container";
import { Typography } from "src/components/Core/Typography";
import { FormikInput } from "src/components/Forms/TextInput";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { FormikCurrencyInput } from "src/components/Forms/CurrencyInput";
import { FormikSelect } from "src/components/Forms/Select";
import { Field } from "src/components/Forms/Field";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import { currency } from "src/constants/payments";
import styled from "styled-components";
import { Loading } from "src/components/Core/Loading";
import { Box } from "src/components/Core/Box";
import {
  FieldErrorMessage,
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { Agency } from "../../../../PaymentRequest/NewPaymentModal/PaymentForm/FormFields";
import { AttachmentsContainer } from "../../../../PaymentRequest/NewPaymentModal/PaymentForm/FormFields/Attachments";
import { BankDetailFormContainer } from "../../../../PaymentRequest/NewPaymentModal/PaymentDetails/BankDetailForm";
import { PaypalFormContainer } from "../../../../PaymentRequest/NewPaymentModal/PaymentDetails/PaypalForm";
import { ScreenshotsContainer } from "../Screenshots";
import DisplayDetails from "../DisplayDetails";
import { PostUrl, CreatePaymentRequest, PayingAgency } from "./FormFields";
import { featureFlags } from "src/constants/featureFlags";

interface CompletePaymentRequestFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  closePaymentModal: () => void;
  paymentType: string;
  setPaymentType: (value: string) => void;
  invitationData: any;
  initialValues: Values;
  isSubmitting: boolean;
  PaymentRequestSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  getAgencies: () => void;
  setPayingToAgency: Function;
}

export const CompletePaymentRequestFormWrapper = styled.div`
  width: 100%;
`;

const CompletePaymentRequestForm: React.FC<CompletePaymentRequestFormProps> = ({
  initialValues,
  isSubmitting,
  PaymentRequestSchema,
  onSubmit,
  globalErrors,
  closePaymentModal,
  setPaymentType,
  paymentType,
  invitationData,
  getAgencies,
  setPayingToAgency,
}) => {
  const [agencies, setAgencies] = useState([] as any)
  const [isUploading, setIsUploading] = useState(false);
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);

  useEffect(() => {
    const agency: any = invitationData?.account?.influencer?.agency
    if(agency){
      setAgencies([agency, ...agencies])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[invitationData])

  return (
    <CompletePaymentRequestFormWrapper>
      <Loading spinning={isSubmitting}>
        <Form
          initialValues={initialValues}
          schema={PaymentRequestSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                <Box mb={40}>
                  <Typography size={40} weight="semi-bold" color="black">
                    Payment Request
                  </Typography>
                  <Typography size={16} color="black1" lspace={1.16}>
                    Please enter a few essential details about your campaign.
                  </Typography>
                </Box>

                <GlobalErrors errors={globalErrors} />

                {formProps?.values?.accountType === "TikTok" && (
                  <Row gutter={48} justify="start">
                    <Col span={12}>
                      <PostUrl />
                    </Col>
                  </Row>
                )}

                <Row gutter={48} justify="start">
                  <Col span={12}>
                    <DisplayDetails invitationData={invitationData} />
                    <Box mt={20}>
                      <PayingAgency
                        isChecked={formProps?.values?.payingAgency}
                        setPayingToAgency={setPayingToAgency}
                        setFieldValue={formProps?.setFieldValue}
                        setFieldTouched={formProps?.setFieldTouched}
                      />
                    </Box>
                  </Col>

                  <Col span={12}>
                    {formProps?.values.payingAgency && (
                      <Agency
                        agencies={agencies}
                        getAgencies={getAgencies}
                        setAgencies={setAgencies}
                        agencyId={formProps.values.agencyId}
                        setFieldValue={(key, value) =>
                          formProps?.setFieldValue(key, value)
                        }
                        formProps={formProps}
                      />
                    )}
                    <Field label="Influencer:" mb={26}>
                      <FormikInput
                        name="influencerName"
                        size="large"
                        background="background"
                        borderRadius={3}
                        borderColor="grey5"
                        value={formProps?.values?.influencerName}
                        disabled={true}
                      />
                    </Field>

                    <Box display="inline-flex" mb={26}>
                      <Checkbox
                        name="isVATRegister"
                        onChange={() => {
                          formProps?.setFieldTouched("isVATRegister");
                          formProps?.setFieldValue(
                            "isVATRegister",
                            !formProps?.values.isVATRegister
                          );
                        }}
                        value={formProps?.values.isVATRegister}
                        data-cy="checkbox-isVATRegister"
                      />
                      <Typography size={12} color="grey9" ml={10} mt={0.9}>
                        Influencer is VAT registered
                      </Typography>
                    </Box>
                    <Field
                      label="VAT Number:"
                      mb={26}
                      disabled={!formProps?.values.isVATRegister}
                    >
                      <FormikInput
                        name="vatNumber"
                        size="large"
                        placeholder="e.g. GB 795796789"
                        background="background"
                        borderRadius={3}
                        borderColor="grey5"
                        disabled={!formProps?.values.isVATRegister}
                      />
                    </Field>
                  </Col>
                </Row>

                <Row gutter={48} justify="start">
                  <Col span={12}>
                    <Field label="Actual views:" mb={26} mt={26}>
                      <FormikInputNumber
                        name="views"
                        size="large"
                        placeholder="0"
                        background="background"
                        borderRadius={1}
                        borderColor="grey5"
                        value={formProps?.values?.views}
                      />
                    </Field>
                    <Field label="Screenshots:">
                      <ScreenshotsContainer
                        name="screenshots"
                        isUploading={isUploading}
                        setIsUploading={loading => setIsUploading(loading)}
                        setFieldValue={value =>
                          formProps?.setFieldValue("screenshots", value)
                        }
                        setFieldTouched={() =>
                          formProps?.setFieldTouched("screenshots", true)
                        }
                        values={formProps?.values}
                      />
                    </Field>

                    <Field label="Attachments:">
                      <AttachmentsContainer
                        name="attachments"
                        setIsUploading={loading =>
                          setIsAttachmentUploading(loading)
                        }
                        setFieldValue={value =>
                          formProps?.setFieldValue("attachments", value)
                        }
                        setFieldTouched={() =>
                          formProps?.setFieldTouched("attachments", true)
                        }
                        values={formProps?.values}
                        formProps={formProps}
                      />
                    </Field>
                  </Col>
                  <Col span={12}>
                    <Field label="Amount:" mb={26} mt={26}>
                      <Box display="inline-flex" width="100%">
                        <FormikSelect
                          name="currency"
                          data={currency}
                          local
                          style={{ width: 80 }}
                          background="background"
                          borderRadius="3px 0px 0px 3px"
                          borderColor="grey5"
                          size="large"
                        />
                        <FormikCurrencyInput
                          name="amount"
                          size="large"
                          background="background"
                          borderColor="grey5"
                          style={{ borderRadius: "0px 3px 3px 0px" }}
                          shouldShowError={false}
                        />
                      </Box>
                      <FieldErrorMessage
                        errors={formProps?.errors}
                        touched={formProps?.touched}
                        mt={20}
                        name="amount"
                      />
                    </Field>

                    {formProps?.values.isVATRegister && (
                      <Alert
                        message="Enter cost including VAT"
                        description="If your payee is VAT registered, please enter the amount including VAT"
                        type="info"
                        style={{ marginBottom: "10px" }}
                      />
                    )}

                    <CreatePaymentRequest formProps={formProps} />

                    <Field
                      label="Payment details:"
                      mb={26}
                      fieldDisabled={
                        formProps?.values?.dontCreatePaymentRequest
                      }
                    >
                      <Row gutter={16}>
                        {featureFlags.paypalPayment && (
                          <Col span={12}>
                            <PaypalFormContainer
                              paymentType={paymentType}
                              setPaymentType={value => setPaymentType(value)}
                            />
                          </Col>
                        )}
                        <Col span={featureFlags.paypalPayment ? 12 : 24}>
                          <BankDetailFormContainer
                            paymentType={paymentType}
                            setPaymentType={value => setPaymentType(value)}
                          />
                        </Col>
                      </Row>
                    </Field>
                  </Col>
                </Row>

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={100}
                >
                  <LinkButton onClick={() => closePaymentModal()} mr={50}>
                    Cancel
                  </LinkButton>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={
                      isSubmitting || isUploading || isAttachmentUploading
                    }
                    buttonSize="middle"
                    data-cy="complete-modal-form-submit"
                  >
                    Submit
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </CompletePaymentRequestFormWrapper>
  );
};

export { CompletePaymentRequestForm, CompletePaymentRequestFormContainer };
