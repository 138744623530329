// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { AdAuthoriseContainer } from "./container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";

interface AdAuthoriseProps {
  isSubmitting: boolean;
  globalErrors?: GlobalErrorProps[];
}

const AdAuthorise: React.FC<AdAuthoriseProps> = ({
  isSubmitting,
  globalErrors,
}) => {
  return (
    <>
      <GlobalErrors errors={globalErrors} />

      <Box background="white" mt={10} mb={10} borderRadius="4px">
        <Loading spinning={isSubmitting}>
          <Box p={60}>Loading</Box>
        </Loading>
      </Box>
    </>
  );
};

export { AdAuthorise, AdAuthoriseContainer };
