// @ts-nocheck
import React from "react";
import { useInfluencersListDetails } from "../InfluencersListProvider";
import { DataSourcePlatform } from "src/generated/ApolloComponents";
import NewAccountModal from "../NewAccountModal";

interface Props {
  showNewAccountForm: boolean;
  closeAccountModal: () => void;
}

const NewAccountModalWrapper = ({
  closeAccountModal,
  showNewAccountForm,
}: Props) => {
  const { actions: influencerAction } = useInfluencersListDetails();

  const onSucessAddNewAccount = () => {
    let container = {} as any;
    container["platformType"] = DataSourcePlatform.Fanbytes;
    container["page"] = 1;
    influencerAction.setFilters({ ...container });

    influencerAction.refetchAccounts({ ...container });

    closeAccountModal();
  };
  return (
    <NewAccountModal
      closeAccountModal={closeAccountModal}
      showNewAccountForm={showNewAccountForm}
      onSucessAddNewAccount={onSucessAddNewAccount}
    />
  );
};

export default NewAccountModalWrapper;
