import React, { Component } from "react";
import FormField from "../FormField";
import { Rate } from "formik-antd";

export default class ResponseTime extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "metadata.rating.response_time"}
        label="Response time"
        help="How quickly did the influencer respond to messages and email"
      >
        <Rate data-cy="account-response-time" />
      </FormField>
    );
  }
}
