// @ts-nocheck
import React, { useState } from "react"
import DashboardLayout from "src/scenes/layouts/DashboardLayout"
import { NoGiftingCampaigns } from "./NoGiftingCampaigns"
import { CreateGiftingCampaignModalContainer } from "./CreateGiftingCampaignModal/container"
import { useGetGiftingCampaignsQuery } from "src/generated/ApolloComponents"
import { Spin } from "antd"
import { Box } from "src/components/Core/Box"
import { GiftingCampaignList } from "./CampaignList"

interface Props {}

export const GiftingDashboard = (props: Props) => {
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const { data, loading, refetch } = useGetGiftingCampaignsQuery({
    pollInterval: 500
  })

  const campaigns = data?.getGiftingCampaigns

  return (
    <DashboardLayout title="Gifting">
      {loading && (
        <Box textAlign="center" mt={30}>
          <Spin spinning={true} />
        </Box>
      )}

      {!loading && campaigns && campaigns?.length === 0 && (
        <NoGiftingCampaigns setCreateModalVisible={setCreateModalVisible} />
      )}

      {!loading && campaigns && campaigns.length > 0 && (
        <GiftingCampaignList
          reloadRecords={refetch}
          campaigns={campaigns}
          setCreateModalVisible={setCreateModalVisible}
        />
      )}

      <CreateGiftingCampaignModalContainer
        setVisible={setCreateModalVisible}
        visible={createModalVisible}
      />
    </DashboardLayout>
  )
}
