// @ts-nocheck
import React from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Radio } from "antd";
import styled from "styled-components";
import { Field } from "src/components/Forms/Field";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { FilterProps } from "../../PaymentQueueList";
import { TextInputNumber } from "src/components/Forms/TextInputNumber";

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const AmountRadio = styled(Radio.Group)`
  &&& {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${props => props.theme.colors.blue} !important;
      color: ${props => props.theme.colors.white} !important;
    }
  }
`;

const Price: React.FC<Props> = ({ handleChange, filters }) => {
  return (
    <Field label="Price:">
      <Box>
        <AmountRadio>
          <Radio.Button
            value={false}
            onChange={value => {
              if (filters?.cost || filters?.cost === 0) {
                handleChange("isGreaterThan", false);
              }
            }}
            checked={filters?.isGreaterThan === false}
          >
            <LeftOutlined />
          </Radio.Button>
          <Radio.Button
            value={null}
            onChange={value => {
              if (filters?.cost || filters?.cost === 0) {
                handleChange("isGreaterThan", null);
              }
            }}
            checked={filters?.isGreaterThan === null}
          >
            <Typography
              size={24}
              lineHeight="22px"
              cursor="pointer"
              fontWeight="bold"
            >
              .
            </Typography>
          </Radio.Button>
          <Radio.Button
            value={true}
            onChange={value => {
              if (filters?.cost || filters?.cost === 0) {
                handleChange("isGreaterThan", true);
              }
            }}
            checked={filters?.isGreaterThan === true}
          >
            <RightOutlined />
          </Radio.Button>
        </AmountRadio>

        <TextInputNumber
          size="small"
          background="background"
          borderRadius={3}
          borderColor="grey5"
          defaultValue={0}
          style={{ marginLeft: 5, width: "65px" }}
          formatter={value => Number(value) / 100}
          parser={value => {
            let parsed = value.replace(/[^0-9]/g, "");
            if (parsed) {
              return parsed * 100;
            } else {
              return 0;
            }
          }}
          onChange={v => {
            handleChange("cost", v);
          }}
          value={filters?.cost}
        />
      </Box>
    </Field>
  );
};

export default Price;
