import React, { Component } from "react";
import { connect } from "react-redux";
import pluralize from "pluralize";
import { Button } from "antd";
import { show } from "redux-modal";
import PageHero from "src/shared/PageHero";
import ColumnsModal from "./ColumnsModal";
import ExportModal from "./ExportModal";
import TableContext from "./Context";

export class HeroActions extends Component {
  render() {
    const { show, model, createModal, exportData } = this.props;

    return (
      <TableContext.Consumer>
        {value => (
          <>
            <PageHero
              title={pluralize(model)}
              subTitle={`Click to view more`}
              extra={[
                exportData ? (
                  <Button key="export" onClick={exportData}>
                    Export CSV
                  </Button>
                ) : (
                  ""
                ),
                <Button
                  key="columns"
                  onClick={() => show("ColumnsModal", { moduleName: value })}
                >
                  Manage Columns
                </Button>,
                <Button
                  type="primary"
                  key="create"
                  data-cy="create-hero-button"
                  onClick={() => show(`Create${model}Modal`)}
                >
                  {`Create new ${model}`}
                </Button>,
              ]}
            />

            <>
              <ExportModal />
              <ColumnsModal />
            </>

            {createModal}
          </>
        )}
      </TableContext.Consumer>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  show,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroActions);
