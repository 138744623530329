// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { Box } from "src/components/Core/Box";
import { Field } from "src/components/Forms/Field";
import { FilterProps } from "../../PaymentQueueList";

const StatusCheckbox = styled("div")`
  &&& {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px !important;
    }
  }
`;

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const PaymentType: React.FC<Props> = ({ handleChange, filters }) => {
  return (
    <Field label="Payment Type:">
      <Box display="block">
        <StatusCheckbox>
          <Checkbox
            value="bankTransfer"
            onChange={value => {
              let filterPaymentType = filters?.paymentType || [];
              const index = filterPaymentType.indexOf("bankTransfer");
              if (index === -1) {
                filterPaymentType.push("bankTransfer");
              } else {
                filterPaymentType.splice(index, 1);
              }

              handleChange("paymentType", [...filterPaymentType]);
            }}
            checked={
              (filters?.paymentType || []).indexOf("bankTransfer") !== -1
            }
          >
            Bank transfer
          </Checkbox>
          <Checkbox
            value="other"
            onChange={value => {
              let filterPaymentType = filters?.paymentType || [];
              const index = filterPaymentType.indexOf("other");
              if (index === -1) {
                filterPaymentType.push("other");
              } else {
                filterPaymentType.splice(index, 1);
              }

              handleChange("paymentType", [...filterPaymentType]);
            }}
            checked={(filters?.paymentType || []).indexOf("other") !== -1}
          >
            Other
          </Checkbox>
        </StatusCheckbox>
      </Box>
    </Field>
  );
};

export default PaymentType;
