import React, { Component } from "react";
import { connect } from "react-redux";
import FormField from "../FormField";
import { connect as FormikConnect } from "formik";
import SearchSelect from "src/shared/SearchSelect";
import { CompanyList as $CompanyList } from "src/ducks/DataList";

const CompanyList = $CompanyList("AccountCompanyList");

export class Company extends Component {
  render() {
    const { loadCompanies } = this.props;

    return (
      <FormField name={this.props.name || "company"} label="Company">
        <SearchSelect
          data-cy="campaign-company"
          loadData={value =>
            loadCompanies({ search: value }).then(res => res.value.data.data)
          }
          optionGenerator={value => ({
            value: value.id,
            label: value.company_name,
          })}
          size="large"
          placeholder="Select..."
        />
      </FormField>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loadCompanies: CompanyList.loadMany,
};

export default FormikConnect(
  connect(mapStateToProps, mapDispatchToProps)(Company)
);
