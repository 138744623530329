// @ts-nocheck
import React from "react";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from "antd";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import ExampleVideo from "../ExampleVideo";
import { useScreenClass } from "react-grid-system";

interface Props {}

const YoutubeDraftUrl: React.FC<Props> = () => {
  const screenClass = useScreenClass();
  return (
    <Box textAlign="left">
      <Field label="Youtube draft URL:" mb={26}>
        <FormikInput
          name="youtubeDraftUrl"
          size="middle"
          borderRadius={2}
          borderColor="grey5"
          placeholder="e.g. youtube.com/vsu7dh"
          suffix={
            <Popover
              trigger={
                screenClass === "xs" || screenClass === "sm" ? "click" : "hover"
              }
              content={<ExampleVideo />}
              title={"Where to find your draft URL?"}
            >
              <InfoCircleOutlined style={{ color: "#1890FF" }} />
            </Popover>
          }
        />
        <Typography size={14} color="grey7">
          Please ensure the draft permissions allow anyone with the link to view
        </Typography>
      </Field>
    </Box>
  );
};

export default YoutubeDraftUrl;
