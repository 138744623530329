import React, { Component } from "react"
import FormField from "../FormField"
import { Select } from "formik-antd"
import { location_targets } from "src/constants/campaign"

export default class Locations extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "locations"}
        label="Location"
        help="Where do you want the influencer's audience to be based?"
      >
        <Select
          data-cy="locations-select"
          size="large"
          placeholder="Select..."
          mode="multiple"
          options={location_targets}
          style={{ width: '100%' }}
        ></Select>
      </FormField>
    )
  }
}
