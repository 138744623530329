// @ts-nocheck
import React, { useState } from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { BankDetailFormContainer } from "./container";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import AddButton from "../AddButton";
import DisplayDetails from "../DisplayDetails";
import { Step1, Step2 } from "./Steps";
import { bankDetailFields } from "./Steps/BankDetailFields";

interface BankDetailFormProps<Values = FormikValues> {
  showBankDetailForm: boolean;
  setShowBankDetailForm: (value: boolean) => void;
  paymentType: string;
  setPaymentType: (value: string) => void;
  initialValues: Values;
  isSubmitting: boolean;
  BankDetailSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
}

export const BankDetailFormWrapper = styled.div`
  width: 100%;
`;

const BankDetailForm: React.FC<BankDetailFormProps> = ({
  initialValues,
  isSubmitting,
  BankDetailSchema,
  onSubmit,
  showBankDetailForm,
  setShowBankDetailForm,
  setPaymentType,
  paymentType,
}) => {
  const [step, setStep] = useState<number>(1);

  return (
    <BankDetailFormWrapper>
      <Loading spinning={false}>
        <Box
          background="#FFFFFF"
          boxShadow="0px 1px 4px rgba(0, 0, 0, 0.157439)"
          borderRadius={2}
        >
          <Form
            initialValues={initialValues}
            schema={BankDetailSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {(formProps: any) => {
              const countryCode = formProps?.values?.countryCode;
              const title = bankDetailFields[countryCode].renderTitle(
                formProps?.values
              );
              const subTitle = bankDetailFields[countryCode].renderSubtitle(
                formProps?.values
              );
              return (
                <>
                  {!showBankDetailForm && (
                    <>
                      {title || subTitle ? (
                        <DisplayDetails
                          paymentType={paymentType}
                          setPaymentType={value => setPaymentType(value)}
                          setShowDetailForm={value =>
                            setShowBankDetailForm(value)
                          }
                          title={title}
                          subTitle={subTitle}
                          formType="bank"
                        />
                      ) : (
                        <AddButton
                          text="Bank Detail"
                          addClick={value => setShowBankDetailForm(true)}
                        />
                      )}
                    </>
                  )}

                  {showBankDetailForm && (
                    <Box textAlign="center" p={10}>
                      {step === 1 && (
                        <Step1
                          continueClick={() => setStep(2)}
                          cancelClick={value => setShowBankDetailForm(value)}
                        />
                      )}

                      {step === 2 && (
                        <Step2
                          countryCode={countryCode}
                          isSubmitting={isSubmitting}
                          backClick={() => setStep(1)}
                          onSave={() => formProps?.submitForm()}
                          formType="bank"
                          value={formProps?.values}
                        />
                      )}
                    </Box>
                  )}
                </>
              );
            }}
          </Form>
        </Box>
      </Loading>
    </BankDetailFormWrapper>
  );
};

export { BankDetailForm, BankDetailFormContainer };
