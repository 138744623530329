import React, { Component } from "react"
import FormField from "../FormField"
import { InputNumber } from "formik-antd"

export default class Comments extends Component {
  render() {
    return (
      <FormField name={this.props.name || "metadata.comments"} label="Comments">
        <InputNumber
          data-cy="invitation-comments"
          style={{ width: "100%" }}
          size="large"
          placeholder="1000"
        />
      </FormField>
    )
  }
}
