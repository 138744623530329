import { string, object, number, array } from "yup";
import values from "lodash/values";
import sum from "lodash/sum";
import sumBy from "lodash/sumBy";

export default object().shape({
  username: string()
    .nullable()
    .required("Please enter a username"),
  type: string()
    .nullable()
    .required("Please enter a platform"),
  // status: string()
  //   .nullable()
  //   .required("Please enter a status"),
  cpm: number()
    .nullable()
    .required("Please enter a cpm"),
  influencer: number()
    .nullable()
    .required("Please select an influencer"),
  format: string()
    .nullable()
    .required("Please enter a format"),

  age_breakdown: object().shape({
    manual: object().test(
      "manual",
      "Please enter total of percentage upto 100%",
      function(value) {
        if (sum(values(value)) > 100) return false;
        return true;
      }
    ),
  }),

  gender_breakdown: object().shape({
    manual: object().test(
      "manual",
      "Please enter total of percentage upto 100%",
      function(value) {
        if (sum(values(value)) > 100) return false;
        return true;
      }
    ),
  }),

  locationBreakdown: array().test(
    "locationBreakdown",
    "Please enter total of percentage upto 100%",
    function(value) {
      let totalPercentage = sumBy(value, function(location) {
        return location.percentage;
      });

      if (totalPercentage > 100) return false;
      return true;
    }
  ),
});
