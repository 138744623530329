// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import { TagsTableContainer } from "./container";
import { Loading } from "src/components/Core/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import DataTable from "./DataTable";
import { DiscoverCategory } from "src/generated/ApolloComponents";

export interface FilterProps {
  category: DiscoverCategory;
}

interface Props {
  allData: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onTrack: (value: any) => void;
}

const TagsTable: React.FC<Props> = ({
  allData,
  loading,
  globalErrors,
  onTrack,
}) => {
  return (
    <Box p={20}>
      <Loading spinning={loading}>
        <GlobalErrors errors={globalErrors} />
        <Row gutter={16}>
          <Col span={8}>
            <DataTable
              data={allData?.views}
              title="Views"
              subTitle="Top performing influencers based on views"
              type="views"
              onTrack={value => onTrack(value)}
            />
          </Col>
          <Col span={8}>
            <DataTable
              data={allData?.engagement}
              title="Engagement rate"
              subTitle="Top performing influencers based on Engagement rate"
              type="engagement"
              onTrack={value => onTrack(value)}
            />
          </Col>
          <Col span={8}>
            <DataTable
              data={allData?.growth}
              title="Growth"
              subTitle="Top performing influencers based on growth over the last x amount of time"
              type="growth"
              onTrack={value => onTrack(value)}
            />
          </Col>
        </Row>
      </Loading>
    </Box>
  );
};

export { TagsTable, TagsTableContainer };
