import * as ormHOD from "./Orm"
import { createSelector } from "reselect"
import * as api from "../api/endpoints"
import { pushIfNotExist } from "src/utils/pushIfNotExist"

export const initialState = {
  isPending: false,
  ids: [],
  allIds: [],
  id: null,
  pagination: {},
  stats: {},
  request_at: 0,
  error: null
}

const DataList = (type) => (name) => {
  const ormModule = ormHOD[type]
  const endpoint = api[type]
  const stateName = "List" + type + name

  // Types
  const types = {
    loadMany: `${name}_LOAD_${type}s`,
    loadOne: `${name}_LOAD_${type}`,
    destroy: `${name}_DESTROY_${type}`,
    clearAll: `${name}_CLEAR_ALL_${type}`,
    clear: `${name}_CLEAR_${type}`,
    clearOne: `${name}_CLEAR_ONE_${type}`,
    addOne: `${name}_ADD_ONE_${type}`,
    reset: `${name}_RESET_${type}`
  }

  // Action creators
  const actions = {
    loadMany: (query = {}) => {
      return (dispatch) => {
        const response = dispatch({
          type: types.loadMany,
          payload: endpoint.index({
            params: query
          })
        })

        response.then((res) => dispatch(ormModule.addMany(res.value.data.data)))

        return response
      }
    },
    loadOne: (id, query = {}) => {
      return (dispatch) => {
        const response = dispatch({
          type: types.loadOne,
          payload: endpoint.get(id, {
            params: query
          })
        })

        response.then((res) => dispatch(ormModule.addOne(res.value.data)))

        return response
      }
    },
    destroy: (id) => {
      return (dispatch) => {
        const response = dispatch({
          type: types.destroy,
          payload: endpoint.destroy(id)
        })

        response.then((res) => dispatch(ormModule.delete(id)))

        return response
      }
    },
    clear: () => ({
      type: types.clear
    }),
    clearOne: (id) => ({
      type: types.clearOne,
      payload: id
    }),
    addOne: (id) => ({
      type: types.addOne,
      payload: id
    }),
    reset: () => ({
      type: types.reset
    }),
    clearAll: () => ({
      type: types.clearAll
    })
  }

  // Reducer
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case `${types.loadMany}_REJECTED`:
        return {
          ...state,
          error: action.payload,
          isPending: false
        }
      case `${types.loadMany}_PENDING`:
        return {
          ...state,
          isPending: true
        }
      case `${types.loadMany}_FULFILLED`:
        const pagination = Object.assign({}, { ...action.payload.data })
        const stats = Object.assign({}, { ...action.payload.data.stats })
        delete pagination.data

        if (action.payload.config.meta.sent_at < state.request_at) {
          return {
            ...state,
            isPending: false
          }
        } else {
          const ids = action.payload.data.data.map((x) => x.id)
          let currentIds = [...state.allIds]
          ids.map((x) => pushIfNotExist(currentIds, x, (y) => x === y))
          return {
            ...state,
            request_at: action.payload.config.meta.sent_at,
            ids: ids,
            allIds: currentIds,
            pagination,
            stats,
            isPending: false
          }
        }
      case `${types.loadOne}_PENDING`:
        return {
          ...state,
          isPending: true
        }
      case `${types.loadOne}_FULFILLED`:
        if (action.payload.config.meta.sent_at < state.request_at) {
          return {
            ...state,
            isPending: false
          }
        } else {
          return {
            ...state,
            id: action.payload.data.id,
            isPending: false
          }
        }
      case types.clear:
        return {
          ...initialState
        }
      case types.clearOne:
        return {
          ...state,
          allIds: [...state.allIds].filter((value, index, arr) => value !== action.payload)
        }
      case types.addOne:
        return {
          ...state,
          allIds: [action.payload, ...state.allIds]
        }
      case types.clearAll:
        return {
          ...state,
          allIds: []
        }
      case types.reset:
        return {
          ...initialState
        }
      default:
        return { ...state }
    }
  }

  // Selectors
  const entities = (state) => state.entities
  const selectId = (state) => state[stateName].id
  const selectIds = (state) => state[stateName].ids
  const selectAllIds = (state) => state[stateName].allIds
  const selectPending = (state) => state[stateName].isPending

  const selectors = {
    selectIds,
    selectId,
    selectAllIds,
    selectPending,

    selectMany: createSelector(entities, selectIds, ormModule.selectMany),

    selectAll: createSelector(entities, selectAllIds, ormModule.selectMany),

    selectOne: createSelector(entities, selectId, ormModule.selectOne),

    selectPagination: (state) => state[stateName].pagination,
    selectStats: (state) => state[stateName].stats
  }

  const DraftListModule = () => ({
    id: stateName,
    reducerMap: {
      [stateName]: reducer
    }
  })

  return {
    types,
    reducer,
    module: DraftListModule,
    ...actions,
    ...selectors
  }
}

export const CampaignList = DataList("Campaign")
export const CampaignListModule = (name) => CampaignList(name).module

export const CompanyList = DataList("Company")
export const CompanyListModule = (name) => CompanyList(name).module

export const InfluencerList = DataList("Influencer")
export const InfluencerListModule = (name) => InfluencerList(name).module

export const BrandList = DataList("Brand")
export const BrandListModule = (name) => BrandList(name).module

export const TagList = DataList("Tag")
export const TagListModule = (name) => TagList(name).module

export const SuggestionList = DataList("Suggestion")
export const SuggestionListModule = (name) => SuggestionList(name).module

export const AccountList = DataList("Account")
export const AccountListModule = (name) => AccountList(name).module

export const AdminList = DataList("Admin")
export const DraftList = DataList("Draft")

export const AccountPostList = DataList("AccountPost")
export const AccountPostListModule = (name) => AccountPostList(name).module

export const InvitationList = DataList("Invitation")
export const InvitationListModule = (name) => InvitationList(name).module

export const LinkList = DataList("Link")
export const LinkListModule = (name) => LinkList(name).module

export const AgencyList = DataList("Agency")
export const AgencyListModule = (name) => AgencyList(name).module

export const PayoutList = DataList("Payout")
export const PayoutListModule = (name) => PayoutList(name).module
