import React, { Component } from "react";
import { connect } from "react-redux";
import { InvitationPersist } from "src/ducks/Persist";
import { Alert } from "antd";
import PayoutForm from "./PayoutForm";

const InvitationManage = InvitationPersist("InvitationModal");

export class PayInfluencer extends Component {
  render() {
    const { invitation, updateInvitation, onUpdate } = this.props;

    return (
      <>
        {
          invitation?.campaign?.status === 'complete' ?
            <Alert
              message="Completed campaign"
              description="This campaign has been completed. No more payment can be made"
              type="info"
              style={{ marginBottom: 24 }}
            />
          :
            !!invitation.payout_pennies && invitation.payout_pennies > 0 && (
            <Alert
              message="Influencer paid"
              description="You can update the payout amount, but this will not pay the influencer again"
              type="info"
              style={{ marginBottom: 24 }}
            />
        )}

        <PayoutForm
          invitation={invitation}
          updateInvitation={updateInvitation}
          onUpdate={onUpdate}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  invitation: InvitationManage.selectOne(state)
});

const mapDispatchToProps = {
  updateInvitation: InvitationManage.update
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayInfluencer);
