// @ts-nocheck
import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { FormikInput } from "src/components/Forms/TextInput";
import { Field } from "src/components/Forms/Field";
import { RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const SearchInput: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (formProps?.values?.searchInput) {
    isVisible = true;
  }

  return (
    <Field
      label="Search:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("searchInput", "");
              clearFilterKey("searchInput");
            }}
            style={{ marginTop: "-1px", marginLeft: "15px" }}
          />
        )
      }
      id="searchInput"
    >
      <FormikInput
        size="middle"
        borderRadius={3}
        name="searchInput"
        placeholder="Search by."
        suffix={<SearchOutlined />}
      />
    </Field>
  );
};

export default SearchInput;
