// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import StyledTextArea from "./StyledTextArea";
import { TextAreaProps } from "./StyledTextArea";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";

interface Props {
  name?: string;
  disabled?: boolean;
  size?: any;
  rows?: number;
}
type InputProps = Omit<TextAreaProps, "size">;

const FormikTextArea: React.FC<Props & InputProps> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur },
      }) => (
        <>
          <StyledTextArea
            {...props}
            {...field}
            rows={props?.rows || 5}
            onBlur={e => {
              handleBlur(e);
            }}
            data-cy={`textarea-${field.name.replace(" ", "-")}`}
          />
          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={field.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikTextArea;
