import { object, array, string, number } from 'yup'

export const schema = object().shape({
  ageScreenshot: object().required('Plesae provide age break down screenshot'),
  ageBreakdown: object().required('Please at least provide detail for one group'),
  locationScreenshot: object().required('Please provide location break down screenshot'),
  locationBreakdown: array().of(object().shape({ key: string(), value: number() }))
                            .test('location-req', 'Please provide at least one location', locations => {
                              return locations.some(location => location.key.length === 2 && location.value > 0)
                            })
})
