// @ts-nocheck
import { string, object, number, array } from "yup";
import sumBy from "lodash/sumBy";

export const PaymentRequestSchema = object().shape({
  ageBreakdown: array()
    .of(
      object().shape({
        key: string()
          .nullable()
          .required("Please select breakdown option"),

        value: number()
          .nullable()
          .required("Please enter value"),
      })
    )
    .min(1, "You must add at least one breakdown")
    .test(
      "ageBreakdown",
      "Please enter total of percentage upto 100%",
      function(value) {
        let totalPercentage = sumBy(value, function(location: any) {
          return location?.value;
        });

        if (totalPercentage > 100) return false;
        return true;
      }
    ),
  locationBreakdown: array()
    .of(
      object().shape({
        key: string()
          .nullable()
          .required("Please select breakdown option"),

        value: number()
          .nullable()
          .required("Please enter value"),
      })
    )
    .min(1, "You must add at least one breakdown")
    .test(
      "locationBreakdown",
      "Please enter total of percentage upto 100%",
      function(value) {
        let totalPercentage = sumBy(value, function(location: any) {
          return location?.value;
        });

        if (totalPercentage > 100) return false;
        return true;
      }
    ),
});
