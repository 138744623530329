// @ts-nocheck
import React from 'react'
import { Field } from 'src/components/Forms/Field'
import { FormikInputNumber } from 'src/components/Forms/TextInputNumber'

interface Props {}

const Goal: React.FC<Props> = () => {
  return (
    <Field label="Goal" mb={26} fontSize={14} labelBottom={0} color="black4">
      <FormikInputNumber
        name="goal"
        size="middle"
        borderRadius={3}
        borderColor="grey8"
        // placeholder="Please enter the amount of views your post got "
      />
    </Field>
  );
};

export default Goal;
