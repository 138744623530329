// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";

interface Props {}

const DisLikes: React.FC<Props> = () => {
  return (
    <Field label="Dislikes" mb={26}>
      <FormikInputNumber
        parser={value => {
          let parsed = value.replace(/\[0-9]/g, "");
          if (parsed) {
            return parsed.match(/\d+/g) ? parseInt(parsed) : "";
          } else {
            return null;
          }
        }}
        name="dislikes"
        size="middle"
        borderRadius={3}
        borderColor="grey8"
        placeholder="0"
      />
    </Field>
  );
};

export default DisLikes;
