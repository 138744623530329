// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { Card, Typography, Tag, Badge, Row, Col, Statistic } from "antd";
import styled from "styled-components";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import { currency as allCuurency } from "src/constants/payments";
import numeral from "numeral";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import moment from "moment";

const { Title, Text } = Typography;

const Wrapper = styled(Card)`
  && {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const Meta = styled("div")`
  display: block;
`;

const LaunchText = styled(Text)`
  margin-right: 15px;
`;

const StatusContainer = styled("span")`
  &&& {
    margin-left: 15px;
  }
`;

const CenterStatistic = styled(Statistic)`
  text-align: center;
`;
interface Props {
  campaign: any;
  destroyCampaign: (campaignId: Number) => void;
}

const CampaignCard = ({ campaign, destroyCampaign }: Props) => {
  let currencySign = find(
    allCuurency,
    matchesProperty("value", campaign?.budgetCurrency)
  );

  return (
    <Link
      to={
        campaign?.isGroup
          ? `/campaign-group/${campaign?.id}`
          : `/campaigns/${campaign?.id}`
      }
    >
      <Wrapper hoverable>
        <Row>
          <Col span={12}>
            <Badge count={0}>
              <Title level={4} data-cy="campaign-card-title">
                {campaign?.title || "Untitled"}
                <StatusContainer>
                  {campaign?.status === "draft" ? (
                    <ConfirmDeleteIcon
                      data-cy="delete-icon"
                      text="Draft"
                      onConfirm={e => {
                        e.preventDefault();
                        destroyCampaign(campaign?.id);
                      }}
                    />
                  ) : (
                    <>
                      {campaign?.status === "active" ? (
                        <Tag color="green" key="status">
                          Active
                        </Tag>
                      ) : campaign?.status === "submitted" ? (
                        <Tag color="purple" key="status">
                          Under Review
                        </Tag>
                      ) : campaign?.status === "complete" ? (
                        <Tag color="magenta" key="status">
                          Complete
                        </Tag>
                      ) : (
                        <Tag color="red" key="status">
                          Draft
                        </Tag>
                      )}
                    </>
                  )}
                </StatusContainer>
              </Title>
            </Badge>
            <Meta>
              {campaign?.isGroup ? (
                <Tag>Multi-level Campaign</Tag>
              ) : (
                <>
                  <LaunchText type="secondary">
                    Launch date:{" "}
                    {campaign?.startDate
                      ? moment(campaign?.startDate).format("Do MMM YYYY")
                      : ""}
                  </LaunchText>
                  <Tag color="blue">{campaign?.adFormat}</Tag>
                </>
              )}
            </Meta>
          </Col>
          <Col sm={0} xs={0} md={0} lg={12}>
            <Row justify="end">
              <Col span={8}>
                <CenterStatistic
                  title="Budget"
                  value={`${
                    campaign?.budgetCurrency === "MIX"
                      ? "[MIX]"
                      : currencySign?.label
                  }${
                    campaign?.budgetPennies
                      ? numeral(campaign?.budgetPennies / 100).format(
                          "0,0[.]00"
                        )
                      : "--"
                  }`}
                />
              </Col>
              <Col span={8}>
                <CenterStatistic
                  title="Views"
                  value={numeral(campaign?.views).format("0,0a")}
                />
              </Col>
              <Col span={8}>
                <CenterStatistic
                  title="Clicks"
                  value={numeral(campaign?.clicks).format("0,0a")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Wrapper>
    </Link>
  );
};

export default CampaignCard;
