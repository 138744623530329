// @ts-nocheck
import React from "react";
import { AttachmentsContainer } from "./container";
import { InboxOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import styled from "styled-components";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";
import { FormikProps } from "formik";

export const AttachmentsWrapper = styled.div`
  width: 100%;
`;

type AttachmentsProps = {
  fileList: Array<any>;

  handleRemove: (value: any) => void;
  onUpload: (value: any) => void;
  formProps: FormikProps<any>;
};

const Attachments: React.FC<AttachmentsProps> = ({
  handleRemove,
  onUpload,
  fileList,
  formProps,
}) => {
  return (
    <AttachmentsWrapper data-cy="input-attachments">
      <Upload.Dragger
        multiple={false}
        fileList={fileList}
        accept="application/pdf"
        customRequest={file => onUpload(file)}
        onRemove={file => handleRemove(file)}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Upload invoices or related documents</p>
      </Upload.Dragger>
      <FieldErrorMessage
        errors={formProps?.errors}
        touched={formProps?.touched}
        mt={20}
        name="attachments"
      />
    </AttachmentsWrapper>
  );
};

export { Attachments, AttachmentsContainer };
