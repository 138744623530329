import React, { Component } from "react"
import FormField from "../FormField"
import { InputNumber } from "formik-antd"

export default class AvgLikes extends Component {
  render() {
    return (
      <FormField name={this.props.name || "metadata.avg_likes"} label="Avg. Likes">
        <InputNumber
          data-cy="account-avg-likes"
          style={{ width: "100%" }}
          size="large"
          placeholder="1000"
        />
      </FormField>
    )
  }
}
