import React, { Component } from "react";
import moment from "moment";
import { Table } from "antd";
import styled from "styled-components";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import withWindowResize from "src/utils/hoc/withWindowResize";

const TeamTableUI = styled(Table)`
  &&& {
    .ant-table-body {
      overflow: auto !important;
    }
  }
`;

class TeamTable extends Component {
  renderDelete = (text, record) => {
    const { currentUser, removeTeamMember } = this.props;

    return currentUser.id !== record.id ? (
      <ConfirmDeleteIcon
        text="Delete"
        key={record.id}
        onConfirm={e => {
          e.preventDefault();
          removeTeamMember(record.id);
        }}
      />
    ) : (
      <div />
    );
  };

  render() {
    const {
      pagination: { current_page, total_entries, per_page },
      changePage,
      data,
      height,
    } = this.props;

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 250,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 300,
      },
      {
        title: "Confirmed At",
        dataIndex: "confirmed_at",
        key: "confirmed_at",
        width: 150,
        render: confirmed_at =>
          confirmed_at ? moment(confirmed_at).format("Do MMM YYYY") : null,
      },
      {
        title: "Actions",
        key: "actions",
        width: 150,
        render: this.renderDelete,
      },
    ];

    return (
      <TeamTableUI
        pagination={{
          current: current_page,
          pageSize: per_page,
          total: total_entries,
          onChange: changePage,
        }}
        rowKey="id"
        columns={columns}
        dataSource={data}
        scroll={{ y: height, x: "max-content" }}
      />
    );
  }
}

export default withWindowResize({ extraHeight: 260 })(TeamTable);
