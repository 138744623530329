import React, { Component } from "react";
import styled from "styled-components";
import { List, Card, Avatar, Empty } from "antd";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import InfiniteScroll from "react-infinite-scroller";
import { InvitationList as $InvitationList } from "src/ducks/DataList";
import idx from "idx";
import { isImgLink } from "src/utils/isImgLink";

const InvitationList = $InvitationList("AnalyticsLiveContent");

const FixedList = styled(List)`
  &&& {
    .ant-row {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

const modules = [InvitationList.module()];

export class LiveContent extends Component {
  state = {
    page: 1,
  };

  componentDidMount() {
    this.fetchInvitations();
  }

  loadMoreInvitations = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      this.fetchInvitations
    );
  };

  fetchInvitations() {
    const { campaign, loadInvitations } = this.props;
    loadInvitations({
      campaign: campaign.id,
      expand: ["account"],
      stage: "posted",
      account_format: "Human",
      page: this.state.page,
    });
  }

  render() {
    const {
      invitations,
      invitationsLoading,
      invitationsPagination: { next_page },
    } = this.props;

    return (
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        threshold={600}
        loadMore={this.loadMoreInvitations}
        hasMore={!invitationsLoading && !!next_page}
      >
        <FixedList
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          dataSource={invitations}
          locale={{
            emptyText: <Empty description="No live content right now!" />,
          }}
          renderItem={item => {
            const draft = item.orderedDrafts().first()
            const draftMedia = idx(draft, x => x.all_media) || [];

            return draftMedia.map((item1, index) => {
              const contentType = isImgLink(item1) ? "image" : "video";
              const content =
                contentType === "video" ? (
                  <video src={item1} controls />
                ) : (
                  <img
                    style={{ width: "100%", height: 420, objectFit: "contain" }}
                    src={item1}
                    alt="account-post-img"
                  />
                );

              return (
                <List.Item>
                  <Card
                    // cover={
                    //   item.orderedDrafts().at(0) && (
                    //     <video
                    //       src={item.orderedDrafts().at(0).video_url}
                    //       controls
                    //     />
                    //   )
                    // }
                    cover={content}
                  >
                    <Card.Meta
                      avatar={
                        <Avatar
                          size="large"
                          src={item.account.profile_pic_url}
                          shape="square"
                        />
                      }
                      title={item.account.username}
                      description={item.formattedUpdatedAt()}
                    />
                  </Card>
                </List.Item>
              );
            });
          }}
        />
      </InfiniteScroll>
    );
  }
}

const mapStateToProps = state => ({
  invitations: InvitationList.selectAll(state),
  invitationsLoading: InvitationList.selectPending(state),
  invitationsPagination: InvitationList.selectPagination(state),
});

const mapDispatchToProps = {
  loadInvitations: InvitationList.loadMany,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(LiveContent)
)(modules);
