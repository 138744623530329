// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import Loading from "src/shared/Loading";
import { DemographicGendersGraphContainer } from "./container";
import PieChartGraphCard from "../PieChartGraphCard";

interface Props {
  loading: boolean;
  data: Array<any>;
  chartDataType: string;
  setChartDataType: (value: string) => void;
  isShowFilter: boolean;
}

const DemographicGendersGraph: React.FC<Props> = ({
  loading,
  data,
  chartDataType,
  setChartDataType,
  isShowFilter,
}) => {
  return (
    <Box background="#FFFFFF" p={10} borderRadius="2px">
      <Loading spinning={loading}>
        <PieChartGraphCard
          title="Demographic: Genders"
          data01={data}
          chartDataType={chartDataType}
          setChartDataType={value => setChartDataType(value)}
          isShowFilter={isShowFilter}
        />
      </Loading>
    </Box>
  );
};

export { DemographicGendersGraph, DemographicGendersGraphContainer };
