// @ts-nocheck
import { Steps } from 'antd'
import styled from 'styled-components'

interface Props {
  status: string
}

export const OuterCircle = styled.div<Props>`
  background-color: #FFFFFF;
  color: white;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.status === 'wait' ? '#D7D7D7' : '#1890FF'};
`

export const InnerCircle = styled.div<Props>`
  background: ${props => props.status === 'wait' ? '#D7D7D7' : '#1890FF'};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: 0 auto;
`

export const StyledSteps = styled(Steps)`
  .ant-steps-item-tail {
    padding: 28px 0 0px !important;
    left: 2px !important;
  }

  .ant-steps-item {
    height: 130px !important;
  }

  .ant-steps-item-tail::after {
    background-image: linear-gradient(white 33%, rgba(255,255,255,0) 0%);
    background-position: right;
    background-size: 1px 15px;
    background-repeat: repeat-y;
    background-color: #D7D7D7 !important;
  }

  .ant-steps-item-content {
    padding-left: 23px !important;
    padding-top: 5px !important;
  }
`
