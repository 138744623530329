// @ts-nocheck
import { string, object } from "yup";

export const InvitationContentSchema = object().shape({
  youtubeDraftUrl: string()
    .nullable()
    .test("youtubeDraftUrl", "Please enter youtube url", function(value) {
      const { type, youtubeDraftUrl } = this.parent;
      if (type === "youtube" && !youtubeDraftUrl) return value != null;
      return true;
    }),

  caption: string()
    .nullable()
    .test("caption", "Please enter caption", function(value) {
      const { type, caption } = this.parent;
      if (type !== "youtube" && !caption) return value != null;
      return true;
    }),
});
