import React, {useEffect} from "react"
import LoginFrom from "./LoginForm"
import LoginLayout from "../../layouts/LoginLayout"
import {GoogleSSO} from "./GoogleSSO";
import Loading from "src/shared/Loading";
import {useGetJwtTokenFromGoogleTokenMutation} from "../../../generated/ApolloComponents";
import {message} from "antd";


export const Login = () => {
    const [getJwtTokenFromGoogleTokenMutation, {loading, error}] = useGetJwtTokenFromGoogleTokenMutation();

    useEffect(() => {
        if (error && error.graphQLErrors.find((err) => err.extensions.code === "FORBIDDEN")) {
            message.error("This email has no 'ADMIN' rights. Please register first.", 3);
        } else if (error && error.graphQLErrors.find((err) => err.extensions.code === "BAD_USER_INPUT")) {
            message.error("A Google error has occurred, please try again later.", 3);
        } else if (error) {
            message.error("An error has occurred, please try again later.", 3);
        }
    }, [error])

    return (
        <Loading spinning={loading}>
            <LoginLayout title="Cortex Influencer Campaign Manager">
                <LoginFrom/>
                <GoogleSSO mutation={getJwtTokenFromGoogleTokenMutation}/>
            </LoginLayout>
        </Loading>
    )
}
