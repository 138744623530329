// @ts-nocheck
import { animated } from "react-spring";
import styled from "styled-components";

export const FirstStar = styled(animated.div)`
  && {
    position: absolute;

    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      right: -4px;
      top: 155px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      top: 148px;
      right: 19px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      top: 117px;
      right: 80px;
    }

    @media only screen and (min-width: 1510px) and (max-width: 1650px) {
      top: 117px;
      right: 78px;
    }

    /* Large Devices, Desktops */
    @media only screen and (min-width: 1920px) {
      top: 117px;
      right: 77px;
    }
  }
`;

export const FirstStarImg = styled("img")`
  && {
    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      height: 110px;
      width: 110px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      height: 110px;
      width: 110px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      height: 170px;
      width: 170px;
    }

    @media only screen and (min-width: 1510px) and (max-width: 1650px) {
      height: 170px;
      width: 170px;
    }

    /* Large Devices, Desktops */
    @media only screen and (min-width: 1920px) {
      height: 170px;
      width: 170px;
    }
  }
`;

export const SecondStar = styled(animated.div)`
  && {
    position: absolute;
    z-index: 999;
    transform: rotate(-21.91deg);
    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      top: 433px;
      right: 214px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      top: 437px;
      right: 237px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      top: 413px;
      right: 287px;
    }

    @media only screen and (min-width: 1510px) and (max-width: 1650px) {
      top: 410px;
      right: 290px;
    }

    /* Large Devices, Desktops */
    @media only screen and (min-width: 1920px) {
      top: 413px;
      right: 293px;
    }
  }
`;

export const SecondStarImg = styled("img")`
  && {
    transform: rotate(-21.91deg);
    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      height: 110px;
      width: 110px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      height: 110px;
      width: 110px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      height: 170px;
      width: 170px;
    }

    @media only screen and (min-width: 1510px) and (max-width: 1650px) {
      height: 170px;
      width: 170px;
    }

    /* Large Devices, Desktops */
    @media only screen and (min-width: 1920px) {
      height: 170px;
      width: 170px;
    }
  }
`;
