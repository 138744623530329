// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Button } from 'antd';
import { Typography } from 'src/components/Core/Typography'

const StyledButton = styled("a")<{top: number, left: number, position: string}>`
  width: 153px;
  height: 40px !important;
  color: #595959 !important;
  padding: 8px 15px !important;
  border-radius: 4px !important;
  background: #E8E8E8 !important;
  top: ${props => `${props.top}px`};
  left: ${props => `${props.left}px`};
  position: ${props => `${props.position} !important`};
`

interface Props {
  top: number
  left: number
  position: string
}

const HomeButton = ({top, left, position}: Props) => {
  return (
    <StyledButton top={top} left={left} position={position} href="/">
      <Row justify="space-around" align="middle">
        <ArrowLeftOutlined />
        <Typography family="Futura" size={14} color="black2" cursor="pointer" style={{marginBottom: 0}}>
          Back to Home
        </Typography>
      </Row>
    </StyledButton>
  );
}

export default HomeButton;
