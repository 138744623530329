// @ts-nocheck
import React from "react";
import { Tag } from "antd";

interface Props {
  status?: string | undefined | null;
}

const DraftStatus: React.FC<Props> = ({ status }) => {
  const formattedStatus = status => {
    return status === "approved" ? (
      <Tag color="green" key="status">
        Approved
      </Tag>
    ) : status === "submitted" ? (
      <Tag color="purple" key="status">
        Under Review
      </Tag>
    ) : status === "redraft" ? (
      <Tag color="magenta" key="status">
        Redraft Requested
      </Tag>
    ) : null;
  };

  return (
    <div style={{ marginLeft: 15 }} data-cy="draft-status">
      {formattedStatus(status)}
    </div>
  );
};

export default DraftStatus;
