// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import { Button } from "src/components/Core/Button";
import { Tag } from "src/components/Core/Tag";
import qs from "qs";
import { Typography } from "src/components/Core/Typography";

interface Props {
  authorised: any;
  campaignId: number;
}

const AuthoriseAdPlatform: React.FC<Props> = ({ authorised, campaignId }) => {
  let urlParams = qs.stringify({
    redirect_uri: `${window.location.origin}/ad-authorise`,
    state: campaignId?.toString(),
    app_id: "6932422143263113218",
  });

  const isAuthorised = isAuthorised => {
    return isAuthorised ? (
      <Tag
        size={12}
        tagColor="green6"
        background="green1"
        borderColor="lime"
        mr={30}
        height="22px"
      >
        Authorised
      </Tag>
    ) : (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey8"
        mr={30}
        height="22px"
      >
        Not authorised
      </Tag>
    );
  };

  return (
    <Box background="white" mt={10} mb={10} borderRadius="4px">
      <Box mb={10}>
        <Row gutter={32}>
          <Col span={6}>
            <Typography size={16} color="black2" lineHeight="22px">
              TikTok:
            </Typography>
          </Col>
          <Col span={6}>{isAuthorised(authorised?.TikTok)}</Col>

          <Col span={12}>
            <a href={`https://ads.tiktok.com/marketing_api/auth?${urlParams}`}>
              <Button
                type="action"
                buttonSize="small"
                data-cy="tiktok-ad-button"
                style={{ width: "100%" }}
              >
                Authorise TikTok Ads
              </Button>
            </a>
          </Col>
        </Row>
      </Box>

      <Box mb={10}>
        <Row gutter={32}>
          <Col span={6}>
            <Typography size={16} color="black2" lineHeight="22px">
              Facebook:
            </Typography>
          </Col>
          <Col span={6}>{isAuthorised(authorised?.Facebook)}</Col>

          <Col span={12}>
            <Button
              type="disabled"
              buttonSize="small"
              data-cy="tiktok-ad-button"
              style={{ width: "100%" }}
            >
              Authorise Facebook Ads
            </Button>
          </Col>
        </Row>
      </Box>

      <Box mb={10}>
        <Row gutter={32}>
          <Col span={6}>
            <Typography size={16} color="black2" lineHeight="22px">
              Instagram:
            </Typography>
          </Col>
          <Col span={6}>{isAuthorised(authorised?.Instagram)}</Col>

          <Col span={12}>
            <Button
              type="disabled"
              buttonSize="small"
              data-cy="tiktok-ad-button"
              style={{ width: "100%" }}
            >
              Authorise Instagram Ads
            </Button>
          </Col>
        </Row>
      </Box>
    </Box>
  );
};

export default AuthoriseAdPlatform;
