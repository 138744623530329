// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";

interface Props {
  name: string;
  count: string;
}

const Stats: React.FC<Props> = ({ name, count }) => {
  return (
    <Box display="flex" justifyContent="space-between" mt={10}>
      <Typography size={12} color="red5" lineHeight={0}>
        {count}
      </Typography>
      <Box flex={1} borderBottom="1px dashed #BFBFBF"></Box>
      <Typography size={12} color="black2" lineHeight={0}>
        {name}
      </Typography>
    </Box>
  );
};

export default Stats;
