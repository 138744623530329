// @ts-nocheck
import React from "react";
import { AdHubTableContainer } from "src/components/Modules/Campaign/AdHub";

interface Props {}

const AdHub: React.FC<Props> = () => {
  return <AdHubTableContainer />;
};

export default AdHub;
