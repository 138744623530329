import React, { Component } from "react"
import FormField from "../FormField"
import { Select } from "formik-antd"
import { ages } from "src/constants/account"

export default class Ages extends Component {
  render() {
    return (
      <FormField name={this.props.name || "detail.age"} label="Age">
        <Select data-cy="influencer-ages" size="large" placeholder="Select..." options={ages}>
        </Select>
      </FormField>
    )
  }
}
