// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import ClearButton from "./ClearButton";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
  getCampaigns: Function;
  clearFilterKey: (key: string) => void;
}

const Campaign: React.FC<Props> = ({
  formProps,
  getCampaigns,
  clearFilterKey,
}) => {
  let campaignIds = formProps?.values?.campaignIds;
  let isVisible = false;
  let backgroundColor = "#FFFFFF";

  if (campaignIds?.length > 0) {
    isVisible = true;
    backgroundColor = "#FAFAFA";
  }

  return (
    <Box background={backgroundColor} padding="5px">
      <Field
        label="Campaign:"
        mb={20}
        extra={
          <ClearButton
            isVisible={isVisible}
            buttonClick={() => {
              formProps?.setFieldValue("campaignIds", []);
              clearFilterKey("campaignIds");
            }}
          />
        }
      >
        <FormikSelect
          name="campaignIds"
          size="middle"
          loadData={async value => {
            let campaign = await getCampaigns({
              campaignTitle: value,
            });
            if (campaign?.data?.getAllCampaigns?.length > 0) {
              return campaign.data.getAllCampaigns;
            } else {
              return [];
            }
          }}
          optionGenerator={value => ({
            value: value.id,
            label: value.title,
          })}
          mode="multiple"
          borderRadius={3}
          borderColor="grey5"
        />
      </Field>
    </Box>
  );
};

export default Campaign;
