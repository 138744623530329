// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import TikTokPost from "src/components/Modules/TikTokPost";
import { EyeFilled, HeartFilled } from '@ant-design/icons';
import { Card } from "antd";
import { Typography } from "src/components/Core/Typography";
import ReactVideoPlayer from "src/components/Modules/ReactVideoPlayer";

const EllipsisText = styled("span")`
  &&& {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-end;
  }
`;

const ContentCard = styled(Card)`
  &&& {
    .ant-card-body {
      display: none;
    }
  }
`;
export const OverlayGradient = styled("div")`
  background: linear-gradient(
    359.36deg,
    rgba(0, 0, 0, 0.6) 5.37%,
    rgba(0, 0, 0, 0) 34.5%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const OverlayDiv = styled("div")`
  position: absolute;
  bottom: 9px;
  z-index: 1;
  color: #fff !important;
  display: inline-block;
  width: 83%;
  padding: 0 10px 0 10px;
`;

const videoStyle = {
  height: "380px",
  objectFit: "contain",
  backgroundColor: "#000",
  borderRadius: "4px",
};

interface Props {
  contentType: string;
  contentUrl: string;
  likes: string;
  views: string;
  accountName: string;
}

const AccountContent: React.FC<Props> = ({
  contentType,
  contentUrl,
  likes,
  views,
  accountName,
}) => {
  return (
    <Box>
      <ContentCard
        hoverable
        cover={
          <>
            <OverlayGradient />

            {contentType === "tiktok" ? (
              <div style={{ width: "325px", height: "570px" }}>
                <TikTokPost url={contentUrl} />
              </div>
            ) : contentType === "video" ? (
              <div style={{ width: "300px" }}>
                <ReactVideoPlayer
                  videoUrl={contentUrl}
                  shouldShowControls={false}
                  isPlay={true}
                  style={videoStyle as any}
                  height={380}
                />
              </div>
            ) : (
              <img
                style={{ width: "100%", height: "380px" }}
                src={contentUrl}
                alt="content-post-img"
              />
            )}
          </>
        }
      />

      <OverlayDiv>
        {(likes || views) && (
          <Box display="flex" pb={10} justifyContent="space-between">
            {likes && <EllipsisText>{accountName}</EllipsisText>}

            <Box>
              {likes && (
                <Typography weight="semi-bold" size={12} mb={0}>
                  <HeartFilled style={{ marginRight: 5 }} />
                  {likes}
                </Typography>
              )}
              {views && (
                <Typography weight="semi-bold" size={12} mb={0}>
                  <EyeFilled style={{ marginRight: 5 }} />
                  {views}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </OverlayDiv>
    </Box>
  );
};

export default AccountContent;
