// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { PageHeader } from "antd";

const DiscoverHeader = styled(PageHeader)`
  && {
    .ant-page-header-heading-title {
      font-size: 24px;
    }
    .ant-page-header-heading-sub-title {
      display: block !important;
    }
  }
`;

interface Props {
  title: string;
  subTitle: string;
}

const HeroActions: React.FC<Props> = ({ title, subTitle }) => {
  return <DiscoverHeader title={title} subTitle={subTitle} ghost={false} />;
};

export default HeroActions;
