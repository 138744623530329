// @ts-nocheck
import {
  DataSourcePlatform,
  MetricSelectType,
} from "src/generated/ApolloComponents";

export function getFormData(values) {
  let filters = {} as any;

  if (values?.platformType) {
    filters["platformType"] = values?.platformType;
  }

  if (values?.searchInput) {
    filters["searchInput"] = values?.searchInput;
  }

  if (values?.includeEstimatedCosts) {
    filters["includeEstimatedCosts"] = values?.includeEstimatedCosts;
  }

  if (
    values?.metricSelect &&
    values?.platformType === DataSourcePlatform.Bytesights
  ) {
    filters["metricSelect"] = values?.metricSelect;
  }

  if (
    (values?.campaignIds || []).length > 0 &&
    values?.platformType === DataSourcePlatform.Fanbytes
  ) {
    filters["campaignIds"] = values?.campaignIds;
  }

  if (
    values?.accountType &&
    values?.platformType !== DataSourcePlatform.Bytesights
  ) {
    filters["accountType"] = values?.accountType;
  }

  if (
    values?.influencerLocation &&
    values?.platformType !== DataSourcePlatform.Bytesights
  ) {
    filters["influencerLocation"] = values?.influencerLocation;
  }

  if (
    values?.influencerGender &&
    values?.platformType !== DataSourcePlatform.Bytesights
  ) {
    filters["influencerGender"] = values?.influencerGender;
  }

  if (
    (values?.tagIds || []).length > 0 &&
    values?.platformType === DataSourcePlatform.Fanbytes
  ) {
    filters["tagIds"] = values?.tagIds;
  }

  if (values?.followers !== 0) {
    let container = {} as any;
    container[values?.followersIsgreaterThan ? "gt" : "lt"] = values?.followers;
    filters["followers"] = container;
  }

  if (values?.hearts !== 0) {
    let container = {} as any;
    container[values?.heartsIsgreaterThan ? "gt" : "lt"] = values?.hearts;
    filters["hearts"] = container;
  }

  if (values?.price !== 0) {
    let container = {} as any;
    container[values?.priceIsgreaterThan ? "gt" : "lt"] = values?.price;
    filters["price"] = container;
  }

  if (
    values?.genderAudience?.gender !== "" &&
    values?.platformType !== DataSourcePlatform.Bytesights
  ) {
    let container = {} as any;
    container["key"] = values?.genderAudience?.gender;

    let obj = {} as any;

    obj[values?.genderAudience?.genderIsgreaterThan ? "gt" : "lt"] =
      values?.genderAudience?.count;

    container["value"] = obj;
    filters["audienceGender"] = container;
  }

  if (
    values?.ageAudience?.age !== "" &&
    values?.platformType !== DataSourcePlatform.Bytesights
  ) {
    let container = {} as any;
    container["key"] = values?.ageAudience?.age;

    let obj = {} as any;

    obj[values?.ageAudience?.ageIsgreaterThan ? "gt" : "lt"] =
      values?.ageAudience?.count;

    container["value"] = obj;
    filters["audienceAge"] = container;
  }

  if (
    values?.locationAudience?.location !== "" &&
    values?.platformType !== DataSourcePlatform.Bytesights
  ) {
    let container = {} as any;
    container["key"] = values?.locationAudience?.location;

    let obj = {} as any;

    obj[values?.locationAudience?.locationIsgreaterThan ? "gt" : "lt"] =
      values?.locationAudience?.count;

    container["value"] = obj;
    filters["audienceLocation"] = container;
  }
  filters["page"] = 1;

  return filters;
}

export function getInitialValues(filters) {
  let initialValues = {
    platformType: filters?.platformType || DataSourcePlatform.Fanbytes,
    searchInput: filters?.searchInput || "",
    metricSelect: filters?.metricSelect || MetricSelectType.Bio,
    campaignIds: filters?.campaignIds || [],
    accountType: filters?.accountType || "",
    influencerLocation: filters?.influencerLocation || "",
    influencerGender: filters?.influencerGender || "",
    includeEstimatedCosts: filters?.includeEstimatedCosts || false,
    tagIds: filters?.tagIds || [],
    followers: Object.values(filters?.followers || {})[0] || 0,
    followersIsgreaterThan: filters?.followers?.gt
      ? true
      : filters?.followers?.lt
      ? false
      : true,
    hearts: Object.values(filters?.hearts || {})[0] || 0,
    heartsIsgreaterThan: filters?.hearts?.gt
      ? true
      : filters?.hearts?.lt
      ? false
      : true,
    price: Object.values(filters?.price || {})[0] || 0,
    priceIsgreaterThan: typeof filters?.price?.gt
      ? true
      : filters?.price?.lt
      ? false
      : true,
    locationAudience: {
      location: filters?.audienceLocation?.key || "",
      locationIsgreaterThan: filters?.audienceLocation?.value?.gt
        ? true
        : filters?.audienceLocation?.value?.lt
        ? false
        : true,
      count: Object.values(filters?.audienceLocation?.value || {})[0] || 0,
    },
    genderAudience: {
      gender: filters?.audienceGender?.key || "",
      genderIsgreaterThan: filters?.audienceGender?.value?.gt
        ? true
        : filters?.audienceGender?.value?.lt
        ? false
        : true,
      count: Object.values(filters?.audienceGender?.value || {})[0] || 0,
    },
    ageAudience: {
      age: filters?.audienceAge?.key || "",
      ageIsgreaterThan: filters?.audienceAge?.value?.gt
        ? true
        : filters?.audienceAge?.value?.lt
        ? false
        : true,
      count: Object.values(filters?.audienceAge?.value || {})[0] || 0,
    },
  };

  return initialValues;
}

export function shouldShowClearAllFilter(filters) {
  let show = false;
  if (filters?.searchInput) {
    show = true;
  }
  if (filters?.metricSelect) {
    show = true;
  }
  if (filters?.campaignIds) {
    show = true;
  }
  if (filters?.accountType) {
    show = true;
  }
  if (filters?.influencerLocation) {
    show = true;
  }
  if (filters?.influencerGender) {
    show = true;
  }
  if (filters?.includeEstimatedCosts) {
    show = true;
  }
  if (filters?.accountType) {
    show = true;
  }

  if (filters?.followers) {
    show = true;
  }

  if (filters?.hearts) {
    show = true;
  }

  if (filters?.price) {
    show = true;
  }

  if (filters?.audienceLocation) {
    show = true;
  }

  if (filters?.audienceGender) {
    show = true;
  }
  if (filters?.audienceAge) {
    show = true;
  }

  return show;
}
