// @ts-nocheck
import SearchSelect from "../../../../SearchSelect";
import styled from "styled-components";
export const SelectSearch = styled(SearchSelect)`
  &&& {
    max-width: 280px;
    height: 40px !important;
    width: 415px;
    color: #808080;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.01px;
    line-height: 16px;
    .ant-select-arrow {
      font-size: 16px !important;
    }
    .ant-select-selection {
      border-radius: 0px !important;
      height: 40px !important;
    }
  }
`;
