// @ts-nocheck
import React, { useRef } from 'react'
import Tags from './Tags'
import Header from './Header'
import Section from './Section'
import { isEmpty } from 'lodash'
import Auth from 'src/ducks/Auth'
import Examples from './Examples'
import DosDonts from './DosDonts'
import { Row, Col, Divider } from 'antd'
import { useSelector } from 'react-redux'
import ApplySection from './ApplySection'
import { Box } from 'src/components/Core/Box'
import DateSection from './Mobile/DateSection'
import { TransactionContainer } from './container'
import { Typography } from "src/components/Core/Typography";
import DashboardLayout from 'src/scenes/layouts/DashboardLayout'
import Loading from 'src/shared/Loading'
import { useHistory } from 'react-router-dom'
import {
  GlobalErrorProps,
  GlobalErrors,
} from 'src/components/Forms/ErrorMessage'
import {
  StyledCol,
  StyledButton
} from './styles'

interface Props {
  campaign: any
  loading: boolean
  error?: GlobalErrorProps[]
  influencerAccounts: [any]
  refetchCampaign: Function
}

const Transaction = ({campaign, loading, error, influencerAccounts, refetchCampaign}: Props) => {
  const history = useHistory()
  const myRef: any = useRef<null | HTMLElement>(null)
  const user = useSelector(state => Auth.selectUser(state))
  const loggedin = !isEmpty(user)
  const moveToPostDetails = () => myRef.current.scrollIntoView()
  return(
    <DashboardLayout title="Influencers">
      <Box p={[10, 40]}>
        <Loading spinning={loading}>
          {loggedin &&
            <Typography
              color="grey7"
              cursor="pointer"
              onClick={() => {history.push('/')}}>
              ← Back to dashboard
            </Typography>
          }
          <Row>
            <GlobalErrors errors={error} />
            <StyledCol md={17} sm={24} xs={24}>
              <Header campaign={campaign} />
              <DateSection campaign={campaign} />
              <Divider style={{color: '#EBEBEB'}} />
              <Section head="Objective:" description={campaign.objective} />
              <StyledButton><Typography color="black" mb={0} cursor="pointer">App Installs</Typography></StyledButton>
              <Section head="A little about the brand:" description={campaign.aboutBrand} />
              <Section head="Style of content:" description={campaign.brief} />
              <Divider><Typography fontSize={18} color="black1" mb={0} ref={myRef}>
                Post Details
              </Typography></Divider>
              <Tags tags={campaign?.tags || []} />
              <DosDonts head="should" points={campaign.briefDo} />
              <DosDonts head="should not" points={campaign.briefDont} />
              <Examples campaign={campaign} />
            </StyledCol>
            <Col md={1} sm={0} xs={0}></Col>
            <StyledCol md={6} sm={24} xs={24}>
              <ApplySection
                campaign={campaign}
                influencerAccounts={influencerAccounts}
                refetchCampaign={refetchCampaign}
                moveTo={moveToPostDetails}
              />
            </StyledCol>
          </Row>
        </Loading>
      </Box>
    </DashboardLayout>
  )
}

export { Transaction, TransactionContainer }
