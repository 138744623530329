// @ts-nocheck
import styled from "styled-components";

export const FeedbackItemWrapper = styled.div`
  align-items: center;
  padding: 16px;
`;

export const SerialNumber = styled("p")`
  flex: 1;
  margin: 0;
  letter-spacing: 0.4px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px !important;
`;

export const ListItem = styled("p")`
  flex: 8;
  margin-bottom: 10px;
  margin-right: 0px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0px !important;
`;

export const DraftTime = styled("p")`
  &&& {
    flex: 16;
    text-align: justify;
    color: #40a9ff;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 15px;
    margin-left: 38px;
  }
`;
