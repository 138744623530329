// @ts-nocheck
import React, { useEffect } from "react";
import { HubLinkAccessForm } from ".";
import { object } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetInfluencerHubLinkStatusQuery,
  useUpdateInfluencerHubLinkMutation,
} from "src/generated/ApolloComponents";

type Props = {
  claimCashoutId: string;
  accountId: number;
};

interface HubLinkAccessFormValues {
  initialContact: boolean;
  initialContactYouTube: boolean;
  contract: boolean;
  inviteFeedback: boolean;
  inviteFeedbackYouTube: boolean;
  paymentDetail: boolean;
  postDetail: boolean;
  postDetailYouTube: boolean;
  claimCashoutId: string;
}

export const HubLinkAccessFormContainer: React.FC<Props> = ({
  claimCashoutId,
  accountId,
}) => {
  const { data, loading, error, refetch } = useGetInfluencerHubLinkStatusQuery({
    variables: {
      claimCashoutId: claimCashoutId,
    },
  });

  const hubLinkData = data?.getInfluencerHubLinkStatus;

  useEffect(() => {
    refetch({
      claimCashoutId: claimCashoutId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimCashoutId]);

  const [
    updateInfluencerHubLinkMutation,
    updateHubLink,
  ] = useUpdateInfluencerHubLinkMutation();

  const HubLinkAccessFormSchema = object().shape({});

  let initialValues = {
    initialContact: hubLinkData?.initialContact?.checked || false,
    initialContactYouTube: hubLinkData?.initialContactYouTube?.checked || false,
    contract: hubLinkData?.contract?.checked || false,
    inviteFeedback: hubLinkData?.inviteFeedback?.checked || false,
    inviteFeedbackYouTube: hubLinkData?.inviteFeedbackYouTube?.checked || false,
    paymentDetail: hubLinkData?.paymentDetail?.checked || false,
    postDetail: hubLinkData?.postDetail?.checked || false,
    postDetailYouTube: hubLinkData?.postDetailYouTube?.checked || false,
    accountId: accountId,
  };

  const onSubmit = async (
    values: HubLinkAccessFormValues,
    actions: FormikActions<HubLinkAccessFormValues>
  ) => {
    try {
      let formData: any = {
        claimCashoutId: claimCashoutId,
        initialContact: values?.initialContact,
        initialContactYouTube: values?.initialContactYouTube,
        contract: values?.contract,
        inviteFeedback: values?.inviteFeedback,
        inviteFeedbackYouTube: values?.inviteFeedbackYouTube,
        paymentDetail: values?.paymentDetail,
        postDetail: values?.postDetail,
        postDetailYouTube: values?.postDetailYouTube,
      };

      const res = await updateInfluencerHubLinkMutation({
        variables: formData,
      });

      if (res?.data?.updateInfluencerHubLink) {
        refetch();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(
    error || updateHubLink?.error
  );

  return (
    <HubLinkAccessForm
      initialValues={initialValues}
      hubLinkAccessFormSchema={HubLinkAccessFormSchema}
      isSubmitting={loading || updateHubLink?.loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      claimCashoutId={claimCashoutId}
      hubLinkData={hubLinkData}
    />
  );
};
