// @ts-nocheck
import React from "react";
import { DatePicker } from "antd";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Field } from "src/components/Forms/Field";
import { FilterProps } from "../../PaymentQueueList";
import moment from "moment";

const PaymentDate = styled(DatePicker)`
  &&& {
    .ant-calendar-picker-icon {
      margin-top: -12px !important;
    }
  }
`;
interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const Date: React.FC<Props> = ({ handleChange, filters }) => {
  return (
    <Field label="Date:">
      <Box display="flex">
        <PaymentDate
          format="YYYY-MM-DD"
          onChange={(date: any) => {
            if (date) {
              handleChange("dateFrom", moment(date).format("YYYY-MM-DD"));
            } else {
              handleChange("dateFrom", "");
            }
          }}
          value={filters.dateFrom ? (moment(filters.dateFrom) as any) : null}
        />
        <Typography size={14} color="black" padding="8px 10px 0px 10px">
          To
        </Typography>
        <PaymentDate
          format="YYYY-MM-DD"
          onChange={(date: any) => {
            if (date) {
              handleChange("dateTo", moment(date).format("YYYY-MM-DD"));
            } else {
              handleChange("dateTo", "");
            }
          }}
          value={filters.dateTo ? (moment(filters.dateTo) as any) : null}
        />
      </Box>
    </Field>
  );
};

export default Date;
