// @ts-nocheck
import React, { Component } from "react";
import { Modal, Button, message } from "antd";
import { withFormik } from "formik";
import parseDotNotation from "src/utils/parseDotNotation";

type Props = {
  show?: boolean;
  handleHide?: any;
  handleSubmit?: any;
  isSubmitting?: boolean;
  isValid?: boolean;
  afterSave?: any;
};

class ModalForm extends Component<Props> {
  render() {
    const {
      show,
      handleHide,
      handleSubmit,
      isSubmitting,
      isValid,
    } = this.props;

    return (
      <Modal
        visible={show}
        onCancel={handleHide as any}
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
        {...this.props}
      />
    );
  }
}

export default withFormik({
  validateOnBlur: false,
  enableReinitialize: true,

  mapPropsToValues: ({ initialValues, validate }: any) => ({
    ...validate.cast(),
    ...initialValues,
  }),
  validationSchema: ({ validate }) => validate,

  handleSubmit: (values, formik: any) => {
    const { props, setSubmitting, setErrors } = formik;

    props
      .onSave(values, formik)
      .then(res => {
        props.handleHide();
        message.success("Saved!");
        setSubmitting(false);
        props.afterSave && props.afterSave();
      })
      .catch(res => {
        setSubmitting(false);
        message.error("Failed!");
        if (res) {
          setErrors(parseDotNotation(res.response.data.meta.errors));
        }
      });
  },
})(ModalForm);
