// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikCurrencyInput } from "src/components/Forms/CurrencyInput";
import { FormikSelect } from "src/components/Forms/Select";
import { currency } from "src/constants/payments";
import { Box } from "src/components/Core/Box";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
}

const Amount: React.FC<Props> = ({ formProps }) => {
  return (
    <Field label="Amount:" mb={26}>
      <Box display="inline-flex" width="100%">
        <FormikSelect
          name="currency"
          data={currency}
          local
          style={{ width: 80 }}
          background="background"
          borderRadius="3px 0px 0px 3px"
          borderColor="grey5"
          size="large"
        />
        <FormikCurrencyInput
          name="amount"
          size="large"
          background="background"
          borderColor="grey5"
          style={{ borderRadius: "0px 3px 3px 0px" }}
          shouldShowError={false}
        />
      </Box>
      <FieldErrorMessage
        errors={formProps?.errors}
        touched={formProps?.touched}
        mt={20}
        name="amount"
      />
    </Field>
  );
};

export default Amount;
