// @ts-nocheck
import React from "react";
import { Descriptions } from "antd";
import moment from "moment";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import numeral from "numeral";
import formatCurrency from "src/utils/formatCurrency";
import { currency as allCuurency } from "src/constants/payments";

interface Props {
  invitationData: any;
}

const DisplayDetails: React.FC<Props> = ({ invitationData }) => {
  let currencySign = find(
    allCuurency,
    matchesProperty("value", invitationData?.payoutTypeCurrency)
  );

  return (
    <Descriptions size="small" bordered column={1}>
      <Descriptions.Item label="Account">
        {invitationData?.account?.username}
      </Descriptions.Item>
      <Descriptions.Item label="Estimated Views">
        {numeral(invitationData?.estimatedViews).format("0,0")}
      </Descriptions.Item>
      <Descriptions.Item label="Payout Type">
        {invitationData?.payoutType}
      </Descriptions.Item>

      {invitationData?.payoutTypeCurrency && (
        <Descriptions.Item label="Original Payout">
          {currencySign?.label || ""}
          {invitationData?.payoutTypeOriginalPennies
            ? numeral(invitationData?.payoutTypeOriginalPennies / 100).format(
                "0,0[.]00"
              )
            : "--"}
        </Descriptions.Item>
      )}

      {invitationData?.payoutType === "cpm" && (
        <Descriptions.Item label="CPM">
          {invitationData?.cpm ? formatCurrency(invitationData?.cpm) : "£--"}
        </Descriptions.Item>
      )}

      {invitationData?.payoutType === "fixed" && (
        <Descriptions.Item label="Fixed Payout">
          {invitationData?.fixedPayoutPennies
            ? formatCurrency(invitationData?.fixedPayoutPennies)
            : "£--"}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Estimated Payout">
        {invitationData?.estimatedPayoutPennies
          ? formatCurrency(invitationData?.estimatedPayoutPennies)
          : "£--"}
      </Descriptions.Item>
      <Descriptions.Item label="Posted At">
        {invitationData?.updatedAt
          ? moment(invitationData?.updatedAt).format("Do MMM YYYY, h:mm a")
          : "--"}
      </Descriptions.Item>
      <Descriptions.Item label="Installs">
        {numeral(invitationData?.installs).format("0,0")}
      </Descriptions.Item>
      <Descriptions.Item label="Clicks">
        {numeral(invitationData?.clicks).format("0,0")}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DisplayDetails;
