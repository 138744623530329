import React, { Component, Fragment } from "react";
import FormField from "../FormField";
import { Input } from "formik-antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Row } from "antd";
import { Typography } from "src/components/Core/Typography"
import styled from "styled-components"
import Loading from "src/shared/Loading";

const StyledRow = styled(Row)`
  margin: 10px 0px 0px 5px;
`

export default class HubspotId extends Component {
  render() {
    const hubspot_url = `https://app.hubspot.com/contacts/5498639/deal/${this.props.dealId}`
    return (
      <Fragment>
        <FormField
          name={this.props.name || "hubspot_deal_id"}
          label="Hubspot deal id"
        >
          <Input
            data-cy="hubspot-deal-input"
            size="large"
            placeholder="Hubspot deal id"
          />
          <Loading spinning={this.props.loading}>
            {this.props.isValid ?
              <StyledRow>
                <CheckCircleTwoTone twoToneColor="#52C41A" style={{fontSize: 18}} />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Typography size={12} color="black">
                  Hubspot deal found: <a href={hubspot_url} target="_blank" rel="noopener noreferrer">{hubspot_url}</a>
                </Typography>
              </StyledRow>
              :
              <StyledRow>
                <CloseCircleTwoTone twoToneColor="#F5222D" style={{fontSize: 18}} />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Typography size={12} color="black">
                  Hubspot deal not found!
                </Typography>
              </StyledRow>
            }
          </Loading>
        </FormField>
      </Fragment>
    );
  }
}
