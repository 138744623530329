// @ts-nocheck
import React from "react"
import { BarChartOutlined, StopFilled } from '@ant-design/icons';
import { Row, Col, Avatar, Popover } from "antd";
import numeral from "numeral"
import styled from "styled-components"
import isEmpty from "lodash/isEmpty"
import { Box } from "src/components/Core/Box"
import UserNameContent from "./UserNameContent"
import { Draggable } from "react-beautiful-dnd"
import tiktok from "src/assets/images/tiktok.png"
import { Typography } from "src/components/Core/Typography"
import AccountStats from "src/components/Modules/Accounts/AccountsList/AccountsTable/AccountStats"
import AccountContent from "src/components/Modules/Accounts/AccountsList/AccountsTable/GridList/AccountContent"

const Image = styled("img")`
   {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const videoStyle = {
  width: "100%",
  height: "75px",
  objectFit: "cover",
};

interface Props {
  suggestions: Array<any>;
  isInnerAccountList: boolean;
  menu: Function
}

const GridView = ({ suggestions, isInnerAccountList, menu }: Props) => {
  const styleObj = (account: any) => ({ marginTop: "5px", marginBottom: "5px", paddingBottom: "8px", opacity: account?.isSuggested ? "0.5" : "1" })
  return (
    <Row gutter={8}>
      {(suggestions).map((suggestion, i) => {
        let prevContent = [
          ...(suggestion?.account?.previousBrandedContent || []),
          ...(suggestion?.account?.previousContent || []),
        ];
        let remainingLength = 4 - prevContent?.length;
        let newPrevContent = [
          ...prevContent,
          ...new Array(remainingLength < 0 ? 0 : remainingLength).fill({}),
        ];

        return (
          <Col xl={8} xxl={6} key={i} style={styleObj(suggestion?.account)}>
            <Draggable draggableId={String(suggestion?.id)} index={i}>
              {provided =>
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <Box border="1px solid #CCCCCC" borderRadius="2px" p="10px">
                    <Row gutter={4}>
                      {(newPrevContent || []).slice(0, 4).map((content, i) => {
                        return (
                          <Col span={12} key={i} style={{ paddingBottom: "4px" }}>
                                  {!isEmpty(content) ? (
                                    <Box
                                      borderRadius={4}
                                      height="75px"
                                      width="100%"
                                      border="1px solid #c4c4c4"
                                    >
                                      <Popover
                                        content={
                                          <AccountContent
                                            contentType={
                                              (content?.contentType || "")?.match("image.*")
                                                ? "image"
                                                : content?.contentType === "tiktok"
                                                ? "tiktok"
                                                : "video"
                                            }
                                            contentUrl={content?.contentUrl}
                                            likes={numeral(content?.likes).format("0a")}
                                            views={numeral(content?.views).format("0a")}
                                            accountName={`@${suggestion?.account?.username}`}
                                          />
                                        }
                                        destroyTooltipOnHide={true}
                                        placement="right"
                                        align={{
                                          overflow: { adjustX: true, adjustY: true },
                                        }}
                                      >
                                        {(content?.contentType || "")?.match("image.*") ? (
                                          <Image
                                            src={content?.contentUrl}
                                            alt="previousBranded"
                                          />
                                        ) : content?.contentType === "tiktok" ? (
                                          <Image src={tiktok} alt="tiktok" />
                                        ) : (
                                          <video
                                            style={videoStyle as React.CSSProperties}
                                            src={content?.contentUrl}
                                          />
                                        )}
                                      </Popover>
                                    </Box>
                                  ) : (
                                    <Box
                                      background="#c4c4c4"
                                      textAlign="center"
                                      height="75px"
                                      width="100%"
                                      borderRadius={4}
                                      p="14.5px"
                                      border="1px solid #c4c4c4"
                                    >
                                      <StopFilled style={{ color: "#595959" }} />
                                      <Typography
                                        mb={0}
                                        color="black2"
                                        style={{ fontSize: "9px" }}
                                      >
                                        Not available
                                      </Typography>
                                    </Box>
                                  )}
                          </Col>
                        );
                      })}
                    </Row>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={10}
                      alignItems="center"
                      >
                      <Box display="flex" alignItems="center">
                        <Avatar
                          shape="circle"
                          src={suggestion?.account?.profilePicUrl}
                          size="small"
                        />
                        <UserNameContent suggestion={suggestion} />
                      </Box>

                      <Popover
                        content={<AccountStats account={suggestion?.account} />}
                        title={`${suggestion?.account?.username}’s stats`}
                      >
                        <BarChartOutlined />
                      </Popover>
                    </Box>
                  </Box>
                </div>
              }
            </Draggable>
          </Col>
        );
      })}
    </Row>
  );
};

export default GridView;
