// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";
import { Posted } from ".";
import { useRouteMatch } from "react-router";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useGetCampaignAnalyticsPostsQuery } from "src/generated/ApolloComponents";

type Props = {
  activeKey: string;
  selectedAccount: any;
};

export const PostedContainer: React.FC<Props> = ({
  activeKey,
  selectedAccount,
}) => {
  const match: any = useRouteMatch();

  const params: any = match?.params;

  const campaignId = parseInt(params?.id);

  const [filters, setFilters] = useState({
    accountName: "",
  });

  const [accounts, setAccounts] = useState([] as any);

  const { data, loading, error, refetch } = useGetCampaignAnalyticsPostsQuery({
    variables: {
      campaignId: campaignId,
    },
    fetchPolicy: "no-cache",
  });

  let allAccounts = data?.getCampaignAnalyticsPosts || [];

  useEffect(() => {
    if (activeKey === "2") {
      setAccounts([]);
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);

  useEffect(() => {
    if (allAccounts.length > 0) {
      setAccounts(allAccounts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccounts, accounts]);

  const onFilterChange = async (filters, accounts, allAccounts) => {
    try {
      if (filters?.accountName !== "") {
        const filterAccounts = accounts.filter(c => {
          return (
            c?.username
              .toLowerCase()
              .indexOf(filters?.accountName.toLowerCase()) > -1
          );
        });

        setAccounts(filterAccounts);
      } else {
        if (allAccounts.length > 0) {
          setAccounts(allAccounts);
        }
      }
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onFilterChange, 1000)).current;

  useEffect(() => {
    let updatedFilters = { ...filters };

    debouncedFilterChange(updatedFilters, accounts, allAccounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <Posted
      accounts={accounts}
      globalErrors={globalErrors}
      loading={loading}
      onFilter={(key, value) => {
        filters[key] = value;
        setFilters({ ...filters });
      }}
      filters={filters}
      selectedAccount={allAccounts?.filter(acc => acc.id === selectedAccount?.id)[0]}
    />
  );
};
