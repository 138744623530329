import React from "react";
import { Tag } from "antd";
import { fk, Model, many } from "redux-orm";
import moment from "moment";
import numeral from "numeral";
import { payout_types } from "src/constants/invitations";
import formatCurrency from "src/utils/formatCurrency";

class Invitation extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Account, Campaign, Draft } = this.session;

    const parsedData = {
      ...data,
      account: Account.parse(data.account),
      drafts: data.drafts.map(d => Draft.parse(d)),
      campaign: Campaign.parse(data.campaign),
    };

    return this.upsert(parsedData);
  }

  orderedDrafts() {
    return this.drafts.orderBy("created_at", "desc");
  }

  formattedPayoutType() {
    return this.payout_type
      ? (payout_types.find(x => x.value === this.payout_type) || {}).label
      : null;
  }

  formattedUpdatedAt() {
    return this.updated_at
      ? "Updated " + moment(this.updated_at).fromNow()
      : null;
  }

  formattedEstimatedViews() {
    return numeral(this.estimated_views).format("0,0");
  }

  formattedClicks() {
    return numeral(this.clicks).format("0,0");
  }

  formattedCpc() {
    return this.cpc_pennies ? formatCurrency(this.cpc_pennies) : "£--";
  }

  formattedCpi() {
    return this.cpi_payout_pennies
      ? formatCurrency(this.cpi_payout_pennies)
      : "£--";
  }

  formattedCtr() {
    return this.ctr ? numeral(this.ctr).format("0,0.00") + "%" : null;
  }

  formattedItr() {
    return this.itr ? numeral(this.itr).format("0,0") + "%" : null;
  }

  formattedViews() {
    return numeral(this.views).format("0,0");
  }

  formattedInstalls() {
    return numeral(this.installs).format("0,0");
  }

  formattedCpm() {
    return this.cpm ? formatCurrency(this.cpm) : "£--";
  }

  formattedPayout() {
    return this.payout_pennies ? formatCurrency(this.payout_pennies) : "£--";
  }

  formattedPostedAt() {
    return this.uploaded_at
      ? moment(this.uploaded_at).format("Do MMM YYYY, h:mm a")
      : "--";
  }

  formattedPayoutTypePennies() {
    return this.payout_type_pennies
      ? formatCurrency(this.payout_type_pennies)
      : "£--";
  }

  formattedStage() {
    return this.stage === "approved" ? (
      <Tag color="green" style={{ margin: 0 }} key="stage">
        Approved
      </Tag>
    ) : this.stage === "under-review" ? (
      <Tag color="purple" style={{ margin: 0 }} key="stage">
        Brand Review
      </Tag>
    ) : this.stage === "admin-review" ? (
      <Tag color="geekblue" style={{ margin: 0 }} key="stage">
        Admin Review
      </Tag>
    ) : this.stage === "drafting" ? (
      <Tag color="magenta" style={{ margin: 0 }} key="stage">
        Drafting
      </Tag>
    ) : this.stage === "posted" ? (
      <Tag color="blue" style={{ margin: 0 }} key="stage">
        Posted
      </Tag>
    ) : this.stage === "redrafting" ? (
      <Tag color="cyan" style={{ margin: 0 }} key="stage">
        Redrafting
      </Tag>
    ) : this.stage === "awaiting" ? (
      <Tag color="blue" style={{ margin: 0 }} key="stage">
        Awaiting Media
      </Tag>
    ) : this.stage === "drafting-creative-concept" ? (
      <Tag color="magenta" style={{ margin: 0 }} key="stage">
        Drafting Creative Concept
      </Tag>
    ) : this.stage === "drafting-content" ? (
      <Tag color="magenta" style={{ margin: 0 }} key="stage">
        Drafting Content
      </Tag>
    ) : this.stage === "redrafting-creative-concept" ? (
      <Tag color="cyan" style={{ margin: 0 }} key="stage">
        Redrafting Creative Concept
      </Tag>
    ) : this.stage === "redrafting-content" ? (
      <Tag color="cyan" style={{ margin: 0 }} key="stage">
        Redrafting Content
      </Tag>
    ) : null;
  }


  getCompanyName() {
    return this.campaign &&
      this.campaign.company &&
      this.campaign.company.company_name
      ? this.campaign.company.company_name
      : "";
  }

  formattedComments() {
    return typeof this.metadata.comments != "undefined"
    ? numeral(this.metadata.comments).format("0a")
    : null;
  }

  formattedLikes() {
    return typeof this.metadata.likes != "undefined"
    ? numeral(this.metadata.likes).format("0a")
    : null;
  }

  formattedContentViews() {
    return typeof this.metadata.views != "undefined"
    ? numeral(this.metadata.views).format("0a")
    : null;
  }

  formattedContentPostedAt() {
    return typeof this.metadata.createTime != "undefined"
      ? "Posted " + moment(this.metadata.createTime).format("Do MMM YYYY, h:mm a")
      : "";
  }

}
Invitation.modelName = "Invitation";

// Declare your related fields.
Invitation.fields = {
  account: fk("Account"),
  campaign: fk("Campaign"),
  drafts: many("Draft"),
};

export default Invitation;
