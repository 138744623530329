// @ts-nocheck
import React from "react";
import { UpdatePaymentDetailForm } from ".";
import { object } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import {
  useUpdateInfleucnerPayoutDetailByIdMutation,
  useUpdateAgencyPayoutDetailByIdMutation
 } from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { usePaymentDetails } from "../PaymentDetailsProvider";
import { featureFlags } from "src/constants/featureFlags";

interface Props {
  setStep: (value: number) => void;
}

interface bankAccount {
  fullName: string;
  accountNumber: string;
  sortCode: string;
}

interface UpdatePaymentDetailFormValues {
  paypalEmail: string;
  bankAccount: bankAccount;
}

export const UpdatePaymentDetailFormContainer: React.FC<Props> = props => {
  const { actualPaymentDetails, selectedInfluencer, selectedAgency } = usePaymentDetails();
  const { isPaypalEnabled } = featureFlags;

  const [
    updateInfleucnerPayoutDetailByIdMutation,
    influencer,
  ] = useUpdateInfleucnerPayoutDetailByIdMutation();

  const [
    updateAgencyPayoutDetailById,
    agency
  ] = useUpdateAgencyPayoutDetailByIdMutation()

  const PaymentRequestSchema = object().shape({});

  let initialValues = {};

  const onSubmit = async (
    values: UpdatePaymentDetailFormValues,
    actions: FormikActions<UpdatePaymentDetailFormValues>
  ) => {
    try {
      let paymentDetail = {
        bankAccount: actualPaymentDetails?.bankAccount,
      };

      if(isPaypalEnabled) {
        paymentDetail["paypalEmail"] = actualPaymentDetails?.paypalEmail;
      }

      let res: any = {}
      // selectedagency is prioritized, as from manage invitations, influencer is always selected
      if(selectedAgency?.id){
        const formData = {
          paymentDetail: paymentDetail,
          agencyId: selectedAgency?.id
        };
        res = await updateAgencyPayoutDetailById({
          variables: formData
        })
      }
      else if(selectedInfluencer?.id) {
        const formData = {
          paymentDetail: paymentDetail,
          influencerId: selectedInfluencer?.id
        };
        res = await updateInfleucnerPayoutDetailByIdMutation({
          variables: formData,
        });
      }

      if (res.data?.updateInfleucnerPayoutDetailById?.id || res.data?.updateAgencyPayoutDetailById?.id) {
        props.setStep(3);
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(influencer?.error || agency?.error);

  return (
    <UpdatePaymentDetailForm
      initialValues={initialValues}
      PaymentRequestSchema={PaymentRequestSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      isSubmitting={influencer?.loading || agency?.loading}
      globalErrors={globalErrors}
      setStep={value => props.setStep(value)}
    />
  );
};
