import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { List, Typography, Divider, Empty } from "antd";
import { AccountPostList, AccountPostListModule } from "src/ducks/DataList";
import AccountPost from "src/shared/AccountPost";
import withModules from "src/utils/hoc/withModules";
const AccountPosts = AccountPostList("FindAccountModal");

const modules = [AccountPostListModule("FindAccountModal")()];

const { Text } = Typography;

const ContentTitle = styled(Text)`
  color: #333333 !important;
  font-size: 24px;
  font-weight: 500;
`;

const FixedList = styled(List)`
  &&& {
    .ant-row {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

class OrganicContent extends Component {
  state = {
    per_page: this.props?.grid
      ? this.props?.grid
      : window.innerWidth >= 1920
      ? 2
      : 1,
    page: 1,
  };

  componentDidMount() {
    this.loadPosts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account.id !== this.props.account.id) {
      this.props.clear();
      this.loadPosts();
    }
  }

  loadPosts = () => {
    const { loadAccountPosts, account } = this.props;
    if (account)
      loadAccountPosts({
        ...this.state,
        account: account.id,
      });
  };

  render() {
    const {
      accountPosts,
      accountPostsPagination: { current_page, total_entries, per_page },
      accountPostsLoading,
      shouldShowLabel = true,
    } = this.props;

    return accountPosts.length > 0 ? (
      <>
        {shouldShowLabel && (
          <>
            <ContentTitle>Previous content</ContentTitle>
            <Divider />
          </>
        )}

        <FixedList
          grid={{
            gutter: 16,
            column: this.props?.grid
              ? this.props?.grid
              : window.innerWidth >= 1920
              ? 2
              : 1,
          }}
          style={{ marginBottom: "15px" }}
          loading={accountPostsLoading}
          pagination={{
            onChange: page => {
              this.setState(
                {
                  page: page,
                },
                this.loadPosts
              );
            },
            current: current_page || null,
            total: total_entries || null,
            pageSize: per_page || null,
          }}
          dataSource={accountPosts}
          renderItem={item => (
            <List.Item>
              <AccountPost
                content_type={item.type_string}
                content_url={item.content_url}
                description={item.formattedDescription()}
                views={item.formattedViews()}
                likes={item.formattedLikes()}
                comments={item.formattedComments()}
                posted_at={item.formattedPostedAt()}
                web_video_url={item.formattedTiktokWebVideoUrl()}
              />
            </List.Item>
          )}
        />
      </>
    ) : (
      <Empty description="No data available!" />
    );
  }
}

const mapStateToProps = state => ({
  accountPosts: AccountPosts.selectMany(state),
  accountPostsPagination: AccountPosts.selectPagination(state),
  accountPostsLoading: AccountPosts.selectPending(state),
});

const mapDispatchToProps = {
  loadAccountPosts: AccountPosts.loadMany,
  clear: AccountPosts.clear,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(OrganicContent)
)(modules);
