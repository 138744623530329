import React, { Component } from "react";
import styled from "styled-components";
import { Form } from "formik-antd";
import { withFormik } from "formik";
import idx from "idx";
import { SubmitButton } from "src/shared/CampaignFields";

const FormWrapper = styled(Form)`
  max-width: 450px;
`;

export class BrandCampaignCreateForm extends Component {
  componentDidMount() {
    this.setServerErrors();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.serverErrors !== this.props.serverErrors)
      this.setServerErrors();
  }

  setServerErrors() {
    const { setErrors, serverErrors, setTouched } = this.props;

    if (serverErrors) {
      setTouched(serverErrors);
      setErrors(serverErrors);
    }
  }

  componentWillUnmount = () => {
    const { handleReset } = this.props;
    handleReset();
  };

  render() {
    const { handleSubmit, isSubmitting, step, values } = this.props;
    const { fields } = step;

    const renderFields = Object.keys(fields).map((key, index) => {
      return React.createElement(fields[key], {
        key: index,
        name: key,
        values,
      });
    });

    return (
      <FormWrapper onSubmit={handleSubmit}>
        {renderFields}

        <SubmitButton isSubmitting={isSubmitting} />
      </FormWrapper>
    );
  }
}

export default withFormik({
  validateOnBlur: false,
  enableReinitialize: true,
  mapPropsToValues: ({ campaign, step: { validate } }) => {
    let link_tracked = false;

    if (idx(campaign, x => x.ref.default_link)) {
      link_tracked = true;
    }

    return campaign
      ? {
          ...campaign.ref,
          link_tracked,
        }
      : validate.cast();
  },

  validationSchema: ({ step: { validate } }) => validate,

  handleSubmit: (values, { props, setSubmitting }) => {
    const { persistData, onSubmit } = props;

    let data;

    persistData(values)
      .then(res => {
        const { link_tracked, ...rest } = res.value.data;

        if (!values.link_tracked) {
          rest.default_link = null;
        }
        data = rest;

        setSubmitting(false);
        onSubmit(data);
      })
      .catch(err => {
        setSubmitting(false);
      });
  },

  displayName: "BrandCampaignCreateForm",
})(BrandCampaignCreateForm);
