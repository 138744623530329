import React, { Component } from "react";
import { Video, Image } from "cloudinary-react";

export default class CreativeDisplay extends Component {
  render() {
    const { creative, style } = this.props;

    return creative && creative.video ? (
      <>
        {creative.video.resource_type || creative.video.resourceType === "video" ? (
          <Video
            style={{ maxWidth: 240, display: "block", ...style }}
            publicId={creative.video.id}
            cloudName="dp05nvlmb"
            controls
          />
        ) : (
          <Image
            style={{ maxWidth: 240, display: "block", ...style }}
            publicId={creative.video.id}
            cloudName="dp05nvlmb"
          />
        )}
      </>
    ) : creative?.preview_url ? (
      <video style={{ maxWidth: 240, display: "block", ...style }} controls>
        <source src={creative.preview_url} />
      </video>
    ) : null;
  }
}
