import React, { Component, Fragment } from "react"
import { connect } from "formik"
import FormField from "../FormField"
import { Radio } from "formik-antd"
import VerticalRadio from "./VerticalRadio"
import AdFormatModal from "./AdFormatModal"
import { ad_formats } from "src/constants/campaign"

class AdFormat extends Component {
  state = {
    modalVisible: false
  }

  showModal = () => {
    this.setState({
      modalVisible: true
    })
  }

  handleClose = (e) => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    const AdFormatLabel = (
      <a onClick={this.showModal} href>
        Click here if you don’t know which format to choose.
      </a>
    )

    return (
      <Fragment>
        <FormField name={this.props.name || "ad_format"} label="Ad Format" help={AdFormatLabel}>
          <Radio.Group>
            {ad_formats.map((value, i) => (
              <VerticalRadio data-cy={`ad-radio-${i}`} key={i} value={value.value}>
                {value.label}
              </VerticalRadio>
            ))}
          </Radio.Group>
        </FormField>

        <AdFormatModal visible={this.state.modalVisible} handleClose={this.handleClose} />
      </Fragment>
    )
  }
}

export default connect(AdFormat)
