// @ts-nocheck
import React, { useState } from "react";
import Form from "src/components/Forms/Form";
import { Row, Col } from "antd";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { InfluencerDataFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  LocationBreakdown,
  AgeBreakdown,
  GenderBreakdown,
} from "src/components/Modules/BreakdownFields";
import { ScreenshotsContainer } from "./Screenshots";
import { AccountTagsContainer } from "./AccountTags";

interface InfluencerDataFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  influencerDataSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
}

export const InfluencerDataFormWrapper = styled.div`
  width: 100%;
`;

const InfluencerDataForm: React.FC<InfluencerDataFormProps> = ({
  initialValues,
  isSubmitting,
  influencerDataSchema,
  onSubmit,
  globalErrors,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  return (
    <InfluencerDataFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Box p={24}>
          <Form
            initialValues={initialValues}
            schema={influencerDataSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {/* @ts-ignore */}
            {(formProps: any) => {
              return (
                <>
                  <Row gutter={12}>
                    <Col span={8}>
                      <ScreenshotsContainer
                        name="screenshots"
                        isUploading={isUploading}
                        setIsUploading={loading => setIsUploading(loading)}
                        setFieldValue={value =>
                          formProps?.setFieldValue("screenshots", value)
                        }
                        setFieldTouched={() =>
                          formProps?.setFieldTouched("screenshots", true)
                        }
                        values={formProps?.values}
                      />
                    </Col>
                    <Col span={16}>
                      <Typography size={14} color="black">
                        Screenshots supplied by the influencer are displayed on
                        the left. Use these screenshots to enter the details
                        below
                      </Typography>
                      <Row gutter={12}>
                        {formProps?.values?.platformType !== "TikTok" && (
                          <Col span={12}>
                            <AgeBreakdown formProps={formProps} />
                          </Col>
                        )}
                        <Col span={12}>
                          <GenderBreakdown formProps={formProps} />
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <LocationBreakdown formProps={formProps} />
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <AccountTagsContainer />
                        </Col>
                      </Row>

                      <Box display="flex" mt={20} justifyContent="flex-end">
                        <Button
                          htmlType="submit"
                          type="action"
                          loading={isSubmitting}
                          disabled={isSubmitting || isUploading}
                          buttonSize="middle"
                          data-cy="submit-button"
                          style={{ borderRadius: 4 }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form>
        </Box>
      </Loading>
    </InfluencerDataFormWrapper>
  );
};

export { InfluencerDataForm, InfluencerDataFormContainer };
