// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { Typography } from "src/components/Core/Typography";
import { Row, Col } from "antd";

interface Props {
  errors: any;
}

const PostLength: React.FC<Props> = ({ errors }) => {
  return (
    <Field label="Post length" mb={26}>
      <Row gutter={16}>
        <Col span={12}>
          <Typography size={14} color="grey7">
            Mins
          </Typography>
          <FormikInputNumber
            parser={value => {
              let parsed = value.replace(/\[0-9]/g, "");
              if (parsed) {
                return parsed.match(/\d+/g) ? parseInt(parsed) : "";
              } else {
                return null;
              }
            }}
            name="durationMinutes"
            size="middle"
            borderRadius={3}
            borderColor="grey8"
            placeholder="Mins"
            shouldShowError={false}
          />
        </Col>
        <Col span={12}>
          <Typography size={14} color="grey7">
            Secs
          </Typography>
          <FormikInputNumber
            parser={value => {
              let parsed = value.replace(/\[0-9]/g, "");
              if (parsed) {
                return parsed.match(/\d+/g) ? parseInt(parsed) : "";
              } else {
                return null;
              }
            }}
            name="durationSeconds"
            size="middle"
            borderRadius={3}
            borderColor="grey8"
            placeholder="Secs"
            shouldShowError={false}
          />
        </Col>
      </Row>
      {(errors?.durationMinutes || errors?.durationSeconds) && (
        <Typography size={14} color="red" mt="5px">
          Please enter post length
        </Typography>
      )}
    </Field>
  );
};

export default PostLength;
