// @ts-nocheck
import React from "react";
import { Select } from "antd";
import { useGetAllCampaignsQuery } from "src/generated/ApolloComponents";
import { Field } from "src/components/Forms/Field";
import { FilterProps } from "../../PaymentQueueList";

const { Option } = Select;

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const Campaign: React.FC<Props> = ({ handleChange, filters }) => {
  const campaigns = useGetAllCampaignsQuery();

  const handleSearch = async value => {
    await campaigns.refetch({
      campaignTitle: value,
    });
  };

  const options = (campaigns?.data?.getAllCampaigns || []).map(d => (
    <Option key={d.id}>{d.title}</Option>
  ));

  return (
    <Field label="Campaign:">
      <Select
        showSearch
        placeholder="Search Campaign"
        style={{ width: "200px" }}
        filterOption={false}
        onSearch={v => handleSearch(v)}
        onChange={v => handleChange("campaignId", parseInt(v))}
        value={filters?.campaignId ? String(filters?.campaignId) : ""}
      >
        {options}
      </Select>
    </Field>
  );
};
export default Campaign;
