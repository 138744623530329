// @ts-nocheck
import React, { useState } from "react";
import { CloseOutlined, InfoCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Spin, Modal, Alert } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { TextInput } from "src/components/Forms/TextInput";
import { Field } from "src/components/Forms/Field";
import { Button } from "src/components/Core/Button";
import { Icon } from '@ant-design/compatible'

const TestModal = styled(Modal)`
  &&& {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.213287);
    border-radius: 6px;
  }
`;

const CopyIcon = styled(Icon)`
  &&& {
    color: ${props => props.theme.colors.black} !important;
    font-size: 16px;
    cursor: pointer;
  }
`;

const InfoIcon = styled(Icon)`
  &&& {
    color: ${props => props.theme.colors.blue} !important;
    margin-left: 10px;
    line-height: 48px;
    font-size: 16px;
  }
`;

interface Props {
  showTestLinkModal: boolean;
  closeTestLinkModal: () => void;
  loading: boolean;
  data: any;
}

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, marginTop: "10px" }} spin />
);

const TestLinkModal = ({
  closeTestLinkModal,
  showTestLinkModal,
  loading,
  data,
}: Props) => {
  const [linkCopyButtonAction, setLinkCopyButttonAction] = useState(false);
  return (
    <TestModal
      style={{
        maxWidth: 600,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showTestLinkModal}
      onCancel={closeTestLinkModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <Box display="inline-flex">
        <InfoIcon type="info-circle" />
        <Typography
          size={16}
          color="black4"
          ml={10}
          lineHeight="43px"
          weight="semi-bold"
        >
          Please follow these steps to test your link
        </Typography>
      </Box>

      <Box padding="0px 35px">
        <Field label="Please enter this link in your mobile device">
          <TextInput
            size="middle"
            value={data?.snaplink}
            readOnly={true}
            borderRadius={4}
            placeholder="http://Trackinglink.com"
            suffix={
              data?.snaplink && (
                <CopyToClipboard
                  text={data?.snaplink}
                  onCopy={() => {
                    linkCopyButtonAction
                      ? setLinkCopyButttonAction(false)
                      : setLinkCopyButttonAction(true);
                    setTimeout(() => setLinkCopyButttonAction(false), 1000);
                  }}
                >
                  <CopyIcon type="copy" />
                </CopyToClipboard>
              )
            }
          />
        </Field>
        <Typography size={14} color="black2" position="absolute">
          {linkCopyButtonAction ? "Copied!" : ""}
        </Typography>

        <Typography size={14} color="black2" mt={20}>
          Ensure you are dirceted to correct page/app, and install. If tracking
          is working you will see data appear below:
        </Typography>
        {loading && (
          <Box textAlign="center">
            <Box
              display="inline-flex"
              padding="5px 19px"
              background="#ffffff"
              boxShadow=" 0px 4px 12px rgba(0, 0, 0, 0.15)"
              borderRadius="2px"
              margin="40px 0px"
            >
              <Spin indicator={antIcon} />
              <Typography size={14} color="black2" ml={10} mt="7px">
                Searching for data
              </Typography>
            </Box>
          </Box>
        )}

        {!loading && data && (
          <>
            <Alert
              message="Live Data"
              type="info"
              showIcon
              icon={
                <InfoCircleFilled
                  style={{
                    color: "#FF3030",
                    fontSize: "10px",
                    lineHeight: "16px",
                  }} />
              }
            />

            <Typography size={14} color="black2" mt={20}>
              Number of clicks: {data?.clicks}
            </Typography>

            <Typography size={14} color="black2">
              Number of installs: {data?.installs}
            </Typography>
          </>
        )}

        <Typography size={14} color="black2" mt={20}>
          Having trouble? Troubleshoot with your relevant partner here:
        </Typography>

        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
          <li>
            <a
              href="https://support.appsflyer.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              AppsFlyer
            </a>
          </li>
          <li>
            <a
              href=" https://help.branch.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Branch.io
            </a>
          </li>
          <li>
            <a
              href="https://docs.tenjin.com/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tenjin
            </a>
          </li>
          <li>
            <a
              href="https://support.kochava.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kochava
            </a>
          </li>
          <li>
            <a
              href="https://www.singular.net/report-and-guides/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Singular
            </a>
          </li>
        </ul>

        <Box alignItems="right" textAlign="right" display="inherit" mt={100}>
          <Button
            type="action"
            buttonSize="middle"
            data-cy="submit-button"
            onClick={() => closeTestLinkModal()}
          >
            Done
          </Button>
        </Box>
      </Box>
    </TestModal>
  );
};

export default TestLinkModal;
