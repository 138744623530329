import axiosInstance from "../axiosInstance";

const GET_INFLUENCERS = `/influencers`;
const CREATE_INFLUENCER = `/influencers`;
const getInfluencer = id => `/influencers/${id}`;

export const index = data => axiosInstance.get(GET_INFLUENCERS, data);

export const create = data => axiosInstance.post(CREATE_INFLUENCER, data);

export const get = (id, data) => axiosInstance.get(getInfluencer(id), data);

export const update = (id, data) =>
  axiosInstance.patch(getInfluencer(id), data);

export const customMethod = (id, request, method, data) =>
  axiosInstance[request](getInfluencer(id + "/" + method), data);
