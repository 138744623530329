// @ts-nocheck
import React, { useState } from "react";
import { Card } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { Table } from "src/components/Core/TableManager";
import getColumns from "./Columns";
import withWindowResize from "src/utils/hoc/withWindowResize";

interface Props {
  data: any;
  onFilter: (key: string, value: any) => void;
  height?: number; // from withWindowResize HOC
  filters: any;
  title: string;
  subTitle: string;
  type: string;
  onTrack: (value: any) => void;
}

const MainDataTable: React.FC<Props> = ({
  data,
  height,
  title,
  subTitle,
  type,
  onTrack,
}) => {
  const [filters, setFilters] = useState({ order: {} as any });

  data = (data || []).map((item: any, index) => {
    const newItem = { ...item };
    newItem.key = index + 1;
    return newItem;
  });

  return (
    <Card>
      <Typography color="black" weight="semi-bold" size={20} mb={0}>
        {title}
      </Typography>
      <Typography color="grey7" size={12}>
        {subTitle}
      </Typography>

      <Box background="white" mt={10} mb={10} borderRadius="4px">
        <Table
          getColumns={getColumns}
          extraGetColumnsArgs={{ type, onTrack }}
          rowKey="accountName"
          dataSource={data || []}
          data-cy="table-DataTable"
          rowHover={true}
          scroll={{ y: height, x: "max-content" }}
          pagination={false}
          filters={filters}
          onFilter={(key, value) => {
            const newFilters = { ...filters };

            newFilters[key] = value;

            if (newFilters?.order === null) {
              delete newFilters?.order;
            }

            setFilters({ ...newFilters });
          }}
        />
      </Box>
    </Card>
  );
};

const DataTable = withWindowResize({ extraHeight: 350 })(MainDataTable);

export default DataTable;
