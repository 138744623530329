// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Row, Col, Card, Statistic } from "antd";
import numeral from "numeral";
const Wrapper = styled(Row)`
  margin-bottom: 10px;
`;

interface Props {
  totalSpent: number;
  totalRecords: number;
  totalViews: number;
}

const Stats = ({ totalSpent, totalViews, totalRecords }: Props) => {
  return (
    <Wrapper gutter={16} justify="center" align="top">
      <Col span={5}>
        <Card>
          <Statistic
            title="No. Campaigns"
            value={totalRecords ? numeral(totalRecords).format("0,0") : 0}
          />
        </Card>
      </Col>

      <Col span={6}>
        <Card>
          <Statistic
            title="Total spent"
            value={
              totalSpent ? numeral(totalSpent / 100).format("0,0[.]00") : 0
            }
          />
        </Card>
      </Col>

      <Col span={5}>
        <Card>
          <Statistic
            title="Total views"
            value={totalViews ? numeral(totalViews).format("0,0") : 0}
          />
        </Card>
      </Col>
    </Wrapper>
  );
};

export default Stats;
