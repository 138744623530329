// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { InfluencerDataFormContainer } from "./InfluencerDataForm";

interface Props {
  invitation: any;
  fetchInvitations: Function;
}

const InfluencerData: React.FC<Props> = ({ invitation }) => {
  return (
    <Box mb={30}>
      <InfluencerDataFormContainer invitation={invitation} />
    </Box>
  );
};

export default InfluencerData;
