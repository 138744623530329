// @ts-nocheck
import isObject from 'lodash/isObject'
import {
  AudienceBreakdownComparisonType,
  FilteringAccountSearchType,
} from "src/generated/ApolloComponents";

export function getInitialValues(filters) {
  let initialValues = {
    searchInput: filters?.searchInput || "",
    searchType: filters?.searchType || FilteringAccountSearchType.AccountName,
    adminListId: filters?.adminListId,
    adminListIdForNotIncluded: filters?.adminListIdForNotIncluded || "",
    completedCampaigns: {
      months: filters?.completedCampaigns?.months || "",
      comparison:
        filters?.completedCampaigns?.comparison ||
        AudienceBreakdownComparisonType.GreaterEqualThan,
      value: filters?.completedCampaigns?.value || 0,
    },
    followers: {
      comparison:
        filters?.followers?.comparison || undefined,
      value: filters?.followers?.value || 0,
    },

    accountType: filters?.accountType || [],
    influencerAge: filters?.influencerAge || [],
    influencerGender: filters?.influencerGender || [],
    influencerLocation: filters?.influencerLocation || [],
    status: filters?.status || [],
    tags: filters?.tags || [],
    customTags: filters?.customTags || [],
    rating: {
      creationTime: filters?.rating?.creationTime || null,
      responseTime: filters?.rating?.responseTime || null,
      contentQuality: filters?.rating?.contentQuality || null,
      professionalism: filters?.rating?.professionalism || null,
    },
    order: filters?.order || [],
  };

  if ((filters?.audienceAge || []).length > 0) {
    const ageAudience = (filters?.audienceAge || []).map(item => {
      let container = { ...item };

      if (item?.value) {
        container["value"] = Number(item?.value);
      }
      return container;
    });
    initialValues["audienceAge"] = ageAudience;
  } else {
    initialValues["audienceAge"] = [
      {
        key: filters?.audienceAge?.key || "",
        comparison:
          filters?.audienceAge?.comparison ||
          AudienceBreakdownComparisonType.GreaterEqualThan,
        value: filters?.audienceAge?.value || 0,
      },
    ];
  }

  if ((filters?.audienceGender || []).length > 0) {
    const genderAudience = (filters?.audienceGender || []).map(item => {
      let container = { ...item };

      if (item?.value) {
        container["value"] = Number(item?.value);
      }
      return container;
    });
    initialValues["audienceGender"] = genderAudience;
  } else {
    initialValues["audienceGender"] = [
      {
        key: filters?.audienceGender?.key || "",
        comparison:
          filters?.audienceGender?.comparison ||
          AudienceBreakdownComparisonType.GreaterEqualThan,
        value: filters?.audienceGender?.value || 0,
      },
    ];
  }

  if ((filters?.audienceLocation || []).length > 0) {
    const locationAudience = (filters?.audienceLocation || []).map(item => {
      let container = { ...item };

      if (item?.value) {
        container["value"] = Number(item?.value);
      }
      return container;
    });
    initialValues["audienceLocation"] = locationAudience;
  } else {
    initialValues["audienceLocation"] = [
      {
        key: filters?.audienceLocation?.key || "",
        comparison:
          filters?.audienceLocation?.comparison ||
          AudienceBreakdownComparisonType.GreaterEqualThan,
        value: filters?.audienceLocation?.value || 0,
      },
    ];
  }

  return initialValues;
}

export function getFormvalues(values) {
  let filters = {} as any;

  if (values?.searchInput && values?.searchType) {
    filters["searchInput"] = values?.searchInput;
  }

  if (values?.searchType && values?.searchInput) {
    filters["searchType"] = values?.searchType;
  }

  if (values?.adminListId && values?.adminListId) {
    filters["adminListId"] = values?.adminListId;
  }

  if (values?.adminListIdForNotIncluded && values?.adminListIdForNotIncluded) {
    filters["adminListIdForNotIncluded"] = values?.adminListIdForNotIncluded;
  }

  if ((values?.accountType || []).length > 0) {
    filters["accountType"] = values?.accountType;
  }

  if ((values?.influencerAge || []).length > 0) {
    filters["influencerAge"] = values?.influencerAge;
  }

  if ((values?.influencerGender || []).length > 0) {
    filters["influencerGender"] = values?.influencerGender;
  }

  if ((values?.influencerLocation || []).length > 0) {
    filters["influencerLocation"] = values?.influencerLocation;
  }

  if ((values?.status || []).length > 0) {
    filters["status"] = values?.status;
  }

  if ((values?.tags || []).length > 0) {
    filters["tags"] = values?.tags;
  }

  if ((values?.customTags || []).length > 0) {
    filters["customTags"] = values?.customTags;
  }

  if (values?.completedCampaigns && values?.completedCampaigns?.months) {
    const value = values?.completedCampaigns;

    let container = { ...value } as any;

    if (value?.value) {
      container["value"] = Number(value?.value) || 0;
    }

    filters["completedCampaigns"] = container;
  }

  if (values?.followers) {
    const value = values?.followers;

    let container = { ...value } as any;

    if (value?.value) {
      container["value"] = Number(value?.value) || 0;
    }

    filters["followers"] = container;
  }

  if ((values?.audienceAge || []).length > 0) {
    const ageAudience = (values?.audienceAge || [])
      .filter(v => v.key !== "")
      .map(item => {
        let container = { ...item };

        if (item?.value) {
          container["value"] = Number(item?.value);
        }
        return container;
      });
    filters["audienceAge"] = ageAudience;
  }

  if ((values?.audienceGender || []).length > 0) {
    const genderAudience = (values?.audienceGender || [])
      .filter(v => v.key !== "")
      .map(item => {
        let container = { ...item };

        if (item?.value) {
          container["value"] = Number(item?.value);
        }
        return container;
      });
    filters["audienceGender"] = genderAudience;
  }

  if ((values?.audienceLocation || []).length > 0) {
    const locationAudience = (values?.audienceLocation || [])
      .filter(v => v.key !== "")
      .map(item => {
        let container = { ...item };

        if (item?.value) {
          container["value"] = Number(item?.value);
        }
        return container;
      });
    filters["audienceLocation"] = locationAudience;
  }

  if (values?.rating && (isObject(values?.rating))) {
    let obj = {}
    Object.keys(values.rating).forEach(key => {
      if(values.rating[key]) {
        obj[key] = values.rating[key]
      }}
    )
    if(Object.keys(obj).length > 0){
      filters["rating"] = values?.rating;
    }
  }

  filters["page"] = 1;

  if (values?.order) {
    filters["order"] = values?.order;
  }

  return filters;
}

export function shouldShowClearAllFilter(filters) {
  let show = false;

  if (
    (filters?.searchInput && filters?.searchType) ||
    filters?.adminListId ||
    filters?.adminListIdForNotIncluded ||
    (filters?.accountType || []).length > 0 ||
    (filters?.influencerAge || []).length > 0 ||
    (filters?.influencerGender || []).length > 0 ||
    (filters?.influencerLocation || []).length > 0 ||
    (filters?.status || []).length > 0 ||
    (filters?.tags || []).length > 0 ||
    (filters?.customTags || []).length > 0 ||
    filters?.completedCampaigns ||
    filters?.followers ||
    (filters?.audienceAge || []).length > 0 ||
    (filters?.audienceGender || []).length > 0 ||
    (filters?.audienceLocation || []).length > 0 ||
    (filters?.rating &&
      (filters?.rating?.creationTime ||
        filters?.rating?.responseTime ||
        filters?.rating?.contentQuality ||
        filters?.rating?.professionalism))
  ) {
    show = true;
  }

  return show;
}

export function getFilterParams (params: any) {
  if(params?.followers) {
    if(!params.followers.comparison) {
      delete params.followers
    }
  }
  return params
}
