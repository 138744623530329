import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "src/shared/Table";
import { DeleteOutlined, DownOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, message, Menu, Dropdown, Popconfirm } from "antd";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import moment from "moment";
import { Input } from "formik-antd";
import { show } from "redux-modal";
import TableManager from "src/ducks/TableManager";
import { BrandList as $BrandList } from "src/ducks/DataList";
import CreateModal from "../CompanyShow/BrandModal";
import Auth from "src/ducks/Auth";
import withWindowResize from "src/utils/hoc/withWindowResize";
import { Typography } from "src/components/Core/Typography";
import { WorkerPersist } from "src/ducks/Persist";

const BrandTable = TableManager("CampaignCompanyBrandList");
const BrandList = $BrandList("CampaignCompanyBrandList");

const WorkerManage = WorkerPersist("CampaignShow")

const modules = [BrandTable.module(), BrandList.module(), WorkerManage.module()];

export class BrandsTable extends Component {
  loadBrands = filters => {
    const { campaign } = this.props;

    return this.props.loadBrands({
      company: campaign.company && campaign.company.id,
      ...filters,
    });
  };

  renderDropDown = (text, record) => {
    const { deleteBrand } = this.props
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Popconfirm
            placement="left"
            title="Are you sure you want to delete this?"
            onConfirm={(e) => {e.preventDefault(); deleteBrand(record.id)}}
            okText="Yes"
            cancelText="No"
          >
            <Typography color="red5" mb={0} cursor="pointer">
              <DeleteOutlined />
              &nbsp;
              Delete user
            </Typography>
          </Popconfirm>
        </Menu.Item>
        <Menu.Item key="2" onClick={() => this.loginOtherUser(record)}>
          <Typography color="blue5" mb={0}  cursor="pointer">
            <LockOutlined />
            &nbsp;
            Login as user
          </Typography>
        </Menu.Item>
        <Menu.Item key="3" onClick={() => this.sendWelcomeEmail(record)}>
          <Typography color="grey7" mb={0} cursor="pointer">
            <MailOutlined />
            &nbsp;
            Send welcome email
          </Typography>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu}>
        <Button>
          Actions <DownOutlined />
        </Button>
      </Dropdown>
    );
  }

  loginOtherUser = brand => {
    const { switchUser, history } = this.props;
    switchUser(brand.id)
      .then(() => history.push("/"))
      .catch(() => message.error("Failed to login user!"));
  };

  sendWelcomeEmail = brand => {
    const { sendBrandConfirmation } = this.props
    sendBrandConfirmation("post", "send_brand_confirmation", {brand_id: brand.id})
      .then(res => message.success('Welcome email sent!'))
      .catch(err => console.log(err))
  }

  render() {
    const {
      data,
      pagination,
      loading,
      style,
      currentFilters,
      show,
      campaign,
      height
    } = this.props;

    const columns = {
      name: {
        title: "Name",
        dataIndex: "name",
        key: "name",
        visible: true,
        width: 180,
      },
      email: {
        title: "Email",
        dataIndex: "email",
        key: "email",
        visible: true,
        width: 250,
      },
      confirmed_at: {
        title: "Confirmed At",
        dataIndex: "confirmed_at",
        key: "confirmed_at",
        visible: true,
        width: 150,
        render: confirmed_at =>
          confirmed_at ? moment(confirmed_at).format("Do MMM YYYY") : null,
      },
    };

    const filters = {
      name: {
        input: () => <Input size="large" placeholder="e.g. Adidas" />,
        label: "Name",
      },
    };

    return (
      <>
        <Table
          style={style}
          title="Company Users"
          columns={columns}
          loadData={this.loadBrands}
          name="CampaignCompanyBrandList"
          pagination={pagination}
          data={data}
          addedColumns={[
            {
              key: "actions",
              // width: 150,
              render: this.renderDropDown
            }
          ]}
          extra={[
            <Button
              type="primary"
              data-cy="campaign-company-user-create-button"
              key="create"
              onClick={() => show(`CreateBrandModal`)}
            >
              Create new User
            </Button>,
          ]}
          filterOptions={filters}
          loading={loading}
          size="middle"
          scroll={{ y: height, x: "max-content" }}
        />

        <CreateModal
          initialValues={{ company: campaign.company && campaign.company.id }}
          onCreate={() => this.loadBrands(currentFilters)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentFilters: BrandTable.selectFilters(state),
  data: BrandList.selectMany(state),
  pagination: BrandList.selectPagination(state),
  loading: BrandList.selectPending(state),
});

const mapDispatchToProps = {
  loadBrands: BrandList.loadMany,
  deleteBrand: BrandList.destroy,
  switchUser: Auth.switchUser,
  show,
  sendBrandConfirmation: WorkerManage.customMethod
};

const MainBrandsTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandsTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainBrandsTable);
