import React, { Component } from "react";
import FormField from "../FormField";
import { Radio } from "formik-antd";
import { choise } from "src/constants/campaign";

export default class NeedLink extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "link_tracked"}
        label="will you need to track a link for this campaign?"
      >
        <Radio.Group>
          {choise.map((value, i) => (
            <Radio data-cy={`need-link-radio-${i}`} key={i} value={value.value}>
              {value.label}
            </Radio>
          ))}
        </Radio.Group>
      </FormField>
    );
  }
}
