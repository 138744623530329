import { fk, Model, many } from "redux-orm";
import numeral from "numeral";
import moment from "moment";
import formatCountries from "../../utils/formatCountries";
import formatCurrency from "src/utils/formatCurrency";
import { locations } from "src/constants/account";
import idx from "idx";

class Account extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;
    const { Influencer, Tag } = this.session;

    const parsedData = {
      ...data,
      tags: data.tags.map(tag => Tag.parse(tag)),
      influencer: Influencer.parse(data.influencer),
    };

    return this.upsert(parsedData);
  }

  get ref() {
    const data = super.ref;

    return {
      ...data,
      tags: this.tags.toRefArray().map(x => x.id),
    };
  }

  formattedStatPerStory() {
    return numeral(this.views).format("0,0") + " views per " + this.type;
  }

  formattedViews() {
    return numeral(this.views).format("0,0");
  }

  formattedInviteCounts() {
    return numeral(this.invite_sent_count).format("0,0");
  }

  formattedPayout() {
    return formatCurrency(this.payout_pennies);
  }

  formattedCpm() {
    return formatCurrency(this.cpm);
  }

  formattedAvgHearts() {
    return idx(this.metadata, x => x.avg_hearts)
      ? numeral(idx(this.metadata, x => x.avg_hearts)).format("0,0")
      : "";
  }

  formattedAvgLikes() {
    return idx(this.metadata, x => x.avg_likes)
      ? numeral(idx(this.metadata, x => x.avg_likes)).format("0,0")
      : "";
  }

  formattedAvgCtr() {
    return this.metadata.avg_ctr
      ? numeral(this.metadata.avg_ctr).format("0,0.00") + "%"
      : null;
  }

  formattedAvgItr() {
    return this.metadata.avg_itr
      ? numeral(this.metadata.avg_itr).format("0,0.00") + "%"
      : null;
  }

  formattedEngagementRate() {
    return this.metadata.engagement_rate
      ? numeral(this.metadata.engagement_rate).format("0,0.00") + "%"
      : null;
  }

  formattedFollowers() {
    return this.metadata.followers
      ? numeral(this.metadata.followers).format("0,0")
      : "";
  }

  formattedAvgComments() {
    return numeral(this.metadata.avg_comments).format("0,0");
  }

  formattedAvgViews() {
    return numeral(this.metadata.avg_views).format("0,0");
  }

  tiktokProfileUrl() {
    return this.metadata.tiktok_profile_url
      ? this.metadata.tiktok_profile_url
      : `https://www.tiktok.com/@${this.username}`;
  }

  instagramProfileUrl() {
    return this.metadata.instagram_profile_url
      ? this.metadata.instagram_profile_url
      : `http://instagram.com/${this.username}`;
  }

  youtubeProfileUrl() {
    return this.metadata.youtube_profile_url
      ? this.metadata.youtube_profile_url
      : `https://www.youtube.com/${this.username}`;
  }

  formattedAges() {
    return this.influencer?.detail?.age && this.influencer?.detail?.age[0] ? this.influencer?.detail?.age[0] : '';
  }

  formattedAgeBreakdown() {
    if (
      typeof this.influencer != "undefined" &&
      this.influencer !== null &&
      this.influencer.detail !== null &&
      typeof this.influencer.detail != "undefined" &&
      typeof this.influencer.detail.age_skew_percentage != "undefined" &&
      this.influencer.detail.age_skew_percentage !== null &&
      this.influencer.detail.age_skew_percentage !== "" &&
      Object.keys(this.influencer.detail.age_skew_percentage).length !== 0
    ) {
      const agePercentageKeys = Object.keys(this.influencer.detail.age_skew_percentage);

      let agePercentage = agePercentageKeys
        .filter((key, index) => {
          return this.influencer.detail.age_skew_percentage[key] !== null;
        })
        .map((key, index) => {
          return this.influencer.detail.age_skew_percentage[key] !== null
            ? `Age ${key} : ${numeral(this.influencer.detail.age_skew_percentage[key]).format(
                "0,0.00"
              )}% `
            : "";
        });

      return agePercentage.length > 0 ? agePercentage.join(", ") : null;
    } else {
      return null;
    }
  }

  formattedLocations() {
    return this.influencer?.detail?.location && this.influencer?.detail?.location[0] ? formatCountries(this.influencer?.detail?.location[0]) : '';
  }

  formattedLocationsBreakdown() {
    const countryName = locations.reduce(function(result, item) {
      result[item.value] = item.label;
      return result;
    }, {});

    if (
      typeof this.influencer != "undefined" &&
      this.influencer !== null &&
      this.influencer.detail !== null &&
      typeof this.influencer.detail != "undefined" &&
      typeof this.influencer.detail.country_percentage != "undefined" &&
      this.influencer.detail.country_percentage !== null &&
      this.influencer.detail.country_percentage !== "" &&
      Object.keys(this.influencer.detail.country_percentage).length !== 0
    ) {
      const locationPercentageKeys = Object.keys(
        this.influencer.detail.country_percentage
      );

      let locationPercentage = (locationPercentageKeys.slice(0, 5)).map((key, index) => {
        return `${countryName[key]} : ${numeral(
          this.influencer.detail.country_percentage[key]
        ).format("0,0.00")}% `;
      });

      return locationPercentage.length > 0
        ? locationPercentage.join(", ")
        : null;
    } else {
      return null;
    }
  }

  formattedCreatedAt() {
    return this.created_at ? moment(this.created_at).format("Do MMM YYYY") : "";
  }

  formattedGender() {
    return this.influencer?.detail?.gender && this.influencer?.detail?.gender[0] ? this.influencer?.detail?.gender[0] : '';
  }

  formattedGenderBreakdown() {
    if (
      typeof this.influencer != "undefined" &&
      this.influencer !== null &&
      this.influencer.detail !== null &&
      typeof this.influencer.detail != "undefined" &&
      typeof this.influencer.detail.gender_skew_percentage != "undefined" &&
      this.influencer.detail.gender_skew_percentage !== null &&
      this.influencer.detail.gender_skew_percentage !== "" &&
      Object.keys(this.influencer.detail.gender_skew_percentage).length !== 0
    ) {
      const genderPercentageKeys = Object.keys(this.influencer.detail.gender_skew_percentage);
      let genderPercentage = genderPercentageKeys
        .filter((key, index) => {
          return this.influencer.detail.gender_skew_percentage[key] !== null;
        })
        .map((key, index) => {
          return this.influencer.detail.gender_skew_percentage[key] !== null
            ? `${key} : ${numeral(this.influencer.detail.gender_skew_percentage[key]).format(
                "0,0.00"
              )}% `
            : "";
        });
      return genderPercentage.length > 0 ? genderPercentage.join(", ") : null;
    } else {
      return null;
    }
  }

  formattedTags() {
    return this.tags
      .toRefArray()
      .map(x => x.name)
      .join(", ");
  }

  formattedLastPaidAmount() {
    return formatCurrency(this.last_paid_amount);
  }

  formattedLastCampaignDate() {
    return this.last_campaign_date ? moment(this.last_campaign_date).format("Do MMM YYYY") : "";
  }

  formattedAvgCpi() {
    return this.metadata.avg_cpi
      ? formatCurrency(this.metadata.avg_cpi)
      : null;
  }

  formattedAvgCpc() {
    return this.metadata.avg_cpc
      ? formatCurrency(this.metadata.avg_cpc)
      : null;
  }

}
Account.modelName = "Account";

// Declare your related fields.
Account.fields = {
  influencer: fk("Influencer"),
  tags: many("Tag"),
};

export default Account;
