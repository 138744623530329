// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { milestone_types } from "src/constants/account";

interface Props {}

const Platform: React.FC<Props> = () => {
  return (
    <Field label="Metric" mb={26} fontSize={14} labelBottom={0} color="black4">
      <FormikSelect
        name="metric"
        size="middle"
        placeholder="Select..."
        data={milestone_types}
        local
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Platform;
