// @ts-nocheck
import React, { Fragment, useState } from 'react'
import { Divider, Button, Modal } from 'antd'
import Steps from './Steps'
import Footer from './Footer'
import styled from 'styled-components'
import { ApplyContainer } from './ApplyForm'
import { Box } from 'src/components/Core/Box'
import { Typography } from 'src/components/Core/Typography'

const StyledButton = styled(Button)`
  width: 100%;
`

interface Props {
  campaign: any
  influencerAccounts: [any]
  refetchCampaign: Function
  moveTo: () => void
}

const ApplySection = ({ campaign, influencerAccounts, refetchCampaign, moveTo }: Props) => {
  const [isModalVisible, setVisible] = useState(false)
  return(
    <Fragment>
      {window.innerWidth > 767 ?
        <Fragment>
          <Box px={20} pt={50} pb={24}>
            <Steps
              createdAt={campaign.createdAt}
              deadline={campaign.publicDeadline}
              startDate={campaign.startDate}
            />
            <ApplyContainer
              influencerAccounts={influencerAccounts}
              campaign={campaign}
              refetchCampaign={refetchCampaign}
              moveTo={moveTo}
            />
            <Typography color='silver' fontSize={15} textAlign='center' mb={0}>{campaign?.admin?.email}</Typography>
          </Box>
          <Divider />
          <Box px={20} pb={24}>
            <Footer />
          </Box>
        </Fragment>
        :
        <Box px={20} pt={50} pb={24}>
          <StyledButton type="primary" onClick={() => {setVisible(true)}}>Apply</StyledButton>
          <Modal visible={isModalVisible} onCancel={() => {setVisible(false)}} footer={null}>
            <Typography color="black" size={24}>Application</Typography>
            <Steps
              createdAt={campaign.createdAt}
              deadline={campaign.publicDeadline}
              startDate={campaign.startDate}
              isMobile={true}
            />
            <ApplyContainer
              influencerAccounts={influencerAccounts}
              campaign={campaign}
              refetchCampaign={refetchCampaign}
              moveTo={moveTo}
              isMobile={true}
            />
            <Typography color='silver' fontSize={15} textAlign='center' mb={0}>{campaign?.admin?.email}</Typography>
            <Divider />
            <Footer />
          </Modal>
        </Box>
      }
    </Fragment>
  )
}

export default ApplySection;
