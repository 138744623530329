import React from "react";
import { Tag } from "antd";
import moment from "moment";
import { fk, attr, Model } from "redux-orm";

class Draft extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Invitation } = this.session;

    const parsedData = {
      ...data,
      invitation: Invitation.parse(data.invitation)
    };

    return this.upsert(parsedData);
  }

  formattedCreatedAt() {
    return this.created_at ? moment(this.created_at).format("Do MMM YYYY") : "";
  }

  formattedStatus() {
    return this.status === "approved" ? (
      <Tag color="green" key="status">
        Approved
      </Tag>
    ) : this.status === "submitted" ? (
      <Tag color="purple" key="status">
        Under Review
      </Tag>
    ) : this.status === "redraft" ? (
      <Tag color="magenta" key="status">
        Redraft Requested
      </Tag>
    ) : null;
  }
}
Draft.modelName = "Draft";

// Declare your related fields.
Draft.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  invitation: fk("Invitation")
};

export default Draft;
