// @ts-nocheck
import { Card } from "antd";
import styled from "styled-components";
import { Icon } from '@ant-design/compatible'

export const Wrapper = styled("div")`
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 16px;
  margin-right: 8px;
  margin-left: 8px;
`;

export const PreviewCard = styled(Card)`
  && {
    height: 123px;
    width: 81px;
    display: inline-block;
    padding: 0px !important;
    border: none !important;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);

    .ant-card-body {
      padding: 0px !important;
    }
    .ant-card-cover {
      overflow: hidden;
      border-radius: 9px;
      object-fit: cover;
    }

    .ant-card-cover:hover > div {
      opacity: 1;
    }
  }
`;

export const OverlayGradient = styled("div")`
  && {
    top: 0;
    position: absolute;
    z-index: 1;
    border-radius: 9px;
    background-color: rgba(29, 29, 29, 0.63);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export const DeleteIcon = styled(Icon)`
  && {
    position: absolute;
    left: 40%;
    top: 45%;
    white-space: nowrap;
    z-index: 10;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    font-size: 18px;
    width: 18px;
    color: #fff;
  }
`;
