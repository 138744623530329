import React, { Component } from "react"
import { connect } from "react-redux"
import { Form } from "formik-antd"
import { connectModal } from "redux-modal"
import { createSelector } from "reselect"
import { Modal, Button, message } from "antd"
import { withFormik } from "formik"
import { TrackingLink, IntegrationPartner, IosId, AndroidId, Instagram } from "src/shared/LinkFields"
import { LinkPersist } from "src/ducks/Persist"
import CampaignManage from "src/ducks/CampaignManage"
import FormError from "src/shared/FormField/FormError"
import { Link } from "src/ducks/Orm"
import Validate from "./Validate"
const LinkCreate = LinkPersist("CampaignLinkModal")

export class LinkModal extends Component {
  render() {
    const { values, show, handleHide, handleSubmit, isSubmitting, status, linkId } = this.props
    return (
      <Modal
        visible={show}
        onCancel={handleHide}
        title={linkId ? "Update Link" : "Create new Link"}
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {linkId ? "Update" : "Submit"}
          </Button>
        ]}
        {...this.props}
      >
        <Form data-cy="campaign-link-form">
          {status &&
            Object.values(status.meta.errors).map((error, i) => (
              <FormError key={i} style={{ margin: "0 0 10px 0" }} message={error} />
            ))}
          <TrackingLink />
          <Instagram />
          <IntegrationPartner />
          <IosId values={values} />
          <AndroidId values={values} />
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = (state, props) => ({
  campaign: CampaignManage.campaign.selectOne(state),
  link: createSelector(
    (state) => state.entities,
    () => props.linkId,
    Link.selectOne
  )(state, props)
})

const mapDispatchToProps = {
  createLink: LinkCreate.create,
  updateLink: LinkCreate.update
}

const EnhancedWithFormik = withFormik({
  validateOnBlur: false,
  enableReinitialize: true,

  mapPropsToValues: ({ link }) => (link ? link.ref : Validate.cast()),

  validationSchema: () => Validate,

  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus }) => {
    const { createLink, updateLink, campaign, handleHide, onCreate, linkId } = props

    linkId
      ? updateLink(linkId, values)
          .then((res) => {
            setSubmitting(false)
            handleHide()
            message.success("Campaign Link Updated Successfully")
          })
          .catch((err) => {
            setSubmitting(false)
            message.error("Failed to update Campaign Link")
            setStatus(err.response.data)
            setErrors(err.response.data.meta.errors)
          })
      : createLink({
          ...values,
          campaign: campaign.id
        })
          .then((res) => {
            setSubmitting(false)
            handleHide()
            message.success("Campaign Link Created Successfully")
            onCreate()
          })
          .catch((err) => {
            setSubmitting(false)
            setStatus(err.response.data)
            setErrors(err.response.data.meta.errors)
          })
  },

  displayName: "CampaignLinkModal"
})(LinkModal)

const EnhancedLink = connect(mapStateToProps, mapDispatchToProps)(EnhancedWithFormik)

const EnhancedLinkUpdateModal = connectModal({
  name: "CampaignLinkModal",
  destroyOnHide: false
})(EnhancedLink)

export default EnhancedLinkUpdateModal
