import React, { Component } from "react"
import FormField from "../FormField"
import { Switch } from "formik-antd"

export default class ManageTag extends Component {
  render() {
    return (
      <FormField name={this.props.name || "detail.managed"} label="Managed influencer?">
        <Switch data-cy="influencer-managed" />
      </FormField>
    )
  }
}
