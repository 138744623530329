import React, { Component } from "react";
import FormField from "../FormField";
import LargeTextArea from "../LargeTextArea";

export default class AboutBrand extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "about_brand"}
        label="Tell the influencers about your brand"
      >
        <LargeTextArea
          data-cy="about-brand-input"
          rows={5}
          size="large"
          placeholder="We are a speaker company who make the loudest speakers in the world"
        />
      </FormField>
    );
  }
}
