// @ts-nocheck
import React from "react";
import { Wrapper, PreviewCard, OverlayGradient, DeleteIcon } from "./styles";

interface Props {
  previewUrl: string | undefined;
  onRemove?: () => void;
  type: string;
}

const videoStyle = {
  width: "100%",
  height: "123px",
  objectFit: "cover",
};

const PreviewVideo: React.FC<Props> = ({ previewUrl, onRemove, type }) => {
  return (
    <Wrapper>
      <PreviewCard
        cover={
          <>
            {!!type.match("image.*") ? (
              <img
                style={videoStyle as React.CSSProperties}
                src={previewUrl}
                alt="draft-img"
              />
            ) : (
              <video
                style={videoStyle as React.CSSProperties}
                src={previewUrl}
              />
            )}

            <OverlayGradient>
              <DeleteIcon type="delete" onClick={onRemove} />
            </OverlayGradient>
          </>
        }
      />
    </Wrapper>
  );
};

export default PreviewVideo;
