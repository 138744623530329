// @ts-nocheck
import styled from "styled-components";
import { Layout } from "antd";
import { Icon } from '@ant-design/compatible'

const { Content, Sider } = Layout;

export const SidebarBox = styled(Sider)`
  && {
    background: #fff;
    overflow: auto;
    min-height: 100vh;
    position: relative;
    border-radius: 6px;
  }
`;

export const ContentBox = styled(Content)`
  && {
    margin-left: 15px;
    position: relative;
    border-radius: 6px;
  }
`;

export const RemoveIcon = styled(Icon)`
  &&& {
    background: #fff1f0;
    color: #f5222d;
    border-radius: 50%;
    margin-top: 8px;
    margin-left: -15px;
  }
`;

export const CheckboxStyle = {
  display: "flex",
  color: "#595959",
  fontSize: "14px",
  marginLeft: "0px",
  width: "98px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: 300,
};
