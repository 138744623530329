import React, { Component } from "react";
import { connect } from "react-redux";
import CampaignWizard from "./CampaignWizard";
import DashboardLayout from "../../layouts/DashboardLayout";
import PageHero from "src/shared/PageHero";
import { CampaignPersist, CampaignPersistModule } from "src/ducks/Persist";
import withModules from "src/utils/hoc/withModules";

const modules = [CampaignPersistModule("BrandCampaignCreate")()];

const CreateModule = CampaignPersist("BrandCampaignCreate");

class CampaignCreateContainer extends Component {
  render() {
    const { createCampaign, createError, history } = this.props;

    const title = "New campaign";

    return (
      <DashboardLayout title={title}>
        <PageHero
          onBack={history.goBack}
          title={title}
          subTitle="Fill in the details below to start your campaign!"
          data-cy={`page-header`}
        />
        <CampaignWizard
          onPersist={data => history.replace("/campaigns/" + data.id)}
          persistData={createCampaign}
          serverErrors={createError}
          title={title}
        />
      </DashboardLayout>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CreateModule.selectOne(state),
  createError: CreateModule.selectCreateErrorData(state)
});

const mapDispatchToProps = {
  createCampaign: CreateModule.create,
  clearModule: CreateModule.clear
};

export default withModules(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CampaignCreateContainer)
)(modules);
