export const statuses = [
  { label: "Sent", value: "sent" },
  { label: "Accepted", value: "accepted" },
  { label: "Complete", value: "complete" },
];

export const payout_types = [
  { label: "CPM", value: "cpm" },
  { label: "Fixed", value: "fixed" },
];

export const stages = [
  { label: "Drafting", value: "drafting" },
  { label: 'Drafting Creative Concept', value: 'drafting-creative-concept'},
  { label: 'Drafting Content', value: 'drafting-content'},
  { label: "Brand Review", value: "under-review" },
  { label: "Admin Review", value: "admin-review" },
  { label: "Approved", value: "approved" },
  { label: "Posted", value: "posted" },
  { label: "Redrafting", value: "redrafting" },
  { label: 'Redrafting Creative Concept', value: 'redrafting-creative-concept'},
  { label: 'Redrafting Content', value: 'redrafting-content'},
  { label: "Awaiting Media", value: "awaiting" },
];

export const feedback_action_types = [
  { label: "Send Admin feedback to influencer", value: "redraft" },
  { label: "Send content to Brand", value: "submitted" },
  { label: "Approve content", value: "approved" },
];

export const draft_format = [
  { label: "Text", value: "text" },
  { label: "Media", value: "media" },
  { label: "Youtube draft", value: "youtube" },
];
