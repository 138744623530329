// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { Avatar, Row, Col } from 'antd'
import { Typography } from 'src/components/Core/Typography'

const AccountRow = styled(Row)`
  padding: 20px 20px 30px;
`

const StyledCol = styled(Col)<{selected: boolean}>`
  cursor: pointer;
  border-right: 1px solid #E5E5E5;
  background: ${props => props.selected ? 'inherit' : '#FAFAFA'};
`

const StyledAvatar = styled(Avatar)`
  margin-top: 5px !important;
`

interface Props {
  account: any
  selected: any
  setAccount: Function
}

const Account = ({account, setAccount, selected}: Props) => {
  return(
    <StyledCol span={6} onClick={() => setAccount(account)} selected={account?.id === selected?.id}>
      <AccountRow>
        <Col span={4}><StyledAvatar size={26} src={account.profilePicUrl} /></Col>
        <Col span={20}>
          <Typography mb={0} color={account?.id === selected?.id ? "blue5" : "black"} size={14} cursor="pointer">{account.username}</Typography>
          <Typography color="black2" size={12} cursor="pointer">{`${account.type} - Connected`}</Typography>
        </Col>
      </AccountRow>
    </StyledCol>
  )
}

export default Account
