// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import { useScreenClass } from "react-grid-system";

interface Props {
  goBack: () => void;
  onSubmit: () => void;
  index: number;
  isSubmitting: boolean;
  isDisabled: boolean;
  id: string;
}

const FormButtons: React.FC<Props> = ({
  goBack,
  onSubmit,
  index,
  isSubmitting,
  isDisabled,
  id,
}) => {
  const screenClass = useScreenClass();
  return (
    <Box
      display="inline-flex"
      justifyContent="space-between"
      flexDirection={
        screenClass === "xs" || screenClass === "sm" ? "column" : "row"
      }
      mt={20}
      width="100%"
    >
      <LinkButton
        onClick={() => goBack()}
        color="blue"
        fontSize={12}
        disabled={index === 0 ? true : false}
        mr={screenClass === "xs" || screenClass === "sm" ? 0 : 50}
        mt={screenClass === "xs" || screenClass === "sm" ? 10 : 0}
        style={
          screenClass === "xs" || screenClass === "sm"
            ? {
                order: 1,
                width: "100%",
                border: "1px solid #1890FF",
                borderRadius: "0px",
              }
            : {
                border: "1px solid #1890FF",
                borderRadius: "0px",
                width: "160px",
              }
        }
      >
        Go back
      </LinkButton>
      <Button
        htmlType="submit"
        type="action"
        loading={isSubmitting}
        disabled={isDisabled}
        buttonSize="small"
        data-cy="submit-button"
        onClick={onSubmit}
        id={id}
      >
        Submit & Continue
      </Button>
    </Box>
  );
};
export default FormButtons;
