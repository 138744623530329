// @ts-nocheck
import React from "react";
import { connectModal } from "redux-modal";
import { Modal } from "antd";
import styled from "styled-components";
import { InfluencerRatingFormContainer } from "./InfluencerRatingForm";

const InfluencerRating = styled(Modal)`
  &&& {
    border-radius: 6px;
    .ant-modal-close {
      display: none;
    }
  }
`;

const InfluencerRatingModal = props => {
  const { show, handleHide, accountId, rating, onRating } = props;

  return (
    <InfluencerRating
      style={{
        maxWidth: 800,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={show}
      onCancel={handleHide}
      footer={null}
      closeIcon={null}
      maskClosable={false}
    >
      <InfluencerRatingFormContainer
        closeInfluencerRatingModal={() => handleHide()}
        accountId={accountId}
        rating={rating}
        onRating={onRating}
      />
    </InfluencerRating>
  );
};

const EnhancedForm = connectModal({
  name: "InfluencerRatingModal",
  destroyOnHide: false,
})(InfluencerRatingModal);

export default EnhancedForm;
