// @ts-nocheck
import { Progress } from "antd";
import styled from "styled-components";

export const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 180px;
`;

export const ProgressCircle = styled(Progress)`
  && {
    position: absolute !important;
    top: 60% !important;
    .ant-progress-text {
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
