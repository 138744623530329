import axios from "axios";
import { config } from "./config";

var axiosInstance = axios.create({
  baseURL: `https://api.cloudinary.com/v1_1/${config.cloudName}`,
  responseType: "json",
  headers: {
    Accept: "application/json",
  },
  params: {
    upload_preset: config.uploadPreset,
    api_key: 147436647785111
  }
});

export const uploadCreative = data => {
  data.append("timestamp", (Date.now() / 1000) | 0);
  data.append("folder", process.env.NODE_ENV + "/creatives");
  return axiosInstance.post("/upload", data);
};

const sleep = ms => new Promise(res => setTimeout(res, ms));

const processFile = async file => {
  let sliceSize = 100000000 //100MB
  let size = file.size;
  let start = 0;
  let end = start + sliceSize;
  let upload = true
  const uniquUploadID = Date.now()

  let promise = new Promise(async (resolve, reject) => {
    while(upload) {
      await sleep(3)
      if(end > size)
        end = size
      let s = slice(file, start, end, uniquUploadID);
      send(s, start, end-1, size, uniquUploadID)
        .then(res => {
          if(res?.data?.done){
            resolve(res)
          }
        })
        .catch(err => {
          reject(err)
        })
      if(end === size)
        upload = false
      start += sliceSize;
      end = start + sliceSize;
    }
  })
  let response = await promise
  return response
}


const send = (piece, start, end, size, XUniqueUploadId) => {
  var data = new FormData();

  data.append("timestamp", (Date.now() / 1000) | 0);
  data.append("folder", process.env.NODE_ENV + "/creatives");
  data.append('file', piece);
  data.append('upload_preset', config.uploadPreset);
  data.append('cloud_name', config.cloudName);
  return axiosInstance.post(`/auto/upload`, data, {
          headers: {
            'X-Unique-Upload-Id': XUniqueUploadId,
            'Content-Range': "bytes " + start + "-" + end + "/" + size
          }
        })
}

const slice = (file, start, end) => {
  const slice = file.mozSlice ? file.mozSlice :
              file.webkitSlice ? file.webkitSlice :
              file.slice ? file.slice : noop;

  return slice.bind(file)(start, end);
}

const noop = () => {}

const uploadSmallFile = (file) => {
  var data = new FormData();
  data.append("timestamp", (Date.now() / 1000) | 0);
  data.append("folder", process.env.NODE_ENV + "/creatives");
  data.append("file", file);
  data.append("upload_preset", config.uploadPreset);
  return axiosInstance.post("/upload", data);
}

export const v2uploadCreative = async file => {
  if(file.size < 100000000) {
    // file less than 100MB
    return uploadSmallFile(file)
  }
  let response = await processFile(file)
  return response
};

export const uploadInfluencerDraft = (file, options) => {
  var data = new FormData();
  data.append("timestamp", (Date.now() / 1000) | 0);
  data.append("folder", process.env.NODE_ENV + "/creatives");
  data.append("file", file);
  data.append("upload_preset", config.uploadPreset);
  return axiosInstance.request({
    "url": "/upload",
    "method": "POST",
    "headers": {
      "accept": "application/json",

    },
    ...options,
    data
  });
};
