// @ts-nocheck
import React, { useState } from "react";
import { ProfileComplete } from ".";
import { object } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useUpdateWelcomeRegisterForBetaMutation } from "src/generated/ApolloComponents";

interface Props {
  claimAccountId: any;
  registeredForBeta: boolean | false;
}

interface FormValues {}

const registerForBetaSchema = object().shape({});

export const ProfileCompleteContainer: React.FC<Props> = ({
  claimAccountId,
  registeredForBeta,
}) => {
  const [isRegisteredForBeta, setIsRegisteredForBeta] = useState<boolean>(
    registeredForBeta
  );

  let initialValues = {};

  const [
    updateWelcomeRegisterForBetaMutation,
    { loading, error },
  ] = useUpdateWelcomeRegisterForBetaMutation();

  const onSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    try {
      const formData = {
        claimAccountId: claimAccountId,
      };

      const res = await updateWelcomeRegisterForBetaMutation({
        variables: formData,
      });

      if (res) {
        setIsRegisteredForBeta(
          res?.data?.updateWelcomeRegisterForBeta?.influencer
            ?.registerForBeta as boolean
        );
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <ProfileComplete
      initialValues={initialValues}
      registerForBetaSchema={registerForBetaSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      isRegisteredForBeta={isRegisteredForBeta}
    />
  );
};
