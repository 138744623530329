// @ts-nocheck
import React, { useRef } from "react";
import { DownCircleOutlined } from '@ant-design/icons';
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { useScreenClass } from "react-grid-system";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import FadeInSection from "../../FadeInSection";
import ParallaxSection from "../../ParallaxSection";
import profile1 from "src/assets/images/reports/profile1.png";
import profile2 from "src/assets/images/reports/profile2.png";
import profile3 from "src/assets/images/reports/profile3.png";
import { Avatar1, Avatar2, Avatar3 } from "./styles";

interface Props {
  campaignData: any;
  loading: boolean;
}

const CampaignDetail: React.FC<Props> = ({ campaignData, loading }) => {
  const screenClass = useScreenClass();

  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};

  let props = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  return (
    <FadeInSection>
      <Box
        position="relative"
        minHeight={
          screenClass === "xs" || screenClass === "sm"
            ? "68vh"
            : screenClass === "md"
            ? "70vh"
            : "80vh"
        }
        ref={myRef as any}
      >
        <VelocityComponent
          key="bounce"
          animation={
            !loading && enterCount <= 1 && inViewport
              ? "transition.bounceIn"
              : "transition.fadeIn"
          }
          delay={50}
        >
          <ParallaxSection direction="up">
            <Avatar1
              size={screenClass === "xs" || screenClass === "sm" ? 54 : 100}
              src={
                campaignData?.topViews?.[0]?.profilePicUrl
                  ? campaignData?.topViews?.[0]?.profilePicUrl
                  : profile1
              }
            />
          </ParallaxSection>
        </VelocityComponent>

        <Box
          textAlign="center"
          mt={screenClass === "xs" || screenClass === "sm" ? 150 : 225}
        >
          <VelocityComponent
            key="slideUp"
            animation={
              !loading && enterCount <= 1 && inViewport
                ? "transition.slideUpIn"
                : "transition.fadeIn"
            }
            stagger={800}
            duration={1000}
          >
            <Typography
              fontSize={screenClass === "xs" || screenClass === "sm" ? 40 : 70}
              fontWeight={600}
              color="white"
            >
              {campaignData?.campaignTitle}
            </Typography>
          </VelocityComponent>
          <VelocityComponent
            key="slideUp1"
            animation={
              !loading && enterCount <= 1 && inViewport
                ? "transition.slideUpIn"
                : "transition.fadeIn"
            }
            delay={500}
            stagger={1200}
            duration={1500}
          >
            <Typography
              fontSize={screenClass === "xs" || screenClass === "sm" ? 32 : 40}
              fontWeight={500}
              color="blue3"
            >
              #TheReport
            </Typography>
          </VelocityComponent>
          <VelocityComponent
            key="slideUp2"
            animation={
              !loading && enterCount <= 1 && inViewport
                ? "transition.slideUpIn"
                : "transition.fadeIn"
            }
            delay={500}
            stagger={1500}
            duration={1800}
          >
            <DownCircleOutlined
              style={{ color: "#818A93", fontSize: "36px", marginTop: "40px" }}
              onClick={() => {
                let elmnt: any = document.getElementById("objective");
                elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
              }} />
          </VelocityComponent>
        </Box>

        <VelocityComponent
          key="bounce1"
          animation={
            !loading && enterCount <= 1 && inViewport
              ? "transition.bounceIn"
              : "transition.fadeIn"
          }
          delay={50}
        >
          <ParallaxSection direction="up">
            <Avatar2
              size={screenClass === "xs" || screenClass === "sm" ? 83 : 175}
              src={
                campaignData?.topViews?.[1]?.profilePicUrl
                  ? campaignData?.topViews?.[1]?.profilePicUrl
                  : profile2
              }
            />
          </ParallaxSection>
        </VelocityComponent>

        <VelocityComponent
          key="bounce2"
          animation={
            !loading && enterCount <= 1 && inViewport
              ? "transition.bounceIn"
              : "transition.fadeIn"
          }
          delay={50}
        >
          <ParallaxSection direction="up">
            <Avatar3
              size={screenClass === "xs" || screenClass === "sm" ? 126 : 184}
              src={
                campaignData?.topViews?.[2]?.profilePicUrl
                  ? campaignData?.topViews?.[2]?.profilePicUrl
                  : profile3
              }
            />
          </ParallaxSection>
        </VelocityComponent>
      </Box>
    </FadeInSection>
  );
};

export default CampaignDetail;
