// @ts-nocheck
import React from 'react'
import { FormikProps } from 'formik'
import { Field } from 'src/components/Forms/Field'
import { location_targets } from 'src/constants/campaign'
import { FormikSelect } from 'src/components/Forms/Select'

interface Props {
  formProps: FormikProps<any>;
}

const Name = ({ formProps }: Props) => {

  return (
    <Field
      label="Location"
      mb={20}
      color="black"
      isBeside={true}
      id="location"
    >
      <FormikSelect
        name="location"
        data={location_targets}
        local
        borderRadius={3}
        borderColor="grey5"
        size="large"
        minHeight={40}
      />
    </Field>
  );
};

export default Name;
