import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Form } from "formik-antd";
import { useFormikContext } from "formik";
import { connectModal } from "redux-modal";
import ModalForm from "src/shared/ModalForm";
import withModules from "src/utils/hoc/withModules";
import { CampaignPersist } from "src/ducks/Persist";
import Validate from "./Validate";
import { Title, Company, CampaignAccess, HubspotId } from "src/shared/CampaignFields";
import {
  useGetHubSpotDealValidationByIdQuery
} from "src/generated/ApolloComponents";
import debounce from 'lodash/debounce';

const CampaignCreate = CampaignPersist("CreateCampaignModal");

const modules = [CampaignCreate.module()];

const FormFields = ({ setIsHubspotIdValid }) => {
  const { values, setFieldError } = useFormikContext();
  const {data, loading, error, refetch} = useGetHubSpotDealValidationByIdQuery({
    variables: {dealId: values?.hubspot_deal_id},
    skip: !values?.hubspot_deal_id,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((dealId) => {
      refetch({dealId});
    }, 300),
    [refetch]
  );

  useEffect(() => {
    if(values?.hubspot_deal_id) {
      debouncedRefetch(values.hubspot_deal_id);
    } else {
      setIsHubspotIdValid(false);
    }
  }, [values?.hubspot_deal_id, debouncedRefetch, setIsHubspotIdValid]);

  useEffect(() => {
    if(data?.getHubSpotDealValidationById?.isDealValid === true) {
      setIsHubspotIdValid(true);
      setFieldError('hubspot_deal_id', undefined);
    } else {
      setFieldError('hubspot_deal_id', 'Invalid HubSpot Deal ID');
      setIsHubspotIdValid(false);
    }
  }, [data, setFieldError, setIsHubspotIdValid]);

  return (
    <>
      <Title />
      {!values.hideCompany && <Company />}
      <CampaignAccess />
      <HubspotId
        dealId={values?.hubspot_deal_id}
        error={error}
        isValid={data?.getHubSpotDealValidationById?.isDealValid}
        loading={loading}
      />
    </>
  );
};

const CreateCampaignModal = ({
  show,
  create,
  handleHide,
  onCreate,
  hideCompany,
  initialValues,
}) => {
  const [isHubspotIdValid, setIsHubspotIdValid] = useState(false);

  const handleSubmit = async (values, formikBag) => {
    if (!isHubspotIdValid) {
      formikBag.setFieldError('hubspot_deal_id', 'Invalid HubSpot Deal ID');
      return;
    }
    await create(values, formikBag);
  };

  return (
    <ModalForm
      show={show}
      onSave={handleSubmit}
      afterSave={onCreate}
      initialValues={{...initialValues, hideCompany}}
      validate={Validate}
      handleHide={handleHide}
      title="Create new campaign"
    >
      <Form data-cy="campaign-create-modal-form">
        <FormFields setIsHubspotIdValid={setIsHubspotIdValid} />
      </Form>
    </ModalForm>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  create: CampaignCreate.create,
};

export default withModules(
  connectModal({
    name: "CreateCampaignModal",
    destroyOnHide: true,
  })(connect(mapStateToProps, mapDispatchToProps)(CreateCampaignModal))
)(modules);
