import { Admin, Brand, Influencer } from "./Orm"
import { createSelector } from "reselect"
import * as auth from "../api/endpoints/auth"

const initialState = {
  user: {},
  isPending: false,
  isLoggingIn: false,
  isAuthenticated: false,
  appLoaded: false,
  error: null,
  loginError: null
}

// Types
const types = {
  fetchUser: "V2_FETCH_CURRENT_USER",
  logout: "V2_LOGOUT_CURRENT_USER",
  forgot: "V2_FORGOT_USER",
  switchUser: "V2_SWITCH_USER",
  loadPerishable: "V2_LOAD_USER_BY_PERISHABLE",
  loadConfirmation: "V2_LOAD_USER_BY_CONFIRMATION",
  login: "V2_LOGIN_USER",
  update: "V2_UDATE_AUTH_USER"
}

// Action creators
const actions = {
  fetchUser: (data) => {
    return (dispatch) => {
      const response = dispatch({
        type: types.fetchUser,
        payload: auth.get({
          params: data
        })
      })

      response.then((res) => {
        const user = res.value.data

        if (user.type === "Admin") {
          dispatch(Admin.addOne(res.value.data))
        } else if (user.type === "Brand") {
          dispatch(Brand.addOne(res.value.data))
        } else if (user.type === "Influencer") {
          dispatch(Influencer.addOne(res.value.data))
        }
      })

      return response
    }
  },
  switchUser: (id) => {
    return (dispatch) => {
      let response = dispatch({
        type: types.switchUser,
        payload: auth.switchUser({ user: id })
      })

      response = response.then((res) => {
        const {
          data: { auth_token }
        } = res.value

        localStorage.setItem("authToken", auth_token)

        return dispatch(actions.fetchUser())
      })

      return response
    }
  },
  forgotEmail: (data) => ({
    type: types.forgot,
    payload: auth.forgot(data)
  }),
  loadUserByPerishableToken: (data) => ({
    type: types.loadPerishable,
    payload: auth.find({
      params: {
        perishable_token: data
      }
    })
  }),
  loadUserByConfirmationToken: (data) => ({
    type: types.loadConfirmation,
    payload: auth.find({
      params: {
        confirmation_token: data
      }
    })
  }),
  updateUser: (data) => {
    return (dispatch) => {
      let response = dispatch({
        type: types.update,
        payload: auth.update(data)
      })

      response = response.then((res) => {
        const {
          data: { auth_token }
        } = res.value

        localStorage.setItem("authToken", auth_token)

        return dispatch(actions.fetchUser())
      })

      return response
    }
  },
  loginUser: (data) => {
    return (dispatch) => {
      let response = dispatch({
        type: types.login,
        payload: auth.create(data)
      })

      response = response.then((res) => {
        const {
          data: { auth_token }
        } = res.value

        localStorage.setItem("authToken", auth_token)

        return dispatch(actions.fetchUser())
      })

      return response
    }
  },
  logoutUser: () => {
    return (dispatch) => {
      localStorage.removeItem("authToken")

      return dispatch({
        type: types.logout
      })
    }
  }
}

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.fetchUser + "_PENDING":
      return {
        ...state,
        isPending: true
      }
    case types.fetchUser + "_FULFILLED":
      return {
        ...state,
        isPending: false,
        appLoaded: true,
        user: {
          id: action.payload.data.id,
          type: action.payload.data.type
        },
        isAuthenticated: true
      }
    case types.fetchUser + "_REJECTED":
      return {
        ...state,
        isPending: false,
        appLoaded: true,
        error: action.payload,
        user: {},
        isAuthenticated: false
      }
    case types.login + "_PENDING":
      return {
        ...state,
        isLoggingIn: true,
        loginError: null
      }
    case types.login + "_REJECTED":
      return {
        ...state,
        isLoggingIn: false,
        loginError: action.payload,
        isAuthenticated: false
      }
    case types.login + "_FULFILLED":
      return {
        ...state,
        isLoggingIn: false,
        loginError: null,
        isAuthenticated: true
      }
    case types.logout:
      return {
        ...state,
        isPending: false,
        isAuthenticated: false,
        user: {}
      }
    default:
      return state
  }
}

// Selectors
const entities = (state) => state.entities
const selectUserId = (state) => state.auth.user.id
const selectUserType = (state) => state.auth.user.type
const selectLoading = (state) => state.auth.isPending

const selectors = {
  selectUserId,
  selectUserType,
  selectLoading,
  selectAppLoaded: (state) => state.auth.appLoaded,
  selectAuthenticated: (state) => state.auth.isAuthenticated,
  selectError: (state) => state.auth.error,
  selectLoginError: (state) => state.auth.loginError,

  selectUser: createSelector(entities, selectUserId, selectUserType, (entities, id, type) => {
    switch (type) {
      case "Brand":
        return Brand.selectOne(entities, id) || {}
      case "Influencer":
        return Influencer.selectOne(entities, id) || {}
      case "Admin":
        return Admin.selectOne(entities, id) || {}
      default:
        return {}
    }
  })
}

const AuthModule = () => ({
  id: "auth",
  reducerMap: {
    auth: reducer
  }
})

export default {
  types,
  reducer,
  module: AuthModule,
  ...actions,
  ...selectors
}
