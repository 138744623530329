// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSwitch } from "src/components/Forms/Switch";

interface Props {}

const Active: React.FC<Props> = () => {
  return (
    <Field label="Active:" mb={26}>
      <FormikSwitch name="active" />
    </Field>
  );
};

export default Active;
