// @ts-nocheck
import React from "react";
import { NewAccountForm } from ".";
import { message } from "antd";
import { NewAccountSchema } from "./Validations";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetAllTagsQuery,
  useGetAllInfluencersQuery,
  useCreateAccountMutation,
  AccountStatusType,
} from "src/generated/ApolloComponents";
import { featureFlags } from "src/constants/featureFlags";

interface Props {
  closeAccountModal?: () => void;
  onSucessAddNewAccount: () => void;
}

interface NewAccountFormValues {
  username: string;
  status: any;
  platform: string;
  format: string;
  cpm: number;
  influencerName?: string;
  influencerEmail?: string;
  influencerPaypalEmail?: string;
  active: boolean;
  salesAppropriate: boolean;
  tagIds: string[];
  createInfluencer?: boolean;
  influencerId?: number;
}

export const NewAccountFormContainer: React.FC<Props> = props => {
  const tags = useGetAllTagsQuery();

  const allTags = tags?.data?.getAllTags || [];

  const tagsArray = allTags.map(item => {
    let container = {} as any;
    container.value = item.id;
    container.label = item.name;
    return container;
  });

  const influencers = useGetAllInfluencersQuery({
    skip: true,
  });

  const [createAccountMutation, createAccount] = useCreateAccountMutation();

  let initialValues = {
    username: "",
    status: "",
    platform: "",
    format: "",
    cpm: 0,
    influencerName: "",
    influencerEmail: "",
    influencerPaypalEmail: "",
    active: true,
    salesAppropriate: false,
    tagIds: [],
    createInfluencer: false,
    influencerId: "",
  };

  const onSubmit = async (
    values: NewAccountFormValues,
    actions: FormikActions<NewAccountFormValues>
  ) => {
    try {
      let status = "";

      if (values?.status === "Not Verified") {
        status = AccountStatusType.NotVerified;
      } else if (values?.status === "Semi Verified") {
        status = AccountStatusType.SemiVerified;
      } else {
        status = AccountStatusType.Verified;
      }

      let formData: any = {
        username: values?.username,
        platform: values?.platform,
        format: values?.format,
        cpm: Math.round(values?.cpm),
        active: values?.active,
        status: status,
        tagIds: values?.tagIds,
        salesAppropriate: values?.salesAppropriate,
      };

      let container = {} as any;

      if (values?.createInfluencer) {
        container["influencerName"] = values?.influencerName;
        container["influencerEmail"] = values?.influencerEmail;
        if(featureFlags.isPaypalEnabled) {
          container["influencerPaypalEmail"] = values?.influencerPaypalEmail;
        }
      } else {
        container["influencerId"] = values?.influencerId;
      }

      formData = { ...formData, ...container };

      const res = await createAccountMutation({
        variables: { data: formData },
      });
      if (res?.data?.createAccount?.id) {
        message.success("Account added successfully");

        props.onSucessAddNewAccount();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(createAccount?.error);

  return (
    <NewAccountForm
      {...props}
      initialValues={initialValues}
      NewAccountSchema={NewAccountSchema}
      isSubmitting={createAccount?.loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      closeAccountModal={props.closeAccountModal}
      tagsData={tagsArray}
      getInfluencers={influencers.refetch}
    />
  );
};
