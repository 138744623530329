import { string, object, number, boolean } from "yup";

export default object().shape({
  username: string()
    .nullable()
    .required("Please enter a username"),
  type: string()
    .nullable()
    .required("Please enter a platform"),
  status: string()
    .nullable()
    .required("Please enter a status"),
  cpm: number()
    .nullable()
    .required("Please enter a cpm"),
  createInfluencer: boolean(),
  create_influencer: object().when("createInfluencer", {
    is: true,
    then: object().shape({
      name: string()
        .nullable()
        .required("Please enter a name"),
      email: string()
        .nullable()
        .required("Please enter an email"),
      payout_details: object().shape({
        paypal_email: string().nullable(),
      }),
    }),
  }),
  influencer: number().when("createInfluencer", {
    is: false,
    then: number()
      .nullable()
      .required("Please select an influencer"),
  }),
  format: string()
    .nullable()
    .required("Please enter a format"),
});
