// @ts-nocheck
import React, { useState } from "react";
import { Screenshots } from ".";
import find from "lodash/find";
import axios from "axios";
import { useGetGeneralSignedUrlMutation } from "src/generated/ApolloComponents";

interface Props {
  name: string;
  setIsUploading: (loading: boolean) => void;
  setFieldValue: (value: any) => void;
  setFieldTouched: () => void;
  setShowExample: () => void;
  values: any;
  setScreenshotType: () => void;
  platformType: string;
  accountName: string;
  screenshotType: string;
  multiple: boolean;
  disabled: boolean;
  showExample: object;
  hideExample: () => void;
  uploadText?: string;
}

export const ScreenshotsContainer: React.FC<Props> = ({
  setIsUploading,
  setFieldValue,
  setFieldTouched,
  setShowExample,
  values,
  name,
  setScreenshotType,
  platformType,
  accountName,
  screenshotType,
  multiple,
  disabled,
  showExample,
  hideExample,
  uploadText,
}) => {
  const [fileList, setUploadedFile] = useState([] as Array<any>);

  const [getGeneralSignedUrlMutation] = useGetGeneralSignedUrlMutation();

  const onUpload = async data => {
    setIsUploading(true);

    const id = Math.floor(Math.random() * 90000) + 10000;

    const newFileList = [
      ...(fileList || []),
      {
        id: id,
        uid: id,
        data: data?.file,
        name: data?.file?.name,
        status: "uploading",
      },
    ];

    setUploadedFile([...newFileList]);

    //AccountName_AccountType_demographic_date

    let fileName = `${accountName}_${platformType}_${screenshotType}`;
    const fileData = {
      // fileName: data.file.name,
      fileName: fileName,
      fileType: data.file.type,
    };

    try {
      const result = await getGeneralSignedUrlMutation({
        variables: fileData,
      });

      if (result) {
        const signedUrl = result?.data?.getGeneralSignedURL?.url!;

        const options = {
          headers: {
            "Content-Type": data.file.type,
          },
        };

        axios
          .put(signedUrl as string, data.file, options)
          .then(response => {
            if (response) {
              const file = find(newFileList, { id: id });

              file.status = "done";

              setUploadedFile([...newFileList]);
            }

            if (multiple) {
              const value = [
                {
                  uploadUrl: result?.data?.getGeneralSignedURL?.uploadUrl,
                  uploadType: data.file.type,
                },
              ];

              setFieldValue(values[name] ? values[name].concat(value) : value);
            } else {
              const value = {
                uploadUrl: result?.data?.getGeneralSignedURL?.uploadUrl,
                uploadType: data.file.type,
              };
              setFieldValue(value);
            }

            setIsUploading(false);

            setFieldTouched();
          })
          .catch(err => {
            setIsUploading(false);
          });
      }
    } catch (e) {
      setIsUploading(false);
    }
  };

  const handleRemove = data => {
    const index = fileList.findIndex(obj => obj.id === data?.id);

    if (index > -1) {
      fileList.splice(index, 1);
      setUploadedFile([...fileList]);

      values[name].splice(index, 1);
      setFieldValue(values[name]);
    }
  };

  return (
    <Screenshots
      handleRemove={value => handleRemove(value)}
      onUpload={value => onUpload(value)}
      fileList={fileList}
      setShowExample={setShowExample}
      setScreenshotType={setScreenshotType}
      multiple={multiple}
      disabled={disabled}
      name={name}
      platformType={platformType}
      screenshotType={screenshotType}
      showExample={showExample}
      hideExample={hideExample}
      uploadText={uploadText}
    />
  );
};
