// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import instagram from "src/assets/images/instagram_logo.png";
import snapchat from "src/assets/images/snapchat_logo.png";
import youtube from "src/assets/images/youtube_logo.png";
import tiktok from "src/assets/images/tiktok_logo.png";

interface Props {
  accountData: any;
}

const ProfileInfoText: React.FC<Props> = ({ accountData }) => {
  return (
    <Box borderBottom="1px solid #D9D9D9">
      <Box p={24}>
        <Box display="inline-flex">
          <Typography
            size={30}
            weight="semi-bold"
            color="black"
            lineHeight="25px"
          >
            Welcome @{accountData?.username}!
          </Typography>
          <Box ml={20}>
            {accountData?.type === "Instagram" && (
              <>
                <img
                  src={instagram}
                  alt="instagram-img"
                  height="30"
                  width="30"
                />
              </>
            )}
            {accountData?.type === "Snapchat" && (
              <>
                <img src={snapchat} alt="snapchat-img" height="30" width="30" />
              </>
            )}
            {accountData?.type === "YouTube" && (
              <>
                <img src={youtube} alt="youtube-img" height="30" width="30" />
              </>
            )}
            {accountData?.type === "TikTok" && (
              <>
                <img src={tiktok} alt="tiktok-img" height="30" width="30" />
              </>
            )}
          </Box>
        </Box>

        <Typography size={16} color="grey2" lineHeight="25px">
          We’re excited to have you onboard! We want creators like yourself on
          campaigns with some of the best brands in the world. All you have to
          do is confirm your details below and we’ll get started.
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileInfoText;
