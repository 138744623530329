import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "antd";
import Container from "src/shared/Container";
import CreativeForm from "./CreativeForm";
import CampaignManage from "src/ducks/CampaignManage";

export class Creative extends Component {
  render() {
    return (
      <Container>
        <Card style={{ marginBottom: 24, marginTop: 24 }}>
          <CreativeForm />
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Creative);
