import React, { Component } from "react"
import FormField from "../FormField"
import { stages } from "src/constants/invitations"
import { Select } from "formik-antd"

export default class Stage extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "stage"}
        label="Stage"
        help="What stage is this influencer at?"
      >
        <Select data-cy="invitation-stage" allowClear={true} size="large" placeholder="Select..." options={stages}>
        </Select>
      </FormField>
    )
  }
}
