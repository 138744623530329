import React, { Component } from "react";
import FormField from "../FormField";
import SearchSelect from "src/shared/SearchSelect";
import { locations } from "src/constants/account";

export default class Locations extends Component {
  render() {
    return (
      <FormField name={this.props.name || "detail.location"} label="Location">
        <SearchSelect
          data-cy="influencer-locations"
          size="large"
          placeholder="Select..."
          data={locations}
          local
        />
      </FormField>
    );
  }
}
