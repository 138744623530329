import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { show } from "redux-modal";
import { default as TableManagerDuck } from "src/ducks/TableManager";
import Filters from "./Filters";
import CreateModal from "./CreateModal";
import TableManager from "src/shared/TableManager";
import { CompanyList as $CompanyList } from "src/ducks/DataList";
import withWindowResize from "src/utils/hoc/withWindowResize";

const CompanyList = $CompanyList("AdminCompanyList");
const CompanyFilters = TableManagerDuck("AdminCompanyList");

export class Companies extends Component {
  loadCompanies = () => {
    const { filters } = this.props;
    return this.props.loadCompanies(filters);
  };

  render() {
    const { companies, pagination, loading, height } = this.props;

    const columns = {
      company_name: {
        title: "Company Name",
        dataIndex: "company_name",
        sorter: true,
        visible: true,
        width: 300,
        render: (text, row) => <Link to={`/companies/${row.id}`}>{text}</Link>,
      },
      created_at: {
        title: "Created At",
        dataIndex: "created_at",
        sorter: true,
        visible: true,
        width: 150,
        render: (text, row) => row.formattedCreatedAt(),
      },
    };

    return (
      <TableManager
        name="AdminCompanyList"
        model="Company"
        columns={columns}
        loadData={this.loadCompanies}
        filterOptions={Filters}
        data={companies}
        pagination={pagination}
        loading={loading}
        createModal={<CreateModal onCreate={this.loadCompanies} />}
        scroll={{ y: height, x: "max-content" }}
      />
    );
  }
}

const mapStateToProps = state => ({
  filters: CompanyFilters.selectFilters(state),
  companies: CompanyList.selectMany(state),
  loading: CompanyList.selectPending(state),
  pagination: CompanyList.selectPagination(state),
});

const mapDispatchToProps = {
  show,
  loadCompanies: CompanyList.loadMany,
};

const MainCompanies = connect(mapStateToProps, mapDispatchToProps)(Companies);

export default withWindowResize({ extraHeight: 260 })(MainCompanies);
