// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  tagsData: Array<any>;
}

const Tags: React.FC<Props> = ({ tagsData }) => {
  return (
    <Field label="Tags:" mb={26}>
      <FormikSelect
        name="tagIds"
        size="middle"
        placeholder="Select..."
        data={tagsData}
        mode="multiple"
        local
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Tags;
