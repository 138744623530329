// @ts-nocheck
import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import { NewMilestoneFormContainer } from './NewMilestoneForm'

const StyledModal = styled(Modal)`
  &&& {
    .ant-modal-close {
      display: none;
    }
  }
`

interface Props {
  show: boolean
  toggleVisibility: () => void
  milestone: any
  refetch: any
}

const NewMilestoneModal = ({show, toggleVisibility, milestone, refetch}: Props) => {
  return(
    <StyledModal
      style={{
        maxWidth: 500,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={show}
      footer={null}
      maskClosable={false}
    >
      <NewMilestoneFormContainer
        toggleVisibility={toggleVisibility}
        milestone={milestone}
        refetch={refetch}
      />
    </StyledModal>
  )
}

export default NewMilestoneModal
