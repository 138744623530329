// @ts-nocheck
import React, { useState, Fragment } from 'react'
import { Pagination } from 'antd'
import { Box } from 'src/components/Core/Box'
import { Loading } from 'src/components/Core/Loading'
import getColumns from '../Columns'
import { AccountsNewTable } from '../utils/styles'
import { columnVisibility } from '../utils'
import { AccountsContainer as Props } from '../props'
import Actions from '../Actions'
import AccountFilterModal from '../../AccountFilterModal'
import FilterIcon from '../../FilterIcon'
import Droppable from './Droppable'
import GridList from '../GridList'
import { Draggable } from 'react-beautiful-dnd'

const AccountsContainer = (props: Props) => {
  const { allAccounts, contentType, filters, height, isInnerAccountList, loading,
          menu, onFilter, rowSelection, list, } = props

  let accountIndexes = {}
  allAccounts?.accounts?.map((account, index) => accountIndexes[account.id] = index)

  const [showFilterAccountForm, setShowFilterAccountForm] = useState(false);
  const DraggableoRow = (props) => {
    return(
      <Fragment>
        {list?.id && props["data-row-key"] ?
          <Draggable draggableId={String(props["data-row-key"])} index={accountIndexes[props["data-row-key"]]}>
            {(provided) => (
              <tr {...props} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} />
            )}
          </Draggable>
          :
          <tr {...props} />
        }
      </Fragment>)
  }

  return(
    <Box background="white" p={10} borderRadius="4px" mr={15}>
      {!showFilterAccountForm && (
        <FilterIcon
          showAccountFilterForm={() => setShowFilterAccountForm(true)}
          {...props}
        />
      )}
      <Actions {...props} />

      <Loading spinning={loading}>
        {contentType === "list" ? (
          <Droppable {...props}>
            <AccountsNewTable
              components={{body: {row: DraggableoRow}}}
              rowSelection={rowSelection}
              getColumns={getColumns}
              rowKey="id"
              dataSource={allAccounts?.accounts || []}
              stripe={true}
              data-cy="table-AccountTable"
              pagination={{
                position: "bottom",
                current: allAccounts?.currentPage,
                pageSize: allAccounts?.perPage,
                total: allAccounts?.totalEntries,
              }}
              rowClassName={
                (record: any) =>
                  record.isSuggested ? "table-row-fade" : ""
              }
              name="CampaignsAccountList"
              rowHover={true}
              scroll={{ y: height, x: "max-content" }}
              filters={filters}
              onFilter={onFilter}
              defaultColumnVisibilty={columnVisibility}
              extraGetColumnsArgs={{ isInnerAccountList, menu }}
            />
          </Droppable>
        ) : (
          <Box>
            <Droppable {...props}>
              <GridList accounts={allAccounts?.accounts || []} {...props} />
            </Droppable>
            <Box mt={20} p={10} textAlign="right">
              <Pagination
                current={allAccounts?.currentPage}
                onChange={page => onFilter("page", page)}
                pageSize={allAccounts?.perPage || 0}
                total={allAccounts?.totalEntries}
              />
            </Box>
          </Box>
        )}
      </Loading>
      <AccountFilterModal
        closeAccountFilterModal={() => setShowFilterAccountForm(false)}
        showFilterAccountForm={showFilterAccountForm}
        {...props}
      />
    </Box>
  )
}

export default AccountsContainer
