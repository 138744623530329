// @ts-nocheck
import React, { Fragment } from 'react'
import { Props } from '../LeftPanel'
import {
  Email,
  Password,
  ConfirmPassword,
} from '../FormFields'

const RightPanel = ({formProps}: Props) => {
  return(
    <Fragment>
      <Email formProps={formProps} />
      <Password formProps={formProps} />
      <ConfirmPassword formProps={formProps} />
    </Fragment>
  )
}

export default RightPanel
