import React, { Component } from "react"
import FormField from "../FormField"
import { Switch } from "formik-antd"

export default class Platform extends Component {
  render() {
    return (
      <FormField name={this.props.name || "sales_appropriate"} label="Sales appropriate?">
        <Switch data-cy="account-sales-appropriate" />
      </FormField>
    )
  }
}
