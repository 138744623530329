// @ts-nocheck
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { AlertProps } from "antd/lib/alert";
import { getIn, FormikErrors, FormikTouched } from "formik";
import { space, SpaceProps, layout, LayoutProps } from "styled-system";
import { Box, BoxProps } from "../../Core/Box";
import { Typography } from "src/components/Core/Typography";

const FormAlert = (props: AlertProps & Props) => {
  return <Alert type="warning" {...props} />;
};

export const ErrorMessage = styled(FormAlert)<
  AlertProps & SpaceProps & LayoutProps
>`
  ${space}
  ${layout}

  .ant-alert-message {
    font-size: 14px;
  }

  .ant-alert-description {
    font-size: 12px;
  }
`;

export interface GlobalErrorProps {
  id: string;
  message: string;
}

export interface GlobalErrorsProps {
  errors?: GlobalErrorProps[];
}

interface Props {
  ref: any
}

export const GlobalErrors = ({
  errors,
  ...rest
}: GlobalErrorsProps & BoxProps) => {
  const myRef = useRef<null | HTMLElement>(null)
  const executeScroll = () => myRef?.current?.scrollIntoView()

  useEffect(() => {
    if((errors?.length ?? 0) > 0) {
      executeScroll()
    }
  },[errors])

  if (!errors) return null;

  return (
    <Box {...rest} mb={15}>
      {errors.map((e, index) => (
        <ErrorMessage
          ref={index === 0 && myRef}
          type="error"
          key={e.message}
          message="Error"
          description={e.message}
          showIcon
          closable
          mb={15}
        />
      ))}
    </Box>
  );
};

interface FieldErrorMessageProps {
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  name: string;
}

export const FieldErrorMessage = ({
  touched,
  errors,
  name,
  ...rest
}: FieldErrorMessageProps & SpaceProps & LayoutProps) => {
  const touch = getIn(touched, name);
  const error = getIn(errors, name);

  return touch && error ? (
    <Typography size={14} color="red" mt="5px">
      {error}
    </Typography>
  ) : null;
};

interface FieldErrorProps {
  errors: FormikErrors<any>;
  name: string;
}

export const FieldError = ({
  errors,
  name,
  ...rest
}: FieldErrorProps & SpaceProps & LayoutProps) => {
  const error = getIn(errors, name);
  return !Array.isArray(error) ? (
    <Typography size={14} color="red" mt="5px">
      {error}
    </Typography>
  ) : null;
};
