// @ts-nocheck
import React, { useState, useEffect } from "react";
import { NotFound } from "../../components";
import { ProfileStep } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useGetWelcomeProfileAccountQuery } from "src/generated/ApolloComponents";

type Props = {
  claimAccountId: any;
};

export const ProfileStepContainer: React.FC<Props> = ({ claimAccountId }) => {
  const [step, setStep] = useState<number>(1);

  const { data, loading, error, refetch } = useGetWelcomeProfileAccountQuery({
    variables: {
      claimAccountId: claimAccountId,
    },
  });

  useEffect(() => {
    refetch({
      claimAccountId: claimAccountId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimAccountId]);

  const accountData: any = data?.getWelcomeProfileAccount || {};

  const globalErrors = useMapGraphQLErrorsMessages(error);

  if (!loading && (error || !accountData)) return <NotFound />;

  return (
    <ProfileStep
      globalErrors={globalErrors}
      loading={loading}
      accountData={accountData}
      step={step}
      setStep={value => setStep(value)}
      claimAccountId={claimAccountId}
    />
  );
};
