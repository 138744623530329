// @ts-nocheck
import React, { ReactNode } from "react";
import { Typography, TypographyProps } from "src/components/Core/Typography";
import { ThemeColors, FontSizes } from "src/utils/theme";

interface Props {
  children: ReactNode;
  disabled?: boolean;
  color?: ThemeColors;
  fontSize?: FontSizes;
  mb?: number
}

export const Label = (props: Props & TypographyProps) => {
  return (
    <Typography
      weight="semi-bold"
      size={props.fontSize || 16}
      color={props.disabled ? "grey1" : "grey9"}
      mb={props.mb ? `${props.mb}px` : "10px"}
      {...props}
    >
      {props.children}
    </Typography>
  );
};
