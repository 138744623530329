// @ts-nocheck
import React from "react";
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import Button from "src/components/Core/Button";
import { TextInput } from "src/components/Forms/TextInput";
import { Table } from "src/components/Core/Table";
import { CampaignsTableContainer } from "./container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import getColumns from "../Columns";
import withWindowResize from "src/utils/hoc/withWindowResize";
import AddCampaignsModal from "../AddCampaignsModal";
import AddNewCampaignModal from "../AddNewCampaignModal";

export interface FilterProps {
  campaignTitle: string;
  page: number;
}

interface Props {
  allCampaigns: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  height?: number; // from withWindowResize HOC
  filters: FilterProps;
  showAddCampaigns: boolean;
  setShowAddCampaigns: (value: boolean) => void;
  showAddNewCampaign: boolean;
  setShowAddNewCampaign: (value: boolean) => void;
  refetchCampaign: () => void;
}

const MainCampaignsTable: React.FC<Props> = ({
  allCampaigns,
  loading,
  globalErrors,
  onFilter,
  height,
  filters,
  showAddCampaigns,
  setShowAddCampaigns,
  showAddNewCampaign,
  setShowAddNewCampaign,
  refetchCampaign,
}) => {
  let isAdmin = ((window as any)?.intercomSettings || {}).user_type;

  return <>
    <Typography size={20} weight="semi-bold" color="black" lineHeight="22px">
      Campaigns
    </Typography>

    <GlobalErrors errors={globalErrors} />

    <Box display="flex" justifyContent="space-between">
      <Box>
        <TextInput
          size="small"
          background="background"
          borderRadius={3}
          borderColor="grey5"
          value={filters?.campaignTitle}
          onChange={e => onFilter("campaignTitle", e.target.value)}
          suffix={<SearchOutlined />}
          placeholder="Search campaigns..."
        />
      </Box>

      <Box display="flex">
        {isAdmin === "Admin" && (
          <Button
            type="action"
            buttonSize="small"
            key="create"
            data-cy="create-hero-button"
            onClick={() => setShowAddNewCampaign(true)}
            style={{ marginRight: 10 }}
          >
            <PlusOutlined /> Add a new campaign
          </Button>
        )}

        <Button
          type="action"
          buttonSize="small"
          key="add-campaign"
          data-cy="create-hero-button"
          onClick={() => setShowAddCampaigns(true)}
        >
          <PlusOutlined /> Add an existing campaign
        </Button>
      </Box>
    </Box>

    <Box background="white" mt={10} mb={10} borderRadius="4px">
      <Loading spinning={loading}>
        <Table
          columns={getColumns()}
          rowKey="id"
          dataSource={allCampaigns?.campaigns || []}
          stripe={true}
          data-cy="table-CampaignsTable"
          pagination={{
            position: "bottom",
            current: allCampaigns?.currentPage,
            pageSize: allCampaigns?.perPage,
            total: allCampaigns?.totalEntries,
            onChange: page => onFilter("page", Number(page)),
          }}
          rowHover={true}
          scroll={{ y: height, x: "max-content" }}
        />
      </Loading>

      <AddCampaignsModal
        closeAddCampaignsModal={() => setShowAddCampaigns(false)}
        showAddCampaigns={showAddCampaigns}
        getCampaignForCampaignGroup={refetchCampaign}
      />

      <AddNewCampaignModal
        closeAddNewCampaignModal={() => setShowAddNewCampaign(false)}
        showAddNewCampaign={showAddNewCampaign}
        getCampaignForCampaignGroup={refetchCampaign}
      />
    </Box>
  </>;
};

const CampaignsTable = withWindowResize({ extraHeight: 350 })(
  MainCampaignsTable
);

export { CampaignsTable, CampaignsTableContainer };
