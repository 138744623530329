import React, { Component } from "react";
import styled from "styled-components";
import { InputNumber } from "formik-antd";

const FullWidthNumber = styled(InputNumber)`
  &&& {
    width: 100%;
  }
`;

export default class CurrencyInput extends Component {
  render() {
    const { withoutPrefix = false } = this.props;
    return (
      <FullWidthNumber
        formatter={value => {
          let finalValue = `${
            value.toString().substr(-1) === "."
              ? parseFloat(value) / 100.0 + "."
              : +(value / 100.0).toFixed(2)
          }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          if (withoutPrefix && (value !== "" || typeof value !== "undefined")) {
            return finalValue;
          } else {
            return `£ ${finalValue}`;
          }
        }}
        parser={value => {
          let parsed = value.replace(/\\s?|([^0-9.]*)/g, "");
          if (parsed) {
            return parsed.substr(-1) === "."
              ? (parseFloat(parsed) * 100).toString() + "."
              : parseFloat(parsed) * 100;
          } else {
            return null;
          }
        }}
        size="large"
        placeholder="1000"
        {...this.props}
      />
    );
  }
}
