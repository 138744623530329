// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import StyledInputNumber from "./StyledInputNumber";
import { NumberInputProps } from "../TextInputNumber/StyledInputNumber";
import get from "lodash/get";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";

interface Props {
  name?: string;
  value?: any;
  shouldShowError?: boolean;
  style?: any;
}

const FormikInputNumber: React.FC<Props & NumberInputProps> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur, setFieldValue },
      }) => (
        <>
          <StyledInputNumber
            {...props}
            {...field}
            touched={get(touched, field.name)}
            error={get(touched, field.name) && get(errors, field.name)}
            onBlur={e => {
              handleBlur(e);
            }}
            onChange={v => {
              setFieldValue(field.name, v);
            }}
            data-cy={`input-${field.name.replace(" ", "-")}`}
          />
          {props.shouldShowError && (
            <FieldErrorMessage
              errors={errors}
              touched={touched}
              mt={20}
              name={field.name}
            />
          )}
        </>
      )}
    </Field>
  );
};

FormikInputNumber.defaultProps = {
  shouldShowError: true,
};

export default FormikInputNumber;
