import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import { show } from "redux-modal";
import { PageHeader, Layout, message } from "antd";
import DashboardLayout from "src/scenes/layouts/DashboardLayout";
import Loading from "src/shared/Loading";
import ApproveAlert from "./ApproveAlert";
import SideMenu from "./SideMenu";
import CampaignDetails from "./CampaignDetails";
import UpdateCampaignStatus from "./UpdateCampaignStatus";
import SendEmail from "./SendEmail";
// import Analytics from "./Analytics";
import PostedContent from "./PostedContent";
import Creative from "./Creative";
import UpdateCampaign from "./UpdateCampaign";
import FindInfluencers from "./FindInfluencers";
import ManageInvitations from "./ManageInvitations";
import { ManageApplicantsContainer } from "./ManageApplicants"
import Links from "./Links";
import InvitationsTable from "./InvitationsTable";
import CampaignManage from "src/ducks/CampaignManage";
import InvitationManage from "src/ducks/InvitationManage";
import TableManager from "src/ducks/TableManager";
import { AccountList as $AccountList } from "src/ducks/DataList";
import { useAppDetails } from "src/AppProvider";
import AdHub from "./AdHub";
import { ReportingFormContainer } from "./Reporting";
import Analytics from "./Analytics";
import Auth from "src/ducks/Auth";

const { Content, Sider } = Layout;

const AccountTable = TableManager("CampaignAccountList");
const AccountList = $AccountList("CampaignAccountList");

const modules = [
  AccountTable.module(),
  AccountList.module(),
  CampaignManage.module(),
  InvitationManage.module(),
];

const BorderHeader = styled(PageHeader)`
  &&& {
    border-bottom: 1px solid #e8e8e8;

    .ant-page-header-heading-extra {
      display: flex;
    }

    .ant-page-header-heading-extra .ant-tag {
      line-height: 30px;
      margin-left: 8px;
    }
  }
`;

const CampaignShow = props => {
  const { collapsed } = useAppDetails();

  useEffect(() => {
    const {
      loadCampaign,
      match: {
        params: { id },
      },
    } = props;
    loadCampaign(id, {
      params: {
        expand: ["company"],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteDraft = () => {
    const { destroyCampaign, campaign, history } = props;
    destroyCampaign(campaign.id)
      .then(history.goBack)
      .catch(() => message.error("Could not delete campaign"));
  };

  const { history, campaign, loading, match, user } = props;

  return (
    <DashboardLayout title={campaign ? campaign.title : "Loading..."}>
      <Loading spinning={!campaign || loading}>
        {campaign && (
          <>
            <BorderHeader
              style={{
                top: "68px",
                width: `calc(100% - ${collapsed ? 80 : 200}px)`,
                overflow: "auto",
                position: "fixed",
                zIndex: 60,
                backgroundColor: 'white'
              }}
              extra={[
                <SendEmail key="email" />,
                campaign.status === "draft" ? (
                  <ConfirmDeleteIcon
                    text="Delete"
                    key="delete-icon"
                    onConfirm={deleteDraft}
                    placement="bottomRight"
                  />
                ) : ((campaign.status === "complete" || campaign.status === "active") && user?.super_admin) ? (
                  <UpdateCampaignStatus key="update" />
                ) : (
                  campaign.formattedStatus()
                ),
              ]}
              onBack={history.goBack}
              title={campaign.title}
            />
            <Layout>
              <Sider
                width={200}
                style={{
                  background: "#fff",
                  overflow: "auto",
                  height: "100vh",
                  marginTop: 69,
                  position: "fixed",
                  zIndex: 1,
                }}
              >
                <SideMenu />
              </Sider>
              <Content
                style={{
                  marginLeft: 200,
                  marginTop: 73,
                  height: "calc(100vh - 126px)",
                }}
              >
                {(campaign.status === "submitted" ||
                  campaign.status === "draft") && <ApproveAlert />}

                <Switch>
                  <Route
                    path={`${match.path}/update`}
                    exact
                    component={UpdateCampaign}
                  />
                  <Route
                    path={`${match.path}/creative`}
                    exact
                    component={Creative}
                  />
                  <Route
                    path={`${match.path}/find`}
                    exact
                    component={FindInfluencers}
                  />
                  <Route
                    path={`${match.path}/manage`}
                    exact
                    component={ManageInvitations}
                  />
                  <Route
                    path={`${match.path}/applicants`}
                    exact
                    component={ManageApplicantsContainer}
                  />
                  <Route
                    path={`${match.path}/analytics`}
                    exact
                    component={Analytics}
                  />
                  <Route
                    path={`${match.path}/content`}
                    exact
                    component={PostedContent}
                  />
                  <Route path={`${match.path}/links`} exact component={Links} />
                  <Route
                    path={`${match.path}/invitation`}
                    exact
                    component={InvitationsTable}
                  />

                  <Route
                    path={`${match.path}/ad-hub`}
                    exact
                    component={AdHub}
                  />

                  <Route
                    path={`${match.path}/reporting`}
                    exact
                    component={ReportingFormContainer}
                  />

                  <Route
                    path={`${match.path}`}
                    exact
                    component={CampaignDetails}
                  />
                </Switch>
              </Content>
            </Layout>
          </>
        )}
      </Loading>
    </DashboardLayout>
  );
};

const mapStateToProps = state => ({
  user: Auth.selectUser(state),
  campaign: CampaignManage.campaign.selectOne(state),
  loading: CampaignManage.campaign.selectGetPending(state),
});

const mapDispatchToProps = {
  loadCampaign: CampaignManage.campaign.get,
  destroyCampaign: CampaignManage.campaign.destroy,
  show,
};

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignShow))
)(modules);
