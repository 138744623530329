// @ts-nocheck
import React from "react";
import { AnalyseForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useGetTikTokUserBrandSafetyQuery } from "src/generated/ApolloComponents";
import { string, object } from "yup";
import { useBrandSafetyDetails } from "../../BrandSafetyProvider";

interface Props {
  step: number;
  goNext?: () => void;
}

interface AnalyseFormValues {
  tiktokUserUrl: string;
}

export const AnalyseFormContainer: React.FC<Props> = ({ step, goNext }) => {
  const { actions: analyseAction, tiktokUserUrl } = useBrandSafetyDetails();

  const { loading, error, refetch } = useGetTikTokUserBrandSafetyQuery({
    skip: true,
  });

  const AnalyseFormSchema = object().shape({
    tiktokUserUrl: string()
      .nullable()
      .url("Must be a valid URL (please include http://)")
      .required("Please enter tiktok url"),
  });

  let initialValues = {
    tiktokUserUrl: tiktokUserUrl || "",
  };

  const onSubmit = async (
    values: AnalyseFormValues,
    actions: FormikActions<AnalyseFormValues>
  ) => {
    try {
      analyseAction.setTiktokUserUrl(values?.tiktokUserUrl);
      analyseAction.setAnalyseLoading(true);

      const res = await refetch({
        tiktokUserUrl: values?.tiktokUserUrl,
      });

      if (res?.data?.getTikTokUserBrandSafety?.username) {
        analyseAction.setAnalyseLoading(false);
        analyseAction?.setAnalyseData(res?.data?.getTikTokUserBrandSafety);
        goNext && goNext();
      }
    } catch (e) {
      analyseAction.setAnalyseLoading(loading);
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <AnalyseForm
      initialValues={initialValues}
      analyseFormSchema={AnalyseFormSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      step={step}
    />
  );
};
