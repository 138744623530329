// @ts-nocheck
import React, { useState } from "react";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { FilterFormContainer } from "./AccountFilter";
import { Box } from "src/components/Core/Box";

const FilterAccountModal = styled(Modal)`
  &&& {
    border-radius: 2px;
    margin-left: 240px;
    margin-top: 45px;
    .ant-modal-close .anticon-close {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
  }
`;

interface Props {
  containerType?: string;
  showFilterAccountForm: boolean;
  closeAccountFilterModal: () => void;
  list?: any
}

const AccountFilterModal = ({
  containerType,
  closeAccountFilterModal,
  showFilterAccountForm,
  list,
}: Props) => {
  const [isExpand, setExpand] = useState(false);

  return (
    <FilterAccountModal
      title=""
      style={{
        maxWidth: 1090,
      }}
      bodyStyle={{
        padding: "15px 20px",
      }}
      width="100%"
      visible={showFilterAccountForm}
      onCancel={closeAccountFilterModal}
      footer={null}
      // closeIcon={<Icon type="close" />}
      maskClosable={false}
    >
      <Box
        background="#FFFFFF"
        height={60}
        width={60}
        textAlign="center"
        borderRadius={50}
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
        fontSize={16}
        p={20}
        onClick={() => closeAccountFilterModal()}
        style={{
          cursor: "pointer",
          zIndex: 99999,
        }}
        mt="-43px"
        ml="-43px"
      >
        <CloseCircleOutlined
          style={{
            fontSize: "20px",
            background: "#E8E8E8",
            borderRadius: "50%",
            color: "#FFFFFF",
          }} />
      </Box>

      <FilterFormContainer
        list={list}
        containerType={containerType}
        closeAccountFilterModal={closeAccountFilterModal}
        isExpand={isExpand}
        setExpand={value => setExpand(value)}
      />
    </FilterAccountModal>
  );
};

export default AccountFilterModal;
