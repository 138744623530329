// @ts-nocheck
import ApolloClient from "apollo-boost";
import { history } from "../store/fanbytesStore";

const uri = process.env.REACT_APP_GRAPHQL_URL;

const client = new ApolloClient({
  uri,
  request: operation => {
    const token = localStorage.getItem("authToken");
    const opName = operation.operationName;
    operation.setContext({
      headers: {
        authorization: token ? `${token}` : "",
      },
      uri: uri + `?operationName=${opName}`,
    });
  },
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors) {
      // Check whethere there is UNAUTHENTICATED error, this mainly comes when token is invalid, navigate user to login
      const errors = (graphQLErrors || []).filter(
        ({ extensions = {} }) => extensions.code === "UNAUTHENTICATED"
      );
      if (errors.length > 0) {
        localStorage.removeItem("authToken");
        history.push("/");
      }
    }
  },
});

export default client;
