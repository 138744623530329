// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Link } from "react-router-dom";
import compact from "lodash/compact";
import moment from "moment";
import formatCurrency from "src/utils/formatCurrency";
import { formattedStatus } from "src/components/Modules/Campaign/utils";

const getColumns = () => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      render: (text, row) => (
        <Link to={`/campaigns/${row?.id}`}>
          <Typography size={12} cursor="pointer" mb={0}>
            {row?.title ? row?.title : "-"}
          </Typography>
        </Link>
      ),
    },
    {
      title: "Platforms",
      dataIndex: "distributionPlatforms",
      key: "distributionPlatforms",
      width: 120,
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.distributionPlatforms && row?.distributionPlatforms.join(", ")}
        </Typography>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (text, row) => formattedStatus(row?.status),
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 130,
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.startDate ? moment(row?.startDate).format("Do MMM YYYY") : ""}
        </Typography>
      ),
    },
    {
      title: "Budget",
      dataIndex: "budgetPennies",
      key: "budgetPennies",
      width: 130,
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.budgetPennies ? formatCurrency(row?.budgetPennies) : ""}
        </Typography>
      ),
    },
  ];

  return compact(columns);
};

export default getColumns;
