// @ts-nocheck
import React, { useState } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import Button from "src/components/Core/Button";
import { Card } from "antd";
import Container from "src/shared/Container";
import { TextInput } from "src/components/Forms/TextInput";
import { Table } from "src/components/Core/Table";
// import { AdHubTableContainer } from "./container";
import { AdHubTableContainer } from "src/components/Modules/Campaign/AdHub/container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import getColumns from "./Columns";
import withWindowResize from "src/utils/hoc/withWindowResize";
import AdUploadModal from "./AdUploadModal";
import AuthoriseAdPlatformsModal from "./AuthoriseAdPlatformsModal";

export interface FilterProps {
  accountName: string;
  page: number;
}

interface Props {
  data: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  height?: number; // from withWindowResize HOC
  filters: FilterProps;
  showAdUpload: boolean;
  setShowAdUpload: (value: boolean) => void;
  showAuthoriseAdPlatform: boolean;
  setShowAuthoriseAdPlatform: (value: boolean) => void;
  refetchAdHubMedia: () => void;
  campaignId: number;
}

const MainAdHubTable: React.FC<Props> = ({
  data,
  loading,
  globalErrors,
  onFilter,
  height,
  filters,
  showAuthoriseAdPlatform,
  setShowAuthoriseAdPlatform,
  showAdUpload,
  setShowAdUpload,
  refetchAdHubMedia,
  campaignId,
}) => {
  const [draftId, setDraftId] = useState();

  const advertisers = (data?.advertisers || []).map(item => {
    let container = {};

    container["label"] = `${item?.name} - ${item?.id}`;
    container["value"] = item?.id.toString();

    return container;
  });

  return (
    <Container>
      <Card style={{ marginBottom: 24, marginTop: 24 }}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography
              size={20}
              weight="semi-bold"
              color="black"
              lineHeight="0px"
            >
              Ad Hub
            </Typography>

            <Typography size={16} color="black1" lspace={1.16}>
              Turn your creatives into ads
            </Typography>
          </Box>

          <Button
            type="action"
            buttonSize="small"
            data-cy="submit-button"
            onClick={() => setShowAuthoriseAdPlatform(true)}
          >
            Authorise Ad platforms
          </Button>
        </Box>

        <GlobalErrors errors={globalErrors} />

        <Box>
          <TextInput
            size="small"
            background="background"
            borderRadius={3}
            borderColor="grey5"
            value={filters?.accountName}
            onChange={e => onFilter("accountName", e.target.value)}
            suffix={<SearchOutlined />}
            placeholder="Search Account"
          />
        </Box>

        <Box background="white" mt={10} mb={10} borderRadius="4px">
          <Loading spinning={loading}>
            <Table
              columns={getColumns(setShowAdUpload, setDraftId)}
              rowKey="draftId"
              dataSource={data?.mediaList || []}
              stripe={true}
              data-cy="table-AdHubTable"
              rowHover={true}
              scroll={{ y: height, x: "max-content" }}
            />
          </Loading>

          <AdUploadModal
            closeAdUploadModal={() => setShowAdUpload(false)}
            showAdUpload={showAdUpload}
            draftId={draftId}
            authorised={data?.isAuthorised}
            campaignId={campaignId}
            advertisers={advertisers || []}
          />

          <AuthoriseAdPlatformsModal
            closeAuthoriseAdPlatformModal={() =>
              setShowAuthoriseAdPlatform(false)
            }
            showAuthoriseAdPlatform={showAuthoriseAdPlatform}
            authorised={data?.isAuthorised}
            campaignId={campaignId}
          />
        </Box>
      </Card>
    </Container>
  );
};

const AdHubTable = withWindowResize({ extraHeight: 350 })(MainAdHubTable);

export { AdHubTable, AdHubTableContainer };
