// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";

import {
  EyeFilled,
  MinusCircleOutlined,
  PieChartOutlined,
  RightCircleFilled,
  RiseOutlined,
} from '@ant-design/icons';

import { Divider, Tooltip, Popconfirm } from "antd";
import styled from "styled-components";
import numeral from "numeral";
import { LinkButton } from "src/components/Core/LinkButton";
import { Typography } from "src/components/Core/Typography";

const AccountDivider = styled(Divider)`
  &&& {
    margin: -5px 0px 0px 0px !important;
  }
`;

const StopTracking = styled(LinkButton)`
  && {
    opacity: 0.3 !important;
  }
  &:hover {
    opacity: 1 !important;
  }
`;

interface Props {
  account: any;
  index: number;
  isActive: boolean;
  setIndex: (value: number) => void;
  removeTrackAccount: Function;
}

const AccountItem: React.FC<Props> = ({
  account,
  index,
  isActive,
  setIndex,
  removeTrackAccount,
}) => {
  return (
    <Box
      onClick={() => setIndex(index)}
      style={{ cursor: "pointer", overflow: "hidden" }}
      ml={10}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p={12}
        alignItems="center"
      >
        <Box display="inline-flex">
          <Box>
            <Typography
              size={18}
              color={isActive ? "blue" : "black"}
              mb={0}
              cursor="pointer"
            >
              @{account?.accountName}
            </Typography>

            <Box mt="5px">
              <Tooltip title="Avg. views">
                <Box display="inline-flex" mr="10px">
                  <EyeFilled style={{ color: "#40A9FF", fontSize: "14px" }} />

                  <Typography
                    size={14}
                    color="black2"
                    mb="3px"
                    ml="5px"
                    lineHeight="16px"
                  >
                    {numeral(account?.averageViews).format("0a")}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip title="Followers">
                <Box display="inline-flex" mr="10px">
                  <RiseOutlined style={{ color: "#40A9FF", fontSize: "14px" }} />

                  <Typography
                    size={14}
                    color="black2"
                    mb="3px"
                    ml="5px"
                    lineHeight="16px"
                  >
                    {numeral(account?.followers).format("0a")}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip title="Eng. rate">
                <Box display="inline-flex" mr="10px">
                  <PieChartOutlined style={{ color: "#40A9FF", fontSize: "14px" }} />

                  <Typography
                    size={14}
                    color="black2"
                    mb="3px"
                    ml="5px"
                    lineHeight="16px"
                  >
                    {numeral(account?.engagementRate).format("0,0.00") + "%"}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
            <Box mt="-5px" ml="-14px">
              <Popconfirm
                data-cy="status-container"
                title="Are you sure you want to stop tracking this account?"
                trigger="click"
                okText="Yes"
                cancelText="No"
                onConfirm={e => removeTrackAccount(account?.accountName)}
                onCancel={(e: any) => e.preventDefault()}
              >
                <StopTracking color="red6" fontSize={12} weight="semi-bold">
                  <MinusCircleOutlined /> Stop tracking
                </StopTracking>
              </Popconfirm>
            </Box>
          </Box>
        </Box>

        <RightCircleFilled
          style={{
            color: isActive ? "#40A9FF" : "#F5F5F5",
            marginTop: "5px",
            marginLeft: "5px",
            fontSize: "21px",
          }} />
      </Box>
      <AccountDivider />
    </Box>
  );
};

export default AccountItem;
