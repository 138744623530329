// @ts-nocheck
import React from "react";
import Button from "src/components/Core/Button";
import { PageHeader } from "antd";
import { connect } from "react-redux";
import { show } from "redux-modal";

interface Props {
  title: string;
  openAccountModal: () => void;
  show: Function;
  contentType: string;
  createList: () => void;
  listFlag: boolean;
}

const HeroActions: React.FC<Props> = ({
  title,
  openAccountModal,
  show,
  contentType,
  createList,
  listFlag
}) => {
  return (
    <PageHeader
      title={title}
      subTitle={``}
      extra={[
        contentType === "list" && (
          <Button
            buttonSize="small"
            key="columns"
            onClick={() => show("ColumnsModal")}
            style={{ boxShadow: "none" }}
          >
            Manage Columns
          </Button>
        ),
        <Button
          type="action"
          buttonSize="small"
          key="create-list"
          data-cy="create-hero-button"
          onClick={createList}
          style={{ boxShadow: "none" }}
          disabled={listFlag}
        >
          Create new List
        </Button>,
        <Button
          type="action"
          buttonSize="small"
          key="create"
          data-cy="create-hero-button"
          onClick={openAccountModal}
          style={{ boxShadow: "none" }}
        >
          Create new Account
        </Button>,
      ]}
    />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  show,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroActions);
