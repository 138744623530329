// @ts-nocheck
import React from "react";
import { useRouteMatch } from "react-router";
import { Box } from "src/components/Core/Box";
import { InfluencerSelectionContainer } from "./InfluencerSelection";

interface Props {}

const ReviewInfluencers: React.FC<Props> = () => {
  const match: any = useRouteMatch();
  const params: any = match?.params;

  const campaignId: any = params["id"];

  return (
    <Box p={20}>
      <InfluencerSelectionContainer campaignId={campaignId} />
    </Box>
  );
};

export default ReviewInfluencers;
