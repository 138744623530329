import React, { Component } from "react"
import styled from "styled-components"
import FormField from "../FormField"
import { InputNumber } from "formik-antd"

const FullWidthNumber = styled(InputNumber)`
  &&& {
    width: 100%;
  }
`

export default class EstimatedViews extends Component {
  render() {
    const { name, ...rest } = this.props

    return (
      <FormField name={name || "estimated_views"} label="Estimated views">
        <FullWidthNumber
          data-cy="invitation-estimated-views"
          size="large"
          placeholder="0"
          {...rest}
        />
      </FormField>
    )
  }
}
