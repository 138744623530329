// @ts-nocheck
export function showEngagement(campaignData) {
  return (
    ((campaignData?.campaignPlatforms || []).length === 1 &&
      campaignData?.campaignPlatforms?.[0] !== "Snapchat") ||
    ((campaignData?.campaignPlatforms || []).length > 1 &&
      (campaignData?.campaignPlatforms || []).indexOf("Snapchat") > -1)
  );
}

export function showCTR(campaignData) {
  return (
    ((campaignData?.campaignPlatforms || []).length === 1 &&
      campaignData?.campaignPlatforms?.[0] === "Snapchat") ||
    ((campaignData?.campaignPlatforms || []).length > 1 &&
      (campaignData?.campaignPlatforms || []).indexOf("Snapchat") > -1)
  );
}
