// @ts-nocheck
export const getFilterData = (data: any) => {
  let filters = {} as any

  filters['page'] = Number(data.page) || 1

  if(data?.tags) {
    filters['tags'] = data.tags.map(tagId => Number(tagId))
  }

  if(data?.searchInput) {
    filters['searchInput'] = data.searchInput
  }

  if(window.location.pathname === '/my-campaigns') {
    filters['isApplied'] = true
  }

  return filters
}

export const getInitialValues = (data: any) => {
  let initialValues = {}

  if(data?.tags) {
    initialValues['tags'] = data.tags
  }

  if(data?.searchInput) {
    initialValues['searchInput'] = data.searchInput
  }

  return initialValues
}
