import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography } from "antd";
import find from "lodash/find";
import FormField from "../FormField";
import { connect as FormikConnect } from "formik";
import withModules from "src/utils/hoc/withModules";
import SearchSelect from "src/shared/SearchSelect";
import { Input } from "formik-antd";
import { InfluencerList as $InfluencerList } from "src/ducks/DataList";
import { Box } from "src/components/Core/Box";

const InfluencerList = $InfluencerList("AccountInfluencerList");

const modules = [InfluencerList.module()];

export class Influencer extends Component {
  toggleCreate = () => {
    const {
      formik: { setFieldValue, values },
    } = this.props;

    if (!values.createInfluencer) {
      setFieldValue("influencer", undefined);
    }

    setFieldValue("createInfluencer", !values.createInfluencer);
  };

  render() {
    const {
      loadInfluencers,
      withCreate,
      formik: { values, setFieldValue },
      influencers,
    } = this.props;

    return (
      <>
        {values.createInfluencer ? (
          [
            <FormField
              data-cy="account-influencer-name"
              key="name"
              name={"create_influencer.name"}
              label="Influencer Name"
            >
              <Input size="large" placeholder="e.g. zoella" />
            </FormField>,

            <FormField
              key="email"
              data-cy="account-influencer-email"
              name={"create_influencer.email"}
              label="Influencer Email"
            >
              <Input size="large" placeholder="e.g. zoella@gmail.com" />
            </FormField>,

            <FormField
              key="payout_details.paypal_email"
              data-cy="account-influencer-paypal-email"
              style={{ marginBottom: 5 }}
              name={"create_influencer.payout_details.paypal_email"}
              label="Influencer Paypal Email"
            >
              <Input size="large" placeholder="e.g. example@gmail.com" />
            </FormField>,
          ]
        ) : (
          <FormField
            style={withCreate && { marginBottom: 5 }}
            name={this.props.name || "influencer"}
            label="Influencer"
          >
            <SearchSelect
              data-cy="account-influencer"
              loadData={value =>
                loadInfluencers({ search: value }).then(
                  res => res.value.data.data
                )
              }
              optionGenerator={value => ({
                value: value.id,
                label: value.name,
              })}
              size="large"
              placeholder="Select..."
              onChange={value => {
                setFieldValue(this.props.name || "influencer", value);
                if (this.props.onInfluencerChange) {
                  let item = find(influencers, function(obj) {
                    return obj._fields.id === value;
                  });

                  this.props.onInfluencerChange(item._fields);
                }
              }}
            />
          </FormField>
        )}

        {withCreate && (
          <Typography style={{ marginBottom: 24 }}>
            Or{" "}
            <Box
              color="blue"
              style={{ cursor: "pointer" }}
              onClick={this.toggleCreate}
            >
              create a new influencer
            </Box>
          </Typography>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  influencers: InfluencerList.selectMany(state),
});

const mapDispatchToProps = {
  loadInfluencers: InfluencerList.loadMany,
};

export default withModules(
  FormikConnect(connect(mapStateToProps, mapDispatchToProps)(Influencer))
)(modules);
