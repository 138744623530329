// @ts-nocheck
import React, { useEffect, useState } from "react";
import { CampaignsList, FilterProps } from ".";
import  debounce  from "lodash/debounce";
import { useGetCampaignsByInfluencerIdQuery } from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useInfluencerDetails } from "../InfluencerProfileProvider";

type Props = {};

export const CampaignsListContainer: React.FC<Props> = () => {
  const { influencerId } = useInfluencerDetails();

  const [filters, setFilters] = useState({
    influencerId: influencerId,
  } as FilterProps);

  const campaigns = useGetCampaignsByInfluencerIdQuery({
    variables: filters,
  });

  let allCampaings = campaigns?.data?.getCampaignsByInfluencerId || [];

  allCampaings = allCampaings.map((item: any, index) => {
    const newItem = { ...item };
    newItem.index = newItem.id + "-" + index;
    return newItem;
  });

  const onFilterChange = async filters => {
    try {
      campaigns.refetch(filters);
    } catch (e) {}
  };

  let debouncedFilterChange = debounce(onFilterChange, 500);

  useEffect(() => {
    let updatedFilters = { ...filters };

    debouncedFilterChange(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const globalErrors = useMapGraphQLErrorsMessages(campaigns?.error);

  return (
    <CampaignsList
      allCampaings={allCampaings}
      globalErrors={globalErrors}
      loading={campaigns?.loading}
      onFilter={(key, value) => {
        filters[key] = value;
        setFilters({ ...filters });
      }}
      filters={filters}
    />
  );
};
