import { countries } from "country-data";
import uniqueMap from "src/utils/uniqueMap";

export const types = [
  { label: "Snapchat", value: "Snapchat" },
  { label: "Instagram", value: "Instagram" },
  { label: "TikTok", value: "TikTok" },
  { label: "YouTube", value: "YouTube" },
];

export const statuses = [
  { label: "Not Verified", value: "Not Verified" },
  // { label: "Semi Verified", value: "Semi Verified" },
  { label: "Verified", value: "Verified" },
];

export const formats = [
  { label: "Page", value: "Page" },
  { label: "Human", value: "Human" },
];

export const genders = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const ages = [
  { label: "13 - 17", value: "13 - 17" },
  { label: "18 - 24", value: "18 - 24" },
  { label: "25 - 34", value: "25 - 34" },
  { label: "35 - 55", value: "35 - 55" },
  { label: "55+", value: "55+" },
];

export const cpm = {
  Snapchat: {
    Human: 800,
    Page: 40,
  },
  Instagram: {
    Human: 800,
    Page: 40,
  },
  TikTok: {
    Human: 400,
    Page: 40,
  },
  YouTube: {
    Human: 400,
    Page: 40,
  },
};

const allLocations = uniqueMap(
  countries.all
    .filter(val => val.alpha2 !== "UK")
    .map(c => {
      return {
        value: c.alpha2,
        label: c.name,
      };
    }),
  "value"
);

const otherLocation = { value: "other", label: "Other" };

export const locations = [...allLocations, otherLocation];

export const actives = [
  { label: "Active", value: true },
  { label: "In-active", value: false },
];

export const breakdowns = [
  { label: "Age Breakdown", value: "Age Breakdown" },
  { label: "Gender Breakdown", value: "Gender Breakdown" },
  { label: "Location Breakdown", value: "Location Breakdown" },
];

export const influencer_flags = [
  { label: "Mature content", value: "Mature content" },
  { label: "Mature language", value: "Mature language" },
];

export const colors = ["#2987D9", "#3AF3B1", "#50D4F2", "#F2C063", "#D97652"];

export const platform_types = [
  // { label: "Bytesights", value: "Bytesights" },
  // { label: "Upfluencer", value: "Upfluence" },
  { label: "Fanbytes", value: "Fanbytes" },
];

export const bytesight_search_criteria = [
  { label: "Video Captions", value: "VideoCaptions" },
  { label: "Name", value: "Name" },
  { label: "Username", value: "Username" },
  { label: "Bio", value: "Bio" },
  { label: "All", value: "All" },
];

export const fanbytes_search_criteria = [
  { label: "Previous Captions", value: "PreviousCaptions" },
  { label: "Account Name", value: "AccountName" },
  { label: "Account Bio", value: "AccountBio" },
];

export const status = [
  { label: "Managed", value: "Managed" },
  { label: "Verified", value: "Verified" },
  { label: "Untagged", value: "Untagged" },
  { label: "No data", value: "NoData" },
];

export const campaign_completed_month = [
  { label: "Last 1 month", value: "Last1Month" },
  { label: "Last 3 months", value: "Last3Month" },
  { label: "Ever", value: "Ever" },
];

export const fees =  [
  { label: "£ 50 - 100", value: "50 - 100" },
  { label: "£ 100 - 200", value: "100 - 200" },
  { label: "£ 200 - 300", value: "200 - 300" },
  { label: "£ 300+", value: "300+" },
]

export const profileURLHelpers = {
  Snapchat: "https://www.snapchat.com/add/",
  Instagram: "https://www.instagram.com/",
  TikTok: "https://www.tiktok.com/@",
  YouTube: "https://www.youtube.com/",
}

export const account_types = [
  { label: 'Admins (Internal)', value: 'admins' },
  { label: 'Clients (External)', value: 'clients' }
]

export const milestone_types = [
  { label: 'Comments', value: 'comments' },
  { label: 'Views', value: 'views' },
  { label: 'Engagement', value: 'engagement' },
  { label: 'Posts', value: 'posts' },
]
