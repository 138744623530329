// @ts-nocheck
import React, { ReactNode } from "react";
import { Label } from "../Label";
import { Box, BoxProps } from "src/components/Core/Box";
import styled from "styled-components";
import { ThemeColors, FontSizes } from "src/utils/theme";

interface Props {
  label?: string;
  children?: ReactNode;
  disabled?: boolean;
  extra?: ReactNode;
  fieldDisabled?: boolean;
  color?: ThemeColors;
  isBeside?: boolean;
  id?: string;
  fontSize?: FontSizes;
  labelBottom?: number;
}

const FormField = styled(Box as any)`
  && {
    pointer-events: ${props =>
      props.fieldDisabled ? "none" : "all"} !important;
    opacity: ${props => (props.fieldDisabled ? 0.2 : 1)} !important;
  }
`;

export const Field = ({
  label,
  children,
  disabled,
  extra,
  fieldDisabled,
  color,
  isBeside,
  fontSize,
  labelBottom,
  ...rest
}: Props & BoxProps) => {
  return (
    <FormField width="100%" fieldDisabled={fieldDisabled} {...rest}>
      <Box
        display="flex"
        justifyContent={!isBeside ? "space-between" : "none"}
        alignItems="center"
      >
        {label && (
          <Label disabled={disabled} color={color} fontSize={fontSize} mb={labelBottom}>
            {label}
          </Label>
        )}
        {extra && extra}
      </Box>

      {children}
    </FormField>
  );
};
