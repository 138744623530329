// @ts-nocheck
import React from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Radio } from "antd";
import { AmountRadio } from "../../Wrapper";
import { Box } from "src/components/Core/Box";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import ClearButton from "./ClearButton";

interface Props {
  formProps: FormikProps<any>;
  collapsed: any;
  clearFilterKey: (key: string) => void;
}

const Hearts: React.FC<Props> = ({ formProps, collapsed, clearFilterKey }) => {
  const fieldValue = formProps?.values;

  let isVisible = false;
  let backgroundColor = "#FFFFFF";

  if (fieldValue?.hearts !== 0) {
    isVisible = true;
    backgroundColor = "#FAFAFA";
  }

  return (
    <Box background={backgroundColor} padding="5px">
      <Field
        label="Avg. Hearts/Likes:"
        mb={20}
        extra={
          <ClearButton
            isVisible={isVisible}
            buttonClick={() => {
              formProps?.setFieldValue("hearts", 0);
              formProps?.setFieldValue("heartsIsgreaterThan", true);
              clearFilterKey("hearts");
            }}
          />
        }
      >
        <Box>
          <AmountRadio>
            <Radio.Button
              value={false}
              onChange={value => {
                if (fieldValue?.hearts || fieldValue?.hearts === 0) {
                  formProps?.setFieldValue("heartsIsgreaterThan", false);
                }
              }}
              checked={fieldValue?.heartsIsgreaterThan === false}
            >
              <LeftOutlined />
            </Radio.Button>
            <Radio.Button
              value={true}
              onChange={value => {
                if (fieldValue?.hearts || fieldValue?.hearts === 0) {
                  formProps?.setFieldValue("heartsIsgreaterThan", true);
                }
              }}
              checked={fieldValue?.heartsIsgreaterThan === true}
            >
              <RightOutlined />
            </Radio.Button>
          </AmountRadio>

          <FormikInputNumber
            parser={value => {
              let parsed = value.replace(/\[0-9]/g, "");
              if (parsed) {
                return parsed.match(/\d+/g) ? parseInt(parsed) : "";
              } else {
                return null;
              }
            }}
            name="hearts"
            borderRadius={3}
            borderColor="grey5"
            size="small"
            value={fieldValue?.hearts}
            style={{ marginLeft: 5, width: `${collapsed ? 72 : 105}px` }}
          />
        </Box>
      </Field>
    </Box>
  );
};

export default Hearts;
