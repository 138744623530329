// @ts-nocheck
import React, { useEffect, useState, useRef, useMemo } from "react";
import debounce from "lodash/debounce";
import { Track } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetDiscoverTrackingInfluencersFromAdminQuery,
  useRemoveDiscoverTrackingInfluencerFromAdminMutation,
} from "src/generated/ApolloComponents";

type Props = {
  activeKey: string;
};

export const TrackContainer: React.FC<Props> = ({ activeKey }) => {
  const [filters, setFilters] = useState({
    accountName: "",
  });

  const [accounts, setAccounts] = useState([] as any);

  const [
    removeDiscoverTrackingInfluencerFromAdminMutation,
    remove,
  ] = useRemoveDiscoverTrackingInfluencerFromAdminMutation();

  const {
    data,
    loading,
    error,
    refetch,
  } = useGetDiscoverTrackingInfluencersFromAdminQuery({
    fetchPolicy: "network-only",
  });

  let allAccounts = useMemo(() => data?.getDiscoverTrackingInfluencersFromAdmin || [],[data]);

  useEffect(() => {
    if (activeKey === "2") {
      refetch();
    }
  }, [activeKey, refetch]);

  useEffect(() => {
    if (allAccounts.length > 0) {
      setAccounts(allAccounts.reverse());
    }
  }, [allAccounts]);

  const onFilterChange = async (filters, accounts, allAccounts) => {
    try {
      if (filters?.accountName !== "") {
        const filterAccounts = accounts.filter(c => {
          return (
            c?.accountName
              .toLowerCase()
              .indexOf(filters?.accountName.toLowerCase()) > -1
          );
        });

        setAccounts(filterAccounts);
      } else {
        if (allAccounts.length > 0) {
          setAccounts(allAccounts.reverse());
        }
      }
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onFilterChange, 1000)).current;

  useEffect(() => {
    let updatedFilters = { ...filters };
    debouncedFilterChange(updatedFilters, accounts, allAccounts);
  }, [filters, accounts, allAccounts, debouncedFilterChange]);

  const onRemoveTrack = async accountName => {
    try {
      const res = await removeDiscoverTrackingInfluencerFromAdminMutation({
        variables: {
          accountName: accountName,
        },
      });
      if (res.data?.removeDiscoverTrackingInfluencerFromAdmin?.id) {
        refetch();
      }
    } catch (e) {}
  };

  const globalErrors = useMapGraphQLErrorsMessages(error || remove?.error);

  return (
    <Track
      accounts={accounts}
      globalErrors={globalErrors}
      loading={loading || remove?.loading}
      onFilter={(key, value) => {
        filters[key] = value;
        setFilters({ ...filters });
      }}
      filters={filters}
      onRemoveTrack={value => onRemoveTrack(value)}
    />
  );
};
