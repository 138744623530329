// @ts-nocheck
import { string, object, array, boolean } from "yup";
import isEmpty from "lodash/isEmpty";

export const ProfileDataSchema = object().shape({
  isAccountScreenshotOutdated: boolean(),

  platformType: string(),

  type: string(),

  age: string()
    .nullable()
    .test("age", "Please select age", function(value) {
      const { platformType } = this.parent;
      if (platformType !== "TikTok") return value != null;
      return true;
    }),

  gender: string().required("Please select gender"),

  location: string().required("Please select location"),

  screenshots: array()
    .of(
      object().shape({
        uploadUrl: string()
          .nullable()
          .required(),
        uploadType: string()
          .nullable()
          .required(),
      })
    )
    .test("screenshots", "You must upload at least one screenshot", function(
      value
    ) {
      const { type, screenshots } = this.parent;
      if (type !== "youtube" && isEmpty(screenshots)) return false;
      return true;
    }),

  ageScreenshot: object()
    .nullable()
    .when("isAccountScreenshotOutdated", {
      is: true,
      then: object()
        .shape({
          uploadUrl: string()
            .nullable()
            .required(),
          uploadType: string()
            .nullable()
            .required(),
        })
        .test(
          "agescreenshots",
          "Please upload age anyaltics screenshot",
          function(value) {
            const {
              type,
              platformType,
              ageScreenshot,
              isAccountScreenshotOutdated,
            } = this.parent;
            if (
              type === "youtube" &&
              isAccountScreenshotOutdated &&
              platformType !== "TikTok" &&
              isEmpty(ageScreenshot)
            )
              return false;
            return true;
          }
        ),
    }),

  genderScreenshot: object()
    .nullable()
    .when("isAccountScreenshotOutdated", {
      is: true,
      then: object()
        .shape({
          uploadUrl: string()
            .nullable()
            .required(),
          uploadType: string()
            .nullable()
            .required(),
        })
        .test(
          "genderScreenshot",
          "Please upload gender anyaltics screenshot",
          function(value) {
            const {
              type,
              genderScreenshot,
              isAccountScreenshotOutdated,
            } = this.parent;
            if (
              type === "youtube" &&
              isAccountScreenshotOutdated &&
              isEmpty(genderScreenshot)
            )
              return false;
            return true;
          }
        ),
    }),

  locationScreenshot: object()
    .nullable()
    .when("isAccountScreenshotOutdated", {
      is: true,
      then: object()
        .shape({
          uploadUrl: string()
            .nullable()
            .required(),
          uploadType: string()
            .nullable()
            .required(),
        })
        .test(
          "locationScreenshot",
          "Please upload location anyaltics screenshot",
          function(value) {
            const {
              type,
              locationScreenshot,
              isAccountScreenshotOutdated,
            } = this.parent;
            if (
              type === "youtube" &&
              isAccountScreenshotOutdated &&
              isEmpty(locationScreenshot)
            )
              return false;
            return true;
          }
        ),
    }),

});
