import React, { Component } from "react";
import styled from "styled-components";
import FormField from "../FormField";
import { InputNumber } from "formik-antd";
const FullWidthNumber = styled(InputNumber)`
  &&& {
    width: 100%;
  }
`;

export default class InviteLimit extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "influencer_invite_limit"}
        label="Invite Limit (Amount of Influencers)"
        help="The amount of Influencers the brand will be able to accept"
      >
        <FullWidthNumber data-cy="invite-limit" size="large" placeholder="0" />
      </FormField>
    );
  }
}
