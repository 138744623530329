import { Model } from "redux-orm";
import moment from "moment";
import numeral from "numeral";

class AccountPost extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const parsedData = {
      ...data,
    };

    return this.upsert(parsedData);
  }

  timePostedAgo() {
    return this.post_datetime
      ? "Posted " + moment(this.post_datetime).fromNow()
      : null;
  }

  formattedDescription() {
    return this.metadata.description;
  }

  formattedViews() {
    return (this.type_string =
      "youtube" && typeof this.metadata.view_count != "undefined"
        ? numeral(this.metadata.view_count).format("0a")
        : null);
  }

  formattedComments() {
    return typeof this.metadata.comment_count != "undefined"
    ? numeral(this.metadata.comment_count).format("0a")
    : null;
  }

  formattedLikes() {
    return typeof this.metadata.like_count != "undefined"
    ? numeral(this.metadata.like_count).format("0a")
    : null;
  }


  formattedPostedAt() {
    return this.post_datetime
      ? "Posted " + moment(this.post_datetime).format("Do MMM YYYY, h:mm a")
      : "";
  }

  formattedTiktokWebVideoUrl() {
    return typeof this.metadata.web_video_url != "undefined"
    ? this.metadata.web_video_url
    : null;
  }

}
AccountPost.modelName = "AccountPost";

// Declare your related fields.
AccountPost.fields = {};

export default AccountPost;
