import React from "react"
import { Input, Switch } from "formik-antd"

export default {
  name: {
    input: () => <Input size="large" placeholder="e.g. Joe" />,
    label: "Name"
  },
  email: {
    input: () => <Input size="large" placeholder="e.g. joe@bloggs.com" />,
    label: "Email"
  },
  managed: {
    input: () => <Switch />,
    label: "Manage Influencer"
  }
}
