// @ts-nocheck
import React from 'react'
import { Box } from 'src/components/Core/Box'
import { Typography } from 'src/components/Core/Typography'

const DosDonts = ({head, points}) => {
  return(
    <Box px={40}>
      <Typography fontSize={22} color="black1" mt={30}>Things you <u>{head}</u> do:</Typography>
      <ol>
        {points?.map((point, index) =>
          <Typography key={index}>
            <li>
              {point}
            </li>
          </Typography>)
        }
      </ol>
    </Box>
  )
}

export default DosDonts;
