// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";

interface Props {}

const TikTokUrl: React.FC<Props> = () => {
  return (
    <Field label="TikTok URL:" mb={26}>
      <FormikInput
        name="tiktokUrl"
        size="middle"
        placeholder="Tiktok.com/..."
        borderRadius={3}
        borderColor="grey8"
      />
    </Field>
  );
};

export default TikTokUrl;
