// @ts-nocheck
import React, { Fragment } from "react";
import { FormikProps } from "formik";
import Button from "src/components/Core/Button";
import { Box } from "src/components/Core/Box";
import { Field } from "src/components/Forms/Field";
import { breakdowns } from "src/constants/account";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  closeManualDataModal: () => void;
  setStep: (value: number) => void;
  formProps?: FormikProps<any>;
}

const FirstStep: React.FC<Props> = ({ closeManualDataModal, setStep }) => {
  return (
    <Fragment>
      <Box p={25}>
        <Field label="What kind of data do you want to input:" mb={26}>
          <FormikSelect
            name="type"
            data={breakdowns}
            local
            background="background"
            borderRadius={2}
            borderColor="grey5"
            size="middle"
          />
        </Field>
      </Box>

      <Box
        alignItems="right"
        textAlign="right"
        display="inherit"
        mt={50}
        borderTop="1px solid #E2E2E2"
        p={10}
      >
        <Button
          buttonSize="middle"
          data-cy="first-cancel-button"
          onClick={() => closeManualDataModal()}
          mr={20}
        >
          Cancel
        </Button>

        <Button
          type="action"
          buttonSize="middle"
          data-cy="first-next-button"
          onClick={() => setStep(2)}
        >
          Next
        </Button>
      </Box>
    </Fragment>
  );
};
export default FirstStep;
