// @ts-nocheck
import React from 'react'
import Account from './Account'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd';
import styled from 'styled-components'
import { Icon } from '@ant-design/compatible'

const StyledRow = styled(Row)`
  border-width: 1px 0px;
  border-color: #E5E5E5;
  border-style: solid;
`

const StyledCol = styled(Col)`
  display: flex !important;
  padding: 33px 30px !important;
  cursor: pointer;
`

const StyledIcon = styled(Icon)`
  font-size: 21px;
  margin-right: 10px;
  color: #1890FF !important;
  > svg {
    background: #E6F7FF;
    border-radius: 50%;
  }
`
interface Props {
  accounts: any
  selected: any
  setAccount: Function
}

const ConnectedAccounts = ({accounts, setAccount, selected}: Props) => {
  return(
    <StyledRow>
      {accounts.map(account =>
        <Account key={account.id} account={account} setAccount={setAccount} selected={selected} />)
      }
      <StyledCol span={12}>
        <StyledIcon type="plus-circle" />
        <Link to="/connect-accounts">Add another account</Link>
      </StyledCol>
    </StyledRow>
  )
}

export default ConnectedAccounts
