import React, { Component } from "react";
import styled from "styled-components";
import FormField from "../FormField";
import { InputNumber, Radio } from "formik-antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";

const AmountRadio = styled(Radio.Group)`
  &&& {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${props => props.theme.colors.blue} !important;
      color: ${props => props.theme.colors.white} !important;
    }
    .ant-radio-button-wrapper {
      height: 30px !important;
      padding: 0 7px !important;
    }
  }
`;

export default class CampaignCompleted extends Component {
  render() {
    return (
      <Row gutter={4}>
        <Col span={6}>
          <Box mt="-10px">
            <FormField
              name={"completed_campaigns.comparison"}
              style={{ marginBottom: "0px" }}
            >
              <AmountRadio>
                <Radio.Button value="lt">
                  <LeftOutlined />
                </Radio.Button>
                <Radio.Button value="gt">
                  <RightOutlined />
                </Radio.Button>
              </AmountRadio>
            </FormField>
          </Box>
        </Col>
        <Col span={6}>
          <Box mt="-10px">
            <FormField
              name={`completed_campaigns.value`}
              style={{ marginBottom: "0px" }}
            >
              <InputNumber size="large" min={0} />
            </FormField>
          </Box>
        </Col>

        <Col span={12}>
          <Box mt="-10px">
            <FormField
              name={"completed_campaigns.months"}
              style={{ marginBottom: "0px" }}
            >
              <Radio.Group>
                <Row gutter={4}>
                  <Col span={12}>
                    <Radio data-cy="redio-last-1-month" value="last_1_months">
                      Last 1 month
                    </Radio>
                  </Col>
                  <Col span={12}>
                    <Radio data-cy="redio-last-3-month" value="last_3_months">
                      Last 3 months
                    </Radio>
                  </Col>
                  <Col span={12}>
                    <Radio data-cy="redio-ever" value="ever">
                      Ever
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </FormField>
          </Box>
        </Col>
      </Row>
    );
  }
}
