// @ts-nocheck
import React, { useEffect } from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  campaignId: number;
  accounts: Array<any>;
  setAccountData: (value: Array<any>) => void;
  setAllAccountData: (value: Array<any>) => void;
  getAccountsByCampaignId: Function;
  allAccountList: Array<any>;
}

const AgencyAccounts: React.FC<Props> = ({
  campaignId,
  accounts,
  setAccountData,
  setAllAccountData,
  getAccountsByCampaignId,
  allAccountList,
}) => {
  useEffect(() => {
    if (allAccountList?.length > 0) {
      const accountArray = allAccountList.map(item => {
        let container = {} as any;
        container.value = item.id;
        container.label = item.username;
        return container;
      });
      setAllAccountData(allAccountList as any);
      setAccountData(accountArray as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccountList]);

  return (
    <Field label="Account(s):" mb={26}>
      <FormikSelect
        name="agencyAccountIds"
        size="large"
        data={accounts || []}
        loadData={async value => {
          let variable: { accountName: string; campaignId?: number } = {
            accountName: value,
          };
          if (campaignId) {
            variable = {
              ...variable,
              campaignId: campaignId as number,
            };
          }
          if (value) {
            let account = await getAccountsByCampaignId(variable);
            if (account?.data?.getAccountsByCampaignId?.length > 0) {
              setAllAccountData({ ...account?.data?.getAccountsByCampaignId });
              setAccountData([]);
              return account?.data?.getAccountsByCampaignId;
            } else {
              return [];
            }
          }
          return [];
        }}
        optionGenerator={value => ({
          value: value.id,
          label: value.username,
        })}
        background="background"
        borderRadius="3px"
        borderColor="grey5"
        mode="multiple"
      />
    </Field>
  );
};
export default AgencyAccounts;
