// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import { Badge } from "src/components/Core/Badge";
import { LinkButton } from "src/components/Core/LinkButton";

interface Props {
  paymentDetail: any;
  isSuperAdmin: boolean;
  onUndo: (value: any) => void;
}

const PaymentStatus: React.FC<Props> = ({
  paymentDetail,
  isSuperAdmin,
  onUndo,
}) => {
  return (
    <Box ml={20} mt={12}>
      {paymentDetail?.status === "pending" ? (
        <Badge
          color="white"
          background="blue4"
          height={25}
          width={78}
          size={12}
        >
          Pending
        </Badge>
      ) : paymentDetail?.status === "rejected" ||
        paymentDetail?.status === "failed" ? (
        <>
          {isSuperAdmin ? (
            <Row gutter={8}>
              <Col span={12}>
                <Box fontSize={16}>
                  <Badge
                    color="white"
                    background="pitch"
                    height={25}
                    width={78}
                    size={12}
                  >
                    {paymentDetail?.status === "rejected"
                      ? "Rejected"
                      : "Failed"}
                  </Badge>
                </Box>
              </Col>
              <Col span={12}>
                <Box fontSize={16}>
                  <LinkButton
                    color="red6"
                    fontSize={14}
                    weight="semi-bold"
                    onClick={() => onUndo(paymentDetail?.id)}
                  >
                    Undo
                  </LinkButton>
                </Box>
              </Col>
            </Row>
          ) : (
            <Badge
              color="white"
              background="pitch"
              height={25}
              width={78}
              size={12}
            >
              {paymentDetail?.status === "rejected" ? "Rejected" : "Failed"}
            </Badge>
          )}
        </>
      ) : paymentDetail?.status === "approved" ? (
        <Badge color="white" background="lime" height={25} width={78} size={12}>
          Approved
        </Badge>
      ) : paymentDetail?.status === "reconciled" ? (
        <Box display="inline-flex">
          <Badge
            color="white"
            background="lime"
            height={25}
            width={78}
            size={12}
          >
            Approved
          </Badge>
          <Box ml={10}>
            <Badge
              color="white"
              background="blue"
              height={25}
              width={78}
              size={12}
            >
              Reconciled
            </Badge>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
export default PaymentStatus;
