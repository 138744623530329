// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  getCompanies: Function;
}

const Company: React.FC<Props> = ({ getCompanies }) => {
  return (
    <Field label="Company:" mb={26}>
      <FormikSelect
        name="companyId"
        size="middle"
        placeholder="Select..."
        loadData={async value => {
          let company = await getCompanies({
            companyName: value,
          });
          if ((company?.data?.getAllCompanies?.companies || []).length > 0) {
            return company?.data?.getAllCompanies?.companies;
          } else {
            return [];
          }
        }}
        optionGenerator={value => ({
          value: value.id,
          label: value.companyName,
        })}
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Company;
