// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikCheckbox } from "src/components/Forms/Checkbox";
import { genders } from "src/constants/account";
import { CheckboxStyle, RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Gender: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if ((formProps?.values?.influencerGender || []).length > 0) {
    isVisible = true;
  }

  return (
    <Field
      label="Influencer Gender:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("influencerGender", []);
              clearFilterKey("influencerGender");
            }}
            style={{ marginTop: "-2px", marginLeft: "2px" }}
          />
        )
      }
      id="influencer_gender"
    >
      <FormikCheckbox
        name="influencerGender"
        data={genders}
        style={CheckboxStyle}
      />
    </Field>
  );
};

export default Gender;
