// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { ManualDataModalFormContainer } from "./container";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FinalStep from "./FinalStep";

const ManualDataModal = styled(Modal)`
  &&& {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.213287);
    border-radius: 6px;
  }
`;

interface ManualDataModalFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  ManualDataModalSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  showManualDataModal: boolean;
  closeManualDataModal: () => void;
  step: number;
  setStep: (value: number) => void;
}

const ManualDataModalForm: React.FC<ManualDataModalFormProps> = ({
  initialValues,
  isSubmitting,
  ManualDataModalSchema,
  onSubmit,
  globalErrors,
  showManualDataModal,
  closeManualDataModal,
  step,
  setStep,
}) => {
  return (
    <ManualDataModal
      title="Manual Data"
      style={{
        maxWidth: 800,
      }}
      bodyStyle={{
        padding: "0px 0px",
      }}
      width="100%"
      visible={showManualDataModal}
      onCancel={closeManualDataModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <GlobalErrors errors={globalErrors} />

      <Form
        initialValues={initialValues}
        schema={ManualDataModalSchema}
        onSubmit={(values, actions) => onSubmit(values, actions)}
      >
        {(formProps: any) => {
          return (
            <>
              {step === 1 && (
                <FirstStep
                  closeManualDataModal={closeManualDataModal}
                  setStep={value => setStep(value)}
                  formProps={formProps}
                />
              )}
              {step === 2 && (
                <SecondStep
                  isSubmitting={isSubmitting}
                  setStep={value => setStep(value)}
                  formProps={formProps}
                />
              )}
              {step === 3 && (
                <FinalStep
                  closeManualDataModal={closeManualDataModal}
                  setStep={value => setStep(value)}
                  formProps={formProps}
                />
              )}
            </>
          );
        }}
      </Form>
    </ManualDataModal>
  );
};

export { ManualDataModalForm, ManualDataModalFormContainer };
