import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Card } from "antd";
import CampaignReview from "src/shared/CampaignReview";
import CampaignManage from "src/ducks/CampaignManage";
import Container from "src/shared/Container";
import BrandCampaignForm from "./UpdateCampaign/BrandCampaignForm"

const DetailsWrapper = styled(Container)`
  margin-top: 30px;
`;

class CampaignDetails extends Component {
  render() {
    const { campaign } = this.props;
    return (
      <DetailsWrapper>
        <Card style={{ marginBottom: 24, marginTop: 24 }}>
          <BrandCampaignForm />
        </Card>
        <CampaignReview campaign={campaign} />
      </DetailsWrapper>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignDetails);
