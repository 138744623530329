// @ts-nocheck
import React from "react";
import { FormikSelect } from "src/components/Forms/Select";
import { countries } from "src/constants/payments";
import { LinkButton } from "src/components/Core/LinkButton";
import { Button } from "src/components/Core/Button";
import { Box } from "src/components/Core/Box";

interface Props {
  cancelClick: (value: boolean) => void;
  continueClick: () => void;
}

const Step1: React.FC<Props> = ({ cancelClick, continueClick }) => {
  return (
    <>
      <Box mb={9}>
        <FormikSelect
          name="countryCode"
          data={countries}
          local
          background="background1"
          borderRadius={1}
          borderColor="grey5"
          size="small"
        />
      </Box>
      <Box mt={18}>
        <Button
          onClick={() => continueClick()}
          type="action"
          buttonSize="small"
          block
          data-cy="step1-continue-button"
        >
          Continue
        </Button>

        <LinkButton
          onClick={() => cancelClick(false)}
          fontSize={12}
          data-cy={"cancel-button-bank"}
        >
          Cancel
        </LinkButton>
      </Box>
    </>
  );
};

export default Step1;
