// @ts-nocheck
import React from 'react'
import { Field } from 'src/components/Forms/Field'
import { FormikInput } from 'src/components/Forms/TextInput'

const Email = () => {
  return (
    <Field id="email">
      <FormikInput
        size="middle"
        borderRadius={3}
        name="email"
        placeholder="Your@emailaddress.com"
      />
    </Field>
  );
};

export default Email;
