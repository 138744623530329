// @ts-nocheck
import React from 'react'
import { List, Divider } from 'antd'
import { Box } from 'src/components/Core/Box'
import CreativeDisplay from 'src/shared/CreativeDisplay'
import { Typography } from 'src/components/Core/Typography'

const Examples = ({campaign}) => {
  return(
    <Box px={40}>
      <Divider>
        <Typography fontSize={18} color="black1" mb={0}>
          Examples
       </Typography>
      </Divider>
      <CreativeDisplay creative={campaign.creative} />
      <List
        dataSource={campaign.metadata?.creativeLinks || []}
        renderItem={(item: string, index) => (
          <List.Item>
            <a target="_blank" rel="noopener noreferrer" href={item}>Example&nbsp;{index + 1}</a>
          </List.Item>
        )}
      />
    </Box>
  )
}

export default Examples
