// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import { HubCheckbox } from "./styles";

interface Props {
  formProps: FormikProps<any>;
  hubLinkData: any;
  getStatus: (value: string) => any;
}

const InviteFeedbackYouTube: React.FC<Props> = ({
  formProps,
  hubLinkData,
  getStatus,
}) => {
  const value = formProps?.values;
  return (
    <Box mt={10}>
      <Box display="inline-flex">
        <HubCheckbox
          name="inviteFeedbackYouTube"
          onChange={() => {
            formProps?.setFieldTouched("inviteFeedbackYouTube");
            formProps?.setFieldValue(
              "inviteFeedbackYouTube",
              !value.inviteFeedbackYouTube
            );

            if (!value.inviteFeedbackYouTube) {
              formProps?.setFieldValue("inviteFeedback", false);
            }
          }}
          disabled={
            hubLinkData?.inviteFeedback?.status === "complete" ||
            hubLinkData?.inviteFeedbackYouTube?.status === "complete"
          }
          value={value.inviteFeedbackYouTube}
          data-cy="checkbox-inviteFeedbackYouTube"
          checked={value.inviteFeedbackYouTube}
        />
        <Typography size={14} color="black2" ml={10} mb={10} fontWeight={600}>
          Invite/Feedback (Youtube)
        </Typography>

        {value.inviteFeedbackYouTube &&
          getStatus(hubLinkData?.inviteFeedbackYouTube?.status)}
      </Box>
      <Typography size={12} color="black">
        This will allow influencers to submit content and review feedback for
        Youtube campaigns if any is available
      </Typography>
    </Box>
  );
};

export default InviteFeedbackYouTube;
