// @ts-nocheck
import React from 'react'
import {DashboardContainer} from './container'
import DashboardLayout from 'src/scenes/layouts/DashboardLayout'
import HeroActions from "../components/HeroAction"
import { FilterContainer } from "./Filters"
import Campaigns from './Campaigns'
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";

interface Props {
  handleFilter: Function
  campaigns: any
  loading: boolean
  isDashboard: boolean
  initialValues: any
  globalErrors?: GlobalErrorProps[]
}

const Dashboard = ({...props}: Props) => {
  const { handleFilter, isDashboard, initialValues, globalErrors } = props

  const getTitle = () => {
    if(isDashboard) {
      return "Public campaigns"
    } else {
      return "My campaigns"
    }
  }

  const getSubTitle = () => {
    if(isDashboard) {
      return "This is the campaign dashboard. Here you will be able to find all available campaigns and apply to them."
    } else {
      return "Here are all the comapaigns you’r eucrrently associated with."
    }
  }

  return(
    <DashboardLayout title="Influencers">
      <HeroActions
        title={getTitle()}
        subTitle={getSubTitle()}
      />
      <GlobalErrors errors={globalErrors} />
      <FilterContainer handleFilter={handleFilter} initialValues={initialValues} />
      <Campaigns {...props} />
    </DashboardLayout>
  )

}

export { Dashboard, DashboardContainer };
