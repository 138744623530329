// @ts-nocheck
import styled from 'styled-components'
import { Table } from 'src/components/Core/TableManager'

export const AccountsNewTable = styled(Table)`
  &&& {

    th .ant-checkbox-wrapper {
      display: none;
    }

    tr.table-row-fade > td {
      opacity: 0.5
    }

  }
`;
