// @ts-nocheck
import React from "react";
import map from "lodash/map";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import { CloseOutlined } from '@ant-design/icons';
import { Tag } from "src/components/Core/Tag";
import moment from "moment";
import formatCurrency from "src/utils/formatCurrency";
import { isBoolean } from "lodash";

export function getFilterData(data) {
  let filters = {} as any;

  if (data?.title) {
    filters["title"] = data?.title;
  }

  if (data?.hubspotDealId) {
    filters["hubspotDealId"] = data?.hubspotDealId;
  }

  if ((data?.status || []).length > 0) {
    filters["status"] = data?.status;
  }

  if (data?.platforms) {
    filters["platforms"] = data?.platforms;
  }

  if (data?.locations) {
    filters["locations"] = data?.locations;
  }

  if (data?.startedAfter) {
    filters["startedAfter"] = data?.startedAfter;
  }

  if ((data?.tags || []).length > 0) {
    filters["tags"] = map(data?.tags, Number);
  }

  if ((data?.customTags || []).length > 0) {
    filters["customTags"] = map(data?.customTags, Number);
  }

  if (
    data?.costOfSales &&
    (data?.costOfSales?.start || data?.costOfSales?.end)
  ) {
    filters["costOfSales"] = {
      start: Number(data?.costOfSales?.start),
      end: Number(data?.costOfSales?.end),
    };
  }

  if (data?.budget && (data?.budget?.start || data?.budget?.end)) {
    filters["budget"] = {
      start: Number(data?.budget?.start),
      end: Number(data?.budget?.end),
    };
  }

  if (data?.page) {
    filters["page"] = Number(data?.page);
  }

  if (data?.order) {
    filters["order"] = data?.order;
  }

  if (data?.adminExecId) {
    filters["adminExecId"] = Number(data?.adminExecId)
  }

  if (data?.isPublic) {
    filters["isPublic"] = data?.isPublic === "true"
  }

  if (data?.paymentCurrency) {
    filters["paymentCurrency"] = data?.paymentCurrency
  }

  return filters;
}

export function getApplyFilter(data, clearFilterKey, allTags) {
  let filters = [] as any;

  if (data?.title) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey6"
        key="title"
      >
        Title:{data?.title}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("title");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.hubspotDealId) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey6"
        key="hubspotDealId"
      >
        HubSpot ID:{data?.hubspotDealId}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("hubspotDealId");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.adminExecId) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey6"
        key="adminExecId"
      >
        Admin/Exec:{data?.adminExecId}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("adminExecId");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.status) {
    (data?.status || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={item}
        >
          Status:{item}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("status", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (isBoolean(data?.isPublic)) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="blue"
        key="isPublic"
      >
        Is Public:{data?.isPublic ? "Yes" : "No"}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("isPublic");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.platforms) {
    (data?.platforms || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={item}
        >
          Type:{item}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("platforms", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.locations) {
    (data?.locations || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={i}
        >
          Location:{item}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("locations", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.startedAfter) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey6"
        key="startedAfter"
      >
        Campaign started after:
        {moment(data?.startedAfter).format("DD/MM/YYYY")}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("startedAfter");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.tags) {
    (data?.tags || []).forEach((item, i) => {
      let tag = find(allTags, matchesProperty("id", item));
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={tag?.name}
        >
          Tag:{tag?.name}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("tags", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.customTags) {
    (data?.customTags || []).forEach((item, i) => {
      let tag = find(allTags, matchesProperty("id", item));

      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={tag?.name}
        >
          C.Tag:{tag?.name}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("customTags", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (
    data?.costOfSales &&
    (data?.costOfSales?.start || data?.costOfSales?.end)
  ) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey6"
        key="costOfSales"
      >
        Cost of Sales:
        {`${formatCurrency(data?.costOfSales?.start)} - ${formatCurrency(
          data?.costOfSales?.end
        )}`}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("costOfSales");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.budget && (data?.budget?.start || data?.budget?.end)) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey6"
        key="budget"
      >
        Budget:
        {`${formatCurrency(data?.budget?.start)} - ${formatCurrency(
          data?.budget?.end
        )}`}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("budget");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.paymentCurrency) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey6"
        key="paymentCurrency"
      >
        Currency:
        {data?.paymentCurrency}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("paymentCurrency");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  return filters;
}

export function getTotalAppliedFilter(filters) {
  let count = 0;

  if (filters?.title) {
    count = Number(count + 1);
  }

  if (filters?.hubspotDealId) {
    count = Number(count + 1);
  }

  if ((filters?.status || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.platforms || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.locations || []).length > 0) {
    count = Number(count + 1)
  }

  if (filters?.startedAfter) {
    count = Number(count + 1);
  }

  if ((filters?.tags || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.customTags || []).length > 0) {
    count = Number(count + 1);
  }

  if (
    filters?.costOfSales &&
    (filters?.costOfSales?.start || filters?.costOfSales?.end)
  ) {
    count = Number(count + 1);
  }

  if (filters?.budget && (filters?.budget?.start || filters?.budget?.end)) {
    count = Number(count + 1);
  }

  if( filters?.adminExecId) {
    count = Number(count + 1)
  }

  if(isBoolean(filters?.isPublic)) {
    count = Number(count + 1)
  }

  if(filters?.paymentCurrency) {
    count = Number(count + 1)
  }

  return count;
}
