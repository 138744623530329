// @ts-nocheck
import React from 'react'
import { Link } from "react-router-dom";
import { Typography } from "src/components/Core/Typography";
import WithPopover from "./WithPopOver"

type UsernameProps = {
  account: any
  isInnerAccountList: Boolean
  menu: any
}

const Username = ({account, isInnerAccountList, menu}: UsernameProps) => {
  return isInnerAccountList ? (
    <WithPopover account={account} menu={menu} />
  ) : (
    <Link to={`/accounts/${account?.id}`}>
      <Typography size={12} cursor="pointer" mb={0}>
        {account?.username ? account?.username : "-"}
      </Typography>
    </Link>
  );
}

export default Username;
