import React, { Component } from "react";
import { Descriptions } from "antd";

export default class AccountLink extends Component {
  render() {
    const { account } = this.props;

    return (
      <>
        {(account.type === "TikTok" ||
          account.type === "Instagram" ||
          account.type === "YouTube") && (
          <div
            style={{
              marginTop: "14px",
              paddingLeft: "64px",
            }}
          >
            <Descriptions bordered size="small" column={1}>
              {account.type === "TikTok"
                ? [
                    <Descriptions.Item key="profile_url" label="TikTok Profile">
                      <a
                        href={account.tiktokProfileUrl()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {account.tiktokProfileUrl()}
                      </a>
                    </Descriptions.Item>,
                  ]
                : []}

              {account.type === "Instagram"
                ? [
                    <Descriptions.Item
                      key="profile_url"
                      label="Instagram Profile"
                    >
                      <a
                        href={account.instagramProfileUrl()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {account.instagramProfileUrl()}
                      </a>
                    </Descriptions.Item>,
                  ]
                : []}

              {account.type === "YouTube"
                ? [
                    <Descriptions.Item
                      key="profile_url"
                      label="YouTube Profile"
                    >
                      <a
                        href={account.youtubeProfileUrl()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {account.youtubeProfileUrl()}
                      </a>
                    </Descriptions.Item>,
                  ]
                : []}
            </Descriptions>
          </div>
        )}
      </>
    );
  }
}
