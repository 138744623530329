// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Checkbox, Avatar } from "antd";
import numeral from "numeral";
import { Typography } from "src/components/Core/Typography";
import { analyricsColors } from "src/constants/campaign";

interface Props {
  account: any;
  isActive: boolean;
  onSelectAccount: () => void;
  index: number;
}

const AccountItem: React.FC<Props> = ({
  account,
  isActive,
  onSelectAccount,
  index,
}) => {
  return (
    <Box
      style={{ cursor: "pointer", overflow: "hidden" }}
      ml={10}
      borderRight={
        isActive
          ? `2px solid ${analyricsColors[index > 11 ? index % 12 : index]}`
          : "none"
      }
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p={12}
        alignItems="center"
      >
        <Box display="flex">
          <Checkbox checked={isActive} onChange={onSelectAccount} />

          <Box ml={10} display="flex">
            <Avatar src={account?.profilePicUrl} size={20} />
            <Typography
              size={14}
              color={"black"}
              mb={0}
              cursor="pointer"
              ml="5px"
              fontWeight={isActive ? 500 : "normal"}
            >
              @{account?.username}
            </Typography>
          </Box>
        </Box>

        <Typography
          size={12}
          color="black2"
          mb="3px"
          ml="5px"
          lineHeight="16px"
        >
          {numeral(account?.views).format("0.0a")} views
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountItem;
