import React, { Component } from "react";
import FormField from "../FormField";
import CurrencyInput from "src/shared/CurrencyInput";

export default class OriginalPayoutPennies extends Component {
  render() {
    const { name, title } = this.props;

    return (
      <FormField name={name || "payout_type_original_pennies"} label={title}>
        <CurrencyInput
          data-cy="invitation-fixed-payout"
          withoutPrefix

        />
      </FormField>
    );
  }
}
