import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class VatNumber extends Component {
  render() {
    return (
      <FormField name={this.props.name || "detail.vat_number"} label="VAT Number">
        <Input data-cy="influencer-vat-number" size="large" placeholder="e.g. GB 795796789" />
      </FormField>
    )
  }
}
