import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "src/shared/Container";
import LinksTable from "./LinksTable";
import CampaignManage from "src/ducks/CampaignManage";

export class Links extends Component {
  render() {
    return (
      <Container style={{marginTop: "24px"}}>
          <LinksTable />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Links);
