import { createSelector } from "reselect"
import * as api from "../api/endpoints"

const initialState = {
  isPending: false,
  pagination: {},
  request_at: 0,
  error: null,
  data: []
}

const name = "AccountTotal"
const type = "Account"
const endpoint = api[type]
const stateName = name

// Types
const types = {
  loadMany: name + "_LIST"
}

// Action creators
const actions = {
  loadMany: (query = {}) => {
    return (dispatch) => {
      const response = dispatch({
        type: types.loadMany,
        payload: endpoint.getAccountTotal({
          params: query
        })
      })

      return response
    }
  }
}

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${types.loadMany}_REJECTED`:
      return {
        ...state,
        error: action.payload,
        isPending: false
      }
    case `${types.loadMany}_PENDING`:
      return {
        ...state,
        isPending: true
      }
    case `${types.loadMany}_FULFILLED`:
      const pagination = Object.assign({}, { ...action.payload.data })
      delete pagination.data
      if (action.payload.config.meta.sent_at < state.request_at) {
        return {
          ...state,
          isPending: false
        }
      } else {
        const data = action.payload.data.data
        return {
          ...state,
          request_at: action.payload.config.meta.sent_at,
          pagination,
          isPending: false,
          data
        }
      }
    default:
      return { ...state }
  }
}

// Selectors
const selectPending = (state) => state[stateName].isPending
const selectData = (state) => state[stateName].data

const selectors = {
  selectPending,

  selectMany: createSelector((state) => state, selectData),

  selectPagination: (state) => state[stateName].pagination
}

const AccountModule = () => [
  {
    id: "AccountTotal",
    reducerMap: {
      AccountTotal: reducer
    }
  }
]

export default {
  types,
  reducer,
  module: AccountModule,
  ...actions,
  ...selectors
}
