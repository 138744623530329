// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import { HubCheckbox } from "./styles";

interface Props {
  formProps: FormikProps<any>;
  hubLinkData: any;
  getStatus: (value: string) => any;
}

const Contract: React.FC<Props> = ({ formProps, hubLinkData, getStatus }) => {
  const value = formProps?.values;
  return (
    <Box mt={10}>
      <Box display="inline-flex">
        <HubCheckbox
          name="contract"
          onChange={() => {
            formProps?.setFieldTouched("contract");
            formProps?.setFieldValue("contract", !value.contract);
          }}
          disabled={hubLinkData?.contract?.status === "complete"}
          value={value.contract}
          data-cy="checkbox-contract"
          checked={value.contract}
        />
        <Typography size={14} color="black2" ml={10} mb={10} fontWeight={600}>
          Contract
        </Typography>

        {value.contract && getStatus(hubLinkData?.contract?.status)}
      </Box>
      <Typography size={12} color="black">
        This will display our default legal terms and request the influencer
        accept them
      </Typography>
    </Box>
  );
};

export default Contract;
