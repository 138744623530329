// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import { HubCheckbox } from "./styles";

interface Props {
  formProps: FormikProps<any>;
  hubLinkData: any;
  getStatus: (value: string) => any;
}

const InviteFeedback: React.FC<Props> = ({
  formProps,
  hubLinkData,
  getStatus,
}) => {
  const value = formProps?.values;
  return (
    <Box mt={10}>
      <Box display="inline-flex">
        <HubCheckbox
          name="inviteFeedback"
          onChange={() => {
            formProps?.setFieldTouched("inviteFeedback");
            formProps?.setFieldValue("inviteFeedback", !value.inviteFeedback);

            if (!value.inviteFeedback) {
              formProps?.setFieldValue("inviteFeedbackYouTube", false);
            }
          }}
          disabled={
            hubLinkData?.inviteFeedback?.status === "complete" ||
            hubLinkData?.inviteFeedbackYouTube?.status === "complete"
          }
          value={value.inviteFeedback}
          data-cy="checkbox-inviteFeedback"
          checked={value.inviteFeedback}
        />
        <Typography size={14} color="black2" ml={10} mb={10} fontWeight={600}>
          Invite/Feedback
        </Typography>

        {value.inviteFeedback && getStatus(hubLinkData?.inviteFeedback?.status)}
      </Box>
      <Typography size={12} color="black">
        This will allow influencers to submit content and review feedback if any
        is available
      </Typography>
    </Box>
  );
};

export default InviteFeedback;
