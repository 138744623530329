import styled from "styled-components";
import { Col, Avatar, Typography } from "antd";
import { Icon } from '@ant-design/compatible'

const { Text } = Typography;

const CurrentUser = styled(Col)`
  margin-left: auto;
`;

CurrentUser.Avatar = styled(Avatar)`
  && {
    margin-right: 10px;
  }
`;

CurrentUser.Name = styled(Text)`
  margin-right: 0px;
`;

CurrentUser.Icon = styled(Icon)`
  && {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default CurrentUser;
