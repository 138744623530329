// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { Box } from "src/components/Core/Box";

interface Props {
  children?: React.ReactNode;
  direction: string;
}

const ParallaxSection: React.FC<Props> = ({ children, direction }) => {
  const calc = o =>
    `translateY(${o * (direction === "down" ? 0.03 : -0.05)}px)`;

  const parallaxRef = useRef();
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

  const handleScroll = () => {
    const posY = (parallaxRef?.current as any).getBoundingClientRect().top;
    const offset = window.pageYOffset - posY;
    set({ offset });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Box ref={parallaxRef as any}>
      <animated.div
        style={{
          transform: offset.interpolate(calc),
        }}
      >
        {children}
      </animated.div>
    </Box>
  );
};

export default ParallaxSection;
