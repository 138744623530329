// @ts-nocheck
import React from "react";
import numeral from "numeral";
import { show } from 'redux-modal'
import { Row, Divider } from 'antd'
import compact from "lodash/compact";
import { Typography } from "src/components/Core/Typography";

interface Props {
  dispatch: Function
  handleUpdate: (account: any) => void
}

const getColumns = ({dispatch, handleUpdate}: Props) => {
  const size = window.innerWidth > 1465 ? 14: 12
  const adjustWidth = window.innerWidth > 1465 ? 0: 10
  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 120 - adjustWidth,
      visible: true,
      render: (text, row) => (
        <Typography size={size} color="black2" mb={0}>
          {row?.account?.username}
        </Typography>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      width: 82 - adjustWidth,
      visible: true,
      render: (text, row) =>
        <Typography size={size} color="black2" mb={0}>
          {row?.account?.type}
        </Typography>,
    },
    {
      title: "Followers",
      dataIndex: "followers",
      key: "followers",
      width: 120 - adjustWidth,
      visible: true,
      render: (text, row) =>
      row?.account?.metadata?.followers &&
        numeral(row?.account?.metadata?.followers).format("0,0")        ,
    },
    {
      title: "Engagement rate",
      dataIndex: "engagementRate",
      key: "engagementRate",
      width: 130 - adjustWidth,
      visible: true,
      render: (text, row) =>
        row?.account?.metadata?.engagementRate &&
          <Typography size={size} color="black2" mb={0}>
            {`${numeral(row?.account?.metadata?.engagementRate).format('0,0.00')}%`}
          </Typography>
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 180 + adjustWidth,
      visible: true,
      render: (text, record) => (
        <Row>
          <Typography size={size} color="blue" mb={0} cursor="pointer">
            <span
              onClick={() => {
                dispatch(
                  show("InfluencerModal", {
                    influencerId: Number(record?.account.influencerId),
                    rationale:
                      record?.rationale,
                    accountId: Number(record?.account?.id),
                  })
                )
              }}
            >
              View Details
            </span>
          </Typography>
          <Divider type="vertical" />
          <Typography size={size} color="blue" mb={0} cursor="pointer">
            <span onClick={() => {handleUpdate(record)}}>
              Add to {record?.status === "pending" ? "liked" : "suggested"}
            </span>
          </Typography>
        </Row>
      ),
    },
  ];

  return compact(columns);
};

export default getColumns;
