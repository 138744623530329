import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Layout } from "antd";
import withSteps from "./withSteps";

import FormSteps from "./FormSteps";
import Steps from "./Wizard";
import Form from "./CampaignForm";
import FormError from "src/shared/FormField/FormError";
import Container from "src/shared/Container";
import idx from "idx";

const { Content } = Layout;

const FormContent = styled(Content)`
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 4px;
  background: #fff;
  padding: 30px;
`;

const ServerFormError = styled(FormError)`
  &&& {
    margin-top: 0px;
    margin-bottom: 15px;
  }
`;

class WizardManager extends Component {
  componentDidMount() {
    const { campaign, changeToInvalidStep } = this.props;

    if (campaign) changeToInvalidStep(campaign.ref);
  }

  componentDidUpdate(prevProps) {
    const { serverErrors, changeToFirstErrorStep } = this.props;

    if (prevProps.serverErrors !== serverErrors)
      changeToFirstErrorStep(serverErrors);
  }

  onSubmit = values => {
    const { onPersist, changeStep, currentStep } = this.props;

    changeStep(currentStep + 1, () => (onPersist ? onPersist(values) : null));
  };

  render() {
    const {
      persistData,
      campaign,
      serverErrors,
      changeToFirstErrorStep,
      errorsForOmittedFields,
      stepStatuses,
      currentStep,
      changeStep,
    } = this.props;

    const newSteps = [...Steps];

    let activeStep = currentStep;
    if (idx(campaign, x => x.ad_format) === "Content generation") {
      newSteps.splice(1, 2);

      activeStep =
        currentStep >= newSteps.length ? newSteps.length - 1 : currentStep;
    }

    const step = newSteps[activeStep];
    const component = step?.component ? step?.component : Form;

    return (
      <Fragment>
        <Container>
          <FormSteps
            currentStep={currentStep}
            changeStep={changeStep}
            campaign={campaign}
            steps={newSteps}
            stepStatuses={stepStatuses}
            serverErrors={serverErrors}
          />
          <FormContent>
            {errorsForOmittedFields(serverErrors).map((e, i) => (
              <ServerFormError key={i} message={e} type="error" />
            ))}

            {React.createElement(component, {
              campaign,
              step,
              persistData,
              changeStep,
              serverErrors,
              changeToFirstErrorStep: changeToFirstErrorStep,
              onSubmit: this.onSubmit,
            })}
          </FormContent>
        </Container>
      </Fragment>
    );
  }
}

export default withSteps(WizardManager)(Steps);
