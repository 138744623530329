// @ts-nocheck
import React from "react"
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { Typography } from "src/components/Core/Typography";

const WithPopOver = ({account, menu}) => {
  return(
    <Popover content={menu(account)}>
      <Link to={`/accounts/${account?.id}`}>
        <Typography size={12} cursor="pointer" mb={0}>
            {account?.username ? account?.username : "-"}
        </Typography>
      </Link>
    </Popover>
  )
}

export default WithPopOver;
