// @ts-nocheck
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Empty, Card } from "antd";
import { Box } from "src/components/Core/Box";
import Loading from "src/shared/Loading";
import withModules from "src/utils/hoc/withModules";
import { InfluencerPersist } from "src/ducks/Persist";
import { AccountList as $AccountList } from "src/ducks/DataList";
import InfluencerDetail from "src/components/Modules/Influencer/Profile";
import Auth from "src/ducks/Auth";
import isEmpty from "lodash/isEmpty";

const AccountList = $AccountList("AdminInfluencerAccountList");

const ShowInfluencer = InfluencerPersist("AdminInfluencerProfile");

const modules = [ShowInfluencer.module(), AccountList.module()];

type Props = {
  influencer: any;
  accounts: any;
  match: any;
  currentUser: any;
};

type InternalProps = {
  loadInfluencer: Function;
  loadAccounts: Function;
  loading: boolean;
  loadingAccounts: boolean;
};

export class InfluencerProfile extends Component<Props & InternalProps> {
  componentDidMount() {
    const {
      loadInfluencer,
      loadAccounts,
      match: {
        params: { id },
      },
    } = this.props;

    loadInfluencer(id, "get", "profile", "");

    loadAccounts({ influencer: id });
  }

  render() {
    const {
      influencer,
      accounts,
      loading,
      loadingAccounts,
      currentUser,
    } = this.props;

    return (
      <Card style={{ marginTop: 24 }}>
        {influencer && !loading ? (
          <>
            {/* <InfluencerMini influencer={influencer} />
            <Divider />
            <InfluencerMetrics
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
              influencer={influencer}
            /> */}
            {accounts && !loadingAccounts ? (
              <>
                {/* <InfluencerStats
                  accounts={accounts}
                  breakdownLastUpdateDate={
                    influencer?.breakdown_last_update_date
                  }
                /> */}
                {(accounts || []).length > 0 ? (
                  <InfluencerDetail
                    accounts={accounts}
                    breakdownLastUpdateDate={
                      influencer?.breakdown_last_update_date
                    }
                    grid={
                      !isEmpty(currentUser)
                        ? undefined
                        : window.innerWidth >= 1100
                        ? 2
                        : 1
                    }
                  />
                ) : (
                  <Box p={60}>
                    <Empty description="No data avaialble" />
                  </Box>
                )}
              </>
            ) : (
              <Loading />
            )}
          </>
        ) : (
          <Loading />
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  influencer: ShowInfluencer.selectMethodData("get", "profile")(state),
  loading: ShowInfluencer.selectMethodPending("get", "profile")(state),
  accounts: AccountList.selectMany(state),
  loadingAccounts: AccountList.selectPending(state),
  currentUser: Auth.selectUser(state),
});

const mapDispatchToProps = {
  loadInfluencer: ShowInfluencer.customMethod,
  loadAccounts: AccountList.loadMany,
};

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InfluencerProfile))
)(modules);
