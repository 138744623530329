// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";

interface Props {
  isVisible: boolean;
  buttonClick: () => void;
}

const ClearButton: React.FC<Props> = ({ isVisible, buttonClick }) => {
  return (
    <>
      {isVisible ? (
        <Typography
          size={10}
          color="red"
          cursor="pointer"
          onClick={() => buttonClick()}
          lineHeight="0px"
          mt={13}
        >
          Clear
        </Typography>
      ) : null}
    </>
  );
};

export default ClearButton;
