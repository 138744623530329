// @ts-nocheck
import React, { useEffect } from "react";
import { ages } from "src/constants/account";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FormikCheckbox } from "src/components/Forms/Checkbox";
import { CheckboxStyle, RemoveIcon } from "../../../Wrapper";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const AgeAudience: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (
    (formProps?.values?.audienceAge || []).length > 0 &&
    (formProps?.values?.audienceAge || [])?.[0]?.key !== ""
  ) {
    isVisible = true;
  }

  let selectedData =
    (formProps?.values?.audienceAge || [])
      .map(item => item?.key)
      .filter(v => v !== "") || [];

  useEffect(() => {
    let simpleAgeAudience = [] as any;
    simpleAgeAudience = (selectedData || []).map(item => {
      let container = {} as any;
      container.key = item;
      return container;
    });

    formProps.setFieldValue("audienceAge", simpleAgeAudience);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Field
      label="Age:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("audienceAge", [{ key: "" }]);
              clearFilterKey("audienceAge");
            }}
            style={{ marginTop: "0px", marginLeft: "10px" }}
          />
        )
      }
      id="ageBreakdown"
    >
      <FormikCheckbox
        name="ageAudience"
        data={ages}
        style={CheckboxStyle}
        value={selectedData}
        onChange={v => {
          let ageAudience = [] as any;
          ageAudience = (v || []).map(item => {
            let container = {} as any;
            container.key = item;
            return container;
          });
          formProps.setFieldValue("audienceAge", ageAudience);
        }}
      />
    </Field>
  );
};

export default AgeAudience;
