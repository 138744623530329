// @ts-nocheck
import React from "react";
import FormLayout from "../FormLayout";
import Container from "src/shared/Container";
import { ErrorContainer, ErrorTitle, ErrorDescription } from "./styles";

interface Props {}

const NotFound: React.FC<Props> = () => {
  return (
    <FormLayout title={"error"} styles={{ alignItems: "flex-start" }}>
      <Container>
        <ErrorContainer>
          <ErrorTitle>Oops...</ErrorTitle>
          <ErrorDescription>
            Looks like the page you're trying to reach has either been removed
            or doesn't exist.
          </ErrorDescription>
        </ErrorContainer>
      </Container>
    </FormLayout>
  );
};

export default NotFound;
