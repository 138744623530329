// @ts-nocheck
import React from "react";
import { AccountsList } from ".";
import { useInfluencerDetails } from "../InfluencerProfileProvider";

interface Props {}

export const AccountsListContainer: React.FC<Props> = () => {
  const { influencerDetails, isLoading } = useInfluencerDetails();

  return (
    <AccountsList
      loading={isLoading}
      accounts={influencerDetails?.accounts || []}
    />
  );
};
