// @ts-nocheck
import React from 'react'
import Loading from "src/shared/Loading"
import { Typography } from "antd";
const { Paragraph } = Typography;

interface Props {
  loading: boolean
  updateTitle: (value: string) => void
  invitation: any
}

export const InvitationTitle = ({loading, updateTitle, invitation}: Props) => (
  <Loading spinning={loading}>
    <Paragraph editable={{ onChange: updateTitle }}>
      {invitation?.title}
    </Paragraph>
  </Loading>
)
