// @ts-nocheck
export const columnVisibility = {
  profilePicUrl: true,
  username: true,
  influencerName: true,
  status: true,
  type: true,
  engagementRate: true,
  lastCampaignDate: true,
  lastPaidAmount: true,
  followers: true,
  actions: true,
};
