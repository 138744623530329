// @ts-nocheck
import React from 'react'
import Loading from 'src/shared/Loading'
import { StyledButton } from '../styles'

interface Props {
  updateTitle: any
  handleDuplicate: (value: any) => void
}

export const DuplicateInvitation = ({updateTitle, handleDuplicate}: Props) => (
  <Loading spinning={updateTitle?.loading}>
    <StyledButton type="primary" onClick={handleDuplicate}>
      Duplicate Invite
    </StyledButton>
  </Loading>
)
