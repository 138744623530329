// @ts-nocheck
import React, { useState } from "react";
import { AdUploadForm } from ".";
import { string, object } from "yup";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useUploadMediaToTikTokCreativeMutation } from "src/generated/ApolloComponents";

interface Props {
  closeAdUploadModal: () => void;
  draftId: any;
  authorised: any;
  campaignId: number;
  advertisers: Array<any>;
}

interface AdUploadFormValues {
  fileName: string;
  advertiserId: string;
  draftId: number;
  campaignId: number;
}

export const AdUploadFormContainer: React.FC<Props> = ({
  closeAdUploadModal,
  draftId,
  authorised,
  campaignId,
  advertisers,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState("");

  const [
    uploadMediaToTikTokCreativeMutation,
    { loading, error },
  ] = useUploadMediaToTikTokCreativeMutation();

  const AdUploadSchema = object().shape({
    fileName: string().required("Please enter name"),
    advertiserId: string().required("Please select an Ad to upload to"),
  });

  let initialValues = {
    fileName: "",
    advertiserId: "",
    draftId: draftId,
    campaignId: campaignId,
  };

  const onSubmit = async (
    values: AdUploadFormValues,
    actions: FormikActions<AdUploadFormValues>
  ) => {
    try {
      const res = await uploadMediaToTikTokCreativeMutation({
        variables: values,
      });
      if (res?.data?.uploadMediaToTikTokCreative) {
        let advertiser = find(
          advertisers,
          matchesProperty("value", values?.advertiserId)
        );
        setSelectedAdvertiser(advertiser?.label);
        setIsSuccess(true);
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <AdUploadForm
      initialValues={initialValues}
      adUploadSchema={AdUploadSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      closeAdUploadModal={() => {
        closeAdUploadModal();
        setIsSuccess(false);
      }}
      isSuccess={isSuccess}
      authorised={authorised}
      advertisers={advertisers}
      selectedAdvertiser={selectedAdvertiser}
    />
  );
};
