// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";
import { Switch } from "antd";

interface Props {
  name?: string;
}

const FormikSwitch: React.FC<Props> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur, setFieldTouched, setFieldValue },
      }) => (
        <>
          <Switch
            {...props}
            {...field}
            onBlur={e => {
              handleBlur(e);
            }}
            onChange={value => {
              setFieldTouched(field.name, true);
              setFieldValue(field.name, value);
            }}
            checked={field.value}
            data-cy={`input-switch-${field.name.replace(" ", "-")}`}
          />
          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={field.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikSwitch;
