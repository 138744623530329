import React, { Component } from "react";
import { Switch } from "formik-antd";
import { connect } from "react-redux";
import Table from "src/shared/Table";
import { Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import { show } from "redux-modal";
import TableManager from "src/ducks/TableManager";
import { PayoutList as $PayoutList } from "src/ducks/DataList";
import CreateModal from "./PayoutModal";
import idx from "idx";
import withWindowResize from "src/utils/hoc/withWindowResize";

const PauoutTable = TableManager("InfluencerPayoutList");
const PayoutList = $PayoutList("InfluencerPayoutList");

const modules = [PauoutTable.module(), PayoutList.module()];

export class PayoutsTable extends Component {
  loadPayouts = filters => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.loadPayouts({
      influencer: id,
      ...filters,
    });
  };

  render() {
    const {
      data,
      pagination,
      loading,
      style,
      currentFilters,
      show,
      height,
      match: {
        params: { id },
      },
    } = this.props;

    const columns = {
      payable_type: {
        title: "Payable Type",
        dataIndex: "payable_type",
        key: "payable_type",
        visible: true,
        width: 150,
      },
      campaign_title: {
        title: "Campaign",
        dataIndex: "campaign_title",
        visible: true,
        sorter: true,
        width: 200,
        render: (text, row) =>
          idx(row, x => x.campaign_id) ? (
            <Link to={`/campaigns/${idx(row, x => x.campaign_id)}`}>
              {text}
            </Link>
          ) : (
            text
          ),
      },
      actual_payout: {
        title: "Payout",
        dataIndex: "payout_pennies",
        visible: true,
        width: 150,
        render: (text, row) => row.formattedPayout(),
      },
      paid: {
        title: "Paid",
        dataIndex: "paid",
        visible: true,
        width: 150,
        render: (text, row) => row.formattedPaid(),
      },
      paid_at: {
        title: "Paid At",
        dataIndex: "paid_at",
        visible: true,
        width: 150,
        render: (text, row) => row.formattedPaidAt(),
      },
    };

    const filters = {
      paid: {
        input: () => <Switch />,
        label: "Paid",
      },
    };

    return (
      <>
        <Table
          style={style}
          title="Influencer Payouts"
          columns={columns}
          loadData={this.loadPayouts}
          name="InfluencerPayoutList"
          pagination={pagination}
          data={data}
          extra={[
            <Button
              type="primary"
              data-cy="influencer-create-payout-button"
              key="create"
              onClick={() => show(`InfluencerPayoutModal`)}
            >
              Create new Payout
            </Button>,
          ]}
          filterOptions={filters}
          loading={loading}
          size="middle"
          scroll={{ y: height, x: "max-content" }}
        />

        <CreateModal
          initialValues={{ influencer: parseInt(id) }}
          onCreate={() => this.loadPayouts(currentFilters)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentFilters: PauoutTable.selectFilters(state),
  data: PayoutList.selectMany(state),
  pagination: PayoutList.selectPagination(state),
  loading: PayoutList.selectPending(state),
});

const mapDispatchToProps = {
  loadPayouts: PayoutList.loadMany,
  show,
};

const MainPayoutsTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PayoutsTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainPayoutsTable);
