// @ts-nocheck
import React, { Fragment } from 'react'
import { Steps, Row, Col } from 'antd'
import { Typography } from 'src/components/Core/Typography'
import { OuterCircle, InnerCircle, StyledSteps } from './CustomCircle'
import moment from 'moment'

interface Props {
  createdAt: string
  deadline: string
  startDate: string
  isMobile?: boolean
}

const { Step } = Steps;

const CampaignSteps = ({createdAt, deadline, startDate, isMobile}: Props) => {
  const getCurrentStep = () => {
    if(moment(startDate).isBefore(new Date()))
      return 2
    else if(moment(startDate).isBefore(new Date()))
      return 1
    else
      return 0
  }
  const customDot = (dot, { status, index }) => (
    <OuterCircle status={status}>
      <InnerCircle status={status} />
    </OuterCircle>
  );

  const getTitle = (text: any) => {
    return <Typography fontSize={14} color="black1">{text}</Typography>
  }

  const getDescription = (text: any,) => {
    return <Typography fontSize={14} color="scorpion">{text}</Typography>
  }

  const getBoldDescription = (text: any,) => {
    return <Typography fontSize={14} color="scorpion" fontWeight="bold">{text}</Typography>
  }

  const getDateText = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : '—/—/——'
  }

  return(
    <Fragment>
      {isMobile ?
        <Row>
          <Col span={12}>
            {getTitle('Campaign created:')}
            {getDescription(moment(createdAt).format("DD/MM/YYYY"))}
          </Col>
          <Col span={12}>
            {getTitle('Application deadline:')}
            {getBoldDescription(getDateText(deadline))}
          </Col>
          <Col span={12}>
            {getTitle('Campaign live:')}
            {getDescription(getDateText(startDate))}
          </Col>
        </Row>
        :
        <StyledSteps current={getCurrentStep()} direction="vertical" progressDot={customDot}>
          <Step
            title={getTitle('Campaign created:')}
            description={getDescription(moment(createdAt).format("DD/MM/YYYY"))}
          />
          <Step
            title={getTitle('Application deadline:')}
            description={getBoldDescription(getDateText(deadline))}
          />
          <Step
            title={getTitle('Campaign live:')}
            description={getDescription(getDateText(startDate))}
          />
        </StyledSteps>
      }
    </Fragment>
  )
}

export default CampaignSteps;
