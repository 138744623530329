// @ts-nocheck
import React, { useRef } from "react";
import { Carousel, Card, Row, Col, Empty } from "antd";
import styled from "styled-components";
import { Field } from "src/components/Forms/Field";
import { Box } from "src/components/Core/Box";
import { Icon } from '@ant-design/compatible'

const ScreenshotsCarousel = styled(Carousel)`
  && {
    margin-top: 20px;
    overflow: hidden !important:
    .slick-list {
      overflow: visible !important;
    }
    .slick-dots li button {
      background: #afadad !important;
      max-width: 9px !important;
      border-radius: 50% !important;
      height: 9px !important;
      margin: 0 2px;
    }
    .slick-dots li.slick-active button {
      background: #1890ff !important;
    }
    .slick-dots-bottom {
      bottom: -25px !important;
    }
  }
`;
const ScreenshotCard = styled(Card)`
  &&& {
    border: none !important;
    display: flex !important;
    item-align: center !important;
    justify-content: center !important;
    margin-left: 25px;
    .ant-card-cover {
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.21);
      padding-bottom: 10px;
      background: #fff;
      height: 500px;
      width: 250px;
    }
  }
`;

const ArrowIcon = styled(Icon)`
  &&& {
    line-height: 450px;
    font-size: 24px;
    color: #000;
  }
`;

interface Props {
  screenshots: Array<any>;
}

const ScreenshotsSlider: React.FC<Props> = ({ screenshots }) => {
  const sliderRef = useRef(null) as any;

  return (
    <Field label="Submitted screenshots:" mb={26} mt={26}>
      {(screenshots || [])?.length > 0 ? (
        <Row>
          <Col span="2">
            <ArrowIcon
              type="arrow-left"
              onClick={() => sliderRef.current.next()}
            />
          </Col>
          <Col span="20">
            <Box justifyContent="center">
              <ScreenshotsCarousel
                ref={sliderRef}
                effect="fade"
                autoplay
                dots={true}
              >
                {(screenshots || []).map((value, i) => (
                  <ScreenshotCard
                    key={i}
                    cover={<img src={value?.previewUrl} alt="example" />}
                  ></ScreenshotCard>
                ))}
              </ScreenshotsCarousel>
            </Box>
          </Col>
          <Col span="2">
            <ArrowIcon
              type="arrow-right"
              onClick={() => sliderRef.current.next()}
            />
          </Col>
        </Row>
      ) : (
        <Empty description="No screenshots avaialble" />
      )}
    </Field>
  );
};

export default ScreenshotsSlider;
