import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Descriptions, Badge } from "antd";

export default class AccountDetails extends Component {
  render() {
    const { account } = this.props;

    return (
      <Descriptions size="middle" bordered>
        <Descriptions.Item label="Username" span={2}>
          {account.username}
        </Descriptions.Item>
        <Descriptions.Item label="Image">
          <img
            style={{ width: "30px" }}
            src={account.profile_pic_url}
            alt="img"
          />
        </Descriptions.Item>
        {account.influencer
          ? [
              <Descriptions.Item key="influencer" label="Influencer" span={3}>
                {account.influencer && (
                  <Link to={`/influencers/${account.influencer.id}`}>
                    {account.influencer.name}
                  </Link>
                )}
              </Descriptions.Item>,
            ]
          : []}
        <Descriptions.Item label="Profile" span={3}>
          {account.influencer && (
            <Link to={`/profiles/${account.id}`}>Profile</Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Fixed price" span={3}>
          {account.formattedPayout()}
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={3}>
          <Badge status="processing" text={account.status} />
        </Descriptions.Item>
        {/* <Descriptions.Item label="Profile Status" span={3}>
          <Tag
            color={
              account.profile_status === "Full Profile"
                ? "volcano"
                : account.profile_status === "Active Profile"
                ? "green"
                : "orange"
            }
          >
            {account.profile_status}
          </Tag>
        </Descriptions.Item> */}
        <Descriptions.Item label="Platform">{account.type}</Descriptions.Item>
        <Descriptions.Item label="Format" span={2}>
          {account.format}
        </Descriptions.Item>
        <Descriptions.Item label="Views" span={3}>
          {account.formattedViews()}
        </Descriptions.Item>
        <Descriptions.Item label="Tags" span={3}>
          {account.formattedTags()}
        </Descriptions.Item>
        <Descriptions.Item label="Age" span={3}>
          {account.formattedAges()}
        </Descriptions.Item>

        <Descriptions.Item label="Location" span={3}>
          {account.formattedLocations()}
        </Descriptions.Item>

        <Descriptions.Item label="Gender" span={3}>
          {account.formattedGender()}
        </Descriptions.Item>

        <Descriptions.Item label="Invite Count" span={3}>
          {account.formattedInviteCounts()}
        </Descriptions.Item>

        {account.type === "TikTok"
          ? [
              <Descriptions.Item key="followers" label="Followers" span={3}>
                {account.formattedFollowers()}
              </Descriptions.Item>,
              <Descriptions.Item key="avg_hearts" label="Avg. Hearts" span={3}>
                {account.formattedAvgHearts()}
              </Descriptions.Item>,
              <Descriptions.Item
                key="avg_comments"
                label="Avg. Comments"
                span={3}
              >
                {account.formattedAvgComments()}
              </Descriptions.Item>,
              <Descriptions.Item
                key="engagement_rate"
                label="Engagement Rate"
                span={3}
              >
                {account.formattedEngagementRate()}
              </Descriptions.Item>,
              <Descriptions.Item
                key="tiktok_profile"
                label="TikTok Profile"
                span={3}
              >
                <a
                  href={account.tiktokProfileUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {account.tiktokProfileUrl()}
                </a>
              </Descriptions.Item>,
            ]
          : account.type === "Instagram"
          ? [
              <Descriptions.Item key="followers" label="Followers" span={3}>
                {account.formattedFollowers()}
              </Descriptions.Item>,
              <Descriptions.Item key="avg_likes" label="Avg. Likes" span={3}>
                {account.formattedAvgLikes()}
              </Descriptions.Item>,
              <Descriptions.Item
                key="avg_comments"
                label="Avg. Comments"
                span={3}
              >
                {account.formattedAvgComments()}
              </Descriptions.Item>,
              <Descriptions.Item key="avg_ctr" label="Avg. CTR" span={3}>
                {account.formattedAvgCtr()}
              </Descriptions.Item>,
              <Descriptions.Item key="avg_itr" label="Conversion Rate" span={3}>
                {account.formattedAvgItr()}
              </Descriptions.Item>,
              <Descriptions.Item
                key="instagram_profile"
                label="Instagram Profile"
                span={3}
              >
                <a
                  href={account.instagramProfileUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {account.instagramProfileUrl()}
                </a>
              </Descriptions.Item>,
            ]
          : account.type === "Snapchat"
          ? [
              <Descriptions.Item key="avg_ctr" label="Avg. CTR" span={3}>
                {account.formattedAvgCtr()}
              </Descriptions.Item>,
              <Descriptions.Item key="avg_itr" label="Conversion Rate" span={3}>
                {account.formattedAvgItr()}
              </Descriptions.Item>,
            ]
          : account.type === "YouTube"
          ? [
              <Descriptions.Item key="followers" label="Followers" span={3}>
                {account.formattedFollowers()}
              </Descriptions.Item>,
              <Descriptions.Item key="avg_views" label="Avg. Views" span={3}>
                {account.formattedAvgViews()}
              </Descriptions.Item>,

              <Descriptions.Item
                key="engagement_rate"
                label="Engagement Rate"
                span={3}
              >
                {account.formattedEngagementRate()}
              </Descriptions.Item>,
              <Descriptions.Item
                key="youtube_profile"
                label="YouTube Profile"
                span={3}
              >
                <a
                  href={account.youtubeProfileUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {account.youtubeProfileUrl()}
                </a>
              </Descriptions.Item>,
            ]
          : []}
      </Descriptions>
    );
  }
}
