import React, { Component } from "react";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import FormField from "../FormField";
import { Checkbox } from "formik-antd";
import { TagList as $TagList, TagListModule } from "src/ducks/DataList";
import styled from "styled-components";

const VerticalCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    height: 30px;
    line-height: 30px;
    color: #595959;
    font-size: 14px;
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width
  }
`;

const TagList = $TagList("AccountTagList");

const modules = [TagListModule("AccountTagList")()];

export class AccountTag extends Component {
  componentDidMount() {
    const { loadTags } = this.props;
    loadTags({ per_page: 100, is_defined: true });
  }

  render() {
    const { data } = this.props;

    return (
      <>
        <FormField
          name={this.props.name || "tags"}
          label="Type of campaign"
          help="Please select tags that apply to this campaign"
        >
          <Checkbox.Group style={{display: 'flex', flexWrap: 'wrap'}}>
            {data.map((value, i) => (
              <VerticalCheckbox
                data-cy={`tags-checkbox-${i}`}
                key={i}
                value={value.id}
              >
                {value.name === "Business & Finance"
                  ? "Bus. & Fin."
                  : value.name}
              </VerticalCheckbox>
            ))}
          </Checkbox.Group>
        </FormField>
      </>
    );
  }
}

const mapStateToProps = state => ({
  data: TagList.selectMany(state),
});

const mapDispatchToProps = {
  loadTags: TagList.loadMany,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(AccountTag)
)(modules);
