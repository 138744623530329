// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { NewAccountFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import { Loading } from "src/components/Core/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  Username,
  Status,
  Influencer,
  Platform,
  Format,
  Cpm,
  Tags,
  Active,
  SalesAppropriative,
} from "./FormFields";

interface NewAccountFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  NewAccountSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  closeAccountModal?: () => void;
  tagsData: Array<any>;
  getInfluencers: Function;
}

export const NewAccountFormWrapper = styled.div`
  width: 100%;
`;

const NewAccountForm: React.FC<NewAccountFormProps> = ({
  initialValues,
  isSubmitting,
  NewAccountSchema,
  onSubmit,
  globalErrors,
  closeAccountModal,
  tagsData,
  getInfluencers,
}) => {
  return (
    <NewAccountFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={NewAccountSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                <Username />
                <Status />
                <Influencer
                  getInfluencers={getInfluencers}
                  formProps={formProps}
                />

                <Platform />
                <Format />
                <Cpm />
                <Tags tagsData={tagsData} />
                <Active />
                <SalesAppropriative />

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={20}
                >
                  <LinkButton
                    onClick={() => {
                      if (closeAccountModal) {
                        closeAccountModal();
                      }
                    }}
                    mr={20}
                  >
                    Cancel
                  </LinkButton>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    buttonSize="middle"
                    data-cy="submit-button"
                  >
                    Submit
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </NewAccountFormWrapper>
  );
};

export { NewAccountForm, NewAccountFormContainer };
