import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Table, Switch, Button } from "antd";
import { connectModal } from "redux-modal";
import TableManager from "src/ducks/TableManager";

class ExportModal extends Component {
  state = {
    columns: {},
  };

  onCheckColumn = (checked, row) => {
    this.setState({
      columns: {
        ...this.state.columns,
        [row.dataIndex]: checked,
      },
    });
  };

  render() {
    const { show, handleHide, handleSubmit, isSubmitting, data } = this.props;

    const columns = [
      {
        title: "Column name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Export?",
        render: (value, row, index) => (
          <Switch onChange={checked => this.onCheckColumn(checked, row)} />
        ),
        key: "visible",
      },
    ];

    return (
      <Modal
        title="Export columns"
        visible={show}
        onCancel={handleHide}
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Export
          </Button>,
        ]}
        {...this.props}
      >
        <Table
          columns={columns}
          rowKey="key"
          pagination={false}
          dataSource={data.filter(x => !!x.dataIndex)}
          size="small"
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const Manager = TableManager(props.moduleName);

  return {
    data: Manager.selectColumns(state),
  };
};

const mapDispatchToProps = {};

export default connectModal({
  name: "ExportModal",
  destroyOnHide: false,
})(connect(mapStateToProps, mapDispatchToProps)(ExportModal));
