// @ts-nocheck
import React, { useState } from "react";
import { InputProps } from "formik-antd";
import { InboxOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Upload } from "antd";
import { Field } from "formik";
import FieldError from "../FieldError";
import { UploadButton, PreviewWrapper } from "./styles";
import PreviewVideo from "../PreviewVideo";
import Caption from "../Caption";
import { Box } from "src/components/Core/Box";

type Props = InputProps & {
  isShowProgress?: boolean; // Its when showing Progress Circle
  isFeedback?: boolean; // If we get FeedbackList
};

const UploadDraft = (props: Props) => {
  const { isShowProgress, isFeedback } = props;
  const [isUploading, setIsUploading] = useState(false);

  const handleChange = (file, name, form, value) => {
    const { setFieldValue, setFieldTouched } = form;

    if (!file.preview) {
      file.preview = window.URL.createObjectURL(file);
    }

    setIsUploading(true);
    setTimeout(() => setIsUploading(false), 2000);

    const newFiles = value.concat(file);
    setFieldValue(name, newFiles);
    setFieldTouched(name, true);
  };

  const handleRemove = (file, name, form) => {
    const { setFieldValue, values } = form;
    let valueCopy = values[name];
    const index = valueCopy.indexOf(file);

    if (index > -1) {
      valueCopy.splice(index, 1);
    } else {
      valueCopy[file.uid].delete = true;
      valueCopy[file.uid].status = "error";
    }

    setFieldValue(name, valueCopy);
  };

  return (
    <Field {...props}>
      {({ field: { value, name }, form }) => (
        <div>
          {value.length === 0 ? (
            <Upload.Dragger
              accept="video/mp4,video/x-m4v,video/*, image/png, image/jpeg, image/*"
              showUploadList={false}
              beforeUpload={() => false}
              onChange={({ file }) => {
                handleChange(file, name, form, value);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
          ) : (
            <Box display="flex">
              <PreviewWrapper totalDraft={value.length} isFeedback={isFeedback}>
                {value &&
                  value.map((file: any, i) => {
                    return (
                      <PreviewVideo
                        key={i}
                        previewUrl={file?.preview}
                        type={file?.type}
                        onRemove={() => handleRemove(file, name, form)}
                      />
                    );
                  })}
              </PreviewWrapper>

              <UploadButton
                listType="picture-card"
                accept="video/mp4,video/x-m4v,video/*, image/png, image/jpeg, image/*"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={({ file }) => handleChange(file, name, form, value)}
                totalDraft={value.length}
                isUploading={isUploading}
              >
                <div data-cy="upload-screenshots">
                  <LegacyIcon type={isUploading ? "loading" : "plus"} />
                </div>
              </UploadButton>
            </Box>
          )}

          <br />

          {/* Preview Video Wrapper will be hide at the time of Process Circle and Thumb Feedback Screen*/}
          {!isShowProgress && (
            <>
              <Caption />

              <FieldError fieldName={name} />
            </>
          )}
        </div>
      )}
    </Field>
  );
};

export default UploadDraft;
