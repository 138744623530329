// @ts-nocheck
import React, { useState } from "react";
import { AccountTags } from ".";
import {
  useGetAllTagsQuery,
  useCreateNewTagMutation,
} from "src/generated/ApolloComponents";

interface Props {}

export const AccountTagsContainer: React.FC<Props> = () => {
  const [error, setError] = useState("");
  const [tagName, setTagName] = useState("");
  const tags = useGetAllTagsQuery();

  const allTags = tags?.data?.getAllTags || [];

  const [CreateNewTagMutation] = useCreateNewTagMutation();

  const onCreate = async () => {
    try {
      if (
        !allTags.find(
          item => (item?.name || "").toLowerCase() === tagName.toLowerCase()
        )
      ) {
        const result = await CreateNewTagMutation({
          variables: { tagName: tagName },
        });

        if (result?.data?.createNewTag?.id) {
          tags.refetch();
          setTagName("");
        } else {
          setError("some went wrong!");
        }
      } else {
        setError("Please enter unique tagname");
      }
    } catch (e) {}
  };

  return (
    <AccountTags
      allTags={allTags}
      onCreate={() => onCreate()}
      error={error}
      setError={setError}
      tagName={tagName}
      setTagName={setTagName}
    />
  );
};
