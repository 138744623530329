// @ts-nocheck
import React, { useState } from "react";
import { connect } from "react-redux";
import { show, hide } from "redux-modal";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box } from "src/components/Core/Box";
import { Layout } from "antd";
import HeroActions from "./HeroActions";
import { CampaignsTableContainer } from "./CampaignsTable";
import { ContentBox } from "./Wrapper";
import { CampaignsListProvider } from "./CampaignsListProvider";
import NewCampaignGroupModal from "./NewCampaignGroupModal";
import NewCampaignpModalWrapper from "./NewCampaignModalWrapper";
import CampaignFilterModal from "./CampaignFilterModal";
import FilterIcon from "./FilterIcon";

interface Props {
  showModal: Function; // from connect HOC
  hideModal: Function;
}

const CampaignsList: React.FC<Props> = ({ showModal, hideModal }) => {
  const [showNewCampaignGroupForm, setShowNewCampaignGroupForm] = useState(
    false
  );

  const [showFilterCampaignForm, setShowFilterCampaignForm] = useState(false);

  return (
    <DashboardLayout title="Campaigns">
      <CampaignsListProvider>
        <HeroActions
          title="Campaigns"
          openCampaignGroupModal={() => setShowNewCampaignGroupForm(true)}
          openCampaignModal={() => showModal("CreateCampaignModal")}
        />

        <Box p={15}>
          <Layout>
            <ContentBox>
              {!showFilterCampaignForm && (
                <FilterIcon
                  showCampaignFilterForm={() => setShowFilterCampaignForm(true)}
                />
              )}
              <CampaignsTableContainer />
            </ContentBox>
          </Layout>
        </Box>

        <NewCampaignGroupModal
          closeCampaignGroupModal={() => setShowNewCampaignGroupForm(false)}
          showNewCampaignGroupForm={showNewCampaignGroupForm}
        />

        <NewCampaignpModalWrapper
          closeCampaignModal={() => hideModal("CreateCampaignModal")}
        />

        <CampaignFilterModal
          closeCampaignFilterModal={() => setShowFilterCampaignForm(false)}
          showFilterCampaignForm={showFilterCampaignForm}
        />
      </CampaignsListProvider>
    </DashboardLayout>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  showModal: show,
  hideModal: hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsList);
