import React, { Component } from "react";
import FormField from "../FormField";
import { Rate } from "formik-antd";

export default class Professionalism extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "metadata.rating.professionalism"}
        label="Professionalism"
        help="Did your influencer conduct themsleves professinally throughout your campaign"
      >
        <Rate data-cy="account-professionalism" />
      </FormField>
    );
  }
}
