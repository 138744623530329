// @ts-nocheck
import React from 'react'
import ViewDetail from './ViewDetail'
import styled from 'styled-components'
import { values } from '../../constants'
import { Row, Card, Avatar, Button } from 'antd'
import { Typography } from 'src/components/Core/Typography'

const StyledCard = styled(Card)`
  cursor: pointer;
  margin: 10px !important;
  .ant-card {
    border-radius: 4px;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px;
    p {
      margin-bottom: 2px;
    }
  }
`

const StyledDiv = styled('div')`
  display: flex;
`

const { Meta } = Card;

interface Props {
  account: any
  moduleKey: string
  handleClick: (accId) => void
}

const Applicant = ({account, moduleKey, handleClick}: Props) => {
  const url = "http://res.cloudinary.com/dp05nvlmb/image/upload/v1592227820/staging/profile_pics/qd3k7urgi2pieapwnuho.png"
  const stats = {
    views: {
      label: 'Views',
      val: 'avgViews'
    },
    comments: {
      label: 'Comments',
      val: 'avgComments'
    },
    likes: {
      label: 'Likes',
      val: 'avgLikes'
    }
  }

  const getDescription = () => {
    return <Row justify="space-between">
      {['views', 'comments', 'likes'].map((key: string) =>
        <StyledDiv key={key}>
          <Typography size={10} color="grey6" mb={0}>{stats[key].label}:&nbsp;</Typography>
          <Typography size={10} mb={0}>
            {account?.metadata?.[stats[key].val] && Math.round(account?.metadata?.[stats[key].val])}
          </Typography>
        </StyledDiv>
      )}
    </Row>
  }

  return(
    <StyledCard>
      <Meta
        avatar={<Avatar src={account.profilePicUrl || url} />}
        title={<Typography size={12} color="black2">{account.username}</Typography>}
        description={account.id && getDescription()}
      />
      <ViewDetail account={account} />
      {account.id && values[moduleKey][account.applyStatus] &&
        <Button type="primary" disabled={values[moduleKey][account.applyStatus].disabled} onClick={() => handleClick(account.id)}>
          {values[moduleKey][account.applyStatus].label}
        </Button>
      }
      {!account.id &&
        <Button
          type="primary"
          disabled={values[account.status].disabled}
          onClick={() => handleClick(account)}
          style={{background: values[account.status].background}}
        >
          {values[account.status].label}
        </Button>
      }
    </StyledCard>
  )
}

export default Applicant
