import React, { Component } from "react";
import { connect } from "react-redux";
import { DraftPersist } from "src/ducks/Persist";
import { message } from "antd";
import DraftsFeedback from "./DraftsFeedback";
import { DraftCollapseWrapper } from "./DraftsFeedback/components";

const DraftManage = DraftPersist("InvitationModal");

export class DraftsCollapse extends Component {
  state = {
    isProcessing: false,
  };

  deleteDraft = async id => {
    const { destroyDraft, fetchInvitations } = this.props;
    try {
      await destroyDraft(id);
      await fetchInvitations();
    } catch (e) {
      message.error("Could not delete draft");
    }
  };

  render() {
    const { invitation, fetchInvitations } = this.props;

    return (
      <DraftCollapseWrapper>
        <DraftsFeedback
          invitation={invitation}
          deleteDraft={id => {
            this.deleteDraft(id);
          }}
          fetchInvitations={fetchInvitations}
        />
      </DraftCollapseWrapper>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  updateDraft: DraftManage.update,
  destroyDraft: DraftManage.destroy,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftsCollapse);
