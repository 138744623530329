// @ts-nocheck
import styled from "styled-components";
import {
  space,
  typography,
  TypographyProps as SSTypographyProps,
  SpaceProps,
  position,
  PositionProps,
  BorderProps,
  border,
} from "styled-system";
import { FontWeights, FontSizes, ThemeColors } from "src/utils/theme";

export interface TypographyProps {
  weight?: FontWeights;
  size?: FontSizes;
  color?: ThemeColors;
  lspace?: any;
  cursor?: string;
  lineHeight?: any;
  family?: string;
}

export const Typography = styled.p<
  TypographyProps & SSTypographyProps & SpaceProps & PositionProps & BorderProps
>`
  ${space}
  ${typography}
  ${position}
  ${border}
  font-size: ${props => props.size}px;
  font-weight: ${props =>
    props.weight && props.theme.typeography.fontWeights[props.weight]};
  line-height: ${props =>
    props.lineHeight || props.lineHeight === 0
      ? props.lineHeight
      : props.size && props.theme.typeography.lineHeights[props.size]};
  color: ${props => props.color && props.theme.colors[props.color]};
  letter-spacing: ${props => props.lspace}px;
  cursor: ${props => (props.cursor ? props.cursor : "context-menu")};
  word-break: break-word;
  font-family: ${props => props.family || 'inherit'}
`;
