// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";

interface Props {}

const Name: React.FC<Props> = () => {
  return (
    <Field label="Name of Milestone" mb={26} fontSize={14} labelBottom={0} color="black4">
      <FormikInput
        name="name"
        size="middle"
        placeholder="e.g. Guaranteed views"
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Name;
