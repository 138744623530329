// @ts-nocheck
import styled from "styled-components";
import Button from "src/components/Core/Button";
export const DraftButtonsWrapper = styled.div`
  position: relative;
  width: 100%;
  bottom: 0;
  padding-left: 8px;
`;

export const SubmitButton = styled(Button)`
  && {
    width: 88px !important;
    font-size: 20px;
    height: 40px !important;
  }
`;
