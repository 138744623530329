export const ad_formats = [
  { label: "Influencers", value: "Influencers" },
  { label: "Themed Pages", value: "Themed Pages" },
  // { label: "Cost Per Install", value: "CPI" },
  { label: "Lenses", value: "Lenses" },
  { label: "Content generation", value: "Content generation" },
];

export const distribution_platforms = [
  { label: "TikTok", value: "TikTok" },
  { label: "Snapchat", value: "Snapchat" },
  { label: "Instagram", value: "Instagram" },
  { label: "YouTube", value: "YouTube" },
];

export const objectives = [
  { label: "App Installs", value: "app" },
  // { label: "Music/Video Streams", value: "streams" },
  // { label: "Purchases", value: "purchases" },
  { label: "Awareness", value: "awareness" },
  { label: "Web Traffic", value: "web" },
  // { label: "Sign Ups", value: "signups" },
];

export const age_targets = [
  { label: "13 - 17", value: "13 - 17" },
  { label: "18 - 24", value: "18 - 24" },
  { label: "25+", value: "25+" },
];

export const gender_targets = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const location_targets = [
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "Australia", value: "AU" },
  { label: "Canada", value: "CA" },
  { label: "Austria", value: "AT" },
  { label: "Belgium", value: "BE" },
  { label: "Bulgaria", value: "BG" },
  { label: "Switzerland", value: "CH" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Croatia", value: "HR" },
  { label: "Germany", value: "DE" },
  { label: "Denmark", value: "DK" },
  { label: "Estonia", value: "EE" },
  { label: "Spain", value: "ES" },
  { label: "Faeroe Islands", value: "FO" },
  { label: "France", value: "FR" },
  { label: "Greece", value: "GR" },
  { label: "Hungary", value: "HU" },
  { label: "Ireland", value: "IE" },
  { label: "Iceland", value: "IS" },
  { label: "Italy", value: "IT" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Latvia", value: "LV" },
  { label: "Monaco", value: "MC" },
  { label: "Macedonia", value: "MK" },
  { label: "Malta", value: "MT" },
  { label: "Norway", value: "NO" },
  { label: "Netherlands", value: "NL" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "Sweden", value: "SE" },
  { label: "Slovenia", value: "SI" },
  { label: "Slovakia", value: "SK" },
  { label: "San Marino", value: "SM" },
  { label: "Turkey", value: "TR" },
  { label: "Ukraine", value: "UA" },
  { label: "Argentina", value: "AR" },
  { label: "Bolivia", value: "BO" },
  { label: "Brazil", value: "BR" },
  { label: "Chile", value: "CL" },
  { label: "Colombia", value: "CO" },
  { label: "Ecuador", value: "EC" },
  { label: "Falkland Islands", value: "FK" },
  { label: "Guyana", value: "GY" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Suriname", value: "SR" },
  { label: "Uruguay", value: "UY" },
  { label: "Venezuela", value: "VE" },
  { label: "Mexico", value: "MX" },
  { label: "India", value: "IN" },
  { label: "Philippines", value: "PH" },
  { label: "Indonesia", value: "ID" },
  { label: "Thailand", value: "TH" },
  { label: "Other", value: "other" },
];

export const integration_partners = [
  { label: "None", value: null },
  { label: "AppsFlyer", value: "appsflyer" },
  { label: "Adjust", value: "adjust" },
  { label: "Branch.io", value: "branch" },
  { label: "Tenjin", value: "tenjin" },
  { label: "Kochava", value: "kochava" },
  { label: "Singular", value: "singular" },
];

export const choise = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const influencer_kinds = [
  { label: "Life Style", value: "Life Style" },
  { label: "Gaming", value: "Gaming" },
  { label: "Cosplay", value: "Cosplay" },
  { label: "Comedy", value: "Comedy" },
  { label: "Sports", value: "Sports" },
  { label: "Beauty and Fashion", value: "Beauty and Fashion" },
  { label: "Others", value: "Others" },
];

export const status = [
  { value: "active", label: "Active" },
  { value: "draft", label: "Draft" },
  { value: "complete", label: "Completed" },
  { value: "submitted", label: "Under Review" },
];

export const analyricsColors = [
  "#91D5FF",
  "#1890FF",
  "#FFA39E",
  "#F5222D",
  "#FFD591",
  "#FA8C16",
  "#52C41A",
  "#87E8DE",
  "#13C2C2",
  "#ADC6FF",
  "#1890FF",
  "#F759AB",
];

export const public_statuses = [
  { value: true, label: "Public" },
  { value: false, label: "Private" }
]
