import { fk, attr, Model } from "redux-orm";
import moment from "moment";
import formatCurrency from "src/utils/formatCurrency";

class Payout extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const parsedData = {
      ...data,
    };

    return this.upsert(parsedData);
  }

  formattedPayout() {
    return formatCurrency(this.payout_pennies);
  }

  formattedPaidAt() {
    return this.paid_at ? moment(this.paid_at).format("Do MMM YYYY") : "";
  }

  formattedPaid() {
    return this.paid ? "Yes" : "No";
  }
}

Payout.modelName = "Payout";

// Declare your related fields.
Payout.fields = {
  id: attr(),
  influencer: fk("Influencer"),
};

export default Payout;
