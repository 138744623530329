import React, { Component } from "react"
import FormField from "../FormField"
import VerticalCheckbox from "./VerticalCheckbox"

export default class Public extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "is_public"}
        label="Public"
        help="Is the campaign public?"
      >
        <VerticalCheckbox>
          Public Campaign
        </VerticalCheckbox>
      </FormField>
    )
  }
}
