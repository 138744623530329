import axiosInstance from "../axiosInstance";

const GET_LINKS = `/links`;
const CREATE_LINK = `/links`;
const updateLink = id => `/links/${id}`;
const deleteLink = id => `/links/${id}`;

export const index = data => axiosInstance.get(GET_LINKS, data);

export const create = data => axiosInstance.post(CREATE_LINK, data);

export const destroy = id => axiosInstance.delete(deleteLink(id));

export const update = (id, data) => axiosInstance.patch(updateLink(id), data);
