// @ts-nocheck
import React from "react";
import { InfluencerDataForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useUpdateAccountBreakdownsByInvitationIdMutation,
  useGetSubmissionInvitationQuery,
} from "src/generated/ApolloComponents";
import _values from "lodash/values";
import sum from "lodash/sum";
import has from "lodash/has";
import { InfluencerDataSchema } from "./Validations";

interface Props {
  invitation: any;
}

interface File {
  id?: number;
  uploadUrl: string;
  uploadType: string;
  isDeleted?: boolean;
}

interface InfluencerDataFormValues {
  platformType?: string;
  screenshots: File[];
  ageBreakdown: Array<any>;
  genderBreakdown: Array<any>;
  locationBreakdown: Array<any>;
  tags: Array<number>;
}

export const InfluencerDataFormContainer: React.FC<Props> = ({
  invitation,
}) => {
  const invitationData = useGetSubmissionInvitationQuery({
    variables: {
      invitationId: invitation?.id.toString() as string,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const influencerData = invitationData?.data?.getSubmissionInvitation;

  const [
    updateAccountBreakdownsByInvitationIdMutation,
    { loading, error },
  ] = useUpdateAccountBreakdownsByInvitationIdMutation();

  let locationBreakdownData =
    influencerData?.account?.locationBreakdown?.manual || {};

  let locationBreakdownArray = Object.keys(locationBreakdownData).map(function(
    key
  ) {
    return {
      key: key,
      value: locationBreakdownData[key],
    };
  }) || [
    { key: "", value: 0 },
    { key: "", value: 0 },
    { key: "", value: 0 },
  ];

  if (locationBreakdownArray.length === 0) {
    locationBreakdownArray = [
      { key: "", value: 0 },
      { key: "", value: 0 },
      { key: "", value: 0 },
    ];
  }

  if (locationBreakdownArray.length === 1) {
    locationBreakdownArray = locationBreakdownArray.concat([
      { key: "", value: 0 },
      { key: "", value: 0 },
    ]);
  }

  if (locationBreakdownArray.length === 2) {
    locationBreakdownArray = locationBreakdownArray.concat([
      { key: "", value: 0 },
    ]);
  }

  let tagData = (influencerData?.account?.tags || []).map(
    (item: any, index) => {
      const newItem = { ...item };
      return newItem.id;
    }
  );

  let initialValues = {
    platformType: influencerData?.account?.type || "",
    screenshots:
      (
        (influencerData?.account?.screenshots || []).filter(
          x => !!x?.uploadUrl && !!x?.uploadType
        ) || []
      ).map(x => ({
        id: x?.id,
        uploadType: x?.uploadType,
        uploadUrl: x?.uploadUrl,
        previewUrl: x?.previewUrl,
      })) || [],
    ageBreakdown: influencerData?.account?.ageBreakdown?.manual || {},
    genderBreakdown: influencerData?.account?.genderBreakdown?.manual || {},
    locationBreakdown: locationBreakdownArray,
    tags: tagData,
  };

  const onSubmit = async (
    values: InfluencerDataFormValues,
    actions: FormikActions<InfluencerDataFormValues>
  ) => {
    try {
      const ageBreakdown = values?.ageBreakdown || {};

      const ageBreakdownData = Object.entries(ageBreakdown).reduce(
        (a, [k, v]) =>
          typeof v !== "undefined" && v !== "" && v !== null && v !== false
            ? { ...a, [k]: v }
            : a,
        {}
      );

      const genderBreakdown = values?.genderBreakdown || {};

      const genderBreakdownData = Object.entries(genderBreakdown).reduce(
        (a, [k, v]) =>
          typeof v !== "undefined" && v !== "" && v !== null && v !== false
            ? { ...a, [k]: v }
            : a,
        {}
      );

      let locationBreakdownData = values?.locationBreakdown.reduce(function(
        result,
        item
      ) {
        if (
          item.key !== "" &&
          typeof item.value !== "undefined" &&
          item.value !== "" &&
          item.value !== null &&
          item.value !== false
        ) {
          result[item.key] = item.value;
          return result;
        }
        return result;
      },
      {});

      let locationSum = sum(_values(locationBreakdownData));

      if (locationSum < 100 && !has(locationBreakdownData, "other")) {
        let otherLocationBreakdownData = { other: 100 - locationSum };

        locationBreakdownData = Object.assign(
          locationBreakdownData,
          otherLocationBreakdownData
        );
      }

      const formData = {
        invitationId: invitation?.id,
        ageBreakdown: ageBreakdownData,
        genderBreakdown: genderBreakdownData,
        locationBreakdown: locationBreakdownData,
        screenshots: values?.screenshots.map(({ previewUrl, ...rest }) => rest),
        tags: values?.tags,
      };

      const res = await updateAccountBreakdownsByInvitationIdMutation({
        variables: formData,
      });
      if (res?.data?.updateAccountBreakdownsByInvitationId?.id) {
        invitationData.refetch();
        window.location.reload();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(
    invitationData?.error || error
  );

  return (
    <InfluencerDataForm
      initialValues={initialValues}
      influencerDataSchema={InfluencerDataSchema}
      isSubmitting={invitationData?.loading || loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
    />
  );
};
