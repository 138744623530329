// @ts-nocheck
import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { CreateNewCampaignFormContainer } from "./CreateNewCampaignForm";

const NewCampaignModal = styled(Modal)`
  &&& {
    border-radius: 6px;
  }
`;

interface Props {
  showAddNewCampaign: boolean;
  closeAddNewCampaignModal: () => void;
  getCampaignForCampaignGroup: () => void;
}

const AddNewCampaignModal = ({
  closeAddNewCampaignModal,
  showAddNewCampaign,
  getCampaignForCampaignGroup,
}: Props) => {
  return (
    <NewCampaignModal
      title="Add new campaigns to your group"
      style={{
        maxWidth: 800,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={showAddNewCampaign}
      onCancel={closeAddNewCampaignModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <CreateNewCampaignFormContainer
        closeCreateNewCampaignModal={closeAddNewCampaignModal}
        getCampaignForCampaignGroup={getCampaignForCampaignGroup}
      />
    </NewCampaignModal>
  );
};

export default AddNewCampaignModal;
