import React from "react";
import { Link } from "react-router-dom";

export default {
  profile_pic_url: {
    title: "Image",
    dataIndex: "profile_pic_url",
    visible: true,
    width: 60,
    render: text => (
      <img alt="profile-img" style={{ height: "30px" }} src={text} />
    ),
  },
  username: {
    title: "Username",
    dataIndex: "username",
    visible: true,
    sorter: true,
    width: 210,
    ellipsis: true,
    render: (text, row) => <Link to={`/accounts/${row.id}`}>{text}</Link>,
  },
  influencer: {
    title: "Influencer",
    dataIndex: "influencer",
    visible: true,
    sorter: true,
    width: 120,
    render: (text, row) => (
      <Link to={`/influencers/${row.ref.influencer}`}>
        {row.influencer ? row.influencer.name : row.influencer}
      </Link>
    ),
  },
  status: {
    title: "Status",
    dataIndex: "status",
    visible: true,
    sorter: true,
    width: 100,
  },
  type: {
    title: "Platform",
    dataIndex: "type",
    visible: true,
    sorter: true,
    width: 90,
  },
  format: {
    title: "Format",
    dataIndex: "format",
    sorter: true,
    width: 80,
  },
  views: {
    title: "Views",
    dataIndex: "views",
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedViews(),
  },
  invite_sent_count: {
    title: "Invite Count",
    dataIndex: "invite_sent_count",
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedInviteCounts(),
  },
  ages: {
    title: "Ages",
    dataIndex: "ages",
    width: 100,
    render: (text, row) => row.formattedAges(),
  },
  gender_skew: {
    title: "Gender",
    dataIndex: "gender_skew",
    width: 100,
    render: (text, row) => row.formattedGender(),
  },
  locations: {
    title: "Locations",
    dataIndex: "locations",
    width: 150,
    render: (text, row) => row.formattedLocations(),
  },
  tags: {
    title: "Tags",
    dataIndex: "tags",
    width: 120,
    render: (text, row) => row.formattedTags(),
  },
  followers: {
    title: "Followers",
    dataIndex: "followers",
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedFollowers(),
  },
  avg_likes: {
    title: "Avg. Likes",
    dataIndex: "avg_likes",
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedAvgLikes(),
  },
  avg_hearts: {
    title: "Avg. Hearts",
    dataIndex: "avg_hearts",
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedAvgHearts(),
  },
  avg_ctr: {
    title: "Avg. CTR",
    dataIndex: "avg_ctr",
    sorter: true,
    width: 120,
    render: (text, row) => row.formattedAvgCtr(),
  },
  avg_comments: {
    title: "Avg. Comments",
    dataIndex: "avg_comments",
    sorter: true,
    width: 120,
    render: (text, row) => row.formattedAvgComments(),
  },
  engagement_rate: {
    title: "Engagements",
    dataIndex: "engagement_rate",
    visible: true,
    sorter: true,
    width: 120,
    render: (text, row) => row.formattedEngagementRate(),
  },
  gender_skew_percentage: {
    title: "Gender Breakdown",
    dataIndex: "gender_skew_percentage",
    width: 100,
    render: (text, row) => row.formattedGenderBreakdown(),
  },
  age_skew_percentage: {
    title: "Age Breakdown",
    dataIndex: "age_skew_percentage",
    width: 100,
    render: (text, row) => row.formattedAgeBreakdown(),
  },
  country_percentage: {
    title: "Country Breakdown",
    dataIndex: "country_percentage",
    width: 100,
    render: (text, row) => row.formattedLocationsBreakdown(),
  },
  last_campaign_date: {
    title: "Campaign Date",
    dataIndex: "last_campaign_date",
    visible: true,
    sorter: true,
    width: 120,
    render: (text, row) => row.formattedLastCampaignDate(),
  },
  last_1_months_campaigns: {
    title: "Campaigns Last 1 month",
    dataIndex: "last_1_months_campaigns",
    sorter: true,
    width: 110,
    render: (text, row) =>
      row?.completed_campaigns ? row?.completed_campaigns?.last_1_months : "-",
  },
  last_3_months_campaigns: {
    title: "Campaigns Last 3 months",
    dataIndex: "last_3_months_campaigns",
    sorter: true,
    width: 110,
    render: (text, row) =>
      row?.completed_campaigns ? row?.completed_campaigns?.last_3_months : "-",
  },
  ever_campaigns: {
    title: "Campaigns ever",
    dataIndex: "ever_campaigns",
    sorter: true,
    width: 110,
    render: (text, row) =>
      row?.completed_campaigns ? row?.completed_campaigns?.ever : "-",
  },
  last_paid_amount: {
    title: "Cost",
    dataIndex: "last_paid_amount",
    visible: true,
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedLastPaidAmount(),
  },
  avg_cpi: {
    title: "Avg. CPI",
    dataIndex: "avg_cpi",
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedAvgCpi(),
  },
  avg_cpc: {
    title: "Avg. CPC",
    dataIndex: "avg_cpc",
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedAvgCpc(),
  },
  cpm: {
    title: "CPM",
    dataIndex: "cpm",
    sorter: true,
    width: 100,
    render: (text, row) => row.formattedCpm(),
  },
};
