import { fk, Model } from "redux-orm";
import numeral from "numeral";
import formatCountries from "../../utils/formatCountries";
import { locations } from "src/constants/account";

class Influencer extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Agency } = this.session;

    const parsedData = {
      ...data,
      agency: Agency.parse(data.agency),
    };

    return this.upsert(parsedData);
  }

  formattedAges() {
    return this.detail?.age && this.detail?.age[0] ? this.detail?.age[0] : '';
  }

  formattedAgeBreakdown() {
    if (
      typeof this.detail != "undefined" &&
      this.detail !== null &&
      typeof this.detail.age_skew_percentage != "undefined" &&
      this.detail.age_skew_percentage !== null &&
      this.detail.age_skew_percentage !== "" &&
      Object.keys(this.detail.age_skew_percentage).length !== 0
    ) {
      const agePercentageKeys = Object.keys(this.detail.age_skew_percentage);

      let agePercentage = agePercentageKeys
        .filter((key, index) => {
          return this.detail.age_skew_percentage[key] !== null;
        })
        .map((key, index) => {
          return this.detail.age_skew_percentage[key] !== null
            ? `Age ${key} : ${numeral(this.detail.age_skew_percentage[key]).format(
              "0,0.00"
            )}`
            : "";
        });

      return agePercentage.length > 0 ? agePercentage.join(", ") : null;
    } else {
      return null;
    }
  }

  formattedLocations() {
    return this.detail?.location && this.detail?.location[0] ? formatCountries(this.detail?.location[0]) : '';
  }

  formattedLocationsBreakdown() {
    const countryName = locations.reduce(function(result, item) {
      result[item.value] = item.label;
      return result;
    }, {});

    if (
      typeof this.detail != "undefined" &&
      this.detail !== null &&
      typeof this.detail.country_percentage != "undefined" &&
      this.detail.country_percentage !== null &&
      this.detail.country_percentage !== "" &&
      Object.keys(this.detail.country_percentage).length !== 0
    ) {
      const locationPercentageKeys = Object.keys(
        this.detail.country_percentage
      );

      let locationPercentage = (locationPercentageKeys.slice(0, 5)).map((key, index) => {
        return `${countryName[key]} : ${numeral(this.detail.country_percentage[key]).format(
          "0,0.00"
        )}% `;
      });

      return locationPercentage.length > 0
        ? locationPercentage.join(", ")
        : null;
    } else {
      return null;
    }
  }

  formattedGender() {
    return this.detail?.gender && this.detail?.gender[0] ? this.detail?.gender[0] : '';
  }

  formattedGenderBreakdown() {
    if (
      typeof this.detail != "undefined" &&
      this.detail !== null &&
      typeof this.detail.gender_skew_percentage != "undefined" &&
      this.detail.gender_skew_percentage !== null &&
      this.detail.gender_skew_percentage !== "" &&
      Object.keys(this.detail.gender_skew_percentage).length !== 0
    ) {
      const genderPercentageKeys = Object.keys(
        this.detail.gender_skew_percentage
      );

      let genderPercentage = genderPercentageKeys
        .filter((key, index) => {
          return this.detail.gender_skew_percentage[key] !== null;
        })
        .map((key, index) => {
          return this.detail.gender_skew_percentage[key] !== null
            ? `${key} : ${numeral(this.detail.gender_skew_percentage[key]).format(
              "0,0.00"
            )}%`
            : "";
        });

      return genderPercentage.length > 0 ? genderPercentage.join(", ") : null;
    } else {
      return null;
    }
  }
}
Influencer.modelName = "Influencer";

// Declare your related fields.
Influencer.fields = {
  agency: fk("Agency"),
};

export default Influencer;
