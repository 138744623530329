// @ts-nocheck
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from 'src/shared/Loading'
import { Box } from 'src/components/Core/Box'
import { CopyOutlined } from '@ant-design/icons';
import { Input, Button, Row, Col } from 'antd';
import { Typography } from 'src/components/Core/Typography'

interface Props {
  campaign: any;
  loading?: boolean;
  partialUrl: string;
  suggestionUrl: string;
  handleCopy: () => void;
  handleSave: (pageTitle: string) => void;
}

const ShareSuggestion = ({
  campaign,
  loading,
  partialUrl,
  suggestionUrl,
  handleSave,
  handleCopy
}: Props) => {
  const [pageTitle, setPageTitle] = useState(campaign?.metadata?.page_title)
  return (
    <React.Fragment>
      <div>
        <Box borderBottom="1px solid #F1F1F1">
          <Typography
            size={14}
            weight="semi-bold"
            color="black"
            lineHeight={0}
          >
            Share suggestions
          </Typography>
        </Box>
        <Box mt={23}>
          <Typography
            size={14}
            weight="semi-bold"
            color="black"
            lineHeight={0}
            mt={10}
            pt={10}
          >
            Page title:
          </Typography>
          <Row gutter={16}>
            <Col span={16}>
              <Input placeholder="Campaign name" onChange={(e) => {setPageTitle(e.target.value)}} value={pageTitle} />
            </Col>
            <Button type="primary" onClick={() => handleSave(pageTitle)} disabled={pageTitle?.length < 1}>
              <Loading spinning={loading}>
                Save
              </Loading>
            </Button>
          </Row>
        </Box>
        <Box mt={23}>
          <Row align="bottom">
            <Col span={2}>
              <Box style={{marginBottom: '-3px'}}>
                <Typography
                  size={14}
                  weight="semi-bold"
                  color="black"
                  lineHeight={0}
                >
                  Link:
                </Typography>
              </Box>
            </Col>
            <Col span={14}>
              <Link to={`${partialUrl}`} target="_blank">
                {suggestionUrl}
              </Link>
            </Col>
            <Col span={1}>
              <CopyOutlined style={{color: '#BFBFBF'}} onClick={handleCopy} />
            </Col>
          </Row>
        </Box>
        <Box style={{marginTop: '7px'}} mb={26}>
          <Typography size={12}>
              You can use the link above to share a list of your suggested influencers. Recipients of the link can select influencers they like and this will be reflected on your invitations page.
          </Typography>
        </Box>
      </div>
    </React.Fragment>
  );

}

export default ShareSuggestion;
