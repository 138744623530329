import React, { Component } from "react";
import { Descriptions, Tag } from "antd";
import styled from "styled-components";
import CreativeDisplay from "../CreativeDisplay";
import moment from 'moment'
import { Link } from 'react-router-dom'

const HashTag = styled(Tag)`
  && {
    margin-top: 8px;
  }
`;

export default class CampaignReview extends Component {
  render() {
    const { campaign, style } = this.props;

    return (
      <div style={{ background: "#fff", ...style }}>
        <Descriptions data-cy="campaign-review-table" column={1} bordered>
          <Descriptions.Item data-cy="campaign-review-title" label="Title">
            {campaign.title}
          </Descriptions.Item>
          <Descriptions.Item data-cy="campaign-review-status" label="Status">
            {campaign.formattedStatus()}
          </Descriptions.Item>
          <Descriptions.Item
            data-cy="campaign-review-goal"
            label="Primary goal"
          >
            {campaign.formattedObjective()}
          </Descriptions.Item>
          <Descriptions.Item data-cy="campaign-review-format" label="Ad format">
            {campaign.formattedAdFormat()}
          </Descriptions.Item>
          <Descriptions.Item
            data-cy="campaign-review-platforms"
            label="Distribution Platforms"
          >
            {campaign.formattedDistributionPlatforms()}
          </Descriptions.Item>
          <Descriptions.Item data-cy="campaign-review-budget" label="Budget">
            {campaign.formattedBudget()}
          </Descriptions.Item>
          <Descriptions.Item
            data-cy="campaign-review-date"
            label="Campaign Duration"
          >
            {campaign.formattedStartDate()} - {campaign.formattedEndDate()}
          </Descriptions.Item>
          {campaign.ad_format !== "Content generation"
            ? [
                <Descriptions.Item
                  data-cy="campaign-review-ages"
                  label="Age target"
                  key="age-target"
                >
                  {campaign.formattedAges()}
                </Descriptions.Item>,
                <Descriptions.Item
                  data-cy="campaign-review-gender"
                  label="Gender target"
                  key="gender-target"
                >
                  {campaign.formattedGenders()}
                </Descriptions.Item>,
                <Descriptions.Item
                  data-cy="campaign-review-location"
                  label="Location target"
                  key="location-target"
                >
                  {campaign.formattedLocations()}
                </Descriptions.Item>,
                <Descriptions.Item
                  data-cy="campaign-review-tags"
                  label="Tags"
                  key="tags"
                >
                  {campaign.formattedTags()}
                </Descriptions.Item>,
                <Descriptions.Item
                  data-cy="campaign-review-link"
                  label="Tracking link"
                  key="tracking-link"
                >
                  {campaign.default_link}
                </Descriptions.Item>,
              ]
            : []}
          {campaign.integration_partner
            ? [
                <Descriptions.Item
                  data-cy="campaign-review-app-partner"
                  key="partner"
                  label="App partner"
                >
                  {campaign.formattedIntegrationPartner()}
                </Descriptions.Item>,
                <Descriptions.Item
                  data-cy="campaign-review-ios-identifer"
                  key="ios"
                  label="iOS identifier"
                >
                  {campaign.ios_id}
                </Descriptions.Item>,
                <Descriptions.Item
                  data-cy="campaign-review-android-identifer"
                  key="android"
                  label="Android identifier"
                >
                  {campaign.android_id}
                </Descriptions.Item>,
              ]
            : []}
          {campaign.creative
            ? [
                <Descriptions.Item
                  data-cy="campaign-review-creative"
                  key="creative"
                  label="Creative"
                >
                  <CreativeDisplay creative={campaign.creative} />
                </Descriptions.Item>,
              ]
            : []}

          <Descriptions.Item
            data-cy="campaign-review-about-brand"
            label="About Brand"
          >
            <div dangerouslySetInnerHTML={{ __html: campaign.about_brand }} />
          </Descriptions.Item>

          <Descriptions.Item data-cy="campaign-review-brief" label="Brief">
            <div dangerouslySetInnerHTML={{ __html: campaign.brief }} />
          </Descriptions.Item>

          <Descriptions.Item
            data-cy="campaign-review-briefdo"
            label="Content should include"
          >
            {campaign.brief_do.map((value, i) => (
              <p key={i} data-cy={`details-campaign-briefdo-.${i}`}>{`${i +
                1}. ${value}`}</p>
            ))}
          </Descriptions.Item>

          <Descriptions.Item
            data-cy="campaign-review-brief-donts"
            label="What not to say"
          >
            {campaign.brief_dont.map((value, i) => (
              <p key={i} data-cy={`details-campaign-brief-dont-.${i}`}>{`${i +
                1}. ${value}`}</p>
            ))}
          </Descriptions.Item>

          {campaign.distribution_platforms.indexOf("TikTok") > -1 && (
            <Descriptions.Item
              data-cy="campaign-review-hashtags"
              label="Hashtags"
            >
              {typeof campaign.hashtags !== "undefined" &&
                campaign.hashtags.length > 0 &&
                campaign.hashtags.map((value, index) => {
                  return (
                    <HashTag key={index}>
                      {`#${value.charAt(0).toUpperCase() + value.slice(1)}`}
                    </HashTag>
                  );
                })}
            </Descriptions.Item>
          )}

          <Descriptions.Item
            data-cy="campaign-review-hygiene-factor"
            label="Hygiene Factor"
          >
            {campaign.hygiene_factor >= 3 ? 3 : campaign.hygiene_factor}
          </Descriptions.Item>

          <Descriptions.Item
            data-cy="campaign-review-influener-kinds"
            label="Influencer Kinds"
          >
            {campaign.formattedInfluencerKinds()}
          </Descriptions.Item>

          <Descriptions.Item
            data-cy="campaign-reporting-link"
            label="Report Link"
          >
            {campaign?.report_link ? (
              <a
                href={campaign?.report_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {campaign?.report_link}
              </a>
            ) : (
              "-"
            )}
          </Descriptions.Item>

          <Descriptions.Item
            data-cy="campaign-reporting-link"
            label="Report Objective"
          >
            {campaign?.report_objective ? campaign.report_objective : "-"}
          </Descriptions.Item>
          <Descriptions.Item data-cy="hubspot-deal-id" label="Hubspot deal id">
            {campaign?.hubspot_deal_id ? campaign.hubspot_deal_id : "-"}
          </Descriptions.Item>
          <Descriptions.Item data-cy="hubspot-deal-id" label="Is public?">
            {campaign?.is_public ? "Yes" : "No"}
          </Descriptions.Item>
          {campaign?.is_public &&
            <Descriptions.Item data-cy="hubspot-deal-id" label="Public Deadline">
              {campaign?.public_deadline && moment(campaign?.public_deadline).format('MM/DD/YYYY')}
            </Descriptions.Item>
          }
          {campaign?.is_public &&
            <Descriptions.Item data-cy="hubspot-deal-id" label="Campaign Public URL">
              <Link to={{pathname: `${process.env.REACT_APP_INFLUENCER_URL}/campaigns/${campaign?.id}`}} target="_blank">
                {`${process.env.REACT_APP_INFLUENCER_URL}/campaigns/${campaign?.id}`}
              </Link>
            </Descriptions.Item>
          }
        </Descriptions>
      </div>
    );
  }
}
