// @ts-nocheck
import React from 'react'
import Wrapper from '../Wrapper'
import { TermsAndConditionsContainer } from './container'
import {
  GlobalErrorProps,
  GlobalErrors,
} from 'src/components/Forms/ErrorMessage'
import Auth from 'src/ducks/Auth'
import Loading from 'src/shared/Loading'
import { useSelector } from 'react-redux'
import { Visible } from 'react-grid-system'
import { Box } from 'src/components/Core/Box'
import { Typography } from 'src/components/Core/Typography'

interface Props {
  campaign: any
  loading?: boolean
  error?: GlobalErrorProps[]
}

const TermsAndConditions = ({campaign, loading, error}: Props) => {
  const user = useSelector(state => Auth.selectUser(state))
  const getLabelDetail = (label, value) => {
    return (
      <Box display="flex" flexWrap="wrap">
        <Typography
          size={16}
          weight="semi-bold"
          color="black"
          lineHeight="25px"
          mb={12}
        >
          {label}
        </Typography>

        <Typography size={16} color="black2" ml="5px" lineHeight="28px">
          {value}
        </Typography>
      </Box>
    );
  };
  return(
    <Wrapper>
      <Loading spinning={loading}>
        <GlobalErrors errors={error} />
        <Box p={24} maxWidth={1000}>
          <Visible xl lg>
            <Typography size={20} color="black6" lineHeight="20px">
              Contract
            </Typography>
          </Visible>

          <Typography size={16} color="black2">
            Read through the terms and conditions then submit your most recent
            screenshots and data to confirm your interest in the{" "}
            {campaign?.title} campaign
          </Typography>

          {campaign?.briefDocLink && (
            <>
              <Typography
                size={20}
                weight="semi-bold"
                color="black"
                lineHeight="25px"
                mb={12}
              >
                The brief
              </Typography>

              <Typography size={16} color="blue">
                <a
                  href={campaign?.briefDocLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {campaign?.briefDocLink}
                </a>
              </Typography>
            </>
          )}

          <Typography
            size={20}
            weight="semi-bold"
            color="black"
            lineHeight="25px"
            mb={20}
          >
            The legal part
          </Typography>

          <Box
            background="#FAFAFA"
            border="1px solid #E8E8E8"
            p={12}
            maxHeight="425px"
            overflow="auto"
          >
            {getLabelDetail(
              "Fanbytes Contact:",
              campaign?.executive?.email
                ? campaign?.executive?.email
                : campaign?.admin?.email
                ? campaign?.admin?.email
                : "--"
            )}

            <Typography
              size={16}
              weight="semi-bold"
              color="black"
              lineHeight="25px"
              mb={12}
            >
              Fanbytes details:
            </Typography>

            <Typography size={16} color="black2" mb={0}>
              Company name: Bandzie LTD
            </Typography>

            <Typography size={16} color="black2" mb={0}>
              Company Number: 08970768
            </Typography>
            <Typography size={16} color="black2" mb={0}>
              VAT No: 230413265
            </Typography>
            <Typography size={16} color="black2">
              Address: Elsley Court, 20-22 Great Titchfield Street, London, UK,
              W1W 8BE
            </Typography>

            {getLabelDetail(
              "Brand:",
              campaign?.company?.companyName
                ? campaign?.company?.companyName
                : "--"
            )}

            {getLabelDetail(
              "Talent name:",
              user?.name
            )}

            {/* {getLabelDetail(
              "Fee:",

              `${currencySign?.label}${
                fixedPayoutPennies
                  ? numeral(fixedPayoutPennies / 100).format(
                      "0,0[.]00"
                    )
                  : "--"
              }`
            )} */}

            {getLabelDetail("Term:", "30 days")}

            <Box>
              <Typography
                size={16}
                weight="semi-bold"
                color="black"
                lineHeight="25px"
                mb={12}
              >
                Creative control:
              </Typography>

              <Typography size={16} color="black2">
                Fanbytes permitted 2 x rounds of feedback on Talent-produced
                deliverables, in respect of factual inaccuracies and creative
                delivery.
              </Typography>
            </Box>

            <Box>
              <Typography
                size={16}
                weight="semi-bold"
                color="black"
                lineHeight="25px"
                mb={12}
              >
                Usage:
              </Typography>

              <Typography size={16} color="black2">
                The client has the right to organically share all Deliverables
                across their digital sites and social media accounts during the
                Term.
              </Typography>

              <Typography size={16} color="black2">
                No media spend to be placed behind any Deliverables. If the
                client wants to use content for paid promotions, this will be
                communicated to the talent in order to discuss the required fee
                and purchase the Intellectual Property rights of the
                deliverables from the Influencer. In so doing, the Influencer
                relinquishes their Moral Rights to the content.
              </Typography>
            </Box>

            <Box>
              <Typography
                size={16}
                weight="semi-bold"
                color="black"
                lineHeight="25px"
                mb={12}
              >
                Commercial exclusivity:
              </Typography>

              <Typography size={16} color="black2">
                No commercial exclusivity, talent is free to work with other
                brands for which they receive a fee.
              </Typography>
            </Box>

            <Box>
              <Typography
                size={16}
                weight="semi-bold"
                color="black"
                lineHeight="25px"
                mb={12}
              >
                Liability:
              </Typography>

              <Typography size={16} color="black2">
                Influencer’s maximum aggregate liability under or in connection
                with this Agreement (including any indemnity contained in this
                Agreement), whether in contract, tort (including negligence) or
                otherwise, shall in no circumstances exceed the level of the Fee
                paid to Influencer hereunder.
              </Typography>
            </Box>

            <Box>
              <Typography
                size={16}
                weight="semi-bold"
                color="black"
                lineHeight="25px"
                mb={12}
              >
                Termination:
              </Typography>

              <Typography size={16} color="black2">
                This partnership will commence once both parties have signed.
                This agreement can only be terminated upon written notice in the
                event of the below:
              </Typography>

              <Typography size={16} color="black2">
                (a) any breach of this Agreement by the other Party which breach
                is not remediable or, if remediable, is not remedied within
                twenty one (21) days after the service by the Party not in
                default of a written notice on the other Party, specifying the
                nature of the breach and requiring that the same be remedied; or
              </Typography>

              <Typography size={16} color="black2">
                (b) the other Party becoming insolvent, entering into
                liquidation, whether voluntary or compulsory, passing a
                resolution for its winding up, having a receiver or
                administrator appointed over the whole or any part of its
                assets, making any composition or arrangement with its creditors
                or taking or suffering any similar action in consequence of its
                debt.
              </Typography>

              <Typography size={16} color="black2">
                (c) If Bandzie LTD or any of their products become the subject
                of any adverse publicity of a real and substantial basis which{" "}
                <strong>{user?.name}</strong> ,
                acting reasonably, believes may damage his/her professional
                reputation{" "}
                <strong>{user?.name}</strong> may
                cancel this Agreement and all of its terms with immediate effect
                and Bandzie LTD will cease all use of his name or image granted
                under this Agreement.
              </Typography>

              <Typography size={16} color="black2">
                (d) If <strong>{user?.name}</strong>{" "}
                becomes the subject of any adverse publicity of a real and
                substantial basis which Bandzie LTD, acting reasonably, believes
                may damage their professional reputation, Bandzie LTD may cancel
                this Agreement and all of its terms with immediate effect and
                Bandzie LTD will cease all use of their name or image granted
                under this Agreement.
              </Typography>
              <Typography size={16} color="black2">
                If for any reason{" "}
                <strong>{user?.name}</strong> does
                not post/fulfil deliverables, he/she will be required to arrange
                returns of any received products. In the event that products are
                not returned, Bandzie LTD may be required to share his/her
                address with the client’s legal team in order for the client to
                recover the cost of goods.
              </Typography>
            </Box>

            <Box>
              <Typography
                size={16}
                weight="semi-bold"
                color="black"
                lineHeight="25px"
                mb={12}
              >
                Contract terms:
              </Typography>

              <Typography size={16} color="black2">
                This Agreement shall be governed by and construed in accordance
                with the law of England and Wales. Each Party irrevocably
                submits for all purposes in connection with this Agreement to
                the exclusive jurisdiction of the courts of England and Wales.
              </Typography>
            </Box>
            {campaign?.additionalTerms &&
              <Box>
                <Typography
                  size={16}
                  weight="semi-bold"
                  color="black"
                  lineHeight="25px"
                  mb={12}
                >
                  Additional terms:
                </Typography>

                <Typography size={16} color="black2">
                    {campaign?.additionalTerms}
                </Typography>
              </Box>
            }
          </Box>
        </Box>
      </Loading>
    </Wrapper>
  )
}

export { TermsAndConditionsContainer, TermsAndConditions }
