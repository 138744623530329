// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { Row, Col } from "antd";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { PersonalDetailFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { Age, Gender, Location } from "../../../components/Fields";
import { Visible, useScreenClass } from "react-grid-system";

interface PersonalDetailFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  personalDetailSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  accountData: any;
}

export const PersonalDetailFormWrapper = styled.div`
  width: 100%;
`;

const PersonalDetailForm: React.FC<PersonalDetailFormProps> = ({
  initialValues,
  isSubmitting,
  personalDetailSchema,
  onSubmit,
  globalErrors,
  accountData,
}) => {
  const screenClass = useScreenClass();

  return (
    <PersonalDetailFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Visible xs sm md>
          <Box borderBottom="1px solid #1890FF">
            <Typography
              size={16}
              color="blue"
              lineHeight="20px"
              textAlign="center"
            >
              1. Personal details
            </Typography>
          </Box>
        </Visible>

        <Box p={24}>
          <Visible xl lg>
            <Typography size={20} color="black3" lineHeight="20px">
              Personal details
            </Typography>
          </Visible>

          <Typography size={16} color="grey2">
            Please enter the following details about yourself:
          </Typography>

          <Form
            initialValues={initialValues}
            schema={personalDetailSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {(formProps: any) => {
              return (
                <>
                  <Row gutter={12}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Gender />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Age />
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Location />
                    </Col>
                  </Row>

                  <Box
                    display="flex"
                    flexDirection={
                      screenClass === "xs" || screenClass === "sm"
                        ? "column"
                        : "row"
                    }
                    mt={20}
                  >
                    <Button
                      htmlType="submit"
                      type="action"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      buttonSize="middle"
                      data-cy="submit-button"
                    >
                      Next
                    </Button>
                  </Box>
                </>
              );
            }}
          </Form>
        </Box>
      </Loading>
    </PersonalDetailFormWrapper>
  );
};

export { PersonalDetailForm, PersonalDetailFormContainer };
