// @ts-nocheck
import * as React from "react";

export interface bankAccount {
  fullName: string;
  accountNumber: string;
  sortCode: string;
}

export interface paymentDetail {
  paypalEmail: string;
  bankAccount: bankAccount;
}

interface ContextType {
  selectedInfluencer?: any;
  actualPaymentDetails?: any;
  existingPaymentDetails?: any;
  selectedAgency?: any;
  actions: {
    setSelectedInfluencer: (value: any) => void;
    setActualPaymentDetails: (value: any) => void;
    setExistingPaymentDetails: (value: any) => void;
    setSelectedAgency: (value: any) => void
  };
}

const PaymentDetailsContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our payment detail context
export function usePaymentDetails() {
  const context = React.useContext(PaymentDetailsContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
const PaymentDetailsProvider = React.memo(props => {
  // export function PaymentDetailsProvider(props) {
  const [actualPaymentDetails, setActualPaymentDetails] = React.useState({});
  const [existingPaymentDetails, setExistingPaymentDetails] = React.useState(
    {}
  );
  const [selectedInfluencer, setSelectedInfluencer] = React.useState({});
  const [selectedAgency, setSelectedAgency] = React.useState({});

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      actualPaymentDetails,
      existingPaymentDetails,
      selectedInfluencer,
      selectedAgency,
      actions: {
        setActualPaymentDetails,
        setExistingPaymentDetails,
        setSelectedInfluencer,
        setSelectedAgency,
      },
    };
    return internalVal;
  }, [actualPaymentDetails, existingPaymentDetails, selectedInfluencer, selectedAgency]);

  return (
    <PaymentDetailsContext.Provider value={value}>
      {props.children}
    </PaymentDetailsContext.Provider>
  );
});

export { PaymentDetailsProvider };
