// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { Box } from "src/components/Core/Box";
import { Field } from "src/components/Forms/Field";
import { FilterProps } from "../../PaymentQueueList";

const StatusCheckbox = styled("div")`
  &&& {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px !important;
    }
  }
`;

interface Props {
  handleChange: (key: string, value: any) => void;
  filters: FilterProps;
}

const Status: React.FC<Props> = ({ handleChange, filters }) => {
  return (
    <Field label="Status:">
      <Box display="block">
        <StatusCheckbox>
          <Checkbox
            value="pending"
            onChange={value => {
              let filterStatus = filters?.status || [];
              const index = filterStatus.indexOf("pending");
              if (index === -1) {
                filterStatus.push("pending");
              } else {
                filterStatus.splice(index, 1);
              }

              handleChange("status", [...filterStatus]);
            }}
            checked={(filters?.status || []).indexOf("pending") !== -1}
          >
            Pending
          </Checkbox>
          <Checkbox
            value="approved"
            onChange={value => {
              let filterStatus = filters?.status || [];
              const index = filterStatus.indexOf("approved");
              if (index === -1) {
                filterStatus.push("approved");
              } else {
                filterStatus.splice(index, 1);
              }

              handleChange("status", [...filterStatus]);
            }}
            checked={(filters?.status || []).indexOf("approved") !== -1}
          >
            Approved
          </Checkbox>
          <Checkbox
            value="rejected"
            onChange={value => {
              let filterStatus = filters?.status || [];
              const index = filterStatus.indexOf("rejected");
              if (index === -1) {
                filterStatus.push("rejected");
              } else {
                filterStatus.splice(index, 1);
              }

              handleChange("status", [...filterStatus]);
            }}
            checked={(filters?.status || []).indexOf("rejected") !== -1}
          >
            Rejected
          </Checkbox>

          <Checkbox
            value="reconciled"
            onChange={value => {
              let filterStatus = filters?.status || [];
              const index = filterStatus.indexOf("reconciled");
              if (index === -1) {
                filterStatus.push("reconciled");
              } else {
                filterStatus.splice(index, 1);
              }

              handleChange("status", [...filterStatus]);
            }}
            checked={(filters?.status || []).indexOf("reconciled") !== -1}
          >
            Reconciled
          </Checkbox>
        </StatusCheckbox>
      </Box>
    </Field>
  );
};

export default Status;
