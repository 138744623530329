import React, { Component } from "react";
import { Descriptions } from "antd";

export default class InfluencerDetails extends Component {
  render() {
    const { influencer } = this.props;

    return influencer ? (
      <Descriptions size="middle" bordered>
        <Descriptions.Item label="Name" span={1}>
          {influencer.name}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={2}>
          {influencer.email}
        </Descriptions.Item>
        <Descriptions.Item label="Phone" span={3}>
          {influencer.phone_number}
        </Descriptions.Item>
        <Descriptions.Item label="Address" span={3}>
          {influencer.detail.address}
        </Descriptions.Item>
      </Descriptions>
    ) : null;
  }
}
