import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withFormik } from "formik"
import { message } from "antd"
import { Form, Input, SubmitButton } from "formik-antd"
import Auth from "src/ducks/Auth"
import FormField from "src/shared/FormField"
import { BrandPersist as $BrandPersist, BrandPersistModule } from "src/ducks/Persist"
import withModules from "src/utils/hoc/withModules"

const modules = [BrandPersistModule("BrandSettings")()]

const BrandPersist = $BrandPersist("BrandSettings")

class ProfileForm extends Component {
  render() {
    const { handleSubmit } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <FormField name="name" label="Name">
          <Input size="large" placeholder="Name" name="name" />
        </FormField>
        <FormField name="email" label="Email">
          <Input size="large" placeholder="Email" name="email" />
        </FormField>
        <SubmitButton size="large" type="primary" disabled={false} block>
          Update profile
        </SubmitButton>
      </Form>
    )
  }
}

const EnhancedProfileForm = withFormik({
  mapPropsToValues: ({ currentUser }) => currentUser,

  validate: (values) => {
    const errors = {}

    if (!values.name) {
      errors.name = "Fill in your name"
    }

    if (!values.email) {
      errors.email = "Fill in an email"
    }

    return errors
  },

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props
      .updateUser(props.currentUser.id, values)
      .then(() => {
        message.success("Profile updated")
        setSubmitting(false)
      })
      .catch((res) => {
        message.error("Failed to update profile")
        setErrors(res.response.data.meta.errors)
        setSubmitting(false)
      })
  },

  displayName: "BrandSettingsForm"
})(ProfileForm)

const mapStateToProps = (state) => ({
  submitting: Auth.selectLoading(state),
  currentUser: Auth.selectUser(state)
})

const mapDispatchToProps = {
  updateUser: BrandPersist.update
}

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EnhancedProfileForm))
)(modules)
