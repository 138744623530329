import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Card, Row, Col, Statistic, Spin } from "antd";
import Container from "src/shared/Container";
import CampaignManage from "src/ducks/CampaignManage";
import { show } from "redux-modal";
import SuggestionsModal from "./SuggestionsModal";
import SuggestedInfluencer from "./SuggestedInfluencer";
import { AccountsList } from "src/components/Modules/Accounts/AccountsList";
import { Box } from "src/components/Core/Box";

const FullHeightCard = styled(Card)`
  height: 100%;
`;

export class FindInfluencers extends Component {
  state = {
    contentType: "list",
  };

  componentDidMount() {
    const { getPackage, campaign } = this.props;
    getPackage(campaign.id);
  }

  render() {
    const { campaign_package, show, campaign } = this.props;

    return (
      <Container>
        <SuggestedInfluencer campaign={campaign} />
        <Spin spinning={!campaign_package}>
          <Row
            gutter={16}
            style={{
              marginTop: 24,
              flexWrap: "nowrap",
            }}
          >
            <Col style={{ flexGrow: 1, flexBasis: 0 }}>
              <FullHeightCard>
                <Statistic
                  title="Suggested Views"
                  value={campaign_package && campaign_package.suggested_views}
                />
                <Box
                  color="blue"
                  style={{ cursor: "pointer" }}
                  onClick={() => show("SuggestionsModal")}
                >
                  View Suggested
                </Box>
              </FullHeightCard>
            </Col>
            <Col style={{ flexGrow: 1, flexBasis: 0 }}>
              <FullHeightCard data-cy="invited-views">
                <Statistic
                  title="Invited Views"
                  value={campaign_package && campaign_package.invited_views}
                />
              </FullHeightCard>
            </Col>
            <Col style={{ flexGrow: 1, flexBasis: 0 }}>
              <FullHeightCard data-cy="invited-spend">
                <Statistic
                  title="Invited Spend"
                  value={
                    campaign_package && campaign_package.formattedInvitedSpend()
                  }
                />
              </FullHeightCard>
            </Col>
            <Col style={{ flexGrow: 1, flexBasis: 0 }}>
              <FullHeightCard>
                <Statistic
                  title="Accepted Views"
                  value={campaign_package && campaign_package.accepted_views}
                />
              </FullHeightCard>
            </Col>
            <Col style={{ flexGrow: 1, flexBasis: 0 }}>
              <FullHeightCard>
                <Statistic
                  title="Accepted Spend"
                  value={
                    campaign_package &&
                    campaign_package.formattedAcceptedSpend()
                  }
                />
              </FullHeightCard>
            </Col>
          </Row>
        </Spin>

        {/* <AccountsTable style={{ marginTop: 24 }} /> */}
        <Box mt={24}>
          <AccountsList
            isInnerAccountList={true}
            campaign={campaign}
            contentType={this.state.contentType}
            setContentType={type => {
              this.setState({ contentType: type });
            }}
          />
        </Box>

        <SuggestionsModal />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
  campaign_package: CampaignManage.package.selectOne(state),
});

const mapDispatchToProps = {
  getPackage: CampaignManage.package.get,
  show,
};

export default connect(mapStateToProps, mapDispatchToProps)(FindInfluencers);
