// @ts-nocheck
import React from "react"
import { Typography } from "src/components/Core/Typography"
import { Link } from "react-router-dom"
import { Button } from "src/components/Core/Button"
import { Avatar } from "antd"
import compact from "lodash/compact"
import styled from "styled-components"
import Duration from "./Duration"

const VideoImg = styled(Avatar)`
  &&& {
    img {
      object-fit: contain;
    }
  }
`

const getColumns = (setShowAdUpload, setDraftId) => {
  const columns = [
    {
      title: "Media",
      dataIndex: "media",
      key: "media",
      width: 30,
      render: (text, row) => {
        let fileExtension = row?.draftVideoUrl?.substr(row?.draftVideoUrl?.lastIndexOf(".") + 1)

        return (
          <a href={row?.draftVideoUrl} target="_blank" rel="noopener noreferrer">
            <VideoImg
              shape="square"
              size="large"
              src={fileExtension === "mp4" ? row?.draftVideoUrl.replace("mp4", "png") : `${row?.draftVideoUrl}.png`}
            />
          </a>
        )
      }
    },

    {
      title: "Account",
      dataIndex: "account",
      key: "account",
      width: 120,
      render: (text, row) => (
        <Link to={`/accounts/${row?.accountId}`}>
          <Typography size={12} cursor="pointer" mb={0}>
            {row?.accountName ? row?.accountName : "-"}
          </Typography>
        </Link>
      )
    },

    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: 90,
      render: (text, row) => <Duration videoUrl={row?.draftVideoUrl} />
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 90,
      render: (text, row) => (
        <Button
          type="action"
          buttonSize="small"
          data-cy="submit-button"
          onClick={() => {
            setDraftId(row?.draftId)
            setShowAdUpload(true)
          }}
        >
          Upload
        </Button>
      )
    }
  ]

  return compact(columns)
}

export default getColumns
