// @ts-nocheck
import React from "react";
import { DemographicScreenshotsContainer } from "./container";
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from "antd";
import FieldError from "../../../../components/Fields/FieldError";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";

export const ScreenshotsWrapper = styled.div`
  &&& {
    width: 100%;
    .ant-upload.ant-upload-drag {
      min-height: 350px;
    }
  }
`;

type ScreenshotsProps = {
  name: string;
  fileList: Array<any>;
  handleRemove: (value: any) => void;
  onUpload: (value: any) => void;
  multiple: boolean;
  disabled: boolean;
  uploadText?: string;
};

const DemograhicScreenshots: React.FC<ScreenshotsProps> = ({
  handleRemove,
  onUpload,
  fileList,
  multiple,
  disabled,
  name,
  uploadText,
}) => {
  return (
    <ScreenshotsWrapper data-cy={`input-${name}-Screenshots`}>
      <Upload.Dragger
        multiple={multiple}
        disabled={disabled}
        accept="image/*"
        fileList={fileList}
        customRequest={file => onUpload(file)}
        onRemove={file => handleRemove(file)}
      >
        <Box p={10} mt={60}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            {uploadText
              ? uploadText
              : "Please provide your analyitics snapshot."}{" "}
          </p>
        </Box>
      </Upload.Dragger>
      <FieldError fieldName={name} />
    </ScreenshotsWrapper>
  );
};

export { DemograhicScreenshots, DemographicScreenshotsContainer };
