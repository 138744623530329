import React, { Component } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import LoginLayout from "../../layouts/LoginLayout";
import CompleteForm from "./CompleteForm";
import Auth from "src/ducks/Auth";

export class Forgot extends Component {
  componentDidMount() {
    const {
      match: { params },
      history,
      loadUser
    } = this.props;

    loadUser(params.id).catch(() => {
      history.push("/sign_in");
      message.error("Incorrect confirmation link!");
    });
  }

  render() {
    const {
      match: { params }
    } = this.props;

    return (
      <LoginLayout title="Complete your account">
        <CompleteForm confirmationToken={params.id} />
      </LoginLayout>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loadUser: Auth.loadUserByConfirmationToken
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forgot);
