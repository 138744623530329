// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Tag } from "src/components/Core/Tag";

interface Props {
  tags: any;
  setHoverTagText: (value: string) => void;
}

const Tags: React.FC<Props> = ({ tags, setHoverTagText }) => {
  const loadTag = (text, i) => {
    // check the weapon text and height that tag
    return (
      <Box
        onMouseOver={() => setHoverTagText(text)}
        onMouseOut={() => setHoverTagText("")}
        display="inline-flex"
        key={i}
      >
        <Tag
          size={12}
          tagColor={
            text.toLowerCase().indexOf("contain") !== -1 ? "white" : "black"
          }
          background={
            text.toLowerCase().indexOf("contain") !== -1
              ? "red5"
              : text.toLowerCase().indexOf("possible") !== -1
              ? "red3"
              : "white"
          }
          borderColor={
            text.toLowerCase().indexOf("contain") !== -1 ||
            text.toLowerCase().indexOf("possible") !== -1
              ? "red8"
              : "grey6"
          }
          borderRadius="26px"
          style={{ padding: "5px 15px" }}
        >
          {text}
        </Tag>
      </Box>
    );
  };
  return (
    <Box mt={10}>
      <Typography size={24} color="black">
        Tags
      </Typography>

      {(tags?.image || []).length > 0 && (
        <Box>
          <Typography size={12} color="grey7" lineHeight={0}>
            Image
          </Typography>

          {(tags?.image || []).map((text, i) => {
            return loadTag(text, i);
          })}
        </Box>
      )}

      {(tags?.text || []).length > 0 && (
        <Box mt={30}>
          <Typography size={12} color="grey7" lineHeight={0}>
            Text
          </Typography>

          {(tags?.text || []).map((text, i) => (
            <Tag
              key={i}
              size={12}
              tagColor="black"
              background="white"
              borderColor="grey6"
              borderRadius="26px"
              style={{ padding: "5px 15px" }}
            >
              {text}
            </Tag>
          ))}
        </Box>
      )}

      {(tags?.video || []).length > 0 && (
        <Box mt={30}>
          <Typography size={12} color="grey7" lineHeight={0}>
            Video
          </Typography>

          {(tags?.video || []).map((text, i) => (
            <Tag
              key={i}
              size={12}
              tagColor="black"
              background="white"
              borderColor="grey6"
              borderRadius="26px"
              style={{ padding: "5px 15px" }}
            >
              {text}
            </Tag>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Tags;
