// @ts-nocheck
import React, { useState, useEffect } from "react";
import { DemographicGendersGraph } from ".";
import numeral from "numeral";
import { colors } from "src/constants/account";
import { useGetGenderBreakdownByAccountIdQuery } from "src/generated/ApolloComponents";

interface Props {
  doRefresh?: boolean; // when Manual data modal add gender, graph should be reload
  setDoRefresh?: () => void;
  accountId: string;
}

export const DemographicGendersGraphContainer: React.FC<Props> = ({
  doRefresh,
  setDoRefresh,
  accountId,
}) => {
  const [chartDataType, setChartDataType] = useState("live");

  const { data, loading, refetch } = useGetGenderBreakdownByAccountIdQuery({
    variables: {
      accountId: accountId,
    },
  });

  useEffect(() => {
    refetch({
      accountId: accountId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    if (doRefresh) {
      refetch({
        accountId: accountId,
      });
      setDoRefresh && setDoRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRefresh]);

  let graphData = data?.getGenderBreakdownByAccountId || {};

  let newGraphData = [] as any;
  let isShowFilter = false;

  if (graphData["live"] && graphData["manual"]) {
    newGraphData = graphData[chartDataType] || [];
    isShowFilter = true;
  } else if (graphData["live"]) {
    newGraphData = graphData["live"];
  } else if (graphData["manual"]) {
    newGraphData = graphData["manual"];
  }

  newGraphData = newGraphData.map((item: any, index) => {
    const newItem = { ...item };
    newItem.color = colors[index];
    newItem.message = `${newItem.key} : ${numeral(newItem.value).format(
      "0,0.00"
    )}%`;
    return newItem;
  });

  return (
    <DemographicGendersGraph
      loading={loading}
      data={newGraphData}
      chartDataType={chartDataType}
      setChartDataType={value => setChartDataType(value)}
      isShowFilter={isShowFilter}
    />
  );
};
