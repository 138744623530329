import React from "react"
import { Select } from "formik-antd"
import SearchSelect from "src/shared/SearchSelect"
import { Tag } from "src/api/endpoints"
import { types, ages, locations, statuses, genders, formats, actives } from "src/constants/account"

export default {
  type: {
    input: () => (
      <Select style={{ width: '100%' }} size="large" data-cy="accounttotal-platform" placeholder="Select..." mode="multiple" options={types}>
      </Select>
    ),
    label: "Type",
    span: 12,
  },
  status: {
    input: () => (
      <Select style={{ width: '100%' }} size="large" placeholder="Select..." mode="multiple" options={statuses}>
      </Select>
    ),
    label: "Status",
    span: 12,
  },
  age_skew: {
    input: () => (
      <Select style={{ width: '100%' }} size="large" placeholder="Select..." mode="multiple" options={ages}>
      </Select>
    ),
    label: "Ages",
    span: 12,
  },
  gender_skew: {
    input: () => (
      <Select style={{ width: '100%' }} size="large" placeholder="Select..." mode="multiple" options={genders}>
      </Select>
    ),
    label: "Genders",
    span: 12,
  },
  format: {
    input: () => (
      <Select style={{ width: '100%' }} size="large" placeholder="Select..." mode="multiple" options={formats}>
      </Select>
    ),
    label: "Formats",
    span: 12,
  },
  location: {
    input: () => (
      <SearchSelect size="large" placeholder="Select..." mode="multiple" data={locations} local />
    ),
    label: "Locations",
    span: 12,
  },
  verticals: {
    input: () => (
      <SearchSelect
        size="large"
        placeholder="Select..."
        mode="multiple"
        loadData={(value) => Tag.index({ params: { search: value } }).then((res) => res.data.data)}
        optionGenerator={(value) => ({
          value: value.id,
          label: value.name
        })}
      />
    ),
    parse: (value) => value.map((x) => parseInt(x)),
    label: "Verticals",
    span: 12,
  },
  active: {
    input: () => (
      <Select style={{ width: '100%' }} size="large" placeholder="Select..." options={actives}>
      </Select>
    ),
    label: "Active",
    span: 12,
  }
}
