// @ts-nocheck
import React, { Fragment } from "react";
import { DeleteOutlined } from '@ant-design/icons';
import { FormikProps } from "formik";
import LinkButton from "src/components/Core/LinkButton";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { locations } from "src/constants/account";
import { FormikSelect } from "src/components/Forms/Select";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import { FieldError } from "src/components/Forms/ErrorMessage";

interface Props {
  formProps?: FormikProps<any>;
}

const LocationBreakdown: React.FC<Props> = ({ formProps }) => {
  let drodownData = locations;
  let dataCount = locations.length;

  drodownData = drodownData.filter((item: any, index) => {
    const newItem = { ...item };
    if (
      !find(
        formProps?.values["locationBreakdown"],
        matchesProperty("key", newItem?.value)
      )
    ) {
      return true;
    }
    return false;
  });

  const addData = () => {
    (formProps?.values["locationBreakdown"]).push({ key: "", value: 0 });
    formProps?.setFieldValue(
      "locationBreakdown",
      formProps?.values["locationBreakdown"]
    );
  };

  const removeData = index => {
    (formProps?.values["locationBreakdown"]).splice(index, 1);
    formProps?.setFieldValue(
      "locationBreakdown",
      formProps?.values["locationBreakdown"]
    );
  };

  return (
    <Fragment>
      <Typography
        mb={26}
        mt={26}
        fontWeight="semi-bold"
        color="black"
        size={16}
      >
        Location breakdown
      </Typography>
      <Box display="flex">
        <Box width="50%" mr="5px">
          <Typography size={14} color="grey2">
            Label:
          </Typography>
        </Box>
        <Box width="50%">
          <Typography size={14} color="grey2">
            Data:
          </Typography>
        </Box>
      </Box>

      {formProps?.values?.["locationBreakdown"].map((value, i) => (
        <Box display="flex" key={i} marginBottom={10} mt={10}>
          <Box width="40%" mr="5px">
            <FormikSelect
              name={`locationBreakdown[${i}].key`}
              data={drodownData}
              local
              borderRadius={2}
              borderColor="grey5"
              size="large"
              value={value?.key}
            />
          </Box>
          <Box width="40%">
            <FormikInputNumber
              parser={value => {
                let parsed = value.replace(/\[0-9]/g, "");
                if (parsed) {
                  return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                } else {
                  return null;
                }
              }}
              name={`locationBreakdown[${i}].value`}
              borderRadius={2}
              borderColor="grey5"
              size="middle"
              value={value?.value}
              // onChange={() =>
              //   formProps.setFieldTouched("locationBreakdown", true)
              // }
            />
          </Box>
          <Box width="10%" mt={10} ml={10}>
            %
          </Box>
          <Box width="10%" color="red" mt={10} ml={10}>
            {i > 2 && <DeleteOutlined onClick={() => removeData(i)} />}
          </Box>
        </Box>
      ))}

      {drodownData?.length > 0 &&
        dataCount >= formProps?.values?.["locationBreakdown"].length + 1 && (
          <LinkButton
            onClick={() => addData()}
            mt={20}
            color="blue"
            fontSize={12}
          >
            + Add data
          </LinkButton>
        )}
      <FieldError
        errors={formProps?.errors as any}
        name={`locationBreakdown`}
      />
    </Fragment>
  );
};
export default LocationBreakdown;
