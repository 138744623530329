import React, { Component } from "react";
import { connect } from "react-redux";
import { AgencyPersist } from "src/ducks/Persist";
import { withFormik } from "formik";
import { Form } from "formik-antd";
import Validate from "./Validate";
import { message, Button, Card } from "antd";
import parseDotNotation from "src/utils/parseDotNotation";
import { AgencyName, PaypalEmail } from "src/shared/AgencyFields";
import Container from "src/shared/Container";
import { featureFlags } from "src/constants/featureFlags";

const ShowAgency = AgencyPersist("AdminAgencyShow");

export class AgencyForm extends Component {
  render() {
    const { isSubmitting } = this.props;

    return (
      <Container style={{ marginTop: 24, marginBottom: 24 }}>
        <Card>
          <Form data-cy="agency-update-form">
            <AgencyName />
            {featureFlags.isPaypalEnabled && <PaypalEmail />}

            <Button
              type="primary"
              size="large"
              loading={isSubmitting}
              disabled={isSubmitting}
              htmlType="submit"
            >
              Update
            </Button>
          </Form>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  agency: ShowAgency.selectOne(state),
});

const mapDispatchToProps = {
  updateAgency: ShowAgency.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,

    mapPropsToValues: ({ agency }) => (agency ? agency.ref : Validate.cast()),
    validationSchema: () => Validate,

    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { updateAgency, agency } = props;

      updateAgency(agency.id, values)
        .then(res => {
          setSubmitting(false);
        })
        .catch(err => {
          message.error("Failed to update agency");
          setSubmitting(false);

          if (err.response.data)
            setErrors(parseDotNotation(err.response.data.meta.errors));
        });
    },
  })(AgencyForm)
);
