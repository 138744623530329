// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import { ThemeColors } from "../../../utils/theme";

export interface TextAreaProps {
  size: "small" | "middle" | "large";
  borderColor?: ThemeColors;
  background?: ThemeColors;
  borderRadius?: any;
  shouldShowError?: boolean;
  onChange?: any;
  value?: any;
  placeholder?: string;
  suffix?: any;
  row?: number;
}

const AntdTextArea: React.FC<TextAreaProps> = props => {
  const {
    size,
    borderColor,
    background,
    borderRadius,
    shouldShowError,
    ...rest
  } = props;

  return <Input.TextArea {...rest} />;
};

const StyledTextArea = styled(AntdTextArea)<TextAreaProps>`
  && {
    font-size: ${props => props.theme.components.inputs.fontSize[props.size]}px;
    border: 1px solid
      ${({ theme, borderColor = "grey5" }) => theme.colors[borderColor]} !important;
    box-sizing: border-box;
    background-color: ${props =>
      props.background && props.theme.colors[props.background]} !important;
    border-radius: ${props => props.borderRadius}px !important;
  }
`;

export default StyledTextArea;
