// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { Row, } from 'antd'
import HomeButton from '../HomeButton'
import logo from '../../../../../src/assets/images/logo.png'

const Wrapper = styled(Row)`
  &&& {
    min-height: 100vh;
    background: #f0f2f5;
    padding-top: 80px;
  }
`;

const Logo = styled("img")`
  width: 150px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
`

const WrapperComponent = (props) => {
  return <Wrapper justify="center" align="middle">
    {window.innerWidth > 767 && <HomeButton top={40} left={50} position="absolute" />}
    <Logo src={logo} />
    {props.children}
  </Wrapper>

}

export default WrapperComponent;
