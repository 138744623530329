// @ts-nocheck
import React from "react";
import { Radio } from "antd";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";

const ChartButton = styled(Radio.Group)`
  && {
    margin-bottom: 10px;

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: ${props => props.theme.colors.blue} !important;
      color: ${props => props.theme.colors.white} !important;
    }
    .ant-radio-button-wrapper:first-child {
      border-radius: 2px 0 0 2px;
    }
    .ant-radio-button-wrapper:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
`;

interface Props {
  chartDataType: string;
  setChartDataType: (value: string) => void;
}

const ChartDataType: React.FC<Props> = ({
  chartDataType,
  setChartDataType,
}) => {
  return (
    <Box color="grey6">
      <ChartButton
        key="filtertype"
        value={chartDataType}
        onChange={e => setChartDataType(e.target.value)}
      >
        <Radio.Button key="filterlive" value="live">
          Live
        </Radio.Button>
        <Radio.Button key="filterview" value="manual">
          Manual
        </Radio.Button>
      </ChartButton>
    </Box>
  );
};

export default ChartDataType;
