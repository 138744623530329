// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikCheckbox } from "src/components/Forms/Checkbox";
import { account_types } from "src/constants/account";

interface Props {}

const Viewers: React.FC<Props> = () => {
  return (
    <Field label="Who can see this milestone?" mb={26} fontSize={14}>
      <FormikCheckbox name="viewers" data={account_types} />
    </Field>
  );
};

export default Viewers;
