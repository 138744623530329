// @ts-nocheck
import React, { useCallback } from "react";
import { CampaignsTable } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useCampaignsListDetails } from "../CampaignsListProvider";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isNumber from "lodash/isNumber"
import { isBoolean } from "lodash";

type Props = {
  filterBackground?: string;
};

export const CampaignsTableContainer: React.FC<Props> = ({
  filterBackground,
}) => {
  const {
    isLoading,
    listErrors,
    campaignsListDetails,
    filters,
    actions,
    allTags,
  } = useCampaignsListDetails();

  let allCampaigns = campaignsListDetails;

  const globalErrors = useMapGraphQLErrorsMessages(listErrors);

  const clearFilterKey = (key, index = null) => {
    const newFilter = { ...filters };

    if (isArray(newFilter[key])) {
      if ((newFilter[key] || []).length === 1) {
        newFilter[key] = [] as any;
      } else {
        newFilter[key].splice(index, 1);
      }
    } else if (isString(newFilter[key])) {
      newFilter[key] = null as any;
    } else if (isObject(newFilter[key])) {
      newFilter[key] = null as any;
    } else if (isNumber(newFilter[key])) {
      newFilter[key] = undefined as any
    } else if (isBoolean(newFilter[key])) {
      newFilter[key] = null as any
    }
    actions.setFilters({ ...newFilter });
    actions.refetchCampaigns({ ...newFilter });
  };

  const onFilterChange = useCallback(
    (key, value) => {
      const newFilters = { ...filters };

      newFilters[key] = value;

      if (newFilters?.order === null) {
        delete newFilters?.order;
      }

      actions?.setFilters({ ...newFilters });
      actions?.refetchCampaigns({ ...newFilters });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  return (
    <CampaignsTable
      allCampaigns={allCampaigns}
      globalErrors={globalErrors}
      loading={isLoading}
      onFilter={onFilterChange}
      clearFilterKey={(key, index) => clearFilterKey(key, index)}
      filters={filters}
      filterBackground={filterBackground}
      allTags={allTags}
    />
  );
};
