import React, { Component } from "react";
import { Modal } from "antd";
import { connectModal } from "redux-modal";
import OrganicContent from "src/shared/OrganicContent";
import BrandedContent from "src/shared/BrandedContent";
import AccountMini from "src/shared/AccountMini";
import AccountMetrics from "src/shared/AccountMetrics";
import AccountLink from "src/shared/AccountLink";
import AccountStats from "src/shared/AccountStats";

class AccountModal extends Component {
  render() {
    const { show, handleHide, account } = this.props;
    return (
      <Modal
        title={`${account.username} (${account.type})`}
        width={800}
        footer={null}
        onCancel={handleHide}
        visible={show}
      >
        <AccountMini account={account} />
        <AccountMetrics
          style={{
            marginTop: "14px",
            paddingLeft: "64px",
            marginBottom: "30px"
          }}
          account={account}
        />
        <AccountLink account={account} />
        <AccountStats account={account} />
        <BrandedContent account={account} />
        <OrganicContent account={account} />
      </Modal>
    );
  }
}

export default connectModal({ name: "AccountModal", destroyOnHide: false })(
  AccountModal
);
