import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "src/shared/Table";
import { EditOutlined } from '@ant-design/icons';
import { Button, Tag } from "antd";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import { show } from "redux-modal";
import TableManager from "src/ducks/TableManager";
import { LinkList as $LinkList } from "src/ducks/DataList";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import CreateModal from "./LinkModal";
import withWindowResize from "src/utils/hoc/withWindowResize";

const LinkTable = TableManager("CampaignLinkList");
const LinkList = $LinkList("CampaignLinkList");

const modules = [LinkTable.module(), LinkList.module()];

export class LinksTable extends Component {
  loadLinks = filters => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.loadLinks({
      campaign: id,
      campaign_invitation_id_is_null: true,
      ...filters,
    });
  };

  renderDelete = (text, record) => {
    const { deleteLink } = this.props;
    if (!record?.tested) {
      return (
        <ConfirmDeleteIcon
          text="Delete"
          trigger="click"
          key={record.id}
          onConfirm={e => {
            e.preventDefault();
            deleteLink(record.id);
          }}
        />
      );
    }
    return null;
  };

  renderTested = (text, record) => {
    if (record?.tested) {
      return <Tag color="blue">Test link</Tag>;
    }
    return null;
  };

  render() {
    const {
      data,
      pagination,
      loading,
      style,
      currentFilters,
      show,
      match: {
        params: { id },
      },
      height
    } = this.props;

    const columns = {
      snaplink: {
        title: "Snaplink",
        dataIndex: "snaplink",
        key: "snaplink",
        visible: true,
        width: 180,
      },
      default_link: {
        title: "Default Link",
        dataIndex: "default_link",
        key: "default_link",
        visible: true,
        width: 180,
      },
      clicks: {
        title: "Click",
        dataIndex: "clicks",
        key: "clicks",
        visible: true,
        width: 100,
      },
      installs: {
        title: "Install",
        dataIndex: "installs",
        key: "installs",
        visible: true,
        width: 100,
      },
      integration_partner: {
        title: "Integration Partner",
        dataIndex: "integration_partner",
        key: "integration_partner",
        visible: false,
        width: 100,
      },
      ios_id: {
        title: "iOS Id",
        dataIndex: "ios_id",
        key: "ios_id",
        visible: false,
        width: 100,
      },
      android_id: {
        title: "Android Id",
        dataIndex: "android_id",
        key: "android_id",
        visible: false,
        width: 100,
      },
    };

    const filters = {
      // default_link: {
      //   input: () => <Input size="large" placeholder="e.g. https://fanbytes.co.uk" />,
      //   label: "Link"
      // }
    };

    return <>
      <Table
        style={style}
        title="Campaign Links"
        columns={columns}
        loadData={this.loadLinks}
        name="CampaignLinkList"
        pagination={pagination}
        data={data}
        addedColumns={[
          {
            key: "tested",
            width: 100,
            render: this.renderTested,
          },
          {
            key: "actions",
            width: 100,
            render: this.renderDelete,
          },
          {
            key: "edit",
            width: 50,
            render: (text, row) => {
              const linkId = row._fields.id;
              if (!row._fields.tested) {
                return (
                  <EditOutlined
                    data-cy="link-edit-button"
                    onClick={() => show(`CampaignLinkModal`, { linkId })} />
                );
              }
              return null;
            },
          },
        ]}
        extra={[
          <Button
            type="primary"
            data-cy="campaign-create-link-button"
            key="create"
            onClick={() => show(`CampaignLinkModal`)}
          >
            Create new Link
          </Button>,
        ]}
        filterOptions={filters}
        loading={loading}
        size="middle"
        scroll={{ y: height, x: "max-content" }}
      />

      <CreateModal
        initialValues={{ campaign: id }}
        onCreate={() => this.loadLinks(currentFilters)}
      />
    </>;
  }
}

const mapStateToProps = state => ({
  currentFilters: LinkTable.selectFilters(state),
  data: LinkList.selectMany(state),
  pagination: LinkList.selectPagination(state),
  loading: LinkList.selectPending(state),
});

const mapDispatchToProps = {
  loadLinks: LinkList.loadMany,
  deleteLink: LinkList.destroy,
  show,
};

const MainLinksTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LinksTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainLinksTable);
