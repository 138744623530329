// @ts-nocheck
import React from "react";
import { LinkButton } from "src/components/Core/LinkButton";
import { Button } from "src/components/Core/Button";
import { Box } from "src/components/Core/Box";

interface Props {
  cancelClick?: (value: boolean) => void;
  backClick?: () => void;
  isSubmitting: boolean;
  onSave: Function;
  formType: string;
}

const ActionButtons: React.FC<Props> = ({
  cancelClick,
  backClick,
  isSubmitting,
  onSave,
  formType,
}) => {
  return (
    <Box mt={18}>
      <Button
        onClick={() => onSave()}
        type="action"
        loading={isSubmitting}
        disabled={isSubmitting}
        buttonSize="small"
        block
        data-cy={`save-button-${formType.replace(" ", "-")}`}
      >
        Save
      </Button>

      <LinkButton
        onClick={() => {
          if (formType === "bank") {
            backClick && backClick();
          } else {
            cancelClick && cancelClick(false);
          }
        }}
        fontSize={12}
        data-cy={`cancel-button-${formType.replace(" ", "-")}`}
      >
        {formType === "bank" ? "Back" : "Cancel"}
      </LinkButton>
    </Box>
  );
};

export default ActionButtons;
