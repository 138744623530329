import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OrderedListOutlined } from '@ant-design/icons';
import { Modal, Table, Switch } from "antd";
import { connectModal } from "redux-modal";
import ReactDragListView from "react-drag-listview";
import TableManager from "src/ducks/TableManager";

class ColumnsModal extends Component {
  render() {
    const {
      show,
      handleHide,
      data,
      reorderColumns,
      changeVisible,
    } = this.props;

    const columns = [
      {
        title: "",
        render: () => <OrderedListOutlined />,
        key: "order",
      },
      {
        title: "Column name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Visible?",
        render: (value, row, index) => (
          <Switch
            checked={value.visible}
            onChange={checked => changeVisible(checked, index)}
          />
        ),
        key: "visible",
      },
    ];

    return (
      <Modal
        title="Manage columns"
        visible={show}
        footer={null}
        onCancel={handleHide}
        {...this.props}
      >
        <ReactDragListView onDragEnd={reorderColumns} handleSelector="i">
          <Table
            columns={columns}
            rowKey="key"
            pagination={false}
            dataSource={data}
            size="small"
          />
        </ReactDragListView>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const Manager = TableManager(props.moduleName);

  return {
    data: Manager.selectColumns(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const Manager = TableManager(ownProps.moduleName);

  return bindActionCreators(
    {
      updateColumns: Manager.updateColumns,
      reorderColumns: Manager.reorderColumns,
      changeVisible: Manager.changeVisible,
    },
    dispatch
  );
};

export default connectModal({
  name: "ColumnsModal",
  destroyOnHide: false,
})(connect(mapStateToProps, mapDispatchToProps)(ColumnsModal));
