// @ts-nocheck
import React from "react";
import { Wrapper, ProgressCircle } from "./styles";

interface Props {
  type?: "dashboard" | "circle" | undefined;
  percent?: number;
  width?: number;
}

const Progress: React.FC<Props> = props => {
  return (
    <Wrapper>
      <ProgressCircle {...props} />
    </Wrapper>
  );
};

export default Progress;
