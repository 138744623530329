// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";

interface Props {}

const Followers: React.FC<Props> = () => {
  return (
    <Field label="Follower count:" mb={26} mt={26}>
      <FormikInputNumber
        name="followers"
        size="large"
        placeholder="0"
        borderRadius={1}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Followers;
