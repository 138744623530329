// @ts-nocheck
import React from "react";
import { PostDetailForm } from ".";
import { object, number, string, array } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useInfluencerCashoutDetailMutation } from "src/generated/ApolloComponents";

interface Props {
  claimCashoutId: any;
  type: string;
  cashoutData: any;
  setShowExample: (value: object) => void;
  setScreenshotType: (value: string) => void;
  showExample: object;
  goNext: () => void;
  goBack: () => void;
  index: number;
}

interface File {
  uploadUrl: string;
  uploadType: string;
}

interface PostDetailFormValues {
  viewCount: number;
  tiktokUrl?: string;
  files: File[];
  durationMinutes?: number;
  durationSeconds?: number;
  likes?: number;
  dislikes?: number;
  comments?: number;
  watchTimeMinutes?: number;
  watchTimeSeconds?: number;
}

export const PostDetailFormContainer: React.FC<Props> = ({
  claimCashoutId,
  type,
  cashoutData,
  setShowExample,
  setScreenshotType,
  showExample,
  goNext,
  goBack,
  index,
}) => {
  const [
    influencerCashoutDetailMutation,
    { loading, error },
  ] = useInfluencerCashoutDetailMutation();

  const CampaignDetailSchema = object().shape({
    viewCount: number()
      .nullable()
      .required("Please enter the amount of views your post got"),
    files: array()
      .of(
        object().shape({
          uploadUrl: string()
            .nullable()
            .required(),
          uploadType: string()
            .nullable()
            .required(),
        })
      )
      .min(1, "You must upload at least one screenshot"),
    ...(cashoutData?.account?.type &&
      cashoutData?.account?.type === "TikTok" && {
        tiktokUrl: string()
          .nullable()
          .required("Please enter the url to your post"),
      }),

    ...(type &&
      type === "youtube" && {
        durationMinutes: number()
          .nullable()
          .required("Please enter the minutes"),
        durationSeconds: number()
          .nullable()
          .required("Please enter the seconds"),
        likes: number()
          .nullable()
          .required("Please enter likes"),
        dislikes: number()
          .nullable()
          .required("Please enter dislikes"),
        comments: number()
          .nullable()
          .required("Please enter comments"),
        watchTimeMinutes: number()
          .nullable()
          .required("Please enter the minutes"),
        watchTimeSeconds: number()
          .nullable()
          .required("Please enter the seconds"),
      }),
  });

  const duration = cashoutData?.metadata?.duration || 0;
  let durationMinutes = Math.floor(duration / 60);
  let durationSeconds = duration - durationMinutes * 60;

  const watchTime = cashoutData?.metadata?.watch_time || 0;
  let watchTimeMinutes = Math.floor(watchTime / 60);
  let watchTimeSeconds = watchTime - watchTimeMinutes * 60;

  let initialValues = {
    viewCount: cashoutData?.views || "",
    tiktokUrl: cashoutData?.metadata?.tiktok_url || "",
    files:
      (cashoutData?.screenshots || [])
        .filter(x => !!x.uploadUrl && !!x.uploadType)
        .map(x => ({
          uploadType: x.uploadType,
          uploadUrl: x.uploadUrl,
        })) || [],
    durationMinutes: durationMinutes || 0,
    durationSeconds: durationSeconds || 0,
    likes: cashoutData?.metadata?.likes || 0,
    dislikes: cashoutData?.metadata?.dislikes || 0,
    comments: cashoutData?.metadata?.comments || 0,
    watchTimeMinutes: watchTimeMinutes || 0,
    watchTimeSeconds: watchTimeSeconds || 0,
  };

  const onSubmit = async (
    values: PostDetailFormValues,
    actions: FormikActions<PostDetailFormValues>
  ) => {
    try {
      let formData: any = {
        claimCashoutId: claimCashoutId,
        viewCount: values?.viewCount,
        files: values?.files,
      };

      if (cashoutData?.account?.type === "TikTok") {
        formData = {
          ...formData,
          tiktokUrl: values?.tiktokUrl,
        };
      }

      if (type === "youtube") {
        formData = {
          ...formData,
          duration:
            (values?.durationMinutes || 0) * 60 +
            (values?.durationSeconds || 0),
          likes: values?.likes,
          dislikes: values?.dislikes,
          comments: values?.comments,
          watchTime:
            (values?.watchTimeMinutes || 0) * 60 +
            (values?.watchTimeSeconds || 0),
        };
      }

      const res = await influencerCashoutDetailMutation({
        variables: formData,
      });

      if (res?.data?.influencerCashoutDetail) {
        goNext();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <PostDetailForm
      initialValues={initialValues}
      campaignDetailSchema={CampaignDetailSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      cashoutData={cashoutData}
      setShowExample={value => setShowExample(value)}
      setScreenshotType={value => setScreenshotType(value)}
      showExample={showExample}
      type={type}
      goBack={goBack}
      index={index}
    />
  );
};
