// @ts-nocheck
import React, { useState, useEffect } from "react";
import { connectModal } from "redux-modal";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { Loading } from "src/components/Core/Loading";
import InvitationManage from "src/ducks/InvitationManage";
import { connect } from "react-redux";
import { CompletePaymentRequestFormContainer } from "./CompletePaymentForm";
import { UpdatePaymentDetailFormContainer } from "../../../PaymentRequest/NewPaymentModal/UpdatePaymentDetail";
import { PaymentDetailsProvider } from "../../../PaymentRequest/NewPaymentModal/PaymentDetailsProvider";
import { CompleteDataFormContainer } from "./CompleteDataFrom";
import { useGetPaymentRequestInvitationByIdQuery } from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { GlobalErrors } from "src/components/Forms/ErrorMessage";
import { InfluencerRatingFormContainer } from "../InfluencerRatingModal/InfluencerRatingForm";

const PaymentModal = styled(Modal)`
  &&& {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.213287);
    border-radius: 6px;
  }
`;

const CompleteModal = props => {
  const {
    show,
    handleHide,
    invitation_id,
    from,
    to,
    loadData,
    clearAll,
  } = props;

  const [step, setStep] = useState<number>(1);

  const {
    data,
    loading,
    error,
    refetch,
  } = useGetPaymentRequestInvitationByIdQuery({
    variables: {
      invitationId: invitation_id,
    },
  });

  const invitationData = data?.getPaymentRequestInvitationById;

  useEffect(() => {
    refetch({
      invitationId: invitation_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation_id]);

  const globalErrors = useMapGraphQLErrorsMessages(error);

  useEffect(() => {
    if (!show) {
      setStep(1);
    }
  }, [show]);

  return (
    <PaymentModal
      style={{
        maxWidth: 1094,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      visible={show}
      onCancel={handleHide}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <Loading spinning={loading}>
        <GlobalErrors errors={globalErrors} />

        <PaymentDetailsProvider>
          {step === 1 && (
            <CompletePaymentRequestFormContainer
              closePaymentModal={handleHide}
              setStep={value => setStep(value)}
              invitationId={invitation_id}
              from={from}
              to={to}
              invitationData={invitationData}
            />
          )}
          {step === 2 && (
            <UpdatePaymentDetailFormContainer
              setStep={value => setStep(value)}
            />
          )}
          {step === 3 && (
            <CompleteDataFormContainer
              closePaymentModal={handleHide}
              setStep={value => setStep(value)}
              invitationData={invitationData}
            />
          )}

          {step === 4 && (
            <InfluencerRatingFormContainer
              accountId={invitationData?.account?.id as any}
              closeInfluencerRatingModal={() => handleHide()}
              rating={{
                creation_time:
                  invitationData?.account?.metadata?.rating?.creationTime ||
                  null,
                response_time:
                  invitationData?.account?.metadata?.rating?.responseTime ||
                  null,
                content_quality:
                  invitationData?.account?.metadata?.rating?.contentQuality ||
                  null,
                professionalism:
                  invitationData?.account?.metadata?.rating?.professionalism ||
                  null,
              }}
              onRating={() => {
                clearAll();
                loadData({ page: 1 });
              }}
            />
          )}
        </PaymentDetailsProvider>
      </Loading>
    </PaymentModal>
  );
};

const EnhancedForm = connectModal({
  name: "CompleteModal",
  destroyOnHide: false,
})(CompleteModal);

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  loadData: data =>
    dispatch(InvitationManage.loadInvitationStatus("complete")(data)),
  clearAll: () => dispatch(InvitationManage["complete"].clearAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedForm);
