// @ts-nocheck
import React from 'react'
import { Field } from 'src/components/Forms/Field'
import { FormikSelect } from 'src/components/Forms/Select'
import { types } from 'src/constants/account'

const Platform = () => {

  return (
    <Field id="platform" label="Platform:">
      <FormikSelect
        name="platform"
        data={types}
        local
        borderRadius={3}
        borderColor="grey5"
        size="small"
        minHeight={40}
      />
    </Field>
  );
};

export default Platform;
