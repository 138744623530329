// @ts-nocheck
import styled from "styled-components";
import { Layout } from "antd";
const { Content } = Layout;

export const ContentBox = styled(Content)`
  && {
    margin-left: 15px;
    position: relative;
    border-radius: 6px;
  }
`;
