import React, { Component } from "react";
import FormField from "../FormField";
import LargeTextArea from "../LargeTextArea";

export default class Brief extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "brief"}
        label="Please describe your general brief:"
      >
        <LargeTextArea
          data-cy="brief-input"
          rows={5}
          size="large"
          placeholder="e.g. Must mention our most recent collection"
        />
      </FormField>
    );
  }
}
