// @ts-nocheck
import React from "react";
import { Avatar } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Link } from "react-router-dom";
import numeral from "numeral";
import compact from "lodash/compact";
import moment from "moment";
import formatCurrency from "src/utils/formatCurrency";
import UserContent from "./UserNameContent"

const getColumns = ({ sorter, columnVisibility, isInnerAccountList, menu }) => {
  let columns = [
    {
      title: "Image",
      dataIndex: "profilePicUrl",
      key: "profilePicUrl",
      width: 30,
      visible: columnVisibility["profilePicUrl"],
      render: (text, row) => (
        <Avatar shape="square" size="large" src={row?.profilePicUrl} />
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: true,
      sortOrder: sorter.field === "username" && sorter.order,
      width: 200,
      visible: columnVisibility["username"],
      render: (text, row) => <UserContent account={row} isInnerAccountList={isInnerAccountList} menu={menu} />,
    },
    {
      title: "Influencer",
      dataIndex: "influencerName",
      key: "influencerName",
      sorter: true,
      sortOrder: sorter.field === "influencerName" && sorter.order,
      width: 180,
      visible: columnVisibility["influencerName"],
      render: (text, row) => (
        <Link to={`/influencers/${row?.influencer?.id}`}>
          <Typography size={12} cursor="pointer" mb={0}>
            {row?.influencer?.name ? row?.influencer?.name : "-"}
          </Typography>
        </Link>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortOrder: sorter.field === "status" && sorter.order,
      width: 120,
      visible: columnVisibility["status"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.status ? row?.status : "-"}
        </Typography>
      ),
    },

    {
      title: "Platform",
      dataIndex: "type",
      key: "type",
      sorter: true,
      sortOrder: sorter.field === "type" && sorter.order,
      width: 120,
      visible: columnVisibility["type"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.type ? row?.type : "-"}
        </Typography>
      ),
    },

    {
      title: "Engagements",
      dataIndex: "engagementRate",
      key: "engagementRate",
      sorter: true,
      sortOrder: sorter.field === "engagementRate" && sorter.order,
      width: 120,
      visible: columnVisibility["engagementRate"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.metadata?.engagementRate
            ? numeral(row?.metadata?.engagementRate).format("0,0.00") + "%"
            : "-"}
        </Typography>
      ),
    },

    {
      title: "Followers",
      dataIndex: "followers",
      key: "followers",
      sorter: true,
      sortOrder: sorter.field === "followers" && sorter.order,
      width: 120,
      visible: columnVisibility["followers"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.metadata?.followers
            ? numeral(row?.metadata?.followers).format("0,0")
            : "-"}
        </Typography>
      ),
    },

    {
      title: "Campaign Date",
      dataIndex: "lastCampaignDate",
      key: "lastCampaignDate",
      sorter: true,
      sortOrder: sorter.field === "lastCampaignDate" && sorter.order,
      width: 130,
      visible: columnVisibility["lastCampaignDate"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.lastCampaignDate
            ? moment(row?.lastCampaignDate).format("Do MMM YYYY")
            : ""}
        </Typography>
      ),
    },
    {
      title: "Cost",
      dataIndex: "lastPaidAmount",
      key: "lastPaidAmount",
      sorter: true,
      sortOrder: sorter.field === "lastPaidAmount" && sorter.order,
      width: 130,
      visible: columnVisibility["lastPaidAmount"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.lastPaidAmount ? formatCurrency(row?.lastPaidAmount) : ""}
        </Typography>
      ),
    },
  ];

  return compact(columns);
};

export default getColumns;
