import React from "react";
import { Layout } from "antd";
import DocumentTitle from "react-document-title";
import styled from "styled-components";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useAppDetails } from "src/AppProvider";
import { isEmpty } from 'lodash'
import Auth from 'src/ducks/Auth'
import { useSelector } from 'react-redux'

const { Content } = Layout;

const MainContent = styled(Layout)`
  &&& {
    background: #f0f2f5;
    min-height: 100vh;
  }
`;

const AppContent = styled(Content)`
  &&& {
    padding-top: 68px;
    height: 100%;
    @media screen and (max-width: 768px) {
      padding-top: 10px;
    }
  }
`;

const Wrapper = styled(Layout)`
  &&& {
    min-height: 100vh;
  }
`;

const contentStyle = (collapsed) => {
  return window.innerWidth > 767 ?
    {
      marginLeft: collapsed ? 80 : 200,
      width: `calc(100% - ${collapsed ? 80 : 200}px)`,
    }
    :
    {
      marginLeft: 10,
      width: `calc(100% - ${collapsed ? 200 : 10}px)`
    }
}

const headerWidth = (collapsed) => {
  return window.innerWidth > 767 ?
    collapsed ? 80 : 200
    :
    200
}

const DashboardLayout = ({ title, children }) => {
  const { collapsed } = useAppDetails();
  const user = useSelector(state => Auth.selectUser(state))
  const getTitle = () => {
    const titleStr = "Cortex Influencer Campaign Manager"
    return title ? `${title} | ${titleStr}` : titleStr
  }
  const loggedin = !isEmpty(user)
  return (
    <DocumentTitle title={getTitle()}>
      <Wrapper>
        {loggedin && <Sidebar />}
        <MainContent
          style={loggedin ? contentStyle(collapsed) : {}}
        >
          {loggedin && <Header sidebarWidth={headerWidth(collapsed)} />}
          <AppContent>{children}</AppContent>
        </MainContent>
      </Wrapper>
    </DocumentTitle>
  );
};

export default DashboardLayout;
