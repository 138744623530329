// @ts-nocheck
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useScreenClass } from "react-grid-system";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import objective_img from "src/assets/images/reports/target_fanbytes 1.png";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import FadeInSection from "../../FadeInSection";
import ParallaxSection from "../../ParallaxSection";

interface Props {
  onChangeSection: (value: string) => void;
  objective: string;
}

const ObjectiveImg = styled("img")`
  && {
    position: absolute;

    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      height: 150px;
      width: 190px;
      left: 130px;
      top: -120px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      height: 300px;
      width: 350px;
      left: 0px;
      top: -370px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      height: 400px;
      width: 500px;
      left: 0px;
      top: -50px;
    }
  }
`;

const Objective: React.FC<Props> = ({ onChangeSection, objective }) => {
  const screenClass = useScreenClass();

  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};
  let props = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  useEffect(() => {
    if (inViewport) {
      onChangeSection("objective");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport]);

  return (
    <FadeInSection>
      <Box
        position="relative"
        pt={screenClass === "xl" || screenClass === "lg" ? 120 : 0}
        pb={screenClass === "xl" || screenClass === "lg" ? 120 : 0}
        id="objective"
        ref={myRef as any}
      >
        <Box
          display="flex"
          flexDirection={
            screenClass === "xs" || screenClass === "sm" || screenClass === "md"
              ? "column"
              : "row"
          }
        >
          <Box
            width={
              screenClass === "xs" ||
              screenClass === "sm" ||
              screenClass === "md"
                ? "100%"
                : "50%"
            }
          >
            <VelocityComponent
              key="slideUp"
              animation={
                enterCount <= 1 && inViewport
                  ? "transition.slideUpIn"
                  : "transition.fadeIn"
              }
              stagger={800}
              duration={1000}
            >
              <Typography
                fontSize={
                  screenClass === "xs" || screenClass === "sm" ? 38 : 65
                }
                fontWeight={600}
                color="white"
                mb="10px"
              >
                Objective
              </Typography>
            </VelocityComponent>

            <VelocityComponent
              key="slideUp1"
              animation={
                enterCount <= 1 && inViewport
                  ? "transition.slideUpIn"
                  : "transition.fadeIn"
              }
              delay={500}
              stagger={1200}
              duration={1500}
            >
              <Typography
                fontSize={
                  screenClass === "xs" || screenClass === "sm" ? 18 : 24
                }
                color="white"
              >
                {objective}
              </Typography>
            </VelocityComponent>
          </Box>
          <ParallaxSection direction="up">
            <ObjectiveImg src={objective_img} alt="Objective" />
          </ParallaxSection>
        </Box>
      </Box>
    </FadeInSection>
  );
};

export default Objective;
