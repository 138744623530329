// @ts-nocheck
import React, { useRef } from "react";
import maxBy from "lodash/maxBy";
import { useScreenClass } from "react-grid-system";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import Strip from "./Strip";
import FadeInSection from "../../FadeInSection";

interface Props {
  viewData: any;
  direction: "right" | "left";
  type: "engagement" | "views";
}

const CategorisedTopData: React.FC<Props> = ({ viewData, direction, type }) => {
  let data = viewData?.data || [];

  const maxValue = maxBy(data, "views") as any;

  let newData = data.map((item: any, index) => {
    const newItem = { ...item };

    let newItemPercentage = (item?.views * 100) / maxValue?.views;

    newItem.percentage = Math.round(newItemPercentage);

    return newItem;
  });

  const screenClass = useScreenClass();

  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};

  let props = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  return (
    <FadeInSection>
      <Box mt={60} position="relative" ref={myRef as any}>
        {(newData || []).length > 0 && (
          <Box mb={100} textAlign={direction}>
            <VelocityComponent
              key="slideUp"
              animation={
                enterCount <= 1 && inViewport
                  ? "transition.slideUpIn"
                  : "transition.fadeIn"
              }
              delay={100}
            >
              <Box>
                <Typography
                  fontSize={
                    screenClass === "xs" || screenClass === "sm" ? 32 : 55
                  }
                  fontWeight={600}
                  color="white"
                  mb="0px"
                >
                  {type === "views" ? "Views achieved" : "Engagement"}
                </Typography>

                <Typography
                  fontSize={
                    screenClass === "xs" || screenClass === "sm" ? 26 : 36
                  }
                  fontWeight={500}
                  color="white"
                  mb={30}
                >
                  {viewData?.type}
                </Typography>
              </Box>
            </VelocityComponent>

            <VelocityComponent
              key="slideUp1"
              animation={
                enterCount <= 1 && inViewport
                  ? "transition.slideUpIn"
                  : "transition.fadeIn"
              }
              delay={120}
            >
              <Typography
                fontSize={
                  screenClass === "xs" || screenClass === "sm" ? 18 : 24
                }
                color="white"
                mb="20px"
              >
                Here are your top 5 performing influencers for this campaign
                based on {type === "views" ? "views" : "engagement"}
              </Typography>
            </VelocityComponent>

            {newData.map((item, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  flexDirection="column"
                  alignItems={direction === "left" ? "flex-start" : "flex-end"}
                >
                  <Strip
                    percentage={item?.percentage}
                    profileUrl={item?.profilePicUrl}
                    username={item?.username}
                    value={
                      type === "views" ? item?.views : item?.engagementRate
                    }
                    inViewport={inViewport}
                    enterCount={enterCount}
                    direction={direction}
                    type={type}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </FadeInSection>
  );
};

export default CategorisedTopData;
