import React, { Component } from "react";
import { connect } from "react-redux";
import { Descriptions } from "antd";
import { InvitationPersist } from "src/ducks/Persist";
import InvitationDetails from "./InvitationDetails";
import { HubLinkAccessFormContainer } from "./HubLinkAccessForm";

const InvitationManage = InvitationPersist("InvitationModal");

export class ManageInvitation extends Component {
  render() {
    const { invitation } = this.props;

    return (
      <>
        <div style={{ marginTop: 24 }}>
          <InvitationDetails invitation={invitation} />
        </div>
        <div style={{ marginTop: 24 }}>
          <HubLinkAccessFormContainer
            claimCashoutId={invitation.claim_cashout_id}
            accountId={invitation?.account?.id}
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <Descriptions title="Tracking" size="small" bordered column={1}>
            <Descriptions.Item label="CPI Tracking URL">
              https://app.fanbytes.co.uk/track_invitation/
              {invitation.claim_cashout_id}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Invite URL">
              https://app.fanbytes.co.uk/invitation/
              {invitation.claim_cashout_id}
            </Descriptions.Item>

            <Descriptions.Item label="Youtube Invite URL">
              https://app.fanbytes.co.uk/invitation/
              {invitation.claim_cashout_id}/youtube
            </Descriptions.Item>

            <Descriptions.Item label="Cashout URL">
              https://app.fanbytes.co.uk/claim-cashout/
              {invitation.claim_cashout_id}
            </Descriptions.Item>

            <Descriptions.Item label="Cashout Youtube URL">
              https://app.fanbytes.co.uk/claim-cashout/
              {invitation.claim_cashout_id}/youtube
            </Descriptions.Item> */}

            {/* <Descriptions.Item label="Initial Contact Detail">
              https://app.fanbytes.co.uk/initial-contact/
              {invitation.claim_cashout_id}/detail
            </Descriptions.Item>

            <Descriptions.Item label="Initial Contact Agreement">
              https://app.fanbytes.co.uk/initial-contact/
              {invitation.claim_cashout_id}/agreement
            </Descriptions.Item> */}

            <Descriptions.Item label="Snaplink">
              {invitation.snaplink || "None"}
            </Descriptions.Item>
          </Descriptions>
        </div>

        <div style={{ marginTop: 24 }}>
          <Descriptions size="middle" layout="vertical" bordered>
            <Descriptions.Item label="Invitation Note" span={3}>
              {invitation.note || "None"}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  invitation: InvitationManage.selectOne(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvitation);
