import styled from "styled-components";
import { Radio } from "formik-antd";

export default styled(Radio.Group)`
  &&& {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${props => props.theme.colors.blue} !important;
      color: ${props => props.theme.colors.white} !important;
    }
    .ant-radio-button-wrapper {
      height: 30px !important;
      padding: 0 7px !important;
    }
  }
`;
