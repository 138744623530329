import axios from "axios";
import qs from "qs";
import store from "src/store/fanbytesStore";
import Auth from "src/ducks/Auth";

var axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_V2_API_URL,
  responseType: "json",
  headers: {
    Accept: "application/json"
  },
  paramsSerializer: function(params) {
    return qs.stringify(params, { encode: false, arrayFormat: "brackets" });
  }
});

// Potential for a global alerting system.

axiosInstance.interceptors.request.use(request => {
  request.meta = request.meta || {};
  request.meta.sent_at = new Date().getTime();
  if (localStorage) {
    request.headers.Authorization = localStorage.getItem("authToken");
  }

  return request;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch(Auth.logoutUser());
    }
    throw error;
  }
);

export default axiosInstance;
