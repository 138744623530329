// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import isEqual from "lodash/isEqual";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Button } from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";
import { FilterFormContainer } from "./container";
import {
  PlatformType,
  SearchInput,
  Campaign,
  Platform,
  Location,
  Gender,
  Tags,
  IncludeEstimatedCost,
  Followers,
  Hearts,
  Price,
  LocationAudience,
  GenderAudience,
  AgeAudience,
  SearchCriteria,
} from "./FormFields";
import { useAppDetails } from "src/AppProvider";
import { DataSourcePlatform } from "src/generated/ApolloComponents";
import { shouldShowClearAllFilter } from "./utils";

interface FilterFormProps<Values = FormikValues> {
  initialValues: Values;
  isSubmitting: boolean;
  FilterSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  allTags: Array<any>;
  getCampaigns: Function;
  clearFilterKey: (key: string) => void;
  clearAllFilter: () => void;
  internalFilters: any;
}

const FilterForm: React.FC<FilterFormProps> = ({
  initialValues,
  isSubmitting,
  FilterSchema,
  onSubmit,
  allTags,
  getCampaigns,
  clearFilterKey,
  clearAllFilter,
  internalFilters,
}) => {
  const { collapsed } = useAppDetails();

  return (
    <>
      <Loading spinning={false}>
        <Box p={10} borderBottom="1px solid #E2E2E2">
          <Typography size={20} weight="semi-bold" color="black">
            Search Accounts
          </Typography>
          <Typography size={10} color="black2" mt="-10px">
            Search influencer accounts on and off platform.
          </Typography>
        </Box>

        <Form
          initialValues={initialValues}
          schema={FilterSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            const platformType = formProps?.values?.platformType;
            return (
              <>
                <Box p={10}>
                  <PlatformType />
                  <SearchInput formProps={formProps} />
                  {platformType === DataSourcePlatform.Bytesights && (
                    <SearchCriteria />
                  )}

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={
                      isSubmitting ||
                      isEqual(formProps?.initialValues, formProps?.values)
                    }
                    buttonSize="middle"
                    style={{ width: "100%" }}
                  >
                    Update results
                  </Button>

                  {shouldShowClearAllFilter(internalFilters) && (
                    <Typography
                      size={12}
                      color="red"
                      cursor="pointer"
                      mt={20}
                      textAlign="center"
                      onClick={() => {
                        formProps?.resetForm();
                        clearAllFilter();
                      }}
                    >
                      Clear all filters
                    </Typography>
                  )}
                </Box>

                <Box p={10}>
                  <Typography size={16} color="black2" weight="semi-bold">
                    Influencer
                  </Typography>
                  <Row gutter={8}>
                    {platformType === DataSourcePlatform.Fanbytes && (
                      <Col span={collapsed ? 12 : 24}>
                        <Campaign
                          formProps={formProps}
                          getCampaigns={getCampaigns}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    )}

                    {(platformType === DataSourcePlatform.Fanbytes ||
                      platformType === DataSourcePlatform.Upfluence) && (
                      <>
                        <Col span={collapsed ? 12 : 24}>
                          <Platform
                            formProps={formProps}
                            clearFilterKey={key => clearFilterKey(key)}
                          />
                        </Col>
                        <Col span={collapsed ? 12 : 24}>
                          <Gender
                            formProps={formProps}
                            clearFilterKey={key => clearFilterKey(key)}
                          />
                        </Col>

                        <Col span={collapsed ? 12 : 24}>
                          <Location
                            formProps={formProps}
                            clearFilterKey={key => clearFilterKey(key)}
                          />
                        </Col>
                      </>
                    )}

                    {platformType === DataSourcePlatform.Fanbytes && (
                      <Col span={collapsed ? 12 : 24}>
                        <Tags
                          formProps={formProps}
                          allTags={allTags}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    )}

                    <Col span={collapsed ? 12 : 24}>
                      <Followers
                        formProps={formProps}
                        collapsed={collapsed}
                        clearFilterKey={key => clearFilterKey(key)}
                      />
                    </Col>

                    <Col span={collapsed ? 12 : 24}>
                      <Hearts
                        formProps={formProps}
                        collapsed={collapsed}
                        clearFilterKey={key => clearFilterKey(key)}
                      />
                    </Col>

                    <Col span={collapsed ? 12 : 24}>
                      <Price
                        formProps={formProps}
                        collapsed={collapsed}
                        clearFilterKey={key => clearFilterKey(key)}
                      />
                    </Col>
                    {platformType !== DataSourcePlatform.Bytesights && (
                      <>
                        <Col span={collapsed ? 12 : 24}></Col>
                        <Col span={collapsed ? 12 : 24}>
                          <IncludeEstimatedCost formProps={formProps} />
                        </Col>
                      </>
                    )}
                  </Row>
                </Box>

                {platformType !== DataSourcePlatform.Bytesights && (
                  <Box p={10}>
                    <Typography size={16} color="black2" weight="semi-bold">
                      Audience
                    </Typography>

                    <LocationAudience
                      formProps={formProps}
                      collapsed={collapsed}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                    <GenderAudience
                      formProps={formProps}
                      collapsed={collapsed}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                    <AgeAudience
                      formProps={formProps}
                      collapsed={collapsed}
                      clearFilterKey={key => clearFilterKey(key)}
                    />
                  </Box>
                )}
              </>
            );
          }}
        </Form>
      </Loading>
    </>
  );
};

export { FilterForm, FilterFormContainer };
