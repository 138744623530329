// @ts-nocheck
import React, { useState } from "react";
import { PostingGuidelinesContainer } from "./2_PostingGuidlines/container";
import { AboutProductContainer } from "./1_AboutProduct/container";
import {
  GiveawayCampaignType,
  useUpdateGiftingCampaignMutation,
  UpdateGiftingCampaign,
  GiftingCampaignFragmentFragment,
} from "src/generated/ApolloComponents";
import omitDeep from "omit-deep-lodash";
import { ReviewContainer } from "./3_Review/container";

interface Props {
  campaign?: GiftingCampaignFragmentFragment;
  loading: boolean;
}

export const NewGiftingCampaign = ({
  campaign,
  loading: initialLoading,
}: Props) => {
  const [updateCampaign, { loading }] = useUpdateGiftingCampaignMutation();

  const initialValues = {
    title: campaign?.title,
    startDate: campaign?.startDate,
    endDate: campaign?.endDate,
    image: campaign?.image,
    brief: {
      campaignType: GiveawayCampaignType.Product,
      ...campaign?.brief,
    },
  };

  const onSubmit = async (values: UpdateGiftingCampaign) => {
    if (campaign?.id)
      await updateCampaign({
        variables: {
          campaignId: campaign?.id,
          data: omitDeep(values, "__typename"),
        },
      });

    nextStep();
  };

  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    case 0:
      return (
        <AboutProductContainer
          campaign={campaign}
          initialValues={initialValues}
          loading={initialLoading || loading}
          onSubmit={onSubmit}
          setStep={setStep}
          nextStep={nextStep}
        />
      );
    case 1:
      return (
        <PostingGuidelinesContainer
          campaign={campaign}
          initialValues={initialValues}
          onSubmit={onSubmit}
          loading={initialLoading || loading}
          setStep={setStep}
          previousStep={previousStep}
          nextStep={nextStep}
        />
      );
    default:
      return (
        <ReviewContainer
          campaign={campaign}
          loading={initialLoading || loading}
          setStep={setStep}
        />
      );
  }
};
