// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikRadio } from "src/components/Forms/Radio";
import { draft_format } from "src/constants/invitations";

interface Props {}

const DraftFormat: React.FC<Props> = () => {
  return (
    <Field label="Draft Format" mb={26}>
      <FormikRadio name="draftType" data={draft_format} />
    </Field>
  );
};

export default DraftFormat;
