// @ts-nocheck
import React, { Fragment } from "react";
import { FormikProps } from "formik";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { ages } from "src/constants/account";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { FieldError } from "src/components/Forms/ErrorMessage";

interface Props {
  formProps?: FormikProps<any>;
}

const AgeBreakdown: React.FC<Props> = ({ formProps }) => {
  return (
    <Fragment>
      <Typography
        mb={26}
        mt={26}
        fontWeight="semi-bold"
        color="black"
        size={16}
      >
        Age breakdown
      </Typography>

      <Box display="flex">
        <Box width="50%" mr="5px">
          <Typography size={14} color="grey2">
            Label:
          </Typography>
        </Box>
        <Box width="50%">
          <Typography size={14} color="grey2">
            Percentage:
          </Typography>
        </Box>
      </Box>

      {ages.map((age, i) => (
        <Box display="flex" key={i} marginBottom={10} mt={10}>
          <Box width="40%" mr="5px">
            {age.label}:
          </Box>
          <Box width="40%">
            <FormikInputNumber
              parser={value => {
                let parsed = value.replace(/\[0-9]/g, "");
                if (parsed) {
                  return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                } else {
                  return null;
                }
              }}
              name={`ageBreakdown[${age.label}]`}
              borderRadius={2}
              borderColor="grey5"
              size="middle"
            />
          </Box>
          <Box width="10%" mt={10} ml={10}>
            %
          </Box>
        </Box>
      ))}

      <FieldError errors={formProps?.errors as any} name={`ageBreakdown`} />
    </Fragment>
  );
};
export default AgeBreakdown;
