// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { DatePicker as BaseAntdDatePicker } from "antd";
import { ThemeColors } from "../../../utils/theme";

export interface DatePickerProps {
  size: "small" | "middle" | "large";
  format?: any;
  borderColor?: ThemeColors;
  background?: ThemeColors;
  borderRadius?: any;
  shouldShowError?: boolean;
  onChange?: any;
  onKeyPress?: any;
  value?: any;
  placeholder?: string;
  readOnly?: boolean;
  style?: any;
}

const AntdDatePicker: React.FC<DatePickerProps> = props => {
  const {
    size,
    borderColor,
    background,
    borderRadius,
    shouldShowError,
    ...rest
  } = props;

  return <BaseAntdDatePicker {...rest} />;
};

const StyledDatePicker = styled(AntdDatePicker)<DatePickerProps>`
  &&& {
    height: ${props =>
      props.theme.components.inputs.height[props.size]}px !important;
    font-size: ${props => props.theme.components.inputs.fontSize[props.size]}px;
    border: ${props =>
      `1px solid ${
        props?.borderColor
          ? props.theme.colors[props.borderColor]
          : props.theme.colors["grey5"]
      }`} !important;

    box-sizing: border-box;
    background-color: ${props =>
      props.background && props.theme.colors[props.background]} !important;
    border-radius: ${props => props.borderRadius}px !important;

    .ant-calendar-picker-icon {
      top: 45% !important;
    }

    .ant-input:focus {
      box-shadow: none !important;
    }
    .ant-input {
      border: none !important;
    }
  }
`;

export default StyledDatePicker;
