import React, { Component } from "react";
import styled from "styled-components";
import { Typography, Avatar, Tag } from "antd";

const { Text } = Typography;

const Details = styled("div")`
  margin-left: 15px;
`;

const Name = styled(Text)`
  &&& {
    font-size: 17px;
    color: #000;
    display: block;
  }
`;

const Views = styled(props => <Text type="secondary" {...props} />)`
  &&& {
    font-size: 14px;
    display: block;
  }
`;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
`;

export default class AccountDetails extends Component {
  render() {
    const { account, ...rest } = this.props;
    const label = account.suggestion ? (
      <Tag color="green" style={{ marginLeft: "10px" }}>
        Recommended
      </Tag>
    ) : (
      account.status === "Verified" && (
        <Tag color="geekblue" style={{ marginLeft: "10px" }}>
          Verified
        </Tag>
      )
    );

    return (
      <Wrapper {...rest}>
        <Avatar shape="square" size={60} src={account.profile_pic_url} />

        <Details>
          <Name data-cy="details-account-name">
            {account.username}
            {label}
          </Name>

          {account.formattedViews() && (
            <Views data-cy="details-account-views">
              {account.formattedViews()} views
            </Views>
          )}

          {account.formattedFollowers() ? (
            <Views>{account.formattedFollowers()} followers</Views>
          ) : null}
        </Details>
      </Wrapper>
    );
  }
}
