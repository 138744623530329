// @ts-nocheck
import styled from 'styled-components'

export const StyledButton = styled.div<{ connected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* Gray / gray-1 */

  background: #FFFFFF;
  /* Elevation 1 */

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 10px 10px 0px;
  width: fit-content;

  cursor: ${props => props.connected ? 'context-menu' : 'pointer'};
  > p {
    cursor: ${props => props.connected ? 'context-menu' : 'pointer'};
  }
`

export const Disabled = styled.div`
  padding: 10px;
  background: #FAFAFA;
  border-radius: 4px;
  width: fit-content;
`
