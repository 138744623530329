// @ts-nocheck
import React from "react";
import {
  GlobalErrorProps
} from "src/components/Forms/ErrorMessage";
import { SuggestionContainer } from "./container"
import { Loading } from "src/components/Core/Loading"
import SuggestedInfluencers from "src/components/Modules/Influencer/Suggested"

interface Props {
  fetchLoading: () => boolean
  globalErrors?: GlobalErrorProps[]
  title: string
  accepted: any
  pending: any
  handleUpdate: (suggestion: any) => void
  setPendingFilters: Function
  setAcceptedFilters: Function
}


const Suggestions = ({fetchLoading, ...props}: Props) => {
  return (
    <Loading spinning={fetchLoading()}>
      <SuggestedInfluencers {...props} />
    </Loading>
  );
}

export { SuggestionContainer, Suggestions }
