// @ts-nocheck
import React from 'react'
import { show } from 'redux-modal'
import styled from "styled-components";
import { useDispatch } from 'react-redux'

const EllipsisText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-left: 5px;
  color: #1890ff;
  display: block;
  white-space: nowrap;
  width: 105px;
  cursor: pointer;
`;

interface Props {
  suggestion: any;
}

const UserNameContent = ({suggestion}: Props) => {
  const dispatch = useDispatch();
  return(
    <EllipsisText
      onClick={() => {
        dispatch(
          show("InfluencerModal", {
            influencerId: Number(suggestion?.account?.influencerId),
            rationale: suggestion?.rationale,
            accountId: Number(suggestion?.account?.id),
          })
        )
      }}
    >
        @{suggestion?.account?.username}
    </EllipsisText>
  )
}

export default UserNameContent;
