// @ts-nocheck
import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Card } from "antd";
import Button from "src/components/Core/Button";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import NewPaymentModal from "./NewPaymentModal";
import Statistics from "./PaymentQueue/Statistics";
import PaymentQueue from "./PaymentQueue";
import { PaymentQueueProvider } from "./PaymentQueueProvider";

const Payments = () => {
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  return (
    <DashboardLayout title="Payment Request">
      <Box m={24}>
        <PaymentQueueProvider>
          <Card>
            <Box display="inline-flex">
              <Typography size={40} weight="semi-bold" color="black" mr={30}>
                Payment Queue
              </Typography>
              <Button
                type="action"
                buttonSize="middle"
                onClick={() => setShowPaymentForm(true)}
                data-cy="create-hero-button"
                mt={12}
              >
                New Request
              </Button>
              <Statistics />
            </Box>

            <PaymentQueue />

            <NewPaymentModal
              closePaymentModal={() => setShowPaymentForm(false)}
              showPaymentForm={showPaymentForm}
            />
          </Card>
        </PaymentQueueProvider>
      </Box>
    </DashboardLayout>
  );
};

export default Payments;
