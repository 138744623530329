import React, { Component } from "react";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import TableManager from "src/ducks/TableManager";
import styled from "styled-components";
import Container from "src/shared/Container";
import CampaignManage from "src/ducks/CampaignManage";
import { Box } from "src/components/Core/Box";
import { Radio } from "antd";
import Media from "./Media";
import InvitationsLinkTable from "./InvitationsLinkTable";
import { InvitationList as $InvitationList } from "src/ducks/DataList";

const InvitationLinkTable = TableManager("CampaignInvitationLinkList");
const InvitationLinkList = $InvitationList("CampaignInvitationLinkList");

const modules = [InvitationLinkTable.module(), InvitationLinkList.module()];

const ContentMode = styled(Radio.Group)`
  &&& {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #fff;
      background: #1890ff;
    }
    .ant-radio-button-wrapper {
      width: 100px;
    }
  }
`;

class PostedContent extends Component {
  state = {
    mode: "Media",
  };

  handleModeChange = e => {
    const mode = e.target.value;
    this.setState({ mode });
  };

  render() {
    const { mode } = this.state;
    return (
      <Container style={{ marginTop: "24px" }}>
        <Box textAlign="center">
          <ContentMode
            onChange={this.handleModeChange}
            value={mode}
            style={{ marginBottom: 8 }}
          >
            <Radio.Button value="Media">Media</Radio.Button>
            <Radio.Button value="Links">Links</Radio.Button>
          </ContentMode>
        </Box>

        {mode === "Media" && <Media />}
        {mode === "Links" && <InvitationsLinkTable />}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(PostedContent)
)(modules);
