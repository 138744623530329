import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "formik-antd";
import { connectModal } from "redux-modal";
import { createSelector } from "reselect";
import { Modal, Button, message } from "antd";
import { object } from "yup";
import { Typography } from "src/components/Core/Typography";
import { withFormik } from "formik";
import { TikTokUrl } from "src/shared/InvitationFields";
import FormError from "src/shared/FormField/FormError";
import { Invitation } from "src/ducks/Orm";
import { InvitationPersist } from "src/ducks/Persist";

const InvitationManage = InvitationPersist("CampaignInvitationLinkModal");

const Validate = object().shape({});

export class InvitationLinkModal extends Component {
  render() {
    const { show, handleHide, handleSubmit, isSubmitting, status } = this.props;
    return (
      <Modal
        visible={show}
        onCancel={handleHide}
        title="Add new content link"
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Update
          </Button>,
        ]}
        {...this.props}
      >
        <Form data-cy="campaign-invitation-link-form">
          {status &&
            Object.values(status.meta.errors).map((error, i) => (
              <FormError
                key={i}
                style={{ margin: "0 0 10px 0" }}
                message={error}
              />
            ))}
          <TikTokUrl />
          <Typography size={12} color="grey7" lineHeight={0} mt="-15px">
            Please ensure the post is still live
          </Typography>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => ({
  invitation: createSelector(
    state => state.entities,
    () => props.invitationId,
    Invitation.selectOne
  )(state, props),
});

const mapDispatchToProps = {
  updateInvitation: InvitationManage.update,
};

const EnhancedWithFormik = withFormik({
  validateOnBlur: false,
  enableReinitialize: true,

  mapPropsToValues: ({ invitation }) =>
    invitation ? invitation.ref : Validate.cast(),

  validationSchema: () => Validate,

  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus }) => {
    const { updateInvitation, handleHide, onUpdate, invitationId } = props;

    updateInvitation(invitationId, values)
      .then(res => {
        setSubmitting(false);
        handleHide();
        message.success("Link Updated Successfully");
        onUpdate && onUpdate();
      })
      .catch(err => {
        setSubmitting(false);
        message.error("Failed to update Link");
        setStatus(err.response.data);
        setErrors(err.response.data.meta.errors);
      });
  },

  displayName: "CampaignInvitationLinkModal",
})(InvitationLinkModal);

const EnhancedLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedWithFormik);

const EnhancedLinkUpdateModal = connectModal({
  name: "CampaignInvitationLinkModal",
  destroyOnHide: false,
})(EnhancedLink);

export default EnhancedLinkUpdateModal;
