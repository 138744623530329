// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { Wrapper, PreviewCard } from './styles'
import { Image } from 'antd';

interface Props {
  paymentDetail: any;
}

const Attachments: React.FC<Props> = ({ paymentDetail }) => {

  const getPreview = (item) => {
    switch (item.split('?')[0].split('.').pop()) {
      case 'apng':
      case 'avif':
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'pjpeg':
      case 'pjp':
      case 'png':
      case 'svg':
      case 'webp':
        return <PreviewCard cover={<Image src={item} alt="attachment"/>} />
      default:
        return <a href={item} target="_blank" rel="noopener noreferrer" alt="view attachment">View Attachment</a>
    }
  }

  return (
    <>
      {(paymentDetail?.previewAttachments || []).length > 0 && (
        <Box mt={40}>
          <Typography size={14} color="black" lineHeight={0}>
            Attachments:
          </Typography>

          <Wrapper>
            {(paymentDetail?.previewAttachments || []).map((item: any, i) => (
              <div key={i}>
                {getPreview(item)}
              </div>
            ))}
          </Wrapper>
        </Box>
      )}
    </>
  );
};

export default Attachments;
