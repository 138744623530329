// @ts-nocheck
import React, { Fragment } from "react";
import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Descriptions } from "antd";
import { bankFields } from "src/constants/payments";
import { Loading } from "src/components/Core/Loading";
import { Button } from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";
import { LinkButton } from "src/components/Core/LinkButton";
import { Box } from "src/components/Core/Box";
import BasicDetails from "./BasicDetails";
import PaymentStatus from "./PaymentStatus";
import Attachments from "./Attachments";
import moment from "moment";

interface Props {
  paymentDetail: any;
  isSuperAdmin: boolean;
  onAccept: (value: any) => void;
  onReject: (value: any) => void;
  onReconcile: (value: any) => void;

  onUndo: (value: any) => void;
  onDelete: (value: any) => void;
  loading: boolean;
}

const PaymentDetail: React.FC<Props> = ({
  paymentDetail,
  isSuperAdmin,
  onAccept,
  onReject,
  onReconcile,
  onUndo,
  onDelete,
  loading,
}) => {
  return (
    <Fragment>
      <Loading spinning={loading}>
        <Box display="inline-flex">
          <Typography size={40} weight="semi-bold" color="black">
            Payment Request #{paymentDetail?.id}
          </Typography>
          <PaymentStatus
            paymentDetail={paymentDetail}
            isSuperAdmin={isSuperAdmin}
            onUndo={value => onUndo(value)}
          />
        </Box>

        <Row gutter={48} justify="start">
          <Col span={12}>
            <BasicDetails paymentDetail={paymentDetail} />
          </Col>

          <Col span={12}>
            <Descriptions size="small" bordered column={1}>
              {['reconciled', 'approved'].includes(paymentDetail?.status) && (
                <Fragment>
                  <Descriptions.Item label="Paypal email">
                    {paymentDetail?.paymentDetail?.paypalEmail}
                  </Descriptions.Item>

                  <Descriptions.Item label="Paypal transaction ID">
                    {paymentDetail?.paymentDetail?.paypalTransactionId}
                  </Descriptions.Item>
                </Fragment>
              )}
              {paymentDetail?.paymentDetail?.bankAccount &&
                Object.keys(
                  paymentDetail?.paymentDetail?.bankAccount || {}
                ).map(function(key, i) {
                  return (
                    <Descriptions.Item key={i} label={bankFields[key]}>
                      {paymentDetail?.paymentDetail?.bankAccount[key]}
                    </Descriptions.Item>
                  );
                })}
            </Descriptions>

            <Box mt={20}>
              <Descriptions size="small" bordered column={1}>
                <Descriptions.Item label="Due Date">
                  {paymentDetail?.status !== "rejected" &&
                  paymentDetail?.dueDate
                    ? `${moment(paymentDetail?.dueDate).format(
                        "DD/MM/YYYY"
                      )}  [28 days]`
                    : "--"}
                </Descriptions.Item>
              </Descriptions>
            </Box>

            <Attachments paymentDetail={paymentDetail} />
          </Col>
        </Row>
        {paymentDetail?.status === "pending" && (
          <Box alignItems="right" textAlign="right" display="inherit" mt={100}>
            {isSuperAdmin ? (
              <>
                <LinkButton
                  onClick={() => onReject(paymentDetail?.id)}
                  mr={50}
                  color="red6"
                  weight="semi-bold"
                >
                  Reject
                </LinkButton>

                <Button
                  htmlType="submit"
                  type="action"
                  buttonSize="small"
                  data-cy="submit-button"
                  onClick={() => onAccept(paymentDetail?.id)}
                >
                  Approve
                </Button>
              </>
            ) : (
              <LinkButton
                onClick={() => onDelete(paymentDetail?.id)}
                color="red6"
                fontSize={14}
                weight="semi-bold"
              >
                <DeleteOutlined /> Delete request
              </LinkButton>
            )}
          </Box>
        )}

        {isSuperAdmin && paymentDetail?.status === "approved" && (
          <Box alignItems="right" textAlign="right" display="inherit" mt={100}>
            <Button
              htmlType="submit"
              type="action"
              buttonSize="small"
              data-cy="submit-button"
              onClick={() => onReconcile(paymentDetail?.id)}
            >
              Reconcile
            </Button>
          </Box>
        )}
      </Loading>
    </Fragment>
  );
};

export default PaymentDetail;
