// @ts-nocheck
import React from 'react'
import { Row, Col } from 'antd'
import { Box } from 'src/components/Core/Box'
import { Typography } from 'src/components/Core/Typography'

interface Props {
  campaign: any
}

const Header = ({campaign}: Props) => {
  const getTextAligh = () => {
    return window.innerWidth > 767 ? 'start' : 'center'
  }

  return(
    <Box pt={40} px={40}>
      <Row>
        <Col md={14} sm={24}>
          <Typography mb={0} fontSize={24} color="black1" textAlign={getTextAligh()} family="Futura">
            {campaign?.title}
          </Typography>
          <Typography fontSize={18} color="black1" textAlign={getTextAligh()}>by <u>{campaign?.company?.companyName}</u></Typography>
        </Col>
        <Col md={5} sm={0} xs={0}>
          <Typography mb={0} color="black1" family="Futura">Publishing on:</Typography>
          <Typography color="black1">{campaign?.distributionPlatforms?.join(',')}</Typography>
        </Col>
        <Col md={5} sm={0} xs={0}>
          <Typography mb={0} color="black1" family="Futura">Post type:</Typography>
          <Typography color="black1">{campaign?.adFormat}</Typography>
        </Col>
      </Row>
    </Box>
  )
}

export default Header
