// @ts-nocheck
import * as React from "react";
import styled from "styled-components";
import Input from "src/components/Core/Input";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";

export const FeedbackItemWrapper = styled.div<{ userType?: string }>`
  align-items: center;
  padding: ${props =>
    props.userType === "brand" ? "5px 0px 5px 0px" : "16px"};
`;

export const SerialNumber = styled("p")`
  font-weight: 500;
  flex: 0.7;
  margin: 0;
  letter-spacing: 0.4px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0px !important;
`;

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AdminFeedbackInput = styled(props => {
  const { isDisabled, ...rest } = props;
  return <Input {...rest} />;
})`
  && {
    flex: 8;
    border-radius: 2px;
    background-color: #f9f9f9;
  }
`;

export const TimestampInput = styled(props => {
  const { isDisabled, ...rest } = props;
  return <FormikInputNumber {...rest} />;
})`
  && {
    flex: 3;
    border-radius: 2px;
    background-color: #f9f9f9;
    width: 30px;
    font-size: 10px;
    height: 22px !important;
    line-height:22px !important;
  }
`;
