// @ts-nocheck
import React from 'react'
import { Row } from 'antd'
import Applicants from './Applicants'
import { DragDropContext } from 'react-beautiful-dnd'
import { ManageApplicantsContainer } from './container'
import {
  GlobalErrors,
  GlobalErrorProps,
} from 'src/components/Forms/ErrorMessage'
import Loading from 'src/shared/Loading'
import InfluencerModal from 'src/scenes/brands/CampaignManage/FindInfluencers/InfluencerModal'

interface Props {
  data?: any
  loading?: boolean
  onDragEnd?: Function
  requestData: (acc) => void
  handleNotify: (accId) => void
  handleConfirm: (accId) => void
  globalErrors?: GlobalErrorProps[];
}

const ManageApplicants = ({loading, data, onDragEnd, globalErrors, handleConfirm, handleNotify, requestData}: Props) => {
  const getApplicants = () => {
    const applicants = data?.applicants || []
    const express = data?.express || []
    return [...applicants, ...express]
  }

  return (
    <Loading spinning={loading}>
      <InfluencerModal />
      <DragDropContext onDragEnd={onDragEnd}>
        <Row

          gutter={24}
          style={{
            margin: "12px",
            padding: "0 24px",
            flexWrap: "nowrap",
            height: "calc(100% - 61px)",
          }}
          >
          {globalErrors && <GlobalErrors errors={globalErrors} />}
          <Applicants moduleKey="applicants" accounts={getApplicants() as []} handleClick={requestData} />
          <Applicants moduleKey="accepted" accounts={data?.accepted || []} handleClick={handleConfirm} />
          <Applicants moduleKey="rejected" accounts={data?.rejected || []} handleClick={handleNotify} />
        </Row>
      </DragDropContext>
    </Loading>
  );
}

export  {ManageApplicants, ManageApplicantsContainer}
