// @ts-nocheck
import React from "react";
import { FilterOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import { FilterProps } from "../PaymentQueueList";
import {
  SearchInput,
  Price,
  CostType,
  Date,
  Status,
  PaymentType,
  Campaign,
  DueDate,
  Currency,
  HubSpotDealId
} from "./Fields";

interface Props {
  handleChange: (key: string, value: any) => void;
  isShowFilter: boolean;
  showFilter: () => void;
  filters: FilterProps;
  resetFilter: () => void;
  filterDataSoruce: any;
}

const Filters: React.FC<Props> = ({
  handleChange,
  isShowFilter,
  showFilter,
  filters,
  resetFilter,
  filterDataSoruce,
}) => {
  return (
    <Box mt={10} mb={10}>
      <Row gutter={32}>
        <Col span={20}>
          <SearchInput
            handleChange={(key, value) => handleChange(key, value)}
            filters={filters}
            filterDataSoruce={filterDataSoruce}
          />
        </Col>

        <Col span={4}>
          <Button buttonSize="large" onClick={() => showFilter()}>
            <Typography size={14} color="grey7" pt={10} cursor="pointer">
              <FilterOutlined /> Filter
            </Typography>
          </Button>
        </Col>
      </Row>
      {isShowFilter && (
        <Box p={21} mt={30} mb={20} borderRadius={3} background="#F6F6F6">
          <Typography
            size={14}
            color="red"
            cursor="pointer"
            onClick={() => resetFilter()}
          >
            Clear all filters
          </Typography>
          <Row gutter={8}>
            <Col span={5}>
              <Price
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>
            <Col span={4}>
              <CostType
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>
            <Col span={9}>
              <Date
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>
            <Col span={4}>
              <Status
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>

            <Col span={5}>
              <Campaign
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>
            <Col span={4}>
              <PaymentType
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>
            {/* <Col span={4}>
              <PaypalTransactionId
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col> */}
            <Col span={9}>
              <DueDate
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col>
              <Currency
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>
            <Col>
              <HubSpotDealId
                handleChange={(key, value) => handleChange(key, value)}
                filters={filters}
              />
            </Col>
          </Row>
        </Box>
      )}
    </Box>
  );
};

export default Filters;
