import React from "react";
import { Row, Col } from "antd";
import {
  Input,
  Select,
  Switch,
  DatePicker,
  Checkbox,
} from "formik-antd";
import SearchSelect from "src/shared/SearchSelect";
import { Tag, Campaign } from "src/api/endpoints";
import moment from "moment";
import formatCurrency from "src/utils/formatCurrency";
import {
  types,
  ages,
  locations,
  statuses,
  formats,
  actives,
} from "src/constants/account";
import { genders } from "src/constants/influencer";
import {
  AgesBreakdown,
  GenderBreakdown,
  LocationsBreakdown,
  AmountPaid,
  CampaignCompleted,
  ClickThroughRate,
  CostPerClick,
  CostPerInstall,
  CPM,
  Hearts,
  Followers,
  AccountTag,
} from "src/shared/AccountFilterFields";
import styled from "styled-components";
import store from "src/store/fanbytesStore";
import idx from "idx";

const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default {
  genderBreakdown: {
    heading: "Audience",
    input: value => {
      const gb =
        typeof value !== "undefined"
          ? Object.keys(value).map(function(key) {
              return {
                key: key,
                comparison: value[key].comparison,
                value: value[key].value,
              };
            })
          : [];

      return <GenderBreakdown genderBreakdown={gb} />;
    },
    label: "Gender Breakdown",
    span: 24,
    customComponent: true,
    incomingTransformData: (filters, currentValues) => {
      if (
        typeof filters.gender_skew_percentage !== "undefined" &&
        filters.gender_skew_percentage.length > 0
      ) {
        currentValues["genderBreakdown"] = filters.gender_skew_percentage;
      }
      return currentValues;
    },
    onSubmitTransformData: values => {
      if (
        typeof values.genderBreakdown !== "undefined" &&
        values.genderBreakdown.length > 0
      ) {
        values["gender_skew_percentage"] = values.genderBreakdown;
        delete values.genderBreakdown;
      }

      if (
        typeof values.genderBreakdown !== "undefined" &&
        values.genderBreakdown.length === 0
      ) {
        values["genderBreakdown"] = undefined;
        values["gender_skew_percentage"] = undefined;
      }
      return values;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (
        typeof filters.gender_skew_percentage !== "undefined" &&
        filters.gender_skew_percentage.length > 0
      ) {
        filterValue = Object.keys(filters.gender_skew_percentage).map(function(
          key
        ) {
          const sign =
            filters.gender_skew_percentage[key].comparison === "lt"
              ? "<"
              : filters.gender_skew_percentage[key].comparison === "gt"
              ? ">"
              : "";

          return `${filters.gender_skew_percentage[key].key}
          ${sign} ${filters.gender_skew_percentage[key].value}%`;
        });
      }
      return filterValue;
    },
    actualName: "gender_skew_percentage",
  },
  ageBreakdown: {
    input: value => {
      const at =
        typeof value !== "undefined"
          ? Object.keys(value).map(function(key) {
              return {
                key: key,
                comparison: value[key].comparison,
                value: value[key].value,
              };
            })
          : [];
      return <AgesBreakdown ageBreakdown={at} />;
    },
    label: "Age Breakdown",
    span: 24,
    customComponent: true,
    incomingTransformData: (filters, currentValues) => {
      if (
        typeof filters.age_skew_percentage !== "undefined" &&
        filters.age_skew_percentage.length > 0
      ) {
        currentValues["ageBreakdown"] = filters.age_skew_percentage;
      }
      return currentValues;
    },
    onSubmitTransformData: values => {
      if (
        typeof values.ageBreakdown !== "undefined" &&
        values.ageBreakdown.length > 0
      ) {
        values["age_skew_percentage"] = values.ageBreakdown;
        delete values.ageBreakdown;
      }

      if (
        typeof values.ageBreakdown !== "undefined" &&
        values.ageBreakdown.length === 0
      ) {
        values["ageBreakdown"] = undefined;
        values["age_skew_percentage"] = undefined;
      }
      return values;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (
        typeof filters.age_skew_percentage !== "undefined" &&
        filters.age_skew_percentage.length > 0
      ) {
        filterValue = Object.keys(filters.age_skew_percentage).map(function(
          key
        ) {
          const sign =
            filters.age_skew_percentage[key].comparison === "lt"
              ? "<"
              : filters.age_skew_percentage[key].comparison === "gt"
              ? ">"
              : "";

          return `${filters.age_skew_percentage[key].key}
          ${sign} ${filters.age_skew_percentage[key].value}%`;
        });
      }
      return filterValue;
    },
    actualName: "age_skew_percentage",
  },
  countryBreakdown: {
    input: value => {
      const cb =
        typeof value !== "undefined"
          ? Object.keys(value).map(function(key) {
              return {
                key: key,
                comparison: value[key].comparison,
                value: value[key].value,
              };
            })
          : [];
      return <LocationsBreakdown countryBreakdown={cb} />;
    },
    label: "Country Breakdown",
    span: 24,
    customComponent: true,
    incomingTransformData: (filters, currentValues) => {
      if (
        typeof filters.country_percentage !== "undefined" &&
        filters.country_percentage.length > 0
      ) {
        currentValues["countryBreakdown"] = filters.country_percentage;
      }
      return currentValues;
    },
    onSubmitTransformData: values => {
      if (
        typeof values.countryBreakdown !== "undefined" &&
        values.countryBreakdown.length > 0
      ) {
        values["country_percentage"] = values.countryBreakdown;
        delete values.countryBreakdown;
      }

      if (
        typeof values.countryBreakdown !== "undefined" &&
        values.countryBreakdown.length === 0
      ) {
        values["countryBreakdown"] = undefined;
        values["country_percentage"] = undefined;
      }
      return values;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (
        typeof filters.country_percentage !== "undefined" &&
        filters.country_percentage.length > 0
      ) {
        filterValue = Object.keys(filters.country_percentage).map(function(
          key
        ) {
          const sign =
            filters.country_percentage[key].comparison === "lt"
              ? "<"
              : filters.country_percentage[key].comparison === "gt"
              ? ">"
              : "";

          return `${filters.country_percentage[key].key}
          ${sign} ${filters.country_percentage[key].value}%`;
        });
      }
      return filterValue;
    },
    actualName: "country_percentage",
  },
  completed_campaigns: {
    heading: "Influencer peformance",
    input: () => <CampaignCompleted />,
    label: "Campaign Completed",
    span: 24,
    initialValue: {
      comparison: "gt",
      value: 0,
      months: "ever",
    },
    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        typeof newValues.completed_campaigns.value !== "undefined" &&
        (newValues.completed_campaigns.value === 0 ||
          newValues.completed_campaigns.value === "" ||
          newValues.completed_campaigns.value === null)
      ) {
        newValues["completed_campaigns"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.completed_campaigns !== "undefined") {
        const sign =
          filters.completed_campaigns.comparison === "lt"
            ? "<"
            : filters.completed_campaigns.comparison === "gt"
            ? ">"
            : "";

        const month =
          filters.completed_campaigns.months === "last_1_months"
            ? "1 months"
            : filters.completed_campaigns.months === "last_3_months"
            ? "3 months"
            : "ever";

        return `
          ${sign} ${filters.completed_campaigns.value} for ${month} `;
      }
      return filterValue;
    },
  },
  last_paid_amount: {
    input: () => <AmountPaid />,
    label: "Amount Paid",
    span: 12,
    initialValue: {
      min: "",
      max: "",
    },

    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        (typeof newValues.last_paid_amount.min !== "undefined" &&
          newValues.last_paid_amount.min === "") ||
        (typeof newValues.last_paid_amount.max !== "undefined" &&
          newValues.last_paid_amount.max === "")
      ) {
        newValues["last_paid_amount"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;

      if (typeof filters.last_paid_amount !== "undefined") {
        return `${formatCurrency(
          filters.last_paid_amount.min
        )} - ${formatCurrency(filters.last_paid_amount.max)}`;
      }
      return filterValue;
    },
  },
  last_campaign_date: {
    input: () => (
      <FullWidthDatePicker
        data-cy="date-picker"
        size="large"
        format="DD/MM/YYYY"
        placeholder="Select date"
      />
    ),
    label: "Campaign ran since",
    span: 12,
    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        typeof newValues.last_campaign_date !== "undefined" &&
        newValues.last_campaign_date !== null
      ) {
        newValues["last_campaign_date"] = moment(
          newValues.last_campaign_date
        ).format("YYYY-MM-DD");
      } else {
        newValues["last_campaign_date"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.last_campaign_date !== "undefined") {
        return moment(filters.last_campaign_date).format("DD/MM/YYYY");
      }
      return filterValue;
    },
  },
  avg_ctr: {
    input: () => <ClickThroughRate />,
    label: "Click Through Rate",
    span: 12,
    initialValue: {
      comparison: "gt",
      value: 0,
    },
    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        typeof newValues.avg_ctr.value !== "undefined" &&
        (newValues.avg_ctr.value === 0 || newValues.avg_ctr.value === "")
      ) {
        newValues["avg_ctr"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.avg_ctr !== "undefined") {
        const sign =
          filters.avg_ctr.comparison === "lt"
            ? "<"
            : filters.avg_ctr.comparison === "gt"
            ? ">"
            : "";

        return `
          ${sign} ${filters.avg_ctr.value}`;
      }
      return filterValue;
    },
  },
  avg_cpi: {
    input: () => <CostPerInstall />,
    label: "Cost per Install",
    span: 12,
    initialValue: {
      comparison: "gt",
      value: 0,
    },
    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        typeof newValues.avg_cpi.value !== "undefined" &&
        (newValues.avg_cpi.value === 0 || newValues.avg_cpi.value === "")
      ) {
        newValues["avg_cpi"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.avg_cpi !== "undefined") {
        const sign =
          filters.avg_cpi.comparison === "lt"
            ? "<"
            : filters.avg_cpi.comparison === "gt"
            ? ">"
            : "";

        return `
          ${sign} ${formatCurrency(filters.avg_cpi.value)}`;
      }
      return filterValue;
    },
  },
  avg_cpc: {
    input: () => <CostPerClick />,
    label: "Cost per Click",
    span: 12,
    initialValue: {
      comparison: "gt",
      value: 0,
    },
    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        typeof newValues.avg_cpc.value !== "undefined" &&
        (newValues.avg_cpc.value === 0 || newValues.avg_cpc.value === "")
      ) {
        newValues["avg_cpc"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.avg_cpc !== "undefined") {
        const sign =
          filters.avg_cpc.comparison === "lt"
            ? "<"
            : filters.avg_cpc.comparison === "gt"
            ? ">"
            : "";

        return `
          ${sign} ${formatCurrency(filters.avg_cpc.value)}`;
      }
      return filterValue;
    },
  },
  cpm: {
    input: () => <CPM />,
    label: "Cost per 1000 Views",
    span: 12,
    initialValue: {
      comparison: "gt",
      value: 0,
    },
    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        typeof newValues.cpm.value !== "undefined" &&
        (newValues.cpm.value === 0 || newValues.cpm.value === "")
      ) {
        newValues["cpm"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.cpm !== "undefined") {
        const sign =
          filters.cpm.comparison === "lt"
            ? "<"
            : filters.cpm.comparison === "gt"
            ? ">"
            : "";

        return `
          ${sign} ${formatCurrency(filters.cpm.value)}`;
      }
      return filterValue;
    },
  },

  hearts: {
    input: () => <Hearts />,
    label: "Hearts",
    span: 12,
    initialValue: {
      comparison: "gt",
      value: 0,
    },
    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        typeof newValues.hearts.value !== "undefined" &&
        (newValues.hearts.value === 0 || newValues.hearts.value === "")
      ) {
        newValues["hearts"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.hearts !== "undefined") {
        const sign =
          filters.hearts.comparison === "lt"
            ? "<"
            : filters.hearts.comparison === "gt"
            ? ">"
            : "";

        return `
          ${sign} ${filters.hearts.value}`;
      }
      return filterValue;
    },
  },

  campaign: {
    input: () => (
      <SearchSelect
        size="large"
        placeholder="Select..."
        loadData={value =>
          Campaign.index({ params: { search: value } }).then(
            res => res.data.data
          )
        }
        optionGenerator={value => ({
          value: value.id,
          label: value.title,
        })}
      />
    ),
    parse: value => (value ? parseInt(value) : null),
    label: "Campaign",
    span: 12,
  },

  followers: {
    input: () => <Followers />,
    label: "Followers",
    span: 24,
    initialValue: {
      comparison: "gt",
      value: 0,
    },
    onSubmitTransformData: values => {
      const newValues = { ...values };
      if (
        typeof newValues.followers.value !== "undefined" &&
        (newValues.followers.value === 0 || newValues.followers.value === "")
      ) {
        newValues["followers"] = undefined;
      }
      return newValues;
    },
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.followers !== "undefined") {
        const sign =
          filters.followers.comparison === "lt"
            ? "<"
            : filters.followers.comparison === "gt"
            ? ">"
            : "";

        return `
          ${sign} ${filters.followers.value}`;
      }
      return filterValue;
    },
  },

  // followers: {
  //   input: () => (
  //     <Input
  //       data-cy="account-followers-filter"
  //       size="large"
  //       placeholder="e.g. 10"
  //       style={{ width: "45%" }}
  //     />
  //   ),
  //   label: "Followers",
  //   span: 24,
  // },

  username: {
    heading: "Influencer",
    input: () => (
      <Input
        data-cy="account-username-filter"
        size="large"
        placeholder="e.g. Adidas"
      />
    ),
    label: "Username",
    span: 12,
  },

  status: {
    input: () => (
      <Checkbox.Group>
        <Row gutter={8}>
          {statuses.map((value, i) => (
            <Col span={24} key={i}>
              <Checkbox value={value.value}>{value.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    ),
    label: "Status",
    span: 12,
  },

  type: {
    input: () => (
      <Checkbox.Group>
        <Row gutter={8}>
          {types.map((value, i) => (
            <Col span={12} key={i}>
              <Checkbox value={value.value}>{value.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    ),
    label: "Type",
    span: 12,
    style: { height: "104px" },
  },

  format: {
    input: () => (
      <Select size="large" placeholder="Select..." mode="multiple" options={formats}>
      </Select>
    ),
    label: "Formats",
    span: 12,
    initialValue: "Human",
  },

  tags: {
    input: () => <AccountTag />,
    parse: value => value.map(x => parseInt(x)),
    label: "Tags",
    span: 12,
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.tags !== "undefined") {
        let state = store.getState();

        let displyTags = filters.tags
          .map(v =>
            idx(state, x => x.entities.Tag.itemsById[v])
              ? idx(state, x => x.entities.Tag.itemsById[v]).name ===
                "Business & Finance"
                ? "Bus. & Fin."
                : idx(state, x => x.entities.Tag.itemsById[v]).name
              : null
          )
          .filter(v => v !== null)
          .join(", ");

        return displyTags;
      }
      return filterValue;
    },
  },

  active: {
    input: () => (
      <Select size="large" placeholder="Select..." options={actives}>
      </Select>
    ),
    label: "Active",
    span: 12,
  },

  managed: {
    input: () => <Switch />,
    label: "Manage Talent",
    span: 12,
  },

  sales_appropriate: {
    input: () => <Switch />,
    label: "Sales Appropriate",
    span: 12,
  },

  custom_tags: {
    input: () => (
      <SearchSelect
        size="large"
        placeholder="Select..."
        mode="multiple"
        loadData={value =>
          Tag.index({ params: { search: value, is_defined: false } }).then(
            res => res.data.data
          )
        }
        optionGenerator={value => ({
          value: value.id,
          label: value.name,
        })}
      />
    ),
    parse: value => value.map(x => parseInt(x)),
    label: "Custom Tags",
    span: 12,
    displayValue: filters => {
      let filterValue = undefined;
      if (typeof filters.custom_tags !== "undefined") {
        let state = store.getState();
        let displyTags = filters.custom_tags
          .map(v =>
            idx(state, x => x.entities.Tag.itemsById[v])
              ? idx(state, x => x.entities.Tag.itemsById[v]).name
              : null
          )
          .filter(v => v !== null)
          .join(", ");

        return displyTags;
      }
      return filterValue;
    },
  },

  influencer_age: {
    input: () => (
      <Checkbox.Group>
        <Row gutter={8}>
          {ages.map((value, i) => (
            <Col span={12} key={i}>
              <Checkbox value={value.value}>{value.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    ),
    label: "Influencer Age",
    span: 12,
    style: { height: "104px" },
  },
  influencer_gender: {
    input: () => (
      <Checkbox.Group>
        <Row gutter={8}>
          {genders.map((value, i) => (
            <Col span={24} key={i}>
              <Checkbox value={value.value}>{value.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    ),
    label: "Influencer Gender",
    span: 12,
  },
  influencer_location: {
    input: () => (
      <SearchSelect
        size="large"
        placeholder="Select..."
        mode="multiple"
        data={locations}
        local
      />
    ),
    label: "Influencer Location",
    span: 12,
  },
  influencer_email: {
    input: () => <Input size="large" placeholder="e.g. joe@bloggs.com" />,
    label: "Influencer Email",
    span: 12,
  },
};
