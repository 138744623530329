// @ts-nocheck
import styled from 'styled-components'
import { Card } from 'antd'


export const Wrapper = styled("div")`
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 16px;
  margin-right: 8px;
  margin-left: 8px;
`;

export const PreviewCard = styled(Card)`
  && {
    height: 57px;
    width: 57px;
    display: inline-block;
    padding: 0px !important;
    border: none !important;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    cursor: pointer;

    .ant-card-body {
      padding: 0px !important;
    }
    .ant-card-cover {
      overflow: hidden;
      border-radius: 2px;
      object-fit: cover;
    }

    .ant-card-cover:hover > div {
      opacity: 1;
    }
  }
`;
