// @ts-nocheck
import React, { useState } from "react"
import { FormWrapper } from "../Wrapper"
import { Form } from "src/components/Forms/Form"
import { Schema } from "yup"

import { Typography } from "src/components/Core/Typography"
import { Field } from "src/components/Forms/Field"
import { FormikInput } from "src/components/Forms/TextInput"
import { Button } from "src/components/Core/Button"
import { Label } from "src/components/Forms/Label"
import { Box } from "src/components/Core/Box"

import tiktokLogo from "src/assets/images/tiktok_logo.png"
import snapchatLogo from "src/assets/images/snapchat_logo.png"
import youtubeLogo from "src/assets/images/youtube_logo.png"
import instagramLogo from "src/assets/images/instagram_logo.png"
import { AddAccountModalContainer } from "./AddAccountModal/container"
import AccountRow from "./AccountRow"
import SocialButton from "./SocialButton"
import { Result } from "antd"

export interface InfluencerSignupFormAccountValues {
  username: string
  type: string
  payoutPennies: number
}

export interface InfluencerSignupFormValues {
  email: string
  name: string
  phoneNumber: string
  accounts: InfluencerSignupFormAccountValues[]
}

interface ModalState {
  visible: boolean
  platform: string
  updateKey?: number
  data?: any
}

interface Props {
  initialValues: any
  completed: boolean
  loading: boolean
  schema: Schema<InfluencerSignupFormValues>
  accounts: InfluencerSignupFormAccountValues[]
  onSubmit: (values: any, actions: any) => any
  onNewAccountSubmit: (values: any, actions: any) => any
  onRemoveAccount: (index: number) => void
}

const InfluencerSignupForm = ({
  initialValues,
  schema,
  onSubmit,
  accounts,
  loading,
  completed,
  onNewAccountSubmit,
  onRemoveAccount
}: Props) => {
  const [accountModal, setAccountModal] = useState<ModalState>({
    visible: false,
    platform: ""
  })

  const editAccountData = (index: number, details: InfluencerSignupFormAccountValues) => {
    setAccountModal({
      visible: true,
      platform: details.type,
      data: {
        ...details,
        index
      }
    })
  }

  return (
    <>
      <Form initialValues={initialValues} schema={schema} onSubmit={onSubmit}>
        <FormWrapper>
          {completed ? (
            <Result
              status="success"
              title="You have successfully joined!"
              subTitle="An admin will be in touch shortly with more details"
            />
          ) : (
            <>
              <Typography size={16} fontWeight={500} mb={30} color="black" textAlign="center">
                Join the Fanbytes platform!
              </Typography>

              <Field label="Name" mb={26}>
                <FormikInput name="name" placeholder="e.g. Joe Bloggs" />
              </Field>

              <Field label="Email" mb={26}>
                <FormikInput name="email" placeholder="e.g. joe@bloggs.com" />
              </Field>

              <Field label="Phone number" mb={26}>
                <FormikInput name="phoneNumber" placeholder="e.g. +44 7586 748 298" />
              </Field>

              <Label>Add a social channel</Label>

              <Box mb={25}>
                <SocialButton
                  image={tiktokLogo}
                  onClick={() => setAccountModal({ visible: true, platform: "TikTok" })}
                />
                <SocialButton
                  image={snapchatLogo}
                  onClick={() => setAccountModal({ visible: true, platform: "Snapchat" })}
                />
                <SocialButton
                  image={youtubeLogo}
                  onClick={() => setAccountModal({ visible: true, platform: "Youtube" })}
                />
                <SocialButton
                  image={instagramLogo}
                  onClick={() => setAccountModal({ visible: true, platform: "Instagram" })}
                />
              </Box>

              {accounts.map((x, k) => (
                <AccountRow
                  key={k}
                  username={x.username}
                  type={x.type}
                  onEdit={() => editAccountData(k, x)}
                  onRemove={() => onRemoveAccount(k)}
                />
              ))}

              <Button
                htmlType="submit"
                type="action"
                loading={loading}
                disabled={loading}
                buttonSize="middle"
              >
                Complete Signup
              </Button>
            </>
          )}
        </FormWrapper>
      </Form>

      <AddAccountModalContainer
        visible={accountModal.visible}
        platform={accountModal.platform}
        initialValues={accountModal.data}
        setVisible={(value: boolean) =>
          setAccountModal({ visible: value, platform: accountModal.platform })
        }
        onSubmit={onNewAccountSubmit}
      />
    </>
  )
}

export default InfluencerSignupForm
