// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Link } from "react-router-dom";
import { formattedStatus } from "src/components/Modules/Campaign/utils";
import compact from "lodash/compact";
import moment from "moment";
import formatCurrency from "src/utils/formatCurrency";
import { currency as allCuurency } from "src/constants/payments";
import numeral from "numeral";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";

const getColumns = ({ sorter, columnVisibility }) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
      sortOrder: sorter.field === "title" && sorter.order,
      width: 200,
      visible: columnVisibility["title"],
      render: (text, row) => (
        <Link
          to={
            row?.isGroup
              ? `/campaign-group/${row?.id}`
              : `/campaigns/${row?.id}`
          }
        >
          <Typography size={12} cursor="pointer" mb={0}>
            {row?.isGroup && "[Group]"} {row?.title ? row?.title : "-"}
          </Typography>
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortOrder: sorter.field === "status" && sorter.order,
      width: 120,
      visible: columnVisibility["status"],
      render: (text, row) => formattedStatus(row?.status),
    },
    {
      title: "Platforms",
      dataIndex: "platforms",
      key: "platforms",
      width: 120,
      visible: columnVisibility["platforms"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.platforms && row?.platforms.join(", ")}
        </Typography>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 130,
      visible: columnVisibility["startDate"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.startDate ? moment(row?.startDate).format("Do MMM YYYY") : ""}
        </Typography>
      ),
    },
    {
      title: "Budget",
      dataIndex: "budgetPennies",
      key: "budgetPennies",
      width: 130,
      visible: columnVisibility["budgetPennies"],
      render: (text, row) => {
        let currencySign = find(
          allCuurency,
          matchesProperty("value", row?.budgetCurrency)
        );

        return (
          <Typography size={12} mb={0}>
            {row?.budgetCurrency === "MIX" ? "[MIX]" : currencySign?.label}
            {row?.budgetPennies
              ? numeral(row?.budgetPennies / 100).format("0,0[.]00")
              : "--"}
          </Typography>
        );
      },
    },
    {
      title: "Cost of sales",
      dataIndex: "costOfSales",
      key: "costOfSales",
      width: 130,
      visible: columnVisibility["costOfSales"],
      render: (text, row) => (
        <Typography size={12} mb={0}>
          {row?.costOfSales ? formatCurrency(row?.costOfSales) : "£--"}
        </Typography>
      ),
    },
    {
      title: "Completed Payment",
      dataIndex: "completedPayments",
      key: "completedPayments",
      width: 150,
      visible: columnVisibility["completedPayments"],
      render: (text, row) => (
        <>
          {row?.approvedPayments} / {row?.totalPayments}
        </>
      ),
    },
  ];

  return compact(columns);
};

export default getColumns;
