import idx from "idx";

const initialState = {
  filters: {},
  columns: [],
};

const TableManager = name => {
  const stateName = "TableManager" + name;

  // Types
  const types = {
    addFilter: `V2_FILTERS_ADD_${name}`,
    addOrder: `V2_ORDERS_ADD_${name}`,
    removeFilter: `V2_FILTERS_REMOVE_${name}`,
    updateColumns: `V2_UPDATE_TABLE_COLUMN_${name}`,
    reorderColumns: `V2_REORDER_TABLE_COLUMNS_${name}`,
    changeVisible: `V2_VISIBLE_TABLE_COLUMNS_${name}`,
    clearAll: `V2_FILTERS_CLEAR_${name}`,
  };

  // Action creators
  const actions = {
    addFilter: data => ({
      type: types.addFilter,
      payload: { data },
    }),
    addOrder: data => ({
      type: types.addOrder,
      payload: { data },
    }),
    removeFilter: key => ({
      type: types.removeFilter,
      payload: { key },
    }),
    clearAll: key => ({
      type: types.clearAll,
    }),
    updateColumns: data => ({
      type: types.updateColumns,
      payload: { data },
    }),
    reorderColumns: (from, to) => ({
      type: types.reorderColumns,
      payload: { from, to },
    }),
    changeVisible: (checked, index) => ({
      type: types.changeVisible,
      payload: { checked, index },
    }),
  };

  // Reducer
  const reducer = (state = initialState, action) => {
    let newData = [...state.columns];

    switch (action.type) {
      case types.addFilter:
        return {
          ...state,
          filters: {
            ...state.filters,
            page: 1,
            ...action.payload.data,
          },
        };
      case types.addOrder:
        return {
          ...state,
          filters: {
            ...state.filters,
            order: action.payload.data,
          },
        };
      case types.removeFilter:
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.payload.key]: undefined,
          },
        };
      case types.clearAll:
        return {
          ...state,
          filters: {},
        };
      case types.updateColumns:
        return {
          ...state,
          columns: action.payload.data,
        };
      case types.reorderColumns:
        const item = newData.splice(action.payload.from, 1)[0];
        newData.splice(action.payload.to, 0, item);

        return {
          ...state,
          columns: newData,
        };
      case types.changeVisible:
        newData[action.payload.index].visible = action.payload.checked;

        return {
          ...state,
          columns: newData,
        };
      default:
        return state;
    }
  };

  // Selectors
  const selectors = {
    selectFilters: state => idx(state, x => x[stateName].filters) || {},
    selectColumns: state => idx(state, x => x[stateName].columns) || [],
    selectColumnKeys: state =>
      (idx(state, x => x[stateName].columns) || [])
        .filter(c => c.visible === true)
        .map(c => c.key),
  };

  const TableManagerModule = () => ({
    id: stateName,
    reducerMap: {
      [stateName]: reducer,
    },
  });

  return {
    types,
    reducer,
    module: TableManagerModule,
    ...actions,
    ...selectors,
  };
};

export default TableManager;
