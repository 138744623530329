// @ts-nocheck
import styled from "styled-components";
import { AutoComplete as AntdAutoComplete } from "antd";
import { ThemeColors } from "../../../utils/theme";

export interface AutoCompleteProps {
  size: "small" | "middle" | "large";
  borderColor?: ThemeColors;
  background?: ThemeColors;
  borderRadius?: any;
}

const StyledAutoComplete = styled(AntdAutoComplete)<AutoCompleteProps>`
  && {
    width: 100%;
    .ant-select-selection {
      height: ${props =>
        props.theme.components.inputs.height[props.size]}px !important;
      font-size: ${props =>
        props.theme.components.inputs.fontSize[props.size]}px;
      border: 1px solid
        ${({ theme, borderColor = "grey5" }) => theme.colors[borderColor]} !important;
      box-sizing: border-box;
      background-color: ${props =>
        props.background && props.theme.colors[props.background]} !important;
      border-radius: ${props => props.borderRadius}px !important;
    }
    .ant-input.ant-select-search__field {
      border-width: 0px !important;
      height: ${props =>
        props.theme.components.inputs.height[props.size]}px !important;
    }
    .ant-input.ant-select-search__field:focus,
    .ant-input.ant-select-search__field:hover {
      border-color: none !important;
      border-right-width: 0px !important;
    }
  }
`;

export default StyledAutoComplete;
