// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import Button from "src/components/Core/Button";
import { InfluencerSelectionContainer } from "./container";
import Loading from "src/shared/Loading";
import { Row, Col, Empty, Alert } from "antd";
import styled from "styled-components";
import AccountCard from "./AccountCard";
import ConfirmedAccounts from "./ConfirmedAccounts";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { Droppable, Draggable } from "react-beautiful-dnd";

const InfluencerSortingAleart = styled(Alert)`
  && {
    .ant-alert-message {
      font-size: 20px !important;
    }
  }
`;

interface Props {
  loading: boolean;
  suggestedAccounts: Array<any>;
  acceptedAccounts: Array<any>;
  acceptedInviteAccounts: Array<any>;
  removeAccount: (index: number) => void;
  updateSuggestion: (index: number, status: string) => void;
  submitAccount: () => void;
  globalErrors?: GlobalErrorProps[];
  isSubmit: boolean;
}

const InfluencerSelection: React.FC<Props> = ({
  loading,
  suggestedAccounts,
  acceptedAccounts,
  acceptedInviteAccounts,
  removeAccount,
  updateSuggestion,
  submitAccount,
  globalErrors,
  isSubmit,
}) => {
  return (
    <Box>
      <Loading spinning={loading}>
        <InfluencerSortingAleart
          message="How to choose your influencers"
          description={
            <>
              <Typography>
                We have handpicked the influencers below. You can see their
                profile analytics and past content by viewing their details.
              </Typography>

              <Typography>
                <b>Drag influencers</b> you like from your <b>Selection Pool</b>{" "}
                to your <b>Selected Influencers</b> list and then{" "}
                <b>order them from your most wanted to least.</b> Once you’re
                happy hit the submit button.
              </Typography>

              <Typography>
                Once an influencer has been contacted by your campaign manager
                and confirmed they will appear in your Confirmed Influencers
                list.
              </Typography>

              <Button
                type={isSubmit ? "confirm" : "action"}
                buttonSize="small"
                onClick={() => submitAccount()}
                data-cy="submit-button"
                disabled={
                  (acceptedInviteAccounts || []).length > 0 ? false : true
                }
              >
                Submit
              </Button>
            </>
          }
          type="info"
        />
        <Box mt={30}>
          <GlobalErrors errors={globalErrors} />
          <Row gutter={16}>
            <Col span={8}>
              <Typography
                color="black2"
                size={20}
                weight="semi-bold"
                lineHeight={0}
              >
                Selection pool
              </Typography>
              <Typography color="grey7" size={12} lineHeight="16px">
                Drag influencers you like from the selection below to your
                selected influencers list.
              </Typography>

              <Droppable droppableId="selection">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef}>
                    {(suggestedAccounts || []).length > 0 ? (
                      <Box overflowY="auto" height={400} overflowX="hidden">
                        {suggestedAccounts.map((account, index) => (
                          <Draggable
                            index={index}
                            key={index}
                            draggableId={String(account?.id)}
                            isDragDisabled={
                              account?.status === "hidden" ? true : false
                            }
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <AccountCard
                                  isSelected={false}
                                  account={account}
                                  index={index}
                                  removeAccount={() => removeAccount(index)}
                                  updateSuggestion={status =>
                                    updateSuggestion(index, status)
                                  }
                                  // moveAccount={(fromIndex, toIndex) =>
                                  //   moveAccount(fromIndex, toIndex)
                                  // }
                                  isAdmin={false}
                                  //addAccount={() => addAccount(index)}
                                  moduleKey="selection"
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Box>
                    ) : (
                      <Box mt={60}>
                        <Empty description="No suggested influencer avaialble" />
                      </Box>
                    )}
                  </div>
                )}
              </Droppable>
            </Col>
            <Col span={8}>
              <Typography
                color="black2"
                size={20}
                weight="semi-bold"
                lineHeight={0}
              >
                Your selected influencers
              </Typography>
              <Typography color="grey7" size={12} lineHeight="16px">
                Order influencers from most wanted at the top to least wanted at
                the bottom
              </Typography>

              <Droppable droppableId="selected">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef}>
                    {(acceptedInviteAccounts || []).length > 0 ? (
                      <Box overflowY="auto" height={400} overflowX="hidden">
                        {acceptedInviteAccounts.map((account, index) => (
                          <Draggable
                            index={index}
                            key={index}
                            draggableId={String(account?.id)}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <AccountCard
                                  isSelected={true}
                                  key={index}
                                  account={account}
                                  index={index}
                                  removeAccount={() => removeAccount(index)}
                                  // isLast={
                                  //   acceptedInviteAccounts?.length === index + 1
                                  // }
                                  // isOnlyOne={acceptedInviteAccounts?.length === 1}
                                  // moveAccount={(fromIndex, toIndex) =>
                                  //   moveAccount(fromIndex, toIndex)
                                  // }
                                  isAdmin={false}
                                  isAccepted={true}
                                  moduleKey="selected"
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Box>
                    ) : (
                      <Box mt={60}>
                        <Empty description="No selected influencers avaialble" />
                      </Box>
                    )}
                  </div>
                )}
              </Droppable>
            </Col>

            <Col span={8}>
              <Typography
                color="black2"
                size={20}
                weight="semi-bold"
                lineHeight={0}
              >
                Confirmed influencers
              </Typography>
              <Typography color="grey7" size={12} lineHeight="16px">
                These influencers have been contacted and are confirmed to be on
                your campaign.
              </Typography>

              <ConfirmedAccounts acceptedAccounts={acceptedAccounts} />
            </Col>
          </Row>
        </Box>
      </Loading>
    </Box>
  );
};

export { InfluencerSelection, InfluencerSelectionContainer };
