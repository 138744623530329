import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "formik-antd";
import { connectModal } from "redux-modal";
import { Modal, Button, message, Typography, Row, Col } from "antd";
import { withFormik } from "formik";
import {
  PayoutType,
  OriginalPayoutPennies,
  PayoutCurrency,
  Rationale,
} from "src/shared/InvitationFields";
import CampaignManage from "src/ducks/CampaignManage";
import { number, object, string } from "yup";

import isString from "lodash/isString";
import isObject from "lodash/isObject";
import idx from "idx";
import formatCurrency from "src/utils/formatCurrency";
import FormError from "src/shared/FormField/FormError";
import InvitationTable from "./InvitationsTable";
import currencyRate from "src/utils/currencyRate";
import { Typography as InfoText } from "src/components/Core/Typography";

const InviteObject = object().shape({
  payout_type: string()
    .required("Please select a payout type")
    .default("fixed"),

  payout_type_currency: string()
    .required("Please select a payout currency")
    .default("GBP"),

  payout_type_original_pennies: number()
    .nullable()
    .required("Please enter a payout"),

  rationale: string(),
});

const SuggestObject = object().shape({
  rationale: string()
});

const inviteAccount = "Invite Account"

export class InviteModal extends Component {
  state = {
    gbp: 0,
  };

  onChangeCurrency = async currency => {
    if (currency !== "GBP") {
      const gbp = await currencyRate(currency);

      if (gbp) {
        this.setState({
          gbp: gbp,
        });
      }
    }
  };

  render() {
    const {
      show,
      title,
      account,
      handleHide,
      values,
      handleSubmit,
      isSubmitting,
      status,
      setFieldValue,
      errors
    } = this.props;

    const isValid = !Object.keys(errors).length

    return (
      <Modal
        visible={show}
        onCancel={handleHide}
        title={title}
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
        {...this.props}
      >
        <Form data-cy="invitation-modal-form">
          {status &&
            isObject(status.meta.errors) &&
            Object.values(status.meta.errors).map((error, i) => (
              <FormError
                key={i}
                style={{ margin: "0 0 10px 0" }}
                message={error}
              />
            ))}

          {title === inviteAccount &&
            <>
              {status && isString(status.meta.errors) && (
                <FormError
                  style={{ margin: "0 0 10px 0" }}
                  message={status.meta.errors}
                />
              )}

              <PayoutType />

              <Row gutter={8}>
                <Col span={8}>
                  <PayoutCurrency
                    onChangeCurrency={currency => {
                      this.onChangeCurrency(currency);
                      setFieldValue("payout_type_currency", currency);
                    }}
                  />
                </Col>
                <Col span={16}>
                  <OriginalPayoutPennies
                    title={
                      values.payout_type === "fixed" ? "Fixed Payout" : "CPM Payout"
                    }
                  />

                  {values.payout_type_currency !== "GBP" &&
                    idx(values, x => x.payout_type_original_pennies) && (
                      <InfoText size={14} color="grey9" mt="-25px">
                        which converts to{" "}
                        {formatCurrency(
                          idx(values, x => x.payout_type_original_pennies) *
                            this.state.gbp
                        )}
                      </InfoText>
                    )}
                </Col>
              </Row>

              {values.payout_type === "cpm" && (
                <Typography>
                  Recommended Cpm: {formatCurrency(account.cpm)}
                  <br />
                  Estimate:{" "}
                  {formatCurrency(
                    idx(values, x => x.payout_type_original_pennies) *
                      (account.views / 1000.0)
                  )}
                </Typography>
              )}

              {values.payout_type === "fixed" && (
                <>
                  <Typography>
                    Fixed price: {formatCurrency(account.payoutPennies)}
                  </Typography>
                </>
              )}
            </>
          }
          <Rationale />
        </Form>
        <InvitationTable account={account} style={{ marginTop: "20px" }} />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {
  updateCampaign: CampaignManage.campaign.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  connectModal({
    name: "InviteModal",
    destroyOnHide: true,
  })(
    withFormik({
      mapPropsToValues: (props) => props.title === inviteAccount ? InviteObject.cast() : SuggestObject.cast(),
      enableReinitialize: true,

      validationSchema: (props) => props.title === inviteAccount ? InviteObject : SuggestObject,

      handleSubmit: (
        values,
        { props, setSubmitting, setErrors, setStatus }
      ) => {
        const { onCreate, campaign, account, handleHide, refetch } = props;

        return onCreate({
          campaign: campaign.id,
          account: account.id,
          ...values,
        })
          .then(() => {
            refetch()
            setSubmitting(false);
            handleHide();
            message.success(`Suggested/Invited ${account.username}`);
          })
          .catch(err => {
            message.error(`Failed to suggest/invite ${account.username}`);
            setStatus(err.response.data);
            setSubmitting(false);
          });
      },

      displayName: "InviteModalForm",
    })(InviteModal)
  )
);
