import React, { Component } from "react"
import { withFormik } from "formik"
import { message } from "antd"
import { Form } from "formik-antd"
import { Payout, DontPay } from "src/shared/InvitationFields"
import { number, object } from "yup"
import FormError from "src/shared/FormField/FormError"

const Validate = object().shape({
  payout_pennies: number()
    .nullable()
    .required("Please enter a payout")
})

export class PayoutForm extends Component {
  render() {
    const { status } = this.props

    return (
      <Form>
        {status &&
          Object.values(status.meta.errors).map((error, i) => (
            <FormError key={i} style={{ margin: "0 0 10px 0" }} message={error} />
          ))}

        <Payout />

        <DontPay />
      </Form>
    )
  }
}

export default withFormik({
  validateOnBlur: false,
  enableReinitialize: true,

  mapPropsToValues: ({ invitation }) =>
    invitation
      ? {
          payout_pennies:
            invitation.payout_pennies && invitation.payout_pennies > 0
              ? invitation.payout_pennies
              : invitation.payout_type_pennies,
          dont_pay: invitation.dont_pay
        }
      : Validate.cast(),
  validationSchema: () => Validate,

  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus }) => {
    const { updateInvitation, onUpdate, invitation } = props

    updateInvitation(invitation.id, values)
      .then(() => {
        setSubmitting(false)
        message.success("Invitation updated!")
        onUpdate && onUpdate(values)
      })
      .catch((err) => {
        setStatus(err.response.data)
      })
  }
})(PayoutForm)
