// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";
import { Checkbox, Tooltip, Row, Col } from "antd";
import styled from 'styled-components'

interface Props {
  name?: string;
  data?: Array<any>;
  style?: any;
  value?: any;
  onChange?: Function;
  tooltip?: boolean;
}

const FormikCheckbox: React.FC<Props> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur, setFieldTouched, setFieldValue },
      }) => (
        <>
          <Checkbox.Group
            {...field}
            value={props?.value ? props?.value : field?.value}
            onChange={value => {
              if (props.onChange) {
                props.onChange(value);
              } else {
                setFieldTouched(field.name, true);
                setFieldValue(field.name, value);
              }
            }}
            data-cy={`input-checkbox-${field.name.replace(" ", "-")}`}
          >
            <Row>
              {(props.data || []).map((value, i) => (
                <Checkbox
                  data-cy={`input-${field.name.replace(" ", "-")}-checkbox-${i}`}
                  key={i}
                  value={value.value}
                  style={props?.style}
                >
                  <Col span={8}>
                    {props.tooltip ?
                      <Tooltip title={value.label}>
                        {value.label}
                      </Tooltip>
                      :
                      value.label
                    }
                  </Col>
                </Checkbox>
              ))}
            </Row>
          </Checkbox.Group>

          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={field.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikCheckbox;
