// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { CreateSuggestionFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  Rationale,
} from "./FormFields";
import formatCurrency from "src/utils/formatCurrency";

interface CreateSuggestionFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  CreateSuggestionSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  closeCreateSuggestionModal: () => void;
  account: any;
}

export const CreateSuggestionFormWrapper = styled.div`
  width: 100%;
`;

const CreateSuggestionForm: React.FC<CreateSuggestionFormProps> = ({
  initialValues,
  isSubmitting,
  CreateSuggestionSchema,
  onSubmit,
  globalErrors,
  closeCreateSuggestionModal,
  account,
}) => {
  return (
    <CreateSuggestionFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={CreateSuggestionSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>

                {formProps?.values?.payoutType === "cpm" && (
                  <Typography size={14} color="grey9">
                    Recommended Cpm: {formatCurrency(account.cpm)}
                    <br />
                    Estimate:{" "}
                    {formatCurrency(
                      formProps?.values?.payoutTypeOriginalPennies *
                        (account.views / 1000.0)
                    )}
                  </Typography>
                )}

                <Rationale />

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={20}
                >
                  <LinkButton
                    onClick={() => closeCreateSuggestionModal()}
                    mr={20}
                  >
                    Cancel
                  </LinkButton>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    buttonSize="middle"
                    data-cy="submit-button"
                  >
                    Submit
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </CreateSuggestionFormWrapper>
  );
};

export { CreateSuggestionForm, CreateSuggestionFormContainer };
