// @ts-nocheck
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Drawer, Alert } from "antd";
import { DraftYoutube } from "src/shared/InvitationModal/DraftsFeedback/components";
import RedraftContent from "../RedraftContent";
import ApproveContentButtons from "../ApproveContentButtons";
import SendFeedbackButtons from "../SendFeedbackButtons";
import BrandFeedbackFrom from "../../BrandFeedbackForm";
import numeral from "numeral";

const FeedbackDrawer = styled(Drawer)`
  &&& {
    .ant-drawer-content-wrapper {
      width: 356px !important;
    }
  }
`;

interface Props {
  onCancel: Function;
  onApprove: Function;
  firstDraft: any;
  isRequestEdit: boolean;
  loadMoreInvitations: Function;
  stage: string;
  onRequestEdit: (value: boolean) => void;
}

const YoutubeDraftCard: React.FC<Props> = ({
  onCancel,
  onApprove,
  firstDraft,
  isRequestEdit,
  loadMoreInvitations,
  stage,
  onRequestEdit,
}) => {
  const [isPlay, setIsPlay] = useState(false);
  const [timestamp, setTimeStamp] = useState("");
  const [visible, setVisible] = useState(false);
  const [localFeedbacks, setLocalFeedbacks] = useState([]);

  const formRef = useRef(null);
  const videoRef = useRef(null);

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box alignItems="center">
        <Alert
          message="Click the plus icon to leave feedback"
          type="info"
          showIcon
          style={{ width: "100%" }}
        />

        <Box mt={20} height="350px">
          <DraftYoutube
            getRef={ref => {
              videoRef.current = ref;
            }}
            videoUrl={firstDraft?.all_media?.[0]}
            isPlay={isPlay}
            onPlay={() => setIsPlay(true)}
            onPause={() => setIsPlay(false)}
          />
        </Box>

        {stage !== "approved" && (
          <ApproveContentButtons
            onRequestEdit={() => {
              if (!!visible) {
                let brandFeedback = (
                  (formRef.current &&
                    (formRef?.current as any)?.state?.values?.brandFeedback) ||
                  []
                ).filter(function(el: any) {
                  return el.feedback !== "";
                });

                setLocalFeedbacks(brandFeedback);
              }
              const time =
                videoRef.current &&
                (videoRef?.current as any)?.getCurrentTime();

              const numeralTime = numeral(time).format("00:00:00");

              const newTime = ("0" + numeralTime).slice(-8);

              setVisible(!visible);
              setIsPlay(!visible ? false : true);

              setTimeStamp(newTime ? newTime : "00:00:00");

              onRequestEdit(!visible);
            }}
            onApprove={onApprove}
          />
        )}

        <FeedbackDrawer
          placement="right"
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          getContainer={false}
          style={{ position: "absolute" }}
        >
          {firstDraft?.status !== "approved" && (
            <Box
              height={30}
              width={30}
              background="#fff"
              left="-28px"
              top="205px"
              position="absolute"
              textAlign="center"
              borderRadius="4px"
              onClick={() => {
                if (!!visible) {
                  let brandFeedback = (
                    (formRef.current &&
                      (formRef?.current as any)?.state?.values
                        ?.brandFeedback) ||
                    []
                  ).filter(function(el: any) {
                    return el.feedback !== "";
                  });

                  setLocalFeedbacks(brandFeedback);
                }
                const time =
                  videoRef.current &&
                  (videoRef?.current as any)?.getCurrentTime();

                const numeralTime = numeral(time).format("00:00:00");

                const newTime = ("0" + numeralTime).slice(-8);

                setVisible(!visible);
                setIsPlay(!visible ? false : true);

                setTimeStamp(newTime ? newTime : "00:00:00");

                onRequestEdit(!visible);
              }}
            >
              <LegacyIcon
                type={visible ? "close-circle" : "plus-circle"}
                style={{
                  color: "#1890FF",
                  cursor: "pointer",
                  lineHeight: "33px",
                }}
              />
            </Box>
          )}

          {firstDraft?.status !== "approved" && (
            <>
              {!isRequestEdit &&
                (firstDraft?.brand_feedback || []).length > 0 && (
                  <RedraftContent
                    feedbacks={firstDraft?.brand_feedback || []}
                    draftType={firstDraft?.draft_type}
                  />
                )}

              {isRequestEdit && (
                <BrandFeedbackFrom
                  draft={firstDraft}
                  loadMoreInvitations={loadMoreInvitations}
                  getRef={ref => {
                    formRef.current = ref;
                  }}
                  closeFeedbackForm={onCancel}
                  timestamp={timestamp}
                  localFeedbacks={localFeedbacks}
                />
              )}
            </>
          )}

          {isRequestEdit && (
            <SendFeedbackButtons
              type={firstDraft.draft_type}
              onCancel={onCancel}
              onSubmit={() =>
                document.querySelector('#brandFeedbackForm form button')?.click()
                // formRef.current && (formRef?.current as any)?.submitForm()
              }
              status={firstDraft.status}
            />
          )}
        </FeedbackDrawer>
      </Box>
    </div>
  );
};

export default YoutubeDraftCard;
