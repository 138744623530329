// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import { TextInput } from "../TextInput";
import { TextInputProps } from "../TextInput/StyledInput";
import get from "lodash/get";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";
import NumberFormat from "react-number-format";

interface Props {
  name?: string;
  size?: any;
  style?: any;
  prefix?: string;
}

type InputProps = Omit<TextInputProps, 'size' | 'type'>;

const FormikCurrencyInput: React.FC<Props & InputProps> = ({
  name,
  ...props
}) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: {
          touched,
          values,
          errors,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        },
      }) => (
        <>
          <NumberFormat
            {...props}
            error={get(touched, field.name) && get(errors, field.name)}
            onBlur={e => {
              handleBlur(e);
            }}
            thousandSeparator={false}
            allowLeadingZeros={false}
            allowNegative={false}
            decimalScale={2}
            customInput={TextInput}
            onValueChange={value => {
              setFieldTouched(field.name, true);
              if (value.floatValue) {
                setFieldValue(field.name, value.floatValue * 100);
              } else {
                setFieldValue(field.name, value.floatValue);
              }
            }}
            value={typeof field.value === "number" ? field.value / 100.0 : ""}
            data-cy={`input-${field.name.replace(" ", "-")}`}
          />
          {props.shouldShowError && (
            <FieldErrorMessage
              errors={errors}
              touched={touched}
              mt={20}
              name={field.name}
            />
          )}
        </>
      )}
    </Field>
  );
};

export default FormikCurrencyInput;
