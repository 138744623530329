// @ts-nocheck
import React from "react";
import { Spin } from "antd";
import { NewGiftingCampaign } from "./NewGiftingCampaign";
import SubmissionComplete from "./SubmissionComplete";
import {
  useGetGiftingCampaignQuery,
  GiftingCampaignStatus,
} from "src/generated/ApolloComponents";
import { RouteComponentProps } from "react-router";
import DashboardLayout from "src/scenes/layouts/DashboardLayout";
import { Box } from "src/components/Core/Box";

export const ManageGiftingCampaign = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const { data, loading } = useGetGiftingCampaignQuery({
    variables: { campaignId: parseInt(match?.params?.id) },
  });

  const campaign = data?.getGiftingCampaign;

  switch (campaign?.status) {
    case GiftingCampaignStatus.Draft:
      return <NewGiftingCampaign loading={loading} campaign={campaign} />;
    case GiftingCampaignStatus.Submitted:
      return <SubmissionComplete campaign={campaign} />;
    default:
      return (
        <DashboardLayout title={campaign?.title || "Gifting Campaign"}>
          <Box textAlign="center" mt={35}>
            <Spin spinning={true} />
          </Box>
        </DashboardLayout>
      );
  }
};
