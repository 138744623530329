// @ts-nocheck
import React, { useRef } from "react";
import { useScreenClass } from "react-grid-system";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { Row, Col } from "antd";
import numeral from "numeral";
import comment from "src/assets/images/reports/comment.png";
import heart from "src/assets/images/reports/hearts.png";
import percentage from "src/assets/images/reports/percentage.png";
import eyes from "src/assets/images/reports/eyes.png";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import { useSpring, animated, config as springConfig } from "react-spring";
import FadeInSection from "../../FadeInSection";
import { showEngagement, showCTR } from "../../../utils";

var imgStyle = {
  width: "100%",
  height: "220px",
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
};

interface Props {
  campaignData: any;
}

const Stats: React.FC<Props> = ({ campaignData }) => {
  const screenClass = useScreenClass();

  const myRef = useRef<HTMLInputElement | null>(null);

  let campaignComments = campaignData?.campaignComments;
  let campaignHearts = campaignData?.campaignHearts;
  let campaignEngagementRate = campaignData?.campaignEngagementRate;
  let campaignClickThroughtRate = campaignData?.campaignClickThroughtRate;
  let campaignViews = campaignData?.views

  let isShowEngagement = showEngagement(campaignData);
  let isShowCTR = showCTR(campaignData);
  let isCol12 =
    isShowEngagement &&
    isShowCTR &&
    campaignEngagementRate &&
    campaignClickThroughtRate;

  let options = {};
  let props = {};

  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  const myRefEngagement = useRef<HTMLInputElement | null>(null);

  const {
    inViewport: inViewportEngagement,
    enterCount: enterCountEngagement,
  } = useInViewport(
    myRefEngagement,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  const myRefCtr = useRef<HTMLInputElement | null>(null);

  const {
    inViewport: inViewportCtr,
    enterCount: enterCountCtr,
  } = useInViewport(
    myRefCtr,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  const comments = useSpring({
    number: enterCount >= 1 ? campaignComments : 0,
    config: springConfig.molasses,
  });
  const hearts = useSpring({
    number: enterCount >= 1 ? campaignHearts : 0,
    config: springConfig.molasses,
  });
  const engRate = useSpring({
    number: enterCount >= 1 ? campaignEngagementRate : 0,
    config: springConfig.molasses,
  });
  const views = useSpring({
    number: enterCount >= 1 ? campaignViews : 0,
    config: springConfig.molasses,
  });

  const ctrRate = useSpring({
    number: enterCount >= 1 ? campaignClickThroughtRate : 0,
    config: springConfig.molasses,
  });

  return (
    <Box mt={100}>
      <FadeInSection>
        <Row gutter={36} ref={myRef as any}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Box textAlign="center">
              <VelocityComponent
                key="bounce"
                animation={
                  enterCount <= 1 && inViewport
                    ? "transition.bounceIn"
                    : "transition.fadeIn"
                }
                delay={100}
              >
                <Box
                  style={{
                    ...imgStyle,
                    backgroundImage: `url(${comment})`,
                  }}
                ></Box>
              </VelocityComponent>
              <Typography
                fontSize={
                  screenClass === "sm" || screenClass === "xs"
                    ? 50
                    : screenClass === "md"
                    ? 80
                    : 100
                }
                style={{ fontWeight: 600 }}
                color="white"
                mb={0}
              >
                <animated.span>
                  {comments?.number.interpolate(
                    x => numeral(x).format("0,0") as number
                  )}
                </animated.span>
              </Typography>
              <Typography
                fontSize={
                  screenClass === "sm" || screenClass === "xs"
                    ? 16
                    : screenClass === "md"
                    ? 30
                    : 40
                }
                fontWeight={500}
                color="white"
              >
                Comments
              </Typography>
            </Box>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Box textAlign="center">
              <VelocityComponent
                key="bounce1"
                animation={
                  enterCount <= 1 && inViewport
                    ? "transition.bounceIn"
                    : "transition.fadeIn"
                }
                delay={100}
              >
                <Box
                  style={{
                    ...imgStyle,
                    backgroundImage: `url(${heart})`,
                  }}
                ></Box>
              </VelocityComponent>
              <Typography
                fontSize={
                  screenClass === "sm" || screenClass === "xs"
                    ? 50
                    : screenClass === "md"
                    ? 80
                    : 100
                }
                style={{ fontWeight: 600 }}
                color="white"
                mb={0}
              >
                <animated.span>
                  {hearts?.number.interpolate(
                    x => numeral(x).format("0,0") as number
                  )}
                </animated.span>
              </Typography>
              <Typography
                fontSize={
                  screenClass === "sm" || screenClass === "xs"
                    ? 16
                    : screenClass === "md"
                    ? 30
                    : 40
                }
                fontWeight={500}
                color="white"
              >
                Hearts
              </Typography>
            </Box>
          </Col>
        </Row>
      </FadeInSection>

      <FadeInSection>
        <Row gutter={36}>
          <Col xs={24} sm={24} md={isCol12 ? 12 : 12} lg={isCol12 ? 12 : 12}>
            <Box textAlign="center">
              <VelocityComponent
                key="bounce1"
                animation={
                  enterCount <= 1 && inViewport
                    ? "transition.bounceIn"
                    : "transition.fadeIn"
                }
                delay={100}
              >
                <Box
                  style={{
                    ...imgStyle,
                    backgroundImage: `url(${eyes})`,
                  }}
                ></Box>
              </VelocityComponent>
              <Typography
                fontSize={
                  screenClass === "sm" || screenClass === "xs"
                    ? 50
                    : screenClass === "md"
                    ? 80
                    : 100
                }
                style={{ fontWeight: 600 }}
                color="white"
                mb={0}
              >
                <animated.span>
                  {views?.number.interpolate(
                    x => numeral(x).format("0,0") as number
                  )}
                </animated.span>
              </Typography>
              <Typography
                fontSize={
                  screenClass === "sm" || screenClass === "xs"
                    ? 16
                    : screenClass === "md"
                    ? 30
                    : 40
                }
                fontWeight={500}
                color="white"
              >
                Total Views
              </Typography>
            </Box>
          </Col>
          {campaignEngagementRate && isShowEngagement && (
            <Col xs={24} sm={24} md={isCol12 ? 12 : 12} lg={isCol12 ? 12 : 12} ref={myRefEngagement as any}>
              <Box textAlign="center">
                <VelocityComponent
                  key="bounce2"
                  animation={
                    enterCountEngagement <= 1 && inViewportEngagement
                      ? "transition.bounceIn"
                      : "transition.fadeIn"
                  }
                  delay={300}
                >
                  <Box
                    style={{
                      ...imgStyle,
                      backgroundImage: `url(${percentage})`,
                    }}
                  ></Box>
                </VelocityComponent>
                <Typography
                  fontSize={
                    screenClass === "sm" || screenClass === "xs"
                      ? 50
                      : screenClass === "md"
                      ? 80
                      : 100
                  }
                  style={{ fontWeight: 600 }}
                  color="white"
                  mb={0}
                >
                  <animated.span>
                    {engRate?.number.interpolate(x =>
                      (x as number)?.toFixed(0)
                    )}
                  </animated.span>
                  %
                </Typography>
                <Typography
                  fontSize={
                    screenClass === "sm" || screenClass === "xs"
                      ? 16
                      : screenClass === "md"
                      ? 30
                      : 40
                  }
                  fontWeight={500}
                  color="white"
                >
                  Engagement rate
                </Typography>
              </Box>
            </Col>
          )}
        </Row>
      </FadeInSection>

      {campaignClickThroughtRate && isShowCTR && (
        <FadeInSection>
          <Row gutter={36} ref={myRefCtr as any}>
            <Col xs={24} sm={24} md={isCol12 ? 12 : 24} lg={isCol12 ? 12 : 24}>
              <Box textAlign="center">
                <VelocityComponent
                  key="bounce2"
                  animation={
                    enterCountCtr <= 1 && inViewportCtr
                      ? "transition.bounceIn"
                      : "transition.fadeIn"
                  }
                  delay={300}
                >
                  <Box
                    style={{
                      ...imgStyle,
                      backgroundImage: `url(${percentage})`,
                    }}
                  ></Box>
                </VelocityComponent>
                <Typography
                  fontSize={
                    screenClass === "sm" || screenClass === "xs"
                      ? 50
                      : screenClass === "md"
                      ? 80
                      : 100
                  }
                  style={{ fontWeight: 600 }}
                  color="white"
                  mb={0}
                >
                  <animated.span>
                    {ctrRate?.number.interpolate(x =>
                      (x as number)?.toFixed(0)
                    )}
                  </animated.span>
                  %
                </Typography>
                <Typography
                  fontSize={
                    screenClass === "sm" || screenClass === "xs"
                      ? 16
                      : screenClass === "md"
                      ? 30
                      : 40
                  }
                  fontWeight={500}
                  color="white"
                >
                  Click through rate
                </Typography>
              </Box>
            </Col>
          </Row>
        </FadeInSection>
      )}
    </Box>
  );
};

export default Stats;
