// @ts-nocheck
import React, { useState } from "react";
import { NewDraftForm } from ".";
import { string, object, array } from "yup";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import { FormikHelpers as FormikActions } from "formik";
import { useUploadNewDraftMutation } from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
// import * as cloudinary from "src/api/cloudinary/axiosInstance";
import { useGetGeneralSignedUrlMutation } from 'src/generated/ApolloComponents'
import axios from 'axios'

type Props = {
  cancelDraft: () => void;
  refetchDrafts: () => void;
  invitationId: string;
  fetchInvitations: Function;
};

interface NewDraftFormValues {
  drafts?: Array<any>;
  allVideos?: Array<any>;
  allMedia?: Array<any>;
  draftType?: string;
  caption?: string;
  description?: string;
  invitationId: string;
  youtubeDraftUrl?: string;
}

export const NewDraftFormContainer: React.FC<Props> = ({
  cancelDraft,
  refetchDrafts,
  invitationId,
  fetchInvitations,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [getGeneralSignedUrlMutation] = useGetGeneralSignedUrlMutation()

  const NewDraftSchema = object().shape({
    draftType: string(),
    youtubeDraftUrl: string()
      .nullable()
      .test("youtubeDraftUrl", "Please enter youtube url", function(
        value
      ) {
        const { draftType, youtubeDraftUrl } = this.parent;
        if (draftType === "youtube" && !youtubeDraftUrl) return value != null;
        return true;
      }),
    description: string()
      .nullable()
      .test("description", "Please enter description of content", function(
        value
      ) {
        const { draftType, description } = this.parent;
        if (draftType === "text" && !description) return value != null;
        return true;
      }),
    caption: string().nullable(),
    // caption: string()
    //   .nullable()
    //   .test("caption", "Please enter caption", function(value) {
    //     const { draftType, caption } = this.parent;
    //     if (draftType === "media" && !caption) return value != null;
    //     return true;
    //   }),

    drafts: array().test(
      "drafts",
      "You must upload at least one video",
      function(value) {
        const { draftType, drafts } = this.parent;

        if (draftType === "media" && (!drafts || isEmpty(drafts)))
          return false;
        return true;
      }
    ),
  });

  let initialValues = {
    draftType: "text",
    description: "",
    caption: "",
    drafts: [],
    youtubeDraftUrl: "",
  };

  const [
    uploadNewDraftMutation,
    { loading, error },
  ] = useUploadNewDraftMutation();

  const onSubmit = async (
    values: NewDraftFormValues,
    actions: FormikActions<NewDraftFormValues>
  ) => {
    try {
      let formData: NewDraftFormValues = {
        draftType: values?.draftType,
        invitationId: invitationId,
      };

      if (values?.draftType === "youtube") {
        formData = {
          ...formData,
          allMedia: [values?.youtubeDraftUrl],
        };
      }

      if (values?.draftType === "text") {
        formData = {
          ...formData,
          description: values?.description,
        };
      }

      if (values?.draftType === "media") {
        const uploadedVideos = [] as any;
        const uploadedMedia = [] as any;

        setIsUploading(true);

        for (let i = 0; i < (values?.drafts || []).length; i++) {
          if (values?.drafts?.[i]) {
            const file = values?.drafts?.[i]
            const fileData = {
              fileName: file.name,
              fileType: file.type,
              folder: 'drafts'
            };

            const response = await getGeneralSignedUrlMutation({
              variables: fileData,
            });

            const options = {
              headers: {
                "Content-Type": file.type,
              },
            };
            const uploadRes = await axios.put(response.data?.getGeneralSignedURL.url as string, file, options)
            if(uploadRes) {
              uploadedMedia.push(response?.data?.getGeneralSignedURL.uploadUrl);
            }

            // const response = await cloudinary.v2uploadCreative(
            //   values.drafts[i],
            // );


            // if (!values.drafts[i]?.type.match("image.*")) {
            //   // uploadedVideos.push(response?.data?.public_id);
            // }

          }
        }

        formData = {
          ...formData,
          allVideos: compact(uploadedVideos),
          allMedia: compact(uploadedMedia),
          caption: values?.caption,
        };
      }

      const res = await uploadNewDraftMutation({
        variables: formData,
      });

      if (res?.data?.uploadNewDraft?.id) {
        setIsUploading(false);
        fetchInvitations();
        refetchDrafts();
        cancelDraft();
      }
    } catch (e) {
      setIsUploading(false);
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <NewDraftForm
      cancelDraft={() => cancelDraft()}
      initialValues={initialValues}
      NewDraftSchema={NewDraftSchema}
      isSubmitting={loading || isUploading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
    />
  );
};
