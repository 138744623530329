// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import { HubCheckbox } from "./styles";
import { Link } from "react-router-dom";

interface Props {
  formProps: FormikProps<any>;
  hubLinkData: any;
  getStatus: (value: string) => any;
}

const InitialContactYouTube: React.FC<Props> = ({
  formProps,
  hubLinkData,
  getStatus,
}) => {
  const instructions = [
    {
      message: "Add tags to influencer account",
      shouldShow: !hubLinkData?.initialContactYouTube?.isTagVerified,
    },
    {
      message: "Screenshot of demographics",
      shouldShow: !hubLinkData?.initialContactYouTube?.isScreenshotVerified,
    },
    {
      message: "Enter demographic data",
      shouldShow: !hubLinkData?.initialContactYouTube?.isBreakdownVerified,
    },
  ];

  const value = formProps?.values;
  let i = 1;
  return (
    <Box mt={10}>
      <Box display="inline-flex">
        <HubCheckbox
          name="initialContactYouTube"
          onChange={() => {
            formProps?.setFieldTouched("initialContactYouTube");
            formProps?.setFieldValue(
              "initialContactYouTube",
              !value.initialContactYouTube
            );

            if (!value.initialContactYouTube) {
              formProps?.setFieldValue("initialContact", false);
            }
          }}
          disabled={
            hubLinkData?.initialContact?.status === "complete" ||
            hubLinkData?.initialContactYouTube?.status === "complete"
          }
          value={value.initialContactYouTube}
          data-cy="checkbox-initialContactYouTube"
          checked={value.initialContactYouTube}
        />
        <Typography size={14} color="black2" ml={10} mb={10} fontWeight={600}>
          Initial contact (Youtube)
        </Typography>

        {value.initialContactYouTube &&
          getStatus(hubLinkData?.initialContactYouTube?.status)}
      </Box>
      <Typography size={12} color="black">
        This will display a form for influencers to verify their account with
        screenshots of their demographic data.
        {value.initialContactYouTube &&
          (!hubLinkData?.initialContactYouTube?.isTagVerified ||
            !hubLinkData?.initialContactYouTube?.isScreenshotVerified ||
            !hubLinkData?.initialContactYouTube?.isBreakdownVerified) &&
          ` To verify this influencer you
        need:`}
      </Typography>

      {value.initialContactYouTube &&
        instructions?.map((instruction, index) => {
          if (instruction?.shouldShow) {
            return (
              <Typography size={12} color="black" mb={0} key={index}>
                {i++}. {instruction?.message}{" "}
              </Typography>
            );
          } else {
            return null;
          }
        })}

      {value.initialContactYouTube &&
        (!hubLinkData?.initialContactYouTube?.isTagVerified ||
          !hubLinkData?.initialContactYouTube?.isScreenshotVerified ||
          !hubLinkData?.initialContactYouTube?.isBreakdownVerified) && (
          <Typography size={12} color="black" mb={0}>
            {" "}
            You can update all of these manually on the{" "}
            <Link to={`/accounts/${value?.accountId}/update`}>
              update account page.
            </Link>
          </Typography>
        )}
    </Box>
  );
};

export default InitialContactYouTube;
