import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";
import { Alert } from "antd";
import { withFormik, ErrorMessage } from "formik";
import { Form, Input, Checkbox, SubmitButton } from "formik-antd";
import Auth from "src/ducks/Auth";
import { Icon } from '@ant-design/compatible'

const FormIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.25);
`;

const LoginError = styled(props => (
  <Alert type="warning" message={props.children} {...props} />
))`
  margin-top: 15px;
`;

class LoginForm extends Component {
  render() {
    const { handleSubmit, failed } = this.props;

    return (
      <Form data-cy="login-form" onSubmit={handleSubmit}>
        {failed && (
          <LoginError message="Incorrect login details" type="error" />
        )}
        <Form.Item name="email">
          <Input
            name="email"
            size="large"
            prefix={<FormIcon type="user" />}
            placeholder="Email"
          />
          <ErrorMessage component={LoginError} name="email" />
        </Form.Item>
        <Form.Item name="password">
          <Input
            size="large"
            name="password"
            prefix={<FormIcon type="lock" />}
            type="password"
            placeholder="Password"
          />
          <ErrorMessage component={LoginError} name="password" />
        </Form.Item>
        <Form.Item name="remember-me">
          <Checkbox name="remember-me">Remember me</Checkbox>
          <Link to="/forgot">Forgot password</Link>
        </Form.Item>
        <SubmitButton size="large" type="primary" disabled={false} block>
          Log in
        </SubmitButton>
      </Form>
    );
  }
}

const EnhancedLoginForm = withFormik({
  mapPropsToValues: () => ({
    email: "",
    password: "",
  }),

  validate: values => {
    const errors = {};

    if (!values.email) {
      errors.email = "Fill in your email";
    }

    if (!values.password) {
      errors.password = "Fill in a password";
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .login(values)
      .then(res => {
        if (res?.value?.data?.type === "Admin") {
          props.history.push("/discover");
        } else if(res?.value?.data?.type === "Influencer") {
          props.history.push("/")
        }
        setSubmitting(false);
      })
      .catch(() => setSubmitting(false));
  },

  displayName: "LoginForm",
})(LoginForm);

const mapStateToProps = state => ({
  submitting: Auth.selectLoading(state),
  failed: Auth.selectLoginError(state),
});

const mapDispatchToProps = {
  login: Auth.loginUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnhancedLoginForm)
);
