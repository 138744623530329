// @ts-nocheck
import React from "react";
import { Checkbox } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";

interface Props {
  formProps?: FormikProps<any>;
}

const IncludeEstimatedCost: React.FC<Props> = ({ formProps }) => {
  return (
    <Box display="inline-flex">
      <Checkbox
        name="includeEstimatedCosts"
        onChange={() => {
          formProps?.setFieldTouched("includeEstimatedCosts");
          formProps?.setFieldValue(
            "includeEstimatedCosts",
            !formProps?.values.includeEstimatedCosts
          );
        }}
        value={formProps?.values.includeEstimatedCosts}
        data-cy="checkbox- includeEstimatedCosts"
        checked={formProps?.values.includeEstimatedCosts}
      />
      <Typography size={12} color="grey9" ml={10} mt={0.9}>
        Include estimated costs
      </Typography>
    </Box>
  );
};

export default IncludeEstimatedCost;
