// @ts-nocheck
import React, { ReactNode } from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';

import moment from "moment";

const renderLastDraftActivity = (invitation: any, draft: any) => {
  let icon: ReactNode;
  let content: ReactNode;
  if (invitation?.stage === "admin-review" && draft?.status === "submitted") {
    icon = <CloudDownloadOutlined style={{ marginLeft: "2px" }} />;
    content = (
      <Box>
        <Typography size={14} weight="semi-bold" color="black2">
          <b>Influencer submitted content.</b>
        </Typography>
        <Typography size={14} color="black2">
          You need to review the content and either
        </Typography>
        <Typography size={14} color="black2">
          1. Leave feedback via the <b>admin tab</b> then select “send feedback
          to influencer” and hit send.
        </Typography>
        <Typography size={14} color="black2">
          2. Pass the content on to the brand for review by selecting “send
          content to brand” and hit send.
        </Typography>
        <Typography size={14} color="black2">
          3. Approve the content directly.
        </Typography>
      </Box>
    );
  }
  if (invitation?.stage === "under-review" && draft?.status === "submitted") {
    icon = <ArrowLeftOutlined />;
    content = (
      <Box>
        <Typography size={14} weight="semi-bold" color="black2">
          <b>Draft sent to brand.</b>
        </Typography>
        <Typography size={14} color="black2">
          The brand needs to approve the content or request a redraft.
        </Typography>
      </Box>
    );
  }

  if (invitation?.stage === "admin-review" && draft?.status === "redraft") {
    icon = <ArrowRightOutlined />;
    content = (
      <Box>
        <Typography size={14} weight="semi-bold" color="black2">
          <b>
            Brand submitted feedback - You need to review it under the Brand tab
            and submit a new draft
          </b>
        </Typography>
        <Typography size={14} color="black2">
          You need to review the feedback and pass it on to the influencer. You
          can see what the brand has submitted in the <b>Brand tab</b>, then add
          the feedback you want to submit to the influencer on the{" "}
          <b>Admin tab</b>. Once you have added your feedback be sure to select
          "Send feedback to influencer" from the dropdown list and hit send
        </Typography>
      </Box>
    );
  }

  if (invitation?.stage === "redrafting" && draft?.status === "redraft") {
    icon = <ArrowRightOutlined />;
    content = (
      <Box>
        <Typography size={14} weight="semi-bold" color="black2">
          <b>Feedback sent to influencer.</b>
        </Typography>
        <Typography size={14} color="black2">
          The influencer needs to create a new draft. You can send them this
          link to upload their new content to (They will be able to view your
          feedback on the link too):
          <a
            href={
              invitation.claim_cashout_id &&
              `https://app.fanbytes.co.uk/invitation/${
                invitation.claim_cashout_id
              }${draft?.draftType === "youtube" ? "/youtube" : ""}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            https://app.fanbytes.co.uk/invitation/
            {invitation.claim_cashout_id}
            {draft?.draftType === "youtube" && "/youtube"}
          </a>
        </Typography>
      </Box>
    );
  }

  if (invitation?.stage === "awaiting" && draft?.status === "approved") {
    content = (
      <Box>
        <Typography size={14} weight="semi-bold" color="black2">
          <b>
            Text content approved! Media needs to be added to the draft below
          </b>
        </Typography>
      </Box>
    );
  }

  if (invitation?.stage === "approved" && draft?.status === "approved") {
    icon = <CheckOutlined />;
    content = (
      <Typography size={14} weight="semi-bold" color="black2">
        <b>Content approved!</b>
      </Typography>
    );
  }

  if (invitation?.stage === "drafting") {
    content = (
      <Typography size={14} weight="semi-bold" color="black2">
        <b>
          Awaiting new draft to either be submitted by the influencer or created
          by Admin
        </b>
      </Typography>
    );
  }

  if (invitation?.stage === "posted") {
    icon = <CheckOutlined />;
    content = (
      <Typography size={14} weight="semi-bold" color="black2">
        <b>Influencer has gone live with their post</b>
      </Typography>
    );
  }

  if (content) {
    return {
      icon: icon,
      render: () => {
        return (
          <>
            {content}

            <Typography size={12} color="grey6">
              {draft?.updatedAt
                ? moment(draft?.updatedAt).format("Do MMM YYYY")
                : ""}
            </Typography>
          </>
        );
      },
    };
  } else {
    return;
  }
};

export default renderLastDraftActivity;
