// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikCheckbox } from "src/components/Forms/Checkbox";
import { types } from "src/constants/account";
import { CheckboxStyle, RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Platform: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if ((formProps?.values?.platforms || []).length > 0) {
    isVisible = true;
  }

  return (
    <Field
      label="Platform:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("platforms", []);
              clearFilterKey("platforms");
            }}
            style={{ marginTop: "-1px", marginLeft: "10px" }}
          />
        )
      }
      id="type"
    >
      <FormikCheckbox name="platforms" data={types} style={CheckboxStyle} />
    </Field>
  );
};

export default Platform;
