// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import ActionButtons from "../../ActionButtons";
import { bankDetailFields } from "./BankDetailFields";

interface Props {
  backClick: () => void;
  isSubmitting: boolean;
  onSave: Function;
  formType: string;
  countryCode: string;
  value: any;
}

const Step2: React.FC<Props> = ({
  backClick,
  isSubmitting,
  onSave,
  formType,
  countryCode,
  value,
}) => {
  return (
    <>
      {bankDetailFields[countryCode].fields.map(item => {
        const FieldComponent = item.component;
        return (
          <Box mb={9}>
            <FieldComponent
              name={item.name}
              value={value[item.name]}
              {...item.props}
            />
          </Box>
        );
      })}

      <ActionButtons
        isSubmitting={isSubmitting}
        backClick={() => backClick()}
        onSave={() => onSave()}
        formType={formType}
      />
    </>
  );
};

export default Step2;
