// @ts-nocheck
import React from 'react'
import { ManageApplicants } from '.'
import {
  useGetPublicCampaignApplicantsQuery,
  useAcceptPublicCampaignApplicantMutation,
  useRejectPublicCampaignApplicantMutation,
  useConfirmPublicCampaignApplicantMutation,
  useNotifyPublicCampaignApplicantMutation,
  useRollbackPublicCampaignApplicantMutation,
  useRequestDataFromExpressApplicantMutation
} from 'src/generated/ApolloComponents'
import {
  useMapGraphQLErrorsMessages
} from 'src/utils/hooks/useMapGraphQLErrors'

export const ManageApplicantsContainer = ({ match: { params } }) => {
  const { data, loading, error, refetch } = useGetPublicCampaignApplicantsQuery({
    variables: {
      campaignId: Number(params?.id)
    }
  })

  const [acceptPublicCampaignApplicant, accept] = useAcceptPublicCampaignApplicantMutation()
  const [rejectPublicCampaignApplicant, reject] = useRejectPublicCampaignApplicantMutation()
  const [notifyPublicCampaignApplicant, notify] = useNotifyPublicCampaignApplicantMutation()
  const [confirmPublicCampaignApplicant, confirm] = useConfirmPublicCampaignApplicantMutation()
  const [rollbackPublicCampaignApplicant, applicant] = useRollbackPublicCampaignApplicantMutation()
  const [requestDataFromExpressApplicant, requestData] = useRequestDataFromExpressApplicantMutation()

  const onDragEnd = async result => {
    if(!result?.destination?.droppableId) {
      return
    }
    const values = {
      variables: {
        campaignId: Number(params.id),
        accountId: Number(result.draggableId)
      }
    }
    let mutation = null as any
    let type = ''
    switch(result?.destination?.droppableId) {
      case 'applicants':
        mutation = rollbackPublicCampaignApplicant
        type = 'rollbackPublicCampaignApplicant'
        break;
      case 'accepted':
        mutation = acceptPublicCampaignApplicant
        type = 'acceptPublicCampaignApplicant'
        break;
      case 'rejected':
        mutation = rejectPublicCampaignApplicant
        type = 'rejectPublicCampaignApplicant'
        break;
    }
    try {
      const res = await mutation(values)
      if(res?.data?.[type].id || res?.data?.[type].status === 'considering') {
        refetch()
      }
    } catch {

    }
  };

  const handleNotify = (accId) => {
    handleTrigger(accId, notifyPublicCampaignApplicant, 'notifyPublicCampaignApplicant')
  }

  const handleConfirm = (accId) => {
    handleTrigger(accId, confirmPublicCampaignApplicant, 'confirmPublicCampaignApplicant')
  }

  const requestDataFun = async (acc: any) => {
    const { email, username, platform } = acc
    try {
      const res = await requestDataFromExpressApplicant({
        variables: {
          campaignId: Number(params.id),
          email: email,
          username: username,
          platform: platform
        }
      })
      if(res.data) {
        refetch()
      }
    } catch {}
  }

  const handleTrigger = async (accId, mutation, type) => {
    const values = {
      variables: {
        campaignId: Number(params.id),
        accountId: accId
      }
    }
    const res = await mutation(values)
    if(res?.data?.[type].id){
      refetch()
    }
  }

  const globalErrors = useMapGraphQLErrorsMessages(
    error || accept?.error || reject?.error || confirm?.error || notify?.error || applicant?.error || requestData?.error
  )

  const fetchLoadingState = () => {
    return loading || accept?.loading || reject?.loading || confirm?.loading || notify?.loading || applicant?.loading || requestData?.loading
  }

  return(
    <ManageApplicants
      onDragEnd={onDragEnd}
      globalErrors={globalErrors}
      loading={fetchLoadingState()}
      requestData={requestDataFun}
      handleNotify={handleNotify}
      handleConfirm={handleConfirm}
      data={data?.getPublicCampaignApplicants || {}}
    />
  )
}
