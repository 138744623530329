// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { ExternalInfluencerContainer } from "./container";
import Loading from "src/shared/Loading";
import { Avatar, Alert } from "antd";
import styled from "styled-components";

const AlertInfo = styled(Alert)`
  && {
    padding: 8px 8px 8px 40px !important;
    .ant-alert-icon {
      top: 16px !important;
      left: 12px !important;
      font-size: 14px !important;
    }
    .ant-alert-message {
      color: ${props => props.theme.colors.black4} !important;
    }
  }
`;

interface Props {
  loading: boolean;
  platformType: string;
  accountData: any;
}

const ExternalInfluencer: React.FC<Props> = ({
  loading,
  platformType,
  accountData,
}) => {
  return (
    <Box p={12}>
      <Loading spinning={loading}>
        <Box display="inline-flex">
          <Box mt="-4px">
            <Avatar src={accountData?.profileImage} size={40} />
          </Box>
          <Box pl={20} width="130px">
            <Typography
              size={16}
              weight="semi-bold"
              color="black"
              lineHeight="22px"
            >
              {accountData?.username}
            </Typography>
          </Box>
        </Box>

        <Box mt={30}>
          <AlertInfo
            message="External Data"
            description={`This user has not been imported to the platform. 
            The data you are viewing is from ${platformType}. `}
            type="info"
            showIcon
          />
        </Box>
      </Loading>
    </Box>
  );
};

export { ExternalInfluencer, ExternalInfluencerContainer };
