// @ts-nocheck
import React from "react";
import { ProfileDetailForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useUpdateWelcomeProfileDetailMutation,
  AccountFragementFragment,
} from "src/generated/ApolloComponents";
import _values from "lodash/values";
import sum from "lodash/sum";
import has from "lodash/has";
import { ProfileDetailSchema } from "./Validations";

interface Props {
  claimAccountId: any;
  goNext: () => void;
  goBack: () => void;
  accountData: AccountFragementFragment | null | undefined;
  setShowExample: (value: object) => void;
  setScreenshotType: (value: string) => void;
  showExample: object;
}

interface File {
  uploadUrl: string;
  uploadType: string;
}

interface ProfileDetailFormValues {
  platformType?: string;
  screenshot: File;
  ageBreakdown: Array<any>;
  genderBreakdown: Array<any>;
  locationBreakdown: Array<any>;
}

export const ProfileDetailFormContainer: React.FC<Props> = ({
  claimAccountId,
  goNext,
  goBack,
  accountData,
  setShowExample,
  setScreenshotType,
  showExample,
}) => {
  const [
    updateWelcomeProfileDetailMutation,
    { loading, error },
  ] = useUpdateWelcomeProfileDetailMutation();

  let locationBreakdownData = accountData?.locationBreakdown?.manual || {};

  delete locationBreakdownData.other;

  let locationBreakdownArray = Object.keys(locationBreakdownData).map(function(
    key
  ) {
    return {
      key: key,
      value: Number(locationBreakdownData[key]),
    };
  }) || [
    { key: "", value: 0 },
    { key: "", value: 0 },
  ];

  if (locationBreakdownArray.length === 0) {
    locationBreakdownArray = [
      { key: "", value: 0 },
      { key: "", value: 0 },
    ];
  }

  if (locationBreakdownArray.length === 1) {
    locationBreakdownArray = locationBreakdownArray.concat([
      { key: "", value: 0 },
    ]);
  }

  let initialValues = {
    platformType: accountData?.type || "",
    ageBreakdown: accountData?.ageBreakdown?.manual || {},
    genderBreakdown: accountData?.genderBreakdown?.manual || {},
    locationBreakdown: locationBreakdownArray,
    screenshot: null,
  };

  const onSubmit = async (
    values: ProfileDetailFormValues,
    actions: FormikActions<ProfileDetailFormValues>
  ) => {
    try {
      const ageBreakdown = values?.ageBreakdown || {};

      const ageBreakdownData = Object.entries(ageBreakdown).reduce(
        (a, [k, v]) =>
          typeof v !== "undefined" && v !== "" && v !== null && v !== false
            ? { ...a, [k]: v }
            : a,
        {}
      );

      const genderBreakdown = values?.genderBreakdown || {};

      const genderBreakdownData = Object.entries(genderBreakdown).reduce(
        (a, [k, v]) =>
          typeof v !== "undefined" && v !== "" && v !== null && v !== false
            ? { ...a, [k]: v }
            : a,
        {}
      );

      let locationBreakdownData = values?.locationBreakdown.reduce(function(
        result,
        item
      ) {
        if (
          item.key !== "" &&
          typeof item.value !== "undefined" &&
          item.value !== "" &&
          item.value !== null &&
          item.value !== false
        ) {
          result[item.key] = item.value;
          return result;
        }
        return result;
      },
      {});

      let locationSum = sum(_values(locationBreakdownData));

      if (locationSum < 100 && !has(locationBreakdownData, "other")) {
        let otherLocationBreakdownData = { other: 100 - locationSum };

        locationBreakdownData = Object.assign(
          locationBreakdownData,
          otherLocationBreakdownData
        );
      }

      const formData = {
        claimAccountId: claimAccountId,
        ageBreakdown: ageBreakdownData,
        genderBreakdown: genderBreakdownData,
        locationBreakdown: locationBreakdownData,
        screenshot: values?.screenshot,
      };

      const res = await updateWelcomeProfileDetailMutation({
        variables: formData,
      });
      if (res?.data?.updateWelcomeProfileDetail?.id) {
        goNext();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <ProfileDetailForm
      initialValues={initialValues}
      profileDetailSchema={ProfileDetailSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      accountData={accountData}
      goBack={goBack}
      setShowExample={value => setShowExample(value)}
      setScreenshotType={value => setScreenshotType(value)}
      showExample={showExample}
    />
  );
};
