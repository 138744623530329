// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { List } from "antd";
import DraftPost from "./DraftPost";
import { isImgLink } from "src/utils/isImgLink";

const FixedList = styled(List)`
  &&& {
    .ant-row {
      display: flex;
      flex-wrap: wrap;
    }
    .ant-list-pagination {
      margin-top: 0px !important;
      text-align: center !important;
    }
    .ant-pagination {
      font-size: 10px !important;
    }
  }
`;

interface Props {
  allMedia: Array<any>;
}

const DraftMedia: React.FC<Props> = ({ allMedia }) => {
  const [page, setPage] = useState(1);

  return (
    <FixedList
      grid={{ gutter: 16, column: 1 }}
      style={{ marginBottom: "15px" }}
      loading={false}
      pagination={{
        onChange: page => {
          setPage(page);
        },
        current: page,
        total: (allMedia || []).length,
        pageSize: 1,
        size: "small",
        simple: true,
      }}
      dataSource={allMedia || []}
      renderItem={item => {
        return (
          <List.Item>
            <DraftPost
              contentType={isImgLink(item) ? "image" : "video"}
              contentUrl={item as string}
            />
          </List.Item>
        );
      }}
    />
  );
};

export default DraftMedia;
