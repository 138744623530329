// @ts-nocheck
import React from 'react'
import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import { StyledButton, Disabled } from './StyledButton'
import { Typography } from 'src/components/Core/Typography'

interface Props {
  connected?: boolean
  text: string
  handleClick: () => void
}

const Button = ({connected, text, handleClick}: Props) => {
  return (
    <StyledButton connected={!!connected} onClick={handleClick}>
      <Typography color="skyblue" mb={0}>
        {connected ?
          <CheckCircleFilled style={{color: "#73D13D"}} />
          :
          <PlusOutlined />
        }
        &nbsp;{text}
      </Typography>
    </StyledButton>
  );
}

export const DisabledButton = () => {
  return(
    <Disabled>
      <Typography color="grey7" size={16} mb={0}>
        Connect accounts by clicking one of the buttons below
      </Typography>
    </Disabled>
  )
}


export default Button;
