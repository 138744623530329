// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import StyledSelect from "./StyledSelect";
import { SelectProps } from "../Select/StyledSelect";
import get from "lodash/get";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";

interface Props {
  name?: string;
  data?: Array<any>;
  local?: boolean;
  loadData?: Function;
  optionGenerator?: Function;
  style?: any;
  value?: any;
  onChange?: Function;
}

const FormikSelect: React.FC<Props & SelectProps> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur, setFieldValue, setFieldTouched },
      }) => (
        <>
          <StyledSelect
            {...field}
            {...props}
            touched={get(touched, field.name)}
            error={get(touched, field.name) && get(errors, field.name)}
            onBlur={e => {
              setFieldTouched(field.name);
              handleBlur(e);
            }}
            onChange={value => {
              if (props.onChange) {
                props.onChange(value);
              } else {
                setFieldTouched(field.name);
                setFieldValue(field.name, value);
              }
            }}
            data-cy={`select-${field.name.replace(" ", "-")}`}
          />
          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={field.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikSelect;
