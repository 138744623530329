import React, { Component } from "react";
import { connect } from "formik";
import FormField from "../FormField";
import { Typography } from "antd";
import CurrencyInput from "src/shared/CurrencyInput";
import formatCurrency from "src/utils/formatCurrency";
import { cpm } from "src/constants/account";

class Cpm extends Component {
  render() {
    const { name, rest, formik } = this.props;
    let recommended = null;

    if (formik) {
      const { values } = formik;

      if (values.type && values.format) {
        recommended = cpm[values.type][values.format];
      }
    }

    return (
      <>
        <FormField name={name || "cpm"} label="CPM Payout">
          <CurrencyInput data-cy="account-cpm" {...rest} />
          {recommended ? (
            <Typography style={{ marginTop: 5 }}>
              Recommended: {formatCurrency(recommended)}
            </Typography>
          ) : (
            <div />
          )}
        </FormField>
      </>
    );
  }
}

export default connect(Cpm);
