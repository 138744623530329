// @ts-nocheck
import React from 'react'
import { Row, Col, Button, message } from 'antd'
import { Box } from 'src/components/Core/Box'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { Typography } from 'src/components/Core/Typography'
import styled from 'styled-components'

interface Props {
  tags: []
}

const StyledButton = styled(Button)`
  width: 100%;
`

const Section = ({tags}: Props) => {
  return(
    <Box px={40}>
      <Typography fontSize={20} color="black1" mb={2}>Hashtags:</Typography>
      <Row>
        <Col md={20} sm={24}>
          <Row>
            {tags.map((tag: any) =>
              <Col md={6} sm={8} xs={8} key={tag.id}>
                <Typography mb={2} fontSize={14} color="grey9">
                  #{tag.name}
                </Typography>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={4} sm={24}>
          <CopyToClipboard text={tags.map((tag: any) => `#${tag.name}`).join(' ')}
            onCopy={() => message.success('Copied!')}
          >
            <StyledButton type="primary">Copy All</StyledButton>
          </CopyToClipboard>
        </Col>
      </Row>
    </Box>
  )
}

export default Section
