// @ts-nocheck
import React from "react";
import { FormikSelect } from "src/components/Forms/Select";
import { bytesight_search_criteria } from "src/constants/account";
import { Box } from "src/components/Core/Box";

interface Props {}

const SearchCriteria: React.FC<Props> = () => {
  return (
    <Box mb={20}>
      <FormikSelect
        name="metricSelect"
        data={bytesight_search_criteria}
        local
        borderRadius={3}
        borderColor="grey5"
        size="middle"
      />
    </Box>
  );
};

export default SearchCriteria;
