// @ts-nocheck
import React, { useState } from "react";
import { Box } from "src/components/Core/Box";
import { Alert, Checkbox, Row, Col } from "antd";
import { Typography } from "src/components/Core/Typography";
import LineChartGraph from "./LineChartGraph";

interface Props {
  account: any;
  graphData: Array<any>;
}

const TrackingData: React.FC<Props> = ({ account, graphData }) => {
  const [dataPoint, setDataPoint] = useState({
    views: true,
    engagement: true,
    followers: true,
  });

  return (
    <Box>
      {account?.accountName && (
        <Box display="flex" justifyContent="space-between">
          <Row gutter={8}>
            <Col span={7}>
              <Box display="inline-flex" alignItems="center">
                <Typography size={16} weight="semi-bold" color="blue" mb={0}>
                  @{account?.accountName}
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#000000",
                    }}
                  >
                    's tracking data
                  </span>
                </Typography>
              </Box>
            </Col>
            <Col span={17}>
              <Alert
                message="Data is collected every 24 hours. Engagement rate is calculated by the average engagement of the posts from the account in the last week."
                type="info"
                showIcon
              />
            </Col>
          </Row>
        </Box>
      )}

      <Box mt={20}>
        <LineChartGraph
          data={graphData}
          YDomain={[0, "auto"]}
          dataPoint={dataPoint}
        />
      </Box>
      {(graphData || []).length > 0 && (
        <Box>
          <Typography size={14} color="grey7" mb={0}>
            Data Point:
          </Typography>
          <Box display="inline-flex">
            <Checkbox
              checked={dataPoint?.engagement}
              value={dataPoint?.engagement}
              onChange={value => {
                setDataPoint(prev => ({
                  ...prev,
                  engagement: !prev.engagement,
                }));
              }}
            >
              Engagement rate (%)
            </Checkbox>

            <Checkbox
              checked={dataPoint?.views}
              value={dataPoint?.views}
              onChange={value => {
                setDataPoint(prev => ({
                  ...prev,
                  views: !prev.views,
                }));
              }}
            >
              Views (avg.)
            </Checkbox>

            <Checkbox
              checked={dataPoint?.followers}
              value={dataPoint?.followers}
              onChange={value => {
                setDataPoint(prev => ({
                  ...prev,
                  followers: !prev.followers,
                }));
              }}
            >
              Follower count
            </Checkbox>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TrackingData;
