// @ts-nocheck
import React from "react";
import { InvoicesContainer } from "./container";
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from "antd";
import FieldError from "../../../../components/Fields/FieldError";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";

export const InvoicesWrapper = styled.div`
  &&& {
    width: 100%;
    .ant-upload.ant-upload-drag {
      min-height: 115px;
      margin-top: 70px;
    }
  }
`;

const StyledP = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: "Futura";
`

type InvoicesProps = {
  name: string;
  fileList: Array<any>;
  handleRemove: (value: any) => void;
  onUpload: (value: any) => void;
  multiple: boolean;
  disabled: boolean;
  uploadText?: string;
};

const Invoices: React.FC<InvoicesProps> = ({
  handleRemove,
  onUpload,
  fileList,
  multiple,
  disabled,
  name,
}) => {
  return (
    <InvoicesWrapper data-cy={`input-${name}-Invoices`}>
      <Upload.Dragger
        multiple={multiple}
        disabled={disabled}
        fileList={fileList}
        customRequest={file => onUpload(file)}
        onRemove={file => handleRemove(file)}
      >
        <Box p={10} mt={60}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <StyledP className="ant-upload-text">
            Attach any invoices here
          </StyledP>
        </Box>
      </Upload.Dragger>
      <FieldError fieldName={name} />
    </InvoicesWrapper>
  );
};

export { Invoices, InvoicesContainer };
