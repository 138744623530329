// @ts-nocheck
import styled from "styled-components";

export const FeedbackWrapper = styled.div`
  margin: 10px 0;
`;

export const FeedbackListWrapper = styled.div`
  padding-top: 10px;
`;

export const SerialNumber = styled("p")`
  flex: 1;
  font-weight: 500;
`;

export const ListItem = styled("p")`
  flex: 10;
  font-weight: 500;
  letter-spacing: 0.4px;
  margin-bottom: 0px !important;
`;

export const ModalFooterWrapper = styled.div`
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 0px;
`;

export const DraftTime = styled("p")`
  &&& {
    flex: 16;
    text-align: justify;
    color: #40a9ff;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 15px;
    margin-left: 26px;
  }
`;
