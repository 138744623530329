import {
  AccountList as $AccountList,
  InvitationList as $InvitationList,
  SuggestionList as $SuggestionList,
} from "./DataList";
import {
  CampaignPersist,
  InvitationPersist,
  SuggestionPersist,
  DraftPersist,
  CampaignAnalyticPersist,
  CampaignPackagePersist,
} from "./Persist";

const name = "CampaignManage";

const AccountList = $AccountList(name);
const DraftsList = $InvitationList(name + "Drafts");
const AllInvitationList = $InvitationList(name + "All");
const CampaignManage = CampaignPersist(name);
const CampaignAnalytic = CampaignAnalyticPersist(name);
const CampaignPackage = CampaignPackagePersist(name);
const InvitationManage = InvitationPersist(name);
const DraftManage = DraftPersist(name);
const SuggestionManage = SuggestionPersist(name);
const SuggestionList = $SuggestionList(name);
const HiddenSuggestionList = $SuggestionList(name + "HiddenList");

const initialState = {};

// Types
const types = {};

// Action creators
const actions = {
  loadSuggestedAccounts: (page = 1) => {
    return (dispatch, getState) => {
      const campaign = CampaignManage.selectOne(getState());
      return dispatch(
        AccountList.loadMany({
          campaign: campaign.id,
          per_page: 12,
          page: page,
        })
      );
    };
  },
  loadCampaignInvitations: (page = 1) => {
    return (dispatch, getState) => {
      const campaign = CampaignManage.selectOne(getState());
      return dispatch(
        AllInvitationList.loadMany({
          campaign: campaign.id,
          expand: ["account"],
          per_page: 4,
          page: page,
        })
      );
    };
  },
  loadHidden: (page = 1) => {
    return (dispatch, getState) => {
      const campaign = CampaignManage.selectOne(getState());
      return dispatch(
        HiddenSuggestionList.loadMany({
          campaign: campaign.id,
          status: ["hidden", "rejected"],
          expand: ["account"],
          per_page: 4,
          page: page,
        })
      );
    };
  },
  createInvitation: data => {
    return (dispatch, getState) => {
      const campaign = CampaignManage.selectOne(getState());
      return dispatch(
        InvitationManage.create({
          status: "sent",
          campaign: campaign.id,
          ...data,
        })
      )
        .then(() => dispatch(actions.loadCampaignInvitations()))
        .then(() => dispatch(actions.loadInviteTotal()));
    };
  },
  createSuggestion: data => {
    return (dispatch, getState) => {
      const campaign = CampaignManage.selectOne(getState());
      return dispatch(
        SuggestionManage.create({
          status: "pending",
          ...data,
          campaign: campaign.id,
        })
      ).then(() => dispatch(actions.loadInviteTotal()));
    };
  },
  deleteSuggestion: data => {
    return (dispatch, getState) => {
      return dispatch(SuggestionManage.destroy(data)).then(() =>
        dispatch(actions.loadInviteTotal())
      );
    };
  },
  hideAccount: account => {
    return (dispatch, getState) => {
      const campaign = CampaignManage.selectOne(getState());

      let method = () =>
        SuggestionManage.create({
          campaign: campaign.id,
          account: account.id,
          status: "hidden",
        });

      if (account.suggestion)
        method = () =>
          SuggestionManage.update(account.suggestion, { status: "rejected" });

      return dispatch(method())
        .then(() => dispatch(actions.loadHidden()))
        .then(() => dispatch(actions.loadSuggestedAccounts()));
    };
  },
  unHideAccount: suggestion => {
    return (dispatch, getState) => {
      let method = () => SuggestionManage.destroy(suggestion.id);

      if (suggestion.status === "rejected")
        method = () =>
          SuggestionManage.update(suggestion.id, { status: "pending" });

      return dispatch(method())
        .then(() => dispatch(actions.loadHidden()))
        .then(() => dispatch(actions.loadSuggestedAccounts()));
    };
  },
  loadInviteTotal: () => {
    return (dispatch, getState) => {
      const campaign = CampaignManage.selectOne(getState());
      return dispatch(CampaignPackage.get(campaign.id));
    };
  },
};

// Reducer
const reducer = (state = initialState, action) => {
  const handlers = {};

  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
};

// Selectors
const selectors = {
  selectInviteTotalPennies: state => {
    const stats = CampaignPackage.selectOne(state);
    return stats ? stats.invited_spend_pennies || 0 : 0;
  },
  selectSentInvitations: state => {
    const stats = CampaignPackage.selectOne(state);
    return stats ? stats.sent_invitations || 0 : 0;
  },
  selectInvitePercentage: state => {
    if (CampaignManage.selectOne(state).influencer_invite_limit) {
      return (
        (selectors.selectSentInvitations(state) * 100) /
        (CampaignManage.selectOne(state).influencer_invite_limit || 0)
      );
    } else {
      return (
        (selectors.selectInviteTotalPennies(state) * 100) /
        (CampaignManage.selectOne(state).influencer_spend_pennies || 0)
      );
    }
  },
  selectNetInvite: state => {
    if (CampaignManage.selectOne(state).influencer_invite_limit) {
      return (
        (CampaignManage.selectOne(state).influencer_invite_limit || 0) -
        selectors.selectSentInvitations(state)
      );
    } else {
      return (
        (CampaignManage.selectOne(state).influencer_spend_pennies || 0) -
        selectors.selectInviteTotalPennies(state)
      );
    }
  },
};

const CampaignModule = () => [
  AccountList.module(),
  DraftsList.module(),
  CampaignManage.module(),
  CampaignAnalytic.module(),
  InvitationManage.module(),
  SuggestionManage.module(),
  HiddenSuggestionList.module(),
  SuggestionList.module(),
  AllInvitationList.module(),
  CampaignPackage.module(),
  {
    id: "CampaignManage",
    reducerMap: {
      CampaignManage: reducer,
    },
  },
];

export default {
  accounts: AccountList,
  drafts: DraftsList,
  invitationPersist: InvitationManage,
  draftPersist: DraftManage,
  suggestionPersist: SuggestionManage,
  allInvitations: AllInvitationList,
  package: CampaignPackage,
  campaign: CampaignManage,
  analytic: CampaignAnalytic,
  hiddenList: HiddenSuggestionList,
  suggestions: SuggestionList,
  types,
  reducer,
  module: CampaignModule,
  ...actions,
  ...selectors,
};
