// @ts-nocheck
import React, { Fragment } from "react";
import { CheckOutlined } from '@ant-design/icons';
import Loading from "src/shared/Loading";
import Button from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { usePaymentDetails, paymentDetail } from "../PaymentDetailsProvider";
import { usePaymentQueueDetails } from "../../PaymentQueueProvider";

interface Props {
  closePaymentModal: () => void;
  setStep: (value: number) => void;
  isCompletePayment?: boolean;
}

const PaymentThankyou: React.FC<Props> = ({
  closePaymentModal,
  setStep,
  isCompletePayment,
}) => {
  const { actions } = usePaymentDetails();
  const { actions: queueAction } = usePaymentQueueDetails();
  return (
    <Fragment>
      <Loading spinning={false}>
        <Box textAlign="center" p={100}>
          <Box
            height={62}
            width={62}
            border="3px solid #97d691"
            alignItems="center"
            justifyContent="center"
            fontSize={30}
            display="flex"
            margin="0 auto 24px"
            borderRadius={100}
            color="green"
          >
            <CheckOutlined />
          </Box>
          <Typography size={30} weight="semi-bold" color="black">
            Thank you!
          </Typography>
          <Typography size={14} color="grey2" lspace={0.43}>
            Your payment request has been registered. You will be notified by
            email once it has been reviewed.
          </Typography>
          <Box mt={70}>
            <Button
              type="action"
              onClick={() => {
                actions.setActualPaymentDetails({});
                actions.setSelectedInfluencer({} as paymentDetail);
                if (!isCompletePayment) {
                  queueAction.refetchQueue();
                }
                closePaymentModal();
                setStep(1);
              }}
              data-cy="thankyou-button"
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Loading>
    </Fragment>
  );
};
export default PaymentThankyou;
