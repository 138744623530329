import styled from "styled-components";
import { Card, Typography } from "antd";
import ResultsTable from "../TableManager/ResultsTable";

const TableContainer = styled(Card)`
  &&& {
    background: none;
    border: none;
    .ant-card-body {
      padding: 0px;
    }
  }
`;

TableContainer.Header = styled("div")`
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 1px 1px 1px 1px;
`;

TableContainer.Actions = styled("div")`
  > * {
    margin-right: 8px;
  }
`;

TableContainer.Table = styled(ResultsTable)`
  &&& {
    .ant-table-body {
      background: #fff;
      margin: 0px;
      border-style: solid;
      border-color: #e8e8e8;
      border-width: 0px 1px 0px 1px;
    }
  }
`;

TableContainer.Title = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
`;

export default TableContainer;
