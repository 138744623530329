import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button, message } from "antd";
import DashboardLayout from "../../layouts/DashboardLayout";
import { show } from "redux-modal";
import PageHero from "src/shared/PageHero";
import TeamTable from "src/shared/TeamTable";
import withModules from "src/utils/hoc/withModules";
import { AdminList } from "src/ducks/DataList";
import { AdminPersist } from "src/ducks/Persist";
import Auth from "src/ducks/Auth";
import Container from "src/shared/Container";
import InviteModal from "./InviteModal";

const TeamList = AdminList("AdminTeamMembers");
const TeamPersist = AdminPersist("AdminTeamMembers");

const modules = [TeamList.module(), TeamPersist.module()];

const ExtraButton = styled(Button)`
  margin-top: -3px;
`;

const TableContainer = styled("div")`
  background: #fff;

  &&& {
    .ant-table-pagination.ant-pagination {
      display: block;
      text-align: center;
      float: none;
    }
  }
`;

export class TeamManagement extends Component {
  componentDidMount() {
    this.loadMembers();
  }

  loadMembers = (page = 1) => {
    this.props.loadTeamMembers({ page });
  };

  removeTeamMember = id => {
    const { destroyTeamMember } = this.props;
    destroyTeamMember(id).catch(() => message.error("Failed to delete user"));
  };

  render() {
    const { show, pagination, teamMembers, currentUser } = this.props;

    return (
      <DashboardLayout title="Team">
        <PageHero
          title="Team"
          subTitle="Here you can add and remove team members"
          extra={[
            <ExtraButton
              key="1"
              onClick={() => show("InviteAdmin")}
              type="primary"
            >
              Add new team member
            </ExtraButton>
          ]}
        />

        <Container>
          <TableContainer>
            <TeamTable
              pagination={pagination}
              data={teamMembers}
              currentUser={currentUser}
              removeTeamMember={this.removeTeamMember}
              changePage={this.loadMembers}
            />
          </TableContainer>
        </Container>

        <InviteModal onInvite={this.loadMembers} />
      </DashboardLayout>
    );
  }
}

const mapStateToProps = state => ({
  teamMembers: TeamList.selectMany(state),
  pagination: TeamList.selectPagination(state),
  currentUser: Auth.selectUser(state),
  loading: TeamPersist.selectCreatePending(state)
});

const mapDispatchToProps = {
  loadTeamMembers: TeamList.loadMany,
  destroyTeamMember: TeamPersist.destroy,
  show
};

export default withModules(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TeamManagement)
)(modules);
