// @ts-nocheck
import React, { useRef, useEffect } from "react";
import numeral from "numeral";
import { Visible, useScreenClass } from "react-grid-system";
import star from "src/assets/images/reports/star.png";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { Avatar, Row, Col } from "antd";
import { Tag } from "src/components/Core/Tag";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import Stats from "./Stats";
import MostLikePost from "./MostLikePost";
import FadeInSection from "../../FadeInSection";
import { useSpring, animated } from "react-spring";
import { FirstStar, FirstStarImg, SecondStar, SecondStarImg } from "./styles";

interface Props {
  onChangeSection: (value: string) => void;
  campaignData: any;
}

const InfluencerStats: React.FC<Props> = ({
  onChangeSection,
  campaignData,
}) => {
  const screenClass = useScreenClass();

  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};

  let props1 = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props1 as any
  );

  useEffect(() => {
    if (inViewport) {
      onChangeSection("influencerstats");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport]);

  let data = campaignData?.mostLikedPost || {};

  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];

  const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
  const trans2 = (x, y) => `translate3d(${x / 14}px,${y / 14}px,0)`;
  const trans3 = (x, y) => `translate3d(${x / 18}px,${y / 18}px,0)`;
  const trans4 = (x, y) => `translate3d(${x / 22}px,${y / 22}px,0)`;
  const trans5 = (x, y) => `translate3d(${x / 26}px,${y / 26}px,0)`;

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <FadeInSection>
      <Box
        pt={screenClass === "sm" || screenClass === "xs" ? 50 : 100}
        pb={100}
        position="relative"
        display="block"
        id="influencerstats"
        ref={myRef as any}
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <Row gutter={36}>
          <Visible md xl lg>
            <Col span={12}>
              <MostLikePost
                videoUrl={data?.videoUrl}
                trans4={trans4}
                trans5={trans5}
                transitionProps={props}
              />
            </Col>
          </Visible>

          <Col span={screenClass === "sm" || screenClass === "xs" ? 24 : 12}>
            <Box alignItems="center" textAlign="center">
              <VelocityComponent
                key="slideUp"
                animation={
                  enterCount <= 1 && inViewport
                    ? "transition.slideUpIn"
                    : "transition.fadeIn"
                }
              >
                <Typography
                  fontSize={
                    screenClass === "lg" || screenClass === "xl" ? 40 : 30
                  }
                  fontWeight={600}
                  color="white"
                  mb={0}
                >
                  Your most liked post was by
                </Typography>
              </VelocityComponent>
              <VelocityComponent
                key="slideUp1"
                animation={
                  enterCount <= 1 && inViewport
                    ? "transition.slideUpIn"
                    : "transition.fadeIn"
                }
              >
                <Typography
                  fontSize={
                    screenClass === "lg" || screenClass === "xl" ? 40 : 30
                  }
                  color="blue3"
                >
                  @{data?.account?.username}
                </Typography>
              </VelocityComponent>

              <Box textAlign="center" display="inline flow-root">
                <VelocityComponent
                  key="bounce"
                  animation={
                    enterCount <= 1 && inViewport
                      ? "transition.bounceIn"
                      : "transition.fadeIn"
                  }
                  delay={100}
                  stagger={100}
                  duration={300}
                >
                  <FirstStar
                    style={{
                      transform: props?.xy?.interpolate(trans1 as any),
                      WebkitTransform: props?.xy?.interpolate(trans1 as any),
                    }}
                  >
                    <FirstStarImg src={star} alt="star" />
                  </FirstStar>
                </VelocityComponent>
                <VelocityComponent
                  key="bounce1"
                  animation={
                    enterCount <= 1 && inViewport
                      ? "transition.bounceIn"
                      : "transition.fadeIn"
                  }
                  delay={200}
                  stagger={100}
                  duration={600}
                >
                  <animated.div
                    style={{
                      transform: props?.xy?.interpolate(trans2 as any),
                      WebkitTransform: props?.xy?.interpolate(trans2 as any),
                    }}
                  >
                    <Box
                      background="white"
                      width="210px"
                      p={15}
                      textAlign="center"
                      position="relative"
                      zIndex={99}
                      mt={40}
                    >
                      <Avatar src={data?.account?.profilePicUrl} size={65} />
                      <Typography
                        size={20}
                        fontWeight={600}
                        color="black"
                        lineHeight={0}
                        mt={20}
                      >
                        @{data?.account?.username}
                      </Typography>

                      <Box display="inline-flex">
                        {(data?.account?.tags || [])
                          .slice(0, 3)
                          .map((item, i) => {
                            return (
                              <Tag
                                key={i}
                                size={10}
                                tagColor="cyan7"
                                background="cyan1"
                                borderColor="cyan3"
                                marginBottom="6px"
                              >
                                {item?.name}
                              </Tag>
                            );
                          })}
                      </Box>

                      <Stats
                        name="Reach"
                        count={`${numeral(data?.reach).format("0a")}+`}
                      />
                      <Stats
                        name="Views"
                        count={`${numeral(data?.account?.views).format("0a")}+`}
                      />
                      <Stats
                        name="Hearts"
                        count={`${numeral(data?.hearts).format("0a")}+`}
                      />
                      <Stats
                        name="Comments"
                        count={`${numeral(data?.comments).format("0,0")}+`}
                      />
                      <Stats
                        name="Engagement"
                        count={
                          numeral(data?.engagement).format("0,0.00") + "%+"
                        }
                      />
                    </Box>
                  </animated.div>
                </VelocityComponent>
                <VelocityComponent
                  key="bounce2"
                  animation={
                    enterCount <= 1 && inViewport
                      ? "transition.bounceIn"
                      : "transition.fadeIn"
                  }
                  delay={300}
                  stagger={100}
                  duration={900}
                >
                  <SecondStar
                    style={{
                      transform: props?.xy?.interpolate(trans3 as any),
                      WebkitTransform: props?.xy?.interpolate(trans3 as any),
                    }}
                  >
                    <SecondStarImg src={star} alt="star" />
                  </SecondStar>
                </VelocityComponent>
              </Box>
            </Box>
          </Col>
        </Row>
      </Box>
    </FadeInSection>
  );
};

export default InfluencerStats;
