// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import DocumentTitle from "react-document-title";
import { CampaignInstantReportContainer } from "./CampaignInstantReport";

interface Props {}

const InstantReport: React.FC<Props> = () => {
  return (
    <DocumentTitle title="Instant Reports">
      <Box>
        <CampaignInstantReportContainer />
      </Box>
    </DocumentTitle>
  );
};

export default InstantReport;
