// @ts-nocheck
import { string, object, array } from 'yup'

export const NewMilestoneSchema = object().shape({
  name: string()
    .required("Please enter the name"),
  metric: string()
    .required("Please select the metric"),
  goal: string()
    .matches(/^[+]?((\.\d+)|(\d+(\.\d+)?))$/, "Goal must be greater than 0 and a valid number"),
  viewers: array()
    .required("Please select atleast one"),
  notifiables: array()
    .required("Please selec atleast one"),
});
