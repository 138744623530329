// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { CreateNewCampaignFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  GroupTitle,
  Company,
  Brands,
} from "src/components/Modules/Campaign/Fields";

interface CreateNewCampaignFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  createNewCampaignSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  closeCreateNewCampaignModal: () => void;
  getCompanies: Function;
  getBrands: Function;
}

export const CreateNewCampaignFormWrapper = styled.div`
  width: 100%;
`;

const CreateNewCampaignForm: React.FC<CreateNewCampaignFormProps> = ({
  initialValues,
  isSubmitting,
  createNewCampaignSchema,
  onSubmit,
  globalErrors,
  closeCreateNewCampaignModal,
  getCompanies,
  getBrands,
}) => {
  return (
    <CreateNewCampaignFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={createNewCampaignSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                <GroupTitle title="Campaign Title:" name="title" />

                <Company getCompanies={getCompanies} />

                <Brands getBrands={getBrands} />

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={20}
                >
                  <LinkButton
                    onClick={() => closeCreateNewCampaignModal()}
                    mr={20}
                  >
                    Cancel
                  </LinkButton>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    buttonSize="middle"
                    data-cy="submit-button"
                  >
                    Submit
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </CreateNewCampaignFormWrapper>
  );
};

export { CreateNewCampaignForm, CreateNewCampaignFormContainer };
