// @ts-nocheck
import React from "react";
import Form from "src/components/Forms/Form";
import { Box } from "src/components/Core/Box";
import { Schema } from "yup";
import { Alert } from "antd";
import { FormikSelect } from "src/components/Forms/Select";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";
import { AdUploadFormContainer } from "./container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { FormikHelpers as FormikActions, FormikValues } from "formik";

interface AdUploadFormProps<Values = FormikValues> {
  initialValues: Values;
  isSubmitting: boolean;
  adUploadSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  globalErrors?: GlobalErrorProps[];
  closeAdUploadModal: () => void;
  isSuccess: boolean;
  authorised: any;
  advertisers: Array<any>;
  selectedAdvertiser: string;
}

const AdUploadForm: React.FC<AdUploadFormProps> = ({
  initialValues,
  isSubmitting,
  adUploadSchema,
  onSubmit,
  globalErrors,
  closeAdUploadModal,
  isSuccess,
  authorised,
  advertisers,
  selectedAdvertiser,
}) => {
  let isAuthorised =
    (authorised?.TikTok || authorised?.Facebook || authorised?.Instagram) &&
    (advertisers || []).length > 0;
  return (
    <>
      <GlobalErrors errors={globalErrors} />

      <Box background="white" mt={10} mb={10} borderRadius="4px">
        <Loading spinning={isSubmitting}>
          <Form
            initialValues={initialValues}
            schema={adUploadSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {(formProps: any) => {
              return (
                <>
                  {!isAuthorised ? (
                    <Alert
                      message="No Ad platforms authorised"
                      description="You need to authorise an ad platform before uploading creeatives."
                      type="warning"
                      showIcon
                    />
                  ) : isSuccess ? (
                    <>
                      <Alert
                        message="Success"
                        description={`
                            Your creative has succesfully been uploaded to
                            ${selectedAdvertiser}`}
                        type="success"
                        showIcon
                      />

                      <Box
                        alignItems="right"
                        textAlign="right"
                        display="inherit"
                        mt={20}
                      >
                        <Button
                          htmlType="submit"
                          type="action"
                          onClick={() => closeAdUploadModal()}
                          buttonSize="middle"
                          data-cy="submit-button"
                        >
                          Close
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Field label="" mb={26}>
                        <FormikInput
                          name="fileName"
                          size="middle"
                          placeholder="Please name your upload"
                          borderRadius={2}
                          borderColor="grey5"
                        />
                      </Field>

                      <Field label="" mb={26}>
                        <FormikSelect
                          name="advertiserId"
                          size="middle"
                          placeholder="Please select an Ad to upload to"
                          data={advertisers}
                          borderRadius={2}
                          borderColor="grey5"
                          local
                        />
                      </Field>
                      <Box
                        alignItems="right"
                        textAlign="right"
                        display="inherit"
                        mt={20}
                      >
                        <LinkButton
                          onClick={() => closeAdUploadModal()}
                          mr={20}
                          color="red6"
                          background="grey21"
                          height="40px"
                        >
                          Cancel
                        </LinkButton>

                        <Button
                          htmlType="submit"
                          type="action"
                          loading={isSubmitting}
                          disabled={isSubmitting || !isAuthorised}
                          buttonSize="middle"
                          data-cy="submit-button"
                        >
                          Upload
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              );
            }}
          </Form>
        </Loading>
      </Box>
    </>
  );
};

export { AdUploadForm, AdUploadFormContainer };
