// @ts-nocheck
import React, { useEffect } from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import find from 'lodash/find'
import {
  usePaymentDetails,
  paymentDetail as PaymentDetail
} from "../../PaymentDetailsProvider";
import { bankDetailFields } from "../../PaymentDetails/BankDetailForm/Steps/BankDetailFields";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";
import { FormikProps } from "formik";
import { featureFlags } from "src/constants/featureFlags";

interface Props {
  agencies: any
  agencyId: number
  getAgencies: Function
  setAgencies: Function
  formProps: FormikProps<any>
  setFieldValue: (key: string, value: any) => void
}


const CampaignField: React.FC<Props> = ({
  agencyId,
  agencies,
  getAgencies,
  setAgencies,
  setFieldValue,
  formProps
}) => {

  const { actions } = usePaymentDetails();
  const { isPaypalEnabled } = featureFlags;

  useEffect(() => {
    if (agencyId) {
      let item: any = find(agencies, obj => obj.id === agencyId)

      actions.setSelectedAgency(item)
      setFieldValue(
        "agencyName",
        item?.agencyName
          ? item?.agencyName
          : ""
      );

      const payoutDetails = item?.payoutDetail;
      const bankAccount = item?.payoutDetail?.bankAccount;

      let paymentDetail = {};

      if (isPaypalEnabled && payoutDetails?.paypalEmail) {
        paymentDetail = {
          ...paymentDetail,
          paypalEmail: payoutDetails?.paypalEmail,
        };
      }

      //set bank detail fields with loop from backDetail fields
      if (bankAccount) {
        let container = {} as any;
        // if bankaccount has country code then we set as per country wise field
        // else before we not set country code just UK bank detail so we set bydefault that fields
        if (bankAccount?.countryCode) {
          container["countryCode"] = bankAccount?.countryCode;
          bankDetailFields[bankAccount?.countryCode].fields.forEach(field => {
            container[field.name] =
              (bankAccount[field.name] && bankAccount[field.name].toString()) ||
              "";
          });

          paymentDetail = {
            ...paymentDetail,
            bankAccount: container,
          };
        } else {
          paymentDetail = {
            ...paymentDetail,
            bankAccount: {
              fullName: bankAccount?.fullName || "",
              accountNumber: bankAccount?.accountNumber || null,
              sortCode: bankAccount?.sortCode || null,
            },
          };
        }
      }

      actions.setExistingPaymentDetails({
        ...paymentDetail,
      } as PaymentDetail);
      actions.setActualPaymentDetails({ ...paymentDetail } as PaymentDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyId]);


  return (
    <Field label="Agency:" mb={26}>
      <FormikSelect
        name="agencyId"
        size="large"
        loadData={async value => {
          let agency = await getAgencies({
            agencyName: value,
          });
          if (agency?.data?.getAllAgencies?.length > 0) {
            const allAgencies =  agency?.data?.getAllAgencies
            const data =  agencyId && allAgencies.indexOf(ag => ag.id === agencyId) === -1 ?
                          [agencies.find(ag => ag.id === agencyId), ...allAgencies] : allAgencies
            setAgencies(data)
            return data;
          } else {
            return [];
          }
        }}
        optionGenerator={value => ({
          value: value.id,
          label: value.agencyName,
        })}
        background="background"
        borderRadius="3px"
        borderColor="grey5"
      />
      <FieldErrorMessage
        errors={formProps?.errors}
        touched={formProps?.touched}
        mt={20}
        name="agencyName"
      />
    </Field>
  );
};
export default CampaignField;
