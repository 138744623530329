// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import AccountItem from "./AccountItem";

interface Props {
  accounts: Array<any>;
  index: number;
  setIndex: (value: number) => void;
  removeTrackAccount: Function;
}

const AccountList: React.FC<Props> = ({
  accounts,
  index,
  setIndex,
  removeTrackAccount,
}) => {
  return (
    <Box>
      {accounts.map((account, i) => (
        <AccountItem
          key={i}
          account={account}
          isActive={i === index ? true : false}
          setIndex={value => setIndex(value)}
          index={i}
          removeTrackAccount={removeTrackAccount}
        />
      ))}
    </Box>
  );
};

export default AccountList;
