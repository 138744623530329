import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "antd";
import CampaignDetails from "./CampaignDetails";
import ApproveContent from "./ApproveContent";
import Analytics from "./Analytics";
import PostedContent from "./PostedContent";
import ActiveMenu from "./ActiveMenu";
import CampaignManage from "src/ducks/CampaignManage";
import Tracking from "./Tracking";
import ReviewInfluencers from "./ReviewInfluencers";
import AdHub from "./AdHub";

const { Content, Sider } = Layout;

const BorderHeader = styled(PageHeader)`
  border-bottom: 1px solid #e8e8e8;
`;

class Active extends Component {
  componentDidMount() {
    const { campaign, loadSuggestedAccounts } = this.props;

    this.loadMoreInvitations();

    if (campaign.status !== "complete") {
      loadSuggestedAccounts();
    }
  }

  loadMoreInvitations = (page = 1) => {
    const { loadInvitations, campaign } = this.props;

    return loadInvitations({
      campaign: campaign.id,
      expand: ["account", "account.influencer", "drafts"],
      status: ["accepted", "complete"],
      order: {
        stage: "asc",
      },
      per_page: 20,
      page: page,
    });
  };

  render() {
    const { history, title, match, campaign } = this.props;

    return (
      <Fragment>
        <BorderHeader
          style={{
            top: "68px",
            width: "100vw",
            overflow: "auto",
            position: "fixed",
            zIndex: 60,
            backgroundColor: 'white'
          }}
          onBack={() => history.push("/")}
          title={title}
        />
        <Layout>
          <Sider
            width={220}
            style={{
              background: "#fff",
              overflow: "auto",
              height: "100vh",
              marginTop: 69,
              position: "fixed",
            }}
          >
            <ActiveMenu campaign={campaign} />
          </Sider>
          <Content style={{ marginLeft: 220, marginTop: 58 }}>
            <Switch>
              <Route
                path={`${match.path}/find`}
                exact
                component={props => <ReviewInfluencers />}
              />

              <Route
                path={`${match.path}/approve`}
                exact
                component={props => (
                  <ApproveContent
                    loadMoreInvitations={this.loadMoreInvitations}
                  />
                )}
              />
              <Route
                path={`${match.path}/analytics`}
                exact
                component={Analytics}
              />
              <Route
                path={`${match.path}/content`}
                exact
                component={PostedContent}
              />
              <Route
                path={`${match.path}/tracking`}
                exact
                component={Tracking}
              />

              <Route path={`${match.path}/ad-hub`} exact component={AdHub} />

              <Route path={`${match.path}`} exact component={CampaignDetails} />
            </Switch>
          </Content>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {
  loadSuggestedAccounts: CampaignManage.loadSuggestedAccounts,
  loadInvitations: CampaignManage.drafts.loadMany,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Active));
