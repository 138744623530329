// @ts-nocheck
import React from "react"
import { Review } from "."
import {
  GiftingCampaignFragmentFragment,
  useSubmitGiftingCampaignMutation
} from "src/generated/ApolloComponents"
import { message } from "antd"

interface Props {
  campaign?: GiftingCampaignFragmentFragment
  loading: boolean
  setStep: (number: number) => void
}

export const ReviewContainer = ({ campaign, setStep, loading: initialLoading }: Props) => {
  const [submitCampaign, { loading }] = useSubmitGiftingCampaignMutation()

  const onSubmit = async () => {
    try {
      if (campaign?.id)
        await submitCampaign({
          variables: {
            campaignId: campaign.id
          }
        })
    } catch {
      message.error("There are some missing values within your brief")
    }
  }

  return (
    <Review
      setStep={setStep}
      onSubmit={onSubmit}
      loading={initialLoading || loading}
      campaign={campaign}
    />
  )
}
