import React, { Component } from "react";
import { AlignLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AgencyPersist } from "src/ducks/Persist";

const ShowAgency = AgencyPersist("AdminAgencyShow");

export class SideMenu extends Component {
  render() {
    const { agency, location } = this.props;

    const agencyPath = `/agencies/${agency.id}`;

    return (
      <Menu
        mode="vertical"
        selectedKeys={[location.pathname]}
        style={{ height: "100%" }}
      >
        <Menu.Item key={agencyPath}>
          <Link data-cy="agency-details" to={agencyPath} replace>
            <AlignLeftOutlined />
            <span>Agency Details</span>
          </Link>
        </Menu.Item>

        <Menu.Item key={agencyPath + "/update"}>
          <Link data-cy="agency-update" to={agencyPath + "/update"} replace>
            <EditOutlined />
            <span>Update Agency</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  agency: ShowAgency.selectOne(state)
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideMenu)
);
