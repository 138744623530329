// @ts-nocheck
import React from "react";
import { Row, Col, Card } from "antd";
import numeral from "numeral";
import meanBy from "lodash/meanBy";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import styled from "styled-components";
import moment from "moment";

const VideoCard = styled(props => {
  const { isSelected, borderColor, ...rest } = props;
  return <Card {...rest} />;
})`
  && {
    margin-top: 15px;
    border:  ${props =>
      props.isSelected && props.borderColor === "red6"
        ? "3px solid #f5222d"
        : props.isSelected && props.borderColor === "red3"
        ? "3px solid #FFA39E"
        : "none"};  !importnat;
    transition: none !important;
    .ant-card-body {
      padding: 0px !important;
    }
    .ant-card-cover {
      overflow: hidden;
      max-height: 290px;
      height: 100%;
    }
  }
`;

interface Props {
  analysedPosts: Array<any>;
  hoverTagText: string;
}

const AnalysedPosts: React.FC<Props> = ({ analysedPosts, hoverTagText }) => {
  return (
    <Box mt={30}>
      <Box display="inline-flex">
        <Typography size={24} color="black">
          Analysed posts
        </Typography>
        <Typography size={18} color="black" lineHeight="33px" ml={10}>
          {`(${(analysedPosts || []).length} most recent)`}
        </Typography>
      </Box>
      <Box>
        <Row gutter={8}>
          {(analysedPosts || []).map((value, i) => (
            <a href={value?.videoUrl} target="_blank" rel="noopener noreferrer">
              <Col span={4} key={i}>
                <VideoCard
                  cover={
                    <img
                      style={{ width: "100%" }}
                      src={value?.coverUrl}
                      alt="analysedPosts-img"
                    />
                  }
                  isSelected={
                    hoverTagText !== "" &&
                    (hoverTagText.toLowerCase().indexOf("contain") !== -1 ||
                      hoverTagText.toLowerCase().indexOf("possible") !== -1) &&
                    value?.tags?.["image"]?.includes(hoverTagText)
                  }
                  borderColor={
                    hoverTagText !== "" &&
                    hoverTagText.toLowerCase().indexOf("contain") !== -1
                      ? "red6"
                      : hoverTagText !== "" &&
                        hoverTagText.toLowerCase().indexOf("possible") !== -1
                      ? "red3"
                      : ""
                  }
                />

                <Typography size={12} color="black" lineHeight={0} mt={10}>
                  {moment.utc(value?.length * 1000).format("mm:ss")}
                </Typography>
              </Col>
            </a>
          ))}
        </Row>
        <Typography size={14} color="black" mt={10}>
          Average video length:{" "}
          {numeral(meanBy(analysedPosts, p => p.length)).format("0,0.00")}{" "}
          Seconds
        </Typography>
      </Box>
    </Box>
  );
};

export default AnalysedPosts;
