import React, { Component } from "react";
import { Spin } from "antd";
import { Select } from "formik-antd";

export default class SearchSelect extends Component {
  state = {
    data: [],
    fetching: false,
  };

  componentDidMount() {
    const { local } = this.props;
    !local && this.fetchSearch();
  }

  fetchSearch = value => {
    const { loadData, optionGenerator } = this.props;

    this.setState({ fetching: true });

    loadData(value)
      .then(data => {
        return data.map(x => optionGenerator(x));
      })
      .then(data => this.setState({ data, fetching: false }));
  };

  render() {
    const { local } = this.props;
    const { fetching, data } = this.state;

    const searchProps = local
      ? {
          filterOption: (input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >=
            0,
        }
      : {
          filterOption: false,
          onSearch: this.fetchSearch,
          notFoundContent: fetching ? <Spin size="small" /> : null,
        };

    let renderedData = [];
    if (data && data.length > 0) {
      // if we are using load data , then it will go here
      renderedData = data || [];
    } else {
      renderedData = this.props.data || [];
    }

    return (
      <Select
        {...searchProps}
        {...this.props}
        showSearch
        options={renderedData}
        style={{width: "100%"}}
      ></Select>
    );
  }
}
