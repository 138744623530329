// @ts-nocheck
import styled from "styled-components";
import { Layout, Radio } from "antd";
const { Content, Sider } = Layout;

export const SidebarBox = styled(Sider)`
  && {
    background: #fff;
    overflow: auto;
    min-height: 100vh;
    position: relative;
    border-radius: 6px;
  }
`;

export const ContentBox = styled(Content)`
  && {
    margin-left: 15px;
    position: relative;
    border-radius: 6px;
  }
`;

export const AmountRadio = styled(Radio.Group)`
  &&& {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${props => props.theme.colors.blue} !important;
      color: ${props => props.theme.colors.white} !important;
    }
    .ant-radio-button-wrapper {
      height: 30px !important;
      padding: 0 7px !important;
    }
  }
`;
