// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { DeleteOutlined } from '@ant-design/icons';
import { Empty, Popconfirm } from "antd";
import numeral from "numeral";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AxisDomain,
  ReferenceLine,
  Label,
  ResponsiveContainer,
} from "recharts";
import ReferenceLineLabel from "./ReferenceLineLabel";
import styled from "styled-components";
import capitalize from 'lodash/capitalize'

const EllipsisText = styled("span")`
  && {
    white-space: nowrap;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: semi-bold;
    color: black;
  }
`;

interface Props {
  data: Array<any>;
  title: string;
  graph: string;
  lastUpdatedDate: string;
  YDomain?: [AxisDomain, AxisDomain];
  onClickYesRemoveButton: Function;
  isEditTrack: boolean;
  isPostUrl: boolean;
}

const LineChartGraphCard: React.FC<Props> = ({
  title,
  graph,
  lastUpdatedDate,
  data,
  YDomain,
  onClickYesRemoveButton,
  isEditTrack,
  isPostUrl,
}) => {
  let newFilterData = data.filter(x => x && x[graph] !== 0);

  let referenceLineData = newFilterData.filter(
    x => x?.posted && (x?.accounts || []).length > 0
  );

  return (
    <Box
      background="#FFFFFF"
      borderRadius="2px"
      border="1px solid #E5E5E5"
      mb="5px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottom="1px solid #E5E5E5"
        p={10}
      >
        <Box display="flex">
          <Box display="inline-flex" mt="4px">
            {isPostUrl ? (
              <EllipsisText>{title}</EllipsisText>
            ) : (
              <Typography size={14} weight="semi-bold" color="black" mb={0}>
                {title}
              </Typography>
            )}

            <Typography size={14} weight="semi-bold" color="grey6" mb={0}>
              {lastUpdatedDate}
            </Typography>
          </Box>
        </Box>

        {isEditTrack && (
          <Popconfirm
            data-cy="status-container"
            title="Are you want to stop tracking this sound?"
            trigger="click"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onClickYesRemoveButton()}
            onCancel={(e: any) => e.preventDefault()}
          >
            <DeleteOutlined style={{ color: "#FF4D4F", fontSize: "14px", marginTop: "5px" }} />
          </Popconfirm>
        )}
      </Box>

      <Box p={10}>
        {newFilterData?.length > 0 ? (
          <ResponsiveContainer height={350}>
            <LineChart
              width={780}
              height={350}
              data={newFilterData}
              margin={{
                top: 5,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="key"
                stroke="#98A1B5"
                axisLine={false}
                style={{
                  fontSize: "12px",
                  fontWeight: "semi-bold",
                }}
                tickFormatter={x => moment(x).format("DD/MM")}
              >
                <Label
                  value="Date"
                  position="center"
                  dy={30}
                  style={{ fontWeight: "bold", fill: "rgba(140, 140, 140, 1)" }}
                />
              </XAxis>
              <YAxis
                domain={YDomain}
                axisLine={false}
                tickFormatter={x => numeral(x).format("0a")}
                style={{
                  fontSize: "12px",
                }}
              >
                <Label
                  value={capitalize(graph)}
                  position="insideLeft"
                  angle={-90}
                  dy={50}
                  dx={5}
                  style={{ fontWeight: "bold", fill: "rgba(140, 140, 140, 1)" }}
                />
              </YAxis>

              <Tooltip
                labelFormatter={t => moment(t).format("DD/MM")}
                formatter={value => numeral(value).format("0,0")}
              />

              {referenceLineData.map((value, i) => {
                return (
                  <ReferenceLine
                    key={i}
                    x={value?.key}
                    stroke="blue"
                    label={
                      <ReferenceLineLabel
                        influencers={value?.accounts || []}
                        labelType={i % 2 === 0 ? "even" : "odd"}
                        isFirst={i === 0}
                      />
                    }
                  />
                );
              })}

              <Line
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                connectNulls={true}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Box p={85}>
            <Empty description="No data avaialble" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LineChartGraphCard;
