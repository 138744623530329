// @ts-nocheck
import React from "react";
import { FormikInput } from "src/components/Forms/TextInput";
import { Field } from "src/components/Forms/Field";
import { RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Title: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (formProps?.values?.title) {
    isVisible = true;
  }

  return (
    <Field
      label="Campaign Title:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("title", "");
              clearFilterKey("title");
            }}
            style={{ marginTop: "-1px", marginLeft: "15px" }}
          />
        )
      }
      id="title"
    >
      <FormikInput
        size="middle"
        borderRadius={3}
        name="title"
        placeholder="Search campaign title"
      />
    </Field>
  );
};

export default Title;
