// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { FormikRatingInput } from "src/components/Forms/Rating";
import { Box } from "src/components/Core/Box";

interface Props {}

const ContentQuality: React.FC<Props> = () => {
  return (
    <Box mb={26}>
      <Typography size={20} fontWeight="semi-bold" color="black" lineHeight={0}>
        Content quality
      </Typography>
      <Typography size={14} color="grey7">
        How good was the content delivered
      </Typography>
      <FormikRatingInput name="contentQuality" size={24} />
    </Box>
  );
};

export default ContentQuality;
