import React, { Component } from "react"
import FormField from "../FormField"
import { Select } from "formik-antd"
import { statuses } from "src/constants/account"

export default class Gender extends Component {
  render() {
    return (
      <FormField name={this.props.name || "status"} label="Status">
        <Select size="large" data-cy="account-status" placeholder="Select..." options={statuses}>
        </Select>
      </FormField>
    )
  }
}
