import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import Container from "src/shared/Container";
import { default as SharedPoastedContent } from "src/shared/Analytics/PostedContent";
import CampaignManage from "src/ducks/CampaignManage";

export class PostedContent extends Component {
  componentDidMount() {
    const { campaign, loadAnalytics } = this.props;
    loadAnalytics(campaign.id);
  }

  render() {
    const { analytics, analyticsLoading, campaign } = this.props;

    return (
      <Spin spinning={analyticsLoading}>
        <Container style={{ paddingBottom: "30px" }}>
          <SharedPoastedContent
            analytics={analytics}
            campaign={campaign}
            style={{ marginBottom: 24, marginTop: 24 }}
          />
        </Container>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
  analytics: CampaignManage.analytic.selectOne(state),
  analyticsLoading: CampaignManage.analytic.selectGetPending(state),
});

const mapDispatchToProps = {
  loadAnalytics: CampaignManage.analytic.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostedContent);
