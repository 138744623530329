// @ts-nocheck
import styled from "styled-components";
import { Radio } from "antd";
import { Icon } from '@ant-design/compatible'

export const AmountRadio = styled(Radio.Group)`
  &&& {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${props => props.theme.colors.blue} !important;
      color: ${props => props.theme.colors.white} !important;
    }
    .ant-radio-button-wrapper {
      height: 30px !important;
      padding: 0 7px !important;
    }
  }
`;

export const CheckboxStyle = {
  display: "flex",
  color: "#595959",
  fontSize: "14px",
  marginLeft: "0px",
  width: "98px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: 300,
};

export const PlusIcon = styled(Icon)`
  &&& {
    background: #e6f7ff;
    color: #1890ff;
    border-radius: 50%;
  }
`;

export const RemoveIcon = styled(Icon)`
  &&& {
    background: #fff1f0;
    color: #f5222d;
    border-radius: 50%;
    margin-top: 8px;
    margin-left: -15px;
  }
`;
