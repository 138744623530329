// @ts-nocheck
import React from "react";
import { Checkbox } from "antd";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";

interface Props {
  formProps?: FormikProps<any>;
}

const CreatePaymentRequest: React.FC<Props> = ({ formProps }) => {
  return (
    <Box display="inline-flex">
      <Checkbox
        name="dontCreatePaymentRequest"
        onChange={() => {
          formProps?.setFieldTouched("dontCreatePaymentRequest");
          formProps?.setFieldValue(
            "dontCreatePaymentRequest",
            !formProps?.values.dontCreatePaymentRequest
          );
        }}
        value={formProps?.values.dontCreatePaymentRequest}
        data-cy="checkbox-dontCreatePaymentRequest"
      />
      <Typography size={12} color="grey9" ml={10} mt={0.9}>
        Do not create payment request
      </Typography>
    </Box>
  );
};

export default CreatePaymentRequest;
