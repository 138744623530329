// @ts-nocheck
import React, { Fragment } from "react";
import { FormikSelect } from "src/components/Forms/Select";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { RemoveIcon } from "../../../Wrapper";
import { Row } from 'antd';
import { FormikProps } from "formik";

interface Props {
  name: string
  label: string
  lists: [any]
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const AdminList: React.FC<Props> = ({name, label, lists, formProps, clearFilterKey}) => {

  let listData = [] as any;
  let isVisible = false

  if(formProps?.values[name])
    isVisible = true

  listData =
    (lists || [])
      .map(item => {
        let container = {} as any;
        container.value = item?.id;
        container.label = item?.listName;
        return container;
      }) || [];

  return (
    <Fragment>
      <Box mt={25}>
        <Row>
          <Typography
            size={16}
            weight="semi-bold"
            color="black"
            lineHeight={0}
          >
            {label}
          </Typography>
          {isVisible && (
            <RemoveIcon
              type="close-circle"
              onClick={() => {
                formProps?.setFieldValue(name, "");
                clearFilterKey(name);
              }}
              style={{ marginTop: "-5px", marginLeft: "15px" }}
            />
          )}
        </Row>
      </Box>
      <Box mb={20} id={name}>
        <FormikSelect
          name={name}
          data={listData}
          local
          borderRadius={3}
          borderColor="grey5"
          size="middle"
        />
      </Box>
    </Fragment>
  );
};

export default AdminList;
