// @ts-nocheck
import React from "react";
import { AdminInviteAccountListContainer } from "./container";
import Loading from "src/shared/Loading";
import AccountCard from "../../AccountCard";

interface Props {
  loading: boolean;
  adminInviteAccounts: Array<any>;
}

const AdminInviteAccountList: React.FC<Props> = ({
  loading,
  adminInviteAccounts,
}) => {
  return (
    <Loading spinning={loading}>
      {adminInviteAccounts.map((account, index) => (
        <AccountCard
          isSelected={true}
          account={account}
          index={index}
          isAdmin={true}
          key={index}
          moduleKey="confirmed"
        />
      ))}
    </Loading>
  );
};

export { AdminInviteAccountList, AdminInviteAccountListContainer };
