// @ts-nocheck
import styled from "styled-components";
import SearchSelect from "src/shared/SearchSelect";
import { ThemeColors } from "../../../utils/theme";

export interface SelectProps {
  size: "small" | "middle" | "large";
  borderColor?: ThemeColors;
  background?: ThemeColors;
  borderRadius?: any;
  mode?: any;
  placeholder?: string;
  onChange?: any;
  minHeight?: number;
}

const StyledSelect = styled(SearchSelect)<SelectProps>`
  font-size: ${props => props.theme.components.inputs.fontSize[props.size]}px;
  width: 100%;

  .ant-select-arrow {
    font-size: ${props => props.theme.components.inputs.fontSize[props.size]}px;
  }
  .ant-select-selection {
    border-radius: ${props => props.borderRadius} !important;
    min-height: ${props =>
      props.minHeight || props.theme.components.inputs.height[props.size]}px !important;
    background-color: ${props =>
      props.background && props.theme.colors[props.background]} !important;
    border: 1px solid
      ${({ theme, borderColor = "grey5" }) => theme.colors[borderColor]} !important;
  }

  .ant-select-lg .ant-select-selection__rendered,
  .ant-select-search--inline,
  .ant-select-selection-selected-value {
    line-height: ${props =>
      props.size &&
      props.theme.components.inputs.lineHeights[props.size]}px !important;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    line-height: ${props =>
      props.size &&
      props.theme.components.inputs.lineHeights[props.size]}px !important;
  }
  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    min-height: ${props =>
      props.theme.components.inputs.height[props.size] - 7}px !important;
  }

  .ant-select-selection__placeholder {
    display: ${props => (!props.value ? "block" : "none")} !important;
    margin-top: -5px;
  }
`;

export default StyledSelect;
