import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class InstagramProfile extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "metadata.instagram_profile_url"}
        label="Instagram Profile Url"
      >
        <Input
          data-cy="account-instagram-url"
          size="large"
          placeholder="e.g. https://www.instagram.com/theres_only_one_hannah/?hl=en"
        />
      </FormField>
    )
  }
}
