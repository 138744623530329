// @ts-nocheck
import React from "react";
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button } from "antd";
import { Box } from "src/components/Core/Box";
import { BreakdownType } from "src/generated/ApolloComponents";

interface Props {
  matchType: string;
  handleChange: (key: string, value: any) => void;
}

const MatchType: React.FC<Props> = ({ matchType, handleChange }) => {
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => handleChange("matchType", BreakdownType.Location)}
      >
        {BreakdownType.Location}
      </Menu.Item>
      <Menu.Item onClick={() => handleChange("matchType", BreakdownType.Age)}>
        {BreakdownType.Age}
      </Menu.Item>

      <Menu.Item
        onClick={() => handleChange("matchType", BreakdownType.Gender)}
      >
        {BreakdownType.Gender}
      </Menu.Item>
    </Menu>
  );

  return (
    <Box color="grey6">
      <Dropdown overlay={menu}>
        <Button>
          {matchType} <DownOutlined />
        </Button>
      </Dropdown>
    </Box>
  );
};

export default MatchType;
