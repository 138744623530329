import React, { Component } from "react";
import { connect } from "react-redux";
import FormField from "../FormField";
import { connect as FormikConnect } from "formik";
import SearchSelect from "src/shared/SearchSelect";
import { BrandList as $BrandList } from "src/ducks/DataList";

const BrandList = $BrandList("AccountBrandList");

export class CampaignAccess extends Component {
  render() {
    const { loadBrands } = this.props;
    return (
      <FormField
        name={this.props.extra_brand_ids || "extra_brand_ids"}
        label="Who has access to this campaign?"
      >
        <SearchSelect
          data-cy="campaign-access"
          loadData={value =>
            loadBrands({ name: value }).then(res => res.value.data.data)
          }
          optionGenerator={value => ({
            value: value.id,
            label: value.name,
          })}
          size="large"
          placeholder="Select..."
          mode="multiple"
        />
      </FormField>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loadBrands: BrandList.loadMany,
};

export default FormikConnect(
  connect(mapStateToProps, mapDispatchToProps)(CampaignAccess)
);
