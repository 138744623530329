// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { Badge } from "src/components/Core/Badge";
import { Popover } from "antd";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import numeral from "numeral";
import moment from "moment";
import { currency as allCuurency } from "src/constants/payments";
import formatCurrency from "src/utils/formatCurrency";

export default [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 60,
    render: (text, row) => (
      <Typography size={12} color="black3" cursor="pointer">
        {row?.id}
      </Typography>
    ),
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    key: "campaign",
    width: 150,
    render: (text, row) => (
      <>
        {row?.campaign ? (
          <Popover content={row?.campaign ? row?.campaign?.title : ""}>
            <Typography size={12} cursor="pointer">
              {row?.campaign ? row?.campaign?.title : ""}
            </Typography>
          </Popover>
        ) : (
          "-"
        )}
      </>
    ),
  },
  {
    title: "Influencer",
    dataIndex: "influencer",
    key: "influencer",
    width: 150,
    render: (text, row) => (
      <>
        {row?.influencer ? (
          <Typography size={12} cursor="pointer">
            {row?.influencer ? row?.influencer?.name : ""}
          </Typography>
        ) : (
          "-"
        )}
      </>
    ),
  },
  {
    title: "Requested by",
    dataIndex: "requestedBy",
    key: "requestedBy",
    width: 120,
    render: (text, row) => (
      <Typography size={12} color="black3">
        {row?.requestedBy ? row?.requestedBy?.name : ""}
      </Typography>
    ),
  },
  {
    title: "Cost Type",
    dataIndex: "description",
    key: "description",
    width: 120,
    render: (text, row) => (
      <Typography size={12} color="black3" cursor="pointer">
        {row?.description ? row?.description : ""}
      </Typography>
    ),
  },
  {
    title: "Requested date",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 130,
    render: (text, row) => (
      <Typography size={12} color="black3" cursor="pointer">
        {row?.createdAt ? moment(row?.createdAt).format("Do MMM YYYY") : ""}
      </Typography>
    ),
  },

  {
    title: "Total amount",
    dataIndex: "amount",
    key: "amount",
    width: 110,
    render: (text, row) => {
      let currencySign = find(
        allCuurency,
        matchesProperty("value", row?.currency)
      );

      return (
        <Typography size={12} color="black3" cursor="pointer">
          {currencySign?.label}
          {row?.amount ? numeral(row?.amount / 100).format("0,0[.]00") : ""}
        </Typography>
      );
    },
  },

  {
    title: "Amount excl. VAT",
    dataIndex: "payoutPenniesExclVat",
    key: "payoutPenniesExclVat",
    width: 110,
    render: (text, row) => {
      return (
        <Typography size={12} color="black3" cursor="pointer">
          {formatCurrency(row?.payoutPenniesExclVat)}
        </Typography>
      );
    },
  },

  {
    title: "VAT paid",
    dataIndex: "vatAmount",
    key: "vatAmount",
    width: 110,
    render: (text, row) => {
      return (
        <Typography size={12} color="black3" cursor="pointer">
          {formatCurrency(row?.vatAmount)}
        </Typography>
      );
    },
  },

  // {
  //   title: "Papal Tran. Id",
  //   dataIndex: "paypalTransactionId",
  //   key: "paypalTransactionId",
  //   width: 150,
  //   render: (text, row) => {
  //     return (
  //       <Typography size={12} color="black3" cursor="pointer">
  //         {row?.paymentDetail?.paypalTransactionId
  //           ? row?.paymentDetail?.paypalTransactionId
  //           : "-"}
  //       </Typography>
  //     );
  //   },
  // },

  {
    title: "",
    dataIndex: "status",
    key: "status",
    width: 150,
    render: (text, row) => (
      <Box pr="10px">
        {row?.status === "pending" ? (
          <Badge
            color="white"
            background="blue4"
            height={25}
            width={78}
            size={12}
          >
            Pending
          </Badge>
        ) : row?.status === "rejected" || row?.status === "failed" ? (
          <Badge
            color="white"
            background="pitch"
            height={25}
            width={78}
            size={12}
          >
            {row?.status === "rejected" ? "Rejected" : "Failed"}
          </Badge>
        ) : row?.status === "approved" ? (
          <Badge
            color="white"
            background="lime"
            height={25}
            width={78}
            size={12}
          >
            Approved
          </Badge>
        ) : (
          ""
        )}
      </Box>
    ),
  },
];
