// @ts-nocheck
import React from "react"
import { CreateGiftingCampaignModal } from "."
import { object, string } from "yup"
import { useCreateGiftingCampaignMutation } from "src/generated/ApolloComponents"
import { message } from "antd"
import { withRouter, RouteComponentProps } from "react-router"

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
}

const Container = ({ history, visible, setVisible }: RouteComponentProps & Props) => {
  const [createCampaign] = useCreateGiftingCampaignMutation()

  const CreateCampaignSchema = object().shape({
    title: string().required("You must complete this field")
  })

  const onSubmit = async (values) => {
    try {
      const { data } = await createCampaign({ variables: { title: values.title } })
      history.push("/gifting/" + data?.createGiftingCampaign.id)
    } catch {
      message.error("There was a problem creating your campaign")
    }
  }

  return (
    <CreateGiftingCampaignModal
      onSubmit={onSubmit}
      initialValues={{}}
      visible={visible}
      setVisible={setVisible}
      schema={CreateCampaignSchema}
    />
  )
}

export const CreateGiftingCampaignModalContainer = withRouter(Container)
