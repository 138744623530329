// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikCurrencyInput } from "src/components/Forms/CurrencyInput";

interface Props {}

const Cpm: React.FC<Props> = () => {
  return (
    <Field label="Cpm Payout:" mb={26}>
      <FormikCurrencyInput
        name="cpm"
        size="middle"
        borderRadius={2}
        borderColor="grey5"
        prefix="£"
        shouldShowError={true}
      />
    </Field>
  );
};

export default Cpm;
