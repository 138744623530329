// @ts-nocheck
import React, { useState, useEffect } from "react";
import { TestLink } from ".";
import isEmpty from "lodash/isEmpty";
import { useGetTestLinkByCamapignIdQuery } from "src/generated/ApolloComponents";

interface Props {
  campaignId: any;
}

export const TestLinkContainer: React.FC<Props> = ({ campaignId }) => {
  const [showTestLinkModal, setShowTestLinkModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data, loading } = useGetTestLinkByCamapignIdQuery({
    variables: {
      campaignId: parseInt(campaignId),
    },
    pollInterval: showTestLinkModal ? 1000 : 0,
  });

  let testLinkData = data?.getTestLinkByCamapignId || ({} as any);

  useEffect(() => {
    if (
      !testLinkData?.clicks &&
      !testLinkData?.installs &&
      showTestLinkModal &&
      !isLoading
    ) {
      setIsLoading(true);
    }
    if (
      ((testLinkData?.clicks || testLinkData?.installs) && showTestLinkModal) ||
      (!showTestLinkModal && isLoading)
    ) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testLinkData, showTestLinkModal]);

  if (!loading && (isEmpty(testLinkData) || testLinkData?.snaplink === ""))
    return null;

  return (
    <TestLink
      loading={isLoading}
      data={testLinkData}
      showTestLinkModal={showTestLinkModal}
      setShowTestLinkModal={value => setShowTestLinkModal(value)}
    />
  );
};
