import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Container from "src/shared/Container";
import { List, Alert } from "antd";
import CampaignManage from "src/ducks/CampaignManage";
import ApproveContentCard from "./ApproveContentCard";

const ApproveWrapper = styled("div")`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const FixedList = styled(List)`
  &&& {
    .ant-row {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

export class ApproveContent extends Component {
  render() {
    const {
      loadMoreInvitations,
      invitations,
      invitationsLoading,
      invitationsPagination: { current_page, total_entries, per_page },
    } = this.props;

    return (
      <ApproveWrapper>
        <Container>
          <Alert
            style={{ marginBottom: "24px" }}
            message="Approve Content"
            description="Here you can see all the content being generated for your campaign. You can either approve or request a redraft."
            type="info"
          />
          <FixedList
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            pagination={{
              onChange: page => loadMoreInvitations(page),
              current: current_page || null,
              total: total_entries || null,
              pageSize: per_page || null,
            }}
            loading={invitationsLoading}
            dataSource={invitations}
            renderItem={item => (
              <ApproveContentCard
                item={item}
                loadMoreInvitations={loadMoreInvitations}
              />
            )}
          />
        </Container>
      </ApproveWrapper>
    );
  }
}

const mapStateToProps = state => ({
  invitations: CampaignManage.drafts.selectAll(state),
  invitationsLoading: CampaignManage.drafts.selectPending(state),
  invitationsPagination: CampaignManage.drafts.selectPagination(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveContent);
