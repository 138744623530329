// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { CampaignsList, FilterProps } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetAllGroupedCampaignsQuery,
  useDeleteDraftCampaignMutation,
} from "src/generated/ApolloComponents";
import debounce from "lodash/debounce";

type Props = {};

export const CampaignsListContainer: React.FC<Props> = () => {
  const [filters, setFilters] = useState({
    page: 1,
  } as FilterProps);

  const [
    deleteDraftCampaignMutation,
    deleteDraft,
  ] = useDeleteDraftCampaignMutation();

  const { data, loading, error, refetch } = useGetAllGroupedCampaignsQuery({
    variables: filters,
    fetchPolicy: "network-only",
  });

  const allCampaigns = data?.getAllGroupedCampaigns;

  const onFilterChange = async filters => {
    try {
      refetch(filters);
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onFilterChange, 1000)).current;

  useEffect(() => {
    let updatedFilters = { ...filters };

    debouncedFilterChange(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const globalErrors = useMapGraphQLErrorsMessages(error || deleteDraft?.error);

  const onDelete = async campaignId => {
    try {
      const res = await deleteDraftCampaignMutation({
        variables: {
          campaignId: parseInt(campaignId),
        },
      });
      if (res.data?.deleteDraftCampaign) {
        refetch(filters);
      }
    } catch (e) {}
  };

  return (
    <CampaignsList
      allCampaigns={allCampaigns}
      globalErrors={globalErrors}
      loading={loading || deleteDraft?.loading}
      onFilter={(key, value) => {
        const newFilters = { ...filters };
        if (key === "status") {
          newFilters[key] = [value];
        } else {
          newFilters[key] = value;
        }

        setFilters({ ...newFilters });
      }}
      filters={filters}
      onDelete={campaignId => onDelete(campaignId)}
    />
  );
};
