// @ts-nocheck
import React from 'react'
import { Row, Col } from 'antd'
import Account from '../Button'
import styled from 'styled-components'
import { Box } from 'src/components/Core/Box'
import Button from 'src/components/Core/Button'
import { Typography } from 'src/components/Core/Typography'
import HomeButton from '../../HomeButton'
import { useHistory } from 'react-router'

interface Props {
  handleSelect: Function
}

const ConnectButtons = ({handleSelect}: Props) => {
  const StyledRow = styled(Row)`
    width: 105%;
  `
  const history = useHistory()

  return(
    <Box>
      <Typography fontSize={16} color="black2" mt={40} mb={0}>Select an account to connect:</Typography>
      <StyledRow justify="space-between">
        {['TikTok', 'Instagram', 'YouTube'].map(platform =>
          <Account
            key={platform}
            text={`Connect ${platform} account`}
            handleClick={() => handleSelect(platform)}
          />
        )}
      </StyledRow>
      <Row style={{ marginTop: '4px' }}>
        {window.innerWidth < 768 &&
          <Col style={{marginRight: '15px'}}>
            <HomeButton top={0} left={0} position="inherit" />
          </Col>
        }
        <Col>
          <Button type="action" buttonSize="middle" onClick={() => history.push('/preferences')}>
            <Typography fontSize={18} mb={0} cursor="pointer">Continue</Typography>
          </Button>
        </Col>
      </Row>
    </Box>
  )
}

export default ConnectButtons
