import React, { Component } from "react";
import { connect } from "react-redux";
import { Collapse } from "antd";
import { Form } from "formik-antd";
import { connectModal } from "redux-modal";
import ModalForm from "src/shared/ModalForm";
import withModules from "src/utils/hoc/withModules";
import { AccountPersist, InfluencerPersist } from "src/ducks/Persist";
import parseDotNotation from "src/utils/parseDotNotation";
import Validate from "./Validate";
import {
  Username,
  Influencer,
  Platform,
  Status,
  Format,
  Views,
  Screenshots,
  Cpm,
  Payout,
  Active,
  Tags,
  SalesAppropriate,
} from "src/shared/AccountFields";

const AccountCreate = AccountPersist("CreateAccountModal");
const InfluencerCreate = InfluencerPersist("CreateAccountModal");

const modules = [AccountCreate.module(), InfluencerCreate.module()];

class CreateAccountModal extends Component {
  createAccount = (values, formik) => {
    const { create, createInfluencer } = this.props;
    const { setErrors, setSubmitting } = formik;

    if (values.createInfluencer) {
      return createInfluencer(values.create_influencer)
        .then(res =>
          create({
            ...values,
            influencer: res.value.data.id,
          })
        )
        .catch(err => {
          setSubmitting(false);
          setErrors(parseDotNotation(err.response.data.meta.errors));
          return Promise.reject();
        });
    } else {
      return create(values);
    }
  };

  render() {
    const {
      show,
      handleHide,
      onCreate,
      initialValues,
      hideInfluencer,
    } = this.props;

    return (
      <ModalForm
        show={show}
        onSave={this.createAccount}
        afterSave={onCreate}
        validate={Validate}
        initialValues={{ active: true, ...initialValues }}
        handleHide={handleHide}
        title="Create new account"
      >
        <Form data-cy="account-create-modal-form">
          <Username />
          <Status />
          <Views />

          {!hideInfluencer && <Influencer withCreate />}

          <Platform />
          <Format />
          <Cpm />
          <Active />
          <SalesAppropriate />

          <Collapse bordered={false}>
            <Collapse.Panel
              style={{ borderBottom: "0px" }}
              header="More fields..."
              key="1"
            >
              <Payout />
              <Tags />
              <Screenshots />
            </Collapse.Panel>
          </Collapse>
        </Form>
      </ModalForm>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  create: AccountCreate.create,
  createInfluencer: InfluencerCreate.create,
};

export default withModules(
  connectModal({
    name: "CreateAccountModal",
    destroyOnHide: true,
  })(connect(mapStateToProps, mapDispatchToProps)(CreateAccountModal))
)(modules);
