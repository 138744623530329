// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikRatingInput } from "src/components/Forms/Rating";

interface Props {}

const Professionalism: React.FC<Props> = () => {
  return (
    <Field label="Professionalism:" mb={26}>
      <FormikRatingInput name="professionalism" size={24} allowHalf />
    </Field>
  );
};

export default Professionalism;
