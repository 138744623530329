// @ts-nocheck
import React from "react";
import { LineOutlined } from '@ant-design/icons';
import { Box } from "src/components/Core/Box";
import SideMenu from "./SideMenu";
import { InfluencerCardContainer } from "../InfluencerCard";
import { AccountsListContainer } from "../Accounts";
import { ExternalInfluencerContainer } from "../ExternalInfluencer";
import { useInfluencerDetails } from "../InfluencerProfileProvider";

const Sidebar = () => {
  const { platformType } = useInfluencerDetails();

  return (
    <Box>
      {platformType !== "fanbytes" ? (
        <ExternalInfluencerContainer />
      ) : (
        <>
          <InfluencerCardContainer />
          <AccountsListContainer />
          <Box textAlign="center" color="grey4" pt={10}>
            <LineOutlined />
          </Box>
          <Box textAlign="center" mt="-15px" color="grey4" pb={10}>
            <LineOutlined />
          </Box>
          <SideMenu />
        </>
      )}
    </Box>
  );
};

export default Sidebar;
