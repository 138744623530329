import React, { Component } from "react"
import styled from "styled-components"
import { Layout } from "antd"
import { Link } from "react-router-dom"
import DocumentTitle from "react-document-title"
import logo from "../../../assets/images/cortex_influencer_logo.png"

const PageTitle = styled("p")`
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
`

const Wrapper = styled(Layout)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FormContainer = styled.div`
  width: 90%;
  max-width: 380px;
`

const Logo = styled("img")`
  width: 150px;
  margin: 0 auto;
  margin-bottom: 40px;
  display: block;
  position: absolute;
  top: 20px;
  left: 20px;
`

export default class LoginLayout extends Component {
  render() {
    const { title } = this.props
    return (
      <DocumentTitle title="Cortex Influencer Campaign Manager">
        <>
          <Link to={"/sign_in"}>
            <Logo src={logo} />
          </Link>
          <Wrapper>
            <FormContainer>
              <PageTitle data-cy={`page-title-${title}`}>{title}</PageTitle>
              {this.props.children}
            </FormContainer>
          </Wrapper>
        </>
      </DocumentTitle>
    )
  }
}
