// @ts-nocheck
import React from "react";
import { FormikInput } from "src/components/Forms/TextInput";
import styled from "styled-components";
import paypal from "src/assets/images/paypal.png";

const PaypalImageDiv = styled("div")`
  && {
    width: 100%;
    max-width: 430px;
    border-radius: 4px 4px 0 0;
    background-color: #1890ff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    margin-top: 10px;
  }
`;

const PaypalImg = styled("img")`
  width: 120px;
  display: block;
  padding: 12px;
`;

interface Props {}

const TikTokUrl: React.FC<Props> = () => {
  return (
    <div>
      <PaypalImageDiv>
        <PaypalImg src={paypal} />
      </PaypalImageDiv>
      <FormikInput
        name="paypalEmail"
        size="middle"
        placeholder="Please enter your Paypal email ID"
        borderColor="grey8"
        borderRadius="0 0 4px 4px"
        style={{ boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.21)" }}
      />
    </div>
  );
};

export default TikTokUrl;
