// @ts-nocheck
import React from "react";
import { ChartCard, MiniBar } from "ant-design-pro/lib/Charts";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip } from "antd";
import numeral from "numeral";
import moment from "moment";

type Props = {
  data: any;
};

const InstallGraph: React.FC<Props> = ({ data }) => {
  if (!data?.installsByHour || !data?.installsByHour.labels) return null;

  const graphData = data?.installsByHour.labels
    .map((label, index) => ({
      moment: moment(label),
      x: moment(label).format("YYYY-MM-DD , ha"),
      y: data?.installsByHour.data[index],
    }))
    .sort((a, b) => {
      if (a.moment < b.moment) {
        return -1;
      }
      if (a.moment > b.moment) {
        return 1;
      }
      return 0;
    });

  return data?.installs > 0 ? (
    <Row>
      <Col span={24} style={{ marginTop: 24 }}>
        <ChartCard
          title="App Installs"
          action={
            <Tooltip
              placement="left"
              title="How many installs has this campaign achieved?"
            >
              <InfoCircleOutlined />
            </Tooltip>
          }
          total={numeral(data?.installs).format("0,0")}
          contentHeight={46}
          style={{ padding: 20 }}
        >
          <MiniBar height={46} data={graphData} />
        </ChartCard>
      </Col>
    </Row>
  ) : null;
};

export default InstallGraph;
