// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { Row, Col, Card, Statistic } from 'antd'
import numeral from 'numeral'
import { Stats as Props } from '../props'
const Wrapper = styled(Row)`
  margin-bottom: 10px;
`;

const Stats = ({
  verifiedViews,
  verifiedInfluencers,
  totalViews,
  totalRecords,
}: Props) => {
  return (
    <Wrapper gutter={16} justify="center" align="top">
      <Col span={4}>
        <Card size="small">
          <Statistic
            title="Total views"
            value={totalViews ? numeral(totalViews).format("0,0") : 0}
            valueStyle={{ fontSize: "14px" }}
          />
        </Card>
      </Col>

      <Col span={4}>
        <Card size="small">
          <Statistic
            title="Verified views"
            value={verifiedViews ? numeral(verifiedViews).format("0,0") : 0}
            valueStyle={{ fontSize: "14px" }}
          />
        </Card>
      </Col>

      <Col span={4}>
        <Card size="small">
          <Statistic
            title="Verified influencers"
            value={
              verifiedInfluencers
                ? numeral(verifiedInfluencers).format("0,0")
                : 0
            }
            valueStyle={{ fontSize: "14px" }}
          />
        </Card>
      </Col>
      <Col span={4}>
        <Card size="small">
          <Statistic
            title="Total records"
            value={totalRecords ? numeral(totalRecords).format("0,0") : 0}
            valueStyle={{ fontSize: "14px" }}
          />
        </Card>
      </Col>
    </Wrapper>
  );
};

export default Stats;
