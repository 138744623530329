import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Alert, Empty } from "antd";
import CampaignReview from "src/shared/CampaignReview";
import { SubmitButton } from "src/shared/CampaignFields";
import ReviewModal from "./ReviewModal";
import { show } from "redux-modal";

const ReivewSubmitButton = styled(SubmitButton)`
  &&& {
    margin-top: 20px;
  }
`;

const ReviewEmpty = styled(Empty)`
  &&& {
    margin-top: 30px;
  }
`;

class Review extends Component {
  submitCampaign = e => {
    const { persistData } = this.props;
    e.preventDefault();
    persistData({ status: "submitted" });
  };

  render() {
    const { campaign, show } = this.props;

    return (
      <Fragment>
        <Alert
          message="Please check all details are correct. Once you continue you will no longer be able to make changes"
          type="info"
          showIcon
        />

        {campaign ? (
          <Fragment>
            <ReivewSubmitButton
              data-cy="review-submit-button"
              style={{ marginBottom: "24px" }}
              type="success"
              onClick={() =>
                show("ReviewModal", {
                  title: "Campaign Creative Review",
                  handleSubmit: this.submitCampaign,
                  campaign: campaign,
                })
              }
              text="Submit campaign for review"
              block
            />
            <CampaignReview campaign={campaign} />
          </Fragment>
        ) : (
          <ReviewEmpty description="No data" />
        )}
        <ReviewModal />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  show,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Review);
