import { Model } from "redux-orm";

class Worker extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const parsedData = {
      ...data
    };

    return this.upsert(parsedData);
  }
}
Worker.modelName = "Worker";

// Declare your related fields.
Worker.fields = {};

export default Worker;
