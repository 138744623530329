// @ts-nocheck
import React, { useState } from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { PaymentRequestFormContainer } from "./PaymentForm";
import { UpdatePaymentDetailFormContainer } from "./UpdatePaymentDetail";
import PaymentThankYou from "./PaymentThankYou";
import { PaymentDetailsProvider } from "./PaymentDetailsProvider";

const PaymentModal = styled(Modal)`
  &&& {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.213287);
    border-radius: 6px;
  }
`;

interface Props {
  showPaymentForm: boolean;
  closePaymentModal: () => void;
}

const NewPaymentModal = ({ closePaymentModal, showPaymentForm }: Props) => {
  const [step, setStep] = useState<number>(1);

  return (
    <PaymentModal
      style={{
        maxWidth: 1094,
      }}
      bodyStyle={{
        padding: "25px 30px",
      }}
      width="100%"
      open={showPaymentForm}
      onCancel={closePaymentModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
    >
      <PaymentDetailsProvider>
        {step === 1 && (
          <PaymentRequestFormContainer
            closePaymentModal={closePaymentModal}
            setStep={value => setStep(value)}
          />
        )}
        {step === 2 && (
          <UpdatePaymentDetailFormContainer setStep={value => setStep(value)} />
        )}
        {step === 3 && (
          <PaymentThankYou
            closePaymentModal={closePaymentModal}
            setStep={value => setStep(value)}
          />
        )}
      </PaymentDetailsProvider>
    </PaymentModal>
  );
};

export default NewPaymentModal;
