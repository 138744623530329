// @ts-nocheck
import React, { useState } from "react";
import { FilterForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { object } from "yup";
import isEqual from "lodash/isEqual";
import { useCampaignsListDetails } from "../../CampaignsListProvider";
import { getInitialValues, getFormvalues } from "./utils";
import { getFilterData } from "../../utils"; // Applied Filter
import { useLocation } from "react-router";
import { GroupedCampaignSortInput } from "src/generated/ApolloComponents";
import qs from "qs";

interface Props {
  closeCampaignFilterModal: () => void
}

export interface FilterFormValues {
  title?: string;
  status?: Array<any>;
  platforms?: Array<any>;
  locations?: Array<any>;
  startedAfter?: Date;
  tags?: Array<any>;
  customTags?: Array<any>;
  adminExecId?: number;
  budget: { start?: number; end?: number };
  costOfSales: { start?: number; end?: number };
  page: number;
  order: GroupedCampaignSortInput;
}

export const FilterFormContainer: React.FC<Props> = props => {
  const location: any = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  // need to convert string and boolean value from query string
  const formatFilters = getFilterData(parsed);
  //const formatFilters = parsed;
  const initialFilters = {
    page: 1,
  };

  const [internalFilters, setInternalFilters] = useState({
    ...formatFilters,
    ...initialFilters,
  });

  const FilterFormSchema = object().shape({});

  const {
    actions: campaignsAction,
    isLoading,
    filters,
    allTags,
    admins,
  } = useCampaignsListDetails();

  let initialValues = getInitialValues(filters);

  const clearAllFilter = () => {
    let container = {} as any;
    container["page"] = 1;
    campaignsAction.setFilters({ ...container });
    setInternalFilters({ ...container });
    campaignsAction.refetchCampaigns({ ...container });
    props.closeCampaignFilterModal();
  };

  const clearFilterKey = key => {
    const newFileter = { ...filters };
    newFileter[key] = null as any;
    campaignsAction.setFilters({ ...newFileter });
    setInternalFilters({ ...newFileter });
    //campaignsAction.refetchCampaigns({ ...newFileter });
  };

  const onSubmit = async (
    values: FilterFormValues,
    actions: FormikActions<FilterFormValues>
  ) => {
    try {
      const filterValue = getFormvalues(values);

      // let newFilter = { ...filters, ...filterValue };

      let newFilter = filterValue;

      campaignsAction.setFilters(filterValue);
      if (!isEqual(newFilter, internalFilters)) {
        campaignsAction.refetchCampaigns(newFilter);
        setInternalFilters(newFilter);
      }
      props?.closeCampaignFilterModal();
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <FilterForm
      initialValues={initialValues}
      FilterSchema={FilterFormSchema}
      isSubmitting={isLoading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      clearAllFilter={() => clearAllFilter()}
      internalFilters={internalFilters}
      clearFilterKey={key => clearFilterKey(key)}
      allTags={allTags}
      admins={admins}
    />
  );
};
