import React, { Component } from "react";
import FormField from "../FormField";
import CurrencyInput from "src/shared/CurrencyInput";

export default class ViewTarget extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "influencer_spend_pennies"}
        label="Invite Limit (Budget)"
        help="The budget the brand will be able to spend on Influencers"
      >
        <CurrencyInput data-cy="view-target" />
      </FormField>
    );
  }
}
