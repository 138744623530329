// @ts-nocheck
import React from "react";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import styled from "styled-components";
import { FilterFormContainer } from "./CampaignFilter";
import { Box } from "src/components/Core/Box";

const FilterCampaignModal = styled(Modal)`
  &&& {
    border-radius: 2px;
    margin-left: 240px;
    margin-top: 45px;
    .ant-modal-close .anticon-close {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
  }
`;

interface Props {
  showFilterCampaignForm: boolean;
  closeCampaignFilterModal: () => void;
}

const CampaignFilterModal = ({
  closeCampaignFilterModal,
  showFilterCampaignForm,
}: Props) => {
  return (
    <FilterCampaignModal
      title=""
      style={{
        maxWidth: 1080,
      }}
      bodyStyle={{
        padding: "15px 20px",
      }}
      width="100%"
      visible={showFilterCampaignForm}
      onCancel={closeCampaignFilterModal}
      footer={null}
      // closeIcon={<Icon type="close" />}
      maskClosable={false}
    >
      <Box
        background="#FFFFFF"
        height={60}
        width={60}
        textAlign="center"
        borderRadius={50}
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
        fontSize={16}
        p={20}
        onClick={() => closeCampaignFilterModal()}
        style={{
          cursor: "pointer",
          zIndex: 99999,
        }}
        mt="-43px"
        ml="-43px"
      >
        <CloseCircleOutlined
          style={{
            fontSize: "20px",
            background: "#E8E8E8",
            borderRadius: "50%",
            color: "#FFFFFF",
          }} />
      </Box>

      <FilterFormContainer
        closeCampaignFilterModal={closeCampaignFilterModal}
      />
    </FilterCampaignModal>
  );
};

export default CampaignFilterModal;
