// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { ChartCard, Pie } from "ant-design-pro/lib/Charts";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip } from "antd";

const StyledPie = styled(Pie)`
  .antd-pro-charts-pie-legend {
    width: 250px;
  }
`;

type Props = {
  data: any;
};

const DevicePie: React.FC<Props> = ({ data }) => {
  if (!data?.platforms || data?.platforms.length === 0) return null;

  const graphData = data?.platforms.map(platform => ({
    x: platform[0],
    y: platform[1],
  }));

  return (
    <Row>
      <Col span={24} style={{ marginTop: 24 }}>
        <ChartCard
          title="Clicks by devices"
          action={
            <Tooltip placement="left" title="Click breakdown by device">
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ padding: 20 }}
        >
          <StyledPie
            hasLegend
            padding={{ top: 20, right: 30, bottom: 20, left: 30 } as any}
            data={graphData}
            height={294}
          />
        </ChartCard>
      </Col>
    </Row>
  );
};

export default DevicePie;
