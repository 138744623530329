// @ts-nocheck
import React, { Component } from "react";
import styled from "styled-components";
import { Box } from "src/components/Core/Box";
import { EditOutlined } from '@ant-design/icons';
import { Col, Row, Progress } from "antd";
import { Typography } from "src/components/Core/Typography";
import { locations } from "src/constants/account";
import idx from "idx";
import values from "lodash/values";
import sum from "lodash/sum";
import has from "lodash/has";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { RatingInput } from "src/components/Forms/Rating";

const countryName = locations.reduce(function(result, item) {
  result[item.value] = item.label;
  return result;
}, {});

const StatBlock = styled(Col)`
  &&& {
    display: block;
    margin-bottom: 15px;
    padding-left: 0px !important;
  }
`;

const StatName = styled(Col)`
  &&& {
    display: block;
    font-size: 12px;
    float: left;
  }
`;

const StatValue = styled(Col)`
  &&& {
    font-weight: 500;
    float: right;
  }
`;

type Props = {
  account: any;
  style: any;
};

export default class AccountDemographic extends Component<Props> {
  render() {
    const { account, ...rest } = this.props;

    const genderBreakdown =
      idx(account, x => x.ref.gender_breakdown.manual) || {};

    const agesBreakdown = idx(account, x => x.ref.age_breakdown.manual) || {};

    let locationBreakdown =
      idx(account, x => x.ref.location_breakdown.manual) || {};

    delete locationBreakdown.other;

    locationBreakdown = Object.keys(locationBreakdown)
      .slice(0, 2)
      .reduce((result, key) => {
        result[key] = locationBreakdown[key];

        return result;
      }, {});

    let locationSum = sum(values(locationBreakdown));

    if (
      locationSum < 100 &&
      !has(locationBreakdown, "other") &&
      Object.keys(locationBreakdown || {}).length !== 0
    ) {
      let otherLocationBreakdownData = { other: 100 - locationSum };

      locationBreakdown = Object.assign(
        locationBreakdown,
        otherLocationBreakdownData
      );
    }

    return (
      <Box background="white" p={30} mb={20}>
        <Typography size={24} weight="semi-bold" color="black" lineHeight={0}>
          Account highlights
        </Typography>
        <Typography size={14} color="black2" mb={20}>
          A snapshot of this accounts most useful information
        </Typography>
        <div {...rest}>
          {(Object.keys(agesBreakdown || {}).length !== 0 ||
            Object.keys(genderBreakdown || {}).length !== 0 ||
            Object.keys(locationBreakdown || {}).length !== 0) && (
            <Box mb={10}>
              <Row gutter={16}>
                {Object.keys(agesBreakdown || {}).length !== 0 && (
                  <Col span={8}>
                    <Typography
                      fontSize={14}
                      fontWeight="semi-bold"
                      color="black2"
                    >
                      Audience age breakdown
                    </Typography>
                    {Object.keys(agesBreakdown)
                      .filter((key, index) => {
                        return idx(agesBreakdown, x => x[key]) !== null;
                      })
                      .map((key, index) => {
                        return idx(agesBreakdown, x => x[key]) !== null ? (
                          <StatBlock key={index}>
                            <StatName>{key}</StatName>
                            <StatValue>
                              {numeral(idx(agesBreakdown, x => x[key])).format(
                                "0,0.0"
                              )}
                              %
                            </StatValue>
                            <Progress
                              size="small"
                              percent={idx(agesBreakdown, x => x[key])}
                              showInfo={false}
                              strokeColor="#474747"
                            />
                          </StatBlock>
                        ) : (
                          ""
                        );
                      })}
                  </Col>
                )}

                {Object.keys(locationBreakdown || {}).length !== 0 && (
                  <Col span={8}>
                    <Typography
                      fontSize={14}
                      fontWeight="semi-bold"
                      color="black2"
                    >
                      Audience location breakdown
                    </Typography>
                    {Object.keys(locationBreakdown)
                      .slice(0, 5)
                      .map((key, index) => {
                        return idx(locationBreakdown, x => x[key]) !== null ? (
                          <StatBlock key={index}>
                            <StatName>{countryName[key]}</StatName>
                            <StatValue>
                              {numeral(
                                idx(locationBreakdown, x => x[key])
                              ).format("0,0.0")}
                              %
                            </StatValue>
                            <Progress
                              size="small"
                              percent={idx(locationBreakdown, x => x[key])}
                              showInfo={false}
                              strokeColor="#474747"
                            />
                          </StatBlock>
                        ) : (
                          ""
                        );
                      })}
                  </Col>
                )}

                {Object.keys(genderBreakdown || {}).length !== 0 && (
                  <Col span={8}>
                    <Typography
                      fontSize={14}
                      fontWeight="semi-bold"
                      color="black2"
                    >
                      Audience gender breakdown
                    </Typography>

                    {Object.keys(genderBreakdown)
                      .filter((key, index) => {
                        return idx(genderBreakdown, x => x[key]) !== null;
                      })
                      .map((key, index) => {
                        return idx(genderBreakdown, x => x[key]) !== null ? (
                          <StatBlock key={index}>
                            <StatName>{key}</StatName>
                            <StatValue>
                              {numeral(
                                idx(genderBreakdown, x => x[key])
                              ).format("0,0.0")}
                              %
                            </StatValue>
                            <Progress
                              size="small"
                              percent={idx(genderBreakdown, x => x[key])}
                              showInfo={false}
                              strokeColor="#474747"
                            />
                          </StatBlock>
                        ) : (
                          ""
                        );
                      })}
                  </Col>
                )}
              </Row>
            </Box>
          )}
        </div>
        <Box mb={15} mt={15}>
          <Row gutter={16}>
            <Col span={6}>
              <Typography
                fontSize={14}
                fontWeight="semi-bold"
                color="black"
                lineHeight={0}
              >
                Creation time
              </Typography>
              <RatingInput
                size={24}
                defaultValue={Math.round(
                  idx(account, x => x.metadata.rating.creation_time)
                )}
                value={Math.round(
                  idx(account, x => x.metadata.rating.creation_time)
                )}
                disabled
                allowHalf
              />
            </Col>
            <Col span={6}>
              <Typography
                fontSize={14}
                fontWeight="semi-bold"
                color="black"
                lineHeight={0}
              >
                Response time
              </Typography>
              <RatingInput
                size={24}
                defaultValue={Math.round(
                  idx(account, x => x.metadata.rating.response_time)
                )}
                value={Math.round(
                  idx(account, x => x.metadata.rating.response_time)
                )}
                disabled
              />
            </Col>
            <Col span={6}>
              <Typography
                fontSize={14}
                fontWeight="semi-bold"
                color="black"
                lineHeight={0}
              >
                Content quality
              </Typography>
              <RatingInput
                size={24}
                defaultValue={Math.round(
                  idx(account, x => x.metadata.rating.content_quality)
                )}
                value={Math.round(
                  idx(account, x => x.metadata.rating.content_quality)
                )}
                disabled
              />
            </Col>
            <Col span={6}>
              <Typography
                fontSize={14}
                fontWeight="semi-bold"
                color="black"
                lineHeight={0}
              >
                Professionalism
              </Typography>
              <RatingInput
                size={24}
                defaultValue={Math.round(
                  idx(account, x => x.metadata.rating.professionalism)
                )}
                value={Math.round(
                  idx(account, x => x.metadata.rating.professionalism)
                )}
                disabled
              />
            </Col>
          </Row>
        </Box>
        <Link to={`/accounts/${account.id}/update#demographic`}>
          <EditOutlined /> Update
        </Link>
      </Box>
    );
  }
}
