import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  Date: any;
};


export type Account = {
  id: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  salesAppropriate?: Maybe<Scalars['Boolean']>;
  payoutPennies?: Maybe<Scalars['Float']>;
  calculatedPayoutPennies?: Maybe<Scalars['Float']>;
  cpm?: Maybe<Scalars['Float']>;
  influencerId?: Maybe<Scalars['Float']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  metadata: MetadataType;
  ageBreakdown: Scalars['JSON'];
  genderBreakdown: Scalars['JSON'];
  locationBreakdown: Scalars['JSON'];
  growthRate?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
  estCostPerPost?: Maybe<Scalars['Float']>;
  actualCostPerPost?: Maybe<Scalars['Float']>;
  lastPaidAmount?: Maybe<Scalars['Float']>;
  lastCampaignDate?: Maybe<Scalars['DateTime']>;
  views?: Maybe<Scalars['Int']>;
  averageRating?: Maybe<Scalars['Float']>;
  previousBrandedContent?: Maybe<Array<Maybe<AccountMediaContent>>>;
  previousContent?: Maybe<Array<Maybe<AccountMediaContent>>>;
  isSuggested?: Maybe<Scalars['Boolean']>;
  applyStatus?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  influencer?: Maybe<User>;
  invitations?: Maybe<Array<Invitation>>;
  paymentRequests?: Maybe<Array<PaymentRequest>>;
  posts?: Maybe<Array<Post>>;
  drafts?: Maybe<Array<Draft>>;
  tags?: Maybe<Array<Tag>>;
  screenshots?: Maybe<Array<Maybe<UploadFile>>>;
  suggestions: Array<Suggestion>;
  publicCampaigns?: Maybe<Array<Campaign>>;
  adminLists?: Maybe<Array<AdminList>>;
};

export type AccountCheck = {
  accountId?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
};

/** The type of the account format */
export enum AccountFormatType {
  Human = 'Human',
  Page = 'Page'
}

export type AccountMediaContent = {
  contentUrl?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
};

export type AccountQueryResult = {
  id?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccountQueryResultType = {
  account?: Maybe<Array<AccountQueryResult>>;
  influencer?: Maybe<Array<AccountQueryResult>>;
  tag?: Maybe<Array<AccountQueryResult>>;
  campaign?: Maybe<Array<AccountQueryResult>>;
};

export type AccountRatingInput = {
  creationTime?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  contentQuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
};

export type AccountRatingType = {
  creationTime?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  contentQuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
};

export type AccountScreenshot = {
  id?: Maybe<Scalars['Int']>;
  fileableType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadType?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  fileableId: Scalars['Float'];
  account?: Maybe<Account>;
};

/** The type of the account status */
export enum AccountStatusType {
  Verified = 'Verified',
  SemiVerified = 'SemiVerified',
  NotVerified = 'NotVerified'
}

/** The type of the account */
export enum AccountType {
  Instagram = 'Instagram',
  YouTube = 'YouTube',
  Snapchat = 'Snapchat',
  TikTok = 'TikTok'
}

export type AdHubAdvertiserType = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AdHubAuthorisedPlatformType = {
  TikTok?: Maybe<Scalars['Boolean']>;
  Facebook?: Maybe<Scalars['Boolean']>;
  Instagram?: Maybe<Scalars['Boolean']>;
};

export type AdHubMediaType = {
  draftId: Scalars['Int'];
  draftVideoUrl?: Maybe<Scalars['String']>;
  accountId: Scalars['Int'];
  accountName: Scalars['String'];
  invitationCost: Scalars['Int'];
};

export type AdHubResultType = {
  mediaList: Array<AdHubMediaType>;
  isAuthorised: AdHubAuthorisedPlatformType;
  advertisers: Array<AdHubAdvertiserType>;
};

export type AdminList = {
  id: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  adminId: Scalars['Float'];
  listName: Scalars['String'];
  admin?: Maybe<User>;
  accounts?: Maybe<Array<Account>>;
};

export type AdminResultType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  admins: Array<User>;
};

export type Agency = {
  id: Scalars['Int'];
  agencyName?: Maybe<Scalars['String']>;
  payoutDetail?: Maybe<PayoutDetailType>;
};

export type AttachAccountToInfluencerDto = {
  accountId?: Maybe<Scalars['String']>;
  influencerId?: Maybe<Scalars['String']>;
};

/** The options of the filtering account breakdown comparison types */
export enum AudienceBreakdownComparisonType {
  GreaterEqualThan = 'GreaterEqualThan',
  SmallerEqualThan = 'SmallerEqualThan'
}

export type AudienceBreakdownSearchInput = {
  comparison?: Maybe<AudienceBreakdownComparisonType>;
  value?: Maybe<Scalars['Float']>;
  key: Scalars['String'];
};

export type BankAccountInput = {
  countryCode?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bsbCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  transitNumber?: Maybe<Scalars['String']>;
  institutionNumber?: Maybe<Scalars['String']>;
};

export type BankAccountType = {
  countryCode?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bsbCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  transitNumber?: Maybe<Scalars['String']>;
  institutionNumber?: Maybe<Scalars['String']>;
};

export type BrandResultType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  brands: Array<User>;
};

export type BrandSafetyAnalysedPostType = {
  coverUrl: Scalars['String'];
  videoUrl: Scalars['String'];
  length: Scalars['Float'];
  tags?: Maybe<BrandSafetyTagType>;
};

export type BrandSafetyKeywordType = {
  word: Scalars['String'];
  count: Scalars['Float'];
};

export type BrandSafetyMentionedAccountType = {
  username: Scalars['String'];
  accountUrl: Scalars['String'];
  count: Scalars['Float'];
};

export type BrandSafetyMetrics = {
  weaponSafety: Scalars['Float'];
  alcoholSafety: Scalars['Float'];
  drugsSafety: Scalars['Float'];
  nuditySafety: Scalars['Float'];
  rawNuditySafety: Scalars['Float'];
  partialNuditySafety: Scalars['Float'];
  offensiveSafety: Scalars['Float'];
};

export type BrandSafetyTagType = {
  image?: Maybe<Array<Scalars['String']>>;
  text?: Maybe<Array<Scalars['String']>>;
  video?: Maybe<Array<Scalars['String']>>;
};

/** The type of the breakdown */
export enum BreakdownType {
  Age = 'Age',
  Gender = 'Gender',
  Location = 'Location'
}

export type Campaign = {
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  adminId?: Maybe<Scalars['Int']>;
  executiveId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  campaignGroupId?: Maybe<Scalars['Int']>;
  creativeId?: Maybe<Scalars['Int']>;
  budgetPennies?: Maybe<Scalars['Int']>;
  cpiPayoutPennies?: Maybe<Scalars['Int']>;
  distributionPlatforms?: Maybe<Array<Scalars['String']>>;
  ages?: Maybe<Array<Scalars['String']>>;
  genders?: Maybe<Array<Scalars['String']>>;
  locations?: Maybe<Array<Scalars['String']>>;
  notifiedBrandIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  influencerSpendPennies?: Maybe<Scalars['Int']>;
  influencerInviteLimit?: Maybe<Scalars['Int']>;
  metadata: CampaignMetadataType;
  reportLink?: Maybe<Scalars['String']>;
  reportObjective?: Maybe<Scalars['String']>;
  briefDocLink?: Maybe<Scalars['String']>;
  hubspotDealId?: Maybe<Scalars['String']>;
  additionalTerms?: Maybe<Scalars['String']>;
  extraBrandIds: Array<Maybe<Scalars['Int']>>;
  ctr?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  publicDeadline?: Maybe<Scalars['DateTime']>;
  brief?: Maybe<Scalars['String']>;
  antiBrief?: Maybe<Scalars['String']>;
  briefDo?: Maybe<Array<Scalars['String']>>;
  briefDont?: Maybe<Array<Scalars['String']>>;
  aboutBrand?: Maybe<Scalars['String']>;
  adFormat?: Maybe<Scalars['String']>;
  isApplied?: Maybe<Scalars['Boolean']>;
  views?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invitations?: Maybe<Array<Invitation>>;
  admin?: Maybe<User>;
  executive?: Maybe<User>;
  paymentRequests?: Maybe<Array<PaymentRequest>>;
  suggestions?: Maybe<Array<Maybe<Suggestion>>>;
  company?: Maybe<Company>;
  campaignGroup?: Maybe<CampaignGroup>;
  tags?: Maybe<Array<Tag>>;
  applicants?: Maybe<Array<Account>>;
  expressApplicants?: Maybe<Array<PublicExpressApplicant>>;
  creative?: Maybe<Creative>;
  milestones?: Maybe<Array<CampaignMilestone>>;
};

export type CampaignAnalytic = {
  id: Scalars['Int'];
  status: Scalars['String'];
  views: Scalars['Int'];
  screenshots: Scalars['Int'];
  clicks: Scalars['Int'];
  uniqueClicks: Scalars['Int'];
  installs: Scalars['Int'];
  cities: Array<Scalars['JSON']>;
  countries: Array<Scalars['JSON']>;
  clicksByHour?: Maybe<CampaignAnalyticLabelData>;
  installsByHour?: Maybe<CampaignAnalyticLabelData>;
  platforms: Array<Scalars['JSON']>;
  completionRate?: Maybe<Scalars['Float']>;
  budgetPennies?: Maybe<Scalars['Int']>;
  cpcPennies?: Maybe<Scalars['Int']>;
  cpiPennies?: Maybe<Scalars['Float']>;
  ctr?: Maybe<Scalars['Float']>;
  cpm?: Maybe<Scalars['Int']>;
  metadata: CampaignMetadataType;
  noInfluencersPosted?: Maybe<CampaignAnalyticPostedTotal>;
  clicksToInstalls?: Maybe<Scalars['Float']>;
  postedAccounts?: Maybe<Array<CampaignAnalyticPostedAccount>>;
  invitations: Array<Invitation>;
};

export type CampaignAnalyticLabelData = {
  labels?: Maybe<Array<Scalars['String']>>;
  data?: Maybe<Array<Scalars['Int']>>;
};

export type CampaignAnalyticPostedAccount = {
  id?: Maybe<Scalars['Int']>;
  invitationId?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
  postedDate?: Maybe<Scalars['DateTime']>;
  graphData?: Maybe<Array<CampaignAnalyticsPostGraphType>>;
};

export type CampaignAnalyticPostedTotal = {
  posted?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type CampaignAnalyticsPostGraphType = {
  key: Scalars['String'];
  engagementRate?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
};

export type CampaignAnalyticsTrackingAccountType = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type CampaignAnalyticsTrackingGraphType = {
  key: Scalars['String'];
  views?: Maybe<Scalars['Float']>;
  posts?: Maybe<Scalars['Float']>;
  posted: Scalars['Boolean'];
  accounts?: Maybe<Array<CampaignAnalyticsTrackingAccountType>>;
};

export type CampaignAnalyticsTrackingType = {
  title?: Maybe<Scalars['String']>;
  trackingKey?: Maybe<Scalars['String']>;
  lastUpdatedDate?: Maybe<Scalars['DateTime']>;
  graphData?: Maybe<Array<CampaignAnalyticsTrackingGraphType>>;
};

export type CampaignDetailType = {
  sentInvitations: Scalars['Int'];
  invitedSpendPennies: Scalars['Float'];
  campaign: Campaign;
};

export type CampaignGroup = {
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  groupName: Scalars['String'];
  extraBrandIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<Scalars['String']>;
  objective?: Maybe<Array<Maybe<Scalars['String']>>>;
  budgetPennies?: Maybe<Scalars['Int']>;
  extraBrands?: Maybe<Array<User>>;
  costOfSales?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  company: Company;
  campaigns: Array<Campaign>;
};

export type CampaignGroupResultType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  campaignGroups: Array<CampaignGroup>;
};

export type CampaignMetadataInput = {
  likeCount?: Maybe<Scalars['Int']>;
  commentCount?: Maybe<Scalars['Int']>;
  engagementRate?: Maybe<Scalars['Float']>;
  tracking?: Maybe<Array<CampaignTrackingInput>>;
  rating?: Maybe<CampaignRatingInput>;
  pageTitle?: Maybe<Scalars['String']>;
  creativeLinks?: Maybe<Array<Scalars['String']>>;
};

export type CampaignMetadataType = {
  likeCount?: Maybe<Scalars['Int']>;
  commentCount?: Maybe<Scalars['Int']>;
  engagementRate?: Maybe<Scalars['Float']>;
  tracking?: Maybe<Array<CampaignTrackingType>>;
  rating?: Maybe<CampaignRatingType>;
  pageTitle?: Maybe<Scalars['String']>;
  creativeLinks?: Maybe<Array<Scalars['String']>>;
};

export type CampaignMilestone = {
  id: Scalars['Int'];
  campaignId: Scalars['Int'];
  milestoneName: Scalars['String'];
  metric: CampaignMilestoneMetric;
  goal: Scalars['Float'];
  achieved: Scalars['Boolean'];
  visibility: CampaignMilestoneOptionType;
  notification: CampaignMilestoneOptionType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  achievedAt?: Maybe<Scalars['DateTime']>;
  checkedAt?: Maybe<Scalars['DateTime']>;
  actualValue?: Maybe<Scalars['Float']>;
  campaign?: Maybe<Campaign>;
};

export enum CampaignMilestoneMetric {
  Views = 'views',
  Clicks = 'clicks',
  Likes = 'likes',
  Comments = 'comments',
  Engagement = 'engagement',
  Posts = 'posts'
}

export type CampaignMilestoneOptionInput = {
  admins: Scalars['Boolean'];
  clients: Scalars['Boolean'];
};

export type CampaignMilestoneOptionType = {
  admins: Scalars['Boolean'];
  clients: Scalars['Boolean'];
};

export type CampaignRatingInput = {
  helpfulness?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type CampaignRatingType = {
  helpfulness?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type CampaignReportAccountType = {
  accountId: Scalars['Int'];
  profilePicUrl?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  views?: Maybe<Scalars['Int']>;
  engagementRate?: Maybe<Scalars['Float']>;
  clickThroughtRate?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Tag>>;
};

export type CampaignReportPostType = {
  account: CampaignReportAccountType;
  reach?: Maybe<Scalars['Int']>;
  hearts?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  engagement?: Maybe<Scalars['Float']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type CampaignReportTopCategoryType = {
  type: Scalars['String'];
  data: Array<CampaignReportAccountType>;
};

export type CampaignReportType = {
  companyName: Scalars['String'];
  campaignTitle: Scalars['String'];
  campaignPlatforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  campaignObjective?: Maybe<Scalars['String']>;
  topViews: Array<CampaignReportAccountType>;
  categorisedTopViews: Array<CampaignReportTopCategoryType>;
  categorisedTopEngagements: Array<CampaignReportTopCategoryType>;
  totalWatchedTime: Scalars['Float'];
  topEngagements: Array<CampaignReportAccountType>;
  topClickThroughtRates: Array<CampaignReportAccountType>;
  campaignComments: Scalars['Int'];
  campaignHearts: Scalars['Int'];
  campaignEngagementRate: Scalars['Float'];
  campaignClickThroughtRate: Scalars['Float'];
  mostLikedPost?: Maybe<CampaignReportPostType>;
  views: Scalars['Int'];
};

export type CampaignResultType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  campaigns: Array<Campaign>;
};

export type CampaignTrackingInput = {
  key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type CampaignTrackingType = {
  key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export enum CampaignTrackingTypeEnum {
  Hashtag = 'hashtag',
  Music = 'music'
}

export type CloudinaryUpload = {
  id: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  secureUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  accessMode?: Maybe<Scalars['String']>;
  uploadableType?: Maybe<Scalars['String']>;
  uploadableId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Company = {
  id: Scalars['Int'];
  companyName: Scalars['String'];
  tiktokAds: TikTokAdsType;
  campaignGroups: Array<CampaignGroup>;
  campaigns: Array<Campaign>;
};

export type CompanyResultType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  companies: Array<Company>;
};

export type ComparisonInput = {
  comparison?: Maybe<AudienceBreakdownComparisonType>;
  value?: Maybe<Scalars['Float']>;
};

export type CompletedCampaignsInput = {
  last1Months?: Maybe<Scalars['Float']>;
  last3Months?: Maybe<Scalars['Float']>;
};

/** The options of the completed campaigns months */
export enum CompletedCampaignsMonthsType {
  Ever = 'Ever',
  Last1Month = 'Last1Month',
  Last3Month = 'Last3Month'
}

export type CompletedCampaignsSearchInput = {
  comparison?: Maybe<AudienceBreakdownComparisonType>;
  value?: Maybe<Scalars['Float']>;
  months: CompletedCampaignsMonthsType;
};

export type CompletedCampaignsType = {
  last1Months?: Maybe<Scalars['Float']>;
  last3Months?: Maybe<Scalars['Float']>;
};

export type Creative = {
  id: Scalars['Int'];
  objective?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  videoUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  company?: Maybe<Company>;
  video?: Maybe<CreativeVideo>;
  campaign?: Maybe<Campaign>;
};

export type CreativeVideo = {
  id: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  secureUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  accessMode?: Maybe<Scalars['String']>;
  uploadableType?: Maybe<Scalars['String']>;
  uploadableId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  creative?: Maybe<Array<Creative>>;
};

/** The source platform of the account data */
export enum DataSourcePlatform {
  Bytesights = 'Bytesights',
  Upfluence = 'Upfluence',
  Fanbytes = 'Fanbytes'
}



export type DetailInput = {
  vatNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  managed?: Maybe<Scalars['Boolean']>;
  age?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Array<Scalars['String']>>;
  ageSkewPercentage?: Maybe<Scalars['JSON']>;
  genderSkewPercentage?: Maybe<Scalars['JSON']>;
  countryPercentage?: Maybe<Scalars['JSON']>;
  note?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<NoteInput>>;
  rating?: Maybe<RatingInput>;
  flags?: Maybe<Array<Scalars['String']>>;
  tracking?: Maybe<TrackingInput>;
};

export type DetailType = {
  vatNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  managed?: Maybe<Scalars['Boolean']>;
  age?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Array<Scalars['String']>>;
  ageSkewPercentage?: Maybe<Scalars['JSON']>;
  genderSkewPercentage?: Maybe<Scalars['JSON']>;
  countryPercentage?: Maybe<Scalars['JSON']>;
  note?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<NoteType>>;
  rating?: Maybe<RatingType>;
  flags?: Maybe<Array<Scalars['String']>>;
  tracking?: Maybe<TrackingType>;
};

export type DiscoverBasicType = {
  accountName?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Int']>;
  averageHearts?: Maybe<Scalars['Float']>;
  averageComments?: Maybe<Scalars['Float']>;
  averageShares?: Maybe<Scalars['Float']>;
  averageViews?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
};

export enum DiscoverCategory {
  Students = 'Students',
  Gaming = 'Gaming',
  Sports = 'Sports',
  Fitness = 'Fitness',
  Food = 'Food',
  BusFin = 'BusFin',
  Beauty = 'Beauty'
}

export type DiscoverCategoryResultType = {
  views?: Maybe<Array<Maybe<DiscoverCategoryType>>>;
  engagement?: Maybe<Array<Maybe<DiscoverCategoryType>>>;
  growth?: Maybe<Array<Maybe<DiscoverCategoryType>>>;
};

export type DiscoverCategoryType = {
  accountName?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Int']>;
  averageHearts?: Maybe<Scalars['Float']>;
  averageComments?: Maybe<Scalars['Float']>;
  averageShares?: Maybe<Scalars['Float']>;
  averageViews?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
  bio?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  lastVideoId?: Maybe<Scalars['String']>;
  growthRate?: Maybe<Scalars['Float']>;
  isTracked?: Maybe<Scalars['Boolean']>;
};

export type DiscoverSearchingType = {
  accountName?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Int']>;
  averageHearts?: Maybe<Scalars['Float']>;
  averageComments?: Maybe<Scalars['Float']>;
  averageShares?: Maybe<Scalars['Float']>;
  averageViews?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
};

export type DiscoverTrackingGraphDataType = {
  time?: Maybe<Scalars['DateTime']>;
  averageViews?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
};

export type DiscoverTrackingType = {
  accountName?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Int']>;
  averageHearts?: Maybe<Scalars['Float']>;
  averageComments?: Maybe<Scalars['Float']>;
  averageShares?: Maybe<Scalars['Float']>;
  averageViews?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
  graphData?: Maybe<Array<DiscoverTrackingGraphDataType>>;
};

export type Draft = {
  status?: Maybe<Scalars['String']>;
  draftType?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  allVideos?: Maybe<Array<Maybe<Scalars['String']>>>;
  allMedia?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewAllMedia?: Maybe<Array<Maybe<Scalars['String']>>>;
  videoUrl?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  redraftRequest?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
  submittedToBrand: Scalars['Boolean'];
  brandFeedback?: Maybe<Array<Maybe<Scalars['String']>>>;
  adminFeedback?: Maybe<Array<Maybe<Scalars['String']>>>;
  campaignInvitationId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invitation?: Maybe<Invitation>;
  campaign?: Maybe<Campaign>;
  account?: Maybe<Account>;
  id: Scalars['Int'];
};

export enum DraftSatus {
  Submitted = 'submitted',
  Approved = 'approved',
  Redraft = 'redraft'
}

export type FileInput = {
  uploadUrl: Scalars['String'];
  uploadType: Scalars['String'];
};

export type FilteringAccountResultType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  accounts: Array<Account>;
  totalViews: Scalars['Float'];
  verifiedViews: Scalars['Float'];
  verifiedInfluencers: Scalars['Float'];
  totalRecords: Scalars['Float'];
};

/** The options of the filtering account search types */
export enum FilteringAccountSearchType {
  AccountName = 'AccountName',
  PreviousCaptions = 'PreviousCaptions',
  AccountBio = 'AccountBio'
}

export type FilteringAccountSortInput = {
  username?: Maybe<Scalars['String']>;
  influencerName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  engagementRate?: Maybe<Scalars['String']>;
  lastCampaignDate?: Maybe<Scalars['String']>;
  lastPaidAmount?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['String']>;
};

/** The options of the filtering account status types */
export enum FilteringAccountStatusType {
  Verified = 'Verified',
  Managed = 'Managed',
  Untagged = 'Untagged',
  NoData = 'NoData'
}

export type FullProfileInput = {
  status?: Maybe<Scalars['Boolean']>;
  pastContent?: Maybe<Scalars['Boolean']>;
  recentCampaign?: Maybe<Scalars['Boolean']>;
  followers?: Maybe<Scalars['Boolean']>;
  avgHearts?: Maybe<Scalars['Boolean']>;
  avgComments?: Maybe<Scalars['Boolean']>;
  avgViews?: Maybe<Scalars['Boolean']>;
  ageBreakdown?: Maybe<Scalars['Boolean']>;
  genderBreakdown?: Maybe<Scalars['Boolean']>;
  locationBreakdown?: Maybe<Scalars['Boolean']>;
};

export type FullProfileType = {
  status?: Maybe<Scalars['Boolean']>;
  pastContent?: Maybe<Scalars['Boolean']>;
  recentCampaign?: Maybe<Scalars['Boolean']>;
  followers?: Maybe<Scalars['Boolean']>;
  avgHearts?: Maybe<Scalars['Boolean']>;
  avgComments?: Maybe<Scalars['Boolean']>;
  avgViews?: Maybe<Scalars['Boolean']>;
  ageBreakdown?: Maybe<Scalars['Boolean']>;
  genderBreakdown?: Maybe<Scalars['Boolean']>;
  locationBreakdown?: Maybe<Scalars['Boolean']>;
};

/** The type of the gender */
export enum GenderType {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other'
}

export type GetSignedUrlInput = {
  fileFolder?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileType: Scalars['String'];
};

export type GiftingBrief = {
  aboutBrand?: Maybe<Scalars['String']>;
  linkToWebsite?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  aboutCampaign?: Maybe<Scalars['String']>;
  postingGuidlines?: Maybe<Scalars['String']>;
  campaignType?: Maybe<GiveawayCampaignType>;
  requiredInformation?: Maybe<RequiredInformation>;
  platforms?: Maybe<Platforms>;
  repurposeContent?: Maybe<Scalars['Boolean']>;
};

export type GiftingBriefInput = {
  aboutBrand?: Maybe<Scalars['String']>;
  linkToWebsite?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  aboutCampaign?: Maybe<Scalars['String']>;
  postingGuidlines?: Maybe<Scalars['String']>;
  campaignType?: Maybe<GiveawayCampaignType>;
  requiredInformation?: Maybe<RequiredInformationInput>;
  platforms?: Maybe<PlatformsInput>;
  repurposeContent?: Maybe<Scalars['Boolean']>;
};

export type GiftingCampaign = {
  id: Scalars['Int'];
  title: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  status: GiftingCampaignStatus;
  companyId?: Maybe<Scalars['Float']>;
  brief: GiftingBrief;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum GiftingCampaignStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Active = 'ACTIVE',
  Complete = 'COMPLETE'
}

export enum GiveawayCampaignType {
  Giveaway = 'GIVEAWAY',
  Experience = 'EXPERIENCE',
  Product = 'PRODUCT'
}

export type GroupedCampaignMoneyRangeInput = {
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export enum GroupedCampaignPlatformType {
  Instagram = 'Instagram',
  YouTube = 'YouTube',
  Snapchat = 'Snapchat',
  TikTok = 'TikTok'
}

export type GroupedCampaignResultType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  groupedCampaigns: Array<GroupedCampaignType>;
  campaignNumbers?: Maybe<Scalars['Int']>;
  totalSpent?: Maybe<Scalars['Float']>;
  totalViews?: Maybe<Scalars['Float']>;
};

export type GroupedCampaignSortInput = {
  title?: Maybe<GroupedCampaignSortType>;
  status?: Maybe<GroupedCampaignSortType>;
  startDate?: Maybe<GroupedCampaignSortType>;
  createdAt?: Maybe<GroupedCampaignSortType>;
};

export enum GroupedCampaignSortType {
  Asc = 'asc',
  Desc = 'desc'
}

export enum GroupedCampaignStatusType {
  Complete = 'complete',
  Submitted = 'submitted',
  Active = 'active',
  Draft = 'draft'
}

export type GroupedCampaignType = {
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  budgetCurrency?: Maybe<Scalars['String']>;
  budgetPennies?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  adFormat?: Maybe<Scalars['String']>;
  hubspotDealId?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  views: Scalars['Float'];
  clicks: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  tags?: Maybe<Array<Scalars['Int']>>;
  locations?: Maybe<Array<Scalars['String']>>;
  costOfSales?: Maybe<Scalars['Float']>;
  approvedPayments?: Maybe<Scalars['Int']>;
  totalPayments?: Maybe<Scalars['Int']>;
  isGroup: Scalars['Boolean'];
};

export type HubSpotDealPropertiesType = {
  amount: Scalars['String'];
  closeDate: Scalars['Timestamp'];
  createDate: Scalars['Timestamp'];
  dealName: Scalars['String'];
  dealStage: Scalars['String'];
  hsLastModifiedDate: Scalars['Timestamp'];
  hsObjectId: Scalars['String'];
  pipeline: Scalars['String'];
};

export type HubSpotDealType = {
  dealId: Scalars['String'];
  properties: HubSpotDealPropertiesType;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  archived: Scalars['Boolean'];
};

export type HubSpotDealValidationType = {
  isDealValid: Scalars['Boolean'];
};

export type InfluencerCashoutDetailType = {
  claimCashoutId: Scalars['String'];
  files: Array<FileInput>;
  viewCount: Scalars['Int'];
  tiktokUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  dislikes?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  watchTime?: Maybe<Scalars['Int']>;
};

export type InfluencerCashoutInvitation = {
  id: Scalars['Int'];
  views?: Maybe<Scalars['Int']>;
  installs?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  claimCashoutId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  payoutTypeCurrency?: Maybe<Scalars['String']>;
  payoutTypeOriginalPennies?: Maybe<Scalars['Int']>;
  fixedPayoutPennies?: Maybe<Scalars['Int']>;
  estimatedPayoutPennies?: Maybe<Scalars['Int']>;
  actualPaidPennies?: Maybe<Scalars['Int']>;
  payoutCurrency: Scalars['String'];
  likeUploadDraft?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<Scalars['String']>;
  rationale?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractSigned?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  estimatedViews?: Maybe<Scalars['Int']>;
  payoutType: Scalars['String'];
  cpm?: Maybe<Scalars['Float']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  paymentRequest?: Maybe<PaymentRequest>;
  sentAt?: Maybe<Scalars['DateTime']>;
  linkHub?: Maybe<InivtiationLinkHubType>;
  influencerAccountId?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  campaign: Campaign;
  account: Account;
  screenshots?: Maybe<Array<Maybe<UploadFile>>>;
  drafts?: Maybe<Array<Maybe<Draft>>>;
  suggestions: Array<Suggestion>;
  isAccountScreenshotOutdated: Scalars['Boolean'];
};

export type InfluencerCashoutPaymentDetailType = {
  claimCashoutId: Scalars['String'];
  paypalEmail?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountInput>;
  invoiceScreenshots?: Maybe<Array<Maybe<FileInput>>>;
};

export type InfluencerCashoutProfileDataType = {
  claimCashoutId: Scalars['String'];
  ageScreenshot?: Maybe<FileInput>;
  genderScreenshot?: Maybe<FileInput>;
  locationScreenshot?: Maybe<FileInput>;
  age?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Array<Scalars['String']>>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
  screenshots?: Maybe<Array<FileInput>>;
};

export type InfluencerCashoutRegisterForBetaType = {
  claimCashoutId: Scalars['String'];
};

export type InfluencerFullProfileChecklist = {
  audienceAgeData?: Maybe<Scalars['Boolean']>;
  audienceGenderData?: Maybe<Scalars['Boolean']>;
  audienceLocationData?: Maybe<Scalars['Boolean']>;
  recentCampaign?: Maybe<Scalars['Boolean']>;
  accountRecentCampaign?: Maybe<Array<AccountCheck>>;
  pastContent?: Maybe<Scalars['Boolean']>;
  accountPastContent?: Maybe<Array<AccountCheck>>;
};

export type InfluencerProfile = {
  pictureUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  punctuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
  flags?: Maybe<Array<Scalars['String']>>;
  accounts?: Maybe<Array<Account>>;
};

export type InfluencerProfileAccountBreakdownData = {
  manual?: Maybe<Array<InfluencerProfileAccountGraphData>>;
  live?: Maybe<Array<InfluencerProfileAccountGraphData>>;
};

export type InfluencerProfileAccountDetailType = {
  username: Scalars['String'];
  type: Scalars['String'];
  profileImage?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  isOnCampaign?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Tag>>;
  growthRate?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
  estCostPerPost?: Maybe<Scalars['Float']>;
  actualCostPerPost?: Maybe<Scalars['Float']>;
};

export type InfluencerProfileAccountGraphData = {
  key: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type InfluencerProfileCampaign = {
  id: Scalars['Float'];
  title: Scalars['String'];
  account: Scalars['String'];
  requestDate?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  campaignStatus: Scalars['String'];
  paymentRequestStatus?: Maybe<Scalars['String']>;
};

export type InfluencerProfileNote = {
  title: Scalars['String'];
  body: Scalars['String'];
  createdAt: Scalars['String'];
};

export type InivtiationLinkHubInput = {
  initialContact: Scalars['Boolean'];
  initialContactYouTube: Scalars['Boolean'];
  contract: Scalars['Boolean'];
  inviteFeedback: Scalars['Boolean'];
  inviteFeedbackYouTube: Scalars['Boolean'];
  postDetail: Scalars['Boolean'];
  postDetailYouTube: Scalars['Boolean'];
  paymentDetail: Scalars['Boolean'];
};

export type InivtiationLinkHubStatus = {
  checked?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type InivtiationLinkHubStatusType = {
  initialContact: InivtiationLinkHubStatus;
  initialContactYouTube: InivtiationLinkHubStatus;
  contract: InivtiationLinkHubStatus;
  inviteFeedback: InivtiationLinkHubStatus;
  inviteFeedbackYouTube: InivtiationLinkHubStatus;
  postDetail: InivtiationLinkHubStatus;
  postDetailYouTube: InivtiationLinkHubStatus;
  paymentDetail: InivtiationLinkHubStatus;
};

export type InivtiationLinkHubType = {
  initialContact: Scalars['Boolean'];
  initialContactYouTube: Scalars['Boolean'];
  contract: Scalars['Boolean'];
  inviteFeedback: Scalars['Boolean'];
  inviteFeedbackYouTube: Scalars['Boolean'];
  postDetail: Scalars['Boolean'];
  postDetailYouTube: Scalars['Boolean'];
  paymentDetail: Scalars['Boolean'];
};

export type Invitation = {
  id: Scalars['Int'];
  views?: Maybe<Scalars['Int']>;
  installs?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  claimCashoutId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  payoutTypeCurrency?: Maybe<Scalars['String']>;
  payoutTypeOriginalPennies?: Maybe<Scalars['Int']>;
  fixedPayoutPennies?: Maybe<Scalars['Int']>;
  estimatedPayoutPennies?: Maybe<Scalars['Int']>;
  actualPaidPennies?: Maybe<Scalars['Int']>;
  payoutCurrency: Scalars['String'];
  likeUploadDraft?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<Scalars['String']>;
  rationale?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractSigned?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  estimatedViews?: Maybe<Scalars['Int']>;
  payoutType: Scalars['String'];
  cpm?: Maybe<Scalars['Float']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  paymentRequest?: Maybe<PaymentRequest>;
  sentAt?: Maybe<Scalars['DateTime']>;
  linkHub?: Maybe<InivtiationLinkHubType>;
  influencerAccountId?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  campaign: Campaign;
  account: Account;
  screenshots?: Maybe<Array<Maybe<UploadFile>>>;
  drafts?: Maybe<Array<Maybe<Draft>>>;
  suggestions: Array<Suggestion>;
};

export type InvitationMetric = {
  id: Scalars['Int'];
  campaignId?: Maybe<Scalars['Int']>;
  campaignInvitationId?: Maybe<Scalars['Int']>;
  recordAt?: Maybe<Scalars['DateTime']>;
  views?: Maybe<Scalars['Int']>;
  installs?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  dislikes?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  favorites?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  engagementRate?: Maybe<Scalars['Float']>;
};

export type InvitationScreenshot = {
  id?: Maybe<Scalars['Int']>;
  fileableType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadType?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  fileableId: Scalars['Float'];
  invitation?: Maybe<Invitation>;
};


export type JwtToken = {
  token: Scalars['String'];
};

/** The filter of line charts */
export enum LineChartFilter {
  Week = 'Week',
  Month = 'Month',
  HalfYear = 'HalfYear'
}

export type Link = {
  id?: Maybe<Scalars['Float']>;
  snaplinkPretty?: Maybe<Scalars['String']>;
  snaplink?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Float']>;
  installs?: Maybe<Scalars['Float']>;
  tested?: Maybe<Scalars['Boolean']>;
  campaignId?: Maybe<Scalars['Float']>;
  campaignInvitationId?: Maybe<Scalars['Float']>;
  campaign?: Maybe<Campaign>;
  invitation?: Maybe<Invitation>;
};

export type MetadataInput = {
  avgHearts?: Maybe<Scalars['Float']>;
  avgLikes?: Maybe<Scalars['Float']>;
  avgComments?: Maybe<Scalars['Float']>;
  avgViews?: Maybe<Scalars['Float']>;
  avgCtr?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Float']>;
  rating?: Maybe<AccountRatingInput>;
};

export type MetadataType = {
  avgHearts?: Maybe<Scalars['Float']>;
  avgLikes?: Maybe<Scalars['Float']>;
  avgComments?: Maybe<Scalars['Float']>;
  avgViews?: Maybe<Scalars['Float']>;
  avgCtr?: Maybe<Scalars['Float']>;
  engagementRate?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Float']>;
  rating?: Maybe<AccountRatingType>;
};

/** The type of the metric select for Bytesights account searching */
export enum MetricSelectType {
  VideoCaptions = 'VideoCaptions',
  Name = 'Name',
  Username = 'Username',
  Bio = 'Bio',
  All = 'All'
}

export type MostPostType = {
  mostLikedPost?: Maybe<PostInfoType>;
  mostViewedPost?: Maybe<PostInfoType>;
  mostCommentedPost?: Maybe<PostInfoType>;
};

export type Mutation = {
  updateAccountRating: Account;
  updateWelcomePersonalDetail: Account;
  updateWelcomeProfileDetail: Account;
  updateWelcomeRegisterForBeta: Account;
  sendWelcomeMails: Array<WelcomeProfileQueryResult>;
  sendFollowUpMails: Array<WelcomeProfileQueryResult>;
  setAuthCodeToCampaignCompany: Company;
  uploadMediaToTikTokCreative: Array<UploadedVideoReturnType>;
  createAdminList: AdminList;
  updateAdminList: AdminList;
  deleteAdminList?: Maybe<Scalars['Boolean']>;
  addAccountsToAdminList: AdminList;
  removeAccountsFromAdminList: AdminList;
  createCampaignGroup: CampaignGroup;
  updateCampaignGroup: CampaignGroup;
  addCampaignsToCampaignGroup: CampaignGroup;
  removeCampaignsFromCampaignGroup: CampaignGroup;
  deleteDraftCampaign?: Maybe<Scalars['Boolean']>;
  createCampaignsToCampaignGroup?: Maybe<Campaign>;
  deleteCampaignGroup?: Maybe<Scalars['Boolean']>;
  createCampaignAnalyticsTracking?: Maybe<Campaign>;
  updateCampaignAnalyticsTracking?: Maybe<Campaign>;
  deleteCampaignAnalyticsTracking?: Maybe<Campaign>;
  createCampaignMilestone?: Maybe<CampaignMilestone>;
  updateCampaignMilestone?: Maybe<CampaignMilestone>;
  deleteCampaignMilestone?: Maybe<CampaignMilestone>;
  checkCampaignMilestones?: Maybe<Scalars['Boolean']>;
  rateCampaignHelpfulness: Campaign;
  updateCampaignReporting?: Maybe<Campaign>;
  recordCampaignMetrics?: Maybe<Scalars['Boolean']>;
  addDiscoverTrackingInfluencerToAdmin: User;
  removeDiscoverTrackingInfluencerFromAdmin: User;
  deleteGiftingCampaign: Scalars['Boolean'];
  submitGiftingCampaign: GiftingCampaign;
  createGiftingCampaign: GiftingCampaign;
  updateGiftingCampaign: GiftingCampaign;
  getJwtTokenFromGoogleToken: JwtToken;
  createNewNoteByInfluencerId: Array<InfluencerProfileNote>;
  updateNotesByInfluencerId?: Maybe<Array<InfluencerProfileNote>>;
  updateInfluencerById: User;
  updateAccountDetailById: Account;
  updateInstagramAccount: UpdateInstagramResponseType;
  createAccount: Account;
  attachAccountToInfluencer: User;
  newInfluencerSignup: User;
  createBytesightsInfluencer: User;
  setBreakdownByAccountId: Account;
  uploadNewDraft?: Maybe<Invitation>;
  updateDraftFeedback?: Maybe<Draft>;
  updateAwaitingDraft?: Maybe<Draft>;
  updateInfluencerHubLink: InivtiationLinkHubType;
  influencerCashoutDetail: Invitation;
  influencerCashoutProfileData: Invitation;
  influencerCashoutPaymentDetail: Invitation;
  registerForBeta: Invitation;
  uploadInitialContractScreenshots: Account;
  signInitialContract: Invitation;
  updateAccountBreakdownsByInvitationId: Invitation;
  recordInvitationMetrics?: Maybe<Scalars['Boolean']>;
  duplicateInvitation?: Maybe<Invitation>;
  udpateInvitationTitle?: Maybe<Invitation>;
  createNewPaymentRequest?: Maybe<PaymentRequestInvitationType>;
  updateInfleucnerPayoutDetailById: User;
  updateAgencyPayoutDetailById: Agency;
  acceptPaymentRequest: PaymentRequest;
  reconcilePaymentRequest: PaymentRequest;
  rejectPaymentRequest: PaymentRequest;
  undoPaymentRequest: PaymentRequest;
  deletePaymentRequest?: Maybe<PaymentRequest>;
  setAccountBreakdownAfterPaymentRequest?: Maybe<Account>;
  signUpPublicInfluencer?: Maybe<PublicCampaignAuthTokenType>;
  updatePublicAccountBreakdowns?: Maybe<Account>;
  updatePublicInfluencerFlags?: Maybe<User>;
  connectPublicYouTubeAccount?: Maybe<Account>;
  connectPublicTikTokAccount?: Maybe<Account>;
  applyPublicCampaign?: Maybe<Campaign>;
  applyPublicCampaignForExpress?: Maybe<PublicExpressApplicant>;
  rollbackPublicCampaignApplicant?: Maybe<Suggestion>;
  acceptPublicCampaignApplicant?: Maybe<Suggestion>;
  confirmPublicCampaignApplicant?: Maybe<Suggestion>;
  rejectPublicCampaignApplicant?: Maybe<Suggestion>;
  notifyPublicCampaignApplicant?: Maybe<Suggestion>;
  requestDataFromExpressApplicant?: Maybe<PublicExpressApplicant>;
  updateShareSuggestionsPageTitile: Campaign;
  addLikedShareSuggestion: Suggestion;
  removeLikedShareSuggestion: Suggestion;
  suggestMultipleAccounts: Array<Suggestion>;
  suggestSingleAccount: Array<Suggestion>;
  addSelectedAccountsByCampaignId: Array<Suggestion>;
  removeInvitedAccount: Invitation;
  updateSuggestionAccount: Suggestion;
  dismissSuggestionAccount: Suggestion;
  createSuggestionAccount: Suggestion;
  createNewTag: Tag;
  getGeneralSignedURL: SignedUrl;
  getSignedURL: SignedUrl;
  getDraftSignedURLs: Array<SignedUrl>;
  getScreenshotSignedURL: SignedUrl;
};


export type MutationUpdateAccountRatingArgs = {
  accountId: Scalars['Int'];
  creationTime?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  contentQuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
};


export type MutationUpdateWelcomePersonalDetailArgs = {
  input: WelcomePersonalDetailInput;
};


export type MutationUpdateWelcomeProfileDetailArgs = {
  input: WelcomeProfileDetailInput;
};


export type MutationUpdateWelcomeRegisterForBetaArgs = {
  claimAccountId: Scalars['String'];
};


export type MutationSendWelcomeMailsArgs = {
  accountIdTo: Scalars['Float'];
  accountIdFrom: Scalars['Float'];
};


export type MutationSendFollowUpMailsArgs = {
  accountIdTo: Scalars['Float'];
  accountIdFrom: Scalars['Float'];
};


export type MutationSetAuthCodeToCampaignCompanyArgs = {
  authCode: Scalars['String'];
  campaignId: Scalars['Int'];
};


export type MutationUploadMediaToTikTokCreativeArgs = {
  fileName: Scalars['String'];
  advertiserId: Scalars['String'];
  draftId: Scalars['Int'];
  campaignId: Scalars['Int'];
};


export type MutationCreateAdminListArgs = {
  listName: Scalars['String'];
};


export type MutationUpdateAdminListArgs = {
  listName: Scalars['String'];
  adminListId: Scalars['Int'];
};


export type MutationDeleteAdminListArgs = {
  adminListId: Scalars['Int'];
};


export type MutationAddAccountsToAdminListArgs = {
  accountIds: Array<Scalars['Int']>;
  adminListId: Scalars['Int'];
};


export type MutationRemoveAccountsFromAdminListArgs = {
  accountIds: Array<Scalars['Int']>;
  adminListId: Scalars['Int'];
};


export type MutationCreateCampaignGroupArgs = {
  groupName: Scalars['String'];
  companyId: Scalars['Int'];
  extraBrandIds: Array<Maybe<Scalars['Int']>>;
};


export type MutationUpdateCampaignGroupArgs = {
  groupName: Scalars['String'];
  companyId: Scalars['Int'];
  extraBrandIds: Array<Maybe<Scalars['Int']>>;
  groupId: Scalars['Int'];
};


export type MutationAddCampaignsToCampaignGroupArgs = {
  groupId: Scalars['Float'];
  campaignIds: Array<Scalars['Int']>;
};


export type MutationRemoveCampaignsFromCampaignGroupArgs = {
  groupId: Scalars['Float'];
  campaignIds: Array<Scalars['Int']>;
};


export type MutationDeleteDraftCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type MutationCreateCampaignsToCampaignGroupArgs = {
  groupId: Scalars['Int'];
  extraBrandIds: Array<Scalars['Int']>;
  companyId: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationDeleteCampaignGroupArgs = {
  groupId: Scalars['Int'];
};


export type MutationCreateCampaignAnalyticsTrackingArgs = {
  trackingKey: Scalars['String'];
  campaignId: Scalars['Int'];
};


export type MutationUpdateCampaignAnalyticsTrackingArgs = {
  trackingKey: Scalars['String'];
  trackingIndex: Scalars['Float'];
  campaignId: Scalars['Int'];
};


export type MutationDeleteCampaignAnalyticsTrackingArgs = {
  trackingKey: Scalars['String'];
  campaignId: Scalars['Int'];
};


export type MutationCreateCampaignMilestoneArgs = {
  notification: CampaignMilestoneOptionInput;
  visibility: CampaignMilestoneOptionInput;
  goal: Scalars['Float'];
  metric: CampaignMilestoneMetric;
  name: Scalars['String'];
  campaignId: Scalars['Int'];
};


export type MutationUpdateCampaignMilestoneArgs = {
  notification: CampaignMilestoneOptionInput;
  visibility: CampaignMilestoneOptionInput;
  goal: Scalars['Float'];
  metric: CampaignMilestoneMetric;
  name: Scalars['String'];
  campaignMilestoneId: Scalars['Int'];
};


export type MutationDeleteCampaignMilestoneArgs = {
  campaignMilestoneId: Scalars['Int'];
};


export type MutationRateCampaignHelpfulnessArgs = {
  helpfulnessRating: Scalars['Float'];
  campaignId: Scalars['Int'];
};


export type MutationUpdateCampaignReportingArgs = {
  reportObjective?: Maybe<Scalars['String']>;
  reportLink?: Maybe<Scalars['String']>;
  campaignId: Scalars['Float'];
};


export type MutationAddDiscoverTrackingInfluencerToAdminArgs = {
  accountName: Scalars['String'];
};


export type MutationRemoveDiscoverTrackingInfluencerFromAdminArgs = {
  accountName: Scalars['String'];
};


export type MutationDeleteGiftingCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type MutationSubmitGiftingCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type MutationCreateGiftingCampaignArgs = {
  title: Scalars['String'];
};


export type MutationUpdateGiftingCampaignArgs = {
  data: UpdateGiftingCampaign;
  campaignId: Scalars['Int'];
};


export type MutationGetJwtTokenFromGoogleTokenArgs = {
  refresh_token: Scalars['String'];
  access_token: Scalars['String'];
};


export type MutationCreateNewNoteByInfluencerIdArgs = {
  title: Scalars['String'];
  body: Scalars['String'];
  influencerId: Scalars['Int'];
};


export type MutationUpdateNotesByInfluencerIdArgs = {
  notes?: Maybe<Array<NoteInput>>;
  influencerId: Scalars['Int'];
};


export type MutationUpdateInfluencerByIdArgs = {
  id: Scalars['Float'];
  name: Scalars['String'];
  email: Scalars['String'];
  paypalEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['Int']>;
  managed?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Array<Scalars['String']>>;
  age?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Array<Scalars['String']>>;
  punctuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
  flags?: Maybe<Array<Scalars['String']>>;
};


export type MutationUpdateAccountDetailByIdArgs = {
  id: Scalars['Int'];
  tags: Array<TagInput>;
  actualCostPerPost?: Maybe<Scalars['Float']>;
};


export type MutationUpdateInstagramAccountArgs = {
  influencerID: Scalars['Float'];
};


export type MutationCreateAccountArgs = {
  data: NewAccountInput;
};


export type MutationAttachAccountToInfluencerArgs = {
  reqEmail: Scalars['String'];
  accountData: NewBytesightInfluencerAccountInput;
  influencerId: Scalars['String'];
};


export type MutationNewInfluencerSignupArgs = {
  data: NewInfluencerInput;
};


export type MutationCreateBytesightsInfluencerArgs = {
  reqEmail: Scalars['String'];
  data: NewBytesightInfluencerInput;
};


export type MutationSetBreakdownByAccountIdArgs = {
  accountId: Scalars['String'];
  type: BreakdownType;
  data: Scalars['JSON'];
};


export type MutationUploadNewDraftArgs = {
  input: UploadNewDraftInput;
};


export type MutationUpdateDraftFeedbackArgs = {
  input: UpdateDraftFeedbackInput;
};


export type MutationUpdateAwaitingDraftArgs = {
  input: UpdateAwaitingDraftInput;
};


export type MutationUpdateInfluencerHubLinkArgs = {
  linkHub: InivtiationLinkHubInput;
  claimCashoutId: Scalars['String'];
};


export type MutationInfluencerCashoutDetailArgs = {
  input: InfluencerCashoutDetailType;
};


export type MutationInfluencerCashoutProfileDataArgs = {
  input: InfluencerCashoutProfileDataType;
};


export type MutationInfluencerCashoutPaymentDetailArgs = {
  input: InfluencerCashoutPaymentDetailType;
};


export type MutationRegisterForBetaArgs = {
  input: InfluencerCashoutRegisterForBetaType;
};


export type MutationUploadInitialContractScreenshotsArgs = {
  screenshots: Array<FileInput>;
  claimCashoutId: Scalars['String'];
};


export type MutationSignInitialContractArgs = {
  claimCashoutId: Scalars['String'];
};


export type MutationUpdateAccountBreakdownsByInvitationIdArgs = {
  invitationId: Scalars['Int'];
  screenshots?: Maybe<Array<ScreenshotFileInput>>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
  tags?: Maybe<Array<Scalars['Int']>>;
};


export type MutationDuplicateInvitationArgs = {
  invitationId: Scalars['Int'];
};


export type MutationUdpateInvitationTitleArgs = {
  title: Scalars['String'];
  invitationId: Scalars['Int'];
};


export type MutationCreateNewPaymentRequestArgs = {
  campaignId?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  paymentDetail?: Maybe<PaymentDetailType>;
  attachments?: Maybe<Array<Scalars['String']>>;
  agencyId?: Maybe<Scalars['Int']>;
  agencyName?: Maybe<Scalars['String']>;
  agencyAccountIds?: Maybe<Array<Scalars['Int']>>;
  invitationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  actualViews?: Maybe<Scalars['Int']>;
  screenshots?: Maybe<Array<FileInput>>;
  dontCreatePaymentRequest?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateInfleucnerPayoutDetailByIdArgs = {
  paymentDetail: PaymentDetailType;
  influencerId: Scalars['Int'];
};


export type MutationUpdateAgencyPayoutDetailByIdArgs = {
  paymentDetail: PaymentDetailType;
  agencyId: Scalars['Int'];
};


export type MutationAcceptPaymentRequestArgs = {
  paymentRequestId: Scalars['Float'];
};


export type MutationReconcilePaymentRequestArgs = {
  paymentRequestId: Scalars['Float'];
};


export type MutationRejectPaymentRequestArgs = {
  paymentRequestId: Scalars['Float'];
};


export type MutationUndoPaymentRequestArgs = {
  paymentRequestId: Scalars['Float'];
};


export type MutationDeletePaymentRequestArgs = {
  paymentRequestId: Scalars['Float'];
};


export type MutationSetAccountBreakdownAfterPaymentRequestArgs = {
  accountId: Scalars['Float'];
  followers?: Maybe<Scalars['Float']>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
};


export type MutationSignUpPublicInfluencerArgs = {
  password: Scalars['String'];
  location: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  age: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdatePublicAccountBreakdownsArgs = {
  accountId: Scalars['Int'];
  screenshots?: Maybe<Array<ScreenshotFileInput>>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
};


export type MutationUpdatePublicInfluencerFlagsArgs = {
  flags: Array<Scalars['String']>;
};


export type MutationConnectPublicYouTubeAccountArgs = {
  accessToken: Scalars['String'];
};


export type MutationConnectPublicTikTokAccountArgs = {
  code: Scalars['String'];
};


export type MutationApplyPublicCampaignArgs = {
  accountId: Scalars['Int'];
  campaignId: Scalars['Int'];
};


export type MutationApplyPublicCampaignForExpressArgs = {
  expectedFee: Scalars['String'];
  email: Scalars['String'];
  username: Scalars['String'];
  platform: PublicExpressApplicantPlatformType;
  campaignId: Scalars['Int'];
};


export type MutationRollbackPublicCampaignApplicantArgs = {
  accountId: Scalars['Int'];
  campaignId: Scalars['Int'];
};


export type MutationAcceptPublicCampaignApplicantArgs = {
  accountId: Scalars['Int'];
  campaignId: Scalars['Int'];
};


export type MutationConfirmPublicCampaignApplicantArgs = {
  accountId: Scalars['Int'];
  campaignId: Scalars['Int'];
};


export type MutationRejectPublicCampaignApplicantArgs = {
  accountId: Scalars['Int'];
  campaignId: Scalars['Int'];
};


export type MutationNotifyPublicCampaignApplicantArgs = {
  accountId: Scalars['Int'];
  campaignId: Scalars['Int'];
};


export type MutationRequestDataFromExpressApplicantArgs = {
  email: Scalars['String'];
  username: Scalars['String'];
  platform: Scalars['String'];
  campaignId: Scalars['Int'];
};


export type MutationUpdateShareSuggestionsPageTitileArgs = {
  pageTitle: Scalars['String'];
  campaignId: Scalars['Int'];
};


export type MutationAddLikedShareSuggestionArgs = {
  suggestionId: Scalars['Int'];
};


export type MutationRemoveLikedShareSuggestionArgs = {
  suggestionId: Scalars['Int'];
};


export type MutationSuggestMultipleAccountsArgs = {
  accountIds: Array<Scalars['Int']>;
  campaignId: Scalars['Int'];
};


export type MutationSuggestSingleAccountArgs = {
  email: Scalars['String'];
  accountId: Scalars['Int'];
  campaignIds: Array<Scalars['Int']>;
};


export type MutationAddSelectedAccountsByCampaignIdArgs = {
  campaignId: Scalars['Float'];
  accountIds?: Maybe<Array<Scalars['Int']>>;
};


export type MutationRemoveInvitedAccountArgs = {
  suggestionStatus: Scalars['String'];
  campaignInvitationId: Scalars['Float'];
};


export type MutationUpdateSuggestionAccountArgs = {
  status: Scalars['String'];
  suggestionId: Scalars['Float'];
};


export type MutationDismissSuggestionAccountArgs = {
  accountId: Scalars['Float'];
  campaignId: Scalars['Float'];
};


export type MutationCreateSuggestionAccountArgs = {
  campaignId: Scalars['Int'];
  accountId: Scalars['Int'];
  payoutType?: Maybe<Scalars['String']>;
  payoutTypeOriginalPennies?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  rationale?: Maybe<Scalars['String']>;
};


export type MutationCreateNewTagArgs = {
  tagName: Scalars['String'];
};


export type MutationGetGeneralSignedUrlArgs = {
  input: GetSignedUrlInput;
};


export type MutationGetSignedUrlArgs = {
  input: GetSignedUrlInput;
};


export type MutationGetDraftSignedUrLsArgs = {
  input: Array<GetSignedUrlInput>;
};


export type MutationGetScreenshotSignedUrlArgs = {
  input: GetSignedUrlInput;
};

export type NewAccountInput = {
  username: Scalars['String'];
  status: AccountStatusType;
  influencerId?: Maybe<Scalars['Int']>;
  influencerName?: Maybe<Scalars['String']>;
  influencerEmail?: Maybe<Scalars['String']>;
  influencerPaypalEmail?: Maybe<Scalars['String']>;
  platform: AccountType;
  format: AccountFormatType;
  cpm: Scalars['Int'];
  tagIds?: Maybe<Array<Scalars['Int']>>;
  active: Scalars['Boolean'];
  salesAppropriate: Scalars['Boolean'];
};

export type NewBytesightInfluencerAccountInput = {
  username: Scalars['String'];
  type: AccountType;
  verified?: Maybe<AccountStatusType>;
  views?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  engagementRate?: Maybe<Scalars['Float']>;
  avgLikes?: Maybe<Scalars['Float']>;
  avgComments?: Maybe<Scalars['Float']>;
  avgViews?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
  payoutPennies?: Maybe<Scalars['Float']>;
  profilePicUrl?: Maybe<Scalars['String']>;
};

export type NewBytesightInfluencerInput = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  age: Scalars['String'];
  gender: Scalars['String'];
};

export type NewInfluencerAccountInput = {
  username: Scalars['String'];
  payoutPennies: Scalars['Float'];
  type: Scalars['String'];
};

export type NewInfluencerInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  accounts: Array<NewInfluencerAccountInput>;
};

export type NoteInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  createdAt: Scalars['String'];
};

export type NoteType = {
  title: Scalars['String'];
  body: Scalars['String'];
  createdAt: Scalars['String'];
};

export type NumberComparison = {
  gt?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
};

export type NumberComparisonWithKey = {
  key: Scalars['String'];
  value: NumberComparison;
};

export type Pagination = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
};

export type PaymentDetailType = {
  paypalEmail?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountInput>;
};

export type PaymentQueryResult = {
  result?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PaymentQueryResultType = {
  campaign?: Maybe<Array<PaymentQueryResult>>;
  influencer?: Maybe<Array<PaymentQueryResult>>;
  requestedBy?: Maybe<Array<PaymentQueryResult>>;
  id?: Maybe<Array<PaymentQueryResult>>;
};

export type PaymentQueueType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  paymentCount: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalNetAmount: Scalars['Float'];
  totalVatAmount: Scalars['Float'];
  paymentRequests: Array<PaymentRequest>;
};

export type PaymentRequest = {
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  vatAmount?: Maybe<Scalars['Float']>;
  payoutPenniesExclVat?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  conversionRate?: Maybe<Scalars['Float']>;
  payoutPennies?: Maybe<Scalars['Float']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  failedReason?: Maybe<Scalars['String']>;
  paymentDetail: Scalars['JSON'];
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewAttachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  agencyId?: Maybe<Scalars['String']>;
  agencyName?: Maybe<Scalars['String']>;
  agencyAccountIds?: Maybe<Array<Scalars['Int']>>;
  deletable?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  campaign?: Maybe<Campaign>;
  influencer?: Maybe<User>;
  account?: Maybe<Account>;
  requestedBy: User;
  agency: Agency;
};

export type PaymentRequestInvitationType = {
  paymentRequest?: Maybe<PaymentRequest>;
  invitation?: Maybe<Invitation>;
};

export type PayoutDetailInput = {
  paypalEmail?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountInput>;
};

export type PayoutDetailType = {
  paypalEmail?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountType>;
};

export type Platforms = {
  snapchat?: Maybe<Scalars['Boolean']>;
  tiktok?: Maybe<Scalars['Boolean']>;
  instagram?: Maybe<Scalars['Boolean']>;
  youtube?: Maybe<Scalars['Boolean']>;
};

export type PlatformsInput = {
  snapchat?: Maybe<Scalars['Boolean']>;
  tiktok?: Maybe<Scalars['Boolean']>;
  instagram?: Maybe<Scalars['Boolean']>;
  youtube?: Maybe<Scalars['Boolean']>;
};

export type Post = {
  accountId?: Maybe<Scalars['Int']>;
  snaplyticsContentUrl?: Maybe<Scalars['String']>;
  typeString?: Maybe<Scalars['String']>;
  contentFileName?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  postDatetime?: Maybe<Scalars['DateTime']>;
  poster?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  metadata: PostMetadataType;
  account?: Maybe<Account>;
};

export type PostInfoType = {
  videoUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  postedTime?: Maybe<Scalars['DateTime']>;
};

export type PostMetadataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
  dislikeCount?: Maybe<Scalars['Float']>;
  commentCount?: Maybe<Scalars['Float']>;
  favoriteCount?: Maybe<Scalars['Float']>;
  postId?: Maybe<Scalars['String']>;
  webVideoUrl?: Maybe<Scalars['String']>;
};

export type PostMetadataType = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Float']>;
  likeCount?: Maybe<Scalars['Float']>;
  dislikeCount?: Maybe<Scalars['Float']>;
  commentCount?: Maybe<Scalars['Float']>;
  favoriteCount?: Maybe<Scalars['Float']>;
  postId?: Maybe<Scalars['String']>;
  webVideoUrl?: Maybe<Scalars['String']>;
};

export type PublicCampaignAndAccountType = {
  publicCampaign: Campaign;
  influencerAccounts: Array<Account>;
};

export type PublicCampaignApplicantsType = {
  campaign: Campaign;
  express?: Maybe<Array<Maybe<PublicExpressApplicant>>>;
  applicants?: Maybe<Array<Maybe<Account>>>;
  accepted?: Maybe<Array<Maybe<Account>>>;
  rejected?: Maybe<Array<Maybe<Account>>>;
  confirmed?: Maybe<Array<Maybe<Account>>>;
  notified?: Maybe<Array<Maybe<Account>>>;
};

export type PublicCampaignAuthTokenType = {
  authToken: Scalars['String'];
  influencer: User;
};

export type PublicCampaignResultType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  publicCampaigns: Array<Campaign>;
};

export type PublicExpressApplicant = {
  id: Scalars['Int'];
  email: Scalars['String'];
  username: Scalars['String'];
  platform: PublicExpressApplicantPlatformType;
  expectedFee?: Maybe<Scalars['String']>;
  status: PublicExpressApplicantStatusType;
  publicCampaignId: Scalars['Int'];
  influencerAccountId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  campaign: Campaign;
};

export enum PublicExpressApplicantPlatformType {
  Instagram = 'Instagram',
  YouTube = 'YouTube',
  Snapchat = 'Snapchat',
  TikTok = 'TikTok'
}

export enum PublicExpressApplicantStatusType {
  Applied = 'Applied',
  Requested = 'Requested',
  Received = 'Received',
  Registered = 'Registered'
}

export type Query = {
  getFilteringAccounts: FilteringAccountResultType;
  getFilteringAccountQuery: AccountQueryResultType;
  getWelcomeProfileAccount: Account;
  getAdHubMediaList: AdHubResultType;
  getAdminLists: Array<AdminList>;
  getAdminList: AdminList;
  getTikTokUserSafetyRatingQuery: UserBrandSafetyType;
  getTikTokUserBrandSafety: TikTokUserBrandSafetyType;
  getAllCampaignGroups: CampaignGroupResultType;
  getCampaignGroupById: CampaignGroup;
  searchCampaignsByCampaignGroupId: CampaignResultType;
  getCampaignsForCampaignGroup: CampaignResultType;
  getAllBrands: BrandResultType;
  getAllAdmins: AdminResultType;
  getAllCompanies: CompanyResultType;
  getAllGroupedCampaigns: GroupedCampaignResultType;
  getCampaignAnalytic: CampaignAnalytic;
  getCampaignAnalyticsTracking?: Maybe<Array<CampaignAnalyticsTrackingType>>;
  getCampaignAnalyticsPosts?: Maybe<Array<CampaignAnalyticPostedAccount>>;
  getCampaignMilestones: Campaign;
  getCampaignInstantReport: CampaignReportType;
  getCampaignDetailByCampaignId: CampaignDetailType;
  getCampaignReporting?: Maybe<Campaign>;
  getCampaignAndCompanyNameByTitle?: Maybe<Array<Campaign>>;
  getDiscoverCategoryTopInfluencers?: Maybe<DiscoverCategoryResultType>;
  getDiscoverSearchingTopInfluencers?: Maybe<Array<DiscoverSearchingType>>;
  getDiscoverTrackingInfluencersFromAdmin?: Maybe<Array<DiscoverTrackingType>>;
  getGiftingCampaign: GiftingCampaign;
  getGiftingCampaigns: Array<GiftingCampaign>;
  getHubSpotDealDetailsById: HubSpotDealType;
  getHubSpotDealValidationById: HubSpotDealValidationType;
  getCampaignsByInfluencerId: Array<InfluencerProfileCampaign>;
  getNotesByInfluencerId?: Maybe<Array<InfluencerProfileNote>>;
  getProfileByInfluencerId?: Maybe<InfluencerProfile>;
  getInfluencerById: User;
  getInfluencerByName: Account;
  getAccountDetailById?: Maybe<InfluencerProfileAccountDetailType>;
  getAllInfluencers: Array<User>;
  getAccountByUsernameOrId: Account;
  getCurrentFollowersByAccountId: Array<InfluencerProfileAccountGraphData>;
  getAverageCommentsByAccountId: Array<InfluencerProfileAccountGraphData>;
  getAverageHeartsByAccountId: Array<InfluencerProfileAccountGraphData>;
  getAverageViewsByAccountId: Array<InfluencerProfileAccountGraphData>;
  getAgeBreakdownByAccountId: InfluencerProfileAccountBreakdownData;
  getGenderBreakdownByAccountId: InfluencerProfileAccountBreakdownData;
  getLocationBreakdownByAccountId: InfluencerProfileAccountBreakdownData;
  searchInfluencerAccounts?: Maybe<SearchAccountQueueType>;
  getSubmissionInvitation?: Maybe<Invitation>;
  getInvitationDraft?: Maybe<Draft>;
  getInfluencerHubLinkStatus: InivtiationLinkHubStatusType;
  getInfluencerCashoutInvitation: InfluencerCashoutInvitation;
  getPaymentRequestInvitationById: Invitation;
  getYoutubeAnalyticsDataByAccessToken: YouTubeAnalyticsBreakdowns;
  getTestLinkByCamapignId?: Maybe<Link>;
  getPaymentRequestQuery: PaymentQueryResultType;
  getPaymentRequests: PaymentQueueType;
  getAllCampaigns: Array<Campaign>;
  getAccountsByCampaignId?: Maybe<Array<Account>>;
  getAllAgencies: Array<Agency>;
  getPopularPostsByAccountId: MostPostType;
  getPublicInfluencer?: Maybe<User>;
  getPublicInfluencerProfile?: Maybe<User>;
  getPublicCampaigns?: Maybe<PublicCampaignResultType>;
  getPublicCampaign?: Maybe<PublicCampaignAndAccountType>;
  getTikTokOauthUrl?: Maybe<Scalars['String']>;
  getPublicCampaignApplicants?: Maybe<PublicCampaignApplicantsType>;
  getPendingShareSuggestions: ShareSuggestionsType;
  getAcceptedShareSuggestions: ShareSuggestionsType;
  getSuggestionAccountsByCampaignId?: Maybe<SuggestionAccountType>;
  getAllSuggestedAccountsByCampaignId?: Maybe<Array<Suggestion>>;
  getAllAcceptedAccountsByCampaignId?: Maybe<Array<Suggestion>>;
  getAllInvitationOfAdminByCampaignId?: Maybe<Array<Invitation>>;
  getAllTags: Array<Tag>;
};


export type QueryGetFilteringAccountsArgs = {
  searchInput?: Maybe<Scalars['String']>;
  searchType?: Maybe<FilteringAccountSearchType>;
  audienceAge?: Maybe<Array<AudienceBreakdownSearchInput>>;
  audienceGender?: Maybe<Array<AudienceBreakdownSearchInput>>;
  audienceLocation?: Maybe<Array<AudienceBreakdownSearchInput>>;
  accountType?: Maybe<Array<AccountType>>;
  influencerAge?: Maybe<Array<Scalars['String']>>;
  influencerGender?: Maybe<Array<GenderType>>;
  influencerLocation?: Maybe<Array<Scalars['String']>>;
  completedCampaigns?: Maybe<CompletedCampaignsSearchInput>;
  status?: Maybe<Array<FilteringAccountStatusType>>;
  tags?: Maybe<Array<Scalars['Int']>>;
  customTags?: Maybe<Array<Scalars['Int']>>;
  rating?: Maybe<AccountRatingInput>;
  followers?: Maybe<ComparisonInput>;
  order?: Maybe<FilteringAccountSortInput>;
  page?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  influencerId?: Maybe<Scalars['Int']>;
  campaignId?: Maybe<Scalars['Int']>;
  viewMode?: Maybe<Scalars['Boolean']>;
  campaignIdForSuggestedAccounts?: Maybe<Scalars['Int']>;
  adminListId?: Maybe<Scalars['Int']>;
  adminListIdForNotIncluded?: Maybe<Scalars['Int']>;
};


export type QueryGetFilteringAccountQueryArgs = {
  searchInput: Scalars['String'];
};


export type QueryGetWelcomeProfileAccountArgs = {
  claimAccountId: Scalars['String'];
};


export type QueryGetAdHubMediaListArgs = {
  accountName?: Maybe<Scalars['String']>;
  campaignId: Scalars['Int'];
};


export type QueryGetAdminListArgs = {
  adminListId: Scalars['Int'];
};


export type QueryGetTikTokUserSafetyRatingQueryArgs = {
  username: Scalars['String'];
};


export type QueryGetTikTokUserBrandSafetyArgs = {
  tiktokUserUrl: Scalars['String'];
};


export type QueryGetAllCampaignGroupsArgs = {
  page?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
};


export type QueryGetCampaignGroupByIdArgs = {
  groupId: Scalars['Float'];
};


export type QuerySearchCampaignsByCampaignGroupIdArgs = {
  page?: Maybe<Scalars['Float']>;
  groupId: Scalars['Int'];
  campaignTitle?: Maybe<Scalars['String']>;
};


export type QueryGetCampaignsForCampaignGroupArgs = {
  page?: Maybe<Scalars['Float']>;
  groupId: Scalars['Int'];
  campaignTitle?: Maybe<Scalars['String']>;
};


export type QueryGetAllBrandsArgs = {
  page?: Maybe<Scalars['Int']>;
  brandName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
};


export type QueryGetAllAdminsArgs = {
  page?: Maybe<Scalars['Int']>;
  adminName?: Maybe<Scalars['String']>;
};


export type QueryGetAllCompaniesArgs = {
  page?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
};


export type QueryGetAllGroupedCampaignsArgs = {
  title?: Maybe<Scalars['String']>;
  status?: Maybe<Array<GroupedCampaignStatusType>>;
  platforms?: Maybe<Array<Maybe<GroupedCampaignPlatformType>>>;
  startedAfter?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  customTags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  costOfSales?: Maybe<GroupedCampaignMoneyRangeInput>;
  budget?: Maybe<GroupedCampaignMoneyRangeInput>;
  locations?: Maybe<Array<Scalars['String']>>;
  companyId?: Maybe<Scalars['Int']>;
  adminExecId?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  paymentCurrency?: Maybe<Scalars['String']>;
  hubspotDealId?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  order?: Maybe<GroupedCampaignSortInput>;
};


export type QueryGetCampaignAnalyticArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetCampaignAnalyticsTrackingArgs = {
  trackingType?: Maybe<CampaignTrackingTypeEnum>;
  campaignId: Scalars['Int'];
};


export type QueryGetCampaignAnalyticsPostsArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetCampaignMilestonesArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetCampaignInstantReportArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetCampaignDetailByCampaignIdArgs = {
  campaignId: Scalars['Float'];
};


export type QueryGetCampaignReportingArgs = {
  campaignId: Scalars['Float'];
};


export type QueryGetCampaignAndCompanyNameByTitleArgs = {
  email: Scalars['String'];
  title: Scalars['String'];
};


export type QueryGetDiscoverCategoryTopInfluencersArgs = {
  category: DiscoverCategory;
};


export type QueryGetDiscoverSearchingTopInfluencersArgs = {
  searchInput?: Maybe<Scalars['String']>;
};


export type QueryGetGiftingCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetHubSpotDealDetailsByIdArgs = {
  dealId: Scalars['String'];
};


export type QueryGetHubSpotDealValidationByIdArgs = {
  dealId: Scalars['String'];
};


export type QueryGetCampaignsByInfluencerIdArgs = {
  searchInput?: Maybe<Scalars['String']>;
  influencerId: Scalars['Float'];
};


export type QueryGetNotesByInfluencerIdArgs = {
  influencerId: Scalars['Float'];
};


export type QueryGetProfileByInfluencerIdArgs = {
  influencerId: Scalars['Float'];
};


export type QueryGetInfluencerByIdArgs = {
  influencerId: Scalars['Float'];
};


export type QueryGetInfluencerByNameArgs = {
  influencerName: Scalars['String'];
};


export type QueryGetAccountDetailByIdArgs = {
  accountId?: Maybe<Scalars['String']>;
  platformType: DataSourcePlatform;
};


export type QueryGetAllInfluencersArgs = {
  page?: Maybe<Scalars['Float']>;
  influencerName?: Maybe<Scalars['String']>;
};


export type QueryGetAccountByUsernameOrIdArgs = {
  reqEmail: Scalars['String'];
  accountId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type QueryGetCurrentFollowersByAccountIdArgs = {
  accountId?: Maybe<Scalars['String']>;
  platformType: DataSourcePlatform;
  username?: Maybe<Scalars['String']>;
  filter?: Maybe<LineChartFilter>;
};


export type QueryGetAverageCommentsByAccountIdArgs = {
  accountId?: Maybe<Scalars['String']>;
  platformType: DataSourcePlatform;
  username?: Maybe<Scalars['String']>;
  filter?: Maybe<LineChartFilter>;
};


export type QueryGetAverageHeartsByAccountIdArgs = {
  accountId?: Maybe<Scalars['String']>;
  platformType: DataSourcePlatform;
  username?: Maybe<Scalars['String']>;
  filter?: Maybe<LineChartFilter>;
};


export type QueryGetAverageViewsByAccountIdArgs = {
  accountId?: Maybe<Scalars['String']>;
  platformType: DataSourcePlatform;
  username?: Maybe<Scalars['String']>;
  filter?: Maybe<LineChartFilter>;
};


export type QueryGetAgeBreakdownByAccountIdArgs = {
  accountId: Scalars['String'];
};


export type QueryGetGenderBreakdownByAccountIdArgs = {
  accountId: Scalars['String'];
};


export type QueryGetLocationBreakdownByAccountIdArgs = {
  accountId: Scalars['String'];
};


export type QuerySearchInfluencerAccountsArgs = {
  platformType: DataSourcePlatform;
  searchInput?: Maybe<Scalars['String']>;
  metricSelect?: Maybe<MetricSelectType>;
  campaignIds?: Maybe<Array<Scalars['Int']>>;
  accountType?: Maybe<AccountType>;
  influencerGender?: Maybe<GenderType>;
  influencerLocation?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['Int']>>;
  followers?: Maybe<NumberComparison>;
  hearts?: Maybe<NumberComparison>;
  price?: Maybe<NumberComparison>;
  includeEstimatedCosts?: Maybe<Scalars['Boolean']>;
  audienceAge?: Maybe<NumberComparisonWithKey>;
  audienceGender?: Maybe<NumberComparisonWithKey>;
  audienceLocation?: Maybe<NumberComparisonWithKey>;
  page?: Maybe<Scalars['Int']>;
  exportCSV?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubmissionInvitationArgs = {
  invitationId?: Maybe<Scalars['String']>;
  claimCashoutId?: Maybe<Scalars['String']>;
};


export type QueryGetInvitationDraftArgs = {
  draftId?: Maybe<Scalars['String']>;
};


export type QueryGetInfluencerHubLinkStatusArgs = {
  claimCashoutId: Scalars['String'];
};


export type QueryGetInfluencerCashoutInvitationArgs = {
  claimCashoutId: Scalars['String'];
};


export type QueryGetPaymentRequestInvitationByIdArgs = {
  invitationId: Scalars['Float'];
};


export type QueryGetYoutubeAnalyticsDataByAccessTokenArgs = {
  accessToken: Scalars['String'];
};


export type QueryGetTestLinkByCamapignIdArgs = {
  campaignId: Scalars['Float'];
};


export type QueryGetPaymentRequestQueryArgs = {
  searchInput: Scalars['String'];
};


export type QueryGetPaymentRequestsArgs = {
  searchInput?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  influencer?: Maybe<Scalars['String']>;
  requestedBy?: Maybe<Scalars['String']>;
  paymentRequestId?: Maybe<Scalars['Int']>;
  campaignId?: Maybe<Scalars['Int']>;
  paypalTransactionId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  isGreaterThan?: Maybe<Scalars['Boolean']>;
  typeOfCost?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  dueDateFrom?: Maybe<Scalars['DateTime']>;
  dueDateTo?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<Scalars['String']>>;
  paymentType?: Maybe<Array<Scalars['String']>>;
  paymentCurrency?: Maybe<Scalars['String']>;
  hubspotDealId?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetAllCampaignsArgs = {
  page?: Maybe<Scalars['Float']>;
  campaignTitle?: Maybe<Scalars['String']>;
};


export type QueryGetAccountsByCampaignIdArgs = {
  page?: Maybe<Scalars['Float']>;
  accountName?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Float']>;
};


export type QueryGetAllAgenciesArgs = {
  page?: Maybe<Scalars['Float']>;
  agencyName?: Maybe<Scalars['String']>;
};


export type QueryGetPopularPostsByAccountIdArgs = {
  accountId: Scalars['Float'];
};


export type QueryGetPublicCampaignsArgs = {
  page?: Maybe<Scalars['Int']>;
  isApplied?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  searchInput?: Maybe<Scalars['String']>;
};


export type QueryGetPublicCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetPublicCampaignApplicantsArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetPendingShareSuggestionsArgs = {
  page?: Maybe<Scalars['Int']>;
  campaignId: Scalars['Int'];
};


export type QueryGetAcceptedShareSuggestionsArgs = {
  page?: Maybe<Scalars['Int']>;
  campaignId: Scalars['Int'];
};


export type QueryGetSuggestionAccountsByCampaignIdArgs = {
  campaignId: Scalars['Float'];
  matchType?: Maybe<BreakdownType>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetAllSuggestedAccountsByCampaignIdArgs = {
  campaignId: Scalars['Float'];
};


export type QueryGetAllAcceptedAccountsByCampaignIdArgs = {
  campaignId: Scalars['Float'];
};


export type QueryGetAllInvitationOfAdminByCampaignIdArgs = {
  campaignId: Scalars['Float'];
};


export type QueryGetAllTagsArgs = {
  tagName?: Maybe<Scalars['String']>;
};

export type RatingInput = {
  punctuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
};

export type RatingType = {
  punctuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
};

export type RequiredInformation = {
  address?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
};

export type RequiredInformationInput = {
  address?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
};

export enum Role {
  Admin = 'Admin',
  Brand = 'Brand',
  Influencer = 'Influencer'
}

export type ScreenshotFileInput = {
  id?: Maybe<Scalars['Int']>;
  uploadUrl: Scalars['String'];
  uploadType: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type SearchAccountQueueType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  searchAccounts?: Maybe<Array<SearchAccountType>>;
  exportCSV?: Maybe<Scalars['Boolean']>;
};

export type SearchAccountType = {
  accountId?: Maybe<Scalars['String']>;
  influencerId?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  influencerName?: Maybe<Scalars['String']>;
  platform: Scalars['String'];
  format?: Maybe<Scalars['String']>;
  campaignCount?: Maybe<Scalars['Int']>;
  engCTR?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  avgLikes?: Maybe<Scalars['Float']>;
  avgViews?: Maybe<Scalars['Float']>;
  avgComments?: Maybe<Scalars['Float']>;
  followers?: Maybe<Scalars['Float']>;
};

export type ShareSuggestionsType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  pageTitle: Scalars['String'];
  suggestions: Array<Suggestion>;
};

export type SignedUrl = {
  url?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
};

export type Suggestion = {
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Float']>;
  influencerAccountId?: Maybe<Scalars['Float']>;
  campaignInvitationId?: Maybe<Scalars['Float']>;
  cpm?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Int']>;
  payoutType: Scalars['String'];
  rationale?: Maybe<Scalars['String']>;
  fixedPayoutPennies?: Maybe<Scalars['Int']>;
  estimatedPayoutPennies?: Maybe<Scalars['Int']>;
  payoutTypeCurrency?: Maybe<Scalars['String']>;
  payoutTypeOriginalPennies?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  campaign?: Maybe<Campaign>;
  account?: Maybe<Account>;
  invitation?: Maybe<Invitation>;
};

export type SuggestionAccountType = {
  totalEntries: Scalars['Int'];
  perPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  previousPage?: Maybe<Scalars['Int']>;
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  accounts: Array<Account>;
};

export type Tag = {
  id: Scalars['Float'];
  name: Scalars['String'];
  isDefined: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TagInput = {
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type TiktokAccount = {
  id: Scalars['Int'];
  secUid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  isSecret?: Maybe<Scalars['Boolean']>;
  uniqueId?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  covers?: Maybe<Array<Scalars['String']>>;
  coversMedium?: Maybe<Array<Scalars['String']>>;
  following?: Maybe<Scalars['Int']>;
  fans?: Maybe<Scalars['Int']>;
  heart?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Boolean']>;
  digg?: Maybe<Scalars['Int']>;
  ftc?: Maybe<Scalars['Boolean']>;
  relation?: Maybe<Scalars['Int']>;
  openFavorite?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TikTokAdsInput = {
  accessToken?: Maybe<Scalars['String']>;
};

export type TikTokAdsType = {
  accessToken?: Maybe<Scalars['String']>;
};

export type TikTokUserBrandSafetyType = {
  username: Scalars['String'];
  avatarUrl: Scalars['String'];
  bio: Scalars['String'];
  hygineRating: Scalars['Float'];
  tags: BrandSafetyTagType;
  keywords: Array<BrandSafetyKeywordType>;
  analysedPosts: Array<BrandSafetyAnalysedPostType>;
  mentionedAccounts: Array<BrandSafetyMentionedAccountType>;
};


export type TrackingInput = {
  discover?: Maybe<Array<Scalars['String']>>;
};

export type TrackingType = {
  discover?: Maybe<Array<Scalars['String']>>;
};

export type UpdateAwaitingDraftInput = {
  draftId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  allVideos?: Maybe<Array<Maybe<Scalars['String']>>>;
  allMedia?: Maybe<Array<Maybe<Scalars['String']>>>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateDraftFeedbackInput = {
  draftId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  brandFeedback?: Maybe<Array<Maybe<Scalars['String']>>>;
  adminFeedback?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateGiftingCampaign = {
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  brief?: Maybe<GiftingBriefInput>;
};

export type UpdateInstagramResponseType = {
  isUpdated: Scalars['Boolean'];
};

export type UploadedVideoReturnType = {
  poster_url?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  video_id?: Maybe<Scalars['String']>;
  displayable?: Maybe<Scalars['Boolean']>;
  material_id?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  bit_rate?: Maybe<Scalars['Float']>;
  create_time?: Maybe<Scalars['String']>;
  modify_time?: Maybe<Scalars['String']>;
  allowed_placements?: Maybe<Array<Scalars['String']>>;
  signature?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  allow_download?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type UploadFile = {
  id?: Maybe<Scalars['Int']>;
  fileableType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadType?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
};

export type UploadNewDraftInput = {
  invitationId?: Maybe<Scalars['String']>;
  claimCashoutId?: Maybe<Scalars['String']>;
  allVideos?: Maybe<Array<Maybe<Scalars['String']>>>;
  allMedia?: Maybe<Array<Maybe<Scalars['String']>>>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  draftType?: Maybe<Scalars['String']>;
};

export type User = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  companyId?: Maybe<Scalars['Float']>;
  phoneNumber?: Maybe<Scalars['String']>;
  detail?: Maybe<DetailType>;
  payoutDetails?: Maybe<PayoutDetailType>;
  registerForBeta?: Maybe<Scalars['Boolean']>;
  superAdmin: Scalars['Boolean'];
  agencyId?: Maybe<Scalars['Float']>;
  expressApplicants?: Maybe<Array<PublicExpressApplicant>>;
  googleId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  accounts: Array<Account>;
  paymentRequests: PaymentRequest;
  agency?: Maybe<Agency>;
  adminList: AdminList;
  id?: Maybe<Scalars['Int']>;
};

export type UserAccountDto = {
  username?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
};

export type UserBrandSafetyType = {
  username: Scalars['String'];
  scores: BrandSafetyMetrics;
};

export type WelcomePersonalDetailInput = {
  claimAccountId: Scalars['String'];
  age?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Array<Scalars['String']>>;
};

export type WelcomeProfileDetailInput = {
  claimAccountId: Scalars['String'];
  screenshot?: Maybe<FileInput>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
};

export type WelcomeProfileQueryResult = {
  influencerId: Scalars['String'];
  influencerName: Scalars['String'];
  accountId: Scalars['String'];
  username: Scalars['String'];
  email: Scalars['String'];
  manualAgeBreakdown?: Maybe<Scalars['JSON']>;
  manualGenderBreakdown?: Maybe<Scalars['JSON']>;
  manualLocationBreakdown?: Maybe<Scalars['JSON']>;
  screenshots: Scalars['Float'];
};

export type YouTubeAnalyticsBreakdowns = {
  ageBreakdown: Scalars['JSON'];
  genderBreakdown: Scalars['JSON'];
  locationBreakdown: Scalars['JSON'];
};

export type YouTubeVideoAnalytics = {
  avgViews: Scalars['Float'];
  avgLikes: Scalars['Float'];
  avgDislikes: Scalars['Float'];
  avgComments: Scalars['Float'];
  avgShares: Scalars['Float'];
};

export type AccountQueryResultFragmentFragment = Pick<AccountQueryResult, 'id' | 'result' | 'type'>;

export type GetFilteringAccountsQueryVariables = Exact<{
  searchInput?: Maybe<Scalars['String']>;
  searchType?: Maybe<FilteringAccountSearchType>;
  audienceAge?: Maybe<Array<AudienceBreakdownSearchInput> | AudienceBreakdownSearchInput>;
  audienceGender?: Maybe<Array<AudienceBreakdownSearchInput> | AudienceBreakdownSearchInput>;
  audienceLocation?: Maybe<Array<AudienceBreakdownSearchInput> | AudienceBreakdownSearchInput>;
  accountType?: Maybe<Array<AccountType> | AccountType>;
  influencerAge?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  influencerGender?: Maybe<Array<GenderType> | GenderType>;
  influencerLocation?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  completedCampaigns?: Maybe<CompletedCampaignsSearchInput>;
  status?: Maybe<Array<FilteringAccountStatusType> | FilteringAccountStatusType>;
  tags?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  customTags?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  rating?: Maybe<AccountRatingInput>;
  followers?: Maybe<ComparisonInput>;
  order?: Maybe<FilteringAccountSortInput>;
  accountId?: Maybe<Scalars['Int']>;
  influencerId?: Maybe<Scalars['Int']>;
  campaignId?: Maybe<Scalars['Int']>;
  viewMode?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  campaignIdForSuggestedAccounts?: Maybe<Scalars['Int']>;
  adminListId?: Maybe<Scalars['Int']>;
  adminListIdForNotIncluded?: Maybe<Scalars['Int']>;
}>;


export type GetFilteringAccountsQuery = { getFilteringAccounts: (
    Pick<FilteringAccountResultType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage' | 'totalViews' | 'verifiedViews' | 'verifiedInfluencers' | 'totalRecords'>
    & { accounts: Array<AccountFragementFragment> }
  ) };

export type GetFilteringAccountQueryQueryVariables = Exact<{
  searchInput: Scalars['String'];
}>;


export type GetFilteringAccountQueryQuery = { getFilteringAccountQuery: { account?: Maybe<Array<AccountQueryResultFragmentFragment>>, influencer?: Maybe<Array<AccountQueryResultFragmentFragment>>, tag?: Maybe<Array<AccountQueryResultFragmentFragment>>, campaign?: Maybe<Array<AccountQueryResultFragmentFragment>> } };

export type SuggestMultipleAccountsMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  accountIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type SuggestMultipleAccountsMutation = { suggestMultipleAccounts: Array<Pick<Suggestion, 'id' | 'status' | 'campaignId' | 'cpm' | 'views'>> };

export type CreateAdminListMutationVariables = Exact<{
  listName: Scalars['String'];
}>;


export type CreateAdminListMutation = { createAdminList: (
    Pick<AdminList, 'id' | 'listName'>
    & { accounts?: Maybe<Array<Pick<Account, 'id' | 'type' | 'status' | 'username'>>>, admin?: Maybe<Pick<User, 'email' | 'name' | 'type'>> }
  ) };

export type AddAccountsToAdminListMutationVariables = Exact<{
  accountIds: Array<Scalars['Int']> | Scalars['Int'];
  adminListId: Scalars['Int'];
}>;


export type AddAccountsToAdminListMutation = { addAccountsToAdminList: (
    Pick<AdminList, 'id' | 'listName'>
    & { accounts?: Maybe<Array<Pick<Account, 'id' | 'type' | 'status' | 'username'>>>, admin?: Maybe<Pick<User, 'email' | 'name' | 'type'>> }
  ) };

export type RemoveAccountsFromAdminListMutationVariables = Exact<{
  accountIds: Array<Scalars['Int']> | Scalars['Int'];
  adminListId: Scalars['Int'];
}>;


export type RemoveAccountsFromAdminListMutation = { removeAccountsFromAdminList: (
    Pick<AdminList, 'id' | 'listName'>
    & { accounts?: Maybe<Array<Pick<Account, 'id' | 'type' | 'status' | 'username'>>>, admin?: Maybe<Pick<User, 'email' | 'name' | 'type'>> }
  ) };

export type GetAdminListsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminListsQuery = { getAdminLists: Array<(
    Pick<AdminList, 'adminId' | 'id' | 'listName'>
    & { admin?: Maybe<Pick<User, 'email' | 'name'>>, accounts?: Maybe<Array<Pick<Account, 'id' | 'username'>>> }
  )> };

export type DeleteAdminListMutationVariables = Exact<{
  adminListId: Scalars['Int'];
}>;


export type DeleteAdminListMutation = Pick<Mutation, 'deleteAdminList'>;

export type AdHubMediaTypeFragmentFragment = Pick<AdHubMediaType, 'draftId' | 'draftVideoUrl' | 'accountId' | 'accountName' | 'invitationCost'>;

export type AdHubResultTypeFragmentFragment = { mediaList: Array<AdHubMediaTypeFragmentFragment>, isAuthorised: Pick<AdHubAuthorisedPlatformType, 'TikTok' | 'Facebook' | 'Instagram'>, advertisers: Array<Pick<AdHubAdvertiserType, 'id' | 'name'>> };

export type UploadedVideoReturnTypeFragmentFragment = Pick<UploadedVideoReturnType, 'poster_url' | 'format' | 'url' | 'file_name' | 'video_id' | 'displayable' | 'material_id' | 'height' | 'width' | 'bit_rate' | 'create_time' | 'modify_time' | 'allowed_placements' | 'signature' | 'duration' | 'allow_download' | 'id' | 'size'>;

export type GetAdHubMediaListQueryVariables = Exact<{
  accountName?: Maybe<Scalars['String']>;
  campaignId: Scalars['Int'];
}>;


export type GetAdHubMediaListQuery = { getAdHubMediaList: AdHubResultTypeFragmentFragment };

export type SetAuthCodeToCampaignCompanyMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  authCode: Scalars['String'];
}>;


export type SetAuthCodeToCampaignCompanyMutation = { setAuthCodeToCampaignCompany: CompanyFragmentFragment };

export type UploadMediaToTikTokCreativeMutationVariables = Exact<{
  fileName: Scalars['String'];
  advertiserId: Scalars['String'];
  draftId: Scalars['Int'];
  campaignId: Scalars['Int'];
}>;


export type UploadMediaToTikTokCreativeMutation = { uploadMediaToTikTokCreative: Array<Pick<UploadedVideoReturnType, 'poster_url' | 'format' | 'url' | 'file_name' | 'video_id' | 'displayable' | 'material_id' | 'height' | 'width' | 'bit_rate' | 'create_time' | 'modify_time' | 'allowed_placements' | 'signature' | 'duration' | 'allow_download' | 'id' | 'size'>> };

export type CreateCampaignMilestoneMutationVariables = Exact<{
  notification: CampaignMilestoneOptionInput;
  visibility: CampaignMilestoneOptionInput;
  metric: CampaignMilestoneMetric;
  goal: Scalars['Float'];
  name: Scalars['String'];
  campaignId: Scalars['Int'];
}>;


export type CreateCampaignMilestoneMutation = { createCampaignMilestone?: Maybe<Pick<CampaignMilestone, 'id'>> };

export type UpdateCampaignMilestoneMutationVariables = Exact<{
  notification: CampaignMilestoneOptionInput;
  visibility: CampaignMilestoneOptionInput;
  metric: CampaignMilestoneMetric;
  goal: Scalars['Float'];
  name: Scalars['String'];
  campaignMilestoneId: Scalars['Int'];
}>;


export type UpdateCampaignMilestoneMutation = { updateCampaignMilestone?: Maybe<Pick<CampaignMilestone, 'id'>> };

export type DeleteCampaignMilestoneMutationVariables = Exact<{
  campaignMilestoneId: Scalars['Int'];
}>;


export type DeleteCampaignMilestoneMutation = { deleteCampaignMilestone?: Maybe<Pick<CampaignMilestone, 'milestoneName'>> };

export type GetCampaignMilestonesQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetCampaignMilestonesQuery = { getCampaignMilestones: { milestones?: Maybe<Array<(
      Pick<CampaignMilestone, 'id' | 'actualValue' | 'milestoneName' | 'metric' | 'goal' | 'achieved' | 'createdAt' | 'checkedAt' | 'updatedAt' | 'achievedAt'>
      & { visibility: Pick<CampaignMilestoneOptionType, 'admins' | 'clients'>, notification: Pick<CampaignMilestoneOptionType, 'admins' | 'clients'> }
    )>> } };

export type CreateCampaignAnalyticsTrackingMutationVariables = Exact<{
  trackingKey: Scalars['String'];
  campaignId: Scalars['Int'];
}>;


export type CreateCampaignAnalyticsTrackingMutation = { createCampaignAnalyticsTracking?: Maybe<CampaignFragmentFragment> };

export type GetCampaignAnalyticsTrackingQueryVariables = Exact<{
  trackingType?: Maybe<CampaignTrackingTypeEnum>;
  campaignId: Scalars['Int'];
}>;


export type GetCampaignAnalyticsTrackingQuery = { getCampaignAnalyticsTracking?: Maybe<Array<(
    Pick<CampaignAnalyticsTrackingType, 'title' | 'trackingKey' | 'lastUpdatedDate'>
    & { graphData?: Maybe<Array<(
      Pick<CampaignAnalyticsTrackingGraphType, 'key' | 'views' | 'posts' | 'posted'>
      & { accounts?: Maybe<Array<Pick<CampaignAnalyticsTrackingAccountType, 'id' | 'name' | 'image'>>> }
    )>> }
  )>> };

export type GetCampaignAnalyticsPostsQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetCampaignAnalyticsPostsQuery = { getCampaignAnalyticsPosts?: Maybe<Array<(
    Pick<CampaignAnalyticPostedAccount, 'id' | 'invitationId' | 'username' | 'profilePicUrl' | 'postedDate' | 'views'>
    & { graphData?: Maybe<Array<Pick<CampaignAnalyticsPostGraphType, 'key' | 'engagementRate' | 'views' | 'likes' | 'comments'>>> }
  )>> };

export type GetCampaignAnalyticQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetCampaignAnalyticQuery = { getCampaignAnalytic: (
    Pick<CampaignAnalytic, 'id' | 'status' | 'views' | 'screenshots' | 'clicks' | 'uniqueClicks' | 'installs' | 'cities' | 'countries' | 'platforms' | 'completionRate' | 'budgetPennies' | 'cpcPennies' | 'cpiPennies' | 'ctr' | 'cpm' | 'clicksToInstalls'>
    & { clicksByHour?: Maybe<Pick<CampaignAnalyticLabelData, 'labels' | 'data'>>, installsByHour?: Maybe<Pick<CampaignAnalyticLabelData, 'labels' | 'data'>>, metadata: (
      Pick<CampaignMetadataType, 'likeCount' | 'commentCount' | 'engagementRate'>
      & { tracking?: Maybe<Array<Pick<CampaignTrackingType, 'key' | 'id' | 'type' | 'date'>>>, rating?: Maybe<Pick<CampaignRatingType, 'helpfulness'>> }
    ), noInfluencersPosted?: Maybe<Pick<CampaignAnalyticPostedTotal, 'posted' | 'total'>>, postedAccounts?: Maybe<Array<(
      Pick<CampaignAnalyticPostedAccount, 'id' | 'invitationId' | 'username' | 'profilePicUrl' | 'postedDate'>
      & { graphData?: Maybe<Array<Pick<CampaignAnalyticsPostGraphType, 'key' | 'engagementRate' | 'views' | 'likes' | 'comments'>>> }
    )>>, invitations: Array<InvitationFragmentFragment> }
  ) };

export type UpdateCampaignAnalyticsTrackingMutationVariables = Exact<{
  trackingKey: Scalars['String'];
  trackingIndex: Scalars['Float'];
  campaignId: Scalars['Int'];
}>;


export type UpdateCampaignAnalyticsTrackingMutation = { updateCampaignAnalyticsTracking?: Maybe<CampaignFragmentFragment> };

export type DeleteCampaignAnalyticsTrackingMutationVariables = Exact<{
  trackingKey: Scalars['String'];
  campaignId: Scalars['Int'];
}>;


export type DeleteCampaignAnalyticsTrackingMutation = { deleteCampaignAnalyticsTracking?: Maybe<CampaignFragmentFragment> };

export type GetCampaignGroupByIdQueryVariables = Exact<{
  groupId: Scalars['Float'];
}>;


export type GetCampaignGroupByIdQuery = { getCampaignGroupById: CampaignGroupFragmentFragment };

export type SearchCampaignsByCampaignGroupIdQueryVariables = Exact<{
  page?: Maybe<Scalars['Float']>;
  groupId: Scalars['Int'];
  campaignTitle?: Maybe<Scalars['String']>;
}>;


export type SearchCampaignsByCampaignGroupIdQuery = { searchCampaignsByCampaignGroupId: (
    Pick<CampaignResultType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { campaigns: Array<CampaignFragmentFragment> }
  ) };

export type UpdateCampaignGroupMutationVariables = Exact<{
  groupId: Scalars['Int'];
  groupName: Scalars['String'];
  companyId: Scalars['Int'];
  extraBrandIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type UpdateCampaignGroupMutation = { updateCampaignGroup: CampaignGroupFragmentFragment };

export type GetCampaignsForCampaignGroupQueryVariables = Exact<{
  page?: Maybe<Scalars['Float']>;
  groupId: Scalars['Int'];
  campaignTitle?: Maybe<Scalars['String']>;
}>;


export type GetCampaignsForCampaignGroupQuery = { getCampaignsForCampaignGroup: (
    Pick<CampaignResultType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { campaigns: Array<CampaignFragmentFragment> }
  ) };

export type AddCampaignsToCampaignGroupMutationVariables = Exact<{
  groupId: Scalars['Float'];
  campaignIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddCampaignsToCampaignGroupMutation = { addCampaignsToCampaignGroup: CampaignGroupFragmentFragment };

export type RemoveCampaignsFromCampaignGroupMutationVariables = Exact<{
  groupId: Scalars['Float'];
  campaignIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveCampaignsFromCampaignGroupMutation = { removeCampaignsFromCampaignGroup: CampaignGroupFragmentFragment };

export type CreateCampaignsToCampaignGroupMutationVariables = Exact<{
  title: Scalars['String'];
  groupId: Scalars['Int'];
  extraBrandIds: Array<Scalars['Int']> | Scalars['Int'];
  companyId: Scalars['Int'];
}>;


export type CreateCampaignsToCampaignGroupMutation = { createCampaignsToCampaignGroup?: Maybe<CampaignFragmentFragment> };

export type DeleteCampaignGroupMutationVariables = Exact<{
  groupId: Scalars['Int'];
}>;


export type DeleteCampaignGroupMutation = Pick<Mutation, 'deleteCampaignGroup'>;

export type RatingFragmentFragment = Pick<RatingType, 'punctuality' | 'professionalism' | 'quality'>;

export type DetailFragmentFragment = (
  Pick<DetailType, 'vatNumber' | 'address' | 'managed' | 'age' | 'gender' | 'location' | 'flags'>
  & { rating?: Maybe<RatingFragmentFragment> }
);

export type TagFragmentFragment = Pick<Tag, 'id' | 'name' | 'isDefined'>;

export type BankAccountFragmentFragment = Pick<BankAccountType, 'countryCode' | 'fullName' | 'sortCode' | 'accountNumber' | 'recipientAddress' | 'routingNumber' | 'swift' | 'iban' | 'bsbCode' | 'bankName' | 'transitNumber' | 'institutionNumber'>;

export type AgencyFragmentFragment = (
  Pick<Agency, 'id' | 'agencyName'>
  & { payoutDetail?: Maybe<(
    Pick<PayoutDetailType, 'paypalEmail'>
    & { bankAccount?: Maybe<BankAccountFragmentFragment> }
  )> }
);

export type UserFragmentFragment = (
  Pick<User, 'id' | 'name' | 'email' | 'type' | 'companyId' | 'phoneNumber' | 'agencyId' | 'registerForBeta' | 'superAdmin' | 'createdAt' | 'updatedAt'>
  & { detail?: Maybe<DetailFragmentFragment>, payoutDetails?: Maybe<(
    Pick<PayoutDetailType, 'paypalEmail'>
    & { bankAccount?: Maybe<BankAccountFragmentFragment> }
  )>, agency?: Maybe<AgencyFragmentFragment> }
);

export type AccountRatingFragmentFragment = Pick<AccountRatingType, 'creationTime' | 'responseTime' | 'contentQuality' | 'professionalism'>;

export type MetadataFragmentFragment = (
  Pick<MetadataType, 'avgHearts' | 'avgLikes' | 'avgComments' | 'avgViews' | 'engagementRate' | 'description' | 'followers'>
  & { rating?: Maybe<AccountRatingFragmentFragment> }
);

export type UploadFileFragmentFragment = Pick<UploadFile, 'id' | 'fileableType' | 'createdAt' | 'updatedAt' | 'uploadUrl' | 'uploadType' | 'previewUrl'>;

export type CampaignTrackingTypeFragmentFragment = Pick<CampaignTrackingType, 'key' | 'id' | 'type' | 'date'>;

export type CampaignMetadataTypeFragmentFragment = (
  Pick<CampaignMetadataType, 'likeCount' | 'commentCount' | 'engagementRate'>
  & { tracking?: Maybe<Array<CampaignTrackingTypeFragmentFragment>> }
);

export type CompanyFragmentFragment = (
  Pick<Company, 'id' | 'companyName'>
  & { tiktokAds: Pick<TikTokAdsType, 'accessToken'> }
);

export type CampaignFragmentFragment = (
  Pick<Campaign, 'id' | 'title' | 'status' | 'startDate' | 'endDate' | 'adminId' | 'executiveId' | 'companyId' | 'campaignGroupId' | 'budgetPennies' | 'cpiPayoutPennies' | 'distributionPlatforms' | 'ages' | 'genders' | 'locations' | 'notifiedBrandIds' | 'influencerSpendPennies' | 'influencerInviteLimit' | 'additionalTerms' | 'reportLink' | 'reportObjective' | 'briefDocLink' | 'createdAt' | 'updatedAt'>
  & { admin?: Maybe<UserFragmentFragment>, executive?: Maybe<UserFragmentFragment>, metadata: CampaignMetadataTypeFragmentFragment, company?: Maybe<CompanyFragmentFragment> }
);

export type AccountMediaContentFragementFragment = Pick<AccountMediaContent, 'contentUrl' | 'contentType' | 'views' | 'likes'>;

export type AccountFragementFragment = (
  Pick<Account, 'id' | 'type' | 'status' | 'username' | 'payoutPennies' | 'calculatedPayoutPennies' | 'influencerId' | 'cpm' | 'views' | 'format' | 'active' | 'salesAppropriate' | 'profilePicUrl' | 'ageBreakdown' | 'genderBreakdown' | 'locationBreakdown' | 'growthRate' | 'engagementRate' | 'estCostPerPost' | 'actualCostPerPost' | 'lastPaidAmount' | 'lastCampaignDate' | 'isSuggested' | 'createdAt' | 'updatedAt'>
  & { previousBrandedContent?: Maybe<Array<Maybe<AccountMediaContentFragementFragment>>>, previousContent?: Maybe<Array<Maybe<AccountMediaContentFragementFragment>>>, metadata: MetadataFragmentFragment, influencer?: Maybe<UserFragmentFragment>, tags?: Maybe<Array<TagFragmentFragment>>, screenshots?: Maybe<Array<Maybe<UploadFileFragmentFragment>>> }
);

export type DraftFragmentFragment = Pick<Draft, 'id' | 'draftType' | 'status' | 'allMedia' | 'allVideos' | 'videoUrl' | 'caption' | 'description' | 'redraftRequest' | 'approved' | 'submittedToBrand' | 'brandFeedback' | 'adminFeedback' | 'previewAllMedia' | 'createdAt' | 'updatedAt'>;

export type InvitationFragmentFragment = (
  Pick<Invitation, 'id' | 'views' | 'installs' | 'clicks' | 'claimCashoutId' | 'status' | 'estimatedPayoutPennies' | 'actualPaidPennies' | 'payoutCurrency' | 'likeUploadDraft' | 'stage' | 'rationale' | 'contractSigned' | 'metadata' | 'estimatedViews' | 'payoutType' | 'payoutTypeCurrency' | 'payoutTypeOriginalPennies' | 'fixedPayoutPennies' | 'cpm' | 'updatedAt'>
  & { screenshots?: Maybe<Array<Maybe<Pick<UploadFile, 'uploadUrl' | 'uploadType' | 'previewUrl'>>>>, campaign: Pick<Campaign, 'id' | 'title'>, account: AccountFragementFragment, drafts?: Maybe<Array<Maybe<DraftFragmentFragment>>> }
);

export type SuggestionFragmentFragment = (
  Pick<Suggestion, 'id' | 'status' | 'campaignId' | 'createdAt' | 'updatedAt' | 'cpm' | 'views' | 'payoutType' | 'rationale' | 'fixedPayoutPennies' | 'estimatedPayoutPennies' | 'campaignInvitationId'>
  & { campaign?: Maybe<CampaignFragmentFragment>, account?: Maybe<AccountFragementFragment> }
);

export type CampaignGroupFragmentFragment = (
  Pick<CampaignGroup, 'id' | 'companyId' | 'groupName' | 'status' | 'objective' | 'budgetPennies' | 'createdAt' | 'updatedAt'>
  & { extraBrands?: Maybe<Array<UserFragmentFragment>>, company: CompanyFragmentFragment, campaigns: Array<CampaignFragmentFragment> }
);

export type GroupedCampaignTypeFragmentFragment = Pick<GroupedCampaignType, 'id' | 'title' | 'platforms' | 'status' | 'startDate' | 'budgetCurrency' | 'budgetPennies' | 'companyId' | 'adFormat' | 'views' | 'clicks' | 'createdAt' | 'costOfSales' | 'approvedPayments' | 'totalPayments' | 'isGroup'>;

export type GetSuggestionAccountsByCampaignIdQueryVariables = Exact<{
  campaignId: Scalars['Float'];
  matchType?: Maybe<BreakdownType>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetSuggestionAccountsByCampaignIdQuery = { getSuggestionAccountsByCampaignId?: Maybe<(
    Pick<SuggestionAccountType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { accounts: Array<AccountFragementFragment> }
  )> };

export type DismissSuggestionAccountMutationVariables = Exact<{
  campaignId: Scalars['Float'];
  accountId: Scalars['Float'];
}>;


export type DismissSuggestionAccountMutation = { dismissSuggestionAccount: SuggestionFragmentFragment };

export type CreateSuggestionAccountMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  accountId: Scalars['Int'];
  rationale?: Maybe<Scalars['String']>;
}>;


export type CreateSuggestionAccountMutation = { createSuggestionAccount: SuggestionFragmentFragment };

export type ApplicantFragment = (
  Pick<Account, 'id' | 'type' | 'username' | 'applyStatus' | 'influencerId' | 'profilePicUrl'>
  & { metadata: Pick<MetadataType, 'avgLikes' | 'avgViews' | 'avgComments'> }
);

export type SuggestedApplicantFragment = (
  Pick<Suggestion, 'id' | 'status'>
  & { account?: Maybe<Pick<Account, 'id' | 'type' | 'username'>> }
);

export type GetPublicCampaignApplicantsQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetPublicCampaignApplicantsQuery = { getPublicCampaignApplicants?: Maybe<{ express?: Maybe<Array<Maybe<Pick<PublicExpressApplicant, 'email' | 'status' | 'platform' | 'username' | 'expectedFee'>>>>, applicants?: Maybe<Array<Maybe<ApplicantFragment>>>, accepted?: Maybe<Array<Maybe<ApplicantFragment>>>, rejected?: Maybe<Array<Maybe<ApplicantFragment>>> }> };

export type AcceptPublicCampaignApplicantMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  accountId: Scalars['Int'];
}>;


export type AcceptPublicCampaignApplicantMutation = { acceptPublicCampaignApplicant?: Maybe<SuggestedApplicantFragment> };

export type RejectPublicCampaignApplicantMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  accountId: Scalars['Int'];
}>;


export type RejectPublicCampaignApplicantMutation = { rejectPublicCampaignApplicant?: Maybe<SuggestedApplicantFragment> };

export type RollbackPublicCampaignApplicantMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  accountId: Scalars['Int'];
}>;


export type RollbackPublicCampaignApplicantMutation = { rollbackPublicCampaignApplicant?: Maybe<SuggestedApplicantFragment> };

export type ConfirmPublicCampaignApplicantMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  accountId: Scalars['Int'];
}>;


export type ConfirmPublicCampaignApplicantMutation = { confirmPublicCampaignApplicant?: Maybe<SuggestedApplicantFragment> };

export type NotifyPublicCampaignApplicantMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  accountId: Scalars['Int'];
}>;


export type NotifyPublicCampaignApplicantMutation = { notifyPublicCampaignApplicant?: Maybe<SuggestedApplicantFragment> };

export type RequestDataFromExpressApplicantMutationVariables = Exact<{
  email: Scalars['String'];
  username: Scalars['String'];
  platform: Scalars['String'];
  campaignId: Scalars['Int'];
}>;


export type RequestDataFromExpressApplicantMutation = { requestDataFromExpressApplicant?: Maybe<Pick<PublicExpressApplicant, 'id'>> };

export type GetScreenshotSignedUrlMutationVariables = Exact<{
  fileName: Scalars['String'];
  fileType: Scalars['String'];
}>;


export type GetScreenshotSignedUrlMutation = { getScreenshotSignedURL: Pick<SignedUrl, 'url' | 'uploadUrl'> };

export type GetPaymentRequestInvitationByIdQueryVariables = Exact<{
  invitationId: Scalars['Float'];
}>;


export type GetPaymentRequestInvitationByIdQuery = { getPaymentRequestInvitationById: InvitationFragmentFragment };

export type SetAccountBreakdownAfterPaymentRequestMutationVariables = Exact<{
  accountId: Scalars['Float'];
  followers?: Maybe<Scalars['Float']>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
}>;


export type SetAccountBreakdownAfterPaymentRequestMutation = { setAccountBreakdownAfterPaymentRequest?: Maybe<AccountFragementFragment> };

export type UpdateAccountRatingMutationVariables = Exact<{
  accountId: Scalars['Int'];
  creationTime?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  contentQuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
}>;


export type UpdateAccountRatingMutation = { updateAccountRating: AccountFragementFragment };

export type GetCampaignReportingQueryVariables = Exact<{
  campaignId: Scalars['Float'];
}>;


export type GetCampaignReportingQuery = { getCampaignReporting?: Maybe<CampaignFragmentFragment> };

export type UpdateCampaignReportingMutationVariables = Exact<{
  reportObjective?: Maybe<Scalars['String']>;
  reportLink?: Maybe<Scalars['String']>;
  campaignId: Scalars['Float'];
}>;


export type UpdateCampaignReportingMutation = { updateCampaignReporting?: Maybe<CampaignFragmentFragment> };

export type GetHubSpotDealValidationByIdQueryVariables = Exact<{
  dealId: Scalars['String'];
}>;


export type GetHubSpotDealValidationByIdQuery = { getHubSpotDealValidationById: Pick<HubSpotDealValidationType, 'isDealValid'> };

export type CreateCampaignGroupMutationVariables = Exact<{
  groupName: Scalars['String'];
  companyId: Scalars['Int'];
  extraBrandIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type CreateCampaignGroupMutation = { createCampaignGroup: CampaignGroupFragmentFragment };

export type GetAllCampaignGroupsQueryVariables = Exact<{
  page?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
}>;


export type GetAllCampaignGroupsQuery = { getAllCampaignGroups: (
    Pick<CampaignGroupResultType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { campaignGroups: Array<CampaignGroupFragmentFragment> }
  ) };

export type GetAllCompaniesQueryVariables = Exact<{
  page?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
}>;


export type GetAllCompaniesQuery = { getAllCompanies: (
    Pick<CompanyResultType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { companies: Array<CompanyFragmentFragment> }
  ) };

export type GetAllGroupedCampaignsQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  status?: Maybe<Array<GroupedCampaignStatusType> | GroupedCampaignStatusType>;
  startedAfter?: Maybe<Scalars['DateTime']>;
  platforms?: Maybe<Array<Maybe<GroupedCampaignPlatformType>> | Maybe<GroupedCampaignPlatformType>>;
  companyId?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  customTags?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  costOfSales?: Maybe<GroupedCampaignMoneyRangeInput>;
  budget?: Maybe<GroupedCampaignMoneyRangeInput>;
  locations?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  order?: Maybe<GroupedCampaignSortInput>;
  adminExecId?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  paymentCurrency?: Maybe<Scalars['String']>;
  hubspotDealId?: Maybe<Scalars['String']>;
}>;


export type GetAllGroupedCampaignsQuery = { getAllGroupedCampaigns: (
    Pick<GroupedCampaignResultType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage' | 'campaignNumbers' | 'totalViews' | 'totalSpent'>
    & { groupedCampaigns: Array<GroupedCampaignTypeFragmentFragment> }
  ) };

export type DeleteDraftCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type DeleteDraftCampaignMutation = Pick<Mutation, 'deleteDraftCampaign'>;

export type GetAllBrandsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  brandName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
}>;


export type GetAllBrandsQuery = { getAllBrands: (
    Pick<BrandResultType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { brands: Array<UserFragmentFragment> }
  ) };

export type GetAllAdminsQueryVariables = Exact<{
  adminName?: Maybe<Scalars['String']>;
}>;


export type GetAllAdminsQuery = { getAllAdmins: { admins: Array<Pick<User, 'id' | 'name'>> } };

export type DiscoverCategoryTypeFragmentFragment = Pick<DiscoverCategoryType, 'accountName' | 'bio' | 'avatarUrl' | 'lastVideoId' | 'followers' | 'growthRate' | 'averageHearts' | 'averageComments' | 'averageShares' | 'averageViews' | 'engagementRate' | 'isTracked'>;

export type DiscoverSearchingTypeFragmentFragment = Pick<DiscoverSearchingType, 'accountName' | 'followers' | 'averageHearts' | 'averageComments' | 'averageShares' | 'averageViews' | 'engagementRate'>;

export type GetDiscoverCategoryTopInfluencersQueryVariables = Exact<{
  category: DiscoverCategory;
}>;


export type GetDiscoverCategoryTopInfluencersQuery = { getDiscoverCategoryTopInfluencers?: Maybe<{ views?: Maybe<Array<Maybe<DiscoverCategoryTypeFragmentFragment>>>, engagement?: Maybe<Array<Maybe<DiscoverCategoryTypeFragmentFragment>>>, growth?: Maybe<Array<Maybe<DiscoverCategoryTypeFragmentFragment>>> }> };

export type GetDiscoverSearchingTopInfluencersQueryVariables = Exact<{
  searchInput?: Maybe<Scalars['String']>;
}>;


export type GetDiscoverSearchingTopInfluencersQuery = { getDiscoverSearchingTopInfluencers?: Maybe<Array<DiscoverSearchingTypeFragmentFragment>> };

export type GetDiscoverTrackingInfluencersFromAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiscoverTrackingInfluencersFromAdminQuery = { getDiscoverTrackingInfluencersFromAdmin?: Maybe<Array<(
    Pick<DiscoverTrackingType, 'accountName' | 'followers' | 'averageHearts' | 'averageComments' | 'averageShares' | 'averageViews' | 'engagementRate'>
    & { graphData?: Maybe<Array<Pick<DiscoverTrackingGraphDataType, 'time' | 'averageViews' | 'followers' | 'engagementRate'>>> }
  )>> };

export type RemoveDiscoverTrackingInfluencerFromAdminMutationVariables = Exact<{
  accountName: Scalars['String'];
}>;


export type RemoveDiscoverTrackingInfluencerFromAdminMutation = { removeDiscoverTrackingInfluencerFromAdmin: UserFragmentFragment };

export type AddDiscoverTrackingInfluencerToAdminMutationVariables = Exact<{
  accountName: Scalars['String'];
}>;


export type AddDiscoverTrackingInfluencerToAdminMutation = { addDiscoverTrackingInfluencerToAdmin: UserFragmentFragment };

export type NoteFragmentFragment = Pick<InfluencerProfileNote, 'title' | 'body' | 'createdAt'>;

export type AccountDetailFragment = (
  Pick<InfluencerProfileAccountDetailType, 'username' | 'type' | 'profileImage' | 'metadata' | 'isOnCampaign' | 'growthRate' | 'engagementRate' | 'estCostPerPost' | 'actualCostPerPost'>
  & { tags?: Maybe<Array<TagFragmentFragment>> }
);

export type GraphDataFragment = Pick<InfluencerProfileAccountGraphData, 'key' | 'value'>;

export type BreakdownGraphDataFragment = { manual?: Maybe<Array<GraphDataFragment>>, live?: Maybe<Array<GraphDataFragment>> };

export type GetNotesByInfluencerIdQueryVariables = Exact<{
  influencerId: Scalars['Float'];
}>;


export type GetNotesByInfluencerIdQuery = { getNotesByInfluencerId?: Maybe<Array<NoteFragmentFragment>> };

export type CreateNewNoteByInfluencerIdMutationVariables = Exact<{
  influencerId: Scalars['Int'];
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type CreateNewNoteByInfluencerIdMutation = { createNewNoteByInfluencerId: Array<NoteFragmentFragment> };

export type UpdateNotesByInfluencerIdMutationVariables = Exact<{
  notes?: Maybe<Array<NoteInput> | NoteInput>;
  influencerId: Scalars['Int'];
}>;


export type UpdateNotesByInfluencerIdMutation = { updateNotesByInfluencerId?: Maybe<Array<NoteFragmentFragment>> };

export type GetCampaignsByInfluencerIdQueryVariables = Exact<{
  searchInput?: Maybe<Scalars['String']>;
  influencerId: Scalars['Float'];
}>;


export type GetCampaignsByInfluencerIdQuery = { getCampaignsByInfluencerId: Array<Pick<InfluencerProfileCampaign, 'id' | 'title' | 'amount' | 'account' | 'requestDate' | 'currency' | 'campaignStatus' | 'paymentRequestStatus'>> };

export type GetProfileByInfluencerIdQueryVariables = Exact<{
  influencerId: Scalars['Float'];
}>;


export type GetProfileByInfluencerIdQuery = { getProfileByInfluencerId?: Maybe<(
    Pick<InfluencerProfile, 'pictureUrl' | 'name' | 'email' | 'punctuality' | 'professionalism' | 'quality' | 'flags'>
    & { accounts?: Maybe<Array<AccountFragementFragment>> }
  )> };

export type GetInfluencerByIdQueryVariables = Exact<{
  influencerId: Scalars['Float'];
}>;


export type GetInfluencerByIdQuery = { getInfluencerById: UserFragmentFragment };

export type GetAllAgenciesQueryVariables = Exact<{
  agencyName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
}>;


export type GetAllAgenciesQuery = { getAllAgencies: Array<AgencyFragmentFragment> };

export type UpdateInfluencerByIdMutationVariables = Exact<{
  id: Scalars['Float'];
  name: Scalars['String'];
  email: Scalars['String'];
  paypalEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['Int']>;
  managed?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  age?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  location?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  punctuality?: Maybe<Scalars['Float']>;
  professionalism?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
  flags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateInfluencerByIdMutation = { updateInfluencerById: UserFragmentFragment };

export type GetAccountDetailByIdQueryVariables = Exact<{
  accountId: Scalars['String'];
  platformType: DataSourcePlatform;
}>;


export type GetAccountDetailByIdQuery = { getAccountDetailById?: Maybe<AccountDetailFragment> };

export type GetAllTagsQueryVariables = Exact<{
  tagName?: Maybe<Scalars['String']>;
}>;


export type GetAllTagsQuery = { getAllTags: Array<TagFragmentFragment> };

export type UpdateAccountDetailByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  tags: Array<TagInput> | TagInput;
  actualCostPerPost?: Maybe<Scalars['Float']>;
}>;


export type UpdateAccountDetailByIdMutation = { updateAccountDetailById: AccountFragementFragment };

export type GetCurrentFollowersByAccountIdQueryVariables = Exact<{
  accountId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  filter?: Maybe<LineChartFilter>;
  platformType: DataSourcePlatform;
}>;


export type GetCurrentFollowersByAccountIdQuery = { getCurrentFollowersByAccountId: Array<GraphDataFragment> };

export type GetAverageCommentsByAccountIdQueryVariables = Exact<{
  accountId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  filter?: Maybe<LineChartFilter>;
  platformType: DataSourcePlatform;
}>;


export type GetAverageCommentsByAccountIdQuery = { getAverageCommentsByAccountId: Array<GraphDataFragment> };

export type GetAverageHeartsByAccountIdQueryVariables = Exact<{
  accountId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  filter?: Maybe<LineChartFilter>;
  platformType: DataSourcePlatform;
}>;


export type GetAverageHeartsByAccountIdQuery = { getAverageHeartsByAccountId: Array<GraphDataFragment> };

export type GetAverageViewsByAccountIdQueryVariables = Exact<{
  accountId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  filter?: Maybe<LineChartFilter>;
  platformType: DataSourcePlatform;
}>;


export type GetAverageViewsByAccountIdQuery = { getAverageViewsByAccountId: Array<GraphDataFragment> };

export type SetBreakdownByAccountIdMutationVariables = Exact<{
  accountId: Scalars['String'];
  type: BreakdownType;
  data: Scalars['JSON'];
}>;


export type SetBreakdownByAccountIdMutation = { setBreakdownByAccountId: AccountFragementFragment };

export type GetAgeBreakdownByAccountIdQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetAgeBreakdownByAccountIdQuery = { getAgeBreakdownByAccountId: BreakdownGraphDataFragment };

export type GetGenderBreakdownByAccountIdQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetGenderBreakdownByAccountIdQuery = { getGenderBreakdownByAccountId: BreakdownGraphDataFragment };

export type GetLocationBreakdownByAccountIdQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetLocationBreakdownByAccountIdQuery = { getLocationBreakdownByAccountId: BreakdownGraphDataFragment };

export type SearchAccountDataFragment = Pick<SearchAccountType, 'accountId' | 'influencerId' | 'username' | 'influencerName' | 'platform' | 'format' | 'campaignCount' | 'engCTR' | 'price' | 'avgLikes' | 'avgViews' | 'avgComments' | 'followers' | 'createdAt'>;

export type SearchInfluencerAccountsQueryVariables = Exact<{
  platformType: DataSourcePlatform;
  searchInput?: Maybe<Scalars['String']>;
  metricSelect?: Maybe<MetricSelectType>;
  campaignIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  accountType?: Maybe<AccountType>;
  influencerGender?: Maybe<GenderType>;
  influencerLocation?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  followers?: Maybe<NumberComparison>;
  hearts?: Maybe<NumberComparison>;
  price?: Maybe<NumberComparison>;
  includeEstimatedCosts?: Maybe<Scalars['Boolean']>;
  audienceAge?: Maybe<NumberComparisonWithKey>;
  audienceGender?: Maybe<NumberComparisonWithKey>;
  audienceLocation?: Maybe<NumberComparisonWithKey>;
  page?: Maybe<Scalars['Int']>;
  exportCSV?: Maybe<Scalars['Boolean']>;
}>;


export type SearchInfluencerAccountsQuery = { searchInfluencerAccounts?: Maybe<(
    Pick<SearchAccountQueueType, 'totalEntries' | 'perPage' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { searchAccounts?: Maybe<Array<SearchAccountDataFragment>> }
  )> };

export type CreateAccountMutationVariables = Exact<{
  data: NewAccountInput;
}>;


export type CreateAccountMutation = { createAccount: AccountFragementFragment };

export type GetAllInfluencersQueryVariables = Exact<{
  influencerName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
}>;


export type GetAllInfluencersQuery = { getAllInfluencers: Array<Pick<User, 'id' | 'name'>> };

export type PaymentRequestFragmentFragment = (
  Pick<PaymentRequest, 'id' | 'status' | 'description' | 'vatNumber' | 'vatAmount' | 'payoutPenniesExclVat' | 'amount' | 'currency' | 'conversionRate' | 'payoutPennies' | 'paidAt' | 'dueDate' | 'failedReason' | 'attachments' | 'previewAttachments' | 'agencyName' | 'createdAt' | 'paymentDetail'>
  & { campaign?: Maybe<Pick<Campaign, 'id' | 'title'>>, influencer?: Maybe<Pick<User, 'id' | 'name'>>, requestedBy: Pick<User, 'id' | 'name'> }
);

export type PaymentQueryResultFragmentFragment = Pick<PaymentQueryResult, 'result' | 'type'>;

export type BankAccountTypeFragmentFragment = Pick<BankAccountType, 'countryCode' | 'fullName' | 'sortCode' | 'accountNumber' | 'swift' | 'recipientAddress' | 'routingNumber' | 'iban' | 'bsbCode' | 'bankName' | 'transitNumber' | 'institutionNumber'>;

export type CreateNewPaymentRequestMutationVariables = Exact<{
  campaignId?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency: Scalars['String'];
  paymentDetail?: Maybe<PaymentDetailType>;
  attachments?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  agencyId?: Maybe<Scalars['Int']>;
  agencyName?: Maybe<Scalars['String']>;
  agencyAccountIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  invitationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  actualViews?: Maybe<Scalars['Int']>;
  screenshots?: Maybe<Array<FileInput> | FileInput>;
  dontCreatePaymentRequest?: Maybe<Scalars['Boolean']>;
}>;


export type CreateNewPaymentRequestMutation = { createNewPaymentRequest?: Maybe<{ paymentRequest?: Maybe<PaymentRequestFragmentFragment>, invitation?: Maybe<InvitationFragmentFragment> }> };

export type GetAllCampaignsQueryVariables = Exact<{
  campaignTitle?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
}>;


export type GetAllCampaignsQuery = { getAllCampaigns: Array<Pick<Campaign, 'id' | 'title' | 'status'>> };

export type GetAccountsByCampaignIdQueryVariables = Exact<{
  campaignId?: Maybe<Scalars['Float']>;
  accountName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
}>;


export type GetAccountsByCampaignIdQuery = { getAccountsByCampaignId?: Maybe<Array<AccountFragementFragment>> };

export type UpdateInfleucnerPayoutDetailByIdMutationVariables = Exact<{
  influencerId: Scalars['Int'];
  paymentDetail: PaymentDetailType;
}>;


export type UpdateInfleucnerPayoutDetailByIdMutation = { updateInfleucnerPayoutDetailById: UserFragmentFragment };

export type GetPaymentRequestsQueryVariables = Exact<{
  searchInput?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  influencer?: Maybe<Scalars['String']>;
  requestedBy?: Maybe<Scalars['String']>;
  paymentRequestId?: Maybe<Scalars['Int']>;
  campaignId?: Maybe<Scalars['Int']>;
  paypalTransactionId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  isGreaterThan?: Maybe<Scalars['Boolean']>;
  typeOfCost?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  dueDateFrom?: Maybe<Scalars['DateTime']>;
  dueDateTo?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  paymentType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  paymentCurrency?: Maybe<Scalars['String']>;
  hubspotDealId?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GetPaymentRequestsQuery = { getPaymentRequests: (
    Pick<PaymentQueueType, 'paymentCount' | 'totalAmount' | 'totalNetAmount' | 'totalVatAmount' | 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { paymentRequests: Array<PaymentRequestFragmentFragment> }
  ) };

export type AcceptPaymentRequestMutationVariables = Exact<{
  paymentRequestId: Scalars['Float'];
}>;


export type AcceptPaymentRequestMutation = { acceptPaymentRequest: PaymentRequestFragmentFragment };

export type RejectPaymentRequestMutationVariables = Exact<{
  paymentRequestId: Scalars['Float'];
}>;


export type RejectPaymentRequestMutation = { rejectPaymentRequest: PaymentRequestFragmentFragment };

export type UndoPaymentRequestMutationVariables = Exact<{
  paymentRequestId: Scalars['Float'];
}>;


export type UndoPaymentRequestMutation = { undoPaymentRequest: PaymentRequestFragmentFragment };

export type DeletePaymentRequestMutationVariables = Exact<{
  paymentRequestId: Scalars['Float'];
}>;


export type DeletePaymentRequestMutation = { deletePaymentRequest?: Maybe<PaymentRequestFragmentFragment> };

export type ReconcilePaymentRequestMutationVariables = Exact<{
  paymentRequestId: Scalars['Float'];
}>;


export type ReconcilePaymentRequestMutation = { reconcilePaymentRequest: PaymentRequestFragmentFragment };

export type GetPaymentRequestQueryQueryVariables = Exact<{
  searchInput: Scalars['String'];
}>;


export type GetPaymentRequestQueryQuery = { getPaymentRequestQuery: { campaign?: Maybe<Array<PaymentQueryResultFragmentFragment>>, influencer?: Maybe<Array<PaymentQueryResultFragmentFragment>>, requestedBy?: Maybe<Array<PaymentQueryResultFragmentFragment>>, id?: Maybe<Array<PaymentQueryResultFragmentFragment>> } };

export type UpdateAgencyPayoutDetailByIdMutationVariables = Exact<{
  agencyId: Scalars['Int'];
  paymentDetail: PaymentDetailType;
}>;


export type UpdateAgencyPayoutDetailByIdMutation = { updateAgencyPayoutDetailById: (
    Pick<Agency, 'id' | 'agencyName'>
    & { payoutDetail?: Maybe<(
      Pick<PayoutDetailType, 'paypalEmail'>
      & { bankAccount?: Maybe<Pick<BankAccountType, 'countryCode' | 'sortCode' | 'accountNumber'>> }
    )> }
  ) };

export type TikTokUserBrandSafetyTypeFragmentFragment = (
  Pick<TikTokUserBrandSafetyType, 'username' | 'avatarUrl' | 'bio' | 'hygineRating'>
  & { tags: Pick<BrandSafetyTagType, 'image' | 'text' | 'video'>, keywords: Array<Pick<BrandSafetyKeywordType, 'word' | 'count'>>, analysedPosts: Array<(
    Pick<BrandSafetyAnalysedPostType, 'coverUrl' | 'videoUrl' | 'length'>
    & { tags?: Maybe<Pick<BrandSafetyTagType, 'image' | 'text' | 'video'>> }
  )>, mentionedAccounts: Array<Pick<BrandSafetyMentionedAccountType, 'username' | 'accountUrl' | 'count'>> }
);

export type GetTikTokUserBrandSafetyQueryVariables = Exact<{
  tiktokUserUrl: Scalars['String'];
}>;


export type GetTikTokUserBrandSafetyQuery = { getTikTokUserBrandSafety: TikTokUserBrandSafetyTypeFragmentFragment };

export type NewInfluencerSignupMutationVariables = Exact<{
  data: NewInfluencerInput;
}>;


export type NewInfluencerSignupMutation = { newInfluencerSignup: Pick<User, 'id'> };

export type CampaignReportAccountTypeFragmentFragment = (
  Pick<CampaignReportAccountType, 'accountId' | 'profilePicUrl' | 'username' | 'views' | 'engagementRate'>
  & { tags?: Maybe<Array<Pick<Tag, 'id' | 'name' | 'isDefined'>>> }
);

export type CampaignReportPostTypeFragmentFragment = (
  Pick<CampaignReportPostType, 'reach' | 'hearts' | 'comments' | 'engagement' | 'videoUrl'>
  & { account: CampaignReportAccountTypeFragmentFragment }
);

export type CampaignReportTopCategoryTypeFragmentFragment = (
  Pick<CampaignReportTopCategoryType, 'type'>
  & { data: Array<CampaignReportAccountTypeFragmentFragment> }
);

export type CampaignReportTypeFragmentFragment = (
  Pick<CampaignReportType, 'companyName' | 'campaignTitle' | 'campaignObjective' | 'campaignPlatforms' | 'totalWatchedTime' | 'campaignComments' | 'campaignHearts' | 'campaignEngagementRate' | 'campaignClickThroughtRate' | 'views'>
  & { topViews: Array<CampaignReportAccountTypeFragmentFragment>, categorisedTopViews: Array<CampaignReportTopCategoryTypeFragmentFragment>, categorisedTopEngagements: Array<CampaignReportTopCategoryTypeFragmentFragment>, topEngagements: Array<CampaignReportAccountTypeFragmentFragment>, topClickThroughtRates: Array<CampaignReportAccountTypeFragmentFragment>, mostLikedPost?: Maybe<CampaignReportPostTypeFragmentFragment> }
);

export type GetCampaignInstantReportQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetCampaignInstantReportQuery = { getCampaignInstantReport: CampaignReportTypeFragmentFragment };

export type RateCampaignHelpfulnessMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  helpfulnessRating: Scalars['Float'];
}>;


export type RateCampaignHelpfulnessMutation = { rateCampaignHelpfulness: CampaignFragmentFragment };

export type GetJwtTokenFromGoogleTokenMutationVariables = Exact<{
  access_token: Scalars['String'];
  refresh_token: Scalars['String'];
}>;


export type GetJwtTokenFromGoogleTokenMutation = { getJwtTokenFromGoogleToken: Pick<JwtToken, 'token'> };

export type SuggestionFragment = (
  Pick<Suggestion, 'id' | 'status' | 'rationale' | 'campaignId' | 'campaignInvitationId'>
  & { account?: Maybe<(
    Pick<Account, 'id' | 'type' | 'username' | 'influencerId' | 'profilePicUrl' | 'ageBreakdown' | 'genderBreakdown'>
    & { metadata: Pick<MetadataType, 'followers' | 'engagementRate'>, previousContent?: Maybe<Array<Maybe<Pick<AccountMediaContent, 'contentUrl' | 'contentType'>>>>, previousBrandedContent?: Maybe<Array<Maybe<Pick<AccountMediaContent, 'contentUrl' | 'contentType'>>>> }
  )> }
);

export type PaginatedSuggestionsFragment = (
  Pick<ShareSuggestionsType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
  & { suggestions: Array<SuggestionFragment> }
);

export type GetPendingShareSuggestionsQueryVariables = Exact<{
  campaignId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type GetPendingShareSuggestionsQuery = { getPendingShareSuggestions: (
    Pick<ShareSuggestionsType, 'pageTitle'>
    & PaginatedSuggestionsFragment
  ) };

export type GetAcceptedShareSuggestionsQueryVariables = Exact<{
  campaignId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type GetAcceptedShareSuggestionsQuery = { getAcceptedShareSuggestions: (
    Pick<ShareSuggestionsType, 'pageTitle'>
    & PaginatedSuggestionsFragment
  ) };

export type AddLikedShareSuggestionMutationVariables = Exact<{
  suggestionId: Scalars['Int'];
}>;


export type AddLikedShareSuggestionMutation = { addLikedShareSuggestion: (
    Pick<Suggestion, 'id' | 'status'>
    & { invitation?: Maybe<Pick<Invitation, 'id' | 'status'>> }
  ) };

export type RemoveLikedShareSuggestionMutationVariables = Exact<{
  suggestionId: Scalars['Int'];
}>;


export type RemoveLikedShareSuggestionMutation = { removeLikedShareSuggestion: (
    Pick<Suggestion, 'id' | 'status'>
    & { invitation?: Maybe<Pick<Invitation, 'id' | 'status'>> }
  ) };

export type UpdateShareSuggestionsPageTitileMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  pageTitle: Scalars['String'];
}>;


export type UpdateShareSuggestionsPageTitileMutation = { updateShareSuggestionsPageTitile: (
    Pick<Campaign, 'id' | 'title'>
    & { metadata: Pick<CampaignMetadataType, 'pageTitle'> }
  ) };

export type AcceptedAccountsTypeFragmentFragment = (
  Pick<Suggestion, 'id' | 'status' | 'campaignId' | 'influencerAccountId' | 'campaignInvitationId' | 'cpm' | 'views' | 'payoutType' | 'rationale' | 'fixedPayoutPennies' | 'estimatedPayoutPennies' | 'payoutTypeCurrency' | 'payoutTypeOriginalPennies' | 'sortOrder' | 'createdAt' | 'updatedAt'>
  & { campaign?: Maybe<CampaignFragmentFragment>, account?: Maybe<AccountFragementFragment>, invitation?: Maybe<InvitationFragmentFragment> }
);

export type GetAllSuggestedAccountsByCampaignIdQueryVariables = Exact<{
  campaignId: Scalars['Float'];
}>;


export type GetAllSuggestedAccountsByCampaignIdQuery = { getAllSuggestedAccountsByCampaignId?: Maybe<Array<SuggestionFragmentFragment>> };

export type GetAllAcceptedAccountsByCampaignIdQueryVariables = Exact<{
  campaignId: Scalars['Float'];
}>;


export type GetAllAcceptedAccountsByCampaignIdQuery = { getAllAcceptedAccountsByCampaignId?: Maybe<Array<AcceptedAccountsTypeFragmentFragment>> };

export type GetAllInvitationOfAdminByCampaignIdQueryVariables = Exact<{
  campaignId: Scalars['Float'];
}>;


export type GetAllInvitationOfAdminByCampaignIdQuery = { getAllInvitationOfAdminByCampaignId?: Maybe<Array<InvitationFragmentFragment>> };

export type GetCampaignDetailByCampaignIdQueryVariables = Exact<{
  campaignId: Scalars['Float'];
}>;


export type GetCampaignDetailByCampaignIdQuery = { getCampaignDetailByCampaignId: (
    Pick<CampaignDetailType, 'sentInvitations' | 'invitedSpendPennies'>
    & { campaign: CampaignFragmentFragment }
  ) };

export type RemoveInvitedAccountMutationVariables = Exact<{
  campaignInvitationId: Scalars['Float'];
  suggestionStatus: Scalars['String'];
}>;


export type RemoveInvitedAccountMutation = { removeInvitedAccount: InvitationFragmentFragment };

export type AddSelectedAccountsByCampaignIdMutationVariables = Exact<{
  campaignId: Scalars['Float'];
  accountIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type AddSelectedAccountsByCampaignIdMutation = { addSelectedAccountsByCampaignId: Array<SuggestionFragmentFragment> };

export type UpdateSuggestionAccountMutationVariables = Exact<{
  suggestionId: Scalars['Float'];
  status: Scalars['String'];
}>;


export type UpdateSuggestionAccountMutation = { updateSuggestionAccount: SuggestionFragmentFragment };

export type GetTestLinkByCamapignIdQueryVariables = Exact<{
  campaignId: Scalars['Float'];
}>;


export type GetTestLinkByCamapignIdQuery = { getTestLinkByCamapignId?: Maybe<Pick<Link, 'id' | 'snaplinkPretty' | 'snaplink' | 'clicks' | 'installs' | 'tested' | 'campaignId' | 'campaignInvitationId'>> };

export type GiftingCampaignFragmentFragment = (
  Pick<GiftingCampaign, 'id' | 'title' | 'image' | 'startDate' | 'status' | 'endDate'>
  & { brief: (
    Pick<GiftingBrief, 'aboutBrand' | 'brandName' | 'aboutCampaign' | 'postingGuidlines' | 'linkToWebsite' | 'repurposeContent' | 'campaignType'>
    & { requiredInformation?: Maybe<Pick<RequiredInformation, 'address' | 'phoneNumber'>>, platforms?: Maybe<Pick<Platforms, 'snapchat' | 'instagram' | 'youtube' | 'tiktok'>> }
  ) }
);

export type CreateGiftingCampaignMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateGiftingCampaignMutation = { createGiftingCampaign: GiftingCampaignFragmentFragment };

export type SubmitGiftingCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type SubmitGiftingCampaignMutation = { submitGiftingCampaign: GiftingCampaignFragmentFragment };

export type DeleteGiftingCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type DeleteGiftingCampaignMutation = Pick<Mutation, 'deleteGiftingCampaign'>;

export type UpdateGiftingCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  data: UpdateGiftingCampaign;
}>;


export type UpdateGiftingCampaignMutation = { updateGiftingCampaign: GiftingCampaignFragmentFragment };

export type GetGiftingCampaignsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGiftingCampaignsQuery = { getGiftingCampaigns: Array<GiftingCampaignFragmentFragment> };

export type GetGiftingCampaignQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetGiftingCampaignQuery = { getGiftingCampaign: GiftingCampaignFragmentFragment };

export type GetPublicCampaignsQueryVariables = Exact<{
  searchInput?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  page?: Maybe<Scalars['Int']>;
  isApplied?: Maybe<Scalars['Boolean']>;
}>;


export type GetPublicCampaignsQuery = { getPublicCampaigns?: Maybe<(
    Pick<PublicCampaignResultType, 'totalEntries' | 'perPage' | 'totalPages' | 'previousPage' | 'currentPage' | 'nextPage'>
    & { publicCampaigns: Array<(
      Pick<Campaign, 'id' | 'title' | 'isPublic' | 'isApplied' | 'publicDeadline' | 'distributionPlatforms'>
      & { tags?: Maybe<Array<Pick<Tag, 'id' | 'name'>>> }
    )> }
  )> };

export type GetPublicCampaignQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetPublicCampaignQuery = { getPublicCampaign?: Maybe<{ publicCampaign: (
      Pick<Campaign, 'id' | 'brief' | 'title' | 'briefDo' | 'isPublic' | 'adFormat' | 'isApplied' | 'briefDont' | 'createdAt' | 'objective' | 'startDate' | 'aboutBrand' | 'publicDeadline' | 'distributionPlatforms'>
      & { tags?: Maybe<Array<Pick<Tag, 'id' | 'name'>>>, creative?: Maybe<{ video?: Maybe<Pick<CreativeVideo, 'id' | 'url' | 'secureUrl' | 'resourceType'>> }>, metadata: Pick<CampaignMetadataType, 'creativeLinks'>, admin?: Maybe<Pick<User, 'email'>>, executive?: Maybe<Pick<User, 'email'>>, applicants?: Maybe<Array<Pick<Account, 'id' | 'username'>>>, company?: Maybe<Pick<Company, 'companyName'>> }
    ), influencerAccounts: Array<Pick<Account, 'id' | 'username' | 'type'>> }> };

export type ApplyPublicCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  accountId: Scalars['Int'];
}>;


export type ApplyPublicCampaignMutation = { applyPublicCampaign?: Maybe<(
    Pick<Campaign, 'id' | 'title'>
    & { applicants?: Maybe<Array<Pick<Account, 'id' | 'username'>>> }
  )> };

export type SignUpPublicInfluencerMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  location: Scalars['String'];
  age: Scalars['String'];
  password: Scalars['String'];
  gender: Scalars['String'];
}>;


export type SignUpPublicInfluencerMutation = { signUpPublicInfluencer?: Maybe<(
    Pick<PublicCampaignAuthTokenType, 'authToken'>
    & { influencer: { expressApplicants?: Maybe<Array<Pick<PublicExpressApplicant, 'username' | 'platform'>>> } }
  )> };

export type ConnectPublicYouTubeAccountMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type ConnectPublicYouTubeAccountMutation = { connectPublicYouTubeAccount?: Maybe<(
    Pick<Account, 'id' | 'type' | 'username' | 'views' | 'ageBreakdown' | 'genderBreakdown' | 'locationBreakdown'>
    & { influencer?: Maybe<Pick<User, 'id' | 'name'>>, metadata: Pick<MetadataType, 'avgViews' | 'avgLikes' | 'avgComments' | 'description' | 'followers' | 'engagementRate'> }
  )> };

export type UpdatePublicInfluencerFlagsMutationVariables = Exact<{
  flags: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdatePublicInfluencerFlagsMutation = { updatePublicInfluencerFlags?: Maybe<(
    Pick<User, 'id' | 'name'>
    & { detail?: Maybe<Pick<DetailType, 'flags'>> }
  )> };

export type GetPublicInfluencerProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublicInfluencerProfileQuery = { getPublicInfluencerProfile?: Maybe<(
    Pick<User, 'id' | 'name'>
    & { detail?: Maybe<Pick<DetailType, 'age' | 'flags' | 'gender' | 'location'>>, accounts: Array<(
      Pick<Account, 'id' | 'type' | 'username' | 'views' | 'ageBreakdown' | 'genderBreakdown' | 'locationBreakdown' | 'profilePicUrl'>
      & { metadata: Pick<MetadataType, 'avgViews' | 'avgLikes' | 'avgComments' | 'description' | 'followers' | 'engagementRate'>, posts?: Maybe<Array<(
        Pick<Post, 'snaplyticsContentUrl' | 'typeString' | 'postDatetime' | 'poster'>
        & { metadata: Pick<PostMetadataType, 'title' | 'postId' | 'viewCount' | 'likeCount' | 'description' | 'webVideoUrl' | 'dislikeCount' | 'commentCount' | 'favoriteCount'> }
      )>> }
    )>, expressApplicants?: Maybe<Array<Pick<PublicExpressApplicant, 'username' | 'platform'>>> }
  )> };

export type UpdatePublicAccountBreakdownsMutationVariables = Exact<{
  accountId: Scalars['Int'];
  screenshots?: Maybe<Array<ScreenshotFileInput> | ScreenshotFileInput>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
}>;


export type UpdatePublicAccountBreakdownsMutation = { updatePublicAccountBreakdowns?: Maybe<(
    Pick<Account, 'id' | 'username' | 'ageBreakdown' | 'genderBreakdown' | 'locationBreakdown'>
    & { screenshots?: Maybe<Array<Maybe<Pick<UploadFile, 'id' | 'uploadUrl'>>>> }
  )> };

export type ConnectPublicTikTokAccountMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type ConnectPublicTikTokAccountMutation = { connectPublicTikTokAccount?: Maybe<Pick<Account, 'id' | 'username'>> };

export type ApplyPublicCampaignForExpressMutationVariables = Exact<{
  expectedFee: Scalars['String'];
  email: Scalars['String'];
  username: Scalars['String'];
  platform: PublicExpressApplicantPlatformType;
  campaignId: Scalars['Int'];
}>;


export type ApplyPublicCampaignForExpressMutation = { applyPublicCampaignForExpress?: Maybe<Pick<PublicExpressApplicant, 'id'>> };

export type InfluencerCashoutInvitationFragmentFragment = (
  Pick<InfluencerCashoutInvitation, 'id' | 'views' | 'installs' | 'clicks' | 'claimCashoutId' | 'status' | 'payoutTypeCurrency' | 'payoutTypeOriginalPennies' | 'fixedPayoutPennies' | 'estimatedPayoutPennies' | 'actualPaidPennies' | 'payoutCurrency' | 'likeUploadDraft' | 'stage' | 'metadata' | 'contractSigned' | 'estimatedViews' | 'payoutType' | 'cpm' | 'updatedAt' | 'isAccountScreenshotOutdated'>
  & { screenshots?: Maybe<Array<Maybe<Pick<UploadFile, 'uploadUrl' | 'uploadType' | 'previewUrl'>>>>, campaign: CampaignFragmentFragment, account: AccountFragementFragment }
);

export type GetInfluencerCashoutInvitationQueryVariables = Exact<{
  claimCashoutId: Scalars['String'];
}>;


export type GetInfluencerCashoutInvitationQuery = { getInfluencerCashoutInvitation: InfluencerCashoutInvitationFragmentFragment };

export type InfluencerCashoutDetailMutationVariables = Exact<{
  claimCashoutId: Scalars['String'];
  viewCount: Scalars['Int'];
  tiktokUrl?: Maybe<Scalars['String']>;
  files: Array<FileInput> | FileInput;
  duration?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  dislikes?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  watchTime?: Maybe<Scalars['Int']>;
}>;


export type InfluencerCashoutDetailMutation = { influencerCashoutDetail: InvitationFragmentFragment };

export type GetSignedUrlMutationVariables = Exact<{
  fileName: Scalars['String'];
  fileType: Scalars['String'];
}>;


export type GetSignedUrlMutation = { getSignedURL: Pick<SignedUrl, 'url' | 'uploadUrl'> };

export type InfluencerCashoutProfileDataMutationVariables = Exact<{
  claimCashoutId: Scalars['String'];
  ageScreenshot?: Maybe<FileInput>;
  genderScreenshot?: Maybe<FileInput>;
  locationScreenshot?: Maybe<FileInput>;
  age?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  gender?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  location?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
  screenshots?: Maybe<Array<FileInput> | FileInput>;
}>;


export type InfluencerCashoutProfileDataMutation = { influencerCashoutProfileData: InvitationFragmentFragment };

export type InfluencerCashoutPaymentDetailMutationVariables = Exact<{
  claimCashoutId: Scalars['String'];
  paypalEmail?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountInput>;
  invoiceScreenshots?: Maybe<Array<FileInput> | FileInput>;
}>;


export type InfluencerCashoutPaymentDetailMutation = { influencerCashoutPaymentDetail: InvitationFragmentFragment };

export type RegisterForBetaMutationVariables = Exact<{
  claimCashoutId: Scalars['String'];
}>;


export type RegisterForBetaMutation = { registerForBeta: InvitationFragmentFragment };

export type GetYoutubeAnalyticsDataByAccessTokenQueryVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type GetYoutubeAnalyticsDataByAccessTokenQuery = { getYoutubeAnalyticsDataByAccessToken: Pick<YouTubeAnalyticsBreakdowns, 'ageBreakdown' | 'genderBreakdown' | 'locationBreakdown'> };

export type SignInitialContractMutationVariables = Exact<{
  claimCashoutId: Scalars['String'];
}>;


export type SignInitialContractMutation = { signInitialContract: InvitationFragmentFragment };

export type GetSubmissionInvitationQueryVariables = Exact<{
  claimCashoutId?: Maybe<Scalars['String']>;
  invitationId?: Maybe<Scalars['String']>;
}>;


export type GetSubmissionInvitationQuery = { getSubmissionInvitation?: Maybe<InvitationFragmentFragment> };

export type UploadNewDraftMutationVariables = Exact<{
  invitationId?: Maybe<Scalars['String']>;
  claimCashoutId?: Maybe<Scalars['String']>;
  allVideos?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  allMedia?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  draftType?: Maybe<Scalars['String']>;
}>;


export type UploadNewDraftMutation = { uploadNewDraft?: Maybe<InvitationFragmentFragment> };

export type GetGeneralSignedUrlMutationVariables = Exact<{
  folder?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileType: Scalars['String'];
}>;


export type GetGeneralSignedUrlMutation = { getGeneralSignedURL: Pick<SignedUrl, 'url' | 'uploadUrl' | 'previewUrl'> };

export type GetTrackingInvitationByCashoutIdQueryVariables = Exact<{
  cashoutId: Scalars['String'];
}>;


export type GetTrackingInvitationByCashoutIdQuery = { getInfluencerCashoutInvitation: (
    Pick<InfluencerCashoutInvitation, 'id' | 'installs' | 'clicks'>
    & { campaign: Pick<Campaign, 'id' | 'title' | 'cpiPayoutPennies'>, account: Pick<Account, 'id' | 'username'> }
  ) };

export type GetWelcomeProfileAccountQueryVariables = Exact<{
  claimAccountId: Scalars['String'];
}>;


export type GetWelcomeProfileAccountQuery = { getWelcomeProfileAccount: AccountFragementFragment };

export type UpdateWelcomePersonalDetailMutationVariables = Exact<{
  claimAccountId: Scalars['String'];
  age?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  gender?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  location?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateWelcomePersonalDetailMutation = { updateWelcomePersonalDetail: AccountFragementFragment };

export type UpdateWelcomeProfileDetailMutationVariables = Exact<{
  claimAccountId: Scalars['String'];
  screenshot?: Maybe<FileInput>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
}>;


export type UpdateWelcomeProfileDetailMutation = { updateWelcomeProfileDetail: AccountFragementFragment };

export type UpdateWelcomeRegisterForBetaMutationVariables = Exact<{
  claimAccountId: Scalars['String'];
}>;


export type UpdateWelcomeRegisterForBetaMutation = { updateWelcomeRegisterForBeta: AccountFragementFragment };

export type UpdateDraftFeedbackMutationVariables = Exact<{
  draftId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  brandFeedback?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  adminFeedback?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type UpdateDraftFeedbackMutation = { updateDraftFeedback?: Maybe<DraftFragmentFragment> };

export type InivtiationLinkHubStatusFragmentFragment = Pick<InivtiationLinkHubStatus, 'checked' | 'status'>;

export type InivtiationLinkHubStatusFragment = Pick<InivtiationLinkHubStatus, 'checked' | 'status'>;

export type GetInfluencerHubLinkStatusQueryVariables = Exact<{
  claimCashoutId: Scalars['String'];
}>;


export type GetInfluencerHubLinkStatusQuery = { getInfluencerHubLinkStatus: { initialContact: InivtiationLinkHubStatusFragment, initialContactYouTube: InivtiationLinkHubStatusFragment, contract: InivtiationLinkHubStatusFragmentFragment, inviteFeedback: InivtiationLinkHubStatusFragmentFragment, inviteFeedbackYouTube: InivtiationLinkHubStatusFragmentFragment, postDetail: InivtiationLinkHubStatusFragmentFragment, postDetailYouTube: InivtiationLinkHubStatusFragmentFragment, paymentDetail: InivtiationLinkHubStatusFragmentFragment } };

export type UpdateInfluencerHubLinkMutationVariables = Exact<{
  claimCashoutId: Scalars['String'];
  initialContact: Scalars['Boolean'];
  initialContactYouTube: Scalars['Boolean'];
  contract: Scalars['Boolean'];
  inviteFeedback: Scalars['Boolean'];
  inviteFeedbackYouTube: Scalars['Boolean'];
  postDetail: Scalars['Boolean'];
  postDetailYouTube: Scalars['Boolean'];
  paymentDetail: Scalars['Boolean'];
}>;


export type UpdateInfluencerHubLinkMutation = { updateInfluencerHubLink: Pick<InivtiationLinkHubType, 'initialContact' | 'initialContactYouTube' | 'contract' | 'inviteFeedback' | 'inviteFeedbackYouTube' | 'postDetail' | 'postDetailYouTube' | 'paymentDetail'> };

export type UpdateAccountBreakdownsByInvitationIdMutationVariables = Exact<{
  invitationId: Scalars['Int'];
  screenshots?: Maybe<Array<ScreenshotFileInput> | ScreenshotFileInput>;
  ageBreakdown?: Maybe<Scalars['JSON']>;
  genderBreakdown?: Maybe<Scalars['JSON']>;
  locationBreakdown?: Maybe<Scalars['JSON']>;
  tags?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type UpdateAccountBreakdownsByInvitationIdMutation = { updateAccountBreakdownsByInvitationId: InvitationFragmentFragment };

export type CreateNewTagMutationVariables = Exact<{
  tagName: Scalars['String'];
}>;


export type CreateNewTagMutation = { createNewTag: Pick<Tag, 'id' | 'name' | 'isDefined'> };

export type UpdateAwaitingDraftMutationVariables = Exact<{
  draftId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  allVideos?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  allMedia?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  caption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateAwaitingDraftMutation = { updateAwaitingDraft?: Maybe<DraftFragmentFragment> };

export type UdpateInvitationTitleMutationVariables = Exact<{
  title: Scalars['String'];
  invitationId: Scalars['Int'];
}>;


export type UdpateInvitationTitleMutation = { udpateInvitationTitle?: Maybe<Pick<Invitation, 'id'>> };

export type DuplicateInvitationMutationVariables = Exact<{
  invitationId: Scalars['Int'];
}>;


export type DuplicateInvitationMutation = { duplicateInvitation?: Maybe<Pick<Invitation, 'id'>> };

export type PostInfoTypeFragmentFragment = Pick<PostInfoType, 'videoUrl' | 'description' | 'likes' | 'views' | 'comments' | 'postedTime'>;

export type MostPostTypeFragmentFragment = { mostLikedPost?: Maybe<PostInfoTypeFragmentFragment>, mostViewedPost?: Maybe<PostInfoTypeFragmentFragment>, mostCommentedPost?: Maybe<PostInfoTypeFragmentFragment> };

export type GetPopularPostsByAccountIdQueryVariables = Exact<{
  accountId: Scalars['Float'];
}>;


export type GetPopularPostsByAccountIdQuery = { getPopularPostsByAccountId: MostPostTypeFragmentFragment };

export const AccountQueryResultFragmentFragmentDoc = gql`
    fragment accountQueryResultFragment on AccountQueryResult {
  id
  result
  type
}
    `;
export const AdHubMediaTypeFragmentFragmentDoc = gql`
    fragment AdHubMediaTypeFragment on AdHubMediaType {
  draftId
  draftVideoUrl
  accountId
  accountName
  invitationCost
}
    `;
export const AdHubResultTypeFragmentFragmentDoc = gql`
    fragment AdHubResultTypeFragment on AdHubResultType {
  mediaList {
    ...AdHubMediaTypeFragment
  }
  isAuthorised {
    TikTok
    Facebook
    Instagram
  }
  advertisers {
    id
    name
  }
}
    ${AdHubMediaTypeFragmentFragmentDoc}`;
export const UploadedVideoReturnTypeFragmentFragmentDoc = gql`
    fragment UploadedVideoReturnTypeFragment on UploadedVideoReturnType {
  poster_url
  format
  url
  file_name
  video_id
  displayable
  material_id
  height
  width
  bit_rate
  create_time
  modify_time
  allowed_placements
  signature
  duration
  allow_download
  id
  size
}
    `;
export const RatingFragmentFragmentDoc = gql`
    fragment RatingFragment on RatingType {
  punctuality
  professionalism
  quality
}
    `;
export const DetailFragmentFragmentDoc = gql`
    fragment DetailFragment on DetailType {
  vatNumber
  address
  managed
  age
  gender
  location
  rating {
    ...RatingFragment
  }
  flags
}
    ${RatingFragmentFragmentDoc}`;
export const BankAccountFragmentFragmentDoc = gql`
    fragment BankAccountFragment on BankAccountType {
  countryCode
  fullName
  sortCode
  accountNumber
  recipientAddress
  routingNumber
  swift
  iban
  bsbCode
  bankName
  transitNumber
  institutionNumber
}
    `;
export const AgencyFragmentFragmentDoc = gql`
    fragment AgencyFragment on Agency {
  id
  agencyName
  payoutDetail {
    paypalEmail
    bankAccount {
      ...BankAccountFragment
    }
  }
}
    ${BankAccountFragmentFragmentDoc}`;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  name
  email
  type
  companyId
  phoneNumber
  agencyId
  detail {
    ...DetailFragment
  }
  payoutDetails {
    paypalEmail
    bankAccount {
      ...BankAccountFragment
    }
  }
  registerForBeta
  superAdmin
  agencyId
  createdAt
  updatedAt
  agency {
    ...AgencyFragment
  }
}
    ${DetailFragmentFragmentDoc}
${BankAccountFragmentFragmentDoc}
${AgencyFragmentFragmentDoc}`;
export const CampaignTrackingTypeFragmentFragmentDoc = gql`
    fragment CampaignTrackingTypeFragment on CampaignTrackingType {
  key
  id
  type
  date
}
    `;
export const CampaignMetadataTypeFragmentFragmentDoc = gql`
    fragment CampaignMetadataTypeFragment on CampaignMetadataType {
  likeCount
  commentCount
  engagementRate
  tracking {
    ...CampaignTrackingTypeFragment
  }
}
    ${CampaignTrackingTypeFragmentFragmentDoc}`;
export const CompanyFragmentFragmentDoc = gql`
    fragment CompanyFragment on Company {
  id
  companyName
  tiktokAds {
    accessToken
  }
}
    `;
export const CampaignFragmentFragmentDoc = gql`
    fragment CampaignFragment on Campaign {
  id
  title
  status
  startDate
  endDate
  adminId
  executiveId
  companyId
  campaignGroupId
  budgetPennies
  cpiPayoutPennies
  distributionPlatforms
  ages
  genders
  locations
  notifiedBrandIds
  influencerSpendPennies
  influencerInviteLimit
  additionalTerms
  cpiPayoutPennies
  admin {
    ...UserFragment
  }
  executive {
    ...UserFragment
  }
  metadata {
    ...CampaignMetadataTypeFragment
  }
  company {
    ...CompanyFragment
  }
  reportLink
  reportObjective
  briefDocLink
  createdAt
  updatedAt
}
    ${UserFragmentFragmentDoc}
${CampaignMetadataTypeFragmentFragmentDoc}
${CompanyFragmentFragmentDoc}`;
export const AccountMediaContentFragementFragmentDoc = gql`
    fragment AccountMediaContentFragement on AccountMediaContent {
  contentUrl
  contentType
  views
  likes
}
    `;
export const AccountRatingFragmentFragmentDoc = gql`
    fragment AccountRatingFragment on AccountRatingType {
  creationTime
  responseTime
  contentQuality
  professionalism
}
    `;
export const MetadataFragmentFragmentDoc = gql`
    fragment MetadataFragment on MetadataType {
  avgHearts
  avgLikes
  avgComments
  avgViews
  engagementRate
  description
  followers
  rating {
    ...AccountRatingFragment
  }
}
    ${AccountRatingFragmentFragmentDoc}`;
export const TagFragmentFragmentDoc = gql`
    fragment TagFragment on Tag {
  id
  name
  isDefined
}
    `;
export const UploadFileFragmentFragmentDoc = gql`
    fragment UploadFileFragment on UploadFile {
  id
  fileableType
  createdAt
  updatedAt
  uploadUrl
  uploadType
  previewUrl
}
    `;
export const AccountFragementFragmentDoc = gql`
    fragment AccountFragement on Account {
  id
  type
  status
  username
  payoutPennies
  calculatedPayoutPennies
  influencerId
  cpm
  views
  format
  active
  salesAppropriate
  profilePicUrl
  previousBrandedContent {
    ...AccountMediaContentFragement
  }
  previousContent {
    ...AccountMediaContentFragement
  }
  metadata {
    ...MetadataFragment
  }
  ageBreakdown
  genderBreakdown
  locationBreakdown
  growthRate
  engagementRate
  estCostPerPost
  actualCostPerPost
  lastPaidAmount
  lastCampaignDate
  influencer {
    ...UserFragment
  }
  tags {
    ...TagFragment
  }
  screenshots {
    ...UploadFileFragment
  }
  isSuggested
  createdAt
  updatedAt
}
    ${AccountMediaContentFragementFragmentDoc}
${MetadataFragmentFragmentDoc}
${UserFragmentFragmentDoc}
${TagFragmentFragmentDoc}
${UploadFileFragmentFragmentDoc}`;
export const SuggestionFragmentFragmentDoc = gql`
    fragment SuggestionFragment on Suggestion {
  id
  status
  campaignId
  campaign {
    ...CampaignFragment
  }
  account {
    ...AccountFragement
  }
  createdAt
  updatedAt
  cpm
  views
  payoutType
  rationale
  fixedPayoutPennies
  estimatedPayoutPennies
  campaignInvitationId
}
    ${CampaignFragmentFragmentDoc}
${AccountFragementFragmentDoc}`;
export const CampaignGroupFragmentFragmentDoc = gql`
    fragment CampaignGroupFragment on CampaignGroup {
  id
  companyId
  groupName
  status
  objective
  budgetPennies
  extraBrands {
    ...UserFragment
  }
  createdAt
  updatedAt
  company {
    ...CompanyFragment
  }
  campaigns {
    ...CampaignFragment
  }
}
    ${UserFragmentFragmentDoc}
${CompanyFragmentFragmentDoc}
${CampaignFragmentFragmentDoc}`;
export const GroupedCampaignTypeFragmentFragmentDoc = gql`
    fragment GroupedCampaignTypeFragment on GroupedCampaignType {
  id
  title
  platforms
  status
  startDate
  budgetCurrency
  budgetPennies
  companyId
  adFormat
  views
  clicks
  createdAt
  costOfSales
  approvedPayments
  totalPayments
  isGroup
}
    `;
export const ApplicantFragmentDoc = gql`
    fragment applicant on Account {
  id
  type
  username
  applyStatus
  influencerId
  profilePicUrl
  metadata {
    avgLikes
    avgViews
    avgComments
  }
}
    `;
export const SuggestedApplicantFragmentDoc = gql`
    fragment suggestedApplicant on Suggestion {
  id
  status
  account {
    id
    type
    username
  }
}
    `;
export const DiscoverCategoryTypeFragmentFragmentDoc = gql`
    fragment DiscoverCategoryTypeFragment on DiscoverCategoryType {
  accountName
  bio
  avatarUrl
  lastVideoId
  followers
  growthRate
  averageHearts
  averageComments
  averageShares
  averageViews
  engagementRate
  isTracked
}
    `;
export const DiscoverSearchingTypeFragmentFragmentDoc = gql`
    fragment DiscoverSearchingTypeFragment on DiscoverSearchingType {
  accountName
  followers
  averageHearts
  averageComments
  averageShares
  averageViews
  engagementRate
}
    `;
export const NoteFragmentFragmentDoc = gql`
    fragment NoteFragment on InfluencerProfileNote {
  title
  body
  createdAt
}
    `;
export const AccountDetailFragmentDoc = gql`
    fragment AccountDetail on InfluencerProfileAccountDetailType {
  username
  type
  profileImage
  metadata
  isOnCampaign
  tags {
    ...TagFragment
  }
  growthRate
  engagementRate
  estCostPerPost
  actualCostPerPost
}
    ${TagFragmentFragmentDoc}`;
export const GraphDataFragmentDoc = gql`
    fragment GraphData on InfluencerProfileAccountGraphData {
  key
  value
}
    `;
export const BreakdownGraphDataFragmentDoc = gql`
    fragment BreakdownGraphData on InfluencerProfileAccountBreakdownData {
  manual {
    ...GraphData
  }
  live {
    ...GraphData
  }
}
    ${GraphDataFragmentDoc}`;
export const SearchAccountDataFragmentDoc = gql`
    fragment SearchAccountData on SearchAccountType {
  accountId
  influencerId
  username
  influencerName
  platform
  format
  campaignCount
  engCTR
  price
  avgLikes
  avgViews
  avgComments
  followers
  createdAt
}
    `;
export const PaymentRequestFragmentFragmentDoc = gql`
    fragment PaymentRequestFragment on PaymentRequest {
  id
  status
  description
  vatNumber
  vatAmount
  payoutPenniesExclVat
  amount
  currency
  conversionRate
  payoutPennies
  paidAt
  dueDate
  failedReason
  attachments
  previewAttachments
  agencyName
  createdAt
  paymentDetail
  campaign {
    id
    title
    hubspotDealId
  }
  influencer {
    id
    name
  }
  requestedBy {
    id
    name
  }
}
    `;
export const PaymentQueryResultFragmentFragmentDoc = gql`
    fragment paymentQueryResultFragment on PaymentQueryResult {
  result
  type
}
    `;
export const BankAccountTypeFragmentFragmentDoc = gql`
    fragment bankAccountTypeFragment on BankAccountType {
  countryCode
  fullName
  sortCode
  accountNumber
  swift
  recipientAddress
  routingNumber
  iban
  bsbCode
  bankName
  transitNumber
  institutionNumber
}
    `;
export const TikTokUserBrandSafetyTypeFragmentFragmentDoc = gql`
    fragment TikTokUserBrandSafetyTypeFragment on TikTokUserBrandSafetyType {
  username
  avatarUrl
  bio
  hygineRating
  tags {
    image
    text
    video
  }
  keywords {
    word
    count
  }
  analysedPosts {
    coverUrl
    videoUrl
    length
    tags {
      image
      text
      video
    }
  }
  mentionedAccounts {
    username
    accountUrl
    count
  }
}
    `;
export const CampaignReportAccountTypeFragmentFragmentDoc = gql`
    fragment CampaignReportAccountTypeFragment on CampaignReportAccountType {
  accountId
  profilePicUrl
  username
  views
  engagementRate
  tags {
    id
    name
    isDefined
  }
}
    `;
export const CampaignReportTopCategoryTypeFragmentFragmentDoc = gql`
    fragment CampaignReportTopCategoryTypeFragment on CampaignReportTopCategoryType {
  type
  data {
    ...CampaignReportAccountTypeFragment
  }
}
    ${CampaignReportAccountTypeFragmentFragmentDoc}`;
export const CampaignReportPostTypeFragmentFragmentDoc = gql`
    fragment CampaignReportPostTypeFragment on CampaignReportPostType {
  account {
    ...CampaignReportAccountTypeFragment
  }
  reach
  hearts
  comments
  engagement
  videoUrl
}
    ${CampaignReportAccountTypeFragmentFragmentDoc}`;
export const CampaignReportTypeFragmentFragmentDoc = gql`
    fragment CampaignReportTypeFragment on CampaignReportType {
  companyName
  campaignTitle
  campaignObjective
  campaignPlatforms
  topViews {
    ...CampaignReportAccountTypeFragment
  }
  categorisedTopViews {
    ...CampaignReportTopCategoryTypeFragment
  }
  categorisedTopEngagements {
    ...CampaignReportTopCategoryTypeFragment
  }
  totalWatchedTime
  topEngagements {
    ...CampaignReportAccountTypeFragment
  }
  topClickThroughtRates {
    ...CampaignReportAccountTypeFragment
  }
  campaignComments
  campaignHearts
  campaignEngagementRate
  campaignClickThroughtRate
  mostLikedPost {
    ...CampaignReportPostTypeFragment
  }
  views
}
    ${CampaignReportAccountTypeFragmentFragmentDoc}
${CampaignReportTopCategoryTypeFragmentFragmentDoc}
${CampaignReportPostTypeFragmentFragmentDoc}`;
export const SuggestionFragmentDoc = gql`
    fragment suggestion on Suggestion {
  id
  status
  rationale
  campaignId
  campaignInvitationId
  account {
    id
    type
    username
    influencerId
    profilePicUrl
    ageBreakdown
    genderBreakdown
    metadata {
      followers
      engagementRate
    }
    previousContent {
      contentUrl
      contentType
    }
    previousBrandedContent {
      contentUrl
      contentType
    }
  }
}
    `;
export const PaginatedSuggestionsFragmentDoc = gql`
    fragment paginatedSuggestions on ShareSuggestionsType {
  totalEntries
  perPage
  totalPages
  previousPage
  currentPage
  nextPage
  suggestions {
    ...suggestion
  }
}
    ${SuggestionFragmentDoc}`;
export const DraftFragmentFragmentDoc = gql`
    fragment DraftFragment on Draft {
  id
  draftType
  status
  allMedia
  allVideos
  videoUrl
  caption
  description
  redraftRequest
  approved
  submittedToBrand
  brandFeedback
  adminFeedback
  previewAllMedia
  createdAt
  updatedAt
}
    `;
export const InvitationFragmentFragmentDoc = gql`
    fragment InvitationFragment on Invitation {
  id
  views
  installs
  clicks
  claimCashoutId
  status
  estimatedPayoutPennies
  actualPaidPennies
  payoutCurrency
  likeUploadDraft
  stage
  rationale
  contractSigned
  metadata
  estimatedViews
  payoutType
  payoutTypeCurrency
  payoutTypeOriginalPennies
  fixedPayoutPennies
  cpm
  updatedAt
  screenshots {
    uploadUrl
    uploadType
    previewUrl
  }
  campaign {
    id
    title
  }
  account {
    ...AccountFragement
  }
  drafts {
    ...DraftFragment
  }
}
    ${AccountFragementFragmentDoc}
${DraftFragmentFragmentDoc}`;
export const AcceptedAccountsTypeFragmentFragmentDoc = gql`
    fragment AcceptedAccountsTypeFragment on Suggestion {
  id
  status
  campaignId
  influencerAccountId
  campaignInvitationId
  cpm
  views
  payoutType
  rationale
  fixedPayoutPennies
  estimatedPayoutPennies
  payoutTypeCurrency
  payoutTypeOriginalPennies
  sortOrder
  createdAt
  updatedAt
  campaign {
    ...CampaignFragment
  }
  account {
    ...AccountFragement
  }
  invitation {
    ...InvitationFragment
  }
}
    ${CampaignFragmentFragmentDoc}
${AccountFragementFragmentDoc}
${InvitationFragmentFragmentDoc}`;
export const GiftingCampaignFragmentFragmentDoc = gql`
    fragment GiftingCampaignFragment on GiftingCampaign {
  id
  title
  image
  startDate
  status
  endDate
  brief {
    aboutBrand
    brandName
    aboutCampaign
    postingGuidlines
    linkToWebsite
    repurposeContent
    campaignType
    requiredInformation {
      address
      phoneNumber
    }
    platforms {
      snapchat
      instagram
      youtube
      tiktok
    }
  }
}
    `;
export const InfluencerCashoutInvitationFragmentFragmentDoc = gql`
    fragment InfluencerCashoutInvitationFragment on InfluencerCashoutInvitation {
  id
  views
  installs
  clicks
  claimCashoutId
  status
  payoutTypeCurrency
  payoutTypeOriginalPennies
  fixedPayoutPennies
  estimatedPayoutPennies
  actualPaidPennies
  payoutCurrency
  likeUploadDraft
  stage
  metadata
  contractSigned
  estimatedViews
  payoutType
  cpm
  updatedAt
  isAccountScreenshotOutdated
  screenshots {
    uploadUrl
    uploadType
    previewUrl
  }
  campaign {
    ...CampaignFragment
  }
  account {
    ...AccountFragement
  }
}
    ${CampaignFragmentFragmentDoc}
${AccountFragementFragmentDoc}`;
export const InivtiationLinkHubStatusFragmentFragmentDoc = gql`
    fragment InivtiationLinkHubStatusFragment on InivtiationLinkHubStatus {
  checked
  status
}
    `;
export const InivtiationLinkHubStatusFragmentDoc = gql`
    fragment InivtiationLinkHubStatus on InivtiationLinkHubStatus {
  checked
  status
}
    `;
export const PostInfoTypeFragmentFragmentDoc = gql`
    fragment PostInfoTypeFragment on PostInfoType {
  videoUrl
  description
  likes
  views
  comments
  postedTime
}
    `;
export const MostPostTypeFragmentFragmentDoc = gql`
    fragment MostPostTypeFragment on MostPostType {
  mostLikedPost {
    ...PostInfoTypeFragment
  }
  mostViewedPost {
    ...PostInfoTypeFragment
  }
  mostCommentedPost {
    ...PostInfoTypeFragment
  }
}
    ${PostInfoTypeFragmentFragmentDoc}`;
export const GetFilteringAccountsDocument = gql`
    query getFilteringAccounts($searchInput: String, $searchType: FilteringAccountSearchType, $audienceAge: [AudienceBreakdownSearchInput!], $audienceGender: [AudienceBreakdownSearchInput!], $audienceLocation: [AudienceBreakdownSearchInput!], $accountType: [AccountType!], $influencerAge: [String!], $influencerGender: [GenderType!], $influencerLocation: [String!], $completedCampaigns: CompletedCampaignsSearchInput, $status: [FilteringAccountStatusType!], $tags: [Int!], $customTags: [Int!], $rating: AccountRatingInput, $followers: ComparisonInput, $order: FilteringAccountSortInput, $accountId: Int, $influencerId: Int, $campaignId: Int, $viewMode: Boolean = false, $page: Int, $campaignIdForSuggestedAccounts: Int, $adminListId: Int, $adminListIdForNotIncluded: Int) {
  getFilteringAccounts(searchInput: $searchInput, searchType: $searchType, audienceAge: $audienceAge, audienceGender: $audienceGender, audienceLocation: $audienceLocation, accountType: $accountType, influencerAge: $influencerAge, influencerGender: $influencerGender, influencerLocation: $influencerLocation, completedCampaigns: $completedCampaigns, status: $status, tags: $tags, customTags: $customTags, rating: $rating, followers: $followers, order: $order, accountId: $accountId, influencerId: $influencerId, campaignId: $campaignId, viewMode: $viewMode, page: $page, campaignIdForSuggestedAccounts: $campaignIdForSuggestedAccounts, adminListId: $adminListId, adminListIdForNotIncluded: $adminListIdForNotIncluded) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    totalViews
    verifiedViews
    verifiedInfluencers
    totalRecords
    accounts {
      ...AccountFragement
    }
  }
}
    ${AccountFragementFragmentDoc}`;

/**
 * __useGetFilteringAccountsQuery__
 *
 * To run a query within a React component, call `useGetFilteringAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteringAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteringAccountsQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      searchType: // value for 'searchType'
 *      audienceAge: // value for 'audienceAge'
 *      audienceGender: // value for 'audienceGender'
 *      audienceLocation: // value for 'audienceLocation'
 *      accountType: // value for 'accountType'
 *      influencerAge: // value for 'influencerAge'
 *      influencerGender: // value for 'influencerGender'
 *      influencerLocation: // value for 'influencerLocation'
 *      completedCampaigns: // value for 'completedCampaigns'
 *      status: // value for 'status'
 *      tags: // value for 'tags'
 *      customTags: // value for 'customTags'
 *      rating: // value for 'rating'
 *      followers: // value for 'followers'
 *      order: // value for 'order'
 *      accountId: // value for 'accountId'
 *      influencerId: // value for 'influencerId'
 *      campaignId: // value for 'campaignId'
 *      viewMode: // value for 'viewMode'
 *      page: // value for 'page'
 *      campaignIdForSuggestedAccounts: // value for 'campaignIdForSuggestedAccounts'
 *      adminListId: // value for 'adminListId'
 *      adminListIdForNotIncluded: // value for 'adminListIdForNotIncluded'
 *   },
 * });
 */
export function useGetFilteringAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFilteringAccountsQuery, GetFilteringAccountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFilteringAccountsQuery, GetFilteringAccountsQueryVariables>(GetFilteringAccountsDocument, baseOptions);
      }
export function useGetFilteringAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFilteringAccountsQuery, GetFilteringAccountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFilteringAccountsQuery, GetFilteringAccountsQueryVariables>(GetFilteringAccountsDocument, baseOptions);
        }
export type GetFilteringAccountsQueryHookResult = ReturnType<typeof useGetFilteringAccountsQuery>;
export type GetFilteringAccountsLazyQueryHookResult = ReturnType<typeof useGetFilteringAccountsLazyQuery>;
export type GetFilteringAccountsQueryResult = ApolloReactCommon.QueryResult<GetFilteringAccountsQuery, GetFilteringAccountsQueryVariables>;
export const GetFilteringAccountQueryDocument = gql`
    query getFilteringAccountQuery($searchInput: String!) {
  getFilteringAccountQuery(searchInput: $searchInput) {
    account {
      ...accountQueryResultFragment
    }
    influencer {
      ...accountQueryResultFragment
    }
    tag {
      ...accountQueryResultFragment
    }
    campaign {
      ...accountQueryResultFragment
    }
  }
}
    ${AccountQueryResultFragmentFragmentDoc}`;

/**
 * __useGetFilteringAccountQueryQuery__
 *
 * To run a query within a React component, call `useGetFilteringAccountQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteringAccountQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteringAccountQueryQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetFilteringAccountQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFilteringAccountQueryQuery, GetFilteringAccountQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFilteringAccountQueryQuery, GetFilteringAccountQueryQueryVariables>(GetFilteringAccountQueryDocument, baseOptions);
      }
export function useGetFilteringAccountQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFilteringAccountQueryQuery, GetFilteringAccountQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFilteringAccountQueryQuery, GetFilteringAccountQueryQueryVariables>(GetFilteringAccountQueryDocument, baseOptions);
        }
export type GetFilteringAccountQueryQueryHookResult = ReturnType<typeof useGetFilteringAccountQueryQuery>;
export type GetFilteringAccountQueryLazyQueryHookResult = ReturnType<typeof useGetFilteringAccountQueryLazyQuery>;
export type GetFilteringAccountQueryQueryResult = ApolloReactCommon.QueryResult<GetFilteringAccountQueryQuery, GetFilteringAccountQueryQueryVariables>;
export const SuggestMultipleAccountsDocument = gql`
    mutation suggestMultipleAccounts($campaignId: Int!, $accountIds: [Int!]!) {
  suggestMultipleAccounts(campaignId: $campaignId, accountIds: $accountIds) {
    id
    status
    campaignId
    cpm
    views
  }
}
    `;
export type SuggestMultipleAccountsMutationFn = ApolloReactCommon.MutationFunction<SuggestMultipleAccountsMutation, SuggestMultipleAccountsMutationVariables>;

/**
 * __useSuggestMultipleAccountsMutation__
 *
 * To run a mutation, you first call `useSuggestMultipleAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestMultipleAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestMultipleAccountsMutation, { data, loading, error }] = useSuggestMultipleAccountsMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useSuggestMultipleAccountsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SuggestMultipleAccountsMutation, SuggestMultipleAccountsMutationVariables>) {
        return ApolloReactHooks.useMutation<SuggestMultipleAccountsMutation, SuggestMultipleAccountsMutationVariables>(SuggestMultipleAccountsDocument, baseOptions);
      }
export type SuggestMultipleAccountsMutationHookResult = ReturnType<typeof useSuggestMultipleAccountsMutation>;
export type SuggestMultipleAccountsMutationResult = ApolloReactCommon.MutationResult<SuggestMultipleAccountsMutation>;
export type SuggestMultipleAccountsMutationOptions = ApolloReactCommon.BaseMutationOptions<SuggestMultipleAccountsMutation, SuggestMultipleAccountsMutationVariables>;
export const CreateAdminListDocument = gql`
    mutation createAdminList($listName: String!) {
  createAdminList(listName: $listName) {
    accounts {
      id
      type
      status
      username
    }
    admin {
      email
      name
      type
    }
    id
    listName
  }
}
    `;
export type CreateAdminListMutationFn = ApolloReactCommon.MutationFunction<CreateAdminListMutation, CreateAdminListMutationVariables>;

/**
 * __useCreateAdminListMutation__
 *
 * To run a mutation, you first call `useCreateAdminListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminListMutation, { data, loading, error }] = useCreateAdminListMutation({
 *   variables: {
 *      listName: // value for 'listName'
 *   },
 * });
 */
export function useCreateAdminListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAdminListMutation, CreateAdminListMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAdminListMutation, CreateAdminListMutationVariables>(CreateAdminListDocument, baseOptions);
      }
export type CreateAdminListMutationHookResult = ReturnType<typeof useCreateAdminListMutation>;
export type CreateAdminListMutationResult = ApolloReactCommon.MutationResult<CreateAdminListMutation>;
export type CreateAdminListMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAdminListMutation, CreateAdminListMutationVariables>;
export const AddAccountsToAdminListDocument = gql`
    mutation addAccountsToAdminList($accountIds: [Int!]!, $adminListId: Int!) {
  addAccountsToAdminList(accountIds: $accountIds, adminListId: $adminListId) {
    accounts {
      id
      type
      status
      username
    }
    admin {
      email
      name
      type
    }
    id
    listName
  }
}
    `;
export type AddAccountsToAdminListMutationFn = ApolloReactCommon.MutationFunction<AddAccountsToAdminListMutation, AddAccountsToAdminListMutationVariables>;

/**
 * __useAddAccountsToAdminListMutation__
 *
 * To run a mutation, you first call `useAddAccountsToAdminListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountsToAdminListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountsToAdminListMutation, { data, loading, error }] = useAddAccountsToAdminListMutation({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      adminListId: // value for 'adminListId'
 *   },
 * });
 */
export function useAddAccountsToAdminListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAccountsToAdminListMutation, AddAccountsToAdminListMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAccountsToAdminListMutation, AddAccountsToAdminListMutationVariables>(AddAccountsToAdminListDocument, baseOptions);
      }
export type AddAccountsToAdminListMutationHookResult = ReturnType<typeof useAddAccountsToAdminListMutation>;
export type AddAccountsToAdminListMutationResult = ApolloReactCommon.MutationResult<AddAccountsToAdminListMutation>;
export type AddAccountsToAdminListMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAccountsToAdminListMutation, AddAccountsToAdminListMutationVariables>;
export const RemoveAccountsFromAdminListDocument = gql`
    mutation removeAccountsFromAdminList($accountIds: [Int!]!, $adminListId: Int!) {
  removeAccountsFromAdminList(accountIds: $accountIds, adminListId: $adminListId) {
    accounts {
      id
      type
      status
      username
    }
    admin {
      email
      name
      type
    }
    id
    listName
  }
}
    `;
export type RemoveAccountsFromAdminListMutationFn = ApolloReactCommon.MutationFunction<RemoveAccountsFromAdminListMutation, RemoveAccountsFromAdminListMutationVariables>;

/**
 * __useRemoveAccountsFromAdminListMutation__
 *
 * To run a mutation, you first call `useRemoveAccountsFromAdminListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountsFromAdminListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountsFromAdminListMutation, { data, loading, error }] = useRemoveAccountsFromAdminListMutation({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      adminListId: // value for 'adminListId'
 *   },
 * });
 */
export function useRemoveAccountsFromAdminListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAccountsFromAdminListMutation, RemoveAccountsFromAdminListMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAccountsFromAdminListMutation, RemoveAccountsFromAdminListMutationVariables>(RemoveAccountsFromAdminListDocument, baseOptions);
      }
export type RemoveAccountsFromAdminListMutationHookResult = ReturnType<typeof useRemoveAccountsFromAdminListMutation>;
export type RemoveAccountsFromAdminListMutationResult = ApolloReactCommon.MutationResult<RemoveAccountsFromAdminListMutation>;
export type RemoveAccountsFromAdminListMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAccountsFromAdminListMutation, RemoveAccountsFromAdminListMutationVariables>;
export const GetAdminListsDocument = gql`
    query getAdminLists {
  getAdminLists {
    admin {
      email
      name
    }
    adminId
    accounts {
      id
      username
    }
    id
    listName
  }
}
    `;

/**
 * __useGetAdminListsQuery__
 *
 * To run a query within a React component, call `useGetAdminListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminListsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdminListsQuery, GetAdminListsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdminListsQuery, GetAdminListsQueryVariables>(GetAdminListsDocument, baseOptions);
      }
export function useGetAdminListsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdminListsQuery, GetAdminListsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdminListsQuery, GetAdminListsQueryVariables>(GetAdminListsDocument, baseOptions);
        }
export type GetAdminListsQueryHookResult = ReturnType<typeof useGetAdminListsQuery>;
export type GetAdminListsLazyQueryHookResult = ReturnType<typeof useGetAdminListsLazyQuery>;
export type GetAdminListsQueryResult = ApolloReactCommon.QueryResult<GetAdminListsQuery, GetAdminListsQueryVariables>;
export const DeleteAdminListDocument = gql`
    mutation deleteAdminList($adminListId: Int!) {
  deleteAdminList(adminListId: $adminListId)
}
    `;
export type DeleteAdminListMutationFn = ApolloReactCommon.MutationFunction<DeleteAdminListMutation, DeleteAdminListMutationVariables>;

/**
 * __useDeleteAdminListMutation__
 *
 * To run a mutation, you first call `useDeleteAdminListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminListMutation, { data, loading, error }] = useDeleteAdminListMutation({
 *   variables: {
 *      adminListId: // value for 'adminListId'
 *   },
 * });
 */
export function useDeleteAdminListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAdminListMutation, DeleteAdminListMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAdminListMutation, DeleteAdminListMutationVariables>(DeleteAdminListDocument, baseOptions);
      }
export type DeleteAdminListMutationHookResult = ReturnType<typeof useDeleteAdminListMutation>;
export type DeleteAdminListMutationResult = ApolloReactCommon.MutationResult<DeleteAdminListMutation>;
export type DeleteAdminListMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAdminListMutation, DeleteAdminListMutationVariables>;
export const GetAdHubMediaListDocument = gql`
    query getAdHubMediaList($accountName: String, $campaignId: Int!) {
  getAdHubMediaList(accountName: $accountName, campaignId: $campaignId) {
    ...AdHubResultTypeFragment
  }
}
    ${AdHubResultTypeFragmentFragmentDoc}`;

/**
 * __useGetAdHubMediaListQuery__
 *
 * To run a query within a React component, call `useGetAdHubMediaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdHubMediaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdHubMediaListQuery({
 *   variables: {
 *      accountName: // value for 'accountName'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetAdHubMediaListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdHubMediaListQuery, GetAdHubMediaListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdHubMediaListQuery, GetAdHubMediaListQueryVariables>(GetAdHubMediaListDocument, baseOptions);
      }
export function useGetAdHubMediaListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdHubMediaListQuery, GetAdHubMediaListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdHubMediaListQuery, GetAdHubMediaListQueryVariables>(GetAdHubMediaListDocument, baseOptions);
        }
export type GetAdHubMediaListQueryHookResult = ReturnType<typeof useGetAdHubMediaListQuery>;
export type GetAdHubMediaListLazyQueryHookResult = ReturnType<typeof useGetAdHubMediaListLazyQuery>;
export type GetAdHubMediaListQueryResult = ApolloReactCommon.QueryResult<GetAdHubMediaListQuery, GetAdHubMediaListQueryVariables>;
export const SetAuthCodeToCampaignCompanyDocument = gql`
    mutation setAuthCodeToCampaignCompany($campaignId: Int!, $authCode: String!) {
  setAuthCodeToCampaignCompany(campaignId: $campaignId, authCode: $authCode) {
    ...CompanyFragment
  }
}
    ${CompanyFragmentFragmentDoc}`;
export type SetAuthCodeToCampaignCompanyMutationFn = ApolloReactCommon.MutationFunction<SetAuthCodeToCampaignCompanyMutation, SetAuthCodeToCampaignCompanyMutationVariables>;

/**
 * __useSetAuthCodeToCampaignCompanyMutation__
 *
 * To run a mutation, you first call `useSetAuthCodeToCampaignCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAuthCodeToCampaignCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAuthCodeToCampaignCompanyMutation, { data, loading, error }] = useSetAuthCodeToCampaignCompanyMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useSetAuthCodeToCampaignCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetAuthCodeToCampaignCompanyMutation, SetAuthCodeToCampaignCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<SetAuthCodeToCampaignCompanyMutation, SetAuthCodeToCampaignCompanyMutationVariables>(SetAuthCodeToCampaignCompanyDocument, baseOptions);
      }
export type SetAuthCodeToCampaignCompanyMutationHookResult = ReturnType<typeof useSetAuthCodeToCampaignCompanyMutation>;
export type SetAuthCodeToCampaignCompanyMutationResult = ApolloReactCommon.MutationResult<SetAuthCodeToCampaignCompanyMutation>;
export type SetAuthCodeToCampaignCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<SetAuthCodeToCampaignCompanyMutation, SetAuthCodeToCampaignCompanyMutationVariables>;
export const UploadMediaToTikTokCreativeDocument = gql`
    mutation uploadMediaToTikTokCreative($fileName: String!, $advertiserId: String!, $draftId: Int!, $campaignId: Int!) {
  uploadMediaToTikTokCreative(fileName: $fileName, advertiserId: $advertiserId, draftId: $draftId, campaignId: $campaignId) {
    poster_url
    format
    url
    file_name
    video_id
    displayable
    material_id
    height
    width
    bit_rate
    create_time
    modify_time
    allowed_placements
    signature
    duration
    allow_download
    id
    size
  }
}
    `;
export type UploadMediaToTikTokCreativeMutationFn = ApolloReactCommon.MutationFunction<UploadMediaToTikTokCreativeMutation, UploadMediaToTikTokCreativeMutationVariables>;

/**
 * __useUploadMediaToTikTokCreativeMutation__
 *
 * To run a mutation, you first call `useUploadMediaToTikTokCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMediaToTikTokCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMediaToTikTokCreativeMutation, { data, loading, error }] = useUploadMediaToTikTokCreativeMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      advertiserId: // value for 'advertiserId'
 *      draftId: // value for 'draftId'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUploadMediaToTikTokCreativeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadMediaToTikTokCreativeMutation, UploadMediaToTikTokCreativeMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadMediaToTikTokCreativeMutation, UploadMediaToTikTokCreativeMutationVariables>(UploadMediaToTikTokCreativeDocument, baseOptions);
      }
export type UploadMediaToTikTokCreativeMutationHookResult = ReturnType<typeof useUploadMediaToTikTokCreativeMutation>;
export type UploadMediaToTikTokCreativeMutationResult = ApolloReactCommon.MutationResult<UploadMediaToTikTokCreativeMutation>;
export type UploadMediaToTikTokCreativeMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadMediaToTikTokCreativeMutation, UploadMediaToTikTokCreativeMutationVariables>;
export const CreateCampaignMilestoneDocument = gql`
    mutation createCampaignMilestone($notification: CampaignMilestoneOptionInput!, $visibility: CampaignMilestoneOptionInput!, $metric: CampaignMilestoneMetric!, $goal: Float!, $name: String!, $campaignId: Int!) {
  createCampaignMilestone(notification: $notification, visibility: $visibility, metric: $metric, goal: $goal, name: $name, campaignId: $campaignId) {
    id
  }
}
    `;
export type CreateCampaignMilestoneMutationFn = ApolloReactCommon.MutationFunction<CreateCampaignMilestoneMutation, CreateCampaignMilestoneMutationVariables>;

/**
 * __useCreateCampaignMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMilestoneMutation, { data, loading, error }] = useCreateCampaignMilestoneMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      visibility: // value for 'visibility'
 *      metric: // value for 'metric'
 *      goal: // value for 'goal'
 *      name: // value for 'name'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCreateCampaignMilestoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCampaignMilestoneMutation, CreateCampaignMilestoneMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCampaignMilestoneMutation, CreateCampaignMilestoneMutationVariables>(CreateCampaignMilestoneDocument, baseOptions);
      }
export type CreateCampaignMilestoneMutationHookResult = ReturnType<typeof useCreateCampaignMilestoneMutation>;
export type CreateCampaignMilestoneMutationResult = ApolloReactCommon.MutationResult<CreateCampaignMilestoneMutation>;
export type CreateCampaignMilestoneMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCampaignMilestoneMutation, CreateCampaignMilestoneMutationVariables>;
export const UpdateCampaignMilestoneDocument = gql`
    mutation updateCampaignMilestone($notification: CampaignMilestoneOptionInput!, $visibility: CampaignMilestoneOptionInput!, $metric: CampaignMilestoneMetric!, $goal: Float!, $name: String!, $campaignMilestoneId: Int!) {
  updateCampaignMilestone(notification: $notification, visibility: $visibility, metric: $metric, goal: $goal, name: $name, campaignMilestoneId: $campaignMilestoneId) {
    id
  }
}
    `;
export type UpdateCampaignMilestoneMutationFn = ApolloReactCommon.MutationFunction<UpdateCampaignMilestoneMutation, UpdateCampaignMilestoneMutationVariables>;

/**
 * __useUpdateCampaignMilestoneMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMilestoneMutation, { data, loading, error }] = useUpdateCampaignMilestoneMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      visibility: // value for 'visibility'
 *      metric: // value for 'metric'
 *      goal: // value for 'goal'
 *      name: // value for 'name'
 *      campaignMilestoneId: // value for 'campaignMilestoneId'
 *   },
 * });
 */
export function useUpdateCampaignMilestoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCampaignMilestoneMutation, UpdateCampaignMilestoneMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCampaignMilestoneMutation, UpdateCampaignMilestoneMutationVariables>(UpdateCampaignMilestoneDocument, baseOptions);
      }
export type UpdateCampaignMilestoneMutationHookResult = ReturnType<typeof useUpdateCampaignMilestoneMutation>;
export type UpdateCampaignMilestoneMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignMilestoneMutation>;
export type UpdateCampaignMilestoneMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCampaignMilestoneMutation, UpdateCampaignMilestoneMutationVariables>;
export const DeleteCampaignMilestoneDocument = gql`
    mutation deleteCampaignMilestone($campaignMilestoneId: Int!) {
  deleteCampaignMilestone(campaignMilestoneId: $campaignMilestoneId) {
    milestoneName
  }
}
    `;
export type DeleteCampaignMilestoneMutationFn = ApolloReactCommon.MutationFunction<DeleteCampaignMilestoneMutation, DeleteCampaignMilestoneMutationVariables>;

/**
 * __useDeleteCampaignMilestoneMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignMilestoneMutation, { data, loading, error }] = useDeleteCampaignMilestoneMutation({
 *   variables: {
 *      campaignMilestoneId: // value for 'campaignMilestoneId'
 *   },
 * });
 */
export function useDeleteCampaignMilestoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCampaignMilestoneMutation, DeleteCampaignMilestoneMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCampaignMilestoneMutation, DeleteCampaignMilestoneMutationVariables>(DeleteCampaignMilestoneDocument, baseOptions);
      }
export type DeleteCampaignMilestoneMutationHookResult = ReturnType<typeof useDeleteCampaignMilestoneMutation>;
export type DeleteCampaignMilestoneMutationResult = ApolloReactCommon.MutationResult<DeleteCampaignMilestoneMutation>;
export type DeleteCampaignMilestoneMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCampaignMilestoneMutation, DeleteCampaignMilestoneMutationVariables>;
export const GetCampaignMilestonesDocument = gql`
    query getCampaignMilestones($campaignId: Int!) {
  getCampaignMilestones(campaignId: $campaignId) {
    milestones {
      id
      actualValue
      milestoneName
      metric
      goal
      achieved
      visibility {
        admins
        clients
      }
      notification {
        admins
        clients
      }
      createdAt
      checkedAt
      updatedAt
      achievedAt
    }
  }
}
    `;

/**
 * __useGetCampaignMilestonesQuery__
 *
 * To run a query within a React component, call `useGetCampaignMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignMilestonesQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignMilestonesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignMilestonesQuery, GetCampaignMilestonesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignMilestonesQuery, GetCampaignMilestonesQueryVariables>(GetCampaignMilestonesDocument, baseOptions);
      }
export function useGetCampaignMilestonesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignMilestonesQuery, GetCampaignMilestonesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignMilestonesQuery, GetCampaignMilestonesQueryVariables>(GetCampaignMilestonesDocument, baseOptions);
        }
export type GetCampaignMilestonesQueryHookResult = ReturnType<typeof useGetCampaignMilestonesQuery>;
export type GetCampaignMilestonesLazyQueryHookResult = ReturnType<typeof useGetCampaignMilestonesLazyQuery>;
export type GetCampaignMilestonesQueryResult = ApolloReactCommon.QueryResult<GetCampaignMilestonesQuery, GetCampaignMilestonesQueryVariables>;
export const CreateCampaignAnalyticsTrackingDocument = gql`
    mutation createCampaignAnalyticsTracking($trackingKey: String!, $campaignId: Int!) {
  createCampaignAnalyticsTracking(trackingKey: $trackingKey, campaignId: $campaignId) {
    ...CampaignFragment
  }
}
    ${CampaignFragmentFragmentDoc}`;
export type CreateCampaignAnalyticsTrackingMutationFn = ApolloReactCommon.MutationFunction<CreateCampaignAnalyticsTrackingMutation, CreateCampaignAnalyticsTrackingMutationVariables>;

/**
 * __useCreateCampaignAnalyticsTrackingMutation__
 *
 * To run a mutation, you first call `useCreateCampaignAnalyticsTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignAnalyticsTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignAnalyticsTrackingMutation, { data, loading, error }] = useCreateCampaignAnalyticsTrackingMutation({
 *   variables: {
 *      trackingKey: // value for 'trackingKey'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCreateCampaignAnalyticsTrackingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCampaignAnalyticsTrackingMutation, CreateCampaignAnalyticsTrackingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCampaignAnalyticsTrackingMutation, CreateCampaignAnalyticsTrackingMutationVariables>(CreateCampaignAnalyticsTrackingDocument, baseOptions);
      }
export type CreateCampaignAnalyticsTrackingMutationHookResult = ReturnType<typeof useCreateCampaignAnalyticsTrackingMutation>;
export type CreateCampaignAnalyticsTrackingMutationResult = ApolloReactCommon.MutationResult<CreateCampaignAnalyticsTrackingMutation>;
export type CreateCampaignAnalyticsTrackingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCampaignAnalyticsTrackingMutation, CreateCampaignAnalyticsTrackingMutationVariables>;
export const GetCampaignAnalyticsTrackingDocument = gql`
    query getCampaignAnalyticsTracking($trackingType: CampaignTrackingTypeEnum, $campaignId: Int!) {
  getCampaignAnalyticsTracking(trackingType: $trackingType, campaignId: $campaignId) {
    title
    trackingKey
    lastUpdatedDate
    graphData {
      key
      views
      posts
      posted
      accounts {
        id
        name
        image
      }
    }
  }
}
    `;

/**
 * __useGetCampaignAnalyticsTrackingQuery__
 *
 * To run a query within a React component, call `useGetCampaignAnalyticsTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignAnalyticsTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignAnalyticsTrackingQuery({
 *   variables: {
 *      trackingType: // value for 'trackingType'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignAnalyticsTrackingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignAnalyticsTrackingQuery, GetCampaignAnalyticsTrackingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignAnalyticsTrackingQuery, GetCampaignAnalyticsTrackingQueryVariables>(GetCampaignAnalyticsTrackingDocument, baseOptions);
      }
export function useGetCampaignAnalyticsTrackingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignAnalyticsTrackingQuery, GetCampaignAnalyticsTrackingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignAnalyticsTrackingQuery, GetCampaignAnalyticsTrackingQueryVariables>(GetCampaignAnalyticsTrackingDocument, baseOptions);
        }
export type GetCampaignAnalyticsTrackingQueryHookResult = ReturnType<typeof useGetCampaignAnalyticsTrackingQuery>;
export type GetCampaignAnalyticsTrackingLazyQueryHookResult = ReturnType<typeof useGetCampaignAnalyticsTrackingLazyQuery>;
export type GetCampaignAnalyticsTrackingQueryResult = ApolloReactCommon.QueryResult<GetCampaignAnalyticsTrackingQuery, GetCampaignAnalyticsTrackingQueryVariables>;
export const GetCampaignAnalyticsPostsDocument = gql`
    query getCampaignAnalyticsPosts($campaignId: Int!) {
  getCampaignAnalyticsPosts(campaignId: $campaignId) {
    id
    invitationId
    username
    profilePicUrl
    postedDate
    views
    graphData {
      key
      engagementRate
      views
      likes
      comments
    }
  }
}
    `;

/**
 * __useGetCampaignAnalyticsPostsQuery__
 *
 * To run a query within a React component, call `useGetCampaignAnalyticsPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignAnalyticsPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignAnalyticsPostsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignAnalyticsPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignAnalyticsPostsQuery, GetCampaignAnalyticsPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignAnalyticsPostsQuery, GetCampaignAnalyticsPostsQueryVariables>(GetCampaignAnalyticsPostsDocument, baseOptions);
      }
export function useGetCampaignAnalyticsPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignAnalyticsPostsQuery, GetCampaignAnalyticsPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignAnalyticsPostsQuery, GetCampaignAnalyticsPostsQueryVariables>(GetCampaignAnalyticsPostsDocument, baseOptions);
        }
export type GetCampaignAnalyticsPostsQueryHookResult = ReturnType<typeof useGetCampaignAnalyticsPostsQuery>;
export type GetCampaignAnalyticsPostsLazyQueryHookResult = ReturnType<typeof useGetCampaignAnalyticsPostsLazyQuery>;
export type GetCampaignAnalyticsPostsQueryResult = ApolloReactCommon.QueryResult<GetCampaignAnalyticsPostsQuery, GetCampaignAnalyticsPostsQueryVariables>;
export const GetCampaignAnalyticDocument = gql`
    query getCampaignAnalytic($campaignId: Int!) {
  getCampaignAnalytic(campaignId: $campaignId) {
    id
    status
    views
    screenshots
    clicks
    uniqueClicks
    installs
    cities
    countries
    clicksByHour {
      labels
      data
    }
    installsByHour {
      labels
      data
    }
    platforms
    completionRate
    budgetPennies
    cpcPennies
    cpiPennies
    ctr
    cpm
    metadata {
      likeCount
      commentCount
      engagementRate
      tracking {
        key
        id
        type
        date
      }
      rating {
        helpfulness
      }
    }
    noInfluencersPosted {
      posted
      total
    }
    clicksToInstalls
    postedAccounts {
      id
      invitationId
      username
      profilePicUrl
      postedDate
      graphData {
        key
        engagementRate
        views
        likes
        comments
      }
    }
    invitations {
      ...InvitationFragment
    }
  }
}
    ${InvitationFragmentFragmentDoc}`;

/**
 * __useGetCampaignAnalyticQuery__
 *
 * To run a query within a React component, call `useGetCampaignAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignAnalyticQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignAnalyticQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignAnalyticQuery, GetCampaignAnalyticQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignAnalyticQuery, GetCampaignAnalyticQueryVariables>(GetCampaignAnalyticDocument, baseOptions);
      }
export function useGetCampaignAnalyticLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignAnalyticQuery, GetCampaignAnalyticQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignAnalyticQuery, GetCampaignAnalyticQueryVariables>(GetCampaignAnalyticDocument, baseOptions);
        }
export type GetCampaignAnalyticQueryHookResult = ReturnType<typeof useGetCampaignAnalyticQuery>;
export type GetCampaignAnalyticLazyQueryHookResult = ReturnType<typeof useGetCampaignAnalyticLazyQuery>;
export type GetCampaignAnalyticQueryResult = ApolloReactCommon.QueryResult<GetCampaignAnalyticQuery, GetCampaignAnalyticQueryVariables>;
export const UpdateCampaignAnalyticsTrackingDocument = gql`
    mutation updateCampaignAnalyticsTracking($trackingKey: String!, $trackingIndex: Float!, $campaignId: Int!) {
  updateCampaignAnalyticsTracking(trackingKey: $trackingKey, trackingIndex: $trackingIndex, campaignId: $campaignId) {
    ...CampaignFragment
  }
}
    ${CampaignFragmentFragmentDoc}`;
export type UpdateCampaignAnalyticsTrackingMutationFn = ApolloReactCommon.MutationFunction<UpdateCampaignAnalyticsTrackingMutation, UpdateCampaignAnalyticsTrackingMutationVariables>;

/**
 * __useUpdateCampaignAnalyticsTrackingMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignAnalyticsTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignAnalyticsTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignAnalyticsTrackingMutation, { data, loading, error }] = useUpdateCampaignAnalyticsTrackingMutation({
 *   variables: {
 *      trackingKey: // value for 'trackingKey'
 *      trackingIndex: // value for 'trackingIndex'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUpdateCampaignAnalyticsTrackingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCampaignAnalyticsTrackingMutation, UpdateCampaignAnalyticsTrackingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCampaignAnalyticsTrackingMutation, UpdateCampaignAnalyticsTrackingMutationVariables>(UpdateCampaignAnalyticsTrackingDocument, baseOptions);
      }
export type UpdateCampaignAnalyticsTrackingMutationHookResult = ReturnType<typeof useUpdateCampaignAnalyticsTrackingMutation>;
export type UpdateCampaignAnalyticsTrackingMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignAnalyticsTrackingMutation>;
export type UpdateCampaignAnalyticsTrackingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCampaignAnalyticsTrackingMutation, UpdateCampaignAnalyticsTrackingMutationVariables>;
export const DeleteCampaignAnalyticsTrackingDocument = gql`
    mutation deleteCampaignAnalyticsTracking($trackingKey: String!, $campaignId: Int!) {
  deleteCampaignAnalyticsTracking(trackingKey: $trackingKey, campaignId: $campaignId) {
    ...CampaignFragment
  }
}
    ${CampaignFragmentFragmentDoc}`;
export type DeleteCampaignAnalyticsTrackingMutationFn = ApolloReactCommon.MutationFunction<DeleteCampaignAnalyticsTrackingMutation, DeleteCampaignAnalyticsTrackingMutationVariables>;

/**
 * __useDeleteCampaignAnalyticsTrackingMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignAnalyticsTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignAnalyticsTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignAnalyticsTrackingMutation, { data, loading, error }] = useDeleteCampaignAnalyticsTrackingMutation({
 *   variables: {
 *      trackingKey: // value for 'trackingKey'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteCampaignAnalyticsTrackingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCampaignAnalyticsTrackingMutation, DeleteCampaignAnalyticsTrackingMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCampaignAnalyticsTrackingMutation, DeleteCampaignAnalyticsTrackingMutationVariables>(DeleteCampaignAnalyticsTrackingDocument, baseOptions);
      }
export type DeleteCampaignAnalyticsTrackingMutationHookResult = ReturnType<typeof useDeleteCampaignAnalyticsTrackingMutation>;
export type DeleteCampaignAnalyticsTrackingMutationResult = ApolloReactCommon.MutationResult<DeleteCampaignAnalyticsTrackingMutation>;
export type DeleteCampaignAnalyticsTrackingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCampaignAnalyticsTrackingMutation, DeleteCampaignAnalyticsTrackingMutationVariables>;
export const GetCampaignGroupByIdDocument = gql`
    query getCampaignGroupById($groupId: Float!) {
  getCampaignGroupById(groupId: $groupId) {
    ...CampaignGroupFragment
  }
}
    ${CampaignGroupFragmentFragmentDoc}`;

/**
 * __useGetCampaignGroupByIdQuery__
 *
 * To run a query within a React component, call `useGetCampaignGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignGroupByIdQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetCampaignGroupByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignGroupByIdQuery, GetCampaignGroupByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignGroupByIdQuery, GetCampaignGroupByIdQueryVariables>(GetCampaignGroupByIdDocument, baseOptions);
      }
export function useGetCampaignGroupByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignGroupByIdQuery, GetCampaignGroupByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignGroupByIdQuery, GetCampaignGroupByIdQueryVariables>(GetCampaignGroupByIdDocument, baseOptions);
        }
export type GetCampaignGroupByIdQueryHookResult = ReturnType<typeof useGetCampaignGroupByIdQuery>;
export type GetCampaignGroupByIdLazyQueryHookResult = ReturnType<typeof useGetCampaignGroupByIdLazyQuery>;
export type GetCampaignGroupByIdQueryResult = ApolloReactCommon.QueryResult<GetCampaignGroupByIdQuery, GetCampaignGroupByIdQueryVariables>;
export const SearchCampaignsByCampaignGroupIdDocument = gql`
    query searchCampaignsByCampaignGroupId($page: Float, $groupId: Int!, $campaignTitle: String) {
  searchCampaignsByCampaignGroupId(page: $page, groupId: $groupId, campaignTitle: $campaignTitle) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    campaigns {
      ...CampaignFragment
    }
  }
}
    ${CampaignFragmentFragmentDoc}`;

/**
 * __useSearchCampaignsByCampaignGroupIdQuery__
 *
 * To run a query within a React component, call `useSearchCampaignsByCampaignGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCampaignsByCampaignGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCampaignsByCampaignGroupIdQuery({
 *   variables: {
 *      page: // value for 'page'
 *      groupId: // value for 'groupId'
 *      campaignTitle: // value for 'campaignTitle'
 *   },
 * });
 */
export function useSearchCampaignsByCampaignGroupIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchCampaignsByCampaignGroupIdQuery, SearchCampaignsByCampaignGroupIdQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchCampaignsByCampaignGroupIdQuery, SearchCampaignsByCampaignGroupIdQueryVariables>(SearchCampaignsByCampaignGroupIdDocument, baseOptions);
      }
export function useSearchCampaignsByCampaignGroupIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchCampaignsByCampaignGroupIdQuery, SearchCampaignsByCampaignGroupIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchCampaignsByCampaignGroupIdQuery, SearchCampaignsByCampaignGroupIdQueryVariables>(SearchCampaignsByCampaignGroupIdDocument, baseOptions);
        }
export type SearchCampaignsByCampaignGroupIdQueryHookResult = ReturnType<typeof useSearchCampaignsByCampaignGroupIdQuery>;
export type SearchCampaignsByCampaignGroupIdLazyQueryHookResult = ReturnType<typeof useSearchCampaignsByCampaignGroupIdLazyQuery>;
export type SearchCampaignsByCampaignGroupIdQueryResult = ApolloReactCommon.QueryResult<SearchCampaignsByCampaignGroupIdQuery, SearchCampaignsByCampaignGroupIdQueryVariables>;
export const UpdateCampaignGroupDocument = gql`
    mutation updateCampaignGroup($groupId: Int!, $groupName: String!, $companyId: Int!, $extraBrandIds: [Int]!) {
  updateCampaignGroup(groupId: $groupId, groupName: $groupName, companyId: $companyId, extraBrandIds: $extraBrandIds) {
    ...CampaignGroupFragment
  }
}
    ${CampaignGroupFragmentFragmentDoc}`;
export type UpdateCampaignGroupMutationFn = ApolloReactCommon.MutationFunction<UpdateCampaignGroupMutation, UpdateCampaignGroupMutationVariables>;

/**
 * __useUpdateCampaignGroupMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignGroupMutation, { data, loading, error }] = useUpdateCampaignGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      groupName: // value for 'groupName'
 *      companyId: // value for 'companyId'
 *      extraBrandIds: // value for 'extraBrandIds'
 *   },
 * });
 */
export function useUpdateCampaignGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCampaignGroupMutation, UpdateCampaignGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCampaignGroupMutation, UpdateCampaignGroupMutationVariables>(UpdateCampaignGroupDocument, baseOptions);
      }
export type UpdateCampaignGroupMutationHookResult = ReturnType<typeof useUpdateCampaignGroupMutation>;
export type UpdateCampaignGroupMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignGroupMutation>;
export type UpdateCampaignGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCampaignGroupMutation, UpdateCampaignGroupMutationVariables>;
export const GetCampaignsForCampaignGroupDocument = gql`
    query getCampaignsForCampaignGroup($page: Float, $groupId: Int!, $campaignTitle: String) {
  getCampaignsForCampaignGroup(page: $page, groupId: $groupId, campaignTitle: $campaignTitle) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    campaigns {
      ...CampaignFragment
    }
  }
}
    ${CampaignFragmentFragmentDoc}`;

/**
 * __useGetCampaignsForCampaignGroupQuery__
 *
 * To run a query within a React component, call `useGetCampaignsForCampaignGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsForCampaignGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsForCampaignGroupQuery({
 *   variables: {
 *      page: // value for 'page'
 *      groupId: // value for 'groupId'
 *      campaignTitle: // value for 'campaignTitle'
 *   },
 * });
 */
export function useGetCampaignsForCampaignGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignsForCampaignGroupQuery, GetCampaignsForCampaignGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignsForCampaignGroupQuery, GetCampaignsForCampaignGroupQueryVariables>(GetCampaignsForCampaignGroupDocument, baseOptions);
      }
export function useGetCampaignsForCampaignGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignsForCampaignGroupQuery, GetCampaignsForCampaignGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignsForCampaignGroupQuery, GetCampaignsForCampaignGroupQueryVariables>(GetCampaignsForCampaignGroupDocument, baseOptions);
        }
export type GetCampaignsForCampaignGroupQueryHookResult = ReturnType<typeof useGetCampaignsForCampaignGroupQuery>;
export type GetCampaignsForCampaignGroupLazyQueryHookResult = ReturnType<typeof useGetCampaignsForCampaignGroupLazyQuery>;
export type GetCampaignsForCampaignGroupQueryResult = ApolloReactCommon.QueryResult<GetCampaignsForCampaignGroupQuery, GetCampaignsForCampaignGroupQueryVariables>;
export const AddCampaignsToCampaignGroupDocument = gql`
    mutation addCampaignsToCampaignGroup($groupId: Float!, $campaignIds: [Int!]!) {
  addCampaignsToCampaignGroup(groupId: $groupId, campaignIds: $campaignIds) {
    ...CampaignGroupFragment
  }
}
    ${CampaignGroupFragmentFragmentDoc}`;
export type AddCampaignsToCampaignGroupMutationFn = ApolloReactCommon.MutationFunction<AddCampaignsToCampaignGroupMutation, AddCampaignsToCampaignGroupMutationVariables>;

/**
 * __useAddCampaignsToCampaignGroupMutation__
 *
 * To run a mutation, you first call `useAddCampaignsToCampaignGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCampaignsToCampaignGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCampaignsToCampaignGroupMutation, { data, loading, error }] = useAddCampaignsToCampaignGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      campaignIds: // value for 'campaignIds'
 *   },
 * });
 */
export function useAddCampaignsToCampaignGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCampaignsToCampaignGroupMutation, AddCampaignsToCampaignGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCampaignsToCampaignGroupMutation, AddCampaignsToCampaignGroupMutationVariables>(AddCampaignsToCampaignGroupDocument, baseOptions);
      }
export type AddCampaignsToCampaignGroupMutationHookResult = ReturnType<typeof useAddCampaignsToCampaignGroupMutation>;
export type AddCampaignsToCampaignGroupMutationResult = ApolloReactCommon.MutationResult<AddCampaignsToCampaignGroupMutation>;
export type AddCampaignsToCampaignGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCampaignsToCampaignGroupMutation, AddCampaignsToCampaignGroupMutationVariables>;
export const RemoveCampaignsFromCampaignGroupDocument = gql`
    mutation removeCampaignsFromCampaignGroup($groupId: Float!, $campaignIds: [Int!]!) {
  removeCampaignsFromCampaignGroup(groupId: $groupId, campaignIds: $campaignIds) {
    ...CampaignGroupFragment
  }
}
    ${CampaignGroupFragmentFragmentDoc}`;
export type RemoveCampaignsFromCampaignGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveCampaignsFromCampaignGroupMutation, RemoveCampaignsFromCampaignGroupMutationVariables>;

/**
 * __useRemoveCampaignsFromCampaignGroupMutation__
 *
 * To run a mutation, you first call `useRemoveCampaignsFromCampaignGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCampaignsFromCampaignGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCampaignsFromCampaignGroupMutation, { data, loading, error }] = useRemoveCampaignsFromCampaignGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      campaignIds: // value for 'campaignIds'
 *   },
 * });
 */
export function useRemoveCampaignsFromCampaignGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCampaignsFromCampaignGroupMutation, RemoveCampaignsFromCampaignGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCampaignsFromCampaignGroupMutation, RemoveCampaignsFromCampaignGroupMutationVariables>(RemoveCampaignsFromCampaignGroupDocument, baseOptions);
      }
export type RemoveCampaignsFromCampaignGroupMutationHookResult = ReturnType<typeof useRemoveCampaignsFromCampaignGroupMutation>;
export type RemoveCampaignsFromCampaignGroupMutationResult = ApolloReactCommon.MutationResult<RemoveCampaignsFromCampaignGroupMutation>;
export type RemoveCampaignsFromCampaignGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCampaignsFromCampaignGroupMutation, RemoveCampaignsFromCampaignGroupMutationVariables>;
export const CreateCampaignsToCampaignGroupDocument = gql`
    mutation createCampaignsToCampaignGroup($title: String!, $groupId: Int!, $extraBrandIds: [Int!]!, $companyId: Int!) {
  createCampaignsToCampaignGroup(groupId: $groupId, extraBrandIds: $extraBrandIds, companyId: $companyId, title: $title) {
    ...CampaignFragment
  }
}
    ${CampaignFragmentFragmentDoc}`;
export type CreateCampaignsToCampaignGroupMutationFn = ApolloReactCommon.MutationFunction<CreateCampaignsToCampaignGroupMutation, CreateCampaignsToCampaignGroupMutationVariables>;

/**
 * __useCreateCampaignsToCampaignGroupMutation__
 *
 * To run a mutation, you first call `useCreateCampaignsToCampaignGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignsToCampaignGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignsToCampaignGroupMutation, { data, loading, error }] = useCreateCampaignsToCampaignGroupMutation({
 *   variables: {
 *      title: // value for 'title'
 *      groupId: // value for 'groupId'
 *      extraBrandIds: // value for 'extraBrandIds'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateCampaignsToCampaignGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCampaignsToCampaignGroupMutation, CreateCampaignsToCampaignGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCampaignsToCampaignGroupMutation, CreateCampaignsToCampaignGroupMutationVariables>(CreateCampaignsToCampaignGroupDocument, baseOptions);
      }
export type CreateCampaignsToCampaignGroupMutationHookResult = ReturnType<typeof useCreateCampaignsToCampaignGroupMutation>;
export type CreateCampaignsToCampaignGroupMutationResult = ApolloReactCommon.MutationResult<CreateCampaignsToCampaignGroupMutation>;
export type CreateCampaignsToCampaignGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCampaignsToCampaignGroupMutation, CreateCampaignsToCampaignGroupMutationVariables>;
export const DeleteCampaignGroupDocument = gql`
    mutation deleteCampaignGroup($groupId: Int!) {
  deleteCampaignGroup(groupId: $groupId)
}
    `;
export type DeleteCampaignGroupMutationFn = ApolloReactCommon.MutationFunction<DeleteCampaignGroupMutation, DeleteCampaignGroupMutationVariables>;

/**
 * __useDeleteCampaignGroupMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignGroupMutation, { data, loading, error }] = useDeleteCampaignGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteCampaignGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCampaignGroupMutation, DeleteCampaignGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCampaignGroupMutation, DeleteCampaignGroupMutationVariables>(DeleteCampaignGroupDocument, baseOptions);
      }
export type DeleteCampaignGroupMutationHookResult = ReturnType<typeof useDeleteCampaignGroupMutation>;
export type DeleteCampaignGroupMutationResult = ApolloReactCommon.MutationResult<DeleteCampaignGroupMutation>;
export type DeleteCampaignGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCampaignGroupMutation, DeleteCampaignGroupMutationVariables>;
export const GetSuggestionAccountsByCampaignIdDocument = gql`
    query getSuggestionAccountsByCampaignId($campaignId: Float!, $matchType: BreakdownType, $page: Int, $limit: Int) {
  getSuggestionAccountsByCampaignId(campaignId: $campaignId, matchType: $matchType, page: $page, limit: $limit) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    accounts {
      ...AccountFragement
    }
  }
}
    ${AccountFragementFragmentDoc}`;

/**
 * __useGetSuggestionAccountsByCampaignIdQuery__
 *
 * To run a query within a React component, call `useGetSuggestionAccountsByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestionAccountsByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestionAccountsByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      matchType: // value for 'matchType'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSuggestionAccountsByCampaignIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSuggestionAccountsByCampaignIdQuery, GetSuggestionAccountsByCampaignIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSuggestionAccountsByCampaignIdQuery, GetSuggestionAccountsByCampaignIdQueryVariables>(GetSuggestionAccountsByCampaignIdDocument, baseOptions);
      }
export function useGetSuggestionAccountsByCampaignIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuggestionAccountsByCampaignIdQuery, GetSuggestionAccountsByCampaignIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSuggestionAccountsByCampaignIdQuery, GetSuggestionAccountsByCampaignIdQueryVariables>(GetSuggestionAccountsByCampaignIdDocument, baseOptions);
        }
export type GetSuggestionAccountsByCampaignIdQueryHookResult = ReturnType<typeof useGetSuggestionAccountsByCampaignIdQuery>;
export type GetSuggestionAccountsByCampaignIdLazyQueryHookResult = ReturnType<typeof useGetSuggestionAccountsByCampaignIdLazyQuery>;
export type GetSuggestionAccountsByCampaignIdQueryResult = ApolloReactCommon.QueryResult<GetSuggestionAccountsByCampaignIdQuery, GetSuggestionAccountsByCampaignIdQueryVariables>;
export const DismissSuggestionAccountDocument = gql`
    mutation dismissSuggestionAccount($campaignId: Float!, $accountId: Float!) {
  dismissSuggestionAccount(campaignId: $campaignId, accountId: $accountId) {
    ...SuggestionFragment
  }
}
    ${SuggestionFragmentFragmentDoc}`;
export type DismissSuggestionAccountMutationFn = ApolloReactCommon.MutationFunction<DismissSuggestionAccountMutation, DismissSuggestionAccountMutationVariables>;

/**
 * __useDismissSuggestionAccountMutation__
 *
 * To run a mutation, you first call `useDismissSuggestionAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissSuggestionAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissSuggestionAccountMutation, { data, loading, error }] = useDismissSuggestionAccountMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDismissSuggestionAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DismissSuggestionAccountMutation, DismissSuggestionAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DismissSuggestionAccountMutation, DismissSuggestionAccountMutationVariables>(DismissSuggestionAccountDocument, baseOptions);
      }
export type DismissSuggestionAccountMutationHookResult = ReturnType<typeof useDismissSuggestionAccountMutation>;
export type DismissSuggestionAccountMutationResult = ApolloReactCommon.MutationResult<DismissSuggestionAccountMutation>;
export type DismissSuggestionAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DismissSuggestionAccountMutation, DismissSuggestionAccountMutationVariables>;
export const CreateSuggestionAccountDocument = gql`
    mutation createSuggestionAccount($campaignId: Int!, $accountId: Int!, $rationale: String) {
  createSuggestionAccount(campaignId: $campaignId, accountId: $accountId, rationale: $rationale) {
    ...SuggestionFragment
  }
}
    ${SuggestionFragmentFragmentDoc}`;
export type CreateSuggestionAccountMutationFn = ApolloReactCommon.MutationFunction<CreateSuggestionAccountMutation, CreateSuggestionAccountMutationVariables>;

/**
 * __useCreateSuggestionAccountMutation__
 *
 * To run a mutation, you first call `useCreateSuggestionAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuggestionAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuggestionAccountMutation, { data, loading, error }] = useCreateSuggestionAccountMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *      rationale: // value for 'rationale'
 *   },
 * });
 */
export function useCreateSuggestionAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSuggestionAccountMutation, CreateSuggestionAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSuggestionAccountMutation, CreateSuggestionAccountMutationVariables>(CreateSuggestionAccountDocument, baseOptions);
      }
export type CreateSuggestionAccountMutationHookResult = ReturnType<typeof useCreateSuggestionAccountMutation>;
export type CreateSuggestionAccountMutationResult = ApolloReactCommon.MutationResult<CreateSuggestionAccountMutation>;
export type CreateSuggestionAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSuggestionAccountMutation, CreateSuggestionAccountMutationVariables>;
export const GetPublicCampaignApplicantsDocument = gql`
    query getPublicCampaignApplicants($campaignId: Int!) {
  getPublicCampaignApplicants(campaignId: $campaignId) {
    express {
      email
      status
      platform
      username
      expectedFee
    }
    applicants {
      ...applicant
    }
    accepted {
      ...applicant
    }
    rejected {
      ...applicant
    }
  }
}
    ${ApplicantFragmentDoc}`;

/**
 * __useGetPublicCampaignApplicantsQuery__
 *
 * To run a query within a React component, call `useGetPublicCampaignApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCampaignApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCampaignApplicantsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetPublicCampaignApplicantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicCampaignApplicantsQuery, GetPublicCampaignApplicantsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicCampaignApplicantsQuery, GetPublicCampaignApplicantsQueryVariables>(GetPublicCampaignApplicantsDocument, baseOptions);
      }
export function useGetPublicCampaignApplicantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicCampaignApplicantsQuery, GetPublicCampaignApplicantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicCampaignApplicantsQuery, GetPublicCampaignApplicantsQueryVariables>(GetPublicCampaignApplicantsDocument, baseOptions);
        }
export type GetPublicCampaignApplicantsQueryHookResult = ReturnType<typeof useGetPublicCampaignApplicantsQuery>;
export type GetPublicCampaignApplicantsLazyQueryHookResult = ReturnType<typeof useGetPublicCampaignApplicantsLazyQuery>;
export type GetPublicCampaignApplicantsQueryResult = ApolloReactCommon.QueryResult<GetPublicCampaignApplicantsQuery, GetPublicCampaignApplicantsQueryVariables>;
export const AcceptPublicCampaignApplicantDocument = gql`
    mutation acceptPublicCampaignApplicant($campaignId: Int!, $accountId: Int!) {
  acceptPublicCampaignApplicant(campaignId: $campaignId, accountId: $accountId) {
    ...suggestedApplicant
  }
}
    ${SuggestedApplicantFragmentDoc}`;
export type AcceptPublicCampaignApplicantMutationFn = ApolloReactCommon.MutationFunction<AcceptPublicCampaignApplicantMutation, AcceptPublicCampaignApplicantMutationVariables>;

/**
 * __useAcceptPublicCampaignApplicantMutation__
 *
 * To run a mutation, you first call `useAcceptPublicCampaignApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPublicCampaignApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPublicCampaignApplicantMutation, { data, loading, error }] = useAcceptPublicCampaignApplicantMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAcceptPublicCampaignApplicantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptPublicCampaignApplicantMutation, AcceptPublicCampaignApplicantMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptPublicCampaignApplicantMutation, AcceptPublicCampaignApplicantMutationVariables>(AcceptPublicCampaignApplicantDocument, baseOptions);
      }
export type AcceptPublicCampaignApplicantMutationHookResult = ReturnType<typeof useAcceptPublicCampaignApplicantMutation>;
export type AcceptPublicCampaignApplicantMutationResult = ApolloReactCommon.MutationResult<AcceptPublicCampaignApplicantMutation>;
export type AcceptPublicCampaignApplicantMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptPublicCampaignApplicantMutation, AcceptPublicCampaignApplicantMutationVariables>;
export const RejectPublicCampaignApplicantDocument = gql`
    mutation rejectPublicCampaignApplicant($campaignId: Int!, $accountId: Int!) {
  rejectPublicCampaignApplicant(campaignId: $campaignId, accountId: $accountId) {
    ...suggestedApplicant
  }
}
    ${SuggestedApplicantFragmentDoc}`;
export type RejectPublicCampaignApplicantMutationFn = ApolloReactCommon.MutationFunction<RejectPublicCampaignApplicantMutation, RejectPublicCampaignApplicantMutationVariables>;

/**
 * __useRejectPublicCampaignApplicantMutation__
 *
 * To run a mutation, you first call `useRejectPublicCampaignApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPublicCampaignApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPublicCampaignApplicantMutation, { data, loading, error }] = useRejectPublicCampaignApplicantMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRejectPublicCampaignApplicantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectPublicCampaignApplicantMutation, RejectPublicCampaignApplicantMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectPublicCampaignApplicantMutation, RejectPublicCampaignApplicantMutationVariables>(RejectPublicCampaignApplicantDocument, baseOptions);
      }
export type RejectPublicCampaignApplicantMutationHookResult = ReturnType<typeof useRejectPublicCampaignApplicantMutation>;
export type RejectPublicCampaignApplicantMutationResult = ApolloReactCommon.MutationResult<RejectPublicCampaignApplicantMutation>;
export type RejectPublicCampaignApplicantMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectPublicCampaignApplicantMutation, RejectPublicCampaignApplicantMutationVariables>;
export const RollbackPublicCampaignApplicantDocument = gql`
    mutation rollbackPublicCampaignApplicant($campaignId: Int!, $accountId: Int!) {
  rollbackPublicCampaignApplicant(campaignId: $campaignId, accountId: $accountId) {
    ...suggestedApplicant
  }
}
    ${SuggestedApplicantFragmentDoc}`;
export type RollbackPublicCampaignApplicantMutationFn = ApolloReactCommon.MutationFunction<RollbackPublicCampaignApplicantMutation, RollbackPublicCampaignApplicantMutationVariables>;

/**
 * __useRollbackPublicCampaignApplicantMutation__
 *
 * To run a mutation, you first call `useRollbackPublicCampaignApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRollbackPublicCampaignApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rollbackPublicCampaignApplicantMutation, { data, loading, error }] = useRollbackPublicCampaignApplicantMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRollbackPublicCampaignApplicantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RollbackPublicCampaignApplicantMutation, RollbackPublicCampaignApplicantMutationVariables>) {
        return ApolloReactHooks.useMutation<RollbackPublicCampaignApplicantMutation, RollbackPublicCampaignApplicantMutationVariables>(RollbackPublicCampaignApplicantDocument, baseOptions);
      }
export type RollbackPublicCampaignApplicantMutationHookResult = ReturnType<typeof useRollbackPublicCampaignApplicantMutation>;
export type RollbackPublicCampaignApplicantMutationResult = ApolloReactCommon.MutationResult<RollbackPublicCampaignApplicantMutation>;
export type RollbackPublicCampaignApplicantMutationOptions = ApolloReactCommon.BaseMutationOptions<RollbackPublicCampaignApplicantMutation, RollbackPublicCampaignApplicantMutationVariables>;
export const ConfirmPublicCampaignApplicantDocument = gql`
    mutation confirmPublicCampaignApplicant($campaignId: Int!, $accountId: Int!) {
  confirmPublicCampaignApplicant(campaignId: $campaignId, accountId: $accountId) {
    ...suggestedApplicant
  }
}
    ${SuggestedApplicantFragmentDoc}`;
export type ConfirmPublicCampaignApplicantMutationFn = ApolloReactCommon.MutationFunction<ConfirmPublicCampaignApplicantMutation, ConfirmPublicCampaignApplicantMutationVariables>;

/**
 * __useConfirmPublicCampaignApplicantMutation__
 *
 * To run a mutation, you first call `useConfirmPublicCampaignApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPublicCampaignApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPublicCampaignApplicantMutation, { data, loading, error }] = useConfirmPublicCampaignApplicantMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useConfirmPublicCampaignApplicantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmPublicCampaignApplicantMutation, ConfirmPublicCampaignApplicantMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmPublicCampaignApplicantMutation, ConfirmPublicCampaignApplicantMutationVariables>(ConfirmPublicCampaignApplicantDocument, baseOptions);
      }
export type ConfirmPublicCampaignApplicantMutationHookResult = ReturnType<typeof useConfirmPublicCampaignApplicantMutation>;
export type ConfirmPublicCampaignApplicantMutationResult = ApolloReactCommon.MutationResult<ConfirmPublicCampaignApplicantMutation>;
export type ConfirmPublicCampaignApplicantMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmPublicCampaignApplicantMutation, ConfirmPublicCampaignApplicantMutationVariables>;
export const NotifyPublicCampaignApplicantDocument = gql`
    mutation notifyPublicCampaignApplicant($campaignId: Int!, $accountId: Int!) {
  notifyPublicCampaignApplicant(campaignId: $campaignId, accountId: $accountId) {
    ...suggestedApplicant
  }
}
    ${SuggestedApplicantFragmentDoc}`;
export type NotifyPublicCampaignApplicantMutationFn = ApolloReactCommon.MutationFunction<NotifyPublicCampaignApplicantMutation, NotifyPublicCampaignApplicantMutationVariables>;

/**
 * __useNotifyPublicCampaignApplicantMutation__
 *
 * To run a mutation, you first call `useNotifyPublicCampaignApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyPublicCampaignApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyPublicCampaignApplicantMutation, { data, loading, error }] = useNotifyPublicCampaignApplicantMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useNotifyPublicCampaignApplicantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotifyPublicCampaignApplicantMutation, NotifyPublicCampaignApplicantMutationVariables>) {
        return ApolloReactHooks.useMutation<NotifyPublicCampaignApplicantMutation, NotifyPublicCampaignApplicantMutationVariables>(NotifyPublicCampaignApplicantDocument, baseOptions);
      }
export type NotifyPublicCampaignApplicantMutationHookResult = ReturnType<typeof useNotifyPublicCampaignApplicantMutation>;
export type NotifyPublicCampaignApplicantMutationResult = ApolloReactCommon.MutationResult<NotifyPublicCampaignApplicantMutation>;
export type NotifyPublicCampaignApplicantMutationOptions = ApolloReactCommon.BaseMutationOptions<NotifyPublicCampaignApplicantMutation, NotifyPublicCampaignApplicantMutationVariables>;
export const RequestDataFromExpressApplicantDocument = gql`
    mutation requestDataFromExpressApplicant($email: String!, $username: String!, $platform: String!, $campaignId: Int!) {
  requestDataFromExpressApplicant(email: $email, username: $username, platform: $platform, campaignId: $campaignId) {
    id
  }
}
    `;
export type RequestDataFromExpressApplicantMutationFn = ApolloReactCommon.MutationFunction<RequestDataFromExpressApplicantMutation, RequestDataFromExpressApplicantMutationVariables>;

/**
 * __useRequestDataFromExpressApplicantMutation__
 *
 * To run a mutation, you first call `useRequestDataFromExpressApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDataFromExpressApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDataFromExpressApplicantMutation, { data, loading, error }] = useRequestDataFromExpressApplicantMutation({
 *   variables: {
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      platform: // value for 'platform'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useRequestDataFromExpressApplicantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestDataFromExpressApplicantMutation, RequestDataFromExpressApplicantMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestDataFromExpressApplicantMutation, RequestDataFromExpressApplicantMutationVariables>(RequestDataFromExpressApplicantDocument, baseOptions);
      }
export type RequestDataFromExpressApplicantMutationHookResult = ReturnType<typeof useRequestDataFromExpressApplicantMutation>;
export type RequestDataFromExpressApplicantMutationResult = ApolloReactCommon.MutationResult<RequestDataFromExpressApplicantMutation>;
export type RequestDataFromExpressApplicantMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestDataFromExpressApplicantMutation, RequestDataFromExpressApplicantMutationVariables>;
export const GetScreenshotSignedUrlDocument = gql`
    mutation getScreenshotSignedURL($fileName: String!, $fileType: String!) {
  getScreenshotSignedURL(input: {fileName: $fileName, fileType: $fileType}) {
    url
    uploadUrl
  }
}
    `;
export type GetScreenshotSignedUrlMutationFn = ApolloReactCommon.MutationFunction<GetScreenshotSignedUrlMutation, GetScreenshotSignedUrlMutationVariables>;

/**
 * __useGetScreenshotSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetScreenshotSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetScreenshotSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getScreenshotSignedUrlMutation, { data, loading, error }] = useGetScreenshotSignedUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useGetScreenshotSignedUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetScreenshotSignedUrlMutation, GetScreenshotSignedUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<GetScreenshotSignedUrlMutation, GetScreenshotSignedUrlMutationVariables>(GetScreenshotSignedUrlDocument, baseOptions);
      }
export type GetScreenshotSignedUrlMutationHookResult = ReturnType<typeof useGetScreenshotSignedUrlMutation>;
export type GetScreenshotSignedUrlMutationResult = ApolloReactCommon.MutationResult<GetScreenshotSignedUrlMutation>;
export type GetScreenshotSignedUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<GetScreenshotSignedUrlMutation, GetScreenshotSignedUrlMutationVariables>;
export const GetPaymentRequestInvitationByIdDocument = gql`
    query getPaymentRequestInvitationById($invitationId: Float!) {
  getPaymentRequestInvitationById(invitationId: $invitationId) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;

/**
 * __useGetPaymentRequestInvitationByIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentRequestInvitationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentRequestInvitationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentRequestInvitationByIdQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useGetPaymentRequestInvitationByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentRequestInvitationByIdQuery, GetPaymentRequestInvitationByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentRequestInvitationByIdQuery, GetPaymentRequestInvitationByIdQueryVariables>(GetPaymentRequestInvitationByIdDocument, baseOptions);
      }
export function useGetPaymentRequestInvitationByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentRequestInvitationByIdQuery, GetPaymentRequestInvitationByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentRequestInvitationByIdQuery, GetPaymentRequestInvitationByIdQueryVariables>(GetPaymentRequestInvitationByIdDocument, baseOptions);
        }
export type GetPaymentRequestInvitationByIdQueryHookResult = ReturnType<typeof useGetPaymentRequestInvitationByIdQuery>;
export type GetPaymentRequestInvitationByIdLazyQueryHookResult = ReturnType<typeof useGetPaymentRequestInvitationByIdLazyQuery>;
export type GetPaymentRequestInvitationByIdQueryResult = ApolloReactCommon.QueryResult<GetPaymentRequestInvitationByIdQuery, GetPaymentRequestInvitationByIdQueryVariables>;
export const SetAccountBreakdownAfterPaymentRequestDocument = gql`
    mutation setAccountBreakdownAfterPaymentRequest($accountId: Float!, $followers: Float, $ageBreakdown: JSON, $genderBreakdown: JSON, $locationBreakdown: JSON) {
  setAccountBreakdownAfterPaymentRequest(accountId: $accountId, followers: $followers, ageBreakdown: $ageBreakdown, genderBreakdown: $genderBreakdown, locationBreakdown: $locationBreakdown) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;
export type SetAccountBreakdownAfterPaymentRequestMutationFn = ApolloReactCommon.MutationFunction<SetAccountBreakdownAfterPaymentRequestMutation, SetAccountBreakdownAfterPaymentRequestMutationVariables>;

/**
 * __useSetAccountBreakdownAfterPaymentRequestMutation__
 *
 * To run a mutation, you first call `useSetAccountBreakdownAfterPaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountBreakdownAfterPaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountBreakdownAfterPaymentRequestMutation, { data, loading, error }] = useSetAccountBreakdownAfterPaymentRequestMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      followers: // value for 'followers'
 *      ageBreakdown: // value for 'ageBreakdown'
 *      genderBreakdown: // value for 'genderBreakdown'
 *      locationBreakdown: // value for 'locationBreakdown'
 *   },
 * });
 */
export function useSetAccountBreakdownAfterPaymentRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetAccountBreakdownAfterPaymentRequestMutation, SetAccountBreakdownAfterPaymentRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SetAccountBreakdownAfterPaymentRequestMutation, SetAccountBreakdownAfterPaymentRequestMutationVariables>(SetAccountBreakdownAfterPaymentRequestDocument, baseOptions);
      }
export type SetAccountBreakdownAfterPaymentRequestMutationHookResult = ReturnType<typeof useSetAccountBreakdownAfterPaymentRequestMutation>;
export type SetAccountBreakdownAfterPaymentRequestMutationResult = ApolloReactCommon.MutationResult<SetAccountBreakdownAfterPaymentRequestMutation>;
export type SetAccountBreakdownAfterPaymentRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SetAccountBreakdownAfterPaymentRequestMutation, SetAccountBreakdownAfterPaymentRequestMutationVariables>;
export const UpdateAccountRatingDocument = gql`
    mutation updateAccountRating($accountId: Int!, $creationTime: Float = null, $responseTime: Float = null, $contentQuality: Float = null, $professionalism: Float = null) {
  updateAccountRating(accountId: $accountId, creationTime: $creationTime, responseTime: $responseTime, contentQuality: $contentQuality, professionalism: $professionalism) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;
export type UpdateAccountRatingMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountRatingMutation, UpdateAccountRatingMutationVariables>;

/**
 * __useUpdateAccountRatingMutation__
 *
 * To run a mutation, you first call `useUpdateAccountRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountRatingMutation, { data, loading, error }] = useUpdateAccountRatingMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      creationTime: // value for 'creationTime'
 *      responseTime: // value for 'responseTime'
 *      contentQuality: // value for 'contentQuality'
 *      professionalism: // value for 'professionalism'
 *   },
 * });
 */
export function useUpdateAccountRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountRatingMutation, UpdateAccountRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountRatingMutation, UpdateAccountRatingMutationVariables>(UpdateAccountRatingDocument, baseOptions);
      }
export type UpdateAccountRatingMutationHookResult = ReturnType<typeof useUpdateAccountRatingMutation>;
export type UpdateAccountRatingMutationResult = ApolloReactCommon.MutationResult<UpdateAccountRatingMutation>;
export type UpdateAccountRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountRatingMutation, UpdateAccountRatingMutationVariables>;
export const GetCampaignReportingDocument = gql`
    query getCampaignReporting($campaignId: Float!) {
  getCampaignReporting(campaignId: $campaignId) {
    ...CampaignFragment
  }
}
    ${CampaignFragmentFragmentDoc}`;

/**
 * __useGetCampaignReportingQuery__
 *
 * To run a query within a React component, call `useGetCampaignReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignReportingQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignReportingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignReportingQuery, GetCampaignReportingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignReportingQuery, GetCampaignReportingQueryVariables>(GetCampaignReportingDocument, baseOptions);
      }
export function useGetCampaignReportingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignReportingQuery, GetCampaignReportingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignReportingQuery, GetCampaignReportingQueryVariables>(GetCampaignReportingDocument, baseOptions);
        }
export type GetCampaignReportingQueryHookResult = ReturnType<typeof useGetCampaignReportingQuery>;
export type GetCampaignReportingLazyQueryHookResult = ReturnType<typeof useGetCampaignReportingLazyQuery>;
export type GetCampaignReportingQueryResult = ApolloReactCommon.QueryResult<GetCampaignReportingQuery, GetCampaignReportingQueryVariables>;
export const UpdateCampaignReportingDocument = gql`
    mutation updateCampaignReporting($reportObjective: String, $reportLink: String, $campaignId: Float!) {
  updateCampaignReporting(reportObjective: $reportObjective, reportLink: $reportLink, campaignId: $campaignId) {
    ...CampaignFragment
  }
}
    ${CampaignFragmentFragmentDoc}`;
export type UpdateCampaignReportingMutationFn = ApolloReactCommon.MutationFunction<UpdateCampaignReportingMutation, UpdateCampaignReportingMutationVariables>;

/**
 * __useUpdateCampaignReportingMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignReportingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignReportingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignReportingMutation, { data, loading, error }] = useUpdateCampaignReportingMutation({
 *   variables: {
 *      reportObjective: // value for 'reportObjective'
 *      reportLink: // value for 'reportLink'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUpdateCampaignReportingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCampaignReportingMutation, UpdateCampaignReportingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCampaignReportingMutation, UpdateCampaignReportingMutationVariables>(UpdateCampaignReportingDocument, baseOptions);
      }
export type UpdateCampaignReportingMutationHookResult = ReturnType<typeof useUpdateCampaignReportingMutation>;
export type UpdateCampaignReportingMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignReportingMutation>;
export type UpdateCampaignReportingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCampaignReportingMutation, UpdateCampaignReportingMutationVariables>;
export const GetHubSpotDealValidationByIdDocument = gql`
    query getHubSpotDealValidationById($dealId: String!) {
  getHubSpotDealValidationById(dealId: $dealId) {
    isDealValid
  }
}
    `;

/**
 * __useGetHubSpotDealValidationByIdQuery__
 *
 * To run a query within a React component, call `useGetHubSpotDealValidationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubSpotDealValidationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubSpotDealValidationByIdQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useGetHubSpotDealValidationByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHubSpotDealValidationByIdQuery, GetHubSpotDealValidationByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHubSpotDealValidationByIdQuery, GetHubSpotDealValidationByIdQueryVariables>(GetHubSpotDealValidationByIdDocument, baseOptions);
      }
export function useGetHubSpotDealValidationByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHubSpotDealValidationByIdQuery, GetHubSpotDealValidationByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHubSpotDealValidationByIdQuery, GetHubSpotDealValidationByIdQueryVariables>(GetHubSpotDealValidationByIdDocument, baseOptions);
        }
export type GetHubSpotDealValidationByIdQueryHookResult = ReturnType<typeof useGetHubSpotDealValidationByIdQuery>;
export type GetHubSpotDealValidationByIdLazyQueryHookResult = ReturnType<typeof useGetHubSpotDealValidationByIdLazyQuery>;
export type GetHubSpotDealValidationByIdQueryResult = ApolloReactCommon.QueryResult<GetHubSpotDealValidationByIdQuery, GetHubSpotDealValidationByIdQueryVariables>;
export const CreateCampaignGroupDocument = gql`
    mutation createCampaignGroup($groupName: String!, $companyId: Int!, $extraBrandIds: [Int]!) {
  createCampaignGroup(groupName: $groupName, companyId: $companyId, extraBrandIds: $extraBrandIds) {
    ...CampaignGroupFragment
  }
}
    ${CampaignGroupFragmentFragmentDoc}`;
export type CreateCampaignGroupMutationFn = ApolloReactCommon.MutationFunction<CreateCampaignGroupMutation, CreateCampaignGroupMutationVariables>;

/**
 * __useCreateCampaignGroupMutation__
 *
 * To run a mutation, you first call `useCreateCampaignGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignGroupMutation, { data, loading, error }] = useCreateCampaignGroupMutation({
 *   variables: {
 *      groupName: // value for 'groupName'
 *      companyId: // value for 'companyId'
 *      extraBrandIds: // value for 'extraBrandIds'
 *   },
 * });
 */
export function useCreateCampaignGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCampaignGroupMutation, CreateCampaignGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCampaignGroupMutation, CreateCampaignGroupMutationVariables>(CreateCampaignGroupDocument, baseOptions);
      }
export type CreateCampaignGroupMutationHookResult = ReturnType<typeof useCreateCampaignGroupMutation>;
export type CreateCampaignGroupMutationResult = ApolloReactCommon.MutationResult<CreateCampaignGroupMutation>;
export type CreateCampaignGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCampaignGroupMutation, CreateCampaignGroupMutationVariables>;
export const GetAllCampaignGroupsDocument = gql`
    query getAllCampaignGroups($page: Float, $groupName: String) {
  getAllCampaignGroups(page: $page, groupName: $groupName) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    campaignGroups {
      ...CampaignGroupFragment
    }
  }
}
    ${CampaignGroupFragmentFragmentDoc}`;

/**
 * __useGetAllCampaignGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllCampaignGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCampaignGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCampaignGroupsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      groupName: // value for 'groupName'
 *   },
 * });
 */
export function useGetAllCampaignGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCampaignGroupsQuery, GetAllCampaignGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCampaignGroupsQuery, GetAllCampaignGroupsQueryVariables>(GetAllCampaignGroupsDocument, baseOptions);
      }
export function useGetAllCampaignGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCampaignGroupsQuery, GetAllCampaignGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCampaignGroupsQuery, GetAllCampaignGroupsQueryVariables>(GetAllCampaignGroupsDocument, baseOptions);
        }
export type GetAllCampaignGroupsQueryHookResult = ReturnType<typeof useGetAllCampaignGroupsQuery>;
export type GetAllCampaignGroupsLazyQueryHookResult = ReturnType<typeof useGetAllCampaignGroupsLazyQuery>;
export type GetAllCampaignGroupsQueryResult = ApolloReactCommon.QueryResult<GetAllCampaignGroupsQuery, GetAllCampaignGroupsQueryVariables>;
export const GetAllCompaniesDocument = gql`
    query getAllCompanies($page: Float, $companyName: String) {
  getAllCompanies(page: $page, companyName: $companyName) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    companies {
      ...CompanyFragment
    }
  }
}
    ${CompanyFragmentFragmentDoc}`;

/**
 * __useGetAllCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompaniesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useGetAllCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>(GetAllCompaniesDocument, baseOptions);
      }
export function useGetAllCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>(GetAllCompaniesDocument, baseOptions);
        }
export type GetAllCompaniesQueryHookResult = ReturnType<typeof useGetAllCompaniesQuery>;
export type GetAllCompaniesLazyQueryHookResult = ReturnType<typeof useGetAllCompaniesLazyQuery>;
export type GetAllCompaniesQueryResult = ApolloReactCommon.QueryResult<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>;
export const GetAllGroupedCampaignsDocument = gql`
    query getAllGroupedCampaigns($title: String, $status: [GroupedCampaignStatusType!], $startedAfter: DateTime, $platforms: [GroupedCampaignPlatformType], $companyId: Int, $tags: [Int!], $customTags: [Int!], $costOfSales: GroupedCampaignMoneyRangeInput, $budget: GroupedCampaignMoneyRangeInput, $locations: [String!], $page: Int = 1, $perPage: Int = 30, $order: GroupedCampaignSortInput, $adminExecId: Int, $isPublic: Boolean, $paymentCurrency: String, $hubspotDealId: String) {
  getAllGroupedCampaigns(title: $title, status: $status, platforms: $platforms, startedAfter: $startedAfter, companyId: $companyId, tags: $tags, customTags: $customTags, costOfSales: $costOfSales, budget: $budget, locations: $locations, page: $page, perPage: $perPage, order: $order, adminExecId: $adminExecId, isPublic: $isPublic, paymentCurrency: $paymentCurrency, hubspotDealId: $hubspotDealId) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    groupedCampaigns {
      ...GroupedCampaignTypeFragment
    }
    campaignNumbers
    totalViews
    totalSpent
  }
}
    ${GroupedCampaignTypeFragmentFragmentDoc}`;

/**
 * __useGetAllGroupedCampaignsQuery__
 *
 * To run a query within a React component, call `useGetAllGroupedCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroupedCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGroupedCampaignsQuery({
 *   variables: {
 *      title: // value for 'title'
 *      status: // value for 'status'
 *      startedAfter: // value for 'startedAfter'
 *      platforms: // value for 'platforms'
 *      companyId: // value for 'companyId'
 *      tags: // value for 'tags'
 *      customTags: // value for 'customTags'
 *      costOfSales: // value for 'costOfSales'
 *      budget: // value for 'budget'
 *      locations: // value for 'locations'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      order: // value for 'order'
 *      adminExecId: // value for 'adminExecId'
 *      isPublic: // value for 'isPublic'
 *      paymentCurrency: // value for 'paymentCurrency'
 *      hubspotDealId: // value for 'hubspotDealId'
 *   },
 * });
 */
export function useGetAllGroupedCampaignsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllGroupedCampaignsQuery, GetAllGroupedCampaignsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllGroupedCampaignsQuery, GetAllGroupedCampaignsQueryVariables>(GetAllGroupedCampaignsDocument, baseOptions);
      }
export function useGetAllGroupedCampaignsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllGroupedCampaignsQuery, GetAllGroupedCampaignsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllGroupedCampaignsQuery, GetAllGroupedCampaignsQueryVariables>(GetAllGroupedCampaignsDocument, baseOptions);
        }
export type GetAllGroupedCampaignsQueryHookResult = ReturnType<typeof useGetAllGroupedCampaignsQuery>;
export type GetAllGroupedCampaignsLazyQueryHookResult = ReturnType<typeof useGetAllGroupedCampaignsLazyQuery>;
export type GetAllGroupedCampaignsQueryResult = ApolloReactCommon.QueryResult<GetAllGroupedCampaignsQuery, GetAllGroupedCampaignsQueryVariables>;
export const DeleteDraftCampaignDocument = gql`
    mutation deleteDraftCampaign($campaignId: Int!) {
  deleteDraftCampaign(campaignId: $campaignId)
}
    `;
export type DeleteDraftCampaignMutationFn = ApolloReactCommon.MutationFunction<DeleteDraftCampaignMutation, DeleteDraftCampaignMutationVariables>;

/**
 * __useDeleteDraftCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteDraftCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftCampaignMutation, { data, loading, error }] = useDeleteDraftCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteDraftCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDraftCampaignMutation, DeleteDraftCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDraftCampaignMutation, DeleteDraftCampaignMutationVariables>(DeleteDraftCampaignDocument, baseOptions);
      }
export type DeleteDraftCampaignMutationHookResult = ReturnType<typeof useDeleteDraftCampaignMutation>;
export type DeleteDraftCampaignMutationResult = ApolloReactCommon.MutationResult<DeleteDraftCampaignMutation>;
export type DeleteDraftCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDraftCampaignMutation, DeleteDraftCampaignMutationVariables>;
export const GetAllBrandsDocument = gql`
    query getAllBrands($page: Int, $brandName: String, $companyId: Int) {
  getAllBrands(page: $page, brandName: $brandName, companyId: $companyId) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    brands {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetAllBrandsQuery__
 *
 * To run a query within a React component, call `useGetAllBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBrandsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      brandName: // value for 'brandName'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAllBrandsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllBrandsQuery, GetAllBrandsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllBrandsQuery, GetAllBrandsQueryVariables>(GetAllBrandsDocument, baseOptions);
      }
export function useGetAllBrandsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllBrandsQuery, GetAllBrandsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllBrandsQuery, GetAllBrandsQueryVariables>(GetAllBrandsDocument, baseOptions);
        }
export type GetAllBrandsQueryHookResult = ReturnType<typeof useGetAllBrandsQuery>;
export type GetAllBrandsLazyQueryHookResult = ReturnType<typeof useGetAllBrandsLazyQuery>;
export type GetAllBrandsQueryResult = ApolloReactCommon.QueryResult<GetAllBrandsQuery, GetAllBrandsQueryVariables>;
export const GetAllAdminsDocument = gql`
    query getAllAdmins($adminName: String) {
  getAllAdmins(adminName: $adminName) {
    admins {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAllAdminsQuery__
 *
 * To run a query within a React component, call `useGetAllAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAdminsQuery({
 *   variables: {
 *      adminName: // value for 'adminName'
 *   },
 * });
 */
export function useGetAllAdminsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllAdminsQuery, GetAllAdminsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllAdminsQuery, GetAllAdminsQueryVariables>(GetAllAdminsDocument, baseOptions);
      }
export function useGetAllAdminsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllAdminsQuery, GetAllAdminsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllAdminsQuery, GetAllAdminsQueryVariables>(GetAllAdminsDocument, baseOptions);
        }
export type GetAllAdminsQueryHookResult = ReturnType<typeof useGetAllAdminsQuery>;
export type GetAllAdminsLazyQueryHookResult = ReturnType<typeof useGetAllAdminsLazyQuery>;
export type GetAllAdminsQueryResult = ApolloReactCommon.QueryResult<GetAllAdminsQuery, GetAllAdminsQueryVariables>;
export const GetDiscoverCategoryTopInfluencersDocument = gql`
    query getDiscoverCategoryTopInfluencers($category: DiscoverCategory!) {
  getDiscoverCategoryTopInfluencers(category: $category) {
    views {
      ...DiscoverCategoryTypeFragment
    }
    engagement {
      ...DiscoverCategoryTypeFragment
    }
    growth {
      ...DiscoverCategoryTypeFragment
    }
  }
}
    ${DiscoverCategoryTypeFragmentFragmentDoc}`;

/**
 * __useGetDiscoverCategoryTopInfluencersQuery__
 *
 * To run a query within a React component, call `useGetDiscoverCategoryTopInfluencersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoverCategoryTopInfluencersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoverCategoryTopInfluencersQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetDiscoverCategoryTopInfluencersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiscoverCategoryTopInfluencersQuery, GetDiscoverCategoryTopInfluencersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiscoverCategoryTopInfluencersQuery, GetDiscoverCategoryTopInfluencersQueryVariables>(GetDiscoverCategoryTopInfluencersDocument, baseOptions);
      }
export function useGetDiscoverCategoryTopInfluencersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiscoverCategoryTopInfluencersQuery, GetDiscoverCategoryTopInfluencersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiscoverCategoryTopInfluencersQuery, GetDiscoverCategoryTopInfluencersQueryVariables>(GetDiscoverCategoryTopInfluencersDocument, baseOptions);
        }
export type GetDiscoverCategoryTopInfluencersQueryHookResult = ReturnType<typeof useGetDiscoverCategoryTopInfluencersQuery>;
export type GetDiscoverCategoryTopInfluencersLazyQueryHookResult = ReturnType<typeof useGetDiscoverCategoryTopInfluencersLazyQuery>;
export type GetDiscoverCategoryTopInfluencersQueryResult = ApolloReactCommon.QueryResult<GetDiscoverCategoryTopInfluencersQuery, GetDiscoverCategoryTopInfluencersQueryVariables>;
export const GetDiscoverSearchingTopInfluencersDocument = gql`
    query getDiscoverSearchingTopInfluencers($searchInput: String) {
  getDiscoverSearchingTopInfluencers(searchInput: $searchInput) {
    ...DiscoverSearchingTypeFragment
  }
}
    ${DiscoverSearchingTypeFragmentFragmentDoc}`;

/**
 * __useGetDiscoverSearchingTopInfluencersQuery__
 *
 * To run a query within a React component, call `useGetDiscoverSearchingTopInfluencersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoverSearchingTopInfluencersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoverSearchingTopInfluencersQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetDiscoverSearchingTopInfluencersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiscoverSearchingTopInfluencersQuery, GetDiscoverSearchingTopInfluencersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiscoverSearchingTopInfluencersQuery, GetDiscoverSearchingTopInfluencersQueryVariables>(GetDiscoverSearchingTopInfluencersDocument, baseOptions);
      }
export function useGetDiscoverSearchingTopInfluencersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiscoverSearchingTopInfluencersQuery, GetDiscoverSearchingTopInfluencersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiscoverSearchingTopInfluencersQuery, GetDiscoverSearchingTopInfluencersQueryVariables>(GetDiscoverSearchingTopInfluencersDocument, baseOptions);
        }
export type GetDiscoverSearchingTopInfluencersQueryHookResult = ReturnType<typeof useGetDiscoverSearchingTopInfluencersQuery>;
export type GetDiscoverSearchingTopInfluencersLazyQueryHookResult = ReturnType<typeof useGetDiscoverSearchingTopInfluencersLazyQuery>;
export type GetDiscoverSearchingTopInfluencersQueryResult = ApolloReactCommon.QueryResult<GetDiscoverSearchingTopInfluencersQuery, GetDiscoverSearchingTopInfluencersQueryVariables>;
export const GetDiscoverTrackingInfluencersFromAdminDocument = gql`
    query getDiscoverTrackingInfluencersFromAdmin {
  getDiscoverTrackingInfluencersFromAdmin {
    accountName
    followers
    averageHearts
    averageComments
    averageShares
    averageViews
    engagementRate
    graphData {
      time
      averageViews
      followers
      engagementRate
    }
  }
}
    `;

/**
 * __useGetDiscoverTrackingInfluencersFromAdminQuery__
 *
 * To run a query within a React component, call `useGetDiscoverTrackingInfluencersFromAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoverTrackingInfluencersFromAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoverTrackingInfluencersFromAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiscoverTrackingInfluencersFromAdminQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiscoverTrackingInfluencersFromAdminQuery, GetDiscoverTrackingInfluencersFromAdminQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiscoverTrackingInfluencersFromAdminQuery, GetDiscoverTrackingInfluencersFromAdminQueryVariables>(GetDiscoverTrackingInfluencersFromAdminDocument, baseOptions);
      }
export function useGetDiscoverTrackingInfluencersFromAdminLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiscoverTrackingInfluencersFromAdminQuery, GetDiscoverTrackingInfluencersFromAdminQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiscoverTrackingInfluencersFromAdminQuery, GetDiscoverTrackingInfluencersFromAdminQueryVariables>(GetDiscoverTrackingInfluencersFromAdminDocument, baseOptions);
        }
export type GetDiscoverTrackingInfluencersFromAdminQueryHookResult = ReturnType<typeof useGetDiscoverTrackingInfluencersFromAdminQuery>;
export type GetDiscoverTrackingInfluencersFromAdminLazyQueryHookResult = ReturnType<typeof useGetDiscoverTrackingInfluencersFromAdminLazyQuery>;
export type GetDiscoverTrackingInfluencersFromAdminQueryResult = ApolloReactCommon.QueryResult<GetDiscoverTrackingInfluencersFromAdminQuery, GetDiscoverTrackingInfluencersFromAdminQueryVariables>;
export const RemoveDiscoverTrackingInfluencerFromAdminDocument = gql`
    mutation removeDiscoverTrackingInfluencerFromAdmin($accountName: String!) {
  removeDiscoverTrackingInfluencerFromAdmin(accountName: $accountName) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type RemoveDiscoverTrackingInfluencerFromAdminMutationFn = ApolloReactCommon.MutationFunction<RemoveDiscoverTrackingInfluencerFromAdminMutation, RemoveDiscoverTrackingInfluencerFromAdminMutationVariables>;

/**
 * __useRemoveDiscoverTrackingInfluencerFromAdminMutation__
 *
 * To run a mutation, you first call `useRemoveDiscoverTrackingInfluencerFromAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDiscoverTrackingInfluencerFromAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDiscoverTrackingInfluencerFromAdminMutation, { data, loading, error }] = useRemoveDiscoverTrackingInfluencerFromAdminMutation({
 *   variables: {
 *      accountName: // value for 'accountName'
 *   },
 * });
 */
export function useRemoveDiscoverTrackingInfluencerFromAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDiscoverTrackingInfluencerFromAdminMutation, RemoveDiscoverTrackingInfluencerFromAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveDiscoverTrackingInfluencerFromAdminMutation, RemoveDiscoverTrackingInfluencerFromAdminMutationVariables>(RemoveDiscoverTrackingInfluencerFromAdminDocument, baseOptions);
      }
export type RemoveDiscoverTrackingInfluencerFromAdminMutationHookResult = ReturnType<typeof useRemoveDiscoverTrackingInfluencerFromAdminMutation>;
export type RemoveDiscoverTrackingInfluencerFromAdminMutationResult = ApolloReactCommon.MutationResult<RemoveDiscoverTrackingInfluencerFromAdminMutation>;
export type RemoveDiscoverTrackingInfluencerFromAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDiscoverTrackingInfluencerFromAdminMutation, RemoveDiscoverTrackingInfluencerFromAdminMutationVariables>;
export const AddDiscoverTrackingInfluencerToAdminDocument = gql`
    mutation addDiscoverTrackingInfluencerToAdmin($accountName: String!) {
  addDiscoverTrackingInfluencerToAdmin(accountName: $accountName) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type AddDiscoverTrackingInfluencerToAdminMutationFn = ApolloReactCommon.MutationFunction<AddDiscoverTrackingInfluencerToAdminMutation, AddDiscoverTrackingInfluencerToAdminMutationVariables>;

/**
 * __useAddDiscoverTrackingInfluencerToAdminMutation__
 *
 * To run a mutation, you first call `useAddDiscoverTrackingInfluencerToAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiscoverTrackingInfluencerToAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiscoverTrackingInfluencerToAdminMutation, { data, loading, error }] = useAddDiscoverTrackingInfluencerToAdminMutation({
 *   variables: {
 *      accountName: // value for 'accountName'
 *   },
 * });
 */
export function useAddDiscoverTrackingInfluencerToAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDiscoverTrackingInfluencerToAdminMutation, AddDiscoverTrackingInfluencerToAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDiscoverTrackingInfluencerToAdminMutation, AddDiscoverTrackingInfluencerToAdminMutationVariables>(AddDiscoverTrackingInfluencerToAdminDocument, baseOptions);
      }
export type AddDiscoverTrackingInfluencerToAdminMutationHookResult = ReturnType<typeof useAddDiscoverTrackingInfluencerToAdminMutation>;
export type AddDiscoverTrackingInfluencerToAdminMutationResult = ApolloReactCommon.MutationResult<AddDiscoverTrackingInfluencerToAdminMutation>;
export type AddDiscoverTrackingInfluencerToAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDiscoverTrackingInfluencerToAdminMutation, AddDiscoverTrackingInfluencerToAdminMutationVariables>;
export const GetNotesByInfluencerIdDocument = gql`
    query getNotesByInfluencerId($influencerId: Float!) {
  getNotesByInfluencerId(influencerId: $influencerId) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;

/**
 * __useGetNotesByInfluencerIdQuery__
 *
 * To run a query within a React component, call `useGetNotesByInfluencerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesByInfluencerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesByInfluencerIdQuery({
 *   variables: {
 *      influencerId: // value for 'influencerId'
 *   },
 * });
 */
export function useGetNotesByInfluencerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotesByInfluencerIdQuery, GetNotesByInfluencerIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotesByInfluencerIdQuery, GetNotesByInfluencerIdQueryVariables>(GetNotesByInfluencerIdDocument, baseOptions);
      }
export function useGetNotesByInfluencerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotesByInfluencerIdQuery, GetNotesByInfluencerIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotesByInfluencerIdQuery, GetNotesByInfluencerIdQueryVariables>(GetNotesByInfluencerIdDocument, baseOptions);
        }
export type GetNotesByInfluencerIdQueryHookResult = ReturnType<typeof useGetNotesByInfluencerIdQuery>;
export type GetNotesByInfluencerIdLazyQueryHookResult = ReturnType<typeof useGetNotesByInfluencerIdLazyQuery>;
export type GetNotesByInfluencerIdQueryResult = ApolloReactCommon.QueryResult<GetNotesByInfluencerIdQuery, GetNotesByInfluencerIdQueryVariables>;
export const CreateNewNoteByInfluencerIdDocument = gql`
    mutation createNewNoteByInfluencerId($influencerId: Int!, $title: String!, $body: String!) {
  createNewNoteByInfluencerId(influencerId: $influencerId, title: $title, body: $body) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;
export type CreateNewNoteByInfluencerIdMutationFn = ApolloReactCommon.MutationFunction<CreateNewNoteByInfluencerIdMutation, CreateNewNoteByInfluencerIdMutationVariables>;

/**
 * __useCreateNewNoteByInfluencerIdMutation__
 *
 * To run a mutation, you first call `useCreateNewNoteByInfluencerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewNoteByInfluencerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewNoteByInfluencerIdMutation, { data, loading, error }] = useCreateNewNoteByInfluencerIdMutation({
 *   variables: {
 *      influencerId: // value for 'influencerId'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateNewNoteByInfluencerIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewNoteByInfluencerIdMutation, CreateNewNoteByInfluencerIdMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewNoteByInfluencerIdMutation, CreateNewNoteByInfluencerIdMutationVariables>(CreateNewNoteByInfluencerIdDocument, baseOptions);
      }
export type CreateNewNoteByInfluencerIdMutationHookResult = ReturnType<typeof useCreateNewNoteByInfluencerIdMutation>;
export type CreateNewNoteByInfluencerIdMutationResult = ApolloReactCommon.MutationResult<CreateNewNoteByInfluencerIdMutation>;
export type CreateNewNoteByInfluencerIdMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewNoteByInfluencerIdMutation, CreateNewNoteByInfluencerIdMutationVariables>;
export const UpdateNotesByInfluencerIdDocument = gql`
    mutation updateNotesByInfluencerId($notes: [NoteInput!], $influencerId: Int!) {
  updateNotesByInfluencerId(notes: $notes, influencerId: $influencerId) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;
export type UpdateNotesByInfluencerIdMutationFn = ApolloReactCommon.MutationFunction<UpdateNotesByInfluencerIdMutation, UpdateNotesByInfluencerIdMutationVariables>;

/**
 * __useUpdateNotesByInfluencerIdMutation__
 *
 * To run a mutation, you first call `useUpdateNotesByInfluencerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotesByInfluencerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotesByInfluencerIdMutation, { data, loading, error }] = useUpdateNotesByInfluencerIdMutation({
 *   variables: {
 *      notes: // value for 'notes'
 *      influencerId: // value for 'influencerId'
 *   },
 * });
 */
export function useUpdateNotesByInfluencerIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotesByInfluencerIdMutation, UpdateNotesByInfluencerIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotesByInfluencerIdMutation, UpdateNotesByInfluencerIdMutationVariables>(UpdateNotesByInfluencerIdDocument, baseOptions);
      }
export type UpdateNotesByInfluencerIdMutationHookResult = ReturnType<typeof useUpdateNotesByInfluencerIdMutation>;
export type UpdateNotesByInfluencerIdMutationResult = ApolloReactCommon.MutationResult<UpdateNotesByInfluencerIdMutation>;
export type UpdateNotesByInfluencerIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotesByInfluencerIdMutation, UpdateNotesByInfluencerIdMutationVariables>;
export const GetCampaignsByInfluencerIdDocument = gql`
    query getCampaignsByInfluencerId($searchInput: String, $influencerId: Float!) {
  getCampaignsByInfluencerId(searchInput: $searchInput, influencerId: $influencerId) {
    id
    title
    amount
    account
    requestDate
    currency
    amount
    campaignStatus
    paymentRequestStatus
  }
}
    `;

/**
 * __useGetCampaignsByInfluencerIdQuery__
 *
 * To run a query within a React component, call `useGetCampaignsByInfluencerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsByInfluencerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsByInfluencerIdQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      influencerId: // value for 'influencerId'
 *   },
 * });
 */
export function useGetCampaignsByInfluencerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignsByInfluencerIdQuery, GetCampaignsByInfluencerIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignsByInfluencerIdQuery, GetCampaignsByInfluencerIdQueryVariables>(GetCampaignsByInfluencerIdDocument, baseOptions);
      }
export function useGetCampaignsByInfluencerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignsByInfluencerIdQuery, GetCampaignsByInfluencerIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignsByInfluencerIdQuery, GetCampaignsByInfluencerIdQueryVariables>(GetCampaignsByInfluencerIdDocument, baseOptions);
        }
export type GetCampaignsByInfluencerIdQueryHookResult = ReturnType<typeof useGetCampaignsByInfluencerIdQuery>;
export type GetCampaignsByInfluencerIdLazyQueryHookResult = ReturnType<typeof useGetCampaignsByInfluencerIdLazyQuery>;
export type GetCampaignsByInfluencerIdQueryResult = ApolloReactCommon.QueryResult<GetCampaignsByInfluencerIdQuery, GetCampaignsByInfluencerIdQueryVariables>;
export const GetProfileByInfluencerIdDocument = gql`
    query getProfileByInfluencerId($influencerId: Float!) {
  getProfileByInfluencerId(influencerId: $influencerId) {
    pictureUrl
    name
    email
    punctuality
    professionalism
    quality
    flags
    accounts {
      ...AccountFragement
    }
  }
}
    ${AccountFragementFragmentDoc}`;

/**
 * __useGetProfileByInfluencerIdQuery__
 *
 * To run a query within a React component, call `useGetProfileByInfluencerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileByInfluencerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileByInfluencerIdQuery({
 *   variables: {
 *      influencerId: // value for 'influencerId'
 *   },
 * });
 */
export function useGetProfileByInfluencerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfileByInfluencerIdQuery, GetProfileByInfluencerIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProfileByInfluencerIdQuery, GetProfileByInfluencerIdQueryVariables>(GetProfileByInfluencerIdDocument, baseOptions);
      }
export function useGetProfileByInfluencerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfileByInfluencerIdQuery, GetProfileByInfluencerIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProfileByInfluencerIdQuery, GetProfileByInfluencerIdQueryVariables>(GetProfileByInfluencerIdDocument, baseOptions);
        }
export type GetProfileByInfluencerIdQueryHookResult = ReturnType<typeof useGetProfileByInfluencerIdQuery>;
export type GetProfileByInfluencerIdLazyQueryHookResult = ReturnType<typeof useGetProfileByInfluencerIdLazyQuery>;
export type GetProfileByInfluencerIdQueryResult = ApolloReactCommon.QueryResult<GetProfileByInfluencerIdQuery, GetProfileByInfluencerIdQueryVariables>;
export const GetInfluencerByIdDocument = gql`
    query getInfluencerById($influencerId: Float!) {
  getInfluencerById(influencerId: $influencerId) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetInfluencerByIdQuery__
 *
 * To run a query within a React component, call `useGetInfluencerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfluencerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfluencerByIdQuery({
 *   variables: {
 *      influencerId: // value for 'influencerId'
 *   },
 * });
 */
export function useGetInfluencerByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInfluencerByIdQuery, GetInfluencerByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInfluencerByIdQuery, GetInfluencerByIdQueryVariables>(GetInfluencerByIdDocument, baseOptions);
      }
export function useGetInfluencerByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInfluencerByIdQuery, GetInfluencerByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInfluencerByIdQuery, GetInfluencerByIdQueryVariables>(GetInfluencerByIdDocument, baseOptions);
        }
export type GetInfluencerByIdQueryHookResult = ReturnType<typeof useGetInfluencerByIdQuery>;
export type GetInfluencerByIdLazyQueryHookResult = ReturnType<typeof useGetInfluencerByIdLazyQuery>;
export type GetInfluencerByIdQueryResult = ApolloReactCommon.QueryResult<GetInfluencerByIdQuery, GetInfluencerByIdQueryVariables>;
export const GetAllAgenciesDocument = gql`
    query getAllAgencies($agencyName: String, $page: Float) {
  getAllAgencies(agencyName: $agencyName, page: $page) {
    ...AgencyFragment
  }
}
    ${AgencyFragmentFragmentDoc}`;

/**
 * __useGetAllAgenciesQuery__
 *
 * To run a query within a React component, call `useGetAllAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAgenciesQuery({
 *   variables: {
 *      agencyName: // value for 'agencyName'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAllAgenciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllAgenciesQuery, GetAllAgenciesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllAgenciesQuery, GetAllAgenciesQueryVariables>(GetAllAgenciesDocument, baseOptions);
      }
export function useGetAllAgenciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllAgenciesQuery, GetAllAgenciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllAgenciesQuery, GetAllAgenciesQueryVariables>(GetAllAgenciesDocument, baseOptions);
        }
export type GetAllAgenciesQueryHookResult = ReturnType<typeof useGetAllAgenciesQuery>;
export type GetAllAgenciesLazyQueryHookResult = ReturnType<typeof useGetAllAgenciesLazyQuery>;
export type GetAllAgenciesQueryResult = ApolloReactCommon.QueryResult<GetAllAgenciesQuery, GetAllAgenciesQueryVariables>;
export const UpdateInfluencerByIdDocument = gql`
    mutation updateInfluencerById($id: Float!, $name: String!, $email: String!, $paypalEmail: String, $phoneNumber: String, $address: String, $vatNumber: String, $agencyId: Int, $managed: Boolean, $gender: [String!], $age: [String!], $location: [String!], $punctuality: Float, $professionalism: Float, $quality: Float, $flags: [String!]) {
  updateInfluencerById(id: $id, name: $name, email: $email, paypalEmail: $paypalEmail, phoneNumber: $phoneNumber, address: $address, vatNumber: $vatNumber, agencyId: $agencyId, managed: $managed, gender: $gender, age: $age, location: $location, punctuality: $punctuality, professionalism: $professionalism, quality: $quality, flags: $flags) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateInfluencerByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateInfluencerByIdMutation, UpdateInfluencerByIdMutationVariables>;

/**
 * __useUpdateInfluencerByIdMutation__
 *
 * To run a mutation, you first call `useUpdateInfluencerByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfluencerByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfluencerByIdMutation, { data, loading, error }] = useUpdateInfluencerByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      paypalEmail: // value for 'paypalEmail'
 *      phoneNumber: // value for 'phoneNumber'
 *      address: // value for 'address'
 *      vatNumber: // value for 'vatNumber'
 *      agencyId: // value for 'agencyId'
 *      managed: // value for 'managed'
 *      gender: // value for 'gender'
 *      age: // value for 'age'
 *      location: // value for 'location'
 *      punctuality: // value for 'punctuality'
 *      professionalism: // value for 'professionalism'
 *      quality: // value for 'quality'
 *      flags: // value for 'flags'
 *   },
 * });
 */
export function useUpdateInfluencerByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInfluencerByIdMutation, UpdateInfluencerByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInfluencerByIdMutation, UpdateInfluencerByIdMutationVariables>(UpdateInfluencerByIdDocument, baseOptions);
      }
export type UpdateInfluencerByIdMutationHookResult = ReturnType<typeof useUpdateInfluencerByIdMutation>;
export type UpdateInfluencerByIdMutationResult = ApolloReactCommon.MutationResult<UpdateInfluencerByIdMutation>;
export type UpdateInfluencerByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInfluencerByIdMutation, UpdateInfluencerByIdMutationVariables>;
export const GetAccountDetailByIdDocument = gql`
    query getAccountDetailById($accountId: String!, $platformType: DataSourcePlatform!) {
  getAccountDetailById(accountId: $accountId, platformType: $platformType) {
    ...AccountDetail
  }
}
    ${AccountDetailFragmentDoc}`;

/**
 * __useGetAccountDetailByIdQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailByIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      platformType: // value for 'platformType'
 *   },
 * });
 */
export function useGetAccountDetailByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountDetailByIdQuery, GetAccountDetailByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountDetailByIdQuery, GetAccountDetailByIdQueryVariables>(GetAccountDetailByIdDocument, baseOptions);
      }
export function useGetAccountDetailByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountDetailByIdQuery, GetAccountDetailByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountDetailByIdQuery, GetAccountDetailByIdQueryVariables>(GetAccountDetailByIdDocument, baseOptions);
        }
export type GetAccountDetailByIdQueryHookResult = ReturnType<typeof useGetAccountDetailByIdQuery>;
export type GetAccountDetailByIdLazyQueryHookResult = ReturnType<typeof useGetAccountDetailByIdLazyQuery>;
export type GetAccountDetailByIdQueryResult = ApolloReactCommon.QueryResult<GetAccountDetailByIdQuery, GetAccountDetailByIdQueryVariables>;
export const GetAllTagsDocument = gql`
    query getAllTags($tagName: String) {
  getAllTags(tagName: $tagName) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;

/**
 * __useGetAllTagsQuery__
 *
 * To run a query within a React component, call `useGetAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTagsQuery({
 *   variables: {
 *      tagName: // value for 'tagName'
 *   },
 * });
 */
export function useGetAllTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, baseOptions);
      }
export function useGetAllTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, baseOptions);
        }
export type GetAllTagsQueryHookResult = ReturnType<typeof useGetAllTagsQuery>;
export type GetAllTagsLazyQueryHookResult = ReturnType<typeof useGetAllTagsLazyQuery>;
export type GetAllTagsQueryResult = ApolloReactCommon.QueryResult<GetAllTagsQuery, GetAllTagsQueryVariables>;
export const UpdateAccountDetailByIdDocument = gql`
    mutation updateAccountDetailById($id: Int!, $tags: [TagInput!]!, $actualCostPerPost: Float) {
  updateAccountDetailById(id: $id, tags: $tags, actualCostPerPost: $actualCostPerPost) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;
export type UpdateAccountDetailByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountDetailByIdMutation, UpdateAccountDetailByIdMutationVariables>;

/**
 * __useUpdateAccountDetailByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAccountDetailByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountDetailByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountDetailByIdMutation, { data, loading, error }] = useUpdateAccountDetailByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tags: // value for 'tags'
 *      actualCostPerPost: // value for 'actualCostPerPost'
 *   },
 * });
 */
export function useUpdateAccountDetailByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountDetailByIdMutation, UpdateAccountDetailByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountDetailByIdMutation, UpdateAccountDetailByIdMutationVariables>(UpdateAccountDetailByIdDocument, baseOptions);
      }
export type UpdateAccountDetailByIdMutationHookResult = ReturnType<typeof useUpdateAccountDetailByIdMutation>;
export type UpdateAccountDetailByIdMutationResult = ApolloReactCommon.MutationResult<UpdateAccountDetailByIdMutation>;
export type UpdateAccountDetailByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountDetailByIdMutation, UpdateAccountDetailByIdMutationVariables>;
export const GetCurrentFollowersByAccountIdDocument = gql`
    query getCurrentFollowersByAccountId($accountId: String, $username: String, $filter: LineChartFilter, $platformType: DataSourcePlatform!) {
  getCurrentFollowersByAccountId(accountId: $accountId, username: $username, filter: $filter, platformType: $platformType) {
    ...GraphData
  }
}
    ${GraphDataFragmentDoc}`;

/**
 * __useGetCurrentFollowersByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentFollowersByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentFollowersByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentFollowersByAccountIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      username: // value for 'username'
 *      filter: // value for 'filter'
 *      platformType: // value for 'platformType'
 *   },
 * });
 */
export function useGetCurrentFollowersByAccountIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentFollowersByAccountIdQuery, GetCurrentFollowersByAccountIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentFollowersByAccountIdQuery, GetCurrentFollowersByAccountIdQueryVariables>(GetCurrentFollowersByAccountIdDocument, baseOptions);
      }
export function useGetCurrentFollowersByAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentFollowersByAccountIdQuery, GetCurrentFollowersByAccountIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentFollowersByAccountIdQuery, GetCurrentFollowersByAccountIdQueryVariables>(GetCurrentFollowersByAccountIdDocument, baseOptions);
        }
export type GetCurrentFollowersByAccountIdQueryHookResult = ReturnType<typeof useGetCurrentFollowersByAccountIdQuery>;
export type GetCurrentFollowersByAccountIdLazyQueryHookResult = ReturnType<typeof useGetCurrentFollowersByAccountIdLazyQuery>;
export type GetCurrentFollowersByAccountIdQueryResult = ApolloReactCommon.QueryResult<GetCurrentFollowersByAccountIdQuery, GetCurrentFollowersByAccountIdQueryVariables>;
export const GetAverageCommentsByAccountIdDocument = gql`
    query getAverageCommentsByAccountId($accountId: String, $username: String, $filter: LineChartFilter, $platformType: DataSourcePlatform!) {
  getAverageCommentsByAccountId(accountId: $accountId, username: $username, filter: $filter, platformType: $platformType) {
    ...GraphData
  }
}
    ${GraphDataFragmentDoc}`;

/**
 * __useGetAverageCommentsByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetAverageCommentsByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAverageCommentsByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAverageCommentsByAccountIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      username: // value for 'username'
 *      filter: // value for 'filter'
 *      platformType: // value for 'platformType'
 *   },
 * });
 */
export function useGetAverageCommentsByAccountIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAverageCommentsByAccountIdQuery, GetAverageCommentsByAccountIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAverageCommentsByAccountIdQuery, GetAverageCommentsByAccountIdQueryVariables>(GetAverageCommentsByAccountIdDocument, baseOptions);
      }
export function useGetAverageCommentsByAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAverageCommentsByAccountIdQuery, GetAverageCommentsByAccountIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAverageCommentsByAccountIdQuery, GetAverageCommentsByAccountIdQueryVariables>(GetAverageCommentsByAccountIdDocument, baseOptions);
        }
export type GetAverageCommentsByAccountIdQueryHookResult = ReturnType<typeof useGetAverageCommentsByAccountIdQuery>;
export type GetAverageCommentsByAccountIdLazyQueryHookResult = ReturnType<typeof useGetAverageCommentsByAccountIdLazyQuery>;
export type GetAverageCommentsByAccountIdQueryResult = ApolloReactCommon.QueryResult<GetAverageCommentsByAccountIdQuery, GetAverageCommentsByAccountIdQueryVariables>;
export const GetAverageHeartsByAccountIdDocument = gql`
    query getAverageHeartsByAccountId($accountId: String, $username: String, $filter: LineChartFilter, $platformType: DataSourcePlatform!) {
  getAverageHeartsByAccountId(accountId: $accountId, username: $username, filter: $filter, platformType: $platformType) {
    ...GraphData
  }
}
    ${GraphDataFragmentDoc}`;

/**
 * __useGetAverageHeartsByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetAverageHeartsByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAverageHeartsByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAverageHeartsByAccountIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      username: // value for 'username'
 *      filter: // value for 'filter'
 *      platformType: // value for 'platformType'
 *   },
 * });
 */
export function useGetAverageHeartsByAccountIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAverageHeartsByAccountIdQuery, GetAverageHeartsByAccountIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAverageHeartsByAccountIdQuery, GetAverageHeartsByAccountIdQueryVariables>(GetAverageHeartsByAccountIdDocument, baseOptions);
      }
export function useGetAverageHeartsByAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAverageHeartsByAccountIdQuery, GetAverageHeartsByAccountIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAverageHeartsByAccountIdQuery, GetAverageHeartsByAccountIdQueryVariables>(GetAverageHeartsByAccountIdDocument, baseOptions);
        }
export type GetAverageHeartsByAccountIdQueryHookResult = ReturnType<typeof useGetAverageHeartsByAccountIdQuery>;
export type GetAverageHeartsByAccountIdLazyQueryHookResult = ReturnType<typeof useGetAverageHeartsByAccountIdLazyQuery>;
export type GetAverageHeartsByAccountIdQueryResult = ApolloReactCommon.QueryResult<GetAverageHeartsByAccountIdQuery, GetAverageHeartsByAccountIdQueryVariables>;
export const GetAverageViewsByAccountIdDocument = gql`
    query getAverageViewsByAccountId($accountId: String, $username: String, $filter: LineChartFilter, $platformType: DataSourcePlatform!) {
  getAverageViewsByAccountId(accountId: $accountId, username: $username, filter: $filter, platformType: $platformType) {
    ...GraphData
  }
}
    ${GraphDataFragmentDoc}`;

/**
 * __useGetAverageViewsByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetAverageViewsByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAverageViewsByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAverageViewsByAccountIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      username: // value for 'username'
 *      filter: // value for 'filter'
 *      platformType: // value for 'platformType'
 *   },
 * });
 */
export function useGetAverageViewsByAccountIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAverageViewsByAccountIdQuery, GetAverageViewsByAccountIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAverageViewsByAccountIdQuery, GetAverageViewsByAccountIdQueryVariables>(GetAverageViewsByAccountIdDocument, baseOptions);
      }
export function useGetAverageViewsByAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAverageViewsByAccountIdQuery, GetAverageViewsByAccountIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAverageViewsByAccountIdQuery, GetAverageViewsByAccountIdQueryVariables>(GetAverageViewsByAccountIdDocument, baseOptions);
        }
export type GetAverageViewsByAccountIdQueryHookResult = ReturnType<typeof useGetAverageViewsByAccountIdQuery>;
export type GetAverageViewsByAccountIdLazyQueryHookResult = ReturnType<typeof useGetAverageViewsByAccountIdLazyQuery>;
export type GetAverageViewsByAccountIdQueryResult = ApolloReactCommon.QueryResult<GetAverageViewsByAccountIdQuery, GetAverageViewsByAccountIdQueryVariables>;
export const SetBreakdownByAccountIdDocument = gql`
    mutation setBreakdownByAccountId($accountId: String!, $type: BreakdownType!, $data: JSON!) {
  setBreakdownByAccountId(accountId: $accountId, type: $type, data: $data) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;
export type SetBreakdownByAccountIdMutationFn = ApolloReactCommon.MutationFunction<SetBreakdownByAccountIdMutation, SetBreakdownByAccountIdMutationVariables>;

/**
 * __useSetBreakdownByAccountIdMutation__
 *
 * To run a mutation, you first call `useSetBreakdownByAccountIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBreakdownByAccountIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBreakdownByAccountIdMutation, { data, loading, error }] = useSetBreakdownByAccountIdMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      type: // value for 'type'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetBreakdownByAccountIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetBreakdownByAccountIdMutation, SetBreakdownByAccountIdMutationVariables>) {
        return ApolloReactHooks.useMutation<SetBreakdownByAccountIdMutation, SetBreakdownByAccountIdMutationVariables>(SetBreakdownByAccountIdDocument, baseOptions);
      }
export type SetBreakdownByAccountIdMutationHookResult = ReturnType<typeof useSetBreakdownByAccountIdMutation>;
export type SetBreakdownByAccountIdMutationResult = ApolloReactCommon.MutationResult<SetBreakdownByAccountIdMutation>;
export type SetBreakdownByAccountIdMutationOptions = ApolloReactCommon.BaseMutationOptions<SetBreakdownByAccountIdMutation, SetBreakdownByAccountIdMutationVariables>;
export const GetAgeBreakdownByAccountIdDocument = gql`
    query getAgeBreakdownByAccountId($accountId: String!) {
  getAgeBreakdownByAccountId(accountId: $accountId) {
    ...BreakdownGraphData
  }
}
    ${BreakdownGraphDataFragmentDoc}`;

/**
 * __useGetAgeBreakdownByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetAgeBreakdownByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgeBreakdownByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgeBreakdownByAccountIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAgeBreakdownByAccountIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAgeBreakdownByAccountIdQuery, GetAgeBreakdownByAccountIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAgeBreakdownByAccountIdQuery, GetAgeBreakdownByAccountIdQueryVariables>(GetAgeBreakdownByAccountIdDocument, baseOptions);
      }
export function useGetAgeBreakdownByAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAgeBreakdownByAccountIdQuery, GetAgeBreakdownByAccountIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAgeBreakdownByAccountIdQuery, GetAgeBreakdownByAccountIdQueryVariables>(GetAgeBreakdownByAccountIdDocument, baseOptions);
        }
export type GetAgeBreakdownByAccountIdQueryHookResult = ReturnType<typeof useGetAgeBreakdownByAccountIdQuery>;
export type GetAgeBreakdownByAccountIdLazyQueryHookResult = ReturnType<typeof useGetAgeBreakdownByAccountIdLazyQuery>;
export type GetAgeBreakdownByAccountIdQueryResult = ApolloReactCommon.QueryResult<GetAgeBreakdownByAccountIdQuery, GetAgeBreakdownByAccountIdQueryVariables>;
export const GetGenderBreakdownByAccountIdDocument = gql`
    query getGenderBreakdownByAccountId($accountId: String!) {
  getGenderBreakdownByAccountId(accountId: $accountId) {
    ...BreakdownGraphData
  }
}
    ${BreakdownGraphDataFragmentDoc}`;

/**
 * __useGetGenderBreakdownByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetGenderBreakdownByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenderBreakdownByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenderBreakdownByAccountIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetGenderBreakdownByAccountIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGenderBreakdownByAccountIdQuery, GetGenderBreakdownByAccountIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGenderBreakdownByAccountIdQuery, GetGenderBreakdownByAccountIdQueryVariables>(GetGenderBreakdownByAccountIdDocument, baseOptions);
      }
export function useGetGenderBreakdownByAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGenderBreakdownByAccountIdQuery, GetGenderBreakdownByAccountIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGenderBreakdownByAccountIdQuery, GetGenderBreakdownByAccountIdQueryVariables>(GetGenderBreakdownByAccountIdDocument, baseOptions);
        }
export type GetGenderBreakdownByAccountIdQueryHookResult = ReturnType<typeof useGetGenderBreakdownByAccountIdQuery>;
export type GetGenderBreakdownByAccountIdLazyQueryHookResult = ReturnType<typeof useGetGenderBreakdownByAccountIdLazyQuery>;
export type GetGenderBreakdownByAccountIdQueryResult = ApolloReactCommon.QueryResult<GetGenderBreakdownByAccountIdQuery, GetGenderBreakdownByAccountIdQueryVariables>;
export const GetLocationBreakdownByAccountIdDocument = gql`
    query getLocationBreakdownByAccountId($accountId: String!) {
  getLocationBreakdownByAccountId(accountId: $accountId) {
    ...BreakdownGraphData
  }
}
    ${BreakdownGraphDataFragmentDoc}`;

/**
 * __useGetLocationBreakdownByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetLocationBreakdownByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationBreakdownByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationBreakdownByAccountIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetLocationBreakdownByAccountIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationBreakdownByAccountIdQuery, GetLocationBreakdownByAccountIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationBreakdownByAccountIdQuery, GetLocationBreakdownByAccountIdQueryVariables>(GetLocationBreakdownByAccountIdDocument, baseOptions);
      }
export function useGetLocationBreakdownByAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationBreakdownByAccountIdQuery, GetLocationBreakdownByAccountIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationBreakdownByAccountIdQuery, GetLocationBreakdownByAccountIdQueryVariables>(GetLocationBreakdownByAccountIdDocument, baseOptions);
        }
export type GetLocationBreakdownByAccountIdQueryHookResult = ReturnType<typeof useGetLocationBreakdownByAccountIdQuery>;
export type GetLocationBreakdownByAccountIdLazyQueryHookResult = ReturnType<typeof useGetLocationBreakdownByAccountIdLazyQuery>;
export type GetLocationBreakdownByAccountIdQueryResult = ApolloReactCommon.QueryResult<GetLocationBreakdownByAccountIdQuery, GetLocationBreakdownByAccountIdQueryVariables>;
export const SearchInfluencerAccountsDocument = gql`
    query searchInfluencerAccounts($platformType: DataSourcePlatform!, $searchInput: String, $metricSelect: MetricSelectType, $campaignIds: [Int!], $accountType: AccountType, $influencerGender: GenderType, $influencerLocation: String, $tagIds: [Int!], $followers: NumberComparison, $hearts: NumberComparison, $price: NumberComparison, $includeEstimatedCosts: Boolean, $audienceAge: NumberComparisonWithKey, $audienceGender: NumberComparisonWithKey, $audienceLocation: NumberComparisonWithKey, $page: Int, $exportCSV: Boolean) {
  searchInfluencerAccounts(platformType: $platformType, searchInput: $searchInput, metricSelect: $metricSelect, campaignIds: $campaignIds, accountType: $accountType, influencerGender: $influencerGender, influencerLocation: $influencerLocation, tagIds: $tagIds, followers: $followers, hearts: $hearts, price: $price, includeEstimatedCosts: $includeEstimatedCosts, audienceAge: $audienceAge, audienceGender: $audienceGender, audienceLocation: $audienceLocation, page: $page, exportCSV: $exportCSV) {
    totalEntries
    perPage
    previousPage
    currentPage
    nextPage
    searchAccounts {
      ...SearchAccountData
    }
  }
}
    ${SearchAccountDataFragmentDoc}`;

/**
 * __useSearchInfluencerAccountsQuery__
 *
 * To run a query within a React component, call `useSearchInfluencerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInfluencerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInfluencerAccountsQuery({
 *   variables: {
 *      platformType: // value for 'platformType'
 *      searchInput: // value for 'searchInput'
 *      metricSelect: // value for 'metricSelect'
 *      campaignIds: // value for 'campaignIds'
 *      accountType: // value for 'accountType'
 *      influencerGender: // value for 'influencerGender'
 *      influencerLocation: // value for 'influencerLocation'
 *      tagIds: // value for 'tagIds'
 *      followers: // value for 'followers'
 *      hearts: // value for 'hearts'
 *      price: // value for 'price'
 *      includeEstimatedCosts: // value for 'includeEstimatedCosts'
 *      audienceAge: // value for 'audienceAge'
 *      audienceGender: // value for 'audienceGender'
 *      audienceLocation: // value for 'audienceLocation'
 *      page: // value for 'page'
 *      exportCSV: // value for 'exportCSV'
 *   },
 * });
 */
export function useSearchInfluencerAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchInfluencerAccountsQuery, SearchInfluencerAccountsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchInfluencerAccountsQuery, SearchInfluencerAccountsQueryVariables>(SearchInfluencerAccountsDocument, baseOptions);
      }
export function useSearchInfluencerAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchInfluencerAccountsQuery, SearchInfluencerAccountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchInfluencerAccountsQuery, SearchInfluencerAccountsQueryVariables>(SearchInfluencerAccountsDocument, baseOptions);
        }
export type SearchInfluencerAccountsQueryHookResult = ReturnType<typeof useSearchInfluencerAccountsQuery>;
export type SearchInfluencerAccountsLazyQueryHookResult = ReturnType<typeof useSearchInfluencerAccountsLazyQuery>;
export type SearchInfluencerAccountsQueryResult = ApolloReactCommon.QueryResult<SearchInfluencerAccountsQuery, SearchInfluencerAccountsQueryVariables>;
export const CreateAccountDocument = gql`
    mutation createAccount($data: NewAccountInput!) {
  createAccount(data: $data) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;
export type CreateAccountMutationFn = ApolloReactCommon.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = ApolloReactCommon.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const GetAllInfluencersDocument = gql`
    query getAllInfluencers($influencerName: String, $page: Float) {
  getAllInfluencers(influencerName: $influencerName, page: $page) {
    id
    name
  }
}
    `;

/**
 * __useGetAllInfluencersQuery__
 *
 * To run a query within a React component, call `useGetAllInfluencersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInfluencersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInfluencersQuery({
 *   variables: {
 *      influencerName: // value for 'influencerName'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAllInfluencersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllInfluencersQuery, GetAllInfluencersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllInfluencersQuery, GetAllInfluencersQueryVariables>(GetAllInfluencersDocument, baseOptions);
      }
export function useGetAllInfluencersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllInfluencersQuery, GetAllInfluencersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllInfluencersQuery, GetAllInfluencersQueryVariables>(GetAllInfluencersDocument, baseOptions);
        }
export type GetAllInfluencersQueryHookResult = ReturnType<typeof useGetAllInfluencersQuery>;
export type GetAllInfluencersLazyQueryHookResult = ReturnType<typeof useGetAllInfluencersLazyQuery>;
export type GetAllInfluencersQueryResult = ApolloReactCommon.QueryResult<GetAllInfluencersQuery, GetAllInfluencersQueryVariables>;
export const CreateNewPaymentRequestDocument = gql`
    mutation createNewPaymentRequest($campaignId: Int, $accountId: Int, $description: String, $vatNumber: String, $amount: Int, $currency: String!, $paymentDetail: PaymentDetailType, $attachments: [String!], $agencyId: Int, $agencyName: String, $agencyAccountIds: [Int!], $invitationId: Int, $status: String, $tiktokUrl: String, $actualViews: Int, $screenshots: [FileInput!], $dontCreatePaymentRequest: Boolean) {
  createNewPaymentRequest(campaignId: $campaignId, accountId: $accountId, description: $description, vatNumber: $vatNumber, amount: $amount, currency: $currency, paymentDetail: $paymentDetail, attachments: $attachments, agencyId: $agencyId, agencyName: $agencyName, agencyAccountIds: $agencyAccountIds, invitationId: $invitationId, status: $status, tiktokUrl: $tiktokUrl, actualViews: $actualViews, screenshots: $screenshots, dontCreatePaymentRequest: $dontCreatePaymentRequest) {
    paymentRequest {
      ...PaymentRequestFragment
    }
    invitation {
      ...InvitationFragment
    }
  }
}
    ${PaymentRequestFragmentFragmentDoc}
${InvitationFragmentFragmentDoc}`;
export type CreateNewPaymentRequestMutationFn = ApolloReactCommon.MutationFunction<CreateNewPaymentRequestMutation, CreateNewPaymentRequestMutationVariables>;

/**
 * __useCreateNewPaymentRequestMutation__
 *
 * To run a mutation, you first call `useCreateNewPaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewPaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewPaymentRequestMutation, { data, loading, error }] = useCreateNewPaymentRequestMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *      description: // value for 'description'
 *      vatNumber: // value for 'vatNumber'
 *      amount: // value for 'amount'
 *      currency: // value for 'currency'
 *      paymentDetail: // value for 'paymentDetail'
 *      attachments: // value for 'attachments'
 *      agencyId: // value for 'agencyId'
 *      agencyName: // value for 'agencyName'
 *      agencyAccountIds: // value for 'agencyAccountIds'
 *      invitationId: // value for 'invitationId'
 *      status: // value for 'status'
 *      tiktokUrl: // value for 'tiktokUrl'
 *      actualViews: // value for 'actualViews'
 *      screenshots: // value for 'screenshots'
 *      dontCreatePaymentRequest: // value for 'dontCreatePaymentRequest'
 *   },
 * });
 */
export function useCreateNewPaymentRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewPaymentRequestMutation, CreateNewPaymentRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewPaymentRequestMutation, CreateNewPaymentRequestMutationVariables>(CreateNewPaymentRequestDocument, baseOptions);
      }
export type CreateNewPaymentRequestMutationHookResult = ReturnType<typeof useCreateNewPaymentRequestMutation>;
export type CreateNewPaymentRequestMutationResult = ApolloReactCommon.MutationResult<CreateNewPaymentRequestMutation>;
export type CreateNewPaymentRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewPaymentRequestMutation, CreateNewPaymentRequestMutationVariables>;
export const GetAllCampaignsDocument = gql`
    query getAllCampaigns($campaignTitle: String, $page: Float) {
  getAllCampaigns(campaignTitle: $campaignTitle, page: $page) {
    id
    title
    status
  }
}
    `;

/**
 * __useGetAllCampaignsQuery__
 *
 * To run a query within a React component, call `useGetAllCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCampaignsQuery({
 *   variables: {
 *      campaignTitle: // value for 'campaignTitle'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAllCampaignsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCampaignsQuery, GetAllCampaignsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCampaignsQuery, GetAllCampaignsQueryVariables>(GetAllCampaignsDocument, baseOptions);
      }
export function useGetAllCampaignsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCampaignsQuery, GetAllCampaignsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCampaignsQuery, GetAllCampaignsQueryVariables>(GetAllCampaignsDocument, baseOptions);
        }
export type GetAllCampaignsQueryHookResult = ReturnType<typeof useGetAllCampaignsQuery>;
export type GetAllCampaignsLazyQueryHookResult = ReturnType<typeof useGetAllCampaignsLazyQuery>;
export type GetAllCampaignsQueryResult = ApolloReactCommon.QueryResult<GetAllCampaignsQuery, GetAllCampaignsQueryVariables>;
export const GetAccountsByCampaignIdDocument = gql`
    query getAccountsByCampaignId($campaignId: Float, $accountName: String, $page: Float) {
  getAccountsByCampaignId(campaignId: $campaignId, accountName: $accountName, page: $page) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;

/**
 * __useGetAccountsByCampaignIdQuery__
 *
 * To run a query within a React component, call `useGetAccountsByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountName: // value for 'accountName'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAccountsByCampaignIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountsByCampaignIdQuery, GetAccountsByCampaignIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountsByCampaignIdQuery, GetAccountsByCampaignIdQueryVariables>(GetAccountsByCampaignIdDocument, baseOptions);
      }
export function useGetAccountsByCampaignIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountsByCampaignIdQuery, GetAccountsByCampaignIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountsByCampaignIdQuery, GetAccountsByCampaignIdQueryVariables>(GetAccountsByCampaignIdDocument, baseOptions);
        }
export type GetAccountsByCampaignIdQueryHookResult = ReturnType<typeof useGetAccountsByCampaignIdQuery>;
export type GetAccountsByCampaignIdLazyQueryHookResult = ReturnType<typeof useGetAccountsByCampaignIdLazyQuery>;
export type GetAccountsByCampaignIdQueryResult = ApolloReactCommon.QueryResult<GetAccountsByCampaignIdQuery, GetAccountsByCampaignIdQueryVariables>;
export const UpdateInfleucnerPayoutDetailByIdDocument = gql`
    mutation updateInfleucnerPayoutDetailById($influencerId: Int!, $paymentDetail: PaymentDetailType!) {
  updateInfleucnerPayoutDetailById(influencerId: $influencerId, paymentDetail: $paymentDetail) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateInfleucnerPayoutDetailByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateInfleucnerPayoutDetailByIdMutation, UpdateInfleucnerPayoutDetailByIdMutationVariables>;

/**
 * __useUpdateInfleucnerPayoutDetailByIdMutation__
 *
 * To run a mutation, you first call `useUpdateInfleucnerPayoutDetailByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfleucnerPayoutDetailByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfleucnerPayoutDetailByIdMutation, { data, loading, error }] = useUpdateInfleucnerPayoutDetailByIdMutation({
 *   variables: {
 *      influencerId: // value for 'influencerId'
 *      paymentDetail: // value for 'paymentDetail'
 *   },
 * });
 */
export function useUpdateInfleucnerPayoutDetailByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInfleucnerPayoutDetailByIdMutation, UpdateInfleucnerPayoutDetailByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInfleucnerPayoutDetailByIdMutation, UpdateInfleucnerPayoutDetailByIdMutationVariables>(UpdateInfleucnerPayoutDetailByIdDocument, baseOptions);
      }
export type UpdateInfleucnerPayoutDetailByIdMutationHookResult = ReturnType<typeof useUpdateInfleucnerPayoutDetailByIdMutation>;
export type UpdateInfleucnerPayoutDetailByIdMutationResult = ApolloReactCommon.MutationResult<UpdateInfleucnerPayoutDetailByIdMutation>;
export type UpdateInfleucnerPayoutDetailByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInfleucnerPayoutDetailByIdMutation, UpdateInfleucnerPayoutDetailByIdMutationVariables>;
export const GetPaymentRequestsDocument = gql`
    query getPaymentRequests($searchInput: String, $campaign: String, $influencer: String, $requestedBy: String, $paymentRequestId: Int, $campaignId: Int, $paypalTransactionId: String, $cost: Int, $isGreaterThan: Boolean, $typeOfCost: String, $dateFrom: DateTime, $dateTo: DateTime, $dueDateFrom: DateTime, $dueDateTo: DateTime, $status: [String!], $paymentType: [String!], $paymentCurrency: String, $hubspotDealId: String, $page: Int) {
  getPaymentRequests(searchInput: $searchInput, campaign: $campaign, influencer: $influencer, requestedBy: $requestedBy, paymentRequestId: $paymentRequestId, campaignId: $campaignId, paypalTransactionId: $paypalTransactionId, cost: $cost, isGreaterThan: $isGreaterThan, typeOfCost: $typeOfCost, dateFrom: $dateFrom, dateTo: $dateTo, dueDateFrom: $dueDateFrom, dueDateTo: $dueDateTo, status: $status, paymentType: $paymentType, paymentCurrency: $paymentCurrency, hubspotDealId: $hubspotDealId, page: $page) {
    paymentCount
    totalAmount
    totalNetAmount
    totalVatAmount
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    paymentRequests {
      ...PaymentRequestFragment
    }
  }
}
    ${PaymentRequestFragmentFragmentDoc}`;

/**
 * __useGetPaymentRequestsQuery__
 *
 * To run a query within a React component, call `useGetPaymentRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentRequestsQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      campaign: // value for 'campaign'
 *      influencer: // value for 'influencer'
 *      requestedBy: // value for 'requestedBy'
 *      paymentRequestId: // value for 'paymentRequestId'
 *      campaignId: // value for 'campaignId'
 *      paypalTransactionId: // value for 'paypalTransactionId'
 *      cost: // value for 'cost'
 *      isGreaterThan: // value for 'isGreaterThan'
 *      typeOfCost: // value for 'typeOfCost'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      dueDateFrom: // value for 'dueDateFrom'
 *      dueDateTo: // value for 'dueDateTo'
 *      status: // value for 'status'
 *      paymentType: // value for 'paymentType'
 *      paymentCurrency: // value for 'paymentCurrency'
 *      hubspotDealId: // value for 'hubspotDealId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetPaymentRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentRequestsQuery, GetPaymentRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentRequestsQuery, GetPaymentRequestsQueryVariables>(GetPaymentRequestsDocument, baseOptions);
      }
export function useGetPaymentRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentRequestsQuery, GetPaymentRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentRequestsQuery, GetPaymentRequestsQueryVariables>(GetPaymentRequestsDocument, baseOptions);
        }
export type GetPaymentRequestsQueryHookResult = ReturnType<typeof useGetPaymentRequestsQuery>;
export type GetPaymentRequestsLazyQueryHookResult = ReturnType<typeof useGetPaymentRequestsLazyQuery>;
export type GetPaymentRequestsQueryResult = ApolloReactCommon.QueryResult<GetPaymentRequestsQuery, GetPaymentRequestsQueryVariables>;
export const AcceptPaymentRequestDocument = gql`
    mutation acceptPaymentRequest($paymentRequestId: Float!) {
  acceptPaymentRequest(paymentRequestId: $paymentRequestId) {
    ...PaymentRequestFragment
  }
}
    ${PaymentRequestFragmentFragmentDoc}`;
export type AcceptPaymentRequestMutationFn = ApolloReactCommon.MutationFunction<AcceptPaymentRequestMutation, AcceptPaymentRequestMutationVariables>;

/**
 * __useAcceptPaymentRequestMutation__
 *
 * To run a mutation, you first call `useAcceptPaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPaymentRequestMutation, { data, loading, error }] = useAcceptPaymentRequestMutation({
 *   variables: {
 *      paymentRequestId: // value for 'paymentRequestId'
 *   },
 * });
 */
export function useAcceptPaymentRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptPaymentRequestMutation, AcceptPaymentRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptPaymentRequestMutation, AcceptPaymentRequestMutationVariables>(AcceptPaymentRequestDocument, baseOptions);
      }
export type AcceptPaymentRequestMutationHookResult = ReturnType<typeof useAcceptPaymentRequestMutation>;
export type AcceptPaymentRequestMutationResult = ApolloReactCommon.MutationResult<AcceptPaymentRequestMutation>;
export type AcceptPaymentRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptPaymentRequestMutation, AcceptPaymentRequestMutationVariables>;
export const RejectPaymentRequestDocument = gql`
    mutation rejectPaymentRequest($paymentRequestId: Float!) {
  rejectPaymentRequest(paymentRequestId: $paymentRequestId) {
    ...PaymentRequestFragment
  }
}
    ${PaymentRequestFragmentFragmentDoc}`;
export type RejectPaymentRequestMutationFn = ApolloReactCommon.MutationFunction<RejectPaymentRequestMutation, RejectPaymentRequestMutationVariables>;

/**
 * __useRejectPaymentRequestMutation__
 *
 * To run a mutation, you first call `useRejectPaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPaymentRequestMutation, { data, loading, error }] = useRejectPaymentRequestMutation({
 *   variables: {
 *      paymentRequestId: // value for 'paymentRequestId'
 *   },
 * });
 */
export function useRejectPaymentRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectPaymentRequestMutation, RejectPaymentRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectPaymentRequestMutation, RejectPaymentRequestMutationVariables>(RejectPaymentRequestDocument, baseOptions);
      }
export type RejectPaymentRequestMutationHookResult = ReturnType<typeof useRejectPaymentRequestMutation>;
export type RejectPaymentRequestMutationResult = ApolloReactCommon.MutationResult<RejectPaymentRequestMutation>;
export type RejectPaymentRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectPaymentRequestMutation, RejectPaymentRequestMutationVariables>;
export const UndoPaymentRequestDocument = gql`
    mutation undoPaymentRequest($paymentRequestId: Float!) {
  undoPaymentRequest(paymentRequestId: $paymentRequestId) {
    ...PaymentRequestFragment
  }
}
    ${PaymentRequestFragmentFragmentDoc}`;
export type UndoPaymentRequestMutationFn = ApolloReactCommon.MutationFunction<UndoPaymentRequestMutation, UndoPaymentRequestMutationVariables>;

/**
 * __useUndoPaymentRequestMutation__
 *
 * To run a mutation, you first call `useUndoPaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoPaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoPaymentRequestMutation, { data, loading, error }] = useUndoPaymentRequestMutation({
 *   variables: {
 *      paymentRequestId: // value for 'paymentRequestId'
 *   },
 * });
 */
export function useUndoPaymentRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UndoPaymentRequestMutation, UndoPaymentRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UndoPaymentRequestMutation, UndoPaymentRequestMutationVariables>(UndoPaymentRequestDocument, baseOptions);
      }
export type UndoPaymentRequestMutationHookResult = ReturnType<typeof useUndoPaymentRequestMutation>;
export type UndoPaymentRequestMutationResult = ApolloReactCommon.MutationResult<UndoPaymentRequestMutation>;
export type UndoPaymentRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UndoPaymentRequestMutation, UndoPaymentRequestMutationVariables>;
export const DeletePaymentRequestDocument = gql`
    mutation deletePaymentRequest($paymentRequestId: Float!) {
  deletePaymentRequest(paymentRequestId: $paymentRequestId) {
    ...PaymentRequestFragment
  }
}
    ${PaymentRequestFragmentFragmentDoc}`;
export type DeletePaymentRequestMutationFn = ApolloReactCommon.MutationFunction<DeletePaymentRequestMutation, DeletePaymentRequestMutationVariables>;

/**
 * __useDeletePaymentRequestMutation__
 *
 * To run a mutation, you first call `useDeletePaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentRequestMutation, { data, loading, error }] = useDeletePaymentRequestMutation({
 *   variables: {
 *      paymentRequestId: // value for 'paymentRequestId'
 *   },
 * });
 */
export function useDeletePaymentRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePaymentRequestMutation, DeletePaymentRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePaymentRequestMutation, DeletePaymentRequestMutationVariables>(DeletePaymentRequestDocument, baseOptions);
      }
export type DeletePaymentRequestMutationHookResult = ReturnType<typeof useDeletePaymentRequestMutation>;
export type DeletePaymentRequestMutationResult = ApolloReactCommon.MutationResult<DeletePaymentRequestMutation>;
export type DeletePaymentRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePaymentRequestMutation, DeletePaymentRequestMutationVariables>;
export const ReconcilePaymentRequestDocument = gql`
    mutation reconcilePaymentRequest($paymentRequestId: Float!) {
  reconcilePaymentRequest(paymentRequestId: $paymentRequestId) {
    ...PaymentRequestFragment
  }
}
    ${PaymentRequestFragmentFragmentDoc}`;
export type ReconcilePaymentRequestMutationFn = ApolloReactCommon.MutationFunction<ReconcilePaymentRequestMutation, ReconcilePaymentRequestMutationVariables>;

/**
 * __useReconcilePaymentRequestMutation__
 *
 * To run a mutation, you first call `useReconcilePaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconcilePaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconcilePaymentRequestMutation, { data, loading, error }] = useReconcilePaymentRequestMutation({
 *   variables: {
 *      paymentRequestId: // value for 'paymentRequestId'
 *   },
 * });
 */
export function useReconcilePaymentRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReconcilePaymentRequestMutation, ReconcilePaymentRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<ReconcilePaymentRequestMutation, ReconcilePaymentRequestMutationVariables>(ReconcilePaymentRequestDocument, baseOptions);
      }
export type ReconcilePaymentRequestMutationHookResult = ReturnType<typeof useReconcilePaymentRequestMutation>;
export type ReconcilePaymentRequestMutationResult = ApolloReactCommon.MutationResult<ReconcilePaymentRequestMutation>;
export type ReconcilePaymentRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<ReconcilePaymentRequestMutation, ReconcilePaymentRequestMutationVariables>;
export const GetPaymentRequestQueryDocument = gql`
    query getPaymentRequestQuery($searchInput: String!) {
  getPaymentRequestQuery(searchInput: $searchInput) {
    campaign {
      ...paymentQueryResultFragment
    }
    influencer {
      ...paymentQueryResultFragment
    }
    requestedBy {
      ...paymentQueryResultFragment
    }
    id {
      ...paymentQueryResultFragment
    }
  }
}
    ${PaymentQueryResultFragmentFragmentDoc}`;

/**
 * __useGetPaymentRequestQueryQuery__
 *
 * To run a query within a React component, call `useGetPaymentRequestQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentRequestQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentRequestQueryQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetPaymentRequestQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentRequestQueryQuery, GetPaymentRequestQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentRequestQueryQuery, GetPaymentRequestQueryQueryVariables>(GetPaymentRequestQueryDocument, baseOptions);
      }
export function useGetPaymentRequestQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentRequestQueryQuery, GetPaymentRequestQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentRequestQueryQuery, GetPaymentRequestQueryQueryVariables>(GetPaymentRequestQueryDocument, baseOptions);
        }
export type GetPaymentRequestQueryQueryHookResult = ReturnType<typeof useGetPaymentRequestQueryQuery>;
export type GetPaymentRequestQueryLazyQueryHookResult = ReturnType<typeof useGetPaymentRequestQueryLazyQuery>;
export type GetPaymentRequestQueryQueryResult = ApolloReactCommon.QueryResult<GetPaymentRequestQueryQuery, GetPaymentRequestQueryQueryVariables>;
export const UpdateAgencyPayoutDetailByIdDocument = gql`
    mutation updateAgencyPayoutDetailById($agencyId: Int!, $paymentDetail: PaymentDetailType!) {
  updateAgencyPayoutDetailById(agencyId: $agencyId, paymentDetail: $paymentDetail) {
    id
    agencyName
    payoutDetail {
      paypalEmail
      bankAccount {
        countryCode
        sortCode
        sortCode
        accountNumber
      }
    }
  }
}
    `;
export type UpdateAgencyPayoutDetailByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateAgencyPayoutDetailByIdMutation, UpdateAgencyPayoutDetailByIdMutationVariables>;

/**
 * __useUpdateAgencyPayoutDetailByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyPayoutDetailByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyPayoutDetailByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyPayoutDetailByIdMutation, { data, loading, error }] = useUpdateAgencyPayoutDetailByIdMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      paymentDetail: // value for 'paymentDetail'
 *   },
 * });
 */
export function useUpdateAgencyPayoutDetailByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAgencyPayoutDetailByIdMutation, UpdateAgencyPayoutDetailByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAgencyPayoutDetailByIdMutation, UpdateAgencyPayoutDetailByIdMutationVariables>(UpdateAgencyPayoutDetailByIdDocument, baseOptions);
      }
export type UpdateAgencyPayoutDetailByIdMutationHookResult = ReturnType<typeof useUpdateAgencyPayoutDetailByIdMutation>;
export type UpdateAgencyPayoutDetailByIdMutationResult = ApolloReactCommon.MutationResult<UpdateAgencyPayoutDetailByIdMutation>;
export type UpdateAgencyPayoutDetailByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAgencyPayoutDetailByIdMutation, UpdateAgencyPayoutDetailByIdMutationVariables>;
export const GetTikTokUserBrandSafetyDocument = gql`
    query getTikTokUserBrandSafety($tiktokUserUrl: String!) {
  getTikTokUserBrandSafety(tiktokUserUrl: $tiktokUserUrl) {
    ...TikTokUserBrandSafetyTypeFragment
  }
}
    ${TikTokUserBrandSafetyTypeFragmentFragmentDoc}`;

/**
 * __useGetTikTokUserBrandSafetyQuery__
 *
 * To run a query within a React component, call `useGetTikTokUserBrandSafetyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTikTokUserBrandSafetyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTikTokUserBrandSafetyQuery({
 *   variables: {
 *      tiktokUserUrl: // value for 'tiktokUserUrl'
 *   },
 * });
 */
export function useGetTikTokUserBrandSafetyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTikTokUserBrandSafetyQuery, GetTikTokUserBrandSafetyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTikTokUserBrandSafetyQuery, GetTikTokUserBrandSafetyQueryVariables>(GetTikTokUserBrandSafetyDocument, baseOptions);
      }
export function useGetTikTokUserBrandSafetyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTikTokUserBrandSafetyQuery, GetTikTokUserBrandSafetyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTikTokUserBrandSafetyQuery, GetTikTokUserBrandSafetyQueryVariables>(GetTikTokUserBrandSafetyDocument, baseOptions);
        }
export type GetTikTokUserBrandSafetyQueryHookResult = ReturnType<typeof useGetTikTokUserBrandSafetyQuery>;
export type GetTikTokUserBrandSafetyLazyQueryHookResult = ReturnType<typeof useGetTikTokUserBrandSafetyLazyQuery>;
export type GetTikTokUserBrandSafetyQueryResult = ApolloReactCommon.QueryResult<GetTikTokUserBrandSafetyQuery, GetTikTokUserBrandSafetyQueryVariables>;
export const NewInfluencerSignupDocument = gql`
    mutation NewInfluencerSignup($data: NewInfluencerInput!) {
  newInfluencerSignup(data: $data) {
    id
  }
}
    `;
export type NewInfluencerSignupMutationFn = ApolloReactCommon.MutationFunction<NewInfluencerSignupMutation, NewInfluencerSignupMutationVariables>;

/**
 * __useNewInfluencerSignupMutation__
 *
 * To run a mutation, you first call `useNewInfluencerSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewInfluencerSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newInfluencerSignupMutation, { data, loading, error }] = useNewInfluencerSignupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewInfluencerSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewInfluencerSignupMutation, NewInfluencerSignupMutationVariables>) {
        return ApolloReactHooks.useMutation<NewInfluencerSignupMutation, NewInfluencerSignupMutationVariables>(NewInfluencerSignupDocument, baseOptions);
      }
export type NewInfluencerSignupMutationHookResult = ReturnType<typeof useNewInfluencerSignupMutation>;
export type NewInfluencerSignupMutationResult = ApolloReactCommon.MutationResult<NewInfluencerSignupMutation>;
export type NewInfluencerSignupMutationOptions = ApolloReactCommon.BaseMutationOptions<NewInfluencerSignupMutation, NewInfluencerSignupMutationVariables>;
export const GetCampaignInstantReportDocument = gql`
    query getCampaignInstantReport($campaignId: Int!) {
  getCampaignInstantReport(campaignId: $campaignId) {
    ...CampaignReportTypeFragment
  }
}
    ${CampaignReportTypeFragmentFragmentDoc}`;

/**
 * __useGetCampaignInstantReportQuery__
 *
 * To run a query within a React component, call `useGetCampaignInstantReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignInstantReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignInstantReportQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignInstantReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignInstantReportQuery, GetCampaignInstantReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignInstantReportQuery, GetCampaignInstantReportQueryVariables>(GetCampaignInstantReportDocument, baseOptions);
      }
export function useGetCampaignInstantReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignInstantReportQuery, GetCampaignInstantReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignInstantReportQuery, GetCampaignInstantReportQueryVariables>(GetCampaignInstantReportDocument, baseOptions);
        }
export type GetCampaignInstantReportQueryHookResult = ReturnType<typeof useGetCampaignInstantReportQuery>;
export type GetCampaignInstantReportLazyQueryHookResult = ReturnType<typeof useGetCampaignInstantReportLazyQuery>;
export type GetCampaignInstantReportQueryResult = ApolloReactCommon.QueryResult<GetCampaignInstantReportQuery, GetCampaignInstantReportQueryVariables>;
export const RateCampaignHelpfulnessDocument = gql`
    mutation rateCampaignHelpfulness($campaignId: Int!, $helpfulnessRating: Float!) {
  rateCampaignHelpfulness(campaignId: $campaignId, helpfulnessRating: $helpfulnessRating) {
    ...CampaignFragment
  }
}
    ${CampaignFragmentFragmentDoc}`;
export type RateCampaignHelpfulnessMutationFn = ApolloReactCommon.MutationFunction<RateCampaignHelpfulnessMutation, RateCampaignHelpfulnessMutationVariables>;

/**
 * __useRateCampaignHelpfulnessMutation__
 *
 * To run a mutation, you first call `useRateCampaignHelpfulnessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateCampaignHelpfulnessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateCampaignHelpfulnessMutation, { data, loading, error }] = useRateCampaignHelpfulnessMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      helpfulnessRating: // value for 'helpfulnessRating'
 *   },
 * });
 */
export function useRateCampaignHelpfulnessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateCampaignHelpfulnessMutation, RateCampaignHelpfulnessMutationVariables>) {
        return ApolloReactHooks.useMutation<RateCampaignHelpfulnessMutation, RateCampaignHelpfulnessMutationVariables>(RateCampaignHelpfulnessDocument, baseOptions);
      }
export type RateCampaignHelpfulnessMutationHookResult = ReturnType<typeof useRateCampaignHelpfulnessMutation>;
export type RateCampaignHelpfulnessMutationResult = ApolloReactCommon.MutationResult<RateCampaignHelpfulnessMutation>;
export type RateCampaignHelpfulnessMutationOptions = ApolloReactCommon.BaseMutationOptions<RateCampaignHelpfulnessMutation, RateCampaignHelpfulnessMutationVariables>;
export const GetJwtTokenFromGoogleTokenDocument = gql`
    mutation getJwtTokenFromGoogleToken($access_token: String!, $refresh_token: String!) {
  getJwtTokenFromGoogleToken(access_token: $access_token, refresh_token: $refresh_token) {
    token
  }
}
    `;
export type GetJwtTokenFromGoogleTokenMutationFn = ApolloReactCommon.MutationFunction<GetJwtTokenFromGoogleTokenMutation, GetJwtTokenFromGoogleTokenMutationVariables>;

/**
 * __useGetJwtTokenFromGoogleTokenMutation__
 *
 * To run a mutation, you first call `useGetJwtTokenFromGoogleTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetJwtTokenFromGoogleTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getJwtTokenFromGoogleTokenMutation, { data, loading, error }] = useGetJwtTokenFromGoogleTokenMutation({
 *   variables: {
 *      access_token: // value for 'access_token'
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useGetJwtTokenFromGoogleTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetJwtTokenFromGoogleTokenMutation, GetJwtTokenFromGoogleTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<GetJwtTokenFromGoogleTokenMutation, GetJwtTokenFromGoogleTokenMutationVariables>(GetJwtTokenFromGoogleTokenDocument, baseOptions);
      }
export type GetJwtTokenFromGoogleTokenMutationHookResult = ReturnType<typeof useGetJwtTokenFromGoogleTokenMutation>;
export type GetJwtTokenFromGoogleTokenMutationResult = ApolloReactCommon.MutationResult<GetJwtTokenFromGoogleTokenMutation>;
export type GetJwtTokenFromGoogleTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<GetJwtTokenFromGoogleTokenMutation, GetJwtTokenFromGoogleTokenMutationVariables>;
export const GetPendingShareSuggestionsDocument = gql`
    query getPendingShareSuggestions($campaignId: Int!, $page: Int) {
  getPendingShareSuggestions(campaignId: $campaignId, page: $page) {
    pageTitle
    ...paginatedSuggestions
  }
}
    ${PaginatedSuggestionsFragmentDoc}`;

/**
 * __useGetPendingShareSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetPendingShareSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingShareSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingShareSuggestionsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetPendingShareSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPendingShareSuggestionsQuery, GetPendingShareSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPendingShareSuggestionsQuery, GetPendingShareSuggestionsQueryVariables>(GetPendingShareSuggestionsDocument, baseOptions);
      }
export function useGetPendingShareSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPendingShareSuggestionsQuery, GetPendingShareSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPendingShareSuggestionsQuery, GetPendingShareSuggestionsQueryVariables>(GetPendingShareSuggestionsDocument, baseOptions);
        }
export type GetPendingShareSuggestionsQueryHookResult = ReturnType<typeof useGetPendingShareSuggestionsQuery>;
export type GetPendingShareSuggestionsLazyQueryHookResult = ReturnType<typeof useGetPendingShareSuggestionsLazyQuery>;
export type GetPendingShareSuggestionsQueryResult = ApolloReactCommon.QueryResult<GetPendingShareSuggestionsQuery, GetPendingShareSuggestionsQueryVariables>;
export const GetAcceptedShareSuggestionsDocument = gql`
    query getAcceptedShareSuggestions($campaignId: Int!, $page: Int) {
  getAcceptedShareSuggestions(campaignId: $campaignId, page: $page) {
    pageTitle
    ...paginatedSuggestions
  }
}
    ${PaginatedSuggestionsFragmentDoc}`;

/**
 * __useGetAcceptedShareSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetAcceptedShareSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcceptedShareSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcceptedShareSuggestionsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAcceptedShareSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAcceptedShareSuggestionsQuery, GetAcceptedShareSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAcceptedShareSuggestionsQuery, GetAcceptedShareSuggestionsQueryVariables>(GetAcceptedShareSuggestionsDocument, baseOptions);
      }
export function useGetAcceptedShareSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAcceptedShareSuggestionsQuery, GetAcceptedShareSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAcceptedShareSuggestionsQuery, GetAcceptedShareSuggestionsQueryVariables>(GetAcceptedShareSuggestionsDocument, baseOptions);
        }
export type GetAcceptedShareSuggestionsQueryHookResult = ReturnType<typeof useGetAcceptedShareSuggestionsQuery>;
export type GetAcceptedShareSuggestionsLazyQueryHookResult = ReturnType<typeof useGetAcceptedShareSuggestionsLazyQuery>;
export type GetAcceptedShareSuggestionsQueryResult = ApolloReactCommon.QueryResult<GetAcceptedShareSuggestionsQuery, GetAcceptedShareSuggestionsQueryVariables>;
export const AddLikedShareSuggestionDocument = gql`
    mutation addLikedShareSuggestion($suggestionId: Int!) {
  addLikedShareSuggestion(suggestionId: $suggestionId) {
    id
    status
    invitation {
      id
      status
    }
  }
}
    `;
export type AddLikedShareSuggestionMutationFn = ApolloReactCommon.MutationFunction<AddLikedShareSuggestionMutation, AddLikedShareSuggestionMutationVariables>;

/**
 * __useAddLikedShareSuggestionMutation__
 *
 * To run a mutation, you first call `useAddLikedShareSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLikedShareSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLikedShareSuggestionMutation, { data, loading, error }] = useAddLikedShareSuggestionMutation({
 *   variables: {
 *      suggestionId: // value for 'suggestionId'
 *   },
 * });
 */
export function useAddLikedShareSuggestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLikedShareSuggestionMutation, AddLikedShareSuggestionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddLikedShareSuggestionMutation, AddLikedShareSuggestionMutationVariables>(AddLikedShareSuggestionDocument, baseOptions);
      }
export type AddLikedShareSuggestionMutationHookResult = ReturnType<typeof useAddLikedShareSuggestionMutation>;
export type AddLikedShareSuggestionMutationResult = ApolloReactCommon.MutationResult<AddLikedShareSuggestionMutation>;
export type AddLikedShareSuggestionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLikedShareSuggestionMutation, AddLikedShareSuggestionMutationVariables>;
export const RemoveLikedShareSuggestionDocument = gql`
    mutation removeLikedShareSuggestion($suggestionId: Int!) {
  removeLikedShareSuggestion(suggestionId: $suggestionId) {
    id
    status
    invitation {
      id
      status
    }
  }
}
    `;
export type RemoveLikedShareSuggestionMutationFn = ApolloReactCommon.MutationFunction<RemoveLikedShareSuggestionMutation, RemoveLikedShareSuggestionMutationVariables>;

/**
 * __useRemoveLikedShareSuggestionMutation__
 *
 * To run a mutation, you first call `useRemoveLikedShareSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLikedShareSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLikedShareSuggestionMutation, { data, loading, error }] = useRemoveLikedShareSuggestionMutation({
 *   variables: {
 *      suggestionId: // value for 'suggestionId'
 *   },
 * });
 */
export function useRemoveLikedShareSuggestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveLikedShareSuggestionMutation, RemoveLikedShareSuggestionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveLikedShareSuggestionMutation, RemoveLikedShareSuggestionMutationVariables>(RemoveLikedShareSuggestionDocument, baseOptions);
      }
export type RemoveLikedShareSuggestionMutationHookResult = ReturnType<typeof useRemoveLikedShareSuggestionMutation>;
export type RemoveLikedShareSuggestionMutationResult = ApolloReactCommon.MutationResult<RemoveLikedShareSuggestionMutation>;
export type RemoveLikedShareSuggestionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveLikedShareSuggestionMutation, RemoveLikedShareSuggestionMutationVariables>;
export const UpdateShareSuggestionsPageTitileDocument = gql`
    mutation updateShareSuggestionsPageTitile($campaignId: Int!, $pageTitle: String!) {
  updateShareSuggestionsPageTitile(campaignId: $campaignId, pageTitle: $pageTitle) {
    id
    title
    metadata {
      pageTitle
    }
  }
}
    `;
export type UpdateShareSuggestionsPageTitileMutationFn = ApolloReactCommon.MutationFunction<UpdateShareSuggestionsPageTitileMutation, UpdateShareSuggestionsPageTitileMutationVariables>;

/**
 * __useUpdateShareSuggestionsPageTitileMutation__
 *
 * To run a mutation, you first call `useUpdateShareSuggestionsPageTitileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareSuggestionsPageTitileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareSuggestionsPageTitileMutation, { data, loading, error }] = useUpdateShareSuggestionsPageTitileMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      pageTitle: // value for 'pageTitle'
 *   },
 * });
 */
export function useUpdateShareSuggestionsPageTitileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShareSuggestionsPageTitileMutation, UpdateShareSuggestionsPageTitileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShareSuggestionsPageTitileMutation, UpdateShareSuggestionsPageTitileMutationVariables>(UpdateShareSuggestionsPageTitileDocument, baseOptions);
      }
export type UpdateShareSuggestionsPageTitileMutationHookResult = ReturnType<typeof useUpdateShareSuggestionsPageTitileMutation>;
export type UpdateShareSuggestionsPageTitileMutationResult = ApolloReactCommon.MutationResult<UpdateShareSuggestionsPageTitileMutation>;
export type UpdateShareSuggestionsPageTitileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShareSuggestionsPageTitileMutation, UpdateShareSuggestionsPageTitileMutationVariables>;
export const GetAllSuggestedAccountsByCampaignIdDocument = gql`
    query getAllSuggestedAccountsByCampaignId($campaignId: Float!) {
  getAllSuggestedAccountsByCampaignId(campaignId: $campaignId) {
    ...SuggestionFragment
  }
}
    ${SuggestionFragmentFragmentDoc}`;

/**
 * __useGetAllSuggestedAccountsByCampaignIdQuery__
 *
 * To run a query within a React component, call `useGetAllSuggestedAccountsByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSuggestedAccountsByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSuggestedAccountsByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetAllSuggestedAccountsByCampaignIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSuggestedAccountsByCampaignIdQuery, GetAllSuggestedAccountsByCampaignIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSuggestedAccountsByCampaignIdQuery, GetAllSuggestedAccountsByCampaignIdQueryVariables>(GetAllSuggestedAccountsByCampaignIdDocument, baseOptions);
      }
export function useGetAllSuggestedAccountsByCampaignIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSuggestedAccountsByCampaignIdQuery, GetAllSuggestedAccountsByCampaignIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSuggestedAccountsByCampaignIdQuery, GetAllSuggestedAccountsByCampaignIdQueryVariables>(GetAllSuggestedAccountsByCampaignIdDocument, baseOptions);
        }
export type GetAllSuggestedAccountsByCampaignIdQueryHookResult = ReturnType<typeof useGetAllSuggestedAccountsByCampaignIdQuery>;
export type GetAllSuggestedAccountsByCampaignIdLazyQueryHookResult = ReturnType<typeof useGetAllSuggestedAccountsByCampaignIdLazyQuery>;
export type GetAllSuggestedAccountsByCampaignIdQueryResult = ApolloReactCommon.QueryResult<GetAllSuggestedAccountsByCampaignIdQuery, GetAllSuggestedAccountsByCampaignIdQueryVariables>;
export const GetAllAcceptedAccountsByCampaignIdDocument = gql`
    query getAllAcceptedAccountsByCampaignId($campaignId: Float!) {
  getAllAcceptedAccountsByCampaignId(campaignId: $campaignId) {
    ...AcceptedAccountsTypeFragment
  }
}
    ${AcceptedAccountsTypeFragmentFragmentDoc}`;

/**
 * __useGetAllAcceptedAccountsByCampaignIdQuery__
 *
 * To run a query within a React component, call `useGetAllAcceptedAccountsByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAcceptedAccountsByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAcceptedAccountsByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetAllAcceptedAccountsByCampaignIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllAcceptedAccountsByCampaignIdQuery, GetAllAcceptedAccountsByCampaignIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllAcceptedAccountsByCampaignIdQuery, GetAllAcceptedAccountsByCampaignIdQueryVariables>(GetAllAcceptedAccountsByCampaignIdDocument, baseOptions);
      }
export function useGetAllAcceptedAccountsByCampaignIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllAcceptedAccountsByCampaignIdQuery, GetAllAcceptedAccountsByCampaignIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllAcceptedAccountsByCampaignIdQuery, GetAllAcceptedAccountsByCampaignIdQueryVariables>(GetAllAcceptedAccountsByCampaignIdDocument, baseOptions);
        }
export type GetAllAcceptedAccountsByCampaignIdQueryHookResult = ReturnType<typeof useGetAllAcceptedAccountsByCampaignIdQuery>;
export type GetAllAcceptedAccountsByCampaignIdLazyQueryHookResult = ReturnType<typeof useGetAllAcceptedAccountsByCampaignIdLazyQuery>;
export type GetAllAcceptedAccountsByCampaignIdQueryResult = ApolloReactCommon.QueryResult<GetAllAcceptedAccountsByCampaignIdQuery, GetAllAcceptedAccountsByCampaignIdQueryVariables>;
export const GetAllInvitationOfAdminByCampaignIdDocument = gql`
    query getAllInvitationOfAdminByCampaignId($campaignId: Float!) {
  getAllInvitationOfAdminByCampaignId(campaignId: $campaignId) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;

/**
 * __useGetAllInvitationOfAdminByCampaignIdQuery__
 *
 * To run a query within a React component, call `useGetAllInvitationOfAdminByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvitationOfAdminByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvitationOfAdminByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetAllInvitationOfAdminByCampaignIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllInvitationOfAdminByCampaignIdQuery, GetAllInvitationOfAdminByCampaignIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllInvitationOfAdminByCampaignIdQuery, GetAllInvitationOfAdminByCampaignIdQueryVariables>(GetAllInvitationOfAdminByCampaignIdDocument, baseOptions);
      }
export function useGetAllInvitationOfAdminByCampaignIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllInvitationOfAdminByCampaignIdQuery, GetAllInvitationOfAdminByCampaignIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllInvitationOfAdminByCampaignIdQuery, GetAllInvitationOfAdminByCampaignIdQueryVariables>(GetAllInvitationOfAdminByCampaignIdDocument, baseOptions);
        }
export type GetAllInvitationOfAdminByCampaignIdQueryHookResult = ReturnType<typeof useGetAllInvitationOfAdminByCampaignIdQuery>;
export type GetAllInvitationOfAdminByCampaignIdLazyQueryHookResult = ReturnType<typeof useGetAllInvitationOfAdminByCampaignIdLazyQuery>;
export type GetAllInvitationOfAdminByCampaignIdQueryResult = ApolloReactCommon.QueryResult<GetAllInvitationOfAdminByCampaignIdQuery, GetAllInvitationOfAdminByCampaignIdQueryVariables>;
export const GetCampaignDetailByCampaignIdDocument = gql`
    query getCampaignDetailByCampaignId($campaignId: Float!) {
  getCampaignDetailByCampaignId(campaignId: $campaignId) {
    sentInvitations
    invitedSpendPennies
    campaign {
      ...CampaignFragment
    }
  }
}
    ${CampaignFragmentFragmentDoc}`;

/**
 * __useGetCampaignDetailByCampaignIdQuery__
 *
 * To run a query within a React component, call `useGetCampaignDetailByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignDetailByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignDetailByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignDetailByCampaignIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignDetailByCampaignIdQuery, GetCampaignDetailByCampaignIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignDetailByCampaignIdQuery, GetCampaignDetailByCampaignIdQueryVariables>(GetCampaignDetailByCampaignIdDocument, baseOptions);
      }
export function useGetCampaignDetailByCampaignIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignDetailByCampaignIdQuery, GetCampaignDetailByCampaignIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignDetailByCampaignIdQuery, GetCampaignDetailByCampaignIdQueryVariables>(GetCampaignDetailByCampaignIdDocument, baseOptions);
        }
export type GetCampaignDetailByCampaignIdQueryHookResult = ReturnType<typeof useGetCampaignDetailByCampaignIdQuery>;
export type GetCampaignDetailByCampaignIdLazyQueryHookResult = ReturnType<typeof useGetCampaignDetailByCampaignIdLazyQuery>;
export type GetCampaignDetailByCampaignIdQueryResult = ApolloReactCommon.QueryResult<GetCampaignDetailByCampaignIdQuery, GetCampaignDetailByCampaignIdQueryVariables>;
export const RemoveInvitedAccountDocument = gql`
    mutation removeInvitedAccount($campaignInvitationId: Float!, $suggestionStatus: String!) {
  removeInvitedAccount(campaignInvitationId: $campaignInvitationId, suggestionStatus: $suggestionStatus) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;
export type RemoveInvitedAccountMutationFn = ApolloReactCommon.MutationFunction<RemoveInvitedAccountMutation, RemoveInvitedAccountMutationVariables>;

/**
 * __useRemoveInvitedAccountMutation__
 *
 * To run a mutation, you first call `useRemoveInvitedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInvitedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInvitedAccountMutation, { data, loading, error }] = useRemoveInvitedAccountMutation({
 *   variables: {
 *      campaignInvitationId: // value for 'campaignInvitationId'
 *      suggestionStatus: // value for 'suggestionStatus'
 *   },
 * });
 */
export function useRemoveInvitedAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveInvitedAccountMutation, RemoveInvitedAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveInvitedAccountMutation, RemoveInvitedAccountMutationVariables>(RemoveInvitedAccountDocument, baseOptions);
      }
export type RemoveInvitedAccountMutationHookResult = ReturnType<typeof useRemoveInvitedAccountMutation>;
export type RemoveInvitedAccountMutationResult = ApolloReactCommon.MutationResult<RemoveInvitedAccountMutation>;
export type RemoveInvitedAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveInvitedAccountMutation, RemoveInvitedAccountMutationVariables>;
export const AddSelectedAccountsByCampaignIdDocument = gql`
    mutation addSelectedAccountsByCampaignId($campaignId: Float!, $accountIds: [Int!]) {
  addSelectedAccountsByCampaignId(campaignId: $campaignId, accountIds: $accountIds) {
    ...SuggestionFragment
  }
}
    ${SuggestionFragmentFragmentDoc}`;
export type AddSelectedAccountsByCampaignIdMutationFn = ApolloReactCommon.MutationFunction<AddSelectedAccountsByCampaignIdMutation, AddSelectedAccountsByCampaignIdMutationVariables>;

/**
 * __useAddSelectedAccountsByCampaignIdMutation__
 *
 * To run a mutation, you first call `useAddSelectedAccountsByCampaignIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSelectedAccountsByCampaignIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSelectedAccountsByCampaignIdMutation, { data, loading, error }] = useAddSelectedAccountsByCampaignIdMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useAddSelectedAccountsByCampaignIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSelectedAccountsByCampaignIdMutation, AddSelectedAccountsByCampaignIdMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSelectedAccountsByCampaignIdMutation, AddSelectedAccountsByCampaignIdMutationVariables>(AddSelectedAccountsByCampaignIdDocument, baseOptions);
      }
export type AddSelectedAccountsByCampaignIdMutationHookResult = ReturnType<typeof useAddSelectedAccountsByCampaignIdMutation>;
export type AddSelectedAccountsByCampaignIdMutationResult = ApolloReactCommon.MutationResult<AddSelectedAccountsByCampaignIdMutation>;
export type AddSelectedAccountsByCampaignIdMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSelectedAccountsByCampaignIdMutation, AddSelectedAccountsByCampaignIdMutationVariables>;
export const UpdateSuggestionAccountDocument = gql`
    mutation updateSuggestionAccount($suggestionId: Float!, $status: String!) {
  updateSuggestionAccount(suggestionId: $suggestionId, status: $status) {
    ...SuggestionFragment
  }
}
    ${SuggestionFragmentFragmentDoc}`;
export type UpdateSuggestionAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateSuggestionAccountMutation, UpdateSuggestionAccountMutationVariables>;

/**
 * __useUpdateSuggestionAccountMutation__
 *
 * To run a mutation, you first call `useUpdateSuggestionAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuggestionAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuggestionAccountMutation, { data, loading, error }] = useUpdateSuggestionAccountMutation({
 *   variables: {
 *      suggestionId: // value for 'suggestionId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateSuggestionAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSuggestionAccountMutation, UpdateSuggestionAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSuggestionAccountMutation, UpdateSuggestionAccountMutationVariables>(UpdateSuggestionAccountDocument, baseOptions);
      }
export type UpdateSuggestionAccountMutationHookResult = ReturnType<typeof useUpdateSuggestionAccountMutation>;
export type UpdateSuggestionAccountMutationResult = ApolloReactCommon.MutationResult<UpdateSuggestionAccountMutation>;
export type UpdateSuggestionAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSuggestionAccountMutation, UpdateSuggestionAccountMutationVariables>;
export const GetTestLinkByCamapignIdDocument = gql`
    query getTestLinkByCamapignId($campaignId: Float!) {
  getTestLinkByCamapignId(campaignId: $campaignId) {
    id
    snaplinkPretty
    snaplink
    clicks
    installs
    tested
    campaignId
    campaignInvitationId
  }
}
    `;

/**
 * __useGetTestLinkByCamapignIdQuery__
 *
 * To run a query within a React component, call `useGetTestLinkByCamapignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestLinkByCamapignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestLinkByCamapignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetTestLinkByCamapignIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTestLinkByCamapignIdQuery, GetTestLinkByCamapignIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTestLinkByCamapignIdQuery, GetTestLinkByCamapignIdQueryVariables>(GetTestLinkByCamapignIdDocument, baseOptions);
      }
export function useGetTestLinkByCamapignIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTestLinkByCamapignIdQuery, GetTestLinkByCamapignIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTestLinkByCamapignIdQuery, GetTestLinkByCamapignIdQueryVariables>(GetTestLinkByCamapignIdDocument, baseOptions);
        }
export type GetTestLinkByCamapignIdQueryHookResult = ReturnType<typeof useGetTestLinkByCamapignIdQuery>;
export type GetTestLinkByCamapignIdLazyQueryHookResult = ReturnType<typeof useGetTestLinkByCamapignIdLazyQuery>;
export type GetTestLinkByCamapignIdQueryResult = ApolloReactCommon.QueryResult<GetTestLinkByCamapignIdQuery, GetTestLinkByCamapignIdQueryVariables>;
export const CreateGiftingCampaignDocument = gql`
    mutation CreateGiftingCampaign($title: String!) {
  createGiftingCampaign(title: $title) {
    ...GiftingCampaignFragment
  }
}
    ${GiftingCampaignFragmentFragmentDoc}`;
export type CreateGiftingCampaignMutationFn = ApolloReactCommon.MutationFunction<CreateGiftingCampaignMutation, CreateGiftingCampaignMutationVariables>;

/**
 * __useCreateGiftingCampaignMutation__
 *
 * To run a mutation, you first call `useCreateGiftingCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGiftingCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGiftingCampaignMutation, { data, loading, error }] = useCreateGiftingCampaignMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateGiftingCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGiftingCampaignMutation, CreateGiftingCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGiftingCampaignMutation, CreateGiftingCampaignMutationVariables>(CreateGiftingCampaignDocument, baseOptions);
      }
export type CreateGiftingCampaignMutationHookResult = ReturnType<typeof useCreateGiftingCampaignMutation>;
export type CreateGiftingCampaignMutationResult = ApolloReactCommon.MutationResult<CreateGiftingCampaignMutation>;
export type CreateGiftingCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGiftingCampaignMutation, CreateGiftingCampaignMutationVariables>;
export const SubmitGiftingCampaignDocument = gql`
    mutation SubmitGiftingCampaign($campaignId: Int!) {
  submitGiftingCampaign(campaignId: $campaignId) {
    ...GiftingCampaignFragment
  }
}
    ${GiftingCampaignFragmentFragmentDoc}`;
export type SubmitGiftingCampaignMutationFn = ApolloReactCommon.MutationFunction<SubmitGiftingCampaignMutation, SubmitGiftingCampaignMutationVariables>;

/**
 * __useSubmitGiftingCampaignMutation__
 *
 * To run a mutation, you first call `useSubmitGiftingCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGiftingCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGiftingCampaignMutation, { data, loading, error }] = useSubmitGiftingCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useSubmitGiftingCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitGiftingCampaignMutation, SubmitGiftingCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitGiftingCampaignMutation, SubmitGiftingCampaignMutationVariables>(SubmitGiftingCampaignDocument, baseOptions);
      }
export type SubmitGiftingCampaignMutationHookResult = ReturnType<typeof useSubmitGiftingCampaignMutation>;
export type SubmitGiftingCampaignMutationResult = ApolloReactCommon.MutationResult<SubmitGiftingCampaignMutation>;
export type SubmitGiftingCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitGiftingCampaignMutation, SubmitGiftingCampaignMutationVariables>;
export const DeleteGiftingCampaignDocument = gql`
    mutation DeleteGiftingCampaign($campaignId: Int!) {
  deleteGiftingCampaign(campaignId: $campaignId)
}
    `;
export type DeleteGiftingCampaignMutationFn = ApolloReactCommon.MutationFunction<DeleteGiftingCampaignMutation, DeleteGiftingCampaignMutationVariables>;

/**
 * __useDeleteGiftingCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteGiftingCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGiftingCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGiftingCampaignMutation, { data, loading, error }] = useDeleteGiftingCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteGiftingCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGiftingCampaignMutation, DeleteGiftingCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGiftingCampaignMutation, DeleteGiftingCampaignMutationVariables>(DeleteGiftingCampaignDocument, baseOptions);
      }
export type DeleteGiftingCampaignMutationHookResult = ReturnType<typeof useDeleteGiftingCampaignMutation>;
export type DeleteGiftingCampaignMutationResult = ApolloReactCommon.MutationResult<DeleteGiftingCampaignMutation>;
export type DeleteGiftingCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGiftingCampaignMutation, DeleteGiftingCampaignMutationVariables>;
export const UpdateGiftingCampaignDocument = gql`
    mutation UpdateGiftingCampaign($campaignId: Int!, $data: UpdateGiftingCampaign!) {
  updateGiftingCampaign(campaignId: $campaignId, data: $data) {
    ...GiftingCampaignFragment
  }
}
    ${GiftingCampaignFragmentFragmentDoc}`;
export type UpdateGiftingCampaignMutationFn = ApolloReactCommon.MutationFunction<UpdateGiftingCampaignMutation, UpdateGiftingCampaignMutationVariables>;

/**
 * __useUpdateGiftingCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateGiftingCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGiftingCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGiftingCampaignMutation, { data, loading, error }] = useUpdateGiftingCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateGiftingCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGiftingCampaignMutation, UpdateGiftingCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGiftingCampaignMutation, UpdateGiftingCampaignMutationVariables>(UpdateGiftingCampaignDocument, baseOptions);
      }
export type UpdateGiftingCampaignMutationHookResult = ReturnType<typeof useUpdateGiftingCampaignMutation>;
export type UpdateGiftingCampaignMutationResult = ApolloReactCommon.MutationResult<UpdateGiftingCampaignMutation>;
export type UpdateGiftingCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGiftingCampaignMutation, UpdateGiftingCampaignMutationVariables>;
export const GetGiftingCampaignsDocument = gql`
    query GetGiftingCampaigns {
  getGiftingCampaigns {
    ...GiftingCampaignFragment
  }
}
    ${GiftingCampaignFragmentFragmentDoc}`;

/**
 * __useGetGiftingCampaignsQuery__
 *
 * To run a query within a React component, call `useGetGiftingCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftingCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftingCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGiftingCampaignsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGiftingCampaignsQuery, GetGiftingCampaignsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGiftingCampaignsQuery, GetGiftingCampaignsQueryVariables>(GetGiftingCampaignsDocument, baseOptions);
      }
export function useGetGiftingCampaignsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGiftingCampaignsQuery, GetGiftingCampaignsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGiftingCampaignsQuery, GetGiftingCampaignsQueryVariables>(GetGiftingCampaignsDocument, baseOptions);
        }
export type GetGiftingCampaignsQueryHookResult = ReturnType<typeof useGetGiftingCampaignsQuery>;
export type GetGiftingCampaignsLazyQueryHookResult = ReturnType<typeof useGetGiftingCampaignsLazyQuery>;
export type GetGiftingCampaignsQueryResult = ApolloReactCommon.QueryResult<GetGiftingCampaignsQuery, GetGiftingCampaignsQueryVariables>;
export const GetGiftingCampaignDocument = gql`
    query GetGiftingCampaign($campaignId: Int!) {
  getGiftingCampaign(campaignId: $campaignId) {
    ...GiftingCampaignFragment
  }
}
    ${GiftingCampaignFragmentFragmentDoc}`;

/**
 * __useGetGiftingCampaignQuery__
 *
 * To run a query within a React component, call `useGetGiftingCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftingCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftingCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetGiftingCampaignQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGiftingCampaignQuery, GetGiftingCampaignQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGiftingCampaignQuery, GetGiftingCampaignQueryVariables>(GetGiftingCampaignDocument, baseOptions);
      }
export function useGetGiftingCampaignLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGiftingCampaignQuery, GetGiftingCampaignQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGiftingCampaignQuery, GetGiftingCampaignQueryVariables>(GetGiftingCampaignDocument, baseOptions);
        }
export type GetGiftingCampaignQueryHookResult = ReturnType<typeof useGetGiftingCampaignQuery>;
export type GetGiftingCampaignLazyQueryHookResult = ReturnType<typeof useGetGiftingCampaignLazyQuery>;
export type GetGiftingCampaignQueryResult = ApolloReactCommon.QueryResult<GetGiftingCampaignQuery, GetGiftingCampaignQueryVariables>;
export const GetPublicCampaignsDocument = gql`
    query getPublicCampaigns($searchInput: String, $tags: [Int], $page: Int, $isApplied: Boolean) {
  getPublicCampaigns(searchInput: $searchInput, tags: $tags, page: $page, isApplied: $isApplied) {
    totalEntries
    perPage
    totalPages
    previousPage
    currentPage
    nextPage
    publicCampaigns {
      id
      title
      isPublic
      isApplied
      publicDeadline
      distributionPlatforms
      tags {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetPublicCampaignsQuery__
 *
 * To run a query within a React component, call `useGetPublicCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCampaignsQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      tags: // value for 'tags'
 *      page: // value for 'page'
 *      isApplied: // value for 'isApplied'
 *   },
 * });
 */
export function useGetPublicCampaignsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicCampaignsQuery, GetPublicCampaignsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicCampaignsQuery, GetPublicCampaignsQueryVariables>(GetPublicCampaignsDocument, baseOptions);
      }
export function useGetPublicCampaignsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicCampaignsQuery, GetPublicCampaignsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicCampaignsQuery, GetPublicCampaignsQueryVariables>(GetPublicCampaignsDocument, baseOptions);
        }
export type GetPublicCampaignsQueryHookResult = ReturnType<typeof useGetPublicCampaignsQuery>;
export type GetPublicCampaignsLazyQueryHookResult = ReturnType<typeof useGetPublicCampaignsLazyQuery>;
export type GetPublicCampaignsQueryResult = ApolloReactCommon.QueryResult<GetPublicCampaignsQuery, GetPublicCampaignsQueryVariables>;
export const GetPublicCampaignDocument = gql`
    query getPublicCampaign($campaignId: Int!) {
  getPublicCampaign(campaignId: $campaignId) {
    publicCampaign {
      id
      tags {
        id
        name
      }
      brief
      title
      briefDo
      isPublic
      adFormat
      isApplied
      briefDont
      createdAt
      objective
      startDate
      aboutBrand
      publicDeadline
      distributionPlatforms
      creative {
        video {
          id
          url
          secureUrl
          resourceType
        }
      }
      metadata {
        creativeLinks
      }
      admin {
        email
      }
      executive {
        email
      }
      applicants {
        id
        username
      }
      company {
        companyName
      }
    }
    influencerAccounts {
      id
      username
      type
    }
  }
}
    `;

/**
 * __useGetPublicCampaignQuery__
 *
 * To run a query within a React component, call `useGetPublicCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetPublicCampaignQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicCampaignQuery, GetPublicCampaignQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicCampaignQuery, GetPublicCampaignQueryVariables>(GetPublicCampaignDocument, baseOptions);
      }
export function useGetPublicCampaignLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicCampaignQuery, GetPublicCampaignQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicCampaignQuery, GetPublicCampaignQueryVariables>(GetPublicCampaignDocument, baseOptions);
        }
export type GetPublicCampaignQueryHookResult = ReturnType<typeof useGetPublicCampaignQuery>;
export type GetPublicCampaignLazyQueryHookResult = ReturnType<typeof useGetPublicCampaignLazyQuery>;
export type GetPublicCampaignQueryResult = ApolloReactCommon.QueryResult<GetPublicCampaignQuery, GetPublicCampaignQueryVariables>;
export const ApplyPublicCampaignDocument = gql`
    mutation applyPublicCampaign($campaignId: Int!, $accountId: Int!) {
  applyPublicCampaign(campaignId: $campaignId, accountId: $accountId) {
    id
    title
    applicants {
      id
      username
    }
  }
}
    `;
export type ApplyPublicCampaignMutationFn = ApolloReactCommon.MutationFunction<ApplyPublicCampaignMutation, ApplyPublicCampaignMutationVariables>;

/**
 * __useApplyPublicCampaignMutation__
 *
 * To run a mutation, you first call `useApplyPublicCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPublicCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPublicCampaignMutation, { data, loading, error }] = useApplyPublicCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useApplyPublicCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApplyPublicCampaignMutation, ApplyPublicCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<ApplyPublicCampaignMutation, ApplyPublicCampaignMutationVariables>(ApplyPublicCampaignDocument, baseOptions);
      }
export type ApplyPublicCampaignMutationHookResult = ReturnType<typeof useApplyPublicCampaignMutation>;
export type ApplyPublicCampaignMutationResult = ApolloReactCommon.MutationResult<ApplyPublicCampaignMutation>;
export type ApplyPublicCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<ApplyPublicCampaignMutation, ApplyPublicCampaignMutationVariables>;
export const SignUpPublicInfluencerDocument = gql`
    mutation signUpPublicInfluencer($name: String!, $email: String!, $location: String!, $age: String!, $password: String!, $gender: String!) {
  signUpPublicInfluencer(name: $name, age: $age, gender: $gender, email: $email, location: $location, password: $password) {
    authToken
    influencer {
      expressApplicants {
        username
        platform
      }
    }
  }
}
    `;
export type SignUpPublicInfluencerMutationFn = ApolloReactCommon.MutationFunction<SignUpPublicInfluencerMutation, SignUpPublicInfluencerMutationVariables>;

/**
 * __useSignUpPublicInfluencerMutation__
 *
 * To run a mutation, you first call `useSignUpPublicInfluencerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpPublicInfluencerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpPublicInfluencerMutation, { data, loading, error }] = useSignUpPublicInfluencerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      location: // value for 'location'
 *      age: // value for 'age'
 *      password: // value for 'password'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useSignUpPublicInfluencerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpPublicInfluencerMutation, SignUpPublicInfluencerMutationVariables>) {
        return ApolloReactHooks.useMutation<SignUpPublicInfluencerMutation, SignUpPublicInfluencerMutationVariables>(SignUpPublicInfluencerDocument, baseOptions);
      }
export type SignUpPublicInfluencerMutationHookResult = ReturnType<typeof useSignUpPublicInfluencerMutation>;
export type SignUpPublicInfluencerMutationResult = ApolloReactCommon.MutationResult<SignUpPublicInfluencerMutation>;
export type SignUpPublicInfluencerMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpPublicInfluencerMutation, SignUpPublicInfluencerMutationVariables>;
export const ConnectPublicYouTubeAccountDocument = gql`
    mutation connectPublicYouTubeAccount($accessToken: String!) {
  connectPublicYouTubeAccount(accessToken: $accessToken) {
    influencer {
      id
      name
    }
    id
    type
    username
    views
    ageBreakdown
    genderBreakdown
    locationBreakdown
    metadata {
      avgViews
      avgLikes
      avgComments
      description
      followers
      engagementRate
    }
  }
}
    `;
export type ConnectPublicYouTubeAccountMutationFn = ApolloReactCommon.MutationFunction<ConnectPublicYouTubeAccountMutation, ConnectPublicYouTubeAccountMutationVariables>;

/**
 * __useConnectPublicYouTubeAccountMutation__
 *
 * To run a mutation, you first call `useConnectPublicYouTubeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectPublicYouTubeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectPublicYouTubeAccountMutation, { data, loading, error }] = useConnectPublicYouTubeAccountMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useConnectPublicYouTubeAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectPublicYouTubeAccountMutation, ConnectPublicYouTubeAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<ConnectPublicYouTubeAccountMutation, ConnectPublicYouTubeAccountMutationVariables>(ConnectPublicYouTubeAccountDocument, baseOptions);
      }
export type ConnectPublicYouTubeAccountMutationHookResult = ReturnType<typeof useConnectPublicYouTubeAccountMutation>;
export type ConnectPublicYouTubeAccountMutationResult = ApolloReactCommon.MutationResult<ConnectPublicYouTubeAccountMutation>;
export type ConnectPublicYouTubeAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectPublicYouTubeAccountMutation, ConnectPublicYouTubeAccountMutationVariables>;
export const UpdatePublicInfluencerFlagsDocument = gql`
    mutation updatePublicInfluencerFlags($flags: [String!]!) {
  updatePublicInfluencerFlags(flags: $flags) {
    id
    name
    detail {
      flags
    }
  }
}
    `;
export type UpdatePublicInfluencerFlagsMutationFn = ApolloReactCommon.MutationFunction<UpdatePublicInfluencerFlagsMutation, UpdatePublicInfluencerFlagsMutationVariables>;

/**
 * __useUpdatePublicInfluencerFlagsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicInfluencerFlagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicInfluencerFlagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicInfluencerFlagsMutation, { data, loading, error }] = useUpdatePublicInfluencerFlagsMutation({
 *   variables: {
 *      flags: // value for 'flags'
 *   },
 * });
 */
export function useUpdatePublicInfluencerFlagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublicInfluencerFlagsMutation, UpdatePublicInfluencerFlagsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePublicInfluencerFlagsMutation, UpdatePublicInfluencerFlagsMutationVariables>(UpdatePublicInfluencerFlagsDocument, baseOptions);
      }
export type UpdatePublicInfluencerFlagsMutationHookResult = ReturnType<typeof useUpdatePublicInfluencerFlagsMutation>;
export type UpdatePublicInfluencerFlagsMutationResult = ApolloReactCommon.MutationResult<UpdatePublicInfluencerFlagsMutation>;
export type UpdatePublicInfluencerFlagsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePublicInfluencerFlagsMutation, UpdatePublicInfluencerFlagsMutationVariables>;
export const GetPublicInfluencerProfileDocument = gql`
    query getPublicInfluencerProfile {
  getPublicInfluencerProfile {
    id
    name
    detail {
      age
      flags
      gender
      location
    }
    accounts {
      id
      type
      username
      views
      ageBreakdown
      genderBreakdown
      locationBreakdown
      profilePicUrl
      metadata {
        avgViews
        avgLikes
        avgComments
        description
        followers
        engagementRate
      }
      posts {
        snaplyticsContentUrl
        typeString
        postDatetime
        poster
        metadata {
          title
          postId
          viewCount
          likeCount
          description
          webVideoUrl
          dislikeCount
          commentCount
          favoriteCount
        }
      }
    }
    expressApplicants {
      username
      platform
    }
  }
}
    `;

/**
 * __useGetPublicInfluencerProfileQuery__
 *
 * To run a query within a React component, call `useGetPublicInfluencerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicInfluencerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicInfluencerProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicInfluencerProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicInfluencerProfileQuery, GetPublicInfluencerProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicInfluencerProfileQuery, GetPublicInfluencerProfileQueryVariables>(GetPublicInfluencerProfileDocument, baseOptions);
      }
export function useGetPublicInfluencerProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicInfluencerProfileQuery, GetPublicInfluencerProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicInfluencerProfileQuery, GetPublicInfluencerProfileQueryVariables>(GetPublicInfluencerProfileDocument, baseOptions);
        }
export type GetPublicInfluencerProfileQueryHookResult = ReturnType<typeof useGetPublicInfluencerProfileQuery>;
export type GetPublicInfluencerProfileLazyQueryHookResult = ReturnType<typeof useGetPublicInfluencerProfileLazyQuery>;
export type GetPublicInfluencerProfileQueryResult = ApolloReactCommon.QueryResult<GetPublicInfluencerProfileQuery, GetPublicInfluencerProfileQueryVariables>;
export const UpdatePublicAccountBreakdownsDocument = gql`
    mutation updatePublicAccountBreakdowns($accountId: Int!, $screenshots: [ScreenshotFileInput!], $ageBreakdown: JSON, $genderBreakdown: JSON, $locationBreakdown: JSON) {
  updatePublicAccountBreakdowns(accountId: $accountId, screenshots: $screenshots, ageBreakdown: $ageBreakdown, genderBreakdown: $genderBreakdown, locationBreakdown: $locationBreakdown) {
    id
    username
    screenshots {
      id
      uploadUrl
    }
    ageBreakdown
    genderBreakdown
    locationBreakdown
  }
}
    `;
export type UpdatePublicAccountBreakdownsMutationFn = ApolloReactCommon.MutationFunction<UpdatePublicAccountBreakdownsMutation, UpdatePublicAccountBreakdownsMutationVariables>;

/**
 * __useUpdatePublicAccountBreakdownsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicAccountBreakdownsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicAccountBreakdownsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicAccountBreakdownsMutation, { data, loading, error }] = useUpdatePublicAccountBreakdownsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      screenshots: // value for 'screenshots'
 *      ageBreakdown: // value for 'ageBreakdown'
 *      genderBreakdown: // value for 'genderBreakdown'
 *      locationBreakdown: // value for 'locationBreakdown'
 *   },
 * });
 */
export function useUpdatePublicAccountBreakdownsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublicAccountBreakdownsMutation, UpdatePublicAccountBreakdownsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePublicAccountBreakdownsMutation, UpdatePublicAccountBreakdownsMutationVariables>(UpdatePublicAccountBreakdownsDocument, baseOptions);
      }
export type UpdatePublicAccountBreakdownsMutationHookResult = ReturnType<typeof useUpdatePublicAccountBreakdownsMutation>;
export type UpdatePublicAccountBreakdownsMutationResult = ApolloReactCommon.MutationResult<UpdatePublicAccountBreakdownsMutation>;
export type UpdatePublicAccountBreakdownsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePublicAccountBreakdownsMutation, UpdatePublicAccountBreakdownsMutationVariables>;
export const ConnectPublicTikTokAccountDocument = gql`
    mutation connectPublicTikTokAccount($code: String!) {
  connectPublicTikTokAccount(code: $code) {
    id
    username
  }
}
    `;
export type ConnectPublicTikTokAccountMutationFn = ApolloReactCommon.MutationFunction<ConnectPublicTikTokAccountMutation, ConnectPublicTikTokAccountMutationVariables>;

/**
 * __useConnectPublicTikTokAccountMutation__
 *
 * To run a mutation, you first call `useConnectPublicTikTokAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectPublicTikTokAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectPublicTikTokAccountMutation, { data, loading, error }] = useConnectPublicTikTokAccountMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConnectPublicTikTokAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectPublicTikTokAccountMutation, ConnectPublicTikTokAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<ConnectPublicTikTokAccountMutation, ConnectPublicTikTokAccountMutationVariables>(ConnectPublicTikTokAccountDocument, baseOptions);
      }
export type ConnectPublicTikTokAccountMutationHookResult = ReturnType<typeof useConnectPublicTikTokAccountMutation>;
export type ConnectPublicTikTokAccountMutationResult = ApolloReactCommon.MutationResult<ConnectPublicTikTokAccountMutation>;
export type ConnectPublicTikTokAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectPublicTikTokAccountMutation, ConnectPublicTikTokAccountMutationVariables>;
export const ApplyPublicCampaignForExpressDocument = gql`
    mutation applyPublicCampaignForExpress($expectedFee: String!, $email: String!, $username: String!, $platform: PublicExpressApplicantPlatformType!, $campaignId: Int!) {
  applyPublicCampaignForExpress(email: $email, username: $username, platform: $platform, campaignId: $campaignId, expectedFee: $expectedFee) {
    id
  }
}
    `;
export type ApplyPublicCampaignForExpressMutationFn = ApolloReactCommon.MutationFunction<ApplyPublicCampaignForExpressMutation, ApplyPublicCampaignForExpressMutationVariables>;

/**
 * __useApplyPublicCampaignForExpressMutation__
 *
 * To run a mutation, you first call `useApplyPublicCampaignForExpressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPublicCampaignForExpressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPublicCampaignForExpressMutation, { data, loading, error }] = useApplyPublicCampaignForExpressMutation({
 *   variables: {
 *      expectedFee: // value for 'expectedFee'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      platform: // value for 'platform'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useApplyPublicCampaignForExpressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApplyPublicCampaignForExpressMutation, ApplyPublicCampaignForExpressMutationVariables>) {
        return ApolloReactHooks.useMutation<ApplyPublicCampaignForExpressMutation, ApplyPublicCampaignForExpressMutationVariables>(ApplyPublicCampaignForExpressDocument, baseOptions);
      }
export type ApplyPublicCampaignForExpressMutationHookResult = ReturnType<typeof useApplyPublicCampaignForExpressMutation>;
export type ApplyPublicCampaignForExpressMutationResult = ApolloReactCommon.MutationResult<ApplyPublicCampaignForExpressMutation>;
export type ApplyPublicCampaignForExpressMutationOptions = ApolloReactCommon.BaseMutationOptions<ApplyPublicCampaignForExpressMutation, ApplyPublicCampaignForExpressMutationVariables>;
export const GetInfluencerCashoutInvitationDocument = gql`
    query getInfluencerCashoutInvitation($claimCashoutId: String!) {
  getInfluencerCashoutInvitation(claimCashoutId: $claimCashoutId) {
    ...InfluencerCashoutInvitationFragment
  }
}
    ${InfluencerCashoutInvitationFragmentFragmentDoc}`;

/**
 * __useGetInfluencerCashoutInvitationQuery__
 *
 * To run a query within a React component, call `useGetInfluencerCashoutInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfluencerCashoutInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfluencerCashoutInvitationQuery({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *   },
 * });
 */
export function useGetInfluencerCashoutInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInfluencerCashoutInvitationQuery, GetInfluencerCashoutInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInfluencerCashoutInvitationQuery, GetInfluencerCashoutInvitationQueryVariables>(GetInfluencerCashoutInvitationDocument, baseOptions);
      }
export function useGetInfluencerCashoutInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInfluencerCashoutInvitationQuery, GetInfluencerCashoutInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInfluencerCashoutInvitationQuery, GetInfluencerCashoutInvitationQueryVariables>(GetInfluencerCashoutInvitationDocument, baseOptions);
        }
export type GetInfluencerCashoutInvitationQueryHookResult = ReturnType<typeof useGetInfluencerCashoutInvitationQuery>;
export type GetInfluencerCashoutInvitationLazyQueryHookResult = ReturnType<typeof useGetInfluencerCashoutInvitationLazyQuery>;
export type GetInfluencerCashoutInvitationQueryResult = ApolloReactCommon.QueryResult<GetInfluencerCashoutInvitationQuery, GetInfluencerCashoutInvitationQueryVariables>;
export const InfluencerCashoutDetailDocument = gql`
    mutation influencerCashoutDetail($claimCashoutId: String!, $viewCount: Int!, $tiktokUrl: String, $files: [FileInput!]!, $duration: Int, $likes: Int, $dislikes: Int, $comments: Int, $watchTime: Int) {
  influencerCashoutDetail(input: {claimCashoutId: $claimCashoutId, viewCount: $viewCount, tiktokUrl: $tiktokUrl, files: $files, duration: $duration, likes: $likes, dislikes: $dislikes, comments: $comments, watchTime: $watchTime}) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;
export type InfluencerCashoutDetailMutationFn = ApolloReactCommon.MutationFunction<InfluencerCashoutDetailMutation, InfluencerCashoutDetailMutationVariables>;

/**
 * __useInfluencerCashoutDetailMutation__
 *
 * To run a mutation, you first call `useInfluencerCashoutDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInfluencerCashoutDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [influencerCashoutDetailMutation, { data, loading, error }] = useInfluencerCashoutDetailMutation({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *      viewCount: // value for 'viewCount'
 *      tiktokUrl: // value for 'tiktokUrl'
 *      files: // value for 'files'
 *      duration: // value for 'duration'
 *      likes: // value for 'likes'
 *      dislikes: // value for 'dislikes'
 *      comments: // value for 'comments'
 *      watchTime: // value for 'watchTime'
 *   },
 * });
 */
export function useInfluencerCashoutDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InfluencerCashoutDetailMutation, InfluencerCashoutDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<InfluencerCashoutDetailMutation, InfluencerCashoutDetailMutationVariables>(InfluencerCashoutDetailDocument, baseOptions);
      }
export type InfluencerCashoutDetailMutationHookResult = ReturnType<typeof useInfluencerCashoutDetailMutation>;
export type InfluencerCashoutDetailMutationResult = ApolloReactCommon.MutationResult<InfluencerCashoutDetailMutation>;
export type InfluencerCashoutDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<InfluencerCashoutDetailMutation, InfluencerCashoutDetailMutationVariables>;
export const GetSignedUrlDocument = gql`
    mutation getSignedURL($fileName: String!, $fileType: String!) {
  getSignedURL(input: {fileName: $fileName, fileType: $fileType}) {
    url
    uploadUrl
  }
}
    `;
export type GetSignedUrlMutationFn = ApolloReactCommon.MutationFunction<GetSignedUrlMutation, GetSignedUrlMutationVariables>;

/**
 * __useGetSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUrlMutation, { data, loading, error }] = useGetSignedUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useGetSignedUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<GetSignedUrlMutation, GetSignedUrlMutationVariables>(GetSignedUrlDocument, baseOptions);
      }
export type GetSignedUrlMutationHookResult = ReturnType<typeof useGetSignedUrlMutation>;
export type GetSignedUrlMutationResult = ApolloReactCommon.MutationResult<GetSignedUrlMutation>;
export type GetSignedUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>;
export const InfluencerCashoutProfileDataDocument = gql`
    mutation influencerCashoutProfileData($claimCashoutId: String!, $ageScreenshot: FileInput, $genderScreenshot: FileInput, $locationScreenshot: FileInput, $age: [String!], $gender: [String!], $location: [String!], $ageBreakdown: JSON, $genderBreakdown: JSON, $locationBreakdown: JSON, $screenshots: [FileInput!]) {
  influencerCashoutProfileData(input: {claimCashoutId: $claimCashoutId, ageScreenshot: $ageScreenshot, genderScreenshot: $genderScreenshot, locationScreenshot: $locationScreenshot, age: $age, gender: $gender, location: $location, ageBreakdown: $ageBreakdown, genderBreakdown: $genderBreakdown, locationBreakdown: $locationBreakdown, screenshots: $screenshots}) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;
export type InfluencerCashoutProfileDataMutationFn = ApolloReactCommon.MutationFunction<InfluencerCashoutProfileDataMutation, InfluencerCashoutProfileDataMutationVariables>;

/**
 * __useInfluencerCashoutProfileDataMutation__
 *
 * To run a mutation, you first call `useInfluencerCashoutProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInfluencerCashoutProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [influencerCashoutProfileDataMutation, { data, loading, error }] = useInfluencerCashoutProfileDataMutation({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *      ageScreenshot: // value for 'ageScreenshot'
 *      genderScreenshot: // value for 'genderScreenshot'
 *      locationScreenshot: // value for 'locationScreenshot'
 *      age: // value for 'age'
 *      gender: // value for 'gender'
 *      location: // value for 'location'
 *      ageBreakdown: // value for 'ageBreakdown'
 *      genderBreakdown: // value for 'genderBreakdown'
 *      locationBreakdown: // value for 'locationBreakdown'
 *      screenshots: // value for 'screenshots'
 *   },
 * });
 */
export function useInfluencerCashoutProfileDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InfluencerCashoutProfileDataMutation, InfluencerCashoutProfileDataMutationVariables>) {
        return ApolloReactHooks.useMutation<InfluencerCashoutProfileDataMutation, InfluencerCashoutProfileDataMutationVariables>(InfluencerCashoutProfileDataDocument, baseOptions);
      }
export type InfluencerCashoutProfileDataMutationHookResult = ReturnType<typeof useInfluencerCashoutProfileDataMutation>;
export type InfluencerCashoutProfileDataMutationResult = ApolloReactCommon.MutationResult<InfluencerCashoutProfileDataMutation>;
export type InfluencerCashoutProfileDataMutationOptions = ApolloReactCommon.BaseMutationOptions<InfluencerCashoutProfileDataMutation, InfluencerCashoutProfileDataMutationVariables>;
export const InfluencerCashoutPaymentDetailDocument = gql`
    mutation influencerCashoutPaymentDetail($claimCashoutId: String!, $paypalEmail: String, $bankAccount: BankAccountInput, $invoiceScreenshots: [FileInput!]) {
  influencerCashoutPaymentDetail(input: {claimCashoutId: $claimCashoutId, paypalEmail: $paypalEmail, bankAccount: $bankAccount, invoiceScreenshots: $invoiceScreenshots}) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;
export type InfluencerCashoutPaymentDetailMutationFn = ApolloReactCommon.MutationFunction<InfluencerCashoutPaymentDetailMutation, InfluencerCashoutPaymentDetailMutationVariables>;

/**
 * __useInfluencerCashoutPaymentDetailMutation__
 *
 * To run a mutation, you first call `useInfluencerCashoutPaymentDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInfluencerCashoutPaymentDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [influencerCashoutPaymentDetailMutation, { data, loading, error }] = useInfluencerCashoutPaymentDetailMutation({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *      paypalEmail: // value for 'paypalEmail'
 *      bankAccount: // value for 'bankAccount'
 *      invoiceScreenshots: // value for 'invoiceScreenshots'
 *   },
 * });
 */
export function useInfluencerCashoutPaymentDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InfluencerCashoutPaymentDetailMutation, InfluencerCashoutPaymentDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<InfluencerCashoutPaymentDetailMutation, InfluencerCashoutPaymentDetailMutationVariables>(InfluencerCashoutPaymentDetailDocument, baseOptions);
      }
export type InfluencerCashoutPaymentDetailMutationHookResult = ReturnType<typeof useInfluencerCashoutPaymentDetailMutation>;
export type InfluencerCashoutPaymentDetailMutationResult = ApolloReactCommon.MutationResult<InfluencerCashoutPaymentDetailMutation>;
export type InfluencerCashoutPaymentDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<InfluencerCashoutPaymentDetailMutation, InfluencerCashoutPaymentDetailMutationVariables>;
export const RegisterForBetaDocument = gql`
    mutation registerForBeta($claimCashoutId: String!) {
  registerForBeta(input: {claimCashoutId: $claimCashoutId}) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;
export type RegisterForBetaMutationFn = ApolloReactCommon.MutationFunction<RegisterForBetaMutation, RegisterForBetaMutationVariables>;

/**
 * __useRegisterForBetaMutation__
 *
 * To run a mutation, you first call `useRegisterForBetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterForBetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerForBetaMutation, { data, loading, error }] = useRegisterForBetaMutation({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *   },
 * });
 */
export function useRegisterForBetaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterForBetaMutation, RegisterForBetaMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterForBetaMutation, RegisterForBetaMutationVariables>(RegisterForBetaDocument, baseOptions);
      }
export type RegisterForBetaMutationHookResult = ReturnType<typeof useRegisterForBetaMutation>;
export type RegisterForBetaMutationResult = ApolloReactCommon.MutationResult<RegisterForBetaMutation>;
export type RegisterForBetaMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterForBetaMutation, RegisterForBetaMutationVariables>;
export const GetYoutubeAnalyticsDataByAccessTokenDocument = gql`
    query getYoutubeAnalyticsDataByAccessToken($accessToken: String!) {
  getYoutubeAnalyticsDataByAccessToken(accessToken: $accessToken) {
    ageBreakdown
    genderBreakdown
    locationBreakdown
  }
}
    `;

/**
 * __useGetYoutubeAnalyticsDataByAccessTokenQuery__
 *
 * To run a query within a React component, call `useGetYoutubeAnalyticsDataByAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoutubeAnalyticsDataByAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoutubeAnalyticsDataByAccessTokenQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useGetYoutubeAnalyticsDataByAccessTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetYoutubeAnalyticsDataByAccessTokenQuery, GetYoutubeAnalyticsDataByAccessTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<GetYoutubeAnalyticsDataByAccessTokenQuery, GetYoutubeAnalyticsDataByAccessTokenQueryVariables>(GetYoutubeAnalyticsDataByAccessTokenDocument, baseOptions);
      }
export function useGetYoutubeAnalyticsDataByAccessTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetYoutubeAnalyticsDataByAccessTokenQuery, GetYoutubeAnalyticsDataByAccessTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetYoutubeAnalyticsDataByAccessTokenQuery, GetYoutubeAnalyticsDataByAccessTokenQueryVariables>(GetYoutubeAnalyticsDataByAccessTokenDocument, baseOptions);
        }
export type GetYoutubeAnalyticsDataByAccessTokenQueryHookResult = ReturnType<typeof useGetYoutubeAnalyticsDataByAccessTokenQuery>;
export type GetYoutubeAnalyticsDataByAccessTokenLazyQueryHookResult = ReturnType<typeof useGetYoutubeAnalyticsDataByAccessTokenLazyQuery>;
export type GetYoutubeAnalyticsDataByAccessTokenQueryResult = ApolloReactCommon.QueryResult<GetYoutubeAnalyticsDataByAccessTokenQuery, GetYoutubeAnalyticsDataByAccessTokenQueryVariables>;
export const SignInitialContractDocument = gql`
    mutation signInitialContract($claimCashoutId: String!) {
  signInitialContract(claimCashoutId: $claimCashoutId) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;
export type SignInitialContractMutationFn = ApolloReactCommon.MutationFunction<SignInitialContractMutation, SignInitialContractMutationVariables>;

/**
 * __useSignInitialContractMutation__
 *
 * To run a mutation, you first call `useSignInitialContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInitialContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInitialContractMutation, { data, loading, error }] = useSignInitialContractMutation({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *   },
 * });
 */
export function useSignInitialContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignInitialContractMutation, SignInitialContractMutationVariables>) {
        return ApolloReactHooks.useMutation<SignInitialContractMutation, SignInitialContractMutationVariables>(SignInitialContractDocument, baseOptions);
      }
export type SignInitialContractMutationHookResult = ReturnType<typeof useSignInitialContractMutation>;
export type SignInitialContractMutationResult = ApolloReactCommon.MutationResult<SignInitialContractMutation>;
export type SignInitialContractMutationOptions = ApolloReactCommon.BaseMutationOptions<SignInitialContractMutation, SignInitialContractMutationVariables>;
export const GetSubmissionInvitationDocument = gql`
    query getSubmissionInvitation($claimCashoutId: String, $invitationId: String) {
  getSubmissionInvitation(claimCashoutId: $claimCashoutId, invitationId: $invitationId) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;

/**
 * __useGetSubmissionInvitationQuery__
 *
 * To run a query within a React component, call `useGetSubmissionInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionInvitationQuery({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useGetSubmissionInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubmissionInvitationQuery, GetSubmissionInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubmissionInvitationQuery, GetSubmissionInvitationQueryVariables>(GetSubmissionInvitationDocument, baseOptions);
      }
export function useGetSubmissionInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubmissionInvitationQuery, GetSubmissionInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubmissionInvitationQuery, GetSubmissionInvitationQueryVariables>(GetSubmissionInvitationDocument, baseOptions);
        }
export type GetSubmissionInvitationQueryHookResult = ReturnType<typeof useGetSubmissionInvitationQuery>;
export type GetSubmissionInvitationLazyQueryHookResult = ReturnType<typeof useGetSubmissionInvitationLazyQuery>;
export type GetSubmissionInvitationQueryResult = ApolloReactCommon.QueryResult<GetSubmissionInvitationQuery, GetSubmissionInvitationQueryVariables>;
export const UploadNewDraftDocument = gql`
    mutation uploadNewDraft($invitationId: String, $claimCashoutId: String, $allVideos: [String], $allMedia: [String], $caption: String, $description: String, $draftType: String) {
  uploadNewDraft(input: {invitationId: $invitationId, claimCashoutId: $claimCashoutId, allVideos: $allVideos, allMedia: $allMedia, caption: $caption, description: $description, draftType: $draftType}) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;
export type UploadNewDraftMutationFn = ApolloReactCommon.MutationFunction<UploadNewDraftMutation, UploadNewDraftMutationVariables>;

/**
 * __useUploadNewDraftMutation__
 *
 * To run a mutation, you first call `useUploadNewDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNewDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNewDraftMutation, { data, loading, error }] = useUploadNewDraftMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      claimCashoutId: // value for 'claimCashoutId'
 *      allVideos: // value for 'allVideos'
 *      allMedia: // value for 'allMedia'
 *      caption: // value for 'caption'
 *      description: // value for 'description'
 *      draftType: // value for 'draftType'
 *   },
 * });
 */
export function useUploadNewDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadNewDraftMutation, UploadNewDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadNewDraftMutation, UploadNewDraftMutationVariables>(UploadNewDraftDocument, baseOptions);
      }
export type UploadNewDraftMutationHookResult = ReturnType<typeof useUploadNewDraftMutation>;
export type UploadNewDraftMutationResult = ApolloReactCommon.MutationResult<UploadNewDraftMutation>;
export type UploadNewDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadNewDraftMutation, UploadNewDraftMutationVariables>;
export const GetGeneralSignedUrlDocument = gql`
    mutation getGeneralSignedURL($folder: String, $fileName: String!, $fileType: String!) {
  getGeneralSignedURL(input: {fileFolder: $folder, fileName: $fileName, fileType: $fileType}) {
    url
    uploadUrl
    previewUrl
  }
}
    `;
export type GetGeneralSignedUrlMutationFn = ApolloReactCommon.MutationFunction<GetGeneralSignedUrlMutation, GetGeneralSignedUrlMutationVariables>;

/**
 * __useGetGeneralSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetGeneralSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getGeneralSignedUrlMutation, { data, loading, error }] = useGetGeneralSignedUrlMutation({
 *   variables: {
 *      folder: // value for 'folder'
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useGetGeneralSignedUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetGeneralSignedUrlMutation, GetGeneralSignedUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<GetGeneralSignedUrlMutation, GetGeneralSignedUrlMutationVariables>(GetGeneralSignedUrlDocument, baseOptions);
      }
export type GetGeneralSignedUrlMutationHookResult = ReturnType<typeof useGetGeneralSignedUrlMutation>;
export type GetGeneralSignedUrlMutationResult = ApolloReactCommon.MutationResult<GetGeneralSignedUrlMutation>;
export type GetGeneralSignedUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<GetGeneralSignedUrlMutation, GetGeneralSignedUrlMutationVariables>;
export const GetTrackingInvitationByCashoutIdDocument = gql`
    query GetTrackingInvitationByCashoutId($cashoutId: String!) {
  getInfluencerCashoutInvitation(claimCashoutId: $cashoutId) {
    id
    installs
    clicks
    campaign {
      id
      title
      cpiPayoutPennies
    }
    account {
      id
      username
    }
  }
}
    `;

/**
 * __useGetTrackingInvitationByCashoutIdQuery__
 *
 * To run a query within a React component, call `useGetTrackingInvitationByCashoutIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrackingInvitationByCashoutIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrackingInvitationByCashoutIdQuery({
 *   variables: {
 *      cashoutId: // value for 'cashoutId'
 *   },
 * });
 */
export function useGetTrackingInvitationByCashoutIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTrackingInvitationByCashoutIdQuery, GetTrackingInvitationByCashoutIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTrackingInvitationByCashoutIdQuery, GetTrackingInvitationByCashoutIdQueryVariables>(GetTrackingInvitationByCashoutIdDocument, baseOptions);
      }
export function useGetTrackingInvitationByCashoutIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTrackingInvitationByCashoutIdQuery, GetTrackingInvitationByCashoutIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTrackingInvitationByCashoutIdQuery, GetTrackingInvitationByCashoutIdQueryVariables>(GetTrackingInvitationByCashoutIdDocument, baseOptions);
        }
export type GetTrackingInvitationByCashoutIdQueryHookResult = ReturnType<typeof useGetTrackingInvitationByCashoutIdQuery>;
export type GetTrackingInvitationByCashoutIdLazyQueryHookResult = ReturnType<typeof useGetTrackingInvitationByCashoutIdLazyQuery>;
export type GetTrackingInvitationByCashoutIdQueryResult = ApolloReactCommon.QueryResult<GetTrackingInvitationByCashoutIdQuery, GetTrackingInvitationByCashoutIdQueryVariables>;
export const GetWelcomeProfileAccountDocument = gql`
    query getWelcomeProfileAccount($claimAccountId: String!) {
  getWelcomeProfileAccount(claimAccountId: $claimAccountId) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;

/**
 * __useGetWelcomeProfileAccountQuery__
 *
 * To run a query within a React component, call `useGetWelcomeProfileAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWelcomeProfileAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWelcomeProfileAccountQuery({
 *   variables: {
 *      claimAccountId: // value for 'claimAccountId'
 *   },
 * });
 */
export function useGetWelcomeProfileAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWelcomeProfileAccountQuery, GetWelcomeProfileAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWelcomeProfileAccountQuery, GetWelcomeProfileAccountQueryVariables>(GetWelcomeProfileAccountDocument, baseOptions);
      }
export function useGetWelcomeProfileAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWelcomeProfileAccountQuery, GetWelcomeProfileAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWelcomeProfileAccountQuery, GetWelcomeProfileAccountQueryVariables>(GetWelcomeProfileAccountDocument, baseOptions);
        }
export type GetWelcomeProfileAccountQueryHookResult = ReturnType<typeof useGetWelcomeProfileAccountQuery>;
export type GetWelcomeProfileAccountLazyQueryHookResult = ReturnType<typeof useGetWelcomeProfileAccountLazyQuery>;
export type GetWelcomeProfileAccountQueryResult = ApolloReactCommon.QueryResult<GetWelcomeProfileAccountQuery, GetWelcomeProfileAccountQueryVariables>;
export const UpdateWelcomePersonalDetailDocument = gql`
    mutation updateWelcomePersonalDetail($claimAccountId: String!, $age: [String!], $gender: [String!], $location: [String!]) {
  updateWelcomePersonalDetail(input: {claimAccountId: $claimAccountId, age: $age, gender: $gender, location: $location}) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;
export type UpdateWelcomePersonalDetailMutationFn = ApolloReactCommon.MutationFunction<UpdateWelcomePersonalDetailMutation, UpdateWelcomePersonalDetailMutationVariables>;

/**
 * __useUpdateWelcomePersonalDetailMutation__
 *
 * To run a mutation, you first call `useUpdateWelcomePersonalDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWelcomePersonalDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWelcomePersonalDetailMutation, { data, loading, error }] = useUpdateWelcomePersonalDetailMutation({
 *   variables: {
 *      claimAccountId: // value for 'claimAccountId'
 *      age: // value for 'age'
 *      gender: // value for 'gender'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateWelcomePersonalDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWelcomePersonalDetailMutation, UpdateWelcomePersonalDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWelcomePersonalDetailMutation, UpdateWelcomePersonalDetailMutationVariables>(UpdateWelcomePersonalDetailDocument, baseOptions);
      }
export type UpdateWelcomePersonalDetailMutationHookResult = ReturnType<typeof useUpdateWelcomePersonalDetailMutation>;
export type UpdateWelcomePersonalDetailMutationResult = ApolloReactCommon.MutationResult<UpdateWelcomePersonalDetailMutation>;
export type UpdateWelcomePersonalDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWelcomePersonalDetailMutation, UpdateWelcomePersonalDetailMutationVariables>;
export const UpdateWelcomeProfileDetailDocument = gql`
    mutation updateWelcomeProfileDetail($claimAccountId: String!, $screenshot: FileInput, $ageBreakdown: JSON, $genderBreakdown: JSON, $locationBreakdown: JSON) {
  updateWelcomeProfileDetail(input: {claimAccountId: $claimAccountId, screenshot: $screenshot, ageBreakdown: $ageBreakdown, genderBreakdown: $genderBreakdown, locationBreakdown: $locationBreakdown}) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;
export type UpdateWelcomeProfileDetailMutationFn = ApolloReactCommon.MutationFunction<UpdateWelcomeProfileDetailMutation, UpdateWelcomeProfileDetailMutationVariables>;

/**
 * __useUpdateWelcomeProfileDetailMutation__
 *
 * To run a mutation, you first call `useUpdateWelcomeProfileDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWelcomeProfileDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWelcomeProfileDetailMutation, { data, loading, error }] = useUpdateWelcomeProfileDetailMutation({
 *   variables: {
 *      claimAccountId: // value for 'claimAccountId'
 *      screenshot: // value for 'screenshot'
 *      ageBreakdown: // value for 'ageBreakdown'
 *      genderBreakdown: // value for 'genderBreakdown'
 *      locationBreakdown: // value for 'locationBreakdown'
 *   },
 * });
 */
export function useUpdateWelcomeProfileDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWelcomeProfileDetailMutation, UpdateWelcomeProfileDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWelcomeProfileDetailMutation, UpdateWelcomeProfileDetailMutationVariables>(UpdateWelcomeProfileDetailDocument, baseOptions);
      }
export type UpdateWelcomeProfileDetailMutationHookResult = ReturnType<typeof useUpdateWelcomeProfileDetailMutation>;
export type UpdateWelcomeProfileDetailMutationResult = ApolloReactCommon.MutationResult<UpdateWelcomeProfileDetailMutation>;
export type UpdateWelcomeProfileDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWelcomeProfileDetailMutation, UpdateWelcomeProfileDetailMutationVariables>;
export const UpdateWelcomeRegisterForBetaDocument = gql`
    mutation updateWelcomeRegisterForBeta($claimAccountId: String!) {
  updateWelcomeRegisterForBeta(claimAccountId: $claimAccountId) {
    ...AccountFragement
  }
}
    ${AccountFragementFragmentDoc}`;
export type UpdateWelcomeRegisterForBetaMutationFn = ApolloReactCommon.MutationFunction<UpdateWelcomeRegisterForBetaMutation, UpdateWelcomeRegisterForBetaMutationVariables>;

/**
 * __useUpdateWelcomeRegisterForBetaMutation__
 *
 * To run a mutation, you first call `useUpdateWelcomeRegisterForBetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWelcomeRegisterForBetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWelcomeRegisterForBetaMutation, { data, loading, error }] = useUpdateWelcomeRegisterForBetaMutation({
 *   variables: {
 *      claimAccountId: // value for 'claimAccountId'
 *   },
 * });
 */
export function useUpdateWelcomeRegisterForBetaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWelcomeRegisterForBetaMutation, UpdateWelcomeRegisterForBetaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWelcomeRegisterForBetaMutation, UpdateWelcomeRegisterForBetaMutationVariables>(UpdateWelcomeRegisterForBetaDocument, baseOptions);
      }
export type UpdateWelcomeRegisterForBetaMutationHookResult = ReturnType<typeof useUpdateWelcomeRegisterForBetaMutation>;
export type UpdateWelcomeRegisterForBetaMutationResult = ApolloReactCommon.MutationResult<UpdateWelcomeRegisterForBetaMutation>;
export type UpdateWelcomeRegisterForBetaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWelcomeRegisterForBetaMutation, UpdateWelcomeRegisterForBetaMutationVariables>;
export const UpdateDraftFeedbackDocument = gql`
    mutation updateDraftFeedback($draftId: String!, $status: String, $brandFeedback: [String], $adminFeedback: [String]) {
  updateDraftFeedback(input: {draftId: $draftId, status: $status, brandFeedback: $brandFeedback, adminFeedback: $adminFeedback}) {
    ...DraftFragment
  }
}
    ${DraftFragmentFragmentDoc}`;
export type UpdateDraftFeedbackMutationFn = ApolloReactCommon.MutationFunction<UpdateDraftFeedbackMutation, UpdateDraftFeedbackMutationVariables>;

/**
 * __useUpdateDraftFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateDraftFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftFeedbackMutation, { data, loading, error }] = useUpdateDraftFeedbackMutation({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      status: // value for 'status'
 *      brandFeedback: // value for 'brandFeedback'
 *      adminFeedback: // value for 'adminFeedback'
 *   },
 * });
 */
export function useUpdateDraftFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDraftFeedbackMutation, UpdateDraftFeedbackMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDraftFeedbackMutation, UpdateDraftFeedbackMutationVariables>(UpdateDraftFeedbackDocument, baseOptions);
      }
export type UpdateDraftFeedbackMutationHookResult = ReturnType<typeof useUpdateDraftFeedbackMutation>;
export type UpdateDraftFeedbackMutationResult = ApolloReactCommon.MutationResult<UpdateDraftFeedbackMutation>;
export type UpdateDraftFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDraftFeedbackMutation, UpdateDraftFeedbackMutationVariables>;
export const GetInfluencerHubLinkStatusDocument = gql`
    query getInfluencerHubLinkStatus($claimCashoutId: String!) {
  getInfluencerHubLinkStatus(claimCashoutId: $claimCashoutId) {
    initialContact {
      ...InivtiationLinkHubStatus
    }
    initialContactYouTube {
      ...InivtiationLinkHubStatus
    }
    contract {
      ...InivtiationLinkHubStatusFragment
    }
    inviteFeedback {
      ...InivtiationLinkHubStatusFragment
    }
    inviteFeedbackYouTube {
      ...InivtiationLinkHubStatusFragment
    }
    postDetail {
      ...InivtiationLinkHubStatusFragment
    }
    postDetailYouTube {
      ...InivtiationLinkHubStatusFragment
    }
    paymentDetail {
      ...InivtiationLinkHubStatusFragment
    }
  }
}
    ${InivtiationLinkHubStatusFragmentDoc}
${InivtiationLinkHubStatusFragmentFragmentDoc}`;

/**
 * __useGetInfluencerHubLinkStatusQuery__
 *
 * To run a query within a React component, call `useGetInfluencerHubLinkStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfluencerHubLinkStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfluencerHubLinkStatusQuery({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *   },
 * });
 */
export function useGetInfluencerHubLinkStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInfluencerHubLinkStatusQuery, GetInfluencerHubLinkStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInfluencerHubLinkStatusQuery, GetInfluencerHubLinkStatusQueryVariables>(GetInfluencerHubLinkStatusDocument, baseOptions);
      }
export function useGetInfluencerHubLinkStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInfluencerHubLinkStatusQuery, GetInfluencerHubLinkStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInfluencerHubLinkStatusQuery, GetInfluencerHubLinkStatusQueryVariables>(GetInfluencerHubLinkStatusDocument, baseOptions);
        }
export type GetInfluencerHubLinkStatusQueryHookResult = ReturnType<typeof useGetInfluencerHubLinkStatusQuery>;
export type GetInfluencerHubLinkStatusLazyQueryHookResult = ReturnType<typeof useGetInfluencerHubLinkStatusLazyQuery>;
export type GetInfluencerHubLinkStatusQueryResult = ApolloReactCommon.QueryResult<GetInfluencerHubLinkStatusQuery, GetInfluencerHubLinkStatusQueryVariables>;
export const UpdateInfluencerHubLinkDocument = gql`
    mutation updateInfluencerHubLink($claimCashoutId: String!, $initialContact: Boolean!, $initialContactYouTube: Boolean!, $contract: Boolean!, $inviteFeedback: Boolean!, $inviteFeedbackYouTube: Boolean!, $postDetail: Boolean!, $postDetailYouTube: Boolean!, $paymentDetail: Boolean!) {
  updateInfluencerHubLink(claimCashoutId: $claimCashoutId, linkHub: {initialContact: $initialContact, initialContactYouTube: $initialContactYouTube, contract: $contract, inviteFeedback: $inviteFeedback, inviteFeedbackYouTube: $inviteFeedbackYouTube, postDetail: $postDetail, postDetailYouTube: $postDetailYouTube, paymentDetail: $paymentDetail}) {
    initialContact
    initialContactYouTube
    contract
    inviteFeedback
    inviteFeedbackYouTube
    postDetail
    postDetailYouTube
    paymentDetail
  }
}
    `;
export type UpdateInfluencerHubLinkMutationFn = ApolloReactCommon.MutationFunction<UpdateInfluencerHubLinkMutation, UpdateInfluencerHubLinkMutationVariables>;

/**
 * __useUpdateInfluencerHubLinkMutation__
 *
 * To run a mutation, you first call `useUpdateInfluencerHubLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfluencerHubLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfluencerHubLinkMutation, { data, loading, error }] = useUpdateInfluencerHubLinkMutation({
 *   variables: {
 *      claimCashoutId: // value for 'claimCashoutId'
 *      initialContact: // value for 'initialContact'
 *      initialContactYouTube: // value for 'initialContactYouTube'
 *      contract: // value for 'contract'
 *      inviteFeedback: // value for 'inviteFeedback'
 *      inviteFeedbackYouTube: // value for 'inviteFeedbackYouTube'
 *      postDetail: // value for 'postDetail'
 *      postDetailYouTube: // value for 'postDetailYouTube'
 *      paymentDetail: // value for 'paymentDetail'
 *   },
 * });
 */
export function useUpdateInfluencerHubLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInfluencerHubLinkMutation, UpdateInfluencerHubLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInfluencerHubLinkMutation, UpdateInfluencerHubLinkMutationVariables>(UpdateInfluencerHubLinkDocument, baseOptions);
      }
export type UpdateInfluencerHubLinkMutationHookResult = ReturnType<typeof useUpdateInfluencerHubLinkMutation>;
export type UpdateInfluencerHubLinkMutationResult = ApolloReactCommon.MutationResult<UpdateInfluencerHubLinkMutation>;
export type UpdateInfluencerHubLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInfluencerHubLinkMutation, UpdateInfluencerHubLinkMutationVariables>;
export const UpdateAccountBreakdownsByInvitationIdDocument = gql`
    mutation updateAccountBreakdownsByInvitationId($invitationId: Int!, $screenshots: [ScreenshotFileInput!], $ageBreakdown: JSON, $genderBreakdown: JSON, $locationBreakdown: JSON, $tags: [Int!] = []) {
  updateAccountBreakdownsByInvitationId(invitationId: $invitationId, screenshots: $screenshots, ageBreakdown: $ageBreakdown, genderBreakdown: $genderBreakdown, locationBreakdown: $locationBreakdown, tags: $tags) {
    ...InvitationFragment
  }
}
    ${InvitationFragmentFragmentDoc}`;
export type UpdateAccountBreakdownsByInvitationIdMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountBreakdownsByInvitationIdMutation, UpdateAccountBreakdownsByInvitationIdMutationVariables>;

/**
 * __useUpdateAccountBreakdownsByInvitationIdMutation__
 *
 * To run a mutation, you first call `useUpdateAccountBreakdownsByInvitationIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountBreakdownsByInvitationIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountBreakdownsByInvitationIdMutation, { data, loading, error }] = useUpdateAccountBreakdownsByInvitationIdMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      screenshots: // value for 'screenshots'
 *      ageBreakdown: // value for 'ageBreakdown'
 *      genderBreakdown: // value for 'genderBreakdown'
 *      locationBreakdown: // value for 'locationBreakdown'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateAccountBreakdownsByInvitationIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountBreakdownsByInvitationIdMutation, UpdateAccountBreakdownsByInvitationIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountBreakdownsByInvitationIdMutation, UpdateAccountBreakdownsByInvitationIdMutationVariables>(UpdateAccountBreakdownsByInvitationIdDocument, baseOptions);
      }
export type UpdateAccountBreakdownsByInvitationIdMutationHookResult = ReturnType<typeof useUpdateAccountBreakdownsByInvitationIdMutation>;
export type UpdateAccountBreakdownsByInvitationIdMutationResult = ApolloReactCommon.MutationResult<UpdateAccountBreakdownsByInvitationIdMutation>;
export type UpdateAccountBreakdownsByInvitationIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountBreakdownsByInvitationIdMutation, UpdateAccountBreakdownsByInvitationIdMutationVariables>;
export const CreateNewTagDocument = gql`
    mutation createNewTag($tagName: String!) {
  createNewTag(tagName: $tagName) {
    id
    name
    isDefined
  }
}
    `;
export type CreateNewTagMutationFn = ApolloReactCommon.MutationFunction<CreateNewTagMutation, CreateNewTagMutationVariables>;

/**
 * __useCreateNewTagMutation__
 *
 * To run a mutation, you first call `useCreateNewTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewTagMutation, { data, loading, error }] = useCreateNewTagMutation({
 *   variables: {
 *      tagName: // value for 'tagName'
 *   },
 * });
 */
export function useCreateNewTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewTagMutation, CreateNewTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewTagMutation, CreateNewTagMutationVariables>(CreateNewTagDocument, baseOptions);
      }
export type CreateNewTagMutationHookResult = ReturnType<typeof useCreateNewTagMutation>;
export type CreateNewTagMutationResult = ApolloReactCommon.MutationResult<CreateNewTagMutation>;
export type CreateNewTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewTagMutation, CreateNewTagMutationVariables>;
export const UpdateAwaitingDraftDocument = gql`
    mutation updateAwaitingDraft($draftId: String, $status: String, $allVideos: [String], $allMedia: [String], $caption: String, $description: String) {
  updateAwaitingDraft(input: {draftId: $draftId, status: $status, allVideos: $allVideos, allMedia: $allMedia, caption: $caption, description: $description}) {
    ...DraftFragment
  }
}
    ${DraftFragmentFragmentDoc}`;
export type UpdateAwaitingDraftMutationFn = ApolloReactCommon.MutationFunction<UpdateAwaitingDraftMutation, UpdateAwaitingDraftMutationVariables>;

/**
 * __useUpdateAwaitingDraftMutation__
 *
 * To run a mutation, you first call `useUpdateAwaitingDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAwaitingDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAwaitingDraftMutation, { data, loading, error }] = useUpdateAwaitingDraftMutation({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      status: // value for 'status'
 *      allVideos: // value for 'allVideos'
 *      allMedia: // value for 'allMedia'
 *      caption: // value for 'caption'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateAwaitingDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAwaitingDraftMutation, UpdateAwaitingDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAwaitingDraftMutation, UpdateAwaitingDraftMutationVariables>(UpdateAwaitingDraftDocument, baseOptions);
      }
export type UpdateAwaitingDraftMutationHookResult = ReturnType<typeof useUpdateAwaitingDraftMutation>;
export type UpdateAwaitingDraftMutationResult = ApolloReactCommon.MutationResult<UpdateAwaitingDraftMutation>;
export type UpdateAwaitingDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAwaitingDraftMutation, UpdateAwaitingDraftMutationVariables>;
export const UdpateInvitationTitleDocument = gql`
    mutation udpateInvitationTitle($title: String!, $invitationId: Int!) {
  udpateInvitationTitle(invitationId: $invitationId, title: $title) {
    id
  }
}
    `;
export type UdpateInvitationTitleMutationFn = ApolloReactCommon.MutationFunction<UdpateInvitationTitleMutation, UdpateInvitationTitleMutationVariables>;

/**
 * __useUdpateInvitationTitleMutation__
 *
 * To run a mutation, you first call `useUdpateInvitationTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUdpateInvitationTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [udpateInvitationTitleMutation, { data, loading, error }] = useUdpateInvitationTitleMutation({
 *   variables: {
 *      title: // value for 'title'
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useUdpateInvitationTitleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UdpateInvitationTitleMutation, UdpateInvitationTitleMutationVariables>) {
        return ApolloReactHooks.useMutation<UdpateInvitationTitleMutation, UdpateInvitationTitleMutationVariables>(UdpateInvitationTitleDocument, baseOptions);
      }
export type UdpateInvitationTitleMutationHookResult = ReturnType<typeof useUdpateInvitationTitleMutation>;
export type UdpateInvitationTitleMutationResult = ApolloReactCommon.MutationResult<UdpateInvitationTitleMutation>;
export type UdpateInvitationTitleMutationOptions = ApolloReactCommon.BaseMutationOptions<UdpateInvitationTitleMutation, UdpateInvitationTitleMutationVariables>;
export const DuplicateInvitationDocument = gql`
    mutation duplicateInvitation($invitationId: Int!) {
  duplicateInvitation(invitationId: $invitationId) {
    id
  }
}
    `;
export type DuplicateInvitationMutationFn = ApolloReactCommon.MutationFunction<DuplicateInvitationMutation, DuplicateInvitationMutationVariables>;

/**
 * __useDuplicateInvitationMutation__
 *
 * To run a mutation, you first call `useDuplicateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateInvitationMutation, { data, loading, error }] = useDuplicateInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useDuplicateInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateInvitationMutation, DuplicateInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateInvitationMutation, DuplicateInvitationMutationVariables>(DuplicateInvitationDocument, baseOptions);
      }
export type DuplicateInvitationMutationHookResult = ReturnType<typeof useDuplicateInvitationMutation>;
export type DuplicateInvitationMutationResult = ApolloReactCommon.MutationResult<DuplicateInvitationMutation>;
export type DuplicateInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateInvitationMutation, DuplicateInvitationMutationVariables>;
export const GetPopularPostsByAccountIdDocument = gql`
    query getPopularPostsByAccountId($accountId: Float!) {
  getPopularPostsByAccountId(accountId: $accountId) {
    ...MostPostTypeFragment
  }
}
    ${MostPostTypeFragmentFragmentDoc}`;

/**
 * __useGetPopularPostsByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetPopularPostsByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopularPostsByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopularPostsByAccountIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetPopularPostsByAccountIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPopularPostsByAccountIdQuery, GetPopularPostsByAccountIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPopularPostsByAccountIdQuery, GetPopularPostsByAccountIdQueryVariables>(GetPopularPostsByAccountIdDocument, baseOptions);
      }
export function useGetPopularPostsByAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPopularPostsByAccountIdQuery, GetPopularPostsByAccountIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPopularPostsByAccountIdQuery, GetPopularPostsByAccountIdQueryVariables>(GetPopularPostsByAccountIdDocument, baseOptions);
        }
export type GetPopularPostsByAccountIdQueryHookResult = ReturnType<typeof useGetPopularPostsByAccountIdQuery>;
export type GetPopularPostsByAccountIdLazyQueryHookResult = ReturnType<typeof useGetPopularPostsByAccountIdLazyQuery>;
export type GetPopularPostsByAccountIdQueryResult = ApolloReactCommon.QueryResult<GetPopularPostsByAccountIdQuery, GetPopularPostsByAccountIdQueryVariables>;