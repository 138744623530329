import React, { Component } from "react";
import { connect } from "react-redux";
import { connectModal } from "redux-modal";
import { Modal, Button, Alert, Descriptions } from "antd";

export class ReviewModal extends Component {
  render() {
    const {
      show,
      handleHide,
      handleSubmit,
      isSubmitting,
      campaign,
    } = this.props;

    return (
      <Modal
        visible={show}
        onCancel={handleHide}
        title="Review Campaign Brief"
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            data-cy="campaign-review-brief-confirm-button"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Confirm & Submit
          </Button>,
        ]}
      >
        <Alert
          message="Please check over your brief, once submitted this can not be changed without dramatically delaying your campaign"
          type="info"
          showIcon
          style={{ marginBottom: 24 }}
        />

        <div style={{ marginBottom: 24 }}>
          <Descriptions bordered column={1}>
            <Descriptions.Item
              data-cy="campaign-review-about-brand"
              label="About Brand"
            >
              <div dangerouslySetInnerHTML={{ __html: campaign.about_brand }} />
            </Descriptions.Item>

            <Descriptions.Item data-cy="campaign-review-brief" label="Brief">
              <div dangerouslySetInnerHTML={{ __html: campaign.brief }} />
            </Descriptions.Item>

            <Descriptions.Item
              data-cy="campaign-review-briefdo"
              label="Content should include"
            >
              {campaign.brief_do.map((value, i) => (
                <p key={i} data-cy={`details-campaign-briefdo-.${i}`}>{`${i +
                  1}. ${value}`}</p>
              ))}
            </Descriptions.Item>

            <Descriptions.Item
              data-cy="campaign-review-brief-donts"
              label="What not to say"
            >
              {campaign.brief_dont.map((value, i) => (
                <p key={i} data-cy={`details-campaign-brief-dont-.${i}`}>{`${i +
                  1}. ${value}`}</p>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {};

export default connectModal({
  name: "ReviewModal",
  destroyOnHide: true,
})(connect(mapStateToProps, mapDispatchToProps)(ReviewModal));
