// @ts-nocheck
import React, { useEffect } from "react";
import { NotFound } from "../../components";
import { InfluencerHubTab } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetInfluencerCashoutInvitationQuery,
  useGetInfluencerHubLinkStatusQuery,
} from "src/generated/ApolloComponents";

type Props = {
  claimCashoutId: any;
};

export const InfluencerHubTabContainer: React.FC<Props> = ({
  claimCashoutId,
}) => {
  const {
    data,
    loading,
    error,
    refetch,
  } = useGetInfluencerCashoutInvitationQuery({
    variables: {
      claimCashoutId: claimCashoutId,
    },
  });

  const hublink = useGetInfluencerHubLinkStatusQuery({
    variables: {
      claimCashoutId: claimCashoutId,
    },
  });

  useEffect(() => {
    refetch({
      claimCashoutId: claimCashoutId,
    });

    hublink?.refetch({
      claimCashoutId: claimCashoutId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimCashoutId]);

  let cashoutData: any = data?.getInfluencerCashoutInvitation || {};

  const hubLinkData = hublink?.data?.getInfluencerHubLinkStatus;

  // useEffect(() => {
  //   if (cashoutData?.actualPaidPennies) {
  //     setStep(4);
  //   }
  // }, [cashoutData]);

  const tabDetails = {
    initialContact: hubLinkData?.initialContact?.checked || false,
    initialContactYouTube: hubLinkData?.initialContactYouTube?.checked || false,
    contract: hubLinkData?.contract?.checked || false,
    inviteFeedback: hubLinkData?.inviteFeedback?.checked || false,
    inviteFeedbackYouTube: hubLinkData?.inviteFeedbackYouTube?.checked || false,
    paymentDetail: hubLinkData?.paymentDetail?.checked || false,
    postDetail: hubLinkData?.postDetail?.checked || false,
    postDetailYouTube: hubLinkData?.postDetailYouTube?.checked || false,
  };

  cashoutData = { ...cashoutData, tabDetails };

  const globalErrors = useMapGraphQLErrorsMessages(error || hublink?.error);

  if (!loading && (error || hublink?.error || !cashoutData))
    return <NotFound />;

  return (
    <InfluencerHubTab
      globalErrors={globalErrors}
      loading={loading || hublink?.loading}
      cashoutData={cashoutData}
      claimCashoutId={claimCashoutId}
      hubLinkData={hubLinkData}
      refetchHublink={() => hublink?.refetch()}
    />
  );
};
