import React, { Component } from "react";
import FormField from "../FormField";
import { DollarOutlined, UserOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { Switch } from "formik-antd";

const InviteSwitch = styled(Switch)`
  &&& {
    background-color: #1890ff !important;
  }
`;

export default class InviteLimitSwitch extends Component {
  render() {
    return (
      <FormField name={this.props.name || "invite_limit_type"}>
        <InviteSwitch
          checkedChildren={<UserOutlined />}
          unCheckedChildren={<DollarOutlined />}
          defaultChecked
        />
      </FormField>
    );
  }
}
