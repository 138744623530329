// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import { TextInput } from "../TextInput";
import { TextInputProps } from "../TextInput/StyledInput";
import get from "lodash/get";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";
import NumberFormat from "react-number-format";

interface Props {
  format?: string;
  name?: string;
  size?: any;
  style?: any;
}

type InputProps = Omit<TextInputProps, "size">;

const FormikMaskInput: React.FC<Props & InputProps> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur, setFieldValue, setFieldTouched },
      }) => (
        <>
          <NumberFormat
            touched={get(touched, field?.name)}
            error={get(touched, field?.name) && get(errors, field?.name)}
            onBlur={e => {
              handleBlur(e);
            }}
            customInput={TextInput}
            onValueChange={value => {
              setFieldTouched(field?.name, true);
              setFieldValue(field?.name, value?.value);
            }}
            value={field.value}
            data-cy={`input-${field?.name?.replace(" ", "-")}`}
            {...props}
          />

          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={field?.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikMaskInput;
