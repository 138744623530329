// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  allTags: Array<any>;
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const CustomTags: React.FC<Props> = ({
  allTags,
  formProps,
  clearFilterKey,
}) => {
  let isVisible = false;

  if ((formProps?.values?.customTags || []).length > 0) {
    isVisible = true;
  }

  let tagData = [] as any;
  tagData =
    (allTags || [])
      .filter(v => !v.isDefined)
      .map(item => {
        let container = {} as any;
        container.value = item.id;
        container.label = item.name;
        return container;
      }) || [];

  return (
    <Field
      label="Custom Tags:"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("customTags", []);
              clearFilterKey("customTags");
            }}
            style={{ marginTop: "-1px", marginLeft: "10px" }}
          />
        )
      }
      id="customTags"
    >
      <FormikSelect
        name="customTags"
        size="small"
        borderRadius={3}
        borderColor="grey5"
        data={tagData}
        mode="multiple"
        local
      />
    </Field>
  );
};

export default CustomTags;
