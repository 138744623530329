import React, {useEffect} from "react";
import {GoogleLogin} from "react-google-login";
import {Button, Typography} from 'antd';
import styled from "styled-components";
import {useDispatch} from 'react-redux';
import auth from 'src/ducks/Auth';
import {gapi} from 'gapi-script';
import googleIcon from "src/assets/images/google.png";


const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const GoogleIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const GoogleSSO = ({mutation}) => {
    const dispatch = useDispatch()


    useEffect(() => {
        const start = () => {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: 'email',
            });
        }
        gapi.load('client:auth2', start);
    }, []);

    const handleGoogleResponse = async (response) => {
        try {
            const {data} = await mutation({
                variables: {
                    access_token: response.accessToken,
                    refresh_token: response.refresh_token || "no token"
                }
            });
            localStorage.setItem("authToken", data.getJwtTokenFromGoogleToken.token)
            dispatch(auth.fetchUser())
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={renderProps => (

                <Button size={"large"} onClick={renderProps.onClick} disabled={renderProps.disabled} block>
                    <StyledDiv>
                        <GoogleIcon src={googleIcon}/>
                        &nbsp;
                        <Typography size={14} color="blue">
                            Log in with Google
                        </Typography>
                    </StyledDiv>
                </Button>
            )}
            onSuccess={handleGoogleResponse}
        />
    )
}
