// @ts-nocheck
import { FormikInput } from "src/components/Forms/TextInput";
import { FormikMaskInput } from "src/components/Forms/MaskInput";

export const bankDetailFields = {
  GB: {
    fields: [
      {
        name: "fullName",
        component: FormikInput,
        props: {
          placeholder: "Name on account",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "sortCode",
        component: FormikMaskInput,
        props: {
          placeholder: "00-00-00",
          format: "##-##-##",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "accountNumber",
        component: FormikInput,
        props: {
          placeholder: "Account number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
    ],
    renderTitle: values => {
      return values.fullName;
    },
    renderSubtitle: values => {
      let subTitle = "";
      if (values?.sortCode) {
        const matchedArr = (values?.sortCode || "").toString().match(/.{1,2}/g);
        if (matchedArr && matchedArr.length > 0) {
          subTitle = matchedArr.join("-") + " ";
        }

        return `${subTitle} - ${values?.accountNumber}`;
      }
    },
  },

  US: {
    fields: [
      {
        name: "fullName",
        component: FormikInput,
        props: {
          placeholder: "Name on account",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "recipientAddress",
        component: FormikInput,
        props: {
          placeholder: "Recipient Address",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "routingNumber",
        component: FormikInput,
        props: {
          placeholder: "Routing number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "accountNumber",
        component: FormikInput,
        props: {
          placeholder: "Account number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "swift",
        component: FormikInput,
        props: {
          placeholder: "Swift",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
    ],
    renderTitle: values => {
      return values.fullName;
    },
    renderSubtitle: values => {
      return `${values?.routingNumber} - ${values?.accountNumber}`;
    },
  },

  EU: {
    fields: [
      {
        name: "fullName",
        component: FormikInput,
        props: {
          placeholder: "Name on account",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "recipientAddress",
        component: FormikInput,
        props: {
          placeholder: "Recipient Address",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "iban",
        component: FormikInput,
        props: {
          placeholder: "Iban",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "swift",
        component: FormikInput,
        props: {
          placeholder: "Swift",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
    ],
    renderTitle: values => {
      return values.fullName;
    },
    renderSubtitle: values => {
      if (values?.swift) {
        return `${values?.swift} - ${values?.iban}`;
      } else {
        return values?.iban;
      }
    },
  },

  NZ: {
    fields: [
      {
        name: "fullName",
        component: FormikInput,
        props: {
          placeholder: "Name on account",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "accountNumber",
        component: FormikInput,
        props: {
          placeholder: "Account number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
    ],
    renderTitle: values => {
      return values.fullName;
    },
    renderSubtitle: values => {
      return values.accountNumber;
    },
  },

  AU: {
    fields: [
      {
        name: "fullName",
        component: FormikInput,
        props: {
          placeholder: "Name on account",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "bsbCode",
        component: FormikInput,
        props: {
          placeholder: "BSB code",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "accountNumber",
        component: FormikInput,
        props: {
          placeholder: "Account number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
    ],
    renderTitle: values => {
      return values.fullName;
    },
    renderSubtitle: values => {
      return `${values?.bsbCode} - ${values?.accountNumber}`;
    },
  },

  PH: {
    fields: [
      {
        name: "fullName",
        component: FormikInput,
        props: {
          placeholder: "Name on account",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "recipientAddress",
        component: FormikInput,
        props: {
          placeholder: "Recipient Address",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "bankName",
        component: FormikInput,
        props: {
          placeholder: "BankName",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "accountNumber",
        component: FormikInput,
        props: {
          placeholder: "Account number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
    ],
    renderTitle: values => {
      return values.fullName;
    },
    renderSubtitle: values => {
      return `${(values?.bankName).substring(0, 4)}... - ${
        values?.accountNumber
      }`;
    },
  },

  CA: {
    fields: [
      {
        name: "transitNumber",
        component: FormikInput,
        props: {
          placeholder: "Transit number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "institutionNumber",
        component: FormikInput,
        props: {
          placeholder: "Institution number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "accountNumber",
        component: FormikInput,
        props: {
          placeholder: "Account number",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "swift",
        component: FormikInput,
        props: {
          placeholder: "Swift",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
      {
        name: "recipientAddress",
        component: FormikInput,
        props: {
          placeholder: "Recipient Address",
          size: "small",
          background: "background1",
          borderRadius: 1,
          borderColor: "grey5",
        },
      },
    ],
    renderTitle: values => {
      return values.institutionNumber;
    },
    renderSubtitle: values => {
      if (values?.swift) {
        return `${values?.swift} - ${values?.accountNumber}`;
      } else {
        return values?.accountNumber;
      }
    },
  },
};
