// @ts-nocheck
import * as React from "react";
import { Field } from "formik";
import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";
import { Radio } from "antd";

interface Props {
  name?: string;
  data?: Array<any>;
  style?: any;
}

const FormikRadio: React.FC<Props> = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur, setFieldTouched, setFieldValue },
      }) => (
        <>
          <Radio.Group
            {...props}
            {...field}
            onChange={e => {
              setFieldTouched(field.name, true);
              setFieldValue(field.name, e.target.value);
            }}
            data-cy={`input-radio-${field.name.replace(" ", "-")}`}
          >
            {(props.data || []).map((value, i) => (
              <Radio
                data-cy={`input-${field.name.replace(" ", "-")}-radio-${i}`}
                key={i}
                value={value.value}
                checked={field.value === value.value}
              >
                {value.label}
              </Radio>
            ))}
          </Radio.Group>

          <FieldErrorMessage
            errors={errors}
            touched={touched}
            mt={20}
            name={field.name}
          />
        </>
      )}
    </Field>
  );
};

export default FormikRadio;
