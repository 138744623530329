// @ts-nocheck
import React, { useEffect } from "react";
import { usePaymentDetails, paymentDetail } from "../../PaymentDetailsProvider";
import { FormikSelect } from "src/components/Forms/Select";

interface Props {
  campaignId: number;
  setAccountData: (value: Array<any>) => void;
  setAllAccountData: (value: Array<any>) => void;
  getCampaigns: Function;
  getAccountsByCampaignId: Function;
  setFieldValue: (key: string, value: any) => void;
}

const CampaignField: React.FC<Props> = ({
  campaignId,
  setAccountData,
  setAllAccountData,
  getCampaigns,
  getAccountsByCampaignId,
  setFieldValue,
}) => {
  const { actions } = usePaymentDetails();

  useEffect(() => {
    async function getInfluencers() {
      actions.setActualPaymentDetails({});
      actions.setExistingPaymentDetails({});
      actions.setSelectedInfluencer({} as paymentDetail);
      setFieldValue("accountId", "");
      setAllAccountData([]);
      setAccountData([]);

      let accountData = await getAccountsByCampaignId({
        campaignId: campaignId,
      });

      if (accountData?.data?.getAccountsByCampaignId?.length > 0) {
        const accountArray = accountData?.data?.getAccountsByCampaignId.map(
          item => {
            let container = {} as any;
            container.value = item.id;
            container.label = item.username;
            return container;
          }
        );
        setAllAccountData(accountData?.data?.getAccountsByCampaignId);
        setAccountData(accountArray);
      }
    }
    if (campaignId) {
      getInfluencers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  return (
    <FormikSelect
      name="campaignId"
      size="large"
      loadData={async value => {
        let campaign = await getCampaigns({
          campaignTitle: value,
        });
        if (campaign?.data?.getAllCampaigns?.length > 0) {
          return campaign.data.getAllCampaigns.filter((val) => (val.status).toLowerCase() !== "draft");
        } else {
          return [];
        }
      }}
      optionGenerator={value => ({
        value: value.id,
        label: value.title,
        disabled: value.status === 'complete',
      })}
      background="background"
      borderRadius="3px"
      borderColor="grey5"
    />
  );
};
export default CampaignField;
