// @ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { CampaignInstantReportContainer } from "./container";
import { GlobalErrorProps } from "src/components/Forms/ErrorMessage";
import { Spin } from "antd";
import Header from "./Header";
import Content from "./Content";
import background from "src/assets/images/reports/report_pattern.png";

const LoadingWrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled("div")`
  && {
    background-color: #031527;
    background-image: url(${background}) !important;
    background-repeat: repeat !important;
  }
`;

const BottomGradient = styled("div")`
  && {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(0deg, #031527, #031527a8, #03152700);
    z-index: 1000;
  }
`;

interface Props {
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  campaignData: any;
  campaignId: number;
}

const CampaignInstantReport: React.FC<Props> = ({
  loading,
  globalErrors,
  campaignData,
  campaignId,
}) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [activeSection, setActiveSection] = useState("");

  window.onscroll = function() {
    myFunction();
  };

  function myFunction() {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrolled = (winScroll / height) * 100;

    setScrollPercentage(Math.round(scrolled));
  }

  return (
    <>
      {loading ? (
        <LoadingWrapper>
          <Spin spinning={loading} />
        </LoadingWrapper>
      ) : (
        <Wrapper>
          <Header
            scrollPercentage={scrollPercentage}
            activeSection={activeSection}
            campaignData={campaignData}
            loading={loading}
          />
          <Content
            onChangeSection={value => setActiveSection(value)}
            campaignId={campaignId}
            campaignData={campaignData}
            loading={loading}
          />
          <BottomGradient />
        </Wrapper>
      )}
    </>
  );
};

export { CampaignInstantReport, CampaignInstantReportContainer };
