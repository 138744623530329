// @ts-nocheck
import React from "react";
import { PaypalFormContainer } from "./container";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { FormikInput } from "src/components/Forms/TextInput";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import AddButton from "../AddButton";
import ActionButtons from "../ActionButtons";
import DisplayDetails from "../DisplayDetails";

export interface PaypalFormValues {
  paypalEmail: string;
}

interface PaypalFormProps<Values = FormikValues> {
  showPaypalForm: boolean;
  setShowPaypalForm: (value: boolean) => void;
  paymentType: string;
  setPaymentType: (value: string) => void;
  initialValues: Values;
  isSubmitting: boolean;
  PaypalFormSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
}

export const PaypalFormWrapper = styled.div`
  width: 100%;
`;

const PaypalForm: React.FC<PaypalFormProps> = ({
  initialValues,
  isSubmitting,
  PaypalFormSchema,
  onSubmit,
  setShowPaypalForm,
  showPaypalForm,
  setPaymentType,
  paymentType,
}) => (
  <PaypalFormWrapper>
    <Loading spinning={false}>
      <Box
        background="#FFFFFF"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.157439)"
        borderRadius={2}
      >
        <Form
          initialValues={initialValues}
          schema={PaypalFormSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <>
                {!showPaypalForm && (
                  <>
                    {formProps?.values.paypalEmail ? (
                      <DisplayDetails
                        paymentType={paymentType}
                        setPaymentType={value => setPaymentType(value)}
                        setShowDetailForm={value => setShowPaypalForm(value)}
                        title="Paypal"
                        subTitle={formProps?.values.paypalEmail}
                        formType="paypal"
                      />
                    ) : (
                      <AddButton
                        text="Paypal"
                        addClick={value => setShowPaypalForm(value)}
                      />
                    )}
                  </>
                )}

                {showPaypalForm && (
                  <Box textAlign="center" p={10}>
                    <Box mb={9}>
                      <FormikInput
                        name="paypalEmail"
                        size="small"
                        placeholder="e.g. joe@bloggs.com"
                        background="background1"
                        borderRadius={1}
                        borderColor="grey5"
                        value={formProps?.values.paypalEmail}
                      />
                    </Box>

                    <ActionButtons
                      isSubmitting={isSubmitting}
                      cancelClick={value => setShowPaypalForm(value)}
                      onSave={() => formProps?.submitForm()}
                      formType="paypal"
                    />
                  </Box>
                )}
              </>
            );
          }}
        </Form>
      </Box>
    </Loading>
  </PaypalFormWrapper>
);

export { PaypalForm, PaypalFormContainer };
