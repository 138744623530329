import React, { Component } from "react";
import FormField from "../FormField";
import { Input } from "formik-antd";

const { TextArea } = Input;

export default class AdditionalTerms extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "additional_terms"}
        label="Additional terms"
      >
        <TextArea row={4}/>
      </FormField>
    );
  }
}
