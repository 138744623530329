// @ts-nocheck
import React, { useState } from "react";
import LineChartGraphCard from "../LineChartGraphCard";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Loading } from "src/components/Core/Loading";
import { Box } from "src/components/Core/Box";
import moment from "moment";
import { Button } from "src/components/Core/Button";
import { Schema } from "yup";
import { Row, Col, Alert, Radio } from "antd";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { Typography } from "src/components/Core/Typography";
import { TrackingGraphContainer } from "./container";
import { isValidUrl } from "src/utils/isValidUrl";
import styled from 'styled-components'

const StyledCol = styled(Col)`
  text-align: end;
`

const StyledRadioButton = styled(Radio.Button)`
  height: 24px !important;
`

interface Props<Values = FormikValues> {
  allChartsData: any;
  isEditTrack: boolean;
  loading: boolean;
  onClickYesRemoveButton: (value: number) => void;
  trackingType: string;
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  NewTrackingSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  isError: boolean;
  success: boolean;
}

const TrackingGraph: React.FC<Props> = ({
  initialValues,
  allChartsData,
  isEditTrack,
  loading,
  onClickYesRemoveButton,
  trackingType,
  NewTrackingSchema,
  onSubmit,
  globalErrors,
  isError,
  success,
}) => {
  const [graph, setGraph] = useState(trackingType === 'music' ? 'posts' : 'views')
  return (
    <Box background="#FFFFFF" p={24}>
      <Loading spinning={loading}>
        <GlobalErrors errors={globalErrors} />

        {isEditTrack && (
          <Form
            initialValues={initialValues}
            schema={NewTrackingSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {(formProps: any) => {
              return (
                <Box alignItems="center">
                  <Row gutter={12}>
                    <Col span={10}>
                      <Field
                        label={
                          trackingType === "music"
                            ? "Track sounds on TikTok:"
                            : "Track hashtag on TikTok"
                        }
                        mb={26}
                      >
                        <FormikInput
                          name="trackingKey"
                          size="middle"
                          placeholder={
                            trackingType === "music" ? "Sound url" : "Hashtag"
                          }
                          borderRadius={2}
                          borderColor="grey5"
                        />
                        <Typography size={14} color="grey7">
                          {trackingType === "music"
                            ? "Enter sound url e.g. https://www.tiktok.com/music/…"
                            : "Enter a hashtag e.g Beauty"}
                        </Typography>
                      </Field>
                    </Col>

                    <Col span={2}>
                      <Button
                        htmlType="submit"
                        type="action"
                        loading={loading}
                        disabled={loading}
                        buttonSize="middle"
                        data-cy="submit-button"
                        style={{ marginTop: "34px" }}
                      >
                        + Track
                      </Button>
                    </Col>
                    <StyledCol span={12}>
                      {trackingType === "hashtag" &&
                        <Box mt={38} ml={10} alignSelf='end'>
                          <Radio.Group onChange={e => setGraph(e.target.value)} value={graph}>
                            <StyledRadioButton value="views">
                              <Typography size={14} family="Futura" cursor="pointer" mb={0}>
                                Views
                              </Typography>
                            </StyledRadioButton>
                            <StyledRadioButton value="posts">
                              <Typography size={14} family="Futura" cursor="pointer">
                                Posts
                              </Typography>
                            </StyledRadioButton>
                          </Radio.Group>
                        </Box>
                      }
                      <Box mt={34} ml={10} alignSelf='end'>
                        {success && (
                          <Alert
                            message="Successfully tracking sound"
                            type="success"
                            showIcon
                            closable
                          />
                        )}

                        {isError && (
                          <Alert
                            message="Could not find post. Please try again."
                            type="error"
                            showIcon
                            closable
                          />
                        )}
                      </Box>
                    </StyledCol>
                  </Row>
                </Box>
              );
            }}
          </Form>
        )}

        {(allChartsData || []).map((chartData, i) => (
          <LineChartGraphCard
            key={i}
            graph={graph}
            data={chartData?.graphData || []}
            title={`Tracking ${chartData?.title || isValidUrl(chartData?.trackingKey) ? "" : "#"}${
              chartData?.title || chartData?.trackingKey
            }  - `}
            lastUpdatedDate={` Last updated: ${moment(
              chartData?.lastUpdatedDate
            ).format("Do MMM YYYY, h:mm a")}`}
            YDomain={["dataMin", "dataMax"]}
            isEditTrack={isEditTrack}
            isPostUrl={chartData?.title ? false : isValidUrl(chartData?.trackingKey)}
            onClickYesRemoveButton={() =>
              onClickYesRemoveButton(chartData?.trackingKey)
            }
          />
        ))}
      </Loading>
    </Box>
  );
};

export { TrackingGraph, TrackingGraphContainer };
