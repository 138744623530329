import React, { Component, Fragment } from "react";
import { message } from "antd";
import PageHero from "src/shared/PageHero";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import CampaignWizard from "../CampaignCreate/CampaignWizard";
import { withRouter } from "react-router-dom";

class Draft extends Component {
  deleteDraft = () => {
    const { campaign, history, destroyCampaign } = this.props;

    destroyCampaign(campaign.id)
      .then(() => history.push("/"))
      .catch(() => message.error("Could not delete campaign"));
  };

  render() {
    const {
      campaign,
      title,
      history,
      updateError,
      updateCampaign
    } = this.props;

    const makeUpdate = id => data => updateCampaign(id, data);

    return (
      <Fragment>
        <PageHero
          onBack={history.goBack}
          title={title}
          subTitle="Fill in the details below to start your campaign!"
          extra={[
            <ConfirmDeleteIcon
              text="Draft"
              key="delete-icon"
              onConfirm={this.deleteDraft}
              placement="bottomRight"
            />
          ]}
        />

        <CampaignWizard
          title={title}
          persistData={makeUpdate(campaign.id)}
          campaign={campaign}
          serverErrors={updateError}
        />
      </Fragment>
    );
  }
}

export default withRouter(Draft);
