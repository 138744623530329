// @ts-nocheck
import * as React from "react";
import { useGetCampaignGroupByIdQuery } from "src/generated/ApolloComponents";
import { useRouteMatch, useHistory } from "react-router";

interface ContextType {
  campaignGroupId?: any;
  isLoading: boolean;
  campaignGroupDetails?: any;
  actions: {
    setCampaignGroupId: (value: any) => void;
    refetchCampaignGroup: () => void;
  };
}

const CampaignGroupContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our campaign Group detail context
export function useCampaignGroupDetails() {
  const context = React.useContext(CampaignGroupContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
const BaseCampaignGroupProvider = props => {
  const match: any = useRouteMatch();
  const params: any = match?.params;

  const history: any = useHistory();

  //get campaign Group id
  const id = parseInt(params.id);

  const [campaignGroupId, setCampaignGroupId] = React.useState(id);

  const { data, loading, error, refetch } = useGetCampaignGroupByIdQuery({
    variables: {
      groupId: campaignGroupId,
    },
  });

  // when change campaign Group id its called
  // React.useEffect(() => {
  //   if (platformType === "fanbytes") {
  //     refetch({
  //       influencerId: campaignGroupId,
  //     });
  //   }
  // }, [influencerId, platformType]);

  React.useEffect(() => {
    if (error) {
      history.replace({ pathname: "/campaigns-list" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const refetchCampaignGroup = () => {
    refetch({
      groupId: campaignGroupId,
    });
  };

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      campaignGroupDetails: data?.getCampaignGroupById,
      campaignGroupId,
      isLoading: loading,
      actions: {
        setCampaignGroupId,
        refetchCampaignGroup,
      },
    };
    return internalVal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, campaignGroupId, loading]);

  return (
    <CampaignGroupContext.Provider value={value}>
      {props.children}
    </CampaignGroupContext.Provider>
  );
};

const CampaignGroupProvider = React.memo(BaseCampaignGroupProvider);

export { CampaignGroupProvider };
