import React, { Component } from "react";
import { connect } from "react-redux";
import LoginLayout from "../../layouts/LoginLayout";
import ForgotForm from "./ForgotForm";

export class Forgot extends Component {
  render() {
    return (
      <LoginLayout title="Forgot password">
        <ForgotForm />
      </LoginLayout>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forgot);
