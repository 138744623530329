// @ts-nocheck
import React, { useState } from "react";
import { PaypalForm } from ".";
import { string, object } from "yup";
import { FormikHelpers as FormikActions } from "formik";
import { usePaymentDetails } from "../../PaymentDetailsProvider";

interface Props {
  paymentType: string;
  setPaymentType: (value: string) => void;
}
interface PaypalFormValues {
  paypalEmail: string;
}

export const PaypalFormContainer: React.FC<Props> = ({
  paymentType,
  setPaymentType,
}) => {
  const [showPaypalForm, setShowPaypalForm] = useState(false);
  const { actions: contextAction, actualPaymentDetails } = usePaymentDetails();

  const PaypalFormSchema = object().shape({
    paypalEmail: string()
      .email("Please enter valid email")
      .nullable()
      .required("Please enter a Paypal Email"),
  });

  let initialValues = {
    paypalEmail: actualPaymentDetails?.paypalEmail || "",
  };

  const onSubmit = async (
    values: PaypalFormValues,
    actions: FormikActions<PaypalFormValues>
  ) => {
    actualPaymentDetails.paypalEmail = values.paypalEmail;
    contextAction.setActualPaymentDetails(actualPaymentDetails);
    actions.setSubmitting(false);
    setShowPaypalForm(false);
    try {
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <PaypalForm
      initialValues={initialValues}
      PaypalFormSchema={PaypalFormSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      isSubmitting={false}
      showPaypalForm={showPaypalForm}
      setShowPaypalForm={value => setShowPaypalForm(value)}
      paymentType={paymentType}
      setPaymentType={value => setPaymentType(value)}
    />
  );
};
