// @ts-nocheck
import React from 'react'
import find from 'lodash/find'
import { Tag } from 'src/components/Core/Tag'
import { CloseOutlined } from '@ant-design/icons';
import matchesProperty from 'lodash/matchesProperty'

export const getSelectedTags = (formProps, tagData) => {
  let selectedTags = [] as any;
  if (formProps?.values?.tags) {
    (formProps.values.tags || []).forEach((item, i) => {
      let tag = find(tagData, matchesProperty("value", item));
      const filterItem = (
          <Tag
            size={12}
            tagColor="black2"
            background="white1"
            borderColor="grey1"
            key={tag?.value}
            style={{marginTop: "10px"}}
          >
            {tag?.label}
            <CloseOutlined
              onClick={() => {
                formProps.setFieldValue('tags',
                  formProps?.values?.tags?.filter(item => item !== tag?.value)
                )
              }} />
          </Tag>
      );
      selectedTags.push(filterItem);
    });
    return selectedTags;
  }

}
