// @ts-nocheck
import React, { useState } from "react";
import { Row, Col, Alert } from "antd";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { PaymentRequestFormContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { Field } from "src/components/Forms/Field";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import { Loading } from "src/components/Core/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { BankDetailFormContainer } from "../PaymentDetails/BankDetailForm";
import { PaypalFormContainer } from "../PaymentDetails/PaypalForm";
import {
  CampaignField,
  AccountField,
  PayingAgency,
  CostType,
  VatNumber,
  Agency,
  Amount,
  IsVATRegister,
  AgencyAccounts,
} from "./FormFields";
import { AttachmentsContainer } from "./FormFields/Attachments";
import { featureFlags } from "src/constants/featureFlags";

interface PaymentRequestFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  closePaymentModal: () => void;
  paymentType: string;
  setPaymentType: (value: string) => void;
  getCampaigns: Function;
  getAgencies: Function;
  getAccountsByCampaignId: Function;
  initialValues: Values;
  isSubmitting: boolean;
  PaymentRequestSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  allAccountList: Array<any>; // default get all accounts at first time load
  isPaymentDetailError: boolean;
}

export const PaymentRequestFormWrapper = styled.div`
  width: 100%;
`;

const PaymentRequestForm: React.FC<PaymentRequestFormProps> = ({
  initialValues,
  isSubmitting,
  PaymentRequestSchema,
  onSubmit,
  globalErrors,
  closePaymentModal,
  setPaymentType,
  paymentType,
  getCampaigns,
  getAgencies,
  getAccountsByCampaignId,
  allAccountList,
  isPaymentDetailError,
}) => {
  //To set all accounts for select option  with value and lable
  const [accounts, setAccountData] = useState([]);

  // To set all Account Data of default get all accounts or as per selected campaign
  const [allAccounts, setAllAccountData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [agencies, setAgencies] = useState([]);

  const { isPaypalEnabled } = featureFlags;

  const renderPaymentDetails = () => {
    return (
      <Field label="Payment details:" mb={26}>
        <Row gutter={16}>
          {isPaymentDetailError && (
            <Typography size={14} color="red" ml={10} lineHeight="43px">
              Please enter payment detail
            </Typography>
          )}
          {isPaypalEnabled && (
            <Col span={12}>
              <PaypalFormContainer
                paymentType={paymentType}
                setPaymentType={value => setPaymentType(value)}
              />
            </Col>
          )}
          <Col span={isPaypalEnabled ? 12 : 24}>
            <BankDetailFormContainer
              paymentType={paymentType}
              setPaymentType={value => setPaymentType(value)}
            />
          </Col>
        </Row>
      </Field>
    );
  };

  return (
    <PaymentRequestFormWrapper>
      <Loading spinning={isSubmitting}>
        <Box mb={40}>
          <Typography size={40} weight="semi-bold" color="black">
            Payment Request
          </Typography>
          <Typography size={16} color="black1" lspace={1.16}>
            Please enter a few essential details about your campaign.
          </Typography>
        </Box>

        <GlobalErrors errors={globalErrors} />

        <Form
          initialValues={initialValues}
          schema={PaymentRequestSchema}
          onSubmit={(values, actions) => {
            return onSubmit(values, actions);
          }}
        >
          {(formProps: any) => {
            return (
              <>
                <Row gutter={48}>
                  <Col span={12}>
                    <Field label="Campaign:" mb={26}>
                      <CampaignField
                        campaignId={formProps?.values?.campaignId}
                        setAccountData={value => setAccountData(value as any)}
                        setAllAccountData={value =>
                          setAllAccountData(value as any)
                        }
                        getAccountsByCampaignId={getAccountsByCampaignId}
                        getCampaigns={getCampaigns}
                        setFieldValue={(key, value) =>
                          formProps?.setFieldValue(key, value)
                        }
                      />
                    </Field>
                  </Col>
                  <Col span={12}>
                    {formProps?.values.payingAgency ? (
                      <Agency
                        agencies={agencies}
                        getAgencies={getAgencies}
                        setAgencies={setAgencies}
                        agencyId={formProps?.values?.agencyId}
                        setFieldValue={(key, value) =>
                          formProps?.setFieldValue(key, value)
                        }
                        formProps={formProps}
                      />
                    ) : (
                      <Field label="Account:" mb={26}>
                        <AccountField
                          accountId={formProps?.values?.accountId}
                          campaignId={formProps?.values?.campaignId}
                          accounts={accounts}
                          allAccounts={allAccounts}
                          setAccountData={value => setAccountData(value as any)}
                          setAllAccountData={value =>
                            setAllAccountData(value as any)
                          }
                          getAccountsByCampaignId={getAccountsByCampaignId}
                          setFieldValue={(key, value) =>
                            formProps?.setFieldValue(key, value)
                          }
                          allAccountList={allAccountList}
                        />
                      </Field>
                    )}
                  </Col>
                </Row>
                <Row gutter={48} style={{ marginBottom: 20 }}>
                  <Col span={12}>
                    <PayingAgency
                      isChecked={formProps?.values?.payingAgency}
                      setFieldValue={formProps?.setFieldValue}
                      setFieldTouched={formProps?.setFieldTouched}
                    />
                  </Col>
                  <Col span={12}>
                    <IsVATRegister formProps={formProps} />
                  </Col>
                </Row>

                <Row gutter={48}>
                  <Col span={12}>
                    <CostType value={formProps?.values.description as string} />
                  </Col>
                  <Col span={12}>
                    <VatNumber
                      isVATRegister={formProps?.values.isVATRegister}
                    />
                  </Col>
                </Row>

                <Row gutter={48}>
                  <Col span={12}>
                    {/* New Payment Request */}
                    <Amount formProps={formProps} />
                    {formProps?.values.isVATRegister && (
                      <Alert
                        message="Enter cost including VAT"
                        description="If your payee is VAT registered, please enter the amount including VAT"
                        type="info"
                        style={{ marginBottom: "10px" }}
                      />
                    )}
                  </Col>
                  <Col span={12}>
                    {formProps?.values.payingAgency ? (
                      <AgencyAccounts
                        campaignId={formProps?.values?.campaignId}
                        accounts={accounts}
                        setAccountData={value => setAccountData(value as any)}
                        setAllAccountData={value =>
                          setAllAccountData(value as any)
                        }
                        getAccountsByCampaignId={getAccountsByCampaignId}
                        allAccountList={allAccountList}
                      />
                    ) : (
                      renderPaymentDetails()
                    )}
                  </Col>
                </Row>

                <Row gutter={48}>
                  <Col span={12}>
                    <Field label="Attachments:">
                      <AttachmentsContainer
                        name="attachments"
                        setIsUploading={loading => setIsUploading(loading)}
                        setFieldValue={value =>
                          formProps?.setFieldValue("attachments", value)
                        }
                        setFieldTouched={() =>
                          formProps?.setFieldTouched("attachments", true)
                        }
                        values={formProps?.values}
                        formProps={formProps}
                      />
                    </Field>
                  </Col>
                  <Col span={12}>
                    {formProps?.values.payingAgency ? (
                      renderPaymentDetails()
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>

                <Box
                  alignItems="right"
                  textAlign="right"
                  display="inherit"
                  mt={100}
                >
                  <LinkButton
                    onClick={() => {
                      formProps?.resetForm();
                      closePaymentModal();
                    }}
                    mr={50}
                  >
                    Cancel
                  </LinkButton>

                  <Button
                    htmlType="submit"
                    type="action"
                    loading={isSubmitting}
                    disabled={isSubmitting || isUploading}
                    buttonSize="middle"
                    data-cy="submit-button"
                  >
                    Submit
                  </Button>
                </Box>
              </>
            );
          }}
        </Form>
      </Loading>
    </PaymentRequestFormWrapper>
  );
};

export { PaymentRequestForm, PaymentRequestFormContainer };
