// @ts-nocheck
import React, { useRef } from "react";
import styled from "styled-components";
import { Card } from "antd";
import inluencer_bg from "src/assets/images/reports/inluencer_bg.png";
import { Box } from "src/components/Core/Box";
import { useInViewport } from "react-in-viewport";
import { VelocityComponent } from "velocity-react";
import { animated } from "react-spring";

const VectorDiv = styled(animated.div)`
  position: absolute;

  top: -40px;
  left: -40px;
`;

const VectorImg = styled("img")`
  height: 450px;
  width: 250px;
`;

const InfluencerPostCard = styled(Card)`
  &&& {
    background: none !important;
    border: none !important;
    display: inline flow-root !important;
  }
`;

const videoStyle = {
  width: "250px",
  height: "450px",
  objectFit: "contain",
};

interface Props {
  videoUrl: any;
  trans4: any;
  trans5: any;
  transitionProps: any;
}

const MostLikePost: React.FC<Props> = ({
  videoUrl,
  trans4,
  trans5,
  transitionProps,
}) => {
  const myRef = useRef<HTMLInputElement | null>(null);
  let options = {};
  let props = {};
  const { inViewport, enterCount } = useInViewport(
    myRef,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  return (
    <Box
      textAlign="center"
      ref={myRef as any}
      display="inline flow-root"
      position="relative"
      top="50px"
      left="50px"
    >
      <VelocityComponent
        key="slideUp"
        animation={
          enterCount <= 1 && inViewport
            ? "transition.slideUpIn"
            : "transition.fadeIn"
        }
        delay={50}
      >
        <VectorDiv
          style={{
            transform: transitionProps?.xy?.interpolate(trans5 as any),
            WebkitTransform: transitionProps?.xy?.interpolate(trans5 as any),
          }}
        >
          <VectorImg src={inluencer_bg} alt="Most Like Influencer" />
        </VectorDiv>
      </VelocityComponent>

      <VelocityComponent
        key="slideUp1"
        animation={
          enterCount <= 1 && inViewport
            ? "transition.slideUpIn"
            : "transition.fadeIn"
        }
        delay={80}
      >
        <animated.div
          style={{
            transform: transitionProps?.xy?.interpolate(trans4 as any),
            WebkitTransform: transitionProps?.xy?.interpolate(trans4 as any),
          }}
        >
          <InfluencerPostCard
            hoverable
            cover={<video style={videoStyle as any} src={videoUrl} controls />}
          />
        </animated.div>
      </VelocityComponent>
    </Box>
  );
};

export default MostLikePost;
