import orm from "../orm";
import { createSelector } from "redux-orm";

function OrmHOD(name) {
  const capName = name.toUpperCase();

  // Types
  const types = {
    addMany: `V2_INDEX_${capName}`,
    addOne: `V2_ADD_${capName}`,
    delete: `V2_DELETE_${capName}`
  };

  // Action creators
  const actions = {
    addMany: (data, params = {}) => ({
      type: types.addMany,
      payload: { data, params }
    }),
    addOne: data => ({
      type: types.addOne,
      payload: { data }
    }),
    delete: id => ({
      type: types.delete,
      payload: id
    })
  };

  const reducer = (dbState, action, model) => {
    const sess = orm.session(dbState);

    switch (action.type) {
      case types.addMany:
        action.payload.data.forEach(item => {
          sess[name].parse(item);
        });
        break;
      case types.addOne:
        sess[name].parse(action.payload.data);
        break;
      case types.delete:
        sess[name].withId(action.payload).delete();
        break;
      default:
        break;
    }

    return sess.state;
  };

  const selectors = {
    selectOne: createSelector(
      orm,
      (entities, id) => entities[name].withId(id)
    ),
    selectMany: createSelector(
      orm,
      (entities, ids) =>
        entities[name]
          .all()
          .toModelArray()
          .filter(x => ids.indexOf(x.id) >= 0)
          .sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id))
    )
  };

  return {
    types,
    reducer,
    ...actions,
    ...selectors
  };
}

export const Campaign = OrmHOD("Campaign");
export const Creative = OrmHOD("Creative");
export const Company = OrmHOD("Company");
export const Admin = OrmHOD("Admin");
export const Brand = OrmHOD("Brand");
export const Draft = OrmHOD("Draft");
export const Tag = OrmHOD("Tag");
export const Influencer = OrmHOD("Influencer");
export const Suggestion = OrmHOD("Suggestion");
export const Account = OrmHOD("Account");
export const AccountPost = OrmHOD("AccountPost");
export const Invitation = OrmHOD("Invitation");
export const CampaignAnalytic = OrmHOD("CampaignAnalytic");
export const CampaignPackage = OrmHOD("CampaignPackage");
export const Link = OrmHOD("Link");
export const Email = OrmHOD("Email");
export const Agency = OrmHOD("Agency");
export const Payout = OrmHOD("Payout");
export const Worker = OrmHOD("Worker")
