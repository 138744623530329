// @ts-nocheck
import React, {useCallback, useEffect, useRef, useState} from "react";
import { AccountsTable } from ".";
import { useParams } from "react-router-dom";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useAccountsListDetails } from "../AccountsListProvider";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import get from "lodash/get";
import set from "lodash/set";
import isNull from "lodash/isNull"
import isUndefined from "lodash/isUndefined"
import intersection from "lodash/intersection"
import {
  useGetFilteringAccountQueryQuery,
  useSuggestMultipleAccountsMutation,
  useGetAdminListsQuery,
} from "src/generated/ApolloComponents";
import { AccountsTableContainer as Props } from './props'
import { debounce } from "lodash";

export const AccountsTableContainer: React.FC<Props> = (props) => {
  const { containerType, error, list, refetchFlag, setAllAccounts, } = props
  const [searchText, setSearchText] = useState("");
  const params: any = useParams();

  const {
    isLoading,
    listErrors,
    accountsListDetails,
    filters,
    actions,
    allTags,
  } = useAccountsListDetails();

  useEffect(() => {
    actions?.setContainerType(containerType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(containerType !== 'listAccounts' && setAllAccounts)
      setAllAccounts(accountsListDetails)
  },[accountsListDetails, containerType, setAllAccounts])

  const [
    suggestMultipleAccountsMutation,
    suggestedData
  ] = useSuggestMultipleAccountsMutation();

  const adminLists = useGetAdminListsQuery()

  const searchInputResult = useGetFilteringAccountQueryQuery({
    variables: { searchInput: "" },
    skip: !searchText,
  });

  const onSearchInputChange = async searchInput => {
    try {
      searchInputResult.refetch({searchInput });
    } catch (e) {}
  };

  const debouncedFilterChange = useRef(debounce(onSearchInputChange, 1000)).current;

  const globalErrors = useMapGraphQLErrorsMessages(listErrors || suggestedData?.error || error);

  const clearFilterKey = (key, index = 0) => {
    let newFilter = { ...filters };

    let newFilterValue: any = get(newFilter, key);

    if (isArray(newFilterValue)) {
      if ((newFilterValue || []).length === 1) {
        newFilter = set(newFilter, key, []);
      } else {
        newFilterValue.splice(index, 1);
        newFilter = set(newFilter, key, newFilterValue);
      }
    } else if (isString(newFilterValue)) {
      newFilter = set(newFilter, key, null);

      if (key === "searchInput") {
        newFilter = set(newFilter, "searchType", null);
      }
    } else if (isObject(newFilter[key])) {
      newFilter = set(newFilter, key, null);
    } else {
      newFilter = set(newFilter, key, null);
    }
    actions?.setFilters({ ...newFilter });
    actions?.refetchAccounts({ ...newFilter });
  };

  const onFilterChange = useCallback(
    (key, value) => {
      let newFilters = { ...filters };

      if (key === "quickSearch") {
        newFilters = { ...filters, ...value };
      } else {
        newFilters[key] = value;
      }

      if (newFilters?.order === null) {
        delete newFilters?.order;
      }

      actions?.setFilters({ ...newFilters });
      actions?.refetchAccounts({ ...newFilters });
    },[filters, actions])

  useEffect(() => {
    if(refetchFlag){
      actions?.refetchAccounts({ ...filters })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refetchFlag])

  useEffect(() => {
    if (searchText) {
      debouncedFilterChange(searchText);
    } else {
      let newFilters = { ...filters };

      const keys = ["tags", "influencerId", "campaignId", "accountId", "accountId"]
      if(!intersection(keys, Object.keys(newFilters)).length)
        return

      if (newFilters?.tags) {
        newFilters["tags"] = [] as any;
      }

      if (newFilters?.influencerId) {
        newFilters["influencerId"] = null as any;
      }

      if (newFilters?.campaignId) {
        newFilters["campaignId"] = null as any;
      }

      if (newFilters?.accountId) {
        newFilters["accountId"] = null as any;
      }

      actions?.setFilters({ ...newFilters });
      actions?.refetchAccounts({ ...newFilters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if(isNull(list) || isUndefined(list)){
      // null or undefined used as initial value for list
      return
    }
    adminLists?.refetch()
    if(!list?.id){
      const included = filters["adminListId"]
      const notIncluded = filters["adminListIdForNotIncluded"]
      if(!isNull(notIncluded) && notIncluded !== "" && notIncluded >= 0){
        setFilterValues("adminListIdForNotIncluded", null)
      } else if(!isNull(included) && included !== "" && included >= 0){
        setFilterValues("adminListId", null)
      }
    } else {
      if(containerType !== "listAccounts"){
        setFilterValues("adminListIdForNotIncluded", list.id)
      } else {
        setFilterValues("adminListId", list.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list])

  const setFilterValues = (name: string, value: any) => {
    let newFilters = { ...filters }
    newFilters[name] = value
    actions?.setFilters({ ...newFilters })
    actions.refetchAccounts({ ...newFilters }, containerType)
  }

  const handleSuggestedAccounts = async (selectedRowKeys: number[]) => {
    try {
      const res: any = await suggestMultipleAccountsMutation({
        variables: {
          campaignId: Number(params?.id),
          accountIds: selectedRowKeys.map(key => Number(key))
        }
      })
      if(res?.data?.suggestMultipleAccounts?.length > 0) {
        actions?.refetchAccounts({ ...filters });
      }
    } catch(e) {
      console.log(e)
    }
  }

  const refetchAccounts = () => {
    actions?.refetchAccounts({ ...filters })
  }

  return (
    <AccountsTable
      allAccounts={accountsListDetails}
      globalErrors={globalErrors}
      loading={isLoading || suggestedData?.loading}
      onFilter={onFilterChange}
      clearFilterKey={(key, index) => clearFilterKey(key, index)}
      filters={filters}
      allTags={(allTags || []) as any}
      filterDataSoruce={searchInputResult?.data?.getFilteringAccountQuery || {}}
      searchText={searchText}
      onSearchTextChange={value => setSearchText(value)}
      refetch={refetchAccounts}
      handleSuggestedAccounts={handleSuggestedAccounts}
      adminLists={adminLists?.data?.getAdminLists || []}
      {...props}
    />
  );
};
