// @ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import isEqual from "lodash/isEqual";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Button } from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";
import LinkButton from "src/components/Core/LinkButton";
import { FilterFormContainer } from "./container";
import {
  SearchInput,
  Platform,
  Gender,
  Age,
  Tags,
  CustomTags,
  LocationAudience,
  GenderAudience,
  AgeAudience,
  SearchType,
  Status,
  CampaignCompleted,
  AdvanceAgeAudience,
  AdvanceGenderAudience,
  AdvanceLocationAudience,
  Professionalism,
  ContentQuality,
  ResponseTime,
  CreationTime,
  Followers,
  Locations,
  AdminList,
} from "./FormFields";
import { shouldShowClearAllFilter } from "./utils";

interface FilterFormProps<Values = FormikValues> {
  containerType?: string
  initialValues: Values;
  isSubmitting: boolean;
  FilterSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  allTags: Array<any>;
  clearAllFilter: () => void;
  internalFilters: any;
  isExpand: boolean;
  setExpand: (value: boolean) => void;
  clearFilterKey: (key: string) => void;
  adminLists: any
  list?: any
}

const FilterForm: React.FC<FilterFormProps> = ({
  containerType,
  initialValues,
  isSubmitting,
  FilterSchema,
  onSubmit,
  allTags,
  isExpand,
  clearAllFilter,
  internalFilters,
  setExpand,
  clearFilterKey,
  adminLists,
  list,
}) => {
  const displayObj = (name: string) => {
    if(!list?.id)
      return {}
    if(containerType === 'listAccounts') {
      return name === 'adminListId' ? {display: 'none'} : {}
    } else {
      return name === 'adminListId' ? {} : {display: 'none'}
    }
  }
  return (
    <>
      <Loading spinning={false}>
        <Form
          initialValues={initialValues}
          schema={FilterSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return (
              <Box p="15px">
                <Row gutter={32}>
                  <Col span={5}>
                    <Box mb={30}>
                      <Typography
                        size={20}
                        weight="semi-bold"
                        color="black"
                        lineHeight={0}
                      >
                        Search Accounts
                      </Typography>
                    </Box>

                    <SearchInput
                      formProps={formProps}
                      clearFilterKey={key => clearFilterKey(key)}
                    />

                    <SearchType />

                    {[{name: "adminListId", label: "Included in the list:"},
                      {name: "adminListIdForNotIncluded", label: "Not in the list:"}
                     ].map(obj =>
                        <div key={obj.name}
                          style={displayObj(obj.name)} >
                          <AdminList
                            name={obj.name}
                            label={obj.label}
                            lists={adminLists}
                            formProps={formProps}
                            clearFilterKey={key => clearFilterKey(key)}
                          />
                        </div>
                      )
                    }

                    <Button
                      htmlType="submit"
                      type="action"
                      loading={isSubmitting}
                      disabled={
                        isSubmitting ||
                        isEqual(formProps?.initialValues, formProps?.values)
                      }
                      buttonSize="middle"
                      style={{ width: "100%" }}
                    >
                      Update results
                    </Button>

                    {shouldShowClearAllFilter(internalFilters) && (
                      <Typography
                        size={12}
                        color="red"
                        cursor="pointer"
                        mt={20}
                        textAlign="center"
                        onClick={() => {
                          formProps?.resetForm();
                          clearAllFilter();
                        }}
                      >
                        Clear all filters
                      </Typography>
                    )}
                    <Box textAlign="center">
                      <LinkButton
                        onClick={() => setExpand(!isExpand)}
                        color="blue"
                        fontSize={12}
                        fontWeight="500"
                        width="100%"
                      >
                        {isExpand ? "Hide" : "Show"} advanced filters
                      </LinkButton>
                    </Box>
                  </Col>

                  <Col span={isExpand ? 8 : 6}>
                    <Box
                      borderBottom="3px solid #F5222D"
                      width="auto"
                      maxWidth="100px"
                      mb={20}
                    >
                      <Typography
                        size={20}
                        color="black2"
                        weight="semi-bold"
                        lineHeight={0}
                      >
                        Audience
                      </Typography>
                    </Box>

                    {isExpand ? (
                      <>
                        <AdvanceLocationAudience
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                        <AdvanceAgeAudience
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                        <AdvanceGenderAudience
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </>
                    ) : (
                      <>
                        <LocationAudience
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                        <GenderAudience
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                        <AgeAudience
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </>
                    )}
                  </Col>

                  <Col span={isExpand ? 11 : 13}>
                    <Box
                      borderBottom="3px solid #1890FF"
                      width="auto"
                      maxWidth="100px"
                      mb={20}
                    >
                      <Typography
                        size={20}
                        color="black2"
                        weight="semi-bold"
                        lineHeight={0}
                      >
                        Influencer
                      </Typography>
                    </Box>

                    <Row gutter={8}>
                      <Col span={6}>
                        <CreationTime
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                      <Col span={6}>
                        <ResponseTime
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                      <Col span={6}>
                        <ContentQuality
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                      <Col span={6}>
                        <Professionalism
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    </Row>

                    <Row gutter={8}>
                      <Col span={11}>
                        <Platform
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>

                      <Col span={13}>
                        <CampaignCompleted
                          formProps={formProps}
                          isExpand={isExpand}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col span={12}>
                        <Age
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>

                      <Col span={12}>
                        <Gender
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    </Row>

                    <Row gutter={8}>
                      <Col span={12}>
                        <Locations
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    </Row>

                    <Row gutter={8}>
                      <Col span={12}>
                        <Status
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                      <Col span={12}>
                        <Followers
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    </Row>

                    <Row gutter={8}>
                      <Col span={16}>
                        <Tags
                          allTags={allTags}
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>

                      <Col span={8}>
                        <CustomTags
                          allTags={allTags}
                          formProps={formProps}
                          clearFilterKey={key => clearFilterKey(key)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Box>
            );
          }}
        </Form>
      </Loading>
    </>
  );
};

export { FilterForm, FilterFormContainer };
