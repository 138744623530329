import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import { default as SharedPostedContent } from "src/shared/Analytics/PostedContent";
import CampaignManage from "src/ducks/CampaignManage";

export class Media extends Component {
  componentDidMount() {
    const { campaign, loadAnalytics } = this.props;
    loadAnalytics(campaign.id);
  }

  render() {
    const { analytics, campaign, analyticsLoading } = this.props;

    return (
      <Spin spinning={analyticsLoading}>
        <SharedPostedContent
          analytics={analytics}
          campaign={campaign}
          style={{ marginBottom: 24, marginTop: 24 }}
        />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
  analytics: CampaignManage.analytic.selectOne(state),
  analyticsLoading: CampaignManage.analytic.selectGetPending(state),
});

const mapDispatchToProps = {
  loadAnalytics: CampaignManage.analytic.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(Media);
