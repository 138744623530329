// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { statuses } from "src/constants/account";

interface Props {}

const Status: React.FC<Props> = () => {
  return (
    <Field label="Status:" mb={26}>
      <FormikSelect
        name="status"
        size="middle"
        placeholder="Select..."
        data={statuses}
        local
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default Status;
