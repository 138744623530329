// @ts-nocheck
import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import { TextInput } from "src/components/Forms/TextInput";
import { Table } from "src/components/Core/Table";
import { AddCampaignsListContainer } from "./container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import getColumns from "../../Columns";
import withWindowResize from "src/utils/hoc/withWindowResize";

export interface FilterProps {
  campaignTitle: string;
  groupId: number;
  page: number;
}

interface Props {
  allCampaigns: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  height?: number; // from withWindowResize HOC
  filters: FilterProps;
  closeAddCampaignsModal: () => void;
  selectedCampaigns: Array<any>;
  setSelectedCampaigns: (value: any) => void;
  addCampaigns: () => void;
}

const MainCampaignsTable: React.FC<Props> = ({
  allCampaigns,
  loading,
  globalErrors,
  onFilter,
  height,
  filters,
  closeAddCampaignsModal,
  selectedCampaigns,
  setSelectedCampaigns,
  addCampaigns,
}) => {
  const onSelectChange = selectedRowKeys => {
    setSelectedCampaigns(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedCampaigns,
    onChange: onSelectChange,
  };

  return <>
    <Typography size={16} color="black" lineHeight="12px">
      Search and select campaigns from below to add to your group
    </Typography>

    <GlobalErrors errors={globalErrors} />

    <Box display="block">
      <TextInput
        size="small"
        background="background"
        borderRadius={3}
        borderColor="grey5"
        value={filters?.campaignTitle}
        onChange={e => onFilter("campaignTitle", e.target.value)}
        suffix={<SearchOutlined />}
        placeholder="Search campaigns..."
      />
    </Box>

    <Box background="white" mt={10} mb={10} borderRadius="4px">
      <Loading spinning={loading}>
        <Table
          columns={getColumns()}
          rowKey="id"
          rowSelection={rowSelection}
          dataSource={allCampaigns?.campaigns || []}
          stripe={true}
          data-cy="table-AddCampaignTable"
          pagination={{
            position: "bottom",
            current: allCampaigns?.currentPage,
            pageSize: allCampaigns?.perPage,
            total: allCampaigns?.totalEntries,
            onChange: page => onFilter("page", Number(page)),
          }}
          rowHover={true}
          scroll={{ y: height, x: "max-content" }}
        />

        <Box alignItems="right" textAlign="right" display="inherit" mt={20}>
          <LinkButton
            onClick={() => closeAddCampaignsModal()}
            mr={20}
            color="red6"
          >
            Cancel
          </LinkButton>

          <Button
            htmlType="submit"
            type="action"
            loading={false}
            disabled={(selectedCampaigns || []).length > 0 ? false : true}
            buttonSize="middle"
            data-cy="submit-button"
            onClick={() => addCampaigns()}
          >
            Add {(selectedCampaigns || []).length} campaigns to group
          </Button>
        </Box>
      </Loading>
    </Box>
  </>;
};

const AddCampaignsList = withWindowResize({ extraHeight: 350 })(
  MainCampaignsTable
);

export { AddCampaignsList, AddCampaignsListContainer };
