// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Card } from "antd";

const DraftMediaCard = styled(Card)`
  &&& {
    .ant-card-body {
      padding: 14px !important;
    }
    box-shadow: none !important;
  }
`;

const videoStyle = {
  width: "100%",
  height: "330px",
  objectFit: "contain",
  backgroundColor: "#D3D3D3",
  borderRadius: "4px",
};

interface Props {
  contentType: string;
  contentUrl: string;
}

const DraftPost: React.FC<Props> = ({ contentType, contentUrl }) => {
  // let newContentUrl = contentUrl;

  // let urlLen = contentUrl.length;
  // let lastDotPos = contentUrl.lastIndexOf(".");

  // let fileNameSub = "";
  // if (lastDotPos === -1) {
  //   fileNameSub = "";
  // } else {
  //   //Remove +1 if you want the "." left too
  //   fileNameSub = contentUrl.substr(lastDotPos + 1, urlLen);
  // }

  // if (fileNameSub.length <= 4) {
  //   newContentUrl = contentUrl.substr(0, contentUrl.lastIndexOf(".")) + ".mp4";
  // }

  const content =
    contentType === "video" ? (
      <video style={videoStyle as any} src={contentUrl} controls />
    ) : (
      <a href={contentUrl} target="_blank" rel="noopener noreferrer">
        <img
          style={{
            width: "100%",
            height: 330,
            objectFit: "contain",
            backgroundColor: "#D3D3D3",
            borderRadius: "4px",
          }}
          src={contentUrl}
          alt="account-post-img"
        />
      </a>
    );

  return <DraftMediaCard hoverable cover={content} bordered={false} />;
};

export default DraftPost;
