// @ts-nocheck
import React from "react";
import {
  GiftingCampaignFragmentFragment,
  GiftingCampaignStatus,
} from "src/generated/ApolloComponents";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import { Tag, Avatar } from "antd";
import capitalize from "lodash/capitalize";
import { Link } from "react-router-dom";

interface Props {
  campaign: GiftingCampaignFragmentFragment;
  deleteDraft: Function;
}

export const GiftingCampaignListItem = ({ campaign, deleteDraft }: Props) => {
  return (
    <Link to={`/gifting/${campaign.id}`}>
      <Box
        mb={15}
        display="flex"
        background="white"
        borderRadius={5}
        overflow="hidden"
        height={120}
        justifyContent="space-between"
        alignItems="center"
        padding="5px"
      >
        <Box display="flex" alignItems="center" height="100%">
          {campaign?.image ? (
            <img
              src={campaign?.image || undefined}
              width={105}
              alt="campaign"
            />
          ) : (
            <Avatar shape="square" size={105} />
          )}
          <Box ml={15}>
            <Typography size={16} mb={10} color="black" weight="semi-bold">
              {campaign?.title}
            </Typography>
            {campaign?.brief?.campaignType && (
              <Box mb="5px">
                <Tag color="#2db7f5">
                  {capitalize(campaign.brief?.campaignType.toString())}
                </Tag>
              </Box>
            )}

            {campaign?.brief?.platforms?.snapchat && (
              <Tag color="orange">Snapchat</Tag>
            )}
            {campaign?.brief?.platforms?.youtube && (
              <Tag color="orange">Youtube</Tag>
            )}
            {campaign?.brief?.platforms?.tiktok && (
              <Tag color="orange">Tiktok</Tag>
            )}
            {campaign?.brief?.platforms?.instagram && (
              <Tag color="orange">Instagram</Tag>
            )}
          </Box>
        </Box>
        <Box>
          {campaign.status === GiftingCampaignStatus.Draft ? (
            <ConfirmDeleteIcon
              text="Delete"
              key="delete-icon"
              onConfirm={e => {
                e.preventDefault();
                deleteDraft();
              }}
              placement="bottomRight"
            />
          ) : (
            <Tag>{campaign.status}</Tag>
          )}
        </Box>
      </Box>
    </Link>
  );
};
