import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "formik-antd";
import { connectModal } from "redux-modal";
import { Modal, Button } from "antd";
import { withFormik } from "formik";
import {
  InviteLimit,
  ViewTarget,
  InviteLimitSwitch,
  Admin,
  Executive,
} from "src/shared/CampaignFields";
import CampaignManage from "src/ducks/CampaignManage";
import { number, object, boolean } from "yup";
import FormError from "src/shared/FormField/FormError";
import idx from "idx";
import styled from "styled-components";

const InviteLimitBlock = styled("div")`
  {
    position: relative;
  }
`;

const InviteLimitSwitchBlock = styled("div")`
   {
    position: absolute;
    right: 0;
  }
`;

export class ApproveForm extends Component {
  render() {
    const {
      show,
      handleHide,
      handleSubmit,
      isSubmitting,
      status,
      isValid,
      values,
    } = this.props;

    return (
      <Modal
        visible={show}
        onCancel={handleHide}
        title="Approve Campaign"
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
        {...this.props}
      >
        <Form data-cy="campaign-approve-form">
          {status &&
            Object.values(status.meta.errors).map((error, i) => (
              <FormError
                key={i}
                style={{ margin: "0 0 10px 0" }}
                message={error}
              />
            ))}

          <InviteLimitBlock>
            <InviteLimitSwitchBlock>
              <InviteLimitSwitch />
            </InviteLimitSwitchBlock>
            {values.invite_limit_type ? <InviteLimit /> : <ViewTarget />}
          </InviteLimitBlock>
          <Admin />
          <Executive />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  campaign: CampaignManage.campaign.selectOne(state),
});

const mapDispatchToProps = {
  updateCampaign: CampaignManage.campaign.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  connectModal({
    name: "ApproveCampaignModal",
    destroyOnHide: false,
  })(
    withFormik({
      mapPropsToValues: ({ campaign }) => {
        let invite_limit_type = true;
        if (idx(campaign, x => x.ref.influencer_invite_limit)) {
          invite_limit_type = true;
        } else if (idx(campaign, x => x.ref.influencer_spend_pennies)) {
          invite_limit_type = false;
        }

        return { ...campaign.ref, invite_limit_type };
      },

      enableReinitialize: true,
      isInitialValid: true,

      validationSchema: () =>
        object().shape({
          invite_limit_type: boolean(),

          influencer_invite_limit: number().when("invite_limit_type", {
            is: true,
            then: number()
              .nullable()
              .required("Please enter invite limit"),
            otherwise: number().nullable(),
          }),

          influencer_spend_pennies: number().when("invite_limit_type", {
            is: false,
            then: number()
              .nullable()
              .required("You need to enter an invite spend budget"),
            otherwise: number().nullable(),
          }),
          admin: number().required("You must select an admin"),
        }),
      handleSubmit: (
        values,
        { props, setSubmitting, setErrors, setStatus }
      ) => {
        const { updateCampaign, campaign, handleHide } = props;

        let data;

        const { invite_limit_type, ...rest } = values;

        if (values.invite_limit_type) {
          rest.influencer_spend_pennies = null;
        } else {
          rest.influencer_invite_limit = null;
        }
        data = rest;

        updateCampaign(campaign.id, {
          ...data,
          status: "active",
        })
          .then(res => {
            setSubmitting(false);
            handleHide();
          })
          .catch(err => {
            setSubmitting(false);
            setStatus(err.response.data);
            setErrors(err.response.data.meta.errors);
          });
      },

      displayName: "AdminApproveCampaignForm",
    })(ApproveForm)
  )
);
