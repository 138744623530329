// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Radio } from "antd";
import { Box } from "src/components/Core/Box";
import { FormikHelpers as FormikActions } from "formik";
import Form from "src/shared/Form";
import Loading from "src/shared/Loading";
import { object } from "yup";
import {
  FeedbackFieldItem,
  FeedbackButton,
  FeedbackWrapper,
  FeedbackTabs,
  FeedbackListItem,
  FeedbackActionSelect,
  FeedbackActionWrapper,
} from "./components";
import compact from "lodash/compact";
import { useUpdateDraftFeedbackMutation } from "src/generated/ApolloComponents";

interface FormValues {
  adminFeedback: Array<any>;
  feedbackAction: string;
}

interface Props {
  draft: any;
  fetchInvitations: Function;
  isActiveDraft: boolean;
}

const DraftFeedbackForm: React.FC<Props> = props => {
  const { draft, fetchInvitations, isActiveDraft } = props;

  const [errors, setErrors] = useState([]);

  const [activeKey, setActiveKey] = useState("Admin");
  const [isSubmitted, setSubmitted] = useState(false);

  let brandFeedback = [] as any;
  let adminFeedback = [] as any;

  (draft?.brandFeedback || []).forEach((item, index) => {
    let startPos = item.lastIndexOf("[[[");
    let endPos = item.lastIndexOf("]]]");
    let time = "";
    let feedback = "";

    if (startPos !== -1 && endPos !== -1) {
      time = item.substring(startPos + 3, endPos);
      feedback = item.substring(0, startPos);
    } else {
      time = "";
      feedback = item;
    }
    let mainTime = (time || "").split(":");

    brandFeedback.push({
      feedback,
      min: mainTime?.[0] || 0,
      sec: mainTime?.[1] || 0,
      isChecked: time !== "" ? true : false,
    });
  });

  (draft?.adminFeedback || []).forEach((item, index) => {
    let startPos = item.lastIndexOf("[[[");
    let endPos = item.lastIndexOf("]]]");
    let time = "";
    let feedback = "";

    if (startPos !== -1 && endPos !== -1) {
      time = item.substring(startPos + 3, endPos);
      feedback = item.substring(0, startPos);
    } else {
      time = "";
      feedback = item;
    }
    let mainTime = (time || "").split(":");
    adminFeedback.push({
      feedback,
      min: mainTime?.[0] || "",
      sec: mainTime?.[1] || "",
      isChecked: time !== "" ? true : false,
    });
  });

  let initialFeedback = [
    {
      feedback: "",
      min: "",
      sec: "",
      isChecked: true,
    },
  ];

  let initialValues = {
    adminFeedback:
      (adminFeedback || []).length > 0
        ? [...adminFeedback, ...initialFeedback]
        : (brandFeedback || []).length > 0
        ? [...brandFeedback, ...initialFeedback]
        : initialFeedback,

    feedbackAction: "redraft",
  };

  const DraftFeedbackSchema = object().shape({});

  const [
    updateDraftFeedbackMutation,
    { loading, error },
  ] = useUpdateDraftFeedbackMutation();

  useEffect(() => {
    const allErrors: any =
      error &&
      error.graphQLErrors.map(function({ message }, i) {
        return message;
      });

    setErrors(allErrors);
  }, [error]);

  const onSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    try {
      let adminFeedback = values.adminFeedback.map(function(el) {
        if (!!el.feedback) {
          if (el.isChecked && (!!el.min || !!el.sec)) {
            return `${el.feedback}[[[${el.min}:${el.sec}]]]`;
          } else {
            return el.feedback;
          }
        }
        return null;
      });

      const formData = {
        draftId: draft?.id.toString() as string,
        status: values.feedbackAction,
        adminFeedback: compact(adminFeedback),
      };

      const res = await updateDraftFeedbackMutation({
        variables: formData,
      });
      if (res) {
        fetchInvitations();
        setSubmitted(true);
        setTimeout(() => setSubmitted(false), 2000);
      }
    } catch (e) {
      setErrors([e.message] as any);
      actions.setSubmitting(false);
    }
  };

  return (
    <div>
      <Loading spinning={false}>
        <Form
          initialValues={initialValues}
          schema={DraftFeedbackSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
          errors={errors}
        >
          {(formProps: any) => {
            return (
              <>
                <Box textAlign="center">
                  <FeedbackTabs
                    onChange={e => setActiveKey(e.target.value)}
                    value={activeKey}
                  >
                    <Radio.Button value="Brand">Brand</Radio.Button>
                    <Radio.Button value="Admin">Admin</Radio.Button>
                  </FeedbackTabs>
                </Box>
                <FeedbackWrapper>
                  {activeKey === "Brand" && (
                    <FeedbackListItem
                      feedbacks={draft?.brandFeedback || []}
                      draftType={draft?.draftType}
                    />
                  )}

                  {activeKey === "Admin" && (
                    <>
                      {draft?.status === "approved" || !isActiveDraft ? (
                        <FeedbackListItem
                          feedbacks={draft?.adminFeedback || []}
                          draftType={draft?.draftType}
                        />
                      ) : (
                        <FeedbackFieldItem
                          name="adminFeedback"
                          placeholder="Please enter feedback..."
                          draft={draft}
                        />
                      )}
                    </>
                  )}
                </FeedbackWrapper>
                {draft?.status !== "approved" && (
                  <FeedbackActionWrapper isActiveDraft={isActiveDraft}>
                    <FeedbackActionSelect name="feedbackAction" />
                    <FeedbackButton
                      isLoading={loading}
                      isDisabled={
                        ((formProps?.values?.adminFeedback.filter(function(el) {
                          return el !== "";
                        })).length === 0 &&
                          formProps?.values?.feedbackAction === "redraft") ||
                        !isActiveDraft
                      }
                      isSubmitted={isSubmitted}
                    />
                  </FeedbackActionWrapper>
                )}
              </>
            );
          }}
        </Form>
      </Loading>
    </div>
  );
};

export default DraftFeedbackForm;
