// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import Loading from "src/shared/Loading";
import { AverageLikesGraphContainer } from "./container";
import LineChartGraphCard from "../LineChartGraphCard";
import numeral from "numeral";

interface Props {
  loading: boolean;
  data: Array<any>;
  period: string;
  setPeriod: (value: string) => void;
  titleValue: any;
}

const AverageLikesGraph: React.FC<Props> = ({
  loading,
  data,
  period,
  setPeriod,
  titleValue,
}) => {
  return data?.length > 0 ? (
    <Box>
      <Loading spinning={loading}>
        <LineChartGraphCard
          data={data}
          title="Average likes"
          titleValue={numeral(titleValue).format("0,0")}
          period={period}
          YDomain={[0, "auto"]}
          setPeriod={value => setPeriod(value)}
        />
      </Loading>
    </Box>
  ) : null;
};

export { AverageLikesGraph, AverageLikesGraphContainer };
