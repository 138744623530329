import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "src/shared/Table";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import { Select } from "formik-antd";
import Container from "src/shared/Container";
import TableManager from "src/ducks/TableManager";
import { InvitationList as $InvitationList } from "src/ducks/DataList";
import { statuses } from "src/constants/invitations";
import { Invitation as ExportApi } from "src/api/endpoints";
import idx from "idx";
import withWindowResize from "src/utils/hoc/withWindowResize";

const InvitationTable = TableManager("CampaignInvitationList");
const InvitationList = $InvitationList("CampaignInvitationList");

const modules = [InvitationTable.module(), InvitationList.module()];

const columns = {
  campaign: {
    title: "Account",
    dataIndex: "account.username",
    visible: true,
    width: 150,
    render: (text, row) =>
      idx(row, x => x.account.id) ? (
        <Link to={`/accounts/${idx(row, x => x.account.id)}`}>{text}</Link>
      ) : (
        text
      ),
  },
  status: {
    title: "Status",
    dataIndex: "status",
    visible: true,
    width: 100,
  },

  estimated_payout: {
    title: "Estimated Payout",
    dataIndex: "payout_type_pennies",
    visible: true,
    width: 100,
    render: (text, row) => row.formattedPayoutTypePennies(),
  },

  actual_payout: {
    title: "Actual Payout",
    dataIndex: "payout_pennies",
    visible: true,
    width: 100,
    render: (text, row) => row.formattedPayout(),
  },

  ctr: {
    title: "CTR",
    dataIndex: "ctr",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedCtr(),
  },
  itr: {
    title: "ITR",
    dataIndex: "itr",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedItr(),
  },
  views: {
    title: "Views",
    dataIndex: "views",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedViews(),
  },
  installs: {
    title: "Installs",
    dataIndex: "installs",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedInstalls(),
  },
  clicks: {
    title: "Clicks",
    dataIndex: "clicks",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedClicks(),
  },
  cpi: {
    title: "CPI",
    dataIndex: "cpi",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedCpi(),
  },
  cpc: {
    title: "CPC",
    dataIndex: "cpc",
    sorter: true,
    visible: true,
    width: 100,
    render: (text, row) => row.formattedCpc(),
  },
  remove_reason: {
    title: "Remove Reason",
    dataIndex: "remove_reason",
    visible: true,
    width: 120,
  },
};

const filters = {
  status: {
    input: () => (
      <Select placeholder="Status" size="large" options={statuses}>
      </Select>
    ),
    label: "Status",
  },
};

export class InvitationsTable extends Component {
  loadInvitations = filters => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.loadInvitations({
      expand: ["account"],
      campaign: id,
      ...filters,
    });
  };

  exportApproveInvitation = () => {
    const {
      filters,
      match: {
        params: { id },
      },
    } = this.props;

    ExportApi.index({
      params: { ...filters, campaign: id, export_csv: true },
      responseType: "blob",
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "influencer.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { data, pagination, loading, style, height } = this.props;

    return (
      <Container style={{ marginTop: "24px" }}>
        <Table
          style={style}
          title="Invitations"
          columns={columns}
          loadData={this.loadInvitations}
          name="CampaignInvitationList"
          pagination={pagination}
          data={data}
          filterOptions={filters}
          loading={loading}
          exportData={this.exportApproveInvitation}
          size="middle"
          scroll={{ y: height, x: "max-content" }}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  data: InvitationList.selectMany(state),
  pagination: InvitationList.selectPagination(state),
  loading: InvitationList.selectPending(state),
});

const mapDispatchToProps = {
  loadInvitations: InvitationList.loadMany,
};

const MainInvitationsTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationsTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainInvitationsTable);
