// @ts-nocheck
import React from "react";
import {
  FeedbackWrapper,
  SerialNumber,
  ListItem,
  FeedbackListWrapper,
  DraftTime,
} from "./styles";
import { Box } from "src/components/Core/Box";
import { Divider } from "antd";
import { Typography } from "src/components/Core/Typography";

interface Props {
  feedbacks: Array<string>;
  draftType: string;
}

const RedraftContent: React.FC<Props> = ({ feedbacks, draftType }) => {
  return (
    <>
      <Divider />
      <FeedbackListWrapper>
        <Typography
          size={14}
          weight="semi-bold"
          color="black"
          mb={20}
          lineHeight={0}
        >
          Feedback:
        </Typography>

        {feedbacks.map((feedback, index) => {
          let time = "";

          if (draftType === "youtube") {
            let startPos = feedback.lastIndexOf("[[[");
            let endPos = feedback.lastIndexOf("]]]");

            if (startPos > -1 && endPos > -1) {
              time = feedback.substring(startPos + 3, endPos);
              feedback = feedback.substring(0, startPos);
            }
          }
          return (
            <FeedbackWrapper key={index}>
              <Box display="flex">
                <SerialNumber>{index + 1}.</SerialNumber>
                <ListItem>{feedback}</ListItem>
              </Box>
              {draftType === "youtube" && <DraftTime>{time}</DraftTime>}
            </FeedbackWrapper>
          );
        })}
      </FeedbackListWrapper>
    </>
  );
};

export default RedraftContent;
