// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import { FormikProps } from "formik";
import { HubCheckbox } from "./styles";

interface Props {
  formProps: FormikProps<any>;
  hubLinkData: any;
  getStatus: (value: string) => any;
}

const PaymentDetail: React.FC<Props> = ({
  formProps,
  hubLinkData,
  getStatus,
}) => {
  const value = formProps?.values;
  return (
    <Box mt={10}>
      <Box display="inline-flex">
        <HubCheckbox
          name="paymentDetail"
          onChange={() => {
            formProps?.setFieldTouched("paymentDetail");
            formProps?.setFieldValue("paymentDetail", !value.paymentDetail);
          }}
          disabled={hubLinkData?.paymentDetail?.status === "complete"}
          value={value.paymentDetail}
          data-cy="checkbox-paymentDetail"
          checked={value.paymentDetail}
        />
        <Typography size={14} color="black2" ml={10} mb={10} fontWeight={600}>
          Payment details
        </Typography>

        {value.paymentDetail && getStatus(hubLinkData?.paymentDetail?.status)}
      </Box>
      <Typography size={12} color="black">
        This will allow influencers to submit payment details
      </Typography>
    </Box>
  );
};

export default PaymentDetail;
