import { attr, Model } from "redux-orm";
import formatCurrency from "src/utils/formatCurrency";

class CampaignPackage extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const parsedData = {
      ...data,
    };

    return this.upsert(parsedData);
  }

  formattedInvitedSpend() {
    return this.invited_spend_pennies
      ? formatCurrency(this.invited_spend_pennies)
      : "£--";
  }

  formattedAcceptedSpend() {
    return this.accepted_spend_pennies
      ? formatCurrency(this.accepted_spend_pennies)
      : "£--";
  }
}

CampaignPackage.modelName = "CampaignPackage";

// Declare your related fields.
CampaignPackage.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
};

export default CampaignPackage;
