// @ts-nocheck
import React from "react";
import { useCampaignsListDetails } from "../CampaignsListProvider";
import CreateModal from "../../Campaigns/CreateModal";

interface Props {
  closeCampaignModal: () => void;
}

const NewCampaignModalWrapper = ({ closeCampaignModal }: Props) => {
  const { actions } = useCampaignsListDetails();

  const onSucessAddNewCampaign = () => {
    let container = {} as any;
    container["page"] = 1;
    actions.setFilters({ ...container });

    actions.refetchCampaigns({ ...container });

    closeCampaignModal();
  };
  return <CreateModal onCreate={onSucessAddNewCampaign} />;
};

export default NewCampaignModalWrapper;
