import React, { Component } from "react";
import styled from "styled-components";
import { Spin } from "antd";

const Wrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default class Loading extends Component {
  render() {
    const { spinning } = this.props;
    const spinner = <Spin {...this.props} />;

    return spinning ? (
      this.props.children ? (
        spinner
      ) : (
        <Wrapper>{spinner}</Wrapper>
      )
    ) : (
      this.props.children
    );
  }
}

Loading.defaultProps = {
  spinning: true
};
