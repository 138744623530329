// @ts-nocheck
import React, { useRef } from "react";
import styled from "styled-components";
import { useScreenClass } from "react-grid-system";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";
import watch from "src/assets/images/reports/clock.png";
import eye from "src/assets/images/reports/eyes.png";
import { useInViewport } from "react-in-viewport";
import { useSpring, animated, config as springConfig } from "react-spring";
import FadeInSection from "../../../FadeInSection";
import ParallaxSection from "../../../ParallaxSection";

const WatchImg = styled("img")`
  && {
    position: relative;
    left: 0px;

    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      height: 102px;
      width: 130px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      height: 200px;
      width: 250px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      height: 350px;
      width: 450px;
    }
  }
`;

const EyeImg = styled("img")`
  && {
    position: relative;
    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 320px) {
      height: 102px;
      width: 130px;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width: 768px) {
      height: 200px;
      width: 250px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width: 992px) {
      height: 350px;
      width: 450px;
    }
  }
`;

interface Props {
  totalWatchedTime: number;
}

const Stats: React.FC<Props> = ({ totalWatchedTime }) => {
  const screenClass = useScreenClass();

  let options = {};
  let props = {};

  const counter = useRef<HTMLInputElement | null>(null);
  const { enterCount: enterCountcounter } = useInViewport(
    counter,
    options as any,
    { disconnectOnLeave: false } as any,
    props as any
  );

  let totalSeconds = totalWatchedTime;

  // calculate (and subtract) whole days
  let countDays = Math.floor(totalSeconds / 86400);
  totalSeconds -= countDays * 86400;

  // calculate (and subtract) whole hours
  let countHours = Math.floor(totalSeconds / 3600) % 24;

  const days = useSpring({
    number: enterCountcounter >= 1 ? countDays : 0,
    config: springConfig.molasses,
  });
  const hours = useSpring({
    number: enterCountcounter >= 1 ? countHours : 0,
    config: springConfig.molasses,
  });

  return (
    <FadeInSection>
      <Box mt={100}>
        <Box>
          <ParallaxSection direction="down">
            <WatchImg src={watch} alt="Watch" />
          </ParallaxSection>
        </Box>

        <Box textAlign="center" mb={50}>
          <Typography
            fontSize={screenClass === "xs" || screenClass === "sm" ? 32 : 55}
            fontWeight={600}
            color="white"
            mb={10}
          >
            Total watch time
          </Typography>

          <Box
            display="inline-flex"
            alignItems="baseline"
            mt={20}
            ref={counter as any}
          >
            <Box
              display={
                screenClass === "sm" || screenClass === "xs"
                  ? "inline-flex"
                  : "block"
              }
              alignItems={
                screenClass === "sm" || screenClass === "xs"
                  ? "baseline"
                  : "center"
              }
            >
              <Typography
                fontSize={
                  screenClass === "sm" || screenClass === "xs" ? 50 : 100
                }
                style={{ fontWeight: "bold" }}
                color="white"
                mb={0}
              >
                <animated.span>
                  {days?.number.interpolate(x => (x as number)?.toFixed(0))}
                </animated.span>
              </Typography>
              <Typography
                fontSize={
                  screenClass === "xs" || screenClass === "sm" ? 18 : 24
                }
                fontWeight={500}
                color="white"
                marginLeft={
                  screenClass === "sm" || screenClass === "xs" ? "5px" : "0px"
                }
              >
                Days
              </Typography>
            </Box>
            <Box
              ml={20}
              display={
                screenClass === "sm" || screenClass === "xs"
                  ? "inline-flex"
                  : "block"
              }
              alignItems={
                screenClass === "sm" || screenClass === "xs"
                  ? "baseline"
                  : "center"
              }
            >
              <Typography
                fontSize={
                  screenClass === "xs" || screenClass === "sm" ? 30 : 60
                }
                fontWeight={600}
                color="white"
                mb={0}
              >
                <animated.span>
                  {hours?.number.interpolate(x => (x as number)?.toFixed(0))}
                </animated.span>
              </Typography>
              <Typography
                fontSize={
                  screenClass === "xs" || screenClass === "sm" ? 18 : 24
                }
                fontWeight={500}
                color="white"
                marginLeft={
                  screenClass === "sm" || screenClass === "xs" ? "5px" : "0px"
                }
                lineHeight={
                  screenClass === "xl" ||
                  screenClass === "lg" ||
                  screenClass === "md"
                    ? "64px"
                    : "0px"
                }
              >
                Hours
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <ParallaxSection direction="up">
            <EyeImg src={eye} alt="Eye" />
          </ParallaxSection>
        </Box>
      </Box>
    </FadeInSection>
  );
};

export default Stats;
