// @ts-nocheck
import React, { Fragment } from 'react'
import AgeSection from './AgeSection'
import { schema } from './Validations'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import Form from 'src/components/Forms/Form'
import LocationSection from './LocationSection'
import { Typography } from 'src/components/Core/Typography'

export const StyledRow = styled(Row)`
  min-width: 900px;
  @media screen and (max-width: 768px) {
    min-width: auto;
  }
`

export const StyledCol = styled(Col)`
  padding-top: 90px;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
`


interface Props {
  account: any
  handleSubmit: Function
}

const DemographicForm = ({handleSubmit, account}: Props) => {
  const locationBreakdown = [
    { key: "", value: 0 },
    { key: "", value: 0 },
    { key: "", value: 0 },
  ]

  return(
    <Fragment>
      <Typography size={20} color="black6" mb={0} mt={20}>Demographic data</Typography>
      <Row>
        <Typography size={16} color="grey2">Please provide the following audience details about your</Typography>
        <b><Typography size={16} color="grey2">&nbsp;@{account?.username}&nbsp;</Typography></b>
        <Typography size={16} color="grey2">profile</Typography>
      </Row>
      <Form
        initialValues={{ locationBreakdown: locationBreakdown, ageScreenshot: undefined, locationScreenshot: undefined }}
        schema={schema}
        onSubmit={(values, actions) => handleSubmit(values)}
      >
        {(formProps: any) => {
          return (
            <Fragment>
              {console.log('errors', formProps.errors)}
              {console.log('formprops', formProps)}
              <AgeSection formProps={formProps} />
              <LocationSection formProps={formProps} />
              <Button type="primary" onClick={formProps.handleSubmit}>Continue</Button>
            </Fragment>
          )
        }}
      </Form>
    </Fragment>
  )
}

export default DemographicForm
