import React from "react";
import { DynamicModuleLoader } from "redux-dynamic-modules";

// This function takes a component...
const withModules = WrappedComponent => modules => {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <DynamicModuleLoader modules={modules}>
          <WrappedComponent {...this.props} />
        </DynamicModuleLoader>
      );
    }
  };
};

export default withModules;
