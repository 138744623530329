// @ts-nocheck
import React, { useState } from "react";
import { Typography } from "src/components/Core/Typography";
import moment from "moment";
import ReactVideoPlayer from "./ReactVideoPlayer";

interface Props {
  videoUrl: string;
}

const Duration: React.FC<Props> = ({ videoUrl }) => {
  const [duration, setDuration] = useState(0);

  return (
    <>
      <ReactVideoPlayer
        videoUrl={videoUrl}
        isPlay={false}
        onVideoLoad={duration => {
          setDuration(duration);
        }}
        style={{ display: "none" }}
      />
      <Typography size={12} mb={0}>
        {moment.utc(duration * 1000).format("mm:ss")}
      </Typography>
    </>
  );
};

export default Duration;
