import { string, object, boolean } from "yup"

export default object().shape({

  link_tracked: boolean(),

  default_link: string()
    .nullable()
    .when("link_tracked", {
      is: true,
      then: string()
        .required("Please enter as URL")
        .url("Must be a valid URL (please include http://)"),
    }),

  integration_partner: string()
    .default(null)
    .nullable()
    .notRequired(),

  ios_id: string()
    .nullable()
    .test("ios_id", "Please enter either an iOS or Android identifier", function(value) {
      const { integration_partner, android_id } = this.parent
      if (integration_partner && !android_id) return value != null
      return true
    }),

  android_id: string()
    .nullable()
    .test("android_id", "Please enter either an iOS or Android identifier", function(value) {
      const { integration_partner, ios_id } = this.parent
      if (integration_partner && !ios_id) return value != null
      return true
    })
})
