// @ts-nocheck
import React from "react";
import { Wrapper, NewIcon, SuccessIcon, Message } from "./styles";
import success from "src/assets/images/success.png";

interface Props {
  type?: "warning" | "confirm" | undefined;
  message?: String;
}

const IconMessage: React.FC<Props> = ({ type, message }) => {
  return (
    <Wrapper>
      {type === "warning" && (
        <NewIcon type="exclamation-circle" color="warning" />
      )}
      {type === "confirm" && <SuccessIcon src={success} />}
      <Message type={type}>{message}</Message>
    </Wrapper>
  );
};

export default IconMessage;
