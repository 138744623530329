// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikSelect } from "src/components/Forms/Select";
import { RemoveIcon } from "../../../Wrapper";
import { FormikProps } from "formik";

interface Props {
  admins: any;
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Admins: React.FC<Props> = ({
  admins,
  formProps,
  clearFilterKey,
}) => {
  let isVisible = false;

  if (formProps?.values?.adminExecId) {
    isVisible = true;
  }

  return (

    <Field
      label="Admin/Exec:"
      mb={20}
      isBeside={true}
      color="black"
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("adminExecId", undefined);
              clearFilterKey("adminExecId");
            }}
            style={{ marginTop: "-1px", marginLeft: "10px" }}
          />
        )
      }
    >
      <FormikSelect
        name="adminExecId"
        size="small"
        loadData={async value => {
          let { data } = await admins.refetch({
            adminName: value,
          });
          if (data?.getAllAdmins?.admins?.length > 0) {
            return data.getAllAdmins?.admins;
          } else {
            return [];
          }
        }}
        optionGenerator={value => ({
          value: value.id,
          label: value.name,
        })}
        borderRadius="3px"
        borderColor="grey5"
        minHeight={35}
      />
    </Field>

  );
};

export default Admins;
