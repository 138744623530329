// @ts-nocheck
import React, { Fragment, useState } from 'react'
import {
  Title,
  Tags
} from './FormFields'
import { Modal } from 'antd'
import { Schema } from 'yup'
import { FormikValues } from 'formik'
import Form from 'src/components/Forms/Form'
import { FilterContainer } from './container'
import { Box } from 'src/components/Core/Box'

interface FilterFormProps<Values = FormikValues> {
  initialValues: Values;
  FilterSchema: Schema<object>
  allTags: any[]
  tagsLoading: boolean
  handleFilter: Function
}

const FilterForm = ({
  FilterSchema,
  allTags,
  tagsLoading,
  handleFilter,
  initialValues
}: FilterFormProps) => {
  const [isModalVisible, setModalVisible] = useState(false)
  return(
    <Box p={24}>
      <Form
        initialValues={initialValues}
        schema={FilterSchema}
        onSubmit={(values, actions) => handleFilter(values)}
      >
        {(formProps: any) => {
          return(
            <Fragment>
              <Title
                formProps={formProps}
                setModalVisible={() => setModalVisible(!isModalVisible)}
              />
              {window.innerWidth > 768 ?
                <Tags tags={allTags} loading={tagsLoading} formProps={formProps} />
                :
                <Modal visible={isModalVisible} footer={null} onCancel={() => setModalVisible(!isModalVisible)}>
                  <Tags tags={allTags} loading={tagsLoading} formProps={formProps} />
                </Modal>
              }
            </Fragment>
          )
        }}
      </Form>
    </Box>
  )
}

export { FilterForm, FilterContainer };
