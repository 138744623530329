import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "src/shared/Container";
import withModules from "src/utils/hoc/withModules";
import { default as SharedProfile } from "src/shared/Profile";
import { AccountPersist } from "src/ducks/Persist";
import NoAuthLayout from "../../layouts/NoAuthLayout";

const ShowAccount = AccountPersist("ProfileShow");

const modules = [ShowAccount.module()];

class Profile extends Component {
  render() {
    const { account } = this.props;

    return (
      <NoAuthLayout title={account ? account.username : "Loading..."}>
        <Container style={{ paddingBottom: 30 }}>
          <SharedProfile />
        </Container>
      </NoAuthLayout>
    );
  }
}

const mapStateToProps = state => ({
  account: ShowAccount.selectOne(state)
});

const mapDispatchToProps = {};

export default withModules(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Profile)
)(modules);
