// @ts-nocheck
import React from "react";
import { Descriptions } from "antd";
import moment from "moment";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import numeral from "numeral";
import { Link } from "react-router-dom";
import formatCurrency from "src/utils/formatCurrency";
import { currency as allCuurency } from "src/constants/payments";

interface Props {
  paymentDetail: any;
}

const BasicDetails: React.FC<Props> = ({ paymentDetail }) => {
  let currencySign = find(
    allCuurency,
    matchesProperty("value", paymentDetail?.currency)
  );

  return (
    <Descriptions size="small" bordered column={1}>
      <Descriptions.Item label="Campaign:">
        <Link to={`/campaigns/${paymentDetail?.campaign?.id}`}>
          {paymentDetail?.campaign ? paymentDetail?.campaign?.title : ""}
        </Link>
      </Descriptions.Item>
      <Descriptions.Item label="HubSpot ID:">
        {paymentDetail?.campaign ? paymentDetail?.campaign?.hubspotDealId : ""}
      </Descriptions.Item>
      <Descriptions.Item label="Influencer Account:">
        {paymentDetail?.influencer ? (
          <Link to={`/influencers/${paymentDetail?.influencer?.id}`}>
            {paymentDetail?.influencer ? paymentDetail?.influencer?.name : ""}
          </Link>
        ) : (
          "-"
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Requested by:">
        {paymentDetail?.requestedBy ? paymentDetail?.requestedBy?.name : ""}
      </Descriptions.Item>

      <Descriptions.Item label="Cost type:">
        {paymentDetail?.description ? paymentDetail?.description : ""}
      </Descriptions.Item>

      <Descriptions.Item label="Requested date:">
        {paymentDetail?.createdAt
          ? moment(paymentDetail?.createdAt).format("Do MMM YYYY")
          : ""}
      </Descriptions.Item>

      <Descriptions.Item label="Total amount:">
        {currencySign?.label}
        {paymentDetail?.amount
          ? numeral(paymentDetail?.amount / 100).format("0,0[.]00")
          : ""}
      </Descriptions.Item>

      <Descriptions.Item label="Amount excl. VAT:">
        {formatCurrency(paymentDetail?.payoutPenniesExclVat)}
      </Descriptions.Item>
      <Descriptions.Item label="VAT paid:">
        {formatCurrency(paymentDetail?.vatAmount)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default BasicDetails;
