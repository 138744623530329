// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { InfluencerCardContainer } from "./container";
import Loading from "src/shared/Loading";
import { Avatar, Row, Col } from "antd";
import { RatingInput } from "src/components/Forms/Rating";
import UpdateInfluencerModal from "./UpdateInfluencerModal";
import { Tag } from "src/components/Core/Tag";
import Punctuality from "src/assets/images/icon1.png";
import Professionalism from "src/assets/images/icon2.png";
import Quality from "src/assets/images/icon3.png";
import styled from "styled-components";
import { Icon } from '@ant-design/compatible'

const SettingIcon = styled(Icon)`
  && {
  }
  &:hover {
    color: ${props => props.theme.colors.blue} !important;
  }
`;

interface Props {
  loading: boolean;
  showInfluencerForm: boolean;
  setShowInfluencerForm: (value: boolean) => void;
  influencerDetails: any;
}

const InfluencerCard: React.FC<Props> = ({
  loading,
  showInfluencerForm,
  setShowInfluencerForm,
  influencerDetails,
}) => {
  return (
    <Box p={12} borderBottom="1px solid #E2E2E2">
      <Loading spinning={loading}>
        <Box display="inline-flex">
          <Box mt="-4px">
            <Avatar src={influencerDetails?.pictureUrl} size={40} />
          </Box>
          <Box pl={15} width="135px">
            <Typography
              size={16}
              weight="semi-bold"
              color="black"
              lineHeight="22px"
            >
              {influencerDetails?.name}
            </Typography>
            <Typography size={10} color="blue" lineHeight={0}>
              <a href={`mailto:${influencerDetails?.email}`}>
                {influencerDetails?.email}
              </a>
            </Typography>
          </Box>
          <Box ml={20} color={showInfluencerForm ? "blue" : "grey6"}>
            <SettingIcon
              type="setting"
              onClick={() => setShowInfluencerForm(true)}
            />
          </Box>
        </Box>
        <Box mb="5px" mt="15px">
          <Row gutter={8}>
            <Col span={12}>
              <Box display="inline-flex">
                <Box mr="4px;">
                  <img src={Punctuality} alt="Punctuality" />
                </Box>
                <Box>
                  <RatingInput
                    size={14}
                    defaultValue={influencerDetails?.punctuality}
                    value={influencerDetails?.punctuality}
                    allowHalf
                    disabled
                  />
                </Box>
              </Box>
            </Col>
            <Col span={12}>
              <Box display="inline-flex">
                <Box mr="4px;">
                  <img src={Professionalism} alt="Professionalism" />
                </Box>
                <Box>
                  <RatingInput
                    size={14}
                    defaultValue={influencerDetails?.professionalism}
                    value={influencerDetails?.professionalism}
                    allowHalf
                    disabled
                  />
                </Box>
              </Box>
            </Col>
          </Row>
        </Box>
        <Box mt="15px">
          <Row gutter={16}>
            <Col span={14}>
              <Box display="inline-flex">
                <Box mr="4px;">
                  <img src={Quality} alt="Quality" />
                </Box>
                <Box>
                  <RatingInput
                    size={14}
                    defaultValue={influencerDetails?.quality}
                    value={influencerDetails?.quality}
                    allowHalf
                    disabled
                  />
                </Box>
              </Box>
            </Col>
            <Col span={12}></Col>
          </Row>
        </Box>

        <Box mt={10}>
          {(influencerDetails?.flags || []).map((flag, index) => {
            return (
              <Tag
                size={12}
                tagColor="white1"
                background="red3"
                borderColor="red4"
                key={index}
                marginBottom="6px"
              >
                {flag}
              </Tag>
            );
          })}
        </Box>
      </Loading>
      <UpdateInfluencerModal
        closeInfluencerModal={() => setShowInfluencerForm(false)}
        showInfluencerForm={showInfluencerForm}
      />
    </Box>
  );
};

export { InfluencerCard, InfluencerCardContainer };
