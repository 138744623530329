// @ts-nocheck
import React from "react";
import { Wrapper, DraftText, DraftTime } from "./styles";
import { Box } from "src/components/Core/Box";

interface Props {
  listNumber: number;
  feedback: string;
  type: string;
}

const ListItem: React.FC<Props> = ({ listNumber, feedback, type }) => {
  let time = "";
  if (type === "youtube") {
    let startPos = feedback.lastIndexOf("[[[");
    let endPos = feedback.lastIndexOf("]]]");

    if (startPos > -1 && endPos > -1) {
      time = feedback.substring(startPos + 3, endPos);
      feedback = feedback.substring(0, startPos);
    }
  }
  return (
    <Wrapper>
      <Box display="flex">
        <h3>{listNumber}. </h3>
        <DraftText>{feedback}</DraftText>
      </Box>
      {type === "youtube" && <DraftTime>{time}</DraftTime>}
    </Wrapper>
  );
};

export default ListItem;
