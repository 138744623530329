// @ts-nocheck
import React, { useState } from 'react'
import { ApplyForm } from '.'
import { isEmpty } from 'lodash'
import Auth from 'src/ducks/Auth'
import { useSelector } from 'react-redux'
import {
  useApplyPublicCampaignMutation,
  useApplyPublicCampaignForExpressMutation
} from 'src/generated/ApolloComponents'
import {
  useMapGraphQLErrorsMessages
} from 'src/utils/hooks/useMapGraphQLErrors'

interface Props {
  campaign: any
  refetchCampaign: Function
  influencerAccounts: [any]
  moveTo: () => void
  isMobile?: boolean
}

export const ApplyContainer = ({
  campaign,
  influencerAccounts,
  refetchCampaign,
  moveTo,
  isMobile
}: Props) => {
  const [ applyPublicCampaign, publicCampaign ] = useApplyPublicCampaignMutation()
  const [ applyPublicCampaignExpress, publicCampaignExpress ] = useApplyPublicCampaignForExpressMutation()
  const [accId, setAccId] = useState()
  const [email, setEmail] = useState()
  const user = useSelector(state => Auth.selectUser(state))
  const loggedIn = !isEmpty(user)

  const globalErrors = useMapGraphQLErrorsMessages(publicCampaign?.error || publicCampaignExpress.error)

  const handleApply = async ({email, username, platform, fee}) => {
    try {
      if(!loggedIn){
        const res = await applyPublicCampaignExpress({
          variables: {
            email: email,
            expectedFee: fee,
            username: username,
            platform: platform,
            campaignId: campaign.id,
          }
        })
        if(res.data?.applyPublicCampaignForExpress?.id){
          setEmail(email)
        }
      } else {
        const res = await applyPublicCampaign({
          variables: {
            accountId: Number(accId),
            campaignId: campaign.id
          }
        })
        if(res.data?.applyPublicCampaign?.id) {
          refetchCampaign()
        }
      }
    } catch(e) {
      console.log(e)
    }
  }

  return(
    <ApplyForm
      accId={accId}
      email={email}
      moveTo={moveTo}
      loggedIn={loggedIn}
      setAccId={setAccId}
      isMobile={isMobile}
      campaignId={campaign.id}
      handleApply={handleApply}
      globalErrors={globalErrors}
      applied={campaign?.isApplied}
      influencerAccounts={influencerAccounts}
    />
  )
}
