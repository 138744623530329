// @ts-nocheck
import React from 'react'
import { Milestone } from '.'
import {
  useGetCampaignMilestonesQuery
} from 'src/generated/ApolloComponents'
import { useRouteMatch } from 'react-router'
import { useMapGraphQLErrorsMessages } from 'src/utils/hooks/useMapGraphQLErrors'
import Auth from 'src/ducks/Auth'
import { connect } from 'react-redux'

const MilestoneContainer = ({user}) => {
  const match: any = useRouteMatch();
  const params: any = match?.params;

  const campaignId = parseInt(params?.id);

  const { data, loading, error, refetch } = useGetCampaignMilestonesQuery({
    variables: { campaignId: campaignId },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })

  const globalErrors = useMapGraphQLErrorsMessages(error)

  return(
    <Milestone
      milestones={data?.getCampaignMilestones?.milestones || []}
      refetch={refetch}
      loading={loading}
      globalErrors={globalErrors}
      user={user}
    />
  )
}

const mapStateToProps = state => ({
  user: Auth.selectUser(state),
});

export default connect(mapStateToProps)(MilestoneContainer);
