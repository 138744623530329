import React, { Component } from "react";
import FormField from "../FormField";
import { Select } from "formik-antd";
import { currency } from "src/constants/payments";

export default class PayoutCurrency extends Component {
  render() {
    const { name, onChangeCurrency } = this.props;

    return (
      <FormField name={name || "payout_type_currency"} label="Payout Currency">
        <Select
          data-cy="invitation-payout-currency"
          size="large"
          placeholder="Select..."
          onChange={value => onChangeCurrency(value)}
          options={currency}
        >
        </Select>
      </FormField>
    );
  }
}
