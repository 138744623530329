// @ts-nocheck
import React, { useState } from "react";
import { Row, Col, Avatar, Popover, Badge } from "antd";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { Tag } from "src/components/Core/Tag";
import styled from "styled-components";
import CreateSuggestionModal from "./CreateSuggestionModal";
import formatCountries from "src/utils/formatCountries";
import BreakdownData from "./BreakdownData";
import { Link } from "react-router-dom";
import { Icon } from '@ant-design/compatible'

const AccountPopover = styled(Popover)`
  &&& {
    .ant-popover-content {
      width: 800px !important;
    }
  }
`;

const EllipsisText = styled("span")`
  &&& {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
  }
`;

const AccountAvatar = styled("div")`
  &&& {
    opacity: 0.5;
  }

  &:hover {
    opacity: 1 !important;
    .ant-avatar {
      border: 1px solid #1890ff;
    }
  }
  & .ant-badge {
    opacity: 0;
  }
  &:hover .ant-badge {
    opacity: 1 !important;
  }
`;

const PlusIcon = styled(Icon)`
  &&& {
    color: #1890ff;
    top: 17px;
    right: 6px;
    cursor: pointer;
  }
`;

const MinusIcon = styled(Icon)`
  &&& {
    color: #f5222d;
    top: 17px;
    right: 41px;
    cursor: pointer;
  }
`;

interface Props {
  account: any;
  campaign: any;
  onDismiss: (value: any) => void;
  refetchSuggestion: () => void;
}

const AccountCard: React.FC<Props> = ({
  account,
  onDismiss,
  refetchSuggestion,
  campaign,
}) => {
  const [showCreateSuggestionModal, setCreateSuggestionModal] = useState(false);

  const getAccountDataHeader = account => {
    return (
      <Box width="800px">
        <Row gutter={8}>
          <Col span={12}>
            <Typography
              size={16}
              weight="semi-bold"
              color="blue"
              lineHeight={0}
              mt={15}
              ml={10}
            >
              <Link to={`/accounts/${account?.id}`}>
                <EllipsisText>@{account?.username}</EllipsisText>
              </Link>
            </Typography>
          </Col>
          <Col span={12} style={{}}>
            <Box display="flex" justifyContent="flex-end" mt="3px">
              {account?.influencer?.detail?.age?.[0] && (
                <Tag
                  size={10}
                  tagColor="black2"
                  background="white1"
                  borderColor="grey8"
                  marginBottom="6px"
                >
                  {account?.influencer?.detail?.age?.[0]}
                </Tag>
              )}

              {account?.type && (
                <Tag
                  size={10}
                  tagColor="black2"
                  background="white1"
                  borderColor="grey8"
                  marginBottom="6px"
                >
                  {account?.type}
                </Tag>
              )}

              {account?.influencer?.detail?.location?.[0] && (
                <Tag
                  size={10}
                  tagColor="black2"
                  background="white1"
                  borderColor="grey8"
                  marginBottom="6px"
                >
                  {formatCountries(account?.influencer?.detail?.location?.[0])}
                </Tag>
              )}
            </Box>
          </Col>
        </Row>
      </Box>
    );
  };

  return (
    <Box>
      <AccountPopover
        content={<BreakdownData account={account} />}
        title={getAccountDataHeader(account)}
      >
        <AccountAvatar>
          <Avatar size={50} src={account?.profilePicUrl} />

          <Badge
            count={
              <MinusIcon
                type="minus-circle"
                theme="filled"
                onClick={() => onDismiss(account?.id)}
              />
            }
          ></Badge>
          <Badge
            count={
              <PlusIcon
                type="plus-circle"
                theme="filled"
                onClick={() => setCreateSuggestionModal(true)}
              />
            }
          ></Badge>
        </AccountAvatar>
      </AccountPopover>

      <CreateSuggestionModal
        closeCreateSuggestionModal={() => {
          setCreateSuggestionModal(false);
        }}
        showCreateSuggestionModal={showCreateSuggestionModal}
        account={account}
        refetchSuggestion={refetchSuggestion}
        campaign={campaign}
      />
    </Box>
  );
};

export default AccountCard;
