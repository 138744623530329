// @ts-nocheck
import React, { useState } from "react";
import { BankDetailForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { usePaymentDetails } from "../../PaymentDetailsProvider";
import { BankDetailSchema } from "./Validations";
import { bankDetailFields } from "./Steps/BankDetailFields";

interface Props {
  paymentType: string;
  setPaymentType: (value: string) => void;
}

interface BankDetailFormValues {
  countryCode: string;
  fullName?: string;
  accountNumber?: string;
  sortCode?: string;
  recipientAddress?: string;
  routingNumber?: string;
  swift?: string;
  iban?: string;
  bsbCode?: string;
  bankName?: string;
  transitNumber?: string;
  institutionNumber?: string;
}

export const BankDetailFormContainer: React.FC<Props> = ({
  paymentType,
  setPaymentType,
}) => {
  const [showBankDetailForm, setShowBankDetailForm] = useState(false);
  const { actions: contextAction, actualPaymentDetails } = usePaymentDetails();

  let initialValues = {
    countryCode: actualPaymentDetails?.bankAccount?.countryCode || "GB",
    fullName: actualPaymentDetails?.bankAccount?.fullName || "",
    accountNumber: actualPaymentDetails?.bankAccount?.accountNumber || "",
    sortCode: actualPaymentDetails?.bankAccount?.sortCode || "",
    recipientAddress: actualPaymentDetails?.bankAccount?.recipientAddress || "",
    routingNumber: actualPaymentDetails?.bankAccount?.routingNumber || "",
    swift: actualPaymentDetails?.bankAccount?.swift || "",
    iban: actualPaymentDetails?.bankAccount?.iban || "",
    bsbCode: actualPaymentDetails?.bankAccount?.bsbCode || "",
    bankName: actualPaymentDetails?.bankAccount?.bankName || "",
    transitNumber: actualPaymentDetails?.bankAccount?.transitNumber || "",
    institutionNumber:
      actualPaymentDetails?.bankAccount?.institutionNumber || "",
  };

  const onSubmit = async (
    values: BankDetailFormValues,
    actions: FormikActions<BankDetailFormValues>
  ) => {
    try {
      let bankAccountDetail: BankDetailFormValues = {
        countryCode: values?.countryCode,
      };

      let container = {} as any;

      bankDetailFields[values?.countryCode].fields.forEach(item => {
        container[item.name] = values[item.name].toString();
      });

      bankAccountDetail = {
        ...bankAccountDetail,
        ...container,
      };

      actualPaymentDetails.bankAccount = bankAccountDetail;

      contextAction.setActualPaymentDetails(actualPaymentDetails);
      actions.setSubmitting(false);
      setShowBankDetailForm(false);
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <BankDetailForm
      initialValues={initialValues}
      BankDetailSchema={BankDetailSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      isSubmitting={false}
      showBankDetailForm={showBankDetailForm}
      setShowBankDetailForm={value => setShowBankDetailForm(value)}
      paymentType={paymentType}
      setPaymentType={value => setPaymentType(value)}
    />
  );
};
