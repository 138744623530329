// @ts-nocheck
import React from 'react'
import { Row, Col, Button } from 'antd'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  color: #FF4D4F !important;
  background: #F5F5F5 !important;
  border: none !important;
  font-size: 11.57px !important;
`
interface Props {
  toggleVisibility: () => void
}

const Footer = ({toggleVisibility}: Props) => {
  return(
    <Row justify="end">
      <Col><StyledButton onClick={() => toggleVisibility()}>Cancel</StyledButton></Col>
      &nbsp;&nbsp;
      <Col>
        <Button type="primary" style={{fontSize: '11.57px'}} htmlType="submit">
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default Footer
