// @ts-nocheck
import React from 'react'
import styled from "styled-components";
import { Popover } from "antd";
import { Link } from "react-router-dom";

const EllipsisText = styled.span<{
  isInnerAccountList: boolean | undefined;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-left: 5px;
  color: #1890ff;
  display: block;
  white-space: nowrap;
  width: ${props => (props.isInnerAccountList ? "75px" : "105px")};
`;

interface Props {
  account: any;
  isInnerAccountList: boolean;
  menu: Function
}

const UserNameContent = ({account, isInnerAccountList, menu}: Props) => {
  return isInnerAccountList ? (
    <Popover content={menu(account)} title={account?.username}>
      <Link to={`/accounts/${account?.id}`}>
        <EllipsisText isInnerAccountList={isInnerAccountList}>
            @{account?.username}
          </EllipsisText>
      </Link>
    </Popover>
  )
  : (
    <Popover content={account?.username}>
      <Link to={`/accounts/${account?.id}`}>
        <EllipsisText isInnerAccountList={isInnerAccountList}>
          @{account?.username}
        </EllipsisText>
      </Link>
    </Popover>

  )
}

export default UserNameContent;
