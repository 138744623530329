import { Model } from "redux-orm";
import moment from "moment";

class Agency extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const parsedData = {
      ...data,
    };

    return this.upsert(parsedData);
  }

  formattedCreatedAt() {
    return this.created_at ? moment(this.created_at).format("Do MMM YYYY") : "";
  }
}
Agency.modelName = "Agency";

// Declare your related fields.
Agency.fields = {};

export default Agency;
