// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";

interface Props {}

const Caption: React.FC<Props> = () => {
  return (
    <Box textAlign="left">
      <Field label="Caption:" mb={26}>
        <FormikInput
          name="caption"
          size="middle"
          borderRadius={2}
          borderColor="grey5"
          placeholder="Caption"
        />
        <Typography size={14} color="grey7">
          Please enter the caption you would post with your content.
        </Typography>
      </Field>
    </Box>
  );
};

export default Caption;
