// @ts-nocheck
import React from "react"
import { object, string } from "yup"
import { PostingGuidelines } from "."
import {
  GiftingCampaignFragmentFragment,
  UpdateGiftingCampaign
} from "src/generated/ApolloComponents"
import { FormikHelpers as FormikActions } from "formik"

interface Props {
  nextStep: Function
  campaign?: GiftingCampaignFragmentFragment
  loading: boolean
  initialValues?: UpdateGiftingCampaign
  onSubmit: (values: any, formikActions: FormikActions<any>) => void
  previousStep: Function
  setStep: (number: number) => void
}

export const PostingGuidelinesContainer = ({
  previousStep,
  initialValues,
  setStep,
  campaign,
  onSubmit,
  loading
}: Props) => {
  const PostingGuidlinesSchema = object().shape({
    brief: object().shape({
      campaignType: string().required("You must complete this field"),
      aboutCampaign: string().required("You must complete this field")
    })
  })

  return (
    <PostingGuidelines
      onSubmit={onSubmit}
      loading={loading}
      campaign={campaign}
      initialValues={initialValues}
      setStep={setStep}
      previousStep={previousStep}
      schema={PostingGuidlinesSchema}
    />
  )
}
