import { Model } from "redux-orm";

class Admin extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const parsedData = {
      ...data
    };

    return this.upsert(parsedData);
  }
}
Admin.modelName = "Admin";

// Declare your related fields.
Admin.fields = {};

export default Admin;
