// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { Button as AntdButton } from "antd";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";

interface Props {
  type?: "confirm" | "action" | "warning" | "ghost" | "danger" | "disabled";
  disabled?: boolean;
  block?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  htmlType?: "submit";
  onClick?: Function;
  buttonSize?: "small" | "middle" | "large";
  style?: any;
  id?: string;
}


// @ts-ignore
const StyledButton = styled(({ buttonSize, ...props }) => (
  // @ts-ignore
  <AntdButton {...props} />
))`
  && {
    height: ${props =>
      props.buttonSize
        ? props.theme.components.buttons.height[props.buttonSize]
        : 45}px !important;

    background: ${props => props.theme.colors[props.type]};
    border-color: ${props => props.theme.colors[props.type]};

    :focus,
    :hover,
    :active {
      background: ${props =>
        props.type ? darken(0.05, props.theme.colors[props.type]) : null};
      border-color: ${props =>
        props.type ? darken(0.1, props.theme.colors[props.type]) : null};
      color: ${props => (props.type ? "#ffffff" : null)};
    }

    color: ${props => (props.type ? "#ffffff" : null)};

    letter-spacing: 0.69px;
    font-size: ${props =>
      props.buttonSize
        ? props.theme.components.buttons.fontSize[props.buttonSize]
        : 14}px !important;
    line-height: 16px;
    border-radius: 2px;
    font-weight: 400;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  }
  ${space}
  ${typography}
`;

export type ButtonProps = SpaceProps & TypographyProps;

export const Button: React.FC<Props & ButtonProps> = props => (
  <StyledButton {...props} />
);

export default Button;
