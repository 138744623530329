// @ts-nocheck
import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import HeroActions from "./HeroActions";
import DiscoverTab from "./DiscoverTab";

const Discover = () => {
  return (
    <DashboardLayout title="Discover">
      <HeroActions
        title="Discover"
        subTitle="Discover new influencers for campaigns, find new trends, and track account growth."
      />
      <DiscoverTab />
    </DashboardLayout>
  );
};

export default Discover;
