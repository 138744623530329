// @ts-nocheck
import React from "react";
import Modal from "src/components/Core/Modal";
import { AdUploadFormContainer } from "./AdUploadForm";

interface Props {
  showAdUpload: boolean;
  closeAdUploadModal: () => void;
  draftId: any;
  authorised: any;
  campaignId: number;
  advertisers: Array<any>;
}

const AdUploadModal = ({
  closeAdUploadModal,
  showAdUpload,

  draftId,
  authorised,
  campaignId,
  advertisers,
}: Props) => {
  return (
    <Modal
      title="Ad upload"
      subTitle="Please enter the ad ID you would like to upload this creative to:"
      width={600}
      showModal={showAdUpload}
      closeModal={closeAdUploadModal}
    >
      <AdUploadFormContainer
        closeAdUploadModal={closeAdUploadModal}
        draftId={draftId}
        authorised={authorised}
        campaignId={campaignId}
        advertisers={advertisers}
      />
    </Modal>
  );
};

export default AdUploadModal;
