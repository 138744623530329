// @ts-nocheck
import React from "react";
import { Empty } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Box } from "src/components/Core/Box";
import Button from "src/components/Core/Button";
import ConfirmDelete from "src/components/Forms/ConfirmDelete";
import { NotesListContainer } from "./container";
import Loading from "src/shared/Loading";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import moment from "moment";
import { useLocation } from "react-router";

const DeleteBox = styled(Box as any)`
  && {
    padding-left: 10px;
    p {
      opacity: 0.5;
    }
    p:hover {
      opacity: 1;
    }
  }
`;

interface Props {
  allNotes: Array<any>;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  deleteNote: (value: number) => void;
}
const NotesList: React.FC<Props> = ({
  allNotes,
  globalErrors,
  loading,
  deleteNote,
}) => {
  const location: any = useLocation();
  return (
    <Box mt={10} p={15}>
      <Loading spinning={loading}>
        <GlobalErrors errors={globalErrors} />
        <Box display="flex" justifyContent="space-between">
          <Typography size={24} weight="semi-bold" color="black" mr={30}>
            Notes
          </Typography>
          <Link to={`${location?.pathname.replace(/\/$/, "")}/add`} replace>
            <Button type="action" buttonSize="middle" data-cy="add-note">
              New Note
            </Button>
          </Link>
        </Box>
        {allNotes?.length > 0 ? (
          <>
            {allNotes.map((value, index) => (
              <div key={index}>
                <Box
                  borderRadius={2}
                  background="#FAFAFA"
                  p={10}
                  mt={20}
                  mb={10}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography size={16} weight="semi-bold" color="black">
                      {value?.title}
                    </Typography>
                    <Typography size={10} color="grey7">
                      {value?.createdAt
                        ? moment(value?.createdAt).format("DD. MM. YYYY")
                        : ""}
                    </Typography>
                  </Box>

                  <Typography size={14} color="black">
                    {value?.body}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <DeleteBox>
                    <ConfirmDelete
                      text="Delete"
                      title="Are you sure to delete this note?"
                      trigger="click"
                      onConfirm={e => {
                        e.preventDefault();
                        deleteNote(index);
                      }}
                    />
                  </DeleteBox>
                </Box>
              </div>
            ))}
          </>
        ) : (
          <Empty description="No data" />
        )}
      </Loading>
    </Box>
  );
};

export { NotesList, NotesListContainer };
