// @ts-nocheck
import React from "react";
import { FormikInput } from "src/components/Forms/TextInput";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";

interface Props {
  formProps: FormikProps<any>;
}

const Name = ({ formProps }: Props) => {

  return (
    <Field
      mb={20}
      color="black"
      isBeside={true}
      id="email"
      label="Email address"
    >
      <FormikInput
        size="middle"
        borderRadius={3}
        name="email"
        placeholder="john@doe.com"
      />
    </Field>
  );
};

export default Name;
