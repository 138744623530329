// @ts-nocheck
import React from "react";
import { Row, Col, Progress, Empty } from "antd";
import { Typography } from "src/components/Core/Typography";
import styled from "styled-components";
import values from "lodash/values";
import sum from "lodash/sum";
import has from "lodash/has";
import numeral from "numeral";
import { Box } from "src/components/Core/Box";
import { locations } from "src/constants/account";

const countryName = locations.reduce(function(result, item) {
  result[item.value] = item.label;
  return result;
}, {});

const StatBlock = styled(Col)`
  &&& {
    display: block;
    margin-bottom: 15px;
    padding-left: 0px !important;
  }
`;

const StatName = styled(Col)`
  &&& {
    display: block;
    font-size: 12px;
    float: left;
  }
`;

const StatValue = styled(Col)`
  &&& {
    font-weight: 500;
    float: right;
  }
`;

interface Props {
  account: any;
}

const BreakdownData: React.FC<Props> = ({ account }) => {
  let ageBreakdownData = account?.ageBreakdown?.manual || {};

  let genderBreakdownData = account?.genderBreakdown?.manual || {};

  let locationBreakdownData = account?.locationBreakdown?.manual || {};

  delete locationBreakdownData.other;

  locationBreakdownData = Object.keys(locationBreakdownData)
    .slice(0, 2)
    .reduce((result, key) => {
      result[key] = locationBreakdownData[key];

      return result;
    }, {});

  let locationSum = sum(values(locationBreakdownData));

  if (
    locationSum < 100 &&
    !has(locationBreakdownData, "other") &&
    Object.keys(locationBreakdownData || {}).length !== 0
  ) {
    let otherLocationBreakdownData = { other: 100 - locationSum };

    locationBreakdownData = Object.assign(
      locationBreakdownData,
      otherLocationBreakdownData
    );
  }

  return (
    <>
      {Object.keys(ageBreakdownData || {}).length !== 0 ||
      Object.keys(genderBreakdownData || {}).length !== 0 ||
      Object.keys(locationBreakdownData || {}).length !== 0 ? (
        <Row gutter={16}>
          {Object.keys(ageBreakdownData || {}).length !== 0 && (
            <Col span={8}>
              <Typography fontSize={14} fontWeight="semi-bold">
                Audience age breakdown
              </Typography>
              {Object.keys(ageBreakdownData)
                .filter((key, index) => {
                  return ageBreakdownData?.key !== null;
                })
                .map((key, index) => {
                  return ageBreakdownData?.key !== null ? (
                    <StatBlock key={index}>
                      <StatName>{key}</StatName>
                      <StatValue>
                        {numeral(ageBreakdownData?.[key]).format("0,0.0")}%
                      </StatValue>
                      <Progress
                        size="small"
                        percent={ageBreakdownData?.[key]}
                        showInfo={false}
                        strokeColor="#474747"
                      />
                    </StatBlock>
                  ) : (
                    ""
                  );
                })}
            </Col>
          )}

          {Object.keys(locationBreakdownData || {}).length !== 0 && (
            <Col span={8}>
              <Typography fontSize={14} fontWeight="semi-bold">
                Audience location breakdown
              </Typography>
              {Object.keys(locationBreakdownData)
                .slice(0, 5)
                .map((key, index) => {
                  return locationBreakdownData?.key !== null ? (
                    <StatBlock key={index}>
                      <StatName>{countryName[key]}</StatName>
                      <StatValue>
                        {numeral(locationBreakdownData?.[key]).format("0,0.0")}%
                      </StatValue>
                      <Progress
                        size="small"
                        percent={locationBreakdownData?.[key]}
                        showInfo={false}
                        strokeColor="#474747"
                      />
                    </StatBlock>
                  ) : (
                    ""
                  );
                })}
            </Col>
          )}

          {Object.keys(genderBreakdownData || {}).length !== 0 && (
            <Col span={8}>
              <Typography fontSize={14} fontWeight="semi-bold">
                Audience gender breakdown
              </Typography>

              {Object.keys(genderBreakdownData)
                .filter((key, index) => {
                  return genderBreakdownData?.key !== null;
                })
                .map((key, index) => {
                  return genderBreakdownData?.key !== null ? (
                    <StatBlock key={index}>
                      <StatName>{key}</StatName>
                      <StatValue>
                        {numeral(genderBreakdownData?.[key]).format("0,0.0")}%
                      </StatValue>
                      <Progress
                        size="small"
                        percent={genderBreakdownData?.[key]}
                        showInfo={false}
                        strokeColor="#474747"
                      />
                    </StatBlock>
                  ) : (
                    ""
                  );
                })}
            </Col>
          )}
        </Row>
      ) : (
        <Box p={40}>
          <Empty description="No data avaialble" />
        </Box>
      )}
    </>
  );
};

export default BreakdownData;
