import { string, object, number } from "yup";

export default object().shape({
  title: string()
    .nullable()
    .required("Please enter a title"),
  company: number()
    .nullable()
    .required("Please select a company"),
  hubspot_deal_id: string()
    .required("Please provide Hubspot Deal ID")
});
