// @ts-nocheck
import { Typography } from "antd";
import styled from "styled-components";

const { Text } = Typography;

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonWrapper = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const FeedbackIcon = styled("img")`
  && {
    width: 32px;
    height: 32px;
    margin: 20px 39px 20px 39px;
    cursor: pointer;
  }
  &.activeUp {
    animation: activeUp 2s normal forwards;
  }
  &.activeDown {
    animation: activeDown 2s normal forwards;
  }
  &.Inactive {
    animation: hide 1s normal forwards;
  }

  @keyframes activeUp {
    0% {
      transform: scale(1.5);
    }
    25% {
      transform: scale(1);
    }
    75% {
    }
    100% {
      transform: translateX(-50px);
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  @keyframes activeDown {
    0% {
      transform: scale(1.5);
    }
    25% {
      transform: scale(1);
    }
    75% {
    }
    100% {
      transform: translateX(50px);
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  @keyframes hide {
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const Message = styled(Text)`
  && {
    color: #575757;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05px;
    line-height: 19px;
    text-align: center;
  }
`;
