// @ts-nocheck
import React, { useState } from 'react'
import { Col } from 'antd'
import { isEmpty } from 'lodash'
import {
  AgeBreakdown,
} from 'src/components/Modules/BreakdownFields'
import {
  ScreenshotsContainer
} from 'src/scenes/influencers/components/Fields/Screenshots'
import { StyledRow, StyledCol } from '..'

interface Props {
  formProps: any
}


const DemographicForm = ({formProps}: Props) => {

  const [, setIsUploading] = useState(false)
  const [showExample, setShowExample] = useState({})
  const [, setScreenshotType] = useState('age')
  return(
    <StyledRow justify="space-between">
      <Col md={12} sm={24}><AgeBreakdown formProps={formProps} /></Col>
      <StyledCol md={12} sm={24}>
        <ScreenshotsContainer
          name="ageScreenshot"
          setIsUploading={loading => setIsUploading(loading)}
          setFieldValue={value =>
            formProps?.setFieldValue("ageScreenshot", value)
          }
          setFieldTouched={() =>
            formProps?.setFieldTouched("ageScreenshot", true)
          }
          values={formProps?.values}
          setShowExample={() =>
            setShowExample({
              age: true,
            })
          }
          setScreenshotType={() => setScreenshotType("age")}
          platformType={"Youtube"}
          accountName={""}
          screenshotType="age"
          multiple={false}
          disabled={!isEmpty(formProps?.values?.ageScreenshot)}
          showExample={showExample}
          hideExample={() =>
            setShowExample({
              age: false,
            })
          }
        />
      </StyledCol>
    </StyledRow>
  )
}

export default DemographicForm
