// @ts-nocheck
import React, { Component } from "react";
import { Modal, Empty } from "antd";
import { connect } from "react-redux";
import { Box } from "src/components/Core/Box";
import { connectModal } from "redux-modal";
import Loading from "src/shared/Loading";
import withModules from "src/utils/hoc/withModules";
import { InfluencerPersist } from "src/ducks/Persist";
import { AccountList as $AccountList } from "src/ducks/DataList";
import InfluencerDetail from "src/components/Modules/Influencer/Profile";

const AccountList = $AccountList("BrandInfluencerAccountList");

const ShowInfluencer = InfluencerPersist("BrandInfluencerShow");

const modules = [ShowInfluencer.module(), AccountList.module()];

type Props = {
  influencerId: number;
  accountId: number;
  influencer: any;
  accounts: any;
  rationale: string;
};

type InternalProps = {
  loadInfluencer: Function;
  loadAccounts: Function;
  show: boolean;
  handleHide: Function;
  loading: boolean;
  loadingAccounts: boolean;
};

class InfluencerModal extends Component<Props & InternalProps> {
  componentDidMount() {
    this.fetchInfluencer();
  }

  componentDidUpdate(prevProps, prevState) {
    const { influencerId } = this.props;
    if (prevProps.influencerId !== influencerId) {
      this.fetchInfluencer();
    }
  }

  fetchInfluencer = () => {
    const { loadInfluencer, loadAccounts, influencerId } = this.props;

    loadInfluencer(influencerId, "get", "profile", "");

    loadAccounts({ influencer: influencerId });
  };

  render() {
    const {
      show,
      handleHide,
      influencer,
      accounts,
      loading,
      loadingAccounts,
      rationale,
      accountId,
    } = this.props;

    const newAccounts = [...accounts];

    const accountIndex = newAccounts.findIndex(x => x.id === accountId);

    const [removedEle] = newAccounts.splice(accountIndex, 1);

    newAccounts.unshift(removedEle);

    return (
      <Modal
        title="Influencer Profile"
        width={1200}
        footer={null}
        onCancel={handleHide as any}
        visible={show}
      >
        {influencer && !loading ? (
          <>
            {/* <InfluencerMini influencer={influencer} rationale={rationale} />
            <Divider />
            <InfluencerMetrics
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
              influencer={influencer}
            /> */}
            {accounts && !loadingAccounts ? (
              <>
                {/* <InfluencerStats
                  accounts={accounts}
             /> */}
                {(accounts || []).length > 0 ? (
                  <InfluencerDetail
                    accounts={newAccounts}
                    breakdownLastUpdateDate={
                      influencer?.breakdown_last_update_date
                    }
                    rationale={rationale}
                    accountId={accountId}
                    grid={2}
                  />
                ) : (
                  <Box p={60}>
                    <Empty description="No data avaialble" />
                  </Box>
                )}
              </>
            ) : (
              <Loading />
            )}
          </>
        ) : (
          <Loading />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  influencer: ShowInfluencer.selectMethodData("get", "profile")(state),
  loading: ShowInfluencer.selectMethodPending("get", "profile")(state),
  accounts: AccountList.selectMany(state),
  loadingAccounts: AccountList.selectPending(state),
});

const mapDispatchToProps = {
  loadInfluencer: ShowInfluencer.customMethod,
  loadAccounts: AccountList.loadMany,
};

export default withModules(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    connectModal({
      name: "InfluencerModal",
      destroyOnHide: false,
    })(InfluencerModal as any)
  )
)(modules);
