import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter, Switch, Route } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import { PageHeader, Layout, message } from "antd";
import DashboardLayout from "src/scenes/layouts/DashboardLayout";
import { AccountPersist } from "src/ducks/Persist";
import { InvitationList, AccountPostList } from "src/ducks/DataList";
import Loading from "src/shared/Loading";
import AccountDetails from "./AccountDetails";
import UpdateAccount from "./UpdateAccount";
import SideMenu from "./SideMenu";
import PastContent from "./PastContent";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import { useAppDetails } from "src/AppProvider";

const { Content, Sider } = Layout;

const ShowAccount = AccountPersist("AdminAccountShow");
const CompleteInvitations = InvitationList("AdminAccountShow");
const AccountPosts = AccountPostList("AdminAccountShow");

const modules = [
  ShowAccount.module(),
  CompleteInvitations.module(),
  AccountPosts.module(),
];

const BorderHeader = styled(PageHeader)`
  border-bottom: 1px solid #e8e8e8;
`;

const AccountShow = props => {
  const { collapsed } = useAppDetails();

  useEffect(() => {
    const {
      loadAccount,
      match: {
        params: { id },
      },
    } = props;
    loadAccount(id, {
      params: {
        expand: ["influencer"],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAccount = () => {
    const { destroyAccount, account, history } = props;
    destroyAccount(account.id)
      .then(history.goBack)
      .catch(() => message.error("Could not delete account"));
  };

  const { history, account, loading, match } = props;

  return (
    <DashboardLayout title={account ? account.username : "Loading..."}>
      <Loading spinning={!account || loading}>
        {account && (
          <>
            <BorderHeader
              style={{
                top: "68px",
                width: `calc(100% - ${collapsed ? 80 : 200}px)`,
                overflow: "auto",
                position: "fixed",
                zIndex: 60,
              }}
              extra={[
                <ConfirmDeleteIcon
                  text="Delete"
                  key="delete-icon"
                  onConfirm={deleteAccount}
                  placement="bottomRight"
                />,
              ]}
              onBack={history.goBack}
              title={account.username}
            />
            <Layout>
              <Sider
                width={200}
                style={{
                  background: "#fff",
                  overflow: "auto",
                  height: "auto",
                  minHeight: "100vh",
                  marginTop: 55,
                  position: "fixed",
                }}
              >
                <SideMenu />
              </Sider>
              <Content
                style={{
                  marginLeft: 200,
                  marginTop: 58,
                  height: "calc(100vh - 126px)",
                }}
              >
                <Switch>
                  <Route
                    path={`${match.path}/update`}
                    exact
                    component={UpdateAccount}
                  />
                  <Route
                    path={`${match.path}/content`}
                    exact
                    component={PastContent}
                  />
                  <Route
                    path={`${match.path}`}
                    exact
                    component={AccountDetails}
                  />
                </Switch>
              </Content>
            </Layout>
          </>
        )}
      </Loading>
    </DashboardLayout>
  );
};

const mapStateToProps = state => ({
  account: ShowAccount.selectOne(state),
  loading: ShowAccount.selectGetPending(state),
});

const mapDispatchToProps = {
  loadAccount: ShowAccount.get,
  destroyAccount: ShowAccount.destroy,
};

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountShow))
)(modules);
