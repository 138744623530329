// @ts-nocheck
import React from "react";
import { FormikInput } from "src/components/Forms/TextInput";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
// import { FieldErrorMessage } from "src/components/Forms/ErrorMessage";

interface Props {
  formProps: FormikProps<any>;
}

const Name = ({ formProps }: Props) => {

  return (
    <Field
      mb={20}
      color="black"
      isBeside={true}
      id="password"
      label="Password"
    >
      <FormikInput
        size="middle"
        borderRadius={3}
        name="password"
        placeholder="- - - - - - - - -"
        type="password"
      />
      {/* <FieldErrorMessage
        errors={errors}
        touched={touched}
        mt={20}
        name={props.name}
      /> */}
    </Field>
  );
};

export default Name;
