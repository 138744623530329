// @ts-nocheck
import React from "react";
import { InfluencerAccountList } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useInfluencersListDetails } from "../InfluencersListProvider";

type Props = {};

export const InfluencerAccountListContainer: React.FC<Props> = () => {
  const {
    isLoading,
    listErrors,
    accountsListDetails,
    filters,
    actions,
  } = useInfluencersListDetails();

  let allAccounts = accountsListDetails;

  const globalErrors = useMapGraphQLErrorsMessages(listErrors);

  return (
    <InfluencerAccountList
      allAccounts={allAccounts}
      globalErrors={globalErrors}
      loading={isLoading}
      onFilter={(key, value) => {
        filters[key] = value;
        actions?.setFilters({ ...filters });
        actions?.refetchAccounts({ ...filters });
      }}
      filters={filters}
    />
  );
};
