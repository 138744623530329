import React, {Fragment} from "react"
import FormField from "../FormField"
import {countries} from "src/constants/payments";
import {Select} from "formik-antd"
import {Box} from "src/components/Core/Box";
import {snakeCase} from 'lodash'
import {
    bankDetailFields
} from 'src/scenes/admins/PaymentRequest/NewPaymentModal/PaymentDetails/BankDetailForm/Steps/BankDetailFields'

const BankAccount = ({bank_account, ...props}) => {
    return (
        <Fragment>
            <FormField name={props.name || "payout_details.bank_account.country_code"} label="Country Code">
                <Select data-cy="influencer-countrycode" size="large" placeholder="Select..." options={countries}>
                </Select>
            </FormField>
            {bank_account?.country_code &&
                bankDetailFields[bank_account.country_code].fields.map(item => {
                    const FieldComponent = item.component;

                    return (
                        <Box mb={9}>
                            <FieldComponent
                                name={`payout_details.bank_account.${snakeCase(item.name)}`}
                                {...item.props}
                            />
                        </Box>
                    );
                })
            }
        </Fragment>
    )
}

export default BankAccount
