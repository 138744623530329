// @ts-nocheck
import React from "react";
import styled from "styled-components";
import { Rate as BaseAntdRating } from "antd";
import { FontSizes } from "src/utils/theme";

export interface RatingInputProps {
  allowHalf?: boolean;
  defaultValue?: number;
  disabled?: boolean;
  size?: FontSizes;
  value?: number;
}

const AntdRatingInput: React.FC<RatingInputProps> = props => {
  const { ...rest } = props;

  return <BaseAntdRating {...rest} />;
};

const StyledRatingInput = styled(AntdRatingInput)<RatingInputProps>`
  && {
    font-size: ${props => props.size}px;
    .ant-rate-star:not(:last-child) {
      margin-right: 5px;
    }
  }
`;

export default StyledRatingInput;
