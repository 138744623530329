import React, { Component } from "react"
import { connect } from "react-redux"
import { Form } from "formik-antd"
import { connectModal } from "redux-modal"
import ModalForm from "src/shared/ModalForm"
import withModules from "src/utils/hoc/withModules"
import { BrandPersist } from "src/ducks/Persist"
import { string, object } from "yup"
import { Name, Email } from "src/shared/BrandFields"

const BrandCreate = BrandPersist("CompanyUserModal")

const modules = [BrandCreate.module()]

const Validate = object().shape({
  name: string()
    .nullable()
    .required("Please enter a name"),
  email: string()
    .nullable()
    .required("Please enter an email")
})

class BrandModal extends Component {
  render() {
    const { show, create, handleHide, onCreate, initialValues } = this.props

    return (
      <ModalForm
        show={show}
        onSave={create}
        afterSave={onCreate}
        initialValues={initialValues}
        validate={Validate}
        handleHide={handleHide}
        title="Create new user"
      >
        <Form data-cy="company-user-create-modal-form">
          <Name />
          <Email />
        </Form>
      </ModalForm>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  create: BrandCreate.create
}

export default withModules(
  connectModal({
    name: "CreateBrandModal",
    destroyOnHide: true
  })(connect(mapStateToProps, mapDispatchToProps)(BrandModal))
)(modules)
