import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FilterTag from "./FilterTag";
import { default as TableManagerDuck } from "src/ducks/TableManager";

export class FilterList extends Component {
  render() {
    const { filters, removeFilter, filterOptions } = this.props;

    return Object.keys(filterOptions).map(
      key => {
        let shouldShowFilterTag = false;
        if (filterOptions[key].displayValue) {
          shouldShowFilterTag = !!filterOptions[key].displayValue(filters);
        } else {
          shouldShowFilterTag = filterOptions[key] && filters[key];
        }

        if (shouldShowFilterTag) {
          return (
            <FilterTag
              key={key}
              name={filterOptions[key].label}
              value={filterOptions[key].displayValue ?
                filterOptions[key].displayValue(filters) : filters[key]}
              onRemove={() =>
                removeFilter(filterOptions[key].actualName ?
                  filterOptions[key].actualName : key)}
            />
          );
        }
        return null;
      }
    );
  }
}

const mapStateToProps = (state, props) => {
  const Manager = TableManagerDuck(props.name);

  return {
    filters: Manager.selectFilters(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const Manager = TableManagerDuck(ownProps.name);

  return bindActionCreators(
    {
      removeFilter: Manager.removeFilter
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterList);
