import * as ormHOD from "./Orm";
import { createSelector } from "reselect";
import * as api from "../api/endpoints";

export const initialState = {
  updatePending: false,
  destroyPending: false,
  getPending: false,
  createPending: false,
  updateError: null,
  destroyError: null,
  createError: null,
  getError: null,
  id: null,
  loadingId: null,
};

const Persist = type => name => {
  const ormModule = ormHOD[type];
  const endpoint = api[type];
  const stateName = "Persist" + type + name;

  // Types
  const types = {
    get: `${name}_SHOW_${type}`,
    customMethod: `${name}_CUSTOM_METHOD_${type}`,
    create: `${name}_CREATE_${type}`,
    update: `${name}_UPDATE_${type}`,
    destroy: `${name}_DESTROY_${type}`,
    clear: `${name}_CLEAR_${type}`,
  };

  const actionCreator = (method, ormAction) => {
    return (...args) => {
      return dispatch => {
        const response = dispatch({
          type: types[method],
          payload: endpoint[method](...args),
        });

        const callback = args =>
          ormAction ? ormAction(args) : res => ormModule.addOne(res.value.data);

        response
          .then(res => dispatch(callback(...args)(res)))
          .catch(console.error);

        return response;
      };
    };
  };

  // Action creators
  const actions = {
    create: actionCreator("create"),
    update: actionCreator("update"),
    customMethod: (id, request, method, data) => {
      return dispatch => {
        const fullMethod = request + method;

        dispatch({
          type: types.customMethod + "_PENDING",
          payload: { fullMethod },
        });

        return endpoint.customMethod(id, request, method, data).then(
          res =>
            dispatch({
              type: types.customMethod + "_FULFILLED",
              payload: { ...res, fullMethod },
            }),
          error =>
            dispatch({
              type: types.customMethod + "_REJECTED",
              payload: { ...error, fullMethod },
            })
        );
      };
    },
    destroy: actionCreator("destroy", id => res => {
      return ormModule.delete(id);
    }),
    get: actionCreator("get"),
    clear: () => ({
      type: types.clear,
    }),
  };

  // Reducer
  const reducerGenerator = type => ({
    [`${types[type]}_REJECTED`]: (state, action) => ({
      ...state,
      [type + "Error"]: action.payload,
      [type + "Pending"]: false,
    }),
    [`${types[type]}_PENDING`]: (state, action) => ({
      ...state,
      [type + "Pending"]: true,
    }),
    [`${types[type]}_FULFILLED`]: (state, action) => ({
      ...state,
      id: action.payload.data ? action.payload.data.id : null,
      [type + "Pending"]: false,
    }),
  });

  const reducer = (state = initialState, action) => {
    const handlers = {
      ...reducerGenerator("create"),
      ...reducerGenerator("update"),
      ...reducerGenerator("get"),
      ...reducerGenerator("destroy"),
      [`${types.customMethod}_REJECTED`]: (state, action) => ({
        ...state,
        [action.payload.fullMethod + "Error"]: action.payload,
        [action.payload.fullMethod + "Pending"]: false,
      }),
      [`${types.customMethod}_PENDING`]: (state, action) => ({
        ...state,
        [action.payload.fullMethod + "Pending"]: true,
      }),
      [`${types.customMethod}_FULFILLED`]: (state, action) => ({
        ...state,
        [action.payload.fullMethod]: action.payload.data,
        [action.payload.fullMethod + "Pending"]: false,
      }),
      [types.clear]: (state, action) => initialState,
    };

    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };

  // Selectors
  const entities = state => state.entities;

  const selectErrorData = method => state => {
    const fullMethod = "select" + method + "Error";
    return selectors[fullMethod](state)
      ? selectors[fullMethod](state).response.data.meta.errors
      : [];
  };

  const selectors = {
    selectUpdatePending: state => state[stateName].updatePending,
    selectDestroyPending: state => state[stateName].destroyPending,
    selectGetPending: state => state[stateName].getPending,
    selectCreatePending: state => state[stateName].createPending,
    selectUpdateError: state => state[stateName].updateError,
    selectDestroyError: state => state[stateName].destroyError,
    selectCreateError: state => state[stateName].createError,
    selectGetError: state => state[stateName].getError,
    selectId: state => state[stateName].id,

    selectMethodData: (request, method) => state =>
      state[stateName][request + method],

    selectMethodError: (request, method) => state =>
      state[stateName][request + method + "Error"],

    selectMethodPending: (request, method) => state =>
      state[stateName][request + method + "Pending"],

    selectCreateErrorData: selectErrorData("Create"),
    selectUpdateErrorData: selectErrorData("Update"),
    selectGetErrorData: selectErrorData("Get"),
    selectDestroyErrorData: selectErrorData("Destroy"),

    selectOne: createSelector(
      entities,
      state => selectors.selectId(state),
      ormModule.selectOne
    ),
  };

  const PerstitModule = () => ({
    id: stateName,
    reducerMap: {
      [stateName]: reducer,
    },
  });

  return {
    types,
    reducer,
    module: PerstitModule,
    ...actions,
    ...selectors,
  };
};

export const CampaignPersist = Persist("Campaign");
export const CampaignPersistModule = name => CampaignPersist(name).module;

export const InfluencerPersist = Persist("Influencer");
export const InfluencerPersistModule = name => InfluencerPersist(name).module;

export const InvitationPersist = Persist("Invitation");
export const InvitationPersistModule = name => InvitationPersist(name).module;

export const CampaignAnalyticPersist = Persist("CampaignAnalytic");

export const CampaignPackagePersist = Persist("CampaignPackage");

export const CreativePersist = Persist("Creative");
export const AdminPersist = Persist("Admin");
export const DraftPersist = Persist("Draft");

export const BrandPersist = Persist("Brand");
export const BrandPersistModule = name => BrandPersist(name).module;

export const CompanyPersist = Persist("Company");
export const CompanyPersistModule = name => CompanyPersist(name).module;

export const SuggestionPersist = Persist("Suggestion");
export const SuggestionPersistModule = name => SuggestionPersist(name).module;

export const GroupPersist = Persist("Group");
export const GroupPersistModule = name => GroupPersist(name).module;

export const AccountPersist = Persist("Account");
export const AccountPersistModule = name => AccountPersist(name).module;

export const LinkPersist = Persist("Link");
export const LinkPersistModule = name => LinkPersist(name).module;

export const EmailPersist = Persist("Email");
export const EmailPersistModule = name => EmailPersist(name).module;

export const AgencyPersist = Persist("Agency");
export const AgencyPersistModule = name => AgencyPersist(name).module;

export const PayoutPersist = Persist("Payout");
export const PayoutPersistModule = name => PayoutPersist(name).module;

export const TagPersist = Persist("Tag");
export const TagPersistModule = name => TagPersist(name).module;

export const WorkerPersist = Persist("Worker");
export const WorkerPersistModule = name => WorkerPersist(name).module;
