// @ts-nocheck
import React from "react"
import { Result } from "antd"
import { GiftingCampaignFragmentFragment } from "src/generated/ApolloComponents"
import { GiftingWizardWrapper } from "../GiftingWizardWrapper"
import { GiftingCampaignBrief } from "../GiftingCampaignBrief"
import { Box } from "src/components/Core/Box"

interface Props {
  campaign?: GiftingCampaignFragmentFragment
}

const SubmissionComplete = ({ campaign }: Props) => {
  return (
    <GiftingWizardWrapper campaign={campaign} step="review">
      <Box width="100%">
        <Result
          status="success"
          title="Campaign successfully submitted"
          subTitle="You will soon recieve a list of influencers who are interested in taking part in your campaign"
        />
        <GiftingCampaignBrief campaign={campaign} />
      </Box>
    </GiftingWizardWrapper>
  )
}

export default SubmissionComplete
