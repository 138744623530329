// @ts-nocheck
import React, { useRef } from "react";
import styled from "styled-components";

interface Props {
  children?: React.ReactNode;
  percentage: number;
}

const ViewBar = styled.div<{
  width: number;
}>`
  && {
    background: #efdbff;
    height: 50px;
    font-size: 16px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 500;
    transition: all 1s ease-in;
    moz-transition: all 1s ease-in;
    justify-content: space-between;
    margin-top: 50px;
    min-width: 250px;
    opacity: 0;
    width: 20%;
  }
  &.activeUp {
    opacity: 1;
    width: ${props => props.width}% !important;
    transform: translateY(150px);
    animation: reducetime 2s;
    animation: come-in 0.8s ease forwards;
  }

  @keyframes come-in {
    to {
      transform: translateY(0);
    }
  }

  @keyframes reducetime {
    0% {
      width: 0;
    }
    100% {
      width: ${props => props.width}% !important;
    }
  }

  @-moz-keyframes reducetime {
    0% {
      width: 0;
    }
    100% {
      width: ${props => props.width}% !important;
    }
  }
`;

const BarSection: React.FC<Props> = ({
  children,

  percentage,
}) => {
  const domRef = useRef();

  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Not possible to set it back to false like this:

          setVisible(true);

          // No need to keep observing:
          observer.unobserve(domRef?.current as any);
        }
      });
    };
    const options = {
      root: null,
      threshold: 0,
      rootMargin: "0px 0px -100px 0px",
    };
    const observer = new IntersectionObserver(callback, options);

    observer.observe(domRef?.current as any);

    return () => observer.unobserve(domRef?.current as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ViewBar
      width={percentage}
      ref={domRef as any}
      className={isVisible ? " activeUp" : ""}
    >
      {children}
    </ViewBar>
  );
};

export default BarSection;
