// @ts-nocheck
import React, { Component } from "react"
import { connect } from "react-redux"
import CampaignManage from "src/ducks/CampaignManage"
import { withFormik } from "formik"
import { Form } from "formik-antd"
import Validate from "./Validate"
import { message } from "antd"
import { Brand, SubmitButton } from "src/shared/CampaignFields"

type Props = {
  values?: any
  handleSubmit?: any
  isSubmitting?: boolean
  isValid?: boolean
  campaign?: any
  setFieldValue?: any
}

export class CampaignForm extends Component<Props> {
  render() {
    const { values, isSubmitting } = this.props
    return (
      <Form data-cy="brand-campaign-update-form">
        <Brand companyId={values.company} />
        <SubmitButton text="Update" isSubmitting={isSubmitting} />
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  campaign: CampaignManage.campaign.selectOne(state)
})

const mapDispatchToProps = {
  updateCampaign: CampaignManage.campaign.update
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,
    enableReinitialize: true,

    mapPropsToValues: ({ campaign, Validate }: any) => (campaign ? campaign.ref : Validate.cast()),
    validationSchema: () => Validate,

    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { updateCampaign, campaign } = props

      updateCampaign(campaign.id, values)
        .then((res) => {
          setSubmitting(false)
        })
        .catch((err) => {
          message.error("Failed to update campaign")
          setSubmitting(false)

          if (err.response.data) setErrors(err.response.data.meta.errors)
        })
    },

    displayName: "BrandCampaignForm"
  })(CampaignForm)
)
