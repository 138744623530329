// @ts-nocheck
import React, { Fragment, useEffect } from "react";
import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { FormikProps } from "formik";
import LinkButton from "src/components/Core/LinkButton";
import Button from "src/components/Core/Button";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { genders, ages, locations } from "src/constants/account";
import { FormikSelect } from "src/components/Forms/Select";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import Graph from "src/assets/images/pie_chart.png";
import matchesProperty from "lodash/matchesProperty";
import find from "lodash/find";
import PieChartGraphCard from "../../Graphs/PieChartGraphCard";
import { colors } from "src/constants/account";
import { FieldError } from "src/components/Forms/ErrorMessage";

interface Props {
  setStep: (value: number) => void;
  formProps?: FormikProps<any>;
  isSubmitting: boolean;
}

const SecondStep: React.FC<Props> = ({ setStep, formProps, isSubmitting }) => {
  // reset if type change
  useEffect(() => {
    formProps?.setFieldValue("data", [{ key: "", value: 0 }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formProps?.values?.type]);

  let drodownData = [] as any;

  let dataCount = 0;

  if (formProps?.values?.type === "Age Breakdown") {
    drodownData = ages;
    dataCount = ages.length;
  } else if (formProps?.values?.type === "Gender Breakdown") {
    drodownData = genders;
    dataCount = genders.length;
  } else if (formProps?.values?.type === "Location Breakdown") {
    drodownData = locations;
    dataCount = locations.length;
  }

  drodownData = drodownData.filter((item: any, index) => {
    const newItem = { ...item };
    if (
      !find(formProps?.values["data"], matchesProperty("key", newItem?.value))
    ) {
      return true;
    }
    return false;
  });

  const addData = () => {
    (formProps?.values["data"]).push({ key: "", value: 0 });
    formProps?.setFieldValue("data", formProps?.values["data"]);
  };

  const removeData = index => {
    (formProps?.values["data"]).splice(index, 1);
    formProps?.setFieldValue("data", formProps?.values["data"]);
  };

  const renderGraphData = data => {
    data = data.map((item: any, index) => {
      const newItem = { ...item };
      newItem.color = colors[index];
      return newItem;
    });

    return data;
  };

  return (
    <Fragment>
      <Box p={25}>
        <Box marginBottom="20px">
          <Typography size={16} weight="semi-bold" color="black">
            {formProps?.values?.type}
          </Typography>
        </Box>

        <Row gutter={16} style={{ marginTop: 50 }}>
          <Col span={12}>
            <Box display="flex">
              <Box width="50%" mr="5px">
                <Typography size={14} color="grey2">
                  Label:
                </Typography>
              </Box>
              <Box width="50%">
                <Typography size={14} color="grey2">
                  Data:
                </Typography>
              </Box>
            </Box>
          </Col>
          <Col span={12}></Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            {formProps?.values?.["data"].map((value, i) => (
              <Box display="flex" key={i} marginBottom={10} mt={10}>
                <Box width="40%" mr="5px">
                  <FormikSelect
                    name={`data[${i}].key`}
                    data={drodownData}
                    local
                    background="background"
                    borderRadius={2}
                    borderColor="grey5"
                    size="middle"
                    value={value?.key}
                  />
                </Box>
                <Box width="40%">
                  <FormikInputNumber
                    parser={value => {
                      let parsed = value.replace(/\[0-9]/g, "");
                      if (parsed) {
                        return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                      } else {
                        return null;
                      }
                    }}
                    name={`data[${i}].value`}
                    background="background"
                    borderRadius={2}
                    borderColor="grey5"
                    size="middle"
                    value={value?.value}
                  />
                </Box>
                <Box width="10%" mt={10} ml={10}>
                  %
                </Box>
                <Box width="10%" color="red" mt={10} ml={10}>
                  <DeleteOutlined onClick={() => removeData(i)} />
                </Box>
              </Box>
            ))}

            {drodownData?.length > 0 &&
              dataCount >= formProps?.values?.["data"].length + 1 && (
                <LinkButton
                  onClick={() => addData()}
                  mt={20}
                  color="blue"
                  fontSize={12}
                >
                  + Add data
                </LinkButton>
              )}

            <FieldError errors={formProps?.errors as any} name={`data`} />
          </Col>

          <Col span={12}>
            {formProps?.values?.["data"].length > 0 &&
            formProps?.values["data"][0].key !== "" &&
            formProps?.values["data"][0].value !== "" ? (
              <PieChartGraphCard
                title={formProps?.values?.type}
                data01={renderGraphData(formProps?.values?.["data"])}
                isShowFilter={false}
              />
            ) : (
              <img src={Graph} alt="Graph" />
            )}
          </Col>
        </Row>
      </Box>

      <Box
        alignItems="right"
        textAlign="right"
        display="inherit"
        mt={50}
        borderTop="1px solid #E2E2E2"
        p={10}
      >
        <Button
          buttonSize="middle"
          data-cy="second-cancel-button"
          onClick={() => setStep(1)}
          mr={20}
        >
          Back
        </Button>

        <Button
          htmlType="submit"
          type="action"
          loading={isSubmitting}
          disabled={isSubmitting}
          buttonSize="middle"
          data-cy="second-next-button"
        >
          Next
        </Button>
      </Box>
    </Fragment>
  );
};
export default SecondStep;
