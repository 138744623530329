// @ts-nocheck
import React from "react";
import { PersonalDetailForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useUpdateWelcomePersonalDetailMutation,
  AccountFragementFragment,
} from "src/generated/ApolloComponents";
import { string, object } from "yup";

interface Props {
  claimAccountId: any;
  goNext: () => void;
  accountData: AccountFragementFragment | null | undefined;
}

interface PersonalDetailFormValues {
  gender: string;
  age: string;
  location: string;
  platformType?: string;
}

export const PersonalDetailFormContainer: React.FC<Props> = ({
  claimAccountId,
  goNext,
  accountData,
}) => {
  const [
    updateWelcomePersonalDetailMutation,
    { loading, error },
  ] = useUpdateWelcomePersonalDetailMutation();

  const influencerData = accountData?.influencer;

  const PersonalDetailSchema = object().shape({
    age: string().required("Please select age"),
    gender: string().required("Please select gender"),
    location: string().required("Please select location"),
  });

  let initialValues = {
    gender: influencerData?.detail?.gender?.[0] || "",
    age: influencerData?.detail?.age?.[0] || "",
    location: influencerData?.detail?.location?.[0] || "",
  };

  const onSubmit = async (
    values: PersonalDetailFormValues,
    actions: FormikActions<PersonalDetailFormValues>
  ) => {
    try {
      const formData = {
        claimAccountId: claimAccountId,
        age:
          (values?.age || []).length > 0
            ? ([values?.age] as Array<string>)
            : [],
        gender: [values?.gender] as Array<string>,
        location: [values?.location] as Array<string>,
      };
      const res = await updateWelcomePersonalDetailMutation({
        variables: formData,
      });
      if (res?.data?.updateWelcomePersonalDetail?.id) {
        goNext();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <PersonalDetailForm
      initialValues={initialValues}
      personalDetailSchema={PersonalDetailSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      accountData={accountData}
    />
  );
};
