import React, { Component } from "react";
import FormField from "../FormField";
import { Slider } from "formik-antd";

export default class HygieneFactor extends Component {
  render() {
    return (
      <FormField
        name={this.props.name || "hygiene_factor"}
        label={`Hygiene Factor: ${
          typeof this.props.values[this.props.name] !== "undefined"
            ? this.props.values[this.props.name] >= 3
              ? 3
              : this.props.values[this.props.name]
            : 0
        }`}
      >
        <Slider min={1} max={3} />

        <p>
          The higher the hygiene factor the more squeaky clean the influencers
          will be. We use our influencer analytics to ensure no nudity or
          profanity is used. The higher the hygiene factor, the more manual
          checks are made.
        </p>
      </FormField>
    );
  }
}
