// @ts-nocheck
import React, {useEffect } from 'react'
import { message } from 'antd'
import ShareSuggestion from '.'
import {
  useUpdateShareSuggestionsPageTitileMutation
} from "src/generated/ApolloComponents"

interface Props {
  campaign: any
}

const ShareSuggestionContainer = ({campaign}: Props) => {
  const partialUrl = `/sharedsuggestions/${campaign.id}`
  const suggestionUrl = `${window.location.origin.split('//')[1]}${partialUrl}`


  const [
    updateShareSuggestionsPageTitile,
    {loading, error}
  ] = useUpdateShareSuggestionsPageTitileMutation();

  useEffect(() => {
    if(error)
      message.error("Failed to update page title")
  },[error])

  const handleCopy = () => {
    navigator.clipboard.writeText(suggestionUrl)
    message.info('URL copied!');
  }

  const handleSave = async (pageTitle: string) => {
    try {
      updateShareSuggestionsPageTitile({
        variables: {
          pageTitle: pageTitle,
          campaignId: campaign?.id
        }
      })
    } catch {}
  }

  return(
    <ShareSuggestion
      campaign={campaign}
      partialUrl={partialUrl}
      loading={loading}
      suggestionUrl={suggestionUrl}
      handleCopy={handleCopy}
      handleSave={handleSave}
    />
  )
}

export default ShareSuggestionContainer;
