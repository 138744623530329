// @ts-nocheck
import React from "react";
import { public_statuses } from "src/constants/campaign";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { RemoveIcon } from "../../../Wrapper";
import { FormikSelect } from "src/components/Forms/Select";
import { isBoolean } from "lodash"

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Public: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  let isVisible = false;

  if (isBoolean(formProps?.values?.isPublic)) {
    isVisible = true;
  }

  return (
    <Field
      label="Public/Private"
      mb={20}
      color="black"
      isBeside={true}
      extra={
        isVisible && (
          <RemoveIcon
            type="close-circle"
            onClick={() => {
              formProps?.setFieldValue("isPublic", undefined);
              clearFilterKey("isPublic");
            }}
            style={{ marginTop: "-1px", marginLeft: "10px" }}
          />
        )
      }
      id="countryBreakdown"
    >
      <FormikSelect
        name="isPublic"
        data={public_statuses}
        local
        borderRadius={3}
        borderColor="grey5"
        size="small"
        minHeight={35}
      />
    </Field>
  );
};

export default Public;
