import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { connectModal } from "redux-modal";
import { Form } from "formik-antd";
import {
  message,
  Modal,
  Button,
  Alert,
  Descriptions,
  Typography,
  Row,
  Col,
} from "antd";
import { withFormik } from "formik";
import idx from "idx";
import {
  PayoutType,
  PayoutCurrency,
  OriginalPayoutPennies,
} from "src/shared/InvitationFields";
import FormError from "src/shared/FormField/FormError";
import InvitationManage from "src/ducks/InvitationManage";
import { Invitation } from "src/ducks/Orm";
import formatCurrency from "src/utils/formatCurrency";
import { string, number, object } from "yup";
import currencyRate from "src/utils/currencyRate";
import { Typography as InfoText } from "src/components/Core/Typography";

const Validate = object().shape({
  payout_type: string().required("Please select a payout type"),
  payout_type_currency: string().required("Please select a payout currency"),
  payout_type_original_pennies: number()
    .nullable()
    .required("Please enter a payout"),
});

export class AcceptModal extends Component {
  state = {
    gbp: 0,
  };

  componentDidMount() {
    const { values } = this.props;

    this.onChangeCurrency(values.payout_type_currency);
  }

  onChangeCurrency = async currency => {
    if (currency !== "GBP") {
      const gbp = await currencyRate(currency);

      if (gbp) {
        this.setState({
          gbp: gbp,
        });
      }
    }
  };

  render() {
    const {
      show,
      handleHide,
      status,
      invitation,
      values,
      handleSubmit,
      isSubmitting,
      setFieldValue,
    } = this.props;

    return (
      <Modal
        visible={show}
        onCancel={handleHide}
        title="Mark invitation accept"
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Form data-cy="accept-modal-form">
          {invitation &&
            !!invitation.payout_pennies &&
            invitation.payout_pennies > 0 && (
              <Alert
                message="Influencer paid"
                description="You can update the payout amount, but this will not pay the influencer again"
                type="info"
                style={{ marginBottom: 24 }}
              />
            )}

          <div style={{ marginBottom: 24 }}>
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="Account">
                {invitation.account.username}
              </Descriptions.Item>
              <Descriptions.Item label="Payout Type">
                {invitation.payout_type}
              </Descriptions.Item>
              <Descriptions.Item label="CPM">
                {invitation.formattedCpm()}
              </Descriptions.Item>
            </Descriptions>
          </div>

          {status &&
            Object.values(status.meta.errors).map((error, i) => (
              <FormError
                key={i}
                style={{ margin: "0 0 10px 0" }}
                message={error}
              />
            ))}

          <PayoutType />

          <Row gutter={8}>
            <Col span={8}>
              <PayoutCurrency
                onChangeCurrency={currency => {
                  this.onChangeCurrency(currency);
                  setFieldValue("payout_type_currency", currency);
                }}
              />
            </Col>
            <Col span={16}>
              <OriginalPayoutPennies
                title={
                  values.payout_type === "fixed" ? "Fixed Payout" : "CPM Payout"
                }
              />
              {values.payout_type_currency !== "GBP" &&
                idx(values, x => x.payout_type_original_pennies) && (
                  <InfoText size={14} color="grey9" mt="-25px">
                    which converts to{" "}
                    {formatCurrency(
                      idx(values, x => x.payout_type_original_pennies) *
                        this.state.gbp
                    )}
                  </InfoText>
                )}
            </Col>
          </Row>

          {values.payout_type === "cpm" && (
            <>
              <Typography style={{ marginBottom: 24 }}>
                Estimate:{" "}
                {formatCurrency(values.cpm * (values.estimated_views / 1000.0))}
              </Typography>
            </>
          )}
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => ({
  invitation: createSelector(
    state => state.entities,
    () => props.invitation_id,
    Invitation.selectOne
  )(state, props),
});

const mapDispatchToProps = {
  updateStatus: InvitationManage.updateStatus,
};

export default connectModal({
  name: "AcceptModal",
  destroyOnHide: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withFormik({
      mapPropsToValues: ({ invitation }) => {
        return {
          ...invitation.ref,
        };
      },
      enableReinitialize: true,

      validationSchema: () => Validate,

      handleSubmit: (
        values,
        { props, setSubmitting, setErrors, setStatus }
      ) => {
        const { updateStatus, invitation, from, to, handleHide } = props;

        // we add payout_type_currency and  payout_type_original_pennies
        // so base on that backend will calculate cpm and fixed_payout_pennies
        // if we pass that its will directly update so we need to remove when its submit

        delete values.cpm;
        delete values.fixed_payout_pennies;

        updateStatus(invitation.id, from, to, values)
          .then(() => {
            setSubmitting(false);
            handleHide();
            message.success("Invitation updated!");
          })
          .catch(err => {
            setSubmitting(false);
            setStatus(err.response.data);
          });
      },
    })(AcceptModal)
  )
);
