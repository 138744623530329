// @ts-nocheck
import React from 'react'
import { DownloadOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Input } from 'antd';
import Button from 'src/components/Core/Button'
import { Typography } from 'src/components/Core/Typography'
import { AdminList as Props } from '../props'
import { StyledCol, ButtonCol, TipCol, StyledDiv } from './styles'

const AdminList = ({
  list,
  addList,
  removeList,
  setListName,
  manageListAccounts
}: Props) => {
  return (
    <StyledDiv>
      <Row gutter={16} justify="space-between" align="top">
        <Col>
          <Input placeholder="Enter list name" onChange={e => setListName(e.target.value)}/>
        </Col>

        <Col>
          <Row>
            <StyledCol>
              <Typography size={14} color="grey7">
                Press ESC to cancel
              </Typography>
            </StyledCol>
            <ButtonCol>
              <Button
                type="danger"
                buttonSize="small"
                key="create"
                data-cy="create-hero-button"
                style={{ boxShadow: "none" }}
                disabled={!list?.id}
                onClick={() => manageListAccounts([], 'delete')}
              >
                <DownloadOutlined />
                Delete list
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button
                type="action"
                buttonSize="small"
                key="create"
                data-cy="create-hero-button"
                style={{ boxShadow: "none" }}
                disabled={removeList.length === 0 || !list?.id}
                onClick={() => manageListAccounts(removeList, 'remove')}
              >
                <MinusOutlined />
                Remove ({removeList.length}) to list
              </Button>
            </ButtonCol>
            <Col>
              <Button
                type="action"
                buttonSize="small"
                key="create"
                data-cy="create-hero-button"
                style={{ boxShadow: "none" }}
                disabled={addList.length === 0 || !list?.id}
                onClick={() => manageListAccounts(addList, 'add')}
              >
                <PlusOutlined />
                Add ({addList.length}) to list
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {list?.id &&
        <TipCol>
          <Typography size={12} color="black">
            Tip: You can drag and drop influencers into, and out of, your list
          </Typography>
        </TipCol>
      }
    </StyledDiv>
  );
};

export default AdminList;
