// @ts-nocheck
import React, { useEffect } from "react";
import { NotesList } from ".";
import { useUpdateNotesByInfluencerIdMutation } from "src/generated/ApolloComponents";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import { useInfluencerNotesDetails } from "../../InfluencerNotesProvider";
import { useInfluencerDetails } from "../../InfluencerProfileProvider";

type Props = {};

export const NotesListContainer: React.FC<Props> = () => {
  const { influencerId } = useInfluencerDetails();

  const {
    actions,
    isLoading,
    notesErrors,
    influencerNotesDetails,
  } = useInfluencerNotesDetails();

  useEffect(() => {
    actions.refetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let allNotes = influencerNotesDetails || [];

  const [
    updateNotesByInfluencerIdMutation,
    { loading, error },
  ] = useUpdateNotesByInfluencerIdMutation();

  const deleteNote = async index => {
    try {
      allNotes.splice(index, 1);

      allNotes = allNotes.map((item: any, index) => {
        const newItem = { ...item };
        delete newItem.__typename;
        return newItem;
      });

      const res = await updateNotesByInfluencerIdMutation({
        variables: {
          notes: allNotes,
          influencerId: influencerId,
        },
      });
      if (res?.data?.updateNotesByInfluencerId) {
        actions.refetchNotes();
      }
    } catch (e) {}
  };

  const globalErrors = useMapGraphQLErrorsMessages(notesErrors || error);

  return (
    <NotesList
      allNotes={allNotes}
      globalErrors={globalErrors}
      loading={isLoading || loading}
      deleteNote={value => deleteNote(value)}
    />
  );
};
