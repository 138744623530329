// @ts-nocheck
import React from "react";
import { Card } from "antd";
import { Box } from "src/components/Core/Box";
import styled from "styled-components";
import { AccountListContainer } from "./AccountList";

const SuggestedInfluencerCard = styled(Card)`
  &&& {
    .ant-card-body {
      padding: 0px !important;
    }
  }
`;

interface Props {
  campaign: any;
}

const SuggestedInfluencer: React.FC<Props> = ({ campaign }) => {
  return (
    <Box mt={20}>
      <SuggestedInfluencerCard>
        <AccountListContainer campaign={campaign} />
      </SuggestedInfluencerCard>
    </Box>
  );
};

export default SuggestedInfluencer;
