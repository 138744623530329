// @ts-nocheck
import React from "react";
import { useAccountsListDetails } from "../AccountsListProvider";
import NewAccountModal from "src/scenes/admins/InfluencersList/NewAccountModal";

interface Props {
  showNewAccountForm?: boolean;
  closeAccountModal?: () => void;
}

const NewAccountModalWrapper = ({
  closeAccountModal,
  showNewAccountForm,
}: Props) => {
  const { actions } = useAccountsListDetails();

  const onSucessAddNewAccount = () => {
    let container = {} as any;
    container["page"] = 1;
    actions.setFilters({ ...container });

    actions.refetchAccounts({ ...container });

    if (closeAccountModal) {
      closeAccountModal();
    }
  };
  return (
    <NewAccountModal
      closeAccountModal={closeAccountModal}
      showNewAccountForm={showNewAccountForm}
      onSucessAddNewAccount={onSucessAddNewAccount}
    />
  );
};

export default NewAccountModalWrapper;
