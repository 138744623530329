import React, { Component } from "react"
import FormField from "../FormField"
import { Select } from "formik-antd"
import { types } from "src/constants/account"

export default class Platform extends Component {
  render() {
    return (
      <FormField name={this.props.name || "type"} label="Platform">
        <Select size="large" data-cy="account-platform" placeholder="Select..." options={types}>
        </Select>
      </FormField>
    )
  }
}
