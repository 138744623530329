// @ts-nocheck
import React from "react";
import { TagsTable } from ".";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetDiscoverCategoryTopInfluencersQuery,
  DiscoverCategory,
  useAddDiscoverTrackingInfluencerToAdminMutation,
} from "src/generated/ApolloComponents";

type Props = {
  category: DiscoverCategory;
};

export const TagsTableContainer: React.FC<Props> = ({ category }) => {
  const [
    addDiscoverTrackingInfluencerToAdminMutation,
    add,
  ] = useAddDiscoverTrackingInfluencerToAdminMutation();

  const {
    data,
    loading,
    error,
    refetch,
  } = useGetDiscoverCategoryTopInfluencersQuery({
    variables: { category: category },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const allData = data?.getDiscoverCategoryTopInfluencers;

  const onTrack = async accountName => {
    try {
      const res = await addDiscoverTrackingInfluencerToAdminMutation({
        variables: {
          accountName: accountName,
        },
      });
      if (res.data?.addDiscoverTrackingInfluencerToAdmin?.id) {
        refetch();
      }
    } catch (e) {}
  };

  const globalErrors = useMapGraphQLErrorsMessages(error || add?.error);

  return (
    <TagsTable
      allData={allData}
      globalErrors={globalErrors}
      loading={loading || add?.loading}
      onTrack={value => onTrack(value)}
    />
  );
};
