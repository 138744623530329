import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class AgencyName extends Component {
  render() {
    return (
      <FormField name={this.props.name || "agency_name"} label="Agency Name">
        <Input data-cy="agency-name" size="large" placeholder="e.g. Fanbyte LLC" />
      </FormField>
    )
  }
}
