// @ts-nocheck
import * as React from "react";

interface ContextType {
  collapsed?: boolean;
  actions: {
    setCollapsed: (value: boolean) => void;
  };
}

const AppContext = React.createContext({} as ContextType);

// Create a React Hook that lets us get data from our app detail context
export function useAppDetails() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error(`something error`);
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
const BaseAppProvider = props => {
  const [collapsed, setCollapsed] = React.useState(window.innerWidth < 768);

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(() => {
    const internalVal: ContextType = {
      collapsed,

      actions: {
        setCollapsed,
      },
    };
    return internalVal;
  }, [collapsed]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

const AppProvider = React.memo(BaseAppProvider);

export { AppProvider };
