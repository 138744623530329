import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Table from "src/shared/Table";
import { LockOutlined } from '@ant-design/icons';
import { Tag, Button, message } from "antd";
import { withRouter } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import moment from "moment";
import { Input } from "formik-antd";
import { show } from "redux-modal";
import TableManager from "src/ducks/TableManager";
import { BrandList as $BrandList } from "src/ducks/DataList";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import CreateModal from "./BrandModal";
import Auth from "src/ducks/Auth";
import withWindowResize from "src/utils/hoc/withWindowResize";

const BrandTable = TableManager("CompanyBrandList");
const BrandList = $BrandList("CompanyBrandList");

const ClickTag = styled(Tag)`
  &&& {
    cursor: pointer;
  }
`;

const modules = [BrandTable.module(), BrandList.module()];

export class BrandsTable extends Component {
  loadBrands = filters => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return this.props.loadBrands({
      company: id,
      ...filters,
    });
  };

  renderDelete = (text, record) => {
    const { deleteBrand } = this.props;

    return (
      <ConfirmDeleteIcon
        text="Delete"
        trigger="click"
        key={record.id}
        onConfirm={e => {
          e.preventDefault();
          deleteBrand(record.id);
        }}
      />
    );
  };

  loginOtherUser = brand => {
    const { switchUser, history } = this.props;
    switchUser(brand.id)
      .then(() => history.push("/"))
      .catch(() => message.error("Failed to login user!"));
  };

  render() {
    const {
      data,
      pagination,
      loading,
      style,
      currentFilters,
      show,
      match: {
        params: { id },
      },
      height,
    } = this.props;

    const columns = {
      name: {
        title: "Name",
        dataIndex: "name",
        key: "name",
        visible: true,
        width: 180,
      },
      email: {
        title: "Email",
        dataIndex: "email",
        key: "email",
        visible: true,
        width: 250,
      },
      confirmed_at: {
        title: "Confirmed At",
        dataIndex: "confirmed_at",
        key: "confirmed_at",
        visible: true,
        width: 150,
        render: confirmed_at =>
          confirmed_at ? moment(confirmed_at).format("Do MMM YYYY") : null,
      },
    };

    const filters = {
      name: {
        input: () => <Input size="large" placeholder="e.g. Adidas" />,
        label: "Name",
      },
    };

    return <>
      <Table
        style={style}
        title="Company Users"
        columns={columns}
        loadData={this.loadBrands}
        name="CompanyBrandList"
        pagination={pagination}
        data={data}
        addedColumns={[
          {
            key: "actions",
            width: 100,
            render: this.renderDelete,
          },
          {
            key: "login",
            width: 150,
            render: (value, record) => (
              <ClickTag
                onClick={() => this.loginOtherUser(record)}
                color="blue"
              >
                Login as user <LockOutlined />
              </ClickTag>
            ),
          },
        ]}
        extra={[
          <Button
            type="primary"
            data-cy="company-user-create-button"
            key="create"
            onClick={() => show(`CreateBrandModal`)}
          >
            Create new User
          </Button>,
        ]}
        filterOptions={filters}
        loading={loading}
        size="middle"
        scroll={{ y: height, x: "max-content" }}
      />

      <CreateModal
        initialValues={{ company: id }}
        onCreate={() => this.loadBrands(currentFilters)}
      />
    </>;
  }
}

const mapStateToProps = state => ({
  currentFilters: BrandTable.selectFilters(state),
  data: BrandList.selectMany(state),
  pagination: BrandList.selectPagination(state),
  loading: BrandList.selectPending(state),
});

const mapDispatchToProps = {
  loadBrands: BrandList.loadMany,
  deleteBrand: BrandList.destroy,
  switchUser: Auth.switchUser,
  show,
};

const MainBrandsTable = withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandsTable))
)(modules);

export default withWindowResize({ extraHeight: 260 })(MainBrandsTable);
