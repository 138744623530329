// @ts-nocheck
import React, { useState } from "react";
import { Box } from "src/components/Core/Box";
import { Layout } from "antd";
import styled from "styled-components";
import SidebarContent from "./SidebarContent";
import Tags from "./Tags";
import MentionedAccounts from "./MentionedAccounts";
import AnalysedPosts from "./AnalysedPosts";
import Keywords from "./Keywords";
import { useBrandSafetyDetails } from "../BrandSafetyProvider";
import AnalyseLoading from "../AnalyseLoading";

const { Content, Sider } = Layout;

const Sidebar = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  zindex: 9999;
  left: 0;
  background: #ffffff !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  width: 250px !important;
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
`;

interface Props {
  step: number;
}

const AnalyseData: React.FC<Props> = ({ step }) => {
  const { analyseLoading, analyseData } = useBrandSafetyDetails();

  const [hoverTagText, setHoverTagText] = useState("");

  return (
    <Box>
      {analyseLoading && <AnalyseLoading />}
      <Layout>
        <Sidebar>
          <SidebarContent step={step} analyseData={analyseData} />
        </Sidebar>
        <Layout>
          <Content style={{ marginLeft: "250px" }}>
            <Box p={20}>
              {analyseData?.tags &&
                ((analyseData?.tags?.image || []).length > 0 ||
                  (analyseData?.tags?.text || []).length > 0 ||
                  (analyseData?.tags?.video || []).length > 0) && (
                  <Tags
                    tags={analyseData?.tags}
                    setHoverTagText={value => setHoverTagText(value)}
                  />
                )}

              {(analyseData?.analysedPosts || []).length > 0 && (
                <AnalysedPosts
                  analysedPosts={analyseData?.analysedPosts}
                  hoverTagText={hoverTagText}
                />
              )}

              {(analyseData?.keywords || []).length > 0 && (
                <Keywords keywords={analyseData?.keywords} />
              )}

              {(analyseData?.mentionedAccounts || []).length > 0 && (
                <MentionedAccounts
                  mentionedAccounts={analyseData?.mentionedAccounts}
                />
              )}
            </Box>
          </Content>
        </Layout>
      </Layout>
    </Box>
  );
};

export default AnalyseData;
