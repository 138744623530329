import axiosInstance from "../axiosInstance";
// Endpoint
const CREATE_CREATIVE = `/creatives`;
const GET_CREATIVE = `/creatives`;
const getCreative = id => `/creatives/${id}`;
const updateCreative = id => `/creatives/${id}`;
const deleteCreative = id => `'/creatives/${id}`;

export const create = data => axiosInstance.post(CREATE_CREATIVE, data);

export const index = data => axiosInstance.get(GET_CREATIVE, data);

export const get = (id, data) => axiosInstance.get(getCreative(id), data);

export const update = (id, data) =>
  axiosInstance.patch(updateCreative(id), data);

export const destroy = id => axiosInstance.delete(deleteCreative(id));
