// @ts-nocheck
import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardLayout from "../../layouts/DashboardLayout";
import Container from "src/shared/Container";
import PageHero from "src/shared/PageHero";
import Auth from "src/ducks/Auth";
import { CampaignPersistModule } from "src/ducks/Persist";
import withModules from "src/utils/hoc/withModules";
import { CampaignsListContainer } from "./CampaignsList";

const modules = [CampaignPersistModule("BrandDashboardPersist")];

class Dashboard extends Component<any> {
  render() {
    const { user } = this.props;

    return (
      <DashboardLayout title="Dashboard">
        <PageHero
          title={"Welcome, " + user.name}
          subTitle="Here you can see all your current Fanbytes campaigns"
        />

        <Container>
          <CampaignsListContainer />
        </Container>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: Auth.selectUser(state),
});

const mapDispatchToProps = {};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
)(modules);
