import React, { Component } from "react";
import FormField from "../FormField";
import ImageUploadField from "../ImageUploadField";

export default class Screenshots extends Component {
  render() {
    const name = this.props.name || "screenshots";

    return (
      <FormField
        name={name}
        label="View Screenshots"
        help="Upload screenshot evidence of the influencers data"
      >
        <ImageUploadField data-cy="account-screenshots" name={name} />
      </FormField>
    );
  }
}
