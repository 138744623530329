import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { List, Typography, Divider, Empty } from "antd";
import { InvitationList, InvitationListModule } from "src/ducks/DataList";
import BrandedPost from "src/shared/BrandedPost";
import withModules from "src/utils/hoc/withModules";
import idx from "idx";
import { isImgLink } from "src/utils/isImgLink";

const { Text } = Typography;

const ContentTitle = styled(Text)`
  color: #333333 !important;
  font-size: 24px;
  font-weight: 500;
`;

const CompleteInvitations = InvitationList("FindAccountModal");

const modules = [InvitationListModule("FindAccountModal")()];

const FixedList = styled(List)`
  &&& {
    .ant-row {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

class BrandedContent extends Component {
  state = {
    per_page: 4,
    page: 1,
  };

  componentDidMount() {
    this.loadInvitations();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account.id !== this.props.account.id) {
      this.props.clear();
      this.loadInvitations();
    }
  }

  loadInvitations = () => {
    const { loadCompleteInvitations, account } = this.props;
    if (account)
      loadCompleteInvitations({
        ...this.state,
        account: account.id,
        stage: "posted",
        expand: ["campaign", "campaign.company"],
      });
  };

  render() {
    const {
      completeInvitations,
      completeInvitationsPagination: { current_page, total_entries, per_page },
      completeInvitationsLoading,
      shouldShowLabel = true,
    } = this.props;

    return completeInvitations.length > 0 ? (
      <>
        {shouldShowLabel && (
          <>
            <ContentTitle>Previous branded content</ContentTitle>
            <Divider />
          </>
        )}
        <FixedList
          grid={{ gutter: 16, column: 4 }}
          style={{ marginBottom: "15px" }}
          loading={completeInvitationsLoading}
          pagination={{
            onChange: page => {
              this.setState(
                {
                  page: page,
                },
                this.loadInvitations
              );
            },
            current: current_page || null,
            total: total_entries || null,
            pageSize: per_page || null,
          }}
          dataSource={completeInvitations}
          renderItem={item => {
            const draft = item.orderedDrafts().at(0);
            const draftMedia = idx(draft, x => x.all_media) || [];

            return draftMedia.map((item1, index) => {
              return (
                <List.Item key={index}>
                  <BrandedPost
                    content_type={isImgLink(item1) ? "image" : "video"}
                    content_url={item1}
                    // content_type="video"
                    // content_url={
                    //   item.orderedDrafts().at(0) &&
                    //   item.orderedDrafts().at(0).video_url
                    // }
                    invitation={item}
                    company_name={`${item.getCompanyName()}_${index + 1}`}
                    likes={item.formattedLikes()}
                    comments={item.formattedComments()}
                    views={item.formattedContentViews()}
                    posted_at={item.formattedContentPostedAt()}
                  />
                </List.Item>
              );
            });
          }}
        />
      </>
    ) : (
      <Empty description="No data available!" />
    );
  }
}

const mapStateToProps = state => ({
  completeInvitations: CompleteInvitations.selectMany(state),
  completeInvitationsPagination: CompleteInvitations.selectPagination(state),
  completeInvitationsLoading: CompleteInvitations.selectPending(state),
});

const mapDispatchToProps = {
  loadCompleteInvitations: CompleteInvitations.loadMany,
  clear: CompleteInvitations.clear,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(BrandedContent)
)(modules);
