import React, { Component } from "react";
import { Row, Col } from "antd";
import TableContext from "./Context";
import ResultsTable from "./ResultsTable";
import FilterBar from "./FilterBar";
import Stats from "./Stats";

export default class TableLayout extends Component {
  render() {
    const {
      columns,
      data,
      loading,
      filterForm,
      pagination,
      name,
      addedColumns,
      filterOptions,
      stats,
      scroll,
    } = this.props;

    return (
      <Row style={{ padding: "0px 24px" }} gutter={24}>
        <Col sm={24} md={4} style={{ marginBottom: "24px" }}>
          <FilterBar filterForm={filterForm} filterOptions={filterOptions} />
        </Col>
        <Col sm={24} md={20}>
          {stats && <Stats stats={stats} pagination={pagination} />}
          <TableContext.Consumer>
            {value => (
              <ResultsTable
                moduleName={value}
                columns={columns}
                addedColumns={addedColumns}
                data={data}
                name={name}
                loading={loading}
                pagination={pagination}
                scroll={scroll}
              />
            )}
          </TableContext.Consumer>
        </Col>
      </Row>
    );
  }
}
