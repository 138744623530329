// @ts-nocheck
import React from "react";
import { Menu } from "antd";

interface Props {
  currentStep: number;
}

const SideMenu: React.FC<Props> = ({ currentStep }) => {
  return (
    <>
      <Menu
        mode="vertical"
        selectedKeys={[currentStep.toString()]}
        style={{ height: "100%" }}
      >
        <Menu.Item key="1">
          1.
          <span> Personal details</span>
        </Menu.Item>

        <Menu.Item key="2">
          2.
          <span> Profile details</span>
        </Menu.Item>

        <Menu.Item key="3">
          3.
          <span> Profile complete</span>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SideMenu;
