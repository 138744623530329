// @ts-nocheck
import React from 'react'
import { Transaction } from '.'
import {
  useGetPublicCampaignQuery
} from 'src/generated/ApolloComponents'
import { useParams } from 'react-router-dom'
import { useMapGraphQLErrorsMessages } from 'src/utils/hooks/useMapGraphQLErrors'

export const TransactionContainer = () => {
  const params: any = useParams();
  const { data, loading, error, refetch } = useGetPublicCampaignQuery({
    variables: {campaignId: Number(params?.id)},
  });

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return(
    <Transaction
      campaign={data?.getPublicCampaign?.publicCampaign || {}}
      influencerAccounts={data?.getPublicCampaign?.influencerAccounts || [] as any}
      loading={loading}
      error={globalErrors}
      refetchCampaign={refetch}
    />
  )
}
