// @ts-nocheck
import React from "react"
import { AddAccountModal, AddTiktokModalValues } from "."
import { object } from "yup"

interface Props {
  visible: boolean
  setVisible: Function
  platform: string
  initialValues: any
  onSubmit: (values: any, actions: any) => any
}

export const AddAccountModalContainer = ({
  initialValues,
  onSubmit,
  platform,
  visible,
  setVisible
}: Props) => {
  const schema = object<AddTiktokModalValues>().shape({})

  const onModalSubmit = async (values, actions) => {
    await onSubmit(values, actions)
    setVisible(false)
  }

  return (
    <AddAccountModal
      platform={platform}
      visible={visible}
      setVisible={setVisible}
      initialValues={initialValues}
      schema={schema}
      onSubmit={onModalSubmit}
    />
  )
}
