// @ts-nocheck
import React, { useEffect } from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Radio, Row, Col } from "antd";
import { AmountRadio } from "../../../Wrapper";
import { Box } from "src/components/Core/Box";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { FormikRadio } from "src/components/Forms/Radio";
import { campaign_completed_month } from "src/constants/account";
import { AudienceBreakdownComparisonType } from "src/generated/ApolloComponents";
import { RemoveIcon } from "../../../Wrapper";

interface Props {
  formProps: FormikProps<any>;
  isExpand: boolean;
  clearFilterKey: (key: string) => void;
}

const CampaignCompleted: React.FC<Props> = ({
  formProps,
  isExpand,
  clearFilterKey,
}) => {
  const fieldValue = formProps?.values?.completedCampaigns;

  let isVisible = false;

  if (
    fieldValue &&
    ((!isExpand && fieldValue?.months) ||
      (isExpand && fieldValue?.months && fieldValue?.months))
  ) {
    isVisible = true;
  }

  useEffect(() => {
    let container = {} as any;
    if (isExpand) {
      container.months = fieldValue?.months ? fieldValue?.months : "";
      container.comparison = fieldValue?.comparison
        ? fieldValue?.comparison
        : AudienceBreakdownComparisonType.GreaterEqualThan;
      container.value = fieldValue?.value ? fieldValue?.value : 0;
    } else {
      container.months = fieldValue?.months ? fieldValue?.months : "";
    }

    formProps.setFieldValue("completedCampaigns", container);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpand]);

  let container = {} as any;
  if (isExpand) {
    container.months = fieldValue?.months ? fieldValue?.months : "";
    container.comparison = fieldValue?.comparison
      ? fieldValue?.comparison
      : AudienceBreakdownComparisonType.GreaterEqualThan;
    container.value = fieldValue?.value ? fieldValue?.value : 0;
  } else {
    container.months = fieldValue?.months ? fieldValue?.months : "";
  }

  return (
    <Box id="completed_campaigns">
      <Row gutter={4}>
        <Field
          label="Has completed a campaign in:"
          mb={20}
          color="black"
          isBeside={true}
          extra={
            isVisible && (
              <RemoveIcon
                type="close-circle"
                onClick={() => {
                  formProps?.setFieldValue(
                    "completedCampaigns",
                    isExpand
                      ? {
                          month: "",
                          comparison:
                            AudienceBreakdownComparisonType.GreaterEqualThan,
                          value: 0,
                        }
                      : { month: "" }
                  );
                  clearFilterKey("completedCampaigns");
                }}
                style={{
                  marginTop: "-1px",
                  marginLeft: "10px",
                }}
              />
            )
          }
        >
          {isExpand && (
            <Col span={15}>
              <Box>
                <AmountRadio>
                  <Radio.Button
                    value={AudienceBreakdownComparisonType.SmallerEqualThan}
                    onChange={value => {
                      if (
                        fieldValue?.comparison ===
                          AudienceBreakdownComparisonType.SmallerEqualThan ||
                        fieldValue?.value === 0
                      ) {
                        formProps?.setFieldValue(
                          "completedCampaigns.comparison",
                          AudienceBreakdownComparisonType.SmallerEqualThan
                        );
                      }
                    }}
                    checked={
                      fieldValue?.comparison ===
                      AudienceBreakdownComparisonType.SmallerEqualThan
                    }
                  >
                    <LeftOutlined />
                  </Radio.Button>
                  <Radio.Button
                    value={AudienceBreakdownComparisonType.GreaterEqualThan}
                    onChange={value => {
                      if (
                        fieldValue?.comparison ===
                          AudienceBreakdownComparisonType.GreaterEqualThan ||
                        fieldValue?.value === 0
                      ) {
                        formProps?.setFieldValue(
                          "completedCampaigns.comparison",
                          AudienceBreakdownComparisonType.GreaterEqualThan
                        );
                      }
                    }}
                    checked={
                      fieldValue?.comparison ===
                      AudienceBreakdownComparisonType.GreaterEqualThan
                    }
                  >
                    <RightOutlined />
                  </Radio.Button>
                </AmountRadio>

                <FormikInputNumber
                  parser={value => {
                    let parsed = value.replace(/\[0-9]/g, "");
                    if (parsed) {
                      return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                    } else {
                      return null;
                    }
                  }}
                  name="completedCampaigns.value"
                  borderRadius={3}
                  borderColor="grey5"
                  size="small"
                  value={fieldValue?.value || 0}
                  style={{ marginLeft: 5, width: "72px" }}
                />
              </Box>
            </Col>
          )}

          <Col span={9}>
            <FormikRadio
              name="completedCampaigns.months"
              data={campaign_completed_month}
              style={{ fontWeight: 300 }}
            />
          </Col>
        </Field>
      </Row>
    </Box>
  );
};

export default CampaignCompleted;
