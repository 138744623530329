import React, { Component } from "react"
import FormField from "../FormField"
import { Input } from "formik-antd"

export default class LinkTitle extends Component {
  render() {
    return (
      <FormField name={this.props.name || "link_title"} label="Link Title">
        <Input data-cy="link-title" size="large" placeholder="e.g. New shoe launch" />
      </FormField>
    )
  }
}
