import React, { Component } from "react"
import { connect } from "react-redux"
import Table from "src/shared/Table"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import withModules from "src/utils/hoc/withModules"
import { Select } from "formik-antd"
import TableManager from "src/ducks/TableManager"
import { InvitationList as $InvitationList } from "src/ducks/DataList"
import { statuses } from "src/constants/invitations"

const InvitationTable = TableManager("CampaignsAccountInvitationList")
const InvitationList = $InvitationList("CampaignsAccountInvitationList")

const modules = [InvitationTable.module(), InvitationList.module()]

const columns = {
  campaign: {
    title: "Campaign",
    dataIndex: "campaign.title",
    visible: true,
    render: (text, row) => row.campaign && <Link to={`/campaigns/${row.campaign.id}`}>{text}</Link>
  },

  actual_payout: {
    title: "Actual Payout",
    dataIndex: "payout_pennies",
    visible: true,
    render: (text, row) => row.formattedPayout()
  }
}

const filters = {
  status: {
    input: () => (
      <Select placeholder="Status" size="large" options={statuses}>
      </Select>
    ),
    label: "Status"
  }
}

export class InvitationsTable extends Component {
  loadInvitations = (filters) => {
    const { account } = this.props

    return this.props.loadInvitations({
      expand: ["campaign"],
      account: account.id,
      ...filters
    })
  }

  render() {
    const { data, pagination, loading, style } = this.props

    return (
      <Table
        style={style}
        title="Invitations"
        columns={columns}
        loadData={this.loadInvitations}
        name="CampaignsAccountInvitationList"
        pagination={pagination}
        data={data}
        filterOptions={filters}
        loading={loading}
        size="middle"
      />
    )
  }
}

const mapStateToProps = (state) => ({
  data: InvitationList.selectMany(state),
  pagination: InvitationList.selectPagination(state),
  loading: InvitationList.selectPending(state)
})

const mapDispatchToProps = {
  loadInvitations: InvitationList.loadMany
}

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationsTable))
)(modules)
