import React, { Component } from "react";
import { connect } from "react-redux";
import { default as $CampaignManage } from "src/ducks/CampaignManage";
import Loading from "src/shared/Loading";
import { message } from "antd";
import DashboardLayout from "../../layouts/DashboardLayout";
import withModules from "src/utils/hoc/withModules";
import Draft from "./Draft";
import Active from "./Active";
import Submitted from "./Submitted";
import InfluencerModal from "./FindInfluencers/InfluencerModal";

const modules = [$CampaignManage.module()];

export class CampaignManage extends Component {
  componentDidMount() {
    const {
      loadCampaign,
      history,
      match: {
        params: { id }
      }
    } = this.props;
    loadCampaign(id).catch(() => {
      message.error("There was an error loading your campaign");
      history.push("/");
    });
  }

  render() {
    const {
      campaign,
      updateCampaign,
      updateError,
      destroyCampaign
    } = this.props;

    const title = campaign && campaign.title ? campaign.title : "New campaign";

    const renderContent = () => {
      if (!campaign) return <Loading />;

      if (campaign.status === "draft")
        return (
          <Draft
            campaign={campaign}
            title={title}
            updateError={updateError}
            updateCampaign={updateCampaign}
            destroyCampaign={destroyCampaign}
          />
        );

      if (campaign.status === "submitted")
        return <Submitted title={title} campaign={campaign} />;

      if (campaign.status === "active" || campaign.status === "complete")
        return <Active title={title} campaign={campaign} />;
    };

    return <DashboardLayout title={title}>{renderContent()}</DashboardLayout>;
  }
}

const mapStateToProps = state => ({
  campaign: $CampaignManage.campaign.selectOne(state),
  updateError: $CampaignManage.campaign.selectUpdateErrorData(state)
});

const mapDispatchToProps = {
  updateCampaign: $CampaignManage.campaign.update,
  destroyCampaign: $CampaignManage.campaign.destroy,
  loadCampaign: $CampaignManage.campaign.get
};

const MainCampaignManage = withModules(
  connect(mapStateToProps, mapDispatchToProps)(CampaignManage)
)(modules);

export default props => {
  return (
    <>
      <MainCampaignManage {...props} />
      <InfluencerModal />
    </>
  );
};
