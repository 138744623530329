import React, { Component } from "react"
import { withFormik } from "formik"
import { connect } from "react-redux"
import { Modal, Button, message } from "antd"
import { connectModal } from "redux-modal"
import { Form, Input } from "formik-antd"
import FormField from "src/shared/FormField"
import { AdminPersist } from "src/ducks/Persist"

const TeamPersist = AdminPersist("AdminTeamMembers")

class InviteModal extends Component {
  render() {
    const { show, handleHide, loading, handleSubmit } = this.props
    return (
      <Modal
        title="Invite new admin"
        visible={show}
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Invite
          </Button>
        ]}
        {...this.props}
      >
        <Form>
          <FormField name="name" label="Name">
            <Input size="large" placeholder="e.g. Joe Bloggs" />
          </FormField>
          <FormField name="email" label="Email">
            <Input size="large" placeholder="e.g. joe@company.com" />
          </FormField>
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  inviteAdmin: TeamPersist.create
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  connectModal({
    name: "InviteAdmin",
    destroyOnHide: true
  })(
    withFormik({
      validateOnBlur: false,

      mapPropsToValues: () => ({ email: "", name: "" }),

      handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        props
          .inviteAdmin(values)
          .then(() => {
            setSubmitting(false)
            props.handleHide()
            props.onInvite && props.onInvite()
          })
          .catch((res) => {
            setSubmitting(false)
            message.error("Failed to invite admin")
            setErrors(res.response.data.meta.errors)
          })
      },

      displayName: "InviteTeamMemberForm"
    })(InviteModal)
  )
)
