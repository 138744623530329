import axiosInstance from "../axiosInstance";
// Endpoint
const CREATE_CAMPAIGN = `/campaigns`;
const GET_CAMPAIGNS = `/campaigns`;
const getCampaign = id => `/campaigns/${id}`;
const updateCampaign = id => `/campaigns/${id}`;
const deleteCampaign = id => `/campaigns/${id}`;
const payForCampaign = id => `/campaigns/${id}/pay`;

export const create = data => axiosInstance.post(CREATE_CAMPAIGN, data);

export const index = data => axiosInstance.get(GET_CAMPAIGNS, data);

export const get = (id, data) => axiosInstance.get(getCampaign(id), data);

export const customMethod = (id, request, method, data) =>
  axiosInstance[request](getCampaign(id + "/" + method), data);

export const update = (id, data) =>
  axiosInstance.patch(updateCampaign(id), data);

export const pay = id => axiosInstance.patch(payForCampaign(id));

export const destroy = id => axiosInstance.delete(deleteCampaign(id));
