// @ts-nocheck
import React, { Fragment } from 'react'
import { InvitationTitle } from './InvitationTitle'
import { Username } from './Username'

export interface Props {
  duplicateInvitation: any
  invitation: any
  updateTitle: (value: string) => void
}

const Title = ({duplicateInvitation, invitation, updateTitle}: Props) => (
  <Fragment>
    {invitation?.campaign?.title}
    {'\u00A0\u00A0'}
    <InvitationTitle
      loading={duplicateInvitation?.loading}
      invitation={invitation}
      updateTitle={updateTitle}
    />
    {'\u00A0\u00A0'}
    <Username username={invitation?.account?.username} />
  </Fragment>
)

export default Title;
