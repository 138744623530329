import { Modal, PageHeader, Button } from "antd";
import styled from "styled-components";

export const InviteModal = styled(Modal)`
  &&& {
    .ant-modal-header {
      padding: 0px 44px;
    }
  }
`;

export const StyledHeader = styled(PageHeader)`
  &&& {
    .ant-page-header-heading-title {
      display: flex;
    }
    .ant-typography {
      font-family: 'Futura';
      font-weight: 500;
      color: #000000;
    }
  }
`

export const StyledButton = styled(Button)`
 &&& {
   font-family: inherit;
   font-style: normal;
   font-weight: 500;
   font-size: 12.24px;
   height: 24.46px;
 }
`
