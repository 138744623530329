import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Descriptions } from "antd";
import Container from "src/shared/Container";
import { AgencyPersist } from "src/ducks/Persist";
import InfluencersTable from "./InfluencersTable";
import { featureFlags } from "src/constants/featureFlags";

const ShowAgency = AgencyPersist("AdminAgencyShow");

const DetailDescriptions = styled(Descriptions)`
  background: #fff;
`;

export class AgencyDetails extends Component {
  render() {
    const { agency } = this.props;

    return (
      <Container style={{ marginTop: 24 }}>
        <DetailDescriptions size="middle" bordered>
          <Descriptions.Item label="Agency Name" span={3}>
            {agency.agency_name}
          </Descriptions.Item>

          {featureFlags.isPaypalEnabled &&
            <Descriptions.Item label="Paypal Email" span={3}>
              {agency.paypal_email}
            </Descriptions.Item>
          }
        </DetailDescriptions>
        <InfluencersTable style={{ marginTop: 24 }} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  agency: ShowAgency.selectOne(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyDetails);
