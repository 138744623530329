// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import numeral from "numeral";
import { currency } from "../../../../../constants/payments";

interface Props {
  cashoutData: any;
}

const HeadingInfoText: React.FC<Props> = ({ cashoutData }) => {
  const getCashOutLabel = (payoutTypeCurrency) => {
    return (currency.find(({value}) => value === payoutTypeCurrency))?.label;
  }

  return (
    <Box borderBottom="1px solid #D9D9D9">
      <Box p={24}>
        <Typography
          size={30}
          weight="semi-bold"
          color="black"
          lineHeight="25px"
        >
          Influencer Hub
        </Typography>

        <Typography size={16} color="grey2" lineHeight="25px">
          Welcome to your campaign hub. Here you will be able to manage
          communication about your{" "}
          <strong>{cashoutData?.campaign?.title}</strong> campaign. Your payout
          for this campaign is{" "}
          <strong>
            {(getCashOutLabel(cashoutData?.payoutTypeCurrency))}
            {cashoutData?.payoutTypeOriginalPennies &&
              numeral(cashoutData.payoutTypeOriginalPennies / 100).format(
                "0,0.00"
              )}
          </strong>
          . Please complete the form below to complete your campaign and receive
          your payout. Once complete your campaign manager will be automatically
          notified
        </Typography>
      </Box>
    </Box>
  );
};

export default HeadingInfoText;
