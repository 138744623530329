import React, { Component } from "react";
import { connect } from "react-redux";
import FormField from "../FormField";
import { connect as FormikConnect } from "formik";
import SearchSelect from "src/shared/SearchSelect";
import { AgencyList as $AgencyList } from "src/ducks/DataList";

const AgencyList = $AgencyList("InfluencerAgencyList");

export class Agency extends Component {
  render() {
    const { loadAgencies } = this.props;

    return (
      <FormField name={this.props.name || "agency"} label="Agency">
        <SearchSelect
          data-cy="influencer-agency"
          loadData={value =>
            loadAgencies({ search: value }).then(res => res.value.data.data)
          }
          optionGenerator={value => ({
            value: value.id,
            label: value.agency_name
          })}
          size="large"
          placeholder="Select..."
        />
      </FormField>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loadAgencies: AgencyList.loadMany
};

export default FormikConnect(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Agency)
);
