import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "src/shared/Container";
import BrandedContent from "src/shared/BrandedContent";
import OrganicContent from "src/shared/OrganicContent";

import { AccountPersist } from "src/ducks/Persist";
const ShowAccount = AccountPersist("AdminAccountShow");

export class PastContent extends Component {
  render() {
    const { account } = this.props;

    return (
      <Container style={{ marginTop: 24, marginBottom: 24 }}>
        <BrandedContent account={account} />
        <OrganicContent
          account={account}
          grid={window.innerWidth >= 1100 ? 2 : 1}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  account: ShowAccount.selectOne(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PastContent);
