import React from "react";

export default WrappedComponent => Steps => {
  // ...and returns another component...
  return class extends React.Component {
    state = {
      currentStep: 0
    };

    changeToInvalidStep = data => {
      for (let key = 0; key < Steps.length; key++) {
        const { validate } = Steps[key];

        if (validate && !validate.isValidSync(data))
          return this.changeStep(key);
      }

      return this.changeStep(Steps.length - 1);
    };

    changeToFirstErrorStep = errors => {
      for (let key = 0; key < Steps.length; key++) {
        const { fields } = Steps[key];

        if (fields) {
          for (var errorKey in errors) {
            if (fields.hasOwnProperty(errorKey)) return this.changeStep(key);
          }
        }
      }
    };

    stepStatuses = (data, errors, currentStep) =>
      Object.keys(Steps).reduce((a, key) => {
        const { validate, fields } = Steps[key];
        const noErrors =
          errors && fields
            ? Object.keys(errors)
                .map(e => fields.hasOwnProperty(e))
                .every(e => !e)
            : true;

        if (currentStep === parseInt(key)) {
          return Object.assign(a, { [key]: "process" });
        } else if (validate && validate.isValidSync(data) && noErrors) {
          return Object.assign(a, { [key]: "finish" });
        } else {
          return Object.assign(a, { [key]: "wait" });
        }
      }, {});

    errorsForOmittedFields = errors => {
      if (!errors) return [];

      const allFields = Steps.map(x => x.fields)
        .filter(x => x)
        .reduce((r, a) => Object.assign(r, a), {});

      return Object.keys(errors)
        .map(key => (!allFields.hasOwnProperty(key) ? errors[key] : null))
        .filter(x => x);
    };

    changeStep = (index, callback) =>
      this.setState(
        {
          currentStep: index
        },
        callback
      );

    render() {
      return (
        <WrappedComponent
          currentStep={this.state.currentStep}
          changeToInvalidStep={this.changeToInvalidStep}
          changeToFirstErrorStep={this.changeToFirstErrorStep}
          stepStatuses={this.stepStatuses}
          changeStep={this.changeStep}
          errorsForOmittedFields={this.errorsForOmittedFields}
          {...this.props}
        />
      );
    }
  };
};
