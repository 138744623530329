import { many, attr, Model } from "redux-orm";

class Group extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    return this.upsert(data);
  }
}
Group.modelName = "Group";

// Declare your related fields.
Group.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  group_name: attr(),
  campaigns: many("Campaign")
};

export default Group;
