// @ts-nocheck
import React from "react";
import { Screenshots } from ".";
import { gql } from "apollo-boost";
import axios from "axios";
import { useGetGeneralSignedUrlMutation } from "src/generated/ApolloComponents";

gql`
  mutation getScreenshotSignedURL($fileName: String!, $fileType: String!) {
    getScreenshotSignedURL(
      input: { fileName: $fileName, fileType: $fileType }
    ) {
      url
      uploadUrl
    }
  }
`;

interface Props {
  name: string;
  isUploading: boolean;
  setIsUploading: (loading: boolean) => void;
  setFieldValue: (value: any) => void;
  setFieldTouched: () => void;
  values: any;
}

export const ScreenshotsContainer: React.FC<Props> = ({
  setIsUploading,
  isUploading,
  setFieldValue,
  setFieldTouched,
  values,
  name,
}) => {
  const [getGeneralSignedUrlMutation] = useGetGeneralSignedUrlMutation()

  const onUpload = async data => {
    setIsUploading(true);

    const fileData = {
      fileName: data.file.name,
      fileType: data.file.type,
    };

    try {
      const result = await getGeneralSignedUrlMutation({
        variables: fileData,
      });

      if (result) {
        const signedUrl = result?.data?.getGeneralSignedURL?.url!;

        const options = {
          headers: {
            "Content-Type": data.file.type,
          },
        };

        axios
          .put(signedUrl as string, data.file, options)
          .then(() => {
            const value = [
              {
                uploadUrl: result?.data?.getGeneralSignedURL?.uploadUrl,
                uploadType: data.file.type,
                previewUrl: result?.data?.getGeneralSignedURL?.previewUrl,
              },
            ];

            setFieldValue(values[name] ? values[name].concat(value) : value);

            setIsUploading(false);

            setFieldTouched();
          })
          .catch(err => {
            setIsUploading(false);
          });
      }
    } catch (e) {
      setIsUploading(false);
    }
  };

  const handleRemove = file => {
    let valueCopy = values[name].slice();

    if (!file.upload_id) {
      valueCopy.splice(file.uid, 1);
    } else {
      valueCopy[file.uid].delete = true;
      valueCopy[file.uid].status = "error";
    }

    setFieldValue(valueCopy);
  };

  return (
    <Screenshots
      value={values?.screenshots}
      isUploading={isUploading}
      handleRemove={value => handleRemove(value)}
      onUpload={value => onUpload(value)}
    />
  );
};
