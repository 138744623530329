// @ts-nocheck
import React from "react";
import { ChartCard } from "ant-design-pro/lib/Charts";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Popover, Avatar } from "antd";
import { Box } from "src/components/Core/Box";
import { Button } from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";
import moment from "moment";

type Props = {
  data: any;
  onChangeActiveKey: Function;
  onViewPerformance: (value: any) => void;
};

const InfluencersPosted: React.FC<Props> = ({
  data,
  onChangeActiveKey,
  onViewPerformance,
}) => {
  if (!data?.noInfluencersPosted) return null;
  const { posted, total } = data?.noInfluencersPosted;

  // const percent = (posted * 1.0) / total;
  // const percent = Number(((posted * 100) / total) * 2)?.toFixed(2);
  const percent = Number(100 / total)?.toFixed(2);

  const postedAccounts = data?.postedAccounts || [];

  return (
    <Row>
      <Col span={24} style={{ marginTop: 24 }}>
        <ChartCard
          title={`Influencers Posted ${posted + " / " + total}`}
          action={
            <Tooltip
              placement="left"
              title="How many influencers have gone live with their content?"
            >
              <InfoCircleOutlined />
            </Tooltip>
          }
          // total={posted + " / " + total}
          contentHeight={46}
          style={{ padding: 20 }}
        >
          <Box width="100%" display="flex">
            {[...Array(total)].map((t, i) => {
              return i < posted ? (
                <Popover
                  key={i}
                  placement="bottom"
                  content={
                    <Box>
                      <Typography size={12} color="grey7" mb={20} ml={10}>
                        Posted:{" "}
                        {moment(postedAccounts?.[i]?.updatedAt).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                      <Button
                        type="action"
                        buttonSize="small"
                        data-cy="track-button"
                        onClick={e => {
                          e.preventDefault();
                          onViewPerformance(postedAccounts?.[i]);
                          onChangeActiveKey();
                        }}
                      >
                        View performance
                      </Button>
                    </Box>
                  }
                  title={
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={postedAccounts?.[i]?.profilePicUrl}
                        size={20}
                      />
                      <Typography size={12} color="black" mb={0} ml={10}>
                        @{postedAccounts?.[i]?.username}
                      </Typography>
                    </Box>
                  }
                >
                  <Box
                    width={`${percent}%`}
                    height="10px"
                    mr="5px"
                    background={"#40A9FF"}
                  ></Box>
                </Popover>
              ) : (
                <Box
                  key={i}
                  width={`${percent}%`}
                  height="10px"
                  mr="5px"
                  background={"#E8E8E8"}
                ></Box>
              );
            })}
          </Box>
        </ChartCard>
      </Col>
    </Row>
  );
};

export default InfluencersPosted;
