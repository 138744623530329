import React, { Component } from "react"
import styled from "styled-components"
import FormField from "../FormField"
import { DatePicker } from "formik-antd"
import moment from "moment"

const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`

export default class EndDate extends Component {
  render() {
    return (
      <FormField name={this.props.name || "end_date"} label="End Date (optional)">
        <FullWidthDatePicker
          data-cy="date-picker"
          disabledDate={(current) => current && current < moment().endOf("day")}
          size="large"
          format="DD/MM/YYYY"
        />
      </FormField>
    )
  }
}
