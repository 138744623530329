// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";

interface Props {}

const PostUrl: React.FC<Props> = () => {
  return (
    <Field label="Post URL:" mb={26}>
      <FormikInput
        name="tiktokUrl"
        size="large"
        placeholder="Tiktok.com/..."
        background="background"
        borderRadius={3}
        borderColor="grey5"
      />
    </Field>
  );
};

export default PostUrl;
