import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter, Switch, Route } from "react-router-dom";
import withModules from "src/utils/hoc/withModules";
import { PageHeader, Layout, message } from "antd";
import DashboardLayout from "src/scenes/layouts/DashboardLayout";
import { AgencyPersist } from "src/ducks/Persist";
import Loading from "src/shared/Loading";
import AgencyDetails from "./AgencyDetails";
import UpdateAgency from "./UpdateAgency";
import SideMenu from "./SideMenu";
import ConfirmDeleteIcon from "src/shared/ConfirmDeleteIcon";
import { useAppDetails } from "src/AppProvider";

const { Content, Sider } = Layout;

const ShowAgency = AgencyPersist("AdminAgencyShow");

const modules = [ShowAgency.module()];

const BorderHeader = styled(PageHeader)`
  border-bottom: 1px solid #e8e8e8;
`;

const AgencyShow = props => {
  const { collapsed } = useAppDetails();

  useEffect(() => {
    const {
      loadAgency,
      match: {
        params: { id },
      },
    } = props;
    loadAgency(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAgency = () => {
    const { destroyAgency, agency, history } = props;
    destroyAgency(agency.id)
      .then(history.goBack)
      .catch(() => message.error("Could not delete account"));
  };

  const { history, agency, loading, match } = props;

  return (
    <DashboardLayout title={agency ? agency.agency_name : "Loading..."}>
      <Loading spinning={!agency || loading}>
        {agency && (
          <>
            <BorderHeader
              style={{
                top: "68px",
                width: `calc(100% - ${collapsed ? 80 : 200}px)`,
                overflow: "auto",
                position: "fixed",
                zIndex: 60,
                backgroundColor: 'white',
              }}
              extra={[
                <ConfirmDeleteIcon
                  text="Delete"
                  trigger="click"
                  key="delete-icon"
                  onConfirm={deleteAgency}
                  placement="bottomRight"
                />,
              ]}
              onBack={history.goBack}
              title={agency.agency_name}
            />
            <Layout>
              <Sider
                width={200}
                style={{
                  background: "#fff",
                  overflow: "auto",
                  height: "100vh",
                  marginTop: 69,
                  position: "fixed",
                }}
              >
                <SideMenu />
              </Sider>
              <Content
                style={{
                  marginLeft: 200,
                  marginTop: 58,
                  height: "calc(100vh - 126px)",
                }}
              >
                <Switch>
                  <Route
                    path={`${match.path}/update`}
                    exact
                    component={UpdateAgency}
                  />
                  <Route
                    path={`${match.path}`}
                    exact
                    component={AgencyDetails}
                  />
                </Switch>
              </Content>
            </Layout>
          </>
        )}
      </Loading>
    </DashboardLayout>
  );
};

const mapStateToProps = state => ({
  agency: ShowAgency.selectOne(state),
  loading: ShowAgency.selectGetPending(state),
});

const mapDispatchToProps = {
  loadAgency: ShowAgency.get,
  destroyAgency: ShowAgency.destroy,
};

export default withModules(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AgencyShow))
)(modules);
