import React, { Component } from "react";
import { connect } from "react-redux";
import withModules from "src/utils/hoc/withModules";
import { Checkbox } from "formik-antd";
import FormField from "../FormField";
import { TagList as $TagList, TagListModule } from "src/ducks/DataList";
import styled from "styled-components";

const VerticalCheckbox = styled(Checkbox)`
  &&& {
    display: inline-block;
    height: 20px;
    color: #595959;
    font-size: 14px;
    margin-left: 5px;
    width: 98px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;

const TagList = $TagList("AccountTagList");

const modules = [TagListModule("AccountTagList")()];

export class AccountTag extends Component {
  componentDidMount() {
    const { loadTags } = this.props;
    loadTags({ is_defined: true });
  }

  render() {
    const { data } = this.props;

    return (
      <>
        <FormField name={this.props.name || "tags"}>
          <Checkbox.Group>
            {(data || []).map((value, i) => (
              <VerticalCheckbox
                data-cy={`tags-checkbox-${i}`}
                key={i}
                value={value.id}
              >
                <span title={value.name}>
                  {value.name === "Business & Finance"
                    ? "Bus. & Fin."
                    : value.name}
                </span>
              </VerticalCheckbox>
            ))}
          </Checkbox.Group>
        </FormField>
      </>
    );
  }
}

const mapStateToProps = state => ({
  data: TagList.selectMany(state),
});

const mapDispatchToProps = {
  loadTags: TagList.loadMany,
};

export default withModules(
  connect(mapStateToProps, mapDispatchToProps)(AccountTag)
)(modules);
