// @ts-nocheck
import React, { useState, useEffect, useRef, useMemo } from "react";
import { InvitationContentForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useGetSubmissionInvitationQuery,
  InfluencerCashoutInvitationFragmentFragment,
  useUploadNewDraftMutation,
} from "src/generated/ApolloComponents";
import compact from "lodash/compact";
import * as cloudinary from "src/api/cloudinary/axiosInstance";
import { InvitationContentSchema } from "./Validations";

interface Props {
  claimCashoutId: any;
  cashoutData: InfluencerCashoutInvitationFragmentFragment | null | undefined;
  type: string;
  goNext: () => void;
  goBack: () => void;
  index: number;
}

interface InvitationContentFormValues {
  claimCashoutId: string;
  drafts?: Array<any>;
  caption?: string;
  youtubeDraftUrl?: string;
  type?: any;
  allVideos?: Array<any>;
  allMedia?: Array<any>;
}

export const InvitationContentFormContainer: React.FC<Props> = ({
  claimCashoutId,
  goNext,
  cashoutData,
  type,
  goBack,
  index,
}) => {
  // const type: any = idx(match as any, x => x.params.type);
  // It is for is we got found Feedback list then its set true and show post feedback screen
  const [isFeedback, setIsFeedback] = useState(false);

  // This is for show thumb like screen after click on send a new draft
  const [isShowAwaitingScreen, showAwaitingScreen] = useState(false);

  //After click on "Send new Draft" button it will show Progress Circle
  const [isShowProgress, setProgress] = useState(false);
  const noOfFilesUploaded = useRef(0);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);

  const { data, loading, error, refetch } = useGetSubmissionInvitationQuery({
    variables: {
      claimCashoutId: claimCashoutId,
    },
  });

  const draftData = useMemo(() => data?.getSubmissionInvitation || ({} as any), [data]);

  useEffect(() => {
    const adminFeedbackLen = (draftData?.drafts?.[0]?.adminFeedback || []).length
    if (adminFeedbackLen > 0) {
      setIsFeedback(true);
    } else {
      setIsFeedback(false);
    }

    if ((draftData?.drafts || []).length > 0 && adminFeedbackLen === 0) {
      showAwaitingScreen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftData]);

  const [uploadNewDraftMutation, uploadDraft] = useUploadNewDraftMutation();

  let initialValues = {
    caption: "",
    drafts: [],
    youtubeDraftUrl: "",
    type: type || "",
  };

  const updateProgressState = state => {
    setProgress(state);
  };

  const onSubmit = async (
    values: InvitationContentFormValues,
    actions: FormikActions<InvitationContentFormValues>
  ) => {
    try {
      const uploadedVideos = [] as any;
      const uploadedMedia = [] as any;

      if (values?.type !== "youtube") {
        setProgress(true);

        for (let i = 0; i < (values?.drafts || []).length; i++) {
          const totalFile = (values?.drafts || []).length;
          const percentagePerFile = 100 / totalFile;

          const options = {
            onUploadProgress: function(progressEvent) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              if (
                percentCompleted !== 100 ||
                noOfFilesUploaded.current === totalFile
              ) {
                const progressPercentage = Math.round(
                  noOfFilesUploaded.current * percentagePerFile +
                    (percentCompleted * percentagePerFile) / 100
                );
                setProgressPercentage(progressPercentage);
              }

              if (
                percentCompleted === 100 &&
                noOfFilesUploaded.current <= totalFile
              ) {
                noOfFilesUploaded.current = noOfFilesUploaded.current + 1;
              }
            },
          };

          const response = await cloudinary.uploadInfluencerDraft(
            values?.drafts?.[i],
            options
          );

          if (!values?.drafts?.[i]?.type.match("image.*")) {
            uploadedVideos.push(response?.data?.public_id);
          }
          uploadedMedia.push(response?.data?.secure_url);
        }
      }

      try {
        let formData = {
          claimCashoutId: draftData?.claimCashoutId.toString() as string,
          type: values?.type !== "youtube" ? "media" : "youtube",
        } as any;

        if (values?.type === "youtube") {
          formData = {
            ...formData,
            allMedia: [values?.youtubeDraftUrl],
          };
        } else {
          formData = {
            ...formData,
            allVideos: compact(uploadedVideos) as Array<string>,
            allMedia: compact(uploadedMedia) as Array<string>,
            caption: values?.caption,
          };
        }

        const res = await uploadNewDraftMutation({
          variables: formData,
        });

        if (res) {
          updateProgressState(false);
          showAwaitingScreen(true);
          refetch();
          goNext();
        }
      } catch (e) {
        actions.setSubmitting(false);
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const globalErrors = useMapGraphQLErrorsMessages(error || uploadDraft?.error);

  return (
    <InvitationContentForm
      initialValues={initialValues}
      invitationContentSchema={InvitationContentSchema}
      isSubmitting={loading || uploadDraft?.loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      draftData={draftData}
      isFeedback={isFeedback}
      isShowAwaitingScreen={isShowAwaitingScreen}
      isShowProgress={isShowProgress}
      progressPercentage={progressPercentage}
      goBack={goBack}
      index={index}
    />
  );
};
