// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInput } from "src/components/Forms/TextInput";

interface Props {}

const PhoneNumber: React.FC<Props> = () => {
  return (
    <Field label="Phone Number:" mb={26}>
      <FormikInput
        name="phoneNumber"
        size="middle"
        placeholder="e.g. +44 7902 353 294"
        borderRadius={2}
        borderColor="grey5"
      />
    </Field>
  );
};

export default PhoneNumber;
