// @ts-nocheck
import React from "react";
import { ChartCard, MiniArea } from "ant-design-pro/lib/Charts";
import NumberInfo from "ant-design-pro/lib/NumberInfo";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip } from "antd";
import numeral from "numeral";
import moment from "moment";

type Props = {
  data: any;
};

const ClickGraph: React.FC<Props> = ({ data }) => {
  if (
    !data?.clicksByHour ||
    data?.clicksByHour.length === 0 ||
    !data?.clicks ||
    data?.clicks === 0
  )
    return null;

  const graphData = data?.clicksByHour?.labels
    .map((label, index) => ({
      moment: moment(label),
      x: moment(label).format("YYYY-MM-DD, ha"),
      y: data?.clicksByHour.data[index],
    }))
    .sort((a, b) => {
      if (a.moment < b.moment) {
        return -1;
      }
      if (a.moment > b.moment) {
        return 1;
      }
      return 0;
    });

  return (
    <Row>
      <Col span={24} style={{ marginTop: 24 }}>
        <ChartCard
          title="Clicks"
          total={numeral(data?.clicks).format("0,0")}
          contentHeight={120}
          action={
            <Tooltip
              placement="left"
              title="How many clicks has this campaign achieved?"
            >
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ padding: 20 }}
        >
          <NumberInfo
            subTitle={<span>Unique clicks</span>}
            total={numeral(data?.uniqueClicks).format("0,0")}
          />
          <MiniArea line height={45} data={graphData} />
        </ChartCard>
      </Col>
    </Row>
  );
};

export default ClickGraph;
