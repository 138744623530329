// @ts-nocheck
import React, { useState } from "react";
import { Wrapper, ButtonWrapper, FeedbackIcon, Message } from "./styles";
import like from "./images/like.png";
import dislike from "./images/dislike.png";
import analytics from "react-segment";

interface Props {
  draftData: any;
}

const ProcessFeedback: React.FC<Props> = ({ draftData }) => {
  // set thumb icon
  const [thumbIconName, setThumbIconName] = useState("");

  const draftRatingClick = iconName => {
    try {
      analytics.default.identify({
        userId: draftData?.account?.influencer?.id,
        displayName: draftData?.account?.influencer?.name,
        email: draftData?.account?.influencer?.email,
        userType: draftData?.account?.influencer?.type,
      });

      analytics.default.track("Liked V2 Draft Upload", {
        claimCashoutId: draftData?.claimCashoutId,
        account: draftData?.account?.username,
        campaign: draftData?.campaign?.title,
        likeUploadDraft: iconName === "up" ? "Yes" : "No",
      });
      setThumbIconName(iconName);
    } catch (e) {}
  };

  return (
    <Wrapper>
      <Message>Did you find it easy to upload your draft?</Message>

      <ButtonWrapper>
        <FeedbackIcon
          src={dislike}
          style={{ marginTop: 37 }}
          onClick={() => {
            setTimeout(() => draftRatingClick("down"), 2500);
          }}
          className={
            thumbIconName === "down"
              ? "activeDown"
              : !!thumbIconName
              ? "Inactive"
              : ""
          }
        />
        <FeedbackIcon
          src={like}
          onClick={() => {
            setTimeout(() => draftRatingClick("up"), 2500);
          }}
          className={
            thumbIconName === "up"
              ? "activeUp"
              : !!thumbIconName
              ? "Inactive"
              : ""
          }
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ProcessFeedback;
