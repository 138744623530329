// @ts-nocheck
import React, { useState } from "react";
import { Box } from "src/components/Core/Box";
import Analyse from "./Analyse";
import AnalyseData from "./AnalyseData";
import { BrandSafetyProvider } from "./BrandSafetyProvider";

interface Props {}

const BrandSafety: React.FC<Props> = () => {
  const [step, setStep] = useState<number>(1);
  return (
    <Box>
      <BrandSafetyProvider>
        {step === 1 && <Analyse step={step} goNext={() => setStep(2)} />}

        {step === 2 && <AnalyseData step={step} />}
      </BrandSafetyProvider>
    </Box>
  );
};

export default BrandSafety;
