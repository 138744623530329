export const inArray = function(array, comparer) {
  for (var i = 0; i < array.length; i++) {
    if (comparer(array[i])) return true;
  }
  return false;
};

export const pushIfNotExist = function(array, element, comparer) {
  if (!inArray(array, comparer)) {
    array.push(element);
  }
};
