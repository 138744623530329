// @ts-nocheck
import React from "react";
import { ErrorMessage } from "formik";
import { Typography } from "antd";
const { Text } = Typography;

type Props = {
  fieldName: any;
  style?: React.CSSProperties;
};

const FieldError = (props: Props) => {
  return (
    <Text
      type="danger"
      style={{
        marginTop: 10,
        display: "block",
        color: "#E74848",
        ...props.style,
      }}
      data-cy={`form-error-${props.fieldName.replace(".", "-")}`}
    >
      <ErrorMessage name={props.fieldName} />
    </Text>
  );
};

export default FieldError;
