// @ts-nocheck
import React from "react";
import { Button } from "src/components/Core/Button";
import { Box } from "src/components/Core/Box";

interface Props {
  onApprove: Function;
  onRequestEdit: Function;
}

const ApproveContentButtons: React.FC<Props> = ({
  onApprove,
  onRequestEdit,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" mt={20} bottom={0}>
      <Button
        onClick={onRequestEdit}
        mr={20}
        buttonSize="small"
        style={{
          border: "1px solid #1890FF",
          color: "#1890FF",
        }}
      >
        Request edits
      </Button>

      <Button
        type="action"
        buttonSize="small"
        data-cy="submit-button"
        onClick={onApprove}
      >
        Approve content
      </Button>
    </Box>
  );
};

export default ApproveContentButtons;
