// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { ProfileDataForm } from ".";
import { FormikHelpers as FormikActions } from "formik";
import { useMapGraphQLErrorsMessages } from "src/utils/hooks/useMapGraphQLErrors";
import {
  useInfluencerCashoutProfileDataMutation,
  InfluencerCashoutInvitationFragmentFragment,
  useGetYoutubeAnalyticsDataByAccessTokenQuery,
} from "src/generated/ApolloComponents";
import _values from "lodash/values";
import sum from "lodash/sum";
import has from "lodash/has";
import { ProfileDataSchema } from "./Validations";

interface Props {
  claimCashoutId: any;
  goNext: () => void;
  goBack: () => void;
  setShowExample: (value: object) => void;
  setScreenshotType: (value: string) => void;
  showExample: object;
  type: string;
  cashoutData: InfluencerCashoutInvitationFragmentFragment | null | undefined;
  index: number;
}

interface File {
  uploadUrl: string;
  uploadType: string;
}

interface ProfileDataFormValues {
  gender: string;
  age: string;
  location: string;
  isAccountScreenshotOutdated?: boolean;
  platformType?: string;
  screenshots?: File[];
  ageScreenshot?: File;
  genderScreenshot?: File;
  locationScreenshot?: File;
  ageBreakdown?: Array<any>;
  genderBreakdown?: Array<any>;
  locationBreakdown?: Array<any>;
  type?: string;
}

export const ProfileDataFormContainer: React.FC<Props> = ({
  claimCashoutId,
  goNext,
  goBack,
  cashoutData,
  setShowExample,
  setScreenshotType,
  showExample,
  type,
  index,
}) => {
  const [isConnected, setIsConnected] = useState(false);
  const [accessToken, setAccessToken] = useState("");

  const [
    influencerCashoutProfileDataMutation,
    { loading, error },
  ] = useInfluencerCashoutProfileDataMutation();

  const influencerData = cashoutData?.account?.influencer;

  const analytics = useGetYoutubeAnalyticsDataByAccessTokenQuery({
    skip: !isConnected,
  });

  const analyicsData =
    analytics?.data?.getYoutubeAnalyticsDataByAccessToken || ({} as any);

  let locationBreakdownData =
    analyicsData?.locationBreakdown ||
    cashoutData?.account?.locationBreakdown?.manual ||
    {};

  let locationBreakdownArray = Object.keys(locationBreakdownData).map(function(
    key
  ) {
    return {
      key: key,
      value: locationBreakdownData[key],
    };
  }) || [
    { key: "", value: 0 },
    { key: "", value: 0 },
    { key: "", value: 0 },
  ];

  if (locationBreakdownArray.length === 0) {
    locationBreakdownArray = [
      { key: "", value: 0 },
      { key: "", value: 0 },
      { key: "", value: 0 },
    ];
  }

  if (locationBreakdownArray.length === 1) {
    locationBreakdownArray = locationBreakdownArray.concat([
      { key: "", value: 0 },
      { key: "", value: 0 },
    ]);
  }

  if (locationBreakdownArray.length === 2) {
    locationBreakdownArray = locationBreakdownArray.concat([
      { key: "", value: 0 },
    ]);
  }

  let initialValues = {
    gender: influencerData?.detail?.gender?.[0] || "",
    age: influencerData?.detail?.age?.[0] || "",
    location: influencerData?.detail?.location?.[0] || "",
    platformType: cashoutData?.account?.type || "",
    isAccountScreenshotOutdated:
      cashoutData?.isAccountScreenshotOutdated || false,
    screenshots: [],
    ageBreakdown:
      analyicsData?.ageBreakdown ||
      cashoutData?.account?.ageBreakdown?.manual ||
      {},
    genderBreakdown:
      analyicsData?.genderBreakdown ||
      cashoutData?.account?.genderBreakdown?.manual ||
      {},
    locationBreakdown: locationBreakdownArray,
    ageScreenshot: null,
    genderScreenshot: null,
    locationScreenshot: null,
    type: type,
  };

  const onSubmit = async (
    values: ProfileDataFormValues,
    actions: FormikActions<ProfileDataFormValues>
  ) => {
    try {
      const ageBreakdown = values?.ageBreakdown || {};

      const ageBreakdownData = Object.entries(ageBreakdown).reduce(
        (a, [k, v]) =>
          typeof v !== "undefined" && v !== "" && v !== null && v !== false
            ? { ...a, [k]: v }
            : a,
        {}
      );

      const genderBreakdown = values?.genderBreakdown || {};

      const genderBreakdownData = Object.entries(genderBreakdown).reduce(
        (a, [k, v]) =>
          typeof v !== "undefined" && v !== "" && v !== null && v !== false
            ? { ...a, [k]: v }
            : a,
        {}
      );

      let locationBreakdownData = (values?.locationBreakdown || []).reduce(
        function(result, item) {
          if (
            item.key !== "" &&
            typeof item.value !== "undefined" &&
            item.value !== "" &&
            item.value !== null &&
            item.value !== false
          ) {
            result[item.key] = item.value;
            return result;
          }
          return result;
        },
        {}
      );

      let locationSum = sum(_values(locationBreakdownData));

      if (locationSum < 100 && !has(locationBreakdownData, "other")) {
        let otherLocationBreakdownData = { other: 100 - locationSum };

        locationBreakdownData = Object.assign(
          locationBreakdownData,
          otherLocationBreakdownData
        );
      }

      let formData: any = {
        claimCashoutId: claimCashoutId,
        age:
          (values?.age || []).length > 0
            ? ([values?.age] as Array<string>)
            : [],
        gender: [values?.gender] as Array<string>,
        location: [values?.location] as Array<string>,
      };

      if (values?.type !== "youtube") {
        formData = {
          ...formData,
          screenshots: values?.screenshots,
        };
      }

      if (values?.type === "youtube") {
        formData = {
          ...formData,
          ageBreakdown: ageBreakdownData,
          genderBreakdown: genderBreakdownData,
          locationBreakdown: locationBreakdownData,
          ageScreenshot: values?.ageScreenshot,
          genderScreenshot: values?.genderScreenshot,
          locationScreenshot: values?.locationScreenshot,
        };
      }

      const res = await influencerCashoutProfileDataMutation({
        variables: formData,
      });
      if (res?.data?.influencerCashoutProfileData) {
        goNext();
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const gAuth: any = useRef(null);

  const config = {
    apiKey: "AIzaSyA27mQxIDkWCawVmKf8dTy2PBs6YIWCsAM",
    clientId:
      "814639961079-8bnafpqcq5bi8er3hpfgudapcjufocdg.apps.googleusercontent.com",
    scope: "profile https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.readonly",
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/client.js";

    script.onload = () => {
      if (type === "youtube") {
        const initClient = () => {
          window.gapi.client
            .init(config)
            .then(() => {
              gAuth.current = window.gapi.auth2.getAuthInstance();

              if (gAuth?.current) {
                gAuth?.current?.isSignedIn.listen(handleSigninStatusChange);
              }

              const currentUser = gAuth?.current?.currentUser.get();

              const authResponse = currentUser.getAuthResponse(true);

              if (authResponse && currentUser) {
                // save access token
                setAccessToken(authResponse?.access_token);
                setIsConnected(true);
              }
            })
            .catch(error => {
              console.log("error", error);
            });
        };
        window.gapi.load("client:auth2", initClient);
      }
    };

    document.body.appendChild(script);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSigninStatusChange = isSignedIn => {
    const auth2 = window.gapi.auth2.getAuthInstance();

    if (isSignedIn) {
      const currentUser = auth2.currentUser.get();

      const authResponse = currentUser.getAuthResponse(true);

      if (authResponse) {
        setAccessToken(authResponse?.access_token);
        setIsConnected(true);
      }
    }
  };

  const handleAuthClick = () => {
    if (gAuth?.current) {
      gAuth?.current?.signIn();
    }
  };

  useEffect(() => {
    if (accessToken !== "") {
      analytics.refetch({
        accessToken: accessToken,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const globalErrors = useMapGraphQLErrorsMessages(error);

  return (
    <ProfileDataForm
      initialValues={initialValues}
      profileDataSchema={ProfileDataSchema}
      isSubmitting={loading}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      globalErrors={globalErrors}
      cashoutData={cashoutData}
      setShowExample={value => setShowExample(value)}
      setScreenshotType={value => setScreenshotType(value)}
      showExample={showExample}
      isConnected={isConnected}
      handleAuthClick={() => {
        handleAuthClick();
      }}
      type={type}
      index={index}
      goBack={goBack}
    />
  );
};
