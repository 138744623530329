import axiosInstance from "../axiosInstance";

const FETCH_USER = "/auth";
const LOGIN = "/auth";
const UPDATE = "/auth";
const FORGOT = "/auth/forgot";
const FIND = "/auth/find";
const SWITCH = "/auth/switch";

export const get = data => axiosInstance.get(FETCH_USER, data);
export const create = data => axiosInstance.post(LOGIN, data);
export const forgot = data => axiosInstance.post(FORGOT, data);
export const update = data => axiosInstance.patch(UPDATE, data);
export const find = data => axiosInstance.get(FIND, data);
export const switchUser = data => axiosInstance.patch(SWITCH, data);
