// @ts-nocheck
import React from 'react'
import { show } from 'redux-modal'
import { useDispatch } from 'react-redux'
import { LinkButton } from 'src/components/Core/LinkButton'
import { profileURLHelpers } from 'src/constants/account'

const ViewDetail = ({account}) => {
  const dispatch = useDispatch()

  const showInfluencerModal = () => {
    dispatch(
      show("InfluencerModal", {
        influencerId: Number(account.influencerId),
        rationale: account?.rationale,
        accountId: Number(account?.id),
      })
    )
  }

  const handleClick = () => {
    if(account?.id) {
      showInfluencerModal()
    } else {
      window.open(
        `${profileURLHelpers[account.platform]}${account.username}`,
        '_blank' // <- This is what makes it open in a new window.
      );
    }
  }

  return(
    <LinkButton
      color="blue"
      fontSize={12}
      weight="semi-bold"
      style={{ padding: 0, display: 'block' }}
      onClick={e => {
        e.stopPropagation()
        handleClick()
      }}
    >
      View details
    </LinkButton>
  )
}

export default ViewDetail;
