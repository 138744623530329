import React, { Component } from "react"
import styled from "styled-components"
import FormField from "../FormField"
import { InputNumber } from "formik-antd"

const FullWidthNumber = styled(InputNumber)`
  &&& {
    width: 100%;
  }
`

export default class Views extends Component {
  render() {
    const { name, ...rest } = this.props

    return (
      <FormField name={name || "views"} label="Actual views">
        <FullWidthNumber data-cy="invitation-views" size="large" placeholder="0" {...rest} />
      </FormField>
    )
  }
}
