// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import { ConnectAccounts } from '.'
import {
  useConnectPublicYouTubeAccountMutation,
  useUpdatePublicAccountBreakdownsMutation,
  useGetPublicInfluencerProfileQuery
 } from 'src/generated/ApolloComponents'
import { message } from 'antd'
import { useMapGraphQLErrorsMessages } from 'src/utils/hooks/useMapGraphQLErrors'
import qs from 'qs'

export const ConnectAccountContainer = () => {
  const history = useHistory()
  const location = useLocation()
  const [collect, setCollect] = useState(false)
  const [account, setAccount] = useState(null as any)
  const [express, setExpress] = useState(false)
  const [expressApplicant, setExpressApplicant] = useState()

  let params = qs.parse(location.search, { ignoreQueryPrefix: true })
  let redirectPath = "/preferences"

  let queryString = qs.stringify(params)
  if(queryString.length)
    redirectPath += `?${queryString}`

  const [
    connectPublicYouTubeAccount,
    analytics
  ] = useConnectPublicYouTubeAccountMutation()

  const [
    updatePublicAccountBreakdowns,
    breakdowns
  ] = useUpdatePublicAccountBreakdownsMutation()

  const profile = useGetPublicInfluencerProfileQuery({
    variables: {}
  })

  const globalErrors = useMapGraphQLErrorsMessages(analytics?.error || breakdowns?.error || profile.error);

  const gAuth: any = useRef(null);

  useEffect(() => {
    let expressApplicants: any = profile?.data?.getPublicInfluencerProfile?.expressApplicants || []
    if(expressApplicants.length){
      setExpress(true)
      expressApplicants = expressApplicants.filter(applicant => ["YouTube", "TikTok"].includes(applicant.platform))
      setExpressApplicant(expressApplicants[0])
    } else {
      setExpress(false)
      setExpressApplicant(null as any)
    }
  }, [profile])

  const config = {
    apiKey: "AIzaSyA27mQxIDkWCawVmKf8dTy2PBs6YIWCsAM",
    clientId:
      "814639961079-8bnafpqcq5bi8er3hpfgudapcjufocdg.apps.googleusercontent.com",
      scope: "email profile openid https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.readonly",
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/client.js";

    script.onload = () => {
      const initClient = () => {
        window.gapi.client
          .init(config)
          .then(() => {
            gAuth.current = window.gapi.auth2.getAuthInstance();

            if (gAuth?.current) {
              gAuth?.current?.isSignedIn.listen(handleSigninStatusChange);
            }
          })
          .catch(error => {
            console.log("error", error);
          });
      };
      window.gapi.load("client:auth2", initClient);
    };

    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSigninStatusChange = async (isSignedIn) => {
    if (isSignedIn) {
      handleSignedInUser()
    }
  };

  const handleSignedInUser = async () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    const currentUser = auth2.currentUser.get();
    const authResponse = currentUser.getAuthResponse(true);

    if (authResponse) {
      try {
        const response = await connectPublicYouTubeAccount({
          variables: {
            accessToken: authResponse?.access_token
          }
        })
        let analytics = response.data?.connectPublicYouTubeAccount
        if(analytics) {
          setAccount(analytics)
          profile.refetch()
          history.push(redirectPath)

          // if(isEmpty(analytics.ageBreakdown?.manual) || isEmpty(analytics.locationBreakdown?.manual)) {
          //   setCollect(true)
          //   profile.refetch()
          // }
          // else
          //   history.push('/preferences')
        } else {
          setCollect(true)
        }
      } catch(e) {
        message.error('Please contact admin about the issue', 10)
        console.log(e)
      }
    }
  }

  const handleAuthClick = (platform: string) => {
    setPlatform(platform)
    switch(platform) {
      case 'YouTube':
        handleYoutubeAuth()
        break
      case 'TikTok':
        handleTittokAuth()
        break

    }
  };

  const handleYoutubeAuth = () => {
    if (gAuth?.current) {
      if(window.gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getAuthResponse(true)) {
        handleSignedInUser()
      } else {
        gAuth?.current?.signIn()
      }
    }
  }

  const handleTittokAuth = () => {
    const csrfState = Math.random().toString(36).substring(7);
    let url = 'https://open-api.tiktok.com/platform/oauth/connect/'
    url += '?client_key=awjh0gpykfu4rdfa';
    url += '&scope=user.info.basic,video.list';
    url += '&response_type=code';
    url += `&redirect_uri=${window.location.origin}`;
    url += '&state=' + csrfState;
    window.location.href = url
  }

  const [platform, setPlatform] = useState('')

  const submitDemographic = async (data) => {
    let locationBreakdown = {}
    data.locationBreakdown.map(location => locationBreakdown[location.key] = location.value)
    let values = {
      accountId: account.id,
      ageBreakdown: data.ageBreakdown,
      locationBreakdown: locationBreakdown,
      screenshots: [data.ageScreenshot, data.locationScreenshot],
    }
    let response = await updatePublicAccountBreakdowns({
      variables: values
    })
    if(response.data?.updatePublicAccountBreakdowns) {
      setCollect(false)
      history.push(redirectPath)
    }
  }

  return(
    <ConnectAccounts
      collect={collect}
      profile={profile}
      account={account}
      express={express}
      globalErrors={globalErrors}
      selectedPlatform={platform}
      handleClick={handleAuthClick}
      expressApplicant={expressApplicant}
      submitDemographic={submitDemographic}
      loading={analytics?.loading || breakdowns?.loading}
    />
  )
}
