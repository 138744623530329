// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { Button } from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";

interface Props {
  account: any;
  onTrack: (value: any) => void;
}

const AccountTitle: React.FC<Props> = ({ account, onTrack }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Typography size={14} color="black" mb={0} ml={10}>
          {account?.accountName}
        </Typography>
      </Box>

      {account?.isTracked ? (
        <Button
          type="confirm"
          style={{
            background: "#95DE64",
            borderColor: "#95DE64",
            color: "#FFFFFF",
          }}
          buttonSize="small"
          data-cy="track-button"
          disabled
        >
          Tracking
        </Button>
      ) : (
        <Button
          type="action"
          buttonSize="small"
          data-cy="track-button"
          onClick={() => onTrack(account?.accountName)}
        >
          +Track
        </Button>
      )}
    </Box>
  );
};

export default AccountTitle;
