// @ts-nocheck
import React, { useState } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { Typography } from "src/components/Core/Typography";
import { TextInput } from "src/components/Forms/TextInput";
import { Box } from "src/components/Core/Box";
import { TrackContainer } from "./container";
import Loading from "src/shared/Loading";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import AccountList from "./AccountList";
import TrackingData from "./TrackingData";

export interface FilterProps {
  accountName: string;
}

interface Props {
  accounts: any;
  loading: boolean;
  globalErrors?: GlobalErrorProps[];
  onFilter: (key: string, value: any) => void;
  filters: FilterProps;
  onRemoveTrack: (value: any) => void;
}

const Track: React.FC<Props> = ({
  accounts,
  loading,
  globalErrors,
  onFilter,
  filters,
  onRemoveTrack,
}) => {
  const [index, setIndex] = useState(0);
  return (
    <Box p={20}>
      <Loading spinning={loading}>
        <Box background="white">
          <GlobalErrors errors={globalErrors} />
          <Row gutter={32}>
            <Col span={8}>
              <Box borderRight="1px solid #E8E8E8">
                <Box borderBottom="1px solid #E8E8E8" p={20}>
                  <Typography color="black" weight="semi-bold" size={24} mb={0}>
                    Tracked accounts
                  </Typography>
                  <Typography color="black2" size={16} mb={0} mt="5px">
                    Below are all the accounts you are currently tracking.
                  </Typography>
                  <Box mt={15} mb={20}>
                    <TextInput
                      size="small"
                      background="background"
                      borderRadius={3}
                      borderColor="grey5"
                      value={filters?.accountName}
                      onChange={e => onFilter("accountName", e.target.value)}
                      suffix={<SearchOutlined />}
                      placeholder="Search tracked accounts..."
                    />
                    <Typography color="grey7" size={10} mb={0} mt="5px">
                      {(accounts || []).length} results
                    </Typography>
                  </Box>
                </Box>

                <AccountList
                  accounts={accounts}
                  index={index}
                  setIndex={value => setIndex(value)}
                  removeTrackAccount={onRemoveTrack}
                />
              </Box>
            </Col>
            <Col span={16}>
              <Box padding="20px 20px 20px 0px">
                <TrackingData
                  account={accounts[index]}
                  graphData={(accounts[index]?.graphData || []).reverse()}
                />
              </Box>
            </Col>
          </Row>
        </Box>
      </Loading>
    </Box>
  );
};

export { Track, TrackContainer };
