// @ts-nocheck
import React from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Radio } from "antd";
import { AmountRadio } from "../../../Wrapper";
import { Box } from "src/components/Core/Box";
import { Field } from "src/components/Forms/Field";
import { FormikProps } from "formik";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { AudienceBreakdownComparisonType } from "src/generated/ApolloComponents";
import { RemoveIcon } from "../../../Wrapper";

interface Props {
  formProps: FormikProps<any>;
  clearFilterKey: (key: string) => void;
}

const Followers: React.FC<Props> = ({ formProps, clearFilterKey }) => {
  const fieldValue = formProps?.values?.followers;

  let isVisible = false;

  if (fieldValue?.value || fieldValue?.comparison) {
    isVisible = true;
  }

  return (
    <Box id="followers">
      <Field
        label="Followers:"
        mb={20}
        color="black"
        isBeside={true}
        extra={
          isVisible && (
            <RemoveIcon
              type="close-circle"
              onClick={() => {
                formProps?.setFieldValue(
                  "followers",

                  {
                    comparison: undefined,
                    value: 0,
                  }
                );
                clearFilterKey("followers");
              }}
              style={{
                marginTop: "-1px",
                marginLeft: "10px",
              }}
            />
          )
        }
      >
        <Box key={fieldValue.comparison}>
          <AmountRadio>
            <Radio.Button
              value={AudienceBreakdownComparisonType.SmallerEqualThan}
              onChange={value => {
                if (
                  fieldValue?.comparison ===
                    AudienceBreakdownComparisonType.SmallerEqualThan ||
                  fieldValue?.value === 0
                ) {
                  formProps?.setFieldValue(
                    "followers.comparison",
                    AudienceBreakdownComparisonType.SmallerEqualThan
                  );
                }
              }}
              checked={
                fieldValue?.comparison ===
                AudienceBreakdownComparisonType.SmallerEqualThan
              }
            >
              <LeftOutlined />
            </Radio.Button>
            <Radio.Button
              value={AudienceBreakdownComparisonType.GreaterEqualThan}
              onChange={value => {
                if (
                  fieldValue?.comparison ===
                    AudienceBreakdownComparisonType.GreaterEqualThan ||
                  fieldValue?.value === 0
                ) {
                  formProps?.setFieldValue(
                    "followers.comparison",
                    AudienceBreakdownComparisonType.GreaterEqualThan
                  );
                }
              }}
              checked={
                fieldValue?.comparison ===
                AudienceBreakdownComparisonType.GreaterEqualThan
              }
            >
              <RightOutlined />
            </Radio.Button>
          </AmountRadio>

          <FormikInputNumber
            parser={value => {
              let parsed = value.replace(/\[0-9]/g, "");
              if (parsed) {
                return parsed.match(/\d+/g) ? parseInt(parsed) : "";
              } else {
                return null;
              }
            }}
            name="followers.value"
            borderRadius={3}
            borderColor="grey5"
            size="small"
            value={fieldValue?.value || 0}
            style={{ marginLeft: 5, width: "72px" }}
          />
        </Box>
      </Field>
    </Box>
  );
};

export default Followers;
