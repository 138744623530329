// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Box } from "src/components/Core/Box";
import { Empty } from "antd";
import numeral from "numeral";
import moment from "moment";
import { analyricsColors } from "src/constants/campaign";
import {
  LineChart,
  Line,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AxisDomain,
  ResponsiveContainer,
} from "recharts";

interface Props {
  data: Array<any>;
  YDomain?: [AxisDomain, AxisDomain];
  dataPoint: any;
}
const LineChartGraph: React.FC<Props> = ({ data, YDomain, dataPoint }) => {
  const [labels, setLabels] = useState([])

  useEffect(() => {
    if(data.length === 0)
      return
    let dates = [] as any
    let flags = {}
    data.forEach(s => {
      s.graphData.forEach(d => {
        if(!flags[d.key]){
          flags[d.key] = 1
          dates.push({key: d.key})
        }
      })
    });
    dates.sort((a: any, b: any) => a.key.localeCompare(b.key))
    setLabels(dates)
  },[data])

  const renderColorfulLegendText = (value: string, name: any, props: any) => {
    if (props?.dataKey === "engagementRate") {
      return <span> {numeral(value).format("0,0.00") + "%"}</span>;
    } else {
      return <span> {numeral(value).format("0,0")}</span>;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
      {data?.length > 0 ? (
        <LineChart
          margin={{
            top: 5,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="key"
            stroke="#98A1B5"
            axisLine={false}
            tickFormatter={x => moment(x).format("Do MMM YYYY")}
            style={{
              fontSize: "12px",
            }}
            allowDuplicatedCategory={false}
          >
            <Label value="Time" offset={-30} position="insideBottom" />
          </XAxis>
          <YAxis
            domain={YDomain}
            axisLine={false}
            tickFormatter={x => numeral(x).format("0a")}
            style={{
              fontSize: "12px",
            }}
            label={{ value: "Amount", angle: -90, position: "insideLeft" }}
          />
          <Tooltip
            formatter={renderColorfulLegendText}
            labelFormatter={value => moment(value).format("Do MMM YYYY")}
          />
          <Line
            type="monotone"
            data={labels}
          />
          {data.map((s, i) =>
            <Line
              key={i}
              type="monotone"
              name={s?.username}
              dataKey={dataPoint}
              data={s?.graphData || []}
              stroke={analyricsColors[i > 11 ? i % 12 : i]}
              strokeDasharray={i > 11 ? "5 5" : "1 0"}
              strokeWidth={i > 11 ? i % 12 : 1}
            />
          )}
        </LineChart>
      ) : (
        <Box p={85}>
          <Empty description="No data avaialble" />
        </Box>
      )}
    </ResponsiveContainer>
  );
};

export default LineChartGraph;
