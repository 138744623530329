// @ts-nocheck
import React, { Fragment } from "react";
import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import { FormikProps } from "formik";
import LinkButton from "src/components/Core/LinkButton";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import { ages } from "src/constants/account";
import { FormikSelect } from "src/components/Forms/Select";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import Graph from "src/assets/images/pie_chart.png";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import PieChartGraphCard from "../../../../../InfluencerProfile/AccountDetail/Graphs/PieChartGraphCard";
import { colors } from "src/constants/account";
import { Field } from "src/components/Forms/Field";
import { FieldError } from "src/components/Forms/ErrorMessage";

interface Props {
  formProps?: FormikProps<any>;
}

const AgeBreakdown: React.FC<Props> = ({ formProps }) => {
  let drodownData = ages;
  let dataCount = ages.length;

  drodownData = drodownData.filter((item: any, index) => {
    const newItem = { ...item };
    if (
      !find(
        formProps?.values["ageBreakdown"],
        matchesProperty("key", newItem?.value)
      )
    ) {
      return true;
    }
    return false;
  });

  const addData = () => {
    (formProps?.values["ageBreakdown"]).push({ key: "", value: 0 });
    formProps?.setFieldValue("ageBreakdown", formProps?.values["ageBreakdown"]);
  };

  const removeData = index => {
    (formProps?.values["ageBreakdown"]).splice(index, 1);
    formProps?.setFieldValue("ageBreakdown", formProps?.values["ageBreakdown"]);
  };

  const renderGraphData = data => {
    data = data.map((item: any, index) => {
      const newItem = { ...item };
      newItem.color = colors[index];
      return newItem;
    });

    return data;
  };

  return (
    <Fragment>
      <Field label="Age breakdown" mb={26} mt={26}>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={12}>
            <Box display="flex">
              <Box width="50%" mr="5px">
                <Typography size={14} color="grey2">
                  Label:
                </Typography>
              </Box>
              <Box width="50%">
                <Typography size={14} color="grey2">
                  Data:
                </Typography>
              </Box>
            </Box>
          </Col>
          <Col span={12}></Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            {formProps?.values?.["ageBreakdown"].map((value, i) => (
              <Box display="flex" key={i} marginBottom={10} mt={10}>
                <Box width="40%" mr="5px">
                  <FormikSelect
                    name={`ageBreakdown[${i}].key`}
                    data={drodownData}
                    local
                    borderRadius={2}
                    borderColor="grey5"
                    size="middle"
                    value={value?.key}
                  />
                </Box>
                <Box width="40%">
                  <FormikInputNumber
                    parser={value => {
                      let parsed = value.replace(/\[0-9]/g, "");
                      if (parsed) {
                        return parsed.match(/\d+/g) ? parseInt(parsed) : "";
                      } else {
                        return null;
                      }
                    }}
                    name={`ageBreakdown[${i}].value`}
                    borderRadius={2}
                    borderColor="grey5"
                    size="middle"
                    value={value?.value}
                  />
                </Box>
                <Box width="10%" mt={10} ml={10}>
                  %
                </Box>
                <Box width="10%" color="red" mt={10} ml={10}>
                  <DeleteOutlined onClick={() => removeData(i)} />
                </Box>
              </Box>
            ))}

            {drodownData?.length > 0 &&
              dataCount >= formProps?.values?.["ageBreakdown"].length + 1 && (
                <LinkButton
                  onClick={() => addData()}
                  mt={20}
                  color="blue"
                  fontSize={12}
                >
                  + Add data
                </LinkButton>
              )}
            <FieldError
              errors={formProps?.errors as any}
              name={`ageBreakdown`}
            />
          </Col>

          <Col span={12}>
            {formProps?.values?.["ageBreakdown"].length > 0 &&
            formProps?.values["ageBreakdown"][0].key !== "" &&
            formProps?.values["ageBreakdown"][0].value !== "" ? (
              <PieChartGraphCard
                title="Age Breakdown"
                data01={renderGraphData(formProps?.values?.["ageBreakdown"])}
                isShowFilter={false}
              />
            ) : (
              <img src={Graph} alt="Graph" />
            )}
          </Col>
        </Row>
      </Field>
    </Fragment>
  );
};
export default AgeBreakdown;
