export const values = {
  applicants: {
    head: 'Applicants',
    title: 'All influencers who have applied to your campaign',
    color: 'blue'
  },
  accepted: {
    head: 'Accepted',
    title: 'Drag influencers you like here. Once notified they will automatically be added to the brands suggested influencers',
    color: 'green',
    accepted: {
      label: 'Confirm',
      disabled: false
    },
    confirmed: {
      label: 'Confirmed',
      disabled: true
    }
  },
  rejected: {
    head: 'Rejected',
    title: 'Influencers that do not fit the brief criteria',
    color: 'red',
    rejected: {
      label: 'Notify',
      disabled: false
    },
    notified: {
      label: 'Notified',
      disabled: true
    }
  },
  Applied: {
    label: 'Request data',
  },
  Requested: {
    label: 'Data requested',
    disabled: true
  },
  Received: {
    label: 'Data received',
    background: '#D9F7BE',
    disabled: true
  },
  Registered: {
    label: 'Registered',
    disabled: true
  },
}
