import React, { Component } from "react";
import styled from "styled-components";
import numeral from "numeral";
import { Descriptions } from "antd";
import formatCurrency from "src/utils/formatCurrency";

const Wrapper = styled("div")`
  &&& {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 24px;
    background: #ececec;
    .ant-descriptions-row:last-child > td {
      padding-bottom: 1px;
    }
  }
`;

export default class MiniStats extends Component {
  render() {
    const {
      stats: { total }
    } = this.props;

    return (
      <Wrapper>
        {total && (
          <Descriptions size="small" column={1}>
            {total.estimated_views && total.estimated_views > 0
              ? [
                  <Descriptions.Item key="estimate" label="Estimated Views">
                    {numeral(total.estimated_views).format("0,0")}
                  </Descriptions.Item>
                ]
              : []}

            {total.views && total.views > 0
              ? [
                  <Descriptions.Item key="views" label="Views">
                    {numeral(total.views).format("0,0")}
                  </Descriptions.Item>
                ]
              : []}

            {total.payout_type_pennies && total.payout_type_pennies > 0
              ? [
                  <Descriptions.Item
                    key="estimate_payout"
                    label="Estimated Payout"
                  >
                    {formatCurrency(total.payout_type_pennies)}
                  </Descriptions.Item>
                ]
              : []}

            {total.payout_pennies && total.payout_pennies > 0
              ? [
                  <Descriptions.Item key="payout" label="Actual Payout">
                    {formatCurrency(total.payout_pennies)}
                  </Descriptions.Item>
                ]
              : []}
          </Descriptions>
        )}
      </Wrapper>
    );
  }
}
