import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { withRouter, Link } from "react-router-dom"
import { message } from "antd";
import { withFormik } from "formik"
import { Form, Input, SubmitButton } from "formik-antd"
import Auth from "src/ducks/Auth"
import FormField from "src/shared/FormField"
import { Icon } from '@ant-design/compatible'

const FormIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.25);
`

class ResetPasswordForm extends Component {
  render() {
    const { handleSubmit } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <FormField name="password" label="Please enter a new password">
          <Input
            size="large"
            prefix={<FormIcon type="lock" />}
            type="password"
            placeholder="Password"
          />
        </FormField>
          <SubmitButton size="large" type="primary" disabled={false} block>
            Go to Dashboard
          </SubmitButton>
          Or <Link to="/sign_in">Login</Link>
      </Form>
    )
  }
}

const EnhancedResetPasswordForm = withFormik({
  mapPropsToValues: () => ({
    password: ""
  }),

  validate: (values) => {
    const errors = {}

    if (!values.password) {
      errors.password = "Fill in a password"
    }

    return errors
  },

  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    props
      .updateUser({
        ...values,
        perishable_token: props.perisableToken
      })
      .then(() => {
        props.history.push("/")
        setSubmitting(false)
      })
      .catch((res) => {
        message.error("Failed to reset password")
        setErrors(res.response.data.meta.errors)
        setSubmitting(false)
      })
  },

  displayName: "ResetPasswordForm"
})(ResetPasswordForm)

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  updateUser: Auth.updateUser
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnhancedResetPasswordForm))
