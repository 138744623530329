// @ts-nocheck
import React, { useState } from "react";
import { Box } from "src/components/Core/Box";
import { Radio } from "antd";
import { Typography } from "src/components/Core/Typography";
import LineChartGraph from "./LineChartGraph";

interface Props {
  accounts: Array<any>;
}

const PostedData: React.FC<Props> = ({ accounts }) => {
  const [dataPoint, setDataPoint] = useState("engagementRate");

  return (
    <Box>
      <Box mt={20}>
        {(accounts || []).length > 0 && (
          <Box mb={20}>
            <Typography size={14} color="grey7" mb={0}>
              Data Point:
            </Typography>
            <Box display="inline-flex">
              <Radio.Group
                onChange={e => {
                  setDataPoint(e.target.value);
                }}
                value={dataPoint}
              >
                <Radio value="engagementRate">Engagement rate (%)</Radio>
                <Radio value="views">Views</Radio>
                <Radio value="likes">Likes/Hearts</Radio>
                <Radio value="comments">Comments</Radio>
              </Radio.Group>
            </Box>
          </Box>
        )}

        <LineChartGraph
          data={accounts}
          YDomain={[0, "auto"]}
          dataPoint={dataPoint}
        />
      </Box>
    </Box>
  );
};

export default PostedData;
