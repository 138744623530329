import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button, Row, Col } from "antd";
import { connectModal } from "redux-modal";
import { Form } from "formik-antd";
import { withFormik } from "formik";
import TableManager from "src/ducks/TableManager";
import FormField from "src/shared/FormField";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";

class FilterModal extends Component {
  render() {
    const {
      show,
      handleHide,
      handleSubmit,
      isSubmitting,
      moduleName,
      filterOptions,
      values,
      dirty,
    } = this.props;

    return (
      <Modal
        title="Filter records"
        visible={show}
        onCancel={handleHide}
        footer={[
          <Button key="back" onClick={handleHide}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
        {...this.props}
      >
        <Form data-cy={`${moduleName}-filter-form`}>
          <Row gutter={48}>
            {Object.keys(filterOptions).map(name => (
              <>
                {filterOptions[name].heading && (
                  <Box mt={30} mb={20} ml={24}>
                    <Typography size={20} weight="semi-bold" color="black">
                      {filterOptions[name].heading}
                    </Typography>
                  </Box>
                )}

                <Col
                  key={name}
                  span={
                    filterOptions[name].span ? filterOptions[name].span : 48
                  }
                  id={name}
                  style={
                    filterOptions[name].style ? filterOptions[name].style : ""
                  }
                >
                  {filterOptions[name].customComponent ? (
                    <div>{filterOptions[name].input(values[name])}</div>
                  ) : (
                    <FormField name={name} label={filterOptions[name].label}>
                      {filterOptions[name].input()}
                    </FormField>
                  )}
                </Col>
              </>
            ))}
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const Manager = TableManager(props.moduleName);

  return {
    filters: Manager.selectFilters(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const Manager = TableManager(ownProps.moduleName);

  return bindActionCreators(
    {
      addFilter: Manager.addFilter,
      removeFilter: Manager.removeFilter,
    },
    dispatch
  );
};

export default connectModal({
  name: "FilterModal",
  destroyOnHide: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withFormik({
      validateOnBlur: false,
      enableReinitialize: true,

      mapPropsToValues: ({ filters, filterOptions }) =>
        Object.keys(filterOptions).reduce((previous, name) => {
          previous[name] = filters[name] || filterOptions[name].initialValue;

          if (filterOptions[name].parse && filters[name])
            previous[name] = filterOptions[name].parse(filters[name]);

          if (filterOptions[name].incomingTransformData)
            previous = filterOptions[name].incomingTransformData(
              filters,
              previous
            );

          return previous;
        }, {}),

      handleSubmit: (values, { props, setSubmitting }) => {
        let newValues = { ...values };

        Object.keys(props.filterOptions).forEach(key => {
          const obj = props.filterOptions[key];
          if (obj.onSubmitTransformData) {
            newValues = obj.onSubmitTransformData(newValues);
          }
        });

        props.addFilter(newValues);
        props.handleHide();
        setSubmitting(false);
      },

      displayName: "FilterRecordsForm",
    })(FilterModal)
  )
);
