// @ts-nocheck
import React from 'react'
import queryString from 'query-string';

const RedirectContainer = () => {
  const params = queryString.parse(window.location.search)
  console.log('params', params)
  return(
    <>Hey, I am redirect container</>
  )
}

export default RedirectContainer
