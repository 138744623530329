// @ts-nocheck
import { Radio } from "antd";
import styled from "styled-components";

export default styled(Radio.Group)`
  &&& {
    margin-bottom: 8px;
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #fff;
      background: #1890ff;
    }
    .ant-radio-button-wrapper {
      width: 100px;
    }
  }
`;
