// @ts-nocheck
import React, { useState, useRef, useEffect, Fragment } from "react";
import { Box } from "src/components/Core/Box";
import { Empty } from "antd";
import { Helmet } from "react-helmet";
import withRetry from "fetch-retry";
import { Loading } from "src/components/Core/Loading";
import styled from "styled-components";

const VideoPost = styled("div")`
  &&& {
    .tiktok-embed {
      min-width: 100px !important;
      margin: 0px !important;
    }
    iframe {
      height: 570px !important;
    }
  }
`;

const TIKTOK_OEMBED_BASE_URL = `https://www.tiktok.com/oembed`;

const fetchRetry = withRetry(window.fetch);

export interface TikTokProps {
  url: string;
}

const TikTokPost: React.FC<TikTokProps> = ({ url }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(undefined);
  const [scriptSrc, setScriptSrc] = useState<string | undefined>(undefined);
  const [html, setHTML] = useState<string | undefined>(undefined);

  const ref = useRef(null);

  /**
   * Trigger loaded state when the iframe has loaded
   */
  useEffect(() => {
    /**
     * MutationObserver:
     * https://stackoverflow.com/questions/3219758/detect-changes-in-the-dom
     */
    if (!("MutationObserver" in window)) return setLoaded(true);

    /**
     * TODO: Check bugs for MutationObserver
     * https://caniuse.com/#feat=mutationobserver
     */
    const elem = ref.current;

    const observer = new MutationObserver(mutationList => {
      // Get the iframe from the mutation that has added it
      const iframeAdded = mutationList.reduce<Node | undefined>((acc, curr) => {
        const iframe = Array.from(curr.addedNodes).find(
          node => node.nodeName === "IFRAME"
        );

        if (iframe) {
          acc = iframe;
        }
        return acc;
      }, undefined);

      if (iframeAdded) {
        iframeAdded.addEventListener("load", () => setLoaded(true));
        // $('iframe').attr('id','tiktok-iframe');
      }
    });

    if (elem) {
      observer.observe(elem, {
        childList: true,
        attributes: true,
        subtree: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchRetry(`${TIKTOK_OEMBED_BASE_URL}?url=${url}`, {
      retries: 3,
      retryDelay: attempt => 2 ** attempt * 1000,
    })
      .then(res => res.json())
      .then(res => {
        if (res && res.status_msg) throw new Error(res.status_msg);

        if (!res || !res.html)
          throw new Error("API response doesn't look right");

        const htmlString = res.html;

        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlString;

        const scriptTag = tempElement.getElementsByTagName("script")[0];

        setScriptSrc(scriptTag && scriptTag.src);
        setHTML(htmlString.substr(0, htmlString.indexOf("<script")));
      })
      .catch(err => setError(err));
  }, [url]);

  // if (error) return <div>Error: {JSON.stringify(error)}</div>;

  if (error)
    return (
      <Box height="350px" pt={50}>
        <Empty
          description="No video available!"
          // image={<Icon type="video-camera" style={{ fontSize: "20px" }} />}
        />
      </Box>
    );

  return (
    <Fragment>
      <Helmet>
        <script id="ttEmbedder" async src={scriptSrc} />
        {/* <style type="text/css">
          {
           body
           {
             backgroundColor: " green"
           }
          }
        </style> */}

        <style
          dangerouslySetInnerHTML={{
            __html: `
      body { background-color: blue }
    `,
          }}
        />
        {/* <link rel="stylesheet" href="./TikTok.module.css" /> */}
      </Helmet>

      <Loading spinning={!loaded}>
        <VideoPost
          ref={ref}
          // style={{ display: loaded && html ? "flex" : "none" }}
          dangerouslySetInnerHTML={{ __html: html || "" }}
        />
      </Loading>
    </Fragment>
  );
};

export default TikTokPost;
