// @ts-nocheck
import React, { Fragment } from "react";
import { FormikProps } from "formik";
import { CheckOutlined } from '@ant-design/icons';
import Loading from "src/shared/Loading";
import Button from "src/components/Core/Button";
import { Typography } from "src/components/Core/Typography";
import { Box } from "src/components/Core/Box";

interface Props {
  closeManualDataModal: () => void;
  setStep: (value: number) => void;
  formProps?: FormikProps<any>;
}

const FinalStep: React.FC<Props> = ({
  closeManualDataModal,
  setStep,
  formProps,
}) => {
  return (
    <Fragment>
      <Loading spinning={false}>
        <Box textAlign="center" p={70}>
          <Box
            height={62}
            width={62}
            border="3px solid #97d691"
            alignItems="center"
            justifyContent="center"
            fontSize={30}
            display="flex"
            margin="0 auto 24px"
            borderRadius={100}
            color="green"
          >
            <CheckOutlined />
          </Box>
          <Typography size={16} weight="semi-bold" color="black">
            {formProps?.values?.type} block created!
          </Typography>
          <Typography size={14} color="grey2" lspace={0.43}>
            You will now be able to view this data on the influencer’s profile.
          </Typography>
        </Box>

        <Box
          alignItems="right"
          textAlign="right"
          display="inherit"
          mt={10}
          borderTop="1px solid #E2E2E2"
          p={10}
        >
          <Button
            type="action"
            onClick={() => {
              closeManualDataModal();
              setStep(1);
            }}
            data-cy="final-button"
          >
            Close
          </Button>
        </Box>
      </Loading>
    </Fragment>
  );
};
export default FinalStep;
