// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikCheckbox } from "src/components/Forms/Checkbox";
import { influencer_flags } from "src/constants/account";

interface Props {}

const InfluencerFlags: React.FC<Props> = () => {
  return (
    <Field label="Influencer flags:" mb={26}>
      <FormikCheckbox name="flags" data={influencer_flags} />
    </Field>
  );
};

export default InfluencerFlags;
