// @ts-nocheck
import React from 'react'
import NewMilestoneFormContainer from './container'
import { Typography } from 'src/components/Core/Typography'
import { Box } from 'src/components/Core/Box'
import { Row, Col } from 'antd'
import Header from './Header'
import Footer from './Footer'
import Form from 'src/components/Forms/Form'
import { Progress } from 'antd'
import { NewMilestoneSchema } from './Validations'
import {
  Goal,
  Metric,
  Name,
  Notifiables,
  Viewers,
} from './FormFields'
import {
  GlobalErrorProps,
  GlobalErrors,
} from 'src/components/Forms/ErrorMessage'
import { Loading } from 'src/components/Core/Loading'

// globalErrors?: GlobalErrorProps[];
interface Props {
  toggleVisibility: () => void
  handleAction: (values: any, action: string) => void
  globalErrors?: GlobalErrorProps[]
  milestone: any
  loading: boolean

}

const NewMilestoneForm = ({toggleVisibility, handleAction, globalErrors, milestone, loading}: Props) => {

  const getCheckBoxValues = (obj) => {
    return obj ? Object.keys(obj).filter(k => obj[k]) : []
  }

  const initialValues = {
    name: milestone?.milestoneName || "",
    goal: milestone?.goal || 0,
    metric: milestone?.metric || "",
    viewers: getCheckBoxValues(milestone?.visibility),
    notifiables: getCheckBoxValues(milestone?.notification)
  }

  return(
    <Box>
      <Header handleAction={handleAction} />
      <Form
        initialValues={initialValues}
        schema={NewMilestoneSchema}
        onSubmit={(values, actions) => handleAction(values, 'save')}
      >
        {(formProps: any) => {
          return (
            <Loading spinning={loading}>
              <Row justify="space-between">
                <GlobalErrors errors={globalErrors} />
                <Col span={11}>
                  <Name />
                  <Goal />
                </Col>
                <Col span={11}>
                  <Metric />
                </Col>
              </Row>
              {milestone?.id &&
                <>
                  <Progress percent={(milestone.actualValue / milestone.goal) * 100} showInfo={false} />
                  <Typography color="black" fontSize={16} family="Futura">
                    {Number(milestone.actualValue.toFixed(2)).toLocaleString()} / {milestone.goal.toLocaleString()}
                  </Typography>
                </>
              }
              <Typography color="black" fontSize={16} family="Futura">Settings</Typography>
              <Viewers />
              <Notifiables />
              <Footer toggleVisibility={toggleVisibility} />
            </Loading>
          )
        }}
        </Form>
    </Box>
  )
}

export { NewMilestoneForm, NewMilestoneFormContainer }
