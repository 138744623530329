// @ts-nocheck
import React from "react";
import { Formik, FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { Alert } from "antd";
import { Form as AntdForm } from "formik-antd";

interface Props<Values = FormikValues> {
  schema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  errors?: Array<string>;
  children: React.ReactNode;
  initialValues?: Values;
  success?: String;
  forwardedRef?: any;
}

const Form: React.FC<Props> = ({
  initialValues,
  errors,
  schema,
  onSubmit,
  children,
  success,
  forwardedRef,
}) => {
  return (
    <Formik
      initialValues={initialValues || schema.cast({})}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
      ref={forwardedRef}
    >
      {formProps => {
        return (
          <AntdForm>
            {errors && errors.length > 0 && (
              <div style={{ marginBottom: 15 }}>
                {errors.map((message, i) => (
                  <Alert
                    style={{ marginBottom: 5 }}
                    key={i}
                    message={message}
                    type="error"
                    closable
                  />
                ))}
              </div>
            )}
            {!!success && (
              <div style={{ marginBottom: 15 }}>
                <Alert message={success} type="success" closable />
              </div>
            )}
            {typeof children === "function" ? children(formProps) : children}
          </AntdForm>
        );
      }}
    </Formik>
  );
};

const ForwardedForm = React.forwardRef((props: Props, ref) => {
  return <Form {...props} forwardedRef={ref} />;
});

export default ForwardedForm;
