// @ts-nocheck
import React from "react";
import moment from 'moment'
import compact from 'lodash/compact'
import { Link } from 'react-router-dom'
import { Typography } from 'src/components/Core/Typography'

const getColumns = () => {
  const isMobile = window.innerWidth < 768;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "title",
      width: isMobile? 80 : 180,
      visible: true,
      render: (text, row) => (
        <Typography color="black2" mb={0}>
          {row?.title}
        </Typography>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "distributionPlatforms",
      width: 80,
      visible: true,
      render: (text, row) =>
        <Typography color="black2" mb={0}>
          {row?.distributionPlatforms}
        </Typography>,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: isMobile? 150 : 400,
      visible: true,
      render: (text, row) =>
        <Typography color="black2" mb={0}>
          {row?.tags.map(tag => tag.name).join(', ')}
        </Typography>,
    },
    {
      title: "Content deadline",
      dataIndex: "deadline",
      key: "publicDeadline",
      width: 130,
      visible: true,
      render: (text, row) =>
        <Typography color="black2" mb={0}>
          {moment(row?.publicDeadline).format('L')}
        </Typography>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 80,
      visible: true,
      render: (text, record) =>
        <Link to={`/campaigns/${record.id}`}>{record.isApplied ? 'View' : 'Apply'}</Link>
      ,
    },
  ];

  return compact(columns);
};

export default getColumns;
