// @ts-nocheck
import React from "react";
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from "antd";
import { Box } from "src/components/Core/Box";

interface Props {
  period: string;
  setPeriod: (value: string) => void;
}

const Period: React.FC<Props> = ({ period, setPeriod }) => {
  const menu = (
    <Menu>
      <Menu.Item onClick={() => setPeriod("Month")}>Last 30 days</Menu.Item>
      <Menu.Item onClick={() => setPeriod("HalfYear")}>Last 6 month</Menu.Item>
    </Menu>
  );

  return (
    <Box color="grey6">
      <Dropdown overlay={menu}>
        <Box
          className="ant-dropdown-link"
          color="blue"
          style={{ cursor: "pointer" }}
          onClick={e => e.preventDefault()}
        >
          {period}
          <DownOutlined />
        </Box>
      </Dropdown>
    </Box>
  );
};

export default Period;
