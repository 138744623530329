// @ts-nocheck
import React from "react";
import { Field } from "src/components/Forms/Field";
import { FormikInputNumber } from "src/components/Forms/TextInputNumber";
import { Typography } from "src/components/Core/Typography";

interface Props {}

const ViewCount: React.FC<Props> = () => {
  return (
    <Field label="View count" mb={26}>
      <FormikInputNumber
        parser={value => {
          let parsed = value.replace(/\[0-9]/g, "");
          if (parsed) {
            return parsed.match(/\d+/g) ? parseInt(parsed) : "";
          } else {
            return null;
          }
        }}
        name="viewCount"
        size="middle"
        borderRadius={3}
        borderColor="grey8"
        placeholder="0"
      />
      <Typography size={14} color="grey7" mb={0}>
        Amount of views on your posted video
      </Typography>
    </Field>
  );
};

export default ViewCount;
