import { connectRouter } from "connected-react-router";
import { reducer as modal } from "redux-modal";

// NEW REDUCERS
import { reduceReducers } from "../utils/reducerUtils";

import {
  Campaign,
  CampaignAnalytic,
  Invitation,
  Suggestion,
  Creative,
  Admin,
  Influencer,
  Brand,
  Tag,
  Company,
  Account,
  AccountPost,
  Draft,
  CampaignPackage,
  Link,
  Email,
  Agency,
  Payout,
} from "src/ducks/Orm";

const reducers = history => ({
  // MOVE TO NEW REDUCER SOON!

  entities: reduceReducers(
    Campaign.reducer,
    CampaignAnalytic.reducer,
    Invitation.reducer,
    Suggestion.reducer,
    CampaignPackage.reducer,
    Creative.reducer,
    Admin.reducer,
    Influencer.reducer,
    Brand.reducer,
    Tag.reducer,
    Company.reducer,
    Draft.reducer,
    Account.reducer,
    AccountPost.reducer,
    Link.reducer,
    Email.reducer,
    Agency.reducer,
    Payout.reducer
  ),

  router: connectRouter(history),
  modal,
});

export default reducers;
