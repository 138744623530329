// @ts-nocheck
export default function getFormattedTags(allTags: any[]) {
  let tagData = [] as any;
  tagData = (allTags || [])
              .filter(v => v.isDefined)
              .map(item => {
                let container = {} as any;
                container.value = item?.id;
                container.label =
                  item.name === "Business & Finance" ? "Bus. & Fin." : item.name;
                return container;
              }) || [];
  return tagData;
}
