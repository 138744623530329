// @ts-nocheck
import styled from "styled-components";
import { InputNumber as AntdInputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import { ThemeColors } from "../../../utils/theme";

export interface NumberInputProps {
  size: "small" | "middle" | "large";
  borderColor?: ThemeColors;
  background?: ThemeColors;
  borderRadius?: any;
  parser?: any;
  formatter?: any;
  placeholder?: string;
  onChange?: any;
}

const StyledInputNumber = styled(AntdInputNumber)<
  NumberInputProps & InputNumberProps
>`
  && {
    height: ${props =>
      props.theme.components.inputs.height[props.size]}px !important;
    font-size: ${props => props.theme.components.inputs.fontSize[props.size]}px;
    border: 1px solid
      ${({ theme, borderColor = "grey5" }) => theme.colors[borderColor]} !important;
    box-sizing: border-box;
    background-color: ${props =>
      props.background && props.theme.colors[props.background]} !important;
    border-radius: ${props => props.borderRadius}px !important;

    .ant-input-number-handler-wrap {
      display: none !important;
    }
  }
  &.ant-input-number {
    width: 100%;
    line-height: ${props =>
      props.theme.components.inputs.lineHeights[props.size]}px !important;
  }
`;

export default StyledInputNumber;
