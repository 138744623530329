// @ts-nocheck
import React from 'react'
import { Row, Col } from 'antd'
import Influencers from './Influencers'
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { Box } from 'src/components/Core/Box'
import { DragDropContext } from "react-beautiful-dnd";
import { Typography } from "src/components/Core/Typography";

interface Props {
  accepted: any
  pending: any
  title: string
  globalErrors?: GlobalErrorProps[];
  setPendingFilters: Function
  setAcceptedFilters: Function
  handleUpdate: (suggestion: any) => void
}

const SuggestedInfluencers = ({
  accepted,
  pending,
  title,
  globalErrors,
  handleUpdate,
  setPendingFilters,
  setAcceptedFilters,
  ...props
}: Props) => {
  const statuses = ["pending", "accepted"]
  const filters = [setPendingFilters, setAcceptedFilters]

  const filterSuggestion = (suggestions: [any], id: number) =>
    suggestions.filter(suggestion => suggestion.id === id)[0]

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result
    if(source?.droppableId !== destination?.droppableId){
      if(source?.droppableId === "pending" && destination?.droppableId === "accepted"){
        handleMove(filterSuggestion(pending?.suggestions, draggableId))
      } else if(source?.droppableId === "accepted" && destination?.droppableId === "pending") {
        handleMove(filterSuggestion(accepted?.suggestions, draggableId))
      }
    }
  }

  const handleMove = (suggestion: any) => {
    handleUpdate(suggestion)
  }

  return(
    <Box pt={48} pr={66} pl={66} pb={51}>
      <Box>
        <Row>
          <Col>
            <Typography size={36} weight="semi-bold" color="black" textAlign='center'>
              {title}
            </Typography>
          </Col>
        </Row>
      </Box>
      <GlobalErrors errors={globalErrors} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Row gutter={{md: 24, lg: 32}}>
        {[pending, accepted].map((suggestions, index) =>
          <Col span={12}  key={index}>
            <Influencers
              suggestions={suggestions}
              status={statuses[index]}
              handleUpdate={handleMove}
              onPageChange={filters[index]}
              {...props}
            />
          </Col>
        )}
        </Row>
      </DragDropContext>
    </Box>
  )
}

export default SuggestedInfluencers;
