import { fk, attr, Model } from "redux-orm";

class Creative extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const { Company } = this.session;

    const parsedData = {
      ...data,
      company: Company.parse(data.company)
    };

    return this.upsert(parsedData);
  }
}
Creative.modelName = "Creative";

// Declare your related fields.
Creative.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
  video_url: attr(),
  company: fk("Company")
};

export default Creative;
