// @ts-nocheck
import React from "react";
import { Box } from "src/components/Core/Box";
import { RightCircleFilled } from '@ant-design/icons';
import { Avatar, Divider } from "antd";
import compact from "lodash/compact";
import sumBy from "lodash/sumBy";
import orderBy from "lodash/orderBy";
import styled from "styled-components";
import instagram from "src/assets/images/instagram_logo.png";
import snapchat from "src/assets/images/snapchat_logo.png";
import youtube from "src/assets/images/youtube_logo.png";
import tiktok from "src/assets/images/tiktok_logo.png";
import { Typography } from "src/components/Core/Typography";
import BreakdownData from "./BreakdownData";

const AccountDivider = styled(Divider)`
  &&& {
    margin: 10px 0px 0px 0px !important;
  }
`;

export const AccountBreakdown = styled.div<{
  isActive: boolean | undefined;
}>`
  transition: all 0.5s ease-in-out;
  overflow: ${props => (props.isActive ? "visible" : "hidden")};
  z-index: 999;
  position: relative;
  max-height: ${props => (props.isActive ? "300px" : "0px")};
`;

interface Props {
  account: any;
  index: number;
  isActive: boolean;
  setIndex: (value: number) => void;
  isShowOtherAcountText: boolean;
}

const AccountItem: React.FC<Props> = ({
  account,
  index,
  isActive,
  setIndex,
  isShowOtherAcountText,
}) => {
  let url = "";
  if (account?.type === "TikTok") {
    url = account?.tiktokProfileUrl();
  } else if (account?.type === "Instagram") {
    url = account?.instagramProfileUrl();
  } else if (account?.type === "YouTube") {
    url = account?.youtubeProfileUrl();
  }

  const colors = ["#69C0FF", "#1890FF", "#91D5FF", "#BAE7FF"];

  let ageBreakdownData = account?.age_breakdown?.manual || {};

  const ageBreakdownArray = compact(
    Object.keys(ageBreakdownData).map(function(key, i) {
      if (Number(ageBreakdownData[key]) === 0) {
        return null;
      }
      return {
        key: key,
        value: Number(ageBreakdownData[key]),
      };
    }) || []
  );

  let ageSum = sumBy(ageBreakdownArray, function(age: any) {
    return age?.value;
  });

  if (ageSum < 100) {
    let otherAgePercentage = {
      key: "No Data",
      value: Number(100 - ageSum),
    };

    ageBreakdownArray.push(otherAgePercentage);
  }

  let newAgeBreakdownArray = ageBreakdownArray.map((item: any, index) => {
    const newItem = { ...item };
    newItem.color = colors[index];
    return newItem;
  });

  let genderBreakdownData = account?.gender_breakdown?.manual || {};

  const genderBreakdownArray = compact(
    Object.keys(genderBreakdownData).map(function(key, i) {
      if (Number(genderBreakdownData[key]) === 0) {
        return null;
      }
      return {
        key: key,
        value: Number(genderBreakdownData[key]),
      };
    }) || []
  );

  let genderSum = sumBy(genderBreakdownArray, function(gender: any) {
    return gender?.value;
  });

  if (genderSum < 100) {
    let otherGenderPercentage = {
      key: "No Data",
      value: Number(100 - genderSum),
    };

    genderBreakdownArray.push(otherGenderPercentage);
  }

  let newGenderBreakdownArray = genderBreakdownArray.map((item: any, index) => {
    const newItem = { ...item };
    newItem.color = colors[index];
    return newItem;
  });

  let locationBreakdownData = account?.location_breakdown?.manual || {};

  delete locationBreakdownData.other;

  const locationBreakdownArray = compact(
    Object.keys(locationBreakdownData).map(function(key, i) {
      if (Number(locationBreakdownData[key]) === 0) {
        return null;
      }
      return {
        key: key,
        value: Number(locationBreakdownData[key]),
      };
    }) || []
  );

  let newLocationBreakdown = [] as Array<any>;

  if (locationBreakdownArray.length > 0) {
    let sortLocationBreakdownArray = orderBy(
      locationBreakdownArray,
      ["value"],
      ["desc"]
    );

    let topTwoLocationBreakdown = sortLocationBreakdownArray.slice(0, 2);

    let locationSum = sumBy(topTwoLocationBreakdown, function(location: any) {
      return location?.value;
    });

    if (locationSum < 100) {
      let otherLocationPercentage = {
        key: "other",
        value: Number(100 - locationSum),
      };

      topTwoLocationBreakdown.push(otherLocationPercentage);
    }

    newLocationBreakdown = topTwoLocationBreakdown.map((item: any, index) => {
      const newItem = { ...item };
      newItem.color = colors[index];
      return newItem;
    });
  }

  return (
    <Box
      onClick={() => setIndex(index)}
      style={{ cursor: "pointer", overflow: "hidden" }}
      mt={10}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="inline-flex">
          <a
            href={account?.type !== "Snapchat" ? url : "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Avatar shape="square" size={72} src={account?.profile_pic_url} />
          </a>
          <Box ml={10} mt={15}>
            <Typography
              size={22}
              weight="semi-bold"
              color={isActive ? "blue" : "grey8"}
              lineHeight={0}
              cursor="pointer"
            >
              @{account?.username}
            </Typography>

            <Box display="inline-flex" style={{ cursor: "pointer" }}>
              {account?.type === "Instagram" && (
                <>
                  <img
                    src={instagram}
                    alt="instagram-img"
                    width={15}
                    height={15}
                  />
                </>
              )}
              {account?.type === "Snapchat" && (
                <>
                  <img
                    src={snapchat}
                    alt="snapchat-img"
                    width={15}
                    height={15}
                  />
                </>
              )}
              {account?.type === "YouTube" && (
                <>
                  <img src={youtube} alt="youtube-img" width={15} height={15} />
                </>
              )}
              {account?.type === "TikTok" && (
                <>
                  <img src={tiktok} alt="tiktok-img" width={15} height={15} />
                </>
              )}
              <Typography
                size={14}
                weight="semi-bold"
                color="grey7"
                ml={10}
                lineHeight="12px"
                cursor="pointer"
              >
                {account?.type} account
              </Typography>
            </Box>
          </Box>
        </Box>
        {isActive && (
          <RightCircleFilled
            style={{
              color: "#40A9FF",
              marginTop: "25px",
              marginLeft: "5px",
            }} />
        )}
      </Box>
      <AccountDivider />

      {((newGenderBreakdownArray || []).length > 0 ||
        (newAgeBreakdownArray || []).length > 0 ||
        (newLocationBreakdown || []).length > 0) && (
        <AccountBreakdown isActive={isActive}>
          <Typography
            size={16}
            weight="semi-bold"
            color="black2"
            lineHeight="12px"
            mt={15}
            mb={25}
          >
            {account?.username} account details:
          </Typography>

          {(newGenderBreakdownArray || []).length > 0 && (
            <BreakdownData
              title="Audience Gender:"
              data={newGenderBreakdownArray}
            />
          )}

          {(newAgeBreakdownArray || []).length > 0 && (
            <BreakdownData title="Audience Age:" data={newAgeBreakdownArray} />
          )}

          {(newLocationBreakdown || []).length > 0 && (
            <BreakdownData
              title="Audience Location:"
              data={newLocationBreakdown}
            />
          )}

          <AccountDivider />
        </AccountBreakdown>
      )}

      {isShowOtherAcountText && (
        <Typography size={14} color="grey7" lineHeight="12px" mt={10}>
          Other accounts owned by this influencer:
        </Typography>
      )}
    </Box>
  );
};

export default AccountItem;
