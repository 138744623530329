// @ts-nocheck
import React, { Fragment } from 'react'
import { Typography } from 'src/components/Core/Typography'
import {
  Age,
  Location,
  FullName,
  Gender
} from '../FormFields'

export interface Props {
  formProps: any
}

const LeftPanel = ({formProps}: Props) => {
  return(
    <Fragment>
      <Typography size={30} weight="semi-bold" color="black1" mb="8px">Signup</Typography>
      <Typography color="black2">Enter your details below</Typography>
      <FullName />
      <Location formProps={formProps} />
      <Age formProps={formProps} />
      <Gender />
    </Fragment>
  )
}

export default LeftPanel
