// @ts-nocheck
import React from "react";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import map from "lodash/map";
import { CloseOutlined } from '@ant-design/icons';
import { Tag } from "src/components/Core/Tag";
import { AudienceBreakdownComparisonType } from "src/generated/ApolloComponents";

export function getFilterData(data) {
  let filters = {} as any;

  if (data?.searchInput && data?.searchType) {
    filters["searchInput"] = data?.searchInput;
  }

  if (data?.searchType && data?.searchInput) {
    filters["searchType"] = data?.searchType;
  }

  if(data?.adminListId) {
    filters["adminListId"] = Number(data?.adminListId)
  }

  if(data?.adminListIdForNotIncluded) {
    filters["adminListIdForNotIncluded"] = Number(data?.adminListIdForNotIncluded)
  }

  if ((data?.accountType || []).length > 0) {
    filters["accountType"] = data?.accountType;
  }

  if ((data?.influencerAge || []).length > 0) {
    filters["influencerAge"] = data?.influencerAge;
  }

  if ((data?.influencerGender || []).length > 0) {
    filters["influencerGender"] = data?.influencerGender;
  }

  if ((data?.influencerLocation || []).length > 0) {
    filters["influencerLocation"] = data?.influencerLocation;
  }

  if ((data?.status || []).length > 0) {
    filters["status"] = data?.status;
  }

  if ((data?.tags || []).length > 0) {
    filters["tags"] = map(data?.tags, Number);
  }

  if ((data?.customTags || []).length > 0) {
    filters["customTags"] = map(data?.customTags, Number);
  }

  if (data?.completedCampaigns) {
    const value = data?.completedCampaigns;

    let container = { ...value } as any;

    if (value?.value) {
      container["value"] = Number(value?.value);
    }

    filters["completedCampaigns"] = container;
  }

  if (data?.followers) {
    const value = data?.followers;

    let container = { ...value } as any;

    if (value?.value) {
      container["value"] = Number(value?.value);
    }

    filters["followers"] = container;
  }

  if ((data?.audienceAge || []).length > 0) {
    const ageAudience = (data?.audienceAge || []).map(item => {
      let container = { ...item };

      if (item?.value) {
        container["value"] = Number(item?.value);
      }
      return container;
    });
    filters["audienceAge"] = ageAudience;
  }

  if ((data?.audienceGender || []).length > 0) {
    const genderAudience = (data?.audienceGender || []).map(item => {
      let container = { ...item };

      if (item?.value) {
        container["value"] = Number(item?.value);
      }
      return container;
    });
    filters["audienceGender"] = genderAudience;
  }

  if ((data?.audienceLocation || []).length > 0) {
    const locationAudience = (data?.audienceLocation || []).map(item => {
      let container = { ...item };

      if (item?.value) {
        container["value"] = Number(item?.value);
      }
      return container;
    });
    filters["audienceLocation"] = locationAudience;
  }

  if (data?.page) {
    filters["page"] = Number(data?.page);
  }

  if (data?.order) {
    filters["order"] = data?.order;
  }

  if(data?.campaignIdForSuggestedAccounts) {
    filters["campaignIdForSuggestedAccounts"] = Number(data?.campaignIdForSuggestedAccounts);
  }

  return filters;
}

function getComparison(comparison) {
  let appliedComparison = "";
  if (comparison === AudienceBreakdownComparisonType.GreaterEqualThan) {
    appliedComparison = ">";
  }

  if (comparison === AudienceBreakdownComparisonType.SmallerEqualThan) {
    appliedComparison = "<";
  }

  return appliedComparison;
}

export function getApplyFilter(data, clearFilterKey, allTags, adminLists, list = undefined as any) {
  let filters = [] as any;

  if (data?.searchInput) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="grey6"
        key="searchInput"
      >
        {data?.searchType}:{data?.searchInput}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("searchInput");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.accountType) {
    (data?.accountType || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={item}
        >
          Type:{item}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("accountType", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.influencerAge) {
    (data?.influencerAge || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={`influencerAge-${item}`}
        >
          Age:{item}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("influencerAge", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.influencerGender) {
    (data?.influencerGender || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={`influencerGender-${item}`}
        >
          Gender:{item}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("influencerGender", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.influencerLocation) {
    (data?.influencerLocation || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={`influencerLocation-${item}`}
        >
          Location:{item}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("influencerLocation", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.status) {
    (data?.status || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={item}
        >
          Status:{item}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("status", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.tags) {
    (data?.tags || []).forEach((item, i) => {
      let tag = find(allTags, matchesProperty("id", item));
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={tag?.name}
        >
          Tag:{tag?.name}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("tags", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.customTags) {
    (data?.customTags || []).forEach((item, i) => {
      let tag = find(allTags, matchesProperty("id", item));

      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="blue"
          key={tag?.name}
        >
          C.Tag:{tag?.name}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("customTags", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.audienceAge) {
    (data?.audienceAge || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="danger"
          key={item?.key}
        >
          A.Age:{item?.key} {getComparison(item?.comparison)} {item?.value}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("audienceAge", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.audienceGender) {
    (data?.audienceGender || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="danger"
          key={item?.key}
        >
          A.Gender:{item?.key} {getComparison(item?.comparison)} {item?.value}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("audienceGender", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.audienceLocation) {
    (data?.audienceLocation || []).forEach((item, i) => {
      const filterItem = (
        <Tag
          size={12}
          tagColor="black2"
          background="white1"
          borderColor="danger"
          key={item?.key}
        >
          A.Location:{item?.key} {getComparison(item?.comparison)} {item?.value}
          <CloseOutlined
            onClick={() => {
              clearFilterKey("audienceLocation", i);
            }} />
        </Tag>
      );
      filters.push(filterItem);
    });
  }

  if (data?.completedCampaigns) {
    let month = "";

    if (data?.completedCampaigns?.months === "Last1Month") {
      month = "1m";
    }

    if (data?.completedCampaigns?.months === "Last3Month") {
      month = "3m";
    }

    if (data?.completedCampaigns?.months === "Ever") {
      month = "ever";
    }

    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="blue"
        key="completedCampaigns"
      >
        Completed {data?.completedCampaigns?.value} campaign in: {month}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("completedCampaigns");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.followers) {
    const filterItem = (
      <Tag
        size={12}
        tagColor="black2"
        background="white1"
        borderColor="blue"
        key="followers"
      >
        Followers : {getComparison(data?.followers?.comparison)}{" "}
        {data?.followers?.value}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("followers");
          }} />
      </Tag>
    );
    filters.push(filterItem);
  }

  if (data?.rating?.creationTime) {
    const filterItem = (
      <Tag size={12} tagColor="black2" background="white1" borderColor="blue" key="creationTime">
        Creation Time:{data?.rating?.creationTime}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("rating.creationTime");
          }} />
      </Tag>
    );

    filters.push(filterItem);
  }

  if (data?.rating?.responseTime) {
    const filterItem = (
      <Tag size={12} tagColor="black2" background="white1" borderColor="blue" key="responseTime">
        Response Time:{data?.rating?.responseTime}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("rating.responseTime");
          }} />
      </Tag>
    );

    filters.push(filterItem);
  }

  if (data?.rating?.contentQuality) {
    const filterItem = (
      <Tag size={12} tagColor="black2" background="white1" borderColor="blue" key="contentQuality">
        Content Quality:{data?.rating?.contentQuality}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("rating.contentQuality");
          }} />
      </Tag>
    );

    filters.push(filterItem);
  }

  if (data?.rating?.professionalism) {
    const filterItem = (
      <Tag size={12} tagColor="black2" background="white1" borderColor="blue" key="professionalism">
        Professionalism:{data?.rating?.professionalism}
        <CloseOutlined
          onClick={() => {
            clearFilterKey("rating.professionalism");
          }} />
      </Tag>
    );

    filters.push(filterItem);
  }

  if (data?.adminListId) {
    let _list = find(adminLists, matchesProperty("id", data?.adminListId));
    const filterItem = (
      <Tag size={12} tagColor="black2" background="white1" borderColor="blue" key="adminListId">
        Included in admin list: {_list?.listName}
        {!list?.id && <CloseOutlined
          onClick={() => {
            clearFilterKey("adminListId");
          }} />}
      </Tag>
    );

    filters.push(filterItem);
  }

  if (data?.adminListIdForNotIncluded) {
    let _list = find(adminLists, matchesProperty("id", data?.adminListIdForNotIncluded));
    const filterItem = (
      <Tag size={12} tagColor="black2" background="white1" borderColor="blue" key="adminListIdForNotIncluded">
        Not in admin list: {_list?.listName}
        {!list?.id && <CloseOutlined
          onClick={() => {
            clearFilterKey("adminListIdForNotIncluded");
          }} />}
      </Tag>
    );

    filters.push(filterItem);
  }

  return filters;
}

export function getTotalAppliedFilter(filters) {
  let count = 0;

  if (filters?.searchInput && filters?.searchType) {
    count = Number(count + 1);
  }

  if ((filters?.accountType || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.influencerAge || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.influencerGender || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.influencerLocation || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.status || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.tags || []).length > 0) {
    count = Number(count + 1);
  }
  if ((filters?.customTags || []).length > 0) {
    count = Number(count + 1);
  }

  if (filters?.completedCampaigns) {
    count = Number(count + 1);
  }

  if (filters?.followers) {
    count = Number(count + 1);
  }

  if (filters?.rating?.creationTime) {
    count = Number(count + 1);
  }

  if (filters?.rating?.responseTime) {
    count = Number(count + 1);
  }

  if (filters?.rating?.contentQuality) {
    count = Number(count + 1);
  }

  if (filters?.rating?.professionalism) {
    count = Number(count + 1);
  }

  if ((filters?.audienceAge || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.audienceGender || []).length > 0) {
    count = Number(count + 1);
  }

  if ((filters?.audienceLocation || []).length > 0) {
    count = Number(count + 1);
  }

  if(filters?.adminListId)
    count = Number(count + 1)

  if(filters?.adminListIdForNotIncluded)
    count = Number(count + 1)

  return count;
}
