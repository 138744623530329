// @ts-nocheck
import React, { useState } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import Button from "src/components/Core/Button";
import { AccountDetailContainer } from "./container";
import { BasicInfoContainer } from "./BasicInfo";
import { ExternalBasicInfoContainer } from "./ExternalBasicInfo";
import { CurrentFollowersGraphContainer } from "./Graphs/CurrentFollowersGraph";
import { AverageLikesGraphContainer } from "./Graphs/AverageLikesGraph";
import { AverageCommentsGraphContainer } from "./Graphs/AverageCommentsGraph";
import { AverageHeartsGraphContainer } from "./Graphs/AverageHeartsGraph";
import { AverageViewsGraphContainer } from "./Graphs/AverageViewsGraph";
import { DemographicAgesGraphContainer } from "./Graphs/DemographicAgesGraph";
import { DemographicGendersGraphContainer } from "./Graphs/DemographicGendersGraph";
import { DemographicLocationsGraphContainer } from "./Graphs/DemographicLocationsGraph";
// import { DemographicGenderLocationsGraphContainer } from "./Graphs/DemographicGenderLocationsGraph";
// import { DemographicAgeLocationsGraphContainer } from "./Graphs/DemographicAgeLocationsGraph";
import { ManualDataModalFormContainer } from "./ManualDataModal";
import {
  DataSourcePlatform,
  AccountType,
} from "src/generated/ApolloComponents";
import { Icon } from '@ant-design/compatible'

const SettingIcon = styled(Icon)`
  && {
  }
  &:hover {
    color: ${props => props.theme.colors.blue} !important;
  }
`;

const GraphRow = styled(Row)`
  && {
    .ant-col {
      padding: 8px !important;
    }
  }
`;

interface Props {
  loading: boolean;
  accountId: string;
  platformType: string;
  doRefresh: boolean; // when Manual data modal add age, gender ,location breakdown , graph should be reload
  setDoRefresh: (value: boolean) => void;
  account: any;
}

const AccountDetail: React.FC<Props> = ({
  loading,
  accountId,
  doRefresh,
  setDoRefresh,
  platformType,
  account,
}) => {
  const accountData = account?.data?.getAccountDetailById;

  const accountType: any = accountData?.type; //"TikTok";
  const username: any = accountData?.username;

  const [showManualDataModal, setShowManualDataModal] = useState(false);
  const [showNewBlockButton, setNewBlockButton] = useState(false);

  return (
    <Box>
      <Loading spinning={loading}>
        {platformType === DataSourcePlatform.Fanbytes ? (
          <BasicInfoContainer
            accountId={parseInt(accountId)}
            account={account}
          />
        ) : (
          <ExternalBasicInfoContainer account={account} />
        )}

        <Box p={0}>
          <Box background="#F8F8F8" p={15} mt={15}>
            <Box display="flex" justifyContent="space-between">
              <Box>
                {/* <Typography size={12} color="grey6">
                  Channel last updated at: 12:05pm GMT 30.04.2020
                </Typography> */}
              </Box>

              {platformType === DataSourcePlatform.Fanbytes && (
                <Box
                  color={showNewBlockButton ? "blue" : "grey6"}
                  display="flex"
                >
                  {showNewBlockButton && (
                    <Box mr={20} marginBottom={20}>
                      <Button
                        type="action"
                        buttonSize="small"
                        onClick={() => setShowManualDataModal(true)}
                        data-cy="new-block-button"
                      >
                        New block
                      </Button>
                    </Box>
                  )}

                  <SettingIcon
                    type="setting"
                    onClick={() => setNewBlockButton(!showNewBlockButton)}
                  />
                </Box>
              )}
            </Box>

            <GraphRow gutter={16}>
              <Col span={12}>
                <CurrentFollowersGraphContainer
                  accountId={
                    accountType === AccountType.TikTok ? "" : accountId
                  }
                  username={username}
                  platformType={
                    accountType === AccountType.TikTok
                      ? DataSourcePlatform.Bytesights
                      : platformType
                  }
                />
              </Col>

              {accountType !== AccountType.Snapchat && (
                <Col span={12}>
                  <AverageCommentsGraphContainer
                    accountId={
                      accountType === AccountType.TikTok ? "" : accountId
                    }
                    username={username}
                    platformType={
                      accountType === AccountType.TikTok
                        ? DataSourcePlatform.Bytesights
                        : platformType
                    }
                  />
                </Col>
              )}

              {accountType === AccountType.TikTok && (
                <Col span={12}>
                  <AverageHeartsGraphContainer
                    accountId={
                      accountType === AccountType.TikTok ? "" : accountId
                    }
                    username={username}
                    platformType={
                      accountType === AccountType.TikTok
                        ? DataSourcePlatform.Bytesights
                        : platformType
                    }
                  />
                </Col>
              )}

              {platformType !== DataSourcePlatform.Bytesights &&
                (accountType === AccountType.Instagram ||
                  accountType === AccountType.YouTube) && (
                  <Col span={12}>
                    <AverageLikesGraphContainer
                      accountId={accountId}
                      username={username}
                      platformType={platformType}
                    />
                  </Col>
                )}

              {accountType === AccountType.TikTok && (
                <Col span={12}>
                  <AverageViewsGraphContainer
                    accountId={
                      accountType === AccountType.TikTok ? "" : accountId
                    }
                    username={username}
                    platformType={
                      accountType === AccountType.TikTok
                        ? DataSourcePlatform.Bytesights
                        : platformType
                    }
                  />
                </Col>
              )}

              {platformType !== DataSourcePlatform.Bytesights && (
                <>
                  <Col span={12}>
                    <DemographicAgesGraphContainer
                      doRefresh={doRefresh}
                      setDoRefresh={() => setDoRefresh(false)}
                      accountId={accountId}
                    />
                  </Col>

                  <Col span={12}>
                    <DemographicGendersGraphContainer
                      doRefresh={doRefresh}
                      setDoRefresh={() => setDoRefresh(false)}
                      accountId={accountId}
                    />
                  </Col>

                  <Col span={12}>
                    <DemographicLocationsGraphContainer
                      doRefresh={doRefresh}
                      setDoRefresh={() => setDoRefresh(false)}
                      accountId={accountId}
                    />
                  </Col>
                </>
              )}

              {/* Hide for now will add after upfluence integration */}
              {/* {platformType !== DataSourcePlatform.Bytesights &&
                accountType !== AccountType.Snapchat && (
                  <>
                    <Col span={12}>
                      <DemographicAgeLocationsGraphContainer />
                    </Col>

                    <Col span={12}>
                      <DemographicGenderLocationsGraphContainer />
                    </Col>
                  </>
                )} */}
            </GraphRow>
          </Box>
        </Box>
      </Loading>

      <ManualDataModalFormContainer
        closeManualDataModal={() => setShowManualDataModal(false)}
        showManualDataModal={showManualDataModal}
        setDoRefresh={() => setDoRefresh(true)}
      />
    </Box>
  );
};

export { AccountDetail, AccountDetailContainer };
