import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import LoginLayout from "../../layouts/LoginLayout";
import ResetPasswordForm from "./ResetPasswordForm";
import Auth from "src/ducks/Auth";

export class ResetPassword extends Component {
  componentDidMount() {
    const {
      match: { params },
      history,
      loadUser
    } = this.props;

    loadUser(params.id).catch(() => {
      history.push("/sign_in");
      message.error("Incorrect link!");
    });
  }

  render() {
    const {
      match: { params }
    } = this.props;

    return (
      <LoginLayout title="Reset your password">
        <ResetPasswordForm perisableToken={params.id} />
      </LoginLayout>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  loadUser: Auth.loadUserByPerishableToken
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
