// @ts-nocheck
import React from "react";
import { ThemeProvider } from "styled-components";

const colors = {
  black: "#000000",
  black1: "#363636",
  black2: "#595959",
  black3: "#535353",
  black4: "#262626",
  black5: "#333333",
  black6: "#0F0F0F",
  white: "#FFFFFF",
  white1: "#F5F5F5",
  confirm: "#78B085",
  action: "#1890FF",
  warning: "#FF1C1C",
  danger: "#F5222D",
  ghost: "#F5F5F5",
  disabled: "#DADBDC",
  grey9: "#808080",
  grey8: "#D9D9D9",
  grey7: "#8C8C8C",
  grey6: "#BFBFBF",
  grey5: "#DCDCDC",
  grey4: "#E4E4E4",
  grey3: "#7D7D7D",
  grey21: "#FAFAFA",
  grey2: "#7F7F7F",
  grey1: "#D8D8D8",
  blue: "#1890FF",
  green: "#97d691",
  green1: "#F6FFED",
  green4: "#95DE64",
  green5: "#73D13D",
  green6: "#52C41A",
  polargreen: "73D13D",
  geekblue1: "#F0F5FF",
  geekblue3: "#ADC6FF",
  geekblue6: "#2F54EB",
  blue1: "#E6F7FF",
  blue3: "#91D5FF",
  blue4: "#69C0FF",
  blue5: "#40A9FF",
  skyblue: "#40A9FF",
  red: "#E74848",
  red2: "#FFCCC7",
  red3: "#FFA39E",
  red4: "#FF7875",
  red5: "#FF4D4F",
  red6: "#F5222D",
  red8: "#A8071A",
  lime: "#B7EB8F",
  pitch: "#FF7875",
  background: "#FBFBFB",
  background1: "#F6F6F6",
  cyan1: "#E6FFFB",
  cyan3: "#87E8DE",
  cyan7: "#08979C",
  scorpion: "#5B5B5B",
  silver: "#C6C6C6",
} as const;

export const fontSizes = [
  10,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  30,
  36,
  40,
  44,
  48,
  50,
] as const;

export const fontWeights = {
  "semi-bold": 600,
  regular: 400,
};

const typeography = {
  fontSizes,
  fontWeights,
  lineHeights: {
    10: "18px",
    12: "20px",
    14: "22px",
    16: "24px",
    18: "25px",
    20: "26px",
    22: "28px",
    24: "30px",
    26: "34px",
    30: "38px",
    40: "48px",
    44: "50px",
    48: "52px",
    50: "54px",
  },
};

const components = {
  inputs: {
    height: {
      large: 50,
      middle: 40,
      small: 30,
    },
    fontSize: {
      large: 16,
      middle: 14,
      small: 12,
    },
    lineHeights: {
      large: 46,
      middle: 40,
      small: 32,
    },
  },
  buttons: {
    height: {
      large: 50,
      middle: 40,
      small: 30,
    },
    width: {
      large: 200,
      middle: 153,
      small: 100,
    },
    fontSize: {
      large: 16,
      middle: 14,
      small: 12,
    },
  },
};

const theme = {
  colors,
  typeography,
  components,
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;

export type FontWeights = keyof typeof fontWeights;
export type FontSizes = typeof fontSizes[number];
export type ThemeColors = keyof typeof colors;
