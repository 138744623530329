// @ts-nocheck
import React from "react";
import { Typography } from "src/components/Core/Typography";
import { Link } from "react-router-dom";
import { Popover } from "antd";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import numeral from "numeral";
import moment from "moment";
import { currency as allCuurency } from "src/constants/payments";
import { Badge } from "src/components/Core/Badge";
import { Box } from "src/components/Core/Box";

export default [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 80,
    render: (text, row) => (
      <Typography size={12} color="black3">
        {row?.id}
      </Typography>
    ),
  },
  {
    title: "Campaign",
    dataIndex: "title",
    key: "title",
    width: 180,
    render: (text, row) => (
      <>
        {row?.title ? (
          <Popover content={row?.title}>
            <Link to={`/campaigns/${row?.id}`} replace>
              <Typography size={12} cursor="pointer">
                {row?.title}
              </Typography>
            </Link>
          </Popover>
        ) : (
          "-"
        )}
      </>
    ),
  },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
    width: 180,
    render: (text, row) => (
      <Typography size={12} cursor="pointer">
        {row?.account ? row?.account : "-"}
      </Typography>
    ),
  },
  {
    title: "Requested date",
    dataIndex: "requestDate",
    key: "requestDate",
    width: 150,
    render: (text, row) => (
      <Typography size={12} color="black3">
        {row?.requestDate
          ? moment(row?.requestDate).format("Do MMM YYYY")
          : "Ongoing"}
      </Typography>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 120,
    render: (text, row) => {
      let currencySign = find(
        allCuurency,
        matchesProperty("value", row?.currency)
      );

      return (
        <>
          {row?.requestDate ? (
            <Typography size={12} color="black3">
              {currencySign?.label}
              {row?.amount ? numeral(row?.amount / 100).format("0,0[.]00") : ""}
            </Typography>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    title: "",
    dataIndex: "",
    key: "x",
    width: 130,
    render: (text: any, row: any) => (
      <Box mr="5px">
        {row?.requestDate ? (
          <>
            {row?.paymentRequestStatus === "pending" && (
              <Badge
                color="white"
                background="blue4"
                height={25}
                width={78}
                size={12}
              >
                Pending
              </Badge>
            )}
            {(row?.paymentRequestStatus === "rejected" ||
              row?.paymentRequestStatus === "failed") && (
              <Badge
                color="white"
                background="pitch"
                height={25}
                width={78}
                size={12}
              >
                {row?.paymentRequestStatus === "rejected"
                  ? "Rejected"
                  : "Failed"}
              </Badge>
            )}
            {row?.paymentRequestStatus === "approved" && (
              <Badge
                color="white"
                background="lime"
                height={25}
                width={78}
                size={12}
              >
                Approved
              </Badge>
            )}{" "}
          </>
        ) : (
          ""
        )}
      </Box>
    ),
  },
];
