import React, { Component } from "react";
import { connect } from "react-redux";
import { AccountPersist } from "src/ducks/Persist";
import { withFormik } from "formik";
import { Link } from "react-router-dom";
import { Form } from "formik-antd";
import Validate from "./Validate";
import styled from "styled-components";
import formatCountries from "src/utils/formatCountries";
import { message, Button, Card, Row, Col, Descriptions, Divider } from "antd";
import {
  Username,
  Influencer,
  Platform,
  Format,
  // Status,
  Active,
  Views,
  Cpm,
  Screenshots,
  TikTokProfile,
  AvgHearts,
  AvgLikes,
  Payout,
  AvgComments,
  Followers,
  InstagramProfile,
  SalesAppropriate,
  YouTubeProfile,
  AvgViews,
  AgesBreakdown,
  GenderBreakdown,
  LocationsBreakdown,
  AccountTag,
  Professionalism,
  ResponseTime,
  CreationTime,
  ContentQuality,
} from "src/shared/AccountFields";
import Container from "src/shared/Container";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import idx from "idx";
import numeral from "numeral";
import isEmpty from "lodash/isEmpty";
import sum from "lodash/sum";
import _values from "lodash/values";
import has from "lodash/has";
import { ages, genders } from "src/constants/account";

const ShowAccount = AccountPersist("AdminAccountShow");

const InfluencerDescription = styled(Descriptions)`
  &&& {
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      padding: 16px 24px 0px 24px !important;
    }
  }
`;

export class AccountForm extends Component {
  state = {
    influencerId: 0,
    influencerName: "",
    influencerAge: "",
    influencerGender: "",
    influencerLocation: "",
  };

  componentDidMount() {
    const { account, location } = this.props;

    this.setState({
      influencerId: account.influencer?.id,
      influencerName: account.influencer ? account.influencer.name : "",
      influencerAge: account.formattedAges(),
      influencerGender: account.formattedGender(),
      influencerLocation: account.formattedLocations(),
    });

    if (location.hash && location.hash !== "") {
      setTimeout(() => {
        document
          .getElementById("demographic")
          .scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }

  updateInfluencer = influencer => {
    if (influencer) {
      this.setState({
        influencerId: influencer?.id,
        influencerName: influencer.name,
        influencerAge:
          influencer.detail.age && influencer.detail.age[0]
            ? influencer.detail.age[0]
            : "",
        influencerGender:
          influencer.detail.gender && influencer.detail.gender[0]
            ? influencer.detail?.gender[0]
            : "",
        influencerLocation:
          influencer.detail.location && influencer.detail.location[0]
            ? formatCountries(influencer.detail.location[0])
            : "",
      });
    }
  };

  render() {
    const { values, isSubmitting, account } = this.props;

    const {
      influencerId,
      influencerName,
      influencerAge,
      influencerGender,
      influencerLocation,
    } = this.state;

    return (
      <Container style={{ marginTop: 24, marginBottom: 24 }}>
        <Card>
          <Form>
            <Username />
            <Payout />
            <Cpm />
            {/* <Status /> */}
            <Influencer
              onInfluencerChange={value => {
                this.updateInfluencer(value);
              }}
            />
            <InfluencerDescription
              size="middle"
              layout="vertical"
              bordered
              style={{ marginBottom: "20px" }}
            >
              <Descriptions.Item
                label={
                  <Box display="flex" justifyContent="space-between">
                    {account.influencer ? (
                      <Typography
                        size={20}
                        weight="semi-bold"
                        color="black4"
                        lineHeight="16px"
                      >
                        {`${influencerName}'s details`}
                      </Typography>
                    ) : (
                      ""
                    )}

                    <Link to={`/influencers/${influencerId}/update`}>
                      Edit details
                    </Link>
                  </Box>
                }
                span={3}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <Box display="inline-flex">
                      <Typography
                        size={16}
                        weight="semi-bold"
                        color="black"
                        mr={10}
                      >
                        Age:
                      </Typography>
                      <Typography size={16} lineHeight="28px">
                        {influencerAge}
                      </Typography>
                    </Box>
                  </Col>
                  <Col span={8}>
                    <Box display="inline-flex">
                      <Typography
                        size={16}
                        weight="semi-bold"
                        color="black"
                        mr={10}
                      >
                        Location:
                      </Typography>
                      <Typography size={16} lineHeight="28px">
                        {influencerLocation}
                      </Typography>
                    </Box>
                  </Col>
                  <Col span={8}>
                    <Box display="inline-flex">
                      <Typography
                        size={16}
                        weight="semi-bold"
                        color="black"
                        mr={10}
                      >
                        Gender:
                      </Typography>
                      <Typography size={16} lineHeight="28px">
                        {influencerGender}
                      </Typography>
                    </Box>
                  </Col>
                </Row>
              </Descriptions.Item>
            </InfluencerDescription>
            <Platform />
            <Format />
            <Active />
            <SalesAppropriate />

            <Row gutter={24}>
              <Col span={12}>
                <AccountTag />
                <Screenshots />
              </Col>

              <Col span={12}>
                <Views />

                {values.type === "TikTok" && (
                  <>
                    <TikTokProfile />
                    <AvgHearts />
                    <AvgComments />
                  </>
                )}

                {values.type === "Instagram" && (
                  <>
                    <InstagramProfile />
                    <AvgComments />
                    <AvgLikes />
                  </>
                )}

                {values.type === "YouTube" && (
                  <>
                    <YouTubeProfile />
                    <AvgViews />
                  </>
                )}

                {(values.type === "Instagram" ||
                  (values.type === "TikTok" && values.type === "YouTube")) && (
                  <Followers />
                )}
              </Col>
            </Row>
            <div id="demographic">
              <Row gutter={24}>
                <Col span={12}>
                  <CreationTime />
                  <ContentQuality />
                </Col>
                <Col span={12}>
                  <ResponseTime />
                  <Professionalism />
                </Col>
              </Row>
              <Divider orientation="left">Ages Breakdown</Divider>
              <AgesBreakdown />
              <Divider orientation="left">Gender Breakdown</Divider>
              <GenderBreakdown />
              <Divider orientation="left">Location Breakdown</Divider>
              <LocationsBreakdown
                locationBreakdown={values.locationBreakdown}
              />
            </div>
            <Button
              type="primary"
              size="large"
              loading={isSubmitting}
              disabled={isSubmitting}
              htmlType="submit"
            >
              Update
            </Button>
          </Form>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  account: ShowAccount.selectOne(state),
});

const mapDispatchToProps = {
  updateAccount: ShowAccount.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    validateOnBlur: false,
    enableReinitialize: true,

    mapPropsToValues: ({ account }) => {
      const locationBreakdown =
        (idx(account, x => x.ref.location_breakdown.manual) &&
          Object.keys(idx(account, x => x.ref.location_breakdown.manual) || {})
            .slice(0, 5)
            .map(function(key) {
              return {
                country: key,
                percentage: Number(
                  numeral(account.ref.location_breakdown.manual[key]).format(
                    "0,0.00"
                  )
                ),
              };
            })) ||
        [];

      let genderBreakdown = {};

      if (!isEmpty(idx(account, x => x.ref.gender_breakdown.manual))) {
        genders.forEach((gender, index) => {
          genderBreakdown[gender.value] = Number(
            numeral(account.ref.gender_breakdown.manual[gender.value]).format(
              "0,0.00"
            )
          );
        });
      }

      let agesBreakdown = {};
      if (!isEmpty(idx(account, x => x.ref.age_breakdown.manual))) {
        ages.forEach((age, index1) => {
          agesBreakdown[age.value] = Number(
            numeral(account.ref.age_breakdown.manual[age.value]).format(
              "0,0.00"
            )
          );
        });
      }

      return account
        ? {
            ...account.ref,
            locationBreakdown,
            age_breakdown: {
              live: idx(account, x => x.ref.age_breakdown.live) || {},
              manual: agesBreakdown,
            },
            gender_breakdown: {
              live: idx(account, x => x.ref.gender_breakdown.live) || {},
              manual: genderBreakdown,
            },
            metadata: {
              ...idx(account, x => x.ref.metadata),
              rating: {
                creation_time:
                  idx(account, x => x.ref.metadata.rating.creation_time) ||
                  null,
                response_time:
                  idx(account, x => x.ref.metadata.rating.response_time) ||
                  null,
                content_quality:
                  idx(account, x => x.ref.metadata.rating.content_quality) ||
                  null,
                professionalism:
                  idx(account, x => x.ref.metadata.rating.professionalism) ||
                  null,
              },
            },
          }
        : Validate.cast();
    },

    validationSchema: () => Validate,

    handleSubmit: (values, { props, setSubmitting, setErrors, error }) => {
      const { updateAccount, account } = props;

      let data;

      if (
        typeof values.locationBreakdown != "undefined" &&
        values.locationBreakdown.length > 0
      ) {
        const { locationBreakdown, ...rest } = values;

        let locationPercentage = values.locationBreakdown.reduce(function(
          result,
          item
        ) {
          if (
            item.country !== "" &&
            typeof item.percentage !== "undefined" &&
            item.percentage !== "" &&
            item.percentage !== null &&
            item.percentage !== false
          ) {
            result[item.country] = item.percentage;
            return result;
          }
          return result;
        },
        {});

        let locationSum = sum(_values(locationPercentage));

        if (locationSum < 100 && !has(locationPercentage, "other")) {
          let otherLocationPercentage = { other: 100 - locationSum };

          locationPercentage = Object.assign(
            locationPercentage,
            otherLocationPercentage
          );
        }

        rest.location_breakdown.manual = locationPercentage;
        data = rest;
      } else {
        values.location_breakdown.manual = {};
        data = values;
      }

      // Filter all falsy values ( "", false, null, undefined )
      const gender_breakdown =
        idx(values, x => x.gender_breakdown.manual) || {};

      const genderBreakdown = Object.entries(gender_breakdown).reduce(
        (a, [k, v]) =>
          typeof v !== "undefined" && v !== "" && v !== null && v !== false
            ? { ...a, [k]: v }
            : a,
        {}
      );

      data.gender_breakdown.manual = genderBreakdown;

      // Filter all falsy values ( "", 0, false, null, undefined )
      const age_breakdown = idx(values, x => x.age_breakdown.manual) || {};

      const ageBreakdown = Object.entries(age_breakdown).reduce(
        (a, [k, v]) =>
          typeof v !== "undefined" && v !== "" && v !== null && v !== false
            ? { ...a, [k]: v }
            : a,
        {}
      );
      data.age_breakdown.manual = ageBreakdown;

      updateAccount(account.id, data)
        .then(res => {
          setSubmitting(false);
        })
        .catch(err => {
          message.error("Failed to update account");
          setSubmitting(false);

          if (err.response.data) setErrors(err.response.data.meta.errors);
        });
    },

    displayName: "AccountForm",
  })(AccountForm)
);
