import React, { Component } from "react"
import FormField from "../FormField"
import { Switch } from "formik-antd"

export default class Platform extends Component {
  render() {
    return (
      <FormField name={this.props.name || "active"} label="Active">
        <Switch data-cy="account-active" />
      </FormField>
    )
  }
}
