// @ts-nocheck
import React from "react";
import { CheckCircleOutlined } from '@ant-design/icons';
import { Row, Col } from "antd";
import Form from "src/components/Forms/Form";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { CompleteDataFormContainer } from "./container";
import { Typography } from "src/components/Core/Typography";
import { Button } from "src/components/Core/Button";
import { LinkButton } from "src/components/Core/LinkButton";
import styled from "styled-components";
import { Loading } from "src/components/Core/Loading";
import { Box } from "src/components/Core/Box";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import {
  Followers,
  AgeBreakdown,
  LocationBreakdown,
  GenderBreakdown,
} from "./FormFields";
import ScreenshotsSlider from "./ScreenshotsSlider";

interface CompleteDataFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  closePaymentModal: () => void;
  invitationData: any;
  initialValues: Values;
  isSubmitting: boolean;
  PaymentRequestSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  goFirstStep: () => void;
}

export const CompleteDataFormWrapper = styled.div`
  width: 100%;
`;

const CompleteDataForm: React.FC<CompleteDataFormProps> = ({
  initialValues,
  isSubmitting,
  PaymentRequestSchema,
  onSubmit,
  globalErrors,
  closePaymentModal,
  invitationData,
  goFirstStep,
}) => {
  return (
    <CompleteDataFormWrapper>
      <Loading spinning={isSubmitting}>
        <Form
          initialValues={initialValues}
          schema={PaymentRequestSchema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {(formProps: any) => {
            return <>
              <Box mb={40}>
                <Box display="inline-flex">
                  <Box color="green" mr={10} fontSize={30}>
                    <CheckCircleOutlined />
                  </Box>
                  <Typography
                    size={30}
                    weight="semi-bold"
                    color="black"
                    lineHeight={0}
                    mt={18}
                  >
                    Thank You!
                  </Typography>
                </Box>
                <Typography size={16} color="black1" lspace={1.16}>
                  Your payment request has been registered. You will be
                  notified by email once it has been reviewed.
                </Typography>

                <Typography size={16} color="black1" lspace={1.16}>
                  <b>{invitationData?.account?.influencer?.name}</b> has
                  entered new analytics details. Please enter the submitted
                  details accordingly:
                </Typography>
              </Box>

              <GlobalErrors errors={globalErrors} />

              <Row gutter={48} justify="start">
                <Col span={12}>
                  <ScreenshotsSlider
                    screenshots={invitationData?.account?.screenshots || []}
                  />
                </Col>

                <Col span={12}>
                  <Followers />
                  <AgeBreakdown formProps={formProps} />
                  <GenderBreakdown formProps={formProps} />
                  <LocationBreakdown formProps={formProps} />
                </Col>
              </Row>

              <Box
                alignItems="right"
                textAlign="right"
                display="inherit"
                mt={20}
              >
                <LinkButton
                  onClick={() => {
                    closePaymentModal();
                    goFirstStep();
                  }}
                  mr={50}
                  id="infl_scrn_cancel"
                >
                  Cancel
                </LinkButton>

                <Button
                  htmlType="submit"
                  type="action"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  buttonSize="middle"
                  data-cy="complete-modal-form-submit"
                  id="infl_scrn_save"
                >
                  Save
                </Button>
              </Box>
            </>;
          }}
        </Form>
      </Loading>
    </CompleteDataFormWrapper>
  );
};

export { CompleteDataForm, CompleteDataFormContainer };
