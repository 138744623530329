// @ts-nocheck
import React, { Fragment } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { AccountsContDroppable as Props } from '../../props'

const DroppableCont = (props: Props) => {
  const { containerType, children, list } = props
  return(
    <Fragment>
      {list?.id ?
        <Droppable droppableId={containerType ? containerType : "other"}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.dropableProps} style={{minHeight: '200px'}}>
              {children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        :
        <Fragment>
          {children}
        </Fragment>
      }
    </Fragment>
  )
}

export default DroppableCont
