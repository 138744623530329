import { attr, Model } from "redux-orm";
import formatCurrency from "src/utils/formatCurrency";
import numeral from "numeral";

class CampaignAnalytic extends Model {
  static parse(data) {
    if (!data || typeof data !== "object") return data;

    const parsedData = {
      ...data,
    };

    return this.upsert(parsedData);
  }

  formattedCpc() {
    return this.cpc_pennies ? formatCurrency(this.cpc_pennies) : "£--";
  }

  formattedCpi() {
    return this.cpi_pennies ? formatCurrency(this.cpi_pennies) : "£--";
  }

  formattedCpm() {
    return this.cpm ? formatCurrency(this.cpm) : "£--";
  }

  formattedLikes() {
    return typeof this.metadata != "undefined" && this.metadata.like_count
      ? numeral(this.metadata.like_count).format("0,0")
      : "";
  }

  formattedEngagementRate() {
    return typeof this.metadata != "undefined" && this.metadata.engagement_rate
      ? numeral(this.metadata.engagement_rate).format("0,0.00") + "%"
      : null;
  }

  formattedComments() {
    return typeof this.metadata != "undefined" && this.metadata.comment_count
      ? numeral(this.metadata.comment_count).format("0,0")
      : "";
  }
}

CampaignAnalytic.modelName = "CampaignAnalytic";

// Declare your related fields.
CampaignAnalytic.fields = {
  id: attr(), // non-relational field for any value; optional but highly recommended
};

export default CampaignAnalytic;
