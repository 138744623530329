// @ts-nocheck
import {FormikInput} from "../../../../../../components/Forms/TextInput";
import {FormikMaskInput} from "../../../../../../components/Forms/MaskInput";

export const bankDetailFieldsByCurrency = {
    GBP: {
        fields: [
            {
                name: "fullName",
                component: FormikInput,
                props: {
                    placeholder: "John Doe",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Name on account"
                },
            },
            {
                name: "sortCode",
                component: FormikMaskInput,
                props: {
                    placeholder: "00-00-00",
                    format: "##-##-##",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Sort Code"
                },
            },
            {
                name: "accountNumber",
                component: FormikInput,
                props: {
                    placeholder: "GB24BKEN10000031510604",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Account number",
                },
            },
        ],
        renderTitle: values => {
            return values.fullName;
        },
        renderSubtitle: values => {
            let subTitle = "";
            if (values?.sortCode) {
                const matchedArr = (values?.sortCode || "").toString().match(/.{1,2}/g);
                if (matchedArr && matchedArr.length > 0) {
                    subTitle = matchedArr.join("-") + " ";
                }

                return `${subTitle} - ${values?.accountNumber}`;
            }
        },
    },

    USD: {
        fields: [
            {
                name: "fullName",
                component: FormikInput,
                props: {
                    placeholder: "John Doe",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Name on account"
                },
            },
            {
                name: "recipientAddress",
                component: FormikInput,
                props: {
                    placeholder: "2 River Ave. Fairhope, AL 36532",
                    size: "meddle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Recipient Address"
                },
            },
            {
                name: "routingNumber",
                component: FormikInput,
                props: {
                    placeholder: "091000019",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Routing number",
                },
            },
            {
                name: "accountNumber",
                component: FormikInput,
                props: {
                    placeholder: "59764439",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Account number",
                },
            },
            {
                name: "swift",
                component: FormikInput,
                props: {
                    placeholder: "USBKUS44XXX",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Swift",
                },
            },
        ],
        renderTitle: values => {
            return values.fullName;
        },
        renderSubtitle: values => {
            return `${values?.routingNumber} - ${values?.accountNumber}`;
        },
    },

    EUR: {
        fields: [
            {
                name: "fullName",
                component: FormikInput,
                props: {
                    placeholder: "John Doe",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Name on account"
                },
            },
            {
                name: "recipientAddress",
                component: FormikInput,
                props: {
                    placeholder: "191, Route des Eyzies",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Recipient Address",
                },
            },
            {
                name: "iban",
                component: FormikInput,
                props: {
                    placeholder: "DE71500105172865296758",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Iban",
                },
            },
            {
                name: "swift",
                component: FormikInput,
                props: {
                    placeholder: "BNPAFRPPBRO",
                    size: "middle",
                    borderRadius: 3,
                    borderColor: "grey8",
                    label: "Swift",
                },
            },
        ],
        renderTitle: values => {
            return values.fullName;
        },
        renderSubtitle: values => {
            if (values?.swift) {
                return `${values?.swift} - ${values?.iban}`;
            } else {
                return values?.iban;
            }
        },
    }
}
