// @ts-nocheck
import React, { useState } from "react";
import Form from "src/components/Forms/Form";
import { EyeOutlined, YoutubeFilled } from '@ant-design/icons';
import { Row, Col, Card } from "antd";
import { FormikHelpers as FormikActions, FormikValues } from "formik";
import { Schema } from "yup";
import { ProfileDataFormContainer } from "./container";
import { Button } from "src/components/Core/Button";
import styled from "styled-components";
import Loading from "src/shared/Loading";
import { Box } from "src/components/Core/Box";
import { Typography } from "src/components/Core/Typography";
import {
  GlobalErrorProps,
  GlobalErrors,
} from "src/components/Forms/ErrorMessage";
import { Age, Gender, Location } from "../../../components/Fields";
import analytics from "src/assets/images/contract/analytics.png";
import { DemographicScreenshotsContainer } from "./Screenshots";
import AnalyticsScreenshotExampleModal from "./AnalyticsScreenshotExampleModal";
import YoutubeProfileData from "./YoutubeProfileData";
import { Visible } from "react-grid-system";
import FormButtons from "../FormButtons";

interface ProfileDataFormProps<Values = FormikValues> {
  globalErrors?: GlobalErrorProps[];
  initialValues: Values;
  isSubmitting: boolean;
  profileDataSchema: Schema<object>;
  onSubmit: (values: any, formikActions: FormikActions<any>) => void;
  cashoutData: any;
  setShowExample: (value: object) => void;
  setScreenshotType: (value: string) => void;
  showExample: object;
  isConnected: boolean;
  handleAuthClick: Function;
  type: string;
  index: number;
  goBack: () => void;
}

export const ProfileDataFormWrapper = styled.div`
  width: 100%;
`;

const ScreenshotCard = styled(Card)`
  &&& {
    background: none !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: none !important;
    border-radius: 4px;
  }
`;

const ProfileDataForm: React.FC<ProfileDataFormProps> = ({
  initialValues,
  isSubmitting,
  profileDataSchema,
  onSubmit,
  globalErrors,
  cashoutData,
  setShowExample,
  setScreenshotType,
  showExample,
  isConnected,
  handleAuthClick,
  type,
  index,
  goBack,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [showDemographicExample, setShowDemographicExample] = useState(false);

  return (
    <ProfileDataFormWrapper>
      <Loading spinning={isSubmitting}>
        <GlobalErrors errors={globalErrors} />

        <Box p={24}>
          <Visible xl lg>
            <Typography size={20} color="black6" lineHeight="20px">
              Profile data
            </Typography>
          </Visible>

          {type === "youtube" && (
            <Typography size={16} color="grey2">
              Please enter the following details about yourself and your account
              by clicking connect with Youtube
            </Typography>
          )}

          {type !== "youtube" && (
            <Typography size={16} color="grey2">
              Please provide the following details about your account
            </Typography>
          )}

          {type === "youtube" && (
            <>
              <Button
                style={{
                  background: "#FF4D4F",
                  color: "#FFFFFF",
                  border: "1px solid #FF4D4F",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
                buttonSize="middle"
                onClick={handleAuthClick}
                disabled={isConnected ? true : false}
              >
                <YoutubeFilled style={{ color: "#FFF", fontSize: 20 }} />{" "}
                {isConnected ? "Connected" : "Connect"} to Youtube
              </Button>

              <Typography size={16} color="grey2">
                Or fill out the form below manually
              </Typography>
            </>
          )}

          <Form
            initialValues={initialValues}
            schema={profileDataSchema}
            onSubmit={(values, actions) => onSubmit(values, actions)}
          >
            {(formProps: any) => {
              return <>
                <Row gutter={12}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Gender />
                    <Location />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {formProps?.values?.platformType !== "TikTok" && <Age />}
                  </Col>
                </Row>
                {type !== "youtube" && (
                  <Box>
                    <Typography size={16} color="grey2">
                      Please submit your demographic screenshot
                    </Typography>

                    <Row gutter={32}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <DemographicScreenshotsContainer
                          name="screenshots"
                          setIsUploading={loading => setIsUploading(loading)}
                          setFieldValue={value =>
                            formProps?.setFieldValue("screenshots", value)
                          }
                          setFieldTouched={() =>
                            formProps?.setFieldTouched("screenshots", true)
                          }
                          values={formProps?.values}
                          multiple={true}
                          disabled={false}
                          uploadText="Please provide your breakdown screenshot"
                          accountName={cashoutData?.account?.username || ""}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <ScreenshotCard
                          cover={
                            <img
                              src={analytics}
                              alt="analytics"
                              height={300}
                              style={{
                                objectFit: "cover",
                                objectPosition: "center center",
                              }}
                            />
                          }
                        >
                          <Button
                            type="action"
                            buttonSize="large"
                            data-cy="example-button"
                            style={{ width: "100%" }}
                            onClick={() => setShowDemographicExample(true)}
                          >
                            <EyeOutlined /> View example
                          </Button>
                        </ScreenshotCard>
                      </Col>
                    </Row>
                  </Box>
                )}
                {type === "youtube" && (
                  <YoutubeProfileData
                    cashoutData={cashoutData}
                    setShowExample={value => setShowExample(value)}
                    setScreenshotType={value => setScreenshotType(value)}
                    showExample={showExample}
                    formProps={formProps}
                    setIsUploading={value => setIsUploading(value)}
                  />
                )}

                <FormButtons
                  index={index}
                  goBack={goBack}
                  onSubmit={formProps?.handleSubmit}
                  isSubmitting={isSubmitting}
                  isDisabled={isSubmitting || isUploading}
                  id="hub-initial-contact"
                />
              </>;
            }}
          </Form>

          <AnalyticsScreenshotExampleModal
            hideExample={() => setShowDemographicExample(false)}
            showExample={showDemographicExample}
          />
        </Box>
      </Loading>
    </ProfileDataFormWrapper>
  );
};

export { ProfileDataForm, ProfileDataFormContainer };
