import React, { Component } from "react";
import LiveContent from "./LiveContent";

export class PostedContent extends Component {
  render() {
    const { analytics, campaign, style } = this.props;

    return (
      analytics && (
        <div style={style}>
          {campaign.ad_format !== "Themed Pages" && (
            <LiveContent campaign={campaign} />
          )}
        </div>
      )
    );
  }
}

export default PostedContent;
